import DB from "@db";
import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import errors from "@feathersjs/errors";
import _ from "lodash";
import { OfflineManager } from "../..";

export async function create(
  hook: MHookContext<
    AdminApplication,
    CreateType<"tableSessions/void">,
    { session: typeof DB.TableSession._mongoType; payments: (typeof DB.Payment._mongoType)[] }
  >,
) {
  hook.result = { session: null, payments: null };

  const session = await hook.app.service("tableSessions").get(hook.data.session);
  if (session.status === "void") {
    hook.result = { session, payments: [] };
    return;
  }
  if (session.status !== "done") {
    throw new errors.BadRequest("Invalid status");
  }

  const refundedPayments = [];

  if (session.payments?.length && (hook.data.refund ?? true)) {
    throw new Error(`Need to refund payments first`);
  }

  // if (session.user && session.coins) {
  //   await hook.app.service("memberCoinsRecords").create({
  //     user: session.user,
  //     change: -session.coins,
  //     description: "POS Order Void",
  //     shop: session.shop,
  //     posOrder: session._id,
  //   });
  // }

  // for (let discount of session.discounts) {
  //   if (discount.source === "coupon" && discount.coupon) {
  //     await hook.app.service("userCoupons").patch(
  //       discount.coupon,
  //       {
  //         useDate: null,
  //         status: "valid",
  //         session: null,
  //       },
  //       {
  //         query: {
  //           session: session._id,
  //         },
  //       },
  //     );
  //     delete discount.coupon;
  //   }
  // }

  // const gifts = await hook.app.service("giftRedeemRecords").find({
  //   query: {
  //     session: session._id,
  //     status: "valid",
  //     $populate: ["gift"],
  //   },
  //   paginate: false,
  // } as const);

  // for (let gift of gifts) {
  //   if (!gift.gift) continue;

  //   await hook.app.service("giftRedeemRecords").patch(gift._id, {
  //     status: "cancel",
  //   });

  //   await hook.app.service("memberCoinsRecords").create({
  //     user: session.user,
  //     change: gift.gift.coins,
  //     description: "POS Order Void",
  //     shop: session.shop,
  //     posOrder: session._id,
  //   });
  // }

  // if (session.hasPendingAction) {
  //   await hook.app.service("tableSessionActions/update").create({
  //     session: session._id,
  //     update: {
  //       status: "cancelled",
  //     },
  //   });
  // }

  hook.result.session = await hook.app.service("tableSessions").patch(session._id, {
    status: "void",
    discounts: session.discounts,
    payments: [],
  });

  if (session.twInvoice) {
    const offline: OfflineManager = (hook as any).$offline;
    if (offline.turnCloud) {
      hook.result.session = await offline.turnCloud.cancel(hook, hook.result.session as any);
    }
  }

  await hook.app.service("actionLogs").create({
    device: hook.params.connection?.posDevice?._id,
    shop: hook.params.connection?.posDevice?.shop,
    shopGroup: hook.params.connection?.shopGroup?._id,
    session: session._id,
    modifiedBy: hook.params.user?._id,
    view: session.view,
    staff: hook.data.staff,
    type: "orderManage/tableSessionCancelOrder",
    detail: { payment: refundedPayments },
  });
}
