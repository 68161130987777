import type DB from "@db";
import type { ObjectID } from "@db";
import type { FindType } from "@feathersjs/feathers";
import type { AdminApplication } from "serviceTypes";

type IngredientType = typeof DB.Ingredient._mongoType;

export interface IngredientInfo {
  ingredient: ObjectID<"Ingredient">;
  amount: number;
  
  expiry?: Date
  cost?: number
  supplier?: string
}

export function sumIngredients(dest: IngredientInfo[], ...list: IngredientInfo[][]) {
  if (!list.length) return dest;
  let destDict: Record<string, IngredientInfo>;
  for (let ingredents of list) {
    if (!ingredents?.length) continue;

    if (!dest.length) {
      for (let cur of ingredents) {
        if (!cur.amount) continue;
        dest.push({ ingredient: cur.ingredient, amount: cur.amount });
      }
    } else {
      if (!destDict) {
        destDict = Object.fromEntries(dest.map(d => [d.ingredient, d]));
      }

      for (let item of ingredents) {
        if (!item.amount) continue;
        let cur = destDict[`${item.ingredient}`];
        if (!cur) {
          cur = destDict[`${item.ingredient}`] = { ingredient: item.ingredient, amount: item.amount };
          dest.push(cur);
        } else {
          cur.amount += item.amount;
        }
      }
    }
  }

  return dest;
}

export function multiplyIngredients(dest: IngredientInfo[], multiplier = 1): IngredientInfo[] {
  if (multiplier === 1) return dest;
  if (multiplier === 0) return [];
  let needFilter = false;
  for (let item of dest) {
    item.amount = Math.round(item.amount * multiplier);
    if (!item.amount) needFilter = true;
  }
  if (needFilter) {
    return dest.filter(it => !!it.amount);
  }
  return dest;
}

export type IngredientItem = FindType<"ingredients/cached", AdminApplication>;
export type IngredientPlan = FindType<"ingredientPlans", AdminApplication>;
export type IngredientPlanEntry = IngredientPlan["ingredients"][number];

export const INGREDIENT_MULTIPLIER = 1000;
export const INGREDIENT_MULTIPLIER_PRICE = INGREDIENT_MULTIPLIER * INGREDIENT_MULTIPLIER * 100;

export function fromDb(v: number) {
  return (v ?? 0) / INGREDIENT_MULTIPLIER;
}
export function toDb(v: number) {
  return isNaN(+v) ? undefined : Math.round(+v * INGREDIENT_MULTIPLIER);
}

export function priceFromDb(v: number, multipler: number) {
  return (v ?? 0) * (multipler || 1) / INGREDIENT_MULTIPLIER_PRICE;
}
export function priceToDb(v: number, multipler: number) {
  return isNaN(+v) ? undefined : Math.round(+v * INGREDIENT_MULTIPLIER_PRICE / multipler);
}
export function totalPrice(v: number) {
  return (v ?? 0) / INGREDIENT_MULTIPLIER_PRICE
}
