<script>
import _ from 'lodash'
import fieldDefs from '~/plugins/fieldDefs'
import { 
    VAutocomplete, 
    VSelect,
    VListItemContent,
    VListItemTitle, 
    VListItemSubtitle
} from 'vuetify/lib'

export default {
    components: {
        VAutocomplete,
        VSelect,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,
    },
    props: {
        value: {},
        label: {},
        path: {},
        fields: {},
        itemText: { default: 'name' },
        itemValue: { default: '_id' },
        enumName: {},
        items: {},
        cond: {},
        args: {},
        multiple: { default: false, type: Boolean},
        outlined: {},
        prefix: {},
        prependIcon: {},
        readonly: {},
        clearable: {},
        returnObject: { default: false, type: Boolean },
        hideDetails: {default: false, type: Boolean },
        pickFirst: { default: false, type: Boolean },
        dense: { default: false, type: Boolean },
        all: { default: false, type: Boolean },
        view: {},
        filter: {},
    },
    data () {
        return {
            loading: false,
            search: '',
            mcond: null,
            lastQuery: null,
            fitems: [],
            canCache: false,
            reg: null,
            selectedCache: {},
        }
    },
    async mounted () {
        await this.loadObjects();
        if(!this.select && this.pickFirst) {
            if(this.mitems.length) {
                this.select = this.returnObject ? this.mitems[0] : this.mitems[0][this.itemValue];
            }
        }
    },
    watch: {
        search (v, ov) {
            if (v === ov) return;
            if (typeof v === 'object') return;
            this.reg = this.search && new RegExp(`(${escapeRegExp(this.search)})`, 'gi');
            return this.loadObjects();
        },

        cond: {
            deep: true,
            handler (val) {
                if (_.isEqual(val, this.mcond)) return;
                this.mcond = _.cloneDeep(val);
                this.clearCache();
                return this.loadObjects();
            }
        },

        path (v, ov) {
            if (v === ov) return;
            this.lastQuery = null;
            this.fitems = [];
            this.clearCache();
            return this.loadObjects();
        }

    },
    computed: {
        mitems () {
            let l = this.items ? _.map(this.items, item => {
                if(!item.hasOwnProperty('$text')) {
                    Object.defineProperty(item, '$text', {
                        enumerable: false,
                        get: () => this.computeText(item, this.customView.name, true, this.customView.func)
                    });
                }
                return item;
            }) : this.enumName ? this.$enums[this.enumName] : this.fitems;
            if (this.filter) {
                l = l.filter(it => this.filter(it));
            }
            return l;
        },
        customView () {
            return (fieldDefs[this.path] && fieldDefs[this.path].view) || this.view;
        },
        select: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val || (this.multiple ? [] : null));
                if (!this.path) return;
                if(val) {
                    _.each(this.multiple ? val : [val], key => {
                        if(!this.selectedCache[key]) {
                            this.selectedCache[key] = this.fitems.find(it => _.get(it, this.itemValue) === key);
                        }
                    })
                }
            }
        },
        mfields () {
            return this.fields || (fieldDefs[this.path] && fieldDefs[this.path].fields)
        },
        rawSearch() {
            return (fieldDefs[this.path] && fieldDefs[this.path].rawSearch)
        },
        mitemText () {
            return this.customView ? '$text' : this.itemText;
        }
    },
    render (_c) {
        return _c((!this.enumName && !this.items) ? 'v-autocomplete' : 'v-select', {
            attrs: {
                label: this.label,
                loading: this.lloading,
                cacheItems: this.canCache,
                searchInput: this.search,
                items: this.mitems,
                itemValue: this.itemValue,
                itemText: this.mitemText,
                filter: (this.mfields || this.rawSearch) ? (() => true) : undefined,
                multiple: this.multiple,
                prefix: this.prefix,
                prependIcon: this.prependIcon,
                clearable: this.clearable,
                readonly: this.readonly,
                returnObject: this.returnObject,
                hideDetails: this.hideDetails,
                outlined: this.outlined,
                dense: this.dense,
            },
            on: {
                'update:searchInput': ($$v) => {
                    this.search = $$v;
                },
                'update:search-input': ($$v) => {
                    this.search = $$v;
                }
            },
            model: {
                value: this.select,
                callback: ($$v) => { this.select = $$v },
            },
            scopedSlots: {
                item: this.customView ? ((data) => _c('v-list-item-content', {
                }, [
                    _c('v-list-item-title', { domProps: { innerHTML: this._s(this.computeText(data.item, this.customView.name, undefined, this.customView.func)) } }),
                    this.customView.subtitle && _c('v-list-item-subtitle', { domProps: { innerHTML: this._s(this.computeText(data.item, this.customView.subtitle, undefined, this.customView.func)) } }),
                ], 1)) : undefined,
            }
        })
    },
    methods: {
        async loadObjects () {
            if (!this.path) return;
            this.loading = true;
            try {
                const service = this.$feathers.service(this.path);
                const reg = this.all ? null : this.search && escapeRegExp(this.search);

                let query = reg && this.mfields && this.mfields.length ? {
                    $or: _.map(this.mfields, f => ({
                        [f]: {
                            $regex: reg,
                            $options: 'i'
                        }
                    }))
                } : this.search && this.rawSearch ? {
                    [this.rawSearch]: this.search,
                } : {};

                if (this.cond && !this.mcond) this.mcond = _.cloneDeep(this.cond);

                if (this.mcond) {
                    if (query) {
                        query = {
                            $and: [
                                query,
                                this.mcond,
                            ]
                        }
                    } else {
                        query = this.mcond;
                    }
                }

                if(this.args) {
                    query = {
                        ...this.args,
                        ...query,
                    }
                }

                query = query || {};
                if (_.isEqual(query, this.lastQuery)) return;
                this.lastQuery = _.cloneDeep(query);
                let data = _.get(await service.find({query}), 'data') || [];

                if(this.select && (this.multiple && this.select.length > 0 || !this.multiple)) {
                    const dict = _.fromPairs(_.map(data, it => [_.get(it, this.itemValue), true]));
                    const needFetch = [];
                    _.each(this.multiple ? this.select : [this.select], key => {
                        if(dict[key]) return;
                        else if(this.selectedCache[key]) {
                            data.push(this.selectedCache[key]);
                        } else {
                            needFetch.push(key);
                        }
                    });

                    if(needFetch.length > 0) {
                        let extra =  _.get(await service.find({
                            query: {
                                ...this.args,
                                [this.itemValue]: {
                                    $in: needFetch,
                                },
                                $limit: needFetch.length,
                            }
                        }), 'data') || [];
                        data = data.concat(extra);
                        _.each(extra, it => this.selectedCache[_.get(it, this.itemValue)] = it);
                    }
                }

                if (this.customView) {
                    _.each(data, item => {
                        if(!item.hasOwnProperty('$text')) {
                            Object.defineProperty(item, '$text', {
                                enumerable: false,
                                get: () => this.computeText(item, this.customView.name, true)
                            });
                        }
                    })
                }
                this.fitems = data;
            } catch(e) {
              console.warn(e);
            } finally {
                this.loading = false;
            }
        },

        computeText (item, view, text, func) {
            if(func) {
                return func(item);
            }
            const isHtml = !text;
            const html = [];
            if (!view) return '';
            isHtml && html.push('<div style="display: flex">');
            if (typeof view === 'string') view = [view];
            _.each(view, it => {
                isHtml && html.push('<div style="flex: 1">');
                let val = _.get(item, it) || '';
                if(this.customView.translate) val = this.$td(val) || '';
                if (typeof val === 'object') val = JSON.stringify(val);
                val = `${val}`
                if (this.reg && isHtml) {
                    val.split(this.reg).map((it, idx) => {
                        if (idx % 2 === 1) {
                            html.push('<span class="v-list__tile__mask">');
                            html.push(escapeHTML(it));
                            html.push('</span>');
                        } else {
                            html.push(escapeHTML(it));
                        }
                    });
                } else {
                    html.push((isHtml && escapeHTML(val)) || val);
                }
                isHtml && html.push('</div>');
            });
            isHtml && html.push('</div>');
            return html.join((text && '/') || '');
        },
        
        clearCache() {
            this.selectedCache = {};
        },
    }
}
function escapeRegExp (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
var escapeHTML = function (unsafe) {
    return unsafe.replace(/[&<"']/g, function (m) {
        switch (m) {
        case '&':
            return '&amp;';
        case '<':
            return '&lt;';
        case '"':
            return '&quot;';
        default:
            return '&#039;';
        }
    });
};
</script>

<style>

</style>
