
import { Component, Vue, FindType, Prop, Watch } from "@feathers-client";

@Component
export default class SessionSummary extends Vue {
  @Prop()
  session: FindType<"shopSessions">;

  @Prop()
  cash: FindType<"shopSessions">["cash"];

  @Prop()
  all: FindType<"shopSessions">["all"];

  @Prop()
  otherMethods: FindType<"orderPaymentSummary">; // to display summaries of different payment methods

  @Prop()
  fromCheck: FindType<"cashboxLogs">;

  @Prop({ default: false })
  disableViewable: boolean;

  viewable: boolean = false;

  hasPermission: boolean = false;

  get needPermission() {
    return this.$shop.hasPermission(["openingClosing/viewShopSummary"]);
  }

  async beforeMount() {
    this.viewable = await this.$shop.hasPermission(["openingClosing/viewShopSummary"]);
  }

  async viewShopSummary() {
    const staff = await this.$shop.checkPermission(["openingClosing/viewShopSummary"]);
    if (staff === false) return;
    this.hasPermission = true;
    this.viewable = true;
    await this.$feathers.service("actionLogs").create({
      staff: staff?._id,
      type: `openingClosing/viewShopSummary`,
    });
  }

  @Watch("needPermission")
  onNeedPermissionChange() {
    if (this.hasPermission && this.viewable) return;
    this.viewable = this.needPermission;
  }
}
