
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, FindType, FindPopRawType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";

@Component
export default class MobileOrderRecord extends Vue {
  @Prop()
  item: FindType<"tableSessions">;

  @Prop(Boolean)
  details: boolean;

  get cancelled() {
    return this.item.status === "cancelledBooking" || this.item.status === "void" || this.item.status === "cancelled";
  }

  get isBooking() {
    return this.item.status === "booking";
  }

  get removing() {
    return (this.item as any).removing as boolean;
  }

  set removing(v: boolean) {
    Vue.set(this.item as any, "removing", v);
  }

  get adding() {
    return (this.item as any).adding as boolean;
  }

  set adding(v: boolean) {
    Vue.set(this.item as any, "adding", v);
  }

  get orderType() {
    return this.item?.type;
  }

  get currentTableCapacity() {
    return this.item.tables?.[0]?.capacity;
  }

  get tableOrderNumber() {
    return this.item.sessionName;
  }

  get tableNumber() {
    return this.item.tableRefName;
  }

  get orderStatusColor() {
    switch (this.item.status) {
      case "toPay":
        return "bg-orange200";
      case "done":
        return "bg-green400";
      case "cancelled":
      case "void":
        return "bg-red400";
      default:
        return "bg-grey400";
    }
  }

  formatDate(time) {
    const m = moment(time);
    const today = moment().startOf("day");
    const tmr = moment(today).add(1, "day");
    const tmrtmr = moment(tmr).add(1, "day");

    if (m.isSameOrAfter(tmrtmr) || m.isBefore(today)) {
      return m.format("YYYY-MM-DD");
    } else if (m.isBefore(tmr)) {
      return this.$t("time.today");
    } else {
      return this.$t("time.tomorrow");
    }
  }

  formatTime(time) {
    return moment(time).format(this.details ? "YYYY-MM-DD HH:mm" : "HH:mm");
  }

  get status() {
    if (this.item?.products.some(product => product.status === "cancel")) return "cancel";
    else if (this.item?.products.some(product => product.status === "hold")) return "hold";
    else {
      if (this.item?.products.some(product => product.status === "sent")) return "sent";
      else {
        return "init";
      }
    }
  }

  get kdsStatus() {
    if (this.item?.products.some(product => product.kdsStatus === "hold")) return "hold";
    else if (this.item?.products.some(product => product.kdsStatus === "pending")) return "pending";
    else {
      if (this.item?.products.some(product => product.kdsStatus === "partialDone" || "done")) return "done";
      else {
        return null;
      }
    }
  }
  productStatus(state) {
    switch (state) {
      case "init":
        return "bg-grey100";
      case "pending":
        return "bg-blue000";
      case "done":
      case "partialDone":
        return "bg-purple400";
      case "hold":
        return "bg-red400";
      case "cancel":
        return "bg-red200";
    }
  }

  statusDisplay(state) {
    switch (state) {
      case "pending":
        return this.$t("tableView.status.pending");
      case "done":
      case "partialDone":
        return this.$t("tableView.status.done");
      case "hold":
        return this.$t("tableView.status.hold");
      case "cancel":
        return this.$t("tableView.status.cancel");
    }
  }
}
