import { MultiCodePage, PrintSequence, TextAlign } from "./";
import { PrinterBaseConf } from "../printers/baseConf";
import { getImage, getQR, js_process_dither } from "./escpos/image";
import { RawLine } from "./rawLine";
import _ from "lodash";
import qr from "qr-image";
import { BitmapOpts, WrappedContext } from "../common";

// StarPRNT
// https://starmicronics.com/support/developers/thermal-desktop-mobile-printer-command-specs/

export class StarGraphPrintSequence extends PrintSequence {
  feedValue = 10;

  constructor(
    public context: WrappedContext,
    printer: PrinterBaseConf,
  ) {
    super(context, printer);
    // ESC * r A
    this.raw([0x1b, 0x2a, 0x72, 0x41]);
    // ESC * r P n NUL
    this.raw([0x1b, 0x2a, 0x72, 0x50, 0x00, 0x00]);
  }

  get nativeQR() {
    return false;
  }

  preCut = false;

  currentAlign: TextAlign = TextAlign.Left;

  align(n: TextAlign): this {
    this.currentAlign = n;
    return this;
  }

  async printImageData(
    buffer: Uint8ClampedArray,
    width: number,
    height: number,
    hiRes?: boolean | number,
    color?: number,
  ) {
    if(this.preCut) {
      this.cutInner();
      this.preCut = false;
    }
    if(color !== undefined) {
      this.raw([0x1b, 0x2a, 0x72, 0x4b, color, 0x00]);
    }

    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;

    const finalWidth = hHi ? width : width * 2;

    const xWidth = this.normalLineWidth * this._dpi;
    const xOfs = this.currentAlign === TextAlign.Center ? Math.max((xWidth - finalWidth) / 2, 0) | 0 : this.currentAlign === TextAlign.Right ? Math.max(xWidth - finalWidth, 0) | 0 : 0;
    const bitsStart = xOfs;
    const lineBytes = (xWidth + 7) >> 3;

    for (let i = 0; i < height; i++) {
      let ofs = i * width * 4;
      const line = Buffer.alloc(lineBytes);
      let bit = bitsStart;
      for (let j = 0; j < finalWidth; j++, bit++) {
        const b = buffer[ofs];
        line[bit >> 3] |= (b > 127 ? 0 : 0x80) >> (bit & 7);
        if (hHi || (j & 1) === 1) {
          ofs += 4;
        }
      }

      this.raw([0x62, lineBytes % 256, lineBytes >> 8]);
      this.raw(line);
      if (!vHi) {
        this.raw([0x62, lineBytes % 256, lineBytes >> 8]);
        this.raw(line);
      }
    }
    return this;
  }

  fill(c?: string, n?: number) {
    const xWidth = this.normalLineWidth * this._dpi;
    const lineBytes = (xWidth + 7) >> 3;
    const blackLine = Buffer.alloc(lineBytes, 0xff);
    const whiteLine = Buffer.alloc(lineBytes, 0x00);

    const px = (c: boolean) => { 
      this.raw([0x62, lineBytes % 256, lineBytes >> 8]);
      this.raw(c ? blackLine : whiteLine);
    }

    if(c === "=") {
      px(false);
      px(false);
      px(false);
      px(true);
      px(false);
      px(false);
      px(false);
      px(true);
      px(false);
      px(false);
      px(false);
    } else {
      px(false);
      px(false);
      px(false);
      px(true);
      px(true);
      px(false);
      px(false);
      px(false);
    }

    return this;
  }

  feed(n: number) {
    for(let i = 0; i < n; i++) {
      this.raw([0x62, 0, 0]);
    }
    return this;
  }

  cut() {
    if(this.preCut) {
      this.cutInner();
    }
    this.preCut = true;
    return this;
  }

  cutInner() {
    // ESC * r E n NUL
    // this.raw([0x1b, 0x2a, 0x72, 0x45, 0x00, 0x00]);
    // ESC FF EOT
    this.raw([0x1b, 0x0c, 0x04]);
  }

  finish(): void {
    // ESC * r E n NUL
    // this.raw([0x1b, 0x2a, 0x72, 0x45, 0x02, 0x00]);
    // ESC * r B
    this.raw([0x1b, 0x2a, 0x72, 0x42]);
  }

  cashBox(which: number, time: number) {
    this.raw([0x07]);
    return this;
  }

  color(n: number): this {
    // ESC * r K n NUL
    this.raw([0x1b, 0x2a, 0x72, 0x4b, n, 0x00]);
    return this;
  }

  reset(): this {
    return this;
  }

  lineHeight(height?: number): this {
    return this;
  }

  getJobOpts() {
    if (this.printer?.conf?.port === "cloud") {
      return {
        type: "escpos", // workaround for cloud printing
      };
    }
    return {
      type: "star",
    };
  }
}
