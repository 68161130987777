
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { getSplitName } from "~/plugins/table/utils";
import { SessionConainterLayout, TableView } from "~/plugins/table/view";
import type { Group } from "konva/lib/Group";
import type { Node } from "konva/lib/Node";
import type { TableItem, TableSessionRef } from "~/plugins/table";
import { SessionMoveAction, SessionMoveActionTo } from "~/plugins/table/session";
import iconFonts from "~/plugins/iconfont";
import type TableEditor from "../Editor.vue";

Vue.directive("konva-animate", {
  bind(el, binding, vm) {
    const node = (vm?.componentInstance as any)?._konvaNode;
    if (binding.value) {
      binding.value(node);
    }
  },
});

@Component
export default class ItemSession extends Vue {
  @Prop()
  layout: SessionConainterLayout;

  @Prop()
  count: number;

  @Prop()
  index: number;

  @Prop(Boolean)
  takeAway: boolean;

  @Prop()
  view: TableView;

  @Prop()
  showTable: boolean;

  async animateConfig(group: Group) {
    // do transform
    // disabled not working animation
    // if (this.layout.item._x === undefined) return;
    // await Vue.nextTick();
    // group.moveToTop();
    // const transform = group.getAbsoluteTransform(group.getLayer()).copy();
    // const newPt = transform.invert().point({ x: this.layout.item._x, y: this.layout.item._y });
    // // console.log(newPt);
    // group.setPosition({
    //   x: newPt.x + this.layout.x + this.layout.w / 2,
    //   y: newPt.y + this.layout.y + this.layout.h / 2,
    // });
    // delete this.layout.item._x;
    // delete this.layout.item._y;
    // group.to({
    //   x: this.layout.x + this.layout.w / 2,
    //   y: this.layout.y + this.layout.h / 2,
    //   duration: 0.2,
    // });
  }

  mounted() {
    Vue.nextTick(() => {
      (this.$refs.group as any)?.getNode?.()?.cache?.();
    });
    if (this.session.session?.selected) {
      this.onSelected();
    }
  }

  @Watch("session.session.selected")
  onSelected() {
    const group: Group = (this.$refs.group as any).getNode();
    if (this.session.session?.selected) {
      Vue.nextTick(() => {
        group.moveToTop();
      });
    } else if (typeof this.index === "number") {
      group.setZIndex(this.index);
    }
  }

  get color() {
    return this.layout.item.session.color;
  }

  get session() {
    return this.layout.item;
  }

  get item() {
    return this.session.table;
  }

  get tableName() {
    return this.item.name + getSplitName(this.session.split);
  }

  get name() {
    return this.session.session?.sessionName || "";
  }

  get mview() {
    return this.view ?? this.session?.session?.view;
  }

  get configPrefix() {
    const w = this.layout.w;
    const h = this.layout.h;

    return {
      x: -w / 2 + 4,
      y: -h / 2 + 7 + (this.showTable ? 24 : 0),
      width: w - 8,
      height: h / 2,
      align: "left",
      verticalAlign: "top",
      text: this.prefixText,
      fontFamily: this.$config.fontFamily,
      fontSize: Math.round(Math.min(w / 4, 18)),
      perfectDrawEnabled: false,
      listening: false,
      fill: this.color,
    };
  }

  get prefixText() {
    // if(this.session.session?.isDineInNoTable && this.$shop.shopData.onlineDineInNoTableEnabledFixedCode){
    //   return this.session.session?.name ?? "#";
    // }
    return this.session.session?.namePrefix ?? "#";
  }

  get configLabel() {
    const w = this.layout.w;
    const h = this.layout.h;
    const text = this.session.session?.nameContent ?? "";

    return {
      x: -w / 2 + 8,
      y: -h / 2 + 10 + (this.showTable ? 24 : 0),
      width: w - 15,
      height: h / 2,
      align: "right",
      verticalAlign: "top",
      text,
      fontFamily: this.$config.fontFamily,
      fontSize: Math.round(Math.min(w / (text.length >= 4 ? 4 : 3), text.length >= 4 ? 16 : 24)),
      perfectDrawEnabled: false,
      listening: false,
      fill: this.color,
    };
  }

  get groupConfig() {
    const w = this.layout.w;
    const h = this.layout.h;
    return {
      x: this.layout.x + this.layout.w / 2,
      y: this.layout.y + this.layout.h / 2,
      draggable: !this.takeAway,
      dragDistance: 20,
    };
  }

  get configContainer() {
    return {
      x: -this.layout.w / 2,
      y: -this.layout.h / 2,
      width: this.layout.w,
      height: this.layout.h,
      fill: "white",
      cornerRadius: 4,
      shadowOffset: {
        x: 4,
        y: 4,
      },
      shadowBlur: 8,
      shadowOpacity: 0.2,
      stroke: this.session.session?.selected ? this.$config.colors.orange500 : this.$config.colors.grey200,
      strokeWidth: 2,
      shadowColor: "rgba(20, 24, 33, 0.2)",
      // fillAfterStrokeEnabled: true,
    };
  }

  get configTableContainer() {
    return {
      x: -this.layout.w / 2 + 4,
      y: -this.layout.h / 2 + 4,
      width: this.layout.w - 8,
      height: 22,
      fill: this.color,
      cornerRadius: 1,
    };
  }

  get configTable() {
    const w = this.layout.w;
    const h = this.layout.h;
    return {
      x: -w / 2 + 4,
      y: -h / 2 + 6,
      width: w - 8,
      height: 20,
      align: "center",
      verticalAlign: "middle",
      text: this.session.session?.name ?? "",
      fontFamily: this.$config.fontFamily,
      fontSize: Math.round(Math.min(w - 8 / 3, 20)),
      perfectDrawEnabled: false,
      listening: false,
      fill: "white",
    };
  }

  iconBgConfig = {
    radius: 15,
    fill: "black",
    opacity: 0.4,
    y: 10,
  };

  get iconConfig() {
    return this.session.session.status === "done"
      ? {
          x: -9,
          y: -7 + 10,
          data: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
          fill: "white",
        }
      : this.session.session.status === "void"
        ? {
            x: -11,
            y: -11 + 10,
            data: "M12 0c-3.31 0-6.291 1.353-8.459 3.522l-2.48-2.48-1.061 7.341 7.437-.966-2.489-2.488c1.808-1.808 4.299-2.929 7.052-2.929 5.514 0 10 4.486 10 10s-4.486 10-10 10c-3.872 0-7.229-2.216-8.89-5.443l-1.717 1.046c2.012 3.803 6.005 6.397 10.607 6.397 6.627 0 12-5.373 12-12s-5.373-12-12-12z",
            fill: "white",
          }
        : this.session.session.status === "cancelled"
          ? {
              x: -11,
              y: -11 + 10,
              data: "M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z",
            }
          : null;
  }

  get timerText() {
    const time = this.session.session.sessionTime;

    return time === -1 ? null : `${time > 9999 ? "9999+" : time + "'"}`;
  }

  get configTimer() {
    const w = this.layout.w;
    return {
      x: -w / 2 - 2,
      y: this.layout.h / 2 - 15,
      width: w - 4,
      height: 10,
      align: "right",
      verticalAlign: "center",
      text: `${iconFonts.timer} ${this.timerText}`,
      fontSize: Math.min(w / 4, 14),
      fontFamily: "iconfont," + this.$config.fontFamily,
      perfectDrawEnabled: false,
      listening: false,
      fill: this.timerColor,
      wrap: "none",
    };
  }

  get timerColor() {
    const status = this.session.session.sessionTimeStatus;
    return status === "nearDue" ? "orange" : status === "due" ? "#DF6D6D" : "black";
  }

  @Watch("configSharp")
  @Watch("configLabel")
  @Watch("groupConfig")
  @Watch("configContainer")
  @Watch("configCorner")
  @Watch("iconConfig")
  @Watch("configTimer")
  onUpdated() {
    if (!this.dirty) {
      this.dirty = true;
      Vue.nextTick(() => {
        this.dirty = false;
        (this.$refs.group as any)?.getNode?.()?.cache?.();
      });
    }
  }

  dirty = false;

  onSessionClick(e) {
    if (this.mview?.editing) return;
    e.currentSession = this.session;
  }

  delayDragStartTimer: any;

  dragHitNode: TableItem;
  dragUpdateTimer: any;
  onDragStart(e) {
    if (this.delayDragStartTimer) {
      clearTimeout(this.delayDragStartTimer);
      this.delayDragStartTimer = null;
    }
    this.delayDragStartTimer = setTimeout(() => {
      this.delayDragStartTimer = null;
      this.$emit("hideMenu");
      this.$emit("cancelClick");
      this.$emit("beginDrag");
      this.$emit("movingSession");
    }, 200);
  }

  onDragMove(e) {
    if (this.dragUpdateTimer) return;
    this.dragUpdateTimer = setTimeout(this.dragUpdate, 250);
  }

  get parentEditor(): TableEditor {
    let cur = (this.$refs.group as any).getNode() as Node;
    while (cur.nodeType !== "Layer") {
      cur = cur.parent;
    }
    return (cur as any)?.VueComponent?.$parent?.$parent;
  }

  dragUpdate() {
    this.dragUpdateTimer = null;
    const gp = (this.$refs.group as any).getNode() as Group;
    const pos = gp.getAbsolutePosition(gp.getLayer());

    if (!this.parentEditor) {
      return;
    }

    const layer = (this.parentEditor?.$refs?.layer as any)?.getNode?.() as Group;
    var transform = layer.getAbsoluteTransform().copy();
    transform.invert();
    const layerPoint = transform.point(pos);

    const r1 = {
      left: layerPoint.x - this.layout.w / 2,
      right: layerPoint.x + this.layout.w / 2,
      top: layerPoint.y - this.layout.h / 2,
      bottom: layerPoint.y + this.layout.h / 2,
    };
    const x = layerPoint.x;
    const y = layerPoint.y;
    const minNode = _.minBy(
      this.mview.viewItems.filter(r2 => {
        if (r2 === this.item) return false;
        return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
      }),
      it => {
        var dx = Math.max(it.left - x, 0, x - it.right);
        var dy = Math.max(it.top - y, 0, y - it.bottom);
        return Math.sqrt(dx * dx + dy * dy);
      },
    );
    if (minNode !== this.dragHitNode) {
      if (this.dragHitNode) {
        this.dragHitNode.dragTo = false;
        this.dragHitNode = null;
      }
      if (minNode) {
        this.dragHitNode = minNode;
        this.dragHitNode.dragTo = true;
      }
    }
  }

  async onDragEnd(e) {
    const gp = e.target as Group;

    if (this.dragUpdateTimer) {
      clearTimeout(this.dragUpdateTimer);
      this.dragUpdateTimer = null;
    }

    if (this.delayDragStartTimer) {
      clearTimeout(this.delayDragStartTimer);
      this.delayDragStartTimer = null;
    } else {
      this.dragUpdate();
      this.$emit("endDrag");
      await Vue.nextTick();

      const dragTo = this.dragHitNode;

      if (this.dragHitNode) {
        this.dragHitNode.dragTo = false;
        this.dragHitNode = null;
      }

      if (dragTo) {
        const session = this.session.session;
        const { x, y } = gp.getAbsolutePosition(gp.getLayer());
        const toMerge = session.tableRefs.find(it => it.table === dragTo);
        const totalCap = (toMerge?.capacity ?? 0) + this.session.capacity;
        const newSplit: TableSessionRef = {
          session: this.session.session,
          table: dragTo,
          split: dragTo.nextSessionSplit,
        };
        const initAction: SessionMoveAction = {
          actions: [
            {
              from: {
                ref: toMerge ? toMerge : this.session,
                x,
                y,
              },
              to: {
                ref: toMerge ? toMerge : newSplit,
                capacity: totalCap,
              },
            },
          ],
        };
        if (toMerge) {
          initAction.actions.push({
            from: {
              ref: this.session,
            },
          });
        }
        const res = await this.$openDialog(
          import("~/components/table/SessionMoveDialog.vue"),
          {
            session,
            initAction,
            initOperation: toMerge ? "merge" : "move",
            mergeSource: {
              ref: this.session,
              x,
              y,
            },
            initMergeTarget: dragTo,
          },
          {
            contentClass: "editor-dialog",
          },
        );
        if (res === "move" || res === "merge") return;
      }
    }

    if (this.$store.state.performance) {
      gp.setAttrs({
        x: this.layout.x + this.layout.w / 2,
        y: this.layout.y + this.layout.h / 2,
      });
    } else {
      await new Promise<void>(resolve => {
        gp.to({
          x: this.layout.x + this.layout.w / 2,
          y: this.layout.y + this.layout.h / 2,
          duration: 0.2,
          onUpdate: resolve,
        });
      });
    }

    this.$emit("updateMenu");
  }
}
