
import { Shape } from "konva/lib/Shape";
import { Stage } from "konva/lib/Stage";
import Konva from "konva";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import Item from "./item/index.vue";
import { InputBehaviorHandler, TableItem, TableSessionRef, TableView } from "~/plugins/table";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";
import { VModel } from "vue-property-decorator";
import { queueingCard } from "types/types";
import { FindType, getID } from "@feathers-client";

Konva.capturePointerEventsEnabled = true;

function getOffset(element) {
  let offsetLeft = 0;
  let offsetTop = 0;

  while (element) {
    offsetLeft += element.offsetLeft;
    offsetTop += element.offsetTop;

    element = element.offsetParent;
  }

  return { left: offsetLeft, top: offsetTop };
}

@Component
export default class TableEditor extends Vue {
  pageWidth = 1280;
  pageHeight = 960;
  containerWidth = 1280;
  containerHeight = 960;
  canvasWidth = 1280;
  canvasHeight = 960;
  offsetX = 0;
  offsetY = 0;
  zoom = 0;
  selectBubbleMenu = false;
  selectMergeMenu = false;
  selectResizeMenu = false;
  position: any = null;
  orderSystem = false;
  isMergeTable = false;
  movingSession = false;
  isTouchAdding = false;
  touchAddingShape = "";

  salesDate = "";

  @Watch("orderSystem")
  onOrderSystem() {
    if (!this.orderSystem) {
      if (this.curSession) {
        if (this.$tableManager.takeAwaySession === this.curSession) {
          this.$tableManager.takeAwaySession = null;
        }
        this.curSession.$destroy();
        this.curSession = null;
      }
    }
  }

  get orderSystemActive() {
    return this.orderSystem && !!this.orderSystemSession;
  }
  set orderSystemActive(v) {
    if (!v) {
      this.orderSystem = false;
    }
  }
  get orderSystemSession() {
    return this.$tableManager.takeAwaySession || (this.tableView && this.tableView.selectedSession);
  }

  enableTransform = true;

  inited = false;

  @Prop()
  tableView: TableView;

  @Prop(Boolean)
  rootMode: boolean;

  @Prop(Boolean)
  takeAwayOnly: boolean;

  @Prop()
  ticketId: string;

  @VModel()
  assigningTable: boolean;

  @Prop({ default: null })
  assigningTicket: queueingCard;

  get editing() {
    return !this.takeAwayOnly && this.tableView?.editing;
  }

  get canPan() {
    return this.tableView?.editing || this.tableView?.manualPosition;
  }

  @Watch("tableView")
  onTableViewChanged(current?: TableView, old?: TableView) {
    if (this.onboarding || this.takeAwayOnly) return;
    if (old) this.savePosition(old);
    this.resetSelect();
    this.onTableFit();
  }

  savePosition(old: TableView) {
    if (this.onboarding || this.takeAwayOnly) return;
    if (old.manualPosition) {
      old.offsetX = this.offsetX;
      old.offsetY = this.offsetY;
      old.zoom = this.zoom;
      old.savePosition();
    }
  }

  @Ref()
  safeContainer: HTMLElement;

  onTableFit() {
    if (!this.tableView || this.takeAwayOnly) return;
    const safeContainer = this.safeContainer?.getBoundingClientRect?.();
    const containerOffset = getOffset(this.safeContainer);
    this.tableView.fit(
      safeContainer?.width ?? this.canvasWidth,
      (safeContainer?.height ?? this.canvasHeight) - 40,
      containerOffset?.left,
      containerOffset?.top,
    );
    this.offsetX = this.tableView.offsetX;
    this.offsetY = this.tableView.offsetY;
    this.zoom = this.tableView.zoom;
  }

  @Prop({ type: Boolean, default: false })
  initEdit: boolean;

  @Prop(Boolean)
  onboarding: boolean;

  handler: InputBehaviorHandler;

  async openViewSettings() {
    const res = await this.$openDialog(
      import("./ViewSettingDialog.vue"),
      {
        deleteFloor: this.deleteFloor,
        tableView: this.tableView,
      },
      {},
    );
  }

  beforeMount() {
    if (matchMedia("(pointer:coarse)").matches) {
      this.enableTransform = false;
    }
    this.handler = new InputBehaviorHandler();
    this.handler.add<
      MouseEvent | TouchEvent,
      {
        downPos: { x: number; y: number };
        offsetPos: { x: number; y: number };
        dragItem: TableItem;
        moved: boolean;
      }
    >({
      type: "drag",
      begin: e => {
        if (e.target.parent && e.target.parent.className === "Transformer") return false;
        if ((e as any).pointerOnExpand) return false;
        if ((e as any).pointerOnExpandEmpty) return false;
        if (window.TouchEvent && e.evt instanceof TouchEvent && e.evt.touches.length > 1) {
          return false;
        }
        const pointer = (window.TouchEvent && e.evt instanceof TouchEvent ? e.evt.touches[0] : e.evt) as
          | MouseEvent
          | Touch;

        let cur = e.target;
        if (this.isTouchAdding) {
          const pos = this.tableView.snapTo(
            (pointer.clientX - this.offsetX) / this.scale,
            (pointer.clientY - this.offsetY) / this.scale,
          );
          // this.tableView.beginAddItem(this.touchAddingShape);
          this.tableView.addingItem.x = pos.x;
          this.tableView.addingItem.y = pos.y;
          // this.resizeTableTransform(this.tableView.addingItem.shapeOpts.getConf(this.tableView.addingItem));
          this.isTouchAdding = false;
        }
        if (this.tableView.addingItem) {
          cur = this.tableView.addingItem.shapeOpts.getConf(this.tableView.addingItem);
          this.selectedShape = cur;
          //hide resize menu when dragging
          this.selectResizeMenu = false;
        }
        let selectedShape = this.selectedShape;
        while (cur && cur !== selectedShape) {
          cur = cur.parent as any;
        }
        return {
          downPos: { x: pointer.clientX, y: pointer.clientY },
          offsetPos: cur ? { x: this.selectedItem.x, y: this.selectedItem.y } : { x: this.offsetX, y: this.offsetY },
          moved: false,
          dragItem: cur ? this.selectedItem : null,
        };
      },
      step: (e, state) => {
        if (window.TouchEvent && e instanceof TouchEvent && e.touches.length > 1) {
          return false;
        }
        // prevent ios bouncing
        e.stopPropagation();
        e.preventDefault();
        const pointer = (window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : e) as MouseEvent | Touch;

        const deltaX = pointer.clientX - state.downPos.x;
        const deltaY = pointer.clientY - state.downPos.y;
        if (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5) {
          if (this.canPan) {
            state.moved = true;
          }
        }
        if (this.selectBubbleMenu) {
          this.closeMenu();
        }
        if (state.dragItem) {
          const pos = this.tableView.snapTo(
            deltaX / this.scale + state.offsetPos.x,
            deltaY / this.scale + state.offsetPos.y,
          );
          state.dragItem.x = pos.x;
          state.dragItem.y = pos.y;
        } else if (state.moved) {
          if (this.canPan) {
            this.offsetX = deltaX + state.offsetPos.x;
            this.offsetY = deltaY + state.offsetPos.y;
          }
        }
        return true;
      },
      cancel: (e, state) => {
        if (this.editing) {
          if (this.tableView.addingItem) {
            //show resize menu when end drag
            this.selectResizeMenu = true;
            state.moved = false;
          }
          if (!state.moved) this.updateMenu();
        } else {
          if (state.moved) this.updateMenu();
          else if (!this.selectMergeMenu && !this.selectResizeMenu) {
            this.clickEmpty();
          }
          // else if (!(e as any).pointerOnExpand) {
          //   this.clickEmpty();
          // }
        }
        return !state.moved;
      },
    });

    function getCenter(p1: Touch, p2: Touch) {
      return {
        x: (p1.clientX + p2.clientX) / 2,
        y: (p1.clientY + p2.clientY) / 2,
      };
    }

    function getDistance(p1: Touch, p2: Touch) {
      return Math.sqrt(Math.pow(p2.clientX - p1.clientX, 2) + Math.pow(p2.clientY - p1.clientY, 2));
    }

    this.handler.add<
      TouchEvent,
      {
        lastPos: { x: number; y: number };
        clientCenter: { x: number; y: number };
        lastCenter: { x: number; y: number };
        lastDist: number;
        lastScale: number;
      }
    >({
      type: "touchstart",
      begin: e => {
        if (e.evt.touches.length !== 2) return false;
        const lastCenter = getCenter(e.evt.touches[0], e.evt.touches[1]);
        const rect = (e.evt.target as HTMLElement).getBoundingClientRect();

        return {
          lastPos: { x: this.offsetX, y: this.offsetY },
          clientCenter: { x: lastCenter.x - rect.x, y: lastCenter.y - rect.y },
          lastCenter: lastCenter,
          lastDist: getDistance(e.evt.touches[0], e.evt.touches[1]),
          lastScale: this.scale,
        };
      },
      step: (e, state) => {
        if (e.touches.length !== 2) return false;
        const newCenter = getCenter(e.touches[0], e.touches[1]);
        const newDist = getDistance(e.touches[0], e.touches[1]);

        if (this.canPan) {
          this.zoom = this.scaleToZoom(state.lastScale * Math.pow(newDist / state.lastDist, 1));
          const s = this.scale / state.lastScale;
          var dx = newCenter.x - state.lastCenter.x;
          var dy = newCenter.y - state.lastCenter.y;

          this.offsetX = state.lastPos.x + (-state.lastPos.x + state.clientCenter.x) * (1 - s) + dx;
          this.offsetY = state.lastPos.y + (-state.lastPos.y + state.clientCenter.y) * (1 - s) + dy;
        }
        return true;
      },
      cancel: (e, state) => {
        return true;
      },
    });

    this.handler.add<WheelEvent>({
      type: "wheel",
      begin: e => {
        if (this.canPan) {
          const scale = this.scale;
          this.zoom += Math.sign(e.evt.deltaY);
          const s = this.scale / scale;
          this.offsetX += (-this.offsetX + e.evt.offsetX) * (1 - s);
          this.offsetY += (-this.offsetY + e.evt.offsetY) * (1 - s);
        }
      },
    });

    this.handler.add({
      type: "mousemove",
      begin: e => {
        if (this.tableView.addingItem) {
          this.selectResizeMenu = false;
          const pos = this.tableView.snapTo(
            (e.evt.offsetX - this.offsetX) / this.scale,
            (e.evt.offsetY - this.offsetY) / this.scale,
          );
          this.tableView.addingItem.x = pos.x;
          this.tableView.addingItem.y = pos.y;
          return true;
        }
      },
    });

    this.handler.add({
      type: "click",
      begin: e => {},
    });

    // prevent ios bouncing
    this.handler.add({
      type: "drag",
      begin: e => {
        return {};
      },
      step: e => {
        e.stopPropagation();
        e.preventDefault();
        return true;
      },
      cancel: (e, state) => {
        return true;
      },
    });
  }

  get dataInited() {
    return (this.tableView || this.takeAwayOnly) && this.$shop.shopData;
  }

  @Ref()
  container: HTMLElement;

  mounted() {
    this.initContainer();
  }

  @Watch("dataInited")
  onDataInited() {
    Vue.nextTick(this.initContainer);
  }

  initContainer() {
    const e = this.container;
    if (!e || this.inited) return;
    this.canvasWidth = e.clientWidth;
    this.canvasHeight = e.clientHeight;
    this.inited = true;
    this.onTableViewChanged();
    if (this.initEdit) {
      this.tableView.beginEdit();
    }
  }

  beforeDestroy() {
    this.savePosition(this.tableView);
  }

  get configKonva() {
    return {
      width: this.canvasWidth,
      height: this.canvasHeight,
    };
  }

  get scale() {
    return Math.pow(2, this.zoom / 4);
  }

  scaleToZoom(v: number) {
    return Math.log2(v) * 4;
  }

  get layerConfig() {
    return {
      x: this.offsetX,
      y: this.offsetY,
      scaleX: this.scale,
      scaleY: this.scale,
      listening: !(this.editing && this.tableView.addingItem),
    };
  }

  get orderPanels() {
    return this.$shop.shopData.openBottomOrderPanel
      ? (this.$shop.shopData.openDineInNoTable ? 1 : 0) + (this.$shop.shopData.openTakeAway ? 1 : 0)
      : 0;
  }

  get dineInNoTableConfig() {
    const paddingTop = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sat") ?? "0");
    const panelWidth = this.canvasWidth / this.orderPanels;
    const panelHeight = this.takeAwayOnly ? this.canvasHeight - paddingTop : 78 * this.orderPanels;

    return this.takeAwayOnly
      ? {
          x: 0,
          y: paddingTop,
          height: panelHeight,
          width: panelWidth,
        }
      : {
          x: 0,
          y: this.canvasHeight - panelHeight,
          height: panelHeight,
          width: panelWidth,
        };
  }

  get takeAwayConfig() {
    const paddingTop = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--sat") ?? "0");
    const panelWidth = this.canvasWidth / this.orderPanels;
    const panelHeight = this.takeAwayOnly ? this.canvasHeight - paddingTop : 78 * this.orderPanels;

    return this.takeAwayOnly
      ? {
          x: panelWidth * (this.orderPanels - 1),
          y: paddingTop,
          height: panelHeight,
          width: panelWidth,
        }
      : {
          x: panelWidth * (this.orderPanels - 1),
          y: this.canvasHeight - panelHeight,
          height: panelHeight,
          width: panelWidth,
        };
  }

  get gridShape() {
    return {
      x: this.offsetX,
      y: this.offsetY,
      width: this.canvasWidth,
      height: this.canvasHeight,
      stroke: "black",
      strokeWidth: 3,
      sceneFunc: (context, shape) => {
        context.beginPath();
        let gridWidth = 50;
        while (gridWidth * this.scale < 25) {
          gridWidth *= 2;
        }
        gridWidth *= this.scale;
        const startX = Math.floor(-this.offsetX / gridWidth) * gridWidth;
        const endX = startX + this.canvasWidth;
        const startY = Math.floor(-this.offsetY / gridWidth) * gridWidth;
        const endY = startY + this.canvasHeight + gridWidth;

        for (let x = startX; x <= endX; x += gridWidth) {
          context.moveTo(x, startY);
          context.lineTo(x, endY);
        }
        context.setLineDash([0, gridWidth]);
        context.lineCap = "round";
        context.fillStrokeShape(shape);
      },
    };
  }

  resize(e) {
    this.canvasWidth = e.width;
    this.canvasHeight = e.height;
    if (!this.onboarding && !this.tableView?.manualPosition && !this.tableView?.editing) {
      this.onTableFit();
    }
  }

  selectedShape: any = null;

  get selectedItem() {
    return this.tableView?.selectedItems[0];
  }
  beginTransform(shape?: Shape, item?: TableItem) {
    if (item === this.selectedItem) {
      if (this.selectBubbleMenu) {
        this.selectBubbleMenu = false;
      } else {
        this.updateMenu();
      }
      return;
    }
    if (this.selectedItem && !this.editing) {
      this.selectedItem.deselect();
    }
    if (item) {
      item.select();
    }
    this.selectedShape = shape;
    const transformerNode = (this.$refs.transformer as any)?.getNode?.();
    if (transformerNode) {
      if (shape) {
        // attach to another node
        transformerNode.nodes([shape]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      transformerNode.getLayer().batchDraw();
    }
    if (this.selectBubbleMenu) {
      this.updateMenu();
    }
  }

  mergeTableTransform(shape?: Shape, item?: TableItem, isMergeTable?: boolean, selectedSession?: TableSession) {
    if (item) {
      item.select();
      if (this.tableView && !this.assigningTable) {
        this.tableView.enableMultiSelect();
      }
    }
    this.selectedShape = shape;
    this.selectMergeMenu = true;
    this.selectBubbleMenu = false;
    this.selectResizeMenu = false;
    this.isMergeTable = isMergeTable;
    if (selectedSession) {
      this.tableView.selectedSession = selectedSession;
      this.tableView.selectedItems = selectedSession.tableRefs.map(table => table.table);
    }
  }

  resizeTableTransform(shape?: Shape, showResizeMenu: boolean = true) {
    this.selectedShape = shape;
    this.selectResizeMenu = showResizeMenu;
    this.selectMergeMenu = false;
    this.selectBubbleMenu = false;
  }

  updateMergeTable() {
    this.isMergeTable = true;
  }

  resizeStep(pos: { x?: number; y?: number }) {
    if (this.selectedItem) {
      this.selectedItem.w = Math.max(75, this.selectedItem.w + (pos.x || 0) * 25);
      this.selectedItem.h = Math.max(75, this.selectedItem.h + (pos.y || 0) * 25);
    }
  }

  @Watch("editing")
  onEditing() {
    this.closeMenu();
    const transformerNode = (this.$refs.transformer as any)?.getNode?.();
    if (transformerNode) {
      transformerNode.nodes([]);
    }
  }

  resetTransform() {
    const transformerNode = (this.$refs.transformer as any)?.getNode?.();
    transformerNode.update();
  }

  get transformConfig() {
    return {
      rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
      rotationSnapTolerance: 22.5,
      keepRatio: false,
      boundBoxFunc: (oldBoundBox, newBoundBox) => {
        const x = (newBoundBox.x - this.offsetX) / this.scale;
        const y = (newBoundBox.y - this.offsetY) / this.scale;
        const rx = (newBoundBox.x - this.offsetX + newBoundBox.width) / this.scale;
        const ry = (newBoundBox.y - this.offsetY + newBoundBox.height) / this.scale;

        const xy = this.tableView.snapTo(x, y);
        const rxy = this.tableView.snapTo(rx, ry);

        if (rx - x < 25 || ry - y < 25) {
          return oldBoundBox;
        }

        return {
          x: xy.x * this.scale + this.offsetX,
          y: xy.y * this.scale + this.offsetY,
          width: (rxy.x - xy.x) * this.scale,
          height: (rxy.y - xy.y) * this.scale,
          rotation: newBoundBox.rotation,
        };
      },
    };
  }

  clickEmpty() {
    this.closeMenu();
    this.$tableManager.clearSelect();
    this.tableView.clearSelect();
    this.tableView.clearSessionSelect();
  }

  closeMenu() {
    this.selectBubbleMenu = false;
    this.selectMergeMenu = false;
    this.selectResizeMenu = false;
  }

  openOrder(e) {
    if (e && e instanceof TableSession && e !== this.orderSystemSession) {
      // don't know why selected session is not current session
      if (e.isNoTable) {
        if (this.$tableManager.takeAwaySession) {
          this.$tableManager.takeAwaySession.selected = false;
          this.$tableManager.takeAwaySession = null;
        }
        this.$tableManager.takeAwaySession = e;
      } else {
        if (this.$tableManager.takeAwaySession) {
          this.$tableManager.takeAwaySession.selected = false;
          this.$tableManager.takeAwaySession = null;
        }
        if (this.tableView && this.tableView.selectedSession !== e) {
          if (this.tableView.selectedSession) {
            this.tableView.selectedSession.selected = false;
            this.tableView.selectedSession = null;
          }
          this.tableView.selectedSession = e;
        }
      }
      if (e !== this.orderSystemSession) {
        for (let tw of this.$tableManager.takeAways) {
          tw.selected = false;
          tw.mselected = false;
        }
        for (let tw of this.$tableManager.dineInNoTables) {
          tw.selected = false;
          tw.mselected = false;
        }
        for (let tw of this.$tableManager.sessions) {
          tw.selected = false;
          tw.mselected = false;
        }
        for (let tv of this.$tableManager.views) {
          tv.selectedSession = null;
          tv.selectedItems = [];
        }
        this.tableView.selectedSession = null;
      }
    }
    this.orderSystem = true;
    this.closeMenu();
    this.$emit("updateMenu", e?.currentTarget);
  }

  updateMenu(shape?: Shape) {
    this.selectMergeMenu = false;
    if (!this.editing) this.selectResizeMenu = false;
    this.tableView?.disableMultiSelect();
    const lastSelected = this.tableView?.selectedItems?.[this.tableView?.selectedItems.length - 1];
    if (!lastSelected) {
      this.clickEmpty();
      return;
    }
    if (!shape) {
      if (this.editing) {
        return;
      }
      const stage = this.$refs.stage as any;
      shape = stage && (stage.getNode() as Stage).findOne("#" + lastSelected.id);
      if (!shape) {
        if (stage) {
          Vue.nextTick(() => {
            if ((stage.getNode() as Stage).findOne("#" + lastSelected.id)) {
              this.updateMenu();
            }
          });
        }
        return;
      }
    }
    this.selectedShape = shape;
    const mainComponent = ((shape as any).VueComponent as Vue).$parent as Item;
    const { x, y } = shape.getAbsolutePosition();
    const { x: sx, y: sy } = shape.getAbsoluteScale();
    const pos = mainComponent.item.expanded
      ? {
          x: x - (sx * 410) / 2,
          y: y - (sy * mainComponent.item.h) / 2,
          width: sx * 410,
          height: sx * mainComponent.item.h,
        }
      : {
          x: x - (sx * mainComponent.item.w) / 2,
          y: y - (sy * mainComponent.item.h) / 2,
          width: sx * mainComponent.item.w,
          height: sx * mainComponent.item.h,
          canvasWidth: this.canvasWidth,
          canvasHeight: this.canvasHeight - 78 * this.orderPanels,
        };
    if (pos.x + pos.width < 0 || pos.x > this.canvasWidth || pos.y + pos.height < 0 || pos.y > this.canvasHeight) {
      this.closeMenu();
      return;
    }
    this.position = pos;
    if (!this.selectBubbleMenu && !this.editing && lastSelected.sessions[0] && !this.movingSession) {
      Vue.nextTick(() => {
        this.selectBubbleMenu = true;
      });
    } else if (this.movingSession) {
      this.movingSession = false;
    }
  }

  async deleteFloor() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToDelete"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (c) {
      await this.tableView.deleteFloor();
      this.$emit("resetSelection");
    }
  }

  resetSelect() {
    const transformerNode = (this.$refs.transformer as any)?.getNode?.();
    if (transformerNode) {
      // remove transformer
      transformerNode.nodes([]);
      transformerNode.getLayer().batchDraw();
    }
    this.updateMenu();
  }

  async beginEdit() {
    const staff = await this.$shop.checkPermission(["tableManage/editFloor"]);
    if (staff === false) return;
    this.savePosition(this.tableView);
    this.tableView.beginEdit();

    await this.$feathers.service("actionLogs").create({
      staff: this.$shop.staffId || staff?._id,
      view: getID(this.tableView),
      type: `tableManage/editFloor`,
    });
  }

  endEdit() {
    if (this.tableView.addingItem) {
      this.tableView.commitAdd();
    }
    this.savePosition(this.tableView);
    this.onTableFit();
    this.tableView.endEdit();
  }

  togglePanZoom() {
    this.tableView.togglePanZoom();
    this.onTableFit();
    this.tableView.savePosition();
  }

  tempHideMenu() {
    this.selectBubbleMenu = false;
  }

  async openTableList() {
    await this.$openDialog(
      import("./TableList.vue"),
      {
        tableView: this.tableView,
        openOrder: this.openOrder,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  curSession: TableSession = null;
  async createSession(type = "takeAway") {
    const staff = await this.$shop.checkPermission(["tableManage/tableSessionCreateOrder"]);
    if (staff === false) return;
    if (this.curSession) {
      this.curSession.$destroy();
      this.curSession = null;
    }
    const session = new TableSession({
      parent: this,
    });
    this.curSession = session;
    session.init({
      startTime: new Date(),
      // TODO: add dineInNoTable and delivery
      type: type as any,
      tables: [],
    });

    session.item.createStaff = staff?._id || this.$shop.staffId;

    if (this.$shop.shopData.autoSelectionTimeSection) {
      if (session.availableSections.length) {
        session.item.section = session.availableSections[0]._id;
      } else {
        console.warn("No available sections");
      }
    }

    session.pauseSave();
    if (this.$tableManager.takeAwaySession) {
      this.$tableManager.takeAwaySession.selected = false;
      this.$tableManager.takeAwaySession = null;
    }
    this.$tableManager.takeAwaySession = session;
    this.orderSystem = true;
    this.closeMenu();
  }

  async openCreateSession() {
    if (this.$shop.shopData.partySizeBeforeOpen) {
      const res = await this.$openDialog(
        import("~/components/table/item/sessionCreate.vue"),
        {
          tableView: this.tableView,
          items: this.tableView.selectedItems,
        },
        {
          contentClass: "editor-dialog",
        },
      );
      if (res) {
        this.createSessionFromTable(res.tables, res.sessionSize, res.section);
      }
    } else {
      this.createSessionFromTable(this.tableView.selectedItems, 1);
    }
  }

  async createSessionFromTable(items: TableItem[], size: number, section?: FindType<"sections">) {
    const staff = await this.$shop.checkPermission(["tableManage/tableSessionCreateOrder"]);
    if (staff === false) return;

    let remainCap = items.map(item => item.capacity).reduce((total, value) => total + value, 0);
    let remainSize = size;
    const testing = !!this.tableView.selectedItems.find(it => it?.view?.testing);
    let tables = items.map((item, i) => {
      if (item.capacity < remainSize) {
        if (items.length == 1 || i == items.length - 1) {
          return {
            item: item.id as any,
            capacity: remainSize,
            split: item.nextSessionSplit,
          };
        }
        remainSize -= item.capacity;
        return {
          item: item.id as any,
          capacity: item.capacity,
          split: item.nextSessionSplit,
        };
      } else {
        const cap = remainSize;
        remainSize = 0;
        return {
          item: item.id as any,
          capacity: cap,
          split: item.nextSessionSplit,
        };
      }
    });
    const startTime = new Date();
    const sessionTimeLimit = items[0]?.view?.sessionTimeLimit ?? 0;
    const placeOrderTimeLimit = items[0]?.view?.placeOrderTimeLimit ?? sessionTimeLimit ?? 0;

    const orderCutoffTime = placeOrderTimeLimit ? moment(startTime).add(placeOrderTimeLimit, "minutes").toDate() : null;
    const orderLeaveTime = sessionTimeLimit ? moment(startTime).add(sessionTimeLimit, "minutes").toDate() : null;

    const session = this.tableView.addSession({
      startTime,
      tables,
      type: "dineIn",
      testing,
      orderCutoffTime,
      orderLeaveTime,
      ...(section ? { section: section._id } : {}),
    });

    session.item.createStaff = staff?._id || this.$shop.staffId;

    await Vue.nextTick();
    session.sessionData.tableRefName = session.name;
    if (!session.section) {
      if (this.$shop.shopData.autoSelectionTimeSection) {
        if (session.availableSections.length) {
          session.delaySave({ section: session.availableSections[0]._id });
        } else {
          console.warn("No available sections");
        }
      }
    } else {
      session.delaySave({ section: session.section });
    }

    session.updateCachedDetails();
    session.selected = true;
    this.tableView.disableMultiSelect();
    if (!this.assigningTable) {
      this.openOrder(session);
    }

    await session.save();
    await session.handleSessionCreate();
  }

  touchAdding(name: string) {
    this.isTouchAdding = true;
    this.touchAddingShape = name;
  }

  saveResize() {
    if (this.tableView.addingItem) {
      this.tableView.commitAdd();
    }
    this.selectResizeMenu = false;
  }

  //for queueing ticket
  async assignTableTicket() {
    await this.createSessionFromTable(
      this.tableView.selectedItems,
      this.assigningTicket ? this.assigningTicket.numberOfPeople : 1,
    );
    this.$emit("assignTableTicket", this.tableView.selectedSession, this.tableView.selectedItems[0].name);
    this.selectedItem.deselect();
  }
}
