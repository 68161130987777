
import { Component, Prop, Vue, Watch, mixins, Ref, FindType, FindPopRawType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";
import Dialog from "domore-table/mixins/Dialog";

const populate = [
  {
    path: "tables.item",
    populate: [
      {
        path: "view",
      },
    ],
  },
] as const;

@Component
export default class ReservationPickDialog extends mixins(Dialog()) {
  @Prop()
  item: FindType<"tableSessions">;

  session: TableSession = null;

  async beforeMount() {
    const session = new TableSession({
      parent: this,
    });
    this.session = session;
    await session.init(this.item, true);
  }

  async confirm() {
    const tables = this.session.tables.map(it => ({
      item: it.item,
      capacity: it.capacity,
      split: this.$tableManager.viewItems[`${it.item}`]?.nextSessionSplit ?? -1,
    }));

    await this.$feathers.service("tableSessions/checkIn").create({
      session: this.session.id,
      tables,
    });
    this.modalResult(this.session);
  }
}
