
import { Component, Prop, mixins, Vue } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";

@Component({
  components: {
    EditorCheckbox,
  },
})
export default class SimplePaymentIntegrationOptions extends mixins(Dialog) {

  @Prop()
  paymentMethodArgs: any;

  get moto() {
    return this.paymentMethodArgs?.moto ?? false;
  }
  set moto(value: boolean) {
    if(!this.paymentMethodArgs) return;
    Vue.set(this.paymentMethodArgs, "moto", value);
  }

}

