
import { Component, Vue, VModel, Prop } from "@feathers-client";

@Component
export default class EditableLabel extends Vue {
  @VModel()
  inputValue: number;

  @Prop(Boolean)
  editing: boolean;

  @Prop(Number)
  reference: number;

  @Prop(Boolean)
  negative: boolean;

  @Prop({ type: Number, default: 0 })
  minValue: number;

  get isNeg() {
    const neg = this.inputValue < 0;
    return this.negative ? !neg : neg;
  }

  get abs() {
    return Math.abs(this.inputValue);
  }
}
