import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand } from "../index";
import type { PrintJob, PrintQueue } from "../printQueue";
import { sign, init, jwk } from "../utils/cloudAuth";
import { FeieyunPrintSequence } from "../printSequence/feieyun";
import { XPrintSequence } from "../printSequence/xprinter/escpos";
import { wrapVue } from "pos-printer";

export interface ServerConf extends PrinterConf {
  port: "server";
  deviceId?: string;
}

export class ServerPrinter extends PrinterBase<ServerConf, ServerConf> {
  constructor(server: PrinterServer, conf: ServerConf) {
    super(server, conf);
  }

  createSequence<T>(context?: Vue): T {
    if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return new XPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
      } else {
        return new FeieyunPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
      }
    }
    return super.createSequence<T>(context);
  }

  get printerOpts() {
    if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return super.printerOpts;
      }
    } else if (this.conf.type === "label") {
      return super.printerOpts;
    }
    return [];
  }

  onDisconnectedCore() {}

  async initCore() {}

  async tryConnectCore() {
    await init();
    const item = await (this.parent.context as any).$feathers?.service?.("cloudPrinters")?.get?.(this.conf.deviceId);
    if (!item) {
      throw new Error("Not supported");
    } else {
      this.conf.opts = item.opts;
    }
  }

  async printCore(job: PrintJob) {
    try {
      const postResp = await (this.parent.context as any).$feathers.service("cloudPrinters/print").create({
        deviceId: this.conf.deviceId,
        content:
          job.opts?.type === "escpos" || job.type === "label" ? job.data.toString("base64") : job.data.toString(),
        type: job.opts?.type || "feieyun",
        printerType: job.type,
        cashBox: job.opts?.cashBox ?? false,
        expires: job.expires,
        jobId: job.id,
        jobName: job.name,
      });
      job.remoteId = postResp.jobId;
      job.remoteOpts = postResp.jobOpts;
    } catch (e) {
      if(e.data?.error?.data?.error?.className === "not-found") {
        job.retryable = false;
        throw e;
      }
      throw e;
    }
  }

  async clear(): Promise<void> {
    await (this.parent.context as any).$feathers.service("cloudPrinters/purge").create({
      deviceId: this.conf.deviceId,
    });
  }

  async disconnectCore() {}

  async requestNewDeviceCore() {
    return this.conf;
  }

  startPollingUpdate(job: PrintJob, queue: PrintQueue): void {
    if (!job.remoteId || !job.remoteOpts) {
      return;
    }
    (async () => {
      let errCount = 0;
      const startTime = Date.now();
      for (let i = 0; i < 60; i++) {
        if (job.remoteOpts?.jobTimeout && Date.now() - startTime > job.remoteOpts.jobTimeout) {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
        try {
          const postResp = await (this.parent.context as any).$feathers
            .service("cloudPrinters/print")
            .get(job.remoteId, {
              query: {
                deviceId: this.conf.deviceId,
              },
            });

          job.status = postResp.status;
          if (postResp.lastError) {
            job.lastError = postResp.lastError;
          }
          queue.updateJobStatus(job);

          if (postResp.status === "error" || postResp.status === "done") {
            return;
          }
        } catch (e) {
          console.warn(e);
          if (++errCount >= 3) {
            job.status = "error";
            job.lastError = e.message;
            queue.updateJobStatus(job);
            return;
          }
        }
      }

      job.status = "error";
      job.lastError = "Timeout waiting for print job";
      queue.updateJobStatus(job);
    })().catch(console.warn);
  }
}
