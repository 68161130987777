import DB from "@db";
import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import errors from "@feathersjs/errors";
import { MService, MHookContext } from "@feathersjs/feathers";
import { FindType, checkID, getID } from "@feathers-client";
import { OrderKitchenView } from "@common/table/invoiceSequencer";
import { getSplitName } from "@common/table/util";
import { handlePrintJob, JobEntry, getProductItems } from "./order";

export async function getMapById<T>(service: MService<T>, ids: string[], optional = false) {
  ids = _.uniq(ids).filter(it => !!it);
  if (!ids.length) {
    return {};
  }
  const items = await service.find({
    query: {
      _id: { $in: ids },
      $paginate: false,
    } as any,
    paginate: false,
  });

  const dict: {
    [key: string]: T;
  } = {};
  for (let item of items) {
    dict[getID(item as any)] = item as any;
  }

  if (!optional) {
    for (let id of ids) {
      if (!dict[id]) {
        throw new errors.NotFound(`${id} not found in ${(service as any).id}`);
      }
    }
  }

  return dict;
}

export interface SessionTableRef {
  session: string;
  table: string;
  splitId: number; // just for reference
  targetSplit?: number;
}

type ProductType = (typeof DB.TableSession._mongoType)["products"][number];
type TableRefType = (typeof DB.TableSession._mongoType)["tables"][number];

function checkRef(a: SessionTableRef, b: SessionTableRef) {
  return a.session === b.session && a.table === b.table && a.splitId === b.splitId;
}

function checkProductRef(ref: SessionTableRef) {
  return (product: ProductType) => {
    return checkID(product.table, ref.table) && product.tableSplit === ref.splitId;
  };
}

function checkTableRef(ref: SessionTableRef) {
  return (table: TableRefType) => {
    return checkID(table.item, ref.table) && table.split === ref.splitId;
  };
}

export interface SessionTableChairRef extends SessionTableRef {
  chairId?: string;
}

export interface SessionMoveActionServer {
  actions: {
    from?: {
      ref: SessionTableRef;
    };
    to?: {
      ref: SessionTableRef;
      capacity: number;
    };
  }[];
  orders?: {
    id: string;
    ref: SessionTableChairRef;
  }[];
  newSessions?: Partial<typeof DB.TableSession._mongoType>[];
  noPrint?: boolean;
  staff?: string;
}

export async function getSplit(
  hook: MHookContext<AdminApplication, SessionMoveActionServer, (typeof DB.TableSession._mongoType)[]>,
  table: FindType<"tableViewItems">,
) {
  const sessions = await hook.app.service("tableSessions").find({
    query: {
      endTime: null,
      tables: {
        $elemMatch: {
          item: getID(table),
        },
      },
      shop: table.shop,
      $paginate: false,
    },
    paginate: false,
  });
  const splitMap = _.map(sessions, session => session.tables.find(it => checkID(it.item, table))?.split ?? 0).sort();
  let nextSplit = 0;
  for (let curSplit of splitMap) {
    if (curSplit !== nextSplit) {
      break;
    }
    nextSplit = curSplit + 1;
  }
  return nextSplit;
}

function getRefName(refs: TableRefType[], tables: Record<string, FindType<"tableViewItems">>) {
  return refs.map(ref => (tables[getID(ref.item)]?.name ?? "") + getSplitName(ref.split)).join("/");
}

export async function create(
  hook: MHookContext<AdminApplication, SessionMoveActionServer, (typeof DB.TableSession._mongoType)[]>,
) {
  const allSessionIds = _.uniq(
    [...hook.data.actions.map(it => it.from?.ref?.session), ...hook.data.actions.map(it => it.to?.ref?.session)].filter(
      it => !!it,
    ),
  );
  const newSessions = _.fromPairs((hook.data.newSessions || []).map(it => [(it as any).tempId, it]));

  const realSessionIds = allSessionIds.filter(it => !newSessions[it]);
  const sessions = await getMapById(hook.app.service("tableSessions"), realSessionIds);

  for (let session of hook.data.newSessions || []) {
    session.tables = [];
    sessions[(session as any).tempId] = session as any;
  }

  const tables = await getMapById(
    hook.app.service("tableViewItems"),
    _.uniq(
      [...hook.data.actions.map(it => it.from?.ref?.table), ...hook.data.actions.map(it => it.to?.ref?.table)].filter(
        it => !!it,
      ),
    ),
  );

  const productsToMove = _.flatMap(hook.data.actions, it => {
    if (!it.from) return [];
    const session = sessions[it.from.ref.session];
    if (session) {
      const products = session.products.filter(checkProductRef(it.from.ref));

      return products.map(it => ({
        product: it,
        session: session,
        sameSession: false,
        ref: {
          table: it.table,
          split: it.tableSplit,
          chairId: it.tableChairId,
        },
        seq: it.seq,
        prodSeq: it.prodSeq,
      }));
    } else {
      return null;
    }
  });

  const productDict = _.fromPairs(_.map(productsToMove, p => [p.product.id, p]));

  const toProductDict = _.fromPairs(
    _.map(hook.data.orders, p => [
      p.id,
      {
        change: p,
        matched: false,
        sameSession: false,
        skip: false,
      },
    ]),
  );

  // find tables to add / remove / patch

  const refToRemove = _.groupBy(
    hook.data.actions.filter(it => !!it.from),
    it => getID(it.from.ref.session),
  );
  const refToAdd = _.groupBy(
    hook.data.actions.filter(it => !!it.to),
    it => getID(it.to.ref.session),
  );

  const refToList: {
    [key: string]: SessionTableRef[];
  } = {};

  // apply table changes
  for (let [sid, session] of Object.entries(sessions)) {
    let removeList = refToRemove[sid] || [];
    let addList = refToAdd[sid] || [];
    let keepList = session.tables.map(
      it =>
        ({
          table: getID(it.item),
          splitId: it.split,
          targetSplit: it.split,
          session: getID(session),
        }) as SessionTableRef,
    );

    const patchList = removeList.filter(a => !!addList.find(b => checkRef(a.from.ref, b.to.ref)));

    if (patchList.length) {
      removeList = removeList.filter(a => !patchList.includes(a));
      addList = addList.filter(a => !patchList.includes(a));
    }

    if (removeList.length) {
      const checks = removeList.map(it => checkTableRef(it.from.ref));
      session.tables = session.tables.filter(it => !_.some(checks, check => check(it)));

      keepList = keepList.filter(a => !removeList.find(b => checkRef(b.from.ref, a)));
    }

    if (addList.length) {
      keepList = keepList.filter(a => !!addList.find(b => checkRef(b.to.ref, a)));
      for (let item of addList) {
        const t = tables[getID(item.to.ref.table)];
        const split = await getSplit(hook, t);
        item.to.ref.targetSplit = split;
        keepList.push(item.to.ref);
        session.tables.push({
          item: t._id,
          capacity: item.to.capacity,
          split,
        });
        session.view = t.view;
        keepList.push(item.to.ref);
      }
    }

    if (patchList.length) {
      for (let item of patchList) {
        const ref = session.tables.find(checkTableRef(item.to.ref));
        if (ref) {
          ref.capacity = item.to.capacity;
        }
      }
    }

    refToList[sid] = keepList;
  }

  // find products to add / remove / patch
  for (let product of productsToMove) {
    const to = toProductDict[product.product.id];
    if (!to) {
      throw new errors.BadRequest(`Missing move request of product ${product.product.id} @ ${product.session._id}`);
    }
    to.matched = true;
    if (checkID(product.session, to.change.ref.session)) {
      product.sameSession = true;
      to.sameSession = true;
    } else {
      product.sameSession = false;
      to.sameSession = false;
    }

    const targetTo = refToList[to.change.ref.session]?.find?.(it => checkRef(it, to.change.ref));

    if (targetTo) {
      if (checkProductRef(targetTo)(product.product)) {
        // no change
        to.skip = true;
      } else {
        to.change.ref = targetTo;
      }
    } else {
      throw new errors.BadRequest(`Invalid move request of product ${to.change.id}`);
    }
  }
  for (let product of Object.values(toProductDict)) {
    if (!product.matched) {
      throw new errors.BadRequest(`Extra move request of product ${product.change.id}`);
    }
  }

  const sessionProductRemove = _.groupBy(
    productsToMove.filter(it => !it.sameSession),
    it => getID(it.session),
  );
  const sessionProductAdd = _.groupBy(
    Object.values(toProductDict).filter(it => !it.sameSession),
    it => getID(it.change.ref.session),
  );
  const sessionProductPatch = _.groupBy(
    Object.values(toProductDict).filter(it => it.sameSession && !it.skip),
    it => getID(it.change.ref.session),
  );

  hook.result = [];
  for (let [sid, session] of Object.entries(sessions)) {
    if (!session._id) {
      session.tableRefName = getRefName(session.tables, tables);
      session = await hook.app.service("tableSessions").create(session);
    } else {
      session = await hook.app.service("tableSessions").patch(session._id, {
        tables: session.tables,
        tableRefName: getRefName(session.tables, tables),
        view: session.view,
      });
    }

    const needToRemove = sessionProductRemove[sid];
    if (needToRemove?.length) {
      session.products = session.products.filter(it => !needToRemove.find(p => p.product.id === it.id));
      session = await hook.app.service("tableSessions").patch(session._id, {
        products: session.products,
      });
    }

    const needToAdd = sessionProductAdd[sid];
    if (needToAdd?.length) {
      const seq = (_.max(session.products.map(it => it.seq)) ?? 0) + 1;
      const prodSeq = session.prodSeq || 0;
      for (let i = 0; i < needToAdd.length; i++) {
        const it = needToAdd[i];
        const product = productDict[it.change.id].product;
        product.prodSeq = prodSeq + i + 1;
        product.seq = seq;
      }

      session.products.push(
        ...(needToAdd.map(it => ({
          ...productDict[it.change.id].product,
          table: it.change.ref.table,
          tableSplit: it.change.ref.targetSplit,
          tableChairId: it.change.ref.chairId,
        })) as any),
      );

      session = await hook.app.service("tableSessions").patch(session._id, {
        products: session.products,
        prodSeq: session.prodSeq + needToAdd.length,
      });
    }

    const needToPatch = sessionProductPatch[sid];
    if (needToPatch?.length) {
      const products = session.products;

      for (let i = 0; i < needToPatch.length; i++) {
        const it = needToPatch[i];

        const update = {
          table: it.change.ref.table,
          tableSplit: it.change.ref.targetSplit,
          tableChairId: it.change.ref.chairId,
        };
        const product = products.find(p => p.id === it.change.id);
        if (product) {
          Object.assign(product, update);
        }
      }

      session = await hook.app.service("tableSessions").patch(session._id, {
        products,
      });
    }

    sessions[sid] = session;

    if (!session.tables.length && !session.products.length) {
      continue;
    }

    hook.result.push(session);
  }

  // need to cancel empty sessions later
  for (let [sid, session] of Object.entries(sessions)) {
    if (!session.tables.length && !session.products.length) {
      // try to close session
      session = await hook.app.service("tableSessions/cancelPending").create({
        session: session._id,
        update: {
          endTime: new Date(),
          amount: 0,
          mergedTo: hook.result[0]?._id,
        },
      });
      sessions[sid] = session;
      hook.result.push(session);
    }
  }

  const moved = productsToMove
    .map(product => {
      const to = toProductDict[product.product.id];
      if (!to || to.skip) return null;

      const session = sessions[to.change.ref.session];
      const item = session.products.find(it => it.id === product.product.id);

      if (
        item.tableSplit === product.ref.split &&
        checkID(item.table, product.product.table) &&
        (item.tableChairId || "") === (product.ref.chairId || "")
      ) {
        return null;
      }

      return {
        item,
        session,
        to,
        product,
        table: tables[to.change.ref.table],
      };
    })
    .filter(it => !!it);

  if (!hook.result.length) return;

  const [shopCache] = await Promise.all([hook.app?.$shopCache?.getShop(hook.result[0].shop)]);

  const jobListSession: {
    [key: string]: {
      session: typeof DB.TableSession._mongoType;
      list: any[];
      jobFlatten: JobEntry[];
    };
  } = {};

  for (let session of hook.result) {
    jobListSession[getID(session)] = {
      session,
      list: [],
      jobFlatten: [],
    };
  }

  const staff = hook.data.staff && (await hook.app.service("staffs").get(hook.data.staff));

  for (let product of moved) {
    const session = product.session;
    const table = product.table;
    const item = product.item;
    const job: Partial<OrderKitchenView> = {
      session: session,

      sourceSessionName: session.sessionName,
      sourceTableName: tables[getID(product.product.ref.table)].name,
      sourceTableSplit: product.product.ref.split,
      sourceChairId: product.product.ref.chairId,

      tableName: table.name,
      tableSplit: item.tableSplit,
      chairId: item.tableChairId,

      staffName: staff?.name,

      table,

      type: "move",
    };

    jobListSession[getID(session)].jobFlatten.push(
      ...getProductItems(product.item, job, shopCache, {
        sourceIndex: product.product.prodSeq,
      }),
    );
  }

  if (!hook.data.noPrint) {
    for (let item of Object.values(jobListSession)) {
      await handlePrintJob(hook.app, item.session, item.list, item.jobFlatten);
    }
  }
}
