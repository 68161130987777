
import { Vue, Component, Prop } from "@feathers-client";

@Component
export default class CenterMenuItem extends Vue {
  @Prop()
  item: any;

  @Prop()
  active: boolean;

  beforeCreate() {}

  created() {}

  beforeMount() {}

  mounted() {}

  beforeUpdate() {}

  updated() {}

  beforeUnmount() {}

  unmounted() {}
}
