import { getStorage } from "@feathers-client/storage";
import Vue from "vue";

export const BOXS_CLOUD_URL = process.env.BOXS_CLOUD_URL ? process.env.BOXS_CLOUD_URL : "https://cloud.boxs.hk/api/";

export async function boxsCloudGet(url: string) {
  const u = new URL(url, BOXS_CLOUD_URL);
  const resp = await fetch(u.toString());
  if (!resp.ok) {
    if ((resp.headers.get("content-type") || "").includes("application/json")) {
      const j = await resp.json();
      throw new Error(j.message || JSON.stringify(j));
    }
    throw new Error("Invalid status: " + resp.statusText);
  }
  return await resp.json();
}

export async function boxsCloudPost(url: string, body: any) {
  const u = new URL(url, BOXS_CLOUD_URL);
  const resp = await fetch(u.toString(), {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!resp.ok) {
    if ((resp.headers.get("content-type") || "").includes("application/json")) {
      const j = await resp.json();
      throw new Error(j.message || JSON.stringify(j));
    }
    throw new Error("Invalid status: " + resp.statusText);
  }
  return await resp.json();
}

export const appName = process.env.CLOUD_APP_NAME || "";
declare let PRODUCTION_MODE: boolean;

export async function preRemoteLang(context, options?: any) {
  const { app, store } = context;
  const preLocales = JSON.stringify(Object.fromEntries((app.i18n.locales || []).map(it => [it.code, it])));
  let remoteLocales = new Set<string>();
  app.i18n.remoteLocales = remoteLocales;
  app.i18n.loadedRemoteLocales = false;
  Vue.set(app.i18n, "displayLocales", app.i18n.locales);

  let normalizedLocales = options?.normalizedLocales ?? options?.locales;
  const regionalConfig = app?.$config?.regionalConfig || {};

  if (regionalConfig.fallbackLocale) {
    if (options.vueI18n) {
      options.vueI18n.fallbackLocale = regionalConfig.fallbackLocale;
    }
    app.i18n.fallbackLocale = regionalConfig.fallbackLocale;
  }

  if (regionalConfig.defaultLocale) {
    if (!regionalConfig.fallbackLocale && options.vueI18n) {
      options.vueI18n.locale = regionalConfig.defaultLocale;
    }
    if (!regionalConfig.fallbackLocale) {
      app.i18n.fallbackLocale = regionalConfig.defaultLocale;
    }
    options.defaultLocale = regionalConfig.defaultLocale;
    app.i18n.defaultLocale = regionalConfig.defaultLocale;
  }

  function updateLocales(locales: any) {
    const localesMap = Object.fromEntries(
      locales.locales.map(it => [
        it.locale,
        {
          ...it,
          code: it.locale,
          iso: it.code || it.iso || it.locale,
        },
      ]),
    );
    app.i18n.remoteLocales = remoteLocales = new Set(locales.locales.map(it => it.locale));
    let newLocales = Object.assign(JSON.parse(preLocales), localesMap);
    newLocales = Object.fromEntries(Object.entries(newLocales).map(([k, v]) => [k, Object.freeze(v)]));

    const disabledLocales = regionalConfig.disabledLocales ?? [];
    const localesOrder = regionalConfig.localesOrder ?? [];
    const enabledLocales = regionalConfig.locales ?? [];
    const fallbackLocale = regionalConfig.fallbackLocale;

    let entries = Object.entries(newLocales);
    let displayLocales = Object.entries(newLocales);
    if (enabledLocales.length) {
      // use white list
      entries = entries.filter(([k, v]) => enabledLocales.includes(k));
      displayLocales = displayLocales.filter(([k, v]) => enabledLocales.includes(k));
    } else {
      // use black list
      entries = entries.filter(([k, v]) => !disabledLocales.includes(k) || fallbackLocale === k);
      displayLocales = displayLocales.filter(([k, v]) => !disabledLocales.includes(k));
    }

    if (localesOrder.length) {
      displayLocales = displayLocales.sort(([k1, v1], [k2, v2]) => {
        let i1 = localesOrder.indexOf(k1);
        if (i1 === -1) i1 = 1000;
        let i2 = localesOrder.indexOf(k2);
        if (i2 === -1) i2 = 1000;
        return i1 - i2;
      });
    }

    app.i18n.locales = Object.freeze(entries.map(([k, v]) => v));
    app.i18n.displayLocales = Object.freeze(displayLocales.map(([k, v]) => v));
    store.commit("SET_LOCALES", Object.freeze(Object.fromEntries(displayLocales)));
    store.commit("SET_ALL_LOCALES", Object.freeze(Object.fromEntries(entries)));
    if (options?.localeCodes) {
      options.locales = app.i18n.locales;
      options.localeCodes = Object.freeze(entries.map(([k, v]) => k));
      options.normalizedLocales = app.i18n.locales;
    }
  }

  async function loadLocales() {
    try {
      const loadAllLocales = localStorage["loadAllLocales"] === "true";
      const locales = await boxsCloudGet(
        `locales/${appName}${app.i18n.loadDraft ? "-draft" : ""}${!loadAllLocales && regionalConfig?.region ? `.${regionalConfig.region}` : ''}.json${app.i18n.loadDraft ? `?${Math.random()}` : ""}`,
      );
      localStorage["cachedLocales"] = JSON.stringify(locales);

      if (locales?.locales) {
        updateLocales(locales);
        app.i18n.loadedRemoteLocales = true;
      }
    } catch (e) {}
  }

  if (localStorage["cachedLocales"]) {
    try {
      const locales = JSON.parse(localStorage["cachedLocales"]);
      if (locales?.locales) {
        updateLocales(locales);
        app.i18n.loadedRemoteLocales = true;
      }
    } catch (e) {}
    loadLocales().catch(console.error);
  } else {
    await loadLocales();
  }

  const res = {
    preLocales,
    remoteLocales,
    options,
    normalizedLocales,
    updateLocales,
    loadLocales,
  };
  app.preRemoteLang = async () => res;
  return res;
}

export async function initRemoteLang(context) {
  const { app, store } = context;

  let { remoteLocales, normalizedLocales } = await app.preRemoteLang(context);

  app.i18n.boxsCloudGet = boxsCloudGet;
  app.i18n.boxsCloudPost = boxsCloudPost;
  app.i18n.appName = appName;
  app.i18n.loadDraft = !PRODUCTION_MODE;

  app.i18n.initI18N = async (loadAllLocales?: boolean) => {
    app.i18n.inited = true;
    remoteLocalesTask = {};
    console.log("loading locales");

    if (loadAllLocales || !PRODUCTION_MODE) {
      await app.i18n.loadAllLocales?.();
    }
  };

  let remoteLocalesTask: Record<string, Promise<void>> = {};
  let i18nOptions: any;
  let i18nMessages: any;
  app.i18n.loadRemoteLocale = async (locale, _i18nOptions, _i18nMessages) => {
    if (_i18nOptions) {
      i18nOptions = _i18nOptions;
    }
    if (_i18nMessages) {
      i18nMessages = _i18nMessages;
    }
    if (!app.i18n.inited) {
      await app.i18n.initI18N();
    }
    if (!app.i18n.loadedRemoteLocales) {
      return;
    }
    let task = remoteLocalesTask[locale];
    if (!task) {
      task = remoteLocalesTask[locale] = loadRemoteLocaleInner(locale);
    }
    return task;
  };

  const storage = getStorage();

  async function loadRemoteLocaleInner(locale: string) {
    if (!remoteLocales.has(locale)) return;
    try {
      await storage.loadFile(
        `locales/${appName}_${locale}.json`,
        async buf => {
          try {
            let messages = {};
            try {
              const localeObject = normalizedLocales?.find?.(l => l.code === locale);
              if (localeObject?.file) {
                const getter = await i18nMessages[localeObject?.file]().then(m => m.default || m);
                messages = typeof getter === "function" ? await Promise.resolve(getter(context, locale)) : getter;
              }
            } catch (e) {
              console.warn(e);
            }

            const body = JSON.parse(buf.toString());
            deepDefaults(body, messages);
            app.i18n.setLocaleMessage(locale, body);
            if (!app.i18n.loadedLanguages?.includes?.(locale)) {
              app.i18n.loadedLanguages?.push?.(locale);
            }
          } catch (e) {
            console.warn(e);
          }
        },
        u => boxsCloudGet(u + (app.i18n.loadDraft ? `?${Math.random()}` : `?build=${process.env.BUILD_INFO || ""}`)),
      );
    } catch (e) {
      console.warn(e);
    }
  }
}

export default function (context) {
  context.app.preRemoteLang = preRemoteLang.bind(null, context);
  context.app.initRemoteLang = initRemoteLang.bind(null, context);
}

function deepDefaults(obj, defaults) {
  if (!obj) return;
  for (const key in defaults) {
    if (obj[key] === undefined) {
      obj[key] = defaults[key];
    } else if (typeof obj[key] === "object" && typeof defaults[key] === "object") {
      deepDefaults(obj[key], defaults[key]);
    }
  }
}
