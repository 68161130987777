
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindType,
  FindPopRawType,
  FindPopType,
  checkID,
  getID,
} from "@feathers-client";

@Component
export default class OrderSystemMobileCrmMerchantPortal extends Vue {
  @Prop() point: FindType<"shopPoints"> = null;
  @Prop() dollar: FindType<"shopPoints"> = null;
}
