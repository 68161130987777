
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import _ from "lodash";

@Component
export default class TableKDSStock extends Vue {
  get productStocks() {
    return _.sortBy(
      this.$shop.products.filter(it => it.stock?.kdsStock?.length),
      it => it.stock.kdsStock[0].date,
    );
  }
}
