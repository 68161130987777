
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import type DB from "@db";
import type { TableSessionRef } from "~/plugins/table";
import type { CartItem } from "@common/table/cart";

@Component
export default class AddDialog extends mixins(Dialog()) {
  item: Partial<typeof DB.CustomProduct._mongoType> = null;

  @Prop()
  currentItem: any;

  @Prop()
  session: TableSession;

  @Prop()
  cartItem: CartItem;

  @Prop({ default: null })
  defaultCategory: string;

  @Prop({ default: null })
  defaultSubCategory: string;

  save = false;

  quantity = 1;

  compareRef(a: TableSessionRef, b: TableSessionRef) {
    return !!a === !!b && a && b && a.table === b.table && a.split === b.split;
  }

  get targetName() {
    return this.session.target ? this.session.target.session.getSplitName(this.session.target) : "";
  }

  get selectedSection() {
    return this.session?.selectedSection;
  }

  get filteredCats() {
    if (!this.selectedSection) return [];
    const result = this.$shop?.cats ?? [];
    return result;
  }

  get filteredSubCats() {
    if (!this.item.category) return [];
    const result = _.filter(this.$shop && this.$shop.subCats, it => checkID(it.category, this.item.category));
    return result;
  }

  async beforeMount() {
    if (this.currentItem) {
      this.item = _.cloneDeep(this.currentItem);
    } else if (this.cartItem) {
      this.quantity = this.cartItem.quantity;
      this.item = _.cloneDeep(this.cartItem.customProduct);
      this.item.remarks = this.cartItem.customRemark;
    } else {
      this.item = {
        name: "",
        price: 0,
        kitchenPrinters: [],
        waterBars: [],
        category: (this.defaultCategory as any) || null,
        subCategory: (this.defaultSubCategory as any) || null,
        remarks: "",
        discountable: true,
      };
    }
  }

  async saveItem() {
    const staff = await this.$shop.checkPermission([`others/${this.currentItem ? "edit" : "create"}CustomProduct`]);
    if (staff === false) return;
    if (this.cartItem) {
      for (let [k, v] of Object.entries(this.item)) {
        Vue.set(this.cartItem.customProduct, k, v);
      }
      this.cartItem.quantity = this.quantity;
      this.cartItem.customRemark = this.item.remarks;
    } else {
      if (this.save) {
        if (this.currentItem) {
          const customProduct = await this.$feathers.service("customProducts").patch(this.item._id, this.item);

          await this.$feathers.service("actionLogs").create({
            staff: staff?._id || this.$shop.staffId,
            type: `others/editCustomProduct`,
            detail: { customProduct: this.currentItem, newCustomProduct: customProduct },
          });
        } else {
          const customProduct = await this.$feathers.service("customProducts").create(this.item);

          await this.$feathers.service("actionLogs").create({
            staff: staff?._id || this.$shop.staffId,
            type: `others/createCustomProduct`,
            detail: { customProduct },
          });
        }
      }
      await this.session.addToCart(
        {
          quantity: this.quantity,
        },
        {
          customProduct: this.item as any,
        },
      );
    }
    this.modalResult(true);
  }

  async remove() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToDelete"),
        lock: !this.$shop.hasPermission([`others/deleteCustomProduct`]),
      },
      {
        maxWidth: "400px",
      },
    );

    if (c) {
      const staff = await this.$shop.checkPermission([`others/deleteCustomProduct`]);
      if (staff === false) return;
      const customProduct = await this.$feathers.service("customProducts").remove(this.item._id);

      await this.$feathers.service("actionLogs").create({
        staff: staff?._id || this.$shop.staffId,
        type: `others/deleteCustomProduct`,
        detail: { customProduct },
      });

      this.modalResult(false);
    }
  }

  get discountable() {
    return this.item?.discountable ?? true;
  }

  set discountable(v) {
    Vue.set(this.item, "discountable", v);
  }
}
