
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import _ from "lodash";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";

@Component({
  components: {
    EditorObjectPickerNew,
    EditorCheckbox,
  },
})
export default class DisplaySetting extends mixins(Dialog()) {
  async saveItem() {
    this.modalResult(true);
  }
  remoteFontDiv = 5;

  getScale(value) {
    return (value < 0 ? 100 - (Math.abs(value) / this.remoteFontDiv) * 80 : 100 + value * 10).toFixed(0);
  }

  get mscale() {
    return this.$shop.localOptions.fontScale ?? 0;
  }
  set mscale(v) {
    this.$shop.localOptions.fontScale = v;
  }

  get orderTypes() {
    return this.$shop.orderTypes.map(it => ({
      _id: it,
      name: { $t: "pos.invoice." + it },
    }));
  }

  statusTypes = [
    {
      _id: "normal",
      name: { $t: "kds.statusTypes.normal" },
    },
    {
      _id: "warn",
      name: { $t: "kds.statusTypes.warn" },
    },
    {
      _id: "urgent",
      name: { $t: "kds.statusTypes.urgent" },
    },
  ];

  kdsModes = [
    {
      _id: "order",
      name: { $t: "kds.kdsModes.order" },
    },
    {
      _id: "product",
      name: { $t: "kds.kdsModes.product" },
    },
  ];
}
