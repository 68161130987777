
import { ObjectID } from "@db";
import { Component, Prop, Vue, mixins, VModel, Watch, PropSync } from "@feathers-client";
import { FindType } from "@feathers-client/../typings/common";
import Dialog from "domore-table/mixins/Dialog";
import { queueingCard } from "types/types";
import _ from "lodash";

@Component
export default class PosSidebar extends mixins(Dialog()) {
  @VModel() drawer: boolean;
  @Prop({ default: [] }) queueTicketGroups: Array<queueingCard>;
  @Prop({ default: [] }) shopQueueingPastTickets: Array<queueingCard>;
  @Prop({ type: Boolean, default: false })
  loading: boolean;
  @Prop({ type: Boolean, default: false })
  singleGroup: boolean;
  @Prop({ type: Boolean, default: false })
  isClinic: boolean;
  @PropSync("calling") callingSync: boolean;

  searchValue = "";
  @Prop()
  currentGroup: FindType<"shopQueueingGroups">;

  current = "";
  filterSeated = "all";

  get ticketGroups() {
    if (this.singleGroup && this.currentGroup) {
      return this.shopQueueingPastTickets.filter(i => i.queueingGroup === this.currentGroup._id);
    } else {
      return this.shopQueueingPastTickets;
    }
  }

  beforeMount() {
    if (this.singleGroup && this.currentGroup) {
      this.current = this.currentGroup._id;
    } else {
      this.current = "all";
    }
  }

  @Watch("currentGroup")
  onCurrentGroupChange() {
    if (this.singleGroup && this.currentGroup) {
      this.current = this.currentGroup._id;
    }
  }

  onClickOutside() {
    this.drawer = false;
  }

  get placeholder() {
    return this.$t("queueing.inputWaitingListNum");
  }
  callTicket(ticket: queueingCard) {
    // if (this.loading || ticket.numCalling) return;
    this.$emit("callTicket", ticket._id);
  }
  assignTable(pastTable: queueingCard) {
    // if (this.loading || pastTable.numCalling) return;
    this.$emit("assignTable", pastTable);
  }

  get tickets() {
    if (!this.queueTicketGroups && !this.shopQueueingPastTickets) return [];
    const allTicket = [
      ...this.queueTicketGroups.reduce(
        (acc, cur) => (cur && cur.queueingTickets ? [...acc, ...cur.queueingTickets] : acc),
        [],
      ),
      ...this.shopQueueingPastTickets.reduce(
        (acc, cur) => (cur && cur.pastTickets ? [...acc, ...cur.pastTickets] : acc),
        [],
      ),
    ];
    const sortedTickets = _.orderBy(allTicket, [
      ticket => ticket.alreadySeated,
      ticket => ticket.passed,
      ticket => (ticket.passed ? -(ticket.passedMinutes || 0) : 0),
      ticket => {
        let time = ticket.time.split(":");
        return parseInt(time[0]) * 60 + parseInt(time[1]);
      },
      ticket => ticket.prefix,
    ]);
    // const sortedTickets = allTicket.sort((a, b) => {
    //   if (a.passedMinutes !== b.passedMinutes) {
    //     return b.passedMinutes - a.passedMinutes;
    //   }
    //   let timeA = a.time.split(":");
    //   let timeB = b.time.split(":");
    //   let minutesA = parseInt(timeA[0]) * 60 + parseInt(timeA[1]);
    //   let minutesB = parseInt(timeB[0]) * 60 + parseInt(timeB[1]);
    //   return minutesA - minutesB;
    // });
    const searchTickets = sortedTickets.filter(ticket => ticket.ticketNum.includes(this.searchValue));
    return searchTickets.filter(this.predicate);
  }

  get filteredTickets() {
    if (this.current !== "all") {
      return this.tickets.filter(ticket => ticket.queueingGroup === this.current);
    }
    return this.tickets;
  }

  get predicate() {
    const isPassed = this.filterSeated === "passed";
    const isSeated = this.filterSeated === "seated";
    const isUncalled = this.filterSeated === "uncalled";
    const predicate = isPassed
      ? ticket => (ticket.passed || ticket.passedMinutes) && !ticket.alreadySeated
      : isSeated
        ? ticket => ticket.alreadySeated
        : isUncalled
          ? ticket => !ticket.alreadySeated && !ticket.passed
          : () => true;
    return predicate;
  }
}
