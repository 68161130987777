import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand } from "../index";
import type { PrintJob, PrintQueue } from "../printQueue";
import { sign, init, jwk } from "../utils/cloudAuth";
import { FeieyunPrintSequence } from "../printSequence/feieyun";
import { XPrintSequence } from "../printSequence/xprinter/escpos";
import { wrapVue } from "pos-printer";
import qs from "qs";
import type { EscPosPrintSequence } from "../printSequence/escpos";
import { Vue } from "nuxt-property-decorator";
import { boxsCloudGet, boxsCloudPost } from "../ports/cloud";

export interface CloudConf extends PrinterConf {
  port: "cloud";
  serial: string;
  printerType?: string;
  key: string;
  deviceId?: string;
}

export interface CloudDeviceConf {
  _id: string;
  name: string;
  portTypes?: string[];
  type: string;
  printerType?: string;
  vid: number;
  pid: number;
  printerOpts: any;
  additonalOpts?: any;
  useBitmap?: boolean;
  useEpos?: boolean;
  useEposCommand?: boolean;
}

export async function lookupConfig(vid: number, pid: number) {
  const conf = await boxsCloudGet(
    "printerConfs?" +
      qs.stringify({
        type: "printer",
        $or: [
          { vid, pid: 0 },
          { vid, pid },
        ],
        $limit: 1,
      }),
  );
  return conf.data[0];
}

export async function lookupConfigs(request: any) {
  const conf = await boxsCloudGet(
    "printerConfs?" +
      qs.stringify({
        type: "printer",
        ...request,
        $limit: 100,
      }),
  );
  return conf.data;
}

export class PrinterConfigCache {
  dict: Record<number, Record<number, CloudDeviceConf>> = {};
  modelDict: Record<string, CloudDeviceConf> = {};

  async lookup(ids: [vid: number, pid: number][]) {
    let needToLookup = ids.filter(([vid, pid]) => {
      const vdict = this.dict[vid];
      return !vdict || !(vdict[0] || vdict[pid]);
    });

    while (needToLookup.length) {
      const confs = await boxsCloudGet(
        "printerConfs?" +
          qs.stringify({
            type: "printer",
            $or: needToLookup.slice(0, 10).flatMap(([vid, pid]) => [
              { vid, pid: 0 },
              { vid, pid },
            ]),
            $limit: 100,
          }),
      );
      for (const conf of confs.data) {
        const vdict = (this.dict[conf.vid] ??= {});
        vdict[conf.pid] = conf;
      }
      needToLookup = needToLookup.slice(10);
    }

    return ids.map(([vid, pid]) => this.dict[vid]?.[pid] ?? this.dict[vid]?.[0]);
  }

  async lookupByModel(models: string[]) {
    let needToLookup = models.filter(model => !this.modelDict[model]);
    if (needToLookup.length) {
      while (needToLookup.length) {
        const confs = await boxsCloudGet(
          "printerConfs?" +
            qs.stringify({
              type: "printer",
              $or: needToLookup.slice(0, 10).map(model => ({ model })),
              $limit: 100,
            }),
        );
        for (const conf of confs.data) {
          if (!conf.models) continue;
          for (let item of conf.models) {
            this.modelDict[item] = conf;
          }
        }
        needToLookup = needToLookup.slice(10);
      }
    }

    return models.map(model => this.modelDict[model]);
  }
}

export class CloudPrinter extends PrinterBase<CloudConf, CloudConf> {
  constructor(server: PrinterServer, conf: CloudConf) {
    super(server, conf);
  }

  createSequence<T>(context?: Vue): T {
    if (this.conf.type === "thermal" && this.conf.printerType !== "boxs") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return new XPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
      } else {
        return new FeieyunPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
      }
    }
    return super.createSequence<T>(context);
  }

  get printerOpts() {
    if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return super.printerOpts;
      }
    } else if (this.conf.type === "label") {
      return super.printerOpts;
    }
    return [];
  }

  onDisconnectedCore() {}

  async initCore() {}

  async tryConnectCore() {
    await init();
    try {
      await boxsCloudGet(
        `printers/?token=${await sign(
          {},
          {
            deviceId: this.conf.deviceId,
          },
        )}`,
      );
    } catch (e) {
      if (e.data) {
        throw new Error(e.data.message);
      } else {
        throw e;
      }
    }
  }

  async printCore(job: PrintJob) {
    try {
      let printContent =
        job.type === "escpos" || job.type === "label" || job.type === "star"
          ? job.data.toString("base64")
          : job.data.toString();

      const postResp = await boxsCloudPost(`printers/print`, {
        token: await sign(
          {},
          {
            deviceId: this.conf.deviceId,
          },
        ),
        content: printContent,
        type: job.opts?.type || "feieyun",
        printerType: job.type,
        cashBox: job.opts?.cashBox ?? false,
        autoCut: job.opts?.autoCut ?? true,
        expires: job.expires,
        jobId: job.id,
      });
      job.remoteId = postResp.jobId;
      job.remoteOpts = postResp.jobOpts;
    } catch (e) {
      if (e?.data?.error?.className === "not-found" || e.message?.includes?.("No record found for id")) {
        job.retryable = false;
      }
      throw e;
    }
  }

  async cashBox(context?: Vue, which?: number, time?: number): Promise<void> {
    if (this.conf.type === "thermal" && !(this.conf.opts?.escpos || this.conf.opts?.features?.escpos)) {
      const postResp = await boxsCloudPost(`printers/print`, {
        token: await sign(
          {},
          {
            deviceId: this.conf.deviceId,
          },
        ),
        type: "xpyun",
        cashBox: true,
        content: "",
      });
    } else {
      return super.cashBox(context, which, time);
    }
  }

  async disconnectCore() {}

  async requestNewDeviceCore() {
    return this.conf;
  }

  async clear() {
    const postResp = await boxsCloudPost(`printers/purge`, {
      token: await sign(
        {},
        {
          deviceId: this.conf.deviceId,
        },
      ),
    });
  }

  startPollingUpdate(job: PrintJob, queue: PrintQueue): void {
    if (!job.remoteId || !job.remoteOpts) {
      return;
    }
    (async () => {
      let errCount = 0;
      const startTime = Date.now();
      for (let i = 0; i < 60; i++) {
        await new Promise(resolve => setTimeout(resolve, 5000));
        try {
          const postResp = await boxsCloudGet(
            `printers/print/${job.remoteId}?${qs.stringify({
              token: await sign(
                {},
                {
                  deviceId: this.conf.deviceId,
                },
              ),
            })}`,
          );

          job.status = postResp.status;
          queue.updateJobStatus(job);

          if (postResp.status === "error" || postResp.status === "done") {
            return;
          }
        } catch (e) {
          console.warn(e);
          if (++errCount >= 3) {
            job.status = "error";
            queue.updateJobStatus(job);
            return;
          }
        }
      }

      job.status = "error";
      queue.updateJobStatus(job);
    })().catch(console.warn);
  }
}
