
import _ from "lodash";
import { Component, mixins, Vue, FindType, Ref } from "@feathers-client";
import { Prop, VModel } from "vue-property-decorator";
import { emit } from "node:process";

@Component
export default class StaffPicker extends Vue {
  staff = false;

  @VModel()
  inputValue: FindType<"staffs">;

  @Prop()
  customFunc: boolean;

  onScanBtn() {
    if (this.customFunc) {
      this.$emit("customFunc");
      return;
    }
    this.staff = !this.staff;
  }

  async inputCode() {
    const staff = await this.$openDialog(
      import("~/components/dialogs/StaffCodeDialog.vue"),
      {},
      {
        maxWidth: "380px",
        persistent: true,
      },
    );

    // if (code) {
    //   const staff = (
    //     await this.$feathers.service("staffs").find({
    //       query: {
    //         code,
    //       },
    //     })
    //   ).data[0];
    //   if (!staff) {
    //     this.$store.commit("SET_ERROR", this.$t("scanner.invalidCode"));
    //     return;
    //   }
    // this.setStaff(staff);
    // }
    if (staff === false) return;

    this.inputValue = staff;
    this.staff = false;
  }

  async setStaff(staff: FindType<"staffs">) {
    if (staff.passCode) {
      if (
        !(await this.$openDialog(
          import("~/components/dialogs/StaffCodeDialog.vue"),
          {
            defaultStaff: staff,
          },
          {
            maxWidth: "400px",
          },
        ))
      ) {
        return;
      }
    }

    this.$shop.staffLoginTime = Date.now();
    this.inputValue = staff;
    this.staff = false;
  }
}
