<script>

import { Line, mixins } from 'vue-chartjs'
import _ from 'lodash'
import reactive from './reactive'

export default {
    extends: Line,
    mixins: [reactive({chartData: 'mchartData', options: 'moptions'})],
    computed: {
        moptions() {
            return _.merge({
                responsive: true,
                maintainAspectRatio: false,
            }, this.options || {});
        },
        mchartData() {
            return _.merge({
                datasets: _.map((this.chartData || {}).datasets || [], (dataset, idx) => ({
                    backgroundColor: (idx % 2) ? this.gradient2 : this.gradient,
                }))
            }, this.chartData || {});
        }
    },
    props: ['labels', 'data', 'options'],
    data() {
        return {
            gradient: null,
            gradient2: null,
        };
    },
    mounted() {
        const ctx = this.$el.getElementsByTagName("canvas")[0].getContext("2d");
        this.gradient = ctx.createLinearGradient(0, 0, 0, 400);
        this.gradient2 = ctx.createLinearGradient(0, 0, 0, 400);

        this.gradient2.addColorStop(0, "rgba(3, 85, 179, 0.9)");
        this.gradient2.addColorStop(0.5, "rgba(3, 85, 179, 0.5)");
        this.gradient2.addColorStop(1, "rgba(2, 27, 121, 0.25)");

        this.gradient.addColorStop(0, "rgba(132, 30, 122, 1)");
        this.gradient.addColorStop(0.5, "rgba(132, 30, 122, 0.8)");
        this.gradient.addColorStop(1, "rgba(90, 33, 122, 0.75)");

        this.renderChart(this.mchartData, this.moptions)
    }
}
</script>