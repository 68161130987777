import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { MapFields } from "./utils";

@Component
export class ProductStock extends mixins(
  MapFields(
    "productStocks",
    ["mode", "onlineMode", "resumeMode", "quantity", "stockMode", "productOptions", "manualQuantity", "manualStockRefill"] as const,
    [],
    {
      onlineMode: "allow",
      manualStockRefill: [],
    },
  ),
) {}
