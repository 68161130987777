var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.points),function(point){return _c('div',{key:point._id},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$td(point.name)))]),_c('data-table',{attrs:{"data":{
        path: 'userPoints',
        filter: {
          point: point._id,
          user: _vm.user,
        },
        sortBy: ['expiresAt'],
        sortDesc: [true],
        name: _vm.$t('userPoints.$'),
      },"headers":[
        { text: _vm.$t('userPoint.expiresAt'), sortable: true, value: 'expiresAt', format: 'moment' },
        { text: _vm.$t('userPoint.totalIn'), sortable: true, value: 'totalIn' },
        { text: _vm.$t('userPoint.totalOut'), sortable: true, value: 'totalOut' },
        { text: _vm.$t('userPoint.amount'), sortable: true, value: 'amount' },
        { text: _vm.$t('userPoint.used'), sortable: true, value: 'used' },
      ],"actions":"","no-remove":"","no-clone":"","no-edit":"","no-add":"","default":{
        point,
        value: 0,
        user: _vm.user,
        message: 'by admin',
      }},scopedSlots:_vm._u([{key:"post-actions",fn:function({ reload }){return [_c('b-btn',{attrs:{"alt-text":_vm.$t('basic.add')},on:{"click":function($event){return _vm.addPoint(point, reload)}}},[_c('v-icon',[_vm._v("add")])],1)]}},{key:"editor",fn:function(edit){return [_c('v-text-field',{attrs:{"label":_vm.$t('point.value')},model:{value:(edit.item.amount),callback:function ($$v) {_vm.$set(edit.item, "amount", _vm._n($$v))},expression:"edit.item.amount"}}),_c('v-text-field',{attrs:{"label":_vm.$t('point.message')},model:{value:(edit.item.message),callback:function ($$v) {_vm.$set(edit.item, "message", $$v)},expression:"edit.item.message"}})]}}],null,true)}),_c('data-table',{attrs:{"data":{
        path: 'userPointLogs',
        filter: {
          point: point._id,
          user: _vm.user,
        },
        sortBy: ['date'],
        sortDesc: [true],
        name: _vm.$t('userPointLogs.$'),
        populate: ['session'],
      },"headers":[
        { text: _vm.$t('userPointLog.date'), sortable: true, value: 'date', format: 'moment' },
        { text: _vm.$t('userPointLog.from'), sortable: true, value: 'from' },
        { text: _vm.$t('userPointLog.to'), sortable: true, value: 'to' },
        { text: _vm.$t('userPointLog.delta'), sortable: true, value: 'delta' },
        { text: _vm.$t('userPointLog.type'), sortable: true, value: 'type' },
        { text: _vm.$t('userPointLog.message'), sortable: true, value: 'message' },
      ],"itemCanClick":""},scopedSlots:_vm._u([{key:"expand",fn:function({ item: log }){return [(log.session)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text')],1):_vm._e()]}}],null,true)})],1)}),0)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }