import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Context } from "@nuxt/types";
import { IndexedDb } from "minimongo";
import { ProductLine } from "../table/session";
import { sumIngredients, multiplyIngredients } from "@common/table/ingredients";
import _ from "lodash";
import errors from "@feathersjs/errors";
import { FindPopRawType, FindType, checkID } from "@feathers-client";
import uuid from "uuid/v4";
import { BatchEditActionList } from "@schemaEditor/batch/editorContext";
import { MHookContext } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import { ObjectId } from "bson";
// import { getID, checkID, translate } from "@util";
import NetworkConnectDialog from "~/components/NetworkConnectDialog.vue";
import type DB from "@db";
import { TurnCloudManager } from "./integrations/turnCloud";

type TableSession = typeof DB.TableSession._mongoType;
type Product = FindPopRawType<["stocks", "options", "ingredients"], "products">;

const hooks = require.context("./services", true, /\.ts$/);

const cachedCollections = [
  { path: "shops", readOnly: true },
  { path: "shopGroups", readOnly: true },
  { path: "ingredients/cached", paginate: false, cacheOnly: true },
  { path: "tableViews" },
  { path: "tableViewItems" },
  { path: "tableSessions" },
  { path: "tableSessionActions" },
  { path: "payments" },
  { path: "posDevices" },
  { path: "cashboxLogs" },
  { path: "shopSessions" },
  { path: "staffPunchRecords" },
  { path: "shopNotifications" },
  { path: "actionLogs" },
  { path: "tvOrderStatuses" },
  { path: "ingredientUsageLocks" },

  { path: "sections", readOnly: true },
  { path: "categories", readOnly: true },
  { path: "subCategories", readOnly: true },
  { path: "orderDiscounts", readOnly: true },
  { path: "products", readOnly: true },
  { path: "productOptions", readOnly: true },
  { path: "productStocks", cacheOnly: true },
  { path: "productOptionStocks", cacheOnly: true },
  { path: "customProducts", readOnly: true },
  { path: "taxSettings", readOnly: true },
  { path: "surchargeSettings", readOnly: true },
  { path: "kitchenPrinters", readOnly: true },
  { path: "waterBars", readOnly: true },
  { path: "kitchenOptions", readOnly: true },
  { path: "cancelReasons", readOnly: true },
  { path: "ingredients", readOnly: true },
  { path: "ingredientCats", readOnly: true },
  { path: "shopModifiers", readOnly: true },
  { path: "shopPrinters", readOnly: true },
  { path: "sharePrintJobs", noCache: true },
  { path: "paymentMethods", readOnly: true },

  { path: "staffs", readOnly: true },
  { path: "schemas", paginate: false, readOnly: true },
  { path: "printerTemplates", readOnly: true },
  { path: "tableSessions/edit", readOnly: true },
  { path: "cloudPrinters", readOnly: true, cacheOnly: true },
  { path: "twInvoiceRolls", init: true, persistent: true },
  { path: "twInvoices" },
  { path: "shopOptions", readOnly: true },
  { path: "shopPoints", readOnly: true },
  { path: "planInfo/cached", readOnly: true },
];

const pathToConfig = Object.fromEntries(cachedCollections.map(it => [it.path, it] as const));

export class OfflineManager {
  db: IndexedDb;

  offline = localStorage["testOffline"] === "1";
  root: Vue;
  hooks: Record<string, Record<string, (hook: MHookContext<AdminApplication, any, any>) => Promise<void>>> = {};
  offlineOrders: Set<string> = new Set();
  dirty = false;
  error: string = null;

  offlineEnabled = false;
  offlineFeature = false;

  products: Record<string, Product> = {};
  productStocks: Record<string, FindType<"productStocks">> = {};
  productOptions: Record<string, Product["options"][number]> = {};

  turnCloud: TurnCloudManager;

  async init(root: Vue) {
    this.root = root;
    (root.$feathers as any).$offline = this;
    this.updateOfflineStat();
    if (!this.offlineFeature) return;
    if (root.$store.state.connected && localStorage["testOffline"] !== "1") {
      this.onConnected();
    }

    if (this.root.$features.turnCloud) {
      this.turnCloud = new TurnCloudManager({ parent: this.root });
    }
  }

  get connected() {
    return this.store.state.connected;
  }

  get user() {
    return this.store.state.user;
  }

  get shopId() {
    return this.user.shop;
  }
  // Copied from online version, currently only accessing the productStocks
  // activeModifiers<T extends Product["priceModifiers"][number] | Product["ingredientModifiers"][number]>(
  //   session: TableSession,
  //   line: ProductLine,
  //   modifiers: T[],
  // ) {
  //   return (
  //     modifiers?.filter?.(mod => {
  //       if (mod.shop && !checkID(mod.shop, this.shopId)) {
  //         return false;
  //       }
  //       switch (mod.type) {
  //         case "productOption": {
  //           const o = line.options.find(it => checkID(it.option, mod.option));
  //           return o && !!o.values.find(opt => checkID(mod.optionValue, opt));
  //         }
  //         case "modifier": {
  //           return !!session?.modifiers?.find?.(it => checkID(it, mod.modifier));
  //         }
  //       }
  //     }) || []
  //   );
  // }

  // calIngredient(session: TableSession, line: ProductLine) {
  //   const product = this.products[`${line.product}`];
  //   if (!product) {
  //     throw new Error(`${line.product} is not found`);
  //   }

  //   const baseIngredients = product.ingredients;
  //   const activeIngredients = this.activeModifiers(session, line, product.ingredientModifiers).map(
  //     it => it.ingredients,
  //   );
  //   const activeOptionIngredients = product.options
  //     .map(opt => this.productOptions[`${opt}`])
  //     .filter(it => !!it)
  //     .flatMap((opt, optionIndex) => {
  //       const lineOpt = line.options?.find?.(lineOpt => checkID(lineOpt.option, opt));
  //       if (!lineOpt) return [];

  //       return opt.options.filter(
  //         item => item.ingredients?.length && !!lineOpt.values?.find?.(lineValue => checkID(item, lineValue)),
  //       );
  //     })
  //     .map(it => it.ingredients);

  //   return multiplyIngredients(
  //     sumIngredients([], baseIngredients, ...activeIngredients, ...activeOptionIngredients),
  //     line.quantity,
  //   );
  // }
  // getStock(product: string) {
  //   if (!product) return null;
  //   let stock = this.productStocks[product];
  //   if (stock) return stock;
  //   let task = this.stockDict[product];
  //   if (task) return task;
  //   task = this.stockDict[product] = (async () => {
  //     try {
  //       const item = await this.parent.admin.service("productStocks").create({
  //         product: product,
  //         shop: this.id,
  //       });
  //       delete this.stockDict[product];
  //       return this.update("productStocks", "created", item);
  //     } catch (e) {
  //       if (process.env.TESTING) return;
  //       try {
  //         console.warn("Cannot create stock", e.message);
  //         const item = (
  //           await this.parent.admin.service("productStocks").find({
  //             query: {
  //               product: product,
  //               shop: this.id,
  //             },
  //             paginate: false,
  //           })
  //         )[0];

  //         if (item) {
  //           delete this.stockDict[product];
  //           return this.update("productStocks", "created", item);
  //         }
  //       } catch (e) {
  //         console.warn("Cannot find stock", e.message);
  //         return null;
  //       }
  //     }
  //   })();
  //   return task;
  // }

  async lockStocks(
    hook: MHookContext<AdminApplication, any>,
    session: TableSession,
    lines: ProductLine[],
    force = false,
    checkOnline = false,
  ) {
    let verifyMode = "async";
    let checkedIngredients: Record<string, number> = {};
    let checkedProducts: Record<string, number> = {};

    // prepare locks
    let locks: any[] = [];

    // let ingredients = await this.feathers.service("ingredients").find({
    //   query: {
    //     _id: {
    //       $in: lines.flatMap(it => this.calIngredient(session, it).map(it => it.ingredient)),
    //     },
    //   },
    //   paginate: false,
    // });
    // let products = await this.feathers.service("products").find({
    //   query: {
    //     _id: {
    //       $in: lines.map(it => it.product),
    //     },
    //     $populate: ["stocks", "options", "ingredients"],
    //   },
    //   paginate: false,
    // });

    await Promise.all(
      lines.map(async line => {
        if (!line.product) return;
        // const product = await this.ensureProduct(getID(line.product));
        // const ingredients = multiplyIngredients(this.calIngredient(session, line), -1);
        const stock = this.productStocks[`${line.product}`]; //await this.getStock(`${line.product}`);

        // if (checkOnline) {
        //   switch (stock?.onlineMode) {
        //     case 'notSelling':
        //     case 'paused':
        //     case 'askStaff':
        //       throw new Error(`Not selling online: ${translate(product?.name, 'en')}`);
        //   }
        // }

        // if (!force) {
        //   for (let item of line.options) {
        //     const optionStock = this.productOptionStocks[`${item.option}`];
        //     if (optionStock) {
        //       for (let select of item.values) {
        //         const valueStock = optionStock.values?.find?.(it => checkID(it.value, select));
        //         if ((valueStock?.mode ?? "selling") !== "selling") {
        //           const valueInfo = this.productOptions[`${item.option}`]?.options?.find?.(it => checkID(it, select));
        //           throw new Error(`Option not selling: ${translate(valueInfo?.name, "en")}`);
        //         }
        //         if (checkOnline && (valueStock?.onlineMode ?? "allow") !== "allow") {
        //           const valueInfo = this.productOptions[`${item.option}`]?.options?.find?.(it => checkID(it, select));
        //           throw new Error(`Option not selling online: ${translate(valueInfo?.name, "en")}`);
        //         }
        //       }
        //     }
        //   }
        // }

        // if (stock.mode === "auto") {
        //   if (stock.stockMode === "sync") {
        //     verifyMode = "sync";
        //   }
        //   // for auto products, need to verify all ingredients
        //   for (let ingredient of ingredients) {
        //     // if (this.ingredients[getID(ingredient.ingredient)]?.checked === false) continue;
        //     checkedIngredients[`${ingredient.ingredient}`] =
        //       (checkedIngredients[`${ingredient.ingredient}`] || 0) - ingredient.amount;
        //   }
        // }

        //   for (let item of line.options) {
        //     const info = stock.productOptions?.find?.(it => checkID(item.option, it.option));
        //     if (info) {
        //       for (let val of item.values) {
        //         if (info.values?.find?.(it => checkID(it, val))?.stockMode === "sync") {
        //           verifyMode = "sync";
        //         }
        //       }
        //     }
        //   }
        // } else if (stock.mode === "manual") {
        //   if (stock.stockMode === "sync") {
        //     verifyMode = "sync";
        //   }
        checkedProducts[`${line.product}`] = (checkedProducts[`${line.product}`] || 0) + line.quantity;
        // } else if (stock.mode === "notSelling" || stock.mode === "paused") {
        //   if (!force) {
        //     throw new Error(`Not selling: ${translate(line?.product, "en")}`);
        //   }
        // }
        const fromProductType =
          (line.fromProduct && lines.find(it => it.id === line.fromProduct)) ||
          session.products.find(it => it.id === line.fromProduct);

        locks.push({
          line: line.id,
          fromProduct: line.fromProduct,
          parentProduct: fromProductType?.product,
          product: line.product,
          ingredients: null,
          shop: this.shopId,
          type: "product",
          session: session._id,
          options: line.options as any,
          quantity: -line.quantity,
          salesPrice: line.salesPrice || 0,
        });
      }),
    );

    if (locks.length) {
      const newLocks = await hook.app.service("ingredientUsageLocks").create(locks);
      for (let lock of newLocks) {
        const currentLine = lines.find(it => it.id === lock.line);
        if (currentLine) {
          currentLine.usageLock = lock._id;
        }
      }
    }
  }

  testOffline(offline?: boolean) {
    this.offline = offline || false;
    if (this.root) {
      this.root.$network.offlineMode = this.offline;
    }
  }

  pendingRetires: Set<Promise<void>> = new Set();

  waitPendingRetires() {
    return Promise.race([
      Promise.all(this.pendingRetires),
      new Promise<void>(resolve => {
        setTimeout(resolve, 10000);
      }),
    ]);
  }

  constructor(
    public feathers: any,
    public store: any,
    public masterSwitch: boolean,
  ) {
    if (!masterSwitch) return;
    this.offlineFeature = true;
    if (localStorage["cachedDevice"]) {
      try {
        const device = JSON.parse(localStorage["cachedDevice"]);
        this.offlineEnabled = device.offlineActive || false;
      } catch (e) {
        console.warn(e);
      }
    }
    if (this.offlineEnabled) {
      this.offline = !navigator.onLine || localStorage["testOffline"] === "1";
    }
    let firstConnect = true;
    if (localStorage["testOffline"] !== "1") {
      feathers.on("connected", () => {
        this.onConnected();
        firstConnect = false;
      });

      feathers.on("disconnected", () => {
        this.onDisconnected();
      });

      if (store.state.connected) {
        this.offline = false;
      }
    }

    this.feathers.hooks({
      before: {
        all: async hook => {
          // console.log(hook.method, hook.path);

          if (!this.offline && !this.connected && !firstConnect) {
            const promise = new Promise<void>(resolve => {
              hook.params.resolveRetry = resolve;
            });
            this.pendingRetires.add(promise);
            promise.finally(() => {
              this.pendingRetires.delete(promise);
              delete hook.params.resolveRetry;
            });
            await this.waitConnected();
          }

          // console.log("this.hooks", this.hooks)
          // console.log("hook", hook)
          // console.log("this.db", this.db)

          if (this.offline) {
            hook.$offline = this;
            await this.hooks?.[hook.path]?.["all"]?.(hook);
            await this.hooks?.[hook.path]?.["allBefore"]?.(hook);
            await this.hooks?.[hook.path]?.[hook.method]?.(hook);
            if (hook.result) return;
            await this.ensureDb();
            const collection = this.db[hook.path];
            if (collection) {
              const query = { ...(hook.params.query || {}) };
              const skip = query.$skip;
              const limit = query.$limit;
              const sort = query.$sort;
              delete query.$paginate;
              delete query.$populate;
              delete query.$sort;
              delete query.$limit;
              delete query.$select;
              delete query.$skip;
              if (hook.params.query?.$tempId) {
                hook.params.tempId = hook.params.query.$tempId;
                delete hook.params.query.$tempId;
              }

              // fix malformed id
              if (hook.id && typeof hook.id === "object" && hook.id?._id) {
                hook.id = hook.id._id;
              }

              switch (hook.method) {
                case "get": {
                  query._id = hook.id;
                  hook.result = await new Promise((resolve, reject) => collection.findOne(query, {}, resolve, reject));
                  break;
                }
                case "find": {
                  const paginated = !(collection.config.paginate === false || hook.params.query?.$paginate === false);
                  if (paginated) {
                    const total = (await collection.find(query, {}).fetch()).length;
                    hook.result = {
                      data: await collection
                        .find(query, {
                          limit,
                          skip,
                          sort,
                        })
                        .fetch(),
                      total,
                      skip,
                      limit,
                    };
                  } else {
                    hook.result = await collection
                      .find(query, {
                        limit,
                        skip,
                        sort,
                      })
                      .fetch();
                  }
                  // console.log("done", hook.method, hook.path);
                  break;
                }
                case "create": {
                  if (pathToConfig[hook.path]?.readOnly) {
                    throw new Error(`${hook.path} is readonly in offline`);
                  }
                  hook.data._id = `$$offline/${hook.path}/${uuid()}`;
                  // fill defaults
                  const editor = await this.root.$schemas.getConfigByApiPath(hook.path);
                  if (editor) {
                    for (let [k, v] of Object.entries(editor.defaultValue)) {
                      if (hook.data[k] === undefined) {
                        hook.data[k] = _.cloneDeep(v);
                      }
                    }
                  }
                  const jsonData = JSON.parse(JSON.stringify(hook.data));
                  fixArrayId(jsonData);
                  hook.result = _.cloneDeep(await collection.upsert(jsonData));
                  this.dirty = true;
                  if (hook.params.tempId) {
                    hook.result.tempId = hook.params.tempId;
                  }
                  break;
                }
                case "patch": {
                  if (!hook.data) {
                    debugger;
                  }
                  if (pathToConfig[hook.path]?.readOnly) {
                    throw new Error(`${hook.path} is readonly in offline`);
                  }
                  hook.data._id = hook.id;
                  const prev = await this.feathers.service(hook.path).get(hook.id);
                  const jsonData = JSON.parse(
                    JSON.stringify({
                      ...prev,
                      ...hook.data,
                    }),
                  );
                  fixArrayId(jsonData);
                  const newItem = await collection.upsert(jsonData, prev);
                  hook.result = _.cloneDeep(newItem);
                  this.dirty = true;
                  if (hook.params.tempId) {
                    hook.result.tempId = hook.params.tempId;
                  }
                  break;
                }
                case "remove": {
                  if (pathToConfig[hook.path]?.readOnly) {
                    throw new Error(`${hook.path} is readonly in offline`);
                  }
                  if (hook.id) {
                    await collection.remove(hook.id);
                    this.dirty = true;
                  }
                  break;
                }
              }
            } else {
              throw new Error("not cached: " + hook.path);
            }
          }
        },
      },
      after: {
        all: async hook => {
          if (!this.offlineEnabled) return;
          if (!this.offline) {
            if (this.shopId && this.user) {
              // cache all
              await this.ensureDb();
              const collection = this.db[hook.path];
              if (collection) {
                if (collection.noCache) {
                  return;
                }
                const query = { ...(hook.params.query || {}) };
                const skip = query.$skip;
                const limit = query.$limit;
                const sort = query.$sort;
                const select = query.$select;
                delete query.$paginate;
                delete query.$populate;
                delete query.$sort;
                delete query.$limit;
                delete query.$select;
                delete query.$skip;

                if (hook.path === "schemas") {
                  hook.result._id = "schemas";
                  hook.method = "get";
                  hook.id = "schemas";
                }

                switch (hook.method) {
                  case "find": {
                    const docs = Array.isArray(hook.result)
                      ? hook.result
                      : Array.isArray(hook.result?.data)
                        ? hook.result.data
                        : [];
                    if (!docs.length) return;
                    collection.cache(
                      docs,
                      query,
                      {
                        skip,
                        limit,
                        sort,
                        select,
                      },
                      () => {},
                      () => {},
                    );
                    break;
                  }
                  case "get": {
                    query._id = hook.result._id;
                    collection.cacheOne(
                      hook.result,
                      () => {},
                      () => {},
                    );
                    break;
                  }
                }
              }
            }
          } else {
            hook.$offline = this;
            await this.hooks?.[hook.path]?.["allAfter"]?.(hook);
            await this.hooks?.[hook.path]?.[hook.method + "After"]?.(hook);

            switch (hook.method) {
              case "create": {
                this.feathers
                  .service(hook.path)
                  .listeners("created")
                  .forEach(cb => cb(hook.result));
                break;
              }
              case "patch": {
                this.feathers
                  .service(hook.path)
                  .listeners("patched")
                  .forEach(cb => cb(hook.result));
                break;
              }
              case "remove": {
                this.feathers
                  .service(hook.path)
                  .listeners("removed")
                  .forEach(cb => {
                    cb(hook.result);
                  });
                break;
              }
            }
          }
        },
      },
      error: {
        all: async hook => {
          if (hook.params.resolveRetry) {
            hook.params.resolveRetry?.();
          }
          if (hook.error?.data?.reason === "socketDisconnect") {
            if (!this.offline && this.connected) {
              const promise = new Promise<void>(resolve => {
                hook.params.resolveRetry = resolve;
              });
              this.pendingRetires.add(promise);
              promise.finally(() => {
                this.pendingRetires.delete(promise);
                delete hook.params.resolveRetry;
              });
              await this.waitConnected();
            }
            try {
              let args: Array<any> = [hook.params];
              switch (hook.method) {
                case "patch":
                case "update":
                case "create":
                  args.unshift(hook.data);
                  break;
              }
              switch (hook.method) {
                case "patch":
                case "update":
                case "remove":
                  args.unshift(hook.id);
                  break;
              }
              hook.result = await (hook as any).service[hook.method].apply(hook.service, args);
            } catch (e) {
              hook.error = e;
            }
            hook.params.resolveRetry?.();
          }
        },
      },
    });

    for (let key of hooks.keys()) {
      let mkey = key;
      if (mkey.endsWith(".ts")) mkey = mkey.slice(0, -3);
      if (mkey.endsWith("/index")) mkey = mkey.slice(0, -6);
      if (mkey === ".") mkey = "";
      if (mkey.startsWith("./")) mkey = mkey.slice(2);
      mkey = mkey.replace(/\\/g, "/"); //fix windows shit
      this.hooks[mkey] = hooks(key);
    }

    for (let table of cachedCollections) {
      const service = this.feathers.service(table.path);
      service.on("created", this.updateData.bind(this, table.path, "create"));
      service.on("patched", this.updateData.bind(this, table.path, "patch"));
      service.on("removed", this.updateData.bind(this, table.path, "remove"));
    }
  }

  async updateData(path: string, method: "create" | "patch" | "remove", item: any) {
    if (this.offline || !this.offlineEnabled) return;
    await this.ensureDb();
    const collection = this.db[path];
    if (!collection) return;
    if (!item._id) return;
    if (method === "remove") {
      collection.uncacheList(
        [item._id],
        () => {},
        () => {},
      );
    } else {
      collection.cacheOne(
        item,
        () => {},
        () => {},
      );
    }
  }

  async fetchData(path: string, query: any) {
    await this.ensureDb();
    const collection = this.db[path];
    if (!collection) return;
    const result = await collection.find(query, {}).fetch();
    return result;
  }

  async updateTwInvoiceRolls(rolls) {
    await this.ensureDb();
    const collection = this.db["twInvoiceRolls"];
    if (!collection) return;
    collection.uncache(
      {
        status: "used",
      },
      () => {},
      () => {},
    );

    for (let roll of rolls) {
      collection.cache(
        [roll],
        {
          _id: roll._id,
        },
        {},
        () => {},
        () => {},
      );
    }
  }

  waitConnectedTask: Promise<void> = null;
  waitConnected() {
    if (!this.waitConnectedTask) {
      const task = (this.waitConnectedTask = this.waitConnectedInner());
      task.finally(() => {
        this.waitConnectedTask = null;
      });
    }
    return this.waitConnectedTask;
  }

  async waitConnectedInner() {
    if (this.offline) return;
    let connected = await this._waitConnectionWithin(3000);
    while (!connected && !this.root) {
      connected = await this._waitConnectionWithin(3000);
    }
    if (!connected) {
      connected = await this.root.$openDialog(
        NetworkConnectDialog,
        {
          offlineEnabled: this.offlineEnabled,
        },
        {
          maxWidth: "400px",
          persistent: true,
        },
      );
    }

    if (!connected && this.offlineEnabled) {
      this.offline = true;
      this.updateOfflineStat();
    }
  }

  async _waitConnectionWithin(timeout = 5000) {
    if ((this.feathers as any).connected) {
      return true;
    }
    return await new Promise<boolean>(resolve => {
      const connected = () => {
        if (timer) {
          clearTimeout(timer);
        }
        resolve(true);
      };

      this.feathers.once("connected", connected);
      let timer = setTimeout(() => {
        timer = null;
        this.feathers.off("connected", connected);
        resolve(false);
      }, timeout);
    });
  }

  asyncDb: Promise<void>;
  async ensureDb() {
    if (!this.db) {
      let dbCreated, dbError;
      const promise = new Promise((resolve, reject) => {
        dbCreated = resolve;
        dbError = reject;
      });
      this.db = new IndexedDb(
        {
          namespace: "shops/" + this.shopId,
        },
        dbCreated,
        dbError,
      );

      this.asyncDb = (async () => {
        await promise;

        for (let item of cachedCollections) {
          await new Promise((resolve, reject) => {
            this.db.addCollection(item.path, resolve, reject);
          });
          this.db[item.path].config = item;
        }

        (async () => {
          for (let item of cachedCollections) {
            if (item.readOnly || item.cacheOnly) continue;
            const collection = this.db[item.path];
            const items = await new Promise<any[]>((resolve, reject) => {
              collection.pendingUpserts(resolve, reject);
            });

            const removeIds = await new Promise<string[]>((resolve, reject) => {
              collection.pendingRemoves(resolve, reject);
            });

            if (items.length || removeIds.length) {
              this.dirty = true;
            }
            if (items.length && item.path === "tableSessions") {
              for (let item of items) {
                this.offlineOrders.add(item.doc._id);
              }
              this.updateOfflineStat();
            }
          }
        })();
      })();
    }
    await this.asyncDb;
  }

  syncing = false;

  async syncData() {
    if (!this.offlineEnabled) return;
    if (this.syncing) return;
    if (!this.shopId) return;
    this.syncing = true;
    this.error = null;
    this.updateOfflineStat();
    await this.ensureDb();
    try {
      const patchData: BatchEditActionList = {
        create: [],
        patch: [],
        remove: [],
      };
      const toClean: Record<string, any[]> = {};
      for (let table of cachedCollections) {
        const collection = this.db[table.path];
        if (table.readOnly) continue;
        const items = await new Promise<any[]>((resolve, reject) => {
          collection.pendingUpserts(resolve, reject);
        });

        const removeIds = await new Promise<string[]>((resolve, reject) => {
          collection.pendingRemoves(resolve, reject);
        });

        if (table.cacheOnly) {
          await new Promise((resolve, reject) => {
            collection.resolveUpserts(items, resolve, reject);
          });
          for (let id of removeIds) {
            await new Promise((resolve, reject) => {
              collection.resolveRemove(id, resolve, reject);
            });
          }
          continue;
        }

        const prefix = `$$offline/${table.path}/`;
        for (let item of items) {
          if (!item.base || item.doc._id.startsWith(prefix)) {
            patchData.create.push({
              path: table.path,
              data: item.doc,
              id: item.doc._id,
              query: { $offlineSync: item.doc._id },
            });
          } else {
            patchData.patch.push({
              path: table.path,
              id: item.base._id,
              data: item.doc,
              query: { $offlineSync: true },
            });
          }
        }
        if (!table.persistent) {
          toClean[table.path] = items;
        }

        for (let id of removeIds) {
          patchData.remove.push({
            path: table.path,
            id,
            query: { $offlineSync: true },
          });
        }
      }
      await this.feathers.service("imports/batch").create(patchData);
      for (let [path, items] of Object.entries(toClean)) {
        const collection = this.db[path];
        await new Promise((resolve, reject) => {
          collection.resolveUpserts(items, resolve, reject);
        });
      }
      for (let [path, items] of Object.entries(_.groupBy(patchData.remove, it => it.path))) {
        const collection = this.db[path];
        for (let id of items) {
          await new Promise((resolve, reject) => {
            collection.resolveRemove(id.id, resolve, reject);
          });
        }
      }
      this.error = null;
      this.dirty = false;
      this.offlineOrders.clear();
      this.updateOfflineStat();
      this.root?.$tableManager?.reloadAll?.();
    } catch (e) {
      console.warn(e);
      this.error = e.message;
    } finally {
      this.syncing = false;
      this.updateOfflineStat();
    }
  }

  async cleanData() {
    if (!this.offlineEnabled) return;
    for (let table of cachedCollections) {
      const collection = this.db[table.path];
      await new Promise((resolve, reject) => {
        collection.uncache({}, resolve, reject);
      });

      const items = await new Promise<any[]>((resolve, reject) => {
        collection.pendingUpserts(resolve, reject);
      });

      await new Promise((resolve, reject) => {
        collection.resolveUpserts(items, resolve, reject);
      });
    }
    this.offlineOrders.clear();
    this.updateOfflineStat();
  }

  updateOfflineStat() {
    if (this.root) {
      this.root.$network.offlineMode = this.offline;
      this.root.$network.offlineOrders = this.offlineOrders.size;
      this.root.$network.offlineStatus = !this.offlineEnabled
        ? "none"
        : this.error
          ? "error"
          : this.syncing
            ? "working"
            : this.dirty || this.offlineOrders.size
              ? "pending"
              : "success";
    }
  }

  toggleOffline() {
    this.offline = !this.offline;
    this.updateOfflineStat();
    if (!this.offline) {
      this.syncData();
    }
  }

  connectedTimer: any;
  onConnected() {
    if (this.connectedTimer) return;
    this.connectedTimer = setTimeout(() => this.onConnectionStable(), 3000);
  }

  onDisconnected() {
    if (this.connectedTimer) {
      clearTimeout(this.connectedTimer);
      this.connectedTimer = null;
    }
    this.waitConnected();
  }

  async onConnectionStable() {
    this.connectedTimer = null;
    console.log("Conneciton stable, resume connection");
    if (this.offline) {
      this.offline = false;
      this.updateOfflineStat();
      // reload device after connection is stable
      this.root.$shop.onConnected().catch(console.error);
      this.syncData().catch(console.error);
      if (this.root) {
        this.root.$shop.localPrinter.retryMissingJobs();
      }
    } else {
      this.syncData().catch(console.error);
    }
  }

  async setup() {
    await this.root.$openDialog(
      import("~/components/OfflineSetupDialog.vue"),
      {},
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }
}

function fixArrayId(item: any) {
  if (Array.isArray(item)) {
    for (let it of item) {
      if (typeof it === "object" && it && !(item instanceof Date || item instanceof Buffer)) {
        if (!Array.isArray(it) && !it._id) {
          it._id = new ObjectId().toString();
        }
        fixArrayId(it);
      }
    }
  } else if (item && typeof item === "object") {
    for (let [k, v] of Object.entries(item)) {
      if (typeof v === "object") {
        fixArrayId(v);
      }
    }
  }
}

Object.defineProperty(Vue.prototype, "$offline", {
  get(this: Vue) {
    return (this.$root.$options as any).$offline;
  },
});

declare module "vue/types/vue" {
  export interface Vue {
    $offline: OfflineManager;
  }
}

export default function (ctx: Context) {
  ctx.app.$offline = new OfflineManager(ctx.app.$feathers, ctx.store, ctx.$config.features?.offline);
}
