<template>
  <div>
    <div v-for="point in points" :key="point._id">
      <div class="text-h5">{{ $td(point.name) }}</div>
      <data-table
        :data="{
          path: 'userPoints',
          filter: {
            point: point._id,
            user: user,
          },
          sortBy: ['expiresAt'],
          sortDesc: [true],
          name: $t('userPoints.$'),
        }"
        :headers="[
          { text: $t('userPoint.expiresAt'), sortable: true, value: 'expiresAt', format: 'moment' },
          { text: $t('userPoint.totalIn'), sortable: true, value: 'totalIn' },
          { text: $t('userPoint.totalOut'), sortable: true, value: 'totalOut' },
          { text: $t('userPoint.amount'), sortable: true, value: 'amount' },
          { text: $t('userPoint.used'), sortable: true, value: 'used' },
        ]"
        actions
        no-remove
        no-clone
        no-edit
        no-add
        :default="{
          point,
          value: 0,
          user,
          message: 'by admin',
        }"
      >
        <template #post-actions="{ reload }">
          <b-btn :alt-text="$t('basic.add')" @click="addPoint(point, reload)">
            <v-icon>add</v-icon>
          </b-btn>
        </template>
        <template slot="editor" slot-scope="edit">
          <v-text-field :label="$t('point.value')" v-model.number="edit.item.amount" />
          <v-text-field :label="$t('point.message')" v-model="edit.item.message" />
        </template>
      </data-table>
      <data-table
        :data="{
          path: 'userPointLogs',
          filter: {
            point: point._id,
            user: user,
          },
          sortBy: ['date'],
          sortDesc: [true],
          name: $t('userPointLogs.$'),
          populate: ['session'],
        }"
        :headers="[
          { text: $t('userPointLog.date'), sortable: true, value: 'date', format: 'moment' },
          { text: $t('userPointLog.from'), sortable: true, value: 'from' },
          { text: $t('userPointLog.to'), sortable: true, value: 'to' },
          { text: $t('userPointLog.delta'), sortable: true, value: 'delta' },
          { text: $t('userPointLog.type'), sortable: true, value: 'type' },
          { text: $t('userPointLog.message'), sortable: true, value: 'message' },
        ]"
        itemCanClick
      >
        <template v-slot:expand="{ item: log }">
          <v-card flat v-if="log.session">
            <v-card-text>
              <!-- <order-manager :order="log.order" :addCancel="false" /> -->
            </v-card-text>
          </v-card>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import nuxtend from "@feathers-client/nuxtend";
import Auth from "~/mixins/auth";
import _ from "lodash";
// import OrderManager from "~/components/OrderManager";

export default nuxtend({
  components: {
    //OrderManager,
  },
  mixins: [Auth],
  props: {
    user: {
      required: true,
    },
    points: {
      required: true,
    },
  },
  methods: {
    async addPoint(point, reload) {
      const c = await this.$openDialog(
        import("~/components/dialogs/AddPointDialog.vue"),
        {
          point,
          user: this.user,
        },
        {
          maxWidth: "50vw",
        },
      );
      reload();
    },
  },
});
</script>
