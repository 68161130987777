
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { checkID, FindType, getID } from "@feathers-client";
import type { ProductType } from "~/plugins/shop";
import { StockLevel, getProductStockLevel, getStockLevel } from "@common/table/util";
import type { TableSession } from "~/plugins/table/session";

@Component
export default class OrderSystemProduct extends Vue {
  @Prop()
  product: ProductType;

  @Prop()
  session: TableSession;

  get productDisplayMode() {
    return this.$shop.localOptions.productDisplay;
  }

  get showProductShortName() {
    return !this.$shop.localOptions.onlyShowProductFullName;
  }

  get showProductImage() {
    return this.$shop.localOptions.showProductImage;
  }

  get showProductCode() {
    return this.$shop.localOptions.showProductCode;
  }

  get isOffline() {
    return this.$network.offlineActivated;
  }

  get showProductStock() {
    return this.$shop.localOptions.showProductStock;
  }

  get showProductPrice() {
    return this.$shop.localOptions.showProductPrice;
  }

  get stockBg() {
    if (this.status === StockLevel.Medium) {
      return "bg-red100";
    }
    return "bg-grey600";
  }

  get status() {
    if (this.product.timeConditions?.length) {
      if (!this.product.timeConditions.some(it => this.$shop.availableTimeConditionDict[getID(it)])) {
        return StockLevel.NotSelling;
      }
    }
    return getProductStockLevel(this.product, {
      optionsDict: this.$shop.productOptionDict,
      productsDict: this.$shop.productDict,
    });
  }

  get stockStatus() {
    switch (this.status) {
      case StockLevel.Medium:
        return "bg-yellow000";
      case StockLevel.Low:
        return "bg-red300";
    }
  }

  get isNotSelling() {
    switch (this.status) {
      case StockLevel.NotSelling:
      case StockLevel.OutOfStock:
        return true;
    }
    return false;
  }

  get stockLevel() {
    return getStockLevel(this.product.stock);
  }

  @Prop({ type: Boolean, default: false })
  shortMode: boolean;

  @Prop({ type: Boolean })
  readonly: boolean;

  get productShop() {
    if (!this.product?.discountMapping) return;
    if (this.product.discountMapping || this.product.discountMapping.length) {
      return null;
    }
    return this.product.discountMapping.find(it => checkID(it.shop, this.$store.state.user.shop));
  }

  activeModifiers<T extends ProductType["priceModifiers"][number] | ProductType["ingredientModifiers"][number]>(
    modifiers: T[],
  ) {
    return (
      modifiers?.filter?.(mod => {
        if (mod.shop && !checkID(mod.shop, this.session?.shopId)) {
          return false;
        }
        switch (mod.type) {
          case "modifier": {
            return !!this.session?.sessionData?.modifiers?.find?.(it => checkID(it, mod.modifier));
          }
          case "section": {
            return checkID(mod.section, this.session?.section);
          }
          case "timeCondition": {
            const activeTimeConditions = this.session?.availableTimeConditionDict;
            return !!activeTimeConditions[getID(mod.timeCondition)];
          }
          case "orderType": {
            return mod.orderTypes?.includes(this.session?.sessionData?.type);
          }
        }
      }) || []
    );
  }

  get actualPrice() {
    const activeModifiers = this.activeModifiers(this.product.priceModifiers);
    const sumPercent = _.sumBy(activeModifiers, m => m.percent ?? 0) || 0;
    return (
      ((this.productShop?.price ?? this.product.price ?? 0) + (_.sumBy(activeModifiers, m => m.price ?? 0) || 0)) *
      ((100 + sumPercent) / 100)
    );
  }

  get discount() {
    return this.productShop?.discount ?? 1;
  }

  get finalPrice() {
    return this.actualPrice * this.discount;
  }

  get thumb() {
    return this.product?.image && this.$thumb(this.product.image);
  }

  async openInfo() {
    if (Date.now() - this.lastPress < 50 || this.isOffline) return;
    this.lastPress = Date.now();
    await this.$openDialog(
      import("~/components/dialogs/ProductInfo.vue"),
      {
        product: this.product,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  lastPress = 0;

  async shortpress() {
    if (Date.now() - this.lastPress < 250) return;
    this.lastPress = Date.now();
    await Vue.nextTick();
    if (this.isNotSelling) {
      const stock = this.product.stock;
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title:
            stock?.mode === "notSelling"
              ? this.$t("tableView.confirmNotSelling", { name: this.$td(this.product.name) })
              : this.$t("tableView.confirmOutOfStock", { name: this.$td(this.product.name) }),
        },
        {
          maxWidth: "400px",
        },
      );
      if (!c) return;
    }
    this.$emit("addToCart", this.product);
  }

  async longpress() {
    if (Date.now() - this.lastPress < 250) return;
    this.lastPress = Date.now();
    await Vue.nextTick();
    this.$emit("addToCart", this.product, true);
  }

  get posMode() {
    switch (this.$shop.localOptions?.posMode ?? "auto") {
      case "auto":
        return this.$breakpoint.mdAndUp ? "tablet" : "mobile";
      case "tablet":
        return "tablet";
      case "mobile":
        return "mobile";
    }
  }
}
