
import _ from "lodash";
import { Component, mixins, Vue, FindType, Ref, Watch, Prop } from "@feathers-client";
import { format, fromNumber, fromHumanNumber } from "@feathers-client/currency";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { openCashBox } from "~/plugins/printer/invoiceSequencer";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class CashBoxInner extends Vue {
  @Prop()
  inOutType: "out" | "in";

  accessStaff = null;
  loading = true;

  // fetch({ store }) {
  //   store.commit("SET_TITLE", {
  //     title: { $t: "pos.cashBox.$" },
  //     tableMode: true,
  //     dark: true,
  //     fullPage: true,
  //   });
  // }

  // staffs: FindType<"staffs">[] = [];

  async cashbox() {
    const staff = await this.$shop.checkPermission(["withDrawDeposit/openCashBox"]);
    if (staff === false) return;
    await openCashBox(this, staff);
  }

  // getStaffName(id: string) {
  //   return this.staffs.find(it => it._id === id)?.name || "";
  // }

  // get logQuery() {
  //   return {
  //     shop: this.$shop.shopId,
  //     type: { $nin: ['void'] },
  //   };
  // }

  // get logSort() {
  //   return {
  //     date: -1,
  //   };
  // }

  @Watch("$shop.shopData.currentSession")
  onShopSession() {
    if (!this.$shop.shopData) return;
    if (!this.$shop.shopData.currentSession) {
      this.$store.commit("SET_ERROR", this.$t("pos.openClose.cashInOut.error.sessionClosed"));
      // this.$router.replace("/shops/openClose");
      this.$emit("backToFirstTab");
    }
  }

  get smAndDown() {
    return this.$breakpoint.smAndDown;
  }

  async mounted() {
    this.onShopSession();

    // const staff = await this.$shop.checkPermission(["withDrawDeposit/viewAndEdit"], true);

    // if (staff === false) this.$router.back();

    // this.staff = staff;

    await this.reset();
    // this.staffs = await this.$feathers.service("staffs").find({
    //   query: {
    //     shop: this.$shop.shopId,
    //     $paginate: false,
    //   },
    //   paginate: false,
    // });
    this.updateToggle();

    this.reloadInterval = setInterval(() => {
      this.reloadCounter--;
      if (this.reloadCounter <= 0) {
        this.reloadCounter = 60;
        this.reset();
      }
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.reloadInterval);
  }

  // async authenicate() {
  //   this.accessStaff = await this.$shop.checkPermission(["withDrawDeposit/viewAndEdit"], true);
  // }
  async setVoidCashPaymentAmt(date: Date) {
    const voidCashPayments = await this.$feathers.service("orderPaymentSummary").create({
      filter: {
        paidTime: {
          $lt: date,
        },
        refundTime: {
          $exists: true,
          $gt: date,
        },
        status: "refunded",
        shop: this.$shop.shopId,
        methodType: "cash",
      },
    });
    this.voidCashPaymentAmt = _.sumBy(voidCashPayments, (s: any) => s.voidAmount) ?? 0;
  }

  async getExpectedBalanceInt() {
    return (await this.$feathers.service("cashboxLogs/expectedAmount").create({})).amount;
  }

  async reset() {
    // this.staff = this.$pos.staff;
    this.loading = true;

    this.expectedBalanceInt = await this.getExpectedBalanceInt();
    this.reloadCounter = 60;

    this.currentCheck = (
      await this.$feathers.service("cashboxLogs").find({
        query: {
          shop: this.$shop.shopId,
          type: { $nin: ["void"] },
          isBuffer: { $nin: [true] },
          $paginate: false,
          $limit: 1,
          $sort: { date: -1 },
        },
        paginate: false,
      })
    )[0];

    // 1-2. get current session
    this.currentSession = (
      await this.$feathers.service("shopSessions").find({
        query: {
          shop: this.$shop.shopId,
          active: true,
          $paginate: false,
          $limit: 1,
          $sort: { startTime: -1 },
        },
        paginate: false,
      })
    )[0];

    if (this.currentSession) {
      if (!this.$network.offlineActivated) {
        // 2-1-1. get payment summary by session
        const _cashSummary = await this.$feathers.service("orderPaymentSummary").create({
          startDate: this.currentSession.startTime,
          endDate: new Date(),
          filter: {
            methodType: "cash",
            shop: this.$shop.shopId,
          },
          timeField: "paidTime",
        });

        const _allSummary = await this.$feathers.service("orderPaymentSummary").create({
          startDate: this.currentSession.startTime,
          endDate: new Date(),
          filter: {
            shop: this.$shop.shopId,
          },
          timeField: "paidTime",
        });

        this.cashSummary = {
          paids: {
            amountInt: _.sumBy(_cashSummary, (s: any) => s.amount) ?? 0,
            total: _.sumBy(_cashSummary, (s: any) => s.count) ?? 0,
          },
          refunds: {
            amountInt: _.sumBy(_cashSummary, (s: any) => s.voidAmount) ?? 0,
            total: _.sumBy(_cashSummary, (s: any) => s.voidCount) ?? 0,
          },
        };

        this.allSummary = {
          paids: {
            amountInt: _.sumBy(_allSummary, (s: any) => s.amount + s.voidAmount),
            total: _.sumBy(_allSummary, (s: any) => s.count + s.voidCount),
          },
          refunds: {
            amountInt: _.sumBy(_allSummary, (s: any) => s.voidAmount),
            total: _.sumBy(_allSummary, (s: any) => s.voidCount),
          },
        };

        // 2-1-2. get payment session from last check
        if (
          this.currentCheck &&
          this.currentCheck.session === this.currentSession._id &&
          this.currentCheck.type !== "beginCheck"
        ) {
          const _currentCashSessionSummary = await this.$feathers.service("orderPaymentSummary").create({
            startDate: this.currentCheck.date,
            endDate: new Date(),
            filter: {
              methodType: "cash",
              shop: this.$shop.shopId,
            },
            timeField: "paidTime",
          });

          const _currentAllSessionSummary = await this.$feathers.service("orderPaymentSummary").create({
            startDate: this.currentCheck.date,
            endDate: new Date(),
            filter: {
              shop: this.$shop.shopId,
            },
            timeField: "paidTime",
          });

          this.currentCashSessionSummary = {
            paids: {
              amountInt: _.sumBy(_currentCashSessionSummary, (s: any) => s.amount) ?? 0,
              total: _.sumBy(_currentCashSessionSummary, (s: any) => s.count) ?? 0,
            },
            refunds: {
              amountInt: _.sumBy(_currentCashSessionSummary, (s: any) => s.voidAmount) ?? 0,
              total: _.sumBy(_currentCashSessionSummary, (s: any) => s.voidCount) ?? 0,
            },
          };

          this.currentAllSessionSummary = {
            paids: {
              amountInt: _.sumBy(_currentAllSessionSummary, (s: any) => s.amount),
              total: _.sumBy(_currentAllSessionSummary, (s: any) => s.count),
            },
            refunds: {
              amountInt: _.sumBy(_currentAllSessionSummary, (s: any) => s.voidAmount),
              total: _.sumBy(_currentAllSessionSummary, (s: any) => s.voidCount),
            },
          };
        } else {
          this.currentCashSessionSummary = null;
          this.currentAllSessionSummary = null;
        }
      }
    }
    this.loading = false;
  }

  voidCashPaymentAmt: number = 0; // this is amount of payment created before middle / end check but void after that

  currentSession: FindType<"shopSessions"> = null;
  currentCheck: FindType<"cashboxLogs"> = null;
  lastSession: FindType<"shopSessions"> = null;
  cashSummary = null;
  allSummary = null;
  currentCashSessionSummary = null;
  currentAllSessionSummary = null;

  // type: "in" | "out" = "out";
  get type() {
    return this.inOutType ?? "in";
  }
  openAmountNum: number = 0;
  openAmount = "";
  openAmountClear = true;
  reason = "bank";
  comment = "";
  staff: FindType<"staffs"> = null;

  historyDialog: boolean = false;

  get reasons() {
    return [
      {
        _id: "bank",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.bank" },
      },
      {
        _id: "misc",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.misc" },
      },
      {
        _id: "unknown",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.unknown" },
      },
      {
        _id: "loss",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.loss" },
      },
      {
        _id: "others",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.others" },
      },
    ];
  }

  get expectedDeltaInt() {
    if (this.currentCashSessionSummary) {
      return fromNumber(
        (this.currentCashSessionSummary?.paids?.amountInt ?? 0) -
          (this.currentCashSessionSummary?.refunds?.amountInt ?? 0),
        this.$shop.currency,
      ).amount;
    } else {
      return fromNumber(
        (this.cashSummary?.paids?.amountInt ?? 0) - (this.cashSummary?.refunds?.amountInt ?? 0),
        this.$shop.currency,
      ).amount;
    }
    return 0;
  }

  expectedBalanceInt = 0;

  get currentCashDelta() {
    return this.expectedBalanceInt - (this.currentCheck?.amountInt ?? 0);
  }

  @Watch("expectedBalanceInt")
  onExpectedBalanceIntChange() {
    console.log("expectedBalanceInt", this.expectedBalanceInt);
    console.log("expectedDeltaInt", this.expectedDeltaInt);
    console.log(this.currentCashSessionSummary);
    console.log(this.cashSummary);
  }

  get openAmountNumInt() {
    if (this.openAmount && !isNaN(+this.openAmount)) {
      return fromNumber(+this.openAmount, this.$shop.currency).amount;
    } else if (this.openAmountClear) {
      return 0;
    } else {
      return fromNumber(this.openAmountNum, this.$shop.currency).amount;
    }
  }

  get newBalanceInt() {
    return (this.expectedBalanceInt ?? 0) + this.openAmountNumInt * (this.type === "in" ? 1 : -1);
  }

  get balanceInvalid() {
    return this.openAmountNumInt <= 0 || (this.type === "out" && this.newBalanceInt < 0);
  }

  get priceStr() {
    if (this.openAmount) {
      const parts = this.openAmount.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.openAmountNumInt);
    }
  }

  doAction(action: number | string) {
    if (action === "C" || this.openAmount === "") {
      this.resetOpenAmount();
      this.openAmountClear = true;
    } else {
      this.openAmountClear = false;
    }
  }

  resetOpenAmount() {
    this.openAmount = "";
    this.openAmountNum = 0;
    this.reason = "bank";
    this.comment = "";
  }

  // @Ref()
  // log: any;

  transiting = false;

  reloadInterval = null;
  reloadCounter = 60;

  async confirmOpen() {
    if (this.transiting) return;

    this.transiting = true;

    if (this.currentSession && this.currentCashDelta != 0) {
      await this.$feathers.service("cashboxLogs/action").create({
        type: "middleCheck",
        shop: this.$shop.shopId,
        session: this.currentSession?._id,
        staff: this.staff?._id,
        // amountInt: this.expectedBalanceInt,
        // deltaInt: this.expectedDeltaInt,
      });
    }

    const cashbox = await this.$feathers.service("cashboxLogs/action").create({
      type: this.type,
      shop: this.$shop.shopId,
      session: this.currentSession?._id,
      staff: this.staff?._id,
      amountInt: this.openAmountNumInt * (this.type === "in" ? 1 : -1),
      // deltaInt: this.openAmountNumInt * (this.type === "in" ? 1 : -1),
      reason: this.reason,
      comment: this.comment,
      isBuffer: this.currentSession ? false : true,
    });

    await this.$feathers.service("actionLogs").create({
      staff: this.staff?._id,
      type: `withDrawDeposit/${this.type === "in" ? "deposit" : "withdraw"}`,
      detail: { cashbox },
    });

    await this.reset();
    this.resetOpenAmount();
    // this.log.reload();

    // commit success

    this.transiting = false;
  }

  @Watch("smAndDown")
  updateToggle() {
    if (this.smAndDown) {
      this.historyDialog = false;
    } else {
      this.historyDialog = true;
    }
  }
}
