
import { Component, Prop, Vue, FindType } from '@feathers-client';
import EditorObjectPicker from '@schemaEditor/EditorObjectPicker.vue';

@Component({
    components: {
        EditorObjectPicker,
    }
})
export default class ShopCategoryPicker extends Vue {
    @Prop()
    label : string;

    @Prop({ type: Boolean })
    outlined : boolean;

    @Prop()
    value : FindType<'shopSearches'>['categories'];

    get categories() {
        return this.value;
    }

    set categories(v) {
        if (!v) v = [];
        this.$emit('input', v);
    }
}
