
import { Vue, Component, Prop, Model, Watch } from "@feathers-client";
import _ from "lodash";

@Component
export default class TakeawayFilter extends Vue {
  @Model()
  item: any;

  @Prop()
  takeawayOrders: any;

  get type() {
    return this.item.type;
  }

  get color() {
    return this.item.color;
  }

  get active() {
    return this.item.active;
  }

  get count() {
    return _.filter(this.takeawayOrders, order => order.takeawayStatus === this.type).length;
  }

  onClick() {
    this.item.active = !this.item.active;
  }
}
