import { ShopManager } from "@server/shop/shop";
import moment from "moment";
import crypto from "crypto";
import { MHookContext, FindType } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import qr from "qrcode";
import { OfflineManager } from "../..";
import { Component, Vue } from "@feathers-client";
import { translate } from "~/common/table/util";

export interface AssignReq {
  user_id: string;
  login_guid: string;
  assign_eui_data: {
    hash_key?: string;
    center_no: string;
    einv_ym: string;
    use_no: string;
    get_roll: string;
  };
}

export interface AssignRes {
  rcrm: RCRM;
  results: {
    assign_einv_date: {
      center_no: string;
      use_no: string;
      "einv_ym ": string;
      einv_word: string;
      einv_begin_no: string;
      einv_end_no: string;
      einv_rolls: number;
    }[];
  };
}

export interface UploadReq {
  user_id: string; //"A001",
  login_guid: string; // "284e25e0d87dcab9a518b897fa54000e",
  sell_count: {
    all_count: number;
  };
  sell_detail: UploadSellData[];
}

export interface UploadSellData {
  hash_key?: string; //"104026fd079391e7dd6331a2d78eb36be7090b5794ed078562d63e464206124";
  site_id: string; //"0001";
  sell_order_no: string; //"000001";
  sell_no?: string; //"000001";
  sell_day: string; //"2016/01/01";
  inv_day?: string; //"2016/01/01";
  sell_posid?: string; //"";
  inv_time?: string; //"12:00:00";
  sell_transtype: string; //"01";
  sell_type: string; //"1";
  sell_invoice: string; //"AB12345678";
  total_qty: number; //7;
  disc_amt: number; //100;
  sale_tax_amt: number; //600;
  rate_amt: number; //28;
  free_tax_amt: number; //0;
  zero_tax_amt: number; //0;
  total_sales_amt: number; //600;
  comp_id?: string; //"";
  vip_no?: string; //"";
  org_sell_posid?: string; //"";
  org_sell_day?: string; //"";
  org_sell_no?: string; //"";
  org_inv_day?: string; //"";
  sell_rtn_type?: string; //"";
  sell_rtn_no?: string; //"";
  eui_random_code?: string; //"1234";
  eui_print?: string; //"1";
  eui_print_trans?: string; //"1";
  eui_donate_no?: string; //"";
  eui_donate_status?: string; //"";
  eui_universal_status?: string; //"";
  eui_vehicle_type_no?: string; //"";
  eui_vehicle_no?: string; //"";
  eui_vehicle_no_hidden?: string; //"";
  nrt_cardno?: string; //"";
  gui_print_flag?: number; //1;
  goods_detail: UploadGoodsData[];
  tender_detail: UploadTendarData[];
  consumer_detail?: {
    consumer_name: string; //"王大明";
    consumer_addr: string; //"XX 市 XX 區 XX 路 XX 號 XX 樓";
    consumer_mobile_no: string; //"0912123123";
    consumer_email: string; //"trun2cloud@trun2cloud.com.tw";
  };
}

export interface UploadGoodsData {
  goods_no: number; //1;
  goods_item_no?: string; //"1234567890";
  goods_name: string; //"測試";
  goods_ori_price: number; //100;
  goods_qty: number; //4;
  goods_discount: number; //100;
  goods_discount_type: string; //"1";
  goods_price: number; //75;
  goods_rate: string; //"1";
  goods_rate_amt: number; //14;
  goods_amt: number; //300;
}
export interface UploadTendarData {
  tender_name: string; //"信用卡";
  tender_price: number; //600;
  sec_no?: string; //"1234567890";
  invoice_flag?: string; //"N";
}
export interface UploadRes {
  rcrm: RCRM;
  results: {
    sell_count: {
      all_count: number;
      success_count: number;
      fail_count: number;
    };
    sell_eui_datas: {
      rcrm: {
        RC: number | string;
        RM: string;
      };
      sell_eui_data: {
        site_id: string; //"0001";
        sell_order_no: string; //"TC201601010001";
        sell_no: string; //"000001";
        inv_day: string; //"2016/01/01";
        inv_time: string; //"131001";
        sell_invoice: string; //"AA12344000";
        eui_random_code: string; //"1234";
      };
    }[];
  };
}

export interface ClearReq {
  user_id: string; //"A001";
  login_guid: string; //"284e25e0d87dcab9a518b897fa54000e";
  hash_key?: string;
  site_id: string; //"0001";
  tdate: string; //"2016/01/01";
  sell_day: string; //"2016/01/01";
  clear_pos: {
    title: string;
    sales_qty: number;
    sales_amt: number;
  }[];
}

export interface ClearRes {
  rcrm: RCRM;
  results: {};
}

export interface RCRM {
  RC: number | string;
  RM: string;
}

@Component
export class TurnCloudManager extends Vue {
  get config(): TurnCloudConfig {
    return this.$shop.shopOptions?.turnCloudConfig ?? ({} as any);
  }

  async assign(hook: MHookContext<AdminApplication, any>, session: FindType<"tableSessions", AdminApplication>) {
    const offline: OfflineManager = (hook as any).$offline;

    if (!this.config?.enabled) return session;
    const taxAmount = session.totalExtTax + session.totalIncTax;

    if (session.totalExtTax) {
      console.warn("external tax detected", session._id);
    }

    const maxTaxable =
      _.max((session.taxes || []).filter(it => !it.disabled && it.percent > 0).map(it => it.accum)) ?? 0;

    const zeroTaxAmount =
      _.max((session.taxes || []).filter(it => !it.disabled && it.percent === 0).map(it => it.accum)) ?? 0;

    const freeTaxAmount = (session.taxFree?.productAmount ?? 0) + (session.taxFree?.surchargeAmount ?? 0);

    const totalAmount = maxTaxable + freeTaxAmount + zeroTaxAmount;

    const discountDict: Record<string, number> = {};

    const adjustsDict = Object.fromEntries((session.lineAdjusts || []).map(it => [it.id, it.amount]));

    // const surchargeAmount = Math.round(100 * session.sumOfSurcharges) / 100;

    const surchargeTaxableAmount = Math.round(
      (session.taxes || []).filter(it => !it.disabled && it.percent > 0).reduce((a, b) => a + b.surchargeAccum, 0) || 0,
    );
    const surchargeTax = Math.round(
      (session.taxes || [])
        .filter(it => !it.disabled && it.percent > 0)
        .reduce((a, b) => a + b.surchargeTaxAmount, 0) || 0,
    );

    const surchargeTaxFreeAmount = session.taxFree?.surchargeAmount || 0;

    const surchargeZeroTaxAmount = Math.round(
      (session.taxes || []).filter(it => !it.disabled && it.percent === 0).reduce((a, b) => a + b.surchargeAccum, 0) ||
        0,
    );

    let fullSurchargeDiscount = -(session.lineAdjusts || [])
      .filter(it => it.id.startsWith("surcharge"))
      .reduce((a, b) => a + b.amount, 0);

    let discountAmount = -Math.round(100 * (session.totalDiscounts + session.adjusts)) / 100;

    const tipsAmount = Math.round(100 * (session.tips || 0)) / 100;
    let tipsTax = 0;
    let hasTipsTax = false;
    for (let tax of (session.taxes || []).filter(it => !it.disabled && it.percent > 0)) {
      const line = (tax.lines || []).find(it => it.id.startsWith("tips"));
      if (line) {
        tipsTax += line.amount;
        hasTipsTax = true;
      }
    }
    tipsTax = Math.round(tipsTax);

    let tipsDiscount = -(session.lineAdjusts || [])
      .filter(it => it.id.startsWith("tips"))
      .reduce((a, b) => a + b.amount, 0);

    for (let discount of session.discounts) {
      if (discount.productsValue) {
        for (let it of discount.productsValue) {
          discountDict[it.id] = (discountDict[it.id] || 0) + it.discountValue;
        }
      }
    }

    for (let [id, amount] of Object.entries(discountDict)) {
      if (id.startsWith("surcharge")) {
        fullSurchargeDiscount += -amount;
      } else if (id.startsWith("tips")) {
        tipsDiscount += -amount;
      }
    }

    const surchargeTaxableDiscount =
      fullSurchargeDiscount && surchargeTaxableAmount + surchargeTaxFreeAmount + surchargeZeroTaxAmount
        ? Math.round(
            (surchargeTaxableAmount / (surchargeTaxableAmount + surchargeTaxFreeAmount + surchargeZeroTaxAmount)) *
              fullSurchargeDiscount,
          )
        : 0;
    const surchargeZeroTaxDiscount =
      fullSurchargeDiscount - surchargeTaxableDiscount && surchargeTaxFreeAmount + surchargeZeroTaxAmount
        ? Math.round(
            (surchargeZeroTaxAmount / (surchargeTaxFreeAmount + surchargeZeroTaxAmount)) *
              (fullSurchargeDiscount - surchargeTaxableDiscount),
          )
        : 0;
    const surchargeTaxFreeDiscount = fullSurchargeDiscount - surchargeTaxableDiscount - surchargeZeroTaxDiscount;

    if (session.twInvoice) {
      const currentInvoice = await hook.app.service("twInvoices").get(session.twInvoice);

      let taxTypeChanged = false;
      if ((currentInvoice.vehicalNo || "") !== (session.twTaxVehicle || "")) taxTypeChanged = true;
      if ((currentInvoice.compId || "") !== (session.twTaxComp || "")) taxTypeChanged = true;
      if ((currentInvoice.nrtCardNo || "") !== (session.twTaxNrt || "")) taxTypeChanged = true;
      if ((currentInvoice.donateNo || "") !== (session.twDonate || "")) taxTypeChanged = true;

      let paymentChanged = false;

      const sessionPayments = session.payments || [];
      const invoicePayments = currentInvoice.payments || [];

      if (sessionPayments.length !== invoicePayments.length) {
        paymentChanged = true;
      } else {
        for (let i = 0; i < sessionPayments.length; i++) {
          if (sessionPayments[i].amount !== invoicePayments[i].amount) {
            paymentChanged = true;
            break;
          }
        }
      }

      if (
        currentInvoice.totalAmount !== totalAmount ||
        currentInvoice.taxableAmount !== maxTaxable ||
        currentInvoice.freeTaxAmount !== freeTaxAmount ||
        currentInvoice.zeroTaxAmount !== zeroTaxAmount ||
        taxTypeChanged ||
        paymentChanged
      ) {
        // tax amount changed
        session = await this.cancel(hook, session);
      }
    }

    if (!session.amount) return session;
    if (!session.twInvoice) {
      const transactionDate = moment(session.paidTime || session.endTime || session.startTime || session.date);
      const year = transactionDate.format("YYYY");
      const month = ((((transactionDate.get("month") + 2) / 2) | 0) * 2).toString().padStart(2, "0");
      const yearMonth = `${year}${month}`;

      let activeRoll = (
        await hook.app.service("twInvoiceRolls").find({
          query: {
            shop: offline.root.$shop.shopId,
            status: "offlineActive",
            yearMonth,
            $sort: {
              shop: 1,
              yearMonth: 1,
              status: 1,
              word: 1,
              beginNo: 1,
            },
            $limit: 1,
            $paginate: false,
          },
          paginate: false,
        })
      )[0];

      if (activeRoll) {
        activeRoll = await hook.app.service("twInvoiceRolls").patch(activeRoll._id, {
          serial: activeRoll.serial + 1,
        });
        if (activeRoll.serial >= activeRoll.count) {
          // roll finished
          await hook.app.service("twInvoiceRolls").patch(activeRoll._id, {
            status: "used",
          });
        }
      } else {
        // assign new roll
        activeRoll = (
          await hook.app.service("twInvoiceRolls").find({
            query: {
              shop: offline.root.$shop.shopId,
              status: "assigned",
              yearMonth,
              $sort: {
                shop: 1,
                yearMonth: 1,
                status: 1,
                word: 1,
                beginNo: 1,
              },
              $limit: 1,
              $paginate: false,
            },
            paginate: false,
          })
        )[0];
        if (activeRoll) {
          activeRoll = await hook.app.service("twInvoiceRolls").patch(activeRoll._id, {
            status: "offlineActive",
            serial: activeRoll.serial + 1,
          });
        }
      }

      if (!activeRoll) {
        // no roll
        throw new Error("Tax invoice roll exhausted");
      }

      const invoiceNo = (activeRoll.beginNo + activeRoll.serial - 1).toString().padStart(8, "0");

      const taiwanYear = (transactionDate.get("year") - 1911).toString().padStart(3, "0");

      const createData: Partial<FindType<"twInvoices", AdminApplication>> = {
        yearMonth,
        invoiceMonth: (+month - 1).toString().padStart(2, "0"),
        invoiceMonthEnd: month,
        taiwanYear,
        month: transactionDate.format("MM"),
        day: transactionDate.format("DD"),
        word: activeRoll.word,
        invoiceNo,
        fullInvoiceNo: `${activeRoll.word}${invoiceNo}`,
        totalQuantity: session.totalQuantity,
        discountAmount,
        taxableAmount: maxTaxable,
        taxAmount: taxAmount,
        freeTaxAmount: freeTaxAmount,
        zeroTaxAmount: zeroTaxAmount,
        totalAmount,
        session: session._id,
        sessionName: session.sessionName,
        goods: [],
        payments: [],
        merchantName: this.config.merchantName || translate(offline.root.$shop.shopData.name, "cht"),
        centerNo: this.config.centerNo,
        randomCode: Math.floor(Math.random() * 10000)
          .toString()
          .padStart(4, "0"),
        shop: session.shop,
        date: transactionDate.toDate(),
        invDate: transactionDate.format("YYYY/MM/DD"),
        invTime: transactionDate.format("HH:mm:ss"),
        vehicalNo: null,
        compId: null,
        nrtCardNo: null,
        donateNo: null,
        siteId: this.config.siteId,
        cashierId: hook.params.connection?.posDevice?.shortId ?? "",
      };

      if (session.twTaxType === "electronic") {
        if (session.twTaxVehicle) createData.vehicalNo = session.twTaxVehicle;
        if (/^\/[0-9A-Z\.\-\+]{7}$/.test(createData.vehicalNo)) {
          createData.vehicalType = "3J0002";
        } else if (/^[A-Z]{2}[0-9]{14}$/.test(createData.vehicalNo)) {
          createData.vehicalType = "CQ0001";
        }
      } else if (session.twTaxType === "company" && session.twTaxComp) {
        createData.compId = session.twTaxComp;
      } else if (session.twTaxType === "nrt" && session.twTaxNrt) {
        createData.nrtCardNo = session.twTaxNrt;
      } else if (session.twTaxType === "donate" && session.twDonate) {
        createData.donateNo = session.twDonate;
      }

      let totalGoods = 0;
      let extraDiscounts = 0;
      for (let item of session.products) {
        if (item.status === "cancel") continue;
        const totalTax = (session.taxes || [])
          .filter(it => !it.disabled && it.percent > 0)
          .map(tax => {
            const line = (tax.lines || []).find(it => it.id === item.id);
            if (!line) return undefined;
            return (line?.amount || 0) - (line?.surcharge || 0);
          })
          .filter(it => it !== undefined);

        const hasTax = totalTax.length > 0;
        const sumTax = Math.round(totalTax.reduce((a, b) => a + b, 0));

        let originalPrice =
          (item.fromProduct || item.fromCoupon ? 0 : item.actualPrice || 0) +
          (item.extraFee || 0) +
          (item.extraFeeEnjoyDiscount || 0);
        let itemDiscount = originalPrice * item.quantity - item.price * item.quantity;
        if (itemDiscount < 0) {
          originalPrice = item.price;
          itemDiscount = 0;
        }

        extraDiscounts += itemDiscount;

        const subTotal = item.price * item.quantity;
        totalGoods += subTotal;

        let curTotal = subTotal;

        if (adjustsDict[item.id]) {
          curTotal += adjustsDict[item.id];
        }

        if (discountDict[item.id]) {
          curTotal += discountDict[item.id];
        }

        let discount = subTotal - curTotal;
        if (curTotal > subTotal) {
          originalPrice = Math.round(curTotal / item.quantity);
          discount = 0;
          extraDiscounts -= discount;
        }
        discount += itemDiscount;

        createData.goods.push({
          name: translate(item.name, "cht"),
          originalPrice,
          quantity: item.quantity,
          discount,
          price: Math.round((curTotal / item.quantity) * 100) / 100,
          taxable: hasTax,
          taxAmount: sumTax,
          taxableAmount: hasTax ? curTotal : 0,
          finalAmount: curTotal,
          itemDiscount: itemDiscount,
        } as Partial<FindType<"twInvoices", AdminApplication>["goods"][0]> as any);
      }

      if (surchargeTaxableAmount) {
        totalGoods += surchargeTaxableAmount;
        createData.totalQuantity++;
        createData.goods.push({
          name: "服務費",
          originalPrice: surchargeTaxableAmount,
          quantity: 1,
          discount: surchargeTaxableDiscount,
          price: surchargeTaxableAmount - surchargeTaxableDiscount,
          taxable: true,
          taxAmount: surchargeTax,
          taxableAmount: surchargeTaxableAmount - surchargeTaxableDiscount,
          finalAmount: surchargeTaxableAmount - surchargeTaxableDiscount,
          itemDiscount: 0,
        } as Partial<FindType<"twInvoices", AdminApplication>["goods"][0]> as any);
      }

      if (surchargeTaxFreeAmount) {
        totalGoods += surchargeTaxFreeAmount;
        createData.totalQuantity++;
        createData.goods.push({
          name: "服務費 (免稅)",
          originalPrice: surchargeTaxFreeAmount,
          quantity: 1,
          discount: surchargeTaxFreeDiscount,
          price: surchargeTaxFreeAmount - surchargeTaxFreeDiscount,
          taxable: false,
          taxAmount: 0,
          taxableAmount: 0,
          finalAmount: surchargeTaxFreeAmount - surchargeTaxFreeDiscount,
          itemDiscount: 0,
        } as Partial<FindType<"twInvoices", AdminApplication>["goods"][0]> as any);
      }

      if (surchargeZeroTaxAmount) {
        totalGoods += surchargeZeroTaxAmount;
        createData.totalQuantity++;
        createData.goods.push({
          name: "服務費 (零稅率)",
          originalPrice: surchargeZeroTaxAmount,
          quantity: 1,
          discount: surchargeZeroTaxDiscount,
          price: surchargeZeroTaxAmount - surchargeZeroTaxDiscount,
          taxable: false,
          taxAmount: 0,
          taxableAmount: 0,
          finalAmount: surchargeZeroTaxAmount - surchargeZeroTaxDiscount,
          itemDiscount: 0,
        } as Partial<FindType<"twInvoices", AdminApplication>["goods"][0]> as any);
      }

      // assert
      let checkDiscount = 0;
      for (let item of createData.goods) {
        if (item.originalPrice * item.quantity - item.discount !== item.finalAmount) {
          console.warn("discount not match", item);
        }
        checkDiscount += item.discount - item.itemDiscount;
      }
      if (checkDiscount !== discountAmount) {
        console.warn("discount not match", checkDiscount, discountAmount);
      }
      if (totalGoods - checkDiscount !== createData.totalAmount) {
        console.warn("totalGoods not match", totalGoods, checkDiscount, createData.totalAmount);
      }

      const computedDiscount = Math.round((totalGoods - totalAmount) * 100) / 100;
      if (computedDiscount !== discountAmount) {
        createData.discountAmount = computedDiscount;
        console.warn("discount not match", computedDiscount, discountAmount);
      }
      createData.discountAmount += extraDiscounts;

      createData.discountTaxFreeAmount = _.sumBy(createData.goods, it => (it.taxable ? 0 : it.discount));
      createData.discountTaxableAmount = _.sumBy(createData.goods, it => (it.taxable ? it.discount : 0));

      if (createData.discountTaxFreeAmount + createData.discountTaxableAmount !== createData.discountAmount) {
        console.warn(
          "discount not match",
          createData.discountTaxFreeAmount,
          createData.discountTaxableAmount,
          discountAmount,
        );
      }

      for (let item of session.payments || []) {
        const paymentMethod = (
          await hook.app.service("paymentMethods").find({
            query: {
              _id: item.paymentMethod,
              $paginate: false,
            },
            paginate: false,
          })
        )[0];
        createData.payments.push({
          name: (translate(paymentMethod?.name, "cht") || "").slice(0, 30),
          amount: item.amount,
        } as Partial<FindType<"twInvoices", AdminApplication>["payments"][0]> as any);
      }

      const { left, right } = encodeTaiwanInvoice(createData, this.config);
      createData.leftQrCode = left;
      createData.rightQrCode = right;
      createData.barCode = `${taiwanYear}${createData.invoiceMonthEnd}${createData.fullInvoiceNo}${createData.randomCode}`;

      const invoice = await hook.app.service("twInvoices").create(createData);

      return await hook.app.service("tableSessions").patch(session._id, {
        twInvoice: invoice._id,
        ...(!session.twTaxType ? { twTaxType: "paper" } : {}),
      });
    }
    return session;
  }

  async cancel(hook: MHookContext<any, any>, session: FindType<"tableSessions", AdminApplication>) {
    if (!this.config?.enabled) return session;
    const offline: OfflineManager = (hook as any).$offline;
    if (session.twInvoice) {
      const current = await hook.app.service("twInvoices").get(session.twInvoice);
      if (current.status === "pending") {
        const resp = await hook.app.service("twInvoices").patch(session.twInvoice, {
          status: "cancelled",
          refundStatus: "pending",
          refundCashierId: offline.root.$shop?.device?._id ?? "",
          refundDate: new Date(),
          refundRtn: current.yearMonth !== getYearMonth(),
        });
      } else if (current.status === "uploaded") {
        const resp = await hook.app.service("twInvoices").patch(session.twInvoice, {
          refundStatus: "pending",
          refundCashierId: offline.root.$shop?.device?._id ?? "",
          refundDate: new Date(),
        });
      }
      return await hook.app.service("tableSessions").patch(session._id, {
        twInvoice: null,
      });
    }
    return session;
  }
}

function getYearMonth() {
  const transactionDate = moment();
  const year = transactionDate.format("YYYY");
  const month = ((((transactionDate.get("month") + 2) / 2) | 0) * 2).toString().padStart(2, "0");
  const yearMonth = `${year}${month}`;
  return yearMonth;
}

function seedToAes(seed: string) {
  const sha1 = crypto.createHash("sha1");
  sha1.update(seed);
  sha1.update(Buffer.from("Nd8NUYIxHIBxlzExqGM9gA==", "base64"));
  const hash = sha1.digest();

  const firstSha1 = crypto.createHash("sha1");
  firstSha1.update(hash);
  const hash2 = firstSha1.digest();

  const finalSha1 = crypto.createHash("sha1");
  finalSha1.update(hash2);
  const aesKey = finalSha1.digest("hex").slice(0, 32);

  return aesKey.toUpperCase();
}

function encodeAES(aesKey: string, data: string) {
  const cipher = crypto.createCipheriv(
    "aes-128-cbc",
    Buffer.from(aesKey, "hex"),
    Buffer.from("Dt8lyToo17X/XkXaQvihuA==", "base64"),
  );
  let encrypted = cipher.update(data, "utf8", "base64");
  encrypted += cipher.final("base64");
  return encrypted;
}

function encodeTaiwanInvoice(item: Partial<FindType<"twInvoices", AdminApplication>>, config: TurnCloudConfig) {
  const amountWithoutTax = Math.round(Math.max(0, Math.floor(item.totalAmount - item.taxAmount)));
  const formattedAmountWithoutTax = amountWithoutTax.toString(16).padStart(8, "0");
  const formattedAmount = Math.floor(item.totalAmount).toString(16).padStart(8, "0");

  const aesKey = config.aesKey || seedToAes(config.seed || "");
  const encoded = encodeAES(aesKey, `${item.word}${item.invoiceNo}${item.randomCode}`);

  const fixedCode = [
    item.word, // 2 char
    item.invoiceNo, // 8 char
    item.taiwanYear, // 3 char
    item.month, // 2 char
    item.day, // 2 char
    item.randomCode, // 4 char
    formattedAmountWithoutTax, // 8 char
    formattedAmount, // 8 char
    item.compId || "00000000", // 8 char
    item.centerNo, // 8 char
    encoded, // 24 char
  ].join(""); // 77 chars

  if (fixedCode.length !== 77) {
    throw new Error(`invalid code length: ${fixedCode.length}`);
  }

  function encodeTrial(data: string): boolean {
    try {
      qr.create(data, {
        version: 6,
        errorCorrectionLevel: "L",
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  function balanceCode(data: string) {
    let left = 77;
    let right = data.length;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      if (encodeTrial(data.slice(0, mid))) {
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    return [data.slice(0, left - 1), data.slice(left - 1)] as const;
  }

  function encodeQr(num: number) {
    const dynamicCode = [
      fixedCode,
      ":",
      (item.remarks || "").slice(0, 10).padEnd(10, "*"),
      ":",
      num,
      ":",
      item.goods?.length ?? 0,
      ":",
      1,
    ];
    for (let i = 0; i < num; i++) {
      dynamicCode.push(":");
      const name = (item.goods[i].name || "").replace(/\:/g, "").trim();
      dynamicCode.push(name);
      dynamicCode.push(":");
      dynamicCode.push(item.goods[i].quantity);
      dynamicCode.push(":");
      dynamicCode.push(Math.floor(item.goods[i].price));
    }
    const code = dynamicCode.join("");
    const [left, right] = balanceCode(code);
    if (!encodeTrial(left)) {
      throw new Error(`invalid code: ${left}`);
    }
    if (encodeTrial("**" + right)) {
      return [left, "**" + right] as const;
    }
  }

  function appendProducts() {
    let left = 0;
    let right = item.goods?.length ?? 0;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const res = encodeQr(mid);
      if (res) {
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    return encodeQr(left - 1);
  }

  const [left, right] = appendProducts();

  return {
    fixedCode,
    left,
    right,
  };
}

export interface TurnCloudConfig {
  merchantName?: string;
  userId: string;
  loginGuid: string;
  centerNo: string;
  siteId?: string;
  useNo: string;
  seed?: string;
  aesKey?: string;
  enabled?: boolean;
  requestThresold?: number;
  warningThresold?: number;
  periodEndingThresold?: number;
}
