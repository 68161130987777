
import { Component, Prop, Vue } from "@feathers-client";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class TableDatePicker extends Vue {
  isOpen = false;

  @Prop({ default: {} }) dateStart: Object;
  @Prop({ default: {} }) dateEnd: Object;
  @Prop(Boolean) dateTime: boolean;

  get dateStartSync() {
    return this.dateStart;
  }
  set dateStartSync(v) {
    this.$emit("update:dateStart", v);
  }

  get dateEndSync() {
    return this.dateEnd;
  }
  set dateEndSync(v) {
    this.$emit("update:dateEnd", v);
  }

  onClickOutside() {
    this.isOpen = false;
  }
}
