
import { Component, Vue, Prop, PropSync, Watch } from "@feathers-client";
import moment from "moment";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import PresetDatePicker from "./PresetDatePicker.vue";
import CustomTimeRangePicker from "./CustomTimeRangePicker.vue";

@Component({
  components: {
    EditorDatePicker,
    PresetDatePicker,
    CustomTimeRangePicker,
  },
})
export default class CustomPickerEditorMenu extends Vue {
  @Prop({ default: "" }) mode: string;
  @Prop({ default: {} }) dateStart: Date;
  @Prop({ default: {} }) dateEnd: Date;
  @Prop(Boolean) dateTime: boolean;

  dateStartSync: Date = null;
  dateEndSync: Date = null;

  loading = false;

  created() {
    this.resetFromData();
  }

  @Watch("dateStart")
  @Watch("dateEnd")
  @Watch("isOpen")
  resetFromData() {
    this.dateStartSync = this.dateStart;
    this.dateEndSync = this.dateEnd;
  }

  isOpen = false;

  reset() {
    this.loading = true;
    this.$emit("updateSetDate", "today");
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  confirmed() {
    this.loading = true;
    this.$emit("update:dateStart", this.dateStartSync);
    this.$emit("update:dateEnd", this.dateEndSync);
    this.isOpen = false;
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  updateSetDate(dateSet: string) {
    this.loading = true;
    this.$emit("updateSetDate", dateSet);
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  get dataMode() {
    const days = moment(this.dateEndSync).diff(this.dateStartSync, "days", true);
    return days > 31 ? "year" : days > 7 ? "month" : days > 1 ? "week" : "day";
  }

  navPrev() {
    const start = moment(this.dateStartSync).subtract(1, this.dataMode).toDate();
    const end = moment(this.dateEndSync).subtract(1, this.dataMode).toDate();
    this.dateStartSync = start;
    this.dateEndSync = end;
  }

  navNext() {
    const start = moment(this.dateStartSync).add(1, this.dataMode).toDate();
    const end = moment(this.dateEndSync).add(1, this.dataMode).toDate();
    this.dateStartSync = start;
    this.dateEndSync = end;
  }
  onClickOutside() {
    this.isOpen = false;
  }
}
