
import { Vue, Component, Prop, VModel } from "@feathers-client";

@Component
export default class BoxsAppContainer extends Vue {
  @Prop(Boolean)
  fullPage: boolean;

  @Prop(Boolean)
  padding: boolean;

  @Prop(Boolean)
  alwaysTop: boolean;
}
