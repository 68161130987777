
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import _ from "lodash";
import { ProductLine } from "~/plugins/table/session";
import moment from "moment";

@Component
export default class HoldTimer extends Vue {
  @Prop()
  product: ProductLine;

  holdRemain: string = "";

  beforeMount() {
    if (this.product.kdsHoldEnd) {
      this.$tableManager.$on("tick", this.tick);
    }
    this.tick();
  }

  beforeDestroy() {
    this.$tableManager.$off("tick", this.tick);
  }

  tick() {
    const newRemain = this.product.kdsHoldEnd
      ? Math.max(0, moment(this.product.kdsHoldEnd).diff(moment(), "minutes", false)).toString()
      : "";
    if (this.holdRemain !== newRemain) this.holdRemain = newRemain;
  }
}
