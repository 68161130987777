
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import moment from "moment";
import type VirtualDataList from "domore-table/VirtualDataList.vue";

const populate = [
  {
    path: "tables.item",
    select: ["name"],
    limit: 1,
    populate: [
      {
        path: "view",
        select: ["name"],
      },
    ],
  },
] as const;

@Component
export default class Reservation extends Vue {
  currentTab = "all";
  tabs = [
    {
      _id: "all",
      name: { $t: "tableView.reservations.all" },
    },
    {
      _id: "online",
      name: { $t: "tableView.reservations.online" },
    },
    {
      _id: "phone",
      name: { $t: "tableView.reservations.phone" },
    },
  ];

  @Ref()
  dataList: VirtualDataList;

  async add() {
    const resp = await this.$openDialog(
      import("~/components/table/reservation/addDialog.vue"),
      {},
      {
        maxWidth: "50vw",
        contentClass: "h-80vh",
      },
    );
    if (resp) {
      await this.dataList.insertMaybePopulate(resp);
    }
  }

  async history() {
    const resp = await this.$openDialog(
      import("~/components/table/reservation/historyDialog.vue"),
      {},
      {
        maxWidth: "50vw",
        contentClass: "h-80vh",
      },
    );
  }

  selectedDate = moment().startOf("day").toDate();

  get selectedDateStr() {
    return moment(this.selectedDate).format("YYYY-MM-DD");
  }

  set selectedDateStr(v) {
    if (v) {
      this.selectedDate = moment(v).toDate();
    } else {
      this.selectedDate = moment().startOf("day").toDate();
    }
  }

  get query() {
    return {
      status: "booking",
      bookedTime: {
        $gte: this.selectedDate,
        $lt: moment(this.selectedDate).add(1, "day").toDate(),
      },
      $sort: {
        bookedTime: 1,
      },
      $populate: populate,
      ...(this.currentTab === "all"
        ? {}
        : {
            bookingSource: this.currentTab,
          }),
    };
  }

  addOneDate() {
    this.selectedDate = moment(this.selectedDate).add(1, "day").toDate();
  }

  minusOneDate() {
    this.selectedDate = moment(this.selectedDate).subtract(1, "day").toDate();
  }

  @Ref()
  input: any;

  showPicker() {
    if ("showPicker" in HTMLInputElement.prototype) {
      this.input.$el.showPicker();
    }
  }
}
