
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "@feathers-client";
import _ from "lodash";

@Component
export default class MobilePageView extends Vue {
  @VModel()
  inputValue: any;

  @Prop()
  items: any[];

  @Prop({ type: Number, default: 2 })
  cols: number;

  @Prop({ type: Number, default: 2 })
  rows: number;

  get num() {
    return this.cols * this.rows;
  }

  catPage = 0;

  get empty() {
    return new Array(this.num).fill(null);
  }

  get itemsChunked() {
    return this.items ? _.chunk(this.items, this.num) : [];
  }

  get catPickerList() {
    return this.itemsChunked[this.catPage] || [];
  }

  navPage(d: number) {
    this.catPage = Math.min(this.itemsChunked.length - 1, Math.max(0, this.catPage + d));
  }
}
