<template>
  <v-app ref="app" class="w-full">
    <v-snackbar top v-model="error">
      {{ errorMessage }}
      <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
    </v-snackbar>

    <v-content v-if="!breakpoint.mdAndDown" style="height: 100%">
      <div class="flex h-full">
        <!-- <v-snackbar top v-model="error">
          {{ errorMessage }}
          <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar> -->
        <div class="h-full w-min-[465px] loginGradient grid content-between py-5 px-10">
          <div>
            <div class="flex justify-start items-center py-8">
              <template v-if="isDefaultName">
                <img class="w-50" :src="require('~/assets/images/boxsRestaurantWhite.png')" />
              </template>
              <template v-else>
                <img class="w-13.75" :src="$config.appLogo || require('~/assets/images/icon.png')" />
                <div class="pl-4 text-white font-normal text-5xl">{{ $config.appName }}</div>
              </template>
            </div>
            <div class="subtitle-profit">{{ $t("login.subtitle") }}</div>
          </div>
          <div class="grid gap-3">
            <div class="version-border flex justify-center align-center mb-5 p-2">
              <div>{{ $t("login.version") }}: {{ versionNumber }} ({{ buildNumber }})</div>
            </div>
            <v-list :class="langToggle == true ? '' : '!hidden'" class="language-box !p-0">
              <div class="grid px-3">
                <!-- <nuxt-link
                  v-for="(item, index) in $i18n.locales"
                  :key="item.code"
                  :value="$i18n.locale === item.code"
                  :to="switchLocalePath(item.code)"
                  class="!text-grey100 py-2 border-bottom"
                >
                  <span>{{ item.name }}</span>
                </nuxt-link> -->
                <div
                  v-for="item in $store.state.locales"
                  :key="item.code"
                  :value="$store.state.locale === item.code"
                  @click="$store.dispatch('changeLang', { code: item.code, $i18n })"
                  class="!text-grey100 py-2 border-bottom cursor-pointer"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </v-list>
            <a @click="langToggle = !langToggle" class="a-text block !text-white text-center">
              {{ $t("login.languages") }}</a
            >
            <a
              class="a-text block !text-white text-center"
              :href="$store.state.locale==='zh-cn'||$store.state.locale==='zh-hk'||$store.state.locale==='zh-tw'?'https://boxshk.notion.site/boxsStore-65b5f57caa614cdcb4fe3fb7021d25c5':'https://boxshk.notion.site/boxsStore-Help-Center-9c5294dec7e841579c3efb8a04804bf3'" target="_blank">
              {{ $t("login.helpCentre") }}</a
            >
          </div>
        </div>
        <div class="loginSide grid relative justify-center items-center">
          <nuxt></nuxt>
          <div class="absolute max-w-125 bottom-10 right-0 left-0 m-auto">
            <a class="a-text block text-center !text-green200" @click="helpToggle = !helpToggle">
              {{ $t("login.forgotPassword") }}</a
            >
            <div
              class="text-xs block text-center !text-grey400 font-medium mt-4"
              :class="helpToggle == false ? '!hidden' : ''"
            >
              {{ $t("login.forgotPasswordMessage") }}
            </div>
          </div>
        </div>
      </div>
    </v-content>

    <v-content v-else>
      <div class="h-full grid content-between justify-center py-9 px-7">
        <div class="flex justify-start items-center pt-3 px-5">
          <template v-if="isDefaultName">
            <img class="w-50" :src="require('~/assets/images/boxsRestaurant.png')" />
          </template>
          <template v-else>
            <img class="w-8.25" :src="$config.appLogo || require('~/assets/images/icon.png')" />
            <div class="pl-4 !text-purple100 font-normal text-3xl">{{ $config.appName }}</div>
          </template>
        </div>
        <div class="px-5 -mt-8">
          <nuxt></nuxt>
        </div>
        <!-- flex h-12 gap-5 justify-between items-center -->
        <div class="relative h-15">
          <!-- grid gap-4 w-70 -->
          <div class="absolute max-w-[80%] bottom-0 left-0 m-auto">
            <a class="forgot-password-mobile !text-green200 font-medium text-xs" @click="helpToggle = !helpToggle">
              {{ $t("login.forgotPassword") }}</a
            >
            <div class="text-xs !text-grey400 leading-sm mt-4" :class="helpToggle == false ? '!hidden' : ''">
              {{ $t("login.forgotPasswordMessage") }}
            </div>
          </div>
          <div class="absolute bottom-0 right-0 m-auto">
            <img
              class="h-7"
              @click="
                () => {
                  menuToggle = true;
                }
              "
              :src="require('~/assets/images/login/menu.svg')"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <LoginMenu class="menu-box" v-model="menuToggle" @close="closeMenu"> </LoginMenu>
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import _ from "lodash";
import Version from "../mixins/version";

export default {
  mixins: [Version],
  data() {
    return {
      isHydrated: false,
      helpToggle: false,
      langToggle: false,
      menuToggle: false,
    };
  },
  head() {
    return {
      title: this.$config.appName || 'boxsRestaurant',
      htmlAttrs: {
        style: this.breakpoint.mdAndDown ? "overflow-y: auto;" : "overflow: hidden; height: 100%;",
      },
      bodyAttrs: {
        style: "overflow: hidden; height: 100%;",
      },
    };
  },
  computed: {
    isDefaultName() {
      return !this.$config.appName || this.$config.appName === "boxsRestaurant" || this.$config.appName === "BOXS"
    },
    error: {
      get() {
        return !!this.$store.state.error;
      },
      set(v) {
        if (!v) {
          this.$store.commit("SET_ERROR");
        }
      },
    },
    breakpoint() {
      return this.isHydrated
        ? this.$vuetify.breakpoint
        : {
            mdAndDown: false,
          }; // "empty" $breakpoint object with initial values
    },

    errorMessage() {
      return this.$store.state.error;
    },
  },
  beforeMount() {},
  async mounted() {
    this.isHydrated = true;
    document.addEventListener("gesturestart", this.gesture);
  },
  beforeDestroy() {
    document.removeEventListener("gesturestart", this.gesture);
  },
  methods: {
    gesture(e) {
      e.preventDefault();
    },
    closeMenu() {
      this.menuToggle = false;
    },
  },
};
</script>

<style scoped>
button {
  border: 1px solid black;
}

.loginGradient {
  background: linear-gradient(
      180deg,
      rgba(62, 57, 106, 0.4) 0%,
      rgba(58, 52, 98, 0.36875) 19.27%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(128, 117, 242, 0.9);
  /* min-width: 422px; */
}

.loginSide {
  background: white;
  width: 100%;
}

.loginSide .v-card {
  border: none;
}

.version-border {
  @apply text-sm font-normal text-white text-opacity-70 border border-white border-opacity-20 rounded-lg;
}

.subtitle-profit {
  @apply text-2xl font-450 pt-2 text-white;
}

.a-text {
  @apply font-bold text-sm;
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.language-box {
  @apply border rounded-lg border-white border-opacity-20 bg-white bg-opacity-10 text-sm font-normal text-center;
}

.border-bottom:not(:last-child) {
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s ease-in-out;
}

.slide-enter-from {
  transform: translateY(-100%);
}

.slide-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.menu-box {
  animation: 0.3s ease-in-out alternate slidein;
  animation-name: menu-in;
  z-index: 9;
}

@supports (-webkit-touch-callout: none) {
  #app >>> .v-application--wrap {
    min-height: -webkit-fill-available !important; /* safari */
  }
}
</style>
