import type { FeatureKey } from "~/common/table/types";
import { FindType } from "@feathers-client";
export type { FeatureKey } from "~/common/table/types";

type ShopKeys = keyof FindType<"shops">;

export interface MenuType {
  href?: string;
  title?: string;
  action?: string;
  excludeFeature?: FeatureKey | FeatureKey[];
  feature?: FeatureKey | FeatureKey[];
  items?: MenuType[];
  exact?: boolean;
  cond?: { role: string; roles?: string; fullRoot?: boolean; root?: boolean; shopGroup?: boolean }[];
  shopData?: ShopKeys | ShopKeys[];

  cms?: boolean;
}

export function defineMenu<T extends MenuType[]>(menus: T) {
  return menus;
}

export const adminMenu = defineMenu([
  // shopAdmin nav bar - admin analytic
  {
    href: "/dashboard",
    title: "pages.home",
    action: "home",
    exact: true,
  },
  {
    title: "pages.groups.sales",
    action: "fas fa-chart-line",
    excludeFeature: "noSale",
    items: [
      {
        href: "/table/summary",
        title: "pages.summaries",
        action: "fas fa-chart-line",
      },
      {
        href: "/table/salesSummary",
        title: "pages.salesSummary",
        action: "fas fa-table",
      },
      {
        href: "/dailyStats/",
        title: "pages.dailyStats.$",
        action: "fas fa-sort-numeric-up",
      },
      {
        href: "/costSummary",
        title: "pages.costSummary",
        action: "table_chart",
        excludeFeature: "noCost",
      },
      {
        href: "/salesSetting",
        title: "pages.salesSetting",
        action: "fas fa-cog",
      },
      {
        href: "/dailySummary",
        title: "pages.dailySummary",
        action: "fas fa-table",
      },
    ],
  },
  {
    href: "/table/orders",
    title: "pages.orders.$",
    action: "far fa-sticky-note",
    excludeFeature: "noOrder",
  },

  {},
  // shopAdmin nav bar - admin management

  {
    title: "pages.groups.menuSetup",
    action: "fa fa-list",
    excludeFeature: "noMenu",
    items: [
      {
        title: "pages.groups.products.$",
        action: "fa fa-list",
        items: [
          {
            href: "/products",
            title: "pages.products.$",
            action: "fa fa-list",
          },
          {
            href: "/products/batch",
            title: "pages.products.batch",
            action: "fa fa-list",
          },
          {
            href: "/categories/",
            title: "pages.categories.$",
            action: "fa fa-filter",
          },
          {
            href: "/subCategories/",
            title: "pages.subCategories.$",
            action: "far fa-object-group",
          },
          {
            href: "/productOptions/",
            title: "pages.productOptions.$",
            action: "fas fa-tags",
          },
          {
            href: "/shopModifiers/",
            title: "pages.shopModifiers.$",
            action: "fa fa-list",
          },
        ],
      },
      {
        title: "pages.groups.options.$",
        action: "fa fa-list",
        items: [
          {
            href: "/kitchenOptions/",
            title: "pages.kitchenOptions.$",
            action: "fa fa-list",
          },
          {
            href: "/cancelReasons/",
            title: "pages.cancelReasons.$",
            action: "fa fa-list",
          },
        ],
      },
      {
        href: "/sections/",
        title: "pages.sections.$",
        action: "far fa-clock",
      },
      {
        href: "/timeConditions/",
        title: "pages.timeConditions.$",
        action: "far fa-clock",
      },
      {
        href: "/kitchenPrinters/",
        title: "pages.kitchenPrinters.$",
        action: "fas fa-print",
      },
      {
        href: "/waterBars/",
        title: "pages.waterBars.$",
        action: "fas fa-coffee",
      },
    ],
  },
  {
    title: "pages.groups.ingredients",
    action: "far fa-lemon",
    excludeFeature: "noIngredients",
    items: [
      {
        href: "/ingredients/",
        title: "pages.ingredients.$",
        action: "fa fa-list",
      },
      {
        href: "/ingredientCats/",
        title: "pages.ingredientCats.$",
        action: "far fa-lemon",
        excludeFeature: "noIngredientCats",
      },
      {
        href: "/ingredientSuppliers/",
        title: "pages.ingredientSuppliers.$",
        action: "shop",
      },
      {
        href: "/legacyIngredients/ingredientCostDailys",
        title: "pages.ingredientCostDailys",
        action: "fas fa-file-invoice-dollar",
        excludeFeature: "noLegacyIngredients",
      },
      {
        href: "/legacyIngredients/ingredientCosts",
        title: "pages.ingredientCosts",
        action: "fas fa-list",
        excludeFeature: "noLegacyIngredients",
      },
      {
        href: "/legacyIngredients/toPayCost",
        title: "pages.toPayCost",
        action: "fas fa-receipt",
        excludeFeature: "noLegacyIngredients",
      },
    ],
  },
  {
    title: "pages.groups.user",
    action: "fas fa-user-tag",
    feature: "point",
    items: [
      {
        href: "/appUsers",
        title: "pages.appUsers.$",
        action: "fas fa-user-tag",
      },
      {
        href: "/messageCentre",
        title: "pages.messageCentre.$",
        action: "fas fa-comment-alt",
        feature: "crm",
        excludeFeature: "noNotification",
      },
      {
        href: "/userPointLogs/",
        title: "pages.userPointLogs.$",
        action: "fas fa-history",
        feature: "crm",
      },
      {
        href: "/userSpendLogs/",
        title: "pages.userSpendLogs.$",
        action: "fas fa-history",
      },
      {
        href: "/userRankLogs/",
        title: "pages.userRankLogs.$",
        action: "fas fa-history",
        feature: "crm",
      },
      {
        href: "/userFavourites/",
        title: "pages.userFavourites.$",
        action: "fas fa-star",
        feature: "crm",
      },
      {
        href: "/userVerifications/",
        title: "pages.userVerifications.$",
        action: "fas fa-phone",
        feature: "crm",
      },
    ],
  },
  {
    title: "pages.group.gift.$",
    action: "fas fa-gift",
    items: [
      {
        href: "/orderDiscounts/",
        title: "pages.orderDiscounts.$",
        action: "fas fa-percentage",
        excludeFeature: "noDiscount",
      },
      {
        href: "/productDiscounts/",
        title: "pages.productDiscounts.$",
        action: "fas fa-percentage",
      },
      {
        href: "/discountGroups/",
        title: "pages.discountGroups.$",
        action: "fas fa-percentage",
      },
      {
        title: "pages.groups.gift",
        action: "fas fa-gift",
        feature: "crm",
        excludeFeature: "noGift",
        items: [
          {
            href: "/giftCategories/",
            title: "pages.giftCategories.$",
            action: "category",
            cond: [{ role: "admin" }],
          },
          {
            href: "/gifts/",
            title: "pages.gifts.$",
            action: "fas fa-gift",
          },
          {
            href: "/giftRedeemRecords",
            title: "pages.giftRedeemRecords.$",
            action: "fas fa-hand-holding-heart",
          },
        ],
      },
      {
        title: "pages.coupons.$",
        action: "fas fa-ticket-alt",
        feature: "crm",
        items: [
          {
            href: "/coupons/",
            title: "pages.coupons.$",
            action: "fas fa-ticket-alt",
          },
          {
            href: "/userCoupons/",
            title: "pages.userCoupons.$",
            action: "fas fa-user",
          },
        ],
      },
      {
        title: "pages.groups.promotion",
        action: "fas fa-bullhorn",
        excludeFeature: "noPromotions",
        feature: "crm",
        items: [
          {
            href: "/promotions/",
            title: "pages.promotions.$",
            action: "fas fa-bullhorn",
          },
          {
            href: "/promotionLogs/",
            title: "pages.promotionLogs.$",
            action: "fas fa-history",
          },
        ],
      },
      {
        title: "pages.groups.stamp",
        action: "fas fa-stamp",
        items: [
          {
            href: "/stampEvents/",
            title: "pages.stampEvents.$",
            action: "fas fa-stamp",
          },
          {
            href: "/stampRecords/",
            title: "pages.stampRecords.$",
            action: "fas fa-history",
          },
        ],
      },
    ],
  },

  {},
  // shopAdmin nav bar - admin settings

  {
    title: "pages.groups.payments.$",
    action: "fas fa-money-check-alt",
    excludeFeature: "noPayment",
    items: [
      {
        href: "/payments",
        title: "pages.payments.$",
        action: "fas fa-money-check-alt",
      },
      {
        href: "/paymentMethods",
        title: "pages.paymentMethods.$",
        action: "fas fa-credit-card",
      },
      {
        href: "/taxSettings",
        title: "pages.taxSettings.$",
        action: "fas fa-percentage",
        feature: "tax",
      },
      {
        href: "/surchargeSettings",
        title: "pages.surchargeSettings.$",
        action: "fas fa-percentage",
      },
      {
        title: "pages.groups.twInvoices.$",
        action: "fas fa-money-check-alt",
        href: "/twInvoices/",
        feature: "turnCloud",
      },
    ],
  },
  {
    title: "pages.groups.shopSettings",
    action: "fa fa-store",
    excludeFeature: "noSetting",
    items: [
      {
        href: "/shops",
        title: "pages.shops.$",
        action: "fas fa-store",
      },
      {
        href: "/shopBrands",
        title: "pages.shopBrands.$",
        action: "fas fa-store",
        excludeFeature: "singleShop",
      },
      {
        href: "/tvMenus",
        title: "pages.tvMenus.$",
        action: "fas fa-tv",
        feature: "queue",
      },
      {
        href: "/banners/",
        title: "pages.banners.$",
        action: "fas fa-images",
      },
      {
        href: "/shopCategories/",
        title: "pages.shopCategories.$",
        action: "category",
        feature: "shopSelect",
      },
      {
        href: "/shopPoints/",
        title: "pages.shopPoints.$",
        action: "fas fa-coins",
        feature: "crm",
      },
      {
        href: "/shopRankGroups/",
        title: "pages.shopRankGroups.$",
        action: "fas fa-coins",
        feature: "crm",
      },
      {
        href: "/shopRanks/",
        title: "pages.shopRanks.$",
        action: "fas fa-coins",
        feature: "crm",
      },
      {
        href: "/regions/",
        title: "pages.regions.$",
        action: "fas fa-map",
        feature: "shopSelect",
      },
      {
        href: "/shopSearches/",
        title: "pages.shopSearches.$",
        action: "fas fa-store",
        feature: "shopSelect",
      },
      {
        href: "/popularSearches/",
        title: "pages.popularSearches.$",
        action: "fas fa-fire",
        feature: "shopSelect",
      },
      {
        href: "/printerTemplates/",
        title: "pages.printerTemplates.$",
        action: "fas fa-cogs",
        feature: "printerTemplates",
      },
    ],
  },
  {
    title: "pages.groups.onlineReservation",
    action: "$calendar",
    feature: "onlineReservation",
    items: [
      {
        href: "/reservation/settings/",
        title: "pages.shopReservationSettings.$",
        action: "$calendar",
      },
      {
        href: "/reservation/details/",
        title: "pages.shopReservationDetail.$",
        action: "fa fa-store",
      },
      {
        href: "/reservation/plans/",
        title: "pages.shopReservationPlan.$",
        action: "$calendar",
      },
      {
        href: "/reservation/orders/",
        title: "pages.reservationOrders.$",
        action: "far fa-sticky-note",
      },
    ],
  },
  {
    title: "pages.groups.moduleSettings",
    action: "fas fa-puzzle-piece",
    items: [
      {
        href: "/posDevices",
        title: "pages.posDevices.$",
        action: "fas fa-phone",
        feature: "selfOrder",
        excludeFeature: "noPosDevice",
      },
      {
        href: "/odsSettings",
        title: "pages.odsSettings.$",
        action: "fas fa-phone",
        feature: "ods",
      },
      {
        href: "/shopQueueingGroups/",
        title: "pages.shopQueueingGroups.$",
        feature: ["queueing", "queueingClinic"],
        action: "fas fa-people-arrows",
      },
      {
        title: "pages.groups.appSettings",
        action: "fas fa-mobile-alt",
        feature: "app",
        cond: [{ role: "admin", root: true }],
        excludeFeature: "noApp",
        items: [
          {
            href: "/appBanners/",
            title: "pages.appBanners",
            action: "fas fa-images",
          },
          {
            href: "/appNewsTypes/",
            title: "pages.appNewsTypes",
            action: "far fa-newspaper",
          },
          {
            href: "/appNews/",
            title: "pages.appNews.$",
            action: "far fa-newspaper",
          },
          {
            href: "/systemNotifications/",
            title: "pages.systemNotifications.$",
            action: "fas fa-bell",
          },
          {
            href: "/notificationTemplates/",
            title: "pages.notificationTemplates.$",
            action: "fas fa-cog",
          },
          {
            href: "/estimatedOrderTimes/",
            title: "pages.estimatedOrderTimes.$",
            action: "fas fa-cog",
          },
          {
            href: "/bookmarks/",
            title: "pages.bookmarks.$",
            action: "fas fa-bookmark",
          },
          {
            href: "/appSettings",
            title: "pages.appSettings",
            action: "fas fa-cogs",
          },
          {
            href: "/systemSettings",
            title: "pages.systemSettings",
            action: "fas fa-cogs",
          },
          {
            href: "/versions/",
            title: "pages.versions",
            action: "fas fa-cogs",
          },
        ],
      },
    ],
  },
  {
    title: "pages.staffs.$",
    action: "fas fa-users",
    excludeFeature: "noStaff",
    items: [
      {
        href: "/staffs/",
        title: "pages.staffs.$",
        action: "fas fa-users",
      },
      {
        href: "/staffPunchRecords/",
        title: "pages.staffPunchRecords.$",
        action: "fas fa-history",
      },
    ],
  },
  {
    title: "pages.groups.logs",
    action: "$history",
    items: [
      {
        href: "/actionLogs/",
        title: "pages.actionLogs.$",
        action: "$time2",
      },
      {
        href: "/cashboxLogs/",
        title: "pages.cashboxLogs.$",
        action: "$cashIO",
      },
    ],
  },
  {
    href: "/admins/",
    title: "pages.admins.$",
    action: "fas fa-lock",
    excludeFeature: "noAdmin",
  },
  {
    href: "/shopGroups/",
    title: "pages.shopGroups.$",
    action: "fas fa-store",
    cond: [{ role: "admin", fullRoot: true }],
  },
  {
    href: "/settings",
    title: "pages.settings.$",
    excludeFeature: "noHardware",
    action: "fas fa-cog",
    items: [
      {
        href: "/settings/general",
        title: "pages.settings.general",
        action: "fas fa-cog",
      },
      {
        href: "/settings/printer",
        title: "pages.settings.printer",
        action: "print",
      },
      {
        href: "/settings/scanner",
        title: "pages.settings.scanner",
        action: "qr_code_scanner",
      },
      {
        href: "/settings/exportTemplate",
        title: "pages.settings.exportTemplate",
        action: "fas fa-cog",
        feature: "devMode",
      },
      {
        href: "/settings/reportEmail",
        title: "reportEmail.title",
        action: "fas fa-cog",
        feature: "reportEmail",
      },
      {
        title: "pages.settings.integration",
        action: "fas fa-cog",
        items: [
          {
            href: "/settings/candao",
            title: "pages.settings.candao",
            action: "cloud_upload",
            feature: "candao",
          },
          {
            href: "/settings/sms",
            title: "pages.settings.smsSettings",
            action: "fas fa-cog",
          },
        ],
      },
    ],
  },
]);

export const posMenu = defineMenu([
  {
    href: "/table",
    title: "pages.tableManager",
    action: "fas fa-chair",
    excludeFeature: "noOrder",
    exact: true,
  },
  {
    href: "/shops/openClose",
    title: "pos.businessOperation.$",
    action: "store",
    excludeFeature: "noOrder",
    exact: true,
  },
  {
    href: "/shops/inventory",
    title: "pos.inventory.$",
    action: "$inventory",
    exact: true,
  },
  {
    href: "/punch",
    title: "pages.punch",
    action: "fas fa-clipboard-check",
  },
  {
    href: "/table/kds",
    title: "pages.kds.$",
    action: "tv",
    feature: "kds",
    exact: true,
    shopData: "kdsEnabled",
  },
  {
    href: "/queueing/pos",
    title: "pages.queueing.posMode",
    action: "fas fa-people-arrows",
    feature: "queueing",
    exact: true,
    cond: [{ role: "shop" }, { role: "staff", roles: "pos" }],
  },
  {
    href: "/queueing",
    title: "pages.queueing.kioskMode",
    action: "fas fa-people-arrows",
    feature: "queueing",
    exact: true,
    cond: [{ role: "shop" }, { role: "staff", roles: "pos" }],
  },
  {
    href: "/queueing/posMobileClinic",
    title: "pages.queueing.posMobileMode",
    action: "fas fa-people-arrows",
    feature: "queueingClinic",
    exact: true,
    cond: [{ role: "shop" }, { role: "staff", roles: "pos" }],
  },
  {
    href: "/queueing/clinic",
    title: "pages.queueing.clinicKioskMode",
    action: "fas fa-people-arrows",
    feature: "queueingClinic",
    exact: true,
    cond: [{ role: "shop" }, { role: "staff", roles: "pos" }],
  },
  {
    href: "/shops/displaySettings",
    title: "pos.settings.$",
    action: "settings",
    exact: true,
  },
]);

export const staffMenu = defineMenu([
  // nav bar - analytic

  {
    href: "/table",
    title: "pages.tableManager",
    action: "fas fa-chair",
    excludeFeature: "noOrder",
    exact: true,
    cms: true,
    cond: [{ role: "shop" }, { role: "staff", roles: "pos" }],
  },
  { cond: [{ role: "shop" }, { role: "staff", roles: "pos" }] },
  {
    title: "pages.groups.paymentRecords.$",
    action: "fas fa-money-check-alt",
    href: "/payments",
    excludeFeature: "noPayment",
    cond: [{ role: "shop" }, { role: "staff", roles: "payments" }],
  },
  {
    title: "pages.groups.orders.$",
    action: "fas fa-sticky-note",
    items: [
      {
        href: "/table/orders",
        title: "pages.tableOrders.$",
        action: "far fa-sticky-note",
        excludeFeature: "noOrder",
        cond: [{ role: "shop" }, { role: "staff", roles: "orders" }],
      },
      {
        href: "/waterBarScanner",
        title: "pages.waterBarSacnner",
        action: "fas fa-qrcode",
        cond: [{ role: "shop" }, { role: "staff", roles: "tv" }],
        excludeFeature: "noScanner",
      },
    ],
  },

  {
    title: "pages.groups.sales",
    action: "fas fa-chart-line",
    excludeFeature: "noSale",
    items: [
      {
        href: "/table/summary",
        title: "pages.summaries",
        action: "fas fa-chart-line",
      },
      {
        href: "/table/salesSummary",
        title: "pages.salesSummary",
        action: "fas fa-table",
      },
      {
        href: "/costSummary",
        title: "pages.costSummary",
        action: "table_chart",
        excludeFeature: "noCost",
      },
      {
        href: "/dailySummary",
        title: "pages.dailySummary",
        action: "fas fa-table",
      },
    ],
    cond: [{ role: "shop" }, { role: "staff", roles: "sales" }],
  },

  {
    cond: [
      { role: "shop" },
      { role: "staff", roles: "sales" },
      { role: "staff", roles: "payments" },
      { role: "staff", roles: "orders" },
      { role: "staff", roles: "tv" },
    ],
  },
  // shop nav bar - management

  {
    title: "pages.groups.ingredients",
    action: "far fa-lemon",
    excludeFeature: "noIngredients",
    items: [
      {
        href: "/ingredientInventories/",
        title: "pages.ingredientInventories.$",
        action: "fa fa-list",
      },
      {
        href: "/ingredients/manage",
        title: "pages.ingredientManage.$",
        action: "fa fa-list",
      },
      {
        href: "/ingredients/semiProduct",
        title: "pages.semiProduct.$",
        action: "fa fa-list",
      },
      {
        href: "/ingredients/summary",
        title: "pages.ingredientSummary.$",
        action: "fa fa-list",
      },
      {
        href: "/ingredientCats/",
        title: "pages.ingredientCats.$",
        action: "far fa-lemon",
        excludeFeature: "noIngredientCats",
      },
      {
        href: "/legacyIngredients/ingredientCostInput",
        title: "pages.ingredientCostInput",
        action: "fas fa-file-invoice-dollar",
        excludeFeature: "noLegacyIngredients",
      },
      {
        href: "/legacyIngredients/ingredientCostDailys",
        title: "pages.ingredientCostDailys",
        action: "fas fa-file-invoice-dollar",
        excludeFeature: "noLegacyIngredients",
      },
      {
        href: "/legacyIngredients/ingredientCosts",
        title: "pages.ingredientCosts",
        action: "fas fa-list",
        excludeFeature: "noLegacyIngredients",
      },
      {
        href: "/legacyIngredients/expenditure",
        title: "pages.ingredientExpenditure",
        action: "fas fa-file-invoice-dollar",
      },
    ],
    cond: [{ role: "shop" }, { role: "staff", roles: "ingredients" }],
  },

  {
    title: "pages.group.gift.$",
    action: "fas fa-gift",
    excludeFeature: "noSale",
    items: [
      {
        title: "pages.groups.gift",
        action: "fas fa-gift",
        feature: "point",
        items: [
          {
            href: "/giftRedeemRecords/",
            title: "pages.giftRedeemRecords.$",
            action: "fas fa-hand-holding-heart",
          },
        ],
        cond: [{ role: "shop" }, { role: "staff", roles: "gifts" }],
      },
      {
        title: "pages.groups.promotion",
        action: "fas fa-bullhorn",
        items: [
          {
            href: "/promotionLogs/",
            title: "pages.promotionLogs.$",
            action: "fas fa-history",
          },
        ],
        cond: [{ role: "shop" }, { role: "staff", roles: "promotions" }],
        excludeFeature: "noPromotions",
      },
    ],
  },

  {
    cond: [
      { role: "shop" },
      { role: "staff", roles: "ingredients" },
      { role: "staff", roles: "gifts" },
      { role: "staff", roles: "promotions" },
    ],
  },
  // shop nav bar - staff settings

  {
    title: "pages.groups.twInvoices.$",
    action: "fas fa-money-check-alt",
    href: "/twInvoices/",
    feature: "turnCloud",
    cond: [{ role: "shop" }, { role: "staff", roles: "payments" }],
  },
  {
    title: "pages.groups.moduleSettings",
    action: "fas fa-puzzle-piece",
    items: [
      {
        title: "pages.shopQueueingGroups.setting",
        action: "fas fa-people-arrows",
        feature: ["queueing", "queueingClinic"],
        items: [
          {
            href: "/shopQueueingGroups/",
            title: "pages.shopQueueingGroups.$",
            feature: ["queueing", "queueingClinic"],
            action: "fas fa-people-arrows",
          },
          {
            href: "/queueing/setting",
            title: "pages.shopQueueingGroups.setting",
            feature: ["queueing", "queueingClinic"],
            action: "fas fa-cog",
          },
        ],
        cond: [{ role: "shop" }, { role: "staff", roles: "queueing" }],
      },
      {
        title: "pages.groups.tvSettings",
        action: "fas fa-tv",
        feature: "queue",
        items: [
          {
            href: "/tvConnect",
            title: "pages.tvConnect",
            action: "fas fa-tv",
          },
          {
            href: "/tvOrderStatuses/",
            title: "pages.tvOrderStatuses.$",
            action: "fas fa-table",
          },
        ],
        cond: [{ role: "shop" }, { role: "staff", roles: "tv" }],
      },
    ],
  },
  {
    title: "pages.staff",
    action: "fas fa-clipboard-check",
    items: [
      {
        href: "/punch",
        title: "pages.punch",
        action: "fas fa-clipboard-check",
      },
      {
        href: "/printIdLabel",
        title: "pages.printIdLabel",
        action: "fas fa-print",
        cond: [{ role: "shop" }],
      },
    ],
    cond: [{ role: "shop" }, { role: "staff", roles: "punch" }],
    excludeFeature: "noStaff",
  },
  {
    href: "/settings",
    title: "pages.settings.$",
    excludeFeature: "noHardware",
    action: "fas fa-cog",
    items: [
      {
        href: "/settings/general",
        title: "pages.settings.general",
        action: "fas fa-cog",
      },
      {
        href: "/settings/printer",
        title: "pages.settings.printer",
        action: "print",
        cond: [{ role: "shop" }, { role: "staff", roles: "hardware" }],
      },
      {
        href: "/settings/scanner",
        title: "pages.settings.scanner",
        action: "qr_code_scanner",
        cond: [{ role: "shop" }, { role: "staff", roles: "hardware" }],
      },
      {
        href: "/settings/candao",
        title: "pages.settings.candao",
        action: "cloud_upload",
        feature: "candao",
      },
      {
        href: "/settings/uberEats",
        title: "pages.settings.uberEats",
        action: "cloud_upload",
        feature: "uberEats",
      },
      {
        href: "/settings/dev",
        title: "pages.settings.dev",
        action: "fas fa-cog",
        feature: "devMode",
      },
      {
        href: "/settings/xero",
        title: "pages.settings.xero",
        action: "fas fa-cog",
        feature: "xero",
      },
      {
        href: "/settings/turncloud",
        title: "turnCloud.invoiceSettings.$",
        action: "fas fa-cog",
        feature: "turnCloud",
      },
    ],
  },
]);

export const guestMenu = defineMenu([
  {
    href: "/login",
    title: "pages.login",
    action: "vpn_key",
    exact: false,
  },
]);
