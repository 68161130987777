var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"queueing-card",class:_vm.cardDetails.currentCallingTicketName ? 'group-color-animation' : '',style:([
    _vm.cardDetails.currentCallingTicketName ? { '--card-color': _vm.cardDetails.color } : { 'background-color': '#3D3D3D' },
    { width: _vm.width + 'px' },
    { height: _vm.height + 'px' },
  ])},[_c('main',{staticClass:"grid grid-cols-12 items-center justify-center h-full w-full",class:_vm.$shop.localOptions.queueingKioskShowTicketsSection && _vm.$shop.localOptions.queueingKioskShowLogo
        ? 'py-4'
        : 'lg:py-6'},[_c('div',{staticClass:"ticket-limit col-span-3 col-start-1"},[_c('img',{staticClass:"w-12 h-12",attrs:{"src":require("assets/images/queueing/person.svg")}}),_c('span',{staticClass:"text-center whitespace-nowrap"},[_vm._v(_vm._s(_vm.cardDetails.ticketLimit))])]),_c('v-divider',{staticClass:"col-span-1 col-start-4",attrs:{"vertical":"","color":"#828282"}}),_c('div',{staticClass:"ticket-group col-span-7 col-start-5 flex flex-col"},[_c('div',{staticClass:"font-medium",class:_vm.cardDetails.currentCallingTicketName ? 'text-color-animation' : '',style:(_vm.cardDetails.currentCallingTicketName
            ? `--text-color: ${_vm.cardDetails.color};`
            : `color: ${_vm.cardDetails.color};`)},[_c('div',{class:_vm.$shop.localOptions.queueingKioskNumberOfTicketWaiting ? 'text-120px' : 'text-140px'},[_vm._v(" "+_vm._s(_vm.cardDetails.currentCallingTicketName ? _vm.cardDetails.currentCallingTicketName : _vm.cardDetails.currentTicketName)+" ")])]),(_vm.$shop.localOptions.queueingKioskNumberOfTicketWaiting)?_c('div',{staticClass:"my-3 text-34px"},[_vm._v(" "+_vm._s(_vm.$t("queueing.waitingCount"))+": "+_vm._s(_vm.cardDetails.waitingLength)+" ")]):_vm._e()])],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }