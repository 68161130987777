
import _ from "lodash";
import { Component, Prop, PropSync, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { checkID, FindType } from "@feathers-client";
import type { ProductType } from "~/plugins/shop";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { escapeRegExp, getID } from "@feathers-client";

@Component({
  components: {
    QrcodeStream,
  },
})
export default class OrderSystem extends Vue {
  @PropSync("numPadMode")
  numPadModeSync: boolean;

  @Prop()
  session: TableSession;

  @Prop()
  loading: boolean;

  checkID = checkID;

  scanning = false;

  get selectedSection() {
    return this.session?.selectedSection;
  }

  @Ref()
  searchContainer: HTMLElement;

  mounted() {
    if (this.searchContainer) {
      this.searchContainer.scrollTop = 100;
    }
  }

  get filteredCats() {
    if (!this.selectedSection) return [];
    const result = _.filter(
      this.$shop && this.$shop.cats,
      it => it?.sections?.some?.(jt => checkID(jt, this.selectedSection)),
    );
    if (!this.selectedCat || !result.find(it => checkID(it._id, this.selectedCat))) {
      this.selectedCat = result[0]?._id;
    }
    return result;
  }

  get filteredSubCats() {
    if (!this.selectedCat) return [];
    const result = _.filter(this.$shop && this.$shop.subCats, it => checkID(it.category, this.selectedCat));
    return result;
  }

  get filteredProducts() {
    if (!this.selectedSection || (!this.selectedCat && this.filteredCats.length)) return [];
    let __ = this.session.type; // to trigger watcher
    var result = _.filter(
      this.$shop && this.$shop.products,
      it => !it.setOrderOrGiftOnly && it.sections.some(jt => checkID(jt, this.selectedSection)),
    );
    if (this.selectedCat) {
      if (this.selectedSubCat) {
        result = _.filter(result, it => checkID(it.subCategory, this.selectedSubCat));
      } else {
        result = _.filter(result, it => checkID(it.category, this.selectedCat));
      }
    }
    if (this.searchKeyword) {
      const keyword = this.searchKeyword.trim().toLowerCase();
      const regex = new RegExp(escapeRegExp(keyword), "ig");
      result = result.filter(item => {
        return (item.code && item.code.match(regex)) || item.name?.find?.(n => n.value?.match?.(regex));
      });
    }
    return result;
  }

  get filteredCustomProducts() {
    if (!this.selectedCat) return [];
    if (this.selectedSubCat) {
      return Object.values(this.$shop.customProducts).filter(it => checkID(it.subCategory, this.selectedSubCat));
    }
    return Object.values(this.$shop.customProducts).filter(it => checkID(it.category, this.selectedCat));
  }

  searchKeyword = "";
  selectedCat = "";
  selectedSubCat = "";

  selectCat(cat: FindType<"categories">) {
    this.selectedCat = cat?._id;
    this.selectedSubCat = null;
    this.searchContainer?.scrollTo({ top: 100, left: 0, behavior: "smooth" });
  }

  selectSubCat(subCat: FindType<"subCategories">) {
    this.selectedSubCat = subCat?._id;
  }

  get readonly() {
    return !this.session.postEditing && this.session.status !== "ongoing";
  }

  async addToCart(item: ProductType, showOptions = false) {
    if (this.loading) return;
    const cart = this.session.addToCartFromProduct(item, {
      swap: true,
      mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
    });
    if (await this.session.updateAutoMerge()) {
      return;
    }
    if (item.options?.find?.(it => this.$shop.productOptionDict[getID(it)]?.required)) {
      showOptions = true;
    }
    if (showOptions) {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.session.openProductMenu({ cart, showOptions });
    }
  }

  @Ref()
  search: HTMLInputElement;

  goSearch() {
    this.search?.blur?.();
    if (
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        this.searchKeyword,
      )
    ) {
      const code = this.searchKeyword;
      this.searchKeyword = "";
      this.$root.$emit("scanner", { code });
    }
  }

  selectAll() {
    this.search?.select?.();
  }

  onDecode(code) {
    this.$root.$emit("scanner", { code });
  }

  @Ref()
  category: HTMLElement;

  updateProgress() {
    if (this.category) {
      const maskWidth = 80;
      const scrollableWidth = this.category.scrollWidth - this.category.clientWidth;
      if (scrollableWidth > maskWidth) {
        const scrollProgress = Math.max(
          0,
          Math.min(1, (this.category.scrollLeft - (scrollableWidth - maskWidth)) / maskWidth),
        );
        this.category.style.setProperty(
          "--mask-progress",
          `${
            (scrollProgress * (maskWidth / this.category.clientWidth) +
              (this.category.clientWidth - maskWidth) / this.category.clientWidth) *
            100
          }%`,
        );
      } else if (this.category.style.getPropertyValue("--mask-progress") !== "100%") {
        this.category.style.setProperty("--mask-progress", "100%");
      }
    }
  }
}
