
import { Component, Prop, Vue, Watch, PropSync, FindType, mixins } from "@feathers-client";
import _, { mixin } from "lodash";
import moment from "moment";
import { queueingCard } from "types/types";
import { TableView } from "../../plugins/table";
import { TableSession } from "@feathers-client/../typings/common/table/session";

@Component
export default class QueueingPOSView extends Vue {
  fetch({ store }) {
    store.commit("SET_TITLE", {
      fullPage: true,
      tableMode: true,
      disableTextSelect: true,
      dark: true,
      hideHeader: true,
    });
  }

  @Prop({ type: Boolean, default: false })
  tableView: boolean;

  drawer = false;
  openSetting = false;
  numDialog = false;
  loading = false;
  nextTicket = {};
  numberOfPeople = null;
  queueTicketGroups = [];
  callingTicket = {};
  openQueueing = false;
  assigningTable = false;
  assigningTicket: queueingCard = null;
  view: TableView = null;
  callingNumBoolean: boolean = false;
  callingOverlay: boolean = false;
  callingNum = "";

  async beforeMount() {
    // this.view = this.$tableManager.views[0];
    await this.resetSelection();

    //auto update ui for every 60s
    setInterval(() => {
      this.onShopSessionUpdated();
    }, 60000);
  }

  async mounted() {
    if (!this.$shop.shopId) {
      this.$router.replace("/profile");
    }
  }

  get shopQueueingPastTickets() {
    return this.$shopSession.shopQueueingPastTickets;
  }

  get maxNum() {
    const { shopQueueingGroups: groups = [] } = this.$shopSession;
    const activeGroups = groups.filter(g => g?.active) ?? [];
    const upperValues = activeGroups.map(g => g?.tableCapacity?.upper);
    const maxUpper = upperValues.length > 0 ? Math.max(...upperValues) : 0;
    return maxUpper;
  }

  get availableTables() {
    return this.queueTicketGroups.filter(i => !i.disableQueueing);
  }

  @Watch("$shop.shopId")
  async resetSelection() {
    await this.$tableManager.ready;
    this.loading = true;
    try {
      if (!this.$shop.shopId) return;
      if (!this.$tableManager.views.length) {
        this.view = null;
      }
      this.view = this.$tableManager.views[0];
    } finally {
      this.loading = false;
      if (!this.view) {
        this.view = null;
      }
    }
  }

  get shopSession() {
    return this.$shopSession.shopSessionData;
  }

  @Watch("shopSession.queueingTicketGroups", { deep: true, immediate: true })
  async onShopSessionUpdated() {
    const shopSession = this.shopSession;
    const queueingGroups = this.$shopSession.shopQueueingGroups;
    const localTicketGroups = [...this.queueTicketGroups];

    let groupCurrentTickets = {};
    let groupCurrentTicketsName = {};
    let queueingTicketsMapped = {};
    let callingTicketMapped = {};
    let latestAssignedTickets = {};

    let promises = [];
    if (this._openQueueing && queueingGroups && queueingGroups.length) {
      //get queueing tickets data and map queueing tickets array
      for (let group of queueingGroups) {
        promises.push(
          (async () => {
            this.nextTicket[group._id] = false;
            let currentTicket = null;
            let currentTicketName = "0";

            if (shopSession.queueingTicketGroups && shopSession.queueingTicketGroups.length > 0) {
              const queueingTicketGroup = shopSession.queueingTicketGroups.find(item => item.ticketGroup === group._id);

              //get current ticket / calling ticket info / latest assigned ticket
              if (queueingTicketGroup) {
                //get current assigned ticket name
                latestAssignedTickets[group._id] =
                  group.prefix + queueingTicketGroup.ticketNumber.toString().padStart(group.numberOfDigits, "0");

                //get current ticket
                const currentTicket_id = queueingTicketGroup.currentTicket;
                if (currentTicket_id) {
                  try {
                    currentTicket = await this.$feathers.service("shopQueueTickets").get(currentTicket_id);
                  } catch (e) {
                    console.log("Error getting currentTicket:", e);
                  }
                }
                if (currentTicket) {
                  groupCurrentTickets[group._id] = currentTicket;
                  currentTicketName = currentTicket.ticketNumber;
                } else {
                  currentTicketName = group.prefix + currentTicketName.padStart(group.numberOfDigits, "0");
                }
                groupCurrentTicketsName[group._id] = currentTicketName;

                //get calling tickets
                const callingTicket_id = queueingTicketGroup.callingTicket;
                if (callingTicket_id) {
                  try {
                    const callingTicket = await this.$feathers.service("shopQueueTickets").get(callingTicket_id);
                    if (callingTicket) {
                      let callingTicketTime = moment(callingTicket.createTime).format("HH:mm");
                      callingTicketMapped[group._id] = {
                        _id: callingTicket._id,
                        queueingGroup: group._id,
                        prefix: group.prefix,
                        ticketLimit: group.tableCapacity.lower + "-" + group.tableCapacity.upper,
                        ticketNum: callingTicket.ticketNumber,
                        latestAssignedTicket: latestAssignedTickets[group._id],
                        numberOfPeople: callingTicket.noOfPeople,
                        color: group.color,
                        time: callingTicketTime,
                        alreadySeated: callingTicket.status == "seated" ? true : false,
                        passed: callingTicket.passed,
                        numCalling: true,
                        disableQueueing: !group.active,
                      };
                    } else {
                      callingTicketMapped[group._id] = null;
                    }
                  } catch (e) {
                    console.log("Error getting callingTicket:", e);
                  }
                } else {
                  callingTicketMapped[group._id] = null;
                }
              }
              // mapping queueing Ticket of the ticket group
              let queueingTickets = null;
              if (queueingTicketGroup && queueingTicketGroup.ticketNumber) {
                queueingTickets = await this.$feathers.service("shopQueueTickets").find({
                  query: {
                    shopSession: shopSession._id,
                    queueingGroup: group._id,
                    status: "waiting",
                    $limit: 1000,
                    $sort: {
                      createTime: 1,
                    },
                    $paginate: false,
                  },
                  paginate: false,
                });
                if (queueingTickets) {
                  queueingTickets = queueingTickets.filter(ticket => ticket._id !== currentTicket?._id);
                  //map queueing tickets
                  queueingTicketsMapped[group._id] = queueingTickets.map(ticket => {
                    // let time = moment(ticket.createTime).format("HH:mm");
                    return {
                      _id: ticket._id,
                      queueingGroup: group._id,
                      prefix: group.prefix,
                      ticketLimit: group.tableCapacity.lower + "-" + group.tableCapacity.upper,
                      ticketNum: ticket.ticketNumber,
                      numberOfPeople: ticket.noOfPeople,
                      color: group.color,
                      time: moment(ticket.createTime).format("HH:mm"), // time,
                      alreadySeated: ticket.status == "seated" ? true : false,
                      passed: ticket.passed,
                      numCalling: ticket.status == "calling" ? true : false,
                      disableQueueing: !group.active,
                    };
                  });
                }
              }
            }
          })(),
        );
      }

      try {
        await Promise.all(promises);
      } catch (error) {
        // An error occurred
        console.log("queueing ticket mapping error", error);
      }
      //mapping queueing ticket groups data for UI
      this.queueTicketGroups = queueingGroups.map(group => {
        let currentTicket = groupCurrentTickets[group._id];
        let currentTicketName = groupCurrentTicketsName[group._id];
        let currentTicketTime = currentTicket ? moment(currentTicket.createTime).format("HH:mm") : "00:00";
        return {
          queueingGroup: group._id,
          prefix: group.prefix,
          skipAssignTable: this.shopSkipAssignTable || group.skipAssignTable,
          ticketLimit: group.tableCapacity.lower + "-" + group.tableCapacity.upper,
          ticketNum: currentTicketName,
          latestAssignedTicket: latestAssignedTickets[group._id],
          numberOfPeople: (currentTicket && currentTicket.noOfPeople) || 0,
          color: group.color,
          time: currentTicketTime,
          alreadySeated: currentTicket ? currentTicket.status === "seated" : false,
          passed: currentTicket ? currentTicket.passed : false,
          numCalling: currentTicket ? currentTicket.status === "calling" : false,
          disableQueueing: !group.active,
          activeAnimation: false,
          callingTicket: callingTicketMapped[group._id],
          queueingTickets: queueingTicketsMapped[group._id],
          _id: currentTicket?._id,
        };
      });
      //trigger animation if current ticket != local copy of current ticket
      for (let group of queueingGroups) {
        if (localTicketGroups && localTicketGroups.length > 0) {
          const localTicketGroup = localTicketGroups.find(item => item.queueingGroup === group._id);
          if (localTicketGroup) {
            const mappedTicketGroup = this.queueTicketGroups.find(item => item.queueingGroup === group._id);
            if (mappedTicketGroup) {
              const currentTicket_id = mappedTicketGroup._id;
              if (localTicketGroup._id !== currentTicket_id) {
                this.updateNextTicketAnimation(localTicketGroup.queueingGroup);
              }
            }
          }
        }
      }
    }
    this.loading = false;
  }

  get _openQueueing() {
    return this.shopSession?.openQueueing || this.openQueueing;
  }

  //update queueing setting
  @Watch("shopSession.openQueueing", { deep: true, immediate: true })
  async onSessionOpenQueueingUpdated() {
    this.openQueueing = this.shopSession?.openQueueing || false;
  }
  @Watch("openQueueing")
  async onOpenQueueingUpdated() {
    try {
      if (this.$shopSession.shopSessionData?._id) {
        await this.$feathers.service("shopSessions").patch(this.$shopSession.shopSessionData._id, {
          openQueueing: this.openQueueing,
        });
        if (this.openQueueing) {
          await this.$shopSession.updateCachedShopQueueingGroups();
          await this.$shopSession.updateShopQueueingPastTickets();
        }
      } else {
        console.log("No shop session id found to patch openQueueing to.");
      }
    } catch (error) {
      console.error("Error updating openQueueing:", error);
    }
  }

  async disableQueueingGroup({ index, disableQueueingGroup }) {
    this.loading = true;
    const queueingGroup = this.queueTicketGroups[index];
    if (queueingGroup) {
      try {
        await this.$feathers.service("shopQueueingGroups").patch(queueingGroup.queueingGroup, {
          active: !disableQueueingGroup,
        });
      } catch (error) {
        console.log("Error updating disableQueueingGroup:", error);
      }
    }
  }

  async openFilterDialog() {
    this.openSetting = true;
  }
  openQueueingSidebar() {
    this.drawer = true;
  }
  async openNumDialog() {
    this.numDialog = true;
  }

  debouncedCallTicket = _.debounce(this.callTicket, 500, { leading: false, trailing: true });
  async callTicket(ticketId: string) {
    this.loading = true;
    try {
      let callingTicket = await this.$feathers.service("shopQueueTickets").patch(ticketId, { status: "calling" });
      this.callingOverlay = true;
      this.callingNum = callingTicket.ticketNumber;
      // setTimeout(() => {
      //   this.callingOverlay = false;
      // }, 8000);
    } catch (error) {
      console.log("Error updating callTicket:", error);
    }
  }
  async assignTable(ticket: queueingCard) {
    const group = this.queueTicketGroups?.find(group => group.queueingGroup === ticket.queueingGroup);
    if (group?.skipAssignTable) {
      await this.$feathers.service("shopQueueTickets").patch(ticket._id, { status: "seated" });
      return;
    }

    this.assigningTable = true;
    this.assigningTicket = ticket;
    // await this.$feathers.service("shopQueueTickets").patch(ticketId, { status: "seated" });
  }

  async assignTableTicket(tableSession: TableSession, tableName: string) {
    this.assigningTable = false;

    if (this.assigningTicket && tableSession) {
      try {
        await this.$feathers
          .service("shopQueueTickets")
          .patch(this.assigningTicket._id, { status: "seated", tableSession: tableSession._id });
        this.$store.commit(
          "SET_ERROR",
          `${this.$t("queueing.assignedTable")}: ${tableName}, ${this.$t("queueing.ticket")}: ${
            this.assigningTicket.ticketNum
          }`,
        );
      } catch (e) {
        console.log("Error updating assignTableTicket:", e);
      } finally {
        this.assigningTicket = null;
      }
    }
  }

  debouncedUpdateQueueingTicket = _.debounce(this.updateQueueingTicket, 500, { leading: true, trailing: false });
  async updateQueueingTicket(queueingGroupId) {
    this.loading = true;
    const queueTicketGroup = this.queueTicketGroups.find(group => group.queueingGroup === queueingGroupId);
    if (queueTicketGroup) {
      //next ticket is the first ticket in queueing ticket array
      let nextTicket = queueTicketGroup.queueingTickets?.[0];
      // calling ticket is not current ticket assign next ticket to current ticket when press next ticket button
      if (
        queueTicketGroup._id &&
        queueTicketGroup.callingTicket &&
        queueTicketGroup.callingTicket._id !== queueTicketGroup._id
      ) {
        nextTicket = queueTicketGroup;
      }

      let shopSessionData = { ...this.$shopSession.shopSessionData };
      let currentTicket = null;

      if (nextTicket) {
        let queueingTicketGroupIndex = shopSessionData.queueingTicketGroups.findIndex(
          ticket => ticket.ticketGroup === queueingGroupId,
        );

        //update current ticket / calling ticket
        if (queueingTicketGroupIndex !== -1) {
          let currentTicketId = null;
          if (shopSessionData.queueingTicketGroups[queueingTicketGroupIndex].currentTicket) {
            const currentTicketId =
              nextTicket._id !== shopSessionData.queueingTicketGroups[queueingTicketGroupIndex].currentTicket
                ? shopSessionData.queueingTicketGroups[queueingTicketGroupIndex].currentTicket
                : queueTicketGroup.callingTicket._id;
            if (currentTicketId) {
              try {
                currentTicket = await this.$feathers.service("shopQueueTickets").get(currentTicketId);
                if (currentTicket && currentTicket.status !== "seated") {
                  await this.$feathers
                    .service("shopQueueTickets")
                    .patch(currentTicketId, { status: "passed", passed: true });
                }
              } catch (e) {
                console.log("Error updating updateQueueingTicket1:", e);
              }
            }
          }
          if (currentTicketId !== nextTicket._id) {
            shopSessionData.queueingTicketGroups[queueingTicketGroupIndex].currentTicket = nextTicket._id;
          }
          shopSessionData.queueingTicketGroups[queueingTicketGroupIndex].callingTicket = null;
          try {
            await this.$feathers
              .service("shopSessions")
              .patch(this.$shopSession.shopSessionData._id, { ...shopSessionData });
          } catch (e) {
            console.log("Error updating updateQueueingTicket2:", e);
          }
          try {
            await this.debouncedCallTicket(nextTicket._id);
            this.callingOverlay = true;
            this.callingNum = nextTicket.ticketNum;
          } catch (e) {
            console.log("Error updating updateQueueingTicket3:", e);
          }
        }
      }
    }
    // setTimeout(() => {
    //   this.callingOverlay = false;
    // }, 8000);
  }

  @Watch("callingNumBoolean")
  onCallingNumBooleanUpdated() {
    this.callingOverlay = this.callingNumBoolean;
  }

  updateNextTicketAnimation(queueingGroupId) {
    let nextTicketAnimation = { ...this.nextTicket };
    nextTicketAnimation[queueingGroupId] = true;
    this.nextTicket = nextTicketAnimation;
    setTimeout(async () => {
      nextTicketAnimation[queueingGroupId] = false;
      this.nextTicket = { ...nextTicketAnimation };
    }, 700);
  }

  //for table editor
  get hideFloorPlan() {
    return this.$shop.shopData && (this.$shop.localOptions.hideFloorPlan || this.$shop.shopData?.hideFloorPlan);
  }

  get shopSkipAssignTable() {
    return this.$shop?.shopData?.queueingSkipAssignTable;
  }
}
