import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand } from "../index";
import type { PrintJob, PrintQueue } from "../printQueue";
import { wrapVue } from "pos-printer";
import { BBPOSHTMLPrintSequence } from "../printSequence/bbpos";

export interface BBPOSConf extends PrinterConf {
  port: "bbpos";
}

export class BBPOSPrinter extends PrinterBase<BBPOSConf, BBPOSConf> {
  constructor(server: PrinterServer, conf: BBPOSConf) {
    super(server, conf);
  }

  createSequence<T>(context?: Vue): T {
    if (this.conf.type === "thermal") {
      return new BBPOSHTMLPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
    }
    return super.createSequence<T>(context);
  }

  get printerOpts() {
    if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return super.printerOpts;
      }
    }
    return [];
  }

  onDisconnectedCore() {}

  async initCore() {}

  async tryConnectCore() {}

  async printCore(job: PrintJob) {
    try {
      let html: string;
      html = job.data.toString();
      console.log(html);

      const pages = html.split("</html>").filter(it => !!it);
      for (let page of pages) {
        const printData = page + "</html>";
        if (this.conf.opts?.isBBPay) {
          await this.parent.callBBPOS(
            {
              transactionType: "PRINT",
            },
            undefined,
            {
              body: Array.from(Buffer.from(JSON.stringify({
                printData,
              }))),
              contentType: "application/json",
            },
          );
        } else {
          await this.parent.callBBPOS({
            transactionType: "PRINT",
            printData,
          });
        }
      }
    } catch (e) {
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      } else {
        if (e.message === "NO PAPER OR COVER OPENED") {
          job.retryable = false;
        }
        throw e;
      }
    }
  }

  async disconnectCore() {}

  async requestNewDeviceCore() {
    return this.conf;
  }
}
