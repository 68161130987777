import { PrintSequence, TextAlign } from "..";
import { RawLine } from '../rawLine'
import _ from 'lodash'
import { WrappedContext } from '../../common'
import type { PrinterBaseConf } from "../../printers/baseConf";

export class FeieyunPrintSequence extends PrintSequence {
    tagMode: 'xpyun' | 'feieyun' = 'xpyun'

    constructor(public context : WrappedContext, printer : PrinterBaseConf) {
        super(context, printer);
        this.tagMode = printer.conf.opts?.features?.tagMode ?? 'xpyun'
    }

    async getImage(url : string, numChars : number, hiRes? : boolean) : Promise<RawLine[]> {
        console.warn("Not supported: getImage");
        return [new RawLine(Buffer.from(`<IMG></IMG>`), this.currentLineWidth)];
    }

    async printImage(url : string, height : number, hiRes? : boolean) {
        this.raw(Buffer.from(`<IMG></IMG>`));
        return this;
    }

    printQR(url : string, numChars : number, width?: number) {
        if(!url) return;
        if(this.tagMode === 'xpyun') {
            return this.text(`<QRCODE s=${width || 0} e=L l=${this.currentAlign === TextAlign.Left ? 'left' : this.currentAlign === TextAlign.Right ? 'right' : 'center'}>${url}</QRCODE>`);
        }
        else if(this.qrCount++ === 0) {
            this.text(`<QR>${url}</QR>`);
            return this;
        } else {
            return this.text(url);
        }
    }

    qrCount = 0;

    getQR(url : string, size : number) {
        if(this.tagMode === 'xpyun') {
            return [new RawLine(Buffer.from(`<QRCODE s=0 e=L l=${this.currentAlign === TextAlign.Left ? 'left' : this.currentAlign === TextAlign.Right ? 'right' : 'center'}>${url}</QRCODE>`), size)];
        }
        else if(this.qrCount++ === 0) {
            return [new RawLine(Buffer.from(`<QR>${url}</QR>`), size)];
        } else {
            return [new RawLine(Buffer.from(url), url.length)];
        }
    }

    feed(n : number) {
        for(let i = 0; i < Math.ceil((n / 5)); i++)
            this.raw(Buffer.from("<BR>"));
        return this;
    }

    cut() {
        this.needCut = true;
        return this;
    }

    needCut = false;
    needCashBox = false;
    currentAlign = TextAlign.Left;
    currentWidth = 0;
    currentHeight = 0;

    align(n : TextAlign) {
        this.currentAlign = n;
        return this;
    }

    fontSize(w? : number, h? : number) {
        this.currentWidth = w ?? 0;
        this.currentHeight = h ?? 0;
        return this;
    }

    get fontWidthScale() {
        return this.currentWidth + 1;
    }

    get currentLineWidth() {
        return Math.floor(this.lineWidth / this.fontWidthScale);
    }

    getText(text : string) {
        return Buffer.from(text, "utf8");
    }

    getLine() {
        return Buffer.from("<BR>");
    }

    isBold = false;

    bold(on : boolean) {
        this.isBold = on || false;
        return this;
    }

    getPreLine() {
        if(this.needCut) {
            this.raw(Buffer.from("<CUT>"));
            this.needCut = false;
        }
        if(this.isBold) {
            this.raw(Buffer.from("<BOLD>"));
        }


        if(this.currentAlign === TextAlign.Left) {
            this.raw(Buffer.from(this.tagMode === 'xpyun' ? "<L>" : ""));
        } else if(this.currentAlign === TextAlign.Center) {
            this.raw(Buffer.from("<C>"))
        } else if(this.currentAlign === TextAlign.Right) {
            this.raw(Buffer.from(this.tagMode === 'xpyun' ? "<R>" : "<RIGHT>"));
        }

        if(this.currentWidth === 0 && this.currentHeight === 0) {
            this.raw(Buffer.from("<N>"))
        }
        else if(this.currentWidth === 1 && this.currentHeight === 1) {
            this.raw(Buffer.from("<B>"))
        } else if(this.currentWidth === 1 && this.currentHeight === 0) {
            this.raw(Buffer.from("<W>"))
        } else if(this.currentHeight === 1 && this.currentWidth === 0) {
            this.raw(Buffer.from("<H>"))
        } else if(this.currentWidth === 2 && this.currentHeight === 0) {
            this.raw(Buffer.from("<W2>"))
        } else if(this.currentHeight === 2 && this.currentWidth === 0) {
            this.raw(Buffer.from("<H2>"))
        } else if(this.currentWidth === 2 && this.currentHeight === 2) {
            this.raw(Buffer.from("<B2>"))
        } else if(this.tagMode === 'xpyun') {
            this.raw(Buffer.from(`<FONT w="${this.currentWidth}" h="${this.currentHeight}">`))
        } else {
            console.warn("Invalid combination", this.currentWidth, this.currentHeight, this.currentAlign)
        }

        return Buffer.from("");
    }

    getPostLine() {

        if(this.currentWidth === 0 && this.currentHeight === 0) {
            this.raw(Buffer.from("</N>"))
        }
        else if(this.currentWidth === 1 && this.currentHeight === 1) {
            this.raw(Buffer.from("</B>"))
        } else if(this.currentWidth === 1 && this.currentHeight === 0) {
            this.raw(Buffer.from("</W>"))
        } else if(this.currentHeight === 1 && this.currentWidth === 0) {
            this.raw(Buffer.from("</H>"))
        } else if(this.currentWidth === 2 && this.currentHeight === 0) {
            this.raw(Buffer.from("</W2>"))
        } else if(this.currentHeight === 2 && this.currentWidth === 0) {
            this.raw(Buffer.from("</H2>"))
        } else if(this.currentWidth === 2 && this.currentHeight === 2) {
            this.raw(Buffer.from("<B2>"))
        } else if(this.tagMode === 'xpyun') {
            this.raw(Buffer.from(`</FONT>`))
        } else {
            console.warn("Invalid combination", this.currentWidth, this.currentHeight, this.currentAlign)
        }

        if(this.currentAlign === TextAlign.Left) {
            this.raw(Buffer.from(this.tagMode === 'xpyun' ? "</L>" : ""));
        } else if(this.currentAlign === TextAlign.Center) {
            this.raw(Buffer.from("</C>"))
        } else if(this.currentAlign === TextAlign.Right) {
            this.raw(Buffer.from(this.tagMode === 'xpyun' ? "</R>" : "</RIGHT>"));
        }


        if(this.isBold) {
            this.raw(Buffer.from("</BOLD>"));
        }

        return Buffer.from("");
    }

    printCode(type : string, code : string) {
        switch(type) {
            case 'code128':
                this.raw(Buffer.from(`<BC128_A>${code}</BC128_A>`));
                break;
            default:
                throw new Error("Not supported code: " + type);
        }

        return this;
    }

    cashBox(which : number, time : number) {
        this.needCashBox = true;
        return this;
    }

    getJobOpts() {
        return {
            type: 'feieyun',
            cashBox: this.needCashBox,
        }
    }
}





