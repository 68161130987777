
import { Component, Vue, Prop, Watch } from "@feathers-client";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { deepCopy } from "@firebase/util";
import Percentage from "../../components/Percentage.vue";
import _ from "lodash";
interface ObjectFromDB {
  [date: string]: {
    value: number;
  };
}

@Component({
  components: {
    Percentage,
    VueApexCharts,
  },
})
export default class smallBarChart extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: true }) type!: string;
  @Prop() startDate!: Date;
  @Prop() endDate!: Date;
  @Prop() previousStartDate!: Date;
  @Prop() previousEndDate!: Date;
  @Prop({ type: Object, required: true }) dataObject!: ObjectFromDB;
  @Prop({ type: Object, required: true }) previousDataObject!: ObjectFromDB;

  formatDataObject = [];
  formatPreviousDateObject = [];
  seriesData: any;
  dataObjectUpdate: any;

  get optionsSmall() {
    return {
      colors: ["#FF9800"],
      chart: {
        type: "bar",
        // width: '100%',
        background: "#fff",
        // redrawOnParentResize: true,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: this.$t("cahrtMessage.noData"),
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        // mode: 'light',
        // palette: 'palette1',
        monochrome: {
          enabled: false,
          color: "#FEB019",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      series: this.series,
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          format: "dd",
        },
        axisTicks: {
          show: false,
        },
      },
    };
  }

  get series() {
    if (!this.dataObject) {
      return [];
    }
    let data = this.dataObject;
    let seriesDataAfterGen = this.genChartSeriesElements(data);
    this.seriesData = this.transformData(seriesDataAfterGen);
    let seriesDataCopy = [...this.seriesData];
    let series = seriesDataCopy.map(data => {
      return {
        name: data.name,
        data: this.fillMissingDataPointsOthers(data.data),
      };
    });
    return series;
  }

  get sumValues() {
    if (!this.dataObject || !Object.values(this.dataObject).length) {
      return 0;
    }
    return _.sumBy(Object.values(this.dataObject), d => Math.abs((d as any).value));
  }

  get sumPreviousValues() {
    if (!this.previousDataObject || !Object.values(this.previousDataObject).length) {
      return 0;
    }
    return _.sumBy(Object.values(this.previousDataObject), d => Math.abs((d as any).value));
  }

  formatDate(date) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
  }

  genChartSeriesElements(latestArray) {
    let latest = _.map(latestArray, (v, k) => ({
      date: k,
      value: v.value,
    }));
    let latest2 = this.checkAndAddDate(latest, this.startDate, this.endDate);
    return latest2;
  }

  checkAndAddDate(oldArray, startDate, endDate, emptyValue: any = []) {
    let arrayBeforeSortByDate = [...oldArray];
    let array = arrayBeforeSortByDate.sort((a, b) =>
      new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? 1 : -1,
    );
    if (array) {
      const firstDate = moment(array[0]?.date);
      const lastDate = moment(array[array.length - 1]?.date);

      // Check Start Date
      if (!moment(startDate).isSame(firstDate, "day")) {
        const newEntry = {
          date: this.formatDate(startDate),
          value: 0,
        };
        array.unshift(newEntry);
      }
      // Check End Date
      if (!moment(endDate).isSame(lastDate, "day")) {
        const newEntry = {
          date: this.formatDate(endDate),
          value: 0,
        };
        array.push(newEntry);
      }
      return array;
    }
  }

  transformData(data) {
    if (data) {
      let percent = data
        .filter(obj => moment(obj.date).isBetween(this.startDate, this.endDate, null, "[]"))
        .map(obj => ({ x: moment(obj.date).format("MM-DD"), y: Math.round(obj.value * 10000) / 100 }));
      let normal = data
        .filter(obj => moment(obj.date).isBetween(this.startDate, this.endDate, null, "[]"))
        .map(obj => ({ x: moment(obj.date).format("MM-DD"), y: Math.round(Math.abs(obj.value) * 100) / 100 }));
      return [
        {
          name: this.$t(this.title),
          data: this.type === "percentage" ? percent : normal,
        },
      ];
    }
  }

  fillMissingDataPointsOthers(data, defaultValue = 0) {
    // done series for week or month
    if (data && data.length) {
      const newData: any = [];
      const dates = data.map(d => d.x);
      const startDate = moment(dates[0], "MM-DD");
      const endDate = moment(dates[dates.length - 1], "MM-DD");
      const currentDate = startDate;

      while (currentDate.diff(endDate, "days") <= 0) {
        const dateString = currentDate.format("MM-DD");
        const dataIndex = dates.indexOf(dateString);
        if (dataIndex > -1) {
          // Date already exists in the data array
          newData.push({
            x: moment(data[dataIndex].x, "MM-DD"),
            y: data[dataIndex].y,
          });
        } else {
          // Date is missing, add a new data point with the default value
          newData.push({
            x: moment(dateString, "MM-DD"),
            y: defaultValue,
          });
        }
        currentDate.add(1, "day");
      }
      return newData;
    } else {
      return [];
    }
  }
}
