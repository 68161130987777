
import _ from "lodash";
import { Component, Prop, Vue, PropSync } from "nuxt-property-decorator";

@Component
export default class MerchantCart extends Vue {
  @Prop()
  title: string;

  @Prop()
  confirmText: string;

  @Prop({ type: Number, default: 10000000 })
  max: number;

  @PropSync("value")
  inputValue: number;

  @PropSync("string")
  stringValue: string;
}
