
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

const defaultOptions = {
  splitOrder: true,
  splitQuantity: false,
  printQR: true,
  source: [],
  preferShortName: false,
  printProductOptionName: true,
  showUserInfo: false,

  printNew: true,
  printMove: true,
  printCancel: true,
  printReprint: true,
  printEdit: false,

  tableOnlineOrderPostfix: "",
  tableAutoPrintOnlineOrder: false,
  tableAutoPrintOnlineOrderSplitTable: false,
  tablePrintToPayWhenCheckBill: false,
  tableDontAutoPrintReceiptCash: false,
  tableDontAutoPrintReceiptAll: false,
  autoPrintCancelReceipt: false,
  dontPrintZeroProduct: false,
};

@Component({
  components: {
    EditorCheckbox,
    EditorTextField,
  },
})
export default class PrintJobOptions extends Vue {
  @Prop()
  type: string;

  @Prop()
  value: any;

  @Prop()
  name: string;

  @Prop()
  label: string;

  get jobOptions() {
    const item: typeof defaultOptions = {} as any;
    const self = this;
    for (let key of Object.keys(defaultOptions)) {
      Object.defineProperty(item, key, {
        get() {
          return self.value?.[key] ?? defaultOptions[key];
        },
        set(v) {
          self.$emit("input", { ...defaultOptions, ...(self.value || {}), [key]: v });
        },
      });
    }
    return item;
  }
}
