
import { Component, Prop, Vue } from "@feathers-client";
import { cond, over } from "lodash";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  compile,
  compileLabel,
} from "pos-printer-template";
import { parseFont } from "pos-printer/labelSequence/base";

@Component
export default class PrinterEditorBlock extends Vue {
  @Prop() template!: PrinterTemplateNodeBlock;

  @Prop() context: any;

  @Prop(Boolean)
  inBlock: boolean;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  @Prop(Boolean)
  svg: boolean;

  get isSpan() {
    return this.template.elementNode.tag === "span";
  }

  get children() {
    const children: {
      span: boolean;
      children: { item: PrinterTemplateNodeBase<any>; condition: boolean }[];
    }[] = [];
    let lastCondFailed = true;

    for (let item of this.template.children) {
      let curCondition = true;
      if (item.condNeedFailed && !lastCondFailed) {
        curCondition = false;
      } else if (item.cond) {
        if (!item.cond.eval(this.context)) {
          curCondition = false;
        }
      }

      lastCondFailed = !curCondition;

      let isSpan = false;
      if (
        this.svg ||
        item instanceof PrinterTemplateNodeInterpolation ||
        item instanceof PrinterTemplateNodeText ||
        // only check for <template>
        item.constructor === PrinterTemplateNodeTemplate ||
        (item instanceof PrinterTemplateNodeBlock && item.elementNode.tag === "span")
      ) {
        isSpan = true;
      }

      const last = children.at(-1);
      if (!last || last.span !== isSpan) {
        children.push({ span: isSpan, children: [{ item, condition: curCondition }] });
      } else {
        last.children.push({ item, condition: curCondition });
      }
    }
    return children;
  }

  get style() {
    return {
      ...(this.fontSize !== undefined
        ? {
            fontSize: `${this.fontSize}px`,
          }
        : {}),
      ...(this.color !== undefined
        ? {
            color: this.color ? "red" : "var(--text-primary)",
            "--current-color": this.color ? "red" : "var(--text-primary)",
          }
        : {}),
      ...(this.fontWeight !== undefined
        ? {
            fontWeight: this.fontWeight ? "bold" : "normal",
          }
        : {}),
      ...(this.fontItalic !== undefined
        ? {
            fontStyle: this.fontItalic ? "italic" : "normal",
          }
        : {}),
      ...(this.align !== undefined
        ? {
            textAlign: this.align,
          }
        : {}),
      ...(this.svg
        ? {
            overflow: "hidden",
            display: "-webkit-box",
            textOverflow: "ellipsis",
            "-webkit-line-clamp": this.size[1] || 1,
            "-webkit-box-orient": "vertical",
          }
        : {}),
    };
  }

  get blockStyle() {
    return {
      ...this.style,
      ...(this.align !== undefined
        ? {
            alignSelf: {
              left: "flex-start",
              center: "center",
              right: "flex-end",
            }[this.align],
          }
        : {}),
    };
  }

  get fontSize() {
    let msize: number;
    const sizeKey = this.svg ? "fontSize" : "size";
    if (this.template.props[sizeKey]) {
      const size = this.template.props[sizeKey].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        msize = +size.split(",")[0];
      } else if (typeof size === "string" || typeof size === "number") {
        msize = +size;
      }
    }

    if (this.svg) {
      const chinese = +(this.template.props["chinese"]?.eval?.(this.context) ?? "0");
      const size = parseFont(this.template.props["font"]?.eval?.(this.context) || (chinese ? "TST24.BF2" : "1"));

      return size[1] * (msize || 1);
    }

    const fontSize = this.svg ? undefined : this.template.props["fontSize"]?.eval?.(this.context);
    let finalPxSize = (msize || 0) * 12 + 24;
    if (typeof fontSize === "string" || typeof fontSize === "number") {
      finalPxSize = +fontSize;
    } else if (msize === undefined) {
      return undefined;
    }

    return finalPxSize;
  }

  get color() {
    if (this.template.props["color"]) {
      const color = this.template.props["color"].eval(this.context);
      if (color === "red" || color == 1 || color === true) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  get fontWeight() {
    if (this.template.props["weight"]) {
      const weight = this.template.props["weight"].eval(this.context);
      if (weight === "normal") {
        return false;
      } else if (weight === "bold" || !weight) {
        return true;
      }
    }
  }

  get fontItalic() {
    if (this.template.props["fontStyle"]) {
      const fontStyle = this.template.props["fontStyle"].eval(this.context);
      if (fontStyle === "normal") {
        return false;
      } else if (fontStyle === "italic" || !fontStyle) {
        return true;
      }
    }
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }

  get position() {
    if (this.template.props["pos"]) {
      const size = this.template.props["pos"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [
        +(this.template.props["x"]?.eval?.(this.context) || "0"),
        +(this.template.props["y"]?.eval?.(this.context) || "0"),
      ];
    }
  }

  get size() {
    if (this.template.props["size"]) {
      const size = this.template.props["size"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [
        +(this.template.props["w"]?.eval?.(this.context) || "0"),
        +(this.template.props["h"]?.eval?.(this.context) || "0"),
      ];
    }
  }
}
