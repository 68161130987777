
import helper from './helper'
import _ from 'lodash'
import { Context } from '@nuxt/types/app';
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import type VueRouter from 'vue-router'
import type { Route } from 'vue-router'
import type { RouteConfig } from '@nuxt/types/config/router';
import { getOptions } from './util';

export interface PageInfo {
    show?: boolean
    noDrawer?: boolean
    noHeader?: boolean
    fullPage? : boolean
    group?: string
    icon?: string
    action?: string
    name?: string
    order? : number
    background? : string
}

type RouteExtend = RouteConfig & {
    page? : PageInfo
}

interface VueRouterExtend {
    loadedModules: {
        default: RouteExtend[]
        name: string
    }[]
    updateModules() : Promise<void>;
}

export interface GUIHeader {
    title: string
    action: string
    href: string
    items?: GUIHeader[]
    gpKey?: string
    order : number
}[]

@Component
export class PluginClass extends Vue {
    get routes() {
        const routes : RouteExtend[] = (<any>this.$router).routes;
        return _.uniqBy(routes, r=> r.component);
    }

    get pageList() {
        let rootMenu : GUIHeader[] = [
            // ...(this.$schemas?.pageList ?? [])
        ]
        const allMenus : GUIHeader[] = [];

        for(let route of this.routes) {
            const show = route.page?.show;
            if(!show) continue;
            
            const gp = (route.page?.group || '').split('.').filter(it => !!it);
            let curList : GUIHeader[] = rootMenu;
            const gpPath = [];
            for(let gpKey of gp) {
                gpPath.push(gpKey);
                let gpItem = curList.find(it => it.gpKey === gpKey);
                if(!gpItem) {
                    curList.push(gpItem = {
                        title: 'pages.groups.' + gpPath.join('_'),
                        action: '',
                        href: '',
                        items: [],
                        gpKey: gpKey,
                        order: 9999,
                    })
                    allMenus.push(gpItem);
                }
                curList = gpItem.items;
            }
            const item : GUIHeader = {
                title: route.page?.name,
                action: route.page?.icon || route.page?.action,
                href: route.path,
                order: route.page?.order ?? 9999,
            }
            curList.push(item)
            allMenus.push(item);
        }

        allMenus.forEach(item => {
            if(item.items) {
                item.order = item.items[0].order;
            }
        })

        allMenus.forEach(item => {
            if(item.items) {
                item.items = _.orderBy(item.items, it => it.order)
            }
        })

        allMenus.reverse().forEach(item => {
            if(item.items) {
                item.action = item.items[0].action;
            }
        })

        rootMenu = _.orderBy(rootMenu, it => it.order);

        return rootMenu;
    }
}

const plugin = (ctx : Context) => {
    const router : VueRouter & VueRouterExtend = <any>ctx.app.router;
    const store = ctx.store;


    function updateRoute(to : Route) {
        const page = (to?.matched?.[0]?.components?.default as any)?.options?.__page as PageInfo;
        if(page) {
            store.commit('SET_TITLE', {
                title: page.name,
                noDrawer: page.noDrawer,
                noHeader: page.noHeader,
                fullPage: page.fullPage,
                background: page.background,
            })
        }
    }
    router.afterEach(updateRoute);
    updateRoute(ctx.route);

    const plugin = new PluginClass(getOptions(ctx.app));
    return plugin;
}

export default helper('uiApp', plugin);

declare module 'vue/types/vue' {
    export interface Vue {
        $uiApp: PluginClass
    }
}

