import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import errors from "@feathersjs/errors";
import _ from "lodash";
import type { OfflineManager } from "../../";
import { computeLineTaxAndSurcharge } from "~/common/table/util";

export async function create(
  hook: MHookContext<AdminApplication, CreateType<"tableSessions/pay">, FindType<"tableSessions">>,
) {
  const session = await hook.app.service("tableSessions").get(hook.data.session, {
    query: {},
  });

  const offline: OfflineManager = (hook as any).$offline;

  const shop = offline.root?.$shop?.shopData;

  const payment = hook.data.payment ? await hook.app.service("payments").get(hook.data.payment) : null;

  const methodRef = payment?.methodRef ? offline.root?.$shop?.paymentMethodDict?.[String(payment.methodRef)] : null;

  if ((session.status !== "toPay" && payment?.status === "done") || (!hook.data.payment && session.outstanding)) {
    throw new errors.BadRequest("Invalid state, order not payable");
  }

  let paymentAmount = payment?.status === "done" ? payment?.amount ?? 0 : 0;

  const totalPaidBefore = _.sumBy(
    session.payments.filter(it => !checkID(it.payment, payment)),
    p => p.amount,
  );
  const totalPaidAfter = totalPaidBefore + paymentAmount;
  const newOutstanding = Math.max(0, session.amount - totalPaidAfter);
  const fullyPaid = newOutstanding === 0;

  let coins = null;
  let afterCoinBalance = null;

  if (payment) {
    if (payment.status === "done") {
      session.payments.push({
        payment: payment._id,
        amount: paymentAmount,
        paymentMethod: payment.methodRef,
        paymentMethodNetwork: payment.methodNetwork,
        paymentMethodSubType: payment.methodSubType,
        tips: payment.tips,
        surcharge: payment.surcharge,
        surchargeWithoutTax: payment.surchargeWithoutTax,
      });
    } else {
      session.payments = session.payments.filter(it => !checkID(it.payment, payment));
    }
  }

  if (fullyPaid) {
    const lineTaxAndSurcharge = computeLineTaxAndSurcharge(session as any);

    for (let product of session.products) {
      if (product.status === "cancel" || product.status === "pending") continue;
      const line = lineTaxAndSurcharge[product.id];

      product.surchargePercent = line?.surchargePercent >> 0;
      product.taxPercent = line?.taxPercent ?? 0;
      product.totalSurcharge = line?.totalSurcharge ?? 0;
      product.totalTax = line?.totalTax ?? 0;
      product.taxGroup = line?.taxGroup ?? null;
    }
  }

  let patch = await hook.app.service("tableSessions").patch(session._id, {
    ...(payment
      ? payment.status === "done"
        ? {
            paymentMethod: payment.method,
            payment: payment._id,
            paymentStaff: payment.staff,
          }
        : {}
      : {}),
    payments: session.payments,

    ...(methodRef?.type === "entFee"
      ? {
          entFee:
            (session.entFee ?? 0) +
            (payment.status === "done" ? payment.amount : payment.status === "refunded" ? -payment.amount : 0),
        }
      : {}),
    ...(fullyPaid
      ? {
          // paidTime: payment?.date ?? new Date(),
          status: "done",
          coins,
          afterCoinBalance,
          receiptPrintTime: 0,
          products: session.products,
        }
      : session.status === "done"
        ? {
            status: "toPay",
            receiptPrintTime: 0,
          }
        : {}),
    paidTime: payment?.date ?? new Date(),
    outstanding: newOutstanding,
  });

  if (fullyPaid) {
    // send take away order to kitchen
    if (patch.type === "takeAway" || patch.type === "dineInNoTable" || patch.type === "delivery") {
      const products = patch.products.filter(it => it.status === "hold");
      for (let p of products) {
        p.status = "init";
      }

      patch = await hook.app.service("tableSessions/order").patch(
        null,
        {
          session: patch._id,
          products: products,
          actionSource: hook.data.actionSource,
        },
        {
          addTvStatus:
            patch.type === "dineInNoTable" && offline.root?.$shop?.shopData?.onlineDineInNoTableEnabledFixedCode
              ? false
              : true,
        },
      );
    }

    if (offline.turnCloud && session.twTaxType !== "none") {
      patch = await offline.turnCloud.assign(hook, patch);
    }
  }

  hook.result = patch;
}
