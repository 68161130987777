import { Vue, Component, Prop } from "@feathers-client";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeCol,
  PrinterTemplateNodeLabelPage,
  PrinterTemplateNodeLabelText,
  PrinterTemplateNodeRow,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  PrinterTemplateNodeInterpolation,
} from "pos-printer-template";

@Component
export default class PrinterEditorEditBase extends Vue {
  @Prop()
  selectedNode: PrinterTemplateNodeBase<any>;

  @Prop()
  selectedPath: {
    node: PrinterTemplateNodeBase<any>;
    path: string;
  }[];

  @Prop()
  selectedContext: any;

  @Prop()
  selectedNodeIdx: number;

  @Prop()
  type: string;

  get conditional() {
    return !!this.selectedNode.cond || this.selectedNode.condNeedFailed;
  }

  get selectedParent() {
    if (this.selectedNodeIdx > 0) {
      return this.selectedPath[this.selectedNodeIdx - 1].node as PrinterTemplateNodeTemplate<any>;
    }
  }

  get parentCol() {
    let cur = this.selectedNodeIdx;
    while (cur >= 0 && !(this.selectedPath[cur].node instanceof PrinterTemplateNodeCol)) {
      cur--;
    }
    if (cur < 0) return;
    return this.selectedPath[cur].node as PrinterTemplateNodeCol;
  }

  get parentRow() {
    let cur = this.selectedNodeIdx;
    while (cur >= 0 && !(this.selectedPath[cur].node instanceof PrinterTemplateNodeRow)) {
      cur--;
    }
    if (cur < 0) return;
    return this.selectedPath[cur].node as PrinterTemplateNodeRow;
  }

  get parentTextBlock() {
    let cur = this.selectedNodeIdx;
    while (cur >= 0 && !(this.selectedPath[cur].node instanceof PrinterTemplateNodeBlock)) {
      cur--;
    }
    if (cur < 0) return;
    return this.selectedPath[cur].node as PrinterTemplateNodeBlock;
  }

  get parentRootTextBlockOrRow() {
    let cur = this.selectedNodeIdx;
    while (cur >= 0) {
      const node = this.selectedPath[cur].node;
      if (
        (node instanceof PrinterTemplateNodeBlock && node.isRoot && this.checkNonText(node)) ||
        node instanceof PrinterTemplateNodeRow
      ) {
        return node;
      }
      cur--;
    }
  }

  checkNonText(node: PrinterTemplateNodeBlock) {
    let flag = true;
    node.walk(n => {
      if (
        n instanceof PrinterTemplateNodeText ||
        n instanceof PrinterTemplateNodeBlock ||
        n instanceof PrinterTemplateNodeInterpolation
      ) {
      } else {
        flag = false;
      }
    });
    return flag;
  }

  get labelTextNode() {
    let cur = this.selectedNodeIdx;
    while (cur >= 0 && !(this.selectedPath[cur].node instanceof PrinterTemplateNodeLabelText)) {
      cur--;
    }
    if (cur < 0) return;
    return this.selectedPath[cur].node as PrinterTemplateNodeLabelText;
  }

  get positionNode() {
    if (this.labelTextNode) return this.labelTextNode;
    return this.selectedNode;
  }

  get parentPreContainer() {
    let cur = this.selectedNodeIdx;
    while (
      cur >= 0 &&
      ![PrinterTemplateNodeTemplate, PrinterTemplateNodeLabelPage].includes(
        this.selectedPath[cur].node.constructor as any,
      )
    ) {
      cur--;
    }
    if (cur < 0 || cur + 1 === this.selectedPath.length) return;
    return this.selectedPath[cur + 1].node;
  }

  get selectedParentIndex() {
    return this.selectedParent ? this.selectedParent.children.indexOf(this.selectedNode) : -1;
  }

  get selectedParentCondIndex() {
    let idx = this.selectedParentIndex;
    if (!this.selectedParent) return -1;
    while (idx >= 0 && this.selectedParent.children[idx].condNeedFailed) {
      idx--;
    }
    return idx;
  }

  get selectedParentCondEndIndex() {
    let idx = this.selectedParentIndex;
    if (!this.selectedParent) return -1;
    while (idx + 1 < this.selectedParent.children.length && this.selectedParent.children[idx + 1].condNeedFailed) {
      idx++;
    }
    return idx;
  }

  get selectedParentCount() {
    return this.selectedParent ? this.selectedParent.children.length : 0;
  }

  move(offset: number) {
    if (this.selectedParent) {
      const idx = this.selectedParentCondIndex;
      if (idx >= 0) {
        this.selectedParent.moveChild(
          idx,
          idx + offset,
          this.selectedParentCondEndIndex - this.selectedParentCondIndex + 1,
        );
        this.$emit("update", true);
      }
    }
  }

  clone() {
    if (!this.selectedParent || this.selectedParentIndex < 0) return;
    const children = this.selectedParent.children;
    const newNode = this.selectedNode.clone();

    if (this.selectedParentCondIndex !== this.selectedParentIndex) {
      let first = children[this.selectedParentCondIndex].cond.not();
      let cur = this.selectedParentCondIndex + 1;
      while (cur < this.selectedParentIndex) {
        first = first.and(children[cur].cond.not());
        cur++;
      }
      newNode.cond = first;
      newNode.condNeedFailed = false;
      newNode.updateCond();
    }

    this.insertAfter(newNode);
  }

  insertAfter(newNode: PrinterTemplateNodeBase<any>, afterNode = this.selectedNode, innerSelection: number[] = null) {
    if (!afterNode) return;

    const selectionIdx = this.selectedPath.findIndex(x => x.node === afterNode);
    if (selectionIdx === -1) return;

    const pselection = this.selectedPath[selectionIdx - 1];
    if (!pselection) return;
    const parent = pselection.node as PrinterTemplateNodeTemplate<any>;

    let idx = parent.children.indexOf(afterNode);
    while (idx + 1 < parent.children.length && parent.children[idx + 1].condNeedFailed) {
      idx++;
    }

    parent.insertChild(newNode, idx + 1);
    this.$emit("add", newNode, selectionIdx - 1, innerSelection);
  }

  insertChild(newNode: PrinterTemplateNodeBase<any>, innerSelection: number[] = null) {
    if (!this.selectedNode) return;
    const node = this.selectedNode as PrinterTemplateNodeTemplate<any>;
    node.insertChild(newNode, node.children.length);
    this.$emit("add", newNode, this.selectedNodeIdx, innerSelection);
  }
}
