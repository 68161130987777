
import { Component, Prop, Vue, Watch, VModel } from "nuxt-property-decorator";
import { TableItem } from "~/plugins/table";
import { SceneCanvas } from "konva/lib/Canvas";

@Component
export default class ItemSessionAssignTicket extends Vue {
  @Prop()
  item: TableItem;

  @Prop()
  canvasWidth: number;

  @Prop()
  canvasHeight: number;

  @Prop()
  assigningTable: boolean;

  configLayer = { listening: true, opacity: 0 };
  headerHeight = 56;
  footerHeight = 328;
  cachedSceneCanvas: SceneCanvas;
  cachedSceneValid = false;
  display = "none";

  get width() {
    return this.canvasWidth - 120;
  }

  mounted() {
    if (this.assigningTable) {
      this.onToggleMenu();
    }
  }

  @Watch("assigningTable")
  onToggleMenu() {
    if (this.assigningTable) {
      this.display = "flex";
    } else {
      this.display = "none";
    }
  }
}
