var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['picker relative', { dragging: _vm.dragging }],style:({
    width: _vm.mini ? '2.625rem' : '250px',
    height: _vm.mini ? '2.625rem' : '150px',
  }),on:{"dragenter":_vm.dragenter,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('media-library',{attrs:{"type":_vm.type,"parent":_vm.parent,"dir":_vm.dir,"selecting":""},on:{"selected":_vm.addFiles},model:{value:(_vm.mediaLibrary),callback:function ($$v) {_vm.mediaLibrary=$$v},expression:"mediaLibrary"}}),_c('img',{style:({
      width: '100%',
      height: '100%',
      'object-position': 'center',
      'object-fit': 'contain',
    }),attrs:{"src":_vm.thumb},on:{"click":_vm.pickImage}}),(_vm.minfo && !_vm.minfo.complete)?_c('div',{staticClass:"floating"},[_c('v-progress-circular',{attrs:{"value":_vm.minfo.progress * 100,"color":"blue"}})],1):_vm._e(),((_vm.label || _vm.value) && !_vm.mini)?_c('div',{staticClass:"floatingLabel"},[_c('div',{staticClass:"imagePickerLabel"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.value)?_c('v-btn',{attrs:{"icon":"","target":"_blank","href":_vm.$image(_vm.value),"small":""}},[_c('v-icon',[_vm._v("get_app")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.addFile}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('div',{staticClass:"imagePickerLabel"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.removeImage}},[_c('v-icon',[_vm._v("close")])],1)],1)]):_vm._e(),(_vm.mini)?_c('teleport-menu',{attrs:{"size":"auto","thumb":"start"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('menu-list',{staticClass:"pos-popup"},[_c('menu-list-item',{attrs:{"icon":"image","text":_vm.$t('imagePicker.library')},on:{"click":function($event){(_vm.mediaLibrary = true), (_vm.menu = false)}}}),_c('menu-list-item',{attrs:{"icon":"add","text":_vm.$t('imagePicker.upload')},on:{"click":function($event){_vm.addFile(), (_vm.menu = false)}}}),(_vm.value)?_c('menu-list-item',{attrs:{"icon":"get_app","text":_vm.$t('imagePicker.download'),"href":_vm.$image(_vm.value),"target":"_blank"},on:{"click":function($event){_vm.menu = false}}}):_vm._e(),_c('menu-list-item',{attrs:{"icon":"close","text":_vm.$t('basic.remove')},on:{"click":function($event){_vm.removeImage(), (_vm.menu = false)}}})],1)]},proxy:true}],null,false,907271294),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }