import { splitText } from "../../printSequence/rawLine";
import { fonts, parseFont } from "../base";
import { CanvasPrintJob, LabelCanvasJob } from "./job";
import qrcode from "qrcode";

export interface RenderedImage {
  dataUrl: string;
  width: number;
  height: number;
}

export async function renderToHtml(job: CanvasPrintJob) {
  const images = await render(job);

  return [
    "<html><body>",

    images.map(
      img =>
        `<img src="${img.dataUrl}" width="${img.width}" height="${img.height}" style="border: 1px solid black; margin: 16px;"/>`,
    ),

    "</body></html>",
  ].join("");
}

export async function render(job: CanvasPrintJob, opts?: RenderOptions) {
  const images: RenderedImage[] = [];

  for (let item of job.jobs) {
    for (let i = 0; i < item.count; i++) {
      images.push(await renderJob(item, i, job, opts));
    }
  }

  return images;
}

function getVText(job: LabelCanvasJob, n: number, text: string) {
  if (text.startsWith("@")) {
    const counter = job.counters[+text.slice(1)];

    if (!counter) return "";

    return (+counter.start + n * counter.step).toString().padStart(counter.start.length, "0");
  }
  return text;
}

export interface RenderOptions {
  textColor?: string;
  bgColor?: string;
  strokeTextRect?: boolean;
  textCharMode?: boolean;
  imageReplace?: boolean;
  font?: string;
}

export async function renderJob(job: LabelCanvasJob, n?: number, parent?: CanvasPrintJob, opts?: RenderOptions) {
  const dpi = parent?.dpi ?? 8;

  const canvas = document.createElement("canvas");
  canvas.width = job.w * dpi;
  canvas.height = job.h * dpi;

  const context = canvas.getContext("2d");

  context.fillStyle = opts?.bgColor ?? "white";
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.fillStyle = opts?.textColor ?? "black";

  for (let cmd of job.cmds) {
    switch (cmd.type) {
      case "text":
      case "vtext":
        let text = cmd.type === "text" ? cmd.text : getVText(job, n, cmd.text);
        context.textAlign = "center";
        context.textBaseline = "middle";

        const size = parseFont(cmd.font);
        context.font = `${cmd.font.endsWith("px") ? cmd.font.split(",")[0] : size[0] * cmd.sizeW + "px"} ${
          opts?.font || "monospace"
        }`;

        let x = cmd.x;
        const dx = size[0] * cmd.sizeW;
        const dy = size[1] * cmd.sizeH;

        if (opts?.textCharMode ?? true) {
          const splittedText = splitText(text);
          const sizes = splittedText.map(c => (Buffer.from(c).length > 1 ? dx * 2 : dx)).reduce((a, b) => a + b, 0);

          if (cmd.align === "center") {
            x -= Math.floor(sizes / 2);
          } else if (cmd.align === "right") {
            x -= sizes;
          }

          for (let i = 0; i < splittedText.length; i++) {
            const cx = Buffer.from(splittedText[i]).length > 1 ? dx * 2 : dx;

            if (opts?.strokeTextRect ?? true) {
              context.strokeRect(x, cmd.y, cx, dy);
            }
            context.translate(x + cx / 2, cmd.y + dy / 2);
            context.scale(1, cmd.sizeH / cmd.sizeW);
            context.fillText(splittedText[i], 0, 0);
            context.resetTransform();
            x += cx;
          }
        } else {
          context.textAlign = cmd.align ?? "left";
          context.translate(x, cmd.y + dy / 2);
          context.scale(1, cmd.sizeH / cmd.sizeW);
          context.fillText(text, 0, 0);
          context.resetTransform();
        }

        break;

      case "barcode":
        break;

      case "qrcode":
        const code = qrcode.create(cmd.text, {
          errorCorrectionLevel: cmd.ecc,
        });
        for (let y = 0; y < code.modules.size; y++) {
          for (let x = 0; x < code.modules.size; x++) {
            const bit = code.modules.data[x + y * code.modules.size];
            if (bit) {
              context.fillRect(cmd.x + x * cmd.w, cmd.y + y * cmd.w, cmd.w, cmd.w);
            }
          }
        }
        break;

      case "image":
        const imageData = new ImageData(cmd.data, cmd.w, cmd.h);
        if (opts?.imageReplace ?? true) {
          context.putImageData(imageData, cmd.x, cmd.y);
        } else {
          const bmp = await createImageBitmap(imageData);
          context.drawImage(bmp, cmd.x, cmd.y);
        }
        break;

      case "rect":
        context.fillRect(cmd.x, cmd.y, cmd.w, cmd.h);
        break;
    }
  }

  return {
    dataUrl: canvas.toDataURL("image/png"),
    width: job.w * dpi,
    height: job.h * dpi,
  } as RenderedImage;
}
