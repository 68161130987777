
import { Vue } from "nuxt-property-decorator";

import VueKonva from 'vue-konva'
import Konva from 'konva';
Vue.use(VueKonva)

export * from "./manager"
export * from "./view"

export interface InputBehavior<TEvt extends Event = Event, T = any> {
    type: 'drag' | 'wheel' | 'mousedown' | 'mousemove' | 'mouseup' | 'click' | 'touchstart'
    begin?: (e : Konva.KonvaEventObject<TEvt>) => boolean | T;
    step?: (e : TEvt, state? : T) => boolean;
    cancel?: (e? : TEvt, state? : T) => boolean;
}

export class InputBehaviorHandler {
    on : any = {};
    constructor() {
        this.on = {
            mousedown: this.mousedown.bind(this),
            mousemove: this.getHandler('mousemove'),
            mouseup: this.getHandler('mouseup'),
            wheel: this.getHandler('wheel'),
            mouseleave: this.getHandler('mouseleave'),
            click: this.getHandler('click'),
            touchstart: this.touchstart.bind(this),
            touchend: this.getHandler('touchend'),
        }
    }

    items : InputBehavior[] = [];
    current : InputBehavior;

    add<TEvt extends Event = MouseEvent, T = any>(item : InputBehavior<TEvt, T>) {
        this.items.push(<any>item);
    }

    mousedown(e : Konva.KonvaEventObject<MouseEvent>, restore? : InputBehavior) {
        for(let item of this.items) {
            if(restore) {
                if(restore === item) restore = null;
                continue;
            }
            if(item.type === 'mousedown' || item.type === 'drag') {
                const s = item?.begin(e);
                if(s) {
                    if(item.type === 'drag') {
                        this.beginDrag(item, s, () => {
                            this.mousedown(e, item);
                            this.on.mouseup(e);
                            this.on.click(e);
                        });
                    }
                    e.cancelBubble = true;
                    break;
                }
            }
        }
    }

    getHandler(type : string) {
        return (e : Konva.KonvaEventObject<MouseEvent>) => {
            if(this.current) {
                e.cancelBubble = true;
            } else {
                for(let item of this.items) {
                    if(item.type === type && item?.begin(e)) {
                        e.cancelBubble = true;
                        break;
                    }
                }
            }
        }
    }

    beginDrag(item : InputBehavior, state : any, restore : () => void) {
        this.current = item;
        const mousemove = (e : MouseEvent) => {
            if(!item?.step?.(e, state)) {
                cancel(e);
            }
        }
        const mouseup = (e : MouseEvent) => {
            cancel(e);
        }
        let cancel = (e? : MouseEvent) => {
            if(this.current === item) this.current = null;
            window.removeEventListener('mousemove', mousemove);
            window.removeEventListener('mouseup', mouseup);
            cancel = () => {};
            if(item.cancel?.(e, state)) {
                restore();
            }
        }
        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);
    }

    touchstart(e : Konva.KonvaEventObject<TouchEvent>, restore? : InputBehavior) {
        for(let item of this.items) {
            if(restore) {
                if(restore === item) restore = null;
                continue;
            }
            if(item.type === 'touchstart' || item.type === 'drag') {
                const s = item?.begin(e);
                if(s) {
                    this.beginTouch(e.evt.target as HTMLElement, item, s, (evt : TouchEvent) => {
                        e.evt = evt;
                        this.touchstart(e, item);
                        this.on.touchend(e);
                        this.on.click(e);
                    });
                    e.cancelBubble = true;
                    break;
                }
            }
        }
    }

    beginTouch(elem : HTMLElement, item : InputBehavior, state : any, restore: (evt : TouchEvent) => void) {
        this.current = item;
        const mousemove = (e : TouchEvent) => {
            if(!item?.step?.(e, state)) {
                cancel(e);
            }
        }
        const mouseup = (e : TouchEvent) => {
            cancel(e);
        }
        let cancel = (e? : TouchEvent) => {
            if(this.current === item) this.current = null;
            elem.removeEventListener('touchmove', mousemove);
            elem.removeEventListener('touchend', mouseup);
            cancel = () => {};
            if(item.cancel?.(e, state)) {
                restore(e);
            }
        }
        elem.addEventListener('touchmove', mousemove, { passive: false });
        elem.addEventListener('touchend', mouseup);
    }


}
