
import type { ProductLine, TableSession } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref, FindType } from "@feathers-client";
@Component
export default class CustomProductAdd extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  product: FindType<"customProducts">;

  @Prop({ type: Boolean })
  readonly: boolean;

  get showProductPrice() {
    return this.$shop.localOptions.showProductPrice ?? true;
  }

  async addCustomProduct() {
    await this.session.addToCart(
      {
        quantity: 1,
      },
      {
        customProduct: this.product,
      },
    );
  }

  async editCustomProduct() {
    const resp = await this.$openDialog(
      import("./addDialog.vue"),
      {
        session: this.session,
        currentItem: this.product,
      },
      {
        maxWidth: "max(50vw,500px)",
        contentClass: "h-80vh",
      },
    );
  }
}
