
import { Vue, Component, Prop } from "@feathers-client";

@Component
export default class Error extends Vue {
  @Prop()
  error: any;

  created() {
    this.$store.commit("SET_TITLE", "Error");
    if (this.$store.state.cast) {
      setTimeout(() => {
        this.reload();
      }, 5000);
    }
  }

  reload() {
    location.reload();
  }
}
