
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";
import Dialog from "domore-table/mixins/Dialog";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class HistoryDialog extends mixins(Dialog()) {
  statuses = [
    { key: "allStatus", name: this.$t("takeaway.allStatus") },
    { key: "booking", name: this.$t("reservation.booking") },
    { key: "absent", name: this.$t("reservation.absent") },
    { key: "cancelledBooking", name: this.$t("reservation.cancelledBooking") },
  ];

  startDate = moment().startOf("day").format("YYYY-MM-DD");
  endDate = moment().endOf("day").format("YYYY-MM-DD");
  currentStatus = "allStatus";
  selectedDate = 0;

  get statues() {
    if (this.currentStatus === "allStatus") {
      return this.statuses.map(it => it.key).filter(it => it !== "allStatus");
    } else {
      return this.statuses.find(it => it.key === this.currentStatus).key;
    }
  }

  get query() {
    return {
      status: {
        $in: this.statues, //["booking", "absent", "cancelledBooking"],
      },
      $populate: {
        path: "tables.item",
        select: ["name"],
        limit: 1,
        populate: [
          {
            path: "view",
            select: ["name"],
          },
        ],
      },
      bookedTime: {
        $gte: moment(this.startDate).toDate(),
        $lte: moment(this.endDate).endOf("day").toDate(),
      },
      $sort: {
        bookedTime: 1,
      },
      // $sort: {
      //   // bookedTime: -1,
      //   // _id: -1,
      // },
    };
  }
  get date() {
    return [{ name: this.$t("time.today") }, { name: this.$t("time.tomorrow") }, { name: this.$t("time.month") }];
  }

  pickDate(index) {
    this.selectedDate = index;
    switch (this.selectedDate) {
      case 0:
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().endOf("day").format("YYYY-MM-DD");
        break;
      case 1:
        this.startDate = moment().add(1, "days").format("YYYY-MM-DD");
        this.endDate = moment().add(1, "days").endOf("day").format("YYYY-MM-DD");
        break;
      case 2:
        this.startDate = moment().startOf("month").format("YYYY-MM-DD");
        this.endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
    }
  }
}
