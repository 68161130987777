
import { DeviceBase, DeviceConf } from './base'
import { install as initBluetooth } from '../utils/bluetoothPolyfill'

export interface BluetoothConf extends DeviceConf {
    service? : string
    oservice? : string
    char? : string
    all? : boolean
    port : 'bluetooth'
    identifier?: string
    extraFilters? : {
      service : string
    }[]
}

export class BLEDevice extends DeviceBase<BluetoothDevice, BluetoothConf> {
    constructor(conf : BluetoothConf) {
        super(conf);
    }

    server : BluetoothRemoteGATTServer;

    onDisconnectedCore() {
        this.server = null;
    }

    async tryConnectCore() {
        if(this.autoDisconnected) {
            this.autoDisconnected = false;
            try {
                if(this.device.watchAdvertisements) {
                    await this.device.watchAdvertisements();
                    await new Promise<void>((resolve) => {
                        const listen = () => {
                            resolve();
                            this.device.removeEventListener('advertisementreceived', listen);
                        }
                        this.device && this.device.addEventListener('advertisementreceived', listen);
                    })
                    await (this.device as any)?.unwatchAdvertisements?.();
                }
            } catch(e) {
                console.warn(e);
            }
        }
        this.server = await this.device.gatt.connect();
        await this.initCore();
        await new Promise(resolve => setTimeout(resolve, 100))
    }

    async initCore() {
        this.device && this.device.addEventListener(
            "gattserverdisconnected",
            this.onDisconnected
        );
    }

    async disconnectCore() {
        const d = this.device;
        d.removeEventListener(
            "gattserverdisconnected",
            this.onDisconnected
        );
        this.onDisconnectedCore();
        try {
            await d.gatt.disconnect();
        } catch(e) {
            console.log(e);
        }
    }

    async requestNewDeviceCore() {
        await initBluetooth();
        if(this.conf.identifier && navigator.bluetooth.getDevices) {
            try {
                const devices = await navigator.bluetooth.getDevices();
                const device = devices.find(it => it.id === this.conf.identifier);
                if(device && device.watchAdvertisements) {
                    await device.watchAdvertisements();
                    await new Promise<void>((resolve) => {
                        const listen = () => {
                            resolve();
                            device.removeEventListener('advertisementreceived', listen);
                        }
                        device.addEventListener('advertisementreceived', listen);
                    })
                    return device;
                }
            } catch(e) {
                console.warn(e);
            }
        }
        const conf = this.conf;
        const r : RequestDeviceOptions = conf.address ? {
            filters: [
                {
                    name: conf.address,
                    ...(conf.oservice ? {
                      optionalServices: [conf.oservice],
                  } : {
                  }),
                }
            ]
        } : {
            ...(conf.all ? {
                acceptAllDevices: true
            } : {
                filters: [
                    {
                        services: [conf.service || "000018f0-0000-1000-8000-00805f9b34fb"]
                    },
                    ...(conf.extraFilters || []).map(it => ({
                        services: [it.service]
                    }))
                ]
            }),
            ...(conf.oservice ? {
                optionalServices: [conf.oservice],
            } : {
            }),
        };

        const device = await navigator.bluetooth.requestDevice(r);
        conf.address = device.name;
        conf.identifier = device.id;
        return device;
    }
}

