import { render, staticRenderFns } from "./PosView.vue?vue&type=template&id=6eaddfd6&scoped=true"
import script from "./PosView.vue?vue&type=script&lang=ts"
export * from "./PosView.vue?vue&type=script&lang=ts"
import style0 from "./PosView.vue?vue&type=style&index=0&id=6eaddfd6&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eaddfd6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VApp,VBtn,VIcon,VSlideXReverseTransition,VSlideYTransition})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6eaddfd6')) {
      api.createRecord('6eaddfd6', component.options)
    } else {
      api.reload('6eaddfd6', component.options)
    }
    module.hot.accept("./PosView.vue?vue&type=template&id=6eaddfd6&scoped=true", function () {
      api.rerender('6eaddfd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/queueing/PosView.vue"
export default component.exports