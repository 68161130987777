import { getVersion } from "../nativeIntegrations";
import { ns } from "../messageQueue";

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("posPrint").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]).catch(e => false);
}

export function init() {
  return ns("posPrint").call<void>("init");
}

export function getPrinterInfo(): Promise<PrinterInfo> {
  return ns("posPrint").call<PrinterInfo>("getPrinterInfo");
}

export function print(buf: Buffer, opts?: any) {
  return ns("posPrint").call<void>("print", Array.from(buf), opts);
}

export function dispose() {
  return ns("posPrint").call<void>("dispose");
}

export interface PrinterInfo {
  paperWidth: number;
  lineWidth: number;
  initPrinter?: boolean;
  // line width for compressed font
  clineWidth?: number;
  cutPreFeed?: number;
  cutPostFeed?: number;
  feedValue?: number;
  codePage?: string;
  escpos?: 'escpos' | 'json' | 'bitmap';
}

