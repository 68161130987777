
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
    @Prop()
    name : string

    @Prop(Boolean)
    first : boolean
}

