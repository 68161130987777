import { getVersion } from "pos-printer/nativeIntegrations";
import { ns } from "pos-printer/messageQueue";

export async function supported() {
  if (!getVersion()) return false;
  try {
    return await Promise.race([
      ns("mypay").call("supported"),
      new Promise(resolve => setTimeout(() => resolve(false), 1000)),
    ]);
  } catch (e) {
    return false;
  }
}

interface MyPayRequest {
  testing?: boolean;
  action: string;
  data: any;
}

export async function callMyPay(req: MyPayRequest) {
  return await ns("mypay").call("call", req);
}
