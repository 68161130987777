
import { Vue, Component, Prop, getID, mixins, Ref } from "@feathers-client";
import SwipeAction from "@feathers-client/components/SwipeAction.vue";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";
import CartPanelBase from "../table/orderSystem/cartPanelBase";

@Component
export default class TakeawayListItem extends mixins(CartPanelBase) {
  @Prop()
  order: any;

  editing = false;

  cb: Function | null = null;

  get takeawayOrder() {
    return this.order.sessionData;
  }

  get sessionName() {
    return this.takeawayOrder.sessionName;
  }

  get amount() {
    return this.takeawayOrder.amount;
  }

  get payments() {
    return this.takeawayOrder.payments;
  }

  get pickUpTime() {
    return this.takeawayOrder.pickUpTime;
  }

  get paymentStatus() {
    if (this.paid) {
      return "paid";
    } else if (this.takeawayOrder.payments.length > 0) {
      return "partialPaid";
    } else {
      return "unpaid";
    }
  }

  get paymentClass() {
    switch (this.paymentStatus) {
      case "paid":
        return "text-green000";
      case "partialPaid":
        return "text-yellow500";
      case "unpaid":
        return "text-dark-text-danger";
    }
  }

  get paymentMethod() {
    switch (this.paymentStatus) {
      case "paid":
        if (this.takeawayOrder.paymentMethod) {
          return this.takeawayOrder.paymentMethod;
        } else if (this.takeawayOrder.payments.length > 1) {
          return this.$t("takeaway.multiPayment");
        } else {
          return this.$t("takeaway.paid");
        }
      case "partialPaid":
        return this.$t("takeaway.partialPaid");
      case "unpaid":
        return this.$t("enum.status.toPay");
    }
  }

  get paid() {
    return this.order.isFinish && !this.order.isCancel && !this.order.isVoid;
  }

  get userPhone() {
    return this.takeawayOrder.userPhone;
  }

  get userName() {
    return this.takeawayOrder.userName;
  }

  get source() {
    return this.takeawayOrder.source;
  }

  get remarks() {
    return this.takeawayOrder.remarks;
  }

  get takeawayStatus() {
    return this.order.takeawayStatus;
  }

  get status() {
    return this.takeawayOrder.status;
  }

  get statusColor() {
    switch (this.order.takeawayStatus) {
      case "hold":
        return "bg-red100";
      case "sent":
        return "bg-blue100";
      case "done":
        return "bg-purple400";
      case "finish":
        return "bg-green000";
    }
  }

  get tvStatus() {
    return this.order.sessionData.tvStatus;
  }

  get deliveryType() {
    switch (this.source) {
      case "pos":
      case "selfOrder":
        if (this.takeawayOrder.type === "dineInNoTable") {
          return this.$t("takeaway.dineInNoTable");
        } else {
          return this.$t("takeaway.selfPickup");
        }
      case "delivery": {
        if (this.takeawayOrder.extOrderPlatForm) {
          return this.$t("takeaway.delivery", { source: this.takeawayOrder.extOrderPlatForm });
        }
        return this.$t(`orderType.${this.order.type}`);
      }
      default:
        return this.$t(`orderType.${this.order.type}`);
    }
  }

  get deliveryIcon() {
    switch (this.source) {
      case "pos":
      case "selfOrder":
        if (this.takeawayOrder.type === "dineInNoTable") {
          return require("~/assets/images/dineIn.png");
        } else {
          return require("~/assets/images/takeaway.png");
        }
      case "deliveroo":
        return require("~/assets/images/deliveroo.png");
      case "foodpanda":
        return require("~/assets/images/foodpanda.png");
      case "keeta":
        return require("~/assets/images/keeta.png");
      default:
        return require("~/assets/images/takeaway.png");
    }
  }

  get kdsEnabled() {
    return this.$shop.shopData?.kdsEnabled;
  }

  get showPickUp() {
    if (this.kdsEnabled) {
      return this.tvStatus === "ready" && this.paid && this.takeawayStatus !== "finish";
    } else {
      return this.tvStatus !== "taken" && this.paid && this.takeawayStatus !== "finish";
    }
  }

  get readyType() {
    if (this.kdsEnabled) {
      return "taken";
    } else {
      return this.tvStatus === "ready" ? "taken" : "ready";
    }
  }

  get showEndSession() {
    return this.takeawayStatus === "finish";
  }

  async editOrder() {
    if (this.editing) return;
    try {
      this.editing = true;
      let session = this.$tableManager.takeAwayDict[getID(this.order)];
      let detached = false;
      if (!session) {
        const sessionData = await this.$feathers.service("tableSessions").get(this.order._id);
        if (!this.$store.state.user.shop) {
          await this.$shop.adminImpersonateShop(getID(sessionData.shop));
        }
        session = new TableSession({
          parent: this,
        });
        await session.init(sessionData, true);
        session.startSubscribe();
        detached = true;
      }

      this.$emit("editOrder", session, detached);
      // session.$destroy();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.$store.commit("SET_THEME", true);
      this.editing = false;
    }
  }

  async deleteOrder() {
    if (this.paid) {
      await this.voidOrder();
    } else {
      await this.cancelOrder();
    }
  }

  async cancelOrder(): Promise<Boolean> {
    const res = await this.$openDialog(
      import("~/components/dialogs/ConfirmDeleteDialog.vue"),
      {
        title: this.$t("cart.cancelOrder"),
        desc: this.$t("takeaway.cancelOrderConfirm", {
          source: this.source,
          sessionName: this.sessionName,
        }),
      },
      {
        maxWidth: "380px",
      },
    );
    if (res && res.result) {
      if (this.order.products.length && this.order.products.every(it => it.status !== "cancel")) {
        const cancelSuccess = await this.$openDialog(
          import("~/components/table/orderSystem/cancelReason.vue"),
          {
            line: this.order.products.filter(it => it.status !== "cancel"),
            session: this.order,
          },
          {
            maxWidth: "max(50vw,500px)",
          },
        );
        await new Promise(resolve => setTimeout(resolve, 200));
        if (!cancelSuccess) return false;
      }
      this.clearAllItem();

      await this.order.cancelPending(true, { cancelReason: res.cancelReason });

      if (res.print) {
        await this.session.printOrder();
      }
    }
    return res?.result;
  }

  async voidOrder() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("pages.cancelOrder") + " ？ ",
        content: this.$t("pages.cancelOrderDialog"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        confirmText: "text-sm font-medium",
        cancelText: "text-sm text-black font-medium",
        confirm: this.$t("pages.cancelOrder"),
        confirmLock: !this.$shop.hasPermission([
          "orderManage/tableSessionCancelOrder",
          "paymentManage/tableSessionRefundPayment",
        ]),
      },
      {
        maxWidth: "500px",
      },
    );

    if (!c) return;

    const staff = await this.$shop.checkPermission([
      "orderManage/tableSessionCancelOrder",
      "paymentManage/tableSessionRefundPayment",
    ]);
    if (staff === false) return;

    // this.loading = true;
    try {
      this.order.voidingPayment = true;
      const paymentItems = [];
      for (let payment of this.order.payments) {
        const paymentItem = await this.$paymentManager.refundPayment(getID(payment.payment));
        if (paymentItem.status === "refunded") {
          await this.order?.refundSplittedPayment(paymentItem);
          await this.$feathers.service("actionLogs").create({
            session: this.order.item._id,
            view: getID(this.session.item.view),
            staff: staff?._id || this.$shop.staffId,
            type: "paymentManage/tableSessionRefundPayment",
            detail: { payment: [paymentItem] },
          });
        }
        await this.order.reload();

        paymentItems.push(paymentItem);
      }

      const sessionData = await this.$feathers.service("tableSessions/cancelPending").create({
        session: this.order.item._id,
        update: {
          endTime: new Date(),
        },
      });

      await this.$feathers.service("actionLogs").create({
        session: this.order.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "orderManage/tableSessionCancelOrder",
        detail: { payment: paymentItems },
      });

      await this.order.reload();
      this.order.receiptPrintTime = 0;
      // this.currentPayment = null;
      //await this.session.printOrder();
      for (let payment of paymentItems) {
        if (payment.status === "refunded") {
          await this.order.printOrder({
            payment: getID(payment._id),
          });
        }
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      // this.loading = false;
      this.order.voidingPayment = false;
    }
  }

  doneTimer: any;
  counter = -1;

  startDoneTimer(cb: () => Promise<void>): void {
    if (this.doneTimer) {
      this.stopDoneTimer();
      return;
    }
    if (this.$config.cypress) {
      cb();
      return;
    }
    this.cb = cb;
    this.counter = 5;
    this.doneTimer = setInterval(this.countNext.bind(this), 1000);
  }

  stopDoneTimer(forceFinish = false) {
    if (!this.doneTimer) {
      return;
    }
    this.counter = -1;
    clearInterval(this.doneTimer);
    this.doneTimer = null;
    if (forceFinish && this.cb) this.cb();
  }

  async countNext() {
    this.counter--;
    if (this.counter === 0) {
      await this.cb();
      this.stopDoneTimer();
      this.cb = null;
    }
  }

  async updateTvOrderStatus() {
    if (!this.order._id && !this.sessionName) return;
    try {
      var query: any = { date: { $gte: moment().startOf("day").toDate() } };
      if (this.sessionName) query.code = this.sessionName;
      if (this.order._id) {
        let id = this.order._id.toLowerCase();
        id = id.split("/")[0];
        query.orderId = id;
      }
      const theRecord = await this.$feathers.service("tvOrderStatuses").find({
        query: {
          ...query,
          $paginate: false,
        },
        paginate: false,
      });
      if (theRecord.length) {
        var newStatus = "";
        switch (theRecord[0].status) {
          case "making":
            if (this.kdsEnabled) {
              newStatus = "taken";
            } else {
              newStatus = "ready";
            }
            break;
          case "ready":
            newStatus = "taken";
            break;
          default:
            newStatus = "making";
        }
        await this.$feathers.service("tvOrderStatuses").patch(
          null,
          {
            status: newStatus,
          },
          {
            query: {
              _id: {
                $in: theRecord.map(it => it._id),
              },
            },
          },
        );
        this.$store.commit(
          "SET_SUCCESS",
          theRecord[0].code + " " + this.$t("basic.newStatus") + ": " + this.$t("tvOrderStatus." + newStatus),
        );
      } else {
        throw new Error("No record found");
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async endSession() {
    try {
      const curSession = this.$tableManager.takeAwayDict[getID(this.order)];
      if (curSession) {
        await curSession.atomic({
          endTime: new Date(),
        });
      } else {
        await this.$feathers.service("tableSessions").patch(this.order._id, {
          endTime: new Date(),
        });
      }
      await this.$feathers.service("actionLogs").create({
        session: this.order._id,
        staff: this.$shop.staffId,
        type: `tableManage/tableSessionFinishOrder`,
      });
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  beforeDestroy() {
    this.stopDoneTimer(true);
  }
}
