
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'

@Component
export default class TakeAway extends Vue {
    @Prop()
    config : any
}

