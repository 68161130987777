
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, getID } from "@feathers-client";
import moment from "moment";
import { wrapJob } from "../../plugins/printer/invoiceSequencer";
import { printTemplate } from "@common/table/invoiceSequencer";
import { sumSummary, groupSummary, statusGrouper } from "~/plugins/summaryUtils";
import _, { values } from "lodash";
import { PrintSequence } from "pos-printer/printSequence";

interface TimeSession {
  _id: string;
  endTime: string;
  startTime: string;
}
@Component({})
export default class SummaryBase extends Vue {
  loading = false;

  shop: FindType<"shops"> = null;
  startDate: Date = null;
  endDate: Date = null;
  previousStartDate: Date = null;
  previousEndDate: Date = null;

  created() {
    this.startDate = this.$shop.normalizedDay(moment().startOf("day").toDate());
    this.endDate = moment(this.startDate).add(1, "day").toDate();
    this.previousStartDate = moment(this.startDate).subtract(1, "day").toDate();
    this.previousEndDate = this.startDate;
  }

  mode = "";
  groupHourSummary: FindType<"tableSessions/summaries"> = [];
  paymentSummary: FindType<"orderPaymentSummary"> = [];
  discountSummary: FindType<"tableSessions/summaries/discount"> = [];
  categorySummary: FindType<"tableSessions/summaries/category"> = [];
  cashlogSummary: FindType<"cashboxLogs">[] = [];
  paymentSummaryPrevious: FindType<"orderPaymentSummary"> = [];
  discountSummaryPrevious: FindType<"tableSessions/summaries/discount"> = [];
  categorySummaryPrevious: FindType<"tableSessions/summaries/category"> = [];
  timeSessionData: FindType<"shopgrouptimesession">[] = [];
  groupHourSummaryChartData: FindType<"tableSessions/summaries"> = [];
  groupSalesRatioChartData: FindType<"tableSessions/summaries"> = [];
  groupHourDiscountChartData: FindType<"tableSessions/summaries/discount"> = [];
  dataPreviousStartDate: Date = null;
  dataPreviousEndDate: Date = null;
  dataStartDate: Date = null;
  dataEndDate: Date = null;
  dataMode: string = "";
  newPaymentSummary: FindType<"orderPaymentSummary"> = [];

  timeSession: TimeSession = {
    _id: "",
    endTime: "23:00",
    startTime: "00:00",
  };

  loadSummaryDirty = false;
  loadSummaryTask: Promise<void>;
  // taxSettingOpen: boolean = false;
  // async loadTaxSetting() {
  //   try {
  //     const res = await this.$feathers.service("taxSettings").find({
  //       query: {
  //         $or: [{ shops: { $exists: false } }, { shops: { $size: 0 } }, { shops: this.$shop.shopId }] as any,
  //         status: "active",
  //         $paginate: false,
  //       },
  //       paginate: false,
  //     })
  //     if(res.length) this.taxSettingOpen = true
  //     // console.log(res)
  //   } catch (e) {
  //     // console.log(e)
  //     return [];
  //   }
  // }

  async loadSummary() {
    if (this.loadSummaryTask) {
      if (this.loading) {
        this.loadSummaryDirty = true;
      }
      return this.loadSummaryTask;
    } else {
      this.loadSummaryDirty = true;
      const task = (this.loadSummaryTask = this.loadSummaryInner());
      task.finally(() => {
        this.loadSummaryTask = null;
      });
      return task;
    }
  }

  async exportSummary() {
    this.loading = true;
    await this.loadSummary();
    try {
      await wrapJob<any, PrintSequence>(
        this,
        "summary",
        printTemplate,
        // templateName: "summary",
        {
          data: {
            shop: this.shop,
            startDate: this.startDate,
            endDate: this.endDate,
            printTime: new Date(),
            totalTax: this.incomeSummary?.tax ?? 0,
            paymentSummary: this.paymentSummary,
            incomeSummary: this.incomeSummary,
            incomeTypeSummary: this.incomeTypeSummary,
            incompleteSummary: this.incompleteSummary,
            incompleteTypeSummary: this.incompleteTypeSummary,
            normalizedDoneGroupByDayStatusSummary: this.normalizedDoneGroupByDayStatusSummary,
            normalizedDoneGroupByTimeSessionStatusSummary: this.normalizedDoneGroupByTimeSessionStatusSummary,
            discountSummary: this.discountSummary2,
            voidTypeSummary: this.voidTypeSummary,
            categorySummary: this.categorySummary,
            categorySummaryGroupByTag: this.categorySummaryGroupByTag,
            cashlogSummary: this.cashlogSummary,
            showSummaryBreakdown: this.$shop.localOptions.showSummaryBreakdown,
            // showTax: this.taxSettingOpen,
          },
        },
      );
    } catch (e) {
      console.error(e.message);
    } finally {
      this.loading = false;
    }
  }

  async loadSummaryInner() {
    while (this.loadSummaryDirty) {
      this.loadSummaryDirty = false;
      await Vue.nextTick();
      this.loading = true;
      try {
        const startDate = this.startDate;
        const endDate = this.endDate;
        const previousStartDate = this.previousStartDate;
        const previousEndDate = this.previousEndDate;
        const mode = this.mode;

        if (!this.startDate || !this.endDate) return;

        const [
          groupSalesRatioChartData,
          groupHourSummary,
          paymentSummary,
          discountSummary,
          categorySummary,
          cashlogSummary,
          paymentSummaryPrevious,
          discountSummaryPrevious,
          categorySummaryPrevious,
          timeSessionData,
          newPaymentSummary,
        ] = await Promise.all([
          // chart
          this.$feathers.service("tableSessions/summaries").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
            },
            groupBy: "hour",
          }),
          this.$feathers.service("tableSessions/summaries").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
            },
            groupBy: "hour",
          }),

          // latest
          this.$feathers.service("orderPaymentSummary").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
              //type: "session",
            },
            timeField: "paidTime",
          }),
          this.$feathers.service("tableSessions/summaries/discount").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
              status: { $in: ["done", "void"] },
            },
          }),
          this.$feathers.service("tableSessions/summaries/category").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
              status: "done",
            },
          }),
          this.$feathers.service("cashboxLogs").find({
            query: {
              date: {
                $gte: startDate,
                $lt: endDate,
              },
              type: { $nin: ["void"] },
              isBuffer: { $nin: [true] },
              ...(this.shop ? { shop: getID(this.shop) } : {}),
              $paginate: false,
              $sort: { date: 1 },
              $populate: "staff",
            },
            paginate: false,
          }),
          // previous
          previousStartDate
            ? this.$feathers.service("orderPaymentSummary").create({
                startDate: previousStartDate,
                endDate: previousEndDate,
                filter: {
                  ...(this.shop ? { shop: getID(this.shop) } : {}),
                  //type: "session",
                },
                timeField: "paidTime",
              })
            : [],
          previousStartDate
            ? this.$feathers.service("tableSessions/summaries/discount").create({
                startDate: previousStartDate,
                endDate: previousEndDate,
                filter: {
                  ...(this.shop ? { shop: getID(this.shop) } : {}),
                },
              })
            : [],
          previousStartDate
            ? this.$feathers.service("tableSessions/summaries/category").create({
                startDate: previousStartDate,
                endDate: previousEndDate,
                filter: {
                  ...(this.shop ? { shop: getID(this.shop) } : {}),
                  status: "done",
                },
              })
            : [],
          this.$feathers.service("shopgrouptimesession").find({
            paginate: false,
            query: {
              $paginate: false,
            },
            /** To-do : filter by shopgroup ??? or auto done in auth hook ??? */
          }),
          this.$feathers.service("orderPaymentSummary").create({
            startDate,
            endDate,
            filter: {
              ...(this.shop ? { shop: getID(this.shop) } : {}),
              // ...(this.selectedFloor ? { view: getID(this.selectedFloor) } : {}),
            },
            groupBy: "hour",
            timeField: "paidTime",
          }),
        ]);
        this.groupHourSummary = groupHourSummary;
        this.paymentSummary = paymentSummary;
        this.discountSummary = discountSummary.filter(i => i.amount <= 0);
        this.categorySummary = categorySummary;

        this.cashlogSummary = cashlogSummary;

        this.paymentSummaryPrevious = paymentSummaryPrevious;
        this.discountSummaryPrevious = discountSummaryPrevious;
        this.categorySummaryPrevious = categorySummaryPrevious;

        this.timeSessionData = timeSessionData;

        this.groupSalesRatioChartData = groupSalesRatioChartData;
        this.dataPreviousStartDate = previousStartDate;
        this.dataPreviousEndDate = previousEndDate;
        this.dataStartDate = startDate;
        this.dataEndDate = endDate;
        this.dataMode = mode;
        this.newPaymentSummary = newPaymentSummary;
      } finally {
        this.loading = false;
      }
    }
  }

  normalizeDate(item: { hour: number; date: string }): Date {
    let openHour = parseInt((this.shop?.openTime ?? "00:00").split(":")[0]);

    if (item.hour < openHour) {
      const m = moment(item.date);
      return m.subtract(1, "day").toDate();
    }
    return moment(item.date).toDate();
  }

  calculateConsolidatedRefundSummary(v) {
    const grouped = v.reduce((acc, transaction) => {
      const paymentMethod = transaction.paymentMethod._id || "";
      acc[paymentMethod] = acc[paymentMethod] || {
        _id: transaction._id,
        amount: 0,
        count: 0,
        tips: 0,
        voidAmount: 0,
        voidCount: 0,
        paymentMethod: transaction.paymentMethod,
      };
      acc[paymentMethod].amount += transaction.amount;
      acc[paymentMethod].count += transaction.count;
      acc[paymentMethod].tips += transaction.tips || 0;
      acc[paymentMethod].voidAmount += transaction.voidAmount;
      acc[paymentMethod].voidCount += transaction.voidCount;
      acc[paymentMethod].paymentMethod = transaction.paymentMethod;
      return acc;
    }, {});
    return Object.values(grouped);
  }

  calculateConsolidatedDiscount(v) {
    const grouped = v.reduce((acc, transaction) => {
      if (transaction._id.type) {
        let id = null;
        switch (transaction._id.type) {
          case "shop":
            id = "shop";
            break;
          case "product":
            id = `product${this.$td(transaction.name)}`;
            break;
          case "coupon":
            id = `coupon${transaction._id.couponRule}`;
            break;
          case "option":
            id = `coupon${transaction._id.optionChoice}`;
            break;
        }
        acc[id] = acc[id] || {
          _id: transaction._id,
          name: transaction.name,
          paymentMethod: transaction.paymentMethod,
          amount: 0,
          count: 0,
          voidAmount: 0,
          voidCount: 0,
        };
        acc[id].amount += transaction.amount;
        acc[id].count += transaction.count;
        acc[id].voidAmount += transaction.voidAmount;
        acc[id].voidCount += transaction.voidCount;
      } else {
        const discountId = transaction._id.discountId;
        acc[discountId] = acc[discountId] || {
          _id: transaction._id,
          name: transaction.name,
          paymentMethod: transaction.paymentMethod,
          amount: 0,
          count: 0,
          voidAmount: 0,
          voidCount: 0,
        };
        acc[discountId].amount += transaction.amount;
        acc[discountId].count += transaction.count;
        acc[discountId].voidAmount += transaction.voidAmount;
        acc[discountId].voidCount += transaction.voidCount;
      }

      return acc;
    }, {});
    return Object.values(grouped);
  }

  filterCallBack(it) {
    return this.timeSession.startTime < this.timeSession.endTime
      ? it._id?.hour >= this.timeStringToNumber(this.timeSession.startTime) &&
          it._id?.hour <= this.timeStringToNumber(this.timeSession.endTime)
      : it._id?.hour >= this.timeStringToNumber(this.timeSession.endTime) &&
          it._id?.hour <= this.timeStringToNumber(this.timeSession.startTime);
  }

  timeStringToNumber(string) {
    let value = string?.split?.(":");
    const hh = isNaN(+value?.[0]) ? 0 : +value?.[0];
    return hh;
  }

  get groupHourStatusSummary() {
    return groupSummary(this.groupHourSummary, statusGrouper);
  }

  get statusSummary() {
    return groupSummary(this.groupHourStatusSummary, it => ({ type: it._id.type, status: it._id.status }));
  }

  get incomeTypeSummary() {
    return this.statusSummary.filter(it => it._id.status === "done");
  }

  get incompleteTypeSummary() {
    return this.statusSummary.filter(it => it._id.status === "ongoing" || it._id.status === "cancelled");
  }

  get incomeTypeWithSourceSummary() {
    return groupSummary(
      this.groupHourStatusSummary.filter(it => it._id.status === "done"),
      it => ({
        type: it._id.type,
        status: it._id.status,
        source: it._id.source,
      }),
    );
  }

  get voidTypeSummary() {
    //return this.statusSummary.filter(it => it._id.status === "void");
    if (!this.newPaymentSummary) return [];
    let paymentSummary = this.newPaymentSummary.filter(this.filterCallBack);
    let tableSessionSummary = this.calculateConsolidatedRefundSummary(paymentSummary);
    let voidTypeSummary = _.filter(tableSessionSummary as any, it => it.voidCount > 0);
    return voidTypeSummary;
  }

  get discountSummary2(): FindType<"tableSessions/summaries/discount"> {
    if (!this.discountSummary) return [];
    let discountSummary2 = this.calculateConsolidatedDiscount(this.discountSummary);

    return discountSummary2;
  }

  get incomeSummary() {
    return sumSummary(this.incomeTypeSummary);
  }

  get incompleteSummary() {
    return sumSummary(this.incompleteTypeSummary);
  }

  get doneGroupDayStatusSummary() {
    const items = groupSummary(
      this.groupHourStatusSummary.filter(it => it._id.status === "done"),
      it => ({ date: it._id.date, hour: it._id.hour }),
    );
    return _.groupBy(items, it => this.normalizeDate(it._id));
  }

  get normalizedDoneGroupByDayStatusSummary() {
    let openHour = parseInt((this.shop?.openTime ?? "00:00").split(":")[0]);
    let closeHour = parseInt((this.shop?.closeTime ?? "24:00").split(":")[0]);
    let closeMinute = parseInt((this.shop?.closeTime ?? "24:00").split(":")[1]);

    if (closeMinute === 0) {
      closeHour--;
      closeMinute = 59;
      if (closeHour === -1) closeHour = 23;
    }

    return Object.entries(this.doneGroupDayStatusSummary).map(([date, daily]) => {
      const hours = _.times(24, i => (openHour + i) % 24).filter(
        i => (closeHour < openHour && i >= openHour) || i <= closeHour || !!daily.find(it => it._id.hour === i),
      );
      const sum = _.sumBy(daily, d => d.count || 0);

      return {
        date,
        items: hours.map(h => {
          const item = daily.find(it => it._id.hour === h);
          const hour = String(h).padStart(2, "0");
          const time = `${hour}:00-${hour}:59`;

          return {
            hour: h,
            time,
            count: item?.count ?? 0,
            amount: item?.amount ?? 0,
            percent: (sum ? ((item?.count ?? 0) / sum) * 100 : 0).toFixed(1),
            capacity: item?.capacity ?? 0,
          };
        }),
      };
    });
  }

  get normalizedDoneGroupByTimeSessionStatusSummary() {
    const r = this.timeSessionData.map(ts => {
      let openHour = parseInt((ts.startTime ?? "00:00").split(":")[0]);
      let closeHour = parseInt((ts.endTime ?? "24:00").split(":")[0]);
      let closeMinute = parseInt((ts.endTime ?? "24:00").split(":")[1]);

      if (closeMinute === 0) {
        closeHour--;
        closeMinute = 59;
        if (closeHour === -1) closeHour = 23;
      }
      if (closeHour < openHour) {
        closeHour += 24;
      }

      let includedHours = _.range(openHour, closeHour + 1, 1).map(v => v % 24);
      let sum = 0;
      let count = 0;
      let amount = 0;
      let capacity = 0;

      Object.entries(this.doneGroupDayStatusSummary).forEach(([date, daily]) => {
        sum += _.sumBy(daily, d => d.count || 0);
        includedHours.forEach(h => {
          const item = daily.find(it => it._id.hour === h);
          count += item?.count ?? 0;
          amount += item?.amount ?? 0;
          capacity += item?.capacity ?? 0;
        });
      });

      return {
        name: ts.name,
        time: `${String(openHour % 24).padStart(2, "0")}:00-${String(closeHour % 24).padStart(2, "0")}:59`,
        count,
        amount,
        percent: (sum ? (count / sum) * 100 : 0).toFixed(1),
        capacity,
      };
    });
    return r;
  }

  get categorySummaryGroupByTag() {
    return Object.entries(_.groupBy(this.categorySummary, it => it.summaryTag || "Other")).map(([tag, list]) => {
      return {
        tag,
        list,
        amount: list.reduce((acc, it) => acc + it.amount, 0),
        amountWithoutDiscount: list.reduce((acc, it) => acc + it.amountWithoutDiscount, 0),
        count: list.reduce((acc, it) => acc + it.count, 0),
        surcharge: list.reduce((acc, it) => acc + it.surcharge, 0),
      };
    });
  }
}
