import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand, wrapVue } from "../index";
import { PrintJob } from "../printQueue";
import { connect, mdns, SocketDevice } from "../ports/socket";
import { SocketConf } from "./socket";
import { request } from "../utils/httpProxy";
import { EPosSequence } from "../printSequence/epos";
import { CanvasSequence } from "../printSequence/canvas";

export interface EPosConf extends Omit<SocketConf, "port"> {
  port: "epos";
  socketHost: string;
  socketPort: number;
}

const statusPayload = `<?xml version="1.0" encoding="UTF-8"?>
<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/" force="true">
<s:Body>
<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print"/>
</s:Body>
</s:Envelope>'`;

export class EPosPrinter extends PrinterBase<EPosConf, EPosConf> {
  onDisconnectedCore(): void {}

  constructor(server: PrinterServer, conf: EPosConf) {
    super(server, conf);
    this.probeSupported = true;
  }

  createSequence<T>(context?: Vue): T {
    if (this.conf.type === "thermal" && this.conf.opts?.epos) {
      if (this.conf.opts?.escpos === "escposGraph") {
        return new CanvasSequence(wrapVue(context ?? this.parent.context), this) as any;
      }
      return new EPosSequence(wrapVue(context ?? this.parent.context), this) as any;
    }
    return super.createSequence<T>(context);
  }

  async request(data: string, timeout = 1000) {
    const connectOne = async (ip: string) => {
      const resp = await request(
        `http://${ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=${timeout}`,
        data,
        "text/xml; charset=utf-8",
        { timeout: timeout * 2 },
      );
      if (resp.statusCode === 200 && resp.body && resp.body.includes("www.epson-pos.com")) {
        return resp.body as string;
      } else {
        throw new Error(`Status code: ${resp.statusCode}`);
      }
    };
    try {
      return await connectOne(this.conf.address);
    } catch (e) {
      if (this.conf.socketHost) {
        const result = await mdns({
          name: this.conf.socketHost,
        });

        const ip = result[0].ip;
        if (ip) {
          const ipParts = ip.split(".");
          const isValidV4 = ipParts.length === 4 && ipParts.every(p => !isNaN(parseInt(p, 10)) && +p >= 0 && +p <= 255);
          if (isValidV4) {
            await connectOne(ip);
            if (this.parent) {
              try {
                this.conf.address = ip;
                this.parent.savePrinters();
              } catch (e) {
                console.warn(e);
              }
            }
          } else {
            throw e;
          }
        }
      } else {
        throw e;
      }
    }
  }

  async initCore() {}

  async tryConnectCore() {
    await this.pollStatus();
  }

  async pollStatus() {
    const resp = await this.request(statusPayload);
    const xml = new DOMParser().parseFromString(resp, "text/xml");
    const response = xml.getElementsByTagName("response")[0];
    if (!response) {
      throw new Error("Invalid response");
    }
    const asb = +response.getAttribute("status");
    if (!isNaN(asb)) {
      return this.checkStatus(asb);
    }
  }

  checkStatus(asb: number) {
    const msg: string[] = [];
    let offline = false;
    let recovery = false;
    if (asb & 0x00000001) {
      msg.push("No printer response");
    }
    if (asb & 0x00000002) {
      msg.push("Print complete");
    }
    if (asb & 0x00000004) {
      msg.push('Drawer kick-out connector pin 3 is "H"');
    }
    if (asb & 0x00000008) {
      msg.push("Offline status");
      offline = true;
    }
    if (asb & 0x00000020) {
      msg.push("Cover is open");
    }
    if (asb & 0x00000040) {
      msg.push("Paper feed switch is feeding paper");
    }
    if (asb & 0x00000100) {
      msg.push("Waiting for online recovery");
    }
    if (asb & 0x00000200) {
      msg.push("Panel switch is ON");
    }
    if (asb & 0x00000400) {
      msg.push("Mechanical error generated");
    }
    if (asb & 0x00000800) {
      msg.push("Auto cutter error generated");
    }
    if (asb & 0x00002000) {
      msg.push("Unrecoverable error generated");
    }
    if (asb & 0x00004000) {
      msg.push("Auto recovery error generated");
      recovery = true;
    }
    if (asb & 0x00020000) {
      msg.push("No paper in the roll paper near end detector");
    }
    if (asb & 0x00080000) {
      msg.push("No paper in the roll paper end detector");
      if(!this.noPaper) {
        this.noPaper = true;
        this.emit("noPaperChanged", true);
      }
    } else {
      if(this.noPaper) {
        this.noPaper = false;
        this.emit("noPaperChanged", false);
      }
    }
    if (asb & 0x80000000) {
      msg.push("Stop the spooler");
    }
    // console.log(msg);
    return {
      msg: msg.join("\n"),
      offline,
      recovery,
    };
  }

  async printCore(job: PrintJob) {
    const buf = job.getDataWithRetry(this.parent.iconv);

    const alphabetNumericID = job.id
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase()
      .slice(0, 16);

    const payload = `
<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
  <s:Header>
    <parameter xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
      <devid>local_printer</devid>
      <timeout>60000</timeout>
      <printjobid>${alphabetNumericID}</printjobid>
    </parameter>
  </s:Header>
  <s:Body>
    <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
      ${job.type === "epos" ? buf.toString() : `<command>${buf.toString("hex")}</command>`}}
    </epos-print>
  </s:Body>
</s:Envelope>`;
    let resp: string;
    try {
      resp = await this.request(payload, 60000);
    } catch (e) {
      this.onDisconnected("Printer error: " + e.message);
      throw e;
    }
    const xml = new DOMParser().parseFromString(resp, "text/xml");
    const response = xml.getElementsByTagName("response")[0];
    if (!response) {
      throw new Error("Invalid response");
    }
    const success = response.getAttribute("success");
    const asb = +response.getAttribute("status");
    if (!isNaN(asb)) {
      const { offline, recovery } = this.checkStatus(asb);
      if (recovery) {
        job.retryCount = Math.max(2, job.retryCount + 1);
      }
      if (offline && success !== "true" && success !== "1" && (!recovery || job.retryable)) {
        const code = response.getAttribute("code");
        job.lastError = eposErrors[code] ?? "Unknown error";

        while (this.connected) {
          const asb = await this.pollStatus();
          if (!asb) {
            throw new Error("Printer offline");
          }
          if (!asb.offline) break;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        return this.printCore(job);
      }
    }
    if (success !== "true" && success !== "1") {
      const code = response.getAttribute("code");
      throw new Error(eposErrors[code] ?? "Unknown error");
    }
  }

  async statusProbeCore() {
    if (!this.conf.opts?.statusProbe) return;
    await this.pollStatus();
  }

  async disconnectCore() {}

  async requestNewDeviceCore() {
    return this.conf;
  }
}

const eposErrors = {
  EPTR_AUTOMATICAL: "Automatic recovery error occurred",
  EPTR_BATTERY_LOW: "Battery has run out",
  EPTR_COVER_OPEN: "Cover open error occurred",
  EPTR_CUTTER: "Auto cutter error occurred",
  EPTR_MECHANICAL: "Mechanical error occurred",
  EPTR_REC_EMPTY: "No paper is left in the roll paper end detector",
  EPTR_UNRECOVERABLE: "Unrecoverable error occurred",
  SchemaError: "Error exists in the requested document syntax",
  DeviceNotFound: "Printer specified by the device ID does not exist",
  PrintSystemError: "Error occurred with the printing system",
  EX_BADPORT: "An error occurred with the communication port",
  EX_TIMEOUT: "Print timeout occurred",
  EX_SPOOLER: "Print queue is full",
  EX_DEVICE_BUSY: "The specified device is in process and the process cannot be executed.",
  EX_ENPC_TIMEOUT: "Status check failed.",
  JobNotFound: "Specified job ID does not exist",
  Printing: "Printing in progress",
  TooManyRequests: "The number of print jobs sent to the printer has exceeded the allowable limit.",
  RequestEntityTooLarge: "The size of the print job data exceeds the capacity of the printer.",
  ERROR_WAIT_EJECT: "Waiting for paper ejection",
};
