
import { FindType } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";

@Component({
  components: {},
})
export default class Verify extends Vue {
  @Prop()
  statusGroup: FindType<"tableSessions/summaries/verify">[number]["items"][number];

  expanded = false;

  get items() {
    return this.statusGroup.errors.length;
  }
}
