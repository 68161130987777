
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { checkID, getID, FindType } from "@feathers-client";
import { FindPopRawType } from "@feathers-client";

@Component
export default class memberCard extends Vue {
  @Prop()
  user: FindType<"appUsers">;

  @Prop()
  userId: string;

  fetchUser: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers"> = null;

  async beforeMount() {
    if (!this.user)
      this.fetchUser = await this.$feathers
        .service("appUsers")
        .get(getID(this.userId), { query: { $populate: ["vipLevel", "ranks.rank"] } })
        .catch(e => {
          return null;
        });
  }

  get member() {
    return this.user ?? this.fetchUser;
  }
}
