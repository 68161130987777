
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class Dialog extends Vue {
    @Prop()
    value : any;
    @Prop()
    modalId : string;

    modalDisposed = false;

    get inputValue() { return this.value }
    set inputValue(v) { this.$emit('input', v)}

    modalResult(item : any) {
        if(!this.modalDisposed) {
            this.modalDisposed = true;
        }
        this.$root.$emit('modalResult', { result: item, id: this.modalId });
    }
}

