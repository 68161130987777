
import { Vue, Component, Prop, VModel, FindType, Watch } from "@feathers-client";
import { PaymentMethodBase } from "pos-printer/payments/methodBase";
import EditorSinglePage from "@schemaEditor/EditorSinglePage.vue";

@Component({
  components: {
    EditorSinglePage,
  },
})
export default class PaymentMethodOptions extends Vue {
  @Prop()
  item: FindType<"paymentMethods">;

  manager: PaymentMethodBase = null;

  @Watch("item.type", { immediate: true })
  async onMethod() {
    this.manager = null;
    if (this.item?.type) {
      const type = this.item.type;
      const manager = await this.$paymentManager.getAllMethod(type);
      if (manager && type === this.item?.type) {
        this.manager = manager;
      }
    }
  }

  get subMethods(): string[] {
    const subMethodKey = this.manager?.capabilities?.subMethodsKey ?? "subMethods";
    return this.item?.props?.[subMethodKey] ?? [];
  }

  get activeSubMethods() {
    return (this.manager?.capabilities?.subMethods || []).filter(it => this.subMethods.includes(it.subType));
  }

  get subMethodDict() {
    let infos = this.item?.subMethodInfos ?? [];
    const filteredInfos = infos.filter(it =>
      this.activeSubMethods.find(subMethod => subMethod.subType === it.subMethod),
    );
    let dirty = false;
    if (filteredInfos.length !== infos.length) {
      dirty = true;
      infos = filteredInfos;
    }
    for (let subMethod of this.activeSubMethods) {
      if (!infos.find(it => it.subMethod === subMethod.subType)) {
        infos.push({
          subMethod: subMethod.subType,
        } as any);
        dirty = true;
      }
    }

    if (dirty) {
      Vue.set(this.item, "subMethodInfos", infos);
    }

    return Object.fromEntries(infos.map(it => [it.subMethod, it]));
  }
}
