
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { TableItem } from "~/plugins/table";

@Component
export default class AvailableContainer extends Vue {
  @Prop({ required: true })
  item: TableItem;

  get showPlate() {
    // TODO: Get Data from item or something
    return false;
  }

  get plusSize() {
    return 24; // TODO: dynamic sizing
  }

  get groupConfig() {
    return {
      x: -this.item.w / 2,
      y: -this.item.h / 2,
    };
  }

  get configPlus() {
    const size = Math.min(this.item.w / 6, this.item.h / 3);
    return {
      id: "plus-sign",
      w: size,
      h: size,
      x: this.item.w / 15,
      y: this.item.h / 2,
      cache: true,
      perfectDrawEnabled: false,
      listening: false,
    };
  }

  get plateLayout() {
    const unitSize = Math.min(this.item.w / 3, this.item.h / 1.5);
    return {
      item: this.item,
      x: this.item.w / 3,
      y: this.item.h / 1.5,
      w: unitSize * 2,
      h: unitSize,
    };
  }
}
