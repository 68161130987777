// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/barlow-v12-latin-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/barlow-v12-latin-700.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/barlow-v12-latin-italic.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-display: swap;\n  font-family: \"Barlow\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Barlow Bold\";\n  font-style: normal;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Barlow Italic\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n}\n\n", "",{"version":3,"sources":["webpack://./assets/css/barlow.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAmE;AACrE;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,4DAA+D;AACjE;;AAEA;EACE,kBAAkB;EAClB,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,4DAAkE;AACpE","sourcesContent":["@font-face {\n  font-display: swap;\n  font-family: \"Barlow\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"../fonts/barlow-v12-latin-regular.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Barlow Bold\";\n  font-style: normal;\n  font-weight: 700;\n  src: url(\"../fonts/barlow-v12-latin-700.woff2\") format(\"woff2\");\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Barlow Italic\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"../fonts/barlow-v12-latin-italic.woff2\") format(\"woff2\");\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
