
import { FindType } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";
import { getStorage } from "@feathers-client/storage";
import moment from "moment";
import type { PrinterServer } from "pos-printer";
import { getVersion } from "pos-printer/nativeIntegrations";
import type { PrinterConf } from "pos-printer/printers/base";
import type { ServerConf } from "pos-printer/printers/server";
import { supported, getBattery, on as onBattery, off as offBattery } from "pos-printer/utils/app";
import { managePrint } from "~/plugins/printer/invoiceSequencer";

let initedBefore = false;

// @ts-ignore
const testingMenu = PRODUCTION_MODE ? undefined : async () => (await import("~/plugins/testing/menu.vue")).default;

@Component({
  components: {
    testingMenu,
  },
})
export default class OrderSystemHeader extends Vue {
  hasTestingMenu = !!testingMenu;

  batteryManager: any = null;
  battery: number = null;
  charging = false;
  batterySupported = false;
  timeStr: string = null;
  batteryHandle: string = null;

  isApp = getVersion();
  isIPad = getIsIPad();
  initing = 2;
  networkSetup = false;
  paymentSetup = false;
  offlineSetup = false;

  loaded = false;

  printerServer: PrinterServer = null;

  resizeObserver: ResizeObserver;

  kitchenPrinters: FindType<"kitchenPrinters">[] = [];
  waterBarPrinters: FindType<"waterBars">[] = [];

  @Prop()
  appHideSupported: boolean;

  get batteryLevel() {
    return Math.round(this.battery * 100);
  }

  async mounted() {
    this.updateHeight();
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver(() => {
        this.updateHeight();
      });
      this.resizeObserver.observe(this.$el as any);
    }
    this.$tableManager.$on("tick", this.tick);
    this.tick();
    await this.initBattery();
    if (initedBefore) {
      this.initing = 0;
      await this.init();
    }
  }

  updateHeight() {
    const el: HTMLElement = this.$el as any;
    this.$store.commit("SET_HEADER_HEIGHT", el.clientHeight);
  }

  async initBattery() {
    if ((navigator as any).getBattery) {
      try {
        this.batteryManager = await (navigator as any).getBattery();
        this.batteryManager.addEventListener("levelchange", this.chargingchange);
        this.batteryManager.addEventListener("chargingchange", this.chargingchange);
        this.chargingchange();
      } catch (e) {}
    }

    try {
      if (!this.batteryManager && (await supported())) {
        const info = await getBattery();
        this.battery = info.level;
        this.charging = info.charging;
        this.batterySupported = true;
        this.batteryHandle = await onBattery("batteryChanged", this.batteryChanged);
      }
    } catch (e) {}
  }

  async beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    this.$tableManager.$off("tick", this.tick);
    if (this.batteryManager) {
      this.batteryManager.removeEventListener("levelchange", this.chargingchange);
      this.batteryManager.removeEventListener("chargingchange", this.chargingchange);
      this.batteryManager = null;
    }
    if (this.batteryHandle) {
      await offBattery(this.batteryHandle);
      this.batteryHandle = null;
    }
  }

  chargingchange() {
    if (!this.batteryManager) return;
    this.battery = this.batteryManager.level;
    this.charging = this.batteryManager.charging;
  }

  async batteryChanged() {
    try {
      const info = await getBattery();
      this.battery = info.level;
      this.charging = info.charging;
    } catch (e) {
      console.warn(e);
    }
  }

  tick() {
    if (this.initing) {
      --this.initing;
      if (!this.initing) {
        this.init();
      }
    }
    if (!this.appHideSupported) return;
    const date = new Date();
    const timeStr = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    if (timeStr !== this.timeStr) {
      this.timeStr = timeStr;
    }
  }

  async init() {
    initedBefore = true;
    const { initPrinter } = await import("~/plugins/printer/invoiceSequencer");
    this.printerServer = await initPrinter(this.$root);
    await import("~/plugins/payments");
    await this.$paymentManager.start();
    this.loaded = true;
  }

  getNotificationIcon(notification: FindType<"shopNotifications">) {
    return notification.level;
  }

  getNotificationColor(notification: FindType<"shopNotifications">) {
    if (notification.level === "error") {
      return "bg-red-500";
    } else if (notification.level === "warning") {
      return "bg-yellow-500";
    }
    return "bg-green-500";
  }
}

function getIsIPad() {
  return (
    ["iPad Simulator", "iPad"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
