
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeScript, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorScript extends Vue {
  @Prop() template!: PrinterTemplateNodeScript<any>;

  @Prop() context: any;
}
