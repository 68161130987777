import { MultiCodePage, PrintSequence, TextAlign } from "./";
import { PrinterBaseConf } from "../printers/baseConf";
import { getImage, getQR, js_process_dither } from "./escpos/image";
import { RawLine } from "./rawLine";
import _ from "lodash";
import qr from "qr-image";
import { BitmapOpts, WrappedContext } from "../common";

// StarPRNT
// https://starmicronics.com/support/developers/thermal-desktop-mobile-printer-command-specs/

export class StarPrintSequence extends PrintSequence {
  feedValue = 10;

  constructor(public context: WrappedContext, printer: PrinterBaseConf) {
    super(context, printer);
    this.raw([0x1b, 0x40]); // 1B 40 esc @
    // Select UTF Code
    // ESC GS ) U
    this.raw([0x1b, 0x1d, 0x29, 0x55, 0x2, 0x0, 48, 1]);
    this.feedValue = printer?.conf?.opts?.feedValue ?? 10;
  }

  async getImage(url: string, numChars: number, hiRes?: boolean) {
    const pxPerChar = hiRes ? 12 : 6;
    const pxPerLine = hiRes ? 24 : 8;
    const pxRatio = pxPerChar / pxPerLine * 2;
    const width = numChars * pxPerChar;

    const img = await this.convertImage(url, width, {
      pxPerLine,
      pxRatio,
    });

    if(!img) {
      console.warn("Failed to load image");
      return [];
    }

    return getImage(img, numChars, pxPerLine, hiRes);
  }

  getQR(url: string, size: number) {
    return getQR(url, size);
  }

  async printImage(url: string, owidth: number, hiRes?: boolean) {
    this.raw(this.getPreLine());
    try {
      const { width, height, buffer } = await this.convertImage(url, owidth);

      const b = (width / 8) | 0;
      const rows = [];

      const h = height;
      const mbuf = Buffer.alloc(8);
      mbuf[0] = 27;
      mbuf[1] = 29;
      mbuf[2] = 83;
      mbuf[3] = 1;
      mbuf[4] = b % 256;
      mbuf[5] = (b / 256) | 0;
      mbuf[6] = h % 256;
      mbuf[7] = (h / 256) | 0;
      rows.push(mbuf);

      for (let i = 0; i < height; i++) {
        const buf = Buffer.alloc(b);

        if (i < height - 1) {
          let ofs = width * i * 4;

          for (let j = 0; j < b; j++) {
            let v = 0;
            for (let k = 0; k < 8; k++) {
              const mv = 128 >> k;
              v |= buffer[ofs] ? 0 : mv;
              ofs += 4;
            }
            buf[j] = v;
          }
        }

        rows.push(buf);
      }

      this.raw(Buffer.concat(rows));
      // const { width, height, buffer } = await this.convertImage(url, owidth);
      // const rows = [];

      // if(hiRes) {
      //   const b = Math.floor((width + 7) / 8)
      //   const heightBytes = ((height + 23) / 24) | 0;
      //   const bitmapLine = width * 4;

      //   const mbuf = Buffer.alloc(4);
      //   mbuf[0] = 0x1b;
      //   mbuf[1] = hiRes ? 0x6b : 0x4b;
      //   mbuf[2] = b % 256;
      //   mbuf[3] = (b / 256) | 0;

      //   for (let i = 0; i < heightBytes; i++) {
      //     rows.push(mbuf);

      //     const buf = new Uint8Array(b);
      //     let bufOfs = 0;
      //     for (let k = 0; k < 24; k++) {
      //       let yofs = (i * 24 + k) * bitmapLine;
      //       let nextOfs = Math.min(buffer.length, yofs + bitmapLine);

      //       for (let j = 0; j < b; j++) {
      //         let v = 0;
      //         for (let l = 0; l < 8; l++) {
      //           const mv = 128 >> l;
      //           v |= yofs >= nextOfs || buffer[yofs] ? 0 : mv;
      //           yofs += 4;
      //         }
      //         buf[bufOfs++] = v;
      //       }
      //     }

      //     rows.push(buf);
      //   }
      // } else {
      //   // TODO
      // }

      // this.raw(Buffer.concat(rows));
    } catch (e) {
      console.warn(e);
    }

    return this;
  }

  async printImageTag(tag: string, url?: string) {
    this.raw(this.getPreLine());
    const opts = this.printer?.bitmapList?.[tag];
    const hiRes = this.printer?.conf?.opts?.[tag + "_hiRes"] ?? opts?.hiRes ?? false;
    if (tag) {
      const buf = Buffer.from(tag);
      if (buf.length !== 2) throw new Error(`invalid image tag`);
      this.raw([0x1b, 0x1d, 0x28, 0x4c, 6, 0, 48, 69, buf[0], buf[1], hiRes ? 1 : 2, hiRes ? 1 : 2]);
      return this;
    }
    return await super.printImageTag(tag, url);
  }

  async downloadImages() {
    const images = Object.values(this.printer?.bitmapList || {});

    for (let opts of images) {
      try {
        const hiRes = this.printer?.conf?.opts?.[opts.tag + "_hiRes"] ?? opts?.hiRes ?? false;
        const url = this.printer.resolveBitmap(opts.tag, undefined, this.context);
        const { width, height, buffer } = await this.convertImage(url, opts.width * (hiRes ? 2 : 1));

        // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=70
        // GS ( L   <Function 67> (raster)
        // GS ( L pL pH 30 43 a kc1 kc2 b xL xH yL yH [c d1...dk]1...[c d1...dk]b
        // 1B 1D 28 4C pL pH m fn a kc1 kc2 b xL xH yL yH [c d1 … dk]1 … [c d1 … dk]b
        const buf = Buffer.from(opts.tag);

        if (buf.length !== 2) throw new Error(`invalid image tag`);

        const b = (width / 8) | 0;
        const rows = [];
        for (let i = 0; i < height; i++) {
          const buf = Buffer.alloc(b);
          if (i < height - 1) {
            let ofs = width * i * 4;

            for (let j = 0; j < b; j++) {
              let v = 0;
              for (let k = 0; k < 8; k++) {
                const mv = 128 >> k;
                v |= buffer[ofs] ? 0 : mv;
                ofs += 4;
              }
              buf[j] = v;
            }
          }
          rows.push(buf);
        }

        const rawSize = Buffer.concat(rows);
        const cmdSize = rawSize.length + 13;
        this.raw([
          0x1b,
          0x1d,
          0x28,
          0x4c,
          cmdSize % 256,
          (cmdSize / 256) | 0,
          48,
          67,
          48,
          buf[0],
          buf[1],
          1,
          width % 256,
          (width / 256) | 0,
          height % 256,
          (height / 256) | 0,
          49,
        ]);
        this.raw(rawSize);
      } catch (e) {
        console.warn(e);
      }
    }

    // TODO
    // this.raw([0x1c, 0x71, maxId]);

    // for (let i = 1; i <= maxId; i++) {
    //   const opts: BitmapOpts = imageById[i];
    //   if (opts) {
    //     const hiRes = this.printer?.conf?.opts?.[opts.tag + "_hiRes"] ?? opts?.hiRes ?? false;
    //     try {
    //       const { width, height, buffer } = await this.convertImage(opts.defaultImage, opts.width * (hiRes ? 2 : 1));
    //       const widthBytes = ((width + 7) / 8) | 0;
    //       const heightBytes = ((height + 7) / 8) | 0;
    //       const bitmapLine = width * 4;

    //       this.raw([widthBytes % 256, (widthBytes / 256) | 0, heightBytes % 256, (heightBytes / 256) | 0]);
    //       const buf = new Uint8Array(heightBytes * widthBytes * 8);
    //       let bufOfs = 0;
    //       for (let j = 0; j < width; j++) {
    //         for (let i = 0; i < heightBytes; i++) {
    //           let v = 0;
    //           let yofs = j * 4 + i * 8 * bitmapLine;
    //           for (let l = 0; l < 8; l++) {
    //             const mv = 128 >> l;
    //             v |= yofs > buffer.length || buffer[yofs] ? 0 : mv;
    //             yofs += bitmapLine;
    //           }
    //           buf[bufOfs++] = v;
    //         }
    //       }

    //       this.raw(buf);
    //       continue;
    //     } catch (e) {
    //       console.warn(e);
    //     }
    //   }
    //   this.raw([0, 0, 0, 0]);
    return this;
  }

  printQR(text: string, numChars: number, w: number = 6) {
    if (!text) return;
    this.raw(this.getPreLine());
    // QR Code: Select the model 2
    this.raw([0x1b, 0x1d, 0x79, 0x53, 0x30, 2]);
    // QR Code: Sets mistake correction level to L.
    this.raw([0x1b, 0x1d, 0x79, 0x53, 0x31, 0]);
    // QR Code: Set the size of module
    this.raw([0x1b, 0x1d, 0x79, 0x53, 0x32, Math.min(8, w || 6)]);
    // QR Code: Store the data in the symbol storage area

    //              ESC   GS    y     D     2     a
    this.raw([0x1b, 0x1d, 0x79, 0x44, 0x32, 1]);
    const buf = this.getText(text);
    const store_len = buf.length;
    const store_pL = store_len % 256;
    const store_pH = (store_len / 256) | 0;

    //              m1 n1L       n1H
    this.raw([3, store_pL, store_pH]);
    this.raw(buf);

    // QR Code: Print the symbol data in the symbol storage area
    this.raw([0x1b, 0x1d, 0x79, 0x50]);

    return this;
  }

  feed(n: number) {
    this.raw([0x1b, 0x61, Math.max(1, Math.ceil(n / 2))]);
    return this;
  }

  cut() {
    this.raw([0x1b, 0x64, 0]);
    return this;
  }

  align(n: TextAlign) {
    this.raw([0x1b, 0x1d, 0x61, n]);
    return this;
  }

  font(n: number) {
    this.raw([0x1b, 0x1e, 0x46, n]);
    return this;
  }

  bold(on: boolean) {
    if (on) {
      this.raw([0x1b, 0x45]);
    } else {
      this.raw([0x1b, 0x46]);
    }
    return this;
  }

  currentWidth = 0;
  currentHeight = 0;

  fontSize(w?: number, h?: number) {
    this.currentWidth = w ?? 0;
    this.currentHeight = h ?? 0;
    this.raw([0x1b, 0x69, h, w]);
    return this;
  }

  get fontWidthScale() {
    return this.currentWidth + 1;
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  getText(text: string): Buffer | number[] {
    return Buffer.from(text);
  }

  getLine(): number[] {
    return [0xa, 0xd];
  }

  printCode(
    type: "upca" | "upce" | "ean8" | "ean13" | "code39" | "itf" | "codabar" | "code93" | "code128",
    code: string,
    showChars?: false | "above" | "below",
    height?: number,
  ) {
    let m = 0;
    let n = 0;
    switch (type) {
      case "upca":
        m = 1;
        n = code.length;
        break;
      case "upce":
        m = 0;
        n = code.length;
        break;
      case "ean8":
        m = 2;
        n = code.length;
        break;
      case "ean13":
        m = 3;
        n = code.length;
        break;
      case "code39":
        m = 4;
        n = code.length;
        break;
      case "itf":
        m = 5;
        n = code.length;
        break;
      case "code128":
        m = 6;
        n = code.length;
        break;
      default:
        throw new Error("Not supported code: " + type);
    }

    this.raw([0x1d, 0x62, m, showChars ? 2 : 0, 2, height ?? 20]);
    this.raw(Buffer.from(code));
    this.raw([0x1e]);

    return this;
  }

  cashBox(which: number, time: number) {
    this.raw([0x07]);
    return this;
  }

  getJobOpts() {
    if(this.printer?.conf?.port === "cloud") {
      return {
        type: "escpos", // workaround for cloud printing
      }
    }
    return {
      type: "star",
    };
  }

  get useBitmapImage(): boolean {
    return this.printer?.conf?.opts?.bitmapImage ?? true;
  }
}
