
import { Component, FindType, Prop, Vue, Watch, checkID, getID, mixins } from "@feathers-client";

@Component
export default class MemberDetailRow extends Vue {
  @Prop()
  item: FindType<"appUsers">;

  get rank() {
    const rank = this.item.ranks?.[0]?.rank;
    if (!rank) return null;
    return this.$shop.shopRanks[getID(rank)];
  }

  get pointQuery() {
    return {
      tag: ["point", "dollar"],
    };
  }

  getUserPoint(item, point) {
    return item?.points?.find?.(list => checkID(list.point, point))?.amount ?? 0;
  }
}
