
import { FindType } from "@feathers-client";
import { Component, Prop, Vue, Watch, VModel } from "@feathers-client";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { managePrint } from "~/plugins/printer/invoiceSequencer";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class LocalPrinterPicker extends Vue {
  @Prop()
  device: any;

  @VModel()
  inputValue: any;

  @Prop()
  contentType: string;

  deviceItem: FindType<"posDevices"> = null;

  @Watch("device", { immediate: true })
  async onDevice() {
    if (this.device) {
      this.deviceItem = await this.$feathers.service("posDevices").get(this.device);
    } else {
      this.deviceItem = null;
    }
  }

  get printers() {
    return this.deviceItem?.localStorage?.printers || [];
  }

  get printerList() {
    return this.printers
      .filter(it => it.type === this.contentType)
      .map((printer: any) => {
        return {
          _id: printer.id,
          name: printer.name,
        };
      });
  }

  async manage() {
    try {
      await managePrint(this, this.contentType, undefined, true);
      await this.onDevice();
    } catch (e) {
      console.log(e.message);
    }
  }

  async testPrint() {
    await this.$feathers.service("sharePrintJobs").create({
      type: "test",
      device: this.device,
      localPrinter: this.inputValue,
      job: {
        jobType: "test",
      },
    });
  }
}
