
import type { FindType } from '@feathers-client'
import _ from 'lodash'

export type SummaryItem = FindType<'tableSessions/summaries'>[number];

export function sumSummary<TIn extends SummaryItem>(input: TIn[]) {
    if(!input.length) return null;
    let result : TIn = Object.fromEntries(
        Object.entries(input[0]).map(([k, v]) => [k, k === '_id' ? {...v as any} : 0])
    ) as any;

    for(let item of input) {
        for(let [k, v] of Object.entries(item)) {
            if(k === '_id') continue;
            result[k] = (result[k] || 0) + v;
        }
    }

    return result;
}

export function groupSummary<TIn extends SummaryItem, T>(input: TIn[], gp: (item: TIn) => T) {
    const result : (Omit<TIn, '_id'> & {_id: T })[] = [];
    for(let [k, v] of Object.entries(_.groupBy(input, it => JSON.stringify(gp(it))))) {
        result.push({
            ...sumSummary(v),
            _id: JSON.parse(k)
        } as any)
    }
    return result;
}

export function statusGrouper<TIn extends SummaryItem>(item: TIn) : Omit<TIn['_id'], 'status'> & {
    status: 'done' | 'void' | 'cancelled' | 'ongoing' | 'test'
} {
    switch(item._id.status) {
        case 'done':
            return { ...item._id, status: 'done' } as any
        case 'void':
            return { ...item._id, status: 'void' } as any
        case 'cancelled':
        case 'cancelledBooking':
        case 'absent':
            return { ...item._id, status: 'cancelled' } as any
        case 'ongoing':
        case 'booking':
        case 'queue':
        case 'toPay':
        default:
            return { ...item._id, status: 'ongoing' } as any
        case 'test':
            return { ...item._id, status: 'test' } as any
    }
}



