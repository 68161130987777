
import { Component, Prop, Vue, Watch, mixins, VModel } from "nuxt-property-decorator";
import _, { isObject } from "lodash";
import { getSplitName } from "~/plugins/table/utils";
import { SessionConainterLayout, TableSessionRef, TableView } from "~/plugins/table/view";
import type { Shape } from "konva/lib/Shape";
import type { Group } from "konva/lib/Group";
import { Blur } from "konva/lib/filters/Blur";
import { Canvas, SceneCanvas } from "konva/lib/Canvas";
import { Context } from "konva/lib/Context";
import { TableItem } from "~/plugins/table";
import iconFonts from "~/plugins/iconfont";
import iconFont from "~/plugins/iconfont";
import { SessionMoveAction, TableSession } from "~/plugins/table/session";
import Konva from "konva";

const isSafari = true; // /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

@Component
export default class ItemSessionExpand extends Vue {
  @Prop()
  item: TableItem;

  @Prop()
  position: {
    x: number;
    y: number;
    width: number;
    height: number;
    canvasWidth: number;
    canvasHeight: number;
  };

  @Prop()
  tableView: TableView;

  @Prop()
  selectedShape: Shape;

  headerHeight = 56;
  footerHeight = 68;

  footerContentHeight = 56;

  footerPaddingX = 12;
  footerPaddingY = 8;

  itemWidth = 48;
  itemHeight = 54;
  itemPadding = 4;

  listPaddingX = 16;
  listPaddingY = 8;

  tableOffsetX = 10;
  get tableOffsetY() {
    return this.headerHeight + 10;
  }

  shapeWidth = 14;
  shapeHeight = 51;
  dragging = false;
  movingSession = false;

  @VModel()
  selectBubbleMenu: boolean;

  get width() {
    return (this.itemWidth + this.itemPadding) * 5 - this.itemPadding + this.listPaddingX * 2;
  }

  get rows() {
    return Math.ceil((this.item?.sessions?.length ?? 0) / 5);
  }

  get height() {
    return (
      (this.itemHeight + this.itemPadding) * this.rows -
      this.itemPadding +
      this.listPaddingY * 2 +
      this.headerHeight +
      this.footerHeight
    );
  }

  get layoutCalc() {
    if (!this.position) return { placement: "none", x: 0, y: 0, popover: null };
    const right = this.position.x + this.width;
    const bottom = this.position.y + this.height;
    const cx = this.position.x + this.width / 2;
    const cy = this.position.y + this.height / 2;
    if (
      right < this.position.canvasWidth &&
      bottom < this.position.canvasHeight &&
      this.position.x > 0 &&
      this.position.y > this.headerHeight
    ) {
      return {
        placement: "top left edge",
        x: cx,
        y: cy,
        popover: null,
      };
    } else if (
      right > this.position.canvasWidth &&
      bottom < this.position.canvasHeight &&
      this.position.x + this.position.width < this.position.canvasWidth &&
      this.position.y > this.headerHeight
    ) {
      return {
        placement: "top right edge",
        x: this.position.x - this.width / 2 + this.position.width,
        y: cy,
      };
    } else if (
      right < this.position.canvasWidth &&
      bottom > this.position.canvasHeight &&
      this.position.x > 0 &&
      this.position.y + this.position.height < this.position.canvasHeight
    ) {
      return {
        placement: "bottom left edge",
        x: cx,
        y: this.position.y - this.height / 2 + this.position.height,
      };
    } else if (
      right > this.position.canvasWidth &&
      bottom > this.position.canvasHeight &&
      this.position.x + this.position.width < this.position.canvasWidth &&
      this.position.y + this.position.height < this.position.canvasHeight
    ) {
      return {
        placement: "bottom right edge",
        x: this.position.x - this.width / 2 + this.position.width,
        y: this.position.y - this.height / 2 + this.position.height,
      };
    }
    return {
      placement: "none",
      x: 0,
      y: 0,
      popover: null,
    };
  }

  get popoverConfig() {
    return this.layoutCalc.popover
      ? {
          ...this.layoutCalc.popover,
          data: "M0.285767 13.2216C2.12058 13.2216 3.95485 13.2303 5.78966 13.2115C7.54575 13.1932 9.51123 13.1672 11.2202 12.5553C13.0662 11.8941 14.2884 10.7379 15.518 9.37725C16.4033 8.3986 18.1422 6.31058 18.9889 5.30243C19.682 4.47587 21.0385 2.84208 21.7792 2.05163C22.7116 1.05722 23.8641 0.000238419 25.4649 0.000238419C27.0656 0.000238419 28.2176 1.05722 29.1495 2.05062C29.8902 2.84056 31.2467 4.47537 31.9403 5.30142C32.7859 6.30957 34.5249 8.39759 35.4107 9.37624C36.6424 10.7369 37.8624 11.893 39.708 12.5543C41.4174 13.1647 43.3835 13.1921 45.1385 13.2105C46.9738 13.2293 48.8081 13.2206 50.6429 13.2206",
          fill: "rgba(249, 249, 249, 0.78)",
        }
      : null;
  }

  cachedSceneCanvas: SceneCanvas;
  cachedSceneValid = false;

  get headerRectConfig() {
    return {
      x: -this.width / 2,
      y: -this.height / 2,
      width: this.width,
      height: this.headerHeight,
      cornerRadius: [12, 12, 0, 0],
      fill: this.item?.tableColor,
    };
  }

  get tableConfig() {
    if (!this.item) return;
    const px = 16;
    return {
      x: this.headerRectConfig.x + px,
      y: this.headerRectConfig.y,
      width: this.headerRectConfig.width / 2 - px * 2,
      height: this.headerRectConfig.height,
      text: this.item.name,
      verticalAlign: "middle",
      fontSize: 24,
      fontWeight: 600,
      fill: "white",
      fontFamily: this.$config.fontFamily,
    };
  }

  get capacityConfig() {
    if (!this.item) return;
    const px = 16;
    return {
      x: this.headerRectConfig.x + this.headerRectConfig.width / 2 + px,
      y: this.headerRectConfig.y,
      width: this.headerRectConfig.width / 2 - px * 2,
      height: this.headerRectConfig.height,
      text: `${iconFont.peoples} ${this.item.usedCapacity}/${this.item.capacity}`,
      verticalAlign: "middle",
      align: "right",
      fontSize: 18,
      lineheight: 1.5,
      fontWeight: 600,
      fill: "white",
      fontFamily: "iconfont," + this.$config.fontFamily,
    };
  }

  get expandConfigRect() {
    return {
      x: -this.width / 2,
      y: -this.height / 2 + this.headerHeight,
      width: this.width,
      height: this.height - this.headerHeight,
      cornerRadius: [0, 0, 12, 12],
      fill: "white",
    };
  }

  get addButtonConfig() {
    return {
      x: this.expandConfigRect.x + this.footerPaddingX,
      y: this.expandConfigRect.y + this.expandConfigRect.height - this.footerContentHeight + this.footerPaddingY,
      width: 40,
      height: 40,
      fill: this.$config.colors.orange200,
      cornerRadius: 8,
      listening: true,
    };
  }

  get addButtonIconConfig() {
    return {
      data: "M19.875 11C19.3227 11 18.875 11.4477 18.875 12V18.875H12C11.4477 18.875 11 19.3227 11 19.875V20.125C11 20.6773 11.4477 21.125 12 21.125H18.875V28C18.875 28.5523 19.3227 29 19.875 29H20.125C20.6773 29 21.125 28.5523 21.125 28V21.125H28C28.5523 21.125 29 20.6773 29 20.125V19.875C29 19.3227 28.5523 18.875 28 18.875H21.125V12C21.125 11.4477 20.6773 11 20.125 11H19.875Z",
      x: this.addButtonConfig.x,
      y: this.addButtonConfig.y,
      fill: "white",
    };
  }

  get bottomButtonsConfig() {
    if (!this.item) return;
    return {
      x: this.addButtonConfig.x + 100,
      y: this.addButtonConfig.y,
      width: this.width - this.footerPaddingX * 2 - 70,
      height: this.addButtonConfig.height,
      verticalAlign: "middle",
      lineheight: 1.5,
    };
  }
  get orderButtonConfig() {
    if (!this.item) return;
    return {
      x: this.bottomButtonsConfig.x,
      width: 50,
      height: 40,
      text: this.$t("basic.placeOrder"),
      fontSize: 18,
      lineheight: 1.5,
      fontWeight: 600,
      verticalAlign: "middle",
      fontFamily: this.$config.fontFamily,
    };
  }

  get splitButtonConfig() {
    if (!this.item) return;
    return {
      x: this.bottomButtonsConfig.x + 150,
      width: 50,
      height: 40,
      text: this.$t("sessionMove.split"),
      fontSize: 18,
      lineheight: 1.5,
      fontWeight: 600,
      verticalAlign: "middle",
      fontFamily: this.$config.fontFamily,
    };
  }

  get addTableButtonConfig() {
    if (!this.item) return;
    return {
      x: this.bottomButtonsConfig.x + 60,
      width: 80,
      height: 40,
      text: this.$t("tableView.addOrSplitTable"),
      fontSize: 18,
      lineheight: 1.5,
      fontWeight: 600,
      verticalAlign: "middle",
      fontFamily: this.$config.fontFamily,
    };
  }

  get clearButtonConfig() {
    if (!this.item) return;
    return {
      x: this.bottomButtonsConfig.x + 150,
      width: 50,
      height: 40,
      text: this.$t("tableView.clearTable"),
      fontSize: 18,
      lineheight: 1.5,
      fontWeight: 600,
      verticalAlign: "middle",
      fontFamily: this.$config.fontFamily,
    };
  }

  get selectedSession() {
    // console.log("selectedSession", this.tableView.selectedSession?.tableRefs[0]?.table.name);
    return this.tableView.selectedSession;
  }

  configLayer = { listening: true, opacity: 0 };

  get configShow() {
    return {
      x: this.layoutCalc.x,
      y: this.layoutCalc.y,
      opacity: this.layoutCalc.placement !== "none" ? 1 : 0,
    };
  }

  get configHide() {
    return {
      x: this.layoutCalc.x,
      y: this.layoutCalc.y,
      opacity: 0,
    };
  }

  mounted() {
    if (this.selectBubbleMenu) {
      this.onToggleMenu();
    }
  }

  @Watch("configShow")
  async onUpdatePosition() {
    if (!this.showing && this.selectBubbleMenu) {
      const group: Group = (this.$refs.group as any).getNode();
      group.setPosition(this.configShow);
      this.cachedSceneValid = false;
    }
  }

  @Watch("selectBubbleMenu")
  onToggleMenu() {
    const group: Group = (this.$refs.group as any).getNode();
    if (this.selectBubbleMenu && this.selectedSession) {
      group.setAttrs(this.configHide);
      this.movingSession = false;
      this.show();
    } else {
      this.hide();
    }
  }

  showing = false;

  async show() {
    if (this.showing) return;
    this.showing = true;
    try {
      await Vue.nextTick();
      const group: Group = (this.$refs.group as any).getNode();
      group.listening(true);
      this.cachedSceneValid = false;
      // do transform

      if (this.$store.state.performance) {
        group.setAttrs(this.configShow);
      } else {
        await new Promise(resolve => {
          group.to({
            ...this.configShow,
            duration: 0.2,
            onFinish: resolve,
          });
        });
      }
    } finally {
      this.showing = false;
    }
  }

  async hide() {
    this.showing = false;
    const group: Group = (this.$refs.group as any).getNode();
    // do transform
    group.listening(false);
    group.setAttrs(this.configHide);
    if (this.dragging && !this.$store.state.performance) {
      await new Promise(resolve => {
        group.to({
          ...this.configHide,
          duration: 0.2,
          onFinish: resolve,
        });
      });
    }
  }

  get items() {
    const items: SessionConainterLayout[] = [];
    const sessions = this.item?.sessions; //_.orderBy(this.item?.sessions, s => s.session.item.startTime, "desc") || [];
    for (let i = 0; i < sessions?.length; i++) {
      const item = sessions[i];
      items.push({
        multiple: false,
        x: (this.itemWidth + this.itemPadding) * (i % 5) + this.tableOffsetX - this.width / 2,
        y: (this.itemHeight + this.itemPadding) * ((i / 5) | 0) + this.tableOffsetY - this.height / 2,
        w: this.itemWidth,
        h: this.itemHeight,
        item: item,
      });
    }
    return items;
  }

  async beginDrag() {
    this.dragging = true;
    const group: Group = (this.$refs.group as any).getNode();
    if (this.$store.state.performance) {
      group.setAttrs({
        opacity: 0.3,
      });
    } else {
      await new Promise(resolve => {
        group.to({
          opacity: 0.3,
          duration: 0.2,
          onFinish: resolve,
        });
      });
    }
  }

  async endDrag() {
    this.dragging = false;
    const group: Group = (this.$refs.group as any).getNode();
    if (!this.movingSession) {
      if (this.$store.state.performance) {
        group.setAttrs({
          opacity: 1,
        });
      } else {
        await new Promise(resolve => {
          group.to({
            opacity: 1,
            duration: 0.2,
            onFinish: resolve,
          });
        });
      }
    } else {
      this.hide();
    }
  }

  get tableSize() {
    return this.tableView.selectedCapacity || 1;
  }
  get tableCapacity() {
    return this.tableView.selectedCapacity;
  }

  async splitOrder() {
    const session = this.selectedSession;
    const ref = session.selectedRef;
    if (!ref) return;
    const initAction: SessionMoveAction = {
      actions: [
        {
          from: {
            ref: ref,
          },
          to: {
            ref: ref,
            capacity: ref.capacity,
          },
        },
      ],
      newSessions: [],
    };
    this.$emit("closeMenu");
    const res = await this.$openDialog(
      import("~/components/table/SessionMoveDialog.vue"),
      {
        session,
        initAction,
        initOperation: "split",
        initMergeTarget: ref.table,
        mergeSource: {
          ref: ref,
          x: 0,
          y: 0,
        },
      },
      {
        contentClass: "editor-dialog",
      },
    );
    if (res) return;
  }

  async addTableMenu() {
    this.$emit("mergeTableTransform", this.selectedShape, this.item, false, this.selectedSession);
  }

  touchingSameRef = false;

  onElemDown(e) {
    e.pointerOnExpand = true;
    this.touchingSameRef = false;
    const evt = e.evt;
    if (evt && window.TouchEvent && evt instanceof TouchEvent) {
      if (evt.touches.length > 1) return;
    }

    e.cancelBubble = true;
    const ref: TableSessionRef = e.currentSession;

    if (this.item.selected && !ref) {
      this.item.deselect();
      this.$emit("updateMenu", e.currentTarget);
      return;
    }

    if (ref) {
      if (ref.session.selected && ref.session.selectedRef === ref) {
        this.touchingSameRef = true;
        return;
      } else {
        ref.session.selected = true;
        ref.session.selectedRef = ref;
      }
    } else if (this.item.view?.selectedSession) {
      this.item.view.selectedSession.selected = false;
    }

    this.item.select();
    this.$emit("updateMenu", e.currentTarget);
  }

  onElemClick(e) {
    e.pointerOnExpand = true;
    e.cancelBubble = true;
    const ref: TableSessionRef = e.currentSession;
    if (ref) {
      if (ref.session.selectedRef === ref && this.touchingSameRef) {
        this.$emit("openOrder", ref.session);
      }
    }
  }

  cancelClick(e) {
    e.pointerOnExpand = true;
    this.touchingSameRef = false;
  }

  onElemTouchUp(e) {
    e.pointerOnExpand = true;
    if (this.touchingSameRef && !this.dragging) {
      this.$emit("openOrder");
    }
  }

  onEmpty(e) {
    if (!e.pointerOnExpand) {
      e.pointerOnExpandEmpty = true;
    }
  }

  async clearSession() {
    await this.selectedSession.atomic({
      endTime: new Date(),
    });
    this.$emit("closeMenu");
  }
}

export const blur = (context: CanvasRenderingContext2D, amount: number) => {
  // do not manipulate without proper amount
  if (amount <= 0) {
    return context;
  }

  const { height, width } = context.canvas;
  const imageData = context.getImageData(0, 0, width, height);
  const { data } = imageData;

  // http://www.quasimondo.com/BoxBlurForCanvas/FastBlur.js
  const wm = width - 1;
  const hm = height - 1;
  const rad1 = amount + 1;

  const mulTable = [
    1, 57, 41, 21, 203, 34, 97, 73, 227, 91, 149, 62, 105, 45, 39, 137, 241, 107, 3, 173, 39, 71, 65, 238, 219, 101,
    187, 87, 81, 151, 141, 133, 249, 117, 221, 209, 197, 187, 177, 169, 5, 153, 73, 139, 133, 127, 243, 233, 223, 107,
    103, 99, 191, 23, 177, 171, 165, 159, 77, 149, 9, 139, 135, 131, 253, 245, 119, 231, 224, 109, 211, 103, 25, 195,
    189, 23, 45, 175, 171, 83, 81, 79, 155, 151, 147, 9, 141, 137, 67, 131, 129, 251, 123, 30, 235, 115, 113, 221, 217,
    53, 13, 51, 50, 49, 193, 189, 185, 91, 179, 175, 43, 169, 83, 163, 5, 79, 155, 19, 75, 147, 145, 143, 35, 69, 17,
    67, 33, 65, 255, 251, 247, 243, 239, 59, 29, 229, 113, 111, 219, 27, 213, 105, 207, 51, 201, 199, 49, 193, 191, 47,
    93, 183, 181, 179, 11, 87, 43, 85, 167, 165, 163, 161, 159, 157, 155, 77, 19, 75, 37, 73, 145, 143, 141, 35, 138,
    137, 135, 67, 33, 131, 129, 255, 63, 250, 247, 61, 121, 239, 237, 117, 29, 229, 227, 225, 111, 55, 109, 216, 213,
    211, 209, 207, 205, 203, 201, 199, 197, 195, 193, 48, 190, 47, 93, 185, 183, 181, 179, 178, 176, 175, 173, 171, 85,
    21, 167, 165, 41, 163, 161, 5, 79, 157, 78, 154, 153, 19, 75, 149, 74, 147, 73, 144, 143, 71, 141, 140, 139, 137,
    17, 135, 134, 133, 66, 131, 65, 129, 1,
  ];
  const mulSum = mulTable[amount];

  const shgTable = [
    0, 9, 10, 10, 14, 12, 14, 14, 16, 15, 16, 15, 16, 15, 15, 17, 18, 17, 12, 18, 16, 17, 17, 19, 19, 18, 19, 18, 18,
    19, 19, 19, 20, 19, 20, 20, 20, 20, 20, 20, 15, 20, 19, 20, 20, 20, 21, 21, 21, 20, 20, 20, 21, 18, 21, 21, 21, 21,
    20, 21, 17, 21, 21, 21, 22, 22, 21, 22, 22, 21, 22, 21, 19, 22, 22, 19, 20, 22, 22, 21, 21, 21, 22, 22, 22, 18, 22,
    22, 21, 22, 22, 23, 22, 20, 23, 22, 22, 23, 23, 21, 19, 21, 21, 21, 23, 23, 23, 22, 23, 23, 21, 23, 22, 23, 18, 22,
    23, 20, 22, 23, 23, 23, 21, 22, 20, 22, 21, 22, 24, 24, 24, 24, 24, 22, 21, 24, 23, 23, 24, 21, 24, 23, 24, 22, 24,
    24, 22, 24, 24, 22, 23, 24, 24, 24, 20, 23, 22, 23, 24, 24, 24, 24, 24, 24, 24, 23, 21, 23, 22, 23, 24, 24, 24, 22,
    24, 24, 24, 23, 22, 24, 24, 25, 23, 25, 25, 23, 24, 25, 25, 24, 22, 25, 25, 25, 24, 23, 24, 25, 25, 25, 25, 25, 25,
    25, 25, 25, 25, 25, 25, 23, 25, 23, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 24, 22, 25, 25, 23, 25, 25, 20, 24, 25,
    24, 25, 25, 22, 24, 25, 24, 25, 24, 25, 25, 24, 25, 25, 25, 25, 22, 25, 25, 25, 24, 25, 24, 25, 18,
  ];
  const shgSum = shgTable[amount];

  const r = [];
  const g = [];
  const b = [];
  const a = [];

  const vmin = [];
  const vmax = [];

  let iterations = 3; // 1 - 3
  let p, p1, p2, pa;

  while (iterations-- > 0) {
    let yw = 0;
    let yi = 0;

    for (let y = 0; y < height; y++) {
      let rsum = data[yw] * rad1;
      let gsum = data[yw + 1] * rad1;
      let bsum = data[yw + 2] * rad1;
      let asum = data[yw + 3] * rad1;

      for (let i = 1; i <= amount; i++) {
        // tslint:disable-next-line no-bitwise
        p = yw + ((i > wm ? wm : i) << 2);
        rsum += data[p++];
        gsum += data[p++];
        bsum += data[p++];
        asum += data[p];
      }

      for (let x = 0; x < width; x++) {
        r[yi] = rsum;
        g[yi] = gsum;
        b[yi] = bsum;
        a[yi] = asum;

        if (y === 0) {
          // tslint:disable-next-line no-bitwise
          vmin[x] = ((p = x + rad1) < wm ? p : wm) << 2;
          // tslint:disable-next-line no-bitwise
          vmax[x] = (p = x - amount) > 0 ? p << 2 : 0;
        }

        p1 = yw + vmin[x];
        p2 = yw + vmax[x];

        rsum += data[p1++] - data[p2++];
        gsum += data[p1++] - data[p2++];
        bsum += data[p1++] - data[p2++];
        asum += data[p1] - data[p2];

        yi++;
      }
      // tslint:disable-next-line no-bitwise
      yw += width << 2;
    }

    for (let x = 0; x < width; x++) {
      let yp = x;
      let rsum = r[yp] * rad1;
      let gsum = g[yp] * rad1;
      let bsum = b[yp] * rad1;
      let asum = a[yp] * rad1;

      for (let i = 1; i <= amount; i++) {
        yp += i > hm ? 0 : width;
        rsum += r[yp];
        gsum += g[yp];
        bsum += b[yp];
        asum += a[yp];
      }

      // tslint:disable-next-line no-bitwise
      yi = x << 2;

      for (let y = 0; y < height; y++) {
        // tslint:disable-next-line no-bitwise
        data[yi + 3] = pa = (asum * mulSum) >>> shgSum;

        if (pa > 0) {
          pa = 255 / pa;
          // tslint:disable-next-line no-bitwise
          data[yi] = ((rsum * mulSum) >>> shgSum) * pa;
          // tslint:disable-next-line no-bitwise
          data[yi + 1] = ((gsum * mulSum) >>> shgSum) * pa;
          // tslint:disable-next-line no-bitwise
          data[yi + 2] = ((bsum * mulSum) >>> shgSum) * pa;
        } else {
          data[yi] = data[yi + 1] = data[yi + 2] = 0;
        }

        if (x === 0) {
          vmin[y] = ((p = y + rad1) < hm ? p : hm) * width;
          vmax[y] = (p = y - amount) > 0 ? p * width : 0;
        }

        p1 = x + vmin[y];
        p2 = x + vmax[y];

        rsum += r[p1] - r[p2];
        gsum += g[p1] - g[p2];
        bsum += b[p1] - b[p2];
        asum += a[p1] - a[p2];

        // tslint:disable-next-line no-bitwise
        yi += width << 2;
      }
    }
  }
};
