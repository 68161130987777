
import { Vue, Component, Prop, VModel, PropSync, FindType, Watch } from "@feathers-client";
import LanguagePicker from "~/components/LanguagePicker.vue";
import moment from "moment";
import { wrapJob } from "../../plugins/printer/invoiceSequencer";
import { printTemplate } from "@common/table/invoiceSequencer";
import { PrintSequence } from "pos-printer/printSequence";

@Component({
  LanguagePicker,
})
export default class InputGroup extends Vue {
  @VModel() numDialog: boolean;
  @Prop() kioskMode: boolean;
  @Prop({ default: false }) darkMode: boolean;
  @Prop() maxNum: number;

  inputValueSyncStr: string = "";
  openOverlay = false;
  ticket: FindType<"shopQueueTickets"> = null;
  newTicketNum = "";
  error = false;
  selectedGroup: FindType<"shopQueueingGroups"> = null;
  loading = false;

  onClickOutside() {
    if (this.kioskMode) return;
    this.numDialog = false;
  }
  async confirmGroup() {
    this.loading = true;
    let shopSessionData = { ...this.$shopSession.shopSessionData };
    // for (let i = 0; i <= 10; i++) {
    let ticketNum = "";
    // get the latest ticket number
    if (shopSessionData.queueingTicketGroups && shopSessionData.queueingTicketGroups.length > 0) {
      const queueingTicketObj = shopSessionData.queueingTicketGroups.find(
        ticket => ticket.ticketGroup === this.selectedGroup._id,
      );
      if (queueingTicketObj) {
        if (queueingTicketObj.ticketNumber !== 0) {
          ticketNum = this.selectedGroup.prefix + (queueingTicketObj.ticketNumber + 1).toString().padStart(3, "0");
          queueingTicketObj.ticketNumber += 1;
        } else {
          queueingTicketObj.ticketNumber = 1;
        }
        //find queueingTicketGroup index
        const queueingTicketGroupIndex = shopSessionData.queueingTicketGroups.findIndex(
          ticket => ticket.ticketGroup === this.selectedGroup._id,
        );
        shopSessionData.queueingTicketGroups[queueingTicketGroupIndex] = queueingTicketObj;
      }
    }
    // no ticket number created before
    if (ticketNum === "") {
      ticketNum = this.selectedGroup.prefix + "1".padStart(3, "0");
    }
    console.log("ticketNum", ticketNum);
    this.newTicketNum = ticketNum;
    //create ticket
    this.ticket = await this.$feathers.service("shopQueueTickets").create({
      queueingGroup: this.selectedGroup._id,
      ticketNumber: ticketNum,
      shopSession: shopSessionData._id,
    });
    this.printTicket(ticketNum, this.$td(this.selectedGroup.name), this.selectedGroup.room);
    // }
    //update the shop session data with the new ticket number
    if (shopSessionData.queueingTicketGroups && shopSessionData.queueingTicketGroups.length > 0) {
      await this.$feathers.service("shopSessions").patch(this.$shopSession.shopSessionData._id, { ...shopSessionData });
    }
    //show the new ticket user got
    this.openOverlay = true;
    setTimeout(() => {
      this.openOverlay = false;
    }, 5000);

    //show no group found error
    // this.newTicketNum = "";
    // this.error = true;
    // setTimeout(() => {
    //   this.error = false;
    // }, 5000);

    //pos mode just hide the num dialog and emit the error message if no ticket
    if (!this.kioskMode) {
      this.numDialog = false;
      if (!this.newTicketNum) {
        this.$store.commit("SET_ERROR", this.$t("queueing.noTicketGroupFound"));
      }
    }
    this.selectedGroup = null;
    this.loading = false;
  }

  // async printTicket(ticketNum: string, groupName: string, room?: string) {
  //   await wrapJob(this, "queueing-ticket", async (sequence, template) => {
  //     await printTemplate(sequence, template, () => import("!!raw-loader!@common/table/templates/queueingTicket.vue"), {
  //       shop: this.$shop.shopData,
  //       time: moment().format("DD/MM/YYYY HH:mm:ss"),
  //       ticketNum,
  //       groupName,
  //       room,
  //     });
  //     return sequence.getJob(`Queueing Ticket ${ticketNum}`);
  //   });
  // }
  async printTicket(ticketNum: string, groupName: string, room?: string) {
    try {
      await wrapJob<any, PrintSequence>(this, "queueing-ticket", 
        printTemplate,
        {
          templateName: "queueingTicket",
          data: {
            shop: this.$shop.shopData,
            time: moment().format("DD/MM/YYYY HH:mm:ss"),
            ticketNum,
            groupName,
            room,
          }
        }
      )
    } catch (error) {
      console.error(error.message);
    }
    
  }
}
