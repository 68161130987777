import { Component, FindType, Prop, Vue, Watch, checkID, getID, getOptions, mixins } from "@feathers-client";
import { update } from "lodash";
import moment from "moment";
import { ObjectID } from "@mfeathers/db/schemas";
@Component
export class ShopSession extends Vue {
  cachedShopQueueingGroups = null;
  shopSessionData: FindType<"shopSessions"> = null;
  loadShopSessionTask: Promise<void>;
  cachedShopQueueingPastTickets = [];
  inited = false;
  init() {
    if (this.inited) return this;
    this.inited = true;
    this.$feathers.service("shopSessions").on("created", async shopSession => {
      const oldShopSession = { ...this.shopSessionData };
      this.shopSessionData = shopSession;
      if (shopSession.openQueueing && shopSession.openQueueing !== oldShopSession.openQueueing) {
        await this.$shopSession.updateCachedShopQueueingGroups();
        await this.$shopSession.updateShopQueueingPastTickets();
      }
    });
    this.$feathers.service("shopSessions").on("patched", async shopSession => {
      const oldShopSession = { ...this.shopSessionData };
      this.shopSessionData = shopSession;
      if (shopSession.openQueueing && shopSession.openQueueing !== oldShopSession.openQueueing) {
        await this.$shopSession.updateCachedShopQueueingGroups();
        await this.$shopSession.updateShopQueueingPastTickets();
      }
    });
    this.$feathers.service("shopQueueingGroups").on("patched", shopQueueingGroup => {
      this.updateCachedShopQueueingGroups();
      this.updateShopQueueingPastTickets();
    });
    this.$feathers.service("shopQueueingGroups").on("created", shopQueueingGroup => {
      this.updateCachedShopQueueingGroups();
      this.updateShopQueueingPastTickets();
    });
    this.$feathers.service("shopQueueTickets").on("created", shopQueueTicket => {
      let shopSessionData = { ...this.shopSessionData };
      let queueingTicketGroup = shopSessionData.queueingTicketGroups.find(item =>
        checkID(item.ticketGroup, shopQueueTicket.queueingGroup),
      );
      if (!queueingTicketGroup) {
        queueingTicketGroup = {
          ticketGroup: shopQueueTicket.queueingGroup,
          ticketNumber: 1,
          currentTicket: null,
          callingTicket: null,
        };
        shopSessionData.queueingTicketGroups.push(queueingTicketGroup);
        this.$feathers.service("shopSessions").patch(shopSessionData._id, { ...shopSessionData });
      }
    });
    this.$feathers.service("shopQueueTickets").on("patched", shopQueueTicket => {
      // let shopSessionData = { ...this.shopSessionData };
      if (shopQueueTicket.status === "calling") {
        // let queueingTikcetGroupIndex = shopSessionData.queueingTicketGroups.findIndex(ticket => ticket.ticketGroup === shopQueueTicket.queueingGroup);
        // shopSessionData.queueingTicketGroups[queueingTikcetGroupIndex].callingTicket = shopQueueTicket._id;
        // this.$feathers.service("shopSessions").patch(shopSessionData._id, { ...shopSessionData });
        this.$root.$emit("callTicket", shopQueueTicket.ticketNumber);
      }
      // else if (shopQueueTicket.status === "seated") {
      //   //set current calling ticket to null when seated
      //   const queueingTikcetGroup = shopSessionData.queueingTicketGroups.find(it => it.ticketGroup === shopQueueTicket.queueingGroup);
      //   if (queueingTikcetGroup && queueingTikcetGroup.callingTicket === shopQueueTicket._id) {
      //     queueingTikcetGroup.callingTicket = null;
      //   }
      //   const index = shopSessionData.queueingTicketGroups.findIndex(it => it.ticketGroup === shopQueueTicket.queueingGroup);
      //   shopSessionData.queueingTicketGroups[index] = queueingTikcetGroup;
      //   this.$feathers.service("shopSessions").patch(shopSessionData._id, { ...shopSessionData });
      // }
      this.updateShopQueueingPastTickets();
    });
    this.loadShopSessionTask = this.loadShopSessionData();
    return this;
  }

  async loadShopSessionData() {
    try {
      await this.$shop.loadShopTask;
      if (!this.$shop.shopData || !this.$shop.shopData.currentSession) return;
      this.shopSessionData = await this.$feathers.service("shopSessions").get(this.$shop.shopData.currentSession);
      await this.updateCachedShopQueueingGroups();
      await this.updateShopQueueingPastTickets();
    } catch (e) {
      console.warn(e);
    }
  }

  get asyncCachedShopQueueingGroups() {
    if (!this.cachedShopQueueingGroups) {
      this.cachedShopQueueingGroups = (async () => {
        const result = await this.$feathers.service("shopQueueingGroups").find({
          query: {
            shop: this.$shop.shopId,
            $limit: 1000,
            $sort: { order: 1 },
            $paginate: false,
          },
          paginate: false,
        });
        this.cachedShopQueueingGroups = result;
        return result;
      })();
    }
    if (this.cachedShopQueueingGroups instanceof Promise) return null;
    return this.cachedShopQueueingGroups;
  }
  get shopQueueingGroups() {
    const defaultColor = ["#FFB466", "#7EC8F1", "#9F99E1", "#57DB8F", "#FFD966", "#EC9393"];
    let colorIndex = 0;
    if (!this.asyncCachedShopQueueingGroups) return [];
    this.asyncCachedShopQueueingGroups.map(group => {
      if (!group.active) {
        group.color = group.color;
      } else {
        group.color = group.color ? group.color : defaultColor[colorIndex] ? defaultColor[colorIndex] : "#7EC8F1";
        colorIndex++;
      }
    });
    return this.asyncCachedShopQueueingGroups ? this.asyncCachedShopQueueingGroups : [];
  }
  async updateCachedShopQueueingGroups() {
    console.warn("start update shop queueing groups");
    this.cachedShopQueueingGroups = await this.$feathers.service("shopQueueingGroups").find({
      query: {
        shop: this.$shop.shopId,
        $limit: 1000,
        $paginate: false,
      },
      paginate: false,
    });
    // let shopSessionData = { ...this.shopSessionData };
    if (this.cachedShopQueueingGroups.length) {
      if (!this.shopSessionData.queueingTicketGroups) {
        this.shopSessionData.queueingTicketGroups = this.cachedShopQueueingGroups.map(item => ({
          ticketGroup: item._id,
          ticketNumber: 0,
          currentTicket: null,
          callingTicket: null,
        }));
      } else {
        this.shopSessionData.queueingTicketGroups = this.shopSessionData.queueingTicketGroups.filter(item =>
          this.cachedShopQueueingGroups.find(group => checkID(group._id, item.ticketGroup)),
        );
        await Promise.all(
          this.cachedShopQueueingGroups.map(async group => {
            if (!this.shopSessionData.queueingTicketGroups.find(item => checkID(item.ticketGroup, group._id))) {
              this.shopSessionData.queueingTicketGroups.push({
                ticketGroup: group._id,
                ticketNumber: 0,
                currentTicket: null,
                callingTicket: null,
              });
            }
          }),
        );
      }
      if (this.shopSessionData.openQueueing === undefined) {
        this.shopSessionData.openQueueing = false;
      }
      await this.$feathers.service("shopSessions").patch(this.shopSessionData._id, { ...this.shopSessionData });
    }
  }

  get shopQueueingPastTickets() {
    return this.cachedShopQueueingPastTickets ? this.cachedShopQueueingPastTickets : null;
  }

  async updateShopQueueingPastTickets() {
    const now = moment();
    if (this.shopSessionData && this.shopQueueingGroups) {
      this.cachedShopQueueingPastTickets = await Promise.all(
        this.shopQueueingGroups.map(async group => {
          //get past tickets
          let pastTicketMapped = [];
          const pastTickets = await this.$feathers.service("shopQueueTickets").find({
            query: {
              shopSession: this.shopSessionData._id,
              queueingGroup: group._id,
              status: { $nin: ["waiting"] },
              $populate: ["tableSession"],
              $limit: 1000,
              $sort: {
                modified: -1,
              },
              $paginate: false,
            },
            paginate: false,
          });
          if (pastTickets.length) {
            pastTicketMapped = pastTickets.map(ticket => {
              let time = moment(ticket.createTime).format("HH:mm");
              let passedMinutes = moment.duration(now.diff(moment(ticket.createTime))).asMinutes();
              return {
                _id: ticket._id,
                ticketNum: ticket.ticketNumber,
                queueingGroup: group._id,
                prefix: group.prefix,
                color: group.color,
                time: time,
                alreadySeated: ticket.status == "seated" || ticket.status == "checked" ? true : false,
                passed: ticket.passed,
                numCalling: ticket.status == "calling" ? true : false,
                disableQueueing: !group.active,
                passedMinutes: Math.floor(passedMinutes),
                numberOfPeople: ticket.noOfPeople,
                tableName: ticket.tableSession ? ticket.tableSession.tableRefName : null,
              };
            });
          }
          return {
            queueingGroup: group._id,
            prefix: group.prefix,
            color: group.color,
            pastTickets: pastTicketMapped,
          };
        }),
      );
    }
  }

  async resetQueueingGroup(isClose: boolean = false, groupId: ObjectID<any> = null) {
    if (!this.shopSessionData) {
      await this.loadShopSessionData();
    }
    let shopSessionData = { ...this.shopSessionData };
    await this.$feathers.service("shopQueueTickets").remove(null, {
      query: {
        shopSession: shopSessionData._id,
        ...(groupId ? { queueingGroup: groupId } : {}),
      },
    });
    if (!shopSessionData.queueingTicketGroups) return;
    if (!groupId) {
      shopSessionData.queueingTicketGroups = shopSessionData.queueingTicketGroups.map(item => ({
        ...item,
        ticketNumber: 0,
        currentTicket: null,
        callingTicket: null,
      }));
    } else if (shopSessionData.queueingTicketGroups && shopSessionData.queueingTicketGroups.length) {
      let queueingTikcetGroupIndex = shopSessionData.queueingTicketGroups.findIndex(item =>
        checkID(item.ticketGroup, groupId),
      );
      shopSessionData.queueingTicketGroups[queueingTikcetGroupIndex] = {
        ticketGroup: groupId,
        ticketNumber: 0,
        currentTicket: null,
        callingTicket: null,
      };
    }

    if (isClose) {
      shopSessionData.openQueueing = false;
    }
    await this.$feathers.service("shopSessions").patch(shopSessionData._id, { ...shopSessionData });
    await this.updateCachedShopQueueingGroups();
    await this.updateShopQueueingPastTickets();
  }

  created() {
    this.$feathers.on("logout", this.logout);
  }

  logout() {
    this.$destroy();
  }

  beforeDestroy() {
    (<any>this.$root.$options).$shopSession = null;
  }
}

if (!Vue.prototype.hasOwnProperty(`$shopSession`)) {
  Object.defineProperty(Vue.prototype, "$shopSession", {
    get(this: Vue) {
      return (
        (<any>this.$root.$options).$shopSession ||
        ((<any>this.$root.$options).$shopSession = new ShopSession(getOptions(this.$root)).init())
      );
    },
  });
}

declare module "vue/types/vue" {
  export interface Vue {
    $shopSession: ShopSession;
  }
}
