import Vue from "vue";

import DataTable from "domore-table";
import DataTableVirtual from "domore-table/virtual";

import _ from "lodash";
import "./table";
import { Context } from "@nuxt/types";
import { needTouchFix } from "@feathers-client";
import { applyAndroidTouchFix } from "@feathers-client/bootstrap";
import { Currencies, format, fromNumber, setScale } from "@feathers-client/currency";

// Fix for ApexCharts, clone recursively
if (process.browser) {
  try {
    (window as any).Apex = (window as any).Apex || {};
    let _chartInstances = [];
    Object.defineProperty((window as any).Apex, "_chartInstances", {
      enumerable: false,
      get() {
        return _chartInstances;
      },
      set(val) {
        _chartInstances = val;
      },
    });
  } catch (e) {
    console.error(e);
  }
}

if (needTouchFix) {
  applyAndroidTouchFix();
}

setScale(100);

Vue.use(DataTable);
Vue.use(DataTableVirtual);

Vue.filter("listAndTranslate", function (ctx, value, item, parent, header) {
  return _.join(
    _.map(value, it => ctx.$td(it["name"])),
    ", ",
  );
});

Vue.filter("boolean", function (ctx, value, item, parent, header) {
  if (typeof value != "boolean") {
    if (!value) return "N";
    return value;
  }
  return value ? "Y" : "N";
});

Vue.prototype.$en = function (val) {
  if (typeof val != "object") return val;
  if (!val || !val.length) return val;
  for (var it of val) {
    if (it["lang"] == "en") return it.value || "";
  }
  return null;
};

Vue.filter("name", (ctx, value) => {
  return typeof value === "string" ? value : value ? `${value.lastName} ${value.firstName}` : "";
});

Vue.filter("username", (ctx, value) => {
  if (!value) return ctx.$t("basic.no_user");
  if (typeof value === "string") return value;
  return Vue.filter("name")(ctx, value.name) || value.email || value._id;
});

Vue.filter("myCurrency", function (ctx, price) {
  if (!price && typeof price !== "number") return "";
  price = (+price).toFixed(2);
  const sign = price < 0 ? "-" : "";
  price = Math.abs(price);
  const num = Math.floor(price);
  const remain = ((price - num) * 100).toFixed(0);
  const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const remainStr = _.padStart(remain.toString(), 2, "0");
  const priceStr = `${numStr}.${remainStr}`;

  return `${sign}${ctx.$shop.currencySymbol} ${priceStr}`;
});

Vue.filter("currency", function (ctx, price) {
  return ctx.$price(price);
});

Vue.filter("currencyInt", function (ctx, price) {
  return ctx.$priceInt(price);
});

Vue.prototype.$enum = function (value, typeName, locale) {
  const type = this.$types.resolve(typeName);
  return this.$td((type && type.enumDict[value]) || value, locale);
};

Object.defineProperty(Vue.prototype, "$isAdmin", {
  get() {
    return this.$store.state.user.role === "admin";
  },
});

Vue.prototype.$priceInt = function (price, f: boolean | "pre" | "preNum" | "noSign" = false) {
  if (!price && typeof price !== "number") return "";
  if (typeof f === "boolean") {
    return format(fromNumber(+price, this.$shop.currency ?? "HKD"), undefined, f ? "num" : "short", true);
  }

  price = (+price).toFixed(2);
  const sign = price < 0 ? "-" : "";
  price = Math.abs(price);
  const num = Math.floor(price);
  const remain = ((price - num) * 100).toFixed(0);
  const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const remainStr = _.padStart(remain.toString(), 2, "0");
  const priceStr = `${numStr}${f === "preNum" ? "" : `.${remainStr}`}`;

  const currency = Currencies[this.$shop.currency]?.symbol ?? "$";
  return `${sign}${f === "noSign" ? "" : currency + " "}${priceStr}`;
};

Vue.prototype.$price = function (this: Vue, price, short = true, showPlus = false) {
  if (!price && typeof price !== "number") return "";
  price = (+price).toFixed(2);
  const sign = price < 0 ? "-" : showPlus ? "+" : "";
  price = Math.abs(price);
  const num = Math.floor(price);
  const posPriceDigits = this.$shop?.shopData?.posPriceDigits ?? 2;
  const remain = short && !posPriceDigits ? "" : ((price - num) * Math.pow(10, short ? posPriceDigits : 2)).toFixed(0);
  const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const remainStr = _.padStart(remain.toString(), short ? posPriceDigits : 2, "0");
  const priceStr = `${numStr}${short && !posPriceDigits ? "" : `.${remainStr}`}`;

  return `${sign}${this.$shop.currencySymbol} ${priceStr}`;
};

export interface PriceFormat {
  short?: boolean;
  showPlus?: boolean;
  posPriceDigits?: number;
  showCurrency?: boolean;
}

Vue.prototype.$price2 = function (this: Vue, price: number, priceFormat?: PriceFormat) {
  if (!price && typeof price !== "number") return "";
  price = +(+price).toFixed(2);
  const sign = price < 0 ? "-" : priceFormat?.showPlus ? "+" : "";
  price = Math.abs(price);
  const num = Math.floor(price);
  const remain = priceFormat?.short ? "" : ((price - num) * 100).toFixed(0);
  const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const remainStr = remain.toString().padStart(priceFormat?.short ? priceFormat.posPriceDigits : 2, "0");
  const priceStr = `${numStr}${priceFormat?.short ? "" : `.${remainStr}`}`;

  return `${sign}${priceFormat?.showCurrency ? this.$shop.currencySymbol : ""} ${priceStr}`;
};

Vue.filter("capacity", (ctx, value) => {
  if (typeof value === "object" && value) {
    if (Array.isArray(value)) {
      return _.sumBy(value, v => v.capacity || 0);
    } else if (Array.isArray(_.get(value, "tables"))) {
      return _.sumBy(value.tables as any[], v => v.capacity || 0);
    } else if (_.has(value, "capacity")) {
      return _.get(value, "capacity");
    }
  }
  return typeof value === "number" ? value : 0;
});

Vue.filter("actualCapacity", (ctx, value: any) => {
  if (value.type !== "dineIn") return "";
  if (value.actualCapacity !== undefined) {
    return value.actualCapacity;
  }
  return _.sumBy(value.tables as any[], v => v.capacity || 0);
});

export default function (ctx: Context) {}
