import { js_process_floyd } from "../escpos/image";
import { BaseRenderer } from "./base";

export class CanvasRenderer extends BaseRenderer {
  _canvas: CanvasRenderingContext2D;
  _canvasElem: HTMLCanvasElement;

  drawText(
    text: string,
    x: number,
    w: number,
    size: number,
    yScale: number = 1,
    align?: "left" | "right" | "center",
    tight?: boolean,
    inverted?: boolean,
    colPadding: number = 0,
  ) {
    this._canvas.save();

    const charsToDraw = this.measureText(text, w - colPadding * 2);

    const metrics = this._canvas.measureText(text.slice(0, charsToDraw));
    const fontBoundingBoxAscent = tight ? metrics.actualBoundingBoxAscent : metrics.fontBoundingBoxAscent || size;
    const fontBoundingBoxDescent = tight ? metrics.actualBoundingBoxDescent : metrics.fontBoundingBoxDescent || size;

    const fontHeight = fontBoundingBoxAscent + fontBoundingBoxDescent;
    this._canvas.translate(x, this._canvasHeight / 2);

    if (yScale != 1) {
      this._canvas.scale(1, yScale);
    }
    const fromX = align === "right" ? w - metrics.width : align === "center" ? (w - metrics.width) / 2 : 0;
    const toX = align === "right" ? x + w : align === "center" ? x + (w + metrics.width) / 2 : x + metrics.width;

    if (inverted) {
      this._canvas.fillStyle = "black";
      this._canvas.fillRect(0, 0, w, fontHeight + colPadding * 2);
      this._canvas.fillStyle = "white";
    }
    this._canvas.fillText(text.slice(0, charsToDraw), fromX + colPadding, colPadding);
    if (inverted) {
      this._canvas.fillStyle = "black";
    }

    this._canvas.restore();

    return {
      x: toX + colPadding * 2,
      top: this._canvasHeight / 2 - fontBoundingBoxAscent * yScale,
      height: fontHeight * yScale + colPadding * 2,
      charsToDraw,
    };
  }

  async setFont(
    fontFamily: string,
    size: number,
    isDefault: boolean,
    props?: {
      bold?: boolean;
      italic?: boolean;
      inverted?: boolean;
    },
  ) {
    if (!this._canvas) {
      await this.prepareContext();
    }
    if (this._canvas) {
      this._canvas.font = `${props?.bold ? "bold " : ""}${props?.italic ? "italic " : ""}${size}px ${fontFamily}`;
    }
  }

  getImageData(x: number, y: number, w: number, h: number): ImageData {
    const imgData = this._canvas.getImageData(x, y, w, h);
    js_process_floyd(imgData.data, imgData.width, imgData.height);
    return imgData;
  }

  measureText(text: string, w: number) {
    let left = 1;
    let right = text.length;
    let lowest = 1;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const width = this._canvas.measureText(text.slice(0, mid)).width;
      if (w < width) {
        right = mid - 1;
      } else if (w > width) {
        left = mid + 1;
        lowest = mid;
      } else {
        return Math.max(1, mid);
      }
    }
    return Math.max(1, lowest);
  }

  async prepareContext() {
    if (!this._canvas) {
      if (typeof document === "undefined" || !document.createElement) {
        return null;
      }
      this._canvasElem = document.createElement("canvas");
      this._canvasElem.width = this.parent.lineWidth * 12;
      this._canvasElem.height = this._canvasHeight;
      this._canvas = this._canvasElem.getContext("2d", {
        willReadFrequently: true,
      });
    }
    this._canvas.fillStyle = "white";
    this._canvas.fillRect(0, 0, this.parent.lineWidth * 12, this._canvasHeight);
    this._canvas.fillStyle = "black";
    return this._canvas;
  }

  disposeContext() {
    if (this._canvas) {
      this._canvasElem.width = this._canvasElem.height = 0;
      this._canvas = undefined;
      this._canvasElem = undefined;
    }
  }
}
