
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { FindPopRawType, FindType, checkID, getID } from "@feathers-client";

@Component
export default class OrderSystemDiscount extends Vue {
  @Prop()
  session: TableSession;

  customAdjusts = false;
  adjustsStr = "";
  tab: string = "discount";

  get items() {
    return [
      { _id: "discount", name: this.$t("pos.mobile.checkout.tabs.discount") },
      ...(!this.$features.disableManualAdjustment
        ? [{ _id: "adjustAmount", name: this.$t("pos.mobile.checkout.tabs.adjustAmount") }]
        : []),
    ];
  }

  get presetAdjusts() {
    const roundTo10 = Math.ceil(this.sumWithoutManual / 10) * 10;
    const roundTo20 = Math.ceil(this.sumWithoutManual / 20) * 20;
    const roundTo50 = Math.ceil(this.sumWithoutManual / 50) * 50;
    const roundTo100 = Math.ceil(this.sumWithoutManual / 100) * 100;
    const roundTo500 = Math.ceil(this.sumWithoutManual / 500) * 500;

    const mroundTo10 = Math.floor(this.sumWithoutManual / 10) * 10;
    const mroundTo20 = Math.floor(this.sumWithoutManual / 20) * 20;
    const mroundTo50 = Math.floor(this.sumWithoutManual / 50) * 50;
    const mroundTo100 = Math.floor(this.sumWithoutManual / 100) * 100;
    const mroundTo500 = Math.floor(this.sumWithoutManual / 500) * 500;

    return _.uniqBy(
      [
        {
          _id: mroundTo10 - this.sumWithoutManual,
          name: this.$price(mroundTo10 - this.sumWithoutManual, true),
        },
        {
          _id: mroundTo20 - this.sumWithoutManual,
          name: this.$price(mroundTo20 - this.sumWithoutManual, true),
        },
        {
          _id: mroundTo50 - this.sumWithoutManual,
          name: this.$price(mroundTo50 - this.sumWithoutManual, true),
        },
        {
          _id: mroundTo100 - this.sumWithoutManual,
          name: this.$price(mroundTo100 - this.sumWithoutManual, true),
        },
        {
          _id: mroundTo500 - this.sumWithoutManual,
          name: this.$price(mroundTo500 - this.sumWithoutManual, true),
        },

        {
          _id: roundTo10 - this.sumWithoutManual,
          name: this.$price(roundTo10 - this.sumWithoutManual, true),
        },
        {
          _id: roundTo20 - this.sumWithoutManual,
          name: this.$price(roundTo20 - this.sumWithoutManual, true),
        },
        {
          _id: roundTo50 - this.sumWithoutManual,
          name: this.$price(roundTo50 - this.sumWithoutManual, true),
        },
        {
          _id: roundTo100 - this.sumWithoutManual,
          name: this.$price(roundTo100 - this.sumWithoutManual, true),
        },
        {
          _id: roundTo500 - this.sumWithoutManual,
          name: this.$price(roundTo500 - this.sumWithoutManual, true),
        },
      ].filter(it => it._id + this.sumWithoutManual >= 0),
      it => it._id,
    );
  }

  get isCustomTips() {
    return this.adjusts && !this.presetAdjusts.find(it => it._id === this.adjusts);
  }

  get adjusts() {
    return this.session.adjusts || 0;
  }
  set adjusts(v) {
    v = !isNaN(+v) ? +v : 0;
    this.session.adjusts = v;
    this.session.delaySave({
      adjusts: v,
      ...this.session.cachedPriceDetails,
    });

    this.customAdjusts = false;
    this.session.syncOrder();
  }

  get sumWithoutManual() {
    return this.session.outstanding - this.adjusts;
  }

  get discounts() {
    const self = this;
    return this.$shop.discounts.map(d => ({
      ...d,
      get active() {
        return !!self.session.coupons.find(it => checkID(d, it.discount));
      },
      set active(v) {
        if (v) {
          (async () => {
            const staff = await self.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
            if (staff === false) return;
            await self.session.tryApplyDiscount(d);
            await self.flushUpdates();

            await self.$feathers.service("actionLogs").create({
              session: self.session.item._id,
              view: getID(self.session.item.view),
              staff: staff?._id || self.$shop.staffId,
              type: `paymentManage/tableSessionDiscount`,
              detail: { discount: d, enabled: v },
            });
          })();
        } else {
          (async () => {
            const staff = await self.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
            if (staff === false) return;
            const tempDiscount = self.session.coupons.find(it => checkID(d, it.discount));
            self.session.coupons.find(it => checkID(d, it.discount))?.remove?.();
            await self.flushUpdates();

            if (tempDiscount) {
              await self.$feathers.service("actionLogs").create({
                session: self.session.item._id,
                view: getID(self.session.item.view),
                staff: staff?._id || self.$shop.staffId,
                type: `paymentManage/tableSessionDiscount`,
                detail: { discount: d, enabled: v },
              });
            }
          })();
        }
      },
    }));
  }

  get vipDiscounts() {
    return (this.session.coupons || []).filter(it => it.discountSource === "vip");
  }

  get coupons() {
    return (this.session.coupons || []).filter(it => it.discountSource === "coupon");
  }

  get gifts() {
    return (this.session.coupons || []).filter(it => it.discountSource === "gift");
  }

  get automatic() {
    return (this.session.coupons || []).filter(it => it.discountSource === "automatic" && (it.valid || it.disabled));
  }

  async flushUpdates() {
    this.session.updateCoupons();
    await this.session.atomic({
      ...this.session.cachedPriceDetails,
    });
    this.customAdjusts = false;
  }
}
