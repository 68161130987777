
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeCut, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorCut extends Vue {
  @Prop() template!: PrinterTemplateNodeCut;

  @Prop() context: any;
}
