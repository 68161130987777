
import type { ProductLine, ProductLineOption, TableSession } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import type { CartItem } from "@common/table/cart";
import { TableSessionRef } from "~/plugins/table";
import { checkID, getID } from "@feathers-client";
import type { ProductType } from "~/plugins/shop";
import { StockLevel, getProductTree } from "@common/table/util";
import type { LangArrType } from "@feathers-client/i18n";

@Component
export default class OrderSystemProductLine extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  line: ProductLine;

  @Prop()
  lastLine: ProductLine;

  @Prop()
  cart: CartItem;

  @Prop()
  lastCart: CartItem;

  @Prop({ type: Boolean, default: true })
  hasMenu: boolean;

  @Prop({ type: Boolean })
  selecting: boolean;

  @Prop(Boolean)
  posScreen: boolean;

  @Prop({ type: Boolean, default: false })
  readOnly: boolean;

  get showRemarks() {
    if (!this.line) return [];
    return this.line.detailedRemarks.filter(
      options =>
        options.type !== "product" &&
        options.type !== "openKey" &&
        options.type !== "discount" &&
        options.type !== "customRemark",
    );
  }

  get showProductShortName() {
    return !this.$shop.localOptions.onlyShowProductFullName;
  }

  get previewMerge() {
    if (this.line && (this.line as any).replacing) {
      return null;
    }
    const merge = this.session?.cachedMergeResultDict?.[this.item.id];
    if (this.line && merge && this.line.fromProduct === merge.fromProduct) {
      return null;
    }
    return merge;
  }

  get previewMergeName() {
    const merge = this.previewMerge;
    if (!merge || merge.mergeStatus !== "mergedFrom") return;
    const parentItem = this.session?.cachedMergeResultDict?.[merge.fromProduct];
    if (!parentItem) return;
    // TODO: if merging other products together into a set, show the relation
    return this.$td(parentItem.name);
  }

  get mobileMode() {
    return this.$breakpoint.mdAndDown;
  }

  get statusLine() {
    if (!this.lastLine) {
      // first item
      return !this.item.fromProduct;
    } else {
      const productsState =
        this.lastLine.status === "cancel" || this.lastLine.status === "pending"
          ? this.statusDisplay(this.lastLine.status)
          : this.statusDisplay(this.lastLine.kdsStatus, true);
      const itemState =
        this.line.status === "cancel" || this.line.status === "pending"
          ? this.statusDisplay(this.line.status)
          : this.statusDisplay(this.line.kdsStatus, true);
      return productsState !== itemState;
    }
  }

  get orderTime() {
    if (!this.line || this.line.fromProduct) return;
    if (!this.lastLine) {
      // first item
      return true;
    } else {
      return this.$moment(this.lastLine.date).format("HH:mm") !== this.$moment(this.line.date).format("HH:mm");
    }
  }

  get divider() {
    if (!this.lastLine) {
      // first item
      return !this.item.fromProduct;
    } else {
      return this.lastLine.seq !== this.line.seq;
    }
  }

  get setDivider() {
    if (this.cart) {
      return this.lastCart && !this.cart.fromProduct;
    }
    if (!this.lastLine) {
      // first item
      return this.$shop.localOptions.showProductOrderTime;
    } else {
      return !this.item.fromProduct;
    }
  }

  get status() {
    return this.item?.status;
  }

  get kdsStatus() {
    return this.line?.kdsStatus;
  }

  get item(): (CartItem | ProductLine) & { replacing?: boolean } {
    return this.cart || this.line;
  }
  get orderSystem() {
    return !!this.line;
  }
  get itemSection() {
    return getID(this.item.section);
  }

  get selected() {
    return ((this.item as any)["selected"] as boolean) || false;
  }
  set selected(v: boolean) {
    Vue.set(this.item, "selected", v);
  }

  get stockLevel() {
    return this.cart?.product ? this.session.stockStatus[getID(this.cart.product)] ?? StockLevel.None : StockLevel.None;
  }

  get stockStatus() {
    switch (this.stockLevel) {
      case StockLevel.Disabled:
      case StockLevel.None:
      case StockLevel.Disabled:
        return "hidden";

      case StockLevel.Conflicted:
        return "text-red300 triangle-after";

      case StockLevel.OutOfStock:
      case StockLevel.NotSelling:
        return "bg-grey500";

      case StockLevel.Low:
        return "bg-red300";
      case StockLevel.Medium:
        return "bg-yellow000";
      case StockLevel.High:
        return "bg-green100";
    }
  }

  productStatus(state) {
    if (this.kdsStatus === "pending" && (this.status === "hold" || this.status === "pending")) {
      state = "unSend";
    }
    switch (state) {
      case "unSend":
        return "bg-red100 text-red200 border-b-red200";
      case "init":
        return "bg-grey100";
      case "pending":
        return "bg-blue000 text-blue200 border-b-blue200";
      case "done":
      case "partialDone":
        return "bg-purple400 text-purple500 border-b-purple400";
      case "hold":
        return "bg-red400 text-red500 border-b-red400";
      case "cancel":
        return "bg-red200 text-white border-b-red200";
    }
  }

  itemStatus(state) {
    if (this.kdsStatus === "pending" && (this.status === "hold" || this.status === "pending")) {
      state = "unSend";
    }
    switch (state) {
      case "unSend":
        return "bg-red100";
      case "init":
        return "bg-grey100";
      case "pending":
        return "bg-blue000";
      case "done":
      case "partialDone":
        return "bg-purple400";
      case "hold":
        return "bg-red400";
      case "cancel":
        return "bg-red200";
    }
  }

  statusDisplay(state, isKds = false) {
    if (
      (this.session?.type === "takeAway" || this.session?.type === "dineInNoTable") &&
      this.kdsStatus === "pending" &&
      !isKds &&
      (state === "hold" || state === "pending")
    ) {
      state = "unSend";
    }
    if (this.session?.type === "dineIn" && !isKds && state === "pending") {
      state = "unSend";
    }
    switch (state) {
      case "unSend":
        return this.$t("takeaway.hold");
      case "orderPending":
        return this.$t("tableView.status.orderPending");
      case "pending":
        return this.$t("tableView.status.pending");
      case "done":
      case "partialDone":
        return this.$t("tableView.status.done");
      case "hold":
        return this.$t("tableView.status.hold");
      case "cancel":
        return this.$t("tableView.status.cancel");
    }
  }

  get displayOrdering() {
    return this.$shop.localOptions.orderingDisplay;
  }

  async openProductMenu(cart: CartItem, showOptions: boolean) {
    if (!this.hasMenu) return;
    if (cart == this.cart) {
      if (!this.$el) {
        await Vue.nextTick();
      }
      if (this.$el) {
        // check if in view
        let scrollable = this.$el.parentElement;
        while (scrollable && !scrollable.classList.contains("scrollable")) {
          scrollable = scrollable.parentElement;
        }
        if (!scrollable) return;
        if (scrollable) {
          const rect = this.$el.getBoundingClientRect();
          const scrollRect = scrollable.getBoundingClientRect();

          if (rect.top < scrollRect.top || rect.bottom + 20 > scrollRect.bottom) {
            this.$el.scrollIntoView({ behavior: "smooth" });
            await new Promise<void>(resolve => setTimeout(resolve, 300));
          }
        }
      }

      this.session.openProductMenu({ cart: this.cart, line: this.line, showOptions });
    }
  }

  get isPaying() {
    return this.session?.status === "toPay";
  }

  shortpress() {
    if (this.isCancelled) return; // guard productLine item onClick , if the product is 'cancelled'

    if (this.selecting) {
      this.selected = !this.selected;
      return;
    }
    if (this.isPaying || !this.session) return;
    if (!this.hasMenu) return;
    if (this.item.id === this.session.selectingCartItemId) {
      this.session.openProductMenu(null);
    } else {
      this.session.openProductMenu({ cart: this.cart, line: this.line, showOptions: true });
    }

    this.$emit("click");
  }

  get product() {
    return typeof this.item.product === "object"
      ? (this.item.product as any as ProductType)
      : this.$shop.productDict[`${this.item.product}`];
  }

  get targetRef() {
    return (
      this.item.table &&
      this.session.tableRefs.find(it => it.table.id === `${this.item.table}` && it.split === this.item.tableSplit)
    );
  }

  get targetName() {
    return (
      (this.line as any)?.targetName || (this.targetRef ? this.targetRef.session.getSplitName(this.targetRef) : "")
    );
  }

  get hasTarget() {
    if (this.session?.tableRefs?.length <= 1) return false;
    return (this.line as any)?.targetName || this.session?.type === "dineIn";
  }

  get orderReadOnly() {
    return !this.session?.postEditing && this.session?.status !== "ongoing";
  }

  get isCancelled() {
    return this.item.status === "cancel";
  }

  get cancellable() {
    // only can cancel
    // 1. if not cancelled
    // 2. order is not read only
    // 3. if in cart, not set product
    return (
      !this.isCancelled &&
      !this.item.fromCoupon &&
      (!this.line || !this.line.fromProduct) &&
      !this.orderReadOnly &&
      (!this.line || this.$shop.hasStaffRole("cancelProduct"))
    );
  }

  get thumb() {
    return (
      (this.product?.image && this.$thumb(this.product.image)) ||
      this.$config.appLogo ||
      require("~/assets/images/logo.png")
    );
  }

  get totalPrice() {
    if (!this.item || this.item.priceWithoutProduct == 0) return;
    if (this.item.fromProduct) {
      if (this.item.originalPriceWithoutProduct == 0) return;
      return this.$price(this.item.quantity * (this.item.originalPriceWithoutProduct ?? this.item.actualPrice));
    }
    return this.$price(this.item.quantity * (this.item.priceWithoutProduct ?? this.item.actualPrice));
  }

  get hold() {
    return this.item.status === "hold";
  }

  set hold(v: boolean) {
    this.item.status = v ? "hold" : "init";
  }

  resumeHold() {
    this.item.status = "init";
    this.$emit("saveRows", [this.item]);
  }

  moveFromCart() {
    this.$emit("remove");
    if (this.session.selectingCartItemId === this.item.id) {
      this.session.openProductMenu(null);
    }
  }

  async cancelProduct() {
    if (!this.line) {
      this.moveFromCart();
      return;
    }
    const c = await this.$openDialog(
      import("./cancelReason.vue"),
      {
        line: [this.line],
        session: this.session,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
    if (c) {
      this.session.updateCoupons();
    }
  }

  get discountList() {
    return this.session?.discountHints?.[this.item.id];
  }
}
