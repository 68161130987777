
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindType,
  FindPopRawType,
  FindPopType,
  checkID,
  getID,
} from "@feathers-client";
import MemberList from "~/components/table/memberSystem/memberList.vue";
import MemberDetail from "~/components/table/memberSystem/memberDetail.vue";
import MemberCard from "~/components/table/memberSystem/memberCard.vue";
import TransactionList from "~/components/table/memberSystem/transactionList.vue";
import MerchantCart from "~/components/table/orderSystem/merchantCart.vue";
import MerchantConfirm from "~/components/table/orderSystem/merchantConfirm.vue";
import CouponTicker from "~/components/table/orderSystem/couponTicker.vue";
import { TableSession } from "~/plugins/table/session";

@Component({
  components: {
    MemberList,
    MemberDetail,
    MemberCard,
    TransactionList,
    MerchantCart,
    MerchantConfirm,
    CouponTicker,
  },
})
export default class OrderSystemMobileCrmMerchantConfirm extends Vue {
  @Prop() point: FindType<"shopPoints">;
  @Prop() dollar: FindType<"shopPoints">;

  @Prop() session: TableSession;
  @Prop() currentAction;

  @Prop() availDollar: number;

  @PropSync("topUpAmount")
  topUpAmountSync: number;

  @Prop()
  pointEarned: number;
}
