
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem } from "~/plugins/table";

@Component
export default class extends Vue {
    @Prop({ type: Number, default: 1 })
    index: number;

    @Prop()
    tableView : TableView

    @Prop(Boolean)
    enableTransform : boolean

    item : TableItem = null;

    get lastItem() {
        return this.tableView?.selectedItems?.[0];
    }

    @Watch('lastItem')
    onLastItem() {
        if(!this.lastItem) return;
        this.item = this.lastItem;
    }

    mounted() {
        this.onLastItem();
    }

    async deleteTable() {
        const c = !this.item.item._id || await this.$openDialog(import('@feathers-client/components-internal/ConfirmDialog.vue'), {
            title: this.$t('basic.doYouWantToDelete')
        }, {
            maxWidth: '400px',
        });
        if(c) {
            this.item.deselect();
            this.$emit('resetSelect');
            await this.item.deleteTable();
        }
    }
}
