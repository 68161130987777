
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import RadioIcon from "@feathers-client/components-internal/RadioIcon.vue";

@Component({
  components: {
    EditorObjectPickerNew,
    EditorTextField,
    RadioIcon,
  },
})
export default class TwTax extends Vue {
  @Prop()
  session: TableSession;

  menu = false;

  mounted() {
    if (!this.session.sessionData.twTaxType) {
      this.session
        .atomic({
          twTaxType: "paper",
        })
        .catch(console.error);
    }
  }

  get uploadInvoice() {
    return this.session.sessionData.twTaxType && this.session.sessionData.twTaxType !== "none";
  }

  get hasData() {
    return (
      this.session.sessionData.twTaxVehicle ||
      this.session.sessionData.twTaxComp ||
      this.session.sessionData.twTaxNrt ||
      this.session.sessionData.twDonate
    );
  }

  clearData() {
    this.session.sessionData.twTaxVehicle = null;
    this.session.sessionData.twTaxComp = null;
    this.session.sessionData.twTaxNrt = null;
    this.session.sessionData.twDonate = null;
  }

  async toggleFunction(value: boolean) {
    if (value) {
      if (this.session.sessionData.twTaxType === "none") {
        this.session.sessionData.twTaxType = "paper";
        this.flushUpdates();
      }
      return true;
    } else {
      if (!this.hasData || (await this.$confirm(this.$t("twInvoice.confirmClear")))) {
        this.session.sessionData.twTaxType = "none";
        this.clearData();
        this.flushUpdates();
        return true;
      }
    }
  }

  async switchType(type: string) {
    if (this.session.sessionData.twTaxType === type) {
      return;
    }

    if (this.hasData && !(await this.$confirm(this.$t("twInvoice.confirmClear")))) {
      return;
    }

    this.clearData();

    this.session.sessionData.twTaxType = type as any;
    this.flushUpdates();
  }

  get items() {
    return [
      {
        _id: "paper",
        name: this.$t("turnCloud.taxType.paper"),
        field: null,
        maxlength: 0,
      },
      {
        _id: "electronic",
        name: this.$t("turnCloud.taxType.electronic"),
        field: "twTaxVehicle",
        maxlength: 64,
      },
      {
        _id: "company",
        name: this.$t("turnCloud.taxType.company"),
        field: "twTaxComp",
        maxlength: 8,
      },
      {
        _id: "nrt",
        name: this.$t("turnCloud.taxType.nrt"),
        field: "twTaxNrt",
        maxlength: 10,
      },
      {
        _id: "donate",
        name: this.$t("turnCloud.taxType.donate"),
        field: "twDonate",
        maxlength: 8,
      },
    ];
  }

  get taxDetails() {
    switch (this.session.sessionData.twTaxType) {
      case "electronic":
        return this.session.sessionData.twTaxVehicle || "-";
      case "company":
        return this.session.sessionData.twTaxComp || "-";
      case "nrt":
        return this.session.sessionData.twTaxNrt || "-";
      case "donate":
        return this.session.sessionData.twDonate || "-";
      default:
        return null;
    }
  }

  async flushUpdates() {
    this.session.delaySave({
      twTaxType: this.session.sessionData.twTaxType,
      ...(this.session.sessionData.twTaxType === "nrt"
        ? {
            twTaxVehicle: null,
            twTaxComp: null,
            twTaxNrt: this.session.sessionData.twTaxNrt,
            twDonate: null,
          }
        : this.session.sessionData.twTaxType === "company"
          ? {
              twTaxVehicle: null,
              twTaxComp: this.session.sessionData.twTaxComp,
              twTaxNrt: null,
              twDonate: null,
            }
          : this.session.sessionData.twTaxType === "electronic"
            ? {
                twTaxVehicle: this.session.sessionData.twTaxVehicle,
                twTaxComp: null,
                twTaxNrt: null,
                twDonate: null,
              }
            : this.session.sessionData.twTaxType === "donate"
              ? {
                  twTaxVehicle: null,
                  twTaxComp: null,
                  twTaxNrt: null,
                  twDonate: this.session.sessionData.twDonate,
                }
              : {
                  twTaxVehicle: null,
                  twTaxComp: null,
                  twTaxNrt: null,
                  twDonate: null,
                }),
    });

    const targetTax = this.session.sessionData.twTaxType === "nrt";
    let dirty = false;

    for (let tax of this.session.sessionData.taxes) {
      if (tax.disabled !== targetTax) {
        tax.disabled = targetTax;
        dirty = true;
      }
    }

    if (dirty) {
      this.session.updateCoupons();
      await this.session.atomic({
        ...this.session.cachedPriceDetails,
      });
    }

    this.session.syncOrder();
  }
}
