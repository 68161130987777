import { PrinterBase, PrinterConf } from './base'
import { PrinterServer, PrinterCommand } from '../index'
import { PrintJob } from '../printQueue';

export interface NetConf extends PrinterConf {
    port : 'net'
}

export class NetPrinter extends PrinterBase<string, NetConf> {
    constructor(server: PrinterServer, conf : NetConf) {
        super(server, conf);
        this.handleMessage = (cmd) => {
            clearTimeout(this.timer);
            this.timer = <any>setTimeout(() => this.timeout(), 2*60*1000);
        }
    }

    timer : number;

    handleMessage: (cmd : PrinterCommand) => void;
    
    onDisconnectedCore() {
    }

    async initCore() {
    }

    async tryConnectCore() {
        await this.parent.sendRemoteEvent({
            command: 'connect',
            deviceId: this.conf.id,
            clientId: this.parent.id,
            serverId: this.device,
        })
        const device = await Promise.race<PrinterCommand>([
            new Promise<PrinterCommand>(resolve => setTimeout(() => resolve(null), 5000)),
            new Promise<PrinterCommand>(resolve => this.parent.once(`netDevice/${this.conf.id}/${this.device}`, resolve)),
        ])
        if(!device) throw new Error("Cannot connect to network device")
        this.timer = <any>setTimeout(() => this.timeout(), 2*60*1000);
        this.parent.on(`netDevice/${this.conf.id}/${this.device}`, this.handleMessage);
    }

    async printCore(job : PrintJob) {
        await this.parent.sendRemoteEvent({
            command: 'print',
            deviceId: this.conf.id,
            clientId: this.parent.id,
            serverId: this.device,
            jobId: job.id,
            jobData: job.data.toString('base64'),
            jobName: job.name,
            jobOpts: job.opts,
        })
        let device = await Promise.race<PrinterCommand>([
            new Promise<PrinterCommand>(resolve => setTimeout(() => resolve(null), 5000)),
            new Promise<PrinterCommand>(resolve => this.parent.once(`netDevice/${this.conf.id}/${this.device}/${job.id}`, resolve)),
        ])
        if(!device) {
            throw new Error("Cannot send job to network device")
        }
        if(device.jobStatus !== 'queued' && device.jobStatus !== 'printing') return;
        device = await Promise.race<PrinterCommand>([
            new Promise<PrinterCommand>(resolve => this.once('disconnected', resolve)),
            new Promise<PrinterCommand>(resolve => this.parent.once(`netDevice/${this.conf.id}/${this.device}/${job.id}/done`, resolve)),
        ])
        if(!device) {
            throw new Error("Job timeout");
        }
    }

    async disconnectCore() {
        if(this.timer) {
            this.timer = <any>setTimeout(() => this.timeout(), 2*60*1000);
            this.parent.removeListener(`netDevice/${this.conf.id}/${this.device}`, this.handleMessage);
        }
    }

    timeout() {
        this.onDisconnected("Printer timeout");
    }

    async requestNewDeviceCore() {
        await this.parent.sendRemoteEvent({
            command: 'connect',
            deviceId: this.conf.id,
            clientId: this.parent.id,
            serverId: this.conf.address,
        })

        const device = await Promise.race<PrinterCommand>([
            new Promise<PrinterCommand>(resolve => setTimeout(() => resolve(null), 5000)),
            new Promise<PrinterCommand>(resolve => this.parent.once(`netDevice/${this.conf.id}/${this.conf.address}`, resolve)),
        ])
        if(!device) throw new Error("Cannot connect to network device")
        return device.serverId;
    }
}
