
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import { ProductType } from "~/plugins/shop";
import { CartItem } from "@common/table/cart";
import { getProductStockLevel, StockLevel } from "~/common/table/util";
import CartPanelBase from "~/components/table/orderSystem/cartPanelBase";

@Component({})
export default class MobileCart extends mixins(CartPanelBase) {
  editingCart: CartItem = null;
  editingLine: ProductLine = null;
  tempKitchenOptions: { _id: string; name: LangArrType }[] = [];

  @Prop()
  session: TableSession;

  @PropSync("lastCart")
  lastCartSync: CartItem;

  @Prop()
  showingDialog: boolean;

  @Prop()
  selectingCart: boolean;

  @Prop()
  selectingLine: boolean;

  currentTempPriceVal: number = null;
  get currentTempPrice() {
    return this.currentTempPriceVal === null ? this.editingCart?.price : this.currentTempPriceVal;
  }

  set currentTempPrice(v) {
    this.currentTempPriceVal = v;
  }
}
