
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class extends Vue {
    @Prop({ type: Number, default: 47 })
    width: number;
    @Prop({ type: Number, default: 48 })
    height: number;

    @Prop(String)
    state : 'none' | 'avail' | 'using' | 'book'

    get color() {
        switch(this.state) {
            case 'avail':
                return '#27AE60';
            case 'using':
                return '#DF6D6D'
            case 'book':
                return '#828282'
            default:
                return '#2D9CDB'
        }
    }

    // get color2() {
    //     switch(this.state) {
    //         case 'avail':
    //             return '#149e7a';
    //         case 'using':
    //             return '#c10808'
    //         case 'book':
    //             return '#e76537'
    //         default:
    //             return '#989898'
    //     }
    // }
}
