
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem } from "~/plugins/table";
import Dialog from "~/mixins/DialogTS";

@Component
export default class extends mixins(Dialog) {
  step = 0;
  restaurantType = null;
  view: TableView = null;
  loading = false;

  beforeMount() {
    this.view = this.$tableManager.createTempView();
    this.view.beginEdit();
    const table = this.view.addViewItem();
    table.select();
  }

  beforeDestroy() {
    if (this.view) {
      this.view.$destroy();
      this.view = null;
    }
  }

  previous() {
    if (this.step > 0) this.step--;
  }
  async next() {
    if (!this.view) return;
    this.loading = true;
    try {
      if (this.step < this.lastStep) this.step++;
      else {
        await this.view.save();
        await this.view.endEdit();
        this.view.clearSelect();
        for (let it of this.view.viewItems) await it.save();
        this.view = null;
        this.modalResult(true);
      }
    } finally {
      this.loading = false;
    }
  }
  get lastStep() {
    return this.steps.length - 1;
  }

  get steps() {
    return [
      {
        title: "歡迎使用餐桌管理系統",
        subtitle: "我們將會一步一步附助你設定你的餐桌",
      },
      {
        title: "請選擇你的餐廳類型",
        subtitle: "系統將會為你設定建議選項",
        component: "table-restaurant-type",
      },
      {
        title: "你的餐廳類型",
        subtitle: "系統將會為你設定建議選項",
        component: "table-or-chair",
      },
      {
        title: "建立餐廳的第一個坐位安排",
        subtitle: "這些餐廳氣泡是可以重復使用的",
        component: "table-bubble-config",
        props: {
          tableView: this.view,
        },
      },
      {
        title: "使用你的餐廳氣泡",
        subtitle: "按一下餐枱或椅子作添加",
        component: "table-editor",
        props: {
          tableView: this.view,
          onboarding: true,
        },
      },
      {
        title: "完成設定",
        subtitle: "你可以在儀表版看到餐廳這設定",
      },
    ];
  }
}
