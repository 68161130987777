import { Component, Prop, Vue, Watch, getOptions, checkID, getID, FindType, FindPopRawType } from "@common";
import _ from "lodash";
import type { SessionBase } from "./sessionBase";
import type DB from "@db";
import type { CouponItem } from "./coupon";
import { multiplyIngredients, sumIngredients } from "./ingredients";
import { priceFormat, StockLevel } from "./util";
import locales from "./locale";
import uuid from "uuid/v4";
import {
  ProductOptionSelectWithPrice,
  ProductOptionSelectWithPriceItem,
  ProductOptionSelectWithPriceSelection,
} from "./productOptionSelectWithPrice";
export {
  ProductOptionSelectWithPrice,
  ProductOptionSelectWithPriceItem,
  ProductOptionSelectWithPriceSelection,
} from "./productOptionSelectWithPrice";

export interface ProductOptionSelect {
  options: FindType<"productOptions">;
  values: ProductLineOption["values"];
  selections: ProductLineOption["selections"];
  fixed: boolean;
  free: boolean;
}

export interface TableRef {
  table: string;
  split: number;
  chairId?: string;
}

export type LangArrType = { lang: string; value: string }[];
export type LangObjType = {
  $t?: string;
  $join?: LangType[];
  $a?: any;
  $ta: { [key: string]: LangType };
};
export type LangType = LangArrType | LangObjType;

export type ProductLine = Partial<FindType<"tableSessions">["products"][number]>;
export type ProductLineOption = ProductLine["options"][number];
type ProductType = typeof DB.Product["_mongoType"];

function parseSelection(it: ProductOptionSelect) {
  const selections = it.selections
    ? it.selections
    : (it.values || []).map(it => ({
        _id: it,
        id: getID(it),
        quantity: 1,
      }));

  return {
    selections,
    values: selections.map(it => it._id),
  };
}

function isValidObjectID(id) {
  return /^[0-9a-fA-F]{24}$/.test(id);
}

function isValidObjectIDFilter(id) {
  return isValidObjectID(id) ? id : null;
}

@Component
export class CartItem extends Vue {
  @Prop()
  product: FindType<"products">;

  @Prop()
  fromLine: ProductLine;

  customProduct: typeof DB.CustomProduct._mongoType = null;
  id: string = null;
  fromProduct: string = null;
  fromCoupon: string = null;
  fromProductCart: CartItem = null;
  coupon: CouponItem;
  section: string = null;
  // for using in product detail page
  tempCart: CartItem[] = null;
  staff: string = null;
  mergeStatus: "merged" | "mergedFrom" | "skip" | null = null;
  date: Date = null;

  /* init props */

  @Prop()
  initData: {
    id: string;
    fromProduct: string;
    fromCoupon: string;
    section: string;
    tempCart: CartItem[];
    staff: string;
    options: ProductOptionSelect[];
    tableRef: TableRef;
    quantity: number;
    customRemark: string;
    customProduct: typeof DB.CustomProduct._mongoType;
    mergeStatus: "merged" | "mergedFrom" | "skip" | null;
    date?: Date;
  };

  editPriceStaff = null;

  options: ProductOptionSelect[] = [];

  takeAway = false;

  customRemark: string = "";
  tableRef: TableRef = null;

  fromOption: string;
  fromOptionSeq: number;
  fromChoice: string;
  fromChoiceSeq: number;
  fromSelection: string;

  status = "init";

  mergeSource: string = null;

  quantityRatio = 1;
  mquantity: number = 0;
  openKey = 0;

  originalPrice = 0;

  selected = false;

  kitchenOptions: { _id: string; name: LangArrType }[] = [];

  get cart() {
    return this.tempCart || this.parent?.cart;
  }

  get quantity() {
    return this.mquantity;
  }
  get table() {
    return this.tableRef?.table;
  }
  get tableSplit() {
    return this.tableRef?.split;
  }

  //make it reactive
  mcustomProduct: typeof DB.CustomProduct._mongoType = null;
  created() {
    this.id = this.initData?.id || uuid();
    this.fromProduct = this.initData?.fromProduct || null;
    this.fromCoupon = this.initData?.fromCoupon || null;
    this.section = this.initData?.section || null;
    this.tempCart = this.initData?.tempCart || null;
    this.staff = this.initData?.staff || null;
    this.customRemark = this.initData?.customRemark || "";
    this.tableRef = this.initData?.tableRef ? { ...this.initData.tableRef } : null;
    this.customProduct = this.initData?.customProduct || null;
    this.mquantity = this.initData?.quantity || 1;
    this.mergeStatus = this.initData?.mergeStatus || null;
    this.date = this.initData?.date || null;

    let opts = this.initData?.options || [];
    if (this.fromProduct) {
      opts = opts.filter(it => it.options.type === "option");
    }
    this.options = _.sortBy(opts, option => (option.options.type === "discount" ? 1 : 0))
      .filter(it => !it.options?.draft)
      .map(it => ({
        options: it.options,
        fixed: it.fixed,
        free: it.free,
        ...parseSelection(it),
      }));
    this.originalPrice = this.actualPrice;
    if (this.fromLine) {
      this.kitchenOptions = (this.fromLine.kitchenOptions || []) as any[];
      if (this.fromLine.status) this.status = this.fromLine.status;
      if (this.fromLine.takeAway) this.takeAway = true;
      this.originalPrice = this.actualPrice;
    }
  }

  get name() {
    return this.customProduct ? [{ lang: "en", value: this.customProduct.name }] : this.product.name;
  }

  get shortName() {
    return this.customProduct
      ? [{ lang: "en", value: this.customProduct.name }]
      : this.product.shortName ?? this.product.name;
  }

  get discountable() {
    return this.customProduct?.discountable ?? this.product?.discountable ?? true;
  }

  get code() {
    return this.product?.code ?? "";
  }

  get kitchenPrinters() {
    if (this.customProduct) {
      return this.customProduct.kitchenPrinters;
    }
    if (this.product && !this.product.notGoKitchen) {
      return this.product.kitchenPrinters?.length
        ? this.product.kitchenPrinters
        : this.parent?.shopData?.defaultKitchen
        ? [this.parent.shopData.defaultKitchen]
        : [];
    }
    return [];
  }

  get waterBars() {
    if (this.customProduct) {
      return this.customProduct.waterBars;
    }
    if (this.product && !this.product.notGoWaterBar) {
      return this.product.waterBars?.length
        ? this.product.waterBars
        : this.parent?.shopData?.defaultWaterbar
        ? [this.parent.shopData.defaultWaterbar]
        : [];
    }
    return [];
  }

  set quantity(v) {
    if (v !== this.mquantity) {
      this.mquantity = v;
      if (this.parent) {
        if (!v) {
          this.remove();
          this.notifyCartChanged();
        } else {
          if (!this.cart) return;
          const related = this.cart.filter(it => it.fromProduct === this.id);
          for (let item of related) {
            item.quantity = v * item.quantityRatio;
          }
          this.notifyCartChanged();
        }
      }
    }
  }

  @Watch("takeAway")
  notifyCartChanged() {
    if (this.tempCart) return;
    this.parent?.saveCart?.();
    if (!this.parent?.couponsLoaded) {
      this.parent?.restoreCoupons?.()?.then?.(() => {
        this.parent?.updateCoupons?.();
      });
    } else {
      this.parent?.updateCoupons?.();
    }
  }

  get parent() {
    return this.$parent as SessionBase;
  }

  isSame(product: FindType<"products">, options: ProductOptionSelect[], ref: TableRef) {
    return (
      !this.customProduct &&
      !this.fromProduct &&
      this.product._id === product._id &&
      options.length === this.options.length &&
      _.every(this.options, a => {
        const o = options.find(b => b.options._id === a.options._id);
        return o && o.values.length === a.values.length && _.every(o.values, bo => a.values.indexOf(bo) !== -1);
      }) &&
      _.isEqual(ref, this.tableRef)
    );
  }

  remove(root = true) {
    const c = this.cart;
    const related = c.filter(it => it.fromProduct === this.id);
    const idx = c.indexOf(this);
    if (idx !== -1) {
      c.splice(idx, 1);
      this.$destroy();
    }
    for (let item of related) {
      item.remove(false);
    }
    if (root) {
      this.notifyCartChanged();
    }
  }

  get related() {
    const related = this.cart.filter(it => it.fromProduct === this.id);
    return related;
  }

  get relatedTree(): CartItem[] {
    return [this, ...this.related.flatMap(r => r.relatedTree)];
  }

  get relatedTreePrice() {
    return _.sumBy(this.relatedTree, t => t.price);
  }

  get relatedTreePriceWithoutDiscount() {
    return _.sumBy(this.relatedTree, t => t.priceWithoutDiscount);
  }

  //#region remarks generations

  get detailedRemarks() {
    return this.summary.detailedRemarks;
  }

  priceFormat(iprice: number) {
    return priceFormat(iprice);
  }

  get summary() {
    var detailedRemarks: Partial<FindType<"tableSessions">["products"][number]["detailedRemarks"][number]>[] = [];

    // for summary
    let discountList: {
      name: LangArrType;
      value: number;
      percent?: number;
      option?: string;
      optionChoice?: string;
      coupon?: string;
      couponRule?: string;
      type: "option" | "shop" | "coupon" | "product";
    }[] = [];
    const savings = Math.round(100 * (this.actualPrice + this.extraFeeEnjoyDiscount) * (1 - this.finalDiscount)) / 100;
    if (this.discount !== 1 && !this.fromProduct && !this.fromCoupon) {
      const value = (Math.log(+this.discount || 1) / Math.log(this.finalDiscount)) * savings;
      discountList.push({
        name: locales.discount,
        value: -value,
        percent: +this.discount || 1,
        type: "shop",
      });
      detailedRemarks.push({
        name: locales.discount,
        type: "discount",
        value: -value,
        hideInKitchen: true,
        hideInReceipt: false,
      });
    }
    if (this.manualDiscount) {
      detailedRemarks.push({
        name: this.predestinedDiscount ? this.predestinedDiscountName : locales.manualDiscount,
        type: "openKey",
        value: this.manualDiscount,
        hideInKitchen: true,
        hideInReceipt: false,
      });
      discountList.push({
        name: this.predestinedDiscount ? this.predestinedDiscountName : locales.manualDiscount,
        value: this.manualDiscount * this.quantity,
        percent: this.manualPercentAdjust,
        type: "product",
      });
    }

    for (let item of this.availableProductOptionsWithPrice) {
      if (!item.selections.length) continue;

      switch (item.options.type) {
        case "discount": {
          let results: LangArrType[] = [];
          for (let select of item.items) {
            if (!select.active) continue;
            const option = select.item;
            results.push(this.$tconcat(option.name, "*", option.price));
            const value =
              this.finalDiscount === 0 && (+option.price || 0) === 0
                ? savings
                : (Math.log(+option.price || 0) / Math.log(this.finalDiscount)) * savings;
            discountList.push({
              name: option.name,
              value: -value,
              percent: +option.price || 0, // somehow is percentage
              option: getID(item.options._id),
              optionChoice: getID(option._id),
              type: "option",
            });
            detailedRemarks.push({
              name: option.name,
              optionName: item.options.name,
              value: -value,
              hideInKitchen: true,
              hideInReceipt: false,
              type: "discount",
            });
          }
          break;
        }
        case "option": {
          for (let select of item.items) {
            if (!select.active) continue;
            const option = select.item;
            const quantity = _.sumBy(select.selections, it => it.quantity);
            const p = (select.originalPrice || 0) * quantity; // should show original price instead of discounted price, * (item.options.enjoyDiscount ? this.finalDiscount : 1)
            detailedRemarks.push({
              name: option.name,
              optionName: item.options.name,
              value: p,
              quantity,
              hideInKitchen: item.options.hideInKitchen || option.hideInKitchen,
              hideInReceipt: item.options.hideInReceipt || option.hideInReceipt,
              type: option.type,
              printerColor: item.options.printerColor || option.printerColor,
            });
          }

          break;
        }
      }
    }

    if (this.customRemark) {
      detailedRemarks.push({
        type: "customRemark",
        name: this.$tconcat([{ lang: "en", value: this.customRemark }]),
        optionName: locales.customRemark,
        hideInKitchen: false,
        hideInReceipt: false,
      });
    }

    return {
      discountList,
      detailedRemarks,
    };
  }

  //#endregion

  //#region conversion

  clone(): Partial<CartItem> {
    return {
      product: this.product,
      options: this.options,
      tableRef: this.tableRef,
      customRemark: this.customRemark,
    };
  }

  toJSON() {
    return {
      id: this.id,
      product: this.product?._id ?? null,
      customProduct: this.customProduct,
      options: this.options.map(it => ({
        option: it.options._id,
        values: Array.from(new Set(it.selections.map(item => item._id))),
        selections: _.cloneDeep(it.selections),
        free: it.free,
        fixed: it.fixed,
      })),
      quantity: this.quantity,
      quantityRatio: this.quantityRatio,
      table: this.tableRef?.table,
      tableSplit: this.tableRef?.split,
      tableChairId: this.tableRef?.chairId,
      section: this.section,
      customRemark: this.customRemark,
      fromCoupon: this.fromCoupon,
      fromProduct: this.fromProduct,
      fromOption: this.fromOption,
      fromChoice: this.fromChoice,
      fromSelection: this.fromSelection,
      status: this.status,
      staff: this.staff,
      openKey: this.openKey,
      predestinedDiscount: this.predestinedDiscount,
      predestinedDiscountName: this.predestinedDiscountName,
      overridePrice: this.overridePrice,
      manualPercentAdjust: this.manualPercentAdjust,
      manualDiscount: this.manualDiscount,
      discountable: this.discountable,
      takeAway: this.takeAway,
      mergeSource: this.mergeSource,
      mergeStatus: this.mergeStatus,
      ...(this.date
        ? {
            date: this.date,
          }
        : {}),
    };
  }

  get categoryInfo() {
    const id = getID(this.customProduct ? this.customProduct.category : this.product.category);
    if (!id) return null;
    return this.parent?.categoriesDict?.[id];
  }

  get subCategoryInfo() {
    const id = getID(this.customProduct ? this.customProduct.subCategory : this.product.subCategory);
    if (!id) return null;
    return this.parent?.subCategoriesDict?.[id];
  }

  toLine(): ProductLine {
    const summary = this.summary;
    const line = this.parent.lineTaxAndSurcharge[this.id];

    return {
      id: this.id,

      name: this.name,
      shortName: this.shortName,
      category: getID(this.customProduct ? this.customProduct.category : this.product.category),
      subCategory: getID(this.customProduct ? this.customProduct.subCategory : this.product.subCategory),
      kitchenPrinters: this.kitchenPrinters,
      waterBars: this.waterBars,
      printerColor: this.product?.printerColor || this.subCategoryInfo?.printerColor || this.categoryInfo?.printerColor,
      kitchenGroup: this.product?.kitchenGroup,

      couponAndAmount: this.product?.couponAndAmount,
      membership: this.product?.membership,
      delivered: this.fromLine?.delivered,

      product: isValidObjectIDFilter(this.product?._id),
      customProduct: isValidObjectIDFilter(this.customProduct?._id),

      seq: this.fromLine?.seq,
      prodSeq: this.fromLine?.prodSeq,
      status: this.status,
      kitchenOptions: this.kitchenOptions,

      price: this.price,
      openKey: this.openKey,
      overridePrice: this.overridePrice,
      predestinedDiscount: this.predestinedDiscount,
      predestinedDiscountName: this.predestinedDiscountName,
      manualPercentAdjust: this.manualPercentAdjust,
      manualDiscount: this.manualDiscount,

      actualPrice: this.actualPrice,
      salesPrice: this.actualPrice,
      discount: this.discount * this.optionDiscount,
      extraFee: this.extraFee,
      extraFeeEnjoyDiscount: this.extraFeeEnjoyDiscount,
      priceWithoutProduct: this.priceWithoutProduct,
      originalPriceWithoutProduct: this.originalPriceWithoutProduct,
      priceWithoutDiscount: this.priceWithoutDiscount,
      extraFeeWithoutProduct: this.extraFeeWithoutProduct,
      extraFeeEnjoyDiscountWithoutProduct: this.extraFeeEnjoyDiscountWithoutProduct,
      fromProductPrice: this.fromProductPrice,
      fromProductOriginalPrice: this.fromProductOriginalPrice,

      relatedTreePrice: this.relatedTreePrice,
      relatedTreePriceWithoutDiscount: this.relatedTreePriceWithoutDiscount,

      detailedRemarks: summary.detailedRemarks,
      quantity: this.quantity,
      quantityRatio: this.quantityRatio,

      discountList: summary.discountList as any,
      options: this.availableProductOptionsWithPrice.map(it => ({
        option: it.options._id,
        values: Array.from(new Set(it.selections.map(item => item._id))),
        selections: it.selections,
        free: it.free,
        fixed: it.fixed,
      })),

      table: this.table,
      tableSplit: this.tableSplit,
      // chairId: // TODO

      section: isValidObjectIDFilter(this.section),
      freeSurcharge: this.freeSurcharge,
      surchargePercent: line?.surchargePercent ?? 0,
      taxPercent: line?.taxPercent ?? 0,
      totalSurcharge: line?.totalSurcharge ?? 0,
      totalTax: line?.totalTax ?? 0,
      taxGroup: line?.taxGroup ?? null,
      fromProduct: this.fromProduct,
      fromOption: this.fromOption,
      fromOptionSeq: this.fromOptionSeq,
      fromChoice: this.fromChoice,
      fromChoiceSeq: this.fromChoiceSeq,
      fromSelection: this.fromSelection,

      fromCoupon: this.fromCoupon,
      staff: this.staff,
      customRemark: this.customRemark,

      discountable: this.discountable,
      takeAway: this.takeAway,
      mergeSource: this.mergeSource,
      mergeStatus: this.mergeStatus,

      ...(this.date
        ? {
            date: this.date,
          }
        : {}),
    } as any;
  }

  get freeSurcharge() {
    return (
      this.product?.freeSurcharge ||
      !!this.availableProductOptionsWithPrice.find(it => it.activeOptions.find(jt => jt.type === "freeSurcharge"))
    );
  }

  //#endregion

  //#region price calculation

  get productOptionsWithPrice(): ProductOptionSelectWithPrice[] {
    return this.options.map((opt, idx) => new ProductOptionSelectWithPrice(this, opt, idx));
  }

  get availableProductOptionsWithPrice() {
    const checkAvailability = this.parent.checkAvailability;
    if (!checkAvailability) {
      return this.productOptionsWithPrice;
    }
    return this.productOptionsWithPrice.filter(it => it.available);
  }

  updateDepTask: Promise<void> = null;
  depDirty = false;

  updateDeps(deferUpdate?: boolean) {
    if (this.updateDepTask) {
      this.depDirty = true;
    } else {
      this.updateDepTask = this.updateDepsAsync(deferUpdate);
      this.updateDepTask.catch(console.warn).finally(() => {
        this.updateDepTask = null;
      });
    }
    return this.updateDepTask;
  }

  async updateDepsAsync(deferUpdate?: boolean) {
    if (!this.cart) return;
    this.depDirty = false;
    try {
      const oldDeps = this.cart.filter(it => it.fromProduct === this.id);
      const newDeps = _.flatMap(this.availableProductOptionsWithPrice, (opt, optionIndex) =>
        opt.selectionItems.filter(item => item.item.item.type === "product" && item.item.item.product),
      );

      if (!oldDeps.length && !newDeps.length) return;

      const newDepDict = _.groupBy(newDeps, opt => getID(opt.item.item.product));

      let newSorted: [select: ProductOptionSelectWithPriceSelection, item: CartItem][] = [];
      const toRemove: CartItem[] = [];

      // check old products to keep or remove
      for (let dep of oldDeps) {
        const list = newDepDict[getID(dep.product)];
        const entryIdx = dep.fromSelection ? list?.findIndex?.(it => it.selection.id === dep.fromSelection) ?? -1 : 0;
        if (entryIdx !== -1 && list?.[entryIdx]) {
          const entry = list[entryIdx];
          list.splice(entryIdx, 1);
          newSorted.push([entry, dep]);
        } else {
          toRemove.push(dep);
        }
      }

      // add new products

      const toAdd = Object.values(newDepDict).filter(it => it.length);
      const productInfos = await Promise.all(
        toAdd.map(remain =>
          this.parent.getProductWithOptions(getID(remain[0].item.item.product), {
            fromProduct: this.id as string,
          }),
        ),
      );

      // do all operations in atomic manner
      if ((this as any)._isDestroyed || !this.parent) return;

      for (let dep of toRemove) {
        dep.remove();
      }

      for (let i = 0; i < toAdd.length; i++) {
        const remain = toAdd[i];
        const cartInfo = productInfos[i];
        for (let item of remain) {
          const cart = this.parent.addToCart(
            {
              ...cartInfo,
              fromProduct: this.id as string,
              quantity: this.quantity * item.selection.quantity,
              quantityRatio: item.selection.quantity,
            },
            {
              mustInsert: true,
              tempCart: this.tempCart,
              deferUpdate,
            },
          );
          newSorted.push([item, cart]);
        }
      }

      newSorted = _.orderBy(newSorted, [it => newDeps.indexOf(it[0])]);

      // fix items order
      let curIdx = this.cart.indexOf(this) + 1;
      for (let i = 0; i < newSorted.length; i++) {
        const item = newSorted[i];
        const target = item[1];

        target.fromOption = getID(item[0].select.options);
        target.fromOptionSeq = item[0].select.idx;
        target.fromChoice = getID(item[0].selection._id);
        target.fromChoiceSeq = item[0].select.values.findIndex(it => it === item[0].selection._id);
        target.fromSelection = item[0].selection.id;
        if (target.fromProductCart !== this) target.fromProductCart = this;
        const movedCnt = target.moveTo(curIdx);
        const newIdx = this.cart.indexOf(target);
        curIdx = newIdx + movedCnt;
        if (target.quantityRatio !== item[0].selection.quantity || target.quantity !== this.quantity * item[0].selection.quantity) {
          target.quantityRatio = item[0].selection.quantity;
          target.quantity = this.quantity * item[0].selection.quantity;
        }
      }
    } finally {
      if (this.depDirty) {
        await this.updateDepsAsync();
      } else {
        if (!deferUpdate) {
          if (!this.parent?.couponsLoaded) {
            await this.parent?.restoreCoupons?.();
          }
          this.parent?.updateCoupons?.();
        }
      }
    }
  }

  toggleTakeAway() {
    this.takeAway = !this.takeAway;
    for (let item of this.relatedTree) {
      if (item !== this) {
        item.takeAway = this.takeAway;
      }
    }
  }

  makeStandalone() {
    this.fromChoice = null;
    this.fromProduct = null;
    this.fromOption = null;
    this.fromSelection = null;
    this.fromProductCart = null;
    this.fromOptionSeq = 0;
    this.fromChoiceSeq = 0;
  }

  moveTo(to: number) {
    const list = this.cart;
    if (!list) return;
    const idx = list.indexOf(this);
    if (idx === -1) return;
    // assume all related items are sorted
    const seenRelated = new Set<string>();
    seenRelated.add(this.id as string);
    let cnt = 1;
    for (let i = idx + 1; i < list.length; i++) {
      const item = list[i];
      if (item.fromProduct && seenRelated.has(item.fromProduct)) {
        seenRelated.add(item.id as string);
        cnt++;
      }
    }
    if (idx === to) return cnt;
    const toInsert = list.splice(idx, cnt);
    list.splice(to, 0, ...toInsert);
    return cnt;
  }

  get pendingOptions() {
    return this.availableProductOptionsWithPrice.some(it => it.invalid);
  }

  get isValid() {
    return (
      !this.pendingOptions &&
      !this.updateDepTask &&
      (!this.product?.setOrderOrGiftOnly || !!this.fromProduct || !!this.fromCoupon)
    );
  }

  activeModifiers<T extends ProductType["priceModifiers"][number] | ProductType["ingredientModifiers"][number]>(
    modifiers: T[],
  ) {
    return (
      modifiers?.filter?.(mod => {
        if (mod.shop && !checkID(mod.shop, this.parent.shopId)) {
          return false;
        }
        switch (mod.type) {
          case "productOption": {
            const o = this.options.find(it => checkID(it.options, mod.option));
            return o && !!o.values.find(opt => checkID(mod.optionValue, opt));
          }
          case "modifier": {
            return !!this.parent?.sessionData?.modifiers?.find?.(it => checkID(it, mod.modifier));
          }
          case "section": {
            return checkID(mod.section, this.section);
          }
          case "timeCondition": {
            const activeTimeConditions = this.parent?.availableTimeConditionDict;
            return !!activeTimeConditions[getID(mod.timeCondition)];
          }
          case "orderType": {
            return mod.orderTypes?.includes(this.parent?.sessionData?.type);
          }
          case "shop": {
            return true;
          }
        }
      }) || []
    );
  }

  get productShop() {
    if (!this?.product?.discountMapping?.length) {
      return null;
    }
    return this.product.discountMapping.find(it => checkID(it.shop, this.parent.shopId));
  }

  get actualPrice() {
    if (this.customProduct) {
      return +this.customProduct.price;
    }
    const activeModifiers = this.activeModifiers(this.product.priceModifiers);
    const sumPercent = _.sumBy(activeModifiers, m => m.percent ?? 0) || 0;
    return (
      ((this.productShop?.price ?? this.product.price ?? 0) + (_.sumBy(activeModifiers, m => m.price ?? 0) || 0)) *
      ((100 + sumPercent) / 100)
    );
  }

  get discount() {
    return this.productShop?.discount ?? 1;
  }

  get optionDiscount() {
    let discount = 1;
    for (let item of this.options) {
      if (item.free) continue;
      if (item.options.type === "discount") {
        for (let value of item.values) {
          const option = item.options.options.find(it => checkID(it, value));
          if (option) {
            discount = discount * (option.price ?? 0);
          }
        }
      }
    }
    return discount;
  }

  get finalDiscount() {
    return this.discount * this.optionDiscount * (1 - this.manualPercentAdjust / 100);
  }

  get extraFee() {
    let price = 0;
    for (let item of this.availableProductOptionsWithPrice) {
      if (item.free) continue;
      if (item.options.type !== "discount" && !item.options.enjoyDiscount) {
        for (let value of item.selectionItems) {
          price += value.quantity * this.calculateOptionPrice(value.item.item);
        }
      }
    }
    return price;
  }

  get extraFeeEnjoyDiscount() {
    let price = 0;
    for (let item of this.availableProductOptionsWithPrice) {
      if (item.free) continue;
      if (item.options.type !== "discount" && item.options.enjoyDiscount) {
        for (let value of item.selectionItems) {
          price += value.quantity * this.calculateOptionPrice(value.item.item);
        }
      }
    }
    return price;
  }

  get extraFeeWithoutProduct() {
    let price = 0;
    for (let item of this.options) {
      if (item.free) continue;
      if (item.options.type !== "discount" && !item.options.enjoyDiscount) {
        for (let value of item.selections) {
          const option = item.options.options.find(it => checkID(it, value));
          if (option) {
            if (option.type === "product") continue;
            price += value.quantity * this.calculateOptionPrice(option);
          }
        }
      }
    }
    return price;
  }

  get extraFeeEnjoyDiscountWithoutProduct() {
    let price = 0;
    for (let item of this.options) {
      if (item.free) continue;
      if (item.options.type !== "discount" && item.options.enjoyDiscount) {
        for (let value of item.selections) {
          const option = item.options.options.find(it => checkID(it, value));
          if (option) {
            if (option.type === "product") continue;
            price += value.quantity * this.calculateOptionPrice(option);
          }
        }
      }
    }
    return price;
  }

  calculateOptionPrice(item: FindType<"productOptions">["options"][number]) {
    const activeModifiers = this.activeModifiers(item.priceModifiers as any[]);
    const sumPercent = _.sumBy(activeModifiers, m => m.percent ?? 0) || 0;

    const optionPrice =
      ((item.price ?? 0) + (_.sumBy(activeModifiers, m => m.price ?? 0) || 0)) * ((100 + sumPercent) / 100);
    return optionPrice;
  }

  get price() {
    let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
    var price = Math.max(
      0,
      Math.round(
        100 * (actualPrice + this.extraFeeEnjoyDiscount) * this.finalDiscount +
          100 * this.extraFee +
          100 * this.openKey,
      ) / 100,
    );
    return price;
  }

  get priceWithoutDiscount() {
    let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
    return Math.max(0, Math.round(100 * (actualPrice + this.extraFeeEnjoyDiscount) + 100 * this.extraFee) / 100);
  }

  get priceWithoutProduct() {
    let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
    var price =
      Math.max(
        0,
        Math.round(100 * (actualPrice + this.extraFeeEnjoyDiscountWithoutProduct) + 100 * this.extraFeeWithoutProduct) /
          100,
      ) + this.fromProductPrice;
    return price;
  }

  get originalPriceWithoutProduct() {
    let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
    var price =
      Math.max(
        0,
        Math.round(100 * (actualPrice + this.extraFeeEnjoyDiscountWithoutProduct) + 100 * this.extraFeeWithoutProduct) /
          100,
      ) + this.fromProductOriginalPrice;
    return price;
  }

  get fromProductPrice() {
    if (this.fromProductCart) {
      const option = this.fromProductCart.productOptionsWithPrice.find(it => checkID(it.options, this.fromOption));
      if (option) {
        const selection = option.selectionItems.find(it => it.selection.id === this.fromSelection);
        if (selection) {
          return selection.item.price;
        }
      }
    }
    return 0;
  }

  get fromProductOriginalPrice() {
    if (this.fromProductCart) {
      const option = this.fromProductCart.productOptionsWithPrice.find(it => checkID(it.options, this.fromOption));
      if (option) {
        const selection = option.selectionItems.find(it => it.selection.id === this.fromSelection);
        if (selection) {
          return selection.item.originalPrice;
        }
      }
    }
    return 0;
  }

  get manualPrice() {
    return this.price;
  }

  set manualPrice(v) {
    if (isNaN(+v) || v === null) {
      this.openKey = 0;
    } else {
      v = +v;
      this.openKey = Math.max(-this.priceWithoutOpenKey, v - this.priceWithoutOpenKey);
    }
    this.notifyCartChanged();
  }

  overridePrice = null;
  manualPercentAdjust = 0;
  predestinedDiscount: string = null;
  predestinedDiscountName: LangArrType = null;

  get unitPrice() {
    return this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
  }

  get manualDiscount() {
    if (this.openKey) {
      return this.openKey;
    }
    if (this.manualPercentAdjust) {
      let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
      return -(100 * (actualPrice + this.extraFeeEnjoyDiscount) * (this.manualPercentAdjust / 100)) / 100;
    }
    return 0;
  }

  get priceWithoutOpenKey() {
    let actualPrice = this.overridePrice != null ? this.overridePrice : this.fromProduct ? 0 : this.actualPrice;
    var price = Math.max(
      0,
      Math.round(100 * (actualPrice + this.extraFeeEnjoyDiscount) * this.finalDiscount + 100 * this.extraFee) / 100,
    );
    return price;
  }

  get subTotal() {
    return this.price * this.quantity;
  }

  get setSubTotal() {
    let total = this.subTotal;
    const related = this.cart.filter(it => it.fromProduct === this.id);
    for (let item of related) {
      total += item.setSubTotal;
    }
    return total;
  }

  //#endregion

  //#region for product info

  get setProducts() {
    return _.flatMap(this.availableProductOptionsWithPrice, (opt, optionIndex) =>
      opt.items.filter(item => item.active && item.item.type === "product" && item.item.product),
    );
  }

  get productIngredients() {
    if (this.customProduct) return [];
    const baseIngredients = this.product.ingredients;
    const activeIngredients = this.activeModifiers(this.product.ingredientModifiers).map(it => it.ingredients);
    const activeOptionIngredients = _.flatMap(this.availableProductOptionsWithPrice, (opt, optionIndex) =>
      opt.items.filter(item => item.active && item.item.ingredients?.length),
    ).map(it => it.item.ingredients);

    return multiplyIngredients(
      sumIngredients([], baseIngredients, ...activeIngredients, ...activeOptionIngredients),
      this.quantity,
    );
  }

  get stockLevel() {
    if (this.customProduct) return StockLevel.Disabled;
    let level = StockLevel.None;
    const stock = this.product.stock;
    if (!stock) return StockLevel.None;

    if (this.parent.selfService && stock?.onlineMode) {
      switch (stock.onlineMode) {
        case "paused":
        case "notSelling":
        case "askStaff":
          return StockLevel.NotSelling;
      }
    }

    switch (stock.mode) {
      case "disable":
        level = StockLevel.Disabled;
        break;
      case "notSelling":
      case "paused":
        level = StockLevel.NotSelling;
        break;
      case "manual":
      case "auto":
        const qty = stock.quantity - (this.parent.selfService ? stock.onlineThreshold || 0 : 0);
        if (stock.quantity === null) level = StockLevel.High;
        else if (qty - this.quantity < 0) level = StockLevel.OutOfStock;
        else if (stock.stockMode === "sync") {
          if (qty < 10) level = StockLevel.Low;
          else level = StockLevel.Medium;
        } else {
          level = StockLevel.High;
        }
        break;
    }

    for (let option of this.options) {
      const info = stock.productOptions?.find?.(it => `${it.option}` === `${option.options._id}`);

      if (!info) continue;
      for (let val of option.values) {
        const cur = info.values?.find?.(it => `${it.value}` === `${val}`);
        if (!cur) continue;

        let curLevel = StockLevel.High;

        switch (stock.mode) {
          case "manual":
          case "auto":
            if (cur.quantity === null) curLevel = StockLevel.High;
            else if (cur.quantity - this.quantity < 0) curLevel = StockLevel.OutOfStock;
            else if (cur.stockMode === "sync") {
              if (cur.quantity < 10) level = StockLevel.Low;
              else level = StockLevel.Medium;
            } else curLevel = StockLevel.High;
            break;
        }

        level = Math.min(curLevel, level);
      }
    }

    return level;
  }

  // #endregion

  toString() {
    return `${this.fromLine?.seq ?? ""} ${this.$td(this.name, "en")}`;
  }
}
