
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeFill, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorFill extends Vue {
  @Prop() template!: PrinterTemplateNodeFill;

  @Prop() context: any;

  get n() {
    return this.template.props["n"]?.eval?.(this.context);
  }

  get char() {
    return this.template.props["char"]?.eval?.(this.context) ?? "-";
  }
}
