import { EventEmitter } from "events";
import type { BitmapOpts, WrappedContext, FontInfo, FontItem } from "../common";
import type iconv from "iconv-lite";

export interface PrinterOpts {
  lineWidth?: number;
  clineWidth?: number;
  initPrinter?: boolean;
  cutPreFeed?: number;
  cutPostFeed?: number;
  codePage?: string;
  paperWidth?: number;
  rlineWidth?: number;
  singleCodePage?: number;
  multiCodePage?: number;
  manualCut?: boolean;
  statusProbe?: boolean;
  multiSessionProbe?: boolean;
  [key: string]: any;
}

export interface PrinterConf {
  opts?: PrinterOpts;
  type?: string;
  port?: string;
  name?: string;
  address?: string;
  serial?: string;
  product?: string;
  manufacturer?: string;
  model?: string;
  id?: string;
  shared?: boolean;
  tags?: string[];
}

export abstract class PrinterBaseConf<TConf extends PrinterConf = PrinterConf> extends EventEmitter {
  constructor(public conf: TConf) {
    super();
  }

  ensurePrinter() {
    return true;
  }

  get bitmapList(): { [key: string]: BitmapOpts } {
    return {};
  }

  get fontsInfo() : FontInfo {
    return null;
  }

  fixLoadPath?: (path: string) => string;
  loadFont?: (font: FontItem) => Promise<ArrayBuffer>;

  resolveBitmap(key: string, url?: string, context?: WrappedContext) {
    let opts = this.bitmapList[key];
    if (opts) {
      const u = typeof opts.defaultImage === "function" ? opts.defaultImage(context) : opts.defaultImage;
      return u || url;
    } else {
      return url;
    }
  }

  setConf(key: string, v: any) {
    this.conf[key] = v;
  }

  createSequence<T>(): T {
    return null;
  }

  abstract readonly iconv: typeof iconv;
}
