import type { PrintSequence } from ".";
import qr from "qrcode";
import { code39 } from "../utils/barcodes";

export class PrintGraph {
  constructor(public parent: PrintSequence, width: number, height: number) {
    this.resize(width, height);
  }

  buffer: Buffer;
  width: number;
  height: number;

  resize(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.buffer = Buffer.alloc(width * height * 4);
    this.buffer.fill(0xff, 0);
  }

  putImage(buf: Buffer, x: number, y: number, width: number, height: number) {
    const dstLineSize = this.width * 4;
    const srcLineSize = width * 4;

    for (let i = 0; i < height; i++) {
      buf.copy(this.buffer, (y + i) * dstLineSize + x * 4, i * srcLineSize, (i + 1) * srcLineSize);
    }
  }

  barCode(text: string, type: "code39", x: number, y: number, width: number, height: number, size = 1) {
    switch (type) {
      case "code39": {
        const data = code39(text);
        if (!size) {
          size = Math.floor(width / data.length);
        }
        if (size < 0) {
          console.warn(`Invalid barcode size for length ${data.length}`);
          return;
        }
        const realWidth = data.length * size;
        const lineWidth = realWidth * 4;
        const lineBuf = Buffer.alloc(lineWidth * height);
        lineBuf.fill(0xff, 0);

        let offset = 0;
        for (let i = 0; i < data.length; i++) {
          const color = data[i] !== "1" ? 255 : 0;
          for (let j = 0; j < size; j++) {
            lineBuf[offset] = color;
            lineBuf[offset + 1] = color;
            lineBuf[offset + 2] = color;
            lineBuf[offset + 3] = 255;
            offset += 4;
          }
        }

        for (let i = 1; i < height; i++) {
          lineBuf.copy(lineBuf, i * lineWidth, 0, lineWidth);
        }

        const left = x + Math.floor((width - realWidth) / 2);
        this.putImage(lineBuf, left, y, realWidth, height);
        break;
      }
      default:
        console.warn(`Unknown barcode type ${type}`);
        break;
    }
  }

  mesaureQrCode(text: string, v?: number, scale = 0, ecc: "L" | "M" | "Q" | "H" = "M") {
    var code = qr.create(text, {
      ...(v ? { version: v } : {}),
      ...(ecc ? { errorCorrectionLevel: ecc } : {}),
    });
    const matrix = code.modules;
    if(!scale) {
      scale = Math.floor(this.width / matrix.size);
    }

    if (scale <= 1) {
      scale = 1;
    }

    return {
      width: matrix.size * scale,
      height: matrix.size * scale,
    };
  }

  qrCode(
    text: string,
    x: number,
    y: number,
    width: number,
    height: number,
    v?: number,
    scale = 0,
    ecc: "L" | "M" | "Q" | "H" = "M",
  ) {
    var code = qr.create(text, {
      ...(v ? { version: v } : {}),
      ...(ecc ? { errorCorrectionLevel: ecc } : {}),
    });
    const matrix = code.modules;
    if(!scale) {
      scale = Math.floor(width / matrix.size);
    }

    if (scale <= 1) {
      throw new Error("QR code too small");
    }

    if(matrix.size * scale > width) {
      throw new Error("QR code too wide");
    }

    const qrBuf = Buffer.alloc(matrix.size * scale * (matrix.size * scale) * 4);
    const lineSize = matrix.size * scale * 4;
    for (let i = 0; i < matrix.size; i++) {
      let offset = i * scale * lineSize;
      const ofs = i * matrix.size;
      for (let j = 0; j < matrix.size; j++) {
        const color = matrix.data[ofs + j] ? 0 : 255;
        for (let s = 0; s < scale; s++) {
          qrBuf[offset] = color;
          qrBuf[offset + 1] = color;
          qrBuf[offset + 2] = color;
          qrBuf[offset + 3] = 255;
          offset += 4;
        }
      }
      for (let s = 1; s < scale; s++) {
        qrBuf.copy(qrBuf, offset, offset - lineSize, offset);
        offset += lineSize;
      }
    }

    const left = x + Math.floor((width - matrix.size * scale) / 2);
    const top = y + Math.floor((height - matrix.size * scale) / 2);

    this.putImage(qrBuf, left, top, matrix.size * scale, matrix.size * scale);
  }

  async print() {
    await this.parent.printImageData(new Uint8ClampedArray(this.buffer), this.width, this.height, true);
  }
}
