import { Prop, Component, Vue, Watch, mixins, Ref, FindType, FindPopRawType, checkID, getID } from "@feathers-client";
import type { ObjectID } from "@db";
import { CartItem } from "@common/table/cart";
import "~/plugins/table";
import { TableSession } from "~/plugins/table/session";
import "pos-printer/scanner";
import _ from "lodash";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import { checkTime } from "~/plugins/table/utils";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { CouponItem, GiftType } from "@common/table/coupon";
import MemberList from "~/components/table/memberSystem/memberList.vue";
import MemberDetail from "~/components/table/memberSystem/memberDetail.vue";
import MemberCard from "~/components/table/memberSystem/memberCard.vue";
import TransactionList from "~/components/table/memberSystem/transactionList.vue";
import MerchantCart from "~/components/table/orderSystem/merchantCart.vue";
import MerchantConfirm from "~/components/table/orderSystem/merchantConfirm.vue";
import CouponTicker from "~/components/table/orderSystem/couponTicker.vue";
import { getProductStockLevel, StockLevel } from "~/common/table/util";
import { ProductType } from "~/plugins/shop";
import CartPanelBase from "../cartPanelBase";

type CouponType = FindPopRawType<["coupon"], "userCoupons">;
@Component({
  components: {
    EditorObjectPickerNew,
    EditorTextField,
    QrcodeStream,
    MemberList,
    MemberDetail,
    MemberCard,
    TransactionList,
    MerchantCart,
    MerchantConfirm,
    CouponTicker,
  },
})
export default class OrderSystemMobileBase extends mixins(CartPanelBase) {
  @Ref("router")
  router: NestedRouter;

  session: TableSession = null;
  orderMenu = false;
  cleanOnUpdated = false;

  currentPage = "tables";

  selectedCat: FindType<"categories"> = null;
  selectedSubCat: FindType<"subCategories"> = null;

  lastCart: CartItem = null;
  editingCart: CartItem = null;
  editingLine: ProductLine = null;
  tempKitchenOptions: { _id: string; name: LangArrType }[] = [];

  selectedTable: FindType<"tableViewItems"> = null;
  orderType: string = "";

  scanning = false;

  searchField: string = "";
  upperCaseSearchField: string = "";

  recordOption = false;
  pickerOption = false;

  queryData = {};

  handheldRecordOptions: keyof FindType<"tableSessions"> = {} as any;

  currentTempPriceVal: number = null;

  filteringByTable = false;

  openTable = false;
  activeSessionsTable = false;

  selectedViewId = null;

  handlingCode = false;

  merchantPortalMode = false;
  lang = false;
  transactionTab = "coupon";
  currentAction = null;
  section = null;

  takeawayMode = false;

  searchRecordNumPad = false;
  code = "";
  sessions: TableSession[] = null;

  isPaymentEnabled: Boolean = null;

  userQuery = {
    $populate: ["vipLevel", "ranks.rank"],
  };

  pointQuery = {
    tag: ["point", "dollar"],
  };

  loading = false;

  @Watch("session.status")
  onSessionStatus(status: string) {
    if (!this.session) {
      return;
    }
    if (status === "cancelled" || status === "done" || status === "void" || status === "test") {
      if (this.merchantPortalMode) {
        this.router?.reset?.("merchantPortalDone");
      } else {
        this.router?.reset?.("done");
      }
    } else if (this.router?.currentPage?.[0] === "done" && (status === "ongoing" || status === "toPay")) {
      this.router?.reset?.("mode");
      if (this.session.isPaying) {
        this.router?.navigate?.("picker");
        this.router?.navigate?.("cart");
        this.router?.navigate?.("checkout");
      } else {
        this.router?.navigate?.("picker");
      }
    }
  }

  @Watch("searchField")
  onWatchSearchField() {
    this.upperCaseSearchField = this.searchField.toUpperCase();
  }

  isAvailableSection(item: FindType<"sections">) {
    return (
      item.timeRange && !!item.timeRange.find(range => checkTime(range.weekdays, range.from, range.to, new Date()))
    );
  }

  autoSelectTimeSection() {
    if (this.session && this.$shop?.shopData && this.$shop?.shopData?.autoSelectionTimeSection) {
      this.section = this.$shop?.sections?.find(it => this.isAvailableSection(it));
      this.session.section = this.section;
    }
  }

  get sessionTime() {
    return this.session?.sessionTime;
  }

  async populateQuery(db: string, _id: ObjectID) {
    const query = await this.$feathers.service(db).get(_id);
    this.queryData = query;

    return this.queryData;
  }

  get showingDialog() {
    return this.recordOption || this.pickerOption || this.openTable || this.activeSessionsTable;
  }

  get optionOnRoute() {
    return this.currentPage === "picker" || this.currentPage === "categories" || this.currentPage === "cart";
  }

  showingOnRoute(route?: string) {
    if (this.session && this.session?.cart) {
      if (this.router && this.router?.currentPage) {
        switch (this.router?.currentPage[0]) {
          case "tables":
          case "sessions":
          case "checkout":
          case "done":
          case "orderType":
          case "record":
            return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  get isDone() {
    return this.session.status === "done";
  }

  get isTest() {
    return this.session.status === "test";
  }

  get isVoid() {
    return this.session.status === "void";
  }

  get isCancel() {
    return this.session.status === "cancelled";
  }

  get isPaying() {
    return (this.session.postEditing && this.session.screenOverride === "checkout") || this.session.status === "toPay";
  }

  get isOngoing() {
    return this.session.postEditing || this.session.status === "ongoing";
  }

  get isCrmRouter() {
    switch (this.currentPage) {
      case "merchantPortal":
      case "merchantConfirm":
      case "merchantPortalDone":
      case "earnPoint":
      case "useDollar":
      case "useDollarConfirm":
      case "useCoupon":
      case "topUp":
      case "member":
      case "memberDetail":
      case "transaction":
        return true;

      default:
        return false;
    }
  }

  get currentSessions() {
    return this.session ?? ([] as any);
  }

  get line() {
    return this.editingLine;
  }

  get cart() {
    return this.session?.selectingCartItem?.cart;
  }

  get item(): (CartItem | ProductLine) & { replacing?: boolean } {
    return this.cart || this.line;
  }

  async splitItem() {
    if (!this.cart) return;
    const curIdx = this.session.cart.indexOf(this.cart);
    const offset = this.cart.moveTo(curIdx); // offset after all related items
    this.cart.quantity--;
    const newItem = await this.session.cloneItem(this.cart);
    if (newItem) {
      newItem.quantity = 1;
    }
    this.session.selectingCartItem = null;
    this.goCart();
  }

  get initialRecordOption() {
    return (this.$shop.localOptions.handheldRecordOptions = [
      "all",
      "all",
      "byCreateTime",
      ["ongoing", "done", "cancelled"],
    ]);
  }

  get selectedHandheldRecord() {
    const [tableView, orderType, sorting, status]: (string | string[])[] =
      this.$shop.localOptions.handheldRecordOptions;

    return {
      sorting: sorting === "byCreateTime" ? "createTime" : "modified",
      type: orderType !== "all" ? orderType : null,
      status: status,
      view: tableView !== "all" ? tableView : null,
    };
  }

  get recordFiltering() {
    return {
      ...(this.upperCaseSearchField?.length
        ? {
            $and: [
              {
                sessionName: {
                  $regex: `[${this.upperCaseSearchField}]`,
                },
              },
              {
                tableRefName: {
                  $regex: `[${this.upperCaseSearchField}]`,
                },
              },
            ],
          }
        : {
            $or: [
              {
                status: {
                  $in: this.selectedHandheldRecord.status,
                },
                ...(this.selectedHandheldRecord.type !== null ? { type: this.selectedHandheldRecord.type } : {}),
                ...(this.selectedHandheldRecord.view !== null ? { view: this.selectedHandheldRecord.view } : {}),
              },
            ],
          }),
      $sort: {
        ...(this.selectedHandheldRecord?.sorting
          ? {
              [this.selectedHandheldRecord?.sorting]: -1,
            }
          : {}),
      },
      $populate: [
        "view",
        "shop",
        {
          path: "tables.item",
          populate: [
            {
              path: "view",
            },
          ],
        },
      ],
    };
  }

  get orderTypes() {
    return [
      this.$shop?.shopData?.openDineInNoTable ? ["dineInNoTable"] : [],
      this.$shop?.shopData?.openTakeAway ? ["takeAway"] : [],
      !this.$shop?.shopData?.hideFloorPlan ? ["dineIn"] : [],
    ].flat();
  }

  get orderTypesSwitch() {
    return [
      this.$shop?.shopData?.openDineInNoTable ? [{ _id: "dineInNoTable", name: { $t: "pos.invoice.dineIn" } }] : [],
      this.$shop?.shopData?.openTakeAway ? [{ _id: "takeAway", name: { $t: "pos.invoice.takeAway" } }] : [],
    ].flat();
  }

  get smallestMobile() {
    return this.$vuetify.breakpoint.height <= 844;
  }

  get showingOrder() {
    return (
      (this.currentPage === "categories" ||
        this.currentPage === "cart" ||
        this.currentPage === "picker" ||
        this.currentPage === "checkout") &&
      this.session
    );
  }

  get selectedSection() {
    return this.session?.selectedSection;
  }

  clickCart(item: CartItem) {
    if (this.currentPage === "cartItem") return;
    this.editingCart = item;
    this.router?.navigate?.("cartItem");
  }

  clickProduct(item: ProductLine) {
    this.editingLine = item;
    this.tempKitchenOptions = (item.kitchenOptions as any[]) || [];
    this.router?.navigate?.("productLine");
  }

  get currentTempPrice() {
    return this.currentTempPriceVal === null ? this.editingCart?.price : this.currentTempPriceVal;
  }

  set currentTempPrice(v) {
    this.currentTempPriceVal = v;
  }

  @Watch("editingCart")
  onCartChanged() {
    this.currentTempPriceVal = null;
  }

  goCart() {
    this.router?.navigate?.("cart");
  }

  goMode() {
    this.router?.navigate?.("mode");
  }

  goOrderType() {
    this.router?.navigate?.("orderType");
  }

  cancelPending() {
    this.router?.reset?.("tables");
    if (this.session) {
      this.session.cancelPending();
      this.session = null;
      this.lastCart = null;
    }
  }

  async cancelBilling() {
    this.router?.navigate?.();
    await this.session.atomic({
      status: "ongoing",
      checkBillTime: null,
    });
  }

  editOrder(refund: boolean = false) {
    this.session.postEditing = true;
    this.$shop.localOptions.simpleOrderingFlow = true;
    this.router?.reset?.("categories");
    this.router?.navigate?.("picker");
    this.router?.navigate?.("cart");
    if (refund) {
      this.router?.navigate?.("checkout");
    }
  }

  onSessionTypeUpdate(type) {
    this.session.delaySave({
      type: type,
    });
    switch (type) {
      case "dineInNoTable":
        this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.dineInSuccess"));
        break;
      case "takeAway":
        this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.takeAwaySuccess"));
        break;
    }
  }

  async printInvoice(printWithoutPayment = false) {
    if (!this.session.cartEmpty && this.currentPage !== "cart") {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("pos.cartIsNotEmptyPlaceNow"),
        },
        {
          maxWidth: "400px",
        },
      );
      if (c) {
        await this.session.confirmOrder(false);
      } else {
        return;
      }
    }
    if (this.session.status === "ongoing" || this.session.status === "toPay") {
      await this.session.restoreCoupons();
      await this.session.atomic(this.session.cachedPriceDetails);
      await this.session.printOrder?.({
        user: true,
        ongoingPayment: printWithoutPayment === true,
      });
    } else {
      await this.session.printOrder();
    }
  }

  showTables() {
    this.router?.navigate?.("tables", true);
  }

  showSessions() {
    this.filteringByTable = false;
    this.activeSessionsTable = true;
  }

  nextSessionSplit(sessions: FindType<"tableSessions">[]) {
    let nextSplit = 0;
    const tables = _.sortBy(
      sessions.map(it => it.tables.find(table => checkID(table.item, this.selectedTable))).filter(it => it),
      it => it.split,
    );
    for (let session of tables) {
      if (session.split !== nextSplit) {
        break;
      }
      nextSplit = session.split + 1;
    }
    return nextSplit;
  }

  confirmOrder() {
    if (this.takeawayMode) {
      this.router?.navigate?.("categories");
      this.openTable = false;
    } else {
      this.confirmDineInOrder();
    }
  }

  async confirmDineInOrder() {
    const staff = await this.$shop.checkPermission(["tableManage/tableSessionCreateOrder"]);
    if (staff === false) return;
    this.session.item.createStaff = staff?._id || this.$shop.staffId;
    this.router?.reset("tables");
    this.router?.navigate("tables");
    this.router?.navigate("categories");
    this.session.dirty = false;
    this.session.startSubscribe();
    await this.session.resumeSave();
    await this.session.atomic({
      ...this.session.item,
      ...this.session.cachedPriceDetails,
      checkBillTime: new Date(),
      staff: this.$shop.staffId as any,
      status: "ongoing",
    });
    await this.session.handleSessionCreate();
    this.openTable = false;
    this.activeSessionsTable = false;
  }

  async placeOrder() {
    try {
      if (this.loading) return;
      this.loading = true;
      this.session.screenOverride = null;
      if (!this.session.item._id) {
        this.session.dirty = false;
        this.session.resumeSave();
        this.session.startSubscribe();
        await this.session.atomic({
          ...this.session.item,
          // ...this.session.cachedPriceDetails,
          checkBillTime: new Date(),
          staff: this.$shop.staffId as any,
          status: "ongoing",
        });
        if (await this.session.redeemGifts()) {
          this.session.updateCoupons();
          // await this.session.atomic({
          //   ...this.session.cachedPriceDetails,
          // });
        }
        this.session.startSubscribe();
      }
      if (this.session.cart.length) {
        this.lastCart = null;
        await this.placeOrderInner();
      } else {
        await this.session.atomic({
          ...this.session.cachedPriceDetails,
        });
      }
      if (this.takeawayMode) {
        this.takeawayMode = false;
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loading = false;
    }
  }

  get filterSessionAll() {
    return {
      startTime: { $ne: null },
      endTime: null,
      status: { $ne: "booking" },
      // status: "ongoing",
      type: "dineIn",
    };
  }

  get filterSessionByTable() {
    return {
      ...this.filterSessionAll,
      ...(this.selectedTable
        ? {
            "tables.item": getID(this.selectedTable),
          }
        : {}),
    };
  }

  async confirmKitchenOptions() {
    try {
      try {
        await this.$feathers.service("tableSessions/order").patch(null, {
          session: this.session.item._id,
          products: [
            {
              ...this.editingLine,
              kitchenOptions: this.tempKitchenOptions,
            },
          ],
          reprint: true,
          staff: this.$shop.staffId,
        });
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
      await this.session.reload();
      this.router?.navigate?.();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
    }
  }

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active";
  }

  async addCoupon(coupon: CouponType) {
    const current = this.session.coupons.find(it => it.coupon && checkID(coupon, it.coupon));
    const result = current && !current.valid ? current : await this.session.tryApplyCoupon(coupon);
    if (result instanceof CouponItem) {
      if (result.error) {
        await result.tryFix();
      }
      if (result.error) {
        this.$store.commit("SET_ERROR", this.$td(result.error));
        result.remove();
      } else if (this.session.type === "dineIn") {
        try {
          this.session.updateCachedDetails();
          await this.session.save();
        } catch (e) {
          console.warn(e);
          this.$store.commit("SET_ERROR", this.$td("couponErrors.cannotApply"));
          result.remove();
        }
      }
      this.session.updateCoupons();
      await this.session.atomic({
        discounts: this.session.discounts,
        ...this.session.cachedPriceDetails,
      });
      this.session.syncOrder();
    } else {
      this.$store.commit("SET_ERROR", this.$td(result));
    }
  }

  async addGift(gift: GiftType) {
    const result = await this.session.tryApplyGift(gift);
    if (result.error) {
      await result.tryFix();
    }
    if (result.error) {
      this.$store.commit("SET_ERROR", this.$td(result.error));
      result.remove();
      return;
    } else if (this.session.type === "dineIn") {
      try {
        this.session.updateCachedDetails();
        await this.session.save();
      } catch (e) {
        console.warn(e);
        this.$store.commit("SET_ERROR", this.$td("couponErrors.cannotApply"));
        result.remove();
        return;
      }
    }
    if (this.session.item._id) {
      const record = await this.$feathers.service("gifts/redeem").create({
        gift: getID(gift),
        user: getID(this.session.user),
        shop: getID(this.$shop?.shopData),
        session: getID(this.session.item._id),
      } as any);
      result.giftRecord = getID(record);
      for (let item of this.session.cart) {
        if (item.coupon === result) {
          item.fromCoupon = getID(result);
        }
      }
    }

    this.session.updateCoupons();
    await this.session.atomic({
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  get filteredCustomProducts() {
    if (!this.selectedCat) return [];
    if (!this.selectedSubCat) return [];

    if (this.selectedSubCat) {
      return Object.values(this.$shop.customProducts).filter(it => checkID(it.subCategory, this.selectedSubCat));
    }
    return Object.values(this.$shop.customProducts).filter(it => checkID(it.category, this.selectedCat));
  }

  get filteredProducts() {
    const cat = this.selectedCat;
    const subCat = this.selectedSubCat;
    const section = this.selectedSection;
    return this.$shop.products.filter(
      it =>
        (subCat !== null ? checkID(it.category, cat) && checkID(it.subCategory, subCat) : checkID(it.category, cat)) &&
        (!section || (it.sections || []).find(it => checkID(it, section))) &&
        !it.setOrderOrGiftOnly,
    );
  }

  get products() {
    return [...this.filteredCustomProducts, ...this.filteredProducts];
  }

  get productList() {
    return [...(this.products || []), ...new Array(0).fill(null)]; // FIXME: hardcoded to '0'
  }

  get filteredCats() {
    if (!this.selectedSection) return this.$shop.cats;
    const result = _.filter(this.$shop && this.$shop.cats, it =>
      it.sections.some(jt => checkID(jt, this.selectedSection)),
    );
    this.selectedCat = (result && result[0] && result[0]._id) || "";
    return result;
  }

  get categories() {
    return this.filteredCats;
    // return _.chunk(this.filteredCats, 6);
  }

  get subCats() {
    return this.selectedCat ? this.$shop.subCats.filter(it => checkID(it.category, this.selectedCat)) : [];
  }

  selectCatIndex(i: number) {
    const cat = this.categories[i];
    if (!cat) return;
    this.selectedCat = cat as any;
    this.router?.navigate?.("picker");
  }

  selectSubCatIndex(i: number) {
    const cat = this.subCats[i];
    if (!cat) return;
    this.selectedSubCat = cat as any;
  }

  get currentCart() {
    return this.session?.cart ?? [];
  }

  get totalQuantity() {
    return _.sumBy(this.currentCart, it => it.quantity) ?? 0;
  }

  isNotSelling(product: ProductType, checked: Set<string> = new Set()) {
    if (!product) return false;
    const isProduct = this.$shop.productDict[String(product._id)];
    if (!isProduct) return false;
    const level = getProductStockLevel(product, {
      session: this.session,
      productsDict: this.$shop.productDict,
      optionsDict: this.$shop.productOptionDict,
    });
    return level === StockLevel.OutOfStock || level === StockLevel.Disabled || level === StockLevel.NotSelling;
  }

  async addToCart(i: number) {
    let cart: CartItem;
    let showOptions = false;
    const item = this.productList[i];
    if (!item) return;
    const isProduct = this.$shop.productDict[item._id];

    if (!isProduct) {
      cart = this.session.addToCart(
        {
          quantity: 1,
        },
        {
          swap: true,
          customProduct: item,
          mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
        },
      );
      if (await this.session.updateAutoMerge()) {
        return;
      }
    } else {
      if (this.isNotSelling(item)) {
        const stock = item.stock;
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title:
              stock?.mode === "notSelling"
                ? this.$t("tableView.confirmNotSelling", { name: this.$td(item.name) })
                : this.$t("tableView.confirmOutOfStock", { name: this.$td(item.name) }),
          },
          {
            maxWidth: "400px",
          },
        );
        if (!c) return;
      }

      if (!item) return;
      cart = this.session.addToCartFromProduct(item, {
        swap: true,
        mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
      });
      if (await this.session.updateAutoMerge()) {
        return;
      }
      if (item.options?.find?.(it => this.$shop.productOptionDict[String(it)]?.required)) {
        showOptions = true;
      }
    }

    this.lastCart = cart;
    if (showOptions) {
      this.editingCart = cart;
      this.router?.navigate?.("cartItem");
    }
  }

  clearCart() {
    if (this.session) {
      this.session.clearCart();
      this.lastCart = null;
    }
  }

  @Watch("searchRecordNumPad")
  onSearchRecordNumPad() {
    this.code = "";
    this.sessions = null;
  }
}

function urlbase64(buf: Buffer | string) {
  return (typeof buf === "string" ? buf : buf.toString("base64")).replace(/\-/g, "+").replace(/_/g, "/");
}
