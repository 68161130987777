import { Component, Vue, mixins, FindType, Watch, isBBPOS } from "@feathers-client";
import AllinpayPOS from "~/mixins/allinpay";
import { checkID, getID, getOptions } from "@feathers-client/util";
import { AllinpayInfo } from "~/mixins/allinpay";
import { OctopusManager } from "pos-printer/octopus";
import { HASEManager } from "pos-printer/hase";
import { TurnCloudManager } from "pos-printer/turncloud";
import { RazerPayHeader, RazerPayManager, RazerPayMessage } from "pos-printer/razerpay";
import { SimplePaymentIntegrationManager } from "pos-printer/simplePaymentIntegration";

const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
import basex from "base-x";
import { CloudPayload, PaymentLike, PaymentMethodBase, PaymentOptions } from "pos-printer/payments/methodBase";
import type { BBMSLConfig } from "pos-printer/payments/methods/bbpos";
const bs62 = basex(BASE62);

const methodContext = require.context("pos-printer/payments/methods", true, /\.ts$/, "lazy");
const methodDict = Object.fromEntries(
  methodContext.keys().map(key => [key.replace(/\.\/|\.ts/g, "").replace(/\/index$/, ""), key]),
);

@Component
export class PaymentManager extends mixins(AllinpayPOS) {
  initManager() {
    this.updateOctopus();
    this.updateHase();
    this.updateTurnCloud();
    this.updateRazer();
    this.updateSimplePaymentIntegration();

    this.initPayments();
    this.scheduleSessionUpdate();
    return this;
  }

  methods: Record<string, PaymentMethodBase> = {};
  allMethods: Record<string, PaymentMethodBase> = {};

  @Watch("$shop.paymentMethods")
  async initPayments() {
    const methodsGroupped = this.$shop.paymentMethods.reduce(
      (acc, method) => {
        const group = acc[method.type];
        if (!group) {
          acc[method.type] = [method];
        } else {
          group.push(method);
        }
        return acc;
      },
      {} as Record<string, typeof this.$shop.paymentMethods>,
    );
    const methods = await Promise.all(
      Object.values(methodsGroupped).map(async methodGroup => [
        methodGroup[0].type,
        await this.initPayment(methodGroup),
      ]),
    );
    this.methods = Object.fromEntries(methods.filter(it => it[1]));
  }

  async initPayment(method: FindType<"paymentMethods">[]) {
    const prev = this.allMethods[method[0].type];
    if (prev) {
      prev.updateMethod(method);
      return prev;
    } else if (methodDict[method[0].type]) {
      return await this._initPayment(method[0].type, method);
    }
  }

  private async _initPayment(type: string, method?: FindType<"paymentMethods">[]) {
    try {
      const methodClass = (await methodContext(methodDict[type]))?.default;
      if (this.allMethods[type]) {
        return this.allMethods[type];
      }
      const instance = new methodClass({
        parent: this,
        propsData: {
          initMethod: method,
          paymentManager: this,
          type: type,
        },
      });
      this.allMethods[type] = instance;
      return instance;
    } catch (e) {
      console.warn(`Failed to load payment method ${type}`, e);
    }
  }

  get methodConnectionCount() {
    return Object.keys(this.methods).length;
  }

  get methodSetupCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.hasConfig ? 1 : 0), 0);
  }

  get methodOnlineCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.connected ? 1 : 0), 0);
  }

  get methodErrorCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.error ? 1 : 0), 0);
  }

  getConfig(type: string) {
    let result = this.$shop.localOptions.methodConfigs?.[type];
    if (result) {
      return result;
    }
    switch (type) {
      case "bbpos": {
        // try upgrade old config

        const bbmsl = this.$shop.localOptions.bbmsl;
        if (bbmsl) {
          const config: BBMSLConfig = {
            serialType: "http",
            http: {
              host: bbmsl.posURL ? new URL(bbmsl.posURL).hostname : "",
            },
            posPass: bbmsl.posPass,
            posUser: bbmsl.posUser,
          };
          return config;
        }

        break;
      }
    }
  }

  setConfig(type: string, config: any) {
    this.$shop.updateLocalOption(`methodConfigs.${type}`, config);
    switch (type) {
      case "bbpos": {
        if (this.$shop.localOptions.bbmsl) {
          this.$shop.localOptions.bbmsl = null;
        }
        break;
      }
    }
  }

  getMethod(type: string) {
    return this.methods[type];
  }

  async getAllMethod(type: string) {
    if (this.allMethods[type]) return this.allMethods[type];
    return await this._initPayment(type);
  }

  getInfo(type: string) {
    return {
      shopId: this.$shop.shopData?._id,
      cashierId: this.$shop.device?._id,
      shopName: this.$td(this.$shop.shopData?.name),
      cashierName: this.$td(this.$shop.device?.name) || this.$shop.device?.shortId,
      cashierShortId: this.$shop.device?.shortId,
      countryCode: "AU",
      // posVersion: "",
    };
  }

  start() {}

  getPaymentId(payment: FindType<"payments">) {
    let id = getID(payment._id);
    if (payment.offlineId) {
      id = payment.offlineId;
    }
    if (id && id.startsWith("$$offline")) {
      return "$" + id.substring(id.lastIndexOf("/") + 1).replace(/\-/g, "");
    }
    return id;
  }

  octopus: OctopusManager = null;
  hase: HASEManager = null;
  turnCloud: TurnCloudManager = null;
  razer: RazerPayManager = null;
  simplePaymentIntegration: SimplePaymentIntegrationManager = null;

  async refundPayment(paymentId: string, retry = 0, opts?: PaymentOptions): Promise<FindType<"payments">> {
    let payment = await this.$feathers.service("payments").get(paymentId);
    let refunded = false;
    let message: string;

    if (payment.status !== "done") {
      return payment;
    }

    try {
      switch (payment.methodType) {
        case "allinpay": {
          refunded = await this.refundAllinpayPayment(payment);
          await this.checkRefundConfirmed();
          break;
        }

        case "hase": {
          const hase = this.checkHase();
          const refund = await hase.refundPayment(payment.metadata);
          console.log(refund);
          refunded = true;
          break;
        }
        case "turnCloud": {
          refunded = await this.refundTurnCloudPayment(payment);
          break;
        }
        case "razer": {
          refunded = await this.refundRazerPayment(payment);
          break;
        }
        case "simplePaymentIntegration": {
          refunded = await this.refundSimplePaymentIntegrationPayment(payment);
          break;
        }
        case "cash":
        case "manual": {
          refunded = true;
          break;
        }
        default: {
          const method = this.$paymentManager.getMethod(payment.methodType);
          if (method) {
            await method.waitReady(true);
            let paymentLike: PaymentLike = {
              _id: payment._id,
              amount: payment.amount,
              tips: payment.tips,
              status: "paid",
              metadata: payment.metadata,
              subType: payment.methodSubType,
            };
            if (!opts) opts = {};
            if (!opts.cloudInvoke) opts.cloudInvoke = this.cloudInvoke;
            if (method.capabilities?.void) {
              try {
                const resp = await method.void(paymentLike, opts);
                if (resp) {
                  paymentLike = resp;
                }
                if (paymentLike.status === "refunded") {
                  refunded = true;
                }
              } catch (e) {
                if (method.capabilities?.restore) {
                  const resp = await method.restore(paymentLike, opts);
                  if (resp) {
                    paymentLike = resp;
                  }
                  if (paymentLike.status === "refunded") {
                    refunded = true;
                  }
                }
                // if void failed, try refund
                if (!refunded && !method.capabilities?.refund) {
                  throw e;
                }
              }
            }
            if (!refunded && method.capabilities?.refund) {
              try {
                const resp = await method.refund(paymentLike, opts);
                if (resp) {
                  paymentLike = resp;
                }
                if (paymentLike.status === "refunded") {
                  refunded = true;
                }
              } catch (e) {
                if (method.capabilities?.restore) {
                  const resp = await method.restore(paymentLike, opts);
                  if (resp) {
                    paymentLike = resp;
                  }
                  if (paymentLike.status === "refunded") {
                    refunded = true;
                  }
                }
                if (!refunded) {
                  throw e;
                }
              }
            }
          } else {
            refunded = true;
          }
          break;
        }
      }
    } catch (e) {
      refunded = false;
      message = e.message || `${e}`;
    }

    if (!refunded) {
      if (retry < 3) {
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog2.vue"),
          {
            title: `${this.$t("payment.failedToCancel")}`,
            content: this.$t("payment.continueToCancel"),
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            cancelText: "text-sm text-primary font-medium",
            confirm: this.$t("paymentDialog.retry"),
            cancel: this.$t("payment.manualRefund"),
            errorMessage: message,
          },
          {
            maxWidth: "600px",
          },
        );
        if (typeof c !== "boolean") {
          return payment;
        }
        if (c) return this.refundPayment(paymentId, retry + 1);

        await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog2.vue"),
          {
            title: `${this.$t("payment.confirmManualRefund")}`,
            content: this.$t("payment.confirmRefundManualDesc"),
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            onlyConfirm: true,
          },
          {
            maxWidth: "600px",
          },
        );
      }
    }

    payment = await this.$feathers.service("payments/cancel").create({
      payment: payment._id,
      status: "refunded",
    });

    return payment;
  }

  async cloudInvoke(paymentLike: PaymentLike, payload: CloudPayload, options?: PaymentOptions) {
    const resp = await this.$feathers.service("payments/cloud").patch(paymentLike._id, {
      payload,
    });
    switch (resp.status) {
      case "done":
        paymentLike.status = "paid";
        break;
      default:
        paymentLike.status = resp.status;
        break;
    }
    paymentLike.metadata = resp.metadata;
    paymentLike.errorMessage = resp.errorMessage;
    paymentLike.message = resp.message;
    return paymentLike;
  }

  async refundAllinpayPayment(payment: FindType<"payments">) {
    let allinpayInfo: AllinpayInfo;
    try {
      allinpayInfo = await this.getAllinpayInfo();
    } catch (e) {
      throw new Error("Cannot connect to terminal");
    }

    if (allinpayInfo.TER_ID !== payment.metadata?.TER_ID) {
      throw new Error("Please refund on original terminal with ID" + payment.metadata?.TER_ID);
    }

    if (allinpayInfo.MERCH_ID !== payment.metadata?.MERCH_ID) {
      throw new Error("Please refund on original terminal with merchant ID" + payment.metadata?.MERCH_ID);
    }

    let resp;

    try {
      resp = await this.callAllinpayPOS({
        BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
        TRANS_TRACE_NO: String(payment._id),
      });
    } catch (e) {
      if (e.REJCODE === "1FF") {
        return false;
      }
    }
    let refunded = resp.TRANS_STATE === "VOID" || resp.TRANS_STATE === "RETURNED";
    if (resp.TRANS_STATE === "PAIED" || resp.CARD_ORGN) {
      if (resp.CARD_ORGN) {
        // try to find refund order
        try {
          const resp = await this.callAllinpayPOS({
            BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
            TRANS_TRACE_NO: String(payment._id) + "REF",
          });
          if (resp) {
            refunded = true;
          }
        } catch (e) {}
      }
    }

    if (!refunded) {
      const resp = await this.callAllinpayPOS({
        BUSINESS_ID: payment.methodSubType === "CREDIT" ? "200100001" : "200300001",
        AMOUNT: Math.floor(payment.amount * 100)
          .toString()
          .padStart(12, "0"),
        ORIG_TRACE_NO: payment.metadata.TRACE_NO,
        TRANS_TRACE_NO: String(payment._id) + "REF",
        CURRENCY: payment.currency || "HKD",
      });

      return true;
    }
  }

  async refundTurnCloudPayment(payment: FindType<"payments">) {
    const turnCloud = this.getTurnCloud();
    await turnCloud.waitReady(true);
    const resp = await turnCloud.doTransaction({
      payType: payment.metadata?.payType,
      actionType: "refund",
      orderNo: String(payment._id),
      ...(payment.metadata?.referenceNo ? { referenceNo: payment.metadata?.referenceNo } : {}),
      ...(payment.metadata?.period ? { period: payment.metadata?.period } : {}),
    });

    return true;
  }

  async refundRazerPayment(payment: FindType<"payments">) {
    const razer = this.getRazer();
    await razer.waitReady(true);
    const cancelHeader = new RazerPayHeader();
    cancelHeader.transactionCode = "40";
    const message = payment.metadata as RazerPayMessage;
    const cancelPayload: Partial<RazerPayMessage> = {
      header: cancelHeader,
      body: {
        payAccountId: "00000000000000000000", // This field is use in another project
        transactionId: bs62.encode(Buffer.from(String(payment._id), "hex")),
        invoiceNo: message?.body?.invoiceNo,
        extendedInvoiceNo: message?.body?.customDataParsed?.walletInvoiceNumber,
      },
    };
    const resp = await razer.doTransaction(cancelPayload);
    return true;
  }

  get hasOctopus() {
    return this.$shop.paymentMethods.find(it => it.type === "octopus");
  }

  get hasHase() {
    return this.$shop.paymentMethods.find(it => it.type === "hase");
  }

  get hasTurnCloud() {
    return this.$shop.paymentMethods.find(it => it.type === "turnCloud");
  }

  get hasRazer() {
    return this.$shop.paymentMethods.find(it => it.type === "razer");
  }

  get connectionCount() {
    return (
      [
        this.hasOctopus, //
        this.hasHase,
        this.hasTurnCloud,
        this.hasRazer,
        this.hasSimplePaymentIntegration,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodConnectionCount
    );
  }

  get connectionSetupCount() {
    return (
      [
        this.hasOctopus && this.$shop.localOptions.octopus,
        this.hasHase && this.$shop.localOptions.hase,
        this.hasTurnCloud && this.$shop.localOptions.turncloud,
        this.hasRazer && this.$shop.localOptions.razer,
        this.hasSimplePaymentIntegration && this.$shop.localOptions.simplePaymentIntegration,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodSetupCount
    );
  }

  get onlineCount() {
    return (
      [
        this.octopusConnected, //
        this.haseConnected,
        this.turnCloudConnected,
        this.razerConnected,
        this.simplePaymentIntegrationConnected,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodOnlineCount
    );
  }

  get errorCount() {
    return (
      [
        this.octopusError, //
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodErrorCount
    );
  }

  @Watch("hasOctopus")
  updateOctopus() {
    return this.getOctopus();
  }

  getOctopus(setup?: boolean) {
    if (!this.octopus && this.hasOctopus && (setup || this.$shop.localOptions.octopus)) {
      this.octopus = new OctopusManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$shop.localOptions.octopus;
          },
          setSetting: v => {
            this.$shop.localOptions.octopus = v;
          },
          getInfo: () => {
            return {
              shopId: this.$shop.shopData?._id,
              cashierId: this.$shop.device?._id,
              locationId: this.hasOctopus?.props?.locationId,
              shopName: this.$td(this.$shop.shopData?.name),
              cashierName: this.$td(this.$shop.device?.name) || this.$shop.device?.shortId,

              cloudArgs: {
                method: this.hasOctopus?._id,
              },
            };
          },
          whenDestroy: () => {
            this.octopus = null;
          },
          syncEnquiryState: state => {
            if (this.$shop.secondScreen) {
              this.$shop.secondScreen.queue.ns("posScreen").call("setOctopusEnquiry", state);
            }
          },
        },
      });
    }
    return this.octopus;
  }

  get octopusStatus() {
    if (!this.octopus) return "";
    switch (this.octopus.status) {
      case "connecting":
        return this.octopus.hasConnection ? this.$t("enum.basic.status.connecting") : this.$t("octopus.manualConnect");
      case "starting":
        return this.$t("enum.basic.status.starting");
      case "manual":
        return this.$t("octopus.manualConnect");
    }
  }

  get octopusError() {
    return this.octopus?.status === "disconnected" || this.octopus?.status === "error";
  }

  get octopusConnected() {
    return this.octopus?.status === "connected";
  }

  connectOctopus() {
    this.getOctopus(true)?.openSettings?.();
  }

  getHase(setup?: boolean) {
    if (!this.hase && this.hasHase && (setup || this.$shop.localOptions.hase)) {
      this.hase = new HASEManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$shop.localOptions.hase;
          },
          setSetting: v => {
            this.$shop.localOptions.hase = v;
          },
          whenDestroy: () => {
            this.hase = null;
          },
          getInfo: () => {
            return {
              shopId: this.$shop.shopData?._id,
              cashierId: this.$shop.device?._id,
              shopName: this.$td(this.$shop.shopData?.name),
              cashierName: this.$td(this.$shop.device?.name) || this.$shop.device?.shortId,
            };
          },
        },
      });
    }
    return this.hase;
  }

  checkHase() {
    console.log("[hase] check hase");

    if (!this.hase) {
      throw new Error(String(this.$t("bbmsl.notSetup")));
    }
    return this.hase;
  }

  @Watch("hasHase")
  updateHase() {
    return this.getHase();
  }

  @Watch("hasTurnCloud")
  updateTurnCloud() {
    return this.getTurnCloud();
  }

  @Watch("hasRazer")
  updateRazer() {
    return this.getRazer();
  }

  get haseConnected() {
    return this.hase?.status === "connected";
  }

  get haseStatus() {
    if (!this.hase) return "";
    switch (this.hase.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }

  async connectHase() {
    this.getHase(true)?.openSettings?.();
  }

  deferSessionUpdateTime: number = 0;
  sessionUpdateTimer: any;
  pendingClose: string = null;

  deferSessionUpdate() {
    this.deferSessionUpdateTime = Date.now();
  }

  @Watch("$shop.shopData.currentSession")
  onSessionUpdated(v, ov) {
    if (v === ov) return;
    if (v && !ov && !checkID(this.$shop.shopData?.currentSession, this.$shop.cacheOptions.lastSession)) {
      this.pendingClose = v;
    }
    if (Date.now() - this.deferSessionUpdateTime < 10 * 1000) {
      return;
    }
    this.scheduleSessionUpdate();
  }

  scheduleSessionUpdate() {
    if (this.sessionUpdateTimer) {
      clearTimeout(this.sessionUpdateTimer);
      this.sessionUpdateTimer = null;
    }
    this.sessionUpdateTimer = setTimeout(this.sessionUpdateCheck, 10 * 1000);
  }

  async sessionUpdateCheck() {
    this.sessionUpdateTimer = null;
    const forceClose = this.pendingClose && !checkID(this.$shop.shopData?.currentSession, this.pendingClose);
    if (
      (this.$shop.shopId && !checkID(this.$shop.shopData?.currentSession, this.$shop.cacheOptions.lastSession)) ||
      forceClose
    ) {
      if (this.$shop.cacheOptions.lastSession || forceClose) {
        await this.triggerShopClose();
      }
      if (this.$shop.shopData?.currentSession) {
        await this.triggerShopOpen();
      }
    }
  }

  async triggerShopOpen() {
    if (this.octopus) {
      try {
        this.octopus.log("Going to Download in shop open");
        await this.octopus.waitReady(true);
        await this.octopus.scheduleHousekeeping(async () => {
          await this.octopus.download();
        });
      } catch (e) {
        if (e.message?.startsWith?.("Timeout")) {
          this.octopus.log("Timeout in shop open");
          return;
        }
        this.$store.commit("SET_ERROR", e.message);
      }
    }
    if (this.$shop.shopId) {
      this.$shop.cacheOptions.lastSession = getID(this.$shop.shopData?.currentSession);
    }
    this.pendingClose = null;
  }

  async settleOctopus() {
    if (this.octopus) {
      try {
        this.octopus.log("Going to XFile in shop close");
        try {
          await this.octopus.waitReady(true, 60 * 1000);
        } catch (e) {
          this.octopus.log("No connection in shop close");
          // warn failed xfile
          this.octopus.sendServerNotification({
            messageId: "octopus.xfileFailedNoConnection",
            level: "warning",
            identifier: `xfileFailed`,
          });
          await this.octopus.waitReady(true);
          this.octopus.log("Connected in shop close");
        }
        await this.octopus.scheduleHousekeeping(async () => {
          await this.octopus.upload();
        });
      } catch (e) {
        if (e.message?.startsWith?.("Timeout")) {
          return;
        }
        this.$store.commit("SET_ERROR", e.message);
      }
    }
  }

  async settleHASE() {
    if (this.hase && this.hase.autoSettlement) {
      try {
        await this.hase.settlementAll(true);
      } catch (e) {
        if (e.message?.startsWith?.("Timeout")) {
          return;
        }
        this.$store.commit("SET_ERROR", e.message);
      }
    }
  }

  async settleGeneric() {
    await Promise.all(
      Object.values(this.methods)
        .filter(m => m.hasConfig && m.capabilities?.autoSettle && m.capabilities?.settlement)
        .map(async method => {
          try {
            await method.waitReady(true);
            await method.settlement();
          } catch (e) {
            console.error(e);
            this.$store.commit("SET_ERROR", e.message);
          }
        }),
    );
  }

  async triggerShopClose() {
    await Promise.all([
      this.settleOctopus(), //
      this.settleHASE(),
      this.settleGeneric(),
    ]);
    if (this.$shop.shopId) {
      this.$shop.cacheOptions.lastSession = null;
    }
    this.pendingClose = null;
  }

  get turnCloudError() {
    return this.turnCloud?.status === "disconnected";
  }

  get turnCloudConnected() {
    return this.turnCloud?.status === "connected";
  }

  get razerConnected() {
    return this.razer?.status === "connected";
  }

  get razerError() {
    return this.razer?.status === "disconnected";
  }

  get turnCloudStatus() {
    if (!this.turnCloud) return "";
    switch (this.turnCloud.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }

  get razerStatus() {
    if (!this.razer) return "";
    switch (this.razer.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }

  connectTurnCloud() {
    this.getTurnCloud(true)?.openSettings?.();
  }

  getTurnCloud(setup?: boolean) {
    if (!this.turnCloud && this.hasTurnCloud && (setup || this.$shop.localOptions.turncloud)) {
      this.turnCloud = new TurnCloudManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$shop.localOptions.turncloud;
          },
          setSetting: v => {
            this.$shop.localOptions.turncloud = v;
          },
          whenDestroy: () => {
            this.turnCloud = null;
          },
          getInfo: () => {
            return {
              shopId: this.$shop.shopData?._id,
              cashierId: this.$shop.device?._id,
              shopName: this.$td(this.$shop.shopData?.name),
              cashierName: this.$td(this.$shop.device?.name) || this.$shop.device?.shortId,
            };
          },
        },
      });
    }
    return this.turnCloud;
  }

  async checkRefundConfirmed() {
    await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: `${this.$t("payment.confirmRefund")}`,
        content: this.$t("payment.confirmRefundDesc"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        onlyConfirm: true,
      },
      {
        maxWidth: "600px",
      },
    );
  }

  getRazer(setup?: boolean) {
    if (!this.razer && this.hasRazer && (setup || this.$shop.localOptions.razer)) {
      this.razer = new RazerPayManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$shop.localOptions.razer;
          },
          setSetting: v => {
            this.$shop.localOptions.razer = v;
          },
          whenDestroy: () => {
            this.razer = null;
          },
        },
      });
    }
    return this.razer;
  }

  connectRazer() {
    this.getRazer(true)?.openSettings?.();
  }

  get hasSimplePaymentIntegration() {
    return this.$shop.paymentMethods.find(it => it.type === "simplePaymentIntegration");
  }

  get simplePaymentIntegrationError() {
    return this.simplePaymentIntegration?.status === "disconnected";
  }

  get simplePaymentIntegrationConnected() {
    return this.simplePaymentIntegration?.status === "connected";
  }

  get simplePaymentIntegrationStatus() {
    if (!this.simplePaymentIntegration) return "";
    switch (this.simplePaymentIntegration.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }

  @Watch("hasSimplePaymentIntegration")
  updateSimplePaymentIntegration() {
    return this.getSimplePaymentIntegration();
  }

  getSimplePaymentIntegration(setup?: boolean) {
    if (
      !this.simplePaymentIntegration &&
      this.hasSimplePaymentIntegration &&
      (setup || this.$shop.localOptions.simplePaymentIntegration)
    ) {
      this.simplePaymentIntegration = new SimplePaymentIntegrationManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$shop.localOptions.simplePaymentIntegration;
          },
          setSetting: v => {
            this.$shop.localOptions.simplePaymentIntegration = v;
          },
          getInfo: () => {
            return {
              shopId: this.$shop.shopData?._id,
              cashierId: this.$shop.device?._id,
              shopName: this.$td(this.$shop.shopData?.name),
              cashierName: this.$td(this.$shop.device?.name) || this.$shop.device?.shortId,
              cashierShortId: this.$shop.device?.shortId,
              countryCode: "AU",
              // posVersion: "",
            };
          },
          whenDestroy: () => {
            this.simplePaymentIntegration = null;
          },
        },
      });
    }
    return this.simplePaymentIntegration;
  }

  connectSimplePaymentIntegration() {
    this.getSimplePaymentIntegration(true)?.openSettings?.();
  }

  async refundSimplePaymentIntegrationPayment(payment: FindType<"payments">) {
    const resp = await this.simplePaymentIntegration.refund({
      payment: "refund_" + getID(payment),
      amount: Math.round(payment.amount * 100),
    });
    if (resp.status === "failed") {
      this.$store.commit("SET_ERROR", resp.message);
      return false;
    }
    return resp.status === "success";
  }
}

Object.defineProperty(Vue.prototype, "$paymentManager", {
  get(this: Vue) {
    return (
      (<any>this.$root.$options).$paymentManager ||
      ((<any>this.$root.$options).$paymentManager = new PaymentManager(getOptions(this.$root)).initManager())
    );
  },
});

declare module "vue/types/vue" {
  export interface Vue {
    $paymentManager: PaymentManager;
  }
}
