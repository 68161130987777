
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";

@Component
export default class OrderSystemSection extends Vue {
  @Prop()
  value: string;

  @Prop()
  session: TableSession;

  get inputValue() {
    return this.session.section;
  }

  selectThisSection(section) {
    this.$emit("selectSection", section._id);
  }
}
