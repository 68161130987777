
import { Component, Prop, PropSync, Vue } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "@common/table/session";
import type { CartItem } from "@common/table/cart";

@Component
export default class ProductLineSideFooter extends Vue {
  @Prop()
  cart: CartItem;

  @Prop()
  session: TableSession;

  @Prop()
  line: ProductLine;

  @Prop()
  navigate: any;

  @PropSync("editCartItem")
  editCartItemValue: boolean;

  get item(): (CartItem | ProductLine) & { replacing?: boolean } {
    return this.cart || this.line;
  }

  get previewMerge() {
    return this.cart && this.session?.cachedMergeResultDict?.[this.cart.id];
  }

  get finalMergeStatus() {
    return this.cart?.mergeStatus || this.previewMerge?.mergeStatus;
  }
}
