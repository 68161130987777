
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { checkID, getID, FindType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";
import { openCashBox } from "~/plugins/printer/invoiceSequencer";

@Component
export default class OrderSystemStaff extends Vue {
  @PropSync("scanning")
  scanningSync: boolean;

  @Prop()
  session: TableSession;

  @Prop({ type: Boolean })
  hideOrderList: boolean;

  get currentDate() {
    return this.$moment().format("ll");
  }

  async cashbox() {
    const staff = await this.$shop.checkPermission(["withDrawDeposit/openCashBox"]);
    if (staff === false) return;
    await openCashBox(this, staff);
  }
}
