
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import "~/plugins/payments";

@Component({})
export default class SplitPayment extends Vue {
  @Prop()
  session: TableSession;

  get isPaid() {
    return this.session?.payments?.length > 0;
  }

  async refund(_payment) {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToCancel"),
        lock: !this.$shop.hasPermission([`paymentManage/tableSessionRefundPayment`]),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    const staff = await this.$shop.checkPermission(["paymentManage/tableSessionRefundPayment"]);
    if (staff === false) return;
    // this.loading = true;
    const refundedPayment = _payment;
    try {
      const payment = await this.$paymentManager.refundPayment(refundedPayment);
      await this.session?.refundSplittedPayment(payment);

      await this.session?.reload?.();
      await this.session?.printOrder?.({
        payment: getID(payment._id),
      });

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "paymentManage/tableSessionRefundPayment",
        detail: { payment: [payment] },
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      // this.loading = false;
    }
  }
}
