
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { ProductType } from "~/plugins/shop";
import _ from "lodash";
import { parseHash } from "~/plugins/table/kdsProduct";

@Component
export default class TableKDSStockGroup extends Vue {
  @Prop()
  product: ProductType;

  getIcon(stock: any) {
    const type = stock?.options[0]?.type || "D";
    switch (type) {
      case "D":
        return "$dineIn";
      case "T":
        return "$ic24-takeaway";
      default:
        return "$dineIn";
    }
  }

  get groupedStocks() {
    return Object.values(_.groupBy(this.product.stock.kdsStock, it => it.hash)).map(it => {
      return {
        hash: it[0].hash,
        options: parseHash(this, it[0].hash).filter(it => it.values.length),
        quantity: _.sumBy(it, i => i.quantity),
      };
    });
  }

  async shortpress(stock: (typeof this)["groupedStocks"][number]) {
    await this.$openDialog(
      import("./productStockNum.vue"),
      {
        action: "adjust",
        product: this.product,
        hash: stock.hash,
        initQuantity: stock.quantity,
      },
      {
        maxWidth: "min(50vw,360px)",
      },
    );
  }
}
