var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-grow flex flex-col"},[(_vm.queueTicketGroupMapped.callingTicket)?_c('div',{staticClass:"flex-grow flex flex-col justify-center items-center border-b-1 border-solid border-[#828282] p-4"},[_c('div',{staticClass:"text-4.3vh"},[_vm._v(" "+_vm._s(_vm.queueTicketGroupMapped.callingTicket.status === "checked" ? _vm.$t("queueing.clinic.currentNumber") : _vm.queueTicketGroupMapped.callingTicket._id === _vm.queueTicketGroupMapped._id ? _vm.$t("queueing.currentTicket") : _vm.$t("queueing.reCall"))+" ")]),_c('div',{staticClass:"text-15vh font-barlow-bold",class:_vm.queueTicketGroupMapped.callingTicket.status === 'calling'
          ? 'calling-color-animation'
          : _vm.queueTicketGroupMapped.callingTicket.status === 'called'
          ? 'text-[#F5B800]'
          : _vm.queueTicketGroupMapped.callingTicket.status === 'checked'
          ? 'text-[#4BC77F]'
          : '',style:([_vm.queueTicketGroupMapped.callingTicket.status === 'calling' ? { '--text-color': '#F5B800' } : ''])},[_vm._v(" "+_vm._s(_vm.queueTicketGroupMapped.callingTicket.ticketNum)+" ")]),_c('div',{staticClass:"text-3.5vh mb-2"},[_vm._v(_vm._s(_vm.queueTicketGroupMapped.callingTicket.time))]),_c('div',{staticClass:"text-2.6vh px-3 py-1 bg-[#4F4F4F] rounded-md",class:_vm.queueTicketGroupMapped.callingTicket.status === 'calling'
          ? 'calling-color-animation'
          : _vm.queueTicketGroupMapped.callingTicket.status === 'called'
          ? 'text-[#D04343]'
          : _vm.queueTicketGroupMapped.callingTicket.status === 'checked'
          ? 'text-[#4BC77F]'
          : '',style:([_vm.queueTicketGroupMapped.callingTicket.status === 'calling' ? { '--text-color': '#F5B800' } : ''])},[_vm._v(" "+_vm._s(_vm.$t("queueing." + _vm.queueTicketGroupMapped.callingTicket.status))+" ")])]):_c('div',{staticClass:"flex-grow flex flex-col justify-center items-center border-b-1 border-solid border-[#828282] p-4"},[_c('div',{staticClass:"text-4.3vh"},[_vm._v(" "+_vm._s(_vm.queueTicketGroupMapped.status === "checked" ? _vm.$t("queueing.clinic.currentNumber") : _vm.$t("queueing.currentTicket"))+" ")]),_c('div',{staticClass:"text-15vh font-barlow-bold",class:_vm.queueTicketGroupMapped.status === 'calling' || _vm.queueTicketGroupMapped.status === 'called'
          ? 'text-[#F5B800]'
          : _vm.queueTicketGroupMapped.status === 'checked'
          ? 'text-[#4BC77F]'
          : ''},[_vm._v(" "+_vm._s(_vm.queueTicketGroupMapped.ticketNum)+" ")]),_c('div',{staticClass:"text-3.5vh mb-2"},[_vm._v(_vm._s(_vm.queueTicketGroupMapped.time))]),_c('div',{staticClass:"text-2.6vh px-3 py-1 bg-[#4F4F4F]",class:_vm.queueTicketGroupMapped.status === 'calling'
          ? 'text-[#F5B800]'
          : _vm.queueTicketGroupMapped.status === 'called'
          ? 'text-[#D04343]'
          : _vm.queueTicketGroupMapped.status === 'checked'
          ? 'text-[#4BC77F]'
          : ''},[_vm._v(" "+_vm._s(_vm.$t("queueing." + _vm.queueTicketGroupMapped.status))+" ")])]),_c('div',{staticClass:"bg-[#2E2E2E] w-full d-flex flex-col mt-auto p-3 gap-3"},[_c('div',{staticClass:"px-6 py-5 bg-[#3D3D3D] text-2.6vh d-flex justify-center rounded-lg",class:_vm.disableButton || !_vm.queueTicketGroupMapped.nextTicket ? 'disabled' : '',attrs:{"role":"button"},on:{"click":function($event){_vm.disableButton ? '' : _vm.queueTicketGroupMapped.nextTicket ? _vm.$emit('updateQueueingTicket', 'next') : ''}}},[_vm._v(" "+_vm._s(_vm.queueTicketGroupMapped.callingTicket && _vm.queueTicketGroupMapped.callingTicket._id !== _vm.queueTicketGroupMapped._id ? _vm.$t("queueing.returnToCurrent") : _vm.$t("queueing.next"))+" ")]),_c('div',{staticClass:"px-6 py-4 bg-[#3D3D3D] text-2.6vh d-flex justify-center rounded-lg",class:_vm.disableButton ||
        (!_vm.queueTicketGroupMapped.callingTicket && _vm.queueTicketGroupMapped.status === 'checked') ||
        (_vm.queueTicketGroupMapped.callingTicket && _vm.queueTicketGroupMapped.callingTicket.status === 'checked') ||
        !_vm.queueTicketGroupMapped._id
          ? 'disabled'
          : '',attrs:{"role":"button"},on:{"click":function($event){_vm.disableButton
          ? ''
          : _vm.queueTicketGroupMapped.callingTicket
          ? _vm.$emit('callTicket', _vm.queueTicketGroupMapped.callingTicket._id)
          : _vm.queueTicketGroupMapped._id
          ? _vm.$emit('callTicket', _vm.queueTicketGroupMapped._id)
          : ''}}},[_vm._v(" "+_vm._s(_vm.$t("queueing.reCall"))+" ")]),_c('div',{staticClass:"px-6 py-4 bg-[#4BC77F] text-2.6vh d-flex justify-center rounded-lg",class:_vm.disableButton ||
        (!_vm.queueTicketGroupMapped.callingTicket && _vm.queueTicketGroupMapped.status === 'checked') ||
        (_vm.queueTicketGroupMapped.callingTicket && _vm.queueTicketGroupMapped.callingTicket.status === 'checked') ||
        !_vm.queueTicketGroupMapped._id
          ? 'disabled'
          : '',attrs:{"role":"button"},on:{"click":function($event){_vm.disableButton
          ? ''
          : _vm.queueTicketGroupMapped.callingTicket
          ? _vm.$emit('seat', _vm.queueTicketGroupMapped.callingTicket._id)
          : _vm.queueTicketGroupMapped._id
          ? _vm.$emit('seat', _vm.queueTicketGroupMapped._id)
          : ''}}},[_vm._v(" "+_vm._s(_vm.$t("queueing.checkIn"))+" ")])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }