
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { parseHash } from "~/plugins/table/kdsProduct";
import Dialog from "@feathers-client/mixins/Dialog";
import { ProductType } from "~/plugins/shop";

@Component
export default class TableKDSProductStockNum extends mixins(Dialog) {
  @Prop({ type: String, default: "make" })
  action: "make" | "adjust";

  @Prop()
  product: ProductType;

  @Prop()
  hash: string;

  @Prop({ type: Number, default: 0 })
  initQuantity: number;

  get currentStock() {
    return (this.product?.stock?.kdsStock?.filter?.(it => it.hash === this.hash) || []).reduce(
      (a, b) => a + b.quantity,
      0,
    );
  }

  get options() {
    return parseHash(this, this.hash).filter(it => it.values.length);
  }

  created() {
    this.quantity = this.initQuantity;
  }

  quantity = 0;
  quantityStr = "";

  async confirm() {
    try {
      this.quantity = +(this.quantityStr || this.quantity);
      if (isNaN(this.quantity)) {
        this.quantity = 0;
        return;
      }
      const quantity = this.action === "make" ? this.quantity : this.quantity - this.currentStock;
      await this.$feathers.service("products/kdsStocks/adjust").create({
        product: this.product._id,
        hash: this.hash,
        quantity,
        type: this.action,
      });
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
