
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType, checkID, getID } from "@feathers-client";
// @ts-ignore
import type VirtualDataList from "domore-table/VirtualDataList.vue";
// @ts-ignore
import draggable from "vuedraggable";
// @ts-ignore
import type { SearchConfig } from "@schemaEditor/plugin/defs";
import { getPathAdv } from "@feathers-client/util";

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function normalizeConfig(conf: string | SearchConfig, value: string) {
  const cfg: SearchConfig =
    typeof conf === "string"
      ? {
          field: conf,
          mode: "regexp",
        }
      : { mode: "regexp", ...conf };

  if (value) {
    if (cfg.field === "_id") {
      if (/^[a-f\d]{24}$/i.test(cfg.mongo)) cfg.mongo = value;
      else {
        cfg.mongo = null;
      }
    } else if (cfg.mode !== "none") {
      if (!cfg.mode) {
        cfg.mode = "regstart";
      }
      const regstr = `${cfg.mode.startsWith("regstart") ? "^" : ""}${
        cfg.mode.endsWith("lowercase")
          ? escapeRegExp(value).toLowerCase()
          : cfg.mode.endsWith("uppercase")
            ? escapeRegExp(value).toUpperCase()
            : escapeRegExp(value)
      }`;
      const regopt = cfg.mode.endsWith("case") ? "" : "i";

      cfg.regexp = regstr;
      cfg.regopt = regopt;
      const field = Array.isArray(cfg.field) ? cfg.field.filter(it => it !== "*").join(".") : cfg.field;
      cfg.mongoField = cfg.translate ? field + ".value" : field;
      cfg.mongo = {
        $regex: regstr,
        $options: regopt,
      };
    } else {
      cfg.regexp = null;
      cfg.regopt = null;
      cfg.mongo = null;
    }
  } else {
    cfg.regexp = null;
    cfg.regopt = null;
    cfg.mongo = null;
  }
  return cfg;
}

function reverseConfig(vue: Vue, conf: SearchConfig, value: string) {
  return conf.translate
    ? [
        {
          // @ts-ignore
          lang: vue.$schemas?.finalLocale ?? vue.$schemas?.locale ?? vue.$i18n?.locale ?? vue.$store.state.locale,
          value,
        },
      ]
    : value;
}

@Component({
  components: {
    draggable,
  },
})
export default class MenuListPicker extends Vue {
  @Prop()
  path: string;

  @Prop()
  enterkeyhint: string;

  @Prop()
  placeholder: string;

  /**
   * Display fields
   */
  @Prop({ type: Array, default: () => ["name"] })
  fields: (string | SearchConfig)[];

  /**
   * Searchable fields
   */
  @Prop({ type: Array, default: null })
  searchFields: (string | SearchConfig)[];

  @Prop({ type: Boolean, default: false })
  searchable: boolean;

  @Prop(Boolean)
  serverSearch: boolean;

  @Prop()
  rowIcon: string;

  @Prop({ default: "$tick" })
  activeIcon: string;

  @Prop()
  items: any[];

  @Prop(Boolean)
  combo: boolean;

  @Prop(Boolean)
  multiple: boolean;

  @Prop()
  query: any;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  ordered: boolean;

  @Prop({ default: "border-orange100" })
  activeClass: string;

  @Prop({ default: "border-transparent" })
  inactiveClass: string;

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop({ type: Boolean })
  returnObject: boolean;

  @Prop(Boolean)
  mandatory: boolean;

  @Prop(Boolean)
  stringOnly: boolean;

  @Prop()
  createNameField: string | SearchConfig;

  @Prop({ type: Boolean, default: true })
  border: boolean;

  @Prop({ default: "border-1" })
  borderStyle: string;

  @Prop({ default: "text-sm" })
  textStyle: string;

  @Prop({ type: Boolean, default: false })
  nowrap: boolean;

  @PropSync("onSelected")
  onSelectedSync: boolean;

  @Prop()
  scanIcon: string;

  @PropSync("scanning")
  scanningSync: boolean;

  @Prop()
  isSmallMobile: boolean;

  get createConfig() {
    // @ts-ignore
    return this.path && this.$schemas?.lookupRoute?.(this.path);
  }

  get canCreate() {
    if (!this.createNameField) return false;
    const cfg = this.createConfig;
    if (!cfg) return false;
    if (!cfg.create) return false;
    if (cfg.acl) {
      // @ts-ignore
      const acl = this.$schemas?.aclList?.[cfg.acl];
      return acl && acl(this, "create");
    }
    return true;
  }

  filterQuery: any = {};

  get group() {
    if (this.returnObject || !this.path) {
      return undefined;
    }
    return {
      name: "shared-" + this.path,
      pull: "clone",
    };
  }

  get finalQuery() {
    return {
      ...(this.query || {}),
      ...this.filterQuery,
    };
  }

  get keySet() {
    return this.multiple
      ? Object.fromEntries(
          Array.isArray(this.inputValueSync) ? this.inputValueSync.map((it, idx) => [getID(it), idx + 1]) : [],
        )
      : this.inputValueSync
        ? {
            [getID(this.inputValueSync)]: 1,
          }
        : {};
  }

  get normalizedFields() {
    return this.fields.map(f => normalizeConfig(f, this.searchKeyword));
  }

  get normalizedSearchFields() {
    return this.searchFields?.map?.(f => normalizeConfig(f, this.searchKeyword)) ?? [];
  }

  get cols() {
    return Math.max(1, (this.fields?.length ?? 0) + (this.searchFields?.length ? 1 : 0));
  }

  get colStyle() {
    return `flex-basis: ${100 / this.cols}%; word-break: break-word;`;
  }

  getter(item: any, field: SearchConfig) {
    const v = getPathAdv(item, field.field);
    if (field.multiple) {
      if (Array.isArray(v)) {
        return v
          .map(it => {
            if (field.translate) return this.$td(it);
            return it;
          })
          .join("/");
      } else return item;
    } else if (field.translate) return this.$td(v);
    return v;
  }

  removeItem(item: any) {
    if (this.multiple) {
      if (Array.isArray(this.inputValueSync)) {
        this.inputValueSync = this.inputValueSync.filter(it => !checkID(it, item));
      }
    } else {
      this.inputValueSync = null;
    }
  }

  chooseItem(item: any) {
    if (this.stringOnly) {
      if (this.multiple) {
        if (!item) return;
        this.inputValueSync = [...(Array.isArray(this.inputValueSync) ? this.inputValueSync : []), item];
      } else {
        this.inputValueSync = item || "";
      }
      return;
    }
    if (this.multiple) {
      if (item) {
        if (this.closeTimer) {
          clearTimeout(this.closeTimer);
          this.closeTimer = null;

          (this.$refs.input as any)?.focus?.();
        }
        let iv = Array.isArray(this.inputValueSync) ? this.inputValueSync : [];
        if (iv.find(it => checkID(it, item))) {
          this.inputValueSync = iv.filter(it => !checkID(it, item));
        } else {
          if (!Array.isArray(this.maybeValue)) {
            this.maybeValue = [];
          }
          if (!this.maybeValue.find(it => checkID(it, item))) {
            this.maybeValue.push(item);
          }
          this.inputValueSync = [...iv, this.returnObject ? item : getID(item)];
        }
      } else {
        this.searchKeyword = "";
        this.inputValueSync = [];
      }
    } else {
      if (item) {
        if (checkID(this.inputValueSync, item)) {
          this.inputValueSync = null;
          return;
        }
      }
      this.inputValueSync = this.returnObject ? item : getID(item);
      this.maybeValue = item;
      (this.$refs.input as any)?.blur?.();
      if (item && this.combo) {
        const valueField = this.normalizedFields.map(it => this.getter(item, it)).find(it => !!it);
        if (valueField) {
          this.searchKeyword = valueField;
        }
      } else {
        this.searchKeyword = "";
      }
    }
    this.$emit("update:cachedValue", item);
    if (!this.multiple) {
      this.$emit("closeDialog");
    }
  }

  @Prop()
  textValue: string;

  @PropSync("inputValue")
  inputValueSync: string | string[];

  beforeMount() {
    if (this.textValue) {
      this.searchKeyword = this.textValue;
    }
  }

  @Watch("textValue")
  onTextValue(v, ov) {
    if (v !== ov && v !== this.searchKeyword) {
      this.searchKeyword = v;
    }
  }

  @Watch("searchKeyword")
  onKeyword(v, ov) {
    if (v !== ov) {
      this.$emit("update:textValue", v);
    }
  }

  onCachedLoaded(item) {
    this.cachedValue = item;
    this.$emit("update:cachedValue", item);
    if (!this.multiple) {
      if (this.combo && this.hasCachedValue && !this.searchKeyword) {
        this.searchKeyword = this.cachedName;
      }
    }
  }

  get cachedItems() {
    const source =
      this.items || (Array.isArray(this.cachedValue) ? this.cachedValue : this.cachedValue ? [this.cachedValue] : []);
    const normalized = this.multiple
      ? Array.isArray(this.inputValueSync)
        ? this.inputValueSync
        : this.inputValueSync
          ? [this.inputValueSync]
          : []
      : this.inputValueSync
        ? [this.inputValueSync]
        : [];

    return normalized.map(item => {
      return source.find(it => checkID(item, it)) || item;
    });
  }

  set cachedItems(items: any[]) {
    const ids = items.map(it => getID(it));
    if (this.multiple) {
      this.inputValueSync = ids;
    } else {
      this.inputValueSync = ids[0] ?? null;
    }
  }

  get cachedItemsEditor() {
    const self = this;
    return {
      get items() {
        return self.cachedItems;
      },
      set items(v) {
        self.cachedItems = v;
      },
    };
  }

  get cachedName() {
    const iv = this.inputValueSync;
    const cv = this.cachedItems;
    if (cv.length) {
      return cv
        .map(cv => {
          return this.normalizedFields.map(it => this.getter(cv, it)).find(it => !!it) || cv._id;
        })
        .join(", ");
    }
    return Array.isArray(iv) ? iv.join(", ") : iv;
  }

  get cachedNames(): [string, string, any][] {
    const iv = this.inputValueSync;
    const cv = this.cachedItems;
    if (cv.length) {
      return cv.map(cv => {
        return [
          cv?._id ?? cv,
          (this.normalizedFields?.map(it => this.getter(cv, it))?.find(it => !!it) || cv._id) ?? cv,
          cv,
        ];
      });
    }
    return Array.isArray(iv) ? iv.map(it => [it, it, it]) : iv ? [[iv, iv, iv]] : [];
  }

  set cachedNames(v: [string, string, any][]) {
    if (this.multiple) {
      this.inputValueSync = Array.from(new Set(v.map(it => (this.returnObject ? it[2] : it[0]))));
    } else {
      this.inputValueSync = this.returnObject ? v[0]?.[2] : v[0]?.[0];
    }
  }

  currentPage = "";
  searchKeyword = "";
  maybeValue: any = null;
  cachedValue: any = null;

  get hasCachedValue() {
    return this.multiple ? Array.isArray(this.cachedValue) && !!this.cachedValue.length : !!this.cachedValue;
  }

  get displayKeyword() {
    return this.stringOnly ? this.searchKeyword : "";
  }

  dragging = false;
  mousedown = false;

  setSearch(v) {
    this.searchKeyword = v;
    this.queueSearch();
  }

  queueSearch() {
    if (this.stringOnly) return;
    if (this.combo) {
      this.inputValueSync = null;
    }
    this.goSearch();
  }

  goSearch() {
    if (this.serverSearch) {
      this.filterQuery = {
        $keyword: this.searchKeyword,
      };
    } else {
      const conds = this.normalizedFields
        .concat(this.normalizedSearchFields)
        .filter(it => !!it.mongo)
        .map(it => ({
          [it.mongoField || (Array.isArray(it.field) ? it.field.filter(it => it !== "*").join(".") : it.field)]:
            it.mongo,
        }));
      this.filterQuery = {
        ...(conds.length
          ? {
              $or: conds,
            }
          : {}),
      };
    }
  }

  closeTimer: any = null;

  onFocus() {
    if (this.readonly) return;
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
    if (!this.stringOnly) {
      this.mousedown = true;
      setTimeout(() => {
        this.mousedown = false;
      }, 100);
    }
  }

  onBlur() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
    this.closeTimer = setTimeout(() => {
      this.closeTimer = null;
    }, 100);
  }

  escape() {
    const el: HTMLElement = (this.$refs.input as any).$el;
    el?.blur?.();
  }

  @Ref()
  dataList: VirtualDataList;

  focusAction = 0;

  listLoaded(initLoad: boolean) {
    if (initLoad) {
      this.resetFocus();
    }
  }

  resetFocus() {
    if (
      (this.multiple && Array.isArray(this.inputValueSync) && this.inputValueSync.length === 1) ||
      (!this.multiple && this.inputValueSync)
    ) {
      const idx = this.dataList.items.findIndex(it => this.keySet[it._id]);
      if (idx !== -1) {
        this.focusAction = idx;
        return;
      }
    }
    this.focusAction = !this.dataList.items.length && this.searchKeyword && this.canCreate ? -1 : 0;
  }

  navigateFocus(offset: number) {
    let newFocus = this.focusAction + offset;
    if (newFocus < 0) {
      if (this.searchKeyword && this.canCreate) {
        if (newFocus === -2) {
          newFocus = this.dataList.items.length - 1;
        } else {
          newFocus = -1;
        }
      } else {
        newFocus = this.dataList.items.length - 1;
      }
    } else if (newFocus >= this.dataList.items.length) {
      newFocus = this.searchKeyword && this.canCreate ? -1 : 0;
    }
    this.focusAction = newFocus;
    this.dataList.scrollToIndex(this.focusAction);
  }

  async submit() {
    if (this.stringOnly) {
      this.chooseItem(this.searchKeyword);
      this.searchKeyword = "";
      return;
    }
    if (!this.dataList) return;
    if (this.focusAction === -1 && this.canCreate) {
      await this.createItem();
      return;
    }
    const item = this.dataList.items[this.focusAction];
    if (item) {
      if (this.keySet[item._id]) {
        return;
      }
      this.chooseItem(item);
      if (!this.combo) {
        this.searchKeyword = "";
      }
    } else if (this.combo) {
      this.inputValueSync = this.multiple ? [] : null;
    }
  }

  async createItem() {
    const name = normalizeConfig(this.createNameField, this.searchKeyword);
    (this.$refs.input as any)?.blur?.();
    // @ts-ignore
    const item = await this.$schemas.createAndEdit(this, this.path, {
      [name.field as any]: reverseConfig(this, name, this.searchKeyword),
    });
    if (!item) return;
    this.chooseItem(item);
  }

  onBackspace() {
    if (this.searchKeyword) return;
    if (this.multiple) {
      if (Array.isArray(this.inputValueSync)) {
        this.inputValueSync = this.inputValueSync.slice(0, -1);
      }
    } else {
      this.inputValueSync = null;
    }
  }

  clickOuter() {
    const el: HTMLElement = (this.$refs.input as any)?.$el;
    // this.$el?.scrollIntoView?.({ block: "nearest", inline: "center" });
    el?.focus?.();
  }

  onStart() {
    this.dragging = true;
  }

  onEnd(evt) {
    this.mousedown = false;
    if (this.dragging) {
      this.dragging = false;
      if (evt.from === evt.to) {
        this.clickOuter();
      }
    }
  }

  onMouseDown() {
    this.mousedown = true;
  }

  onMouseUp() {
    this.mousedown = false;
  }
}
