import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import errors from "@feathersjs/errors";
import _ from "lodash";

export async function create(
  hook: MHookContext<AdminApplication, CreateType<"payments/cancel">, FindType<"payments">>,
) {
  let payment = await hook.app.service("payments").get(hook.data.payment);

  if (payment.status === "cancelled" || payment.status === "refunded") {
    hook.result = payment;
    return;
  }

  if (payment.status !== "done") {
    throw new Error("Invalid status: " + payment.status);
  }

  payment = await hook.app.service("payments").patch(payment._id, {
    status: hook.data.status || "cancelled",
    metadata: {
      ...(payment.metadata || {}),
      ...(hook.data.metadata || {}),
    },
    ...(hook.data.status === "refunded" ? {
      refundTime: new Date(),
    } : {}),
  });

  if (payment.session) {
    await hook.app.service("tableSessions/pay").create({
      payment: getID(payment),
      session: getID(payment.session),
    });
  }

  hook.result = payment;
}
