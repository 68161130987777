
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";
import VueApexCharts from "vue-apexcharts";
import Percentage from "../Percentage.vue";
import { deepCopy } from "@firebase/util";
import _, { keyBy } from "lodash";
import moment from "moment";
import { start } from "repl";

type middleForm = {
  date: string, // YYYY-MM-DD
  value: number,
  hour: number[],
  values: number[],
}

type seriesConfig = {
  name: string,
  data: {
    x: string,  // YYYY-MM-DD or hour
    y: number | string
  }[]
}

type input = {
  [key: string]: {
    value: number,
    hour: number[],
    values: number[],
  }
}

interface ObjectFromDB {
  [date: string]: {
    value: number;
  };
}
@Component({
  components: {
    Percentage,
    VueApexCharts,
  },
})
export default class NewLineChart extends Vue {
  @Prop() openHour!: number;
  @Prop() closeHour!: number;
  @Prop() latestStartDate!: Date;
  @Prop() latestEndDate!: Date;
  @Prop() previousStartDate!: Date;
  @Prop() previousEndDate!: Date;
  @Prop() latestChartData!: input;
  @Prop() previousChartData!: input;
  @Prop({ type: String, required: false }) title!: string;
  @Prop({ type: String, required: false }) type!: string;
  @Prop({ type: String, required: false }) mode!: string;
  @Prop({ type: String, required: false }) latestAmount!: any;
  @Prop({ type: String, required: false }) previousAmount!: any;
  barColor = ["#FF9800", "#FBE6D0"];
  formatDataObject = [];
  formatPreviousDateObject = [];
  seriesData: any
  outPut = ``
  get newLineChartOption() {
    if (this.series) {
      let options = {
        series: this.series,
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          parentHeightOffset: 15,
          redrawOnParentResize: true,
        },
        colors: this.barColor,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: this.$vuetify.breakpoint.mdAndDown ? 2 : 3,
        },
        markers: {
          size: this.$vuetify.breakpoint.mdAndDown ? 3 : 5,
          colors: ["#fff"],
          strokeColors: this.barColor,
          strokeDashArray: 10,
          hover: {
            size: 7,
          },
        },
        grid: {
          borderColor: "#ccc",
          strokeDashArray: 2,
        },
        xaxis: {
          type: 'category', //this.mode == 'today' ? 'time' : 'time',
          labels: {
            datetimeUTC: false,
            format: this.mode == 'today' ? 'HH:mm' : 'MM-dd',
            trim: false,
            rotate: 0,
            minHeight: 40,
            hideOverlappingLabels: true,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          x: {
            show: false, format: 'MMM dd HH:mm',
          },
          y: {
            show: false,
            formatter: undefined,
          },
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const data = w.globals.initialSeries.map((x) => x.data[dataPointIndex]);
            const globals = w.globals;
            const latestData = this.type === "customer" ? `${data[0].y}${this.$t("overview.customerUnit")}` : this.$price(data[0].y);
            const previousData = this.type === "customer" ? `${data[1]?.y ?? ''}${this.$t("overview.customerUnit")}` : this.$price(data[1]?.y);
            return (
              `<div class='p-3 text-white bg-[rgba(51,51,51,0.8)]'>
                 <div>${this.$t(this.title)}</div>
                 <div> <span class='inline-block rounded-full w-3 h-3 bg-[#FF9800] '> </span> ${globals.seriesNames[0]}  ${latestData}</div>
                 <div> <span class='inline-block rounded-full w-3 h-3 bg-[#FBE6D0] '> </span> ${globals.seriesNames[1]}  ${previousData}</div>
              </div>`)
          }
        }
      };
      return options;
    }
  }

  get series() {
    if (!this.latestChartData && !this.latestChartData) {
      return []
    }

    let seriesData: seriesConfig[];

    let seriesDataAfterGen = this.genChartSeriesElements(this.latestChartData, this.previousChartData)
      // if(this.title==='overview.salesSummary') {
      //   console.log( 'seriesDataAfterGen', seriesDataAfterGen)
      // }
    if (seriesDataAfterGen) {
      // this.seriesData = this.seriesFormatting(seriesDataAfterGen)
      seriesData = this.seriesFormatting(seriesDataAfterGen)
      // if(this.title==='overview.salesSummary') {
      //   console.log( 'seriesData', seriesData)
      // }
    }
    if (seriesData) {
      // let seriesDataCopy = deepCopy(this.seriesData)
      // let series: seriesConfig[] = seriesDataCopy.map((data) => {
      let series: seriesConfig[] = seriesData.map((data) => {
        return {
          name: data.name,
          data: this.mode === 'today' ? this.fillMissingHour(data.data) : this.fillMissingDay(data.data)
        }
      })
      return series
    }
  }

  sum = 0;
  sumPrevious = 0
  get amountLatest() {
    if (!this.latestChartData || !Object.values(this.latestChartData).length) {
      return 0
    }
    return (_.sumBy(Object.values(this.latestChartData), (d) => Math.abs((d as any).value)))
  }
  get amountPrevious() {
    if (!this.previousChartData || !Object.values(this.previousChartData).length) {
      return 0
    }
    return (_.sumBy(Object.values(this.previousChartData), (d) => Math.abs((d as any).value)))
  }

  genChartSeriesElements(latestData: input, previousData: input): middleForm[][] {
    let latest = _.map((latestData), (v, k) => ({
      date: k,
      value: v.value,
      hour: v.hour,
      values: v.values
    }));
    let latest2 = this.checkAndAddDate(latest, this.latestStartDate, this.latestEndDate, this.mode);

    let previous = _.map((previousData), (v, k) => ({
      date: k,
      value: v.value,
      hour: v.hour,
      values: v.values
    }));

    let previous2 = this.checkAndAddDate(previous, this.previousStartDate, this.previousEndDate, this.mode);
    return [latest2, previous2];
  }

  checkAndAddDate(oldArray, startDate, endDate, mode, emptyValue: any = []): middleForm[] {
    let array = deepCopy(oldArray);
    if (array) {
      let shopOpen = this.openHour
      let shopClose = this.closeHour

      if (!array.length) {
        array.push({ date: moment(startDate).format("YYYY-MM-DD"), hour: [shopOpen, shopClose], values: [0, 0], value: 0 });
      } else {
        const firstDate = moment(array[0]?.date);
        const lastDate = moment(array[array.length - 1]?.date);
        // Check Start Hour
        if (moment(startDate).isSame(firstDate, "day")) {
          if (!array[0].hour.find(e => e == shopOpen)) {
            array[0].hour.unshift(shopOpen);
            array[0].values.unshift(0);
          }
        }
        // Check End Hour
        if (moment(endDate).isSame(lastDate, "day")) {
          if (!array[0].hour.find(e => e == shopClose)) {
            array[0].hour.push(shopClose);
            array[0].values.push(0);
          }
        }
      }
      const firstDate = moment(array[0]?.date);
      const lastDate = moment(array[array.length - 1]?.date);
      // Check Start Date
      if (!moment(startDate).isSame(firstDate, "day")) {
        const newEntry = {
          date: this.formatDate(startDate),
          hour: emptyValue,
          value: 0,
          values: [0, 0],
        };
        array.unshift(newEntry);
      }
      // Check End Date
      if (!moment(endDate).isSame(lastDate, "day")) {
        const newEntry = {
          date: this.formatDate(endDate),
          hour: emptyValue,
          value: 0,
          values: [0, 0],
        };
        array.push(newEntry);
      }
      return array
    }
  }

  fillMissingHour(data: seriesConfig['data']): seriesConfig['data'] { // series for day
    if(!data || !data.length) return [];

    // if (data && data.length) {
      data = data.filter(it => !!it)
      const newData: seriesConfig['data'] = [];
      let index = data.length - 1
      let start = moment(data[0].x) //moment(data[0].x).hour()
      let end = moment(data[index]?.x) //moment(data[index].x).hour()
      // todo: finish for loop condition
      // let start = start
      while (start.valueOf() <= end.valueOf()) { // 24 need to modify when confirm 
        // let found = false;
        // for (let i = 0; i < data.length; i++) {
        //   let date = moment(data[i].x);
        //   if (date.valueOf() === start.valueOf()) {
        //     newData.push({ x: date.format("HH:mm"), y: Math.round((data[i].y as number) * 100) / 100 });
        //     found = true;
        //     break;
        //   }
        // }
        // if (!found) {
        //   newData.push({
        //     x: moment(data[0].x).hours(moment(start).hour()).minute(0).format("HH:mm"),
        //     // y: defaultValue,
        //     y: 0
        //   });
        // }

        const found2 = data.find(el => moment(el.x).valueOf() === start.valueOf() )

        newData.push(found2 ? 
        {
          x: moment(found2.x).format("HH:mm"), 
          y: Math.round((found2.y as number) * 100) / 100 
        } : {
          x: moment(data[0].x).hours(moment(start).hour()).minute(0).format("HH:mm"),
          y: 0
        })

        start = start.add(1, 'hour')
      }
      return newData;
    // } else {
      // return []
    // }
  }

  fillMissingDay(data: seriesConfig['data']): seriesConfig['data'] { // series for week or month
    if(!data || !data.length) return [];
    // if (data && data.length) {
      const newData: seriesConfig['data'] = [];
      const dates = data.map((d) => d.x,);
      const startDate = moment(dates[0], 'MM-DD');
      const endDate = moment(dates[dates.length - 1], 'MM-DD');
      const currentDate = startDate;

      while (currentDate.diff(endDate, 'days') <= 0) {
        const dateString = currentDate.format('MM-DD');
        const dataIndex = dates.indexOf(dateString);
        if (dataIndex !== -1) {
          // Date already exists in the data array
          newData.push(data[dataIndex]);
        } else {
          // Date is missing, add a new data point with the default value
          newData.push({
            x: currentDate.format('MM-DD'),
            // y: defaultValue,
            y: 0
          });
        }
        currentDate.add(1, 'day');
      }
      return newData;
    // } 
    // else {
    //   return []
    // }
  }

  transformData(middleFormArr: middleForm[][]): seriesConfig[] {
    if (middleFormArr) {
      return [
        {
          name: this.mode == "week" ? this.$t("chartMessage.thisWeek") as string : this.$t("chartMessage.thisMonth") as string,
          data: middleFormArr[0]
            .filter((obj) => moment(obj.date).isBetween(this.latestStartDate, this.latestEndDate, null, "[]"))
            .map((obj) => ({ x: moment(obj.date).format("MM-DD"), y: (obj.value).toFixed(0) })),
        },
        {
          name: this.mode == "week" ? this.$t("chartMessage.lastWeek") as string : this.$t("chartMessage.lastMonth")as string,
          data: middleFormArr[1]
            .filter((obj) => moment(obj.date).isBetween(this.previousStartDate, this.previousEndDate, null, "[]"))
            .map((obj) => ({ x: moment(obj.date).format("MM-DD"), y: (obj.value).toFixed(0) })),
        },
      ];
    }
  };

  seriesFormatting(middleFormArrNest: middleForm[][]): seriesConfig[] {
    if (!middleFormArrNest) return
    if(['week', 'month'].includes(this.mode)) return this.transformData(middleFormArrNest)
    if (this.mode === 'today') {
      const shopOpenMin = 0 //moment(this.shop?.openTime, "HH:mm:ss").minute() || 0;
      const shopCloseMin = 0 //moment(this.shop?.closeTime, "HH:mm:ss").minute() || 0;

      let result: seriesConfig[] = []
      for (let middleFormArr of middleFormArrNest) {
        middleFormArr.map(({ date, hour, values }) => {
          let _shopOpen = moment(date).hour(this.openHour)
          let _shopClose = moment(_shopOpen).add(1, 'day').add(-1, 'minute')
          let j = _shopOpen
          const newData = hour.map((h, index) => {
            let currentDay = moment(date)
            if (h < this.openHour) {
              currentDay = currentDay.add(1, 'day')
            }
            while (j.valueOf() <= _shopClose.valueOf()) {
              j = j.add(1, 'hour')
              return {
                x: `${(moment(currentDay.format('YYYY-MM-DD')).hour(h).minute(shopOpenMin)).format('YYYY-MM-DD HH:mm')}`,
                y: Math.round(values[index] * 100) / 100
              };
            }
          });
          return result.push({ name: date, data: newData });
        });
      }
      return result
    }
    // else {
    //   // let a = this.transformData(array)
    //   // return a
    //   return this.transformData(array)
    // }
  }

  formatDate(date) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
  }

  // updated() {
  //   if(this.latestChartData && this.previousChartData && this.title==='overview.salesSummary') {
  //     console.log( 'latestChartData', this.latestChartData)
  //     console.log( 'previousChartData', this.previousChartData)
  //     console.log( 'series', this.series)
  //   }
  // }
}
