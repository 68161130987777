
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { checkID, getID, FindType } from "@feathers-client";

@Component
export default class OrderSystemStaff extends Vue {
  staff = false;

  async inputCode() {
    const staff = await this.$openDialog(
      import("~/components/dialogs/StaffCodeDialog.vue"),
      {},
      {
        maxWidth: "380px",
        persistent: true,
      },
    );

    // if (code) {
    //   const staff = (
    //     await this.$feathers.service("staffs").find({
    //       query: {
    //         code,
    //       },
    //     })
    //   ).data[0];
    //   if (!staff) {
    //     this.$store.commit("SET_ERROR", this.$t("scanner.invalidCode"));
    //     return;
    //   }
    //   this.setStaff(staff);
    // }
    if (staff === false) return;

    this.$shop.staffLoginTime = Date.now();
    this.$shop.staff = staff;
    this.staff = false;
  }

  async setStaff(staff: FindType<"staffs">) {
    if (staff.passCode) {
      if (
        !(await this.$openDialog(
          import("~/components/dialogs/StaffCodeDialog.vue"),
          {
            defaultStaff: staff,
          },
          {
            maxWidth: "400px",
          },
        ))
      ) {
        return;
      }
    }

    this.$shop.staffLoginTime = Date.now();
    this.$shop.staff = staff;
    this.staff = false;
  }
}
