
import { Component, Prop, PropSync, Vue } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import _ from "lodash";
@Component({})
export default class MobileSessions extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  filteringByTable;

  @Prop()
  filterSessionByTable;

  @Prop()
  filterSessionAll;

  @Prop()
  selectedTable;

  @PropSync("activeSessionsTable")
  activeSessionsTableSync: Boolean;

  async mounted() {
    const session = await this.$feathers.service("tableSessions").find({
      query: this.filteringByTable ? this.filterSessionByTable : this.filterSessionAll,
    });
  }

  kdsStatus(products: [] | {}) {
    if (_.some(products, ["kdsStatus" || "status", "cancel"])) return "cancel";
    if (_.some(products, ["kdsStatus" || "status", "hold"])) return "hold";
    else if (_.some(products, ["kdsStatus", "pending"])) return "pending";
    else if (_.every(products, ["kdsStatus", "partialDone"])) return "done";
    else if (_.every(products, ["kdsStatus", "done"])) return "done";
    else return null;
  }

  kdsStatusColor(products: [] | {}) {
    if (_.some(products, ["kdsStatus" || "status", "cancel"])) return "bg-red200";
    if (_.some(products, ["kdsStatus" || "status", "hold"])) return "bg-red400";
    else if (_.some(products, ["kdsStatus", "pending"])) return "bg-blue000";
    else if (_.every(products, ["kdsStatus", "partialDone"])) return "bg-purple400";
    else if (_.every(products, ["kdsStatus", "done"])) return "bg-purple400";
    else return null;
  }

  billStatus(item: TableSession) {
    let status = item.status.toString();
    if (this.$shop?.shopData?.kdsEnabled) {
      if (item.products.length > 0) {
        if (item.products.every(product => product.kdsStatus === "done")) {
          if (item.status !== "done" && item.status !== "toPay") {
            status = "productDone";
          }
        } else {
          status = "ordered";
        }
      }
    } else {
      if (item.status == "ongoing" && item.products.some(product => product.kdsStatus == "pending")) {
        status = "ordered";
      }
    }
    return status;
  }

  billColor(item: TableSession) {
    switch (this.billStatus(item)) {
      case "ordered":
        return "#2D9CDB";
      case "productDone":
        return "#7369DE";
      case "booking":
        return "#828282";
      case "ongoing":
        return "#DF6D6D";
      case "toPay":
        return "#27AE60";
      case "done":
        return "#27AE60";
      case "cancelled":
        return "#828282";
      case "void":
        return "#828282";
    }
  }

  orderStatusColor(item) {
    switch (item.status) {
      case "toPay":
        return "bg-orange200";
      case "done":
        return "bg-green400";
      case "cancelled":
      case "void":
        return "bg-red400";
      default:
        return "bg-grey400";
    }
  }
}
