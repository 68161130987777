
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import type { Screen } from "pos-printer/multiscreen";

@Component
export default class OrderSystem extends Vue {
  @VModel()
  inputValue: boolean;

  @Prop()
  session: TableSession;

  async mounted() {
    this.updateScreen();
    this.$shop.$on("updateScreen", this.updateScreen);
    this.$shop.connectScreen();
  }

  screen: Screen;

  disconnectScreen() {
    if (this.screen) {
      this.screen.removeListener("loaded", this.updateScreenContent);
      try {
        this.screen.queue.ns("posScreen").call("setCart", []);
      } catch (e) {
        console.error(e);
      }
      this.screen = null;
    }
  }

  async updateScreen() {
    this.disconnectScreen();
    this.screen = this.$shop.secondScreen;
    if (this.screen) {
      this.screen.on("loaded", this.updateScreenContent);
      this.screen.queue.register("orderSystem", {
        printInvoice: this.printInvoice,
      });
      if (this.screen.loaded) {
        this.updateScreenContent();
      }
    }
  }

  async printInvoice() {
    if (!this.session) return;
    await this.session.printOrder({
      user: true,
    });
  }

  updateScreenContent() {
    this.session?.syncOrder?.();
    this.updateBanners().catch(e => console.warn(e));
  }

  async beforeDestroy() {
    this.disconnectScreen();
  }

  async updateBanners() {
    const allBanners = await this.$feathers.service("banners").find({
      query: {
        type: "pos",
        status: "published",
        $paginate: false,
      },
      paginate: false,
    });
    const banners = _.flatMap(allBanners, banner => banner.pictures.map(jt => this.$image(jt)));
    await this.$shop.secondScreen.queue.ns("posScreen").call("setBanners", banners);
  }

  @Watch("session")
  @Watch("inputValue")
  async onSessionChanged() {
    if (!this.inputValue) {
      await Vue.nextTick();
      if (this.screen) {
        await this.screen.queue.ns("posScreen").call("setCart", {});
      }
      if (this.$tableManager.posScreenHandler) {
        await this.$tableManager.posScreenHandler.syncPosScreen();
      }
      return;
    }
    this.session?.syncOrder?.();
  }
}
