import _, { reject } from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import { PaymentMethodType } from "~/plugins/shop";
import AllinpayPOS from "~/mixins/allinpay";
import { openCashBox, wrapJob } from "~/plugins/printer/invoiceSequencer";
import moment from "moment";
import { checkTwCenterNo, normalizedDay } from "@common/table/util";
import Cash from "./paymentMethods/cash.vue";
import Point from "./paymentMethods/point.vue";
import Octopus from "pos-printer/octopus/OctopusOptions.vue";
import Supacity from "./paymentMethods/supacity.vue";
import TwTaxCreditCardInstallment from "./paymentMethods/twTaxCreditCardInstallment.vue";
import { supported as myPaySupported, callMyPay } from "pos-printer/vendor/mypay";
import {
  OctopusCommandRespTransactionError,
  OctopusCommandRespTransactionSuccess,
  OctopusCommandTransaction,
} from "pos-printer/octopus/lib";
import {
  CupSaleResponse,
  EDCSaleResponse,
  FetchResponseType,
  HASEError,
  HasePayType,
  OctopusResponse,
  PayResponseType,
  QRResponse,
  transactionStatus,
} from "pos-printer/hase";
import { encodeBase32 } from "pos-printer/payments/utils";
import { ResponseCode, TurnCloudError, TurnCloudMessage, TurnCloudConnectionError } from "pos-printer/turncloud";
import {
  RazerPayConnectionError,
  RazerPayError,
  RazerPayHeader,
  RazerPayMessage,
  RazerPaymentMethod,
} from "pos-printer/razerpay";

const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
import basex from "base-x";
import { SimplePaymentIntegrationTransaction } from "pos-printer/simplePaymentIntegration";
import SimplePaymentIntegrationOptions from "pos-printer/simplePaymentIntegration/SimplePaymentIntegrationOptions.vue";

import { LangArrType } from "@feathers-client/i18n";
import {
  CloudPayload,
  PaymentError,
  PaymentLike,
  PaymentMethodBase,
  PaymentOptions,
} from "pos-printer/payments/methodBase";
import { ScannerEvent } from "pos-printer/scanner";
import { PrintJob } from "pos-printer/printJob";
import "pos-printer/scanner";

const bs62 = basex(BASE62);

type PaymentType = FindType<"payments">;

export interface PaymentMethodFull {
  name: string;
  method: FindType<"paymentMethods">;
  props?: any;
  _id: any;
  icon?: string;

  tag?: string;
  type?: string;
  subType?: string;
  image?: any;
}

@Component
export default class OrderSystemCheckoutBase extends mixins(AllinpayPOS) {
  session!: TableSession;

  loading = false;
  cancelling = false;

  currentPayment: FindType<"payments"> = null;
  paymentPromise: Promise<boolean> = null;
  paymentPromiseCancel: AbortController = null;
  currentPaymentCancellable = false;
  paymentImage: string = null;

  pointValid = false;
  paymentDialog = false;

  editingMethod = false;
  paymentStaff: FindType<"staffs"> = null;
  get showDialog() {
    return this.loading || this.cancelling || !!this.errorMessage || this.status === "recovery";
  }

  status:
    | "pending"
    | "paying"
    | "retrying"
    | "restoring"
    | "error"
    | "errorConnect"
    | "done"
    | "recovery"
    | "scanning" = "pending";
  currentPendingPayments: FindType<"payments">[] = [];

  created() {
    this.$scanner.registerHandler(this.onScannerCheckout, 10);
  }

  // @ts-ignore
  async beforeDestroy(): Promise<void> | void {
    this.$scanner.unregisterHandler(this.onScannerCheckout);

    if (this.session) {
      this.session.showingPendingPayment = false;
      this.session.showingCheckout = null;
      this.session.paymentImage = null;
      this.session.tempPaymentSurcharge = 0;
    }
    this.updateStatusChange();

    if (this.currentPayment) {
      try {
        const payment = await this.$feathers.service("payments").get(this.currentPayment._id);
        if (payment.status === "pending") {
          await this.cancelCurrentPayment();
        }
      } catch (e) {
        console.warn(e);
      }
      this.currentPayment = null;
    }
  }

  scannerTask: { resolve: (value: string) => void; reject: (reason?: any) => void } = null;
  showManualScanner = false;

  async scanCode(): Promise<string> {
    if (this.status === "scanning") {
      throw new Error("Already scanning");
    }
    const cancellable = this.currentPaymentCancellable;
    this.currentPaymentCancellable = true;
    const status = this.status;
    this.status = "scanning";
    this.showManualScanner = false;
    try {
      return await new Promise<string>((resolve, reject) => {
        this.scannerTask = { resolve, reject };
      });
    } finally {
      if (this.status === "scanning") {
        this.status = status;
      }
      this.currentPaymentCancellable = cancellable;
      this.scannerTask = null;
      this.showManualScanner = false;
    }
  }

  onScannerCheckout(item: ScannerEvent) {
    if (item.handled || !item.code) return;
    if (this.scannerTask) {
      this.scannerTask.resolve(item.code);
      item.handled = true;
      item.delay = 2000;
    }
    if (!this.loading && this.$features.turnCloud) {
      if (/^\/[0-9A-Z\.\-\+]{7}$/.test(item.code)) {
        item.handled = true;
        this.session
          .atomic({
            twTaxType: "electronic",
            twTaxVehicle: item.code,
          })
          .catch(console.error);
      } else if (/^[A-Z]{2}[0-9]{14}$/.test(item.code)) {
        item.handled = true;
        this.session
          .atomic({
            twTaxType: "electronic",
            twTaxVehicle: item.code,
          })
          .catch(console.error);
      } else if (/^[a-zA-Z0-9]{10}$/.test(item.code) && this.session.sessionData.twTaxType === "nrt") {
        item.handled = true;
        this.session
          .atomic({
            twTaxNrt: item.code,
          })
          .catch(console.error);
      }
    }
  }

  get paymentMethodFull() {
    return this.session?.paymentMethodFull;
  }
  set paymentMethodFull(v) {
    if (!this.session) return;
    if (v) {
      (async () => {
        await this.$feathers.service("actionLogs").create({
          session: getID(this.session.item._id),
          view: getID(this.session.view),
          staff: this.$shop.staffId,
          type: "paymentManage/tableSessionSelectPayment",
          detail: {
            paymentMethod: v.method,
          },
        });
      })();
    }

    this.session.paymentMethodFull = v;
    this.session.paymentMethodArgs = {};
  }

  get paymentMethodArgs() {
    return this.session.paymentMethodArgs;
  }

  get paymentMethod() {
    return this.paymentMethodFull?.method;
  }

  get paymentMethodsFlatten() {
    const self = this;
    return this.$shop.paymentMethods.flatMap(method => {
      switch (method.type) {
        case "mypay": {
          return (method.props?.mypaySubTypes ?? []).map(subType => ({
            method,
            get name() {
              return [(self.$td(method.displayName) || self.$td(method.name) || "").trim(), self.$t("mypay." + subType)]
                .filter(it => !!it)
                .join(" ");
            },
            _id: `${method._id}_${subType}`,
            icon: subType,
            props: {
              method: subType,
            },
            subType: subType,
            tag: subType,
            image: method.image,
          }));
        }
        case "allinpay": {
          return (method.props?.allinpaySubTypes ?? []).map(subType => ({
            method,
            get name() {
              return [(self.$td(method.displayName) || self.$td(method.name) || "").trim(), self.$t("mypay." + subType)]
                .filter(it => !!it)
                .join(" ");
            },
            _id: `${method._id}_${subType}`,
            icon: subType,
            props: {
              method: subType,
              currency: method.props.currency,
            },
            subType: subType,
            image: method.image,
          }));
        }
        case "hase": {
          return (method.props?.haseSubtypes ?? []).map(subType => ({
            method,
            get name() {
              return [(self.$td(method.displayName) || self.$td(method.name) || "").trim(), self.$t("hase." + subType)]
                .filter(it => !!it)
                .join(" ");
            },
            _id: `${method._id}_${subType}`,
            icon: subType,
            props: {
              method: subType,
              currency: method.props.currency,
            },
            subType: subType,
            image: method.image,
          }));
        }

        case "turnCloud": {
          return (method.props?.turnCloudSubTypes ?? []).map(subType => ({
            method,
            get name() {
              return [
                (self.$td(method.displayName) || self.$td(method.name) || "").trim(),
                self.$t("turncloud." + subType),
              ]
                .filter(it => !!it)
                .join(" ");
            },
            _id: `${method._id}_${subType}`,
            icon: subType,
            props: {
              method: subType,
            },
            subType: subType,
            image: method.image,
          }));
        }
        case "razer": {
          return (method.props?.razerSubTypes ?? []).map(subType => ({
            method,
            get name() {
              return [(self.$td(method.displayName) || self.$td(method.name) || "").trim(), self.$t("razer." + subType)]
                .filter(it => !!it)
                .join(" ");
            },
            _id: `${method._id}_${subType}`,
            icon: subType,
            props: {
              method: subType,
            },
            subType: subType,
            image: method.image,
          }));
        }
        case "razerOnline": {
          const subType = "KBANK_THQR_Payment";
          return [
            {
              method,
              get name() {
                return self.$td(method.displayName) || self.$td(method.name);
              },
              _id: `${method._id}_${subType}`,
              icon: subType,
              props: {
                method: subType,
              },
              subType: subType,
              image: method.image,
            },
          ];
        }

        default: {
          const manager = this.$paymentManager.getMethod(method.type);
          if (manager) {
            const subMethods = manager.subMethodsForMethod(method);
            if (subMethods) {
              return subMethods.map(subMethod => {
                const finalMethod = (subMethod.method as typeof method) ?? method;
                const methodInfo = method?.subMethodInfos?.find(it => it.subMethod === subMethod.subType);

                return {
                  method: finalMethod,
                  name: subMethod.name,
                  _id: `${finalMethod._id}_${subMethod.subType}`,
                  icon: subMethod.icon ?? subMethod.subType,
                  props: subMethod.props,
                  subType: subMethod.subType,
                  image: finalMethod.image,

                  ...(methodInfo?.image ? { image: methodInfo.image } : {}),
                  ...(methodInfo?.name && methodInfo.name.find(it => it.value) ? { name: methodInfo.name } : {}),
                  ...(methodInfo?.order ? { order: methodInfo.order } : {}),
                };
              });
            }
          }

          break;
        }
      }

      return [
        {
          method,
          get name() {
            return self.$td(method.displayName) || self.$td(method.name);
          },
          _id: method._id,
          icon: method.icon,
        },
      ];
    }) as PaymentMethodFull[];
  }

  getIconClass(method: PaymentMethodFull) {
    const icon = method?.method?.type.toUpperCase();
    switch (icon) {
      case "CASH":
      case "OCTOPUS":
      case "POINT":
      case "WECHAT":
      case "ALIPAY":
      case "QUICKPASS":
        return `$payment_${icon}`;
      case "CREDIT":
      case "CREDITCARD":
        return "$payment_CREDITCARD";
      case "BBPOS":
      case "ALLINPAY":
        return "$payment_EPAYMENT";
      default:
        return "$payment_OTHERS";
    }
  }

  @Ref()
  paymentDetail: any;

  remarks = "";
  isPartial = false;
  // partialPayment = 0;
  // isSplit = false;
  // splitPayment = 0;
  viewPayment: any = null;

  payTime: string = null;
  paymentDateType: string = "now";

  get payTimeOverride() {
    if (this.needSelectDate) {
      if (this.paymentDateType === null && this.dateValid) {
        return moment(this.payTime, "YYYY-MM-DDTHH:mm").toDate();
      }
      if (this.paymentDateType === "before") {
        return this.session.paidTime;
      }
    }
    return undefined;
  }

  get dateValid() {
    return moment(this.payTime, "YYYY-MM-DDTHH:mm").isValid();
  }

  get needSelectDate() {
    if (this.session?.paidTime) {
      const previousDay = normalizedDay(this.$shop.shopData as any, this.session.startTime || this.session.paidTime);
      const nowDay = normalizedDay(this.$shop.shopData as any, new Date());
      if (+previousDay !== +nowDay) {
        return true;
      }
    }
    return false;
  }

  get dateItems() {
    return [
      {
        _id: "before",
        name: this.$moment(this.session.paidTime).format("lll"),
      },
      {
        _id: "now",
        name: this.$t("tableView.now"),
      },
    ];
  }

  get isCustomDate() {
    return !this.paymentDateType;
  }

  useCustomTime() {
    this.paymentDateType = null;
    this.payTime = moment().format("YYYY-MM-DDTHH:mm");
  }

  @Watch("session.payments")
  onPayments() {
    this.viewPayment = this.session?.payments?.at?.(-1) ?? null;
  }

  reset() {
    this.isPartial = false;
    // this.isSplit = false;
    this.session.partialPayment = 0;
    // this.session.splitPayment = 0;
    this.session.payingSplitBill = null;
    this.remarks = "";
    this.statusMessage = null;
    this.manualConfirming = null;
    this.session.paymentMethodArgs = {};
  }

  @Watch("isPartial")
  onIsPartial() {
    if (!this.isPartial) {
      this.session.partialPayment = 0;
    }
    // console.log(this.isPartial);
  }

  // @Watch("isSplit")
  // onIsSplit() {
  //   if (!this.isSplit) {
  //     this.splitPayment = 0;
  //   }
  // }

  get amount() {
    return +(this.session.splitPayment || this.session.partialPayment || this.session.outstanding);
  }

  get isCash() {
    switch (this.paymentMethod?.type) {
      case "cash":
        return true;
      case "mypay":
        return this.paymentMethodFull?.props?.method === "CASH";
      case "turnCloud":
        return this.paymentMethodFull?.props?.method === "cash";
    }
    return false;
  }

  get paymentComponent() {
    switch (this.paymentMethod?.type) {
      case "cash":
        return Cash;
      case "point":
        return Point;
      case "supacity":
        return Supacity;
      case "mypay":
        if (this.paymentMethodFull?.props?.method === "CASH") {
          return Cash;
        }
        break;
      case "turnCloud":
        if (this.paymentMethodFull?.props?.method === "cash") {
          return Cash;
        }
        if (this.paymentMethodFull?.props?.method === "creditI") {
          return TwTaxCreditCardInstallment;
        }
        break;
      case "simplePaymentIntegration":
        return SimplePaymentIntegrationOptions;
      case "octopus":
        return Octopus;
    }
  }

  get canCheckout() {
    if (!this.session) return false;
    if (!this.$shop.hasStaffRole("pos/checkout")) return false;
    if (this.session.testing) return true;
    return true;
  }

  get canPay() {
    if (!this.session) return false;
    if (!this.$shop.hasStaffRole("pos/checkout")) return false;
    if (this.session.testing) return true;
    if (!this.session.orderComfirmed) return false;
    return (
      this.paymentMethod &&
      (!this.paymentComponent || this.session.paymentDetailValid) &&
      this.amount <= this.session.outstanding &&
      (this.paymentMethod.type !== "point" || this.pointValid)
    );
  }

  async initCheckout(useDollarId: string = null, autoPickFirstPayment = null) {
    if (!this.session) return;
    this.resetError();
    if (this.needSelectDate) {
      this.paymentDateType = "before";
    }
    await this.reloadPayment(null, this.session.item._id);
    if (useDollarId) {
      this.paymentMethodFull = this.paymentMethodsFlatten.find(p => p?.method?.props?.point === useDollarId);
    } else if (autoPickFirstPayment ?? this.$shop.localOptions.autoPickFirstPayment ?? true) {
      this.editingMethod = false;
      this.paymentMethodFull = this.paymentMethodsFlatten[0];
    } else {
      this.editingMethod = true;
      this.paymentMethodFull = null;
    }
    this.viewPayment = this.session?.payments?.at?.(-1) ?? null;
    this.session.showingPendingPayment = this.$shop.localOptions.autoShowPendingPayment;
    this.session.syncOrder();
  }

  @Watch("session.item._id")
  async reloadPayment(v, ov) {
    if (v === ov) return;
    if (!this.session?.item) return;
    await this.session.restoreCoupons();
    if (this.currentPayment) {
      try {
        await this.cancelCurrentPayment();
      } catch (e) {
        console.warn(e);
      }
      this.currentPayment = null;
    }
    const pendingPayments = this.session.item._id
      ? await this.$feathers.service("payments").find({
          query: {
            session: this.session.item._id,
            status: "pending",
            device: getID(this.$shop.device),
            $paginate: false,
          },
          paginate: false,
        })
      : [];
    this.currentPendingPayments = pendingPayments;
    if (pendingPayments.length) {
      this.status = "recovery";
    }
  }

  async tryPay() {
    const staff = await this.$shop.checkPermission(["paymentManage/tableSessionCheckout"]);
    if (staff === false) return;

    if (!(await this.checkTaxStatus())) {
      return;
    }

    this.paymentPromiseCancel = new AbortController();
    this.paymentPromise = this.tryPayInner(this.paymentPromiseCancel.signal, staff);
    this.paymentStaff = staff;
    return this.paymentPromise;
  }

  paySession = 0;

  async tryPayInner(controller: AbortSignal, staff: FindType<"staffs">) {
    const amount = this.amount;
    const method = this.paymentMethod;
    const methodFull = this.paymentMethodFull;
    this.status = "pending";
    this.loading = true;

    const paySession = ++this.paySession;

    if (isNaN(amount)) {
      throw new Error("Invalid amount");
    }
    this.currentPaymentCancellable = true;

    try {
      await this.session._savingTask;
    } catch (e) {
      console.warn(e);
    }

    await this.session.reload();
    await this.session.restoreCoupons();
    await this.session.atomic(this.session.cachedPriceDetails);
    this.session.syncOrder();
    try {
      if (this.session.status === "done" || this.session.status === "test") {
        return true;
      }
      const cancelResp = await this.cancelCurrentPaymentInner(controller);
      if (cancelResp) {
        return cancelResp === "cancel" ? false : true;
      }
      // @ts-ignore
      if (this.session.status === "done" || this.session.status === "test") {
        return true;
      }
      if (
        amount !== this.amount ||
        (!this.session.splitPayment && !this.session.partialPayment && this.amount !== this.session.outstanding)
      ) {
        throw new Error("Amount changed, please retry");
      }

      if (this.session.testing) {
        await this.session.atomic({
          status: "test",
        });
        await this.finishOrder();
        return (this.session as any).status === "test";
      }

      this.status = "paying";
      let payment: FindType<"payments">;

      if (controller.aborted) throw new Error("Aborted");
      this.currentPaymentCancellable = false;
      switch (method.type) {
        case "cash": {
          const received = (await this.paymentDetail?.apply?.()) ?? null;
          if (received === null) {
            throw new Error("Invalid received");
          }
          if (received < this.amount) {
            throw new Error("Invalid received, please retry");
          }
          payment = await this.createPayment(methodFull, {
            receiveAmount: received,
          });
          break;
        }
        case "entFee":
        case "manual": {
          payment = await this.createPayment(methodFull);
          break;
        }

        case "supacity": {
          // TODO: implement cancel
          payment = await this.createPayment(methodFull, null, true, "payments/supacity/intent", {
            userToken: this.session.supacityInfo.userToken,
          });
          await this.waitSupacityPayment(payment);
          break;
        }

        case "mypay": {
          // TODO: implement cancel
          const methodSubType = methodFull.props?.method ?? method.props?.method;
          const local = (await myPaySupported()) && methodSubType !== "CASH";

          let received: number;
          if (methodSubType === "CASH") {
            received = this.paymentDetail?.apply?.() ?? null;
            if (received === null) {
              throw new Error("Invalid received");
            }
            if (received < this.amount) {
              throw new Error("Invalid received, please retry");
            }
          }

          payment = await this.createPayment(
            methodFull,
            {
              receiveAmount: received,
              device_name: this.$shop.localOptions.mypay,
            },
            true,
            "payments/mypay/intent",
            {
              methodSubType: methodFull.props?.method ?? method.props?.method,
              received,
              local,
            },
          );
          if (local && (payment as any).app) {
            await callMyPay((payment as any).app);
          }
          await this.waitMypayPayment(payment);
          break;
        }

        case "adyenPos": {
          // TODO: implement cancel
          payment = await this.createPayment(methodFull, {}, true, "payments/adyen/intent", {
            methodSubType: methodFull.props?.method ?? method.props?.method,
          });
          await this.waitAdyenPayment(payment);
          break;
        }

        case "allinpay": {
          const currency = methodFull.props?.currency || this.$shop.currency || "HKD";
          payment = await this.createPayment(methodFull, null, true, undefined, {
            currency,
          });

          try {
            this.currentPaymentCancellable = true;
            const resp: any = await Promise.race([
              this.callAllinpayPOS({
                BUSINESS_ID:
                  payment.methodSubType === "CREDIT"
                    ? "100100001"
                    : payment.methodSubType === "SCAN"
                      ? "100300001"
                      : "100300002",
                AMOUNT: Math.round(payment.amount * 100)
                  .toString()
                  .padStart(12, "0"),
                TRANS_TRACE_NO: String(payment._id),
                TRANS_ORDER_NO: String(this.session._id),
                CURRENCY: currency,
              }),
              new Promise((resolve, reject) => controller.addEventListener("abort", reject, { once: true })),
            ]);
            payment = await this.confirmAllinpayPayment(payment, resp);
          } catch (e) {
            if (e.REJCODE === "1FF") {
              payment = await this.$feathers.service("payments").patch(payment._id, {
                status: "cancelled",
                cancelReason: "Cancelled by terminal",
              });
              this.currentPayment = null;
            }
            throw e;
          }
          break;
        }

        case "octopus": {
          if (!this.$paymentManager.octopus) {
            throw new Error(String(this.$t("octopus.notSetup")));
          }
          if (this.session.outstanding !== this.amount) {
            throw new Error(String(this.$t("octopus.needToPayFullAmount")));
          }
          this.currentPaymentCancellable = true;
          await this.$paymentManager.octopus.waitReady(true, this.$paymentManager.octopus.useNative ? 10000 : 30000);
          payment = await this.createPayment(
            methodFull,
            {
              deviceId: this.$paymentManager.octopus.version.devId,
              locId: this.$paymentManager.octopus.version.locId,
            },
            true,
            "payments/octopus",
          );

          payment = await this.doOctopusPayment(payment, controller);

          break;
        }

        case "point": {
          const received = this.paymentDetail?.apply?.() ?? null;
          if (received === null) {
            throw new Error("Invalid received");
          }
          payment = await this.createPayment(
            methodFull,
            {
              usePoint: received,
            },
            true,
            "payments/point/pay",
            {
              usePointRatioInt: methodFull.method.props?.usePointRatioInt,
              point: methodFull.method.props?.point,
            },
          );

          break;
        }

        case "hase": {
          const hase = this.$paymentManager.checkHase();
          await hase.checkHASESetuped();
          const methodSubType = methodFull.props?.method ?? method.props?.method;

          payment = await this.createPayment(methodFull, { methodSubType }, true, undefined, {
            methodSubType,
          });

          try {
            const tips = Math.max(0, Math.round(payment.tips * 100) / 100);
            const amountWithoutTips = Math.round((payment.amount - tips) * 100) / 100;

            const response = await hase.pay({
              amount: amountWithoutTips,
              tips,
              type: methodSubType as any,
              reference: encodeBase32(Buffer.from(String(payment._id), "hex")),
            });

            if (response?.responseCode === "00") {
              payment = await this.confirmHasePayment(payment, response);
            } else {
              if (response?.responseCode === "CN" || response?.responseCode === "OT") {
                payment = await this.$feathers.service("payments").patch(payment._id, {
                  status: "cancelled",
                  cancelReason: "Cancelled by terminal",
                  paymentId: response?.traceNumber,
                  metadata: {
                    ...payment.metadata,
                    ...response,
                  },
                });
                this.currentPayment = null;
              }
              throw new Error(
                `Payment Error: ${
                  response?.responseCode && transactionStatus[response?.responseCode]
                    ? transactionStatus[response?.responseCode]
                    : response?.responseText ?? "Unknown Error"
                }`,
              );
            }
          } catch (e) {
            if (e instanceof HASEError) {
              if (!e.responseProcessed) {
                payment = await this.$feathers.service("payments").patch(payment._id, {
                  status: "cancelled",
                  errorMessage: e.message,
                });
                this.currentPayment = null;
              }
            }
            throw e;
          }

          break;
        }

        case "turnCloud": {
          if (!this.$paymentManager.turnCloud) {
            throw new Error(String(this.$t("turnCloud.notSetup")));
          }
          payment = await this.createPayment(methodFull, {}, true, "payments");
          const payload: TurnCloudMessage = {
            payType: payment.methodSubType as any,
            actionType: "sale",
            orderNo: String(payment._id),
            transAmount: payment.amount,
          };

          if (methodFull.method.props?.turnCloudTaxEnabled) {
            payload.taxType = "taxable";
            payload.taxRate = this.session.taxes.find(it => !it.disabled)?.percent ?? 0;
            payload.taxAmount = this.session.taxes.find(it => !it.disabled)?.amount ?? 0;
            payload.salesAmount = payment.amount - payload.taxAmount;
          }

          if (payment.methodSubType === "creditI") {
            payload.period = this.session.sessionData.twTaxPeriod;
          }

          if (payment.methodSubType === "qrCode") {
            payload.transMethod = "reverse";
          }

          payment = await this.$feathers.service("payments").patch(payment._id, {
            metadata: payload,
          });

          payment = await this.doTurnCloudPayment(payment);
          break;
        }

        case "razer": {
          if (!this.$paymentManager.razer) {
            throw new Error(String(this.$t("razer.notSetup")));
          }
          payment = await this.createPayment(methodFull, {}, true, "payments");
          const header = new RazerPayHeader();
          header.transactionCode = "20";
          const payload: Partial<RazerPayMessage> = {
            header,
            body: {
              payAccountId: "00000000000000000000", // This field is use in another project
              transactionId: bs62.encode(Buffer.from(String(payment._id), "hex")),
              amount: Math.round(payment.amount * 100)
                .toString()
                .padStart(12, "0"),
              ...(RazerPaymentMethod[payment.methodSubType] == RazerPaymentMethod.CREDIT
                ? {}
                : { walletProductId: RazerPaymentMethod[payment.methodSubType] }),
            },
          };

          this.currentPaymentCancellable = true;
          payment = await this.doRazerPayment(payment, payload, controller);
          break;
        }
        case "simplePaymentIntegration": {
          if (!this.$paymentManager.simplePaymentIntegration) {
            throw new Error(String(this.$t("simplePaymentIntegration.notSetup")));
          }
          if (this.$paymentManager.simplePaymentIntegration.status !== "connected") {
            throw new Error(String(this.$t("simplePaymentIntegration.notConnection")));
          }
          payment = await this.createPayment(methodFull, {}, true, "payments");
          this.statusMessage = null;

          this.currentPaymentCancellable = true;
          const finalAmount = Math.round((payment.amount - (payment.tips || 0)) * 100) / 100;
          const resp = await this.$paymentManager.simplePaymentIntegration.pay(
            {
              payment: getID(payment),
              amount: finalAmount,
              tips: payment.tips || 0,
              surchargeAmount: payment.surcharge || 0,
              moto: this.paymentMethodArgs?.moto,
            },
            async receipt => {
              await wrapJob(this, null, async ({ sequence, template }) => {
                sequence.text(receipt);
                return sequence.getJob(`SPI Payment ${getID(payment)}`);
              });
            },
            status => {
              this.statusMessage = status;
            },
            controller,
          );
          this.statusMessage = null;
          console.log("simplePaymentIntegration resp", resp);
          payment = await this.confirmSimplePaymentIntegrationPayment(payment, resp);
          break;
        }
        case "razerOnline": {
          payment = await this.createPayment(methodFull, {}, true, "payments/razerOnline");
          if (payment.metadata.paymentImage) {
            this.paymentImage = this.session.paymentImage = payment.metadata.paymentImage;
            this.session.syncOrder();
          }
          this.currentPaymentCancellable = true;
          payment = await this.pollPayment(payment, controller);
          this.paymentImage = this.session.paymentImage = null;
          break;
        }

        default: {
          const method = this.$paymentManager.getMethod(methodFull.method.type);
          if (!method) {
            throw new Error("Invalid payment method");
          }

          const paymentLike: PaymentLike = {
            amount: this.amount,
            tips: this.session.outstandingTips,
            status: "pending",
            subType: methodFull.subType,
            props: methodFull.props,
          };

          const authDataMode = method.getAuthDataMode(methodFull.subType);
          if (authDataMode) {
            if (authDataMode === "qrcode") {
              paymentLike.authData = await this.scanCode();
            } else {
              throw new Error("Invalid payment method");
            }
          }

          await method.waitReady(true, 5000);
          await method.beginPay(paymentLike);

          if (method.capabilities.cloud) {
            payment = await this.createPayment(methodFull, paymentLike.metadata || {}, true, "payments/cloud", {
              authData: paymentLike.authData,
            });
          } else {
            payment = await this.createPayment(methodFull, paymentLike.metadata || {}, true, "payments");
          }

          if (payment.status === "pending") {
            if (payment.qrCodeUrl) {
              this.paymentImage = this.session.paymentImage = payment.qrCodeUrl;
              this.session.syncOrder();
            } else if (payment.qrCodeData) {
              const qrcode = await import("qrcode");
              this.paymentImage = this.session.paymentImage = await qrcode.toDataURL(payment.qrCodeData, {
                width: 512,
                color: {
                  light: "#ffffff",
                  dark: "#000000",
                },
              });
              this.session.syncOrder();
            }
            if (method.capabilities.cancel) {
              this.currentPaymentCancellable = true;
            }
            payment = await this.doGenericPayment(method, payment, controller, undefined, paymentLike.authData);
            if (!payment) {
              this.paySession++;
              return;
            }
          }

          break;
        }

        // default: {
        //   const e = Error("Invalid payment method");
        //   (e as any).className = "notSetup";
        //   throw e;
        // }
      }
      if (payment.status === "done") {
        await this.session.doPaySplitBills(amount, payment);
        await this.finishOrder(staff);
        this.status = "done";

        await this.$feathers.service("actionLogs").create({
          session: getID(this.session.item._id),
          view: getID(this.session.view),
          staff: staff?._id || this.$shop.staffId,
          type: "paymentManage/tableSessionCheckout",
          detail: { payment },
        });
        return (this.session as any).status === "done";
      } else if (payment.status === "cancelled") {
        this.currentPayment = null;
      } else if (payment.status === "error") {
        this.currentPayment = null;
        if (!this.errorMessage) {
          this.setError(payment.errorMessage || "Unknown error");
        }
      } else if (payment.status !== "pending") {
        this.currentPayment = null;
      } else {
        throw new Error("Unknown error");
      }
      return false;
    } catch (e) {
      if (e.className === "notSetup" && this.currentPayment) {
        await this.$feathers.service("payments").patch(this.currentPayment._id, {
          status: "cancelled",
          cancelReason: "Cancelled due to not setup",
        });
        this.currentPayment = null;
      }
      this.session.paymentImage = null;
      this.paymentImage = null;
      console.error(e);
      this.setError(e.messaget || e.message);
      if (!this.paymentDialog) {
        this.$store.commit("SET_ERROR", e.message);
      }
      try {
        await this.session.reload();
      } catch (e) {
        console.warn(e);
      }
    } finally {
      if (this.paySession !== paySession) return;
      this.loading = false;
      this.paymentPromise = null;
      this.paymentPromiseCancel = null;
      this.currentPaymentCancellable = false;
    }
  }

  async pollPayment(payment: PaymentType, signal?: AbortSignal) {
    while (checkID(this.currentPayment, payment) && payment.status === "pending") {
      signal?.throwIfAborted?.();
      await new Promise(resolve => setTimeout(resolve, 1000));
      payment = await this.$feathers.service("payments").get(payment._id);
    }
    return payment;
  }

  get surchargeAmount() {
    if (!this.paymentMethodFull) return 0;

    let surchargeAmount =
      Math.round(
        (this.paymentMethodFull.method.fixedSurcharge ?? 0) * 100 +
          (this.paymentMethodFull.method.percentSurcharge ?? 0) * this.amount,
      ) / 100;

    surchargeAmount = this.session.roundPrice(surchargeAmount);

    return surchargeAmount;
  }

  @Watch("surchargeAmount")
  onUpdateSurchargeAmount() {
    this.session.tempPaymentSurcharge = this.surchargeAmount;
    this.session.updateCoupons();
  }

  async createPayment(
    method: PaymentMethodFull,
    metadata: any = {},
    pending = false,
    path = "payments",
    extras: any = {},
  ) {
    if (this.currentPayment) throw new Error("Invalid state, should cancel previous payment first");

    this.currentPayment = await (this.$feathers.service(path) as any).create({
      methodRef: method.method._id,
      method: method.tag ?? method.method.tag,
      methodType: method.type ?? method.method.type,
      methodSubType: method.subType ?? (method.method.subType as any),
      amount: this.amount + this.surchargeAmount,
      tips: Math.min(this.session.outstandingTips, this.amount),
      surcharge: this.surchargeAmount,
      surchargeWithoutTax: this.session.previewPaymentSurcharge(this.surchargeAmount),
      session: this.session.item._id,
      status: pending ? "pending" : "done",
      metadata: metadata,
      type: "session",
      remarks: this.remarks,
      date: this.payTimeOverride,
      staff: this.paymentStaff ? this.paymentStaff : this.$shop.staffId,
      user: this.session.user,
      currency: this.$shop.currency,
      device: getID(this.$shop.device),
      shop: this.$shop.shopId,
      ...extras,
    });

    return this.currentPayment;
  }

  async confirmPayment(payment: FindType<"payments">, metadata: any = {}, opts: Partial<PaymentType> = {}) {
    this.currentPaymentCancellable = true;
    if (payment.status !== "done" && payment.status !== "pending") {
      throw new Error("Invalid state, payment should be pending or done");
    }
    if (payment.status !== "done") {
      try {
        return (this.currentPayment = await this.$feathers.service("payments").patch(payment._id, {
          status: "done",
          metadata,
          // paidSession: this.$shop.shopData.currentSession,
          ...opts,
        }));
      } catch (e) {
        e.confirmPayment = true;
        throw e;
      }
    }
    return payment;
  }

  async cloudInvoke(paymentLike: PaymentLike, payload: CloudPayload, options?: PaymentOptions) {
    const resp = await this.$feathers.service("payments/cloud").patch(paymentLike._id, {
      payload,
    });

    switch (resp.status) {
      case "done":
        paymentLike.status = "paid";
        break;
      default:
        paymentLike.status = resp.status;
        break;
    }
    paymentLike.metadata = resp.metadata;
    paymentLike.errorMessage = resp.errorMessage;

    return paymentLike;
  }

  genericSession = 0;

  async doGenericPayment(
    method: PaymentMethodBase,
    payment: FindType<"payments">,
    signal?: AbortSignal,
    restore?: boolean,
    authData?: string,
  ) {
    const session = ++this.genericSession;

    let paymentLike: PaymentLike = {
      _id: payment._id,
      orderPaymentId: payment.orderPaymentId,
      amount: payment.amount,
      tips: payment.tips,
      status: "pending",
      metadata: payment.metadata,
      subType: payment.methodSubType,
      authData,
    };
    let paymentResult: PaymentLike = paymentLike;
    try {
      if (restore) {
        if (method.capabilities.cancel) {
          try {
            await method.cancel(paymentLike);
          } catch (e) {
            console.log(e.message);
          }
        }
        paymentLike =
          (await method.restore(paymentLike, {
            signal,
            statusCb: status => {
              this.statusMessage = status as any;
            },
            cloudInvoke: this.cloudInvoke,
          })) || paymentLike;
      } else {
        paymentLike =
          (await method.pay(paymentLike, {
            signal,
            statusCb: status => {
              this.statusMessage = status as any;
            },
            subscribe: async () => {
              return new Promise((resolve, reject) => {
                const updateHandler = (payment: PaymentType) => {
                  this.statusMessage = payment.message;
                  if (payment.status !== "pending") {
                    paymentLike.status = payment.status as any;
                    paymentLike.metadata = payment.metadata;
                    paymentLike.errorMessage = payment.errorMessage;
                    paymentLike.message = payment.message;
                    cleanUp();
                    resolve(paymentLike);
                  }
                };
                this.$feathers.service("payments").on("patched", updateHandler);

                const timer = setInterval(async () => {
                  try {
                    payment = await this.$feathers.service("payments").get(payment._id);
                    if (payment.status !== "pending") {
                      paymentLike.status = payment.status as any;
                      paymentLike.metadata = payment.metadata;
                      paymentLike.errorMessage = payment.errorMessage;
                      paymentLike.message = payment.message;
                      cleanUp();
                      resolve(paymentLike);
                    }
                  } catch (e) {
                    console.warn(e);
                  }
                }, 15000);

                if (signal) {
                  signal.addEventListener(
                    "abort",
                    () => {
                      cleanUp();
                      setTimeout(() => {
                        reject(new Error("Aborted"));
                      }, 500);
                    },
                    { once: true },
                  );
                }

                const cleanUp = () => {
                  this.$feathers.service("payments").off("patched", updateHandler);
                  clearInterval(timer);
                };
              });
            },
            cloudInvoke: this.cloudInvoke,
          })) || paymentLike;
      }
    } catch (e) {
      if (e instanceof PaymentError) {
        if (e.payment) {
          paymentLike = e.payment;
        }
      } else if (e.message === "Aborted") {
      } else {
        if (!restore && method.capabilities.restore) {
          if (session !== this.genericSession) {
            return;
          }
          return this.doGenericPayment(method, payment, undefined, true);
        }
        throw e;
      }
    }

    if (session !== this.genericSession) {
      return;
    }

    if (method.capabilities.cloud) {
      payment = await this.$feathers.service("payments").get(payment._id);
      if (paymentResult.status !== "paid") {
        if (paymentResult.errorMessage) {
          this.setError(paymentResult.errorMessage);
        }
      }
    } else {
      if (paymentResult.status === "paid") {
        payment = await this.confirmPayment(payment, paymentResult.metadata || {}, {
          amount: paymentResult.amount,
          tips: paymentResult.tips,
          surcharge: paymentResult.surcharge,
          metadata: paymentResult.metadata,
          errorMessage: paymentResult.errorMessage,
          message: paymentResult.message,
          methodNetwork: paymentResult.network,
        });
      } else {
        if (paymentResult.errorMessage) {
          this.setError(paymentResult.errorMessage);
        }
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: paymentResult.status === "cancelled" ? "cancelled" : "error",
          metadata: paymentResult.metadata,
          errorMessage: paymentResult.errorMessage,
          message: paymentResult.message,
        });
      }
    }

    return payment;
  }

  async waitSupacityPayment(payment: PaymentType) {
    while (checkID(this.currentPayment, payment) && payment.status === "pending") {
      await new Promise(resolve => setTimeout(resolve, 1000));
      payment = await this.$feathers.service("payments/supacity/update").create({
        payment: String(payment._id),
      });
    }
  }

  async waitMypayPayment(payment: PaymentType) {
    while (checkID(this.currentPayment, payment) && payment.status === "pending") {
      await new Promise(resolve => setTimeout(resolve, 1000));
      payment = await this.$feathers.service("payments/mypay/update").create({
        payment: String(payment._id),
      });
    }
  }

  async waitAdyenPayment(payment: PaymentType) {
    while (checkID(this.currentPayment, payment) && payment.status === "pending") {
      await new Promise(resolve => setTimeout(resolve, 1000));
      payment = await this.$feathers.service("payments/adyen/update").create({
        payment: String(payment._id),
      });
    }
  }

  async cancelSupacity(payment: FindType<"payments">) {
    try {
      payment = await this.$feathers.service("payments/supacity/update").create({
        payment: String(payment._id),
      });
      if (payment.status === "pending") {
        payment = await this.$feathers.service("payments/supacity/cancel").create({
          payment: String(payment._id),
        });
      }
      return payment;
    } catch (e) {
      return false;
    }
    return payment;
  }

  async cancelMypay(payment: FindType<"payments">) {
    try {
      payment = await this.$feathers.service("payments/mypay/update").create({
        payment: String(payment._id),
      });
      if (payment.status === "pending") {
        payment = await this.$feathers.service("payments/mypay/cancel").create({
          payment: String(payment._id),
        });
      }
      return payment;
    } catch (e) {
      return false;
    }
  }

  async cancelAdyen(payment: FindType<"payments">) {
    try {
      payment = await this.$feathers.service("payments/adyen/update").create({
        payment: String(payment._id),
      });
      if (payment.status === "pending") {
        payment = await this.$feathers.service("payments/adyen/cancel").create({
          payment: String(payment._id),
        });
      }
      return payment;
    } catch (e) {
      return false;
    }
  }

  async cancelPoint(payment: FindType<"payments">) {
    try {
      if (payment.status === "pending") {
        payment = await this.$feathers.service("payments/point/cancel").create({
          payment: String(payment._id),
        });
      }
      return payment;
    } catch (e) {
      return false;
    }
  }

  async cancelAllinpay(payment: FindType<"payments">) {
    try {
      const resp = await this.callAllinpayPOS({
        BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
        TRANS_TRACE_NO: String(payment._id),
      });
      if (resp.TRANS_STATE === "PAIED" || resp.CARD_ORGN) {
        if (resp.CARD_ORGN) {
          // try to find refund order
          try {
            const resp = await this.callAllinpayPOS({
              BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
              TRANS_TRACE_NO: String(payment._id) + "REF",
            });
            if (resp) {
              payment = await this.$feathers.service("payments").patch(payment._id, {
                status: "refunded",
              });
              return payment;
            }
          } catch (e) {}
        }
        payment = await this.confirmAllinpayPayment(payment, resp);
        return;
      } else if (resp?.TRANS_STATE === "PAYING") {
        throw new Error("Paying");
      }
    } catch (e) {
      if (e.REJCODE === "1FF") {
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "cancelled",
          cancelReason: "Cancelled by terminal",
        });
        return payment;
      }
      return false;
    }
    return payment;
  }

  async confirmHasePayment(payment: FindType<"payments">, response: PayResponseType | FetchResponseType) {
    if (response?.responseCode === "00" || response?.responseCode === "000") {
      let methodNetwork: string = "unknown";

      if (response instanceof EDCSaleResponse) {
        methodNetwork = response?.cardType?.toLowerCase?.() ?? "unknown";
      } else if (response instanceof QRResponse) {
        methodNetwork =
          {
            a: "alipay",
            w: "wechat",
            p: "payme",
          }[response?.transactionType?.toLowerCase?.()] ??
          response?.transactionType?.toLowerCase?.() ??
          "unknown";
      } else if (response instanceof CupSaleResponse) {
        methodNetwork = response?.cardType?.toLowerCase?.() ?? "unknown";
      } else if (response instanceof OctopusResponse) {
        methodNetwork = "octopus";
      }

      payment = await this.confirmPayment(
        payment,
        {
          ...payment.metadata,
          ...response,
        },
        {
          methodNetwork,
          paymentId: response?.traceNumber,
          deviceId: response?.terminalNumber,
        },
      );
    }
    return payment;
  }

  async cancelHase(payment: FindType<"payments">, retrying = false) {
    const hase = this.$paymentManager.checkHase();
    await hase.checkHASESetuped();
    if (!payment.metadata.traceNumber) {
      payment = await this.$feathers.service("payments").patch(payment._id, {
        status: "cancelled",
        cancelReason: "Cancelled due to no trace number",
      });
      return payment;
    }
    const response = await hase.fetchPayment({
      ...payment.metadata,
      methodSubType: payment.methodSubType ?? payment.metadata.methodSubType,
    });
    if (response.responseCode === "000") {
      if (retrying) {
        payment = await this.confirmHasePayment(payment, response);
        return payment;
      }
      await hase.refundPayment({
        amount: Math.floor(payment.amount),
        tips: 0,
        methodSubType: payment.methodSubType ?? payment.metadata.methodSubType,
        traceNumber: payment.metadata.traceNumber,
      });
    } else {
      // CANCEL
      payment = await this.$feathers.service("payments").patch(payment._id, {
        status: "cancelled",
        cancelReason: "Cancelled by user (during restore)",
      });
    }
    return payment;
  }

  async cancelOctopus(payment: FindType<"payments">) {
    if (checkID(this.currentPayment, payment)) {
      await this.$paymentManager.octopus.cancel();
      payment = await this.$feathers.service("payments").patch(payment._id, {
        status: "cancelled",
        cancelReason: "Cancelled by user (during restore)",
      });
      return payment;
    } else {
      return await this.doOctopusPayment(payment);
    }
  }

  async doOctopusPayment(payment: FindType<"payments">, signal?: AbortSignal) {
    const amount = Math.ceil(payment.amount * 10);
    let transaction: OctopusCommandRespTransactionSuccess | OctopusCommandRespTransactionError;
    let txnId = +payment.paymentId | 0;
    let transactionStarted = false;
    let errorMessage;
    try {
      const input: Partial<OctopusCommandTransaction> = {
        type: "transaction",
        txnId,
        amount,
        pollSeconds: 30,
        orderInfo: `sessionId=${this.session._id},sessionName=${this.session.sessionData?.sessionName}`,
      };
      const progress = status => {
        if (status === "starting") {
          this.status = "paying";
          transactionStarted = true;
        }
      };
      let cancelRetry = false;
      let mustRetrying = false;
      const retry = async (transaction: OctopusCommandRespTransactionError, retry: "mustRetry" | "retry") => {
        if (cancelRetry) {
          this.resetError();
          return false;
        }
        if (mustRetrying || retry === "mustRetry") {
          mustRetrying = true;
          this.currentPaymentCancellable = false;
          this.errorRetry = retry => {
            if (!retry) {
              cancelRetry = true;
            }
          };
          this.errorRetryAuto = true;
          if (transaction.error !== "noCardPresent") {
            this.errorRetryCancel = false;
            if (this._errorRetryTimeout) {
              clearTimeout(this._errorRetryTimeout);
              this._errorRetryTimeout = null;
            }
          }
          if (!this._errorRetryTimeout && !this.errorRetryCancel) {
            this._errorRetryTimeout = setTimeout(() => {
              this.errorRetryCancel = true;
              this._errorRetryTimeout = null;
            }, 20000);
          }
          this.errorMessage = [
            {
              lang: "en",
              value: transaction.octopusErrorMessage[0],
            },
            {
              lang: "cht",
              value: transaction.octopusErrorMessage[1],
            },
            {
              lang: "chs",
              value: transaction.octopusErrorMessage[2],
            },
          ];
          return true;
        } else {
          const retry = new Promise<boolean | string>(resolve => {
            this.setError(
              [
                {
                  lang: "en",
                  value: transaction.octopusErrorMessage[0],
                },
                {
                  lang: "cht",
                  value: transaction.octopusErrorMessage[1],
                },
                {
                  lang: "chs",
                  value: transaction.octopusErrorMessage[2],
                },
              ],
              resolve,
            );
          });
          if (retry) {
            this.resetError();
          }
          return retry;
        }
      };

      if (checkID(this.currentPayment, payment)) {
        this.currentPaymentCancellable = true;
        transaction = await this.$paymentManager.octopus.transaction(
          input,
          String(payment._id),
          progress,
          retry as any,
          signal,
        );
      } else {
        if (!this.$paymentManager.octopus) {
          throw new Error(String(this.$t("octopus.notSetup")));
        }
        await this.$paymentManager.octopus.waitReady(true, this.$paymentManager.octopus.useNative ? 10000 : 30000);
        transaction = await this.$paymentManager.octopus.restoreTransaction(
          input,
          String(payment._id),
          progress,
          retry as any,
        );
      }
    } catch (e) {
      if (e?.type === "transactionError") {
        transaction = e;
      } else {
        errorMessage = e.message;
      }
    } finally {
      if (this._errorRetryTimeout) {
        clearTimeout(this._errorRetryTimeout);
        this._errorRetryTimeout = null;
      }
    }

    if (!transaction) {
      if (!transactionStarted) {
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "error",
          errorMessage: errorMessage,
        });
      }
      if (errorMessage) {
        this.setError(errorMessage);
      } else {
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "cancelled",
          errorMessage: "Cancelled by library (unknown error)",
        });
      }
    } else if (transaction.type === "transactionError") {
      if (transaction.error === "noCardPresent") {
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "cancelled",
          errorMessage: "Cancelled by user (no card present)",
        });
      } else {
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "error",
          metadata: {
            errorMessage: transaction.errorMessage,
          },
          errorMessage: transaction.errorMessage,
        });
        if (checkID(this.currentPayment, payment)) {
          this.currentPayment = null;
        }
        if (transaction.octopusErrorMessage) {
          this.setError([
            {
              lang: "en",
              value: transaction.octopusErrorMessage[0],
            },
            {
              lang: "cht",
              value: transaction.octopusErrorMessage[1],
            },
            {
              lang: "chs",
              value: transaction.octopusErrorMessage[2],
            },
          ]);
        } else {
          this.setError(transaction.errorMessage);
        }
      }
    } else if (transaction.type === "transactionSuccess") {
      payment = await this.$feathers.service("payments").patch(payment._id, {
        status: "done",
        metadata: {
          transaction: transaction,
          locId: this.$paymentManager.octopus.formatLocationId(transaction.locId || 0),
          devId: (transaction.devId || 0).toString(16).toUpperCase().padStart(6, "0"),
          txnId: (transaction.txnId || 0).toString(16).toUpperCase().padStart(4, "0"),
        },
      });
    }
    return payment;
  }

  async doTurnCloudPayment(payment: FindType<"payments">) {
    if (!payment.metadata) {
      throw new Error("Invalid payment");
    }
    try {
      await this.$paymentManager.turnCloud.waitReady(true);
      const resp = await this.$paymentManager.turnCloud.doTransaction(payment.metadata);
      return await this.confirmTurnCloudPayment(payment, resp);
    } catch (e) {
      if (e instanceof TurnCloudError) {
        console.log(e.message);
        this.setError(e.message);
        return await this.confirmTurnCloudPayment(payment, e.payload);
      } else if (e instanceof TurnCloudConnectionError) {
        this.setError(e.message);
        return await this.$feathers.service("payments").patch(payment._id, {
          status: "cancelled",
          errorMessage: e.message,
        });
      }
      throw e;
    }
  }

  async cancelTurnCloud(payment: FindType<"payments">) {
    try {
      return await this.doTurnCloudPayment(payment);
    } catch (e) {
      return await this.$feathers.service("payments").patch(payment._id, {
        status: "error",
        errorMessage: e.message,
      });
    }
  }

  async confirmTurnCloudPayment(payment: FindType<"payments">, resp: TurnCloudMessage) {
    if (resp.responseCode !== "orderSucess") {
      return await this.$feathers.service("payments").patch(payment._id, {
        status: "error",
        errorMessage: resp.responseCode,
        metadata: resp,
      });
    }
    const networkList = this.$paymentManager.turnCloud.networkList;
    let network = networkList[resp.payFrom ?? resp.payType] ?? resp.payFrom ?? resp.payType;
    if (resp.cardType) {
      network = resp.cardType;
    }
    return await this.confirmPayment(payment, resp, {
      amount: resp.transAmount,
      methodNetwork: (network || "").toUpperCase(),
    });
  }

  async doRazerPayment(payment: FindType<"payments">, payload: Partial<RazerPayMessage>, signal?: AbortSignal) {
    try {
      await this.$paymentManager.razer.waitReady(true, undefined, signal);
      const resp = await this.$paymentManager.razer.doTransaction(payload, undefined, undefined, signal);
      return await this.confirmRazerPayment(payment, resp);
    } catch (e) {
      if (e instanceof RazerPayError) {
        this.setError(e.message);
        return await this.confirmRazerPayment(payment, e.payload);
      } else if (e instanceof RazerPayConnectionError) {
        this.setError(e.message);
        return await this.$feathers.service("payments").patch(payment._id, {
          status: "cancelled",
          errorMessage: e.message,
        });
      }
      throw e;
    }
  }

  async cancelRazer(payment: FindType<"payments">) {
    await this.$paymentManager.razer.waitReady(true);
    await this.$paymentManager.razer.cancelPayment(bs62.encode(Buffer.from(String(payment._id), "hex")));

    try {
      const header = new RazerPayHeader();
      header.transactionCode = "12";
      const payload: Partial<RazerPayMessage> = {
        header,
        body: {
          transactionId: bs62.encode(Buffer.from(String(payment._id), "hex")),
        },
      };
      const resp = await this.$paymentManager.razer.doTransaction(payload, true, false);

      if (
        (resp.header.responseCode === "00" || resp.header.responseCode === "WE") &&
        (resp.header.transactionCode === "12" || resp.header.transactionCode === "20")
      ) {
        return this.confirmRazerPayment(payment, resp);
      }
      if (resp.header.responseCode === "NF") {
        // not found
        return await this.$feathers.service("payments").patch(payment._id, {
          status: "error",
          errorMessage: resp.body.responseText,
        });
      }
    } catch (e) {
      return await this.$feathers.service("payments").patch(payment._id, {
        status: "error",
        errorMessage: e.message,
      });
    }
  }

  async confirmSimplePaymentIntegrationPayment(
    payment: FindType<"payments">,
    payload: SimplePaymentIntegrationTransaction,
  ) {
    if (payload.status !== "success") {
      if (payload.status === "failed") {
        this.setError(payload.message);
        payment = await this.$feathers.service("payments").patch(payment._id, {
          status: "error",
          errorMessage: payload.message,
          metadata: payload,
        });
        this.currentPayment = null;
        return payment;
      } else {
        throw new Error(payload.message);
      }
    }
    payment = await this.confirmPayment(payment, payload, {
      amount: Math.round((payload.amount + (payment.tips || 0) + (payment.surcharge || 0)) * 100) / 100,
      methodNetwork: payload.network,
      surcharge: payload.surcharge,
    });
    if (payload.tips !== payment.tips) {
      payment = await this.session.editOrAddTips(getID(payment), payload.tips);
    }
    return payment;
  }

  async cancelSimplePaymentIntegration(payment: FindType<"payments">) {
    if (this.$paymentManager.simplePaymentIntegration.status !== "connected") {
      throw new Error(String(this.$t("simplePaymentIntegration.notConnection")));
    }
    const resp = await this.$paymentManager.simplePaymentIntegration.query(getID(payment));
    if (resp.status === "success" || resp.status === "failed") {
      return await this.confirmSimplePaymentIntegrationPayment(payment, resp);
    } else {
      return await this.$feathers.service("payments").patch(payment._id, {
        status: "cancelled",
        cancelReason: "Cancelled by user (during restore)",
      });
    }
  }

  async confirmRazerPayment(payment: FindType<"payments">, resp: RazerPayMessage) {
    if (resp.header.responseCode !== "00") {
      // should be dead code since it throw error already :(
      return await this.$feathers.service("payments").patch(payment._id, {
        status: "error",
        errorMessage: resp.header.responseCode,
        metadata: resp,
      });
    }
    return await this.confirmPayment(payment, resp, {
      amount: +resp.body.amount / 100 ?? 0,
      methodNetwork: resp.body.customDataParsed?.walletProductName ?? resp.body.cardLabel,
    });
  }

  async cancelCurrentPayment(user = false) {
    if (this.scannerTask) {
      this.scannerTask.reject(new Error("Cancelled"));
    }
    this.cancelling = true;
    try {
      const method = this.$paymentManager.getMethod(this.currentPayment?.methodType);
      if (method?.capabilities?.abortable) {
        this.paymentPromiseCancel?.abort?.(new Error("Cancel by user"));
        if (!method?.capabilities?.cancel) {
          await this.paymentPromise;
        }
      } else if (!method) {
        this.paymentPromiseCancel?.abort?.(new Error("Cancel by user"));
        await this.paymentPromise;
      } else if (!method?.capabilities?.cancel) {
        await this.paymentPromise;
      }
    } finally {
      this.cancelling = false;
    }
    await this.cancelCurrentPaymentInner(undefined, user);
  }

  async cancelCurrentPaymentInner(signal?: AbortSignal, user?: boolean): Promise<boolean | "cancel"> {
    if (this.cancelling) return;
    this.cancelling = true;
    try {
      if (this.status === "pending" || this.status === "recovery") {
        this.status = "restoring";
      }
      const pendingPayments = await this.$feathers.service("payments").find({
        query: {
          $sort: { date: -1 },
          $paginate: false,
          session: this.session.item._id,
          status: "pending",
          device: getID(this.$shop.device),
        },
        paginate: false,
      });

      this.currentPendingPayments = pendingPayments;

      if (!pendingPayments.length) {
        this.currentPayment = null;
        return false;
      }

      for (let payment of pendingPayments) {
        signal?.throwIfAborted?.();
        const res = await this.cancelOnePayment(payment, undefined, user);
        if (res === null) {
          continue;
        }
        return res;
      }
      return false;
    } finally {
      this.cancelling = false;
    }
  }

  async cancelOnePayment(payment: FindType<"payments">, retrying = false, user?: boolean) {
    let cancelPayment: typeof payment | boolean;
    let error: string;
    let isConfirmPaymentError = false;
    let forceCancel = false;
    if (payment.status === "pending") {
      try {
        switch (payment.methodType) {
          case "supacity": {
            cancelPayment = await this.cancelSupacity(payment);
            break;
          }
          case "mypay": {
            cancelPayment = await this.cancelMypay(payment);
            break;
          }
          case "allinpay": {
            cancelPayment = await this.cancelAllinpay(payment);
            break;
          }

          case "octopus": {
            cancelPayment = await this.cancelOctopus(payment);
            break;
          }

          case "adyenPos": {
            cancelPayment = await this.cancelAdyen(payment);
            break;
          }
          case "hase": {
            cancelPayment = await this.cancelHase(payment, retrying);
            break;
          }

          case "point": {
            cancelPayment = await this.cancelPoint(payment);
            break;
          }
          case "turnCloud": {
            cancelPayment = await this.cancelTurnCloud(payment);
            break;
          }
          case "razer": {
            cancelPayment = await this.cancelRazer(payment);
            break;
          }

          case "turnCloud": {
            cancelPayment = await this.cancelTurnCloud(payment);
            break;
          }
          case "razer": {
            cancelPayment = await this.cancelRazer(payment);
            break;
          }
          case "simplePaymentIntegration": {
            cancelPayment = await this.cancelSimplePaymentIntegration(payment);
            break;
          }
          default: {
            const method = this.$paymentManager.getMethod(payment.methodType);
            if (!method) {
              throw new Error("Invalid payment method: " + payment.methodType);
            }
            if (method) {
              let cancelResp: boolean | "async" | void;

              try {
                cancelResp = (await method.cancel()) ?? true;
                if (cancelResp === "async") {
                  return;
                }
              } catch (e) {
                console.error(e);
                if (user) {
                  this.$store.commit("SET_ERROR", e.message);
                  return;
                }
              }

              if (cancelResp === false) {
                throw new Error("Cancel failed");
              } else {
                if (method.capabilities.restore) {
                  await method.waitReady(true, 5000);
                  cancelPayment = await this.doGenericPayment(method, payment, undefined, true);
                }
              }
              forceCancel = true;
            }
            break;
          }
        }
      } catch (e) {
        error = e.message;
        if (e.confirmPayment) {
          isConfirmPaymentError = true;
        }
      }
    } else {
      return null;
    }
    if (typeof cancelPayment === "boolean" || !cancelPayment) {
      if (!cancelPayment) {
        const isRestoring = this.status === "restoring" || this.status === "recovery";
        while (true) {
          const c = await new Promise<boolean | string>(resolve => {
            this.setError(
              `${this.$t("paymentDialog.failedToCancel")}:\n${payment._id} (${payment.method})${
                error ? `\n${error}` : ""
              }`,
              resolve,
              undefined,
              isConfirmPaymentError ? [] : [this.$t("paymentDialog.manual") as string],
            );
          });
          this.resetError();
          if (isRestoring) {
            this.status = "restoring";
          }
          if (typeof c === "string") {
            const result = await this.doManualConfirm(payment);
            if (!result) continue;
            return result;
          } else if (c) {
            return this.cancelOnePayment(payment);
          } else {
            return "cancel";
          }
        }
      }
    } else {
      payment = cancelPayment;
    }

    if (payment?.status === "done") {
      if (await this.finishOrder()) {
        return true;
      }
    }

    if (!retrying && checkID(this.currentPayment, payment)) {
      this.currentPayment = null;
    }

    if (forceCancel) {
      this.loading = false;
      this.paymentPromise = null;
      this.paymentPromiseCancel = null;
    }
    return null;
  }

  async confirmAllinpayPayment(payment: FindType<"payments">, resp: any) {
    let network = ["UNION PAY", "VISA", "MASTERCARD", "JCD", "DINNERS", "AE"][+resp.CARD_ORGN] || resp.CARD_ORGN;
    if (resp.TRANS_CHANNEL) {
      switch (resp.TRANS_CHANNEL) {
        case "ALP": {
          network = "ALIPAY";
          break;
        }
        case "WXP": {
          network = "WECHAT";
          break;
        }
        case "UNP": {
          network = "UNIONPAY";
          break;
        }
        case "PNP": {
          network = "PAYNOW";
          break;
        }
      }
    }
    return await this.confirmPayment(payment, resp, {
      methodNetwork: network,
      amount: +(resp.PAY_AMOUNT || resp.AMOUNT) / 100,
      paymentId: resp.REF_NO,
      metadata: {
        AUTH_NO: resp.AUTH_NO,
        BATCH_NO: resp.BATCH_NO,
        CARD_NO: (resp.CARDNO || "").replace(/(?!\d{0,4}$)\d/g, "*"),
        CURRENCY: resp.CURRENCY,
        DATE: resp.DATE,
        MERCH_ID: resp.MERCH_ID,
        REF_NO: resp.REF_NO,
        TER_ID: resp.TER_ID,
        TIME: resp.TIME,
        TRACE_NO: resp.TRACE_NO,
        TRANS_CHANNEL: resp.TRANS_CHANNEL,
        TRANS_TRACE_NO: resp.TRANS_TRACE_NO,
      },
    });
  }

  async finishOrder(staff?: FindType<"staffs">) {
    await this.session.reload();
    if (this.session.paymentSurcharge) {
      await this.session.updateCachedInfo();
    }
    this.session.partialPayment = this.session.outstanding;
    this.session.received = null;
    if (this.session.status !== "done") {
      return false;
    }

    let jobs: PrintJob[] = [];

    if (
      !this.$shop.localOptions.tableDontAutoPrintReceiptAll &&
      this.session.type !== "crm" &&
      this.session.type !== "hidden"
    ) {
      jobs = await this.printOrder(this.currentPayment?.methodType == "cash", {});
    }
    if (!jobs?.length && this.currentPayment?.methodType == "cash" && !this.$shop.localOptions.noOpenCashBox) {
      await openCashBox(this, staff);
    }
    if (
      this.session.sessionData.twInvoice &&
      (this.session.sessionData.twTaxType === "company" ||
        this.session.sessionData.twTaxType === "paper" ||
        this.session.twInvoicePrintDetails)
    ) {
      try {
        await this.session.printTwInvoice();
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }
    this.session.showingInvoice = this.$shop.localOptions.autoShowInvoice;
    this.session.syncOrder();
    this.currentPayment = null;
    this.reset();
    return true;
  }

  async retryPayment() {
    try {
      this.cancelling = true;
      this.resetError();
      await this.cancelOnePayment(this.currentPayment, true);
    } catch (e) {
      this.setError(e.message);
    } finally {
      this.cancelling = false;
    }
  }

  async printOrder(cashBox = false, props: any = {}) {
    try {
      return await this.session.printOrder({
        cashBox,
        ...props,
      });
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async manualConfirm() {
    await this.$feathers.service("actionLogs").create({
      session: getID(this.session.item._id),
      view: getID(this.session.view),
      staff: this.$shop.staffId,
      type: "paymentManage/tableSessionManualConfirmPayment",
      detail: {
        type: this.manualConfirmType,
        serialNo: this.serialNo,
        manualConfirmRef: this.manualConfirmRef,
        manualConfirmNetwork: this.manualConfirmNetwork,
      },
    });
    if (this.manualConfirmType === "confirm") {
      const payment = await this.$feathers.service("payments").patch(this.manualConfirming._id, {
        status: "done",
        paymentId: this.manualConfirmRef,
        ...(this.manualConfirmNetwork
          ? {
              network: this.manualConfirmNetwork,
            }
          : {}),
        manualConfirm: true,
      });
      if (checkID(payment, this.currentPayment)) {
        this.currentPayment = payment;
      }
      await this.finishOrder();
      this.manualConfirming = null;
      if (this.manualConfirmCb) {
        this.manualConfirmCb(payment);
        this.manualConfirmCb = null;
      }
      return payment;
    } else {
      const payment = await this.$feathers.service("payments").patch(this.manualConfirming._id, {
        status: "cancelled",
        ...(this.manualConfirmRef
          ? {
              paymentId: this.manualConfirmRef,
            }
          : {}),
        ...(this.manualConfirmNetwork
          ? {
              network: this.manualConfirmNetwork,
            }
          : {}),
        cancelReason: "Cancelled by user (manual)",
        manualConfirm: true,
      });
      if (checkID(payment, this.currentPayment)) {
        this.currentPayment = null;
        this.manualConfirming = null;
        this.resetError();
      }
      if (this.manualConfirmCb) {
        this.manualConfirmCb(payment);
        this.manualConfirmCb = null;
      }
      return payment;
    }
  }

  statusMessage: string | LangArrType = null;
  errorMessage: string | LangArrType = null;
  errorRetry: (retry?: boolean | string) => Promise<void> | void = null;
  errorButtons: string[] = null;
  errorRetryCancel = true;
  errorRetryCalled = false;
  errorRetryAuto = false;
  manualConfirming: FindType<"payments"> = null;
  manualConfirmRef: string = "";
  manualConfirmNetwork: string = "";
  manualConfirmType: "confirm" | "cancel" = "confirm";

  cancelManualConfirm() {
    this.manualConfirming = null;
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
  }

  get serialNo() {
    const manager = this.$paymentManager.getMethod(this.currentPayment?.methodType);
    if (manager) {
      return manager.serialNo;
    }
  }

  get manualConfirmTypes() {
    return [
      {
        _id: "confirm",
        name: { $t: "paymentDialog.manualConfirm" },
      },
      {
        _id: "cancel",
        name: { $t: "paymentDialog.manualCancel" },
      },
    ];
  }

  get networks() {
    const manager = this.$paymentManager.getMethod(this.currentPayment?.methodType);
    if (manager) {
      return manager.capabilities?.networks?.map?.(network => {
        return {
          _id: network.id,
          name: network.name,
        };
      });
    }
  }

  _errorRetryTimeout: any;

  async setError(
    error: string | LangArrType,
    retry?: (retry?: boolean | string) => Promise<void> | void,
    cancelDelay?: number,
    buttons?: string[],
  ) {
    this.status = "error";
    await this.$feathers.service("actionLogs").create({
      session: getID(this.session.item._id),
      view: getID(this.session.view),
      staff: this.$shop.staffId,
      type: "paymentManage/tableSessionPaymentFailed",
      detail: { paymentMethod: this.paymentMethodFull.method, error },
    });
    if (this.paymentDialog) {
      this.errorMessage = error;
      this.errorRetry = retry;
      this.errorRetryAuto = false;
      this.errorButtons = buttons;
      this.errorRetryCalled = false;
      if (this._errorRetryTimeout) {
        clearTimeout(this._errorRetryTimeout);
        this._errorRetryTimeout = null;
      }
      if (cancelDelay) {
        this.errorRetryCancel = false;
        this._errorRetryTimeout = setTimeout(() => {
          this._errorRetryTimeout = null;
          this.errorRetryCancel = true;
        }, cancelDelay);
      } else {
        this.errorRetryCancel = true;
      }
    } else {
      this.$store.commit("SET_ERROR", error);
    }
  }

  async errorConfirmRetry(retry: boolean | string) {
    try {
      if (!this.errorRetry) {
        this.resetError();
      } else {
        await this.errorRetry(retry);
        this.errorRetryCalled = true;
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async continueRecovery(doRecovery: boolean) {
    if (doRecovery) {
      this.status = "restoring";
      await this.cancelCurrentPayment();
    } else {
      this.status = "pending";
    }
  }

  getMethodName(item: FindType<"payments">) {
    return (
      item.methodNetwork || item.methodSubType || this.$shop.paymentMethodDict?.[item.methodRef as any]?.name || ""
    );
  }

  resetDialog() {
    this.status = "pending";
    this.resetError();
  }

  resetError() {
    this.errorMessage = null;
    if (!this.errorRetryCalled) {
      this.errorRetry?.(false);
    }
    if (this._errorRetryTimeout) {
      clearTimeout(this._errorRetryTimeout);
      this._errorRetryTimeout = null;
    }
    this.errorRetryAuto = false;
    this.errorRetry = null;
    this.errorButtons = null;
    this.errorRetryCalled = false;
    this.manualConfirming = null;
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
    this.manualConfirmType = "confirm";
    this.statusMessage = null;
  }

  manualConfirmCb: (confirm: FindType<"payments">) => void;

  doManualConfirm(payment: FindType<"payments">) {
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
    this.manualConfirming = payment;
    this.manualConfirmRef = this.currentPayment?.paymentId ?? "";
    return new Promise<FindType<"payments">>(resolve => {
      this.manualConfirmCb = resolve;
    });
  }

  async checkTaxStatus() {
    if (this.$features.turnCloud && !this.$network.offlineActivated) {
      if (this.session.sessionData.twTaxType !== "none") {
        if (!this.session.twTaxNumValid) {
          switch (this.session.sessionData.twTaxType) {
            case "electronic":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxElectNumError"));
              break;
            case "donate":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxDonateNumError"));
              break;
            case "company":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxCompanyNumError"));
              break;
            default:
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxNumError"));
              break;
          }
          return false;
        }

        const resp = await this.$feathers.service("turnCloud/checkTaxStatus").create({
          shop: getID(this.$shop.shopData),
        });
        if (resp.invoiceStatus === "low") {
          await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("turnCloud.taxStatusWarn", { remain: resp.remain }),
              hasCancel: false,
            },
            {
              maxWidth: "500px",
            },
          );
        } else if (resp.invoiceStatus === "no") {
          await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("turnCloud.taxStatusError"),
              hasCancel: false,
            },
            {
              maxWidth: "500px",
            },
          );
          return false;
        }
        if (resp.errors?.length) {
          for (let error of resp.errors) {
            await this.$openDialog(
              import("@feathers-client/components-internal/ConfirmDialog.vue"),
              {
                title: this.$t("turnCloud.taxAutoRequestError", { error }),
                hasCancel: false,
              },
              {
                maxWidth: "500px",
              },
            );
          }
        }
      }
    }
    if (
      this.$features.turnCloud &&
      this.session.sessionData.twTaxType !== "none" &&
      (!this.session.taxSettings?.length || !this.session.sessionData.taxes?.length)
    ) {
      await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("turnCloud.taxSettingsInvalid"),
          hasCancel: false,
        },
        {
          maxWidth: "500px",
        },
      );
      return false;
    }
    return true;
  }

  @Watch("status")
  @Watch("errorMessage")
  @Watch("loading")
  @Watch("cancelling")
  onStatusChange() {
    if (this.$shop.secondScreen && !this._updateStatusChange) {
      this._updateStatusChange = this.updateStatusChange();
    }
    if (this.session) {
      this.session.showingCheckout =
        this.loading || this.cancelling || !!this.errorMessage
          ? {
              status: this.status,
              errorMessage: this.errorMessage,
              loading: this.loading,
              cancelling: this.cancelling,
              amount: this.amount,
            }
          : null;
      if (!this.session.showingCheckout && this.session.paymentImage) {
        this.session.paymentImage = null;
      }
    }
  }

  @Watch("currentPayment")
  onSaveCurrentPayment() {
    localStorage.setItem(
      "currentPayment",
      this.currentPayment
        ? JSON.stringify({
            payment: getID(this.currentPayment),
            session: getID(this.session._id),
            shop: getID(this.$shop.shopData),
            user: getID(this.$store.getters.userId),
            device: getID(this.$shop.device),
          })
        : "",
    );
  }

  _updateStatusChange: any;
  async updateStatusChange() {
    await Vue.nextTick();
    if (!this._updateStatusChange) return;
    this._updateStatusChange = null;
    await this.session?.syncOrder?.();
  }
}
