
import uuid from "uuid/v4";
import MediaLibrary from "~/components/MediaLibrary.vue";
import { Component, Prop, Vue } from "@feathers-client";
import _ from "lodash";

@Component
export default class ImagePicker extends Vue {
  @Prop({ type: String, default: "" })
  url: string;

  @Prop({ type: String, default: "" })
  parent: string;

  @Prop({ type: String, default: "" })
  dir: string;

  @Prop({ type: String, default: "image/*" })
  type: string;

  @Prop()
  value: any;

  @Prop({ type: Boolean })
  mini: boolean;

  @Prop(String)
  defImage: string;

  @Prop(Boolean)
  attachment: boolean;

  @Prop(Boolean)
  attachmentId: boolean;

  @Prop(String)
  label: string;

  @Prop(String)
  name: string;

  @Prop(Boolean)
  readonly: boolean;

  minfo: any = null;
  mediaLibrary = false;
  dragging = false;
  menu = false;

  get thumb() {
    if (this.value && this.value.thumb) {
      return `data:image/png;base64,${this.value.thumb}`;
    }
    if (this.attachmentId && this.value) return (this.$thumb as any)(this.value, true);
    return (
      (this.value && this.value.src) || this.defImage || this.$config.appLogo || require("~/assets/images/logo.png")
    );
  }

  removeImage() {
    this.value = {};
    this.$emit("input", null);
  }
  async addFile() {
    const file = document.createElement("input");
    file.style.display = "none";
    file.type = "file";
    file.accept = this.type;
    file.multiple = true;
    document.body.append(file);
    file.click();
    await new Promise(resolve => (file.onchange = resolve));
    if (file.files.length == 0) return;
    await Promise.all(_.map(file.files, img => this.uploadFile(img)));
  }
  addFiles(files) {
    const f = files[0];
    this.mediaLibrary = false;
    if (this.attachmentId) {
      this.$emit("input", f._id);
    } else if (this.attachment) {
      this.$emit("input", f);
    } else {
      this.$emit("input", {
        type: "attachment",
        attachment: f._id,
        width: f.width,
        height: f.height,
        thumb: f.thumb,
        src: f.src,
      });
    }
  }

  pickImage() {
    if (this.mini) {
      this.menu = true;
    } else {
      this.mediaLibrary = true;
    }
  }

  async uploadFile(mfile) {
    var data = new FormData();
    data.append("file", mfile, mfile.name);

    const info: any = {
      name: mfile.name,
      size: mfile.size,
      mime: mfile.type,
      thumb: null,
      id: uuid(),
      success: false,
      complete: false,
      processing: true,
      error: null,
      progress: 0,
    };
    this.minfo = info;

    try {
      const response = await this.$feathers.post(`attachments/upload`, data, {
        onUploadProgress: progressEvent => {
          info.progress = progressEvent.loaded / progressEvent.total;
        },
      });

      const rinfo = (response.data || {}).info || {};
      _.assign(info, rinfo);
      info.success = true;
      info.complete = true;
      info.progress = 1;
      info.processing = false;
    } catch (e) {
      info.error = e.message;
      info.complete = true;
      info.processing = false;
    }

    if (this.attachmentId) {
      this.$emit("input", info._id);
    } else if (this.attachment) {
      this.$emit("input", info);
    } else {
      this.$emit("input", {
        type: "attachment",
        attachment: info._id,
        width: info.width,
        height: info.height,
        thumb: info.thumb,
        src: info.src,
      });
    }
  }

  dragenter(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes("Files")) {
      if (!this.dragging) {
        this.dragging = true;
        this.$emit("beginDrag");
      }
      e.dataTransfer.dropEffect = "copy";
      return;
    }
    if (this.dragging) {
      this.dragging = false;
      this.$emit("endDrag");
    }
    e.dataTransfer.dropEffect = "none";
  }
  dragover(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes("Files")) {
      if (!this.dragging) {
        this.dragging = true;
        this.$emit("beginDrag");
      }
      e.dataTransfer.dropEffect = "copy";
      return;
    }
    if (this.dragging) {
      this.dragging = false;
      this.$emit("endDrag");
    }
    e.dataTransfer.dropEffect = "none";
  }
  async drop(e) {
    if (!this.dragging) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragging = false;
    if (e.dataTransfer.types.includes("Files")) {
      const imgs = _.filter(e.dataTransfer.files, file => file.type.match("^image/"));
      if (imgs.length > 0) {
        await this.uploadFile(imgs[0]);
      }
    }
    this.$emit("endDrag");
  }
  dragleave(e) {
    if (!this.dragging) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragging = false;
    this.$emit("endDrag");
  }
}
