import { SingleCodePage, MultiCodePage, PrintSequence, TextAlign } from "..";
import { PrinterBaseConf } from "../../printers/baseConf";
import { getImage, getQR } from "./image";
import { RawLine } from "../rawLine";
import _ from "lodash";
import qr from "qr-image";
import { BitmapOpts, WrappedContext } from "../../common";

export class EscPosPrintSequence extends PrintSequence {
  feedValue = 30;
  codePage = "gbk";
  compactMode = false;

  constructor(
    public context: WrappedContext,
    printer: PrinterBaseConf,
  ) {
    super(context, printer);
    if (printer?.conf?.opts?.initPrinter ?? true) {
      this.raw([0x1b, 0x40]); // 1B 40 esc @
    }
    this.feedValue = printer?.conf?.opts?.feedValue ?? 30;
    this.codePage = printer?.conf?.opts?.codePage ?? "gbk";
    if (printer?.conf?.opts?.singleCodePage !== undefined && printer?.conf?.opts?.singleCodePage != -1) {
      this.singleCodePage(printer?.conf?.opts?.singleCodePage ?? 0);
    }
    if (printer?.conf?.opts?.multiCodePage !== undefined && printer?.conf?.opts?.multiCodePage != -1) {
      this.multiCodePage(printer?.conf?.opts?.multiCodePage ?? 0);
    }
    if (printer?.conf?.opts?.lineWidth !== printer?.conf?.opts?.rlineWidth && printer?.conf?.opts?.rlineWidth) {
      const v = 12 * printer?.conf?.opts?.rlineWidth;
      const l = v % 256;
      const h = (v / 256) | 0;
      this.raw([0x1d, 0x57, l, h]); // ESC W nL nH
      this.lineWidth = this.normalLineWidth = printer?.conf?.opts?.rlineWidth;
    }
    this.dirty = false;
  }

  get supportMultiColor() {
    return this.printer?.conf?.opts?.supportMultiColor ?? false;
  }

  async getImage(url: string, numChars: number, hiRes?: boolean | number) {
    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;

    const pxPerChar = hHi ? 12 : 6;
    const pxPerLine = vHi ? 24 : 8;
    const pxRatio = (pxPerChar / pxPerLine) * 2 * (this.fontWidth / this.fontHeight);
    const width = numChars * pxPerChar;

    const img = await this.convertImage(url, width, {
      pxPerLine,
      pxRatio,
    });

    if (!img) {
      console.warn("Failed to load image");
      return [];
    }

    return getImage(img, numChars, pxPerLine, hiRes);
  }

  getQR(url: string, size: number) {
    return getQR(url, size);
  }

  printRawImage(lines: RawLine[]) {
    this.lineHeight(this.lineImageHeight);
    _.each(lines, it => {
      this.raw(it.buf);
      this.raw([0xa]);
    });
    this.lineHeight();
  }

  async printImageData(buffer: Uint8ClampedArray, width: number, height: number, hiRes?: boolean | number) {
    if (this.useBitmapImage) {
      const b = (width / 8) | 0;
      const rows = [];

      rows.push(Buffer.from([0x1b, 0x74, 0xa]));

      for (let i = 0; i < height; i++) {
        if (i % 128 === 0) {
          const h = Math.min(128, height - i) | 0;
          const mbuf = Buffer.alloc(8);
          mbuf[0] = 29;
          mbuf[1] = 118;
          mbuf[2] = 48;
          const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
          const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
          let m = vHi && hHi ? 0 : hHi ? 2 : vHi ? 1 : 3;
          mbuf[3] = m;
          mbuf[4] = b % 256;
          mbuf[5] = (b / 256) | 0;
          mbuf[6] = h % 256;
          mbuf[7] = (h / 256) | 0;
          rows.push(mbuf);
        }

        const buf = Buffer.alloc(b);

        if (i < height - 1) {
          let ofs = width * i * 4;

          for (let j = 0; j < b; j++) {
            let v = 0;
            for (let k = 0; k < 8; k++) {
              const mv = 128 >> k;
              v |= buffer[ofs] ? 0 : mv;
              ofs += 4;
            }
            buf[j] = v;
          }
        }

        rows.push(buf);
      }

      rows.push(Buffer.from([0x1b, 0x74, 0xa]));

      this.raw(Buffer.concat(rows));
    } else if (this.useLineImage) {
      const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
      const hHi = hiRes === true || hiRes === 1 || hiRes === 33;

      const pxPerChar = hHi ? 12 : 6;
      const pxPerLine = vHi ? 24 : 8;
      const lines = await getImage(
        {
          buffer,
          width,
          height,
        },
        width / pxPerChar,
        pxPerLine,
        hiRes,
      );
      this.printRawImage(lines);
    } else {
      console.warn("No image support");
    }
    return this;
  }

  async printImage(url: string, owidth: number, hiRes?: boolean | number) {
    if (!this.useBitmapImage && this.useLineImage) {
      await super.printImage(url, owidth, hiRes);
      return this;
    }
    this.raw(this.getPreLine());
    if (!url) {
      console.warn("No image url");
      return;
    }
    try {
      const img = await this.convertImage(url, owidth);
      if (!img) {
        console.warn("Failed to load image");
        return;
      }
      const { width, height, buffer } = img;
      this.printImageData(buffer, width, height, hiRes);
    } catch (e) {
      console.warn(e);
    }

    return this;
  }

  async printImageTag(tag: string, url?: string) {
    this.raw(this.getPreLine());
    const opts = this.printer?.bitmapList?.[tag];
    const hiRes = this.printer?.conf?.opts?.[tag + "_hiRes"] ?? opts?.hiRes ?? false;
    if (tag && this.nvImage !== "no") {
      if (this.nvImage === "legacy" && opts) {
        // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=89
        // FS p n m
        this.raw([0x1c, 0x70, opts.legacyId, hiRes ? 0 : 3]);
        return this;
      } else if (this.nvImage === "gs") {
        // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=107
        // GS ( L   <Function 69>
        // GS ( L pL pH m fn kc1 kc2 x y
        const buf = Buffer.from(tag);
        if (buf.length !== 2) throw new Error(`invalid image tag`);
        this.raw([0x1d, 0x28, 0x4c, 0x06, 0x00, 0x30, 0x45, buf[0], buf[1], hiRes ? 0 : 1, hiRes ? 0 : 1]);
        return this;
      }
    }
    return await super.printImageTag(tag, url);
  }

  async downloadImages() {
    if (this.nvImage !== "no") {
      const images = Object.values(this.printer?.bitmapList || {});
      if (this.nvImage === "legacy") {
        const maxId = _.maxBy(images, m => m.legacyId)?.legacyId ?? 0;
        const imageById = _.fromPairs(images.map(it => [it.legacyId, it]));

        // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=90
        // FS q n
        this.raw([0x1c, 0x71, maxId]);

        for (let i = 1; i <= maxId; i++) {
          const opts: BitmapOpts = imageById[i];
          if (opts) {
            const hiRes = this.printer?.conf?.opts?.[opts.tag + "_hiRes"] ?? opts?.hiRes ?? false;
            try {
              const url = this.printer.resolveBitmap(opts.tag, undefined, this.context);
              const img = await this.convertImage(url, opts.width * (hiRes ? 2 : 1));
              if (!img) {
                console.warn("Failed to load image");
              }
              const { width, height, buffer } = img;
              const widthBytes = ((width + 7) / 8) | 0;
              const heightBytes = ((height + 7) / 8) | 0;
              const bitmapLine = width * 4;

              this.raw([widthBytes % 256, (widthBytes / 256) | 0, heightBytes % 256, (heightBytes / 256) | 0]);
              const buf = new Uint8Array(heightBytes * widthBytes * 8);
              let bufOfs = 0;
              for (let j = 0; j < width; j++) {
                for (let i = 0; i < heightBytes; i++) {
                  let v = 0;
                  let yofs = j * 4 + i * 8 * bitmapLine;
                  for (let l = 0; l < 8; l++) {
                    const mv = 128 >> l;
                    v |= yofs > buffer.length || buffer[yofs] ? 0 : mv;
                    yofs += bitmapLine;
                  }
                  buf[bufOfs++] = v;
                }
              }

              this.raw(buf);
              continue;
            } catch (e) {
              console.warn(e);
            }
          }
          this.raw([0, 0, 0, 0]);
        }
      } else if (this.nvImage === "gs") {
        for (let opts of images) {
          try {
            const hiRes = this.printer?.conf?.opts?.[opts.tag + "_hiRes"] ?? opts?.hiRes ?? false;
            const url = this.printer.resolveBitmap(opts.tag, undefined, this.context);
            const img = await this.convertImage(url, opts.width * (hiRes ? 2 : 1));
            if (!img) {
              console.warn("Failed to load image");
            }
            const { width, height, buffer } = img;

            // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=70
            // GS ( L   <Function 67> (raster)
            // GS ( L pL pH 30 43 a kc1 kc2 b xL xH yL yH [c d1...dk]1...[c d1...dk]b
            const buf = Buffer.from(opts.tag);
            if (buf.length !== 2) throw new Error(`invalid image tag`);

            const b = (width / 8) | 0;
            const rows = [];
            for (let i = 0; i < height; i++) {
              const buf = Buffer.alloc(b);
              if (i < height - 1) {
                let ofs = width * i * 4;

                for (let j = 0; j < b; j++) {
                  let v = 0;
                  for (let k = 0; k < 8; k++) {
                    const mv = 128 >> k;
                    v |= buffer[ofs] ? 0 : mv;
                    ofs += 4;
                  }
                  buf[j] = v;
                }
              }
              rows.push(buf);
            }

            const rawSize = Buffer.concat(rows);
            const cmdSize = rawSize.length + 12;
            this.raw([
              0x1d,
              0x28,
              0x4c,
              cmdSize % 256,
              (cmdSize / 256) | 0,
              0x30,
              0x43,
              48,
              buf[0],
              buf[1],
              1,
              width % 256,
              (width / 256) | 0,
              height % 256,
              (height / 256) | 0,
            ]);
            this.raw(rawSize);
          } catch (e) {
            console.warn(e);
          }
        }
      }
      return this;
    }
  }

  printQR(text: string, numChars: number, w: number = 6) {
    if (!text) return;
    this.raw(this.getPreLine());
    if (this.nativeQR) {
      // QR Code: Select the model
      //              Hex     1D      28      6B      04      00      31      41      n1(x32)     n2(x00) - size of model
      // set n1 [49 x31, model 1] [50 x32, model 2] [51 x33, micro qr code]
      // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=140
      this.raw([0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x32, 0x00]);
      // QR Code: Set the size of module
      // Hex      1D      28      6B      03      00      31      43      n
      // n depends on the printer
      // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=141
      this.raw([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, w || 6]);
      //          Hex     1D      28      6B      03      00      31      45      n
      // Set n for error correction [48 x30 -> 7%] [49 x31-> 15%] [50 x32 -> 25%] [51 x33 -> 30%]
      // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=142
      this.raw([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x31]);
      // QR Code: Store the data in the symbol storage area
      // Hex      1D      28      6B      pL      pH      31      50      30      d1...dk
      // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=143
      //                        1D          28          6B         pL          pH  cn(49->x31) fn(80->x50) m(48->x30) d1…dk
      const buf = this.getText(text);
      const store_len = buf.length + 3;
      const store_pL = store_len % 256;
      const store_pH = (store_len / 256) | 0;
      this.raw([0x1d, 0x28, 0x6b, store_pL, store_pH, 0x31, 0x50, 0x30]);
      this.raw(buf);
      // QR Code: Print the symbol data in the symbol storage area
      // Hex      1D      28      6B      03      00      31      51      m
      // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=144
      this.raw([0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30]);
    } else if (this.useBitmapImage) {
      var matrix = qr.matrix(text);
      w = Math.ceil(w / 2);

      if (numChars && matrix[0].length > numChars * 6) {
        throw new Error("Too much data");
      }

      const numLines = Math.ceil(matrix.length * w);
      const width = matrix[0].length;
      const numBytes = Math.ceil((width * w) / 8);

      const rows = [];
      rows.push(Buffer.from([0x1b, 0x74, 0xa]));
      for (let i = 0; i < numLines; i++) {
        if (i % 128 === 0) {
          const h = Math.min(128, numLines - i) | 0;
          const mbuf = Buffer.alloc(8);
          mbuf[0] = 29;
          mbuf[1] = 118;
          mbuf[2] = 48;
          mbuf[3] = 3;
          mbuf[4] = numBytes % 256;
          mbuf[5] = (numBytes / 256) | 0;
          mbuf[6] = h % 256;
          mbuf[7] = (h / 256) | 0;
          rows.push(mbuf);
        }

        const buf = Buffer.alloc(numBytes);
        let ofs = 0;
        for (let j = 0; j < numBytes; j++) {
          let v = 0;
          for (let k = 0; k < 8; k++) {
            const mv = 128 >> k;
            v |= matrix[(i / w) | 0][(ofs++ / w) | 0] ? mv : 0;
          }
          buf[j] = v;
        }
        rows.push(buf);
      }

      rows.push(Buffer.from([0x1b, 0x74, 0xa]));
      this.raw(Buffer.concat(rows));
    } else if (this.useLineImage) {
      this.printRawImage(this.getQR(text, numChars));
    } else {
      this.text(text);
    }

    return this;
  }

  fill(c?: string, n?: number) {
    if (!this.compactMode && (this.useLineImage || this.useBitmapImage)) {
      if (!c || c === "-" || c === "=") {
        const width = Math.floor(this.pxWidth / 2 / 8) * 8;
        const widthBytes = width * 4;
        const buf = new Uint8ClampedArray(widthBytes * 8);
        buf.fill(0xff);

        function fillLine(idx: number) {
          const start = idx * widthBytes;
          const end = start + widthBytes;
          for (let i = start; i < end; i++) {
            buf[i] = 0;
          }
        }

        if(c === '=') {
          fillLine(2);
          fillLine(5);
        } else {
          fillLine(3);
          fillLine(4);
        }

        this.printImageData(buf, width, 8, false);
      }
    } else {
      return super.fill(c, n);
    }

    return this;
  }

  status() {
    this.raw([0x10, 0x04, 1]);
    return this;
  }

  feed(n: number) {
    // ESC J [n]
    // https://www.epson-biz.com/modules/ref_escpos/index.php?content_id=15
    for (let i = 0; i < n; i++) this.raw([0x1b, 0x4a, this.feedValue]);
    return this;
  }

  cut() {
    // GS V
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=87
    this.raw([0x1d, 0x56, 0x0]);
    return this;
  }

  align(n: TextAlign) {
    this.raw([0x1b, 0x61, n]);
    return this;
  }

  font(n: number) {
    this.raw([0x1b, 0x4d, n]);
    return this;
  }

  bold(on: boolean) {
    // ESC E [0/1]
    // https://www.epson-biz.com/modules/ref_escpos/index.php?content_id=25
    this.raw([0x1b, 0x45, +on]);
    return this;
  }

  color(n: number = 0) {
    if (this.supportMultiColor) {
      // ESC r [0/1]
      // https://download4.epson.biz/sec_pubs/pos/reference_en/escpos/ref_escpos_en/esc_lr.html
      this.raw([0x1b, 0x72, n]);
    }
    return this;
  }

  currentWidth = 0;
  currentHeight = 0;

  fontSize(w?: number, h?: number) {
    this.currentWidth = w ?? 0;
    this.currentHeight = h ?? 0;
    if (this.usePrintModeOnly) {
      const doubleWidth = w > 0;
      const doubleHeight = h > 0;
      this.raw([0x1c, 0x57, doubleWidth || doubleHeight ? 1 : 0]);
      this.raw([0x1b, 0x21, (doubleWidth ? 0x20 : 0) | (doubleHeight ? 0x10 : 0)]);
    } else {
      this.raw([0x1d, 0x21, (w << 4) | h]);
    }
    return this;
  }

  get fontWidthScale() {
    return Math.max(1, this.currentWidth + 1);
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  getText(text: string): Buffer | number[] {
    return this.printer.iconv.encode(text, this.codePage, {
      stripBOM: true,
    });
  }

  getLine(): number[] {
    return [0xa, 0xd];
  }

  printCode(
    type: "upca" | "upce" | "ean8" | "ean13" | "code39" | "itf" | "codabar" | "code93" | "code128",
    code: string,
    showChars?: false | "above" | "below",
    height?: number,
  ) {
    let m = 0;
    let n = 0;
    switch (type) {
      case "upca":
        m = 65;
        n = code.length;
        break;
      case "upce":
        m = 66;
        n = code.length;
        break;
      case "ean8":
        m = 67;
        n = code.length;
        break;
      case "ean13":
        m = 68;
        n = code.length;
        break;
      case "code39":
        m = 69;
        n = code.length;
        break;
      case "itf":
        m = 70;
        n = code.length;
        break;
      case "codabar":
        m = 71;
        n = code.length;
        break;
      case "code39":
        m = 72;
        n = code.length;
        break;
      case "code128":
        m = 73;
        n = code.length;
        break;
      default:
        throw new Error("Not supported code: " + type);
    }

    if (height !== undefined) {
      this.raw([0x1d, 0x68, height]);
    }

    this.raw([0x1d, 0x48, showChars === "above" ? 1 : showChars === "below" ? 2 : 0]);
    this.raw([0x1d, 0x6b, m, ...(n ? [n] : [])]);
    this.raw(Buffer.from(code));
    if (!n) this.raw([0]);

    return this;
  }

  codeHeight(height: number) {
    this.raw([0x1d, 0x68, height]);
    return this;
  }

  lineHeight(height?: number) {
    if (height !== undefined) {
      this.raw([0x1b, 0x33, height]); // ESC 3 n
    } else {
      this.raw([0x1b, 0x32]); // ESC 2
    }
    return this;
  }

  needCashBox = false;

  cashBox(which: number = 48, time: number = 50) {
    const dirty = this.dirty;
    this.raw([0x1b, 0x70, which, time, time]);
    this.dirty = dirty;
    this.needCashBox = true;
    return this;
  }

  getJobOpts() {
    return {
      type: "escpos",
      codePage: this.codePage,
      cashBox: this.needCashBox,
    };
  }

  get usePrintModeOnly(): boolean {
    return this.printer?.conf?.opts?.usePrintModeOnly ?? false;
  }

  get nvImage(): "legacy" | "gs" | "no" {
    return this.useBitmapImage ? this.printer?.conf?.opts?.nvImage ?? "legacy" : "no";
  }

  pageMode() {
    // ESC L
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=193
    this.raw([0x18, 0x4c]);
    // GS "P" x y
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=199
    this.raw([0x1d, 0x50, 0, 0]);
    return this;
  }

  pageModePos(x: number, y: number, w: number, h: number) {
    // ESC W xL xH yL yH dxL dxH dyL dyH
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=56
    this.raw([
      0x1b,
      0x57,
      x % 256,
      (x / 256) | 0,
      y % 256,
      (y / 256) | 0,
      w % 256,
      (w / 256) | 0,
      h % 256,
      (h / 256) | 0,
    ]);
    return this;
  }

  pageModePrint() {
    // ESC FF
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=14
    this.raw([0x1b, 0x0c]);

    // ESC "S"
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=194
    this.raw([0x1b, 0x53]);
    return this;
  }

  pageModeDirection(dir: "ltr" | "btt" | "rtl" | "ttb" = "ltr") {
    // ESC T
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=55
    this.raw([0x1b, 0x54, { ltr: 0, btt: 1, rtl: 2, ttb: 3 }[dir]]);
    return this;
  }

  singleCodePage(codePage: SingleCodePage) {
    this.raw([0x1b, 0x74, +codePage as number]);
    return this;
  }

  multiCodePage(codePage: MultiCodePage) {
    this.raw([0x1b, 0x52, +codePage as number]);
    return this;
  }

  beep(times = 1, duration = 5) {
    this.raw([0x1b, 0x42, times, duration]);
    return this;
  }
}
