
import { FindType } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";
import { BBMSLVerify } from "~/pages/settings/verifyPayment.vue";

@Component({
  components: {},
})
export default class Verify extends Vue {
  @Prop()
  statusGroup: FindType<"tableSessions/summaries/verify">[number]["items"][number];

  @Prop()
  date: string;

  @Prop()
  group: BBMSLVerify;

  expanded = false;
}
