import { TableItem } from "./view";
import _ from 'lodash';

const positions = ['top', 'right', 'bottom', 'left', 'topLeft', 'topRight', 'bottomRight', 'bottomLeft'] as const;
type Position = typeof positions[number];

class Point {
    constructor(
        protected readonly width: number,
        protected readonly height: number,
        public readonly position: Position
    ) {}

    protected _x: number = 0;
    get x() {
        return this._x;
    }

    protected _y: number = 0;
    get y() {
        return this._y;
    }
}

class RectPoint extends Point {
    constructor(width: number, height: number, position: Position) {
        super(width, height, position);
        this.calc();
    }

    private calc() {
        if (this.position.toLowerCase().includes('right')) {
            this._x = this.width;
        } else if (!this.position.toLowerCase().includes('left')) {
            this._x = this.width / 2;
        }

        if (this.position.toLowerCase().includes('bottom')) {
            this._y = this.height;
        } else if (!this.position.toLowerCase().includes('top')) {
            this._y = this.height / 2;
        }
    }
}

class RoundPoint extends Point {
    constructor(width: number, height: number, position: Position) {
        super(width, height, position);
        this.calc();
    }

    private calc() {
        switch (this.position) {
            case 'top':
                this._x = this.width / 2;
                break;
            case 'right':
                this._x = this.width;
                this._y = this.height / 2;
                break;
            case 'bottom':
                this._x = this.width / 2;
                this._y = this.height;
                break;
            case 'left':
                this._y = this.height / 2;
                break;
            default:
                this.calcArc();
                break;
        }
    }

    private calcArc() {
        let angle = 0;
        switch (this.position) {
            case 'topLeft':
                angle = 225;
                break;
            case 'topRight':
                angle = 315;
                break;
            case 'bottomRight':
                angle = 45;
                break;
            case 'bottomLeft':
                angle = 135;
                break;
        }
        this._x = (this.width / 2) * (Math.cos(angle * (Math.PI / 180)) + 1);
        this._y = (this.height / 2) * (Math.sin(angle * (Math.PI / 180)) + 1);
    }
}

export function computePoint(item: TableItem, position: Position, enlargeX: number = 0, enlargeY: number = 0): Point {
    const width = item.w + enlargeX;
    const height = item.h + enlargeY;
    return item.shape === 'round' ? new RoundPoint(width, height, position) : new RectPoint(width, height, position);
}

export function computePoints(item: TableItem, enlargeX: number = 0, enlargeY: number = 0): Point[] {
    const width = item.w + enlargeX;
    const height = item.h + enlargeY;
    const con = item.shape === 'round' ? RoundPoint : RectPoint;
    return _.map(positions, position => new con(width, height, position));
}
