
import { ProductLine } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { OrderGroupChunk } from "~/plugins/table/kds";
import { StatusType } from "~/pages/table/kds.vue";
import { KdsProductChunk } from "~/plugins/table/kdsProduct";

@Component
export default class TableKDSProductChunk extends Vue {
  @Prop()
  chunk: KdsProductChunk;

  @Ref()
  container: HTMLElement;
  @Ref()
  scroller: HTMLElement;

  observer: ResizeObserver;

  mounted() {
    if (typeof ResizeObserver !== "undefined") {
      this.observer = new ResizeObserver(this.onResize);
      this.observer.observe(this.container);
      this.observer.observe(this.scroller);
    }
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onResize(entries: ResizeObserverEntry[], observer: ResizeObserver) {
    if (!this.scroller) return;
    if (this.scroller.scrollHeight > this.scroller.clientHeight && this.chunk.length > 1) {
      // divide the chunk if cannot show on one page
      const containerBounds = this.scroller.getBoundingClientRect();
      for (let i = 0; i < this.container.children.length && i < this.chunk.length; i++) {
        const child = this.container.children[i];
        const bounds = child.getBoundingClientRect();
        const block = this.chunk[i];
        if (bounds.bottom > containerBounds.bottom) {
          const blockContainer = child.querySelector("#blockContainer");
          let innerIndex = -1;

          if (blockContainer) {
            for (let j = 0; j < block.items.length; j++) {
              const item = blockContainer.children[j];
              const itemBounds = item.getBoundingClientRect();
              if (itemBounds.bottom > containerBounds.bottom) {
                innerIndex = j;
                break;
              }
            }
          }

          // console.log("break at", i, innerIndex);
          if(i === 0 && innerIndex === 0) return;
          this.chunk[0].group.parent.splitAt(this.chunk, i, innerIndex);
          break;
        }
      }
    }
  }
}
