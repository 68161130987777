
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { FindPopRawType, FindType } from "@feathers-client";

@Component
export default class OrderSystemDiscount extends Vue {
  @VModel()
  remarks: string;

  
}
