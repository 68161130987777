import helper from "~/plugins/helper";
import { Context } from "@nuxt/types";
import { INGREDIENT_MULTIPLIER, priceFromDb, priceToDb } from "@common/table/ingredients";
import { setTemplateContext } from "pos-printer-template/plugin";

export default helper("schemaEditorAddons", (ctx: Context) => {
  if (process.server) return;
  ctx.app.$schemas.addComponents(require.context("~/components/dialogs", true, /\.(js|ts|vue)$/, "lazy"));

  setTemplateContext(require.context("!!raw-loader!@common/table/templates", true, /\.vue$/, "lazy"));

  ctx.app.$schemas.appendTranslateRule({
    common: ["shopGroup"] as any,
  });

  ctx.app.$schemas.registerType("jwk", {
    component: () => import("~/components/JwkEditor.vue"),
  });

  ctx.app.$schemas.registerType("productOptionValue", {
    component: () => import("~/components/ProductOptionValue.vue"),
  });

  ctx.app.$schemas.registerType("shopCategoryList", {
    component: () => import("~/components/ShopCategoryPicker.vue"),
    format: "listAndTranslate",
  });

  ctx.app.$schemas.registerType("currencyType", {
    component: () => import("~/components/CurrencyPicker.vue"),
  });

  ctx.app.$schemas.registerType("currencyNum", {
    // @ts-ignore
    component: () => import("~/components/boxs/SingleCurrencyEditor.vue"),
    format: "currencyInt",
  });

  ctx.app.$schemas.registerType("roundedPrice", {
    format: "currency",
  });
  ctx.app.$schemas.registerType("printJobOptions", {
    component: () => import("~/components/PrintJobOptions.vue"),
  });

  ctx.app.$schemas.registerType("localPrinter", {
    component: () => import("~/components/LocalPrinterPicker.vue"),
  });

  ctx.app.$schemas.registerType("time", {});

  ctx.app.$schemas.registerType("translateId", {
    format(self, v: string, item) {
      return self.$t(v, (item as any).data || {}) as string;
    },
  });

  ctx.app.$schemas.registerAcl("shop", (context, action, item) => {
    if (action === "patch" || action === "remove") {
      if (context.$store.state.user.role === "admin") {
        if (context.$store.state.user.shop) {
          return item.shop === context.$store.state.user.shop;
        } else {
          return true;
        }
      } else if (item._id === context.$store.state.user._id) {
        return true;
      }
      return false;
    }
    return true;
  });

  ctx.app.$schemas.registerAcl("shops", (context, action, item) => {
    if (action === "patch" || action === "remove") {
      if (context.$store.state.user.shop) {
        return item?.shops?.length === 1 && item.shops[0] === context.$store.state.user.shop;
      }
    }
    return true;
  });

  ctx.app.$schemas.registerAcl("shopList", (context, action) => {
    if (
      context.$store.state.user.role === "admin" &&
      !context.$store.state.user.shop &&
      !context.$store.state.user.shopGroup
    ) {
      // super admin
      return true;
    }
    if (context.$features?.singleShop) {
      if (action === "create" || action === "remove" || action === "clone") return false;
    }
    return true;
  });

  ctx.app.$schemas.registerAcl("userList", (context, action, item) => {
    if (
      context.$store.state.user.role === "admin" &&
      !context.$store.state.user.shop &&
      !context.$store.state.user.shopGroup
    ) {
      // super admin
      return true;
    }
    if (context.$features?.singleShop) {
      if (action === "create" || action === "remove" || action === "clone") return false;
    }
    if (action === "patch" || action === "remove") {
      if (context.$store.state.user.role === "admin") {
        if (context.$store.state.user.shop) {
          return item.shop === context.$store.state.user.shop;
        } else {
          return true;
        }
      } else if (item._id === context.$store.state.user._id) {
        return true;
      }
      return false;
    }
    return true;
  });

  ctx.app.$schemas.registerType("ingredient", {
    fromDb(self, v: number) {
      return (v ?? 0) / INGREDIENT_MULTIPLIER;
    },
    toDb(self, v: number) {
      return isNaN(+v) ? undefined : Math.round(+v * INGREDIENT_MULTIPLIER);
    },
    format(self, v: number, item: any) {
      const info = self.$shop.ingredientDict[item?.ingredient || item?._id];
      return `${+((v ?? 0) / INGREDIENT_MULTIPLIER).toFixed(2)}${info?.unit ?? ""}`;
    },
  });

  ctx.app.$schemas.registerType("ingredientPrice", {
    fromDb(self, v: number, root?: any) {
      return priceFromDb(v, self.$shop?.ingredientDict?.[root?.ingredient]?.priceQuantity);
    },
    toDb(self, v: number, root?: any) {
      return priceToDb(v, self.$shop?.ingredientDict?.[root?.ingredient]?.priceQuantity);
    },
    format(self, v: number, root: any) {
      return self.$price(priceFromDb(v, self.$shop?.ingredientDict?.[root?.ingredient]?.priceQuantity));
    },
  });

  ctx.app.$schemas.registerType("session", {
    format(self, v: number, item: any) {
      return (
        item.session?.sessionName +
        (item.session?.tables && item.session?.tables.length
          ? (item.session?.tableRefName ? "/" + item.session?.tableRefName : "") +
            "/" +
            item.session?.tables.reduce((a, b) => a + b.capacity, 0)
          : "")
      );
    },
  });

  ctx.app.$schemas.registerHook("initImport", async (...args) => {
    (await import("./schemaImportAddons")).default(ctx);
  });

  ctx.app.$schemas.registerType("paymentMethodOptions", {
    component: () => import("~/components/payment/methodOptions.vue"),
  });

  ctx.app.$schemas.registerType("paymentMethodSecrets", {
    component: () => import("~/components/payment/methodSecrets.vue"),
  });

  ctx.app.$schemas.registerType("kdsColorTag", {
    component: () => import("~/components/KdsColorPicker.vue"),
  });

  ctx.app.$schemas.registerType("colorPicker", {
    // @ts-ignore
    component: () => import("~/components/ColorPicker.vue"),
  });

  ctx.app.$schemas.registerType("addressPicker", {
    component: () => import("~/components/AddressPicker.vue"),
  });

  ctx.app.$schemas.registerType("countryCode", {
    component: () => import("~/components/TelCodeSelector.vue"),
  });

  ctx.app.$schemas.registerType("rankPicker", {
    component: () => import("~/components/RankPicker.vue"),
  });

  ctx.app.$schemas.registerType("json", {
    component: () => import("~/dep/pos-printer-template/JsonEditor.vue"),
    props: {
      "editor-size": 12,
    },
  });

  ctx.app.$schemas.registerType("jsonobj", {
    component: () => import("~/dep/pos-printer-template/JsonEditor.vue"),
    props: {
      "editor-size": 12,
      isObject: true,
    },
  });
});
