
import _ from "lodash";
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import MemberList from "~/components/table/memberSystem/memberList.vue";
import MemberCard from "~/components/table/memberSystem/memberCard.vue";

@Component({
  components: {
    MemberCard,
    MemberList,
  },
})
export default class MerchantCart extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  confirmText: string;

  @Prop({ type: Boolean, default: true })
  title: boolean;
}
