
import { Component, Vue, FindType, Prop, PropSync, checkID } from "@feathers-client";
import { fromDb, INGREDIENT_MULTIPLIER_PRICE, toDb, totalPrice, priceFromDb } from "@common/table/ingredients";
import _ from "lodash";

interface SummaryRow {
  _id: string;
  ingredient: string;
  time: string;
  quantity: number;

  lastestCostSum: number;
  costSum: number;
  costAmount: number;
  amount: number;

  ingredients: {
    ingredient: string;
    sumCost: number;
    usage: number;
  }[];
}

@Component({})
export default class SemiProduct extends Vue {
  @Prop()
  item: SummaryRow;

  @Prop()
  showTime: boolean;

  fromDb = fromDb;
  totalPrice = totalPrice;
  priceFromDb = priceFromDb;

  showDetails = false;

  get cachedProduct() {
    return this.$shop.ingredientDict[this.item.ingredient];
  }

  get name() {
    return this.cachedProduct?.name;
  }

  get unit() {
    return this.cachedProduct?.unit ?? '';
  }

  get image() {
    return this.$thumb(this.cachedProduct?.image) || require("~/assets/images/icons/placeholder.svg");
  }

  get totalCost() {
    return _.sumBy(this.item.ingredients, it => it.sumCost);
  }
}
