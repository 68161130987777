
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import _ from "lodash";

type Picture = {
  _id: string;
  mime: string;
};

@Component
export default class BannerCarousel extends Vue {
  pictures: Picture[] = [];

  @Prop({ default: "100%" })
  height: string | number;

  @Prop({ default: "contain" })
  bgSize: string;

  @Prop({ default: 5000 })
  interval: number;

  currentPictureIdx = 0;

  picHandler = null;

  mounted() {
    this.loadPictures();
  }

  beforeDestroy() {
    this.stopLoop();
  }

  async loadPictures() {
    const banners = await this.$feathers.service("posDevices/banners").create({});
    this.pictures = _.uniqBy(
      _.flatMap(banners, b => b.pictures),
      "_id",
    ) as any;
  }

  get hasMultiplePictures() {
    return this.pictures.length > 1;
  }

  isImage(picture: Picture) {
    return picture.mime.match(/image\/.*/);
  }

  startLoop() {
    if (this.picHandler) {
      return;
    }
    this.picHandler = setTimeout(async () => {
      this.picHandler = null;
      await this.showNextPicture();
    }, this.interval);
  }

  stopLoop() {
    if (this.picHandler) {
      clearTimeout(this.picHandler);
      this.picHandler = null;
    }
  }

  async showNextPicture() {
    this.currentPictureIdx = (this.currentPictureIdx + 1) % this.pictures.length;
    this.startLoop();
    if (!this.isImage(this.pictures[this.currentPictureIdx])) {
      // Reload and restart video
      await this.$nextTick();
      const video = document.querySelector(`#video-${this.currentPictureIdx}`) as HTMLVideoElement;
      if (video && video.src) {
        video.currentTime = 0;
        video.play();
      } else {
        this.showNextPicture();
      }
    }
  }

  @Watch("pictures")
  onPictureChanged() {
    if (this.hasMultiplePictures) {
      this.startLoop();
    } else {
      this.stopLoop();
    }
  }
}
