
import url from "url";
import { imageSync } from "qr-image";
import { Component, Vue, Prop } from "@feathers-client";
import { downloadFile } from "domore-table/exportExcel";
import { FindPopRawType } from "@feathers-client";

@Component
export default class AppQrCode extends Vue {
  @Prop()
  shop: string;

  @Prop()
  url: string;

  @Prop()
  logo: string;

  @Prop()
  viewItem: string;

  @Prop()
  fullShop: FindPopRawType<["shopGroup"], "shops">;
  mfullShop: FindPopRawType<["shopGroup"], "shops"> = null;

  get finalShop() {
    return this.mfullShop || this.fullShop;
  }

  async mounted() {
    if (this.shop && !this.fullShop) {
      try {
        this.mfullShop = await this.$feathers.service("shops").get(this.shop, {
          query: {
            $populate: ["shopGroup"],
          },
        } as const);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  get qrcode() {
    if (!this.murl) return "";
    return "data:image/svg+xml;base64," + Buffer.from(imageSync(this.murl, { type: "svg" })).toString("base64");
  }

  async png() {
    const blob = new Blob([Buffer.from(imageSync(this.murl, { type: "png", size: 32 }))], { type: "image/png" });
    await downloadFile([blob], "qrcode.png", "image/png");
  }

  async svg() {
    const blob = new Blob([Buffer.from(imageSync(this.murl, { type: "svg" }))], { type: "image/svg+xml" });
    await downloadFile([blob], "qrcode.svg", "image/svg+xml");
  }

  get murl() {
    return this.url || this.urlFromShop;
  }

  get urlFromShop() {
    if (!this.finalShop) return "";
    let params = `/?id=${this.finalShop._id}`;
    if (this.viewItem) {
      params += `&t=${this.viewItem}`;
    }
    return url.resolve(this.$shop.getShopUrl(this.finalShop as any), params);
  }

  select(e: Event) {
    (e.target as HTMLInputElement).setSelectionRange(0, (e.target as HTMLInputElement).value.length);
  }
}
