
import { parseInt } from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
  @Prop({ type: Number, default: 1 })
  index: number;
  @Prop()
  position;
  @Prop()
  value: boolean;

  get inputValue() {
    return this.value && this.position;
  }
  set inputValue(v) {
    this.$emit("input", v);
  }

  @Prop()
  canvasWidth: number;

  @Prop()
  canvasHeight: number;

  get left() {
    return this.position ? this.position.x + this.position.width + 400 > this.canvasWidth : false;
  }

  get top() {
    return this.position ? this.position.y > 200 : false;
  }

  pointerOffset: number = 0;
  menu: any = null;

  mounted() {
    this.menu = this.$refs.menu as any;
  }

  @Watch("position")
  async onPosition() {
    await Vue.nextTick();
    const menu = this.$refs.menu as any;
    if (menu) {
      menu.updateDimensions();
      setTimeout(() => {
        this.onTopUpdated(menu.calculatedTop);
      }, 10);
    }
  }

  @Watch("menu.calculatedTop")
  onTopUpdated(v) {
    if (!v) {
      this.pointerOffset = 0;
      return;
    }
    const menuTop = parseInt(this.menu.calculatedTop);
    const menuContainer = this.$refs.refPane as HTMLElement;
    const menuPos = menuContainer.getBoundingClientRect();
    const menuCenter = menuPos.y + menuPos.height / 2;
    this.pointerOffset = Math.min(menuCenter - menuTop - 15, (this.menu.dimensions?.content?.height ?? 0) - 30);
  }

  get pointerY() {
    return this.pointerOffset + "px";
  }
}
