import { PrintJob } from "../../printJob";

export interface LabelCmdBase  {
    type: string
}

export type LabelCmd = 
    LabelRect |
    LabelQR |
    LabelBarcode |
    LabelText | 
    LabelImage
;

export interface LabelRect extends LabelCmdBase {
    type: 'rect'
    x: number
    y: number
    w: number
    h: number
}

export interface LabelText extends LabelCmdBase {
    type: 'text' | 'vtext'
    x: number
    y: number
    sizeW: number
    sizeH: number
    font: string
    text: string
    r: number
    align?: 'left' | 'center' | 'right'
}

export interface LabelQR extends LabelCmdBase {
    type: 'qrcode'
    x: number
    y: number
    ecc: string
    w: number
    r: number
    text: string
}

export interface LabelBarcode extends LabelCmdBase {
    type: 'barcode'
    x: number
    y: number
    h: number
    text: string
}

export interface LabelImage extends LabelCmdBase {
    type: 'image'
    x: number
    y: number
    w: number
    h: number
    data?: Uint8ClampedArray
    base64?: string
}

export interface LabelCounter {
    id: number
    step: number
    start: string
}

export interface LabelCanvasJob {
    w: number
    h: number
    count: number
    cmds: LabelCmd[]
    counters: Record<number, LabelCounter>
}


export class CanvasPrintJob extends PrintJob {

    constructor(name : string, public jobs: LabelCanvasJob[], public dpi: number, id? : string, opts? : any) {
        super(name, Buffer.alloc(0), id, opts);
    }
}
