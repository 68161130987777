
import { Component, Prop, Vue, Watch, PropSync, FindType, mixins } from "@feathers-client";
@Component
export default class QueueingMobilePanel extends Vue {
  @Prop() queueTicketGroupMapped: any;
  @Prop() currentGroup: FindType<"shopQueueingGroups">;
  @Prop({ type: Boolean, default: false }) loading: boolean;

  get disableButton() {
    return (
      this.loading ||
      (this.queueTicketGroupMapped.callingTicket && this.queueTicketGroupMapped.callingTicket.status === "calling")
    );
  }
}
