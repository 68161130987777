
import { Component, Prop, Vue } from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";

@Component({})
export default class MobileFinishOrder extends Vue {
  @Prop()
  session: TableSession;
}
