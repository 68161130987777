import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { getOptions } from "@feathers-client/util";
import { EntityTable, MapSubView, shapes } from "./utils";
import { TableView, TableItem } from "./view";
import { TableSession, TableSessionAction } from "./session";
import { Ingredient } from "./ingredient";
import moment from "moment";
import type { ObjectID } from "@db";
import type { PosScreenHandler } from "./posScreen";
import { supported as posScreenSupported } from "pos-printer/posScreen";
import { KdsProductManager } from "./kdsProduct";

@Component
export class TableManager extends mixins(
  EntityTable([
    { service: "ingredients/cached", field: "ingredient", cb: () => Ingredient },
    {
      service: "tableViews",
      field: "view",
      cb: () => TableView,
      sort: { order: 1, name: 1, _id: 1 },
    },
    {
      service: "tableViewItems",
      field: "viewItem",
      cb: () => TableItem,
      resolveParent: ((self: TableManager, item: Partial<TableItem["item"]>) => {
        return self.viewDict[`${item.view}`];
      }) as any,

      sort: { name: 1, _id: 1 },
    },
    {
      service: "tableSessions",
      field: "booking",
      cb: () => TableSession,
      resolveParent: ((self: TableManager, item: Partial<TableItem["item"]>) => {
        return self.viewDict[`${item.view}`];
      }) as any,

      filter: () => ({
        type: "dineIn",
        status: { $in: ["booking"] },
        bookedTime: { $gt: moment().subtract(1.5, "hour").toDate(), $lt: moment().add(1.5, "hour").toDate() },
      }),
    },
    {
      service: "tableSessions",
      field: "session",
      cb: () => TableSession,
      resolveParent: ((self: TableManager, item: Partial<TableItem["item"]>) => {
        return self.viewDict[`${item.view}`];
      }) as any,

      filter: { startTime: { $ne: null }, endTime: null, type: "dineIn", status: { $nin: ["booking"] } },
    },
    {
      service: "tableSessions",
      field: "takeAway",
      cb: () => TableSession,
      filter: {
        endTime: null,
        type: { $in: ["takeAway", "delivery"] },
      },
    },
    {
      service: "tableSessions",
      field: "dineInNoTable",
      cb: () => TableSession,
      filter: {
        endTime: null,
        type: { $in: ["dineInNoTable"] },
      },
    },
    {
      service: "tableSessionActions",
      field: "tableSessionAction",
      cb: () => TableSessionAction,
      filter: {
        status: "pending",
      },
    },
  ] as const),
) {
  shapes = shapes;

  takeAwaySession: TableSession = null;
  kdsHideDone = false;
  posScreenHandler: PosScreenHandler;

  clearSelect() {
    if (this.takeAwaySession) {
      this.takeAwaySession.selected = false;
      this.takeAwaySession = null;
    }
  }

  getFirstView() {
    if (!this.views.length) return this.addView();
    return this.views[0];
  }

  createTempView() {
    return this.addView({}, null, true);
  }

  init() {
    return this;
  }

  tick() {
    this.$emit("tick");
  }

  async longTick() {
    this.$emit("longTick");
    await this.reloadBooking();
  }

  tickTimer: any;
  longTickTimer: any;

  created() {
    this.$feathers.on("logout", this.logout);
    this.tickTimer = setInterval(this.tick, 1000);
    this.longTickTimer = setInterval(this.longTick, 1000 * 60 * 15);
    posScreenSupported().then(async v => {
      if (v) {
        const posScreen = await import("./posScreen");
        this.posScreenHandler = new posScreen.PosScreenHandler({
          parent: this,
        });
      }
    });

    // workaround for session type change
    this.$on("add:session", (session: TableSession) => {
      if (this.takeAwaySession && this.takeAwaySession.id === session.id) {
        this.takeAwaySession = session;
      }
    });
    this.$on("remove:dineInNoTable", (session: TableSession) => {
      if (this.takeAwaySession === session) {
        Vue.nextTick(() => {
          if (this.takeAwaySession === session) {
            this.takeAwaySession = null;
          }
        });
      }
    });
  }

  logout() {
    this.$destroy();
  }

  beforeDestroy() {
    (<any>this.$root.$options).$tableManager = null;
    if (this.tickTimer) {
      clearInterval(this.tickTimer);
      this.tickTimer = null;
    }
    if (this.longTickTimer) {
      clearInterval(this.longTickTimer);
      this.longTickTimer = null;
    }
  }

  getIngredientName(id: string | ObjectID<any>) {
    return this.ingredientDict[`${id}`]?.name ?? null;
  }

  getIngredientStock(id: string | ObjectID<any>) {
    return this.ingredientDict[`${id}`]?.currentStock ?? 0;
  }

  async clearAllFloorAllCurrentOrder() {
    for (let session of this.sessions.slice()) {
      try {
        if (session.status === "done" || session.status === "cancelled" || session.status === "void") {
          await session.atomic({
            endTime: new Date(),
          });
        } else {
          await session.cancelPending();
        }
      } catch (e) {
        console.warn(e);
      }
    }
    for (let session of this.takeAways.slice()) {
      try {
        if (session.status === "done" || session.status === "cancelled" || session.status === "void") {
          await session.atomic({
            endTime: new Date(),
          });
        } else {
          await session.cancelPending();
        }
      } catch (e) {
        console.warn(e);
      }
    }
    for (let session of this.dineInNoTables.slice()) {
      try {
        if (session.status === "done" || session.status === "cancelled" || session.status === "void") {
          await session.atomic({
            endTime: new Date(),
          });
        } else {
          await session.cancelPending();
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }

  _kdsProductManager: KdsProductManager;
  get kdsProductManager() {
    if (!this._kdsProductManager) {
      this._kdsProductManager = new KdsProductManager({
        parent: this,
      });
    }
    return this._kdsProductManager;
  }
}

Object.defineProperty(Vue.prototype, "$tableManager", {
  get(this: Vue) {
    return (
      (<any>this.$root.$options).$tableManager ||
      ((<any>this.$root.$options).$tableManager = new TableManager(getOptions(this.$root)).init())
    );
  },
});

declare module "vue/types/vue" {
  export interface Vue {
    $tableManager: TableManager;
  }
}
