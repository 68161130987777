
import { Vue, Component, Watch, FindType, Prop } from "@feathers-client";
import { getKey, KeyCache, sign } from "pos-printer/utils/cloudAuth";
import url from "url";

@Component
export default class Setup extends Vue {
  keyCache: KeyCache = null;
  qrImage: string = "";
  loaded = false;

  @Prop()
  keyName: string;

  async mounted() {
    this.$shop.init();
    await (this.$feathers as any).tryLogin();

    if (!this.$shop.castSetup) {
      this.keyCache = await getKey(this.keyName);
      const qrcode = await import("qrcode");
      const settingURL = url.resolve(
        window.location.href,
        `/posDevices/edit/?key=${encodeURIComponent(JSON.stringify(this.keyCache.jwk))}&type=ods`,
      );
      console.log("settingURL", settingURL);
      this.qrImage = await qrcode.toDataURL(settingURL, { width: 512 });
    }

    window.addEventListener("resize", this.resize);
    this.resize();
    this.loaded = true;
  }

  get rotate() {
    return this.$shop.odsSetting?.rotation || 0;
  }

  width = 0;
  height = 0;

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  get showWidth() {
    return this.rotate === 90 || this.rotate === 270 ? this.height : this.width;
  }

  get showHeight() {
    return this.rotate === 90 || this.rotate === 270 ? this.width : this.height;
  }

  get showLeft() {
    return (this.width - this.showWidth) / 2;
  }

  get showTop() {
    return (this.height - this.showHeight) / 2;
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
}
