
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID, VModel } from "@feathers-client";
import type { LangType } from "@feathers-client/i18n";
// @ts-ignore
import ItemPickerNew from "@feathers-client/components/ItemPickerNew.vue";

// @ts-ignore
import MenuListPicker, { SearchConfig } from "@feathers-client/components/MenuListPicker.vue";
import {
  getNameField,
  getNameFields,
  isTranslate,
  lookupType,
  convertFieldToSearch,
  isEnum,
} from "./plugin/utils";

@Component({
  components: {
    ItemPickerNew,
    MenuListPicker,
  },
})
export default class EditorObjectPickerNew2 extends Vue {
  @Prop()
  items: any[];

  mitems: any[] = [];

  get finalItems() {
    if (this.filter) {
      console.log(this.filter);
      return (this.items || this.mitems).filter((item) =>
        item[this.filterField]
          .map((it) => it.value.toLowerCase())
          .includes(this.filter.toLowerCase()),
      );
    }
    return this.items || this.mitems;
  }

  @Prop({ type: Boolean, default: true })
  light: boolean;

  @Prop()
  label: string;

  @Prop()
  path: string;

  @VModel()
  inputValue: any;

  @Prop(Boolean)
  multiple: boolean;

  @Prop({ type: Boolean, default: false })
  required: boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  returnsObject: boolean;

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop()
  args: any;

  @Prop()
  filter: string;

  @Prop()
  filterField: string;

  @Prop()
  name: SearchConfig[];

  @Prop()
  fields: SearchConfig[];

  @Prop(Boolean)
  resolve: boolean;

  mname: SearchConfig[] = null;
  mfields: SearchConfig[] = null;
  mlabel: LangType = null;

  beforeMount() {
    this.loadData();
  }

  async loadData() {
    if (this.path) {
      console.log({query: {
            ...(this.args || {}),
            $paginate: false,
          }})
      try {
        this.mitems = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
            $paginate: false,
          },
        });
        console.log(this.mitems);
      } catch (e) {
        const resp = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
          },
        });
        if (Array.isArray(resp)) {
          this.items = resp;
        } else if (Array.isArray(resp?.data)) {
          this.items = resp.data;
        }
      }
    }
  }
  @Watch("finalItems")
  async updateResolve() {
    console.log("updateResolve");
    if (this.resolve && this.path) {
      await this.$schemas.init();
      const refRoute = this.$schemas.lookupRoute(this.path);
      const refTable = refRoute?.item;
      if (!refTable) return;
      const nameField = getNameField(refTable);
      const nameFields = getNameFields(refTable);

      const extraFields = (refTable.schema?.fields || []).filter(
        (it) => it.name !== nameField?.name && !nameFields.find((f) => f.name === it.name),
      );

      this.mfields = extraFields
        .filter((it) => lookupType(it.type) === "string" && !isEnum(it) || isTranslate(it.type))
        .map((it) => convertFieldToSearch(it));

      if (nameFields?.length) {
        this.mname = nameFields.map((it) => convertFieldToSearch(it));
      } else if (nameField) {
        this.mname = [convertFieldToSearch(nameField)];
      }

      this.mlabel = { $t: refRoute.name };
    }
  }
}
