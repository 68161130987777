import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { ProductType } from "~/plugins/shop";
import { CartItem } from "@common/table/cart";
import { getProductStockLevel, StockLevel } from "~/common/table/util";

@Component({})
export default class MobilePickerBase extends Vue {
  @PropSync("session")
  sessionSync: TableSession;

  @PropSync("lastCart")
  lastCartSync: CartItem;

  @PropSync("editingCart")
  editingCartSync: CartItem;

  @Prop()
  currentPage: string;

  selectedCat: FindType<"categories"> = null;
  selectedSubCat: FindType<"subCategories"> = null;
  showingSubCat: Boolean = false;

  realCatPage = 0;
  productPage = 0;
  editingLine: ProductLine = null;
  tempKitchenOptions: { _id: string; name: LangArrType }[] = [];

  pickerOption = false;

  windowWidth = window.innerWidth;

  initialCategory() {
    this.selectedCat = null;
    this.selectedSubCat = null;
    this.showingSubCat = false;
  }

  get catPage() {
    return Math.max(0, Math.min(this.realCatPage, this.catListSource.length - 1));
  }

  set catPage(val: number) {
    this.realCatPage = val;
    this.productPage = 0;
  }

  get filteredCustomProducts() {
    if (!this.selectedCat) return [];
    if (!this.selectedSubCat) return [];

    if (this.selectedSubCat) {
      return Object.values(this.$shop.customProducts).filter(it => checkID(it.subCategory, this.selectedSubCat));
    }
    return Object.values(this.$shop.customProducts).filter(it => checkID(it.category, this.selectedCat));
  }

  get filteredProducts() {
    const cat = this.selectedCat;
    const subCat = this.selectedSubCat;
    const section = this.selectedSection;
    return this.$shop.products.filter(
      it =>
        (this.showingSubCat ? checkID(it.subCategory, subCat) : checkID(it.category, cat)) &&
        (!section || (it.sections || []).find(it => checkID(it, section))) &&
        !it.setOrderOrGiftOnly,
    );
  }

  get products() {
    return [...this.filteredCustomProducts, ...this.filteredProducts];
  }

  get productList() {
    return [...(this.products || []), ...new Array(1).fill(null)]; // FIXME: hardcoded to '1'
  }

  get selectedSection() {
    return this.sessionSync?.selectedSection;
  }

  get filteredCats() {
    if (!this.selectedSection) return this.$shop.cats;
    const result = _.filter(this.$shop && this.$shop.cats, it =>
      it.sections.some(jt => checkID(jt, this.selectedSection)),
    );
    this.selectedCat = (result && result[0] && result[0]._id) || "";
    return result;
  }

  get categories() {
    return this.filteredCats;
    // return _.chunk(this.filteredCats, 6);
  }

  get subCats() {
    return this.selectedCat ? this.$shop.subCats.filter(it => checkID(it.category, this.selectedCat)) : [];
  }

  get catPickerList() {
    return [...this.catListSource, null, null, null, null, null, null].slice(0, this.showingSubCat ? 5 : 6);
  }

  get catListSource() {
    return this.showingSubCat ? this.subCats : this.categories;
  }

  @Watch("showingSubCat")
  onWatchSubCat() {
    console.log("showingSubCat : ", this.showingSubCat);
    console.log("filteredProducts : ", this.filteredProducts);
    console.log("subCats : ", this.subCats);
  }

  selectCatIndex(i: number) {
    if (i === -1) {
      this.selectedCat = null;
      this.selectedSubCat = null;
      this.showingSubCat = false;
      return;
    } else if (this.showingSubCat && this.subCats.length) {
      const cat = this.catListSource[i];
      if (!cat) return;
      this.selectedSubCat = cat as any;
      console.log("selected Sub-cat : ", this.selectedSubCat);
      this.$emit("navigate", "picker");
    } else {
      const cat = this.catListSource[i];
      if (!cat) return;
      this.selectedCat = cat as any;
      console.log("selected Cat : ", this.selectedCat);
      if (this.subCats.length) {
        this.showingSubCat = true;
      } else {
        this.$emit("navigate", "picker");
      }
    }
  }

  // selectCatIndex(i: number) {
  //   if (i === -1) {
  //     this.selectedCat = null;
  //     this.selectedSubCat = null;
  //     this.showingSubCat = false;
  //     return;
  //   } else if (this.showingSubCat && this.subCats.length) {
  //     console.log("selected Sub-cat : ", this.catListSource[i])
  //     const cat = this.catListSource[i];
  //     if (!cat) return;
  //     this.selectedSubCat = cat as any;
  //     this.$emit("navigate", "picker");
  //   } else {
  //     console.log("selected Cat : ", this.catListSource[i])
  //     const cat = this.catListSource[i];
  //     if (!cat) return;
  //     this.selectedCat = cat as any;

  //     if(this.subCats.length){
  //       this.showingSubCat = true;
  //       console.log("this Cat have subCat");
  //       this.$emit("navigate", "subCategories");
  //     }else{
  //       this.$emit("navigate", "picker");
  //     }
  //   }
  // }

  navPage(d: number) {
    this.catPage = Math.min(this.catListSource.length - 1, Math.max(0, this.catPage + d));
  }

  get currentCart() {
    return this.sessionSync?.cart ?? [];
  }

  isNotSelling(product: ProductType, checked: Set<string> = new Set()) {
    if (!product) return false;
    const isProduct = this.$shop.productDict[String(product._id)];
    if (!isProduct) return false;
    const level = getProductStockLevel(product, {
      session: this.sessionSync,
      productsDict: this.$shop.productDict,
      optionsDict: this.$shop.productOptionDict,
    });
    return level === StockLevel.OutOfStock || level === StockLevel.Disabled || level === StockLevel.NotSelling;
  }

  get totalQuantity() {
    return _.sumBy(this.currentCart, it => it.quantity) ?? 0;
  }

  async addToCart(i: number) {
    let cart: CartItem;
    let showOptions = false;
    const item = this.productList[i];
    console.log("added Item : ", item);
    if (!item) return;
    const isProduct = this.$shop.productDict[item._id];

    if (!isProduct) {
      cart = this.sessionSync.addToCart(
        {
          quantity: 1,
        },
        {
          swap: true,
          customProduct: item,
          mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
        },
      );
    } else {
      if (this.isNotSelling(item)) {
        const stock = item.stock;
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title:
              stock?.mode === "notSelling"
                ? this.$t("tableView.confirmNotSelling", { name: this.$td(item.name) })
                : this.$t("tableView.confirmOutOfStock", { name: this.$td(item.name) }),
          },
          {
            maxWidth: "400px",
          },
        );
        if (!c) return;
      }

      if (!item) return;
      cart = this.sessionSync.addToCartFromProduct(item, {
        swap: true,
        mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
      });
      if (item.options?.find?.(it => this.$shop.productOptionDict[String(it)]?.required)) {
        showOptions = true;
      }
    }

    this.lastCartSync = cart;
    if (showOptions) {
      this.editingCartSync = cart;
      this.$emit("navigate", "cartItem");
    }
  }

  clearCart() {
    if (this.sessionSync) {
      this.sessionSync.clearCart();
      this.lastCartSync = null;
    }
  }
}
