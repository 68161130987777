
import _ from "lodash";
import { ObjectID } from "@db";
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { getID, FindType } from "@feathers-client";
import MemberInfo from "./memberInfo.vue";
import MemberHistory from "./memberHistory.vue";
import TransactionList from "./transactionList.vue";
import CouponTicker from "../orderSystem/couponTicker.vue";
import PointRecord from "./pointRecord.vue";
import PointAdjustPanel from "./pointAdjustPanel.vue";

@Component({
  components: { MemberInfo, MemberHistory, TransactionList, CouponTicker, PointRecord, PointAdjustPanel },
})
export default class memberDetail extends Vue {
  @Prop()
  memberId: ObjectID;

  @Prop({ type: Boolean })
  fullScreen: boolean;

  @Prop({ type: Boolean })
  mobile: boolean;

  @Prop({ type: Boolean })
  simple: boolean;

  user: FindType<"appUsers"> = null;

  tab: string = "info";

  couponTab: string = "valid";

  pointTab: string = "point";

  get point() {
    return this.$shop.pointOrDollar.find(it => it.tag === "point");
  }

  get dollar() {
    return this.$shop.pointOrDollar.find(it => it.tag === "dollar");
  }

  adjustPoint: FindType<"shopPoints"> = null;

  get couponQuery() {
    return {
      status: this.couponTab,
      user: this.memberId,
      shop: this.$shop.shopId,
      $populate: ["coupon"],
    };
  }

  get couponSort() {
    return this.couponTab === "valid" ? { endDate: 1 } : { useDate: -1 };
  }

  get pointQuery() {
    return {
      point: this.pointTab === "point" ? getID(this.point) : getID(this.dollar),
      shop: this.$shop.shopId,
      ...(this.user ? { user: this.user } : {}),
      $populate: ["session"],
    };
  }

  get pointSort() {
    return { date: -1 };
  }

  async beforeMount() {
    this.user = await this.$feathers
      .service("appUsers")
      .get(getID(this.memberId), { query: { $populate: ["vipLevel", "ranks.rank"] } })
      .catch(e => {
        return null;
      });
  }

  get items() {
    return [
      { _id: "info", name: this.$t("vipMemberships.memberDetail.memberInfo.$") },
      { _id: "history", name: this.$t("vipMemberships.memberDetail.memberHistory.$") },
      { _id: "coupon", name: this.$t("vipMemberships.memberDetail.coupon.$") },
      { _id: "point", name: this.$t("vipMemberships.memberDetail.point.$") },
    ];
  }

  get couponType() {
    return [
      { _id: "valid", name: this.$t("enum.status.valid") },
      { _id: "used", name: this.$t("vipMemberships.memberDetail.coupon.usedRecord") },
    ];
  }

  get pointType() {
    return [
      ...(this.point ? [{ _id: "point", name: this.$td(this.point.name) }] : []),
      ...(this.dollar ? [{ _id: "dollar", name: this.$td(this.dollar.name) }] : []),
    ];
  }

  async onAdjust() {
    this.adjustPoint = this.pointTab === "point" ? this.point : this.dollar;
  }

  get avatar() {
    return (this.user.avatar && this.$image(this.user.avatar)) || require("~/assets/images/avatar.png");
  }

  get rank() {
    const rank = this.user.ranks?.[0]?.rank;
    if (!rank) return null;
    return this.$shop.shopRanks[getID(rank)];
  }
}
