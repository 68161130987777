import { PrintJob } from "../../printJob";

export interface ThermalCmdBase {
  type: string;
}

export type ThermalCmd =
  | ThermalText
  | ThermalMultiText
  | ThermalTable
  | ThermalBeep
  | ThermalCut
  | ThermalCashBox
  | ThermalFeed
  | ThermalImage
  | ThermalQrcode;

export interface ThermalText extends ThermalCmdBase {
  type: "text";
  text: string;
  fontX?: number;
  fontY?: number;
  scaleX?: number;
  scaleY?: number;
  align: "left" | "right" | "center";
  bold?: boolean;
  color?: number;
  fontFamily?: string;
  italic?: boolean;
  pxSize?: number;
  yScale?: number;
}

export interface ThermalMultiText extends ThermalCmdBase {
  type: "multiText";
  parts: ThermalText[];
}

export interface ThermalTableItem {
  x: number;
  w: number;
  align: "left" | "right" | "center";
  text: string;
  bold?: boolean;
  color?: number;
  fontFamily?: string;
  italic?: boolean;
  inverted?: boolean;
  fontSize?: number;
  yScale?: number;
}

export interface ThermalTable extends ThermalCmdBase {
  type: "table";
  cols: ThermalTableItem[];
  fontX?: number;
  fontY?: number;
  scaleX?: number;
  scaleY?: number;
  bold?: boolean;
  color?: number;
  lines?: string[];
  fill?: string;
  fontFamily?: string;
  italic?: boolean;
  fontSize?: number;
  colPadding?: number;
  yScale?: number;
}

export interface ThermalBeep extends ThermalCmdBase {
  type: "beep";
  times?: number;
  duration?: number;
}

export interface ThermalCut extends ThermalCmdBase {
  type: "cut";
}

export interface ThermalCashBox extends ThermalCmdBase {
  type: "cashBox";
  which?: number;
  time?: number;
}

export interface ThermalFeed extends ThermalCmdBase {
  type: "feed";
  offset?: number;
}

export interface ThermalImage extends ThermalCmdBase {
  type: "image";
  w: number;
  h: number;
  data?: Uint8ClampedArray;
  base64?: string;
  align: "left" | "right" | "center";
  hiRes?: boolean | number;
}

export interface ThermalQrcode extends ThermalCmdBase {
  type: "qrcode";
  text?: string;
  size?: number;
  scale?: number;
  align: "left" | "right" | "center";
}

export interface ThermalCanvasJob {
  cmds: ThermalCmd[];
}

export class ThermalCanvasPrintJob extends PrintJob {
  constructor(
    name: string,
    public job: ThermalCanvasJob,
    id?: string,
    opts?: any,
    asJson?: boolean,
  ) {
    super(name, asJson ? Buffer.from(JSON.stringify(job)) : Buffer.alloc(0), id, opts);
  }
}
