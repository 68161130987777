
import { Component, Vue } from "@feathers-client";
import type { SchemaHelper } from "../plugin";
import EditorSinglePage from "../EditorSinglePage.vue";
import EditorTopMenu from "../EditorTopMenu.vue";
import type { EditorPageMixin } from "../mixins/EditorPageMixin";

@Component({
  components: {
    EditorSinglePage,
    EditorTopMenu,
  },
})
export default class SlideEditorHost extends Vue {
  get schemas() {
    return (this as any).$schemas as SchemaHelper;
  }

  mounted() {
    this.schemas.hasSlideEditorHost = true;
  }

  async save(panel: EditorPageMixin<any>) {
    await this.schemas.reportEditResult(panel, true);
  }

  async cancel(panel: EditorPageMixin<any>) {
    await this.schemas.reportEditResult(panel, false);
  }
}
