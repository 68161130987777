
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: { EditorTextField },
})
export default class ConfirmDialog extends mixins(Dialog) {
  @Prop({ default: "$delete" })
  icon: string;

  @Prop()
  title!: string;

  @Prop()
  desc: string;

  @Prop({ default: "text-light-text-secondary" })
  descClass: string;

  @Prop({ default: "!bg-light-action-primary-default !text-grey000" })
  confirmBtnClass: string;

  @Prop()
  reasonTitle: string;

  @Prop()
  confirmText: string;

  @Prop({ type: Boolean })
  confirmLock: boolean;

  cancelReason: string = "";

  mounted() {
    // setTimeout(() => this.modalResult(true), 2000);
  }
  get posMode() {
    switch (this.$shop.localOptions?.posMode ?? "auto") {
      case "auto":
        return this.$breakpoint.mdAndUp ? "tablet" : "mobile";
      case "tablet":
        return "tablet";
      case "mobile":
        return "mobile";
    }
  }
}
