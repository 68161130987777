
import { FindType } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";

@Component({
  components: {},
})
export default class Verify extends Vue {
  @Prop()
  group: FindType<"tableSessions/summaries/verify">[number];

  expanded = false;

  get items() {
    return this.group.items.reduce((acc, item) => acc + item.errors.length, 0);
  }
}
