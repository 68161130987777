
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, getID } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import { FindPopRawType, FindType } from "@feathers-client";
import moment from "moment";
import { checkID } from "@feathers-client";
import { CouponItem, GiftType, GiftRecordType } from "@common/table/coupon";
import { Screen } from "pos-printer/multiscreen";
import type CartPanel from "./cartPanel.vue";
import NestedRouter from "@feathers-client/components/NestedRouter.vue";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { ScannerEvent } from "pos-printer/scanner";
import { BOXS_CLOUD_URL } from "pos-printer/ports/cloud";

type CouponType = FindPopRawType<["coupon"], "userCoupons">;

function urlbase64(buf: Buffer | string) {
  return (typeof buf === "string" ? buf : buf.toString("base64")).replace(/\-/g, "+").replace(/_/g, "/");
}

@Component({
  components: {
    QrcodeStream,
  },
})
export default class OrderSystem extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  screen: Screen;

  @Prop({ type: Boolean })
  fromOrder: boolean;

  @Prop({ type: Boolean })
  preview: boolean;

  @Prop()
  pendingProducts: ProductLine[];

  @Ref()
  panel: CartPanel;

  @Ref()
  main: NestedRouter;

  checkID = checkID;

  peopleMenu = false;
  crmMenu = false;
  orderMenu = false;

  showCart = false;
  numPadMode = false;
  scanning = false;
  editUserId = "";
  editUserScreen = false;
  showMessage = false;
  message = "";

  get orderTypes() {
    return [
      this.$shop?.shopData?.openDineInNoTable ? [{ _id: "dineInNoTable", name: { $t: "pos.invoice.dineIn" } }] : [],
      this.$shop?.shopData?.openTakeAway ? [{ _id: "takeAway", name: { $t: "pos.invoice.takeAway" } }] : [],
    ].flat();
  }

  get requiredPermission() {
    const permissions = [`orderManage/tableSessionPlaceProducts`];

    const replaceProducts = this.session.getCartReplacingIds();

    if (replaceProducts.length) permissions.push(`orderManage/tableSessionEditProducts`);
    return permissions;
  }

  get splitted() {
    return (this.session.splittedPayments?.length ?? 0) > 0;
  }

  get currentScreen() {
    if (this.editUserScreen) {
      return "table-order-system-edit-user";
    } else if (this.crmMenu) {
      return "table-order-system-people-crm";
    } else if (
      !this.session.screenOverride &&
      (this.session.isFinish || this.session.voidingPayment) &&
      !this.session.postEditing
    ) {
      return "table-order-system-finish-order";
    } else if (this.session.isPayingSplit) {
      if (this.session.currentSplitPaid && this.session.payingSplitBill !== null) {
        return "table-order-system-split-bill-paid";
      } else {
        return "table-order-system-checkout";
      }
    } else if (this.isPaying) {
      if (this.splitted) {
        return "table-order-system-split-payment";
      } else {
        return "table-order-system-checkout";
      }
      // return "table-order-system-checkout";
    } else if (!this.session.section) {
      return "table-order-system-section";
    } else {
      return this.numPadMode ? "table-order-system-product-num-pad" : "table-order-system-picker";
    }
  }

  get selectedSection() {
    return this.session?.section;
  }
  set selectedSection(v) {
    this.session?.delaySave?.({
      section: v,
    });
  }

  get isPaying() {
    return (this.session.postEditing && this.session.screenOverride === "checkout") || this.session.status === "toPay";
  }

  get isOngoing() {
    return this.session.postEditing || this.session.status === "ongoing";
  }

  get availbleSwitchMode() {
    return this.isOngoing && this.orderTypes.length > 1;
  }

  loading = false;

  get checkBillRole() {
    return this.$shop.hasStaffRole("pos/checkout");
  }

  get canCheckBill() {
    return (
      (this.session.isNoTable
        ? (this.isOngoing || this.session.status === "toPay") &&
          (this.session.cartEmpty ? !!this.session.item._id : this.session.cartValid)
        : this.isOngoing && this.session.cartEmpty) && this.session.orderComfirmed
    );
  }

  get outstanding() {
    return this.session.outstanding;
  }

  async syncOrder() {
    if (!this.screen) return;
    this.screen.queue.ns("posScreen").call("setCart", {
      items: this.session.cart,
    });
  }

  async cancelTakeAwayOrder() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("pages.cancelOrder") + " ？ ",
        content: this.$t("pages.cancelOrderDialog"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        confirm: this.$t("pages.cancelOrder"),
      },
      {
        maxWidth: "500px",
      },
    );

    if (!c) return;
    try {
      if (this.loading) return;
      this.loading = true;
      await this.session.cancelPending();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loading = false;
    }
  }

  async printInvoice(printWithoutPayment = false) {
    if (!this.session.cartEmpty) {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("pos.cartIsNotEmptyPlaceNow"),
        },
        {
          maxWidth: "400px",
        },
      );
      if (c) {
        await this.confirmOrder(false);
      } else {
        return;
      }
    }
    if (this.session.status === "ongoing" || this.session.status === "toPay") {
      await this.session.restoreCoupons();
      await this.session.atomic(this.session.cachedPriceDetails);
      await this.session.printOrder({
        ongoingPayment: printWithoutPayment === true,
        user: true,
      });
    } else {
      await this.session.printOrder({ user: true });
    }
  }

  async confirmOrder(toPay = true) {
    const values = Object.values(this.session.queryStock());
    if (values.length) {
      const result = await this.$openDialog(
        import("~/components/dialogs/StockWarnDialog.vue"),
        {
          values,
        },
        {
          maxWidth: "520px",
        },
      );
      if (!result) return;
    }
    try {
      this.session.cancelSelect();
      if (this.session.selectingCartItem) {
        this.session.openProductMenu(null);
      }
      if (this.loading) return;
      this.loading = true;
      this.session.screenOverride = null;
      if (!this.session.item._id) {
        this.session.dirty = false;
        this.session.resumeSave();
        await this.session.atomic({
          ...this.session.item,
          ...this.session.cachedPriceDetails,
          checkBillTime: new Date(),
          staff: this.$shop.staffId as any,
          status: "ongoing",
        });
        if (await this.session.redeemGifts()) {
          this.session.updateCoupons();
          await this.session.atomic({
            ...this.session.cachedPriceDetails,
          });
        }
        this.session.startSubscribe();
      }
      await this.session.updateCachedInfo(true);
      if (!this.session.cartEmpty) {
        const result = await this.panel.placeOrderInner();
        if (result === false) return;
        await this.session.reload();
      }
      await this.session.confirmOrder(toPay);
      this.session.postEditing = false;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loading = false;
    }
  }

  async cancelBilling() {
    await this.session.atomic({
      status: "ongoing",
      checkBillTime: null,
    });
  }

  async addCoupon(coupon: CouponType) {
    const current = this.session.coupons.find(it => it.coupon && checkID(coupon, it.coupon));
    const result = current && !current.valid ? current : await this.session.tryApplyCoupon(coupon);
    if (result instanceof CouponItem) {
      if (result.error) {
        await result.tryFix();
      }
      if (result.error) {
        this.$store.commit("SET_ERROR", this.$td(result.error));
        result.remove();
      } else if (this.session.type === "dineIn") {
        try {
          this.session.updateCachedDetails();
          await this.session.save();
        } catch (e) {
          console.warn(e);
          this.$store.commit("SET_ERROR", this.$td("couponErrors.cannotApply"));
          result.remove();
        }
      }
      this.session.updateCoupons();
      await this.session.atomic({
        discounts: this.session.discounts,
        ...this.session.cachedPriceDetails,
      });
      this.session.syncOrder();
    } else {
      this.$store.commit("SET_ERROR", this.$td(result));
    }
  }

  async addGift(gift: GiftType) {
    const result = await this.session.tryApplyGift(gift);
    if (result.error) {
      await result.tryFix();
    }
    if (result.error) {
      this.$store.commit("SET_ERROR", this.$td(result.error));
      result.remove();
      return;
    } else if (this.session.type === "dineIn") {
      try {
        this.session.updateCachedDetails();
        await this.session.save();
      } catch (e) {
        console.warn(e);
        this.$store.commit("SET_ERROR", this.$td("couponErrors.cannotApply"));
        result.remove();
        return;
      }
    }
    if (this.session.item._id) {
      const record = await this.$feathers.service("gifts/redeem").create({
        gift: getID(gift),
        user: getID(this.session.user),
        shop: getID(this.$shop.shopData),
        session: getID(this.session.item._id),
      } as any);
      result.giftRecord = getID(record);
      for (let item of this.session.cart) {
        if (item.coupon === result) {
          item.fromCoupon = getID(result);
        }
      }
    }

    this.session.updateCoupons();
    await this.session.atomic({
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  handlingCode = false;

  async mounted() {
    await this.$feathers.service("actionLogs").create({
      session: getID(this.session.item._id),
      view: getID(this.session.view),
      staff: this.$shop.staffId,
      type: "tableManage/tableSessionViewOrder",
    });
    this.$scanner.registerHandler(this.onScanner);
  }

  beforeDestroy() {
    this.$scanner.unregisterHandler(this.onScanner);
  }

  async onScanner(item: ScannerEvent) {
    const code = `${item.code || ""}`.trim();
    if (!code) return;

    if (!this.isPaying && !this.isOngoing) {
      return;
    }

    if (this.handlingCode) {
      return;
    }

    this.handlingCode = true;
    const imeActive = item.imeActive;

    try {
      if (
        !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,8}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
          code,
        )
      ) {
        item.fallbackMessage = { $t: "scanner.invalidCode" };
        return;
      }

      const domains = [this.$config.selfOrder, this.$config.appDomain, BOXS_CLOUD_URL.slice(0, -1)].filter(it => !!it);

      const domain = domains.find(d => code.startsWith(d));

      const supacityUrl = "https://wallet.okinawa/u/";
      if (code.startsWith(supacityUrl)) {
        item.handled = true;
        const resp = await this.$feathers.service("payments/supacity/validate").create({
          token: code.substring(supacityUrl.length),
        });
        const user = await this.$feathers.service("appUsers").get(resp.user, {
          query: {
            $populate: ["vipLevel", "ranks.rank"],
          },
        } as const);
        await this.applyUser(user);
        this.session.supacityInfo = resp.info;
        return;
      }

      if (!domain) {
        console.warn("[cart] invalid domain", domains);
        item.fallbackMessage = { $t: item.imeActive ? "scanner.imeActive" : "scanner.invalidDomain" };
        return;
      }
      // self order url
      const u = code.substr(domain.length);
      const match = u.match(/\/(u|c|g|w)\/(.+)(?:\/)?(.+)?/);
      if (!match) {
        console.warn("[cart] invalid match");
        item.fallbackMessage = { $t: item.imeActive ? "scanner.imeActive" : "scanner.invalidCodeType" };
        return;
      }

      item.handled = true;

      const buf = Buffer.from(urlbase64(match[2]), "base64");
      const ts = moment.unix(buf.readUInt32LE(0));
      const isWallet = match[1] === "w";

      if (this.$features?.crm && isWallet) {
        const token = await this.$feathers.service("cloudWallet/authentication").create({
          walletAuthenticationToken: match[2],
        });

        if (!token.token) {
          this.$store.commit(
            "SET_ERROR",
            imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidWalletToken"),
          );
        }
        const [shopGroup, userId] = token.token.split("_");

        const user = await this.$feathers.service("appUsers").get(userId, {
          query: {
            $populate: ["vipLevel", "ranks.rank"],
          },
        } as const);

        if (user) {
          this.applyUser(user);
        }
      } else {
        const buf = Buffer.from(urlbase64(match[2]), "base64");
        const ts = moment.unix(buf.readUInt32LE(0));

        if (moment().subtract(5, "minutes").isAfter(ts)) {
          console.warn("[cart] expired");
          this.$store.commit("SET_ERROR", this.$t("basic.qrCodeTimeout"));
          return;
        }

        const isCoupon = match[1] === "c";
        const isGift = match[1] === "g";

        let ofs = 4;
        const uid = buf.slice(ofs, (ofs += 12));
        const cid = isCoupon ? buf.slice(ofs, (ofs += 12)) : null;
        const gid = isGift ? buf.slice(ofs, (ofs += 12)) : null;
        const hash = buf.slice(ofs);

        const [userP, couponP] = await Promise.allSettled([
          (async () => {
            return await this.$feathers.service("appUsers").get(uid.toString("hex"), {
              query: {
                $populate: ["vipLevel", "ranks.rank"],
              },
            } as const);
          })(),
          (async () => {
            if (!cid) return;
            return await this.$feathers.service("userCoupons").get(cid.toString("hex"), {
              query: {
                $populate: ["coupon"],
              } as const,
            });
          })(),
        ]);

        const user = userP.status === "fulfilled" ? userP.value : null;
        const coupon = couponP.status === "fulfilled" ? couponP.value : null;

        if (!user) {
          this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidCode"));
          return;
        }

        const supk: string = user.publicKey;
        if ((isCoupon && !coupon) || !user || !supk) {
          this.$store.commit("SET_ERROR", this.$t("scanner.invalidCode"));
          return;
        }

        if (user.vipLevel && user.status != "active") {
          this.$store.commit("SET_ERROR", this.$t("scanner.vipOutdate"));
          return;
        }

        // verify code sign

        if (!supk) {
          this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidUser"));
          return;
        }
        const pk = supk
          .slice(
            supk.indexOf("-----BEGIN PUBLIC KEY-----") + "-----BEGIN PUBLIC KEY-----".length,
            supk.indexOf("-----END PUBLIC KEY-----"),
          )
          .replace(/\n|\r/g, "");

        const upk = await crypto.subtle.importKey(
          "spki",
          Buffer.from(pk, "base64"),
          {
            name: "ECDSA",
            namedCurve: "P-256",
            hash: "SHA-256",
          },
          true,
          ["verify"],
        );

        if (
          !(await crypto.subtle.verify(
            {
              name: "ECDSA",
              hash: "SHA-256",
            },
            upk,
            hash,
            buf.slice(0, ofs),
          ))
        ) {
          this.$store.commit(
            "SET_ERROR",
            imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidUserSign"),
          );
          return;
        }

        await this.applyUser(user);

        // verify coupon status
        if (coupon) {
          await this.addCoupon(coupon);
        }

        if (isGift) {
          try {
            const gift = await this.$feathers.service("gifts").get(gid.toString("hex"), {
              query: {
                $populate: ["coupon"],
              },
            } as const);
            const resp = await this.$openDialog(
              import("./redeemGift.vue"),
              {
                gift,
                user,
                session: this.session,
              },
              {
                maxWidth: "max(50vw,500px)",
              },
            );
            if (resp) {
              if (resp.type === "redeem") {
                this.session.tempGifts.push(gift as any);
              } else if (resp.type === "coupon") {
                await this.addGift(gift);
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    } catch (e) {
      console.error(e);
      this.$store.commit("SET_ERROR", this.$t("scanner.notTheShop"));
    } finally {
      this.handlingCode = false;
    }
  }

  async applyUser(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers">) {
    if (this.session.user !== (user as any)?._id ?? null) {
      this.peopleMenu = true;
      this.session.setUser(user?._id ?? (null as any));
      if (user?.vipLevel && user.status === "active") {
        const vipLeve = user.vipLevel;
        await this.session.tryApplyVip(user.vipLevel);
      }
      if (user?.ranks && user?.ranks.length) {
        for (let rank of user?.ranks) {
          await this.session.tryApplyRank(rank.rank);
        }
      }

      await this.$feathers.service("actionLogs").create({
        session: getID(this.session.item._id),
        view: getID(this.session.item.view),
        staff: this.$shop.staffId,
        type: user ? "orderManage/tableSessionSelectMember" : "orderManage/tableSessionDeselectMember",
        detail: { user },
      });

      await this.session.atomic({
        user: user?._id ?? null,
        discounts: this.session.discounts,
        userName: this.session.userName ? this.session.userName : user.name ?? "",
        userPhone: this.session.userPhone ? this.session.userPhone : user.phone ?? "",
        userGender: user.gender ?? this.session.userGender,
        ...this.session.cachedPriceDetails,
      });
      this.session.syncOrder();
    }
  }

  @Prop()
  modalId: string;

  async closeDialog() {
    if (
      (this.session.checkoutFrom && (this.session.status === "ongoing" || this.session.status === "toPay")) ||
      this.session.singleItemCheckout
    ) {
      //todo singleItemCheckout cancel dialog
      const res = await this.$openDialog(
        import("~/components/dialogs/ConfirmDialog2.vue"),
        {
          icon: "$warning",
          iconClass: "w-12 h-12",
          title: this.$t("tableView.singleItemCheckout.unfinish"),
          desc: this.$t("tableView.singleItemCheckout.unfinishDesc"),
          cancelText: this.$t("tableView.singleItemCheckout.continue"),
          confirmText: this.$t("tableView.singleItemCheckout.cancel"),
        },
        {
          maxWidth: "400px",
        },
      );
      if (res) {
        this.session.singleItemCheckout = false;
        if (this.session.checkoutFrom) {
          const moved = await this.session.cancelSingleItemCheckout();
          if (!moved) return;

          let changedSessions: TableSession[] = [];
          for (let sesisonData of moved) {
            let newSession = this.$tableManager.addSession(sesisonData);
            if (newSession) {
              changedSessions.push(newSession);
            }
          }

          for (let sessionData of changedSessions) {
            await sessionData.updateCachedInfo();

            this.$emit("openOrder", sessionData);
            this.confirmOrder();
          }
        }
      }
      return;
    }
    if (this.session.selectingCartItem) {
      this.session.openProductMenu(null);
      return;
    }
    if (
      this.session.type === "takeAway" &&
      this.currentScreen === "table-order-system-picker" &&
      this.session.cart.length > 0
    ) {
      const confirmExit = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog2.vue"),
        {
          title: this.$t("mobile.backButtonPrompt.$"),
          content: this.$t("mobile.backButtonPrompt.content"),
        },
        {
          maxWidth: "80%",
        },
      );
      if (!confirmExit) return;
    }
    this.$emit("close");
    if (this.modalId) {
      this.$root.$emit("modalResult", { id: this.modalId });
    }
  }

  nextOrder(type) {
    this.$emit("nextOrder", type);
    if (this.modalId) {
      this.$root.$emit("modalResult", { id: this.modalId });
    }
  }

  editOrder() {
    if (this.session.splittedPayments?.length > 0) {
      this.cancelAllSplit();
    }
    this.session.screenOverride = "cart";
    this.session.postEditing = true;
  }

  async cancelAllSplit() {
    if (this.session.splittedPayments?.length > 0) {
      await this.session.forceResetSplitBills();
    }
    this.session.isPayingSplit = false;
  }

  searchKeyword = "";
  @Ref()
  searchContainer: HTMLElement;
  @Ref()
  search: HTMLInputElement;

  selectAll() {
    this.search?.select?.();
  }

  goSearch() {
    this.search?.blur?.();
    if (
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        this.searchKeyword,
      )
    ) {
      const code = this.searchKeyword;
      this.searchKeyword = "";
      this.$root.$emit("scanner", { code });
    }
  }

  onSessionTypeUpdate(type: string) {
    this.session.delaySave({
      type: type as any,
    });
    switch (type) {
      case "dineInNoTable":
        this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.dineInSuccess"));
        break;
      case "takeAway":
        this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.takeAwaySuccess"));
        break;
    }
  }
  @Watch("showMessage")
  onChangeShowMessage() {
    if (this.showMessage) {
      return new Promise<void>(resolve => {
        setTimeout(() => {
          this.showMessage = false;
          resolve();
        }, 2000);
      });
    }
  }

  get posCheckoutNewLayout() {
    return (
      this.$shop.shopData.posCheckoutNewLayout ||
      (this.$shop.shopData.enableBeepForIncomingOrder && this.$shop.shopData.enableManualConfirmOrder)
    );
  }
}
