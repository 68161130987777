
import { Component, Vue, Prop, mixins, FindType } from "@feathers-client";
import type { ProductType } from "~/plugins/shop";
import Dialog from "~/mixins/DialogTS";

@Component
export default class ProductStockNum extends mixins(Dialog) {
  @Prop()
  product: ProductType;

  @Prop()
  staff: FindType<"staffs">;

  quantityStr: string = "";
  quantity: number = null;

  async apply() {
    if (this.quantity !== null) {
      const newItem = await this.$feathers.service("productStocks").patch(this.product.stock._id, {
        quantity: this.quantity,
      });

      let productName = null;
      if (newItem.product) {
        productName = (await this.$feathers.service("products").get(newItem.product)).name;
      }

      await this.$feathers.service("actionLogs").create({
        staff: this.staff?._id,
        type: `inventory/editProductStatus`,
        detail: { type: "quantity", original: this.product.stock, current: newItem, productName },
      });
    }

    this.modalResult(true);
  }
}
