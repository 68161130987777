
import { Component, Vue, Prop } from "@feathers-client";
import Percentage from "./Percentage.vue";

@Component({
  components: {
    Percentage,
  },
})
export default class Overview extends Vue {
  @Prop() fontSize!: string
  @Prop() title!: string
  @Prop() latestAmount!: number
  @Prop() previousAmount!: number
  @Prop() type!: 'amount' | 'customer' | 'percentage'
  @Prop({default: false}) hidePrevious: boolean

  get formatting() {
    let latestAmount: number | string = null
    if (this.type == 'amount') {
      latestAmount = `${this.$price(this.latestAmount)}`
    }
    else if (this.type == 'customer') {
      latestAmount = `${this.latestAmount}`
    }
    else if (this.type == 'percentage') {
      latestAmount = `${(this.latestAmount * 100).toFixed(3)}`
    }

    return latestAmount
  }
}
