
import { Vue, Component, Prop } from "@feathers-client";
import { OdsSetting, OdsShop } from "./baseShop";
import { splitText } from "pos-printer/printSequence/rawLine";

@Component
export default class SingleShopRow extends Vue {
  @Prop()
  shop: OdsShop;

  @Prop()
  odsSetting: OdsSetting;

  @Prop()
  rows: number;

  @Prop()
  columns: number;

  get showTitle() {
    return !!(this.logo || this.titlePartsTranslated.length);
  }

  get style() {
    return {
      "--shop-color": this.shop.color || "#3B3838",
    };
  }

  get widthChars() {
    return 3 * 0.75 * this.columns;
  }

  get widthOffset() {
    return -40 + -8 * this.columns - 16;
  }

  get scale() {
    return 1 / this.rows;
  }

  get offset() {
    return -32 * this.rows - 16;
  }

  get titleOffset() {
    return this.titlePartsTranslated.length * -8 + (this.logo ? -8 : 0);
  }

  get titlePartChars() {
    const chars = splitText(this.titlePartsTranslated.join(""));
    const cnLen = chars.filter(it => it.charCodeAt(0) > 255).length;
    const enLen = chars.length - cnLen;

    return enLen * 0.75 + cnLen || 1;
  }

  get titleParts() {
    return [
      ...(this.odsSetting.showWaterBarName && this.shop.singleItem.waterBar
        ? [this.shop.singleItem.waterBar.name]
        : []),
      ...(this.odsSetting.showStatus ? [{ $t: `enum.basic.status.${this.shop.singleItem.status}` }] : []),
      ,
    ];
  }

  get titlePartsTranslated() {
    if (this.$store.state.secondaryLocale) {
      return this.titleParts.flatMap(it => [this.$td(it), this.$td(it, this.$store.state.secondaryLocale)]);
    } else {
      return this.titleParts.map(it => this.$td(it));
    }
  }

  get titleScaleY() {
    return this.logo ? -1 : 0;
  }

  get logo() {
    return this.odsSetting.showShopLogo && this.$shop.shopData?.logo;
  }
}
