
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
  @Prop({ type: Number, default: 0 })
  min: number;

  @Prop()
  value: number;

  @Prop(Number)
  softMax: number;

  @Prop(Boolean)
  readonly: boolean;

  @Prop()
  color: string;

  get mvalue() {
    return this.value || this.min || 0;
  }
  set mvalue(v) {
    this.$emit("input", v);
  }

  minus() {
    if (this.mvalue > this.min) this.mvalue--;
  }
  add() {
    this.mvalue++;
  }

  get valid() {
    return !(this.softMax !== undefined && this.mvalue > this.softMax);
  }
}
