
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { checkID, getID, FindType } from "@feathers-client";

@Component
export default class PointAdjustPanel extends Vue {
  @Prop()
  user: FindType<"appUsers">;

  @Prop()
  point: FindType<"shopPoints">;

  @Prop({ type: Boolean })
  fullScreen: boolean;

  action = "plus";

  amount: number = 0;

  remarks: string = "";

  async confirm() {
    const staff = await this.$shop.checkPermission(["crm/pointAdjustment"]);
    if (staff === false) return;

    await this.$feathers.service("users/points/adjust").create({
      point: getID(this.point),
      user: getID(this.user),
      amount: this.amount * (this.action === "plus" ? 1 : -1),
      message: this.remarks,
      type: "adjust",
    });

    await this.$feathers.service("actionLogs").create({
      staff: staff?._id || this.$shop.staffId,
      type: "crm/pointAdjustment",
      detail: {
        userId: this.user.userId,
        name: this.user.name,
        pointName: this.point.name,
        delta: this.amount * (this.action === "plus" ? 1 : -1),
        message: this.remarks,
      },
    });

    this.$emit("confirm");
  }
}
