var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$paymentManager.connectionCount)?_c('div',{staticClass:"d-flex gap-x-1 items-center rounded py-.5 px-2 relative",class:{ 'bg-surface-secondary-default': _vm.paymentSetup },attrs:{"role":"button"},on:{"click":function($event){_vm.paymentSetup = !_vm.paymentSetup}}},[_c('v-icon',{staticClass:"custom-icon w-3.5 h-3.5"},[_vm._v(_vm._s(_vm.$paymentManager.errorCount ? "$cardError" : _vm.$paymentManager.connectionSetupCount ? "$cardOk" : "$cardOff"))]),_c('span',{staticClass:"text-xs <md:hidden"},[_vm._v(_vm._s(_vm.$paymentManager.onlineCount)+" / "+_vm._s(_vm.$paymentManager.connectionSetupCount))]),_c('teleport-menu',{staticClass:"w-80",attrs:{"thumb":"start"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"pos-popup rounded-lg overflow-x-hidden max-h-50vh overflow-y-auto"},[_c('menu-list',[(_vm.$paymentManager.hasOctopus)?_c('menu-list-item',{attrs:{"text":_vm.$t('octopus.$'),"append-icon":_vm.$paymentManager.octopusError ? '$cardError' : _vm.$paymentManager.octopusConnected ? '$cardOk' : '$cardOff',"append-text":_vm.$paymentManager.octopusStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectOctopus}}):_vm._e(),(_vm.$paymentManager.hasBBMSL)?_c('menu-list-item',{attrs:{"text":"BBMSL","append-icon":_vm.$paymentManager.bbmslError ? '$cardError' : _vm.$paymentManager.bbmslConnected ? '$cardOk' : '$cardOff',"append-text":_vm.$paymentManager.bbmslStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectBBMSL}}):_vm._e(),(_vm.$paymentManager.hasHase)?_c('menu-list-item',{attrs:{"text":"HASE","append-icon":_vm.$paymentManager.haseConnected ? '$cardOk' : '$cardOff',"append-text":_vm.$paymentManager.haseStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectHase}}):_vm._e(),(_vm.$paymentManager.hasTurnCloud)?_c('menu-list-item',{attrs:{"text":"Turn Cloud","append-icon":_vm.$paymentManager.turnCloudError
                ? '$cardError'
                : _vm.$paymentManager.turnCloudConnected
                  ? '$cardOk'
                  : '$cardOff',"append-text":_vm.$paymentManager.turnCloudStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectTurnCloud}}):_vm._e(),(_vm.$paymentManager.hasRazer)?_c('menu-list-item',{attrs:{"text":"Razer","append-icon":_vm.$paymentManager.razerError ? '$cardError' : _vm.$paymentManager.razerConnected ? '$cardOk' : '$cardOff',"append-text":_vm.$paymentManager.razerStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectRazer}}):_vm._e(),(_vm.$paymentManager.hasSimplePaymentIntegration)?_c('menu-list-item',{attrs:{"text":"SimplePaymentIntegration","append-icon":_vm.$paymentManager.simplePaymentIntegrationError
                ? '$cardError'
                : _vm.$paymentManager.simplePaymentIntegrationConnected
                  ? '$cardOk'
                  : '$cardOff',"append-text":_vm.$paymentManager.simplePaymentIntegrationStatus,"iconClass":"w-6 h-6"},on:{"click":_vm.$paymentManager.connectSimplePaymentIntegration}}):_vm._e(),_vm._l((_vm.$paymentManager.methods),function(method){return _c('menu-list-item',{attrs:{"text":_vm.$td(method.name),"append-icon":method.error ? '$cardError' : method.connected ? '$cardOk' : '$cardOff',"append-text":method.statusText,"iconClass":"w-6 h-6"},on:{"click":method.openSettings}})})],2)],1)]},proxy:true}],null,false,264722251),model:{value:(_vm.paymentSetup),callback:function ($$v) {_vm.paymentSetup=$$v},expression:"paymentSetup"}})],1):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }