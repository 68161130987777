
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import Dialog from "domore-table/mixins/Dialog";
import { TableSession } from "~/plugins/table/session";
import moment from "moment";

@Component
export default class AddDialog extends mixins(Dialog()) {
  item: TableSession = null;
  hasRemarks = false;

  @Prop()
  currentItem: any;

  isEdit = false;

  get genderItems() {
    return [
      {
        _id: "male",
        name: { $t: "gender.maleShort" },
      },
      {
        _id: "female",
        name: { $t: "gender.femaleShort" },
      },
      {
        _id: "others",
        name: { $t: "gender.othersShort" },
      },
    ];
  }

  async beforeMount() {
    const session = new TableSession({
      parent: this,
    });
    this.item = session;
    if (this.currentItem) {
      this.datePlaceholder = false;
      this.timePlaceholder = false;
      if (this.currentItem.remarks) {
        this.hasRemarks = true;
      }
      this.isEdit = true;
    }
    await session.init(
      this.currentItem ?? {
        tables: [],
        type: "dineIn",
        status: "booking",
        desiredCapacity: 1,
        startTime: null,
        bookedTime: moment().add(1, "day").startOf("hour").toDate(),
        bookingSource: "phone",
      },
      true,
    );
    this.bookDate = moment(this.item.bookedTime).format("YYYY-MM-DD");
    this.bookTime = moment(this.item.bookedTime).format("HH:mm:ss");
  }

  beforeDestory() {}

  datePlaceholder = true;
  timePlaceholder = true;

  dateValid = true;
  timeValid = true;
  bookDate = "";
  bookTime = "";

  get currentDate() {
    return this.bookDate;
  }

  set currentDate(v) {
    this.bookDate = v;
    if (!v) {
      this.resetTime();
      return;
    }
    const m = moment(v + " " + this.currentTime);
    this.datePlaceholder = false;
    if (m.isValid()) {
      this.dateValid = true;
      this.item.bookedTime = m.toDate();
    } else {
      this.dateValid = false;
    }
  }

  get currentTime() {
    return this.bookTime;
  }

  set currentTime(v) {
    this.bookTime = v;
    if (!v) {
      if (typeof v !== "string") {
        this.resetTime();
      }
      return;
    }
    const m = moment(this.currentDate + " " + v);
    this.timePlaceholder = false;
    if (m.isValid()) {
      this.timeValid = true;
      this.item.bookedTime = m.toDate();
    } else {
      this.timeValid = false;
    }
  }

  resetTime() {
    this.datePlaceholder = true;
    this.timePlaceholder = true;
    this.dateValid = true;
    this.timeValid = true;
    this.item.bookedTime = moment().add(1, "day").startOf("hour").toDate();
    this.bookDate = moment(this.item.bookedTime).format("YYYY-MM-DD");
    this.bookTime = moment(this.item.bookedTime).format("HH:mm:ss");
  }

  applyUserInfo(item) {
    if (!item) return;
    this.item.item.userPhone = item.phone ?? this.item.item.userPhone;
    this.item.item.userGender = item.gender ?? this.item.item.userGender;
  }

  get targetRefs() {
    return this.item.item.tables.map(it => `${it.item}`);
  }

  set targetRefs(tables: string[]) {
    this.item.item.tables = tables.map(it => ({
      item: it,
      capacity: 0,
      split: -1,
    })) as any[];
  }

  async saveItem() {
    const staff = await this.$shop.checkPermission(["reservation/tableSessionEditBooking"]);
    if (staff === false) return;
    const item = await this.item.save();

    await this.$feathers.service("actionLogs").create({
      session: this.item._id,
      staff: staff?._id || this.$shop.staffId,
      type: `reservation/tableSessionEditBooking`,
      detail: { booking: item, isEdit: this.isEdit },
    });

    this.modalResult(this.item);
  }
}
