import { ProductOptionType, ProductType } from "./util";
import { checkID, getID } from "@common";
import _ from "lodash";
import { SessionBase } from "./sessionBase";
import { CartItem, ProductLine, ProductOptionSelect } from "./cart";
import uuid from "uuid/v4";
import Vue from "vue";
import { TargetSymbol } from "./productOptionSelectWithPrice";

export class AutoMerger {
  enabled = false;
  autoMergeProducts: Record<string, ProductType> = {};
  options: Record<string, ProductOptionType> = {};

  optionsToProducts: Record<string, SortedSet<string>> = {};
  productToOptions: Record<string, SortedSet<string>> = {};

  addProduct(product: ProductType) {
    if (!product.autoMerge) return;

    if (this.autoMergeProducts[getID(product)]) {
      this.removeProduct(product);
    }

    this.autoMergeProducts[getID(product)] = product;
    for (let option of product.options) {
      let cur = this.optionsToProducts[getID(option)];
      if (!cur) {
        cur = this.optionsToProducts[getID(option)] = createSet();
      }
      addSet(cur, getID(product));
    }
    this.enabled = true;
  }

  removeProduct(product: ProductType) {
    if (!this.autoMergeProducts[getID(product)]) return;
    const current = this.autoMergeProducts[getID(product)];
    delete this.autoMergeProducts[getID(product)];

    for (let option of current.options) {
      const products = this.optionsToProducts[getID(option)];
      if (!products) continue;
      if (!removeSet(products, getID(product))) {
        delete this.optionsToProducts[getID(option)];
      }
    }
    this.enabled = !!Object.keys(this.autoMergeProducts).length;
  }

  addOption(option: ProductOptionType) {
    if (this.options[getID(option)]) {
      this.removeOption(option);
    }

    this.options[getID(option)] = option;
    for (let item of option.options) {
      if (item.type !== "product") continue;
      let cur = this.productToOptions[getID(item.product)];
      if (!cur) {
        cur = this.productToOptions[getID(item.product)] = createSet();
      }
      addSet(cur, getID(option));
    }
  }

  removeOption(option: ProductOptionType) {
    if (!this.options[getID(option)]) return;
    const current = this.options[getID(option)];
    delete this.options[getID(option)];

    for (let item of current.options) {
      if (item.type !== "product") continue;
      const options = this.productToOptions[getID(item.product)];
      if (!options) continue;
      if (!removeSet(options, getID(option))) {
        delete this.productToOptions[getID(item.product)];
      }
    }
  }

  // WIP
  async tryMerge(session: SessionBase) {
    const allItems = [...(session.sessionData?.products || []), ...(session.cart || []).map(it => it.toLine())]
      .filter(it => it.status !== "cancel" && it.status !== "pending" && !(it as any).replacing)
      .map(it => [it, it.quantity] as const);

    const oldItems = new Set((session.sessionData?.products || []).map(it => it.id));
    const idToIdx = Object.fromEntries(allItems.map((it, idx) => [it[0].id, idx]));
    const autoMergeItems = allItems.filter(it => this.autoMergeProducts[getID(it[0].product)]);
    const autoMergeItemsByProduct = _.groupBy(autoMergeItems, it => getID(it[0].product));

    for (let i = 0; i < allItems.length; i++) {
      const [item, remain] = allItems[i];
      if (item.fromCoupon || item.fromProduct || !remain) continue;
      const mergeRoute = this.productToOptions[getID(item.product)];
      if (!mergeRoute?.set?.size) continue;

      for (let option of setValues(mergeRoute)) {
        const tryProducts = this.optionsToProducts[option];
        if (!tryProducts?.set?.size) continue;

        for (let product of setValues(tryProducts)) {
          if (!this.autoMergeProducts[product]) continue;

          const beforeTargets = (autoMergeItemsByProduct[product] || []).filter(it => idToIdx[it[0].id] < i).reverse();

          for (let target of beforeTargets) {
            const optionData = target[0].options.find(it => checkID(it, option));
            const selections = optionData?.selections ?? [];
          }
        }
      }
    }
  }

  async tryMergeCart(session: SessionBase, cart: CartItem[] = null) {
    if (!cart) {
      cart = session.cart;
    }

    const mergeProductStats: Record<string, MergeStat> = {};
    const productDeps: Record<string, Set<MergeStat>> = {};
    const cartStats = Object.fromEntries(
      cart.map((it, idx) => [
        it.id,
        {
          idx,
          line: it.id,
          quantity: it.quantity,
          quantityRatio: it.quantityRatio || 1,
          product: getID(it.product),
          fromProduct: it.fromProduct,
          raw: it.toLine(),
          fromLine: it.fromLine,
        } as LineStat,
      ]),
    );
    const parentToStats = _.groupBy(
      Object.values(cartStats).filter(it => it.fromProduct),
      it => it.fromProduct,
    );

    for (let item of cart) {
      if (
        item.overridePrice != null ||
        item.status === "pending" ||
        item.manualDiscount ||
        item.fromCoupon ||
        item.fromProduct ||
        item.mergeStatus === "skip"
      )
        continue;
      const mergeRoute = this.productToOptions[getID(item.product)];
      if (!mergeRoute?.set?.size) continue;
      for (let option of setValues(mergeRoute)) {
        if (
          this.options[option]?.allowedOrderType?.length &&
          !this.options[option]?.allowedOrderType?.includes?.(session?.sessionData?.type)
        ) {
          continue;
        }
        const products = this.optionsToProducts[option];
        if (!products?.set?.size) continue;
        for (let product of setValues(products)) {
          const productInfo = this.autoMergeProducts[product];
          if (!productInfo) continue;
          const sectionCheck = (productInfo.sections || []).find(it => checkID(it, item.section || session.sectionId));
          if (!sectionCheck) continue;
          const mergeKey = `${product}_${getID(item.section || session.sectionId)}`;
          let stats = mergeProductStats[mergeKey];
          if (!stats) {
            stats = mergeProductStats[mergeKey] = {
              product: productInfo,
              options: Object.fromEntries(
                productInfo.options
                  .filter(opt => this.options[getID(opt)])
                  .map(it => [
                    getID(it),
                    {
                      _id: getID(it),
                      line: new Set(),
                      options: this.options[getID(it)],
                      count: 0,
                    },
                  ]),
              ),
            };
          }
          const opt = stats.options[option];
          if (opt) {
            opt.line.add(cartStats[item.id]);
          }
          let deps = productDeps[item.id];
          if (!deps) {
            productDeps[item.id] = deps = new Set();
          }
          deps.add(stats);
        }
      }
    }

    const stats = Object.values(mergeProductStats);

    // max 100 trials
    const result: CartItem[] = [];
    for (let trial = 0; trial < 100; trial++) {
      for (let i = 0; i < cart.length; i++) {
        const stat = cartStats[cart[i].id];
        if (stat && stat.raw.id === cart[i].id) {
          stat.idx = i;
        }
      }
      for (let stat of stats) {
        this.updateLineIndex(stat);
      }
      stats.sort((a, b) => (a.minLine?.idx ?? 99999) - (b.minLine?.idx ?? 99999));
      let merged = false;
      for (let stat of stats) {
        if (!this.statCanMerge(stat)) continue;
        const combineResult = await this.combineCart(session, stat, cartStats, parentToStats, cart);
        if (combineResult) {
          merged = true;
          result.push(combineResult);
          break;
        }
      }
      if (!merged) break;
    }

    if (result.length) {
      return result;
    } else {
      return false;
    }
  }

  async tryMergeAddons(session: SessionBase, toCart: CartItem[] = null) {
    if (!toCart) {
      toCart = session.cart;
    }
    const optionsToLine: Record<string, Set<string>> = {};
    const idToCart = Object.fromEntries(toCart.map(it => [it.id, it]));
    const productToCart: Record<string, Set<CartItem>> = {};
    const result: CartItem[] = [];

    for (let i = 0; i < toCart.length; i++) {
      let item = toCart[i];

      if (this.autoMergeProducts[getID(item.product)]) {
        for (let option of item.options) {
          const optId = getID(option.options);
          const optionInfo = this.options[optId];
          if (!optionInfo) continue;

          const someProduct = optionInfo.options.find(it => it.type === "product");
          if (!someProduct) continue;

          let isFull = checkFull(item, optionInfo, option);
          if (isFull) continue;

          // backward group
          for (let optionValue of optionInfo.options) {
            if (optionValue.type !== "product") continue;
            if (isFull) break;

            const mergeKey = `${getID(optionValue.product)}_${getID(item.section || session.sectionId)}`;
            const cartToTry = productToCart[mergeKey];
            if (!cartToTry) continue;
            for (let cart of cartToTry) {
              if (isFull) break;

              let cur = cart;
              let flag = true;
              while(cur) {
                if (cur.id === item.id) {
                  flag = false;
                  break;
                }
                cur = idToCart[cur.fromProduct];
              }
    
              if (!flag) continue;

              cur = item;
              while (cur) {
                if (cur.id === cart.id) {
                  flag = false;
                  break;
                }
                cur = idToCart[cur.fromProduct];
              }

              if (!flag) continue;

              const resp = await this.mergeAddons(
                item,
                cart,
                optionInfo,
                optionValue,
                session,
                toCart,
                optionsToLine,
                idToCart,
              );
              if (resp) {
                result.push(item);
                cartToTry.delete(cart);
                if (resp.item) {
                  cartToTry.add(resp.item);
                }
                isFull = checkFull(item, optionInfo, option);
              }
            }
          }
          if (!isFull) {
            const optionMergeKey = `${optId}_${getID(item.section || session.sectionId)}`;
            (optionsToLine[optionMergeKey] = optionsToLine[optionMergeKey] || new Set()).add(item.id);
          }
        }
      }

      if (
        item.overridePrice != null ||
        item.status === "pending" ||
        item.manualDiscount ||
        item.fromCoupon ||
        item.fromProduct ||
        item.mergeStatus === "skip"
      )
        continue;

      const mergeRoute = this.productToOptions[getID(item.product)];
      if (!mergeRoute?.set?.size) continue;

      let remainQty = item.quantity;

      for (let option of setValues(mergeRoute)) {
        const optionInfo = this.options[option];
        const optionValueInfo = optionInfo.options.find(it => checkID(it.product, item.product));

        if (!optionValueInfo) continue;

        const optioMergeKey = `${option}_${getID(item.section || session.sectionId)}`;
        const idToCheck = Array.from(optionsToLine[optioMergeKey] || []).reverse();

        for (let line of idToCheck) {
          if (!remainQty) break;
          const targetCart = idToCart[line];
          if (!targetCart) continue;

          let cur = targetCart;
          let flag = true;
          while(cur) {
            if (cur.id === item.id) {
              flag = false;
              break;
            }
            cur = idToCart[cur.fromProduct];
          }

          if (!flag) continue;

          cur = item;
          while (cur) {
            if (cur.id === targetCart.id) {
              flag = false;
              break;
            }
            cur = idToCart[cur.fromProduct];
          }

          if (!flag) continue;

          const resp = await this.mergeAddons(
            targetCart,
            item,
            optionInfo,
            optionValueInfo,
            session,
            toCart,
            optionsToLine,
            idToCart,
          );
          if (resp) {
            result.push(targetCart);
            item = resp.item;
            remainQty = resp.remainQty;
          }
        }
        if (!remainQty) break;
      }

      if (remainQty) {
        const mergeKey = `${getID(item.product)}_${getID(item.section || session.sectionId)}`;
        (productToCart[mergeKey] = productToCart[mergeKey] || new Set()).add(item);
      }
    }

    function checkFull(item: CartItem, optionInfo: ProductOptionType, option: ProductOptionSelect) {
      return (
        item.quantity === 1 &&
        ((optionInfo.maxPick && option.values?.length >= optionInfo.maxPick) ||
          (optionInfo.maxQuantity && _.sumBy(option.selections, s => s.quantity) >= optionInfo.maxQuantity) ||
          (!optionInfo.allowSameValue &&
            !optionInfo.allowQuantity &&
            ((optionInfo.maxPick && option.values?.length >= optionInfo.maxPick) ||
              option.values?.length >= optionInfo.options.length)) ||
          (!optionInfo.multiple &&
            option.values?.length >= 1 &&
            optionInfo.options.find(o => checkID(o, option.values[0]))?.type === "product"))
      );
    }

    if (result.length) {
      for (let item of toCart.slice().reverse()) {
        await item.updateDeps();
      }
    }

    if (!result.length) {
      return false;
    }

    return result;
  }

  async mergeAddons(
    targetCart: CartItem,
    item: CartItem,
    optionInfo: ProductOptionType,
    optionValueInfo: ProductOptionType["options"][0],
    session: SessionBase,
    toCart: CartItem[],
    optionsToLine: Record<string, Set<string>>,
    idToCart: Record<string, CartItem>,
  ) {
    if (!toCart) {
      toCart = session.cart;
    }
    let tempCartVal = toCart;
    if (toCart === session.cart) {
      tempCartVal = null;
    }
    let qtyToAdd = item.quantity;

    const optionData = targetCart.productOptionsWithPrice.find(it => checkID(it.options, optionInfo));

    let addFunc: (cartItem: CartItem, qty: number, oldItem: CartItem) => Promise<void>;

    const currentOptionRaw = optionData[TargetSymbol];

    addFunc = async (cartItem, qty, oldItem) => {
      for (let i = 0; i < qty; i++) {
        const selection = {
          id: uuid(),
          _id: optionValueInfo._id,
          quantity: 1,
        };
        currentOptionRaw.selections.push(selection);
        if (!currentOptionRaw.values.find(i => checkID(i, optionValueInfo))) {
          currentOptionRaw.values.push(optionValueInfo._id);
        }
        const cur = oldItem;
        const curIdx = toCart.indexOf(oldItem);
        const offset = oldItem.moveTo(curIdx); // offset after all related items
        if (cur.quantity > cartItem.quantity) {
          oldItem = await session.cloneItem(oldItem, tempCartVal, curIdx + offset);
          oldItem.mergeSource = cur.mergeSource || cur.id;
          if (oldItem) {
            oldItem.quantity = cur.quantity - cartItem.quantity;
          }
        }
        cur.quantityRatio = cartItem.quantity;
        cur.quantity = 1 * cur.quantityRatio;
        this.applyOption(cur, cartItem, selection, optionInfo);
      }
    };

    if (optionInfo.allowQuantity) {
      if (optionInfo.maxQuantity) {
        qtyToAdd = Math.min(qtyToAdd, optionInfo.maxQuantity - optionData.totalQuantity);
      }
      if (
        optionInfo.maxPick === optionData.values.length &&
        !optionData.values.find(i => checkID(i, optionValueInfo))
      ) {
        qtyToAdd = 0;
      }

      const currentSelection = optionData.selectionItems.find(i => checkID(i.selection, optionValueInfo));

      if (
        currentSelection &&
        (!optionInfo.multiple || !optionInfo.allowSameValue || optionInfo.maxPick === optionData.values.length)
      ) {
        addFunc = async (cartItem, qty, oldItem) => {
          currentSelection.quantity += qty;
          // TODO: merge status
          oldItem.remove();
        };
      } else {
        addFunc = async (cartItem, qty, cur) => {
          const selection = {
            id: uuid(),
            _id: optionValueInfo._id,
            quantity: qty,
          };
          currentOptionRaw.selections.push(selection);
          if (!currentOptionRaw.values.find(i => checkID(i, optionValueInfo))) {
            currentOptionRaw.values.push(optionValueInfo._id);
          }
          cur.quantityRatio = cartItem.quantity;
          cur.quantity = qty * cur.quantityRatio;
          this.applyOption(cur, cartItem, selection, optionInfo);
        };
      }
    } else if (optionInfo.multiple) {
      if (optionInfo.allowSameValue) {
        if (optionInfo.maxPick) {
          qtyToAdd = Math.min(qtyToAdd, optionInfo.maxPick - optionData.values.length);
        }
      } else if (optionData.values.find(i => checkID(i, optionValueInfo))) {
        qtyToAdd = 0;
      } else {
        qtyToAdd = 1;
      }
    } else {
      if (optionData.values.find(i => checkID(i, optionValueInfo))) {
        qtyToAdd = 0;
      } else if (!optionData.values.length) {
        qtyToAdd = 1;
      } else if (optionInfo.options.find(i => checkID(i, optionData.values[0]))?.type === "option") {
        qtyToAdd = 1;
        addFunc = async (cartItem, qty, cur) => {
          const selection = {
            id: uuid(),
            _id: optionValueInfo._id,
            quantity: 1,
          };
          currentOptionRaw.selections = [selection];
          currentOptionRaw.values = [optionValueInfo._id];
          cur.quantityRatio = cartItem.quantity;
          cur.quantity = cur.quantityRatio;
          this.applyOption(cur, cartItem, selection, optionInfo);
        };
      } else {
        qtyToAdd = 0;
      }
    }

    if (!qtyToAdd) return;

    const quantityPerTarget = Math.min(qtyToAdd, Math.max(1, (qtyToAdd / targetCart.quantity) | 0));
    const useQuantity = Math.min(targetCart.quantity, Math.max(1, (item.quantity / quantityPerTarget) | 0));
    qtyToAdd = useQuantity * quantityPerTarget;

    const remainQty = item.quantity - qtyToAdd;
    const oldItem = item;
    item.mergeStatus = "mergedFrom";
    if (remainQty) {
      const curIdx = toCart.indexOf(item);
      const offset = item.moveTo(curIdx); // offset after all related items
      item = await session.cloneItem(item, tempCartVal, curIdx + offset);
      item.mergeSource = oldItem.mergeSource || oldItem.id;
      oldItem.quantity = qtyToAdd;
      item.quantity = remainQty;
    } else {
      item = null;
    }
    if (useQuantity !== targetCart.quantity) {
      const curIdx = toCart.indexOf(targetCart);
      const offset = targetCart.moveTo(curIdx); // offset after all related items
      const newItem = await session.cloneItem(targetCart, tempCartVal, curIdx + offset);
      newItem.mergeSource = targetCart.mergeSource || targetCart.id;
      newItem.quantity = targetCart.quantity - useQuantity;
      newItem.date = new Date(new Date(newItem.date).getTime() + 1);
      targetCart.quantity = useQuantity;
      idToCart[newItem.id] = newItem;
      for (let option of newItem.options) {
        const optId = getID(option.options);
        const optionMergeKey = `${optId}_${getID(targetCart.section || session.sectionId)}`;
        const set = optionsToLine[optionMergeKey];
        if (!set) continue;
        if (set.has(targetCart.id)) {
          set.add(newItem.id);
        }
      }
    }
    await addFunc(targetCart, quantityPerTarget, oldItem);

    return {
      item,
      remainQty,
      qtyToAdd,
    };
  }

  async tryMergeOrderedItems(session: SessionBase, dryRun = false) {
    const lines = (session.sessionData?.products || []).filter(
      it => it.status !== "cancel" && it.status !== "pending" && !(it as any).replacing,
    );
    if (!lines.length) return;
    const cancelledLines = (session.sessionData?.products || []).filter(
      it => it.status === "cancel" && !(it as any).replacing,
    );

    let backupProducts: CartItem[];
    if (!dryRun) {
      backupProducts = session.cart.slice();
      session.cart.splice(0, session.cart.length);
    } else {
      lines.push(...session.cart.map(it => it.toLine() as any));
    }

    const lineItems = await session.addFromJSONs(lines, { temp: dryRun });
    const tempCart = dryRun ? lineItems : null;
    if (!dryRun) {
      for (let item of backupProducts) {
        item.tempCart = backupProducts;
      }
      for (let product of backupProducts) {
        if (product.fromProduct) continue;
        await session.cloneItem(product);
      }
    }

    const mergeResult = await this.tryMergeCart(session, tempCart);
    const addonResult = await this.tryMergeAddons(session, tempCart);
    const dirty = !!(mergeResult || addonResult);

    if (dryRun) {
      let resultLines: ProductLine[];
      if (dirty) {
        const oldLineDict = Object.fromEntries(lines.map(it => [it.id, it]));

        let resultSet = new Set([...(mergeResult || []), ...(addonResult || [])]);
        resultSet = new Set(Array.from(resultSet).flatMap(it => it.relatedTree));

        for (let item of tempCart) {
          if (!resultSet.has(item) && !oldLineDict[item.id]) {
            resultSet.add(item);
          }
        }

        resultLines = Array.from(resultSet).map(it => it.toLine());
        for (let line of resultLines) {
          const src = oldLineDict[line.id] || oldLineDict[line.mergeSource];
          if (src) {
            _.defaults(line, src);
          }
        }
      }
      for (let item of tempCart) {
        item.$destroy();
      }
      return dirty && resultLines.length ? resultLines : null;
    }

    if (!dirty) {
      for (let item of session.cart) {
        item.$destroy();
      }
      session.cart.splice(0, session.cart.length);
      for (let item of backupProducts) {
        item.tempCart = null;
      }
      session.cart.push(...backupProducts);
      return;
    }

    for (let item of backupProducts) {
      item.$destroy();
    }

    const newLines = session.cart.map(it => it.toLine());
    newLines.unshift(...cancelledLines);
    const oldLineDict = Object.fromEntries(lines.map(it => [it.id, it]));

    for (let line of newLines) {
      const src = oldLineDict[line.id] || oldLineDict[line.mergeSource];
      if (src) {
        _.defaults(line, src);
      }
    }

    session.sessionData.products = [];

    return newLines;
  }

  updateLineIndex(stat: MergeStat) {
    let minLine: LineStat = null;
    for (let option of Object.values(stat.options)) {
      minLine = this.updateOptionQuantity(option, minLine);
    }
    stat.minLine = minLine;
  }

  private updateOptionQuantity(option: MergeOptionStat, minLine?: LineStat) {
    const values = new Set<string>();
    let count = 0;
    for (let line of option.line) {
      if (!minLine || line.idx < minLine.idx) {
        minLine = line;
      }
      count += line.quantity;
      values.add(line.product);
    }
    option.count = count;
    option.values = values;
    return minLine;
  }

  statCanMerge(stat: MergeStat) {
    let hasAnyChoice = false;
    for (let options of Object.values(stat.options)) {
      if (!options.options?.required) continue;
      const hasProduct = options.options.options.some(it => it.type === "product");
      const hasDefault = options.options.options.find(it => it.defaultSelected);
      if (!hasProduct) {
        if (!hasDefault) return false;
        continue;
      }
      let qty = options.count ?? 0;
      hasAnyChoice = true;
      if (!options.options.allowQuantity && !options.options.multiple) {
        qty = Math.min(qty, 1);
      }
      if (options.values.size < options.options.minPick) return false;
      if (options.options.maxPick && options.values.size > options.options.maxPick) {
        qty = Math.min(qty, options.options.maxPick);
      }
      if (options.options.allowQuantity) {
        if (options.options.maxQuantity && qty > options.options.maxQuantity) {
          qty = Math.min(qty, options.options.maxQuantity);
        }
        if (qty < options.options.minQuantity) return false;
      }
      // multiple is not checked yet

      if (!qty) return false;
    }

    return hasAnyChoice;
  }

  applyOption(
    cur: CartItem,
    cartItem: CartItem,
    selection: {
      id: string;
      _id: any;
      quantity: number;
    },
    optionInfo: ProductOptionType,
  ) {
    cur.fromProduct = cartItem.id;
    cur.fromOption = getID(optionInfo);
    cur.fromOptionSeq = cartItem.productOptionsWithPrice.findIndex(it => checkID(it.options, optionInfo));
    cur.fromSelection = selection.id;
    cur.fromChoice = getID(selection._id);
    cur.fromChoiceSeq = optionInfo.options.findIndex(it => checkID(it, selection));
  }

  async combineCart(
    session: SessionBase,
    stat: MergeStat,
    cartStats: Record<string, LineStat>,
    parentToStats: Record<string, LineStat[]>,
    targetCart: CartItem[],
  ) {
    // console.log('Try product', stat.product, quantity);

    const currentChoices: Record<string, number> = {};

    const selectedOptions = Object.values(stat.options)
      .map(option => {
        if (!option.options) return null;
        this.updateOptionQuantity(option);

        const hasProduct = option.options.options.some(it => it.type === "product");
        if (!hasProduct) {
          return null;
        }

        const lines = Array.from(option.line)
          .sort((a, b) => a.idx - b.idx)
          .filter(it => it.quantity - (currentChoices[it.line] || 0) > 0);

        let cur = option.options.minPick || option.options.minQuantity || 1;
        if (option.options.autoMergeGreedy) {
          cur = 1000;
        }
        const selections: {
          id: string;
          _id: string;
          quantity: number;
        }[] = [];

        for (let line of lines) {
          if (!option.options) continue;
          const optionId = option.options.options.find(it => it.type === "product" && checkID(it.product, line.product))
            ?._id;

          if (!optionId) continue;
          let qty = Math.min(line.quantity - (currentChoices[line.line] || 0), cur);
          if (qty && !option.options.allowQuantity && !option.options.allowSameValue) {
            qty = currentChoices[line.line] || 0 ? 0 : 1;
          }
          if (!qty) continue;
          cur -= qty;
          currentChoices[line.line] = (currentChoices[line.line] || 0) + qty;

          if (option.options.allowQuantity) {
            selections.push({
              id: uuid(),
              _id: getID(optionId),
              quantity: qty,
            });
          } else {
            for (let i = 0; i < qty; i++) {
              selections.push({
                id: uuid(),
                _id: getID(optionId),
                quantity: 1,
              });
            }
          }

          if (!qty) break;
        }

        if (option.options.required && !selections.length) {
          return false;
        }

        return {
          option: option._id,
          selections,
        };
      })
      .filter(it => it !== null);

    if (Object.values(selectedOptions).find(it => it === false)) {
      return false;
    }

    // maxiamize selection
    const quantity = Math.min(...Object.entries(currentChoices).map(([k, qty]) => (cartStats[k].quantity / qty) | 0));

    if (quantity < 0) {
      console.warn("Invalid merge qty");
      return false;
    }

    for (let [k, qty] of Object.entries(currentChoices)) {
      currentChoices[k] = qty * quantity;
    }

    const relatedItems = new Map<LineStat, number>();
    for (let [line, qty] of Object.entries(currentChoices)) {
      this.collectRelatedItems(relatedItems, cartStats[line], parentToStats, qty);
    }

    let minDate: Date;
    for (let item of relatedItems.keys()) {
      if (item.raw.date && (!minDate || item.raw.date < minDate)) {
        minDate = item.raw.date;
      }
    }

    const tempCart: CartItem[] = [];

    const id = uuid();
    const firstItem = relatedItems.keys().next().value?.raw;

    const rootProduct = await session.addFromJSON(
      {
        product: stat.product,
        quantity: quantity,
        options: selectedOptions,
        id,
        date: minDate,
        table: firstItem?.table,
        tableSplit: firstItem?.tableSplit,
      },
      { tempCart },
    );
    rootProduct.mergeStatus = "merged";
    for (let [item, qty] of relatedItems) {
      const cart = await session.addFromJSON(
        {
          ...item.raw,
          quantity: qty,
          fromProduct: id,
        },
        { tempCart, fromLine: item.fromLine, replacing: !!item.fromLine },
      );
      cart.mergeStatus = "mergedFrom";
    }

    for (let item of tempCart.reverse()) {
      await item.updateDeps();
    }
    await Vue.nextTick();

    const isInvalid = tempCart.some(it => !it.isValid);
    if (isInvalid) {
      console.warn("Invalid merge");
      for (let item of tempCart) {
        item.$destroy();
      }
      return false;
    } else {
      const mappedIds: Record<string, string> = {};

      const splitItems: [LineStat, CartItem][] = [];
      for (let [k, v] of relatedItems) {
        k.quantity -= v;
        let line = targetCart.find(it => it.id === k.raw.id);
        if (k.quantity) {
          mappedIds[k.line] = `${uuid()}`;
          const j = k.raw;
          j.mergeSource = j.mergeSource || j.id;
          j.id = mappedIds[j.id];
          j.fromCoupon = null;
          j.quantity = k.quantity;
          if (line) {
            line.quantity = k.quantity;
            line.id = mappedIds[line.id];
          } else {
            line = await session.addFromJSON(j, { swap: k.idx, tempCart: targetCart });
          }
          cartStats[j.id] = k;
          splitItems.push([k, line]);
        } else {
          if (line) {
            line.remove();
          }
        }
      }

      for (let [item, line] of splitItems) {
        const j = item.raw;
        j.fromProduct = mappedIds[j.fromProduct];
      }

      for (let item of tempCart) {
        if (item.fromProduct) continue;
        const minIndex = Array.from(relatedItems.keys())
          .map(it => it.idx)
          .reduce((a, b) => Math.min(a, b), Infinity);
        await session.confirmTempItem(item, minIndex, targetCart);
      }

      return rootProduct;
    }
  }

  collectRelatedItems(
    set: Map<LineStat, number>,
    line: LineStat,
    parentToStats: Record<string, LineStat[]>,
    qty: number,
  ) {
    if (set.has(line)) return;
    set.set(line, qty);
    for (let parent of parentToStats[line.product] || []) {
      this.collectRelatedItems(set, parent, parentToStats, qty * parent.quantityRatio);
    }
  }
}

interface MergeStat {
  minLine?: LineStat;
  product: ProductType;
  options: Record<string, MergeOptionStat>;
}

interface MergeOptionStat {
  _id: string;
  line: Set<LineStat>;
  options: ProductOptionType;
  count?: number;
  values?: Set<string>;
}

interface LineStat {
  idx: number;
  line: string;
  raw: ProductLine;
  fromLine?: ProductLine;
  quantity: number;
  quantityRatio: number;
  product: string;
  fromProduct: string;
}

interface SortedSet<T> {
  set: Set<T>;
  list: T[];
}

function createSet<T>(): SortedSet<T> {
  return {
    set: new Set(),
    list: null,
  };
}

function addSet<T>(set: SortedSet<T>, value: T) {
  set.set.add(value);
  set.list = null;
}

function removeSet<T>(set: SortedSet<T>, value: T) {
  set.set.delete(value);
  set.list = null;
  return set.set.size;
}

function setValues<T>(set: SortedSet<T>) {
  if (!set.list) {
    set.list = Array.from(set.set);
    set.list.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  }
  return set.list;
}
