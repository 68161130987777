import { PrintSequence } from "pos-printer/printSequence";
import { DevicePrintersInfo, init, PrinterServer, PrinterTag } from "pos-printer";
import _, { uniq } from "lodash";
import type { TableSession } from "~/plugins/table/session";
import type { FindType } from "@feathers-client";
import Vue from "vue";
import {
  KitchenViewOptions,
  OrderKitchenView,
  tableOrderSequencer as commonTableOrderSequencer,
  receiptSequencer as commonReceiptSequencer,
  tableOrderRecordSequencer as commonTableOrderRecordSequencer,
  printTemplate,
  taiwanTaxSequencer,
  ReviewJob,
  OrderKitchenViewData,
  QrLinkData,
  ReceiptJob,
  ReceiptInput,
  setTemplateLoader,
} from "@common/table/invoiceSequencer";
import type { TableSessionRef } from "../table";
import type { PrintJob } from "pos-printer/printJob";
import VueI18n from "vue-i18n";
import { checkID, getID } from "@feathers-client";
import { LabelSequence } from "pos-printer/labelSequence";
import { PrintQueue } from "pos-printer/printQueue";
// import type { LabelSequence } from "pos-printer/labelSequence";
import { PrinterConf } from "pos-printer/printers/base";
import uuid from "uuid/v4";
import type BBMSL from "pos-printer/payments/methods/bbpos";

const printerSelections: Map<string, string> = new Map();
const printerLastSelections: Map<string, string> = new Map();

export function clearSelection() {
  printerSelections.clear();
}

export async function wrapJob<
  T extends {
    sequence: TSeq;
    template?: string;
    templateName?: string;
    printerType?: "thermal" | "label";
    printerLocale?: string;
    jobFunc?: (job: PrintJob) => void | Promise<void>;
    jobId?: string;
    uniqueJobId?: string;
    printerTag?: string;
    printerId?: string;
    jobOptions?: any;

    printJob?: FindType<"sharePrintJobs">;
    shopPrinter?: FindType<"shopPrinters">;

    name?: string;
    jobName?: string;
    retryable?: boolean;
    flusherId?: string;
    tries?: number;
  },
  // TSeq extends PrintSequence | LabelSequence,
  TSeq extends PrintSequence,
>(
  context: Vue,
  templateNames: string | string[],
  func: (opts: T) => PrintJob | Promise<PrintJob> | void | Promise<void>,
  opts?: Partial<T>,
  printerIds?: string[],
  fullIds?: string[],
) {
  await initPrinter(context);

  if (!opts) {
    opts = {} as any;
  }
  let trialId = `${opts.tries || 0}_${printer.id}_${opts.flusherId || ""}_${uuid()}`;

  if (!printerIds && opts.printJob) {
    if (!opts.jobId) {
      opts.jobId = getID(opts.printJob._id);
    }
    if (!opts.uniqueJobId) {
      opts.uniqueJobId = getID(opts.printJob._id);
    }
  }

  if (opts.shopPrinter) {
    if (!opts.printerTag) {
      opts.printerTag = getID(opts.shopPrinter._id);
    }
    if (!opts.printerId) {
      if (opts.shopPrinter.printerType === "template") {
        opts.printerId = opts.shopPrinter.templateSetting?.find?.(j => j.device === context.$shop.device?._id)?.printer;
      } else {
        opts.printerId = opts.shopPrinter.localPrinter;
        if (opts.shopPrinter.subPrinters?.length) {
          printerIds = [opts.shopPrinter.localPrinter, ...opts.shopPrinter.subPrinters.map(it => it.printer)];

          switch (opts.shopPrinter.selectionMethod) {
            case "roundRobin":
              const last = printerLastSelections.get(getID(opts.shopPrinter));
              if (last && printerIds.includes(last)) {
                printerIds = printerIds
                  .slice(printerIds.indexOf(last) + 1)
                  .concat(printerIds.slice(0, printerIds.indexOf(last) + 1));
              }
              break;
            case "random":
              printerIds = _.shuffle(printerIds);
              break;
            case "loadBalance":
              const queues = _.orderBy(
                printer.queues.filter(it => printerIds.includes(it.id)),
                it => it.jobs.length,
              );
              printerIds = queues.map(it => it.id);
              break;
            case "failover":
              break;
          }

          const selection = printerSelections.get(getID(opts.shopPrinter));
          if (selection && printerIds.includes(selection)) {
            printerIds = printerIds
              .slice(printerIds.indexOf(selection))
              .concat(printerIds.slice(0, printerIds.indexOf(selection)));
          }

          fullIds = printerIds.slice();
          opts.printerId = printerIds[0];
        }
      }
    }
    if (!opts.printerType) {
      opts.printerType = opts.shopPrinter.contentType;
    }
    if (!opts.jobOptions) {
      opts.jobOptions = opts.shopPrinter.jobOptions;
    }
    if (!opts.template) {
      opts.template = getID(opts.shopPrinter.template);
    }
    if (!opts.printerLocale) {
      opts.printerLocale = opts.shopPrinter.locale;
    }
  }

  if (!opts.printerType) {
    opts.printerType = "thermal";
  }

  if (!opts.printerTag) {
    opts.printerTag = "default";
  }

  let queue: PrintQueue;
  if (printerIds) {
    for (let i = 0; i < printerIds.length; i++) {
      queue = printer.queues.find(it => it.connected && it.id === printerIds[i]);
      if (queue) {
        printerIds = printerIds.slice(i + 1);
        break;
      }
    }
    if (!queue) {
      while (printerIds.length) {
        const printerId = printerIds.shift();
        try {
          const tryQueue = await printer.getQueue(opts.printerType, true, opts.printerTag, printerId);
          if (tryQueue.connected) {
            queue = tryQueue;
            break;
          }
        } catch (e) {
          console.warn(e);
          continue;
        }
      }
    }

    if (!queue) {
      opts.printerId = null;
      if (fullIds?.length) {
        opts.printerId = fullIds[0];
      }
      if (!opts.printerId) {
        if (opts.jobId) {
          context.$feathers
            .service("sharePrintJobs")
            .patch(opts.jobId, {
              status: "error",
              lastError: "No printer available",
              errorDate: new Date(),
              $push: {
                history: { status: "error", date: new Date(), error: "No printer available", id: trialId },
              },
            })
            .catch(console.warn);
        }
        return;
      }
    }
  }

  trialId += `_p_${queue?.id}`;

  if (!queue) {
    try {
      if ((opts.shopPrinter as any)?.exportData) {
        queue = printer.getTestQueue(opts.printerType);
      } else {
        queue = await printer.getQueue(opts.printerType, true, opts.printerTag, opts.printerId);
      }
    } catch (e) {
      console.warn(e);
      if (opts.jobId) {
        context.$feathers
          .service("sharePrintJobs")
          .patch(opts.jobId, {
            status: "error",
            lastError: e.message,
            errorDate: new Date(),
            $push: {
              history: { status: "error", date: new Date(), error: e.message, id: trialId },
            },
          })
          .catch(console.warn);
      }
      return;
    }
  }

  if (opts.shopPrinter?._id) {
    printerSelections.set(getID(opts.shopPrinter), queue.id);
    printerLastSelections.set(getID(opts.shopPrinter), queue.id);
  }

  if (queue.printer.conf.type) {
    opts.printerType = queue.printer.conf.type as any;
  }

  const locale = opts?.printerLocale ?? context?.$store?.state?.settings?.printLocale ?? context.$i18n?.locale ?? "en";
  await context.$i18n?.loadLocale?.(locale);

  let templateName = Array.isArray(templateNames)
    ? opts.printerType === "label"
      ? templateNames[1] || templateNames[0]
      : templateNames[0]
    : templateNames;
  let templateItem: FindType<"printerTemplates"> = null;

  const templates: FindType<"printerTemplates">[] = [];
  if (opts?.template) {
    const template = context.$shop.printerTemplateDict[opts.template];
    if (template) {
      templates.push((templateItem = template));
    }
  }
  if (!templateItem && templateNames) {
    const names = Array.isArray(templateNames) ? templateNames : [templateNames];
    for (const name of names) {
      const template = context.$shop.printerTemplateDict[opts.printerType + "/" + name];
      if (template) {
        templates.push(template);
      }
    }
    if (templates.length) {
      templateItem = templates.find(it => it.tag === templateName);
      if (!templateItem) {
        const altName = Array.isArray(templateNames) ? templateNames[0] : templateNames;
        templateItem = templates.find(it => it.tag === altName);
      }
      if (!templateItem) {
        templateItem = templates[0];
      }
      if (templateItem) {
        templateName = templateItem.tag;
      }
    }
  }

  if (templateItem && templateItem.type) {
    opts.printerType = templateItem.type;
  }

  let collectDebug = false;

  if (
    !opts.printJob &&
    templateName &&
    context.$shop.shopData?.debugPrintJob &&
    context.$shop.shopData.debugPrintJob === templateName
  ) {
    collectDebug = true;
    opts.printJob = await context.$feathers.service("sharePrintJobs").create({
      type: opts?.shopPrinter?.type ?? templateName,
      status: "pending",
      shop: context.$shop.shopData._id,
      shopPrinter: opts.shopPrinter?._id,
      debugPlaceholder: true,
    });
    if (!printerIds && opts.printJob) {
      if (!opts.jobId) {
        opts.jobId = getID(opts.printJob._id);
      }
      if (!opts.uniqueJobId) {
        opts.uniqueJobId = getID(opts.printJob._id);
      }
    }
  }

  const template = templateItem?.template?.template ?? null;

  const vue = new Vue({
    parent: context,
    name: "PrintContext",
    i18n: new VueI18n({
      locale: locale,
      silentFallbackWarn: true,
      missing(locale, key, vm, values) {
        if (!Array.isArray(values)) values = values ? [values] : [];
        return context.$i18n.t(key, locale, ...values) as string;
      },
    }),
  });

  try {
    const sequence = queue.createSequence<PrintSequence>(vue);
    // await func({
    //   sequence,
    //   ...opts,
    // } as T);
    // const jobItem = await func(sequence, template, printerType);
    if (!opts.templateName) {
      opts.templateName = templateName;
    }
    const jobItem = ((await func({
      sequence,
      ...opts,
      template: template,
      printerType: opts.printerType,
    } as T)) ?? sequence.getJob(opts.name || `${opts.templateName || ""} ${opts.jobId || ""}`)) as PrintJob;
    if (!jobItem) return;
    if (opts.retryable === false) {
      jobItem.retryable = false;
    }

    await opts?.jobFunc?.(jobItem);

    // if (!jobItem) return;
    attachHandler(
      context,
      jobItem,
      queue,
      opts.jobId,
      context?.$shop?.shopData?.debugPrinter || collectDebug
        ? {
            data: sequence.debugData || {},
            template: getID(templateItem),
            templateTag: templateName,
          }
        : undefined,
      async () => {
        if (opts.retryable === false) {
          return false;
        }
        if (printerIds?.length) {
          opts.tries = (opts.tries || 0) + 1;
          wrapJob(context, templateNames, func, opts, printerIds, fullIds);
          return true;
        } else if (fullIds?.length && !queue.connected) {
          // push back to first printer if all printer disconnected
          opts.tries = (opts.tries || 0) + 1;
          wrapJob(context, templateNames, func, opts, [], fullIds);
        }
        return false;
      },
      async () => {
        if (opts.retryable === false) {
          return false;
        }
        if (printerIds?.length) {
          opts.tries = (opts.tries || 0) + 1;
          wrapJob(context, templateNames, func, opts, printerIds, fullIds);
          return true;
        }
        return false;
      },
      trialId,
    );
    if (context.$shop?.shopData?.debugPrinter || (opts.shopPrinter as any)?.exportData || collectDebug) {
      jobItem.debugData = {
        data: sequence.debugData || {},
        template: getID(templateItem),
        templateTag: templateName,
      };
    }
    await queue.print(jobItem);
    return jobItem;
  } catch (e) {
    console.warn(e);
    if (printerIds?.length) {
      return wrapJob(context, templateNames, func, opts, printerIds, fullIds);
    } else if (opts.jobId) {
      context.$feathers
        .service("sharePrintJobs")
        .patch(opts.jobId, {
          status: "error",
          lastError: e.message,
          errorDate: new Date(),
          $push: {
            history: { status: "error", date: new Date(), error: e.message, id: trialId },
          },
        })
        .catch(console.warn);
    }
  } finally {
    vue.$destroy();
  }
}

export function attachHandler(
  context: Vue,
  jobItem: PrintJob,
  queue: PrintQueue,
  jobId?: string,
  debugData?: any,
  errorCallback?: () => Promise<boolean>,
  disconnectCallback?: () => Promise<boolean>,
  trialId?: string,
) {
  if (jobId) {
    context.$feathers
      .service("sharePrintJobs")
      .patch(
        jobId,
        {
          queueDate: new Date(),
          status: "queued",
          jobName: jobItem.name,
          ...(debugData
            ? {
                debugData,
              }
            : {}),
          $push: {
            history: { status: "queued", date: new Date(), id: trialId },
          },
        },
        {
          query: {
            status: { $ne: "done" },
          },
        },
      )
      .catch(console.warn);
    const disconnected = async () => {
      if (await disconnectCallback?.()) {
        queue.cancelJob(jobItem);
      }
    };
    jobItem.once("jobprinting", () => {
      context.$feathers
        .service("sharePrintJobs")
        .patch(
          jobId,
          {
            status: "printing",
            localPrinter: queue.printer.conf.id,
            $push: {
              history: { status: "printing", date: new Date(), id: trialId },
            },
          },
          {
            query: {
              status: { $ne: "done" },
            },
          },
        )
        .catch(console.warn);
    });
    jobItem.once("jobdone", () => {
      queue.printer.off("disconnected", disconnected);
      context.$feathers
        .service("sharePrintJobs")
        .patch(jobId, {
          status: "done",
          localPrinter: queue.printer.conf.id,
          doneDate: new Date(),
          $push: {
            history: { status: "done", date: new Date(), id: trialId },
            ...(jobItem.remoteId ? { remoteIds: jobItem.remoteId } : {}),
          },
        })
        .catch(console.warn);
    });
    jobItem.once("joberror", async () => {
      queue.printer.off("disconnected", disconnected);
      if (errorCallback) {
        if (await errorCallback()) {
          context.$feathers
            .service("sharePrintJobs")
            .patch(jobId, {
              $push: {
                history: { status: "error", date: new Date(), id: trialId, error: jobItem.lastError },
              },
              lastError: jobItem.lastError,
            })
            .catch(console.warn);
          return;
        }
      }
      context.$feathers
        .service("sharePrintJobs")
        .patch(jobId, {
          status: "error",
          localPrinter: queue.printer.conf.id,
          errorDate: new Date(),
          lastError: jobItem.lastError,
          $push: {
            history: { status: "error", date: new Date(), id: trialId, error: jobItem.lastError },
          },
        })
        .catch(console.warn);
    });
    queue.printer.once("disconnected", disconnected);
  }
}

export function getFailJobs(context) {
  var failJobs = [];
  const queues = printer?.queues || [];
  return queues.flatMap(q => q.allJobs.filter(it => it.status !== "done"));
}

export async function openCashBox(context, staff?) {
  await context.$feathers.service("actionLogs").create({
    staff: context.$shop.staffId ?? staff,
    type: `withDrawDeposit/openCashBox`,
  });
  return await wrapJob<any, PrintSequence>(
    context,
    null,
    ({ sequence }) => {
      sequence.cashBox(48, 50);
    },
    {
      jobFunc: async job => {
        job.cashBoxOnly = true;
      },
    },
  );
}

export async function staffQrCodePrinter(context: Vue, item: FindType<"staffs">) {
  return await wrapJob(context, null, ({ sequence }) => {
    sequence.center();
    sequence.printQR(String(item._id), 40, 10);
    sequence.feed(2).fontSize(1, 1);
    if (item.code) {
      sequence.text(item.code);
    }
    sequence.text(item.name || item.title);
    sequence.feed(10);
    sequence.cut();
    sequence.feed(1);
  });
}

export async function pickupLocationPrintForTable(context: Vue, session: TableSession, rePrint?: boolean) {
  return await wrapJob(
    context,
    "pickup",
    async (opts: { sequence: PrintSequence; data: OrderKitchenViewData }) => {
      await printTemplate<OrderKitchenViewData>({
        templateName: "pickup",
        ...opts,
      });
    },
    {
      data: {
        rePrint,
        job: {
          jobType: "table-kitchen",
          items: session.products
            .filter(it => it.status !== "cancel")
            .map(it => ({
              product: it,
              pickupLocation: context.$shop.productDict[getID(it.product)]?.pickupLocation,
              name: it.name,
              shortName: it.shortName,
              index: it.prodSeq,
              rootProduct: null,
            }))
            .filter(it => it.pickupLocation),
        },
      },
    },
  );
}

export async function kitchenSequencer(
  context: Vue,
  job: OrderKitchenView,
  printJob?: FindType<"sharePrintJobs">,
  shopPrinter?: FindType<"shopPrinters">,
  uniqueJobId?: string,
  flusherId?: string,
) {
  const jobName = shopPrinter ? context.$td(context.$shop.formatJobName(shopPrinter)) : "";
  return await wrapJob<any, any>(
    context,
    ["kitchen", "kitchenLabel"],
    commonTableOrderSequencer,
    // async ({sequence, template, printerType}) => {
    // if (
    //   !(await commonTableOrderSequencer({
    //     sequence,
    //     job,
    //     jobOptions,
    //     template,
    //     printerType,
    //   }))
    // ) {
    //   return null;
    // }
    // return sequence.getJob(
    //   `${job?.order?._id || "order"}:${job.items?.map?.(it => it.index)?.join?.(",")}:${printerName || ""}`,
    // );
    // },
    {
      name: `${shopPrinter ? `${context.$td(context.$shop.formatJobName(shopPrinter))}: ` : ""}${job.session
        ?.sessionName}, ${
        context.$td(job.items?.[0]?.name) || context.$td(job.items?.[0]?.shortName) || job.items?.[0]?.index
      }${job.items.length > 1 ? ` ...(${job.items.length - 1}+)` : ""}`,
      job,
      jobName,
      printJob,
      shopPrinter,
      uniqueJobId,
      flusherId,
    },
  );
}

export async function receiptSequencer(
  props: {
    context: Vue;
    printJob?: FindType<"sharePrintJobs">;
    shopPrinter?: FindType<"shopPrinters">;
    flusherId?: string;
  } & ReceiptInput,
) {
  return await wrapJob(props.context, "receipt", commonReceiptSequencer, props);
}

export async function tableSessionLinkSequencer(
  session: TableSession,
  table?: TableSessionRef,
  shopPrinter?: FindType<"shopPrinters">,
) {
  return await wrapJob(
    session,
    "qrLink",
    async opts => {
      await printTemplate<QrLinkData>({
        sequence: opts.sequence as any,
        templateName: "qrLink",
        printerType: opts?.printerType,
        template: opts?.template,
        data: {
          shop: session.shopData,
          session: session.sessionData,
          tableName: table
            ? session.getSplitName(table)
            : session.tableRefs?.length
              ? session.tableRefs.map(ref => session.getSplitName(ref)).join(",")
              : session.sessionData?.tableRefName,
          url: `${session.getOrderLink(table)}`,
          tableOnlineOrderPostfix: opts?.jobOptions?.tableOnlineOrderPostfix,
        },
      });
    },
    {
      shopPrinter,
    },
  );
}

export async function tableReceiptOrderOnlySequencer(
  context: Vue,
  job: ReviewJob,
  shopPrinter?: FindType<"shopPrinters">,
  printJob?: FindType<"sharePrintJobs">,
  flusherId?: string,
) {
  return await wrapJob<any, any>(context, "table-receipt", commonTableOrderRecordSequencer, {
    job,
    shopPrinter,
    printJob,
    flusherId,
  });
}

export async function printTwInvoice(props: {
  context: Vue;
  invoice: FindType<"twInvoices">;
  printDetails?: "only" | boolean;
  retryable?: boolean;
}) {
  props.retryable = false;
  // return await wrapJob(props.context, taiwanTaxSequencer, props);
  return await wrapJob(props.context, "taiwanTax", taiwanTaxSequencer, props);
}

let printer: PrinterServer;
let addNewTemplate: (it: Partial<FindType<"shopPrinters">>) => Promise<PrinterTag>;

export function getPrinterServer() {
  return printer;
}

export async function initPrinter(root: Vue) {
  if (!printer) {
    try {
      await root.$shop.loadShopTask;
    } catch (e) {
      console.warn(e);
    }
    printer = await init(root, root.$shop.storage, {
      localFonts: [
        {
          type: "local",
          name: "Noto Sans TC",
          url: require("!!file-loader!~/assets/fonts/noto-sans-tc-v36-chinese-traditional_cyrillic_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["chinese-traditional", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans TC",
          url: require("!!file-loader!~/assets/fonts/noto-sans-tc-v36-chinese-traditional_cyrillic_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["chinese-traditional", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans HK",
          url: require("!!file-loader!~/assets/fonts/noto-sans-hk-v32-chinese-hongkong_cyrillic_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["chinese-hongkong", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans HK",
          url: require("!!file-loader!~/assets/fonts/noto-sans-hk-v32-chinese-hongkong_cyrillic_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["chinese-hongkong", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans SC",
          url: require("!!file-loader!~/assets/fonts/noto-sans-sc-v37-chinese-simplified_cyrillic_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["chinese-simplified", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans SC",
          url: require("!!file-loader!~/assets/fonts/noto-sans-sc-v37-chinese-simplified_cyrillic_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["chinese-simplified", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans JP",
          url: require("!!file-loader!~/assets/fonts/noto-sans-jp-v53-cyrillic_japanese_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["japanese", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans JP",
          url: require("!!file-loader!~/assets/fonts/noto-sans-jp-v53-cyrillic_japanese_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["japanese", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans KR",
          url: require("!!file-loader!~/assets/fonts/noto-sans-kr-v36-cyrillic_korean_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["korean", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans KR",
          url: require("!!file-loader!~/assets/fonts/noto-sans-kr-v36-cyrillic_korean_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["korean", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans Thai",
          url: require("!!file-loader!~/assets/fonts/noto-sans-thai-v25-latin_latin-ext_thai-regular.woff2").default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["thai", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans Thai",
          url: require("!!file-loader!~/assets/fonts/noto-sans-thai-v25-latin_latin-ext_thai-700.woff2").default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["thai", "latin"],
        },
        {
          type: "local",
          name: "Noto Sans",
          url: require("!!file-loader!~/assets/fonts/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2")
            .default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["cyrillic", "cyrillic-ext", "devanagari", "greek", "greek-ext", "latin", "latin-ext", "vietnamese"],
        },
        {
          type: "local",
          name: "Noto Sans",
          url: require("!!file-loader!~/assets/fonts/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2")
            .default,
          variant: "700",
          weight: "700",
          style: "normal",
          subsets: ["cyrillic", "cyrillic-ext", "devanagari", "greek", "greek-ext", "latin", "latin-ext", "vietnamese"],
        },
        {
          type: "local",
          name: "Noto Emoji",
          url: require("!!file-loader!~/assets/fonts/noto-emoji-v50-emoji-regular.woff2").default,
          variant: "regular",
          weight: "regular",
          style: "normal",
          subsets: ["emoji"],
        },
      ],
    });
    printer.registerBitmap({
      tag: "LO",
      legacyId: 1,
      name: "Logo",
      width: 200,
      defaultImage: () => {
        if (root.$shop?.shopData?.logo) {
          return root.$image(root.$shop?.shopData?.logo);
        }
        return root.$config.appLogo || new URL(require("~/assets/images/logo.png"), location.toString()).toString();
      },
    });
    printer.formatTag = function (tag) {
      const j = root.$shop.shopPrinterDict[tag];
      if (!j) return null;
      return j.name || root.$shop.formatJobName(j as any);
    };
    printer.editTag = async function (tag, conf, device) {
      if (tag === "default") return;
      const item = root.$shop.shopPrinterDict[tag];
      if (!item) return;
      await root.$openDialog(
        import("~/components/dialogs/EditShopPrinterDialogNew.vue"),
        {
          printerId: tag,
        },
        {
          maxWidth: "80%",
          contentClass: "editor-dialog",
        },
      );
    };

    addNewTemplate = async function (it: Partial<FindType<"shopPrinters">>) {
      if (!it.printerType) {
        it.printerType = "local";
      }
      it = await root.$feathers.service("shopPrinters").create(it);
      root.$shop.shopPrinterDict[getID(it)] = it as any;

      return {
        tag: getID(it),
        name: it.name || root.$shop.formatJobName(it as any),
        type: it.type === "table-waterBar" || it.type === "table-kitchen" ? null : it.contentType,
        group:
          it.type === "table-kitchen"
            ? `table-kitchen_${it.kitchen}`
            : it.type === "table-waterBar"
              ? `table-waterBar_${it.waterBar}`
              : it.type === "table-receipt"
                ? "receipt"
                : it.type === "table-payment-receipt"
                  ? "payment-receipt"
                  : "template",
      } as PrinterTag;
    };

    printer.getAllGroups = async function () {
      return [
        {
          group: "template",
          name: { $t: "enum.pages.shopPrinters.printerType.template" },
          type: "thermal",
          portType: "local",
        },
        {
          group: "receipt",
          name: { $t: "enum.pages.shopPrinters.type.table-receipt" },
          add: addNewTemplate.bind(null, { type: "table-receipt" }),
          type: "thermal",
        },
        {
          group: "payment-receipt",
          name: { $t: "enum.pages.shopPrinters.type.table-payment-receipt" },
          add: addNewTemplate.bind(null, { type: "table-payment-receipt" }),
          type: "thermal",
        },
        ...root.$shop.kitchenPrinters.map(it => ({
          group: `table-kitchen_${it._id}`,
          name: it.name,
          add: addNewTemplate.bind(null, { type: "table-kitchen", kitchen: it._id }),
        })),
        ...root.$shop.waterBars.map(it => ({
          group: `table-waterBar_${it._id}`,
          name: it.name,
          add: addNewTemplate.bind(null, { type: "table-waterBar", waterBar: it._id }),
        })),
      ];
    };

    const templateTypes = ["table-receipt", "table-payment-receipt", "table-qr-link"] as const;

    printer.getAllTags = async function () {
      await root.$shop.cacheLoadPrinters();
      const types = new Set(
        Object.values(root.$shop.shopPrinterDict)
          .filter(it => it.printerType === "template")
          .map(it => it.type),
      );

      const tags: PrinterTag[] = Object.values(root.$shop.shopPrinterDict).map(it => ({
        tag: getID(it),
        name: it.name || root.$shop.formatJobName(it),
        type: it.type === "table-waterBar" || it.type === "table-kitchen" ? null : it.contentType,
        group:
          it.printerType === "template"
            ? "template"
            : it.type === "table-kitchen"
              ? `table-kitchen_${it.kitchen}`
              : it.type === "table-waterBar"
                ? `table-waterBar_${it.waterBar}`
                : it.type === "table-receipt"
                  ? "receipt"
                  : it.type === "table-payment-receipt"
                    ? "payment-receipt"
                    : "template",
        multiple: it.printerType === "local",
      }));

      for (let type of templateTypes) {
        if (!types.has(type)) {
          tags.push({
            tag: type,
            name: { $t: "enum.pages.shopPrinters.type." + type },
            type: "thermal",
            group: "template",
          });
        }
      }
      return tags;
    };
    printer.setPrinterTags = async function (conf: PrinterConf, tags: string[]) {
      const isDefault = (conf.tags || []).includes("default");
      const newIsDefault = tags.includes("default");
      if (isDefault !== newIsDefault) {
        Vue.set(conf, "tags", newIsDefault ? ["default"] : []);
        if (newIsDefault) {
          const anyOtherDefault = printer.confs.find(
            it => it.id !== conf.id && it.type === conf.type && it.tags?.includes("default"),
          );
          if (anyOtherDefault) {
            Vue.set(anyOtherDefault, "tags", []);
          }
        }
        printer.savePrinters();
      }
    };
    printer.getPrinterTagsWithDetails = function (conf: PrinterConf, device?: DevicePrintersInfo) {
      const isDefault = (conf.tags || []).includes("default");
      const deviceId = device?._id ?? root.$shop.device?._id;
      const tags = Object.values(root.$shop.shopPrinterDict)
        .filter(it => {
          if (it.printerType === "template") {
            const d = (it.templateSetting || []).find(j => j.device === deviceId);
            return d?.printer === conf.id;
          } else if (it.printerType === "local") {
            return (
              (it.device === deviceId && it.localPrinter === conf.id) ||
              it.subPrinters?.find(j => j.device === deviceId && j.printer === conf.id)
            );
          }
          return false;
        })
        .map(it => {
          if (it.printerType === "local" && it.subPrinters?.length) {
            const idx = it.subPrinters.findIndex(j => j.device === deviceId && j.printer === conf.id);
            if (idx !== -1) {
              return {
                tag: getID(it),
                index: idx + 2,
              };
            }
            return {
              tag: getID(it),
              index: 1,
            };
          }
          return {
            tag: getID(it),
          };
        });
      if (isDefault) {
        tags.push({ tag: "default" });
      }
      return tags;
    };
    printer.getCloudPrinterTags = function (item: FindType<"cloudPrinters">) {
      return Object.values(root.$shop.shopPrinterDict)
        .filter(it => it.printerType === "cloud" && checkID(it.printer, item))
        .map(getID);
    };
    printer.addTag = async function (tag: string, conf: PrinterConf, addingSub?: boolean) {
      let item = root.$shop.shopPrinterDict[tag];
      if (!item) {
        if (templateTypes.includes(tag as any)) {
          const r = await addNewTemplate({ type: tag as any, printerType: "template" });
          item = root.$shop.shopPrinterDict[r.tag];
        }
        if (!item) return;
      }

      if (item.printerType === "template") {
        const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
          printerType: "template",
          templateSetting: (item.templateSetting || [])
            .filter(j => j.device !== root.$shop.device?._id)
            .concat([
              {
                device: root.$shop.device?._id,
                printer: conf.id,
              },
            ]),
          jobOptions: {
            printQR: false,
            preferShortName: false,
            printProductOptionName: false,
            ...(item.type === "table-kitchen" || item.type === "table-waterBar"
              ? {
                  splitQuantity: true,
                  printNew: true,
                  printMove: true,
                  printCancel: true,
                  printReprint: true,
                  printEdit: false,
                }
              : {}),

            ...(item.type === "table-receipt" || item.type === "table-payment-receipt" ? { showUserInfo: false } : {}),

            ...(item.type === "table-payment-receipt"
              ? {
                  tablePrintToPayWhenCheckBill: false,
                  tableDontAutoPrintReceiptCash: false,
                  tableDontAutoPrintReceiptAll: false,
                  autoPrintCancelReceipt: false,
                  dontPrintZeroProduct: false,
                }
              : {}),

            ...(item.type === "table-qr-link"
              ? {
                  tableAutoPrintOnlineOrder: false,
                  tableAutoPrintOnlineOrderSplitTable: false,
                  tableOnlineOrderPostfix: "",
                }
              : {}),
          },
        });
        root.$shop.shopPrinterDict[getID(item)] = updated;
      } else {
        const toUpdate: Partial<FindType<"shopPrinters">> = {};

        if (addingSub) {
          item.subPrinters = item.subPrinters || [];
          const curSub = item.subPrinters.find(j => j.device === root.$shop.device?._id && j.printer === conf.id);
          if (curSub) {
            curSub.contentType = conf.type as any;
          } else {
            item.subPrinters.push({
              device: root.$shop.device?._id,
              printer: conf.id,
              contentType: conf.type as any,
            });
          }
          toUpdate.subPrinters = item.subPrinters;
        } else {
          toUpdate.device = root.$shop.device?._id;
          toUpdate.localPrinter = conf.id;
          toUpdate.contentType = conf.type as any;
          toUpdate.subPrinters = [];
        }

        const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
          printerType: "local",
          ...toUpdate,
          printer: null,
          jobOptions: {
            printQR: false,
            preferShortName: false,
            printProductOptionName: false,
            ...(item.type === "table-kitchen" || item.type === "table-waterBar"
              ? {
                  splitQuantity: true,
                  printNew: true,
                  printMove: true,
                  printCancel: true,
                  printReprint: true,
                  printEdit: false,
                }
              : {}),

            ...(item.type === "table-receipt" || item.type === "table-payment-receipt" ? { showUserInfo: false } : {}),

            ...(item.type === "table-payment-receipt"
              ? {
                  tablePrintToPayWhenCheckBill: false,
                  tableDontAutoPrintReceiptCash: false,
                  tableDontAutoPrintReceiptAll: false,
                  autoPrintCancelReceipt: false,
                  dontPrintZeroProduct: false,
                }
              : {}),

            ...(item.type === "table-qr-link"
              ? {
                  tableAutoPrintOnlineOrder: false,
                  tableAutoPrintOnlineOrderSplitTable: false,
                  tableOnlineOrderPostfix: "",
                }
              : {}),
          },
        });
        root.$shop.shopPrinterDict[getID(item)] = updated;
      }
    };
    printer.removeRemoteTag = async function (tag: string, conf: PrinterConf, device?: DevicePrintersInfo) {
      const item = root.$shop.shopPrinterDict[tag];
      if (!item) return;

      const curDeviceId = device?._id ?? root.$shop.device?._id;

      if (item.printerType === "template") {
        const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
          printerType: "template",
          templateSetting: (item.templateSetting || []).filter(j => j.device !== curDeviceId),
        });
        root.$shop.shopPrinterDict[tag] = updated;
      } else {
        if (
          item.printerType === "local" &&
          item.subPrinters?.find?.(sub => sub.device === curDeviceId && sub.printer === conf.id)
        ) {
          item.subPrinters = item.subPrinters.filter(j => j.device !== curDeviceId || j.printer !== conf.id);
          const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
            printerType: "local",
            subPrinters: item.subPrinters,
          });
          root.$shop.shopPrinterDict[tag] = updated;
        } else if (
          item.printerType === "local" &&
          item.subPrinters?.length &&
          item.device === curDeviceId &&
          item.localPrinter === conf.id
        ) {
          const firstSub = item.subPrinters.shift();
          const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
            printerType: "local",
            device: firstSub.device,
            localPrinter: firstSub.printer,
            contentType: firstSub.contentType,
            subPrinters: item.subPrinters,
          });
          root.$shop.shopPrinterDict[tag] = updated;
        } else {
          const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
            printerType: "local",
            device: null,
            localPrinter: null,
          });
          root.$shop.shopPrinterDict[tag] = updated;
        }
      }
    };
    printer.removeTag = printer.removeRemoteTag as any;
    printer.addCloudTag = async function (tag: string, printer: FindType<"cloudPrinters">) {
      const item = root.$shop.shopPrinterDict[tag];
      if (!item || item.printerType === "template") return;
      const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
        printerType: "cloud",
        device: null,
        localPrinter: null,
        printer: printer._id,
        contentType: printer.type,
      });
      root.$shop.shopPrinterDict[getID(item)] = updated;
    };
    printer.removeCloudTag = async function (tag: string, printer: FindType<"cloudPrinters">) {
      const item = root.$shop.shopPrinterDict[tag];
      if (!item || item.printerType === "template") return;
      const updated = await root.$feathers.service("shopPrinters").patch(item._id, {
        printer: null,
      });
      root.$shop.shopPrinterDict[getID(item)] = updated;
    };

    printer.addPrinter = async function (_printer) {
      const printerActive = !!printer.confs.length;
      if (root.$shop.localOptions.printerActive !== printerActive) {
        root.$shop.localOptions.printerActive = printerActive;
      }
    };
    printer.removePrinter = async function (_printer) {
      const printerActive = !!printer.confs.length;
      if (root.$shop.localOptions.printerActive !== printerActive) {
        root.$shop.localOptions.printerActive = printerActive;
      }
    };
    printer.removeRemoteDevice = async function (conf, device) {
      await root.$feathers.service("posDevices/removePrinter").create({
        device: device._id,
        printer: conf.id,
      });
      device.printers = device.printers.filter(j => j !== conf.id);
    };
    printer.queryDevices = async function (device?: string | string[]) {
      const devices = await root.$feathers.service("posDevices").find({
        query: {
          _id: { $ne: root.$shop.device?._id },
          ...(device
            ? {
                $or: [
                  {
                    _id: Array.isArray(device) ? { $in: device } : device,
                  },
                  {
                    printerActive: true,
                  },
                ],
              }
            : {
                printerActive: true,
              }),
          ...(root.$shop.shopId
            ? {
                shop: root.$shop.shopId,
              }
            : {}),
          $paginate: false,
        },
        paginate: false,
      });
      return devices.map(it => ({
        _id: it._id,
        name: it.name && it.name.find(j => j.value) ? it.name : it.shortId || it.kid,
        printers: it.localStorage?.printers ?? [],
      }));
    };
    printer.openTemplateEditor = async function (debugData, queue) {
      const { EditorPageMixinFactory } = await import("@schemaEditor/mixins/EditorPageMixin");

      const templateTable = new (EditorPageMixinFactory({
        path: "printerTemplates",
      }))({
        propsData: {
          nested: true,
        },
        parent: root,
      });

      try {
        let currentTemplate = debugData?.template
          ? (
              await root.$feathers.service("printerTemplates").find({
                query: {
                  _id: debugData.template,
                },
              })
            ).data[0]
          : null;
        if (!currentTemplate) {
          currentTemplate = {
            tag: debugData?.templateTag || "",
            type: queue.printer?.conf?.type,
          } as any;
        }
        if (!currentTemplate.template) {
          currentTemplate.template = {
            template: "",
            testData: "",
            testDatas: [],
          };
        }
        const { QueueSymbol } = await import("pos-printer-template/TemplateEditor.vue");
        currentTemplate.template.testData = JSON.stringify(debugData?.data || {}, null, 2);
        currentTemplate.template.testDatas = [JSON.stringify(debugData?.data || {}, null, 2)];
        currentTemplate.template[QueueSymbol] = queue;
        await templateTable.editItem(currentTemplate);
      } finally {
        templateTable.$destroy();
      }
    };
    await import("~/plugins/payments");
    let id = printer.id;
    Object.defineProperty(printer, "id", {
      get() {
        return root.$shop.device?._id || id;
      },
    });
    Object.defineProperty(printer, "serverName", {
      get() {
        if (root.$shop.device?.name?.find?.(j => j.value)) {
          return root.$shop.device.name;
        }
        return root.$shop.device?.shortId || root.$shop.device?.kid;
      },
    });
    Object.defineProperty(printer, "callBBPOS", {
      get() {
        const bbmsl = root.$paymentManager.getMethod("bbpos") as BBMSL;
        return bbmsl?.callPOS;
      },
    });
    Object.defineProperty(printer, "isBBPay", {
      get() {
        const bbmsl = root.$paymentManager.getMethod("bbpos") as BBMSL;
        return bbmsl?.isBBPay;
      },
    });
    Object.defineProperty(printer, "remotePOSPrint", {
      get() {
        const tc = root.$paymentManager.getTurnCloud();
        return tc?.posURL
          ? {
              print: tc.printerPrint,
              info: tc.printerInfo,
              init: tc.printerInit,
            }
          : null;
      },
    });
    setTemplateLoader(async (templateName: string) => {
      return root.$shop.printerTemplateDict[templateName]?.template?.template;
    });
  }
  return printer;
}

export async function managePrint(root: Vue, type?: string, printerTag?: string, allowAdd?: boolean) {
  await initPrinter(root);
  await printer.managePrint(type, printerTag, allowAdd);
}

export async function manageCloudPrint(root: Vue, type?: string) {
  await initPrinter(root);
  await printer.manageCloudPrint(type);
}

export async function ensureTemplateJob(context: Vue, type: string) {
  const currentJob = Object.values(context.$shop.shopPrinterDict).find(
    it => it.printerType === "template" && it.type === type,
  );
  let tag = type;
  if (currentJob) {
    tag = getID(currentJob);
  }
  const printer = await initPrinter(context.$root);
  try {
    const queue = await printer.getQueue("thermal", true, tag);
    if (queue) {
      const tags = printer.getPrinterTags(queue.printer.conf);

      for (let tag of tags) {
        if (context.$shop.shopPrinterDict[tag]?.type === type) return context.$shop.shopPrinterDict[tag];
      }
    }
  } catch (e) {
    console.warn(e);
  }
}
