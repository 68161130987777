
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import { getID } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import { checkID } from "@feathers-client";

@Component
export default class OrderSystemPartialPayment extends Vue {
  @Prop()
  session: TableSession;

  @VModel()
  partialPayment: number;

  @PropSync("isPartial")
  partialTabActive: boolean;

  splitPercentage: number = 1;
  isCustomSplit: boolean = false;
  splitPeopleNumStr = "";
  isEnteringSplitPeople: boolean = false;

  set splitPeopleNum(value) {
    // if(value > this.session.totalCapacity){
    //   value = this.session.totalCapacity
    // }
    if (value > 99) {
      value = 99;
    }
    if (value === 1) {
      value = 2;
    }
    if (value < 0) {
      value = 0;
    }
    this.session.splitPeopleNum = value;
  }

  get splitPeopleNum() {
    return this.session.splitPeopleNum;
  }

  get splitted() {
    return (this.session.splittedPayments?.length ?? 0) > 0;
  }

  get isPaid() {
    return this.session?.payments?.length > 0;
  }

  // get itemPickerCondition() {
  //   let paid = this.splitted;
  //   // let b = this.session.isPayingSplit;
  //   return paid;
  // }

  // @Watch("itemPickerCondition")
  // updateItemPickerCondition() {
  //   this.$emit("disableForSplit", this.splitted);
  // }

  partialPaymentStr = "";
  customPartial = false;

  get isCustomReceived() {
    return !this.percentageOptions.find(it => checkID(it, this.partialPayment));
  }

  // get partialValue() { return this.active ? 'partial' : this.isEqually ? 'equally' : 'fully' }
  // set partialValue(v) { this.active = ( v === 'partial' || v === 'equally' ) }
  customSplit() {
    this.isCustomSplit = true;
    this.splitPeopleNum = 0;
    // this.splitPeopleNumStr = this.splitPeopleNum.toString();
  }

  @Watch("session.partialPaymentType")
  onPartialValue(newValue, oldValue) {
    switch (newValue) {
      case "partial":
        this.partialTabActive = true;
        this.session.partialEqually = false;
        break;
      case "equally":
        this.partialTabActive = false;
        this.session.partialEqually = true;
        this.splitPeopleNum = 0;
        this.isCustomSplit = false;
        this.splitPeopleNumStr = "";
        this.isEnteringSplitPeople = false;
        break;
      default:
        this.partialTabActive = false;
        this.session.partialEqually = false;
        break;
    }
  }

  get partialItems() {
    return [
      {
        _id: "fully",
        name: this.session?.payments?.length
          ? this.$t("tableView.splitPayment.payOutstanding")
          : this.$t("tableView.splitPayment.fully"),
      },
      {
        _id: "partial",
        name: this.$t("tableView.splitPayment.partial"),
      },
      {
        _id: "equally",
        name: this.$t("tableView.splitPayment.split"),
      },
    ];
  }

  get percentageOptions() {
    const outstanding = this.session.outstanding;

    const per = [0.9, 0.75, 0.5, 0.25, 0.1];

    return _.uniqBy(
      [
        ...per.map(p => ({
          name: `${(p * 100).toFixed(0)}%`,
          _id: this.session.roundPrice(outstanding * p),
        })),
        // {
        //     name: `1/${this.session.capacity || 1}`,
        //     _id: this.session.roundPrice(outstanding * (1 / (this.session.capacity || 1)))
        // },
        // {
        //     name: `1/${(this.session.capacity || 1) - this.session.payments?.length ?? 0}`,
        //     _id: this.session.roundPrice(outstanding * (1 / ((this.session.capacity || 1) - this.session.payments?.length ?? 0)))
        // },
      ].filter(it => it._id !== outstanding && it._id > 0),
      it => it._id,
    );
  }

  get distributeOptions() {
    const outstanding = this.session.outstanding;
    return [
      {
        _id: 2, // this.session.roundPrice(outstanding * .5),
        name: this.$t("tableView.splitPayment.twoPerson"),
      },
      {
        _id: 3, // this.session.roundPrice(outstanding * (1/3)),
        name: this.$t("tableView.splitPayment.threePerson"),
      },
      {
        _id: 4, //this.session.roundPrice(outstanding * .25),
        name: this.$t("tableView.splitPayment.fourPerson"),
      },
      {
        _id: 5, //this.session.roundPrice(outstanding * .2),
        name: this.$t("tableView.splitPayment.fivePerson"),
      },
      // {
      //   _id: "other",
      //   name: this.$t("basic.others")
      // }
    ];
  }

  markAsTips() {
    this.session.tips -= this.session.outstanding - this.partialPayment;
  }

  // beforeMount(){
  //   if(this.session.splittedPayments.length){
  //       this.session.partialPaymentType = 'equally'
  //       this.partialTabActive = false;
  //       this.session.partialEqually = true;
  //   }
  // }

  getIconClass(item) {
    switch (item._id) {
      case "fully":
        return "$all";
      default:
        return "$partial";
    }
  }
}
