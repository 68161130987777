
export function Field(len = 1) {
    return function (target: UsbDescriptor, propertyKey: string) {
        if(!target.hasOwnProperty('fieldMappings')) target.fieldMappings = [...(target.fieldMappings || [])];
        target.fieldMappings.push([propertyKey, len]);
    };
}

export class UsbDescriptor {
    @Field()
    bLength: number
    @Field()
    bDescriptorType: number

    fieldMappings : [string,number][];

    constructor(public raw : Buffer) {
        let ofs = 0;
        if(!this.fieldMappings) return;
        for(let mapping of this.fieldMappings) {
            let val : number;
            switch(mapping[1]) {
                case 1: val = raw.readInt8(ofs); break;
                case 2: val = raw.readUInt16LE(ofs); break;
                case 4: val = raw.readUInt32LE(ofs); break;
                default: throw new Error("Invalid size" + mapping[0]);
            }
            this[mapping[0]] = val;
            ofs += mapping[1];
        }
    }
}

export class UsbDescriptorDevice extends UsbDescriptor {
    @Field()
    bcdUSBMinor: number
    @Field()
    bcdUSB: number
    @Field()
    bDeviceClass: number
    @Field()
    bDeviceSubClass: number
    @Field()
    bDeviceProtocol: number
    @Field()
    bMaxPacketSize0 : number

    @Field(2)
    idVendor: number
    @Field(2)
    idProduct: number

    @Field()
    bcdDeviceMinor: number
    @Field()
    bcdDevice: number

    @Field()
    iManufacturer: number
    @Field()
    iProduct: number
    @Field()
    iSerialNumber: number
    @Field()
    bNumConfigurations: number
}

export class UsbDescriptorConfiguration extends UsbDescriptor {
    @Field(2)
    wTotalLength : number

    @Field()
    bNumInterfaces: number
    @Field()
    bConfigurationValue: number
    @Field()
    iConfiguration: number
    @Field()
    bmAttributes: number
    @Field()
    bMaxPower: number
}

export class UsbDescriptorInterface extends UsbDescriptor {
    @Field()
    bInterfaceNumber: number
    @Field()
    bAlternateSetting: number
    @Field()
    bNumEndpoints: number
    @Field()
    bInterfaceClass: number
    @Field()
    bInterfaceSubClass: number
    @Field()
    bInterfaceProtocol: number
    @Field()
    iInterface: number
}

export class UsbDescriptorEndpoint extends UsbDescriptor {
    @Field()
    bEndpointAddress: number
    @Field()
    bmAttributes: number
    @Field(2)
    wMaxPacketSize: number
    @Field()
    bInterval: number
}

export class UsbDescriptorHID extends UsbDescriptor {
    @Field()
    bcdHidMajor : number
    @Field()
    bcdHid : number
    @Field()
    bCountryCode: number
    @Field()
    bNumDescriptors: number
    @Field()
    bDescriptorType: number
    @Field(2)
    wDescriptorLength : number
}

export function parseOne(buf : Buffer) : UsbDescriptor {
    switch(buf[1]) {
        case 0x1:
            return new UsbDescriptorDevice(buf);
        case 0x2:
            return new UsbDescriptorConfiguration(buf);
        case 0x4:
            return new UsbDescriptorInterface(buf);
        case 0x5:
            return new UsbDescriptorEndpoint(buf);
        case 0x21:
            return new UsbDescriptorHID(buf);
        default:
            return new UsbDescriptor(buf);
    }
}

export function parse(buf : Buffer) : UsbDescriptor[] {
    let i = 0;
    const results : UsbDescriptor[] = [];
    while(i < buf.length) {
        const len = buf[i];
        results.push(parseOne(buf.slice(i, i + len)));
        i += len;
    }
    return results;
}

interface HidGlobal {
    usage_page : number
    logical_minimum : number
    logical_maximum : number
    physical_minimum : number
    physical_maximum : number
    unit_exponent : number
    unit : number
    report_id : number
    report_size : number
    report_count : number
}

interface HidGlobal {
    usage_page : number
    logical_minimum : number
    logical_maximum : number
    physical_minimum : number
    physical_maximum : number
    unit_exponent : number
    unit : number
    report_id : number
    report_size : number
    report_count : number
}

interface HidLocal {
    usage : number
    usage_minimum : number
    usage_maximum : number
    designator_index : number
    designator_minimum : number
    designator_maximum : number
    string_index : number
    string_minimum : number
    string_maximum : number
    delimiter : number
}

export class HidField {
    constructor(public params : HidLocal & HidGlobal, public fieldType : number, public collection : HidCollection) {
        this.sizeInBits = params.report_count * params.report_size;
        this.sizeInBytes = Math.ceil(this.sizeInBits / 8);
    }

    sizeInBits : number;
    sizeInBytes : number;
}

export class HidCollection {
    collections : HidCollection[] = [];

    constructor(public parent : HidCollection, public collectionType : number) {
        if(parent) {
            parent.collections.push(this);
        }
    }
}

export class HidReportInfo {
    inputs : HidField[] = [];
    outputs : HidField[] = [];
    features : HidField[] = [];
    collections : HidCollection[] = [];

    inputsById : {
        [key : number]: HidField[]
    } = {};
    outputsById : {
        [key : number]: HidField[]
    } = {};
    featuresById : {
        [key : number]: HidField[]
    } = {};
    inputHasId = false;
    outputHasId = false;
    featureHasId = false;

    constructor(public raw : Buffer) {
    }

    process() {
        for(let input of this.inputs) {
            if(input.params.report_id) this.inputHasId = true;
            if(!this.inputsById[input.params.report_id]) this.inputsById[input.params.report_id] = [];
            this.inputsById[input.params.report_id].push(input);
        }

        for(let input of this.outputs) {
            if(input.params.report_id) this.outputHasId = true;
            if(!this.outputsById[input.params.report_id]) this.outputsById[input.params.report_id] = [];
            this.outputsById[input.params.report_id].push(input);
        }

        for(let input of this.features) {
            if(input.params.report_id) this.featureHasId = true;
            if(!this.featuresById[input.params.report_id]) this.featuresById[input.params.report_id] = [];
            this.featuresById[input.params.report_id].push(input);
        }
    }

    parse(report : Buffer) {
        let ofs = 0;
        let inputs = this.inputs;
        if(this.inputHasId) {
            inputs = this.inputsById[report[ofs++]];
        }

        const reports : HidReport[] = [];

        for(let field of inputs) {
            const raw = report.slice(ofs, ofs + field.sizeInBytes);
            ofs += field.sizeInBytes;
            switch(field.params.usage_page) {
                case 0x7:
                    reports.push(new HidKeyboardReport(field, raw));
                    break;
                default:
                    reports.push(new HidReport(field, raw));
                    break;
            }
        }

        return reports;
    }
}

export class HidReport {
    constructor(public field : HidField, public raw : Buffer) {
    }

    getBit(bit : number) {
        const byte = (bit / 8) | 0;
        return !!(this.raw[byte] & (1 << (bit % 8)));
    }
}

export class HidKeyboardReport extends HidReport {
    constructor(public field : HidField, public raw : Buffer) {
        super(field, raw);

        if(field.fieldType & 0x2) {
            for(let i = 0; i < this.field.params.report_count; i++) {
                // implement size != 1
                if(this.getBit(this.field.params.report_size * i)) {
                    this.keycodes.push(i + this.field.params.usage_minimum);
                }
            }
        } else {
            for(let i = 0; i < this.field.params.report_count; i++) {
                // implement size != 8
                this.keycodes.push(raw[i] + this.field.params.usage_minimum);
            }
        }
    }

    keycodes : number[] = [];
}

export function parseHid(buf : Buffer) {
    let global : HidGlobal = {
        usage_page : 0,
        logical_minimum : 0,
        logical_maximum : 0,
        physical_minimum : 0,
        physical_maximum : 0,
        unit_exponent : 0,
        unit : 0,
        report_id : 0,
        report_size : 0,
        report_count : 0,
    }

    let local : HidLocal = {
        usage : 0,
        usage_minimum : 0,
        usage_maximum : 0,
        designator_index : 0,
        designator_minimum : 0,
        designator_maximum : 0,
        string_index : 0,
        string_minimum : 0,
        string_maximum : 0,
        delimiter : 0,
    }

    let collections : HidCollection[] = [];
    let collection : HidCollection;

    const report = new HidReportInfo(buf);

    const stacks : HidGlobal[] = [];

	for (var i = 0; i < buf.length; )
	{
		var b0 = buf[i++];
		var bSize = b0 & 0x03;
		bSize = bSize == 3 ? 4 : bSize; // size is 4 when bSize is 3
		var bType = (b0 >> 2) & 0x03;
		var bTag = (b0 >> 4) & 0x0F;

		if (bType == 0x03 && bTag == 0x0F && bSize == 2 && i + 2 < buf.length)
		{
			var bDataSize = buf[i++];
			var bLongItemTag = buf[i++];
			// outTxt += pHexC(b0) + pHexC(bDataSize) + pHexC(bLongItemTag) + pIndentComment("Long Item (" + pHex(bLongItemTag) + ")", 2);
			for (var j = 0; j < bDataSize && i < buf.length; j++) {
                i++;
				// outTxt += pHexC(buf[i++]);
				// possible_errors++; // there are no devices that use long item data right now
			}
		}
		else
		{
			var bSizeActual = 0;
			var itemVal = 0;
			for (var j = 0; j < bSize; j++)
			{
				if (i + j < buf.length) {
					itemVal += buf[i + j] << (8 * j);
					bSizeActual++;
				}
			} 

			if (bType == 0x00)
			{
				if (bTag == 0x08)
				{
                    // main
                    report.inputs.push(new HidField({ ...global, ...local }, itemVal, collection))
				}
				else if (bTag == 0x09)
				{
                    // main
                    report.outputs.push(new HidField({ ...global, ...local }, itemVal, collection))
				}
				else if (bTag == 0x0B)
				{
                    // main
                    report.features.push(new HidField({ ...global, ...local }, itemVal, collection))
				}
				else if (bTag == 0x0A)
				{
                    // main
                    const item = new HidCollection(collection, itemVal);

                    if(!collection) report.collections.push(item);
                    collection = item;
                    collections.push(collection);
				}
				else if (bTag == 0x0C)
				{
                    collections.pop();
				}
				else
				{
					console.warn("Unknown (bTag: " + pHex(bTag) + ", bType: " + pHex(bType) + ")", bSizeActual);
				}
                local = {
                    usage : 0,
                    usage_minimum : 0,
                    usage_maximum : 0,
                    designator_index : 0,
                    designator_minimum : 0,
                    designator_maximum : 0,
                    string_index : 0,
                    string_minimum : 0,
                    string_maximum : 0,
                    delimiter : 0,
                }
			}
			else if (bType == 0x01)
			{
				if (bTag == 0x00)
				{
                    global.usage_page = itemVal;
				}
				else if (bTag == 0x01)
				{
                    global.logical_minimum = itemVal;
				}
				else if (bTag == 0x02)
				{
                    global.logical_maximum = itemVal;
				}
				else if (bTag == 0x03)
				{
                    global.physical_minimum = itemVal;
				}
				else if (bTag == 0x04)
				{
                    global.physical_maximum = itemVal;
				}
				else if (bTag == 0x05)
				{
                    global.unit_exponent = itemVal;
				}
				else if (bTag == 0x06)
				{
                    global.unit = itemVal;
				}
				else if (bTag == 0x07)
				{
                    global.report_size = itemVal;
				}
				else if (bTag == 0x08)
				{
                    global.report_id = itemVal;
				}
				else if (bTag == 0x09)
				{
                    global.report_count = itemVal;
				}
				else if (bTag == 0x0A)
				{
                    //push
                    stacks.push(global);
                    global = {...global };
				}
				else if (bTag == 0x0B)
				{
                    //pop
                    global = stacks.pop();
				}
				else
				{
					console.warn("Unknown (bTag: " + pHex(bTag) + ", bType: " + pHex(bType) + ")", bSizeActual);
				}
			}
			else if (bType == 0x02)
			{
				if (bTag == 0x00)
				{
                    local.usage = itemVal;
				}
				else if (bTag == 0x01)
				{
                    local.usage_minimum = itemVal;
				}
				else if (bTag == 0x02)
				{
                    local.usage_maximum = itemVal;
				}
				else if (bTag == 0x03)
				{
                    local.designator_index = itemVal;
				}
				else if (bTag == 0x04)
				{
                    local.designator_minimum = itemVal;
				}
				else if (bTag == 0x05)
				{
                    local.designator_maximum = itemVal;
				}
				else if (bTag == 0x07)
				{
                    local.string_index = itemVal;
				}
				else if (bTag == 0x08)
				{
                    local.string_minimum = itemVal;
				}
				else if (bTag == 0x09)
				{
                    local.string_maximum = itemVal;
				}
				else if (bTag == 0x0A)
				{
                    local.delimiter = itemVal;
				}
				else
				{
					console.warn("Unknown (bTag: " + pHex(bTag) + ", bType: " + pHex(bType) + ")", bSizeActual);
				}
			}
			else
			{
				console.warn("Unknown (bTag: " + pHex(bTag) + ", bType: " + pHex(bType) + ")", bSizeActual);
			}

			i += bSize;
		}
	}

    report.process();

    return report;
}




function pHex(x)
{
	var y = (+x).toString(16).toUpperCase();
	if (y.length % 2 != 0) {
		y = "0" + y;
	}
	return "0x" + y;
}

function pHexC(x)
{
	return pHex(x) + ", ";
}
