
import qs from 'querystring'

export default {
    async asyncData ({ route, store, redirect, app }) {
        if(!store.getters['userId']) {
            redirect(`/login?${qs.stringify({
                from: route.fullPath
            })}`)
            return 'shortcut';
        }
    },
}

export const AuthOnly = {
    async mounted() {
    }
}

