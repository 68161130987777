import moment from "moment";
import { createDecorator, Vue, checkID, FindType } from "@common";
import _ from "lodash";
import type { CartItem, ProductLine } from "./cart";
import type { SessionBase, SessionType } from "./sessionBase";
import type DB from "@db";
import { IngredientInfo } from "@common/table/ingredients";

const weekday: Record<string, number> = {
  Sunday: 7,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const reverseWeekday = Object.fromEntries(Object.entries(weekday).map(([k, v]) => [v, k]));

export function getWeekdayName(time: Date) {
  return reverseWeekday[moment(time).get("isoWeekday")];
}

export function normalizeFromTo(from: string, to: string) {
  if (from) {
    if (from.length === 4 || from.length === 3) {
      if (!from.includes(":")) {
        from = `${from.slice(0, from.length - 2).padStart(2, "0")}:${from.slice(from.length - 2)}`;
      } else {
        const parts = from.split(":");
        from = `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`;
      }
    } else if (from.length <= 2) {
      from = `${from.padStart(2, "0")}:00`;
    }
  }

  if (to) {
    if (to.length === 4 || to.length === 3) {
      if (!to.includes(":")) {
        to = `${to.slice(0, to.length - 2).padStart(2, "0")}:${to.slice(to.length - 2)}`;
      } else {
        const parts = to.split(":");
        to = `${parts[0].padStart(2, "0")}:${parts[1].padStart(2, "0")}`;
      }
    } else if (to.length <= 2) {
      to = `${to.padStart(2, "0")}:00`;
    }
  }

  if (from && !/^\d{2}:\d{2}$/.test(from)) {
    from = null;
  }

  if (to && !/^\d{2}:\d{2}$/.test(to)) {
    to = null;
  }

  let offsetStart = 0;

  if (from && from > "24:00") {
    const parts = from.split(":");
    offsetStart--;
    from = `${(+parts[0] % 24).toString().padStart(2, "0")}:${parts[1]}`;
  }

  if (to && to > "24:00") {
    const parts = to.split(":");
    to = `${(+parts[0] % 24).toString().padStart(2, "0")}:${parts[1]}`;
  }

  return [from, to, offsetStart] as const;
}

export function checkTime(weekdays: string[], from: string, to: string, time: Date, startDate?: Date, endDate?: Date) {
  const m = moment(time);
  const days = (weekdays || []).map(day => weekday[day] || -1).filter(it => it !== -1);
  const ts = m.format("HH:mm");

  const n = normalizeFromTo(from, to);
  from = n[0];
  to = n[1];
  let dateOffset = n[2];

  if (startDate || endDate) {
    const date = moment(time).format("YYYY-MM-DD");
    if (startDate) {
      const startDateStr = moment(startDate).format("YYYY-MM-DD");
      if (date < startDateStr) {
        return false;
      }
    }

    if (endDate) {
      const endDateStr = moment(endDate).format("YYYY-MM-DD");
      if (date > endDateStr) {
        return false;
      }
    }
  }

  // from (inclusive) to (exclusive)

  if (to && from) {
    if (to < from) {
      if (ts >= to && ts < from) {
        return false;
      }

      if (ts <= to) {
        // check previous weekday
        dateOffset = -1;
      }
    } else if (to > from) {
      if (ts >= to) {
        return false;
      }

      if (ts < from) {
        return false;
      }
    } else {
      return false;
    }
  } else {
    if (to) {
      if (ts >= to) {
        return false;
      }
    }

    if (from) {
      if (ts < from) {
        return false;
      }
    }
  }

  if (days.length && !days.includes(moment(m).add(dateOffset, "day").get("isoWeekday"))) {
    return false;
  }

  return true;
}

export function compileCondition(weekdays: string[], from: string, to: string, startDate?: Date, endDate?: Date) {
  const days = new Set((weekdays || []).map(day => weekday[day] || -1).filter(it => it !== -1));
  const n = normalizeFromTo(from, to);
  from = n[0];
  to = n[1];
  let dateOffset = n[2];
  const startDateStr = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
  const endDateStr = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

  if (!days.size && !from && !to && !startDateStr && !endDateStr) {
    return null;
  }

  return (ts: string, date: string, weekday: number) => {
    if (startDateStr && date < startDateStr) {
      return false;
    }

    if (endDateStr && date > endDateStr) {
      return false;
    }

    // from (inclusive) to (exclusive)

    if (to && from) {
      if (to < from) {
        if (ts >= to && ts < from) {
          return false;
        }

        if (ts <= to) {
          // check previous weekday
          dateOffset = -1;
        }
      } else if (to > from) {
        if (ts >= to) {
          return false;
        }

        if (ts < from) {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (to) {
        if (ts >= to) {
          return false;
        }
      }

      if (from) {
        if (ts < from) {
          return false;
        }
      }
    }

    weekday = isoWeekDayOffset(weekday, dateOffset);

    if (days.size && !days.has(weekday)) {
      return false;
    }

    return true;
  };
}

export function isoWeekDayOffset(weekday: number, dateOffset: number) {
  if (!dateOffset) return weekday;
  weekday += dateOffset;
  while (weekday < 1) {
    weekday += 7;
  }
  while (weekday > 7) {
    weekday -= 7;
  }
  return weekday;
}

export function checkTimeRange(
  weekdays: string[],
  from: string,
  to: string,
  time: Date,
  end: Date,
  startDate?: Date,
  endDate?: Date,
) {
  const startTime = moment(time);
  const endTime = moment(end);

  if (endTime.isBefore(startTime)) {
    return false;
  }

  const n = normalizeFromTo(from, to);
  from = n[0];
  to = n[1];
  let dateOffset = n[2];
  let edateOffset = dateOffset;

  if (moment.duration(endTime.diff(startTime)).asDays() < 1) {
    const startTimeStr = startTime.format("HH:mm");
    const endTimeStr = endTime.format("HH:mm");

    const fromMinutes = from ? +from.split(":")[0] * 60 + +from.split(":")[1] : 0;
    const toMinutes = to ? +to.split(":")[0] * 60 + +to.split(":")[1] : 60 * 24;

    if (from && to && to < from) {
      if (startTimeStr <= to) {
        dateOffset = -1;
      }

      if (endTimeStr <= to) {
        edateOffset = -1;
      }
    }

    const sameDay = startTime.isSame(endTime, "day");

    const ranges: [Date, Date][] = [];

    if (to < from) {
      ranges.push([
        moment(startTime).startOf("day").toDate(),
        moment(startTime).startOf("day").add(toMinutes, "minute").toDate(),
      ]);

      ranges.push([
        moment(startTime).startOf("day").add(fromMinutes, "minute").toDate(),
        moment(startTime).startOf("day").add(1, "day").toDate(),
      ]);
    } else {
      ranges.push([
        moment(startTime).startOf("day").add(fromMinutes, "minute").toDate(),
        moment(startTime).startOf("day").add(toMinutes, "minute").toDate(),
      ]);
    }

    if (!sameDay) {
      if (to < from) {
        ranges.push([
          moment(endTime).startOf("day").toDate(),
          moment(endTime).startOf("day").add(toMinutes, "minute").toDate(),
        ]);

        ranges.push([
          moment(endTime).startOf("day").add(fromMinutes, "minute").toDate(),
          moment(endTime).startOf("day").add(1, "day").toDate(),
        ]);
      } else {
        ranges.push([
          moment(endTime).startOf("day").add(fromMinutes, "minute").toDate(),
          moment(endTime).startOf("day").add(toMinutes, "minute").toDate(),
        ]);
      }
    }

    // console.log(ranges);

    // check any overlap
    if (!ranges.find(([start, end]) => startTime.isBefore(end) && endTime.isSameOrAfter(start))) {
      return false;
    }
  }

  if (weekdays?.length) {
    const days = weekdays.map(day => weekday[day] || -1).filter(it => it !== -1);
    if (days.length) {
      if (moment.duration(endTime.diff(startTime)).asDays() < 7) {
        const fromWeekday = moment(startTime).add(dateOffset, "day").get("isoWeekday");
        let toWeekday = moment(endTime).add(edateOffset, "day").get("isoWeekday");

        if (toWeekday < fromWeekday) {
          toWeekday += 7;
        }

        let flag = false;

        for (let i = fromWeekday; i <= toWeekday; i++) {
          if (days.includes(i > 8 ? i - 7 : i)) {
            flag = true;
            break;
          }
        }

        if (!flag) {
          return false;
        }
      }
    }
  }

  if (startDate || endDate) {
    const curStartDate = startTime.format("YYYY-MM-DD");
    const curEndDate = endTime.format("YYYY-MM-DD");
    const ruleStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    const ruleEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

    if (startDate) {
      if (curEndDate < ruleStartDate) {
        return false;
      }
    }

    if (endDate) {
      if (curStartDate > ruleEndDate) {
        return false;
      }
    }
  }

  return true;
}

export function checkWeekday(weekdays: string[], time: Date = new Date()) {
  const m = moment(time);
  const days = weekdays.map(day => weekday[day]);
  if (!days.includes(m.get("isoWeekday"))) return false;
  return true;
}

export function CachedList(service: string, findParams: any, def = []) {
  return function (target: Vue, key: string) {
    createDecorator(function (componentOptions, k) {
      componentOptions.mixins.push({
        data() {
          return {
            [key + "_cache"]: null,
          };
        },
      });
      componentOptions.computed[key] = {
        get(this: any) {
          const cached = this[key + "_cache"];

          if (cached === null) {
            const p = typeof findParams === "function" ? findParams(this) : findParams;
            this[key + "_cache"] = this.$feathers
              .service(service)
              .find(p)
              .then(v => {
                this[key + "_cache"] = v;
              });
          } else if (cached instanceof Promise) {
            return def;
          }
          return cached || def;
        },
        set(this: any, v: any) {
          this[key + "_cache"] = v;
        },
      };
    })(target, key);
  };
}

export enum StockLevel {
  None = 0,
  Conflicted = 1,
  NotSelling = 2,
  OutOfStock = 3,
  Low = 4,
  Medium = 5,
  High = 6,
  Disabled = 7,
}

export type StockInfo = {
  stockLevel: StockLevel;
  total: number;
  image: any;
  name: LangArrType;
  stock?: number;
  ingredients?: IngredientInfo[];
};

export function priceFormat(iprice: number, currency = "") {
  if (!iprice && typeof iprice !== "number") return "";
  iprice = +(+iprice).toFixed(2);
  const sign = iprice < 0 ? "-" : "";
  iprice = Math.abs(iprice);
  const num = Math.floor(iprice);
  const remain = ((iprice - num) * 100).toFixed(0);
  const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const remainStr = _.padStart(remain.toString(), 2, "0");
  const priceStr = `${numStr}.${remainStr}`;

  // TODO: currencySymbol
  return `${sign}${currency}$ ${priceStr}`;
}

export type LangArrType = { lang: string; value: string }[];
export type LangType = LangArrType | string;

export function translate(text: LangArrType | string, loc: string) {
  if (typeof text === "string") return text;
  let enValue, locValue, defValue;
  _.each(text, v => {
    if (v.lang === loc) locValue = v.value;
    if (v.lang === "en") enValue = v.value;
    defValue = v.value;
  });
  return locValue || enValue || defValue || "";
}

export function getLangs(value: LangType) {
  if (Array.isArray(value)) {
    return value.map(it => it.lang);
  } else if (value && typeof value === "object") {
    return Object.keys(value);
  } else return [];
}

export function langJoin(join: string, ...values: LangType[]): LangArrType {
  if (!values || !values.length) return [];
  const uniqLang = _.uniq(_.flatMap(values, getLangs));
  if (!uniqLang.length) {
    return [{ lang: "en", value: values.join(join) }];
  }
  return uniqLang.map(lang => ({
    lang,
    value: values.map(v => translate(v, lang)).join(join),
  }));
}

function translateItem(text: string, params: { [key: string]: any }, lang: string): string {
  return String(text || "").replace(/\{(.+?)\}/g, (cap, gp) => {
    const args = gp.split("||");
    for (let arg of args) {
      const part = arg.trim().split("|");
      let val = _.get(params, part[0].trim());
      if (val) {
        switch ((part[1] || "").trim()) {
          case "enum": {
            const map = (part[2] || "").split(";");
            const it = map.find(it => it.split(",")[0] === val);
            if (it) {
              val = it.split(",")[1];
            }
            break;
          }
        }
        if (!Array.isArray(val)) val = `${val}`;
        return translate(val, lang);
      } else if ((part[1] || "").trim() === "default") {
        return (part[2] || "").trim();
      }
    }
    return "";
  });
}

export function formatTranslate(text: LangArrType, params: { [key: string]: any }): LangArrType {
  return (text || []).map(it => ({
    lang: it.lang,
    value: translateItem(it.value, params, it.lang),
  }));
}

export function getProductTree(session: SessionBase, item: ProductLine): ProductLine[] {
  return getProductTreeFromData(session?.sessionData, item);
}

export function getProductTreeFromData(session: SessionType, item: ProductLine): ProductLine[] {
  return [
    item,
    ...(session?.products ?? [])
      .filter(it => it.fromProduct === item.id)
      .flatMap(it => getProductTreeFromData(session, it)),
  ];
}

export function calculateQuantity(cartItem: CartItem, sourceItem: CartItem) {
  const orderedQuantity = _.sumBy(cartItem?.parent?.sessionData?.products || [], it =>
    it.status !== "cancel" && checkID(it.product, cartItem.product) ? it.quantity : 0,
  );

  const cartQuantity = _.sumBy(cartItem?.parent?.cart || [], it =>
    it.status !== "cancel" && it !== sourceItem && checkID(it.product, cartItem.product) ? it.quantity : 0,
  );

  const orderedCatQuantity = cartItem?.product?.category
    ? _.sumBy(cartItem?.parent?.sessionData?.products || [], it =>
        it.status !== "cancel" && checkID(it.category, cartItem.product?.category) ? it.quantity : 0,
      )
    : 0;

  const cartCatQuantity = cartItem?.product?.category
    ? _.sumBy(cartItem?.parent?.cart || [], it =>
        it.status !== "cancel" && it !== sourceItem && checkID(it.product?.category, cartItem.product?.category)
          ? it.quantity
          : 0,
      )
    : 0;

  const orderedSubCatQuantity = cartItem?.product?.subCategory
    ? _.sumBy(cartItem?.parent?.sessionData?.products || [], it =>
        it.status !== "cancel" && checkID(it.subCategory, cartItem.product?.subCategory) ? it.quantity : 0,
      )
    : 0;

  const cartSubCatQuantity = cartItem?.product?.subCategory
    ? _.sumBy(cartItem?.parent?.cart || [], it =>
        it.status !== "cancel" && it !== sourceItem && checkID(it.product?.subCategory, cartItem.product?.subCategory)
          ? it.quantity
          : 0,
      )
    : 0;

  return {
    product: [orderedQuantity, cartQuantity] as const,
    category: [orderedCatQuantity, cartCatQuantity] as const,
    subCategory: [orderedSubCatQuantity, cartSubCatQuantity] as const,
  };
}

export function getLimit(
  subject: FindType<"products"> | FindType<"categories"> | FindType<"subCategories">,
  sessionData: FindType<"tableSessions">,
) {
  let perOrder: number = null;
  let perCart: number = null;
  let hasOrder = false;
  let hasCart = false;

  const capacity = _.sumBy(sessionData?.tables, it => it.capacity) || 0;

  for (let i = (subject?.onlineOrderLimits?.length ?? 0) - 1; i >= 0; i--) {
    const it = subject.onlineOrderLimits[i];

    if (it.limitType === "perOrder") {
      if (perOrder !== null) continue;
      hasOrder = true;
    } else {
      if (perCart !== null) continue;
      hasCart = true;
    }

    if (it.capacity && capacity < it.capacity) continue;
    if (it.numOfTables && sessionData?.tables?.length < it.numOfTables) continue;

    if (it.limitType === "perOrder") {
      perOrder = it.quantity;
    } else {
      perCart = it.quantity;
    }
  }

  return [hasOrder ? perOrder || 0 : null, perCart ? perCart || 0 : null] as const;
}

export function calculateCurrentMax(
  current: readonly [number, number],
  limit: readonly [number, number],
  max: number = null,
) {
  if (limit[0] !== null) {
    const cur = limit[0] - current[0] - current[1];
    if (!max || cur < max) max = cur;
  }
  if (limit[1] !== null) {
    const cur = limit[1] - current[1];
    if (!max || cur < max) max = cur;
  }
  return max;
}

export function normalizedDay(shop: FindType<"shops">, date: Date) {
  let openHour = shop?.openTime || "00:00";

  const time = openHour?.split?.(":");
  const hh = isNaN(+time?.[0]) ? 0 : +time?.[0];
  const mm = isNaN(+time?.[1]) ? 0 : +time?.[1];

  const now = moment();
  const day = moment().startOf("day").add(hh, "hours").add(mm, "minutes");
  const cur = moment(date).startOf("day").add(hh, "hours").add(mm, "minutes");

  if (day.isAfter(now)) {
    return cur.subtract(1, "day").toDate();
  }
  return cur.toDate();
}

export type ProductStockType = typeof DB.ProductStock._mongoType;
export type ProductType = FindType<"products">;
export type ProductOptionType = FindType<"productOptions">;

export function getProductStockStatus(stock: ProductStockType) {
  if (!stock) return "selling";
  switch (stock.mode) {
    case "disable":
      return "selling";
    case "notSelling":
    case "paused":
      return "notSelling";
    case "manual":
    case "auto":
      if (stock.quantity === null) return null;
      if (stock.quantity <= 0) return "outOfStock";
      if (stock.quantity < 10) return "warn";
      if (stock.stockMode === "sync") return "warn";
      return "selling";
  }
}

export function getProductStockLevel(
  product: ProductType,
  opts?: {
    stock?: ProductStockType;
    session?: SessionBase;
    optionsDict?: Record<string, ProductOptionType>;
    productsDict?: Record<string, ProductType>;
    tree?: Set<string>;
  },
) {
  const stock = opts?.stock ?? product?.stock;
  const session = opts?.session;

  if (!stock) return StockLevel.Disabled;

  let level = StockLevel.None;
  if (session?.selfService && stock?.onlineMode) {
    switch (stock.onlineMode) {
      case "paused":
      case "notSelling":
      case "askStaff":
        level = StockLevel.NotSelling;
        break;
    }
  }

  switch (stock.mode) {
    case "disable":
      level = combineMinLevel(level, StockLevel.Disabled);
      break;
    case "notSelling":
    case "paused":
      level = StockLevel.NotSelling;
      break;
    case "manual":
    case "auto":
      if (stock.quantity === null) {
        level = combineMinLevel(level, StockLevel.High);
      } else if (stock.quantity <= 0) {
        level = combineMinLevel(level, StockLevel.OutOfStock);
      } else if (stock.quantity < 10) {
        level = combineMinLevel(level, StockLevel.Low);
      } else if (stock.stockMode === "sync") {
        level = combineMinLevel(level, StockLevel.Medium);
      } else {
        level = combineMinLevel(level, StockLevel.High);
      }
      break;
  }

  if (opts?.optionsDict) {
    for (let optionId of product.options) {
      const option = opts.optionsDict[String(optionId)];
      if (!option || !option.required) continue;
      const cur = getOptionsStockLevel(option, {
        product,
        session,
        tree: opts?.tree,
        optionsDict: opts?.optionsDict,
        productsDict: opts?.productsDict,
      });
      if (cur === StockLevel.NotSelling) return cur;
      level = combineMinLevel(level, cur);
    }
  }
  return level;
}

export function getOptionsStockLevel(
  option: ProductOptionType,
  opts?: {
    product?: ProductType;
    session?: SessionBase;
    tree?: Set<string>;
    optionsDict?: Record<string, ProductOptionType>;
    productsDict?: Record<string, ProductType>;
  },
) {
  let level = StockLevel.None;
  for (let value of option.options) {
    let cur = getOptionStockLevel(option, value, opts);

    level = combineMaxLevel(cur, level);
  }
  return level;
}

export function getOptionStockLevel(
  option: ProductOptionType,
  value: ProductOptionType["options"][number],
  opts?: {
    product?: ProductType;
    session?: SessionBase;
    optionsDict?: Record<string, ProductOptionType>;
    productsDict?: Record<string, ProductType>;
    tree?: Set<string>;
  },
) {
  let cur = getOptionStockLevelInner(option, String(value._id), opts);
  if (value.type === "product" && value.product && !opts?.tree?.has?.(String(value.product))) {
    const sub = opts?.productsDict?.[String(value.product)];
    if (sub) {
      const tree = new Set(opts?.tree);
      if (opts?.product?._id) {
        tree.add(String(opts?.product?._id));
      }
      cur = combineMinLevel(
        cur,
        getProductStockLevel(sub, {
          session: opts?.session,
          optionsDict: opts?.optionsDict,
          productsDict: opts?.productsDict,
          tree,
        }),
      );
    }
  }
  return cur;
}

export function combineMinLevel(a: StockLevel, b: StockLevel) {
  return a === StockLevel.None ? b : b === StockLevel.None ? a : Math.min(a, b);
}

export function combineMaxLevel(a: StockLevel, b: StockLevel) {
  return a === StockLevel.None ? b : b === StockLevel.None ? a : Math.max(a, b);
}

export function getOptionStockLevelInner(
  option: ProductOptionType,
  value: string,
  opts?: {
    product?: ProductType;
    session?: SessionBase;
  },
) {
  const optionStock = option?.stock?.values?.find?.(it => `${it.value}` === value);
  switch (optionStock?.mode ?? "selling") {
    case "notSelling":
    case "paused":
      return StockLevel.NotSelling;
  }
  const session = opts?.session;
  if (session?.selfService && optionStock?.onlineMode) {
    switch (optionStock.onlineMode) {
      case "paused":
      case "notSelling":
      case "askStaff":
        return StockLevel.NotSelling;
    }
  }
  const stock = opts?.product?.stock;
  if (!stock) return StockLevel.Disabled;
  const info = stock.productOptions?.find?.(it => `${it.option}` === String(option._id));
  if (!info) return StockLevel.Disabled;
  const cur = info.values?.find?.(it => `${it.value}` === value);
  if (!cur) return StockLevel.Disabled;

  switch (stock.mode) {
    case "disable":
      return StockLevel.High;
    case "notSelling":
      return StockLevel.NotSelling;
    case "manual":
    case "auto":
      if (cur.quantity === null) return StockLevel.High;
      if (cur.quantity <= 0) return StockLevel.OutOfStock;
      if (cur.quantity < 10) return StockLevel.Low;
      if (cur.stockMode === "sync") return StockLevel.Medium;
      return StockLevel.High;
  }
  return StockLevel.High;
}

export function getStockLevel(stock: ProductStockType) {
  if (!stock || stock.stockMode !== "sync" || stock.mode === "disable") return null;
  return stock.quantity;
}

export function getProductTreeInfo<
  T extends {
    id?: string;
    fromProduct?: string;
  },
>(
  products: T[],
): {
  productDict: Record<string, T>;
  rootDict: Record<string, string>;
  rootSet: Record<string, Set<string>>;
} {
  const productDict = Object.fromEntries(products.map(p => [p.id, p] as const));
  const rootSet: Record<string, Set<string>> = {};
  const curSet: Record<string, Set<string>> = {};

  for (let product of products) {
    if (product.fromProduct) {
      let cur = curSet[product.fromProduct];
      const me = curSet[product.id];
      if (!cur) {
        cur = curSet[product.fromProduct] = me || new Set();
      }
      if (me && me !== cur) {
        me.forEach(it => {
          cur.add(it);
          curSet[it] = cur;
        });
      }
      cur.add(product.id);
      curSet[product.id] = cur;
    } else {
      const set = (curSet[product.id] = rootSet[product.id] = curSet[product.id] || new Set([product.id]));
      set.add(product.id);
    }
  }

  return {
    productDict,
    rootDict: Object.fromEntries(
      Object.entries(rootSet).flatMap(([k, v]) => Array.from(v).map(it => [it, k] as const)),
    ),
    rootSet,
  };
}

export function getSplitName(n: number) {
  if (!n || n < 0 || isNaN(n)) return "";
  n = n | 0;
  n--;
  const str: string[] = [];
  while (true) {
    str.unshift(String.fromCharCode(65 + (n % 26)));
    n = Math.floor(n / 26);
    if (!n) break;
  }
  return str.join("");
}

export function getSortProducts(products: (typeof DB.TableSession._mongoType)["products"]) {
  const productDict = Object.fromEntries(products.map(p => [p.id, p] as const));
  const productRoot = Object.fromEntries(
    products.map(p => {
      let product = p;
      while (product.fromProduct) {
        const parent = productDict[product.fromProduct];
        if (!parent) break;
        product = parent;
      }

      return [p.id, product] as const;
    }),
  );
  return _.orderBy(
    products.map(p => {
      const product = productRoot[p.id];
      return [p, product] as const;
    }),
    [
      ([ori, product]) => {
        return product.seq;
      },
      ([ori, product]) => {
        return product.date || product.id;
      },
      ([ori, product]) => {
        return ori.fromOptionSeq ?? -1;
      },
      ([ori, product]) => {
        return ori.fromChoiceSeq ?? -1;
      },
      ([ori, product]) => {
        return ori.date || ori.id;
      },
    ],
    ["asc", "asc", "asc", "asc", "asc"],
  ).map(it => it[0]);
}

export function computeLineTaxAndSurcharge(session: FindType<"tableSessions">) {
  const lines: Record<
    string,
    {
      taxPercent: number;
      surchargePercent: number;
      totalTax: number;
      totalSurcharge: number;
      taxGroup: string;
    }
  > = {};

  function ensureLine(id: string) {
    const line = lines[id];
    if (!line) {
      return (lines[id] = {
        taxPercent: 0,
        surchargePercent: 0,
        totalTax: 0,
        totalSurcharge: 0,
        taxGroup: null,
      });
    }
    return line;
  }

  for (let surcharge of session.surcharges || []) {
    if (surcharge.disabled) continue;
    for (let line of surcharge.lines || []) {
      const target = ensureLine(line.id);
      if (!target) continue;
      if (surcharge.percent) target.surchargePercent += surcharge.percent;
      if (line.amount) target.totalSurcharge += line.amount;
    }
  }

  for (let tax of session.taxes || []) {
    if (tax.disabled) continue;
    for (let line of tax.lines || []) {
      const target = ensureLine(line.id);
      if (!target) continue;
      if (tax.percent) target.taxPercent += tax.percent;
      if (line.amount) target.totalTax += line.amount;
      if (tax.taxGroup) target.taxGroup = tax.taxGroup;
    }
  }

  return lines;
}

export function roundWithFactor(
  p: number,
  roundFactor: number = 1,
  roundMethod: "floor" | "round" | "ceil" | "no" = "ceil",
) {
  let roundingFactor = Math.round(1 / (roundFactor || 1));
  if (isNaN(roundingFactor) || roundingFactor < 1) roundingFactor = 1;
  switch (roundMethod) {
    case "floor":
      return Math.floor(p * roundingFactor) / roundingFactor;
    case "round":
      return Math.round(p * roundingFactor) / roundingFactor;
    case "no":
      return +p.toFixed(2);
  }
  return Math.ceil(p * roundingFactor) / roundingFactor;
}

export function checkTwCenterNo(twCenterNo: string): boolean {
  const invalidList: string[] = ["00000000", "11111111"];

  const rgx: RegExp = /^\d{8}$/;

  if (!rgx.test(twCenterNo) || invalidList.includes(twCenterNo)) {
    return false;
  }

  const idNoArray: number[] = Array.from(twCenterNo).map(c => parseInt(c));
  const weight: number[] = [1, 2, 1, 2, 1, 2, 4, 1];

  let subSum: number; //小和
  let sum: number = 0; //總和
  let sumFor7: number = 1;
  for (let i = 0; i < idNoArray.length; i++) {
    subSum = idNoArray[i] * weight[i];
    sum += Math.floor(subSum / 10) + (subSum % 10); //商數 + 餘數
  }
  if (idNoArray[6] === 7) {
    //若第7碼=7，則會出現兩種數值都算對，因此要特別處理。
    sumFor7 = sum + 1;
  }
  return sum % 5 === 0 || sumFor7 % 5 === 0;
}
