
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, FindType, getID, checkID } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import CartPanelBase from "./cartPanelBase";
import _ from "lodash";

@Component
export default class CartPanel extends mixins(CartPanelBase) {
  serviceTab = null;
  taxTab = null;
  more = false;
  print = false;
  timeMenu = false;
  member: FindType<"appUsers"> = null;

  @Prop(Boolean)
  mobile: boolean;

  @Prop()
  session: TableSession;

  @PropSync("crmMenu")
  crmMenuSync: boolean;

  @Prop()
  posCheckoutNewLayout: boolean;

  @Prop(Boolean)
  preview: boolean;

  @Prop()
  pendingProducts: ProductLine[];

  loading = false;

  get sumOfPendingProducts() {
    return this.pendingProducts ? _.sumBy(this.pendingProducts, p => p.price * p.quantity) : 0;
  }

  get takeawaySession() {
    return this.session.type;
  }

  async mounted() {
    if (this.session.user) {
      this.member = await this.$feathers.service("appUsers").get(this.session.user);
    }
    if (!this.session.sessionData.remarks) {
      this.$set(this.session.sessionData, "remarks", "");
    }
  }

  async cancelBilling() {
    await this.session.atomic({
      status: "ongoing",
      checkBillTime: null,
    });
  }

  get isDone() {
    return this.session.status === "done";
  }

  get isCancel() {
    return this.session.status === "cancelled";
  }

  get isTest() {
    return this.session.status === "test";
  }

  get canEdit() {
    return (this.isDone || this.isTest) && this.$shop.hasStaffRole("refundOrEdit");
  }

  get canCheckBill() {
    return (
      (this.session.isNoTable
        ? (this.isOngoing || this.session.status === "toPay") &&
          (this.session.cartEmpty ? !!this.session.item._id : this.session.cartValid)
        : this.isOngoing && this.session.cartEmpty) && this.session.orderComfirmed
    );
  }

  get checkBillRole() {
    return this.$shop.hasStaffRole("pos/checkout");
  }

  get remarks() {
    return this.session.sessionData.remarks ?? "";
  }

  get requiredPermission() {
    const permissions = [`orderManage/tableSessionPlaceProducts`];

    const replaceProducts = this.session.getCartReplacingIds();

    if (replaceProducts.length) permissions.push(`orderManage/tableSessionEditProducts`);
    return permissions;
  }

  async confirmOrder(toPay = true) {
    const values = Object.values(this.session.queryStock());
    if (values.length) {
      const result = await this.$openDialog(
        import("~/components/dialogs/StockWarnDialog.vue"),
        {
          values,
        },
        {
          maxWidth: "520px",
        },
      );
      if (!result) return;
    }
    try {
      this.session.cancelSelect();
      if (this.session.selectingCartItem) {
        this.session.openProductMenu(null);
      }
      if (this.loading) return;
      this.loading = true;
      this.session.screenOverride = null;
      if (!this.session.item._id) {
        this.session.dirty = false;
        this.session.resumeSave();
        await this.session.atomic({
          ...this.session.item,
          ...this.session.cachedPriceDetails,
          checkBillTime: new Date(),
          staff: this.$shop.staffId as any,
          status: "ongoing",
        });
        if (await this.session.redeemGifts()) {
          this.session.updateCoupons();
          await this.session.atomic({
            ...this.session.cachedPriceDetails,
          });
        }
        this.session.startSubscribe();
      }
      await this.session.updateCachedInfo(true);
      if (!this.session.cartEmpty) {
        const result = await this.placeOrderInner();
        if (result === false) return;
        await this.session.reload();
      }
      await this.session.confirmOrder(toPay);
      this.session.postEditing = false;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loading = false;
    }
  }

  editOrder() {
    this.$emit("editOrder");
  }

  @Watch("session.user")
  async onUserChange() {
    if (this.session.user) {
      this.member = await this.$feathers.service("appUsers").get(this.session.user);
    } else {
      this.member = null;
    }
  }

  async splitItemCheckout() {
    const result = await this.session.splitItemCheckout();

    if (!result) return;
    const { moved, newSessions } = result;

    let changedSessions: TableSession[] = [];
    for (let sesisonData of moved) {
      let newSession = this.$tableManager.addSession(sesisonData);
      if (newSession) {
        changedSessions.push(newSession);
      }
    }

    for (let session of newSessions) {
      await session.postsave();
      this.$emit("openOrder", session);
      this.$emit("confirmOrder");
    }

    for (let sessionData of changedSessions) {
      await sessionData.updateCachedInfo();
    }
  }

  async cancelSingleItemCheckout() {
    const moved = await this.session.cancelSingleItemCheckout();
    if (!moved) return;

    let changedSessions: TableSession[] = [];
    for (let sesisonData of moved) {
      let newSession = this.$tableManager.addSession(sesisonData);
      if (newSession) {
        changedSessions.push(newSession);
      }
    }

    for (let sessionData of changedSessions) {
      await sessionData.updateCachedInfo();
      this.$emit("openOrder", sessionData);
      this.$emit("confirmOrder");
    }
  }
}
