
import { Component, Prop, Vue, mixins, InjectReactive } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";
import type TemplateEditor from "~/pages/templateEditor/_.vue";
import { TemplateField, TemplateDef } from "@common/table/templateFields/base";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeCol,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeInterpolationLabel,
  PrinterTemplateNodeLabelStaticText,
  PrinterTemplateNodeLabelText,
  PrinterTemplateNodeText,
  PrinterTemplateNodeTextLabel,
} from "pos-printer-template";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorVariable extends mixins(PrinterEditorEditBase) {
  @InjectReactive("templateEditor")
  templateEditor: TemplateEditor;

  get designComponents() {
    return this.templateEditor.fieldDef?.fields ?? [];
  }

  placeNode(component: TemplateField) {
    if (component.expression) {
      let node: PrinterTemplateNodeBase<any>;
      if (this.type === "label") {
        node = PrinterTemplateNodeInterpolationLabel.createFromExpression(component.expression);
      } else {
        node = PrinterTemplateNodeInterpolation.createFromExpression(component.expression);
      }
      switch (this.selectedNode.constructor) {
        case PrinterTemplateNodeText:
        case PrinterTemplateNodeInterpolation:
        case PrinterTemplateNodeLabelStaticText:
        case PrinterTemplateNodeTextLabel:
        case PrinterTemplateNodeInterpolationLabel:
          this.insertAfter(node);
          break;
        case PrinterTemplateNodeBlock:
        case PrinterTemplateNodeCol:
        case PrinterTemplateNodeLabelText:
          this.insertChild(node);
          break;
        default:
          if (!this.parentPreContainer) return;
          if (this.type === "label") {
            node = PrinterTemplateNodeLabelText.wrap(node);
          } else {
            node = PrinterTemplateNodeBlock.wrap(node, "div");
          }
          this.insertAfter(node, this.parentPreContainer, [0]);
          break;
      }
    } else if (component.template) {
      if (!this.parentPreContainer) return;
      const node = this.templateEditor.compileCode(component.template);
      this.insertAfter(node, this.parentPreContainer);
    }
  }
}
