
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import { CartItem } from "@common/table/cart";
import CartPanelBase from "~/components/table/orderSystem/cartPanelBase";

@Component({})
export default class MobileBottomButtonBar extends mixins(CartPanelBase) {
  @Prop()
  session: TableSession;

  @Prop()
  lastCart: CartItem;

  @Prop()
  currentPage: string;

  @Prop()
  selectingCart: boolean;

  @Prop()
  selectingLine: boolean;

  @Prop(Boolean)
  mobile: boolean;

  get currentCart() {
    return this.session?.cart ?? [];
  }

  get totalQuantity() {
    return _.sumBy(this.currentCart, it => it.quantity) ?? 0;
  }
}
