
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import { ProductLine, TableSession } from '~/plugins/table/session'
import { CartItem } from '@common/table/cart'
import { getProductTree } from '@common/table/util'
import Dialog from "domore-table/mixins/Dialog"
import type { LangArrType } from '@feathers-client/i18n'

@Component

export default class BatchSetKitchenOption extends mixins(Dialog()) {

    @Prop()
    cart: CartItem[]

    @Prop()
    line: ProductLine[]

    @Prop()
    session: TableSession

    loading = false;


    includeDetails = true;

    @Prop()
    defaultIncludeDetails : boolean

    beforeMount() {
        this.includeDetails = this.defaultIncludeDetails ?? true;
    }

    get items() {
        return this.cart || this.line;
    }

    kitchenOptions: { _id: string, name: LangArrType }[] = [];

    async confirmSet() {
        if(this.line) {
            try {
                this.loading = true;
                await this.$feathers.service('tableSessions/order').patch(null, {
                    session: this.session.item._id,
                    products: this.line.map(line => (
                        {
                            ...line,
                            kitchenOptions: this.kitchenOptions,
                        }
                    )),
                    reprint: true,
                    noSplit: true,
                    staff: this.$shop.staffId,
                    includeDetails: this.includeDetails,
                });
                this.modalResult(true)
            } catch(e) {
                console.warn(e);
                this.$store.commit("SET_ERROR", e.mesage)
            } finally {
                this.loading = false;
            }
        } else if(this.cart) {
            for(let item of this.cart) {
                item.kitchenOptions = this.kitchenOptions.slice()
            }
            this.modalResult(true)
        }
    }

}


