
import url from "url";
import { imageSync } from "qr-image";
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { downloadFile } from "domore-table/exportExcel";
import { FindPopRawType } from "@feathers-client";
import jszip from "jszip";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class ShopQrcodeGenerator extends Vue {
  @Prop()
  shop: string;

  fullShop: FindPopRawType<["shopGroup"], "shops"> = null;

  view: string = null;
  viewItem: string = null;

  @Watch('view')
  onView() {
    this.viewItem = null;
  }

  async mounted() {
    try {
      this.fullShop = await this.$feathers.service("shops").get(this.shop, {
        query: {
          $populate: ["shopGroup"],
        },
      } as const);
    } catch (e) {
      console.warn(e);
    }
  }

  async exportTable(format: "png" | "svg") {
    const views = await this.$feathers.service("tableViews").find({
      query: {
        $paginate: false,
        shop: this.shop,
      },
      paginate: false,
    });

    const zip = new jszip();

    for (let view of views) {
      const viewItems = await this.$feathers.service("tableViewItems").find({
        query: {
          $paginate: false,
          view: view._id,
          shop: this.shop,
        },
        paginate: false,
      });

      for (let viewItem of viewItems) {
        const u = url.resolve(
          this.$shop.getShopUrl(this.fullShop as any),
          `/?id=${this.fullShop._id}&t=${viewItem._id}`,
        );
        const p = `${view.name}/${viewItem.name}`;
        switch (format) {
          case "png": {
            zip.file(`${p}.png`, Buffer.from(imageSync(u, { type: "png", size: 32 })));
            break;
          }
          case "svg": {
            zip.file(`${p}.svg`, Buffer.from(imageSync(u, { type: "svg" })));
            break;
          }
        }
      }
    }
    const buf = await zip.generateAsync({ type: "arraybuffer" });
    const blob = new Blob([Buffer.from(buf)], { type: "application/zip" });
    await downloadFile([blob], "table-codes.zip", "application/zip");
  }
}
