
import { Component, Vue, FindType, Prop, PropSync, checkID } from "@feathers-client";
import { fromDb, INGREDIENT_MULTIPLIER_PRICE, toDb, totalPrice, priceFromDb } from "@common/table/ingredients";

interface SummaryRow {
  _id: string;
  ingredient: FindType<"ingredients">;
  time: string;
  amount: number;
  sum: number;
  cost: number;
}

@Component({})
export default class SemiProduct extends Vue {
  @Prop()
  item: SummaryRow;

  @Prop()
  showTime: boolean;

  fromDb = fromDb;
  totalPrice = totalPrice;

  get name() {
    return this.item.ingredient?.name;
  }

  get image() {
    return this.$thumb(this.item.ingredient?.image) || require("~/assets/images/icons/placeholder.svg");
  }

  get unit() {
    return this.item.ingredient?.unit ?? "";
  }

  get priceQuantity() {
    return this.item.ingredient?.priceQuantity ?? 1000;
  }

  get avgCost() {
    return priceFromDb(this.item.cost, this.priceQuantity);
  }
}
