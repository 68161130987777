import { render, staticRenderFns } from "./useCoupon.vue?vue&type=template&id=f8977686"
import script from "./useCoupon.vue?vue&type=script&lang=ts"
export * from "./useCoupon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8977686')) {
      api.createRecord('f8977686', component.options)
    } else {
      api.reload('f8977686', component.options)
    }
    module.hot.accept("./useCoupon.vue?vue&type=template&id=f8977686", function () {
      api.rerender('f8977686', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/table/orderSystem/mobile/crm/useCoupon.vue"
export default component.exports