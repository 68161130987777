import { PrinterBase, PrinterConf } from './base'
import { PrinterServer, PrinterCommand, wrapVue } from '../index'
import type { PrintJob, PrintQueue } from '../printQueue';
import { Vue } from 'vue-property-decorator';
import { CanvasSequence } from '../printSequence/canvas';

export interface PosPrintRemoteConf extends PrinterConf {
    port : 'posPrintRemote'
}

export class PosPrintRemotePrintPrinter extends PrinterBase<PosPrintRemoteConf, PosPrintRemoteConf> {
    constructor(server: PrinterServer, conf : PosPrintRemoteConf) {
        super(server, conf);
    }

    createSequence<T>(context?: Vue): T {
      if(this.conf.opts.escpos === 'bitmap') {
        throw new Error('Bitmap mode not supported');
      } else if(this.conf.opts.escpos === 'json') {
        const seq = new CanvasSequence(wrapVue(context ?? this.parent.context), this);
        seq.imageAsBase64 = true;
        return seq as any;
      } else {
        return super.createSequence<T>(context);
      }
    }
    
    onDisconnectedCore() {
    }

    async initCore() {
    }

    async tryConnectCore() {
        await this.parent.remotePOSPrint.init();
    }

    async printCore(job : PrintJob) {
        try {
            await this.parent.remotePOSPrint.print(job.data, job.opts);
        }
        catch(e) {
            if(e.response && e.response.data) {
                throw new Error(e.response.data.message)
            } else {
                throw e;
            }
        }
    }

    async disconnectCore() {
      await this.parent.remotePOSPrint.dispose?.();
    }

    async requestNewDeviceCore() {
        const info = await this.parent.remotePOSPrint.info?.() || {};
        return {
          ...this.conf,
          opts: {
            ...(this.conf.opts || {}),
            ...info,
          }
        }
    }
}
