
import { Component, Vue, Prop, Watch } from "@feathers-client";
import PopularProductPercentage from "./PopularProductPercentage.vue";

@Component({
  components: {
    PopularProductPercentage,
  },
})
export default class PopularProduct extends Vue {
  @Prop() data!: any;
  @Prop() history!: any;
}
