import { checkID, Component, FindPopRawType, FindType, getID, Prop, Vue } from "@common";
import type DB from "@db";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import type { ProductLine } from "./cart";
import type { SessionBase } from "./sessionBase";
import { checkTime, checkTimeRange } from "./util";
import { roundWithFactor } from "./util";

export type LangArrType = { lang: string; value: string }[];

export type CouponType = FindPopRawType<["coupon"], "userCoupons">;
export type DiscountType = FindType<"orderDiscounts">;
export type TaxType = FindType<"taxSettings">;
export type SurchargeType = FindType<"surchargeSettings">;

export type CouponRuleType = typeof DB.Coupon._mongoType;
export type CouponRuleCondType = CouponRuleType["conditions"][number];
export type CouponRuleFreeType = CouponRuleType["freeProducts"][number];

export type GiftType = FindPopRawType<["coupon"], "gifts">;
export type GiftRecordType = typeof DB.GiftRedeemRecord._mongoType;
export type SessionTaxType = FindType<"tableSessions">["taxes"][number];
export type SessionFreeTaxType = FindType<"tableSessions">["taxFree"];
export type SessionSurchargeType = FindType<"tableSessions">["surcharges"][number];
export type SessionDiscountType = FindType<"tableSessions">["discounts"][number];

export type DiscountHint = {
  active: {
    name: LangArrType;
    _id: string;
  }[];
  hint: {
    name: LangArrType;
    _id: string;
  }[];
};

function applyLineStateBase(
  lineState: Record<string, Record<string, number | Record<string, number>>>,
  id: string,
  surchargeId: string,
  amount: number,
  field?: string,
) {
  if (!lineState) return;
  if (!lineState[surchargeId]) {
    lineState[surchargeId] = {};
  }
  if (field) {
    if (!lineState[surchargeId][id]) {
      lineState[surchargeId][id] = {
        [field]: amount,
      };
    } else {
      lineState[surchargeId][id][field] = (lineState[surchargeId][id][field] || 0) + amount;
    }
  } else {
    lineState[surchargeId][id] = ((lineState[surchargeId][id] || 0) as any) + amount;
  }
}

export class ProductLineCompute {
  constructor(
    public context: DiscountComputeContext,
    public before?: ProductLine,
    public opts?: {
      source: string;
      _id: string;
      type: string;
      price: number;
      quantity: number;
      discountable: boolean;
    },
  ) {
    let price = 0;
    if (opts) {
      price = opts.price;
    } else if (before && !before.fromProduct) {
      price = (before.overridePrice ?? before.actualPrice) * before.discount;
    }
    let optionPrice = 0;
    if (before) {
      optionPrice = before.extraFeeEnjoyDiscount * before.discount;
    }

    this.countAsCondition = before && !before.fromProduct && !before.fromCoupon;
    this.beforePrice = price;
    this.beforeOptions = optionPrice;
    this.quantity = before?.quantity ?? opts?.quantity ?? 1;

    this.discountable = opts?.discountable ?? before?.discountable ?? true;
  }

  discountable: boolean;

  countAsCondition: boolean;
  beforePrice: number;
  beforeOptions: number;
  couponFee = 0;
  couponDiscount = 1;
  quantity: number;
  adjusts = 0;

  includedTaxes: {
    id: string;
    percent: number;
    priceTypes?: string[];
    taxGroup: string;
  }[] = [];

  excludedTaxes: {
    id: string;
    percent: number;
    priceTypes?: string[];
    taxGroup: string;
  }[] = [];

  surcharges: {
    id: string;
    percent: number;
    amountPerProduct: number;
    amount: number;
    accum: number;
    quantity: number;
  }[] = [];
  totalSurcharge = 0;

  discounts: {
    id: string;
    delta: number;
  }[] = [];
  totalDiscount = 0;

  totalIncTax = 0;
  totalExcTax = 0;
  totalTaxValid = false;

  get id() {
    return this.before?.id ?? `${this.opts?.source || ""}_${this.opts?._id || ""}_${this.opts?.type || ""}`;
  }

  get total() {
    return this.computeTotal();
  }

  get price() {
    return this.computeTotal(undefined, 1);
  }

  computeTotal(priceTypes?: string[], qty?: number, all?: boolean, computingTax?: boolean) {
    if (!this.discountable && !all) return 0;

    let total = 0;
    const type = this.opts?.type || "price";
    if (!priceTypes?.length || priceTypes.includes(type)) {
      total += this.beforePrice * this.quantity;
    }
    if (!priceTypes?.length || priceTypes.includes("option")) {
      total += this.beforeOptions * this.quantity;
    }
    if (!priceTypes?.length || priceTypes.includes("optionNoDiscount")) {
      total += (this.before?.extraFee ?? 0) * this.quantity;
    }
    if (!priceTypes?.length || priceTypes.includes("openKey")) {
      total += (this.before?.manualDiscount ?? 0) * this.quantity;
    }
    if (!priceTypes?.length || priceTypes.includes("discount")) {
      total += this.totalDiscount;
    }
    if (!priceTypes?.length || priceTypes.includes("surcharge")) {
      total += this.totalSurcharge;
    }
    if ((!priceTypes?.length && all) || (priceTypes && priceTypes.includes("adjusts"))) {
      total += this.adjusts;
    }
    if (!computingTax) {
      if ((!priceTypes?.length && all) || (priceTypes && priceTypes.includes("tax"))) {
        total += this.computeTotalTax().totalIncTax;
      }
    }

    if (qty) {
      total = (total / this.quantity) * qty;
    }
    return total;
  }

  split(quantity: number) {
    if (quantity >= this.quantity) {
      throw new Error("Cannot split more than the quantity");
    }
    const idx = this.context.list.indexOf(this);
    if (idx === -1) {
      throw new Error("Cannot find the item in the list");
    }
    const newLine = new ProductLineCompute(this.context, this.before, this.opts);
    newLine.quantity = this.quantity - quantity;
    newLine.includedTaxes = this.includedTaxes.map(it => ({ ...it }));
    newLine.excludedTaxes = this.excludedTaxes.map(it => ({ ...it }));
    newLine.surcharges = this.surcharges.map(it => ({
      ...it,
      amount: (it.amount / this.quantity) * newLine.quantity,
      quantity: newLine.quantity,
    }));
    newLine.discounts = this.discounts.map(it => ({ ...it, delta: (it.delta / this.quantity) * newLine.quantity }));
    newLine.totalDiscount = (this.totalDiscount / this.quantity) * newLine.quantity;
    newLine.totalSurcharge = (this.totalSurcharge / this.quantity) * newLine.quantity;

    for (let surcharge of this.surcharges) {
      surcharge.amount = (surcharge.amount / this.quantity) * quantity;
      surcharge.quantity = quantity;
    }

    for (let discount of this.discounts) {
      discount.delta = (discount.delta / this.quantity) * quantity;
    }

    this.quantity = quantity;
    this.context.list.splice(idx + 1, 0, newLine);

    return newLine;
  }

  applyDiscount(discountId: string, delta: number) {
    this.discounts.push({ id: discountId, delta });
    this.totalDiscount += delta;
    this.totalTaxValid = false;
  }

  applyTax(taxId: string, percent: number, included: boolean, priceTypes?: string[], taxGroup?: string) {
    if (included) {
      this.includedTaxes.push({ id: taxId, percent, priceTypes, taxGroup });
    } else {
      this.excludedTaxes.push({ id: taxId, percent, priceTypes, taxGroup });
    }
    this.totalTaxValid = false;
  }

  applySurcharge(surcharge: SurchargeType) {
    const total = this.computeTotal(surcharge.priceTypes, undefined, true);
    const quantity = this.opts ? 0 : this.quantity;
    const amount = ((surcharge.percent ?? 0) * total) / 100 + (surcharge.amountPerProduct ?? 0) * quantity;

    this.surcharges.push({
      id: surcharge._id,
      percent: surcharge.percent ?? 0,
      amountPerProduct: surcharge.amountPerProduct ?? 0,
      amount,
      quantity,
      accum: total,
    });
    this.totalSurcharge += amount;
    this.totalTaxValid = false;
  }

  computeTotalTax(all = false) {
    if (!all && this.totalTaxValid) {
      return {
        totalIncTax: this.totalIncTax,
        totalExcTax: this.totalExcTax,
      };
    }
    const state = {} as Record<string, SessionTaxType>;
    for (let tax of this.includedTaxes) {
      state[tax.id] = {
        _id: tax.id as any,
        name: null,
        disabled: false,
        included: true,
        percent: tax.percent,
        amount: 0,
        productAmount: 0,
        accum: 0,
        lines: [],
        taxGroup: tax.taxGroup,

        surchargeTaxAmount: 0,
        surchargeProductAmount: 0,
        surchargeAccum: 0,
      };
    }
    for (let tax of this.excludedTaxes) {
      state[tax.id] = {
        _id: tax.id as any,
        name: null,
        disabled: false,
        included: false,
        percent: tax.percent,
        amount: 0,
        productAmount: 0,
        accum: 0,
        lines: [],
        taxGroup: tax.taxGroup,

        surchargeTaxAmount: 0,
        surchargeProductAmount: 0,
        surchargeAccum: 0,
      };
    }
    this.computeTax(state, undefined, all);
    const totalIncTax = _.sumBy(
      Object.values(state).filter(it => it.included),
      it => it.amount,
    );

    const totalExcTax = _.sumBy(
      Object.values(state).filter(it => !it.included),
      it => it.amount,
    );

    if (!all) {
      this.totalIncTax = totalIncTax;
      this.totalExcTax = totalExcTax;
      this.totalTaxValid = true;
    }

    return {
      totalIncTax,
      totalExcTax,
    };
  }

  computeIncTax(
    state: Record<string, SessionTaxType>,
    lineState?: Record<string, Record<string, Record<string, number>>>,
  ) {
    const includedPercent = _.sumBy(this.includedTaxes, it => it.percent);
    const applyLineState = applyLineStateBase.bind(null, lineState, this.id);

    for (let tax of this.includedTaxes) {
      const incSurcharge = tax.priceTypes?.length
        ? (tax.priceTypes.includes("surcharge") && this.totalSurcharge) ||
          ((this.opts?.type === "perPerson" || this.opts?.type === "fixed") && tax.priceTypes.includes(this.opts.type))
        : true;

      const total = this.computeTotal(tax.priceTypes, undefined, true, true);
      const beforeTaxTotal = includedPercent ? (total * 100) / (100 + includedPercent) : total;

      const taxAmount = (beforeTaxTotal * tax.percent) / 100;
      const cur = state[tax.id];
      cur.amount += taxAmount;
      cur.productAmount += beforeTaxTotal;
      cur.accum += total;
      applyLineState(tax.id, taxAmount, "amount");

      if (incSurcharge) {
        let total = this.totalSurcharge;
        if (
          (this.opts?.type === "perPerson" || this.opts?.type === "fixed") &&
          tax.priceTypes.includes(this.opts.type)
        ) {
          total += this.beforePrice * this.quantity;
        }
        const beforeTaxTotal = includedPercent ? (total * 100) / (100 + includedPercent) : total;

        const taxAmount = (beforeTaxTotal * tax.percent) / 100;
        const cur = state[tax.id];
        cur.surchargeTaxAmount += taxAmount;
        cur.surchargeProductAmount += beforeTaxTotal;
        cur.surchargeAccum += total;

        applyLineState(tax.id, taxAmount, "surcharge");
      }
    }
  }

  computeExcTax(
    state: Record<string, SessionTaxType>,
    lineState?: Record<string, Record<string, Record<string, number>>>,
  ) {
    const includedPercent = _.sumBy(this.includedTaxes, it => it.percent);
    const applyLineState = applyLineStateBase.bind(null, lineState, this.id);

    for (let tax of this.excludedTaxes) {
      const incSurcharge = tax.priceTypes?.length
        ? (tax.priceTypes.includes("surcharge") && this.totalSurcharge) ||
          ((this.opts?.type === "perPerson" || this.opts?.type === "fixed") && tax.priceTypes.includes(this.opts.type))
        : true;

      const total = this.computeTotal(tax.priceTypes, undefined, true, true);
      const beforeTaxTotal = includedPercent ? (total * 100) / (100 + includedPercent) : total;

      const taxAmount = (beforeTaxTotal * tax.percent) / 100;
      const cur = state[tax.id];
      cur.amount += taxAmount;
      cur.productAmount += beforeTaxTotal;
      cur.accum += total;
      applyLineState(tax.id, taxAmount, "amount");

      if (incSurcharge) {
        let total = this.totalSurcharge;
        if (
          (this.opts?.type === "perPerson" || this.opts?.type === "fixed") &&
          tax.priceTypes.includes(this.opts.type)
        ) {
          total += this.beforePrice * this.quantity;
        }
        const beforeTaxTotal = includedPercent ? (total * 100) / (100 + includedPercent) : total;

        const taxAmount = (beforeTaxTotal * tax.percent) / 100;
        const cur = state[tax.id];
        cur.surchargeTaxAmount += taxAmount;
        cur.surchargeProductAmount += beforeTaxTotal;
        cur.surchargeAccum += total;

        applyLineState(tax.id, taxAmount, "surcharge");
      }
    }
  }

  computeTax(
    state: Record<string, SessionTaxType>,
    lineState?: Record<string, Record<string, Record<string, number>>>,
    all = false,
  ) {
    this.computeIncTax(state, lineState);
    if (!all && this.opts?.type === "paymentSurcharge") return;
    this.computeExcTax(state, lineState);
  }

  computeSurcharge(state: Record<string, SessionSurchargeType>, lineState?: Record<string, Record<string, number>>) {
    const applyLineState = applyLineStateBase.bind(null, lineState, this.id);

    for (let surcharge of this.surcharges) {
      const cur = state[surcharge.id];
      cur.amount += surcharge.amount;
      cur.accum += surcharge.accum;
      cur.accumQty += surcharge.quantity;
      applyLineState(surcharge.id, surcharge.amount);
    }
  }

  computeDiscount(state: Record<string, SessionDiscountType>, lineState?: Record<string, Record<string, number>>) {
    const applyLineState = applyLineStateBase.bind(null, lineState, this.id);

    for (let discount of this.discounts) {
      const cur = state[discount.id];
      cur.discountValue += discount.delta;
      applyLineState(discount.id, discount.delta);
    }
  }
}

export class DiscountComputeContext {
  list: ProductLineCompute[];
  constructor(public session: SessionBase) {
    const productList = [...session.sessionData.products, ...session.cart.map(it => it.toLine())];
    const calList: ProductLineCompute[] = productList
      .filter(it => it.status !== "cancel" && !(it as any).replacing)
      .map(item => new ProductLineCompute(this, item));
    this.list = calList;

    // TODO: optimize, create only when needed
    this.tipsFee = this.applyFee("tips", "tips", "tips", 0, 1, false);
    this.paymentSurchargeFee = this.applyFee("paymentSurcharge", "paymentSurcharge", "paymentSurcharge", 0, 1, false);
  }

  tipsFee: ProductLineCompute;
  paymentSurchargeFee: ProductLineCompute;

  freeSeatCharge = false;
  freeSurcharge = false;

  toTaxItems(items: CouponItem[]) {
    const result: Record<string, SessionTaxType> = {};
    const lineState: Record<string, Record<string, Record<string, number>>> = {};
    const taxedProducts = new Set<string>();

    for (let item of items) {
      const tax = item.tax;
      result[item._id] = {
        _id: tax._id,
        name: tax.name,
        disabled: item.disabled,
        included: tax.included,
        percent: tax.percent,
        amount: 0,
        productAmount: 0,
        accum: 0,
        lines: [],

        surchargeAccum: 0,
        surchargeProductAmount: 0,
        surchargeTaxAmount: 0,
      } as SessionTaxType;
    }

    for (let item of this.list) {
      item.computeTax(result, lineState);
    }

    const taxFree: SessionFreeTaxType = {
      lines: [],
      productAmount: 0,
      surchargeAmount: 0,
      active: false,
    };

    const dict = Object.fromEntries(this.list.map(it => [it.id, it]));

    for (let item of items) {
      const tax = result[item._id];
      tax.lines = Object.entries(lineState[item._id] || {}).map(([id, amount]) => ({
        id,
        amount: amount.amount ?? 0,
        surcharge: amount.surcharge ?? 0,
      }));
      for (let line of tax.lines) {
        taxedProducts.add(line.id);
        const lineInfo = dict[line.id];
        // if surcharge is not included in tax if product is taxable
        if (item.tax.priceTypes?.length && !item.tax.priceTypes.includes("surcharge")) {
          taxFree.surchargeAmount += lineInfo.totalSurcharge;
        }
        if (lineInfo.opts?.type === "fixed" || lineInfo.opts?.type === "perPerson") {
          if (item.tax.priceTypes?.length && !item.tax.priceTypes.includes(lineInfo.opts?.type)) {
            taxFree.surchargeAmount += lineInfo.beforePrice * lineInfo.quantity;
          }
        }
      }
      tax.amount = Math.round(tax.amount * 100) / 100;
      tax.surchargeTaxAmount = Math.round(tax.surchargeTaxAmount * 100) / 100;
    }

    const taxes = Object.values(result);

    for (let tax of taxes) {
      tax.amount = this.roundTax(tax.amount);
      tax.accum = this.roundTax(tax.accum);
      if (tax.included) {
        tax.productAmount = Math.round((tax.accum - tax.amount) * 100) / 100;
      } else {
        tax.productAmount = this.roundTax(tax.productAmount);
      }
      tax.surchargeTaxAmount = this.roundTax(tax.surchargeTaxAmount);
      tax.surchargeAccum = this.roundTax(tax.surchargeAccum);
      if (tax.included) {
        tax.surchargeProductAmount = Math.round((tax.surchargeAccum - tax.surchargeTaxAmount) * 100) / 100;
      } else {
        tax.surchargeProductAmount = this.roundTax(tax.surchargeProductAmount);
      }
    }

    if (this.paymentSurchargeFee) {
      const tax = this.paymentSurchargeFee.computeTotalTax(true);
      if (tax.totalExcTax || tax.totalIncTax) {
        taxedProducts.add(this.paymentSurchargeFee.id);
      }
    }

    for (let product of this.list) {
      if (taxedProducts.has(product.id)) continue;
      if (!product.total) continue;
      taxFree.active = true;
      taxFree.lines.push(product.id);
      const isSurcharge = product.opts?.type && (product.opts.type === "perPerson" || product.opts.type === "fixed");
      taxFree.productAmount += isSurcharge ? 0 : product.total;

      // if line is not taxable, its surcharge is also tax free
      taxFree.surchargeAmount += isSurcharge ? product.total : product.totalSurcharge;
    }
    taxFree.productAmount = this.roundTax(taxFree.productAmount);
    taxFree.surchargeAmount = this.roundTax(taxFree.surchargeAmount);

    return {
      taxes,
      taxFree,
    };
  }

  roundDiscount(amount: number) {
    return roundWithFactor(
      amount,
      this.session.shopData?.discountRoundFactor,
      this.session.shopData?.discountRoundMethod,
    );
  }

  roundTax(amount: number) {
    return roundWithFactor(amount, this.session.shopData?.taxRoundFactor, this.session.shopData?.taxRoundMethod);
  }

  roundSurcharge(amount: number) {
    return roundWithFactor(
      amount,
      this.session.shopData?.surchargeRoundFactor,
      this.session.shopData?.surchargeRoundMethod,
    );
  }

  toSurchargeItems(items: CouponItem[]) {
    const result: Record<string, SessionSurchargeType> = {};
    const lineState: Record<string, Record<string, number>> = {};

    for (let item of items) {
      const surcharge = item.surcharge;
      result[item._id] = {
        _id: surcharge._id,
        name: surcharge.name,
        disabled: item.disabled,
        percent: surcharge.percent,
        amountPerProduct: surcharge.amountPerProduct,
        fixedAmount: surcharge.fixedAmount,
        amountPerPerson: surcharge.amountPerPerson,
        amount: 0,
        accum: 0,
        accumQty: 0,
        lines: [],
      } as SessionSurchargeType;
    }

    for (let item of this.list) {
      item.computeSurcharge(result, lineState);
      if (item.opts?.source === "surcharge") {
        result[item.opts._id].amount += item.beforePrice * item.quantity;
        result[item.opts._id].accumQty += item.quantity;
      }
    }

    for (let item of items) {
      const surcharge = result[item._id];
      surcharge.amount = this.roundSurcharge(surcharge.amount);
      surcharge.lines = Object.entries(lineState[item._id] || {}).map(([id, amount]) => ({ id, amount }));
    }

    return Object.values(result);
  }

  toDiscountItems(items: CouponItem[]) {
    const result: Record<string, SessionDiscountType> = {};
    const lineState: Record<string, Record<string, number>> = {};

    for (let item of items) {
      result[item._id] = {
        _id: (item.discountSource === "gift" && !item.giftRecord ? undefined : item._id) as any,
        name: item.name,
        code: item.discount?.code,
        discount: item.discount?._id,
        coupon: item.coupon?._id,
        couponRule: item.gift?.coupon?._id ?? item.coupon?.coupon?._id,
        gift: item.gift?._id,
        giftRecord: item.giftRecord as any,
        vipLevel: item.vipLevel as any,
        source: item.discountSource as any,
        type: item.rule.type as any,
        point: item.rule.point,
        fixedPoint: item.rule.fixedPoint,
        percentagePoint: item.rule.percentagePoint,
        discountType: item.rule.discountType,
        discountValue: item.discountValue,
        pointValue: item.pointValue,
        disabled: item.disabled,
        valid: item.valid,
        rank: item.rank as any,
        productsValue: [],
        beforeServiceCharge: item.rule.beforeServiceCharge,
      };
    }

    for (let item of this.list) {
      item.computeDiscount(result, lineState);
    }

    for (let item of items) {
      const surcharge = result[item._id];
      surcharge.productsValue = Object.entries(lineState[item._id] || {}).map(([id, discountValue]) => ({
        id,
        discountValue,
      }));
    }

    return Object.values(result);
  }

  applyAdjusts(adjusts: number) {
    if (!adjusts) return;
    adjusts = this.roundDiscount(adjusts);
    let total = _.sumBy(this.list, it => it.total);
    for (let item of this.list) {
      const cur = item.total;
      if (!cur) continue;

      const adjust = this.roundDiscount((cur / total) * adjusts);
      item.adjusts += adjust;
      adjusts -= adjust;
      total -= cur;
    }
    if (adjusts) {
      this.list[0].adjusts += adjusts;
    }
  }

  applyFee(source: string, id: string, type: string, price: number, quantity: number, discountable = true) {
    const compute = new ProductLineCompute(this, null, {
      source,
      _id: id,
      type,
      price,
      quantity,
      discountable,
    });
    this.list.push(compute);
    return compute;
  }

  updateTips() {
    let tips = this.session.sessionData.tips;
    if (tips) {
      const excPercent = _.sumBy(
        this.tipsFee.excludedTaxes.filter(it => !it.priceTypes?.length || it.priceTypes.includes("tips")),
        it => it.percent,
      );
      tips = tips / (1 + excPercent / 100);

      this.tipsFee.beforePrice = tips;
    }
  }

  updatePaymentSurcharge() {
    let paymentSurcharge = this.session.totalPaymentSurcharge;
    if (paymentSurcharge) {
      const excPercent = _.sumBy(
        this.paymentSurchargeFee.excludedTaxes.filter(
          it => !it.priceTypes?.length || it.priceTypes.includes("paymentSurcharge"),
        ),
        it => it.percent,
      );
      paymentSurcharge = paymentSurcharge / (1 + excPercent / 100);

      this.paymentSurchargeFee.beforePrice = paymentSurcharge;
    }
  }

  previewPaymentSurcharge(surcharge: number) {
    const excPercent = _.sumBy(
      this.paymentSurchargeFee.excludedTaxes.filter(
        it => !it.priceTypes?.length || it.priceTypes.includes("paymentSurcharge"),
      ),
      it => it.percent,
    );
    return surcharge / (1 + excPercent / 100);
  }

  calculateDiscountHints(coupons: CouponItem[]) {
    const result: Record<string, DiscountHint> = {};
    const discountDict = Object.fromEntries(coupons.map(it => [getID(it), it]));

    for (let line of this.list) {
      const hint: DiscountHint = {
        active: [],
        hint: [],
      };
      result[line.id] = hint;

      for (let discount of line.discounts) {
        const rule = discountDict[discount.id];
        hint.active.push({
          name: rule.name,
          _id: discount.id,
        });
      }

      for (let coupon of coupons) {
        if (coupon.valid) continue;
        if (
          coupon.error.$t === "errors.noEffect" ||
          coupon.error.$t === "errors.ruleDisabled" ||
          coupon.error.$t === "errors.ruleDeny" ||
          coupon.error.$t === "errors.timeRange" ||
          coupon.error.$t === "errors.notApplicableSection" ||
          coupon.error.$t === "errors.notApplicableOrderType" ||
          coupon.error.$t === "errors.ruleExclude" ||
          coupon.error.$t === "errors.ruleNeedUpdate" ||
          coupon.error.$t === "errors.notCurrentShop"
        )
          continue;
        const condMatched = coupon.allRule.conditions?.find(
          it => it.for === "product" && matchLine(this, line.before, it as any, coupon.rule.productMode),
        );

        if (!condMatched) {
          if (coupon.rule.for !== "product") continue;

          const discountMatched = coupon.rule.discountRules?.find(it =>
            matchLine(this, line.before, it as any, coupon.rule.productMode),
          );
          if (!discountMatched) continue;
        }

        hint.hint.push({
          name: coupon.name,
          _id: coupon._id,
        });
      }
    }

    return result;
  }
}

export type AllRuleType = CouponRuleType | DiscountType | TaxType | SurchargeType;

export interface CouponErrorUpdate {
  $t:
    | "errors.ruleNeedUpdate"
    | "errors.ruleDisabled"
    | "errors.notCurrentShop"
    | "errors.noEffect"
    | "errors.timeRange";
}

export interface CouponErrorEmpty {
  $t: "errors.ruleNothing";
  $ta: {
    rule: AllRuleType;
  };
}

export interface CouponErrorDeny {
  $t: "errors.ruleDeny";
  $ta: {
    rule: AllRuleType;
    target: AllRuleType;
  };
}

export interface CouponErrorOrderFilter {
  $t: "errors.notApplicableSection" | "errors.notApplicableOrderType";
  $ta: {
    rule: AllRuleType;
  };
}

export interface CouponErrorFilter {
  $t: "errors.ruleExclude" | "errors.ruleInclude";
  $ta: {
    rule: AllRuleType;
    matched: ProductLineCompute[];
    cond: CouponRuleCondType;
  };
}

export interface CouponModifierFilter {
  $t: "errors.ruleIncludeModifier" | "errors.ruleExcludeModifier";
  $ta: {
    rule: AllRuleType;
    cond: CouponRuleCondType;
  };
}

export interface CouponErrorFilterCond {
  $t: "errors.ruleMinQty" | "errors.ruleMinTotal" | "errors.ruleMinWeight" | "errors.ruleAfterMinTotal";
  $ta: {
    rule: AllRuleType;
    matched: ProductLineCompute[];
    cond: CouponRuleCondType;
    qty?: number;
    total?: number;
    weight?: number;
    afterTotal?: number;
  };
}

export interface CouponErrorFree {
  $t: "errors.ruleMissingFree" | "errors.ruleExtraFree";
  $ta: {
    rule: AllRuleType;
    extraFree?: ProductLineCompute[];
    missingFree?: CouponRuleFreeType[];
  };
}

export type CouponError =
  | CouponErrorUpdate
  | CouponErrorDeny
  | CouponErrorFilter
  | CouponErrorFilterCond
  | CouponErrorEmpty
  | CouponErrorOrderFilter
  | CouponErrorFree
  | CouponModifierFilter;

export interface ProductOptionFilter {
  option: string;
  values?: string[];
  evalues?: string[];
  optionNumMin?: number;
  optionNumMax?: number;
  optionQtyMin?: number;
  optionQtyMax?: number;
}

function matchOption(line: ProductLine, option: ProductOptionFilter) {
  // find values of the option
  const opt = line.options?.find?.(v => checkID(v.option, option.option));
  if (!opt) return false;
  // check option value intersection
  const selections = (opt.selections || []).filter(selection => {
    if (option.values?.length && !option.values.find(it => checkID(it, selection._id))) {
      return false;
    }
    if (option.evalues?.length && option.evalues.find(it => checkID(it, selection._id))) {
      return false;
    }
    return true;
  });
  const optionNum = new Set(selections.map(s => getID(s))).size;
  const optionQty = _.sumBy(selections, s => s.quantity);
  if (option.optionNumMin && optionNum < option.optionNumMin) return false;
  if (option.optionNumMax && optionNum > option.optionNumMax) return false;
  if (option.optionQtyMin && optionQty < option.optionQtyMin) return false;
  if (option.optionQtyMax && optionQty > option.optionQtyMax) return false;
  return true;
}

function matchLine(
  context: DiscountComputeContext,
  line: ProductLine,
  rule: {
    productOption?: ProductOptionFilter[];
    product?: string[];
    category?: string[];

    eproductOption?: ProductOptionFilter[];
    eproduct?: string[];
    ecategory?: string[];
    sections?: string[];

    timeConditions?: DiscountType["discountRules"][number]["timeConditions"];

    productMode?: "all" | "takeAway" | "dineIn";
  },
  productMode: "all" | "takeAway" | "dineIn",
) {
  if (rule.product?.length) {
    if (!line) return false;
    if (!rule.product.find(it => checkID(it, line.product))) return false;
  }
  if (rule.productOption?.length) {
    if (!line) return false;
    if (!rule.productOption.find(option => matchOption(line, option))) return false;
  }
  if (rule.category?.length) {
    if (!line) return false;
    if (!rule.category.find(it => checkID(it, line.category))) return false;
  }
  if (rule.sections?.length) {
    if (!line) return false;
    if (!rule.sections.find(it => checkID(it, line.section))) return false;
  }

  if (rule.eproduct?.length) {
    if (line && rule.eproduct.find(it => checkID(it, line.product))) return false;
  }
  if (rule.eproductOption?.length) {
    if (line && rule.eproductOption.find(option => matchOption(line, option))) return false;
  }
  if (rule.ecategory?.length) {
    if (line && rule.ecategory.find(it => checkID(it, line.category))) return false;
  }

  if (rule.timeConditions?.length) {
    const timeslot = rule.timeConditions.find(it => {
      if (it.timeType === "range") {
        // check time from start to pay or current time
        const start = context.session.sessionData?.startTime ?? new Date();
        const end =
          context.session.sessionData?.checkBillTime ??
          context.session.sessionData?.paidTime ??
          context.session.sessionData?.endTime ??
          new Date();

        return checkTimeRange(it.weekdays, it.from, it.to, start, end, it.startDate, it.endDate);
      } else {
        let time = new Date();
        switch (it.timeType) {
          case "start":
            time = context.session.sessionData?.startTime ?? new Date();
            break;
          case "book":
            time = context.session.sessionData?.bookedTime ?? new Date();
            break;
          case "create":
            time = context.session.sessionData?.createTime ?? new Date();
            break;
          case "pay":
            time =
              context.session.sessionData?.checkBillTime ??
              context.session.sessionData?.paidTime ??
              context.session.sessionData?.endTime ??
              new Date();
            break;
          case "order":
            time = line?.date ?? context.session.sessionData?.startTime ?? new Date();
            break;
        }
        return checkTime(it.weekdays, it.from, it.to, time, it.startDate, it.endDate);
      }
    });

    if (!timeslot) return false;
  }

  if (rule.productMode && rule.productMode !== "all") {
    if (context.session.sessionData?.type === "dineIn" || context.session.sessionData?.type === "dineInNoTable") {
      if (rule.productMode === "takeAway" && !line?.takeAway) return false;
      if (rule.productMode === "dineIn" && line?.takeAway) return false;
    } else if (context.session.sessionData?.type === "takeAway" && rule.productMode === "dineIn") {
      return false;
    }
  }

  if (productMode && productMode !== "all") {
    if (context.session.sessionData?.type === "dineIn" || context.session.sessionData?.type === "dineInNoTable") {
      if (productMode === "takeAway" && !line?.takeAway) return false;
      if (productMode === "dineIn" && line?.takeAway) return false;
    } else if (context.session.sessionData?.type === "takeAway" && productMode === "dineIn") {
      return false;
    }
  }

  return true;
}

function matchProductMode(
  context: DiscountComputeContext,
  line: ProductLine,
  productMode: "all" | "takeAway" | "dineIn",
) {
  if (productMode && productMode !== "all") {
    if (context.session.sessionData?.type === "dineIn" || context.session.sessionData?.type === "dineInNoTable") {
      if (productMode === "takeAway" && !line?.takeAway) return false;
      if (productMode === "dineIn" && line?.takeAway) return false;
    } else if (context.session.sessionData?.type === "takeAway" && productMode === "dineIn") {
      return false;
    }
  }
  return true;
}

@Component
export class CouponItem extends Vue {
  @Prop()
  coupon: CouponType;

  @Prop()
  discount: DiscountType;

  @Prop()
  gift: GiftType;

  giftRecord: string = null;

  @Prop()
  vipLevel: string;

  @Prop()
  rank: string;

  @Prop()
  tax: TaxType;

  @Prop()
  surcharge: SurchargeType;

  @Prop({ default: "manual" })
  discountSource: "manual" | "automatic" | "vip" | "memberRank" | "coupon" | "gift" | "tax" | "surcharge";

  disabled = false;
  tempID = uuid();
  code = "";

  get active() {
    return !this.disabled;
  }
  set active(v) {
    this.disabled = !v;
  }

  get _id() {
    if (this.gift) {
      return getID(this.giftRecord || this.tempID);
    }
    return getID(this.coupon?._id || this.allRule?._id);
  }

  get rule() {
    return this.gift?.coupon || this.coupon?.coupon || this.discount;
  }

  get allRule(): AllRuleType {
    return this.rule || this.tax || this.surcharge;
  }

  error: CouponError = { $t: "errors.ruleNeedUpdate" };

  discountValue = 0;
  pointValue = 0;

  get valid() {
    return !this.error;
  }

  get name() {
    return this.allRule.name;
  }

  get parent() {
    return this.$parent as SessionBase;
  }

  coexists(item: CouponItem) {
    if (!this.valid) return true;
    const rule = this.allRule;
    if (rule.ruleType === "allowlist") {
      if (
        !((rule.rules as any) || []).find(it => checkID(it, item.rule)) &&
        !(rule.rulesDiscount || []).find(it => checkID(it, item.rule)) &&
        !(rule.rulesTax || []).find(it => checkID(it, item.rule))
      ) {
        return false;
      }
    } else if (rule.ruleType === "denylist") {
      if (
        ((rule.rules as any) || []).find(it => checkID(it, item.rule)) ||
        (rule.rulesDiscount || []).find(it => checkID(it, item.rule)) ||
        (rule.rulesTax || []).find(it => checkID(it, item.rule))
      ) {
        return false;
      }
    }
    return true;
  }

  updatePreCheck(context: DiscountComputeContext) {
    const list = context.list;

    if (!this.parent.coupons) return;
    const index = this.parent.coupons.indexOf(this);
    const rulesBefore = this.parent.coupons.slice(0, index).filter(it => it.valid);

    this.error = null;

    if (this.disabled || this.allRule?.status === "draft") {
      this.error = { $t: "errors.ruleDisabled" };
      return false;
    }

    if (this.allRule.timeConditions?.length) {
      const timeslot = this.allRule.timeConditions.find(it => {
        if (it.timeType === "range") {
          // check time from start to pay or current time
          const start = context.session.sessionData?.startTime ?? new Date();
          const end =
            context.session.sessionData?.checkBillTime ??
            context.session.sessionData?.paidTime ??
            context.session.sessionData?.endTime ??
            new Date();

          return checkTimeRange(it.weekdays, it.from, it.to, start, end, it.startDate, it.endDate);
        } else {
          let time = new Date();
          switch (it.timeType) {
            case "start":
              time = context.session.sessionData?.startTime ?? new Date();
              break;
            case "book":
              time = context.session.sessionData?.bookedTime ?? new Date();
              break;
            case "create":
              time = context.session.sessionData?.createTime ?? new Date();
              break;
            case "pay":
              time =
                context.session.sessionData?.checkBillTime ??
                context.session.sessionData?.paidTime ??
                context.session.sessionData?.endTime ??
                new Date();
              break;
          }
          return checkTime(it.weekdays, it.from, it.to, time, it.startDate, it.endDate);
        }
      });

      if (!timeslot) {
        this.error = { $t: "errors.timeRange" };
        return false;
      }
    }

    const rule = this.allRule;
    if (rule.shops?.length && !rule.shops.find(s => checkID(s, this.parent.shopId))) {
      this.error = { $t: "errors.notCurrentShop" };
      return false;
    }

    for (let before of rulesBefore) {
      if (!this.coexists(before) || !before.coexists(this)) {
        this.error = { $t: "errors.ruleDeny", $ta: { rule, target: before.rule } };
        return false;
      }
    }

    if (rule.sections?.length && !rule.sections.find(s => checkID(s, this.parent.sectionId))) {
      this.error = { $t: "errors.notApplicableSection", $ta: { rule } };
      return false;
    }

    if (rule.orderType?.length && !rule.orderType.includes(this.parent.sessionData?.type)) {
      this.error = { $t: "errors.notApplicableOrderType", $ta: { rule } };
      return false;
    }

    if (rule.conditions?.length) {
      for (let cond of rule.conditions) {
        const matched =
          cond.for === "order"
            ? list
            : list.filter(p => p.countAsCondition && matchLine(context, p.before, cond as any, rule.productMode));

        if (cond.for === "exclude") {
          if (matched.length) {
            this.error = { $t: "errors.ruleExclude", $ta: { rule, matched, cond } };
            return false;
          }
        } else if (cond.for === "product") {
          if (!matched.length) {
            this.error = { $t: "errors.ruleInclude", $ta: { rule, matched, cond } };
            return false;
          }
        }

        const qty = _.sumBy(matched, p => (p.countAsCondition ? p.quantity ?? 0 : 0));
        const weight = _.sumBy(matched, p => p.before?.weight ?? 0);
        const total = _.sumBy(matched, p => p.beforePrice * p.quantity);
        const afterTotal = _.sumBy(matched, p => p.computeTotal(rule.priceTypes));

        if (cond.minQty && qty < cond.minQty) {
          this.error = { $t: "errors.ruleMinQty", $ta: { rule, matched, cond, qty } };
          return false;
        }

        if (cond.minWeight && weight < cond.minWeight) {
          this.error = { $t: "errors.ruleMinWeight", $ta: { rule, matched, cond, weight } };
          return false;
        }

        if (cond.minTotal && total < cond.minTotal) {
          this.error = { $t: "errors.ruleMinTotal", $ta: { rule, matched, cond, total } };
          return false;
        }

        if (cond.afterMinTotal && afterTotal < cond.afterMinTotal) {
          this.error = { $t: "errors.ruleAfterMinTotal", $ta: { rule, matched, cond, afterTotal } };
          return false;
        }

        if (cond.includeModifier?.length) {
          if (
            !cond.includeModifier.find(mod => !!this.parent?.sessionData?.modifiers?.find?.(it => checkID(it, mod)))
          ) {
            this.error = { $t: "errors.ruleIncludeModifier", $ta: { rule, cond } };
            return false;
          }
        }

        if (cond.excludeModifier?.length) {
          if (cond.excludeModifier.find(mod => !!this.parent?.sessionData?.modifiers?.find?.(it => checkID(it, mod)))) {
            this.error = { $t: "errors.ruleExcludeModifier", $ta: { rule, cond } };
            return false;
          }
        }
      }
    }

    return true;
  }

  updateCoupon(context: DiscountComputeContext) {
    // TODO: cart
    // if (coupon.coupon.type == 'free' && !this.session.cart.find(c => c._id == coupon.coupon.product)) {
    //     this.$store.commit('SET_ERROR', this.$t('scanner.addToCartFirst'))
    //     return;
    // }

    if (!this.updatePreCheck(context)) return false;

    const list = context.list;

    this.pointValue = 0;

    let pointValue = 0;

    const rule = this.rule;
    const id = this._id;

    function addProductDiscountValue(
      it: ProductLineCompute,
      delta: number,
      qty: number = it.quantity,
      lastPicked?: Map<ProductLineCompute, number>,
    ) {
      delta = context.roundDiscount(delta);
      if (it.quantity !== qty) {
        const newLine = it.split(qty);
        if (lastPicked && lastPicked.get(it) > it.quantity) {
          const move = lastPicked.get(it) - it.quantity;
          lastPicked.set(newLine, move);
          lastPicked.set(it, it.quantity);
        }
      }
      it.applyDiscount(id, delta);
    }

    function addOrderDiscountValue(delta: number, sumPrice: number) {
      delta = context.roundDiscount(delta);
      if (sumPrice) {
        for (let it of list) {
          const total = it.computeTotal(rule.priceTypes);
          const sign = Math.sign(delta);
          const cur =
            Math.min(Math.abs(delta), context.roundDiscount((total / (sumPrice || 1)) * Math.abs(delta))) * sign;
          delta -= cur;
          sumPrice -= total;
          it.applyDiscount(id, cur);
        }
      }
    }

    switch (rule.type) {
      case "point":
      case "discount": {
        if (rule.for === "product") {
          const lastPicked = new Map<ProductLineCompute, number>();

          do {
            const isFirst = !lastPicked.size;
            const set = new Map<ProductLineCompute, number>();
            let flag = true;
            for (let cond of rule.discountRules) {
              let matched = list.filter(p => matchLine(context, p.before, cond as any, rule.productMode));
              switch (cond.pickOrder) {
                case "orderDesc": {
                  matched = matched.reverse();
                  break;
                }
                case "priceAsc": {
                  matched = _.sortBy(matched, p => p.computeTotal(rule.priceTypes, 1));
                  break;
                }
                case "priceDesc": {
                  matched = _.sortBy(matched, p => -p.computeTotal(rule.priceTypes, 1));
                  break;
                }
              }
              const selfPicked = new Map<ProductLineCompute, number>();
              let cur = 0;
              for (let it of matched) {
                let toPick = (it.quantity || 0) - (selfPicked.get(it) ?? 0) - (lastPicked.get(it) ?? 0);
                if (cond.maxQty && toPick + cur > cond.maxQty) {
                  toPick = Math.max(0, cond.maxQty - cur);
                }
                if (!toPick) continue;
                // TODO: handle partial quantity
                selfPicked.set(it, (selfPicked.get(it) ?? 0) + toPick);
                cur += toPick;
                if (cur === cond.maxQty) break;
              }
              const isRuleApplied = cur && (!cond.minQty || cur >= cond.minQty);
              // @ts-ignore
              if (!isRuleApplied && !rule.discountAny) {
                flag = false;
                break;
              }
              for (let [it, picked] of selfPicked) {
                set.set(it, (set.get(it) ?? 0) + picked);
              }
            }

            if (!set.size || !flag) {
              if (!lastPicked.size) {
                this.error = { $t: "errors.ruleNothing", $ta: { rule } };
                return false;
              } else {
                break;
              }
            }

            for (let [it, picked] of set) {
              lastPicked.set(it, (lastPicked.get(it) ?? 0) + picked);
            }

            if (rule.type === "point") {
              if (!this.parent.sessionData.user) {
                this.error = { $t: "errors.noEffect" };
                return false;
              }
              for (let [it, picked] of set) {
                pointValue +=
                  (rule.percentagePoint || 0) * it.computeTotal(rule.priceTypes, picked) + (rule.fixedPoint || 0);
              }
            } else {
              if (rule.discountType === "percent") {
                for (let [it, picked] of set) {
                  const price = it.computeTotal(rule.priceTypes, picked);
                  const delta = Math.max(-price, (rule.discount - 1) * price);
                  addProductDiscountValue(it, delta, picked, lastPicked);
                }
              } else if (rule.discountType === "percentPre") {
                for (let [it, picked] of set) {
                  const delta = Math.max(
                    -it.computeTotal(rule.priceTypes, picked),
                    (rule.discount - 1) * it.beforePrice * picked,
                  );
                  addProductDiscountValue(it, delta, picked, lastPicked);
                }
              } else if (rule.discountType === "amount") {
                let sumPrice = _.sumBy(Array.from(set.entries()), l => l[0].computeTotal(rule.priceTypes, l[1]));
                let delta = Math.max(-sumPrice, rule.discount);
                if (sumPrice) {
                  for (let [it, picked] of set) {
                    const curTotal = it.computeTotal(rule.priceTypes, picked);
                    const curDelta = context.roundDiscount((curTotal / (sumPrice || 1)) * delta);

                    delta -= curDelta;
                    sumPrice -= curTotal;
                    addProductDiscountValue(it, curDelta, picked, lastPicked);
                  }

                  if (delta) {
                    const last = Array.from(set.entries()).at(-1);
                    addProductDiscountValue(last[0], delta, last[1], lastPicked);
                  }
                }
              } else if (rule.discountType === "pickNMaxPrice") {
                const flattendPick = _.sortBy(
                  Array.from(set).flatMap(it => new Array(it[1]).fill(null).map(_ => it[0])),
                  it => -it.computeTotal(rule.priceTypes, 1),
                );

                if (flattendPick.length < (rule.discount ?? 1)) {
                  break;
                }

                const priceToZero = Object.entries(
                  _.groupBy(flattendPick.slice(rule.discount ?? 1), it => it.before.id),
                ).map(([k, v]) => [v[0], v.length] as const);

                if (!priceToZero.length) {
                  if (!isFirst) break;
                  this.error = { $t: "errors.ruleNothing", $ta: { rule } };
                  return false;
                }

                for (let [it, picked] of priceToZero) {
                  addProductDiscountValue(it, -it.computeTotal(rule.priceTypes, picked), picked, lastPicked);
                }
              }
            }
          } while (rule.repeat);
        } else if (rule.for === "order") {
          const sumPrice = _.sumBy(list, l => l.computeTotal(rule.priceTypes));

          let fulfillPrice = sumPrice;
          if (rule.targetStepAmount) {
            let step = Math.floor(fulfillPrice / (rule.targetStepAmount || 1));
            if (rule.maxStep && step > rule.maxStep) step = rule.maxStep;
            fulfillPrice = step * (rule.targetStepAmount || 1);
          }

          if (rule.type === "point") {
            if (!this.parent.sessionData.user) {
              this.error = { $t: "errors.noEffect" };
              return false;
            }
            pointValue = (rule.percentagePoint || 0) * fulfillPrice + (rule.fixedPoint || 0);
          } else if (rule.discountType === "percent") {
            const delta = Math.max(-sumPrice, (rule.discount - 1) * fulfillPrice);
            addOrderDiscountValue(delta, sumPrice);
          } else if (rule.discountType === "percentPre") {
            let sumBeforePrice = _.sumBy(list, l =>
              l.computeTotal(["price", "option", "optionNoDiscount", "surcharge", "openKey"]),
            );

            if (rule.targetStepAmount) {
              let step = Math.floor(sumBeforePrice / (rule.targetStepAmount || 1));
              if (rule.maxStep && step > rule.maxStep) step = rule.maxStep;
              sumBeforePrice = step * (rule.targetStepAmount || 1);
            }

            const delta = Math.max(-sumPrice, (rule.discount - 1) * sumBeforePrice);
            addOrderDiscountValue(delta, sumPrice);
          } else if (rule.discountType === "amount") {
            const delta = Math.max(-sumPrice, rule.discount);
            addOrderDiscountValue(delta, sumPrice);
          } else if (rule.discountType === "freeSeatCharge") {
            context.freeSeatCharge = true;
          } else if (rule.discountType === "freeSurcharge") {
            context.freeSurcharge = true;
          }
        }
        break;
      }
      case "free": {
        const currentList = list.filter(it => checkID(it.before?.fromCoupon, this._id) && it.quantity === 1);
        const missingFree = rule.freeProducts.filter(p => {
          const idx = currentList.findIndex(it => {
            if (!checkID(it.before.product, p.product) || it.quantity !== 1) return false;

            if (p.options?.length) {
              if (
                !p.options.find(option => {
                  // find values of the option
                  const opt = it.before.options?.find?.(v => checkID(v.option, option.option));
                  if (!opt) return false;
                  // check option value intersection
                  return !!opt.values.find(v => option.values.find(v2 => checkID(v, v2)));
                })
              )
                return false;
            }
            if (p.productOption?.length) {
              if (!p.productOption.find(option => matchOption(it.before, option as any))) {
                return false;
              }
            }
            if (p.eproductOption?.length) {
              if (p.eproductOption.find(option => matchOption(it.before, option as any))) {
                return false;
              }
            }
            return true;
          });
          if (idx === -1) {
            return true;
          }
          addProductDiscountValue(currentList[idx], -currentList[idx].computeTotal(rule.priceTypes));
          currentList.splice(idx, 1);
          return false;
        });

        if (missingFree.length) {
          this.error = { $t: "errors.ruleMissingFree", $ta: { rule, missingFree } };
          return false;
        }

        if (currentList.length) {
          this.error = { $t: "errors.ruleExtraFree", $ta: { rule, extraFree: currentList } };
          return false;
        }
        break;
      }
    }

    this.pointValue = Math.floor(pointValue);

    return true;
  }

  updateTax(context: DiscountComputeContext) {
    if (!this.updatePreCheck(context)) return false;
    const list = context.list;

    const rules = this.tax.rules || [];
    for (let line of list) {
      if (
        (!rules.length && matchProductMode(context, line.before, this.tax.productMode)) ||
        rules.find(r => matchLine(context, line.before, r as any, this.tax.productMode))
      ) {
        line.applyTax(this._id, this.tax.percent, this.tax.included, this.tax.priceTypes, this.tax.taxGroup);
      }
    }

    return true;
  }

  updateSurcharge(context: DiscountComputeContext) {
    if (!this.updatePreCheck(context)) return false;
    const list = context.list;

    const rules = this.surcharge.rules || [];
    if (this.surcharge.percent || this.surcharge.amountPerProduct) {
      for (let line of list) {
        if (
          (!rules.length && matchProductMode(context, line.before, this.surcharge.productMode)) ||
          rules.find(r => matchLine(context, line.before, r as any, this.surcharge.productMode))
        ) {
          line.applySurcharge(this.surcharge);
        }
      }
    }

    if (this.surcharge.fixedAmount) {
      context.applyFee(
        "surcharge",
        getID(this.surcharge),
        "fixed",
        this.surcharge.fixedAmount,
        1,
        this.surcharge.discountable,
      );
    }

    if (this.surcharge.amountPerPerson && (context.session.isDineInNoTable || context.session.isDineIn)) {
      context.applyFee(
        "surcharge",
        getID(this.surcharge),
        "perPerson",
        this.surcharge.amountPerPerson,
        context.session.capacity,
        this.surcharge.discountable,
      );
    }

    return true;
  }

  remove() {
    const c = this.parent.coupons;
    const related = this.parent.cart.filter(it => it.coupon === this || checkID(it.fromCoupon, this._id));

    {
      const idx = c.indexOf(this);
      if (idx !== -1) {
        c.splice(idx, 1);
        this.$destroy();
      }
    }

    {
      const coupons = this.parent.sessionData?.discounts ?? [];
      const idx = coupons.findIndex(
        it =>
          checkID(it._id, this._id) || (!it._id && !it.giftRecord && !this.giftRecord && checkID(it.gift, this.gift)),
      );
      if (idx !== -1) {
        coupons.splice(idx, 1);
      }
    }

    for (let item of related) {
      item.remove();
    }
  }

  async tryFix() {
    if (!this.error) return false;

    switch (this.error.$t) {
      case "errors.ruleMissingFree": {
        for (let extra of this.error.$ta.missingFree) {
          const cart = await this.parent.getProductWithOptions(getID(extra.product));
          for (let option of extra.options) {
            const opt = cart.options.find(it => checkID(it.options, option.option));
            if (opt) {
              opt.fixed = true;
              opt.free = true;
              opt.values = option.values as any[];
            }
          }

          const item = this.parent.addToCart(cart, {
            mustInsert: true,
            coupon: this,
          });

          if (
            this.parent.sessionData?.type === "takeAway" ||
            this.parent.sessionData?.type === "dineInNoTable" ||
            this.parent.sessionData?.type === "delivery"
          ) {
            item.status = "hold";
          }
        }
        break;
      }
      case "errors.ruleExtraFree": {
        for (let extra of this.error.$ta.extraFree) {
          const cart = this.parent.cart.find(it => it.id === extra.before.id);
          if (cart) {
            cart.remove();
          }
        }
        break;
      }
      default:
        return false;
    }

    this.parent.updateCoupons();
    return true;
  }
}
