import { PrinterBase } from "./printers/base";
import { PrinterServer, PrinterCommand, wrapVue } from ".";
import { PrintJob } from "./printJob";
import { StarGraphPrintSequence } from "./printSequence/starGraph";
import { EscPosPrintSequence } from "./printSequence/escpos";
import { HTMLPrintSequence } from "./printSequence/html";
import moment from "moment";
import { EPosSequence } from "./printSequence/epos";
import EventEmitter from "events";
export { PrintJob } from "./printJob";
export class PrintQueue extends EventEmitter {
  constructor(
    printer: PrinterBase,
    public server: PrinterServer,
  ) {
    super();
    this.reset(printer);
    printer.on("statusChanged", this.updatePrinter.bind(this));
  }

  updatePrinter() {
    if (this.printer.connected && !this.printWorker) {
      this.handleJob();
    }
    this.server.sendRemoteEvent({
      command: "printerStatus",
      ...this.getDeviceInfo(),
    });
  }

  printer: PrinterBase;
  type: string;
  port: string;

  get connected() {
    return this.printer?.connected;
  }

  get id() {
    return this.printer?.conf?.id;
  }

  jobs: PrintJob[] = [];
  allJobs: PrintJob[] = [];
  printWorker: Promise<void>;
  currentJob: PrintJob = null;
  lastJob = 0;
  lastError: string = null;

  pendingCount = 0;
  doneCount = 0;
  errorCount = 0;

  _pendingJobs: Set<string>;
  _errorJobs: Set<string>;
  _doneJobs: Set<string>;

  print(job: PrintJob) {
    if (this.jobs.length > 200) {
      throw new Error("Print queue is full");
    }
    this.jobs.push(job);
    this.allJobs.push(job);
    this.updateJobStatus(job);
    if (this.allJobs.length > 200) {
      while (this.allJobs.length > 200) {
        const job = this.allJobs.shift();
        this._removeJob(job);
      }
      this._flushCount();
    }

    this.handleJob();
  }

  handleJob() {
    if (this.printWorker || !this.jobs.length) return;
    const worker = (this.printWorker = this.printCore().finally(() => {
      if (this.printWorker === worker) {
        this.printWorker = null;
        this.currentJob = null;
        if (this.jobs.length && (this.printer.connected || !this.printer.autoReconnecting)) this.handleJob();
        else if (!this.jobs.length) {
          this.printer.beginIdle();
        }
      }
    }));
  }

  async printCore() {
    if (Date.now() - this.lastJob < 5000 && this.printer.conf.opts?.manualCut) {
      await this.server.context.$openDialog(
        import("./dialogs/ManualCutDialog.vue"),
        {},
        {
          maxWidth: "400px",
        },
      );
    }

    const curLength = this.jobs.length;
    const job = this.jobs.shift();
    let isConnectedBefore = this.printer.connected;
    let isWaitingReady = true;
    if (!job || job.status !== "queued") return;
    try {
      if (!(await this.printer.waitReady())) {
        return;
      }
      isWaitingReady = false;
      if (job.expires && job.createTime.getTime() + job.expires > Date.now()) {
        job.status = "cancel";
        this.updateJobStatus(job);
        return;
      }
      job.lastPreFailed = false;
      this.currentJob = job;
      job.retryCount++;
      job.status = "printing";
      this.updateJobStatus(job);
      if (job.type === "cmd" && this.printer.conf.opts?.escpos === "starGraph" && !job.data.length) {
        // raster for star graph
        const seq = new StarGraphPrintSequence(wrapVue(this.server.context), this.printer);
        await seq.printCmds((job as any).job.cmds);
        const newJob = await seq.getJob("", "");
        job.data = newJob.data;
        if (job.opts) {
          job.opts.type = "star";
        } else {
          job.opts = { type: "star " };
        }
      } else if (job.type === "cmd" && this.printer.conf.opts?.escpos === "escposGraph" && !job.data.length) {
        // raster for star graph
        const seq =
          this.printer.conf.port === "debug"
            ? new HTMLPrintSequence(wrapVue(this.server.context), this.printer)
            : this.printer.conf.port === "epos" && this.printer.conf.opts?.epos
              ? new EPosSequence(wrapVue(this.server.context), this.printer)
              : new EscPosPrintSequence(wrapVue(this.server.context), this.printer);
        await seq.printCmds((job as any).job.cmds);
        const newJob = await seq.getJob("", "");
        job.data = newJob.data;
        if (job.opts) {
          Object.assign(job.opts, seq.getJobOpts());
        } else {
          job.opts = seq.getJobOpts();
        }
      }
      await this.printer.print(job);
      if (!(await this.printer.waitDone(job))) {
        // @ts-ignore
        if (job.status !== "cancel") {
          job.status = "queued";
          this.jobs.unshift(job);
        }
        return;
      }
      if (job.remoteId && job.remoteOpts?.pollingCheck) {
        job.status = "remoteQueued";
        this.printer.startPollingUpdate(job, this);
      } else {
        job.status = "done";

        if (job.opts && ((job as any)?.job?.cmds?.length) && (this.printer.conf.opts?.escpos === "escposGraph" || this.printer.conf.opts?.escpos === "starGraph")) {
          // cleanup raster data to save memory
          job.data = Buffer.alloc(0);
          job.opts.type = "cmd";
        }
      }
      this.updateJobStatus(job);
    } catch (e) {
      this.lastError = `${moment().format("YYYY-MM-DD HH:mm:ss")} ${e.message}`;
      if (job.lastPreFailed) {
        job.retryCount--;
      }
      if (this.connected) {
        if (job.lastPreFailed || (this.printer.retryable && job.retryable && job.retryCount < 5)) {
          job.lastError = e.message;
          // @ts-ignore
          if (job.status !== "cancel") {
            job.status = "queued";
            this.jobs.unshift(job);
          }
        } else {
          job.status = "error";
        }
        await new Promise(resolve => setTimeout(resolve, 500));
      } else {
        if (e.message && e.message.includes("No record found for id")) {
          job.status = "error";
        } else {
          if (isWaitingReady && !isConnectedBefore) {
            if (job.lastPreFailed || (this.printer.retryable && job.retryable && job.retryCount < 5)) {
              job.lastError = e.message;
              // @ts-ignore
              if (job.status !== "cancel") {
                job.status = "queued";
                this.jobs.unshift(job);
              }
            } else {
              job.status = "error";
            }
            await new Promise(resolve => setTimeout(resolve, 500));
          } else {
            // @ts-ignore
            if (job.status !== "cancel") {
              job.status = "queued";
              this.jobs.unshift(job);
            }
          }
        }
      }
      this.updateJobStatus(job);
      console.warn(e);
    } finally {
      if (this.jobs.length < curLength) {
        this.emit("queueChanged");
      }
    }
    this.lastJob = Date.now();
  }

  async close() {
    await this.printer.disconnect(true);
    this.printer.dispose();
  }

  reset(printer: PrinterBase) {
    if (this.printer) {
      this.printer.disconnect(true).catch(e => console.warn(e));
      this.printer.queue = null;
      this.printer = null;
    }
    this.type = printer.conf.type;
    this.port = printer.conf.port;
    this.printer = printer;
    this.printer.queue = this;
    this.printWorker = null;
    if (this.currentJob) {
      this.currentJob.status = "queued";
      this.jobs.unshift(this.currentJob);
      this.currentJob = null;
    }
    this.handleJob();
    this.resetSharing();
  }

  resetSharing() {
    if (this.printer?.conf?.shared) {
      if (!this.advertiseWorkerPromise) {
        this.advertiseWorkerPromise = this.advertiseWorker();
      }
      this.advertise();
    }
  }

  advertiseWorkerPromise: Promise<void> = null;

  async advertiseWorker() {
    try {
      while (this.printer && this.printer.conf.shared) {
        this.advertise();
        await new Promise(resolve => setTimeout(resolve, 60 * 1000));
      }
      await new Promise(resolve => setTimeout(resolve, 10));
    } finally {
      this.advertiseWorkerPromise = null;
    }
  }

  async clear() {
    const removeJobs = this.allJobs.filter(
      it => it.status === "done" || it.status === "cancel" || it.status === "error",
    );
    this.allJobs = this.allJobs.filter(it => it.status !== "done" && it.status !== "cancel" && it.status !== "error");

    for (const job of removeJobs) {
      this._removeJob(job);
    }

    this._flushCount();
    await this.printer?.clear?.();
  }

  advertise() {
    if (!this.printer || !this.printer.conf.shared) return;
    this.server.sendRemoteEvent({
      command: "advertise",
      ...this.getDeviceInfo(),
    });
  }

  handleConnect(ev: PrinterCommand) {
    if (!this.printer || !this.printer.conf.shared) return;
    this.server.sendRemoteEvent({
      command: "status",
      clientId: ev.clientId,
      ...this.getDeviceInfo(),
    });
  }

  get status(): PrinterCommand["status"] {
    if (this.printer.connected) {
      if (this.printer.noPaper) return "noPaper";
      else if (this.currentJob) return "printing";
      else return "ready";
    } else {
      return "offline";
    }
  }

  getDeviceInfo(): Partial<PrinterCommand> {
    return {
      deviceId: this.printer.conf.id,
      serverId: this.server.id,
      deviceName: this.printer.conf.name,
      deviceType: this.printer.conf.type,
      deviceOpts: this.printer.conf.opts,
      deviceQueue: this.jobs.length,
      status: this.status,
      deviceConnected: this.printer.connected,
      lastError: this.lastError,
      lastDisconnectReason: this.printer.lastDisconnectReason,
      lastProbe: this.printer.lastProbe,
    };
  }

  updateJob(ev: PrinterCommand) {
    const job = this.allJobs.find(it => it.id === ev.jobId);
    if (job) {
      job.status = ev.jobStatus;
    }
  }

  updateJobStatus(job: PrintJob) {
    this._removeJob(job);
    if (job.status === "error") {
      if (!this._errorJobs) {
        this._errorJobs = new Set();
      }
      this._errorJobs.add(job.id);
    } else if (job.status === "done") {
      if (!this._doneJobs) {
        this._doneJobs = new Set();
      }
      this._doneJobs.add(job.id);
    } else if (job.status === "printing" || job.status === "queued" || job.status === "remoteQueued") {
      if (!this._pendingJobs) {
        this._pendingJobs = new Set();
      }
      this._pendingJobs.add(job.id);
    }
    this._flushCount();

    job.emit("job" + job.status);
    if (job.status === "cancel" || job.status === "error" || job.status === "done") job.emit("end");
    if (!this.printer || !this.printer.conf.shared) return;
    this.server.sendRemoteEvent({
      command: "printStatus",
      jobId: job.id,
      jobStatus: job.status,
      retryable: job.retryable,
      lastError: job.lastError,
      ...this.getDeviceInfo(),
    });
  }

  cancelJob(job: PrintJob) {
    const idx = this.jobs.findIndex(item => item.id === job.id);
    if (idx !== -1) {
      const job = this.jobs[idx];
      job.status = "cancel";
      this.updateJobStatus(job);
      this.jobs.splice(idx, 1);

      if (this.printer.conf.port === "net") {
        this.server.sendRemoteEvent({
          command: "cancel",
          jobId: job.id,
          deviceId: this.printer.conf.id,
          serverId: this.printer.device,
          clientId: this.server.id,
        });
      }
    }
    const idx2 = this.allJobs.findIndex(item => item.id === job.id);
    if (idx2 !== -1) {
      const job = this.allJobs[idx2];
      job.status = "cancel";
      this.updateJobStatus(job);
    }
  }

  retryJob(job: PrintJob) {
    if (this.jobs.find(item => item.id === job.id)) {
      return;
    }
    const newJob = this.allJobs.find(item => item.id === job.id);
    if (newJob) {
      newJob.retryCount = 0;
      if (newJob.status === "done") {
        if (newJob.uniqueJobId) {
          const m = newJob.uniqueJobId.match(/-\d+$/);
          if (m) {
            newJob.uniqueJobId = newJob.uniqueJobId.replace(/-\d+$/, `-${parseInt(m[0].slice(1)) + 1}`);
          } else {
            newJob.uniqueJobId = `${newJob.uniqueJobId}-1`;
          }
        }
      }
      newJob.status = "queued";
      this.jobs.push(newJob);
      this.handleJob();
    } else {
      job.status = "queued";
      this.print(job);
    }
  }

  _removeJob(job: PrintJob) {
    if (this._errorJobs) {
      this._errorJobs.delete(job.id);
    }
    if (this._doneJobs) {
      this._doneJobs.delete(job.id);
    }
    if (this._pendingJobs) {
      this._pendingJobs.delete(job.id);
    }
  }

  _flushCount() {
    this.doneCount = this._doneJobs?.size ?? 0;
    this.errorCount = this._errorJobs?.size ?? 0;
    this.pendingCount = this._pendingJobs?.size ?? 0;
  }

  createSequence<T>(context?: Vue) {
    return this.printer.createSequence<T>(context);
  }
}
