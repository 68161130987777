
import { Component, Prop, Vue, Watch, FindType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";

@Component
export default class NumPadButton extends Vue {
  numPad = false;

  code = "";
  sessions: TableSession[] = null;

  async confirm() {
    if (!this.code) return;
    const code = this.code.padStart(3, "0");
    const sessions = [
      this.$tableManager.sessions.filter(s => s.sessionNameNumber === code),
      this.$tableManager.takeAways.filter(s => s.sessionNameNumber === code),
      this.$tableManager.dineInNoTables.filter(s => s.sessionNameNumber === code),
    ].flat();
    if (sessions.length === 1) {
      this.$emit("openOrder", sessions[0]);
      this.numPad = false;
    } else {
      this.sessions = sessions;
      this.code = "";
    }
  }

  @Watch('numPad')
  onNumPad() {
    this.code = '';
    this.sessions = null;
  }
}
