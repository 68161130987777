import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import uuid from "uuid/v4";
import _ from "lodash";
import { FindType } from "@feathers-client";
import { MapFields, MapSubView, shapes, getSplitName } from "./utils";
import { getOptions } from "@feathers-client/util";
import { TableSession } from "./session";

export interface TableSessionRef {
  session: TableSession;
  table: TableItem;
  capacity?: number;
  split: number;
  _x?: number;
  _y?: number;
  index?: number;
}

@Component
export class TableItem extends mixins(
  MapFields("tableViewItems", ["x", "y", "w", "h", "shape", "capacity", "name"] as const, ["view"], {
    x: 0,
    y: 0,
    w: 100,
    h: 100,
    shape: "rect",
    name: "",
    capacity: 4,
  }),
) {
  selected = false;
  dragTo = false;
  get view() {
    return this.$parent as TableView;
  }

  init(item: Partial<FindType<"tableViewItems">> = {}) {
    this.item = item;
    if (!item._id) {
      // init table name
      const tablePrefix = this.view.tablePrefix || "";
      let maxId = 0;
      for (let table of this.$tableManager.viewItems) {
        if (table.name?.startsWith?.(tablePrefix)) {
          const suffix = table.name.substring(tablePrefix.length);
          const suffixId = +suffix;
          if (!isNaN(suffixId) && suffixId > maxId) maxId = suffixId;
        }
      }
      item.name = tablePrefix + (maxId + 1);
    }
    return this;
  }

  get tableStatus() {
    const statusOrder = ["empty", "ongoing", "ordered", "productDone", "toPay", "done"];
    let status = "empty";
    const sessions = this.sessions;
    const lowestStatusObject = sessions.reduce((minObject, currentObject) => {
      const minIndex = statusOrder.indexOf(minObject.session.billStatus);
      const currentIndex = statusOrder.indexOf(currentObject.session.billStatus);

      return currentIndex < minIndex ? currentObject : minObject;
    }, sessions[0]);
    status = lowestStatusObject?.session?.billStatus ?? status;
    return status;
  }

  get tableColor() {
    switch (this.tableStatus) {
      case "ongoing":
        return "#DF6D6D";
      case "productDone":
        return "#7369DE";
      case "ordered":
        return "#2D9CDB";
      case "done":
        return "#27AE60";
      case "toPay":
        return "#27AE60";
      default:
        return "#828282";
    }
  }

  get shapeOpts() {
    return shapes[this.shape] || shapes["rect"];
  }

  get nextSessionName() {
    return this.name + getSplitName(this.nextSessionSplit);
  }

  get nextSessionSplit() {
    let nextSplit = 0;
    if (this.sessions.length) {
      nextSplit = _.maxBy(this.sessions, it => it.split).split + 1;
    }

    return nextSplit;
  }

  select() {
    if (this.selected) return false;
    if (this.$tableManager.takeAwaySession) {
      this.$tableManager.takeAwaySession.selected = false;
      this.$tableManager.takeAwaySession = null;
    }
    if (!this.view.multiSelect && this.view.selectedItems.length) {
      this.view.clearSelect();
    }
    this.selected = true;
    this.view.selectedItems.push(this);
    this.view.lastSelected = this;
    return true;
  }

  deselect() {
    if (!this.selected) return false;
    this.selected = false;
    const idx = this.view.selectedItems.indexOf(this);
    if (idx == 0) {
      this.view.selectedItems = [];
    }
    idx !== -1 && this.view.selectedItems.splice(idx, 1);
    this.collapse();
    this.view.lastSelected = this;
    return true;
  }

  expanded = false;

  expand() {
    if (this.expanded) return;
    this.view.clearExpand();
    this.expanded = true;
    this.view.expandedItems.push(this);
    this.select();
  }

  collapse() {
    if (!this.expanded) return;
    this.expanded = false;
    const idx = this.view.expandedItems.indexOf(this);
    idx !== -1 && this.view.expandedItems.splice(idx, 1);
  }

  get hw() {
    return this.w / 2;
  }
  get hh() {
    return this.h / 2;
  }
  get top() {
    return this.y - this.hh;
  }
  get bottom() {
    return this.y + this.hh;
  }
  get left() {
    return this.x - this.hw;
  }
  get right() {
    return this.x + this.hw;
  }

  get remainCapacity() {
    return Math.max(0, this.capacity - this.usedCapacity);
  }

  get usedCapacity() {
    return _.sumBy(this.sessions, s => s.capacity);
  }

  sessions: TableSessionRef[] = [];

  async deleteTable() {
    // TODO: remove session ref
    await this.remove();
  }

  getSplitName(split: number) {
    return this.name + getSplitName(split);
  }
}

@Component
export class TableView extends mixins(
  MapFields(
    "tableViews",
    [
      "name",
      "orderPrefix",
      "tablePrefix",
      "sessionTimeLimit",
      "placeOrderTimeLimit",
      "sessionCheckoutAutoClearTime",
      "sessionAutoCancelTime",
      "order",
      "testing",
    ] as const,
    [],
    {
      name: "New Floor",
      orderPrefix: "",
      tablePrefix: "T",
      sessionTimeLimit: null,
      placeOrderTimeLimit: null,
      sessionCheckoutAutoClearTime: null,
      sessionAutoCancelTime: null,
      order: 0,
      testing: false,
    },
  ),
  MapSubView(() => TableItem, "viewItem"),
  MapSubView(() => TableSession, "session"),
) {
  init(item: Partial<FindType<"tableViews">>) {
    this.item = item;
    if (item._id) {
      const info = this.$shop.localOptions.tableView[`${this.item._id}`];
      if (info) {
        this.manualPosition = true;
        this.offsetX = info.offsetX;
        this.offsetY = info.offsetY;
        this.zoom = info.zoom;
      }
    }
    return this;
  }

  addingItem: TableItem = null;
  lastSelected: TableItem = null;
  editing = false;
  selectedItems: TableItem[] = [];
  expandedItems: TableItem[] = [];
  multiSelect = false;
  selectedSession: TableSession = null;

  @Watch("selectedItems.length")
  onLengthChanged(v) {
    if (!v) {
      this.$emit("resetSelect");
      this.multiSelect = false;
      if (this.selectedSession) {
        this.selectedSession.selected = false;
      }
    }
  }

  get selectedName() {
    return this.selectedItems.map(it => it.nextSessionName).join(",");
  }

  get selectedCapacity() {
    return _.sumBy(this.selectedItems, it => it.remainCapacity) || 0;
  }

  get sortedViewItems() {
    return this.viewItems.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      }),
    );
  }

  beginEdit() {
    this.editing = true;
    this.clearSelect();
  }

  async endEdit() {
    this.editing = false;
    for (let view of this.viewItems) {
      await view.save();
    }
    await this.save();
    this.clearSelect();
  }

  clearSelect() {
    for (let view of this.viewItems) {
      view.deselect();
    }
  }

  clearSessionSelect() {
    if (this.selectedSession) {
      this.selectedSession.selected = false;
    }
  }

  clearExpand() {
    for (let view of this.expandedItems) {
      view.collapse();
    }
  }

  snapTo(x: number, y: number) {
    return {
      x: Math.round(x / 25) * 25,
      y: Math.round(y / 25) * 25,
    };
  }

  beginAddItem(shape: string) {
    this.addingItem = new TableItem(getOptions(this)).init({
      shape,
    });
    this.addingItem.select();
  }

  commitAdd() {
    if (this.addingItem) {
      const entity = this.addEntityViewItem(this.addingItem);
      this.addingItem = null;
    }
  }

  commitRemove() {
    if (this.addingItem) {
      const entity = this.removeEntityViewItem(this.addingItem);
      this.addingItem = null;
    }
  }

  enableMultiSelect() {
    this.multiSelect = true;
  }

  disableMultiSelect() {
    this.multiSelect = false;
    for (let item of this.selectedItems.slice(0, -1)) {
      item.deselect();
    }
  }

  async deleteFloor() {
    // TODO: remove orders / tables
    await this.clearAllCurrentOrder();
    await this.removeAllTables();
    await this.remove();
  }

  offsetX = 0;
  offsetY = 0;
  zoom = 0;
  manualPosition = false;

  togglePanZoom() {
    this.manualPosition = !this.manualPosition;
  }

  fit(width: number, height: number, left = 0, top = 0) {
    if (this.manualPosition) return;
    const padding = 40;
    const minX = (_.minBy(this.viewItems, v => v.left)?.left ?? -100) - padding;
    const maxX = (_.maxBy(this.viewItems, v => v.right)?.right ?? 100) + padding;

    const minY = (_.minBy(this.viewItems, v => v.top)?.top ?? -100) - padding;
    const maxY = (_.maxBy(this.viewItems, v => v.bottom)?.bottom ?? 100) + padding;

    const rangeX = maxX - minX;
    const rangeY = maxY - minY;

    const centerX = minX + rangeX / 2;
    const centerY = minY + rangeY / 2;

    const scale = Math.min(1, Math.min(width / rangeX, height / rangeY));
    const zoom = Math.log2(scale) * 4;

    this.offsetX = left + Math.floor(width / 2 - centerX * scale);
    this.offsetY = top + Math.floor(height / 2 - centerY * scale);
    this.zoom = zoom;
  }

  savePosition() {
    if (!this.item._id) return;
    this.$shop.localOptions.tableView[`${this.item._id}`] = this.manualPosition
      ? {
          offsetX: this.offsetX,
          offsetY: this.offsetY,
          zoom: this.zoom,
        }
      : null;
  }

  async removeAllTables() {
    for (let session of this.viewItems.slice()) {
      try {
        await session.deleteTable();
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async clearAllCurrentOrder() {
    for (let session of this.sessions.slice()) {
      try {
        if (session.status === "done") {
          await session.atomic({
            endTime: new Date(),
          });
        } else {
          await session.cancelPending();
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
}

export interface SessionConainterLayout {
  multiple: boolean;
  item?: TableSessionRef;
  items?: TableSessionRef[];
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface ReservationPlateLayout {
  item: TableItem;
  x: number;
  y: number;
  w: number;
  h: number;
}
