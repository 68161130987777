
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { ProductType } from "~/plugins/shop";
import { CartItem } from "@common/table/cart";
import { getProductStockLevel, StockLevel } from "~/common/table/util";
import { PropSync } from "vue-property-decorator";

@Component({})
export default class MobileCartItem extends Vue {
  @Ref("router")
  router: NestedRouter;

  @PropSync("session")
  sessionSync: TableSession;

  @PropSync("editingCart")
  editingCartSync: CartItem;

  @PropSync("lastCart")
  lastCartSync: CartItem;

  editingPrice = false;

  tempKitchenOptions: { _id: string; name: LangArrType }[] = [];

  get currentCart() {
    return this.sessionSync?.cart ?? [];
  }

  currentTempPriceVal: number = null;
  get currentTempPrice() {
    return this.currentTempPriceVal === null ? this.editingCartSync?.price : this.currentTempPriceVal;
  }

  set currentTempPrice(v) {
    this.currentTempPriceVal = v;
  }

  get hasNextOptions() {
    if (!this.editingCartSync) return null;
    const list = this.currentCart;
    let cartIndex = list.indexOf(this.editingCartSync);
    if (cartIndex === -1) return null;

    for (let i = cartIndex + 1; i < list.length; i++) {
      if (list[i].pendingOptions) return list[i];
    }
    return null;
  }

  removeItem() {
    if (this.editingCartSync) {
      const cart = this.editingCartSync;
      cart.remove();
      this.editingCartSync = null;
      if (this.lastCartSync == cart) {
        this.lastCartSync = this.currentCart.filter(it => !it.fromProduct && !it.fromCoupon).slice(-1)[0] || null;
      }
    }
    this.$emit("goBack");
  }

  clearCart() {
    if (this.sessionSync) {
      this.sessionSync.clearCart();
      this.lastCartSync = null;
    }
  }

  async navigateNext() {
    if (this.currentTempPriceVal !== null) {
      if (!this.$shop.hasPermission([`orderManage/tableSessionEditProductPrice`])) {
        const staff = await this.$shop.checkPermission(["orderManage/tableSessionEditProductPrice"]);
        if (staff === false) return;
        this.editingCartSync.editPriceStaff = staff;
      } else {
        this.editingCartSync.editPriceStaff = this.$shop.staff;
      }
      this.editingCartSync.manualPrice = this.currentTempPriceVal;
    }

    if (this.editingPrice) {
      this.editingPrice = false;
      return;
    }

    if (this.hasNextOptions) {
      this.editingCartSync = this.hasNextOptions;
    } else {
      this.$emit("navigate");
    }
  }
}
