
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class AlertBadge extends Vue {
    @Prop({ type: Number, default: 18 })
    width: number;
    @Prop({ type: Number, default: 18 })
    height: number;
}
