import { BLEDevice, BluetoothConf } from "../devices/bluetooth";


export class BluetoothScanner extends BLEDevice {

    service : BluetoothRemoteGATTService;
    ch : BluetoothRemoteGATTCharacteristic;

    constructor(conf: BluetoothConf) {
      super(conf);
      this.onData = this.onData.bind(this);
    }

    onDisconnectedCore() {
        super.onDisconnectedCore();
        if(this.ch) {
            try {
                this.ch.removeEventListener('characteristicvaluechanged', this.onData);
                this.ch.stopNotifications();
            } catch(e) {
                console.warn(e);
            }
        }
        this.server = this.ch = null;
    }

    async tryConnectCore() {
        await super.tryConnectCore();

        this.service = await Promise.race([
            this.server.getPrimaryService(
                this.conf.service || "0000fff0-0000-1000-8000-00805f9b34fb"
            ),
            new Promise<BluetoothRemoteGATTService>((resolve, reject) => setTimeout(() => reject(new Error("GATT: getPrimaryService timeout")), 5000))
        ])
        await new Promise(resolve => setTimeout(resolve, 100))
        this.ch = await Promise.race([
            this.service.getCharacteristic(
                this.conf.char || "0000fff1-0000-1000-8000-00805f9b34fb"
            ),
            new Promise<BluetoothRemoteGATTCharacteristic>((resolve, reject) => setTimeout(() => reject(new Error("GATT: getCharacteristic timeout")), 5000))
        ])
        console.log(this.ch);
        if(this.ch) {
            this.ch.removeEventListener('characteristicvaluechanged', this.onData);
            this.ch.addEventListener('characteristicvaluechanged', this.onData);
            await this.ch.startNotifications();
        }
    }

    onData() {
        if(this.ch) {
            this.emit('data', Buffer.from(this.ch.value.buffer.slice(this.ch.value.byteOffset, this.ch.value.byteLength + this.ch.value.byteOffset)));
        }
    }
}
