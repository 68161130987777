
  
  import _ from "lodash"
  import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
  import { TableSession } from "~/plugins/table/session";
  import { FindPopRawType, FindType } from '@feathers-client'
  
  @Component
  export default class OrderSystemDiscount extends Vue {
      @Prop()
      session : TableSession;
  
      tipsStr = '';
      customTips = false;
  
      get tips() { return this.session.tips || 0 }
      set tips(v) {
          v = !isNaN(+v) ? +v : 0;
          this.session.tips = v;
          this.session.delaySave({
              tips: v,
              ...this.session.cachedPriceDetails,
          });
          this.customTips = false;
          this.session.syncOrder();
      }
  }
  
  