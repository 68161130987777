
import { Component, Vue, FindType, Prop, PropSync, checkID } from "@feathers-client";
import { fromDb, INGREDIENT_MULTIPLIER_PRICE, toDb, totalPrice, priceFromDb } from "@common/table/ingredients";
import _ from "lodash";

interface SummaryRow {
  _id: string;
  product: string;
  parentProduct: string;
  time: string;
  quantity: number;
  sales: number;

  ingredients: {
    ingredient: string;
    sumCost: number;
    usage: number;
  }[];
}

@Component({})
export default class SemiProduct extends Vue {
  @Prop()
  item: SummaryRow;

  @Prop()
  showTime: boolean;

  fromDb = fromDb;
  totalPrice = totalPrice;
  priceFromDb = priceFromDb;

  showDetails = false;

  get cachedProduct() {
    return this.$shop.productDict[this.item.product];
  }

  get cachedParent() {
    return this.$shop.productDict[this.item.parentProduct];
  }

  get name() {
    return this.cachedProduct?.name;
  }

  get parentName() {
    return this.cachedParent?.name;
  }

  get image() {
    return this.$thumb(this.cachedProduct?.image) || require("~/assets/images/icons/placeholder.svg");
  }

  get totalCost() {
    return _.sumBy(this.item.ingredients, it => it.sumCost);
  }

  get ratio() {
    return ((totalPrice(-this.totalCost) / this.item.sales) * 100) | 0;
  }
}
