
import { Vue, Component, Prop, Model, PropSync } from "@feathers-client"
import moment from "moment";
import _ from "lodash";
import { Watch } from "vue-property-decorator";

@Component
export default class TakeawayCalendarFilter extends Vue {

  @Model()
  datetimeCondition: any;

  pickedDate = null;

  selectedDate = 0;

  // tempSession =[];
  salesSession = [
    { "startTime": "00:00", "endTime": "23:59", "name": this.getLang('basic.all') },
    { "startTime": "09:00", "endTime": "11:00", "name": this.getLang('takeaway.breakfast') },
    { "startTime": "11:00", "endTime": "14:00", "name": this.getLang('takeaway.lunch') },
    { "startTime": "14:00", "endTime": "18:00", "name": this.getLang('takeaway.afternoonTea') },
    { "startTime": "18:00", "endTime": "23:59", "name": this.getLang('takeaway.dinner') },
    { "startTime": null, "endTime": null, "name": this.getLang('time.custom'), "custom": true }
  ];

  selectedSession = 0;

  @PropSync("confirmedDate", { type: Number })
  mconfirmedDate: number;

  @PropSync("confirmedSession", { type: Number })
  mconfirmedSession: number;

  @Prop()
  calendarDialog: boolean;


  get date() {
    return [
      { name: this.$t("basic.all") },
      { name: this.$t("time.today") },
      { name: this.$t("time.tomorrow") },
      { name: this.$t("time.custom") }]
  }

  get startTime() {
    return this.salesSession[this.selectedSession]?.startTime;
  }

  get endTime() {
    return this.salesSession[this.selectedSession]?.endTime;
  }

  get editable() {
    return !((this.salesSession[this.selectedSession] as any).custom && (this.salesSession[this.selectedSession] as any).custom == true)
  }

  get allSelected() {
    return this.startTime&&this.endTime;
  }

  get startLessThanEnd() {
    return this.startTime < this.endTime;
  }

  async beforeMount() {
    // let tempSession = await this.$feathers.service("shopgrouptimesession").find({
    //   paginate: false,
    //   query: {
    //     $paginate: false,
    //   },
    // })
    // this.salesSession.push(...tempSession, { "startTime": null, "endTime": null, "name": this.getLang('time.custom'), "custom": true } as any);

  }

  getLang(key) {
    return [
      {
        "lang": "en",
        "value": this.$t(key, "en")
      },
      {
        "lang": "zh-cn",
        "value": this.$t(key, "zh-cn")
      },
      {
        "lang": "zh-hk",
        "value": this.$t(key, "zh-hk")
      }
    ]
  }

  onFilterClick() {
    if (this.startTime === null || this.endTime === null) {
      this.$store.commit("SET_ERROR", this.$t("time.pleaseSelectTime"));
      return 0;
    }
    if (this.startTime > this.endTime) {
      this.$store.commit("SET_ERROR", this.$t("time.endTimeMustLaterThanStartTime"));
      return 0;
    }
    this.mconfirmedDate = this.selectedDate;
    this.mconfirmedSession = this.selectedSession;
    this.$emit('onFilterClick', [this.pickedDate, this.startTime, this.endTime])
  }

  pickDate(index) {
    this.selectedDate = index;
    switch (this.selectedDate) {
      case 0:
        this.pickedDate = null;
        break;
      case 1:
        this.pickedDate = moment().toISOString().substr(0, 10);
        break;
      case 2:
        this.pickedDate = moment().add(1, 'days').toISOString().substr(0, 10);
        break;
      case 3:
        this.pickedDate = moment().toISOString().substr(0, 10);
        break;
    }
  }

  allowedStartDate(val) {
    let cur = moment(val).toISOString().substr(0, 10);
    let start = moment().add(-1, 'days').toISOString().substr(0, 10);
    return cur >= start;
  }

  @Watch("calendarDialog")
  @Watch("mconfirmedDate")
  onSelectedDate() {
    this.selectedDate = this.mconfirmedDate;
  }

  @Watch("calendarDialog")
  @Watch("mconfirmedSession")
  onSelectedSession() {
    this.selectedSession = this.mconfirmedSession;
  }
};
