
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

@Component
export default class Reservation extends Vue {
  currentTab = "summary";
  tabs = [
    {
      _id: "summary",
      name: { $t: "tableView.operation.summary" },
    },
    {
      _id: "cashin",
      name: { $t: "tableView.operation.cashin" },
    },
    {
      _id: "cashout",
      name: { $t: "tableView.operation.cashout" },
    },
  ];

  openingAmount = null;
  openingAmountStr = "";
  selectedDateStr = "";

  // Date picker utils
  @Ref()
  input: any;

  showPicker() {
    if ("showPicker" in HTMLInputElement.prototype) {
      this.input.$el.showPicker();
    }
  }

  adjAmount = null;
  adjAmountStr = "";
}
