import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import { getProductTree } from "@common/table/util";
import { StockLevel, getOptionStockLevel } from "~/common/table/util";
import { ProductOptionType } from "~/plugins/shop";
import type { CartItem, ProductOptionSelectWithPrice, ProductOptionSelectWithPriceItem } from "@common/table/cart";

@Component
export default class ProductOptionBase extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  cart: CartItem;

  @Prop()
  opt: ProductOptionSelectWithPrice;

  @Prop(Boolean)
  dark: boolean;

  @PropSync("item")
  itemSync: CartItem | ProductLine;

  selectIndex: number = 0;

  idx: number = 0;
  jdx: number = -1; // set -1 to un-show the sub-item options on right column

  @Ref("rightCol")
  rightCol: HTMLElement;

  toTop() {
    this.rightCol?.scrollIntoView?.({ behavior: "smooth", block: "start" });
  }
  get nonSetOptions() {
    return this.cart.productOptionsWithPrice.filter(it => !it.options?.options.find(jt => jt.type === "product"));
  }

  get setOptions() {
    return this.cart.productOptionsWithPrice.filter(it => it.options?.options.find(jt => jt.type === "product"));
  }

  get currentOptions() {
    if (this.idx === -1) {
      return this.nonSetOptions;
    } else {
      return [
        this.setOptions.find(it => it.idx === this.idx),
        ...(this.setOptions.find(it => it.idx === this.idx)?.selectionItems?.[this.jdx]?.targetProduct
          ?.productOptionsWithPrice ||
          [] ||
          []),
      ];
    }
  }

  get onePageOrdering() {
    return this.cart.productOptionsWithPrice;
  }

  async toggleItem(opt: ProductOptionType, item: ProductOptionSelectWithPriceItem) {
    if (!!(await this.confirmUseNoStockOption(opt, item))) {
      item.toggleSelection();
    }
  }

  async confirmUseNoStockOption(opt: ProductOptionType, item: ProductOptionSelectWithPriceItem): Promise<boolean> {
    if (item.active) return true;
    // const option = opt.options;
    const level = getOptionStockLevel(opt, item.item, {
      session: this.session,
      optionsDict: this.$shop.productOptionDict,
      productsDict: this.$shop.productDict,
    });
    switch (level) {
      case StockLevel.Conflicted:
      case StockLevel.OutOfStock:
      case StockLevel.NotSelling:
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: `${this.$t("basic.productNotSelling")}, ${this.$t("basic.doYouWantToContinue")}`,
          },
          {
            maxWidth: "400px",
          },
        );
        return !!c;
    }
    return true;
  }

  stockStatus(option: ProductOptionSelectWithPrice, value: ProductOptionSelectWithPriceItem) {
    const level =
      option.available && value.available
        ? getOptionStockLevel(option.options, value.item, {
            session: this.session,
            optionsDict: this.$shop.productOptionDict,
            productsDict: this.$shop.productDict,
          })
        : StockLevel.NotSelling;
    switch (level) {
      case StockLevel.Conflicted:
      case StockLevel.OutOfStock:
      case StockLevel.NotSelling:
        return "!brightness-50 !opacity-30";

      case StockLevel.Low:
        return "bg-red300";
      case StockLevel.Medium:
        return "bg-yellow000";
      case StockLevel.High:
        return "bg-green100";
    }
  }
}
