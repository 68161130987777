var CanvasKitInit = (() => {
  var _scriptDir = typeof document !== "undefined" && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== "undefined") _scriptDir = _scriptDir || __filename;
  return function (moduleArg = {}) {
    var w = moduleArg,
      aa,
      ba;
    w.ready = new Promise((a, b) => {
      aa = a;
      ba = b;
    });
    (function (a) {
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        a.MakeSWCanvasSurface = function (b) {
          var d = b,
            f = "undefined" !== typeof OffscreenCanvas && d instanceof OffscreenCanvas;
          if (
            !(
              ("undefined" !== typeof HTMLCanvasElement && d instanceof HTMLCanvasElement) ||
              f ||
              ((d = document.getElementById(b)), d)
            )
          )
            throw "Canvas with id " + b + " was not found";
          if ((b = a.MakeSurface(d.width, d.height))) b.Ld = d;
          return b;
        };
        a.MakeCanvasSurface || (a.MakeCanvasSurface = a.MakeSWCanvasSurface);
        a.MakeSurface = function (b, d) {
          var f = {
              width: b,
              height: d,
              colorType: a.ColorType.RGBA_8888,
              alphaType: a.AlphaType.Unpremul,
              colorSpace: a.ColorSpace.SRGB,
            },
            h = b * d * 4,
            m = a._malloc(h);
          if ((f = a.Surface._makeRasterDirect(f, m, 4 * b)))
            (f.Ld = null), (f.Hf = b), (f.Df = d), (f.Ff = h), (f.bf = m), f.getCanvas().clear(a.TRANSPARENT);
          return f;
        };
        a.MakeRasterDirectSurface = function (b, d, f) {
          return a.Surface._makeRasterDirect(b, d.byteOffset, f);
        };
        a.Surface.prototype.flush = function (b) {
          a.Md(this.Kd);
          this._flush();
          if (this.Ld) {
            var d = new Uint8ClampedArray(a.HEAPU8.buffer, this.bf, this.Ff);
            d = new ImageData(d, this.Hf, this.Df);
            b
              ? this.Ld.getContext("2d").putImageData(d, 0, 0, b[0], b[1], b[2] - b[0], b[3] - b[1])
              : this.Ld.getContext("2d").putImageData(d, 0, 0);
          }
        };
        a.Surface.prototype.dispose = function () {
          this.bf && a._free(this.bf);
          this.delete();
        };
        a.Md = a.Md || function () {};
        a.Te =
          a.Te ||
          function () {
            return null;
          };
      });
    })(w);
    (function (a) {
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        function b(n, p, v) {
          return n && n.hasOwnProperty(p) ? n[p] : v;
        }
        function d(n) {
          var p = ca(ha);
          ha[p] = n;
          return p;
        }
        function f(n) {
          return n.naturalHeight || n.videoHeight || n.displayHeight || n.height;
        }
        function h(n) {
          return n.naturalWidth || n.videoWidth || n.displayWidth || n.width;
        }
        function m(n, p, v, E) {
          n.bindTexture(n.TEXTURE_2D, p);
          E || v.alphaType !== a.AlphaType.Premul || n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !0);
          return p;
        }
        function u(n, p, v) {
          v || p.alphaType !== a.AlphaType.Premul || n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !1);
          n.bindTexture(n.TEXTURE_2D, null);
        }
        a.GetWebGLContext = function (n, p) {
          if (!n) throw "null canvas passed into makeWebGLContext";
          var v = {
            alpha: b(p, "alpha", 1),
            depth: b(p, "depth", 1),
            stencil: b(p, "stencil", 8),
            antialias: b(p, "antialias", 0),
            premultipliedAlpha: b(p, "premultipliedAlpha", 1),
            preserveDrawingBuffer: b(p, "preserveDrawingBuffer", 0),
            preferLowPowerToHighPerformance: b(p, "preferLowPowerToHighPerformance", 0),
            failIfMajorPerformanceCaveat: b(p, "failIfMajorPerformanceCaveat", 0),
            enableExtensionsByDefault: b(p, "enableExtensionsByDefault", 1),
            explicitSwapControl: b(p, "explicitSwapControl", 0),
            renderViaOffscreenBackBuffer: b(p, "renderViaOffscreenBackBuffer", 0),
          };
          v.majorVersion = p && p.majorVersion ? p.majorVersion : "undefined" !== typeof WebGL2RenderingContext ? 2 : 1;
          if (v.explicitSwapControl) throw "explicitSwapControl is not supported";
          n = ia(n, v);
          if (!n) return 0;
          ja(n);
          A.fe.getExtension("WEBGL_debug_renderer_info");
          return n;
        };
        a.deleteContext = function (n) {
          A === la[n] && (A = null);
          "object" == typeof JSEvents && JSEvents.xg(la[n].fe.canvas);
          la[n] && la[n].fe.canvas && (la[n].fe.canvas.zf = void 0);
          la[n] = null;
        };
        a._setTextureCleanup({
          deleteTexture: function (n, p) {
            var v = ha[p];
            v && la[n].fe.deleteTexture(v);
            ha[p] = null;
          },
        });
        a.MakeWebGLContext = function (n) {
          if (!this.Md(n)) return null;
          var p = this._MakeGrContext();
          if (!p) return null;
          p.Kd = n;
          var v = p.delete.bind(p);
          p["delete"] = function () {
            a.Md(this.Kd);
            v();
          }.bind(p);
          return (A.ff = p);
        };
        a.MakeGrContext = a.MakeWebGLContext;
        a.GrDirectContext.prototype.getResourceCacheLimitBytes = function () {
          a.Md(this.Kd);
          this._getResourceCacheLimitBytes();
        };
        a.GrDirectContext.prototype.getResourceCacheUsageBytes = function () {
          a.Md(this.Kd);
          this._getResourceCacheUsageBytes();
        };
        a.GrDirectContext.prototype.releaseResourcesAndAbandonContext = function () {
          a.Md(this.Kd);
          this._releaseResourcesAndAbandonContext();
        };
        a.GrDirectContext.prototype.setResourceCacheLimitBytes = function (n) {
          a.Md(this.Kd);
          this._setResourceCacheLimitBytes(n);
        };
        a.MakeOnScreenGLSurface = function (n, p, v, E, G, L) {
          if (!this.Md(n.Kd)) return null;
          p =
            void 0 === G || void 0 === L
              ? this._MakeOnScreenGLSurface(n, p, v, E)
              : this._MakeOnScreenGLSurface(n, p, v, E, G, L);
          if (!p) return null;
          p.Kd = n.Kd;
          return p;
        };
        a.MakeRenderTarget = function () {
          var n = arguments[0];
          if (!this.Md(n.Kd)) return null;
          if (3 === arguments.length) {
            var p = this._MakeRenderTargetWH(n, arguments[1], arguments[2]);
            if (!p) return null;
          } else if (2 === arguments.length) {
            if (((p = this._MakeRenderTargetII(n, arguments[1])), !p)) return null;
          } else return null;
          p.Kd = n.Kd;
          return p;
        };
        a.MakeWebGLCanvasSurface = function (n, p, v) {
          p = p || null;
          var E = n,
            G = "undefined" !== typeof OffscreenCanvas && E instanceof OffscreenCanvas;
          if (
            !(
              ("undefined" !== typeof HTMLCanvasElement && E instanceof HTMLCanvasElement) ||
              G ||
              ((E = document.getElementById(n)), E)
            )
          )
            throw "Canvas with id " + n + " was not found";
          n = this.GetWebGLContext(E, v);
          if (!n || 0 > n) throw "failed to create webgl context: err " + n;
          n = this.MakeWebGLContext(n);
          p = this.MakeOnScreenGLSurface(n, E.width, E.height, p);
          return p
            ? p
            : ((p = E.cloneNode(!0)),
              E.parentNode.replaceChild(p, E),
              p.classList.add("ck-replaced"),
              a.MakeSWCanvasSurface(p));
        };
        a.MakeCanvasSurface = a.MakeWebGLCanvasSurface;
        a.Surface.prototype.makeImageFromTexture = function (n, p) {
          a.Md(this.Kd);
          n = d(n);
          if ((p = this._makeImageFromTexture(this.Kd, n, p))) p.Le = n;
          return p;
        };
        a.Surface.prototype.makeImageFromTextureSource = function (n, p, v) {
          p ||
            (p = {
              height: f(n),
              width: h(n),
              colorType: a.ColorType.RGBA_8888,
              alphaType: v ? a.AlphaType.Premul : a.AlphaType.Unpremul,
            });
          p.colorSpace || (p.colorSpace = a.ColorSpace.SRGB);
          a.Md(this.Kd);
          var E = A.fe;
          v = m(E, E.createTexture(), p, v);
          2 === A.version
            ? E.texImage2D(E.TEXTURE_2D, 0, E.RGBA, p.width, p.height, 0, E.RGBA, E.UNSIGNED_BYTE, n)
            : E.texImage2D(E.TEXTURE_2D, 0, E.RGBA, E.RGBA, E.UNSIGNED_BYTE, n);
          u(E, p);
          this._resetContext();
          return this.makeImageFromTexture(v, p);
        };
        a.Surface.prototype.updateTextureFromSource = function (n, p, v) {
          if (n.Le) {
            a.Md(this.Kd);
            var E = n.getImageInfo(),
              G = A.fe,
              L = m(G, ha[n.Le], E, v);
            2 === A.version
              ? G.texImage2D(G.TEXTURE_2D, 0, G.RGBA, h(p), f(p), 0, G.RGBA, G.UNSIGNED_BYTE, p)
              : G.texImage2D(G.TEXTURE_2D, 0, G.RGBA, G.RGBA, G.UNSIGNED_BYTE, p);
            u(G, E, v);
            this._resetContext();
            ha[n.Le] = null;
            n.Le = d(L);
            E.colorSpace = n.getColorSpace();
            p = this._makeImageFromTexture(this.Kd, n.Le, E);
            v = n.Jd.Rd;
            G = n.Jd.Yd;
            n.Jd.Rd = p.Jd.Rd;
            n.Jd.Yd = p.Jd.Yd;
            p.Jd.Rd = v;
            p.Jd.Yd = G;
            p.delete();
            E.colorSpace.delete();
          }
        };
        a.MakeLazyImageFromTextureSource = function (n, p, v) {
          p ||
            (p = {
              height: f(n),
              width: h(n),
              colorType: a.ColorType.RGBA_8888,
              alphaType: v ? a.AlphaType.Premul : a.AlphaType.Unpremul,
            });
          p.colorSpace || (p.colorSpace = a.ColorSpace.SRGB);
          var E = {
            makeTexture: function () {
              var G = A,
                L = G.fe,
                y = m(L, L.createTexture(), p, v);
              2 === G.version
                ? L.texImage2D(L.TEXTURE_2D, 0, L.RGBA, p.width, p.height, 0, L.RGBA, L.UNSIGNED_BYTE, n)
                : L.texImage2D(L.TEXTURE_2D, 0, L.RGBA, L.RGBA, L.UNSIGNED_BYTE, n);
              u(L, p, v);
              return d(y);
            },
            freeSrc: function () {},
          };
          "VideoFrame" === n.constructor.name &&
            (E.freeSrc = function () {
              n.close();
            });
          return a.Image._makeFromGenerator(p, E);
        };
        a.Md = function (n) {
          return n ? ja(n) : !1;
        };
        a.Te = function () {
          return A && A.ff && !A.ff.isDeleted() ? A.ff : null;
        };
      });
    })(w);
    (function (a) {
      function b(e, c, g, l, r) {
        for (var x = 0; x < e.length; x++) c[x * g + ((x * r + l + g) % g)] = e[x];
        return c;
      }
      function d(e) {
        for (var c = e * e, g = Array(c); c--; ) g[c] = 0 === c % (e + 1) ? 1 : 0;
        return g;
      }
      function f(e) {
        return e ? e.constructor === Float32Array && 4 === e.length : !1;
      }
      function h(e) {
        return ((n(255 * e[3]) << 24) | (n(255 * e[0]) << 16) | (n(255 * e[1]) << 8) | (n(255 * e[2]) << 0)) >>> 0;
      }
      function m(e) {
        if (e && e._ck) return e;
        if (e instanceof Float32Array) {
          for (var c = Math.floor(e.length / 4), g = new Uint32Array(c), l = 0; l < c; l++)
            g[l] = h(e.slice(4 * l, 4 * (l + 1)));
          return g;
        }
        if (e instanceof Uint32Array) return e;
        if (e instanceof Array && e[0] instanceof Float32Array) return e.map(h);
      }
      function u(e) {
        if (void 0 === e) return 1;
        var c = parseFloat(e);
        return e && -1 !== e.indexOf("%") ? c / 100 : c;
      }
      function n(e) {
        return Math.round(Math.max(0, Math.min(e || 0, 255)));
      }
      function p(e, c) {
        (c && c._ck) || a._free(e);
      }
      function v(e, c, g) {
        if (!e || !e.length) return V;
        if (e && e._ck) return e.byteOffset;
        var l = a[c].BYTES_PER_ELEMENT;
        g || (g = a._malloc(e.length * l));
        a[c].set(e, g / l);
        return g;
      }
      function E(e) {
        var c = { be: V, count: e.length, colorType: a.ColorType.RGBA_F32 };
        if (e instanceof Float32Array) (c.be = v(e, "HEAPF32")), (c.count = e.length / 4);
        else if (e instanceof Uint32Array) (c.be = v(e, "HEAPU32")), (c.colorType = a.ColorType.RGBA_8888);
        else if (e instanceof Array) {
          if (e && e.length) {
            for (var g = a._malloc(16 * e.length), l = 0, r = g / 4, x = 0; x < e.length; x++)
              for (var C = 0; 4 > C; C++) (a.HEAPF32[r + l] = e[x][C]), l++;
            e = g;
          } else e = V;
          c.be = e;
        } else throw "Invalid argument to copyFlexibleColorArray, Not a color array " + typeof e;
        return c;
      }
      function G(e) {
        if (!e) return V;
        var c = Xb.toTypedArray();
        if (e.length) {
          if (6 === e.length || 9 === e.length)
            return v(e, "HEAPF32", Na), 6 === e.length && a.HEAPF32.set(xd, 6 + Na / 4), Na;
          if (16 === e.length)
            return (
              (c[0] = e[0]),
              (c[1] = e[1]),
              (c[2] = e[3]),
              (c[3] = e[4]),
              (c[4] = e[5]),
              (c[5] = e[7]),
              (c[6] = e[12]),
              (c[7] = e[13]),
              (c[8] = e[15]),
              Na
            );
          throw "invalid matrix size";
        }
        if (void 0 === e.m11) throw "invalid matrix argument";
        c[0] = e.m11;
        c[1] = e.m21;
        c[2] = e.m41;
        c[3] = e.m12;
        c[4] = e.m22;
        c[5] = e.m42;
        c[6] = e.m14;
        c[7] = e.m24;
        c[8] = e.m44;
        return Na;
      }
      function L(e) {
        if (!e) return V;
        var c = Yb.toTypedArray();
        if (e.length) {
          if (16 !== e.length && 6 !== e.length && 9 !== e.length) throw "invalid matrix size";
          if (16 === e.length) return v(e, "HEAPF32", $a);
          c.fill(0);
          c[0] = e[0];
          c[1] = e[1];
          c[3] = e[2];
          c[4] = e[3];
          c[5] = e[4];
          c[7] = e[5];
          c[10] = 1;
          c[12] = e[6];
          c[13] = e[7];
          c[15] = e[8];
          6 === e.length && ((c[12] = 0), (c[13] = 0), (c[15] = 1));
          return $a;
        }
        if (void 0 === e.m11) throw "invalid matrix argument";
        c[0] = e.m11;
        c[1] = e.m21;
        c[2] = e.m31;
        c[3] = e.m41;
        c[4] = e.m12;
        c[5] = e.m22;
        c[6] = e.m32;
        c[7] = e.m42;
        c[8] = e.m13;
        c[9] = e.m23;
        c[10] = e.m33;
        c[11] = e.m43;
        c[12] = e.m14;
        c[13] = e.m24;
        c[14] = e.m34;
        c[15] = e.m44;
        return $a;
      }
      function y(e, c) {
        return v(e, "HEAPF32", c || Ja);
      }
      function M(e, c, g, l) {
        var r = Zb.toTypedArray();
        r[0] = e;
        r[1] = c;
        r[2] = g;
        r[3] = l;
        return Ja;
      }
      function T(e) {
        for (var c = new Float32Array(4), g = 0; 4 > g; g++) c[g] = a.HEAPF32[e / 4 + g];
        return c;
      }
      function R(e, c) {
        return v(e, "HEAPF32", c || da);
      }
      function ta(e, c) {
        return v(e, "HEAPF32", c || $b);
      }
      function ma() {
        for (var e = 0, c = 0; c < arguments.length - 1; c += 2) e += arguments[c] * arguments[c + 1];
        return e;
      }
      function ib(e, c, g) {
        for (var l = Array(e.length), r = 0; r < g; r++)
          for (var x = 0; x < g; x++) {
            for (var C = 0, J = 0; J < g; J++) C += e[g * r + J] * c[g * J + x];
            l[r * g + x] = C;
          }
        return l;
      }
      function jb(e, c) {
        for (var g = ib(c[0], c[1], e), l = 2; l < c.length; ) (g = ib(g, c[l], e)), l++;
        return g;
      }
      a.Color = function (e, c, g, l) {
        void 0 === l && (l = 1);
        return a.Color4f(n(e) / 255, n(c) / 255, n(g) / 255, l);
      };
      a.ColorAsInt = function (e, c, g, l) {
        void 0 === l && (l = 255);
        return ((n(l) << 24) | (n(e) << 16) | (n(c) << 8) | ((n(g) << 0) & 268435455)) >>> 0;
      };
      a.Color4f = function (e, c, g, l) {
        void 0 === l && (l = 1);
        return Float32Array.of(e, c, g, l);
      };
      Object.defineProperty(a, "TRANSPARENT", {
        get: function () {
          return a.Color4f(0, 0, 0, 0);
        },
      });
      Object.defineProperty(a, "BLACK", {
        get: function () {
          return a.Color4f(0, 0, 0, 1);
        },
      });
      Object.defineProperty(a, "WHITE", {
        get: function () {
          return a.Color4f(1, 1, 1, 1);
        },
      });
      Object.defineProperty(a, "RED", {
        get: function () {
          return a.Color4f(1, 0, 0, 1);
        },
      });
      Object.defineProperty(a, "GREEN", {
        get: function () {
          return a.Color4f(0, 1, 0, 1);
        },
      });
      Object.defineProperty(a, "BLUE", {
        get: function () {
          return a.Color4f(0, 0, 1, 1);
        },
      });
      Object.defineProperty(a, "YELLOW", {
        get: function () {
          return a.Color4f(1, 1, 0, 1);
        },
      });
      Object.defineProperty(a, "CYAN", {
        get: function () {
          return a.Color4f(0, 1, 1, 1);
        },
      });
      Object.defineProperty(a, "MAGENTA", {
        get: function () {
          return a.Color4f(1, 0, 1, 1);
        },
      });
      a.getColorComponents = function (e) {
        return [Math.floor(255 * e[0]), Math.floor(255 * e[1]), Math.floor(255 * e[2]), e[3]];
      };
      a.parseColorString = function (e, c) {
        e = e.toLowerCase();
        if (e.startsWith("#")) {
          c = 255;
          switch (e.length) {
            case 9:
              c = parseInt(e.slice(7, 9), 16);
            case 7:
              var g = parseInt(e.slice(1, 3), 16);
              var l = parseInt(e.slice(3, 5), 16);
              var r = parseInt(e.slice(5, 7), 16);
              break;
            case 5:
              c = 17 * parseInt(e.slice(4, 5), 16);
            case 4:
              (g = 17 * parseInt(e.slice(1, 2), 16)),
                (l = 17 * parseInt(e.slice(2, 3), 16)),
                (r = 17 * parseInt(e.slice(3, 4), 16));
          }
          return a.Color(g, l, r, c / 255);
        }
        return e.startsWith("rgba")
          ? ((e = e.slice(5, -1)), (e = e.split(",")), a.Color(+e[0], +e[1], +e[2], u(e[3])))
          : e.startsWith("rgb")
            ? ((e = e.slice(4, -1)), (e = e.split(",")), a.Color(+e[0], +e[1], +e[2], u(e[3])))
            : e.startsWith("gray(") || e.startsWith("hsl") || !c || ((e = c[e]), void 0 === e)
              ? a.BLACK
              : e;
      };
      a.multiplyByAlpha = function (e, c) {
        e = e.slice();
        e[3] = Math.max(0, Math.min(e[3] * c, 1));
        return e;
      };
      a.Malloc = function (e, c) {
        var g = a._malloc(c * e.BYTES_PER_ELEMENT);
        return {
          _ck: !0,
          length: c,
          byteOffset: g,
          qe: null,
          subarray: function (l, r) {
            l = this.toTypedArray().subarray(l, r);
            l._ck = !0;
            return l;
          },
          toTypedArray: function () {
            if (this.qe && this.qe.length) return this.qe;
            this.qe = new e(a.HEAPU8.buffer, g, c);
            this.qe._ck = !0;
            return this.qe;
          },
        };
      };
      a.Free = function (e) {
        a._free(e.byteOffset);
        e.byteOffset = V;
        e.toTypedArray = null;
        e.qe = null;
      };
      var Na = V,
        Xb,
        $a = V,
        Yb,
        Ja = V,
        Zb,
        sa,
        da = V,
        zc,
        Oa = V,
        ac,
        ab = V,
        Ac,
        bc = V,
        Ab,
        kb = V,
        Bc,
        $b = V,
        Cc,
        Dc = V,
        xd = Float32Array.of(0, 0, 1),
        V = 0;
      a.onRuntimeInitialized = function () {
        function e(c, g, l, r, x, C, J) {
          C ||
            ((C = 4 * r.width),
            r.colorType === a.ColorType.RGBA_F16 ? (C *= 2) : r.colorType === a.ColorType.RGBA_F32 && (C *= 4));
          var N = C * r.height;
          var P = x ? x.byteOffset : a._malloc(N);
          if (J ? !c._readPixels(r, P, C, g, l, J) : !c._readPixels(r, P, C, g, l)) return x || a._free(P), null;
          if (x) return x.toTypedArray();
          switch (r.colorType) {
            case a.ColorType.RGBA_8888:
            case a.ColorType.RGBA_F16:
              c = new Uint8Array(a.HEAPU8.buffer, P, N).slice();
              break;
            case a.ColorType.RGBA_F32:
              c = new Float32Array(a.HEAPU8.buffer, P, N).slice();
              break;
            default:
              return null;
          }
          a._free(P);
          return c;
        }
        Zb = a.Malloc(Float32Array, 4);
        Ja = Zb.byteOffset;
        Yb = a.Malloc(Float32Array, 16);
        $a = Yb.byteOffset;
        Xb = a.Malloc(Float32Array, 9);
        Na = Xb.byteOffset;
        Bc = a.Malloc(Float32Array, 12);
        $b = Bc.byteOffset;
        Cc = a.Malloc(Float32Array, 12);
        Dc = Cc.byteOffset;
        sa = a.Malloc(Float32Array, 4);
        da = sa.byteOffset;
        zc = a.Malloc(Float32Array, 4);
        Oa = zc.byteOffset;
        ac = a.Malloc(Float32Array, 3);
        ab = ac.byteOffset;
        Ac = a.Malloc(Float32Array, 3);
        bc = Ac.byteOffset;
        Ab = a.Malloc(Int32Array, 4);
        kb = Ab.byteOffset;
        a.ColorSpace.SRGB = a.ColorSpace._MakeSRGB();
        a.ColorSpace.DISPLAY_P3 = a.ColorSpace._MakeDisplayP3();
        a.ColorSpace.ADOBE_RGB = a.ColorSpace._MakeAdobeRGB();
        a.GlyphRunFlags = { IsWhiteSpace: a._GlyphRunFlags_isWhiteSpace };
        a.Path.MakeFromCmds = function (c) {
          var g = v(c, "HEAPF32"),
            l = a.Path._MakeFromCmds(g, c.length);
          p(g, c);
          return l;
        };
        a.Path.MakeFromVerbsPointsWeights = function (c, g, l) {
          var r = v(c, "HEAPU8"),
            x = v(g, "HEAPF32"),
            C = v(l, "HEAPF32"),
            J = a.Path._MakeFromVerbsPointsWeights(r, c.length, x, g.length, C, (l && l.length) || 0);
          p(r, c);
          p(x, g);
          p(C, l);
          return J;
        };
        a.Path.prototype.addArc = function (c, g, l) {
          c = R(c);
          this._addArc(c, g, l);
          return this;
        };
        a.Path.prototype.addCircle = function (c, g, l, r) {
          this._addCircle(c, g, l, !!r);
          return this;
        };
        a.Path.prototype.addOval = function (c, g, l) {
          void 0 === l && (l = 1);
          c = R(c);
          this._addOval(c, !!g, l);
          return this;
        };
        a.Path.prototype.addPath = function () {
          var c = Array.prototype.slice.call(arguments),
            g = c[0],
            l = !1;
          "boolean" === typeof c[c.length - 1] && (l = c.pop());
          if (1 === c.length) this._addPath(g, 1, 0, 0, 0, 1, 0, 0, 0, 1, l);
          else if (2 === c.length)
            (c = c[1]), this._addPath(g, c[0], c[1], c[2], c[3], c[4], c[5], c[6] || 0, c[7] || 0, c[8] || 1, l);
          else if (7 === c.length || 10 === c.length)
            this._addPath(g, c[1], c[2], c[3], c[4], c[5], c[6], c[7] || 0, c[8] || 0, c[9] || 1, l);
          else return null;
          return this;
        };
        a.Path.prototype.addPoly = function (c, g) {
          var l = v(c, "HEAPF32");
          this._addPoly(l, c.length / 2, g);
          p(l, c);
          return this;
        };
        a.Path.prototype.addRect = function (c, g) {
          c = R(c);
          this._addRect(c, !!g);
          return this;
        };
        a.Path.prototype.addRRect = function (c, g) {
          c = ta(c);
          this._addRRect(c, !!g);
          return this;
        };
        a.Path.prototype.addVerbsPointsWeights = function (c, g, l) {
          var r = v(c, "HEAPU8"),
            x = v(g, "HEAPF32"),
            C = v(l, "HEAPF32");
          this._addVerbsPointsWeights(r, c.length, x, g.length, C, (l && l.length) || 0);
          p(r, c);
          p(x, g);
          p(C, l);
        };
        a.Path.prototype.arc = function (c, g, l, r, x, C) {
          c = a.LTRBRect(c - l, g - l, c + l, g + l);
          x = ((x - r) / Math.PI) * 180 - 360 * !!C;
          C = new a.Path();
          C.addArc(c, (r / Math.PI) * 180, x);
          this.addPath(C, !0);
          C.delete();
          return this;
        };
        a.Path.prototype.arcToOval = function (c, g, l, r) {
          c = R(c);
          this._arcToOval(c, g, l, r);
          return this;
        };
        a.Path.prototype.arcToRotated = function (c, g, l, r, x, C, J) {
          this._arcToRotated(c, g, l, !!r, !!x, C, J);
          return this;
        };
        a.Path.prototype.arcToTangent = function (c, g, l, r, x) {
          this._arcToTangent(c, g, l, r, x);
          return this;
        };
        a.Path.prototype.close = function () {
          this._close();
          return this;
        };
        a.Path.prototype.conicTo = function (c, g, l, r, x) {
          this._conicTo(c, g, l, r, x);
          return this;
        };
        a.Path.prototype.computeTightBounds = function (c) {
          this._computeTightBounds(da);
          var g = sa.toTypedArray();
          return c ? (c.set(g), c) : g.slice();
        };
        a.Path.prototype.cubicTo = function (c, g, l, r, x, C) {
          this._cubicTo(c, g, l, r, x, C);
          return this;
        };
        a.Path.prototype.dash = function (c, g, l) {
          return this._dash(c, g, l) ? this : null;
        };
        a.Path.prototype.getBounds = function (c) {
          this._getBounds(da);
          var g = sa.toTypedArray();
          return c ? (c.set(g), c) : g.slice();
        };
        a.Path.prototype.lineTo = function (c, g) {
          this._lineTo(c, g);
          return this;
        };
        a.Path.prototype.moveTo = function (c, g) {
          this._moveTo(c, g);
          return this;
        };
        a.Path.prototype.offset = function (c, g) {
          this._transform(1, 0, c, 0, 1, g, 0, 0, 1);
          return this;
        };
        a.Path.prototype.quadTo = function (c, g, l, r) {
          this._quadTo(c, g, l, r);
          return this;
        };
        a.Path.prototype.rArcTo = function (c, g, l, r, x, C, J) {
          this._rArcTo(c, g, l, r, x, C, J);
          return this;
        };
        a.Path.prototype.rConicTo = function (c, g, l, r, x) {
          this._rConicTo(c, g, l, r, x);
          return this;
        };
        a.Path.prototype.rCubicTo = function (c, g, l, r, x, C) {
          this._rCubicTo(c, g, l, r, x, C);
          return this;
        };
        a.Path.prototype.rLineTo = function (c, g) {
          this._rLineTo(c, g);
          return this;
        };
        a.Path.prototype.rMoveTo = function (c, g) {
          this._rMoveTo(c, g);
          return this;
        };
        a.Path.prototype.rQuadTo = function (c, g, l, r) {
          this._rQuadTo(c, g, l, r);
          return this;
        };
        a.Path.prototype.stroke = function (c) {
          c = c || {};
          c.width = c.width || 1;
          c.miter_limit = c.miter_limit || 4;
          c.cap = c.cap || a.StrokeCap.Butt;
          c.join = c.join || a.StrokeJoin.Miter;
          c.precision = c.precision || 1;
          return this._stroke(c) ? this : null;
        };
        a.Path.prototype.transform = function () {
          if (1 === arguments.length) {
            var c = arguments[0];
            this._transform(c[0], c[1], c[2], c[3], c[4], c[5], c[6] || 0, c[7] || 0, c[8] || 1);
          } else if (6 === arguments.length || 9 === arguments.length)
            (c = arguments), this._transform(c[0], c[1], c[2], c[3], c[4], c[5], c[6] || 0, c[7] || 0, c[8] || 1);
          else throw "transform expected to take 1 or 9 arguments. Got " + arguments.length;
          return this;
        };
        a.Path.prototype.trim = function (c, g, l) {
          return this._trim(c, g, !!l) ? this : null;
        };
        a.Image.prototype.encodeToBytes = function (c, g) {
          var l = a.Te();
          c = c || a.ImageFormat.PNG;
          g = g || 100;
          return l ? this._encodeToBytes(c, g, l) : this._encodeToBytes(c, g);
        };
        a.Image.prototype.makeShaderCubic = function (c, g, l, r, x) {
          x = G(x);
          return this._makeShaderCubic(c, g, l, r, x);
        };
        a.Image.prototype.makeShaderOptions = function (c, g, l, r, x) {
          x = G(x);
          return this._makeShaderOptions(c, g, l, r, x);
        };
        a.Image.prototype.readPixels = function (c, g, l, r, x) {
          var C = a.Te();
          return e(this, c, g, l, r, x, C);
        };
        a.Canvas.prototype.clear = function (c) {
          a.Md(this.Kd);
          c = y(c);
          this._clear(c);
        };
        a.Canvas.prototype.clipRRect = function (c, g, l) {
          a.Md(this.Kd);
          c = ta(c);
          this._clipRRect(c, g, l);
        };
        a.Canvas.prototype.clipRect = function (c, g, l) {
          a.Md(this.Kd);
          c = R(c);
          this._clipRect(c, g, l);
        };
        a.Canvas.prototype.concat = function (c) {
          a.Md(this.Kd);
          c = L(c);
          this._concat(c);
        };
        a.Canvas.prototype.drawArc = function (c, g, l, r, x) {
          a.Md(this.Kd);
          c = R(c);
          this._drawArc(c, g, l, r, x);
        };
        a.Canvas.prototype.drawAtlas = function (c, g, l, r, x, C, J) {
          if (c && r && g && l && g.length === l.length) {
            a.Md(this.Kd);
            x || (x = a.BlendMode.SrcOver);
            var N = v(g, "HEAPF32"),
              P = v(l, "HEAPF32"),
              Y = l.length / 4,
              W = v(m(C), "HEAPU32");
            if (J && "B" in J && "C" in J) this._drawAtlasCubic(c, P, N, W, Y, x, J.B, J.C, r);
            else {
              let t = a.FilterMode.Linear,
                D = a.MipmapMode.None;
              J && ((t = J.filter), "mipmap" in J && (D = J.mipmap));
              this._drawAtlasOptions(c, P, N, W, Y, x, t, D, r);
            }
            p(N, g);
            p(P, l);
            p(W, C);
          }
        };
        a.Canvas.prototype.drawCircle = function (c, g, l, r) {
          a.Md(this.Kd);
          this._drawCircle(c, g, l, r);
        };
        a.Canvas.prototype.drawColor = function (c, g) {
          a.Md(this.Kd);
          c = y(c);
          void 0 !== g ? this._drawColor(c, g) : this._drawColor(c);
        };
        a.Canvas.prototype.drawColorInt = function (c, g) {
          a.Md(this.Kd);
          this._drawColorInt(c, g || a.BlendMode.SrcOver);
        };
        a.Canvas.prototype.drawColorComponents = function (c, g, l, r, x) {
          a.Md(this.Kd);
          c = M(c, g, l, r);
          void 0 !== x ? this._drawColor(c, x) : this._drawColor(c);
        };
        a.Canvas.prototype.drawDRRect = function (c, g, l) {
          a.Md(this.Kd);
          c = ta(c, $b);
          g = ta(g, Dc);
          this._drawDRRect(c, g, l);
        };
        a.Canvas.prototype.drawImage = function (c, g, l, r) {
          a.Md(this.Kd);
          this._drawImage(c, g, l, r || null);
        };
        a.Canvas.prototype.drawImageCubic = function (c, g, l, r, x, C) {
          a.Md(this.Kd);
          this._drawImageCubic(c, g, l, r, x, C || null);
        };
        a.Canvas.prototype.drawImageOptions = function (c, g, l, r, x, C) {
          a.Md(this.Kd);
          this._drawImageOptions(c, g, l, r, x, C || null);
        };
        a.Canvas.prototype.drawImageNine = function (c, g, l, r, x) {
          a.Md(this.Kd);
          g = v(g, "HEAP32", kb);
          l = R(l);
          this._drawImageNine(c, g, l, r, x || null);
        };
        a.Canvas.prototype.drawImageRect = function (c, g, l, r, x) {
          a.Md(this.Kd);
          R(g, da);
          R(l, Oa);
          this._drawImageRect(c, da, Oa, r, !!x);
        };
        a.Canvas.prototype.drawImageRectCubic = function (c, g, l, r, x, C) {
          a.Md(this.Kd);
          R(g, da);
          R(l, Oa);
          this._drawImageRectCubic(c, da, Oa, r, x, C || null);
        };
        a.Canvas.prototype.drawImageRectOptions = function (c, g, l, r, x, C) {
          a.Md(this.Kd);
          R(g, da);
          R(l, Oa);
          this._drawImageRectOptions(c, da, Oa, r, x, C || null);
        };
        a.Canvas.prototype.drawLine = function (c, g, l, r, x) {
          a.Md(this.Kd);
          this._drawLine(c, g, l, r, x);
        };
        a.Canvas.prototype.drawOval = function (c, g) {
          a.Md(this.Kd);
          c = R(c);
          this._drawOval(c, g);
        };
        a.Canvas.prototype.drawPaint = function (c) {
          a.Md(this.Kd);
          this._drawPaint(c);
        };
        a.Canvas.prototype.drawParagraph = function (c, g, l) {
          a.Md(this.Kd);
          this._drawParagraph(c, g, l);
        };
        a.Canvas.prototype.drawPatch = function (c, g, l, r, x) {
          if (24 > c.length) throw "Need 12 cubic points";
          if (g && 4 > g.length) throw "Need 4 colors";
          if (l && 8 > l.length) throw "Need 4 shader coordinates";
          a.Md(this.Kd);
          const C = v(c, "HEAPF32"),
            J = g ? v(m(g), "HEAPU32") : V,
            N = l ? v(l, "HEAPF32") : V;
          r || (r = a.BlendMode.Modulate);
          this._drawPatch(C, J, N, r, x);
          p(N, l);
          p(J, g);
          p(C, c);
        };
        a.Canvas.prototype.drawPath = function (c, g) {
          a.Md(this.Kd);
          this._drawPath(c, g);
        };
        a.Canvas.prototype.drawPicture = function (c) {
          a.Md(this.Kd);
          this._drawPicture(c);
        };
        a.Canvas.prototype.drawPoints = function (c, g, l) {
          a.Md(this.Kd);
          var r = v(g, "HEAPF32");
          this._drawPoints(c, r, g.length / 2, l);
          p(r, g);
        };
        a.Canvas.prototype.drawRRect = function (c, g) {
          a.Md(this.Kd);
          c = ta(c);
          this._drawRRect(c, g);
        };
        a.Canvas.prototype.drawRect = function (c, g) {
          a.Md(this.Kd);
          c = R(c);
          this._drawRect(c, g);
        };
        a.Canvas.prototype.drawRect4f = function (c, g, l, r, x) {
          a.Md(this.Kd);
          this._drawRect4f(c, g, l, r, x);
        };
        a.Canvas.prototype.drawShadow = function (c, g, l, r, x, C, J) {
          a.Md(this.Kd);
          var N = v(x, "HEAPF32"),
            P = v(C, "HEAPF32");
          g = v(g, "HEAPF32", ab);
          l = v(l, "HEAPF32", bc);
          this._drawShadow(c, g, l, r, N, P, J);
          p(N, x);
          p(P, C);
        };
        a.getShadowLocalBounds = function (c, g, l, r, x, C, J) {
          c = G(c);
          l = v(l, "HEAPF32", ab);
          r = v(r, "HEAPF32", bc);
          if (!this._getShadowLocalBounds(c, g, l, r, x, C, da)) return null;
          g = sa.toTypedArray();
          return J ? (J.set(g), J) : g.slice();
        };
        a.Canvas.prototype.drawTextBlob = function (c, g, l, r) {
          a.Md(this.Kd);
          this._drawTextBlob(c, g, l, r);
        };
        a.Canvas.prototype.drawVertices = function (c, g, l) {
          a.Md(this.Kd);
          this._drawVertices(c, g, l);
        };
        a.Canvas.prototype.getDeviceClipBounds = function (c) {
          this._getDeviceClipBounds(kb);
          var g = Ab.toTypedArray();
          c ? c.set(g) : (c = g.slice());
          return c;
        };
        a.Canvas.prototype.getLocalToDevice = function () {
          this._getLocalToDevice($a);
          for (var c = $a, g = Array(16), l = 0; 16 > l; l++) g[l] = a.HEAPF32[c / 4 + l];
          return g;
        };
        a.Canvas.prototype.getTotalMatrix = function () {
          this._getTotalMatrix(Na);
          for (var c = Array(9), g = 0; 9 > g; g++) c[g] = a.HEAPF32[Na / 4 + g];
          return c;
        };
        a.Canvas.prototype.makeSurface = function (c) {
          c = this._makeSurface(c);
          c.Kd = this.Kd;
          return c;
        };
        a.Canvas.prototype.readPixels = function (c, g, l, r, x) {
          a.Md(this.Kd);
          return e(this, c, g, l, r, x);
        };
        a.Canvas.prototype.saveLayer = function (c, g, l, r) {
          g = R(g);
          return this._saveLayer(c || null, g, l || null, r || 0);
        };
        a.Canvas.prototype.writePixels = function (c, g, l, r, x, C, J, N) {
          if (c.byteLength % (g * l)) throw "pixels length must be a multiple of the srcWidth * srcHeight";
          a.Md(this.Kd);
          var P = c.byteLength / (g * l);
          C = C || a.AlphaType.Unpremul;
          J = J || a.ColorType.RGBA_8888;
          N = N || a.ColorSpace.SRGB;
          var Y = P * g;
          P = v(c, "HEAPU8");
          g = this._writePixels({ width: g, height: l, colorType: J, alphaType: C, colorSpace: N }, P, Y, r, x);
          p(P, c);
          return g;
        };
        a.ColorFilter.MakeBlend = function (c, g, l) {
          c = y(c);
          l = l || a.ColorSpace.SRGB;
          return a.ColorFilter._MakeBlend(c, g, l);
        };
        a.ColorFilter.MakeMatrix = function (c) {
          if (!c || 20 !== c.length) throw "invalid color matrix";
          var g = v(c, "HEAPF32"),
            l = a.ColorFilter._makeMatrix(g);
          p(g, c);
          return l;
        };
        a.ContourMeasure.prototype.getPosTan = function (c, g) {
          this._getPosTan(c, da);
          c = sa.toTypedArray();
          return g ? (g.set(c), g) : c.slice();
        };
        a.ImageFilter.prototype.getOutputBounds = function (c, g, l) {
          c = R(c, da);
          g = G(g);
          this._getOutputBounds(c, g, kb);
          g = Ab.toTypedArray();
          return l ? (l.set(g), l) : g.slice();
        };
        a.ImageFilter.MakeDropShadow = function (c, g, l, r, x, C) {
          x = y(x, Ja);
          return a.ImageFilter._MakeDropShadow(c, g, l, r, x, C);
        };
        a.ImageFilter.MakeDropShadowOnly = function (c, g, l, r, x, C) {
          x = y(x, Ja);
          return a.ImageFilter._MakeDropShadowOnly(c, g, l, r, x, C);
        };
        a.ImageFilter.MakeImage = function (c, g, l, r) {
          l = R(l, da);
          r = R(r, Oa);
          if ("B" in g && "C" in g) return a.ImageFilter._MakeImageCubic(c, g.B, g.C, l, r);
          const x = g.filter;
          let C = a.MipmapMode.None;
          "mipmap" in g && (C = g.mipmap);
          return a.ImageFilter._MakeImageOptions(c, x, C, l, r);
        };
        a.ImageFilter.MakeMatrixTransform = function (c, g, l) {
          c = G(c);
          if ("B" in g && "C" in g) return a.ImageFilter._MakeMatrixTransformCubic(c, g.B, g.C, l);
          const r = g.filter;
          let x = a.MipmapMode.None;
          "mipmap" in g && (x = g.mipmap);
          return a.ImageFilter._MakeMatrixTransformOptions(c, r, x, l);
        };
        a.Paint.prototype.getColor = function () {
          this._getColor(Ja);
          return T(Ja);
        };
        a.Paint.prototype.setColor = function (c, g) {
          g = g || null;
          c = y(c);
          this._setColor(c, g);
        };
        a.Paint.prototype.setColorComponents = function (c, g, l, r, x) {
          x = x || null;
          c = M(c, g, l, r);
          this._setColor(c, x);
        };
        a.Path.prototype.getPoint = function (c, g) {
          this._getPoint(c, da);
          c = sa.toTypedArray();
          return g ? ((g[0] = c[0]), (g[1] = c[1]), g) : c.slice(0, 2);
        };
        a.Picture.prototype.makeShader = function (c, g, l, r, x) {
          r = G(r);
          x = R(x);
          return this._makeShader(c, g, l, r, x);
        };
        a.Picture.prototype.cullRect = function (c) {
          this._cullRect(da);
          var g = sa.toTypedArray();
          return c ? (c.set(g), c) : g.slice();
        };
        a.PictureRecorder.prototype.beginRecording = function (c, g) {
          c = R(c);
          return this._beginRecording(c, !!g);
        };
        a.Surface.prototype.getCanvas = function () {
          var c = this._getCanvas();
          c.Kd = this.Kd;
          return c;
        };
        a.Surface.prototype.makeImageSnapshot = function (c) {
          a.Md(this.Kd);
          c = v(c, "HEAP32", kb);
          return this._makeImageSnapshot(c);
        };
        a.Surface.prototype.makeSurface = function (c) {
          a.Md(this.Kd);
          c = this._makeSurface(c);
          c.Kd = this.Kd;
          return c;
        };
        a.Surface.prototype.Gf = function (c, g) {
          this.He || (this.He = this.getCanvas());
          return requestAnimationFrame(
            function () {
              a.Md(this.Kd);
              c(this.He);
              this.flush(g);
            }.bind(this),
          );
        };
        a.Surface.prototype.requestAnimationFrame ||
          (a.Surface.prototype.requestAnimationFrame = a.Surface.prototype.Gf);
        a.Surface.prototype.Cf = function (c, g) {
          this.He || (this.He = this.getCanvas());
          requestAnimationFrame(
            function () {
              a.Md(this.Kd);
              c(this.He);
              this.flush(g);
              this.dispose();
            }.bind(this),
          );
        };
        a.Surface.prototype.drawOnce || (a.Surface.prototype.drawOnce = a.Surface.prototype.Cf);
        a.PathEffect.MakeDash = function (c, g) {
          g || (g = 0);
          if (!c.length || 1 === c.length % 2) throw "Intervals array must have even length";
          var l = v(c, "HEAPF32");
          g = a.PathEffect._MakeDash(l, c.length, g);
          p(l, c);
          return g;
        };
        a.PathEffect.MakeLine2D = function (c, g) {
          g = G(g);
          return a.PathEffect._MakeLine2D(c, g);
        };
        a.PathEffect.MakePath2D = function (c, g) {
          c = G(c);
          return a.PathEffect._MakePath2D(c, g);
        };
        a.Shader.MakeColor = function (c, g) {
          g = g || null;
          c = y(c);
          return a.Shader._MakeColor(c, g);
        };
        a.Shader.Blend = a.Shader.MakeBlend;
        a.Shader.Color = a.Shader.MakeColor;
        a.Shader.MakeLinearGradient = function (c, g, l, r, x, C, J, N) {
          N = N || null;
          var P = E(l),
            Y = v(r, "HEAPF32");
          J = J || 0;
          C = G(C);
          var W = sa.toTypedArray();
          W.set(c);
          W.set(g, 2);
          c = a.Shader._MakeLinearGradient(da, P.be, P.colorType, Y, P.count, x, J, C, N);
          p(P.be, l);
          r && p(Y, r);
          return c;
        };
        a.Shader.MakeRadialGradient = function (c, g, l, r, x, C, J, N) {
          N = N || null;
          var P = E(l),
            Y = v(r, "HEAPF32");
          J = J || 0;
          C = G(C);
          c = a.Shader._MakeRadialGradient(c[0], c[1], g, P.be, P.colorType, Y, P.count, x, J, C, N);
          p(P.be, l);
          r && p(Y, r);
          return c;
        };
        a.Shader.MakeSweepGradient = function (c, g, l, r, x, C, J, N, P, Y) {
          Y = Y || null;
          var W = E(l),
            t = v(r, "HEAPF32");
          J = J || 0;
          N = N || 0;
          P = P || 360;
          C = G(C);
          c = a.Shader._MakeSweepGradient(c, g, W.be, W.colorType, t, W.count, x, N, P, J, C, Y);
          p(W.be, l);
          r && p(t, r);
          return c;
        };
        a.Shader.MakeTwoPointConicalGradient = function (c, g, l, r, x, C, J, N, P, Y) {
          Y = Y || null;
          var W = E(x),
            t = v(C, "HEAPF32");
          P = P || 0;
          N = G(N);
          var D = sa.toTypedArray();
          D.set(c);
          D.set(l, 2);
          c = a.Shader._MakeTwoPointConicalGradient(da, g, r, W.be, W.colorType, t, W.count, J, P, N, Y);
          p(W.be, x);
          C && p(t, C);
          return c;
        };
        a.Vertices.prototype.bounds = function (c) {
          this._bounds(da);
          var g = sa.toTypedArray();
          return c ? (c.set(g), c) : g.slice();
        };
        a.Sd &&
          a.Sd.forEach(function (c) {
            c();
          });
      };
      a.computeTonalColors = function (e) {
        var c = v(e.ambient, "HEAPF32"),
          g = v(e.spot, "HEAPF32");
        this._computeTonalColors(c, g);
        var l = { ambient: T(c), spot: T(g) };
        p(c, e.ambient);
        p(g, e.spot);
        return l;
      };
      a.LTRBRect = function (e, c, g, l) {
        return Float32Array.of(e, c, g, l);
      };
      a.XYWHRect = function (e, c, g, l) {
        return Float32Array.of(e, c, e + g, c + l);
      };
      a.LTRBiRect = function (e, c, g, l) {
        return Int32Array.of(e, c, g, l);
      };
      a.XYWHiRect = function (e, c, g, l) {
        return Int32Array.of(e, c, e + g, c + l);
      };
      a.RRectXY = function (e, c, g) {
        return Float32Array.of(e[0], e[1], e[2], e[3], c, g, c, g, c, g, c, g);
      };
      a.MakeAnimatedImageFromEncoded = function (e) {
        e = new Uint8Array(e);
        var c = a._malloc(e.byteLength);
        a.HEAPU8.set(e, c);
        return (e = a._decodeAnimatedImage(c, e.byteLength)) ? e : null;
      };
      a.MakeImageFromEncoded = function (e) {
        e = new Uint8Array(e);
        var c = a._malloc(e.byteLength);
        a.HEAPU8.set(e, c);
        return (e = a._decodeImage(c, e.byteLength)) ? e : null;
      };
      var lb = null;
      a.MakeImageFromCanvasImageSource = function (e) {
        var c = e.width,
          g = e.height;
        lb || (lb = document.createElement("canvas"));
        lb.width = c;
        lb.height = g;
        var l = lb.getContext("2d", { willReadFrequently: !0 });
        l.drawImage(e, 0, 0);
        e = l.getImageData(0, 0, c, g);
        return a.MakeImage(
          {
            width: c,
            height: g,
            alphaType: a.AlphaType.Unpremul,
            colorType: a.ColorType.RGBA_8888,
            colorSpace: a.ColorSpace.SRGB,
          },
          e.data,
          4 * c,
        );
      };
      a.MakeImage = function (e, c, g) {
        var l = a._malloc(c.length);
        a.HEAPU8.set(c, l);
        return a._MakeImage(e, l, c.length, g);
      };
      a.MakeVertices = function (e, c, g, l, r, x) {
        var C = (r && r.length) || 0,
          J = 0;
        g && g.length && (J |= 1);
        l && l.length && (J |= 2);
        void 0 === x || x || (J |= 4);
        e = new a._VerticesBuilder(e, c.length / 2, C, J);
        v(c, "HEAPF32", e.positions());
        e.texCoords() && v(g, "HEAPF32", e.texCoords());
        e.colors() && v(m(l), "HEAPU32", e.colors());
        e.indices() && v(r, "HEAPU16", e.indices());
        return e.detach();
      };
      a.Matrix = {};
      a.Matrix.identity = function () {
        return d(3);
      };
      a.Matrix.invert = function (e) {
        var c =
          e[0] * e[4] * e[8] +
          e[1] * e[5] * e[6] +
          e[2] * e[3] * e[7] -
          e[2] * e[4] * e[6] -
          e[1] * e[3] * e[8] -
          e[0] * e[5] * e[7];
        return c
          ? [
              (e[4] * e[8] - e[5] * e[7]) / c,
              (e[2] * e[7] - e[1] * e[8]) / c,
              (e[1] * e[5] - e[2] * e[4]) / c,
              (e[5] * e[6] - e[3] * e[8]) / c,
              (e[0] * e[8] - e[2] * e[6]) / c,
              (e[2] * e[3] - e[0] * e[5]) / c,
              (e[3] * e[7] - e[4] * e[6]) / c,
              (e[1] * e[6] - e[0] * e[7]) / c,
              (e[0] * e[4] - e[1] * e[3]) / c,
            ]
          : null;
      };
      a.Matrix.mapPoints = function (e, c) {
        for (var g = 0; g < c.length; g += 2) {
          var l = c[g],
            r = c[g + 1],
            x = e[6] * l + e[7] * r + e[8],
            C = e[3] * l + e[4] * r + e[5];
          c[g] = (e[0] * l + e[1] * r + e[2]) / x;
          c[g + 1] = C / x;
        }
        return c;
      };
      a.Matrix.multiply = function () {
        return jb(3, arguments);
      };
      a.Matrix.rotated = function (e, c, g) {
        c = c || 0;
        g = g || 0;
        var l = Math.sin(e);
        e = Math.cos(e);
        return [e, -l, ma(l, g, 1 - e, c), l, e, ma(-l, c, 1 - e, g), 0, 0, 1];
      };
      a.Matrix.scaled = function (e, c, g, l) {
        g = g || 0;
        l = l || 0;
        var r = b([e, c], d(3), 3, 0, 1);
        return b([g - e * g, l - c * l], r, 3, 2, 0);
      };
      a.Matrix.skewed = function (e, c, g, l) {
        g = g || 0;
        l = l || 0;
        var r = b([e, c], d(3), 3, 1, -1);
        return b([-e * g, -c * l], r, 3, 2, 0);
      };
      a.Matrix.translated = function (e, c) {
        return b(arguments, d(3), 3, 2, 0);
      };
      a.Vector = {};
      a.Vector.dot = function (e, c) {
        return e
          .map(function (g, l) {
            return g * c[l];
          })
          .reduce(function (g, l) {
            return g + l;
          });
      };
      a.Vector.lengthSquared = function (e) {
        return a.Vector.dot(e, e);
      };
      a.Vector.length = function (e) {
        return Math.sqrt(a.Vector.lengthSquared(e));
      };
      a.Vector.mulScalar = function (e, c) {
        return e.map(function (g) {
          return g * c;
        });
      };
      a.Vector.add = function (e, c) {
        return e.map(function (g, l) {
          return g + c[l];
        });
      };
      a.Vector.sub = function (e, c) {
        return e.map(function (g, l) {
          return g - c[l];
        });
      };
      a.Vector.dist = function (e, c) {
        return a.Vector.length(a.Vector.sub(e, c));
      };
      a.Vector.normalize = function (e) {
        return a.Vector.mulScalar(e, 1 / a.Vector.length(e));
      };
      a.Vector.cross = function (e, c) {
        return [e[1] * c[2] - e[2] * c[1], e[2] * c[0] - e[0] * c[2], e[0] * c[1] - e[1] * c[0]];
      };
      a.M44 = {};
      a.M44.identity = function () {
        return d(4);
      };
      a.M44.translated = function (e) {
        return b(e, d(4), 4, 3, 0);
      };
      a.M44.scaled = function (e) {
        return b(e, d(4), 4, 0, 1);
      };
      a.M44.rotated = function (e, c) {
        return a.M44.rotatedUnitSinCos(a.Vector.normalize(e), Math.sin(c), Math.cos(c));
      };
      a.M44.rotatedUnitSinCos = function (e, c, g) {
        var l = e[0],
          r = e[1];
        e = e[2];
        var x = 1 - g;
        return [
          x * l * l + g,
          x * l * r - c * e,
          x * l * e + c * r,
          0,
          x * l * r + c * e,
          x * r * r + g,
          x * r * e - c * l,
          0,
          x * l * e - c * r,
          x * r * e + c * l,
          x * e * e + g,
          0,
          0,
          0,
          0,
          1,
        ];
      };
      a.M44.lookat = function (e, c, g) {
        c = a.Vector.normalize(a.Vector.sub(c, e));
        g = a.Vector.normalize(g);
        g = a.Vector.normalize(a.Vector.cross(c, g));
        var l = a.M44.identity();
        b(g, l, 4, 0, 0);
        b(a.Vector.cross(g, c), l, 4, 1, 0);
        b(a.Vector.mulScalar(c, -1), l, 4, 2, 0);
        b(e, l, 4, 3, 0);
        e = a.M44.invert(l);
        return null === e ? a.M44.identity() : e;
      };
      a.M44.perspective = function (e, c, g) {
        var l = 1 / (c - e);
        g /= 2;
        g = Math.cos(g) / Math.sin(g);
        return [g, 0, 0, 0, 0, g, 0, 0, 0, 0, (c + e) * l, 2 * c * e * l, 0, 0, -1, 1];
      };
      a.M44.rc = function (e, c, g) {
        return e[4 * c + g];
      };
      a.M44.multiply = function () {
        return jb(4, arguments);
      };
      a.M44.invert = function (e) {
        var c = e[0],
          g = e[4],
          l = e[8],
          r = e[12],
          x = e[1],
          C = e[5],
          J = e[9],
          N = e[13],
          P = e[2],
          Y = e[6],
          W = e[10],
          t = e[14],
          D = e[3],
          U = e[7],
          ea = e[11];
        e = e[15];
        var ka = c * C - g * x,
          ua = c * J - l * x,
          xa = c * N - r * x,
          Ea = g * J - l * C,
          fa = g * N - r * C,
          I = l * N - r * J,
          k = P * U - Y * D,
          q = P * ea - W * D,
          z = P * e - t * D,
          B = Y * ea - W * U,
          F = Y * e - t * U,
          H = W * e - t * ea,
          O = ka * H - ua * F + xa * B + Ea * z - fa * q + I * k,
          Z = 1 / O;
        if (0 === O || Infinity === Z) return null;
        ka *= Z;
        ua *= Z;
        xa *= Z;
        Ea *= Z;
        fa *= Z;
        I *= Z;
        k *= Z;
        q *= Z;
        z *= Z;
        B *= Z;
        F *= Z;
        H *= Z;
        c = [
          C * H - J * F + N * B,
          J * z - x * H - N * q,
          x * F - C * z + N * k,
          C * q - x * B - J * k,
          l * F - g * H - r * B,
          c * H - l * z + r * q,
          g * z - c * F - r * k,
          c * B - g * q + l * k,
          U * I - ea * fa + e * Ea,
          ea * xa - D * I - e * ua,
          D * fa - U * xa + e * ka,
          U * ua - D * Ea - ea * ka,
          W * fa - Y * I - t * Ea,
          P * I - W * xa + t * ua,
          Y * xa - P * fa - t * ka,
          P * Ea - Y * ua + W * ka,
        ];
        return c.every(function (na) {
          return !isNaN(na) && Infinity !== na && -Infinity !== na;
        })
          ? c
          : null;
      };
      a.M44.transpose = function (e) {
        return [e[0], e[4], e[8], e[12], e[1], e[5], e[9], e[13], e[2], e[6], e[10], e[14], e[3], e[7], e[11], e[15]];
      };
      a.M44.mustInvert = function (e) {
        e = a.M44.invert(e);
        if (null === e) throw "Matrix not invertible";
        return e;
      };
      a.M44.setupCamera = function (e, c, g) {
        var l = a.M44.lookat(g.eye, g.coa, g.up);
        g = a.M44.perspective(g.near, g.far, g.angle);
        c = [(e[2] - e[0]) / 2, (e[3] - e[1]) / 2, c];
        e = a.M44.multiply(a.M44.translated([(e[0] + e[2]) / 2, (e[1] + e[3]) / 2, 0]), a.M44.scaled(c));
        return a.M44.multiply(e, g, l, a.M44.mustInvert(e));
      };
      a.ColorMatrix = {};
      a.ColorMatrix.identity = function () {
        var e = new Float32Array(20);
        e[0] = 1;
        e[6] = 1;
        e[12] = 1;
        e[18] = 1;
        return e;
      };
      a.ColorMatrix.scaled = function (e, c, g, l) {
        var r = new Float32Array(20);
        r[0] = e;
        r[6] = c;
        r[12] = g;
        r[18] = l;
        return r;
      };
      var yd = [
        [6, 7, 11, 12],
        [0, 10, 2, 12],
        [0, 1, 5, 6],
      ];
      a.ColorMatrix.rotated = function (e, c, g) {
        var l = a.ColorMatrix.identity();
        e = yd[e];
        l[e[0]] = g;
        l[e[1]] = c;
        l[e[2]] = -c;
        l[e[3]] = g;
        return l;
      };
      a.ColorMatrix.postTranslate = function (e, c, g, l, r) {
        e[4] += c;
        e[9] += g;
        e[14] += l;
        e[19] += r;
        return e;
      };
      a.ColorMatrix.concat = function (e, c) {
        for (var g = new Float32Array(20), l = 0, r = 0; 20 > r; r += 5) {
          for (var x = 0; 4 > x; x++)
            g[l++] = e[r] * c[x] + e[r + 1] * c[x + 5] + e[r + 2] * c[x + 10] + e[r + 3] * c[x + 15];
          g[l++] = e[r] * c[4] + e[r + 1] * c[9] + e[r + 2] * c[14] + e[r + 3] * c[19] + e[r + 4];
        }
        return g;
      };
      (function (e) {
        e.Sd = e.Sd || [];
        e.Sd.push(function () {
          function c(t) {
            t && (t.dir = 0 === t.dir ? e.TextDirection.RTL : e.TextDirection.LTR);
            return t;
          }
          function g(t) {
            if (!t || !t.length) return [];
            for (var D = [], U = 0; U < t.length; U += 5) {
              var ea = e.LTRBRect(t[U], t[U + 1], t[U + 2], t[U + 3]),
                ka = e.TextDirection.LTR;
              0 === t[U + 4] && (ka = e.TextDirection.RTL);
              D.push({ rect: ea, dir: ka });
            }
            e._free(t.byteOffset);
            return D;
          }
          function l(t) {
            t = t || {};
            void 0 === t.weight && (t.weight = e.FontWeight.Normal);
            t.width = t.width || e.FontWidth.Normal;
            t.slant = t.slant || e.FontSlant.Upright;
            return t;
          }
          function r(t) {
            if (!t || !t.length) return V;
            for (var D = [], U = 0; U < t.length; U++) {
              var ea = x(t[U]);
              D.push(ea);
            }
            return v(D, "HEAPU32");
          }
          function x(t) {
            if (N[t]) return N[t];
            var D = oa(t) + 1,
              U = e._malloc(D);
            pa(t, K, U, D);
            return (N[t] = U);
          }
          function C(t) {
            t._colorPtr = y(t.color);
            t._foregroundColorPtr = V;
            t._backgroundColorPtr = V;
            t._decorationColorPtr = V;
            t.foregroundColor && (t._foregroundColorPtr = y(t.foregroundColor, P));
            t.backgroundColor && (t._backgroundColorPtr = y(t.backgroundColor, Y));
            t.decorationColor && (t._decorationColorPtr = y(t.decorationColor, W));
            Array.isArray(t.fontFamilies) && t.fontFamilies.length
              ? ((t._fontFamiliesPtr = r(t.fontFamilies)), (t._fontFamiliesLen = t.fontFamilies.length))
              : ((t._fontFamiliesPtr = V), (t._fontFamiliesLen = 0));
            if (t.locale) {
              var D = t.locale;
              t._localePtr = x(D);
              t._localeLen = oa(D);
            } else (t._localePtr = V), (t._localeLen = 0);
            if (Array.isArray(t.shadows) && t.shadows.length) {
              D = t.shadows;
              var U = D.map(function (fa) {
                  return fa.color || e.BLACK;
                }),
                ea = D.map(function (fa) {
                  return fa.blurRadius || 0;
                });
              t._shadowLen = D.length;
              for (var ka = e._malloc(8 * D.length), ua = ka / 4, xa = 0; xa < D.length; xa++) {
                var Ea = D[xa].offset || [0, 0];
                e.HEAPF32[ua] = Ea[0];
                e.HEAPF32[ua + 1] = Ea[1];
                ua += 2;
              }
              t._shadowColorsPtr = E(U).be;
              t._shadowOffsetsPtr = ka;
              t._shadowBlurRadiiPtr = v(ea, "HEAPF32");
            } else (t._shadowLen = 0), (t._shadowColorsPtr = V), (t._shadowOffsetsPtr = V), (t._shadowBlurRadiiPtr = V);
            Array.isArray(t.fontFeatures) && t.fontFeatures.length
              ? ((D = t.fontFeatures),
                (U = D.map(function (fa) {
                  return fa.name;
                })),
                (ea = D.map(function (fa) {
                  return fa.value;
                })),
                (t._fontFeatureLen = D.length),
                (t._fontFeatureNamesPtr = r(U)),
                (t._fontFeatureValuesPtr = v(ea, "HEAPU32")))
              : ((t._fontFeatureLen = 0), (t._fontFeatureNamesPtr = V), (t._fontFeatureValuesPtr = V));
            Array.isArray(t.fontVariations) && t.fontVariations.length
              ? ((D = t.fontVariations),
                (U = D.map(function (fa) {
                  return fa.axis;
                })),
                (ea = D.map(function (fa) {
                  return fa.value;
                })),
                (t._fontVariationLen = D.length),
                (t._fontVariationAxesPtr = r(U)),
                (t._fontVariationValuesPtr = v(ea, "HEAPF32")))
              : ((t._fontVariationLen = 0), (t._fontVariationAxesPtr = V), (t._fontVariationValuesPtr = V));
          }
          function J(t) {
            e._free(t._fontFamiliesPtr);
            e._free(t._shadowColorsPtr);
            e._free(t._shadowOffsetsPtr);
            e._free(t._shadowBlurRadiiPtr);
            e._free(t._fontFeatureNamesPtr);
            e._free(t._fontFeatureValuesPtr);
            e._free(t._fontVariationAxesPtr);
            e._free(t._fontVariationValuesPtr);
          }
          e.Paragraph.prototype.getRectsForRange = function (t, D, U, ea) {
            t = this._getRectsForRange(t, D, U, ea);
            return g(t);
          };
          e.Paragraph.prototype.getRectsForPlaceholders = function () {
            var t = this._getRectsForPlaceholders();
            return g(t);
          };
          e.Paragraph.prototype.getGlyphInfoAt = function (t) {
            return c(this._getGlyphInfoAt(t));
          };
          e.Paragraph.prototype.getClosestGlyphInfoAtCoordinate = function (t, D) {
            return c(this._getClosestGlyphInfoAtCoordinate(t, D));
          };
          e.TypefaceFontProvider.prototype.registerFont = function (t, D) {
            t = e.Typeface.MakeTypefaceFromData(t);
            if (!t) return null;
            D = x(D);
            this._registerFont(t, D);
          };
          e.ParagraphStyle = function (t) {
            t.disableHinting = t.disableHinting || !1;
            if (t.ellipsis) {
              var D = t.ellipsis;
              t._ellipsisPtr = x(D);
              t._ellipsisLen = oa(D);
            } else (t._ellipsisPtr = V), (t._ellipsisLen = 0);
            null == t.heightMultiplier && (t.heightMultiplier = -1);
            t.maxLines = t.maxLines || 0;
            t.replaceTabCharacters = t.replaceTabCharacters || !1;
            D = (D = t.strutStyle) || {};
            D.strutEnabled = D.strutEnabled || !1;
            D.strutEnabled && Array.isArray(D.fontFamilies) && D.fontFamilies.length
              ? ((D._fontFamiliesPtr = r(D.fontFamilies)), (D._fontFamiliesLen = D.fontFamilies.length))
              : ((D._fontFamiliesPtr = V), (D._fontFamiliesLen = 0));
            D.fontStyle = l(D.fontStyle);
            null == D.fontSize && (D.fontSize = -1);
            null == D.heightMultiplier && (D.heightMultiplier = -1);
            D.halfLeading = D.halfLeading || !1;
            D.leading = D.leading || 0;
            D.forceStrutHeight = D.forceStrutHeight || !1;
            t.strutStyle = D;
            t.textAlign = t.textAlign || e.TextAlign.Start;
            t.textDirection = t.textDirection || e.TextDirection.LTR;
            t.textHeightBehavior = t.textHeightBehavior || e.TextHeightBehavior.All;
            t.textStyle = e.TextStyle(t.textStyle);
            t.applyRoundingHack = !1 !== t.applyRoundingHack;
            return t;
          };
          e.TextStyle = function (t) {
            t.color || (t.color = e.BLACK);
            t.decoration = t.decoration || 0;
            t.decorationThickness = t.decorationThickness || 0;
            t.decorationStyle = t.decorationStyle || e.DecorationStyle.Solid;
            t.textBaseline = t.textBaseline || e.TextBaseline.Alphabetic;
            null == t.fontSize && (t.fontSize = -1);
            t.letterSpacing = t.letterSpacing || 0;
            t.wordSpacing = t.wordSpacing || 0;
            null == t.heightMultiplier && (t.heightMultiplier = -1);
            t.halfLeading = t.halfLeading || !1;
            t.fontStyle = l(t.fontStyle);
            return t;
          };
          var N = {},
            P = e._malloc(16),
            Y = e._malloc(16),
            W = e._malloc(16);
          e.ParagraphBuilder.Make = function (t, D) {
            C(t.textStyle);
            D = e.ParagraphBuilder._Make(t, D);
            J(t.textStyle);
            return D;
          };
          e.ParagraphBuilder.MakeFromFontProvider = function (t, D) {
            C(t.textStyle);
            D = e.ParagraphBuilder._MakeFromFontProvider(t, D);
            J(t.textStyle);
            return D;
          };
          e.ParagraphBuilder.MakeFromFontCollection = function (t, D) {
            C(t.textStyle);
            D = e.ParagraphBuilder._MakeFromFontCollection(t, D);
            J(t.textStyle);
            return D;
          };
          e.ParagraphBuilder.ShapeText = function (t, D, U) {
            let ea = 0;
            for (const ka of D) ea += ka.length;
            if (ea !== t.length) throw "Accumulated block lengths must equal text.length";
            return e.ParagraphBuilder._ShapeText(t, D, U);
          };
          e.ParagraphBuilder.prototype.pushStyle = function (t) {
            C(t);
            this._pushStyle(t);
            J(t);
          };
          e.ParagraphBuilder.prototype.pushPaintStyle = function (t, D, U) {
            C(t);
            this._pushPaintStyle(t, D, U);
            J(t);
          };
          e.ParagraphBuilder.prototype.addPlaceholder = function (t, D, U, ea, ka) {
            U = U || e.PlaceholderAlignment.Baseline;
            ea = ea || e.TextBaseline.Alphabetic;
            this._addPlaceholder(t || 0, D || 0, U, ea, ka || 0);
          };
          e.ParagraphBuilder.prototype.setWordsUtf8 = function (t) {
            var D = v(t, "HEAPU32");
            this._setWordsUtf8(D, (t && t.length) || 0);
            p(D, t);
          };
          e.ParagraphBuilder.prototype.setWordsUtf16 = function (t) {
            var D = v(t, "HEAPU32");
            this._setWordsUtf16(D, (t && t.length) || 0);
            p(D, t);
          };
          e.ParagraphBuilder.prototype.setGraphemeBreaksUtf8 = function (t) {
            var D = v(t, "HEAPU32");
            this._setGraphemeBreaksUtf8(D, (t && t.length) || 0);
            p(D, t);
          };
          e.ParagraphBuilder.prototype.setGraphemeBreaksUtf16 = function (t) {
            var D = v(t, "HEAPU32");
            this._setGraphemeBreaksUtf16(D, (t && t.length) || 0);
            p(D, t);
          };
          e.ParagraphBuilder.prototype.setLineBreaksUtf8 = function (t) {
            var D = v(t, "HEAPU32");
            this._setLineBreaksUtf8(D, (t && t.length) || 0);
            p(D, t);
          };
          e.ParagraphBuilder.prototype.setLineBreaksUtf16 = function (t) {
            var D = v(t, "HEAPU32");
            this._setLineBreaksUtf16(D, (t && t.length) || 0);
            p(D, t);
          };
        });
      })(w);
      a.MakeManagedAnimation = function (e, c, g, l, r) {
        if (!a._MakeManagedAnimation) throw "Not compiled with MakeManagedAnimation";
        g || (g = "");
        if (!c) return a._MakeManagedAnimation(e, 0, V, V, V, g, l, r);
        for (var x = [], C = [], J = [], N = Object.keys(c || {}), P = 0; P < N.length; P++) {
          var Y = N[P],
            W = new Uint8Array(c[Y]),
            t = a._malloc(W.byteLength);
          a.HEAPU8.set(W, t);
          C.push(t);
          J.push(W.byteLength);
          W = oa(Y) + 1;
          t = a._malloc(W);
          pa(Y, K, t, W);
          x.push(t);
        }
        c = v(x, "HEAPU32");
        C = v(C, "HEAPU32");
        J = v(J, "HEAPU32");
        e = a._MakeManagedAnimation(e, N.length, c, C, J, g, l, r);
        a._free(c);
        a._free(C);
        a._free(J);
        return e;
      };
      a.sg = function (e) {
        e.text = e.text || "";
        e.textSize = e.textSize || 0;
        e.minTextSize = e.minTextSize || 0;
        e.maxTextSize = e.maxTextSize || Number.MAX_VALUE;
        e.strokeWidth = e.strokeWidth || 0;
        e.lineHeight = e.lineHeight || 0;
        e.lineShift = e.lineShift || 0;
        e.ascent = e.ascent || 0;
        e.maxLines = e.maxLines || 0;
        e.horizAlign = e.horizAlign || a.TextAlign.Left;
        e.vertAlign = e.vertAlign || a.tg.Top;
        e.strokeJoin = e.strokeJoin || a.StrokeJoin.Miter;
        e.direction = e.direction || a.TextDirection.LTR;
        e.linebreak = e.linebreak || a.LineBreakType.HardLineBreak;
        e.resize = e.resize || a.rg.None;
        e.fillColor || (e.fillColor = a.TRANSPARENT);
        e.strokeColor || (e.strokeColor = a.TRANSPARENT);
        e.boundingBox || (e.boundingBox = [0, 0, 0, 0]);
        return e;
      };
      (function (e) {
        e.Sd = e.Sd || [];
        e.Sd.push(function () {
          e.Animation.prototype.render = function (c, g) {
            R(g, da);
            this._render(c, da);
          };
          e.Animation.prototype.size = function (c) {
            this._size(da);
            var g = sa.toTypedArray();
            return c ? ((c[0] = g[0]), (c[1] = g[1]), c) : g.slice(0, 2);
          };
          e.ManagedAnimation &&
            ((e.ManagedAnimation.prototype.render = function (c, g) {
              R(g, da);
              this._render(c, da);
            }),
            (e.ManagedAnimation.prototype.seek = function (c, g) {
              this._seek(c, da);
              c = sa.toTypedArray();
              return g ? (g.set(c), g) : c.slice();
            }),
            (e.ManagedAnimation.prototype.seekFrame = function (c, g) {
              this._seekFrame(c, da);
              c = sa.toTypedArray();
              return g ? (g.set(c), g) : c.slice();
            }),
            (e.ManagedAnimation.prototype.setColor = function (c, g) {
              g = y(g);
              return this._setColor(c, g);
            }),
            (e.ManagedAnimation.prototype.setColorSlot = function (c, g) {
              g = y(g);
              return this._setColorSlot(c, g);
            }),
            (e.ManagedAnimation.prototype.getColorSlot = function (c) {
              this._getColorSlot(c, Ja);
              c = T(Ja);
              return -1 == c[0] ? null : c;
            }),
            (e.ManagedAnimation.prototype.setVec2Slot = function (c, g) {
              v(g, "HEAPF32", ab);
              return this._setVec2Slot(c, ab);
            }),
            (e.ManagedAnimation.prototype.getVec2Slot = function (c) {
              this._getVec2Slot(c, ab);
              c = ac.toTypedArray();
              return -1 === c[2] ? null : c.slice(0, 2);
            }),
            (e.ManagedAnimation.prototype.setTextSlot = function (c, g) {
              var l = y(g.fillColor, Ja),
                r = y(g.strokeColor, da),
                x = R(g.boundingBox, Oa);
              g._fillColorPtr = l;
              g._strokeColorPtr = r;
              g._boundingBoxPtr = x;
              return this._setTextSlot(c, g);
            }),
            (e.ManagedAnimation.prototype.setTransform = function (c, g, l, r, x, C, J) {
              g = v([g[0], g[1], l[0], l[1], r[0], r[1], x, C, J], "HEAPF32", Na);
              return this._setTransform(c, g);
            }),
            (e.ManagedAnimation.prototype.size = function (c) {
              this._size(da);
              var g = sa.toTypedArray();
              return c ? ((c[0] = g[0]), (c[1] = g[1]), c) : g.slice(0, 2);
            }));
        });
      })(w);
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        a.Path.prototype.op = function (e, c) {
          return this._op(e, c) ? this : null;
        };
        a.Path.prototype.simplify = function () {
          return this._simplify() ? this : null;
        };
      });
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        a.Canvas.prototype.drawText = function (e, c, g, l, r) {
          var x = oa(e),
            C = a._malloc(x + 1);
          pa(e, K, C, x + 1);
          this._drawSimpleText(C, x, c, g, r, l);
          a._free(C);
        };
        a.Canvas.prototype.drawGlyphs = function (e, c, g, l, r, x) {
          if (!(2 * e.length <= c.length)) throw "Not enough positions for the array of gyphs";
          a.Md(this.Kd);
          const C = v(e, "HEAPU16"),
            J = v(c, "HEAPF32");
          this._drawGlyphs(e.length, C, J, g, l, r, x);
          p(J, c);
          p(C, e);
        };
        a.Font.prototype.getGlyphBounds = function (e, c, g) {
          var l = v(e, "HEAPU16"),
            r = a._malloc(16 * e.length);
          this._getGlyphWidthBounds(l, e.length, V, r, c || null);
          c = new Float32Array(a.HEAPU8.buffer, r, 4 * e.length);
          p(l, e);
          if (g) return g.set(c), a._free(r), g;
          e = Float32Array.from(c);
          a._free(r);
          return e;
        };
        a.Font.prototype.getGlyphIDs = function (e, c, g) {
          c || (c = e.length);
          var l = oa(e) + 1,
            r = a._malloc(l);
          pa(e, K, r, l);
          e = a._malloc(2 * c);
          c = this._getGlyphIDs(r, l - 1, c, e);
          a._free(r);
          if (0 > c) return a._free(e), null;
          r = new Uint16Array(a.HEAPU8.buffer, e, c);
          if (g) return g.set(r), a._free(e), g;
          g = Uint16Array.from(r);
          a._free(e);
          return g;
        };
        a.Font.prototype.getGlyphIntercepts = function (e, c, g, l) {
          var r = v(e, "HEAPU16"),
            x = v(c, "HEAPF32");
          return this._getGlyphIntercepts(r, e.length, !(e && e._ck), x, c.length, !(c && c._ck), g, l);
        };
        a.Font.prototype.getGlyphWidths = function (e, c, g) {
          var l = v(e, "HEAPU16"),
            r = a._malloc(4 * e.length);
          this._getGlyphWidthBounds(l, e.length, r, V, c || null);
          c = new Float32Array(a.HEAPU8.buffer, r, e.length);
          p(l, e);
          if (g) return g.set(c), a._free(r), g;
          e = Float32Array.from(c);
          a._free(r);
          return e;
        };
        a.FontMgr.FromData = function () {
          if (!arguments.length) return null;
          var e = arguments;
          1 === e.length && Array.isArray(e[0]) && (e = arguments[0]);
          if (!e.length) return null;
          for (var c = [], g = [], l = 0; l < e.length; l++) {
            var r = new Uint8Array(e[l]),
              x = v(r, "HEAPU8");
            c.push(x);
            g.push(r.byteLength);
          }
          c = v(c, "HEAPU32");
          g = v(g, "HEAPU32");
          e = a.FontMgr._fromData(c, g, e.length);
          a._free(c);
          a._free(g);
          return e;
        };
        a.Typeface.MakeTypefaceFromData = function (e) {
          e = new Uint8Array(e);
          var c = v(e, "HEAPU8");
          return (e = a.Typeface._MakeTypefaceFromData(c, e.byteLength)) ? e : null;
        };
        a.Typeface.MakeFreeTypeFaceFromData = a.Typeface.MakeTypefaceFromData;
        a.Typeface.prototype.getGlyphIDs = function (e, c, g) {
          c || (c = e.length);
          var l = oa(e) + 1,
            r = a._malloc(l);
          pa(e, K, r, l);
          e = a._malloc(2 * c);
          c = this._getGlyphIDs(r, l - 1, c, e);
          a._free(r);
          if (0 > c) return a._free(e), null;
          r = new Uint16Array(a.HEAPU8.buffer, e, c);
          if (g) return g.set(r), a._free(e), g;
          g = Uint16Array.from(r);
          a._free(e);
          return g;
        };
        a.TextBlob.MakeOnPath = function (e, c, g, l) {
          if (e && e.length && c && c.countPoints()) {
            if (1 === c.countPoints()) return this.MakeFromText(e, g);
            l || (l = 0);
            var r = g.getGlyphIDs(e);
            r = g.getGlyphWidths(r);
            var x = [];
            c = new a.ContourMeasureIter(c, !1, 1);
            for (var C = c.next(), J = new Float32Array(4), N = 0; N < e.length && C; N++) {
              var P = r[N];
              l += P / 2;
              if (l > C.length()) {
                C.delete();
                C = c.next();
                if (!C) {
                  e = e.substring(0, N);
                  break;
                }
                l = P / 2;
              }
              C.getPosTan(l, J);
              var Y = J[2],
                W = J[3];
              x.push(Y, W, J[0] - (P / 2) * Y, J[1] - (P / 2) * W);
              l += P / 2;
            }
            e = this.MakeFromRSXform(e, x, g);
            C && C.delete();
            c.delete();
            return e;
          }
        };
        a.TextBlob.MakeFromRSXform = function (e, c, g) {
          var l = oa(e) + 1,
            r = a._malloc(l);
          pa(e, K, r, l);
          e = v(c, "HEAPF32");
          g = a.TextBlob._MakeFromRSXform(r, l - 1, e, g);
          a._free(r);
          return g ? g : null;
        };
        a.TextBlob.MakeFromRSXformGlyphs = function (e, c, g) {
          var l = v(e, "HEAPU16");
          c = v(c, "HEAPF32");
          g = a.TextBlob._MakeFromRSXformGlyphs(l, 2 * e.length, c, g);
          p(l, e);
          return g ? g : null;
        };
        a.TextBlob.MakeFromGlyphs = function (e, c) {
          var g = v(e, "HEAPU16");
          c = a.TextBlob._MakeFromGlyphs(g, 2 * e.length, c);
          p(g, e);
          return c ? c : null;
        };
        a.TextBlob.MakeFromText = function (e, c) {
          var g = oa(e) + 1,
            l = a._malloc(g);
          pa(e, K, l, g);
          e = a.TextBlob._MakeFromText(l, g - 1, c);
          a._free(l);
          return e ? e : null;
        };
        a.MallocGlyphIDs = function (e) {
          return a.Malloc(Uint16Array, e);
        };
      });
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        a.MakePicture = function (e) {
          e = new Uint8Array(e);
          var c = a._malloc(e.byteLength);
          a.HEAPU8.set(e, c);
          return (e = a._MakePicture(c, e.byteLength)) ? e : null;
        };
      });
      a.Sd = a.Sd || [];
      a.Sd.push(function () {
        a.RuntimeEffect.Make = function (e, c) {
          return a.RuntimeEffect._Make(e, {
            onError:
              c ||
              function (g) {
                console.log("RuntimeEffect error", g);
              },
          });
        };
        a.RuntimeEffect.MakeForBlender = function (e, c) {
          return a.RuntimeEffect._MakeForBlender(e, {
            onError:
              c ||
              function (g) {
                console.log("RuntimeEffect error", g);
              },
          });
        };
        a.RuntimeEffect.prototype.makeShader = function (e, c) {
          var g = !e._ck,
            l = v(e, "HEAPF32");
          c = G(c);
          return this._makeShader(l, 4 * e.length, g, c);
        };
        a.RuntimeEffect.prototype.makeShaderWithChildren = function (e, c, g) {
          var l = !e._ck,
            r = v(e, "HEAPF32");
          g = G(g);
          for (var x = [], C = 0; C < c.length; C++) x.push(c[C].Jd.Rd);
          c = v(x, "HEAPU32");
          return this._makeShaderWithChildren(r, 4 * e.length, l, c, x.length, g);
        };
        a.RuntimeEffect.prototype.makeBlender = function (e) {
          var c = !e._ck,
            g = v(e, "HEAPF32");
          return this._makeBlender(g, 4 * e.length, c);
        };
      });
      (function () {
        function e(I) {
          for (var k = 0; k < I.length; k++) if (void 0 !== I[k] && !Number.isFinite(I[k])) return !1;
          return !0;
        }
        function c(I) {
          var k = a.getColorComponents(I);
          I = k[0];
          var q = k[1],
            z = k[2];
          k = k[3];
          if (1 === k)
            return (
              (I = I.toString(16).toLowerCase()),
              (q = q.toString(16).toLowerCase()),
              (z = z.toString(16).toLowerCase()),
              (I = 1 === I.length ? "0" + I : I),
              (q = 1 === q.length ? "0" + q : q),
              (z = 1 === z.length ? "0" + z : z),
              "#" + I + q + z
            );
          k = 0 === k || 1 === k ? k : k.toFixed(8);
          return "rgba(" + I + ", " + q + ", " + z + ", " + k + ")";
        }
        function g(I) {
          return a.parseColorString(I, xa);
        }
        function l(I) {
          I = Ea.exec(I);
          if (!I) return null;
          var k = parseFloat(I[4]),
            q = 16;
          switch (I[5]) {
            case "em":
            case "rem":
              q = 16 * k;
              break;
            case "pt":
              q = (4 * k) / 3;
              break;
            case "px":
              q = k;
              break;
            case "pc":
              q = 16 * k;
              break;
            case "in":
              q = 96 * k;
              break;
            case "cm":
              q = (96 * k) / 2.54;
              break;
            case "mm":
              q = (96 / 25.4) * k;
              break;
            case "q":
              q = (96 / 25.4 / 4) * k;
              break;
            case "%":
              q = (16 / 75) * k;
          }
          return { style: I[1], variant: I[2], weight: I[3], sizePx: q, family: I[6].trim() };
        }
        function r() {
          fa || (fa = { "Noto Mono": { "*": a.Typeface.GetDefault() }, monospace: { "*": a.Typeface.GetDefault() } });
        }
        function x(I) {
          this.Ld = I;
          this.Od = new a.Paint();
          this.Od.setAntiAlias(!0);
          this.Od.setStrokeMiter(10);
          this.Od.setStrokeCap(a.StrokeCap.Butt);
          this.Od.setStrokeJoin(a.StrokeJoin.Miter);
          this.Re = "10px monospace";
          this.me = new a.Font(a.Typeface.GetDefault(), 10);
          this.me.setSubpixel(!0);
          this.ae = this.ge = a.BLACK;
          this.ve = 0;
          this.Je = a.TRANSPARENT;
          this.xe = this.we = 0;
          this.Ke = this.ie = 1;
          this.Ie = 0;
          this.ue = [];
          this.Nd = a.BlendMode.SrcOver;
          this.Od.setStrokeWidth(this.Ke);
          this.Od.setBlendMode(this.Nd);
          this.Qd = new a.Path();
          this.Td = a.Matrix.identity();
          this.lf = [];
          this.Be = [];
          this.le = function () {
            this.Qd.delete();
            this.Od.delete();
            this.me.delete();
            this.Be.forEach(function (k) {
              k.le();
            });
          };
          Object.defineProperty(this, "currentTransform", {
            enumerable: !0,
            get: function () {
              return { a: this.Td[0], c: this.Td[1], e: this.Td[2], b: this.Td[3], d: this.Td[4], f: this.Td[5] };
            },
            set: function (k) {
              k.a && this.setTransform(k.a, k.b, k.c, k.d, k.e, k.f);
            },
          });
          Object.defineProperty(this, "fillStyle", {
            enumerable: !0,
            get: function () {
              return f(this.ae) ? c(this.ae) : this.ae;
            },
            set: function (k) {
              "string" === typeof k ? (this.ae = g(k)) : k.te && (this.ae = k);
            },
          });
          Object.defineProperty(this, "font", {
            enumerable: !0,
            get: function () {
              return this.Re;
            },
            set: function (k) {
              var q = l(k);
              var z = (q.style || "normal") + "|" + (q.variant || "normal") + "|" + (q.weight || "normal");
              var B = q.family;
              r();
              z = fa[B] ? fa[B][z] || fa[B]["*"] : a.Typeface.GetDefault();
              q.typeface = z;
              q && (this.me.setSize(q.sizePx), this.me.setTypeface(q.typeface), (this.Re = k));
            },
          });
          Object.defineProperty(this, "globalAlpha", {
            enumerable: !0,
            get: function () {
              return this.ie;
            },
            set: function (k) {
              !isFinite(k) || 0 > k || 1 < k || (this.ie = k);
            },
          });
          Object.defineProperty(this, "globalCompositeOperation", {
            enumerable: !0,
            get: function () {
              switch (this.Nd) {
                case a.BlendMode.SrcOver:
                  return "source-over";
                case a.BlendMode.DstOver:
                  return "destination-over";
                case a.BlendMode.Src:
                  return "copy";
                case a.BlendMode.Dst:
                  return "destination";
                case a.BlendMode.Clear:
                  return "clear";
                case a.BlendMode.SrcIn:
                  return "source-in";
                case a.BlendMode.DstIn:
                  return "destination-in";
                case a.BlendMode.SrcOut:
                  return "source-out";
                case a.BlendMode.DstOut:
                  return "destination-out";
                case a.BlendMode.SrcATop:
                  return "source-atop";
                case a.BlendMode.DstATop:
                  return "destination-atop";
                case a.BlendMode.Xor:
                  return "xor";
                case a.BlendMode.Plus:
                  return "lighter";
                case a.BlendMode.Multiply:
                  return "multiply";
                case a.BlendMode.Screen:
                  return "screen";
                case a.BlendMode.Overlay:
                  return "overlay";
                case a.BlendMode.Darken:
                  return "darken";
                case a.BlendMode.Lighten:
                  return "lighten";
                case a.BlendMode.ColorDodge:
                  return "color-dodge";
                case a.BlendMode.ColorBurn:
                  return "color-burn";
                case a.BlendMode.HardLight:
                  return "hard-light";
                case a.BlendMode.SoftLight:
                  return "soft-light";
                case a.BlendMode.Difference:
                  return "difference";
                case a.BlendMode.Exclusion:
                  return "exclusion";
                case a.BlendMode.Hue:
                  return "hue";
                case a.BlendMode.Saturation:
                  return "saturation";
                case a.BlendMode.Color:
                  return "color";
                case a.BlendMode.Luminosity:
                  return "luminosity";
              }
            },
            set: function (k) {
              switch (k) {
                case "source-over":
                  this.Nd = a.BlendMode.SrcOver;
                  break;
                case "destination-over":
                  this.Nd = a.BlendMode.DstOver;
                  break;
                case "copy":
                  this.Nd = a.BlendMode.Src;
                  break;
                case "destination":
                  this.Nd = a.BlendMode.Dst;
                  break;
                case "clear":
                  this.Nd = a.BlendMode.Clear;
                  break;
                case "source-in":
                  this.Nd = a.BlendMode.SrcIn;
                  break;
                case "destination-in":
                  this.Nd = a.BlendMode.DstIn;
                  break;
                case "source-out":
                  this.Nd = a.BlendMode.SrcOut;
                  break;
                case "destination-out":
                  this.Nd = a.BlendMode.DstOut;
                  break;
                case "source-atop":
                  this.Nd = a.BlendMode.SrcATop;
                  break;
                case "destination-atop":
                  this.Nd = a.BlendMode.DstATop;
                  break;
                case "xor":
                  this.Nd = a.BlendMode.Xor;
                  break;
                case "lighter":
                  this.Nd = a.BlendMode.Plus;
                  break;
                case "plus-lighter":
                  this.Nd = a.BlendMode.Plus;
                  break;
                case "plus-darker":
                  throw "plus-darker is not supported";
                case "multiply":
                  this.Nd = a.BlendMode.Multiply;
                  break;
                case "screen":
                  this.Nd = a.BlendMode.Screen;
                  break;
                case "overlay":
                  this.Nd = a.BlendMode.Overlay;
                  break;
                case "darken":
                  this.Nd = a.BlendMode.Darken;
                  break;
                case "lighten":
                  this.Nd = a.BlendMode.Lighten;
                  break;
                case "color-dodge":
                  this.Nd = a.BlendMode.ColorDodge;
                  break;
                case "color-burn":
                  this.Nd = a.BlendMode.ColorBurn;
                  break;
                case "hard-light":
                  this.Nd = a.BlendMode.HardLight;
                  break;
                case "soft-light":
                  this.Nd = a.BlendMode.SoftLight;
                  break;
                case "difference":
                  this.Nd = a.BlendMode.Difference;
                  break;
                case "exclusion":
                  this.Nd = a.BlendMode.Exclusion;
                  break;
                case "hue":
                  this.Nd = a.BlendMode.Hue;
                  break;
                case "saturation":
                  this.Nd = a.BlendMode.Saturation;
                  break;
                case "color":
                  this.Nd = a.BlendMode.Color;
                  break;
                case "luminosity":
                  this.Nd = a.BlendMode.Luminosity;
                  break;
                default:
                  return;
              }
              this.Od.setBlendMode(this.Nd);
            },
          });
          Object.defineProperty(this, "imageSmoothingEnabled", {
            enumerable: !0,
            get: function () {
              return !0;
            },
            set: function () {},
          });
          Object.defineProperty(this, "imageSmoothingQuality", {
            enumerable: !0,
            get: function () {
              return "high";
            },
            set: function () {},
          });
          Object.defineProperty(this, "lineCap", {
            enumerable: !0,
            get: function () {
              switch (this.Od.getStrokeCap()) {
                case a.StrokeCap.Butt:
                  return "butt";
                case a.StrokeCap.Round:
                  return "round";
                case a.StrokeCap.Square:
                  return "square";
              }
            },
            set: function (k) {
              switch (k) {
                case "butt":
                  this.Od.setStrokeCap(a.StrokeCap.Butt);
                  break;
                case "round":
                  this.Od.setStrokeCap(a.StrokeCap.Round);
                  break;
                case "square":
                  this.Od.setStrokeCap(a.StrokeCap.Square);
              }
            },
          });
          Object.defineProperty(this, "lineDashOffset", {
            enumerable: !0,
            get: function () {
              return this.Ie;
            },
            set: function (k) {
              isFinite(k) && (this.Ie = k);
            },
          });
          Object.defineProperty(this, "lineJoin", {
            enumerable: !0,
            get: function () {
              switch (this.Od.getStrokeJoin()) {
                case a.StrokeJoin.Miter:
                  return "miter";
                case a.StrokeJoin.Round:
                  return "round";
                case a.StrokeJoin.Bevel:
                  return "bevel";
              }
            },
            set: function (k) {
              switch (k) {
                case "miter":
                  this.Od.setStrokeJoin(a.StrokeJoin.Miter);
                  break;
                case "round":
                  this.Od.setStrokeJoin(a.StrokeJoin.Round);
                  break;
                case "bevel":
                  this.Od.setStrokeJoin(a.StrokeJoin.Bevel);
              }
            },
          });
          Object.defineProperty(this, "lineWidth", {
            enumerable: !0,
            get: function () {
              return this.Od.getStrokeWidth();
            },
            set: function (k) {
              0 >= k || !k || ((this.Ke = k), this.Od.setStrokeWidth(k));
            },
          });
          Object.defineProperty(this, "miterLimit", {
            enumerable: !0,
            get: function () {
              return this.Od.getStrokeMiter();
            },
            set: function (k) {
              0 >= k || !k || this.Od.setStrokeMiter(k);
            },
          });
          Object.defineProperty(this, "shadowBlur", {
            enumerable: !0,
            get: function () {
              return this.ve;
            },
            set: function (k) {
              0 > k || !isFinite(k) || (this.ve = k);
            },
          });
          Object.defineProperty(this, "shadowColor", {
            enumerable: !0,
            get: function () {
              return c(this.Je);
            },
            set: function (k) {
              this.Je = g(k);
            },
          });
          Object.defineProperty(this, "shadowOffsetX", {
            enumerable: !0,
            get: function () {
              return this.we;
            },
            set: function (k) {
              isFinite(k) && (this.we = k);
            },
          });
          Object.defineProperty(this, "shadowOffsetY", {
            enumerable: !0,
            get: function () {
              return this.xe;
            },
            set: function (k) {
              isFinite(k) && (this.xe = k);
            },
          });
          Object.defineProperty(this, "strokeStyle", {
            enumerable: !0,
            get: function () {
              return c(this.ge);
            },
            set: function (k) {
              "string" === typeof k ? (this.ge = g(k)) : k.te && (this.ge = k);
            },
          });
          this.arc = function (k, q, z, B, F, H) {
            D(this.Qd, k, q, z, z, 0, B, F, H);
          };
          this.arcTo = function (k, q, z, B, F) {
            Y(this.Qd, k, q, z, B, F);
          };
          this.beginPath = function () {
            this.Qd.delete();
            this.Qd = new a.Path();
          };
          this.bezierCurveTo = function (k, q, z, B, F, H) {
            var O = this.Qd;
            e([k, q, z, B, F, H]) && (O.isEmpty() && O.moveTo(k, q), O.cubicTo(k, q, z, B, F, H));
          };
          this.clearRect = function (k, q, z, B) {
            this.Od.setStyle(a.PaintStyle.Fill);
            this.Od.setBlendMode(a.BlendMode.Clear);
            this.Ld.drawRect(a.XYWHRect(k, q, z, B), this.Od);
            this.Od.setBlendMode(this.Nd);
          };
          this.clip = function (k, q) {
            "string" === typeof k ? ((q = k), (k = this.Qd)) : k && k.af && (k = k.Ud);
            k || (k = this.Qd);
            k = k.copy();
            q && "evenodd" === q.toLowerCase() ? k.setFillType(a.FillType.EvenOdd) : k.setFillType(a.FillType.Winding);
            this.Ld.clipPath(k, a.ClipOp.Intersect, !0);
            k.delete();
          };
          this.closePath = function () {
            W(this.Qd);
          };
          this.createImageData = function () {
            if (1 === arguments.length) {
              var k = arguments[0];
              return new N(new Uint8ClampedArray(4 * k.width * k.height), k.width, k.height);
            }
            if (2 === arguments.length) {
              k = arguments[0];
              var q = arguments[1];
              return new N(new Uint8ClampedArray(4 * k * q), k, q);
            }
            throw "createImageData expects 1 or 2 arguments, got " + arguments.length;
          };
          this.createLinearGradient = function (k, q, z, B) {
            if (e(arguments)) {
              var F = new P(k, q, z, B);
              this.Be.push(F);
              return F;
            }
          };
          this.createPattern = function (k, q) {
            k = new ka(k, q);
            this.Be.push(k);
            return k;
          };
          this.createRadialGradient = function (k, q, z, B, F, H) {
            if (e(arguments)) {
              var O = new ua(k, q, z, B, F, H);
              this.Be.push(O);
              return O;
            }
          };
          this.drawImage = function (k) {
            k instanceof J && (k = k.tf());
            var q = this.Qe();
            if (3 === arguments.length || 5 === arguments.length)
              var z = a.XYWHRect(arguments[1], arguments[2], arguments[3] || k.width(), arguments[4] || k.height()),
                B = a.XYWHRect(0, 0, k.width(), k.height());
            else if (9 === arguments.length)
              (z = a.XYWHRect(arguments[5], arguments[6], arguments[7], arguments[8])),
                (B = a.XYWHRect(arguments[1], arguments[2], arguments[3], arguments[4]));
            else throw "invalid number of args for drawImage, need 3, 5, or 9; got " + arguments.length;
            this.Ld.drawImageRect(k, B, z, q, !1);
            q.dispose();
          };
          this.ellipse = function (k, q, z, B, F, H, O, Z) {
            D(this.Qd, k, q, z, B, F, H, O, Z);
          };
          this.Qe = function () {
            var k = this.Od.copy();
            k.setStyle(a.PaintStyle.Fill);
            if (f(this.ae)) {
              var q = a.multiplyByAlpha(this.ae, this.ie);
              k.setColor(q);
            } else (q = this.ae.te(this.Td)), k.setColor(a.Color(0, 0, 0, this.ie)), k.setShader(q);
            k.dispose = function () {
              this.delete();
            };
            return k;
          };
          this.fill = function (k, q) {
            "string" === typeof k ? ((q = k), (k = this.Qd)) : k && k.af && (k = k.Ud);
            if ("evenodd" === q) this.Qd.setFillType(a.FillType.EvenOdd);
            else {
              if ("nonzero" !== q && q) throw "invalid fill rule";
              this.Qd.setFillType(a.FillType.Winding);
            }
            k || (k = this.Qd);
            q = this.Qe();
            var z = this.ye(q);
            z && (this.Ld.save(), this.re(), this.Ld.drawPath(k, z), this.Ld.restore(), z.dispose());
            this.Ld.drawPath(k, q);
            q.dispose();
          };
          this.fillRect = function (k, q, z, B) {
            var F = this.Qe(),
              H = this.ye(F);
            H &&
              (this.Ld.save(), this.re(), this.Ld.drawRect(a.XYWHRect(k, q, z, B), H), this.Ld.restore(), H.dispose());
            this.Ld.drawRect(a.XYWHRect(k, q, z, B), F);
            F.dispose();
          };
          this.fillText = function (k, q, z) {
            var B = this.Qe();
            k = a.TextBlob.MakeFromText(k, this.me);
            var F = this.ye(B);
            F && (this.Ld.save(), this.re(), this.Ld.drawTextBlob(k, q, z, F), this.Ld.restore(), F.dispose());
            this.Ld.drawTextBlob(k, q, z, B);
            k.delete();
            B.dispose();
          };
          this.getImageData = function (k, q, z, B) {
            return (k = this.Ld.readPixels(k, q, {
              width: z,
              height: B,
              colorType: a.ColorType.RGBA_8888,
              alphaType: a.AlphaType.Unpremul,
              colorSpace: a.ColorSpace.SRGB,
            }))
              ? new N(new Uint8ClampedArray(k.buffer), z, B)
              : null;
          };
          this.getLineDash = function () {
            return this.ue.slice();
          };
          this.mf = function (k) {
            var q = a.Matrix.invert(this.Td);
            a.Matrix.mapPoints(q, k);
            return k;
          };
          this.isPointInPath = function (k, q, z) {
            var B = arguments;
            if (3 === B.length) var F = this.Qd;
            else if (4 === B.length) (F = B[0]), (k = B[1]), (q = B[2]), (z = B[3]);
            else throw "invalid arg count, need 3 or 4, got " + B.length;
            if (!isFinite(k) || !isFinite(q)) return !1;
            z = z || "nonzero";
            if ("nonzero" !== z && "evenodd" !== z) return !1;
            B = this.mf([k, q]);
            k = B[0];
            q = B[1];
            F.setFillType("nonzero" === z ? a.FillType.Winding : a.FillType.EvenOdd);
            return F.contains(k, q);
          };
          this.isPointInStroke = function (k, q) {
            var z = arguments;
            if (2 === z.length) var B = this.Qd;
            else if (3 === z.length) (B = z[0]), (k = z[1]), (q = z[2]);
            else throw "invalid arg count, need 2 or 3, got " + z.length;
            if (!isFinite(k) || !isFinite(q)) return !1;
            z = this.mf([k, q]);
            k = z[0];
            q = z[1];
            B = B.copy();
            B.setFillType(a.FillType.Winding);
            B.stroke({
              width: this.lineWidth,
              miter_limit: this.miterLimit,
              cap: this.Od.getStrokeCap(),
              join: this.Od.getStrokeJoin(),
              precision: 0.3,
            });
            z = B.contains(k, q);
            B.delete();
            return z;
          };
          this.lineTo = function (k, q) {
            U(this.Qd, k, q);
          };
          this.measureText = function (k) {
            k = this.me.getGlyphIDs(k);
            k = this.me.getGlyphWidths(k);
            let q = 0;
            for (const z of k) q += z;
            return { width: q };
          };
          this.moveTo = function (k, q) {
            var z = this.Qd;
            e([k, q]) && z.moveTo(k, q);
          };
          this.putImageData = function (k, q, z, B, F, H, O) {
            if (e([q, z, B, F, H, O]))
              if (void 0 === B) this.Ld.writePixels(k.data, k.width, k.height, q, z);
              else if (
                ((B = B || 0),
                (F = F || 0),
                (H = H || k.width),
                (O = O || k.height),
                0 > H && ((B += H), (H = Math.abs(H))),
                0 > O && ((F += O), (O = Math.abs(O))),
                0 > B && ((H += B), (B = 0)),
                0 > F && ((O += F), (F = 0)),
                !(0 >= H || 0 >= O))
              ) {
                k = a.MakeImage(
                  {
                    width: k.width,
                    height: k.height,
                    alphaType: a.AlphaType.Unpremul,
                    colorType: a.ColorType.RGBA_8888,
                    colorSpace: a.ColorSpace.SRGB,
                  },
                  k.data,
                  4 * k.width,
                );
                var Z = a.XYWHRect(B, F, H, O);
                q = a.XYWHRect(q + B, z + F, H, O);
                z = a.Matrix.invert(this.Td);
                this.Ld.save();
                this.Ld.concat(z);
                this.Ld.drawImageRect(k, Z, q, null, !1);
                this.Ld.restore();
                k.delete();
              }
          };
          this.quadraticCurveTo = function (k, q, z, B) {
            var F = this.Qd;
            e([k, q, z, B]) && (F.isEmpty() && F.moveTo(k, q), F.quadTo(k, q, z, B));
          };
          this.rect = function (k, q, z, B) {
            var F = this.Qd;
            k = a.XYWHRect(k, q, z, B);
            e(k) && F.addRect(k);
          };
          this.resetTransform = function () {
            this.Qd.transform(this.Td);
            var k = a.Matrix.invert(this.Td);
            this.Ld.concat(k);
            this.Td = this.Ld.getTotalMatrix();
          };
          this.restore = function () {
            var k = this.lf.pop();
            if (k) {
              var q = a.Matrix.multiply(this.Td, a.Matrix.invert(k.Jf));
              this.Qd.transform(q);
              this.Od.delete();
              this.Od = k.ag;
              this.ue = k.Zf;
              this.Ke = k.mg;
              this.ge = k.lg;
              this.ae = k.fs;
              this.we = k.jg;
              this.xe = k.kg;
              this.ve = k.sb;
              this.Je = k.ig;
              this.ie = k.ga;
              this.Nd = k.Qf;
              this.Ie = k.$f;
              this.Re = k.Pf;
              this.Ld.restore();
              this.Td = this.Ld.getTotalMatrix();
            }
          };
          this.rotate = function (k) {
            if (isFinite(k)) {
              var q = a.Matrix.rotated(-k);
              this.Qd.transform(q);
              this.Ld.rotate((k / Math.PI) * 180, 0, 0);
              this.Td = this.Ld.getTotalMatrix();
            }
          };
          this.save = function () {
            if (this.ae.se) {
              var k = this.ae.se();
              this.Be.push(k);
            } else k = this.ae;
            if (this.ge.se) {
              var q = this.ge.se();
              this.Be.push(q);
            } else q = this.ge;
            this.lf.push({
              Jf: this.Td.slice(),
              Zf: this.ue.slice(),
              mg: this.Ke,
              lg: q,
              fs: k,
              jg: this.we,
              kg: this.xe,
              sb: this.ve,
              ig: this.Je,
              ga: this.ie,
              $f: this.Ie,
              Qf: this.Nd,
              ag: this.Od.copy(),
              Pf: this.Re,
            });
            this.Ld.save();
          };
          this.scale = function (k, q) {
            if (e(arguments)) {
              var z = a.Matrix.scaled(1 / k, 1 / q);
              this.Qd.transform(z);
              this.Ld.scale(k, q);
              this.Td = this.Ld.getTotalMatrix();
            }
          };
          this.setLineDash = function (k) {
            for (var q = 0; q < k.length; q++) if (!isFinite(k[q]) || 0 > k[q]) return;
            1 === k.length % 2 && Array.prototype.push.apply(k, k);
            this.ue = k;
          };
          this.setTransform = function (k, q, z, B, F, H) {
            e(arguments) && (this.resetTransform(), this.transform(k, q, z, B, F, H));
          };
          this.re = function () {
            var k = a.Matrix.invert(this.Td);
            this.Ld.concat(k);
            this.Ld.concat(a.Matrix.translated(this.we, this.xe));
            this.Ld.concat(this.Td);
          };
          this.ye = function (k) {
            var q = a.multiplyByAlpha(this.Je, this.ie);
            if (!a.getColorComponents(q)[3] || !(this.ve || this.xe || this.we)) return null;
            k = k.copy();
            k.setColor(q);
            var z = a.MaskFilter.MakeBlur(a.BlurStyle.Normal, this.ve / 2, !1);
            k.setMaskFilter(z);
            k.dispose = function () {
              z.delete();
              this.delete();
            };
            return k;
          };
          this.cf = function () {
            var k = this.Od.copy();
            k.setStyle(a.PaintStyle.Stroke);
            if (f(this.ge)) {
              var q = a.multiplyByAlpha(this.ge, this.ie);
              k.setColor(q);
            } else (q = this.ge.te(this.Td)), k.setColor(a.Color(0, 0, 0, this.ie)), k.setShader(q);
            k.setStrokeWidth(this.Ke);
            if (this.ue.length) {
              var z = a.PathEffect.MakeDash(this.ue, this.Ie);
              k.setPathEffect(z);
            }
            k.dispose = function () {
              z && z.delete();
              this.delete();
            };
            return k;
          };
          this.stroke = function (k) {
            k = k ? k.Ud : this.Qd;
            var q = this.cf(),
              z = this.ye(q);
            z && (this.Ld.save(), this.re(), this.Ld.drawPath(k, z), this.Ld.restore(), z.dispose());
            this.Ld.drawPath(k, q);
            q.dispose();
          };
          this.strokeRect = function (k, q, z, B) {
            var F = this.cf(),
              H = this.ye(F);
            H &&
              (this.Ld.save(), this.re(), this.Ld.drawRect(a.XYWHRect(k, q, z, B), H), this.Ld.restore(), H.dispose());
            this.Ld.drawRect(a.XYWHRect(k, q, z, B), F);
            F.dispose();
          };
          this.strokeText = function (k, q, z) {
            var B = this.cf();
            k = a.TextBlob.MakeFromText(k, this.me);
            var F = this.ye(B);
            F && (this.Ld.save(), this.re(), this.Ld.drawTextBlob(k, q, z, F), this.Ld.restore(), F.dispose());
            this.Ld.drawTextBlob(k, q, z, B);
            k.delete();
            B.dispose();
          };
          this.translate = function (k, q) {
            if (e(arguments)) {
              var z = a.Matrix.translated(-k, -q);
              this.Qd.transform(z);
              this.Ld.translate(k, q);
              this.Td = this.Ld.getTotalMatrix();
            }
          };
          this.transform = function (k, q, z, B, F, H) {
            k = [k, z, F, q, B, H, 0, 0, 1];
            q = a.Matrix.invert(k);
            this.Qd.transform(q);
            this.Ld.concat(k);
            this.Td = this.Ld.getTotalMatrix();
          };
          this.addHitRegion = function () {};
          this.clearHitRegions = function () {};
          this.drawFocusIfNeeded = function () {};
          this.removeHitRegion = function () {};
          this.scrollPathIntoView = function () {};
          Object.defineProperty(this, "canvas", { value: null, writable: !1 });
        }
        function C(I) {
          this.df = I;
          this.Kd = new x(I.getCanvas());
          this.Se = [];
          this.decodeImage = function (k) {
            k = a.MakeImageFromEncoded(k);
            if (!k) throw "Invalid input";
            this.Se.push(k);
            return new J(k);
          };
          this.loadFont = function (k, q) {
            k = a.Typeface.MakeTypefaceFromData(k);
            if (!k) return null;
            this.Se.push(k);
            var z = (q.style || "normal") + "|" + (q.variant || "normal") + "|" + (q.weight || "normal");
            q = q.family;
            r();
            fa[q] || (fa[q] = { "*": k });
            fa[q][z] = k;
          };
          this.makePath2D = function (k) {
            k = new ea(k);
            this.Se.push(k.Ud);
            return k;
          };
          this.getContext = function (k) {
            return "2d" === k ? this.Kd : null;
          };
          this.toDataURL = function (k, q) {
            this.df.flush();
            var z = this.df.makeImageSnapshot();
            if (z) {
              k = k || "image/png";
              var B = a.ImageFormat.PNG;
              "image/jpeg" === k && (B = a.ImageFormat.JPEG);
              if ((q = z.encodeToBytes(B, q || 0.92))) {
                z.delete();
                k = "data:" + k + ";base64,";
                if ("undefined" !== typeof Buffer) q = Buffer.from(q).toString("base64");
                else {
                  z = 0;
                  B = q.length;
                  for (var F = "", H; z < B; )
                    (H = q.slice(z, Math.min(z + 32768, B))), (F += String.fromCharCode.apply(null, H)), (z += 32768);
                  q = btoa(F);
                }
                return k + q;
              }
            }
          };
          this.dispose = function () {
            this.Kd.le();
            this.Se.forEach(function (k) {
              k.delete();
            });
            this.df.dispose();
          };
        }
        function J(I) {
          this.width = I.width();
          this.height = I.height();
          this.naturalWidth = this.width;
          this.naturalHeight = this.height;
          this.tf = function () {
            return I;
          };
        }
        function N(I, k, q) {
          if (!k || 0 === q) throw new TypeError("invalid dimensions, width and height must be non-zero");
          if (I.length % 4) throw new TypeError("arr must be a multiple of 4");
          q = q || I.length / (4 * k);
          Object.defineProperty(this, "data", { value: I, writable: !1 });
          Object.defineProperty(this, "height", { value: q, writable: !1 });
          Object.defineProperty(this, "width", { value: k, writable: !1 });
        }
        function P(I, k, q, z) {
          this.Wd = null;
          this.ce = [];
          this.Zd = [];
          this.addColorStop = function (B, F) {
            if (0 > B || 1 < B || !isFinite(B)) throw "offset must be between 0 and 1 inclusively";
            F = g(F);
            var H = this.Zd.indexOf(B);
            if (-1 !== H) this.ce[H] = F;
            else {
              for (H = 0; H < this.Zd.length && !(this.Zd[H] > B); H++);
              this.Zd.splice(H, 0, B);
              this.ce.splice(H, 0, F);
            }
          };
          this.se = function () {
            var B = new P(I, k, q, z);
            B.ce = this.ce.slice();
            B.Zd = this.Zd.slice();
            return B;
          };
          this.le = function () {
            this.Wd && (this.Wd.delete(), (this.Wd = null));
          };
          this.te = function (B) {
            var F = [I, k, q, z];
            a.Matrix.mapPoints(B, F);
            B = F[0];
            var H = F[1],
              O = F[2];
            F = F[3];
            this.le();
            return (this.Wd = a.Shader.MakeLinearGradient([B, H], [O, F], this.ce, this.Zd, a.TileMode.Clamp));
          };
        }
        function Y(I, k, q, z, B, F) {
          if (e([k, q, z, B, F])) {
            if (0 > F) throw "radii cannot be negative";
            I.isEmpty() && I.moveTo(k, q);
            I.arcToTangent(k, q, z, B, F);
          }
        }
        function W(I) {
          if (!I.isEmpty()) {
            var k = I.getBounds();
            (k[3] - k[1] || k[2] - k[0]) && I.close();
          }
        }
        function t(I, k, q, z, B, F, H) {
          H = ((H - F) / Math.PI) * 180;
          F = (F / Math.PI) * 180;
          k = a.LTRBRect(k - z, q - B, k + z, q + B);
          1e-5 > Math.abs(Math.abs(H) - 360)
            ? ((q = H / 2), I.arcToOval(k, F, q, !1), I.arcToOval(k, F + q, q, !1))
            : I.arcToOval(k, F, H, !1);
        }
        function D(I, k, q, z, B, F, H, O, Z) {
          if (e([k, q, z, B, F, H, O])) {
            if (0 > z || 0 > B) throw "radii cannot be negative";
            var na = 2 * Math.PI,
              bb = H % na;
            0 > bb && (bb += na);
            var cb = bb - H;
            H = bb;
            O += cb;
            !Z && O - H >= na
              ? (O = H + na)
              : Z && H - O >= na
                ? (O = H - na)
                : !Z && H > O
                  ? (O = H + (na - ((H - O) % na)))
                  : Z && H < O && (O = H - (na - ((O - H) % na)));
            F
              ? ((Z = a.Matrix.rotated(F, k, q)),
                (F = a.Matrix.rotated(-F, k, q)),
                I.transform(F),
                t(I, k, q, z, B, H, O),
                I.transform(Z))
              : t(I, k, q, z, B, H, O);
          }
        }
        function U(I, k, q) {
          e([k, q]) && (I.isEmpty() && I.moveTo(k, q), I.lineTo(k, q));
        }
        function ea(I) {
          this.Ud = null;
          this.Ud = "string" === typeof I ? a.Path.MakeFromSVGString(I) : I && I.af ? I.Ud.copy() : new a.Path();
          this.af = function () {
            return this.Ud;
          };
          this.addPath = function (k, q) {
            q || (q = { a: 1, c: 0, e: 0, b: 0, d: 1, f: 0 });
            this.Ud.addPath(k.Ud, [q.a, q.c, q.e, q.b, q.d, q.f]);
          };
          this.arc = function (k, q, z, B, F, H) {
            D(this.Ud, k, q, z, z, 0, B, F, H);
          };
          this.arcTo = function (k, q, z, B, F) {
            Y(this.Ud, k, q, z, B, F);
          };
          this.bezierCurveTo = function (k, q, z, B, F, H) {
            var O = this.Ud;
            e([k, q, z, B, F, H]) && (O.isEmpty() && O.moveTo(k, q), O.cubicTo(k, q, z, B, F, H));
          };
          this.closePath = function () {
            W(this.Ud);
          };
          this.ellipse = function (k, q, z, B, F, H, O, Z) {
            D(this.Ud, k, q, z, B, F, H, O, Z);
          };
          this.lineTo = function (k, q) {
            U(this.Ud, k, q);
          };
          this.moveTo = function (k, q) {
            var z = this.Ud;
            e([k, q]) && z.moveTo(k, q);
          };
          this.quadraticCurveTo = function (k, q, z, B) {
            var F = this.Ud;
            e([k, q, z, B]) && (F.isEmpty() && F.moveTo(k, q), F.quadTo(k, q, z, B));
          };
          this.rect = function (k, q, z, B) {
            var F = this.Ud;
            k = a.XYWHRect(k, q, z, B);
            e(k) && F.addRect(k);
          };
        }
        function ka(I, k) {
          this.Wd = null;
          I instanceof J && (I = I.tf());
          this.Ef = I;
          this._transform = a.Matrix.identity();
          "" === k && (k = "repeat");
          switch (k) {
            case "repeat-x":
              this.ze = a.TileMode.Repeat;
              this.Ae = a.TileMode.Decal;
              break;
            case "repeat-y":
              this.ze = a.TileMode.Decal;
              this.Ae = a.TileMode.Repeat;
              break;
            case "repeat":
              this.Ae = this.ze = a.TileMode.Repeat;
              break;
            case "no-repeat":
              this.Ae = this.ze = a.TileMode.Decal;
              break;
            default:
              throw "invalid repetition mode " + k;
          }
          this.setTransform = function (q) {
            q = [q.a, q.c, q.e, q.b, q.d, q.f, 0, 0, 1];
            e(q) && (this._transform = q);
          };
          this.se = function () {
            var q = new ka();
            q.ze = this.ze;
            q.Ae = this.Ae;
            return q;
          };
          this.le = function () {
            this.Wd && (this.Wd.delete(), (this.Wd = null));
          };
          this.te = function () {
            this.le();
            return (this.Wd = this.Ef.makeShaderCubic(this.ze, this.Ae, 1 / 3, 1 / 3, this._transform));
          };
        }
        function ua(I, k, q, z, B, F) {
          this.Wd = null;
          this.ce = [];
          this.Zd = [];
          this.addColorStop = function (H, O) {
            if (0 > H || 1 < H || !isFinite(H)) throw "offset must be between 0 and 1 inclusively";
            O = g(O);
            var Z = this.Zd.indexOf(H);
            if (-1 !== Z) this.ce[Z] = O;
            else {
              for (Z = 0; Z < this.Zd.length && !(this.Zd[Z] > H); Z++);
              this.Zd.splice(Z, 0, H);
              this.ce.splice(Z, 0, O);
            }
          };
          this.se = function () {
            var H = new ua(I, k, q, z, B, F);
            H.ce = this.ce.slice();
            H.Zd = this.Zd.slice();
            return H;
          };
          this.le = function () {
            this.Wd && (this.Wd.delete(), (this.Wd = null));
          };
          this.te = function (H) {
            var O = [I, k, z, B];
            a.Matrix.mapPoints(H, O);
            var Z = O[0],
              na = O[1],
              bb = O[2];
            O = O[3];
            var cb = (Math.abs(H[0]) + Math.abs(H[4])) / 2;
            H = q * cb;
            cb *= F;
            this.le();
            return (this.Wd = a.Shader.MakeTwoPointConicalGradient(
              [Z, na],
              H,
              [bb, O],
              cb,
              this.ce,
              this.Zd,
              a.TileMode.Clamp,
            ));
          };
        }
        a._testing = {};
        var xa = {
          aliceblue: Float32Array.of(0.941, 0.973, 1, 1),
          antiquewhite: Float32Array.of(0.98, 0.922, 0.843, 1),
          aqua: Float32Array.of(0, 1, 1, 1),
          aquamarine: Float32Array.of(0.498, 1, 0.831, 1),
          azure: Float32Array.of(0.941, 1, 1, 1),
          beige: Float32Array.of(0.961, 0.961, 0.863, 1),
          bisque: Float32Array.of(1, 0.894, 0.769, 1),
          black: Float32Array.of(0, 0, 0, 1),
          blanchedalmond: Float32Array.of(1, 0.922, 0.804, 1),
          blue: Float32Array.of(0, 0, 1, 1),
          blueviolet: Float32Array.of(0.541, 0.169, 0.886, 1),
          brown: Float32Array.of(0.647, 0.165, 0.165, 1),
          burlywood: Float32Array.of(0.871, 0.722, 0.529, 1),
          cadetblue: Float32Array.of(0.373, 0.62, 0.627, 1),
          chartreuse: Float32Array.of(0.498, 1, 0, 1),
          chocolate: Float32Array.of(0.824, 0.412, 0.118, 1),
          coral: Float32Array.of(1, 0.498, 0.314, 1),
          cornflowerblue: Float32Array.of(0.392, 0.584, 0.929, 1),
          cornsilk: Float32Array.of(1, 0.973, 0.863, 1),
          crimson: Float32Array.of(0.863, 0.078, 0.235, 1),
          cyan: Float32Array.of(0, 1, 1, 1),
          darkblue: Float32Array.of(0, 0, 0.545, 1),
          darkcyan: Float32Array.of(0, 0.545, 0.545, 1),
          darkgoldenrod: Float32Array.of(0.722, 0.525, 0.043, 1),
          darkgray: Float32Array.of(0.663, 0.663, 0.663, 1),
          darkgreen: Float32Array.of(0, 0.392, 0, 1),
          darkgrey: Float32Array.of(0.663, 0.663, 0.663, 1),
          darkkhaki: Float32Array.of(0.741, 0.718, 0.42, 1),
          darkmagenta: Float32Array.of(0.545, 0, 0.545, 1),
          darkolivegreen: Float32Array.of(0.333, 0.42, 0.184, 1),
          darkorange: Float32Array.of(1, 0.549, 0, 1),
          darkorchid: Float32Array.of(0.6, 0.196, 0.8, 1),
          darkred: Float32Array.of(0.545, 0, 0, 1),
          darksalmon: Float32Array.of(0.914, 0.588, 0.478, 1),
          darkseagreen: Float32Array.of(0.561, 0.737, 0.561, 1),
          darkslateblue: Float32Array.of(0.282, 0.239, 0.545, 1),
          darkslategray: Float32Array.of(0.184, 0.31, 0.31, 1),
          darkslategrey: Float32Array.of(0.184, 0.31, 0.31, 1),
          darkturquoise: Float32Array.of(0, 0.808, 0.82, 1),
          darkviolet: Float32Array.of(0.58, 0, 0.827, 1),
          deeppink: Float32Array.of(1, 0.078, 0.576, 1),
          deepskyblue: Float32Array.of(0, 0.749, 1, 1),
          dimgray: Float32Array.of(0.412, 0.412, 0.412, 1),
          dimgrey: Float32Array.of(0.412, 0.412, 0.412, 1),
          dodgerblue: Float32Array.of(0.118, 0.565, 1, 1),
          firebrick: Float32Array.of(0.698, 0.133, 0.133, 1),
          floralwhite: Float32Array.of(1, 0.98, 0.941, 1),
          forestgreen: Float32Array.of(0.133, 0.545, 0.133, 1),
          fuchsia: Float32Array.of(1, 0, 1, 1),
          gainsboro: Float32Array.of(0.863, 0.863, 0.863, 1),
          ghostwhite: Float32Array.of(0.973, 0.973, 1, 1),
          gold: Float32Array.of(1, 0.843, 0, 1),
          goldenrod: Float32Array.of(0.855, 0.647, 0.125, 1),
          gray: Float32Array.of(0.502, 0.502, 0.502, 1),
          green: Float32Array.of(0, 0.502, 0, 1),
          greenyellow: Float32Array.of(0.678, 1, 0.184, 1),
          grey: Float32Array.of(0.502, 0.502, 0.502, 1),
          honeydew: Float32Array.of(0.941, 1, 0.941, 1),
          hotpink: Float32Array.of(1, 0.412, 0.706, 1),
          indianred: Float32Array.of(0.804, 0.361, 0.361, 1),
          indigo: Float32Array.of(0.294, 0, 0.51, 1),
          ivory: Float32Array.of(1, 1, 0.941, 1),
          khaki: Float32Array.of(0.941, 0.902, 0.549, 1),
          lavender: Float32Array.of(0.902, 0.902, 0.98, 1),
          lavenderblush: Float32Array.of(1, 0.941, 0.961, 1),
          lawngreen: Float32Array.of(0.486, 0.988, 0, 1),
          lemonchiffon: Float32Array.of(1, 0.98, 0.804, 1),
          lightblue: Float32Array.of(0.678, 0.847, 0.902, 1),
          lightcoral: Float32Array.of(0.941, 0.502, 0.502, 1),
          lightcyan: Float32Array.of(0.878, 1, 1, 1),
          lightgoldenrodyellow: Float32Array.of(0.98, 0.98, 0.824, 1),
          lightgray: Float32Array.of(0.827, 0.827, 0.827, 1),
          lightgreen: Float32Array.of(0.565, 0.933, 0.565, 1),
          lightgrey: Float32Array.of(0.827, 0.827, 0.827, 1),
          lightpink: Float32Array.of(1, 0.714, 0.757, 1),
          lightsalmon: Float32Array.of(1, 0.627, 0.478, 1),
          lightseagreen: Float32Array.of(0.125, 0.698, 0.667, 1),
          lightskyblue: Float32Array.of(0.529, 0.808, 0.98, 1),
          lightslategray: Float32Array.of(0.467, 0.533, 0.6, 1),
          lightslategrey: Float32Array.of(0.467, 0.533, 0.6, 1),
          lightsteelblue: Float32Array.of(0.69, 0.769, 0.871, 1),
          lightyellow: Float32Array.of(1, 1, 0.878, 1),
          lime: Float32Array.of(0, 1, 0, 1),
          limegreen: Float32Array.of(0.196, 0.804, 0.196, 1),
          linen: Float32Array.of(0.98, 0.941, 0.902, 1),
          magenta: Float32Array.of(1, 0, 1, 1),
          maroon: Float32Array.of(0.502, 0, 0, 1),
          mediumaquamarine: Float32Array.of(0.4, 0.804, 0.667, 1),
          mediumblue: Float32Array.of(0, 0, 0.804, 1),
          mediumorchid: Float32Array.of(0.729, 0.333, 0.827, 1),
          mediumpurple: Float32Array.of(0.576, 0.439, 0.859, 1),
          mediumseagreen: Float32Array.of(0.235, 0.702, 0.443, 1),
          mediumslateblue: Float32Array.of(0.482, 0.408, 0.933, 1),
          mediumspringgreen: Float32Array.of(0, 0.98, 0.604, 1),
          mediumturquoise: Float32Array.of(0.282, 0.82, 0.8, 1),
          mediumvioletred: Float32Array.of(0.78, 0.082, 0.522, 1),
          midnightblue: Float32Array.of(0.098, 0.098, 0.439, 1),
          mintcream: Float32Array.of(0.961, 1, 0.98, 1),
          mistyrose: Float32Array.of(1, 0.894, 0.882, 1),
          moccasin: Float32Array.of(1, 0.894, 0.71, 1),
          navajowhite: Float32Array.of(1, 0.871, 0.678, 1),
          navy: Float32Array.of(0, 0, 0.502, 1),
          oldlace: Float32Array.of(0.992, 0.961, 0.902, 1),
          olive: Float32Array.of(0.502, 0.502, 0, 1),
          olivedrab: Float32Array.of(0.42, 0.557, 0.137, 1),
          orange: Float32Array.of(1, 0.647, 0, 1),
          orangered: Float32Array.of(1, 0.271, 0, 1),
          orchid: Float32Array.of(0.855, 0.439, 0.839, 1),
          palegoldenrod: Float32Array.of(0.933, 0.91, 0.667, 1),
          palegreen: Float32Array.of(0.596, 0.984, 0.596, 1),
          paleturquoise: Float32Array.of(0.686, 0.933, 0.933, 1),
          palevioletred: Float32Array.of(0.859, 0.439, 0.576, 1),
          papayawhip: Float32Array.of(1, 0.937, 0.835, 1),
          peachpuff: Float32Array.of(1, 0.855, 0.725, 1),
          peru: Float32Array.of(0.804, 0.522, 0.247, 1),
          pink: Float32Array.of(1, 0.753, 0.796, 1),
          plum: Float32Array.of(0.867, 0.627, 0.867, 1),
          powderblue: Float32Array.of(0.69, 0.878, 0.902, 1),
          purple: Float32Array.of(0.502, 0, 0.502, 1),
          rebeccapurple: Float32Array.of(0.4, 0.2, 0.6, 1),
          red: Float32Array.of(1, 0, 0, 1),
          rosybrown: Float32Array.of(0.737, 0.561, 0.561, 1),
          royalblue: Float32Array.of(0.255, 0.412, 0.882, 1),
          saddlebrown: Float32Array.of(0.545, 0.271, 0.075, 1),
          salmon: Float32Array.of(0.98, 0.502, 0.447, 1),
          sandybrown: Float32Array.of(0.957, 0.643, 0.376, 1),
          seagreen: Float32Array.of(0.18, 0.545, 0.341, 1),
          seashell: Float32Array.of(1, 0.961, 0.933, 1),
          sienna: Float32Array.of(0.627, 0.322, 0.176, 1),
          silver: Float32Array.of(0.753, 0.753, 0.753, 1),
          skyblue: Float32Array.of(0.529, 0.808, 0.922, 1),
          slateblue: Float32Array.of(0.416, 0.353, 0.804, 1),
          slategray: Float32Array.of(0.439, 0.502, 0.565, 1),
          slategrey: Float32Array.of(0.439, 0.502, 0.565, 1),
          snow: Float32Array.of(1, 0.98, 0.98, 1),
          springgreen: Float32Array.of(0, 1, 0.498, 1),
          steelblue: Float32Array.of(0.275, 0.51, 0.706, 1),
          tan: Float32Array.of(0.824, 0.706, 0.549, 1),
          teal: Float32Array.of(0, 0.502, 0.502, 1),
          thistle: Float32Array.of(0.847, 0.749, 0.847, 1),
          tomato: Float32Array.of(1, 0.388, 0.278, 1),
          transparent: Float32Array.of(0, 0, 0, 0),
          turquoise: Float32Array.of(0.251, 0.878, 0.816, 1),
          violet: Float32Array.of(0.933, 0.51, 0.933, 1),
          wheat: Float32Array.of(0.961, 0.871, 0.702, 1),
          white: Float32Array.of(1, 1, 1, 1),
          whitesmoke: Float32Array.of(0.961, 0.961, 0.961, 1),
          yellow: Float32Array.of(1, 1, 0, 1),
          yellowgreen: Float32Array.of(0.604, 0.804, 0.196, 1),
        };
        a._testing.parseColor = g;
        a._testing.colorToString = c;
        var Ea = RegExp(
            "(italic|oblique|normal|)\\s*(small-caps|normal|)\\s*(bold|bolder|lighter|[1-9]00|normal|)\\s*([\\d\\.]+)(px|pt|pc|in|cm|mm|%|em|ex|ch|rem|q)(.+)",
          ),
          fa;
        a._testing.parseFontString = l;
        a.MakeCanvas = function (I, k) {
          return (I = a.MakeSurface(I, k)) ? new C(I) : null;
        };
        a.ImageData = function () {
          if (2 === arguments.length) {
            var I = arguments[0],
              k = arguments[1];
            return new N(new Uint8ClampedArray(4 * I * k), I, k);
          }
          if (3 === arguments.length) {
            var q = arguments[0];
            if (q.prototype.constructor !== Uint8ClampedArray)
              throw new TypeError("bytes must be given as a Uint8ClampedArray");
            I = arguments[1];
            k = arguments[2];
            if (q % 4) throw new TypeError("bytes must be given in a multiple of 4");
            if (q % I) throw new TypeError("bytes must divide evenly by width");
            if (k && k !== q / (4 * I)) throw new TypeError("invalid height given");
            return new N(q, I, q / (4 * I));
          }
          throw new TypeError("invalid number of arguments - takes 2 or 3, saw " + arguments.length);
        };
      })();
    })(w);
    var qa = Object.assign({}, w),
      ra = "./this.program",
      va = (a, b) => {
        throw b;
      },
      wa = "object" == typeof window,
      ya = "function" == typeof importScripts,
      za =
        "object" == typeof process && "object" == typeof process.versions && "string" == typeof process.versions.node,
      Aa = "",
      Ba,
      Ca,
      Da;
    if (za) {
      var fs = require("fs"),
        Fa = require("path");
      Aa = ya ? Fa.dirname(Aa) + "/" : __dirname + "/";
      Ba = (a, b) => {
        a = a.startsWith("file://") ? new URL(a) : Fa.normalize(a);
        return fs.readFileSync(a, b ? void 0 : "utf8");
      };
      Da = a => {
        a = Ba(a, !0);
        a.buffer || (a = new Uint8Array(a));
        return a;
      };
      Ca = (a, b, d, f = !0) => {
        a = a.startsWith("file://") ? new URL(a) : Fa.normalize(a);
        fs.readFile(a, f ? void 0 : "utf8", (h, m) => {
          h ? d(h) : b(f ? m.buffer : m);
        });
      };
      !w.thisProgram && 1 < process.argv.length && (ra = process.argv[1].replace(/\\/g, "/"));
      process.argv.slice(2);
      va = (a, b) => {
        process.exitCode = a;
        throw b;
      };
      w.inspect = () => "[Emscripten Module object]";
    } else if (wa || ya)
      ya
        ? (Aa = self.location.href)
        : "undefined" != typeof document && document.currentScript && (Aa = document.currentScript.src),
        _scriptDir && (Aa = _scriptDir),
        0 !== Aa.indexOf("blob:") ? (Aa = Aa.substr(0, Aa.replace(/[?#].*/, "").lastIndexOf("/") + 1)) : (Aa = ""),
        (Ba = a => {
          var b = new XMLHttpRequest();
          b.open("GET", a, !1);
          b.send(null);
          return b.responseText;
        }),
        ya &&
          (Da = a => {
            var b = new XMLHttpRequest();
            b.open("GET", a, !1);
            b.responseType = "arraybuffer";
            b.send(null);
            return new Uint8Array(b.response);
          }),
        (Ca = (a, b, d) => {
          var f = new XMLHttpRequest();
          f.open("GET", a, !0);
          f.responseType = "arraybuffer";
          f.onload = () => {
            200 == f.status || (0 == f.status && f.response) ? b(f.response) : d();
          };
          f.onerror = d;
          f.send(null);
        });
    var Ga = w.print || console.log.bind(console),
      Ha = w.printErr || console.error.bind(console);
    Object.assign(w, qa);
    qa = null;
    w.thisProgram && (ra = w.thisProgram);
    w.quit && (va = w.quit);
    var Ia;
    w.wasmBinary && (Ia = w.wasmBinary);
    var noExitRuntime = w.noExitRuntime || !0;
    "object" != typeof WebAssembly && Ka("no native wasm support detected");
    var La,
      Ma,
      Pa = !1,
      Qa,
      K,
      Ra,
      Sa,
      Q,
      Ta,
      S,
      Ua;
    function Va() {
      var a = La.buffer;
      w.HEAP8 = Qa = new Int8Array(a);
      w.HEAP16 = Ra = new Int16Array(a);
      w.HEAP32 = Q = new Int32Array(a);
      w.HEAPU8 = K = new Uint8Array(a);
      w.HEAPU16 = Sa = new Uint16Array(a);
      w.HEAPU32 = Ta = new Uint32Array(a);
      w.HEAPF32 = S = new Float32Array(a);
      w.HEAPF64 = Ua = new Float64Array(a);
    }
    var Wa,
      Xa = [],
      Ya = [],
      Za = [];
    function db() {
      var a = w.preRun.shift();
      Xa.unshift(a);
    }
    var eb = 0,
      fb = null,
      gb = null;
    function Ka(a) {
      if (w.onAbort) w.onAbort(a);
      a = "Aborted(" + a + ")";
      Ha(a);
      Pa = !0;
      a = new WebAssembly.RuntimeError(a + ". Build with -sASSERTIONS for more info.");
      ba(a);
      throw a;
    }
    function hb(a) {
      return a.startsWith("data:application/octet-stream;base64,");
    }
    var mb;
    mb = "canvaskit.wasm";
    if (!hb(mb)) {
      var nb = mb;
      mb = w.locateFile ? w.locateFile(nb, Aa) : Aa + nb;
    }
    function ob(a) {
      if (a == mb && Ia) return new Uint8Array(Ia);
      if (Da) return Da(a);
      throw "both async and sync fetching of the wasm failed";
    }
    function pb(a) {
      if (!Ia && (wa || ya)) {
        if ("function" == typeof fetch && !a.startsWith("file://"))
          return fetch(a, { credentials: "same-origin" })
            .then(b => {
              if (!b.ok) throw "failed to load wasm binary file at '" + a + "'";
              return b.arrayBuffer();
            })
            .catch(() => ob(a));
        if (Ca)
          return new Promise((b, d) => {
            Ca(a, f => b(new Uint8Array(f)), d);
          });
      }
      return Promise.resolve().then(() => ob(a));
    }
    function qb(a, b, d) {
      return pb(a)
        .then(f => WebAssembly.instantiate(f, b))
        .then(f => f)
        .then(d, f => {
          Ha("failed to asynchronously prepare wasm: " + f);
          Ka(f);
        });
    }
    function rb(a, b) {
      var d = mb;
      return Ia ||
        "function" != typeof WebAssembly.instantiateStreaming ||
        hb(d) ||
        d.startsWith("file://") ||
        za ||
        "function" != typeof fetch
        ? qb(d, a, b)
        : fetch(d, { credentials: "same-origin" }).then(f =>
            WebAssembly.instantiateStreaming(f, a).then(b, function (h) {
              Ha("wasm streaming compile failed: " + h);
              Ha("falling back to ArrayBuffer instantiation");
              return qb(d, a, b);
            }),
          );
    }
    function sb(a) {
      this.name = "ExitStatus";
      this.message = `Program terminated with exit(${a})`;
      this.status = a;
    }
    var tb = a => {
        for (; 0 < a.length; ) a.shift()(w);
      },
      ub = "undefined" != typeof TextDecoder ? new TextDecoder("utf8") : void 0,
      vb = (a, b, d) => {
        var f = b + d;
        for (d = b; a[d] && !(d >= f); ) ++d;
        if (16 < d - b && a.buffer && ub) return ub.decode(a.subarray(b, d));
        for (f = ""; b < d; ) {
          var h = a[b++];
          if (h & 128) {
            var m = a[b++] & 63;
            if (192 == (h & 224)) f += String.fromCharCode(((h & 31) << 6) | m);
            else {
              var u = a[b++] & 63;
              h =
                224 == (h & 240)
                  ? ((h & 15) << 12) | (m << 6) | u
                  : ((h & 7) << 18) | (m << 12) | (u << 6) | (a[b++] & 63);
              65536 > h
                ? (f += String.fromCharCode(h))
                : ((h -= 65536), (f += String.fromCharCode(55296 | (h >> 10), 56320 | (h & 1023))));
            }
          } else f += String.fromCharCode(h);
        }
        return f;
      },
      wb = {};
    function xb(a) {
      for (; a.length; ) {
        var b = a.pop();
        a.pop()(b);
      }
    }
    function yb(a) {
      return this.fromWireType(Q[a >> 2]);
    }
    var zb = {},
      Bb = {},
      Cb = {},
      Db = void 0;
    function Eb(a) {
      throw new Db(a);
    }
    function Fb(a, b, d) {
      function f(n) {
        n = d(n);
        n.length !== a.length && Eb("Mismatched type converter count");
        for (var p = 0; p < a.length; ++p) Gb(a[p], n[p]);
      }
      a.forEach(function (n) {
        Cb[n] = b;
      });
      var h = Array(b.length),
        m = [],
        u = 0;
      b.forEach((n, p) => {
        Bb.hasOwnProperty(n)
          ? (h[p] = Bb[n])
          : (m.push(n),
            zb.hasOwnProperty(n) || (zb[n] = []),
            zb[n].push(() => {
              h[p] = Bb[n];
              ++u;
              u === m.length && f(h);
            }));
      });
      0 === m.length && f(h);
    }
    function Hb(a) {
      switch (a) {
        case 1:
          return 0;
        case 2:
          return 1;
        case 4:
          return 2;
        case 8:
          return 3;
        default:
          throw new TypeError(`Unknown type size: ${a}`);
      }
    }
    var Ib = void 0;
    function Jb(a) {
      for (var b = ""; K[a]; ) b += Ib[K[a++]];
      return b;
    }
    var Kb = void 0;
    function Lb(a) {
      throw new Kb(a);
    }
    function Mb(a, b, d = {}) {
      var f = b.name;
      a || Lb(`type "${f}" must have a positive integer typeid pointer`);
      if (Bb.hasOwnProperty(a)) {
        if (d.Wf) return;
        Lb(`Cannot register type '${f}' twice`);
      }
      Bb[a] = b;
      delete Cb[a];
      zb.hasOwnProperty(a) && ((b = zb[a]), delete zb[a], b.forEach(h => h()));
    }
    function Gb(a, b, d = {}) {
      if (!("argPackAdvance" in b)) throw new TypeError("registerType registeredInstance requires argPackAdvance");
      Mb(a, b, d);
    }
    function Nb(a) {
      Lb(a.Jd.Vd.Pd.name + " instance already deleted");
    }
    var Ob = !1;
    function Pb() {}
    function Qb(a) {
      --a.count.value;
      0 === a.count.value && (a.Yd ? a.ee.ke(a.Yd) : a.Vd.Pd.ke(a.Rd));
    }
    function Rb(a, b, d) {
      if (b === d) return a;
      if (void 0 === d.$d) return null;
      a = Rb(a, b, d.$d);
      return null === a ? null : d.Mf(a);
    }
    var Sb = {},
      Tb = [];
    function Ub() {
      for (; Tb.length; ) {
        var a = Tb.pop();
        a.Jd.Ee = !1;
        a["delete"]();
      }
    }
    var Vb = void 0,
      Wb = {};
    function cc(a, b) {
      for (void 0 === b && Lb("ptr should not be undefined"); a.$d; ) (b = a.Oe(b)), (a = a.$d);
      return Wb[b];
    }
    function dc(a, b) {
      (b.Vd && b.Rd) || Eb("makeClassHandle requires ptr and ptrType");
      !!b.ee !== !!b.Yd && Eb("Both smartPtrType and smartPtr must be specified");
      b.count = { value: 1 };
      return ec(Object.create(a, { Jd: { value: b } }));
    }
    function ec(a) {
      if ("undefined" === typeof FinalizationRegistry) return (ec = b => b), a;
      Ob = new FinalizationRegistry(b => {
        Qb(b.Jd);
      });
      ec = b => {
        var d = b.Jd;
        d.Yd && Ob.register(b, { Jd: d }, b);
        return b;
      };
      Pb = b => {
        Ob.unregister(b);
      };
      return ec(a);
    }
    function fc() {}
    function gc(a) {
      if (void 0 === a) return "_unknown";
      a = a.replace(/[^a-zA-Z0-9_]/g, "$");
      var b = a.charCodeAt(0);
      return 48 <= b && 57 >= b ? `_${a}` : a;
    }
    function hc(a, b) {
      a = gc(a);
      return {
        [a]: function () {
          return b.apply(this, arguments);
        },
      }[a];
    }
    function ic(a, b, d) {
      if (void 0 === a[b].Xd) {
        var f = a[b];
        a[b] = function () {
          a[b].Xd.hasOwnProperty(arguments.length) ||
            Lb(
              `Function '${d}' called with an invalid number of arguments (${arguments.length}) - expects one of (${a[b].Xd})!`,
            );
          return a[b].Xd[arguments.length].apply(this, arguments);
        };
        a[b].Xd = [];
        a[b].Xd[f.Ce] = f;
      }
    }
    function jc(a, b, d) {
      w.hasOwnProperty(a)
        ? ((void 0 === d || (void 0 !== w[a].Xd && void 0 !== w[a].Xd[d])) &&
            Lb(`Cannot register public name '${a}' twice`),
          ic(w, a, a),
          w.hasOwnProperty(d) &&
            Lb(`Cannot register multiple overloads of a function with the same number of arguments (${d})!`),
          (w[a].Xd[d] = b))
        : ((w[a] = b), void 0 !== d && (w[a].wg = d));
    }
    function kc(a, b, d, f, h, m, u, n) {
      this.name = a;
      this.constructor = b;
      this.Fe = d;
      this.ke = f;
      this.$d = h;
      this.Rf = m;
      this.Oe = u;
      this.Mf = n;
      this.cg = [];
    }
    function lc(a, b, d) {
      for (; b !== d; )
        b.Oe || Lb(`Expected null or instance of ${d.name}, got an instance of ${b.name}`), (a = b.Oe(a)), (b = b.$d);
      return a;
    }
    function mc(a, b) {
      if (null === b) return this.gf && Lb(`null is not a valid ${this.name}`), 0;
      b.Jd || Lb(`Cannot pass "${nc(b)}" as a ${this.name}`);
      b.Jd.Rd || Lb(`Cannot pass deleted object as a pointer of type ${this.name}`);
      return lc(b.Jd.Rd, b.Jd.Vd.Pd, this.Pd);
    }
    function oc(a, b) {
      if (null === b) {
        this.gf && Lb(`null is not a valid ${this.name}`);
        if (this.Ve) {
          var d = this.hf();
          null !== a && a.push(this.ke, d);
          return d;
        }
        return 0;
      }
      b.Jd || Lb(`Cannot pass "${nc(b)}" as a ${this.name}`);
      b.Jd.Rd || Lb(`Cannot pass deleted object as a pointer of type ${this.name}`);
      !this.Ue &&
        b.Jd.Vd.Ue &&
        Lb(`Cannot convert argument of type ${b.Jd.ee ? b.Jd.ee.name : b.Jd.Vd.name} to parameter type ${this.name}`);
      d = lc(b.Jd.Rd, b.Jd.Vd.Pd, this.Pd);
      if (this.Ve)
        switch ((void 0 === b.Jd.Yd && Lb("Passing raw pointer to smart pointer is illegal"), this.hg)) {
          case 0:
            b.Jd.ee === this
              ? (d = b.Jd.Yd)
              : Lb(
                  `Cannot convert argument of type ${b.Jd.ee ? b.Jd.ee.name : b.Jd.Vd.name} to parameter type ${
                    this.name
                  }`,
                );
            break;
          case 1:
            d = b.Jd.Yd;
            break;
          case 2:
            if (b.Jd.ee === this) d = b.Jd.Yd;
            else {
              var f = b.clone();
              d = this.dg(
                d,
                pc(function () {
                  f["delete"]();
                }),
              );
              null !== a && a.push(this.ke, d);
            }
            break;
          default:
            Lb("Unsupporting sharing policy");
        }
      return d;
    }
    function qc(a, b) {
      if (null === b) return this.gf && Lb(`null is not a valid ${this.name}`), 0;
      b.Jd || Lb(`Cannot pass "${nc(b)}" as a ${this.name}`);
      b.Jd.Rd || Lb(`Cannot pass deleted object as a pointer of type ${this.name}`);
      b.Jd.Vd.Ue && Lb(`Cannot convert argument of type ${b.Jd.Vd.name} to parameter type ${this.name}`);
      return lc(b.Jd.Rd, b.Jd.Vd.Pd, this.Pd);
    }
    function rc(a, b, d, f, h, m, u, n, p, v, E) {
      this.name = a;
      this.Pd = b;
      this.gf = d;
      this.Ue = f;
      this.Ve = h;
      this.bg = m;
      this.hg = u;
      this.vf = n;
      this.hf = p;
      this.dg = v;
      this.ke = E;
      h || void 0 !== b.$d ? (this.toWireType = oc) : ((this.toWireType = f ? mc : qc), (this.de = null));
    }
    function sc(a, b, d) {
      w.hasOwnProperty(a) || Eb("Replacing nonexistant public symbol");
      void 0 !== w[a].Xd && void 0 !== d ? (w[a].Xd[d] = b) : ((w[a] = b), (w[a].Ce = d));
    }
    var tc = (a, b) => {
      var d = [];
      return function () {
        d.length = 0;
        Object.assign(d, arguments);
        if (a.includes("j")) {
          var f = w["dynCall_" + a];
          f = d && d.length ? f.apply(null, [b].concat(d)) : f.call(null, b);
        } else f = Wa.get(b).apply(null, d);
        return f;
      };
    };
    function uc(a, b) {
      a = Jb(a);
      var d = a.includes("j") ? tc(a, b) : Wa.get(b);
      "function" != typeof d && Lb(`unknown function pointer with signature ${a}: ${b}`);
      return d;
    }
    var vc = void 0;
    function wc(a) {
      a = xc(a);
      var b = Jb(a);
      yc(a);
      return b;
    }
    function Ec(a, b) {
      function d(m) {
        h[m] || Bb[m] || (Cb[m] ? Cb[m].forEach(d) : (f.push(m), (h[m] = !0)));
      }
      var f = [],
        h = {};
      b.forEach(d);
      throw new vc(`${a}: ` + f.map(wc).join([", "]));
    }
    function Fc(a, b, d, f, h) {
      var m = b.length;
      2 > m && Lb("argTypes array size mismatch! Must at least get return value and 'this' types!");
      var u = null !== b[1] && null !== d,
        n = !1;
      for (d = 1; d < b.length; ++d)
        if (null !== b[d] && void 0 === b[d].de) {
          n = !0;
          break;
        }
      var p = "void" !== b[0].name,
        v = m - 2,
        E = Array(v),
        G = [],
        L = [];
      return function () {
        arguments.length !== v && Lb(`function ${a} called with ${arguments.length} arguments, expected ${v} args!`);
        L.length = 0;
        G.length = u ? 2 : 1;
        G[0] = h;
        if (u) {
          var y = b[1].toWireType(L, this);
          G[1] = y;
        }
        for (var M = 0; M < v; ++M) (E[M] = b[M + 2].toWireType(L, arguments[M])), G.push(E[M]);
        M = f.apply(null, G);
        if (n) xb(L);
        else
          for (var T = u ? 1 : 2; T < b.length; T++) {
            var R = 1 === T ? y : E[T - 2];
            null !== b[T].de && b[T].de(R);
          }
        y = p ? b[0].fromWireType(M) : void 0;
        return y;
      };
    }
    function Gc(a, b) {
      for (var d = [], f = 0; f < a; f++) d.push(Ta[(b + 4 * f) >> 2]);
      return d;
    }
    function Hc() {
      this.je = [void 0];
      this.sf = [];
    }
    var Ic = new Hc();
    function Jc(a) {
      a >= Ic.Ge && 0 === --Ic.get(a).wf && Ic.Bf(a);
    }
    var Kc = a => {
        a || Lb("Cannot use deleted val. handle = " + a);
        return Ic.get(a).value;
      },
      pc = a => {
        switch (a) {
          case void 0:
            return 1;
          case null:
            return 2;
          case !0:
            return 3;
          case !1:
            return 4;
          default:
            return Ic.Af({ wf: 1, value: a });
        }
      };
    function Lc(a, b, d) {
      switch (b) {
        case 0:
          return function (f) {
            return this.fromWireType((d ? Qa : K)[f]);
          };
        case 1:
          return function (f) {
            return this.fromWireType((d ? Ra : Sa)[f >> 1]);
          };
        case 2:
          return function (f) {
            return this.fromWireType((d ? Q : Ta)[f >> 2]);
          };
        default:
          throw new TypeError("Unknown integer type: " + a);
      }
    }
    function Mc(a, b) {
      var d = Bb[a];
      void 0 === d && Lb(b + " has unknown type " + wc(a));
      return d;
    }
    function nc(a) {
      if (null === a) return "null";
      var b = typeof a;
      return "object" === b || "array" === b || "function" === b ? a.toString() : "" + a;
    }
    function Nc(a, b) {
      switch (b) {
        case 2:
          return function (d) {
            return this.fromWireType(S[d >> 2]);
          };
        case 3:
          return function (d) {
            return this.fromWireType(Ua[d >> 3]);
          };
        default:
          throw new TypeError("Unknown float type: " + a);
      }
    }
    function Oc(a, b, d) {
      switch (b) {
        case 0:
          return d
            ? function (f) {
                return Qa[f];
              }
            : function (f) {
                return K[f];
              };
        case 1:
          return d
            ? function (f) {
                return Ra[f >> 1];
              }
            : function (f) {
                return Sa[f >> 1];
              };
        case 2:
          return d
            ? function (f) {
                return Q[f >> 2];
              }
            : function (f) {
                return Ta[f >> 2];
              };
        default:
          throw new TypeError("Unknown integer type: " + a);
      }
    }
    var pa = (a, b, d, f) => {
        if (!(0 < f)) return 0;
        var h = d;
        f = d + f - 1;
        for (var m = 0; m < a.length; ++m) {
          var u = a.charCodeAt(m);
          if (55296 <= u && 57343 >= u) {
            var n = a.charCodeAt(++m);
            u = (65536 + ((u & 1023) << 10)) | (n & 1023);
          }
          if (127 >= u) {
            if (d >= f) break;
            b[d++] = u;
          } else {
            if (2047 >= u) {
              if (d + 1 >= f) break;
              b[d++] = 192 | (u >> 6);
            } else {
              if (65535 >= u) {
                if (d + 2 >= f) break;
                b[d++] = 224 | (u >> 12);
              } else {
                if (d + 3 >= f) break;
                b[d++] = 240 | (u >> 18);
                b[d++] = 128 | ((u >> 12) & 63);
              }
              b[d++] = 128 | ((u >> 6) & 63);
            }
            b[d++] = 128 | (u & 63);
          }
        }
        b[d] = 0;
        return d - h;
      },
      oa = a => {
        for (var b = 0, d = 0; d < a.length; ++d) {
          var f = a.charCodeAt(d);
          127 >= f ? b++ : 2047 >= f ? (b += 2) : 55296 <= f && 57343 >= f ? ((b += 4), ++d) : (b += 3);
        }
        return b;
      },
      Pc = "undefined" != typeof TextDecoder ? new TextDecoder("utf-16le") : void 0,
      Qc = (a, b) => {
        var d = a >> 1;
        for (var f = d + b / 2; !(d >= f) && Sa[d]; ) ++d;
        d <<= 1;
        if (32 < d - a && Pc) return Pc.decode(K.subarray(a, d));
        d = "";
        for (f = 0; !(f >= b / 2); ++f) {
          var h = Ra[(a + 2 * f) >> 1];
          if (0 == h) break;
          d += String.fromCharCode(h);
        }
        return d;
      },
      Rc = (a, b, d) => {
        void 0 === d && (d = 2147483647);
        if (2 > d) return 0;
        d -= 2;
        var f = b;
        d = d < 2 * a.length ? d / 2 : a.length;
        for (var h = 0; h < d; ++h) (Ra[b >> 1] = a.charCodeAt(h)), (b += 2);
        Ra[b >> 1] = 0;
        return b - f;
      },
      Sc = a => 2 * a.length,
      Tc = (a, b) => {
        for (var d = 0, f = ""; !(d >= b / 4); ) {
          var h = Q[(a + 4 * d) >> 2];
          if (0 == h) break;
          ++d;
          65536 <= h
            ? ((h -= 65536), (f += String.fromCharCode(55296 | (h >> 10), 56320 | (h & 1023))))
            : (f += String.fromCharCode(h));
        }
        return f;
      },
      Uc = (a, b, d) => {
        void 0 === d && (d = 2147483647);
        if (4 > d) return 0;
        var f = b;
        d = f + d - 4;
        for (var h = 0; h < a.length; ++h) {
          var m = a.charCodeAt(h);
          if (55296 <= m && 57343 >= m) {
            var u = a.charCodeAt(++h);
            m = (65536 + ((m & 1023) << 10)) | (u & 1023);
          }
          Q[b >> 2] = m;
          b += 4;
          if (b + 4 > d) break;
        }
        Q[b >> 2] = 0;
        return b - f;
      },
      Vc = a => {
        for (var b = 0, d = 0; d < a.length; ++d) {
          var f = a.charCodeAt(d);
          55296 <= f && 57343 >= f && ++d;
          b += 4;
        }
        return b;
      },
      Wc = {};
    function Xc(a) {
      var b = Wc[a];
      return void 0 === b ? Jb(a) : b;
    }
    var Yc = [];
    function Zc() {
      function a(b) {
        b.$$$embind_global$$$ = b;
        var d = "object" == typeof $$$embind_global$$$ && b.$$$embind_global$$$ == b;
        d || delete b.$$$embind_global$$$;
        return d;
      }
      if ("object" == typeof globalThis) return globalThis;
      if ("object" == typeof $$$embind_global$$$) return $$$embind_global$$$;
      "object" == typeof global && a(global)
        ? ($$$embind_global$$$ = global)
        : "object" == typeof self && a(self) && ($$$embind_global$$$ = self);
      if ("object" == typeof $$$embind_global$$$) return $$$embind_global$$$;
      throw Error("unable to get global object.");
    }
    function $c(a) {
      var b = Yc.length;
      Yc.push(a);
      return b;
    }
    function ad(a, b) {
      for (var d = Array(a), f = 0; f < a; ++f) d[f] = Mc(Ta[(b + 4 * f) >> 2], "parameter " + f);
      return d;
    }
    var bd = [];
    function cd(a) {
      var b = Array(a + 1);
      return function (d, f, h) {
        b[0] = d;
        for (var m = 0; m < a; ++m) {
          var u = Mc(Ta[(f + 4 * m) >> 2], "parameter " + m);
          b[m + 1] = u.readValueFromPointer(h);
          h += u.argPackAdvance;
        }
        d = new (d.bind.apply(d, b))();
        return pc(d);
      };
    }
    var dd = {};
    function ed(a) {
      var b = a.getExtension("ANGLE_instanced_arrays");
      b &&
        ((a.vertexAttribDivisor = function (d, f) {
          b.vertexAttribDivisorANGLE(d, f);
        }),
        (a.drawArraysInstanced = function (d, f, h, m) {
          b.drawArraysInstancedANGLE(d, f, h, m);
        }),
        (a.drawElementsInstanced = function (d, f, h, m, u) {
          b.drawElementsInstancedANGLE(d, f, h, m, u);
        }));
    }
    function fd(a) {
      var b = a.getExtension("OES_vertex_array_object");
      b &&
        ((a.createVertexArray = function () {
          return b.createVertexArrayOES();
        }),
        (a.deleteVertexArray = function (d) {
          b.deleteVertexArrayOES(d);
        }),
        (a.bindVertexArray = function (d) {
          b.bindVertexArrayOES(d);
        }),
        (a.isVertexArray = function (d) {
          return b.isVertexArrayOES(d);
        }));
    }
    function gd(a) {
      var b = a.getExtension("WEBGL_draw_buffers");
      b &&
        (a.drawBuffers = function (d, f) {
          b.drawBuffersWEBGL(d, f);
        });
    }
    var hd = 1,
      jd = [],
      kd = [],
      ld = [],
      md = [],
      ha = [],
      nd = [],
      od = [],
      la = [],
      pd = [],
      qd = [],
      rd = {},
      sd = {},
      td = 4;
    function ud(a) {
      vd || (vd = a);
    }
    function ca(a) {
      for (var b = hd++, d = a.length; d < b; d++) a[d] = null;
      return b;
    }
    function ia(a, b) {
      a.Ge ||
        ((a.Ge = a.getContext),
        (a.getContext = function (f, h) {
          h = a.Ge(f, h);
          return ("webgl" == f) == h instanceof WebGLRenderingContext ? h : null;
        }));
      var d = 1 < b.majorVersion ? a.getContext("webgl2", b) : a.getContext("webgl", b);
      return d ? wd(d, b) : 0;
    }
    function wd(a, b) {
      var d = ca(la),
        f = { handle: d, attributes: b, version: b.majorVersion, fe: a };
      a.canvas && (a.canvas.zf = f);
      la[d] = f;
      ("undefined" == typeof b.Nf || b.Nf) && zd(f);
      return d;
    }
    function ja(a) {
      A = la[a];
      w.ug = X = A && A.fe;
      return !(a && !X);
    }
    function zd(a) {
      a || (a = A);
      if (!a.Xf) {
        a.Xf = !0;
        var b = a.fe;
        ed(b);
        fd(b);
        gd(b);
        b.pf = b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");
        b.uf = b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");
        2 <= a.version && (b.qf = b.getExtension("EXT_disjoint_timer_query_webgl2"));
        if (2 > a.version || !b.qf) b.qf = b.getExtension("EXT_disjoint_timer_query");
        b.vg = b.getExtension("WEBGL_multi_draw");
        (b.getSupportedExtensions() || []).forEach(function (d) {
          d.includes("lose_context") || d.includes("debug") || b.getExtension(d);
        });
      }
    }
    var A,
      vd,
      Ad = {},
      Cd = () => {
        if (!Bd) {
          var a = {
              USER: "web_user",
              LOGNAME: "web_user",
              PATH: "/",
              PWD: "/",
              HOME: "/home/web_user",
              LANG:
                (("object" == typeof navigator && navigator.languages && navigator.languages[0]) || "C").replace(
                  "-",
                  "_",
                ) + ".UTF-8",
              _: ra || "./this.program",
            },
            b;
          for (b in Ad) void 0 === Ad[b] ? delete a[b] : (a[b] = Ad[b]);
          var d = [];
          for (b in a) d.push(`${b}=${a[b]}`);
          Bd = d;
        }
        return Bd;
      },
      Bd,
      Dd = [null, [], []];
    function Ed(a) {
      X.bindVertexArray(od[a]);
    }
    function Fd(a, b) {
      for (var d = 0; d < a; d++) {
        var f = Q[(b + 4 * d) >> 2];
        X.deleteVertexArray(od[f]);
        od[f] = null;
      }
    }
    var Gd = [];
    function Hd(a, b, d, f) {
      X.drawElements(a, b, d, f);
    }
    function Id(a, b, d, f) {
      for (var h = 0; h < a; h++) {
        var m = X[d](),
          u = m && ca(f);
        m ? ((m.name = u), (f[u] = m)) : ud(1282);
        Q[(b + 4 * h) >> 2] = u;
      }
    }
    function Jd(a, b) {
      Id(a, b, "createVertexArray", od);
    }
    function Kd(a, b, d) {
      if (b) {
        var f = void 0;
        switch (a) {
          case 36346:
            f = 1;
            break;
          case 36344:
            0 != d && 1 != d && ud(1280);
            return;
          case 34814:
          case 36345:
            f = 0;
            break;
          case 34466:
            var h = X.getParameter(34467);
            f = h ? h.length : 0;
            break;
          case 33309:
            if (2 > A.version) {
              ud(1282);
              return;
            }
            f = 2 * (X.getSupportedExtensions() || []).length;
            break;
          case 33307:
          case 33308:
            if (2 > A.version) {
              ud(1280);
              return;
            }
            f = 33307 == a ? 3 : 0;
        }
        if (void 0 === f)
          switch (((h = X.getParameter(a)), typeof h)) {
            case "number":
              f = h;
              break;
            case "boolean":
              f = h ? 1 : 0;
              break;
            case "string":
              ud(1280);
              return;
            case "object":
              if (null === h)
                switch (a) {
                  case 34964:
                  case 35725:
                  case 34965:
                  case 36006:
                  case 36007:
                  case 32873:
                  case 34229:
                  case 36662:
                  case 36663:
                  case 35053:
                  case 35055:
                  case 36010:
                  case 35097:
                  case 35869:
                  case 32874:
                  case 36389:
                  case 35983:
                  case 35368:
                  case 34068:
                    f = 0;
                    break;
                  default:
                    ud(1280);
                    return;
                }
              else {
                if (
                  h instanceof Float32Array ||
                  h instanceof Uint32Array ||
                  h instanceof Int32Array ||
                  h instanceof Array
                ) {
                  for (a = 0; a < h.length; ++a)
                    switch (d) {
                      case 0:
                        Q[(b + 4 * a) >> 2] = h[a];
                        break;
                      case 2:
                        S[(b + 4 * a) >> 2] = h[a];
                        break;
                      case 4:
                        Qa[(b + a) >> 0] = h[a] ? 1 : 0;
                    }
                  return;
                }
                try {
                  f = h.name | 0;
                } catch (m) {
                  ud(1280);
                  Ha(
                    "GL_INVALID_ENUM in glGet" +
                      d +
                      "v: Unknown object returned from WebGL getParameter(" +
                      a +
                      ")! (error: " +
                      m +
                      ")",
                  );
                  return;
                }
              }
              break;
            default:
              ud(1280);
              Ha(
                "GL_INVALID_ENUM in glGet" +
                  d +
                  "v: Native code calling glGet" +
                  d +
                  "v(" +
                  a +
                  ") and it returns " +
                  h +
                  " of type " +
                  typeof h +
                  "!",
              );
              return;
          }
        switch (d) {
          case 1:
            d = f;
            Ta[b >> 2] = d;
            Ta[(b + 4) >> 2] = (d - Ta[b >> 2]) / 4294967296;
            break;
          case 0:
            Q[b >> 2] = f;
            break;
          case 2:
            S[b >> 2] = f;
            break;
          case 4:
            Qa[b >> 0] = f ? 1 : 0;
        }
      } else ud(1281);
    }
    var Md = a => {
      var b = oa(a) + 1,
        d = Ld(b);
      d && pa(a, K, d, b);
      return d;
    };
    function Nd(a) {
      return "]" == a.slice(-1) && a.lastIndexOf("[");
    }
    function Od(a) {
      a -= 5120;
      return 0 == a
        ? Qa
        : 1 == a
          ? K
          : 2 == a
            ? Ra
            : 4 == a
              ? Q
              : 6 == a
                ? S
                : 5 == a || 28922 == a || 28520 == a || 30779 == a || 30782 == a
                  ? Ta
                  : Sa;
    }
    function Pd(a, b, d, f, h) {
      a = Od(a);
      var m = 31 - Math.clz32(a.BYTES_PER_ELEMENT),
        u = td;
      return a.subarray(
        h >> m,
        (h +
          f *
            ((d *
              ({ 5: 3, 6: 4, 8: 2, 29502: 3, 29504: 4, 26917: 2, 26918: 2, 29846: 3, 29847: 4 }[b - 6402] || 1) *
              (1 << m) +
              u -
              1) &
              -u)) >>
          m,
      );
    }
    function Qd(a) {
      var b = X.Kf;
      if (b) {
        var d = b.Ne[a];
        "number" == typeof d && (b.Ne[a] = d = X.getUniformLocation(b, b.xf[a] + (0 < d ? "[" + d + "]" : "")));
        return d;
      }
      ud(1282);
    }
    var Rd = [],
      Sd = [],
      Td = a => 0 === a % 4 && (0 !== a % 100 || 0 === a % 400),
      Ud = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      Vd = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    function Wd(a) {
      var b = Array(oa(a) + 1);
      pa(a, b, 0, b.length);
      return b;
    }
    var Xd = (a, b, d, f) => {
      function h(y, M, T) {
        for (y = "number" == typeof y ? y.toString() : y || ""; y.length < M; ) y = T[0] + y;
        return y;
      }
      function m(y, M) {
        return h(y, M, "0");
      }
      function u(y, M) {
        function T(ta) {
          return 0 > ta ? -1 : 0 < ta ? 1 : 0;
        }
        var R;
        0 === (R = T(y.getFullYear() - M.getFullYear())) &&
          0 === (R = T(y.getMonth() - M.getMonth())) &&
          (R = T(y.getDate() - M.getDate()));
        return R;
      }
      function n(y) {
        switch (y.getDay()) {
          case 0:
            return new Date(y.getFullYear() - 1, 11, 29);
          case 1:
            return y;
          case 2:
            return new Date(y.getFullYear(), 0, 3);
          case 3:
            return new Date(y.getFullYear(), 0, 2);
          case 4:
            return new Date(y.getFullYear(), 0, 1);
          case 5:
            return new Date(y.getFullYear() - 1, 11, 31);
          case 6:
            return new Date(y.getFullYear() - 1, 11, 30);
        }
      }
      function p(y) {
        var M = y.oe;
        for (y = new Date(new Date(y.pe + 1900, 0, 1).getTime()); 0 < M; ) {
          var T = y.getMonth(),
            R = (Td(y.getFullYear()) ? Ud : Vd)[T];
          if (M > R - y.getDate())
            (M -= R - y.getDate() + 1),
              y.setDate(1),
              11 > T ? y.setMonth(T + 1) : (y.setMonth(0), y.setFullYear(y.getFullYear() + 1));
          else {
            y.setDate(y.getDate() + M);
            break;
          }
        }
        T = new Date(y.getFullYear() + 1, 0, 4);
        M = n(new Date(y.getFullYear(), 0, 4));
        T = n(T);
        return 0 >= u(M, y) ? (0 >= u(T, y) ? y.getFullYear() + 1 : y.getFullYear()) : y.getFullYear() - 1;
      }
      var v = Q[(f + 40) >> 2];
      f = {
        pg: Q[f >> 2],
        og: Q[(f + 4) >> 2],
        Ze: Q[(f + 8) >> 2],
        jf: Q[(f + 12) >> 2],
        $e: Q[(f + 16) >> 2],
        pe: Q[(f + 20) >> 2],
        he: Q[(f + 24) >> 2],
        oe: Q[(f + 28) >> 2],
        yg: Q[(f + 32) >> 2],
        ng: Q[(f + 36) >> 2],
        qg: v ? (v ? vb(K, v) : "") : "",
      };
      d = d ? vb(K, d) : "";
      v = {
        "%c": "%a %b %d %H:%M:%S %Y",
        "%D": "%m/%d/%y",
        "%F": "%Y-%m-%d",
        "%h": "%b",
        "%r": "%I:%M:%S %p",
        "%R": "%H:%M",
        "%T": "%H:%M:%S",
        "%x": "%m/%d/%y",
        "%X": "%H:%M:%S",
        "%Ec": "%c",
        "%EC": "%C",
        "%Ex": "%m/%d/%y",
        "%EX": "%H:%M:%S",
        "%Ey": "%y",
        "%EY": "%Y",
        "%Od": "%d",
        "%Oe": "%e",
        "%OH": "%H",
        "%OI": "%I",
        "%Om": "%m",
        "%OM": "%M",
        "%OS": "%S",
        "%Ou": "%u",
        "%OU": "%U",
        "%OV": "%V",
        "%Ow": "%w",
        "%OW": "%W",
        "%Oy": "%y",
      };
      for (var E in v) d = d.replace(new RegExp(E, "g"), v[E]);
      var G = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
        L = "January February March April May June July August September October November December".split(" ");
      v = {
        "%a": y => G[y.he].substring(0, 3),
        "%A": y => G[y.he],
        "%b": y => L[y.$e].substring(0, 3),
        "%B": y => L[y.$e],
        "%C": y => m(((y.pe + 1900) / 100) | 0, 2),
        "%d": y => m(y.jf, 2),
        "%e": y => h(y.jf, 2, " "),
        "%g": y => p(y).toString().substring(2),
        "%G": y => p(y),
        "%H": y => m(y.Ze, 2),
        "%I": y => {
          y = y.Ze;
          0 == y ? (y = 12) : 12 < y && (y -= 12);
          return m(y, 2);
        },
        "%j": y => {
          for (var M = 0, T = 0; T <= y.$e - 1; M += (Td(y.pe + 1900) ? Ud : Vd)[T++]);
          return m(y.jf + M, 3);
        },
        "%m": y => m(y.$e + 1, 2),
        "%M": y => m(y.og, 2),
        "%n": () => "\n",
        "%p": y => (0 <= y.Ze && 12 > y.Ze ? "AM" : "PM"),
        "%S": y => m(y.pg, 2),
        "%t": () => "\t",
        "%u": y => y.he || 7,
        "%U": y => m(Math.floor((y.oe + 7 - y.he) / 7), 2),
        "%V": y => {
          var M = Math.floor((y.oe + 7 - ((y.he + 6) % 7)) / 7);
          2 >= (y.he + 371 - y.oe - 2) % 7 && M++;
          if (M) 53 == M && ((T = (y.he + 371 - y.oe) % 7), 4 == T || (3 == T && Td(y.pe)) || (M = 1));
          else {
            M = 52;
            var T = (y.he + 7 - y.oe - 1) % 7;
            (4 == T || (5 == T && Td((y.pe % 400) - 1))) && M++;
          }
          return m(M, 2);
        },
        "%w": y => y.he,
        "%W": y => m(Math.floor((y.oe + 7 - ((y.he + 6) % 7)) / 7), 2),
        "%y": y => (y.pe + 1900).toString().substring(2),
        "%Y": y => y.pe + 1900,
        "%z": y => {
          y = y.ng;
          var M = 0 <= y;
          y = Math.abs(y) / 60;
          return (M ? "+" : "-") + String("0000" + ((y / 60) * 100 + (y % 60))).slice(-4);
        },
        "%Z": y => y.qg,
        "%%": () => "%",
      };
      d = d.replace(/%%/g, "\x00\x00");
      for (E in v) d.includes(E) && (d = d.replace(new RegExp(E, "g"), v[E](f)));
      d = d.replace(/\0\0/g, "%");
      E = Wd(d);
      if (E.length > b) return 0;
      Qa.set(E, a);
      return E.length - 1;
    };
    Db = w.InternalError = class extends Error {
      constructor(a) {
        super(a);
        this.name = "InternalError";
      }
    };
    for (var Yd = Array(256), Zd = 0; 256 > Zd; ++Zd) Yd[Zd] = String.fromCharCode(Zd);
    Ib = Yd;
    Kb = w.BindingError = class extends Error {
      constructor(a) {
        super(a);
        this.name = "BindingError";
      }
    };
    fc.prototype.isAliasOf = function (a) {
      if (!(this instanceof fc && a instanceof fc)) return !1;
      var b = this.Jd.Vd.Pd,
        d = this.Jd.Rd,
        f = a.Jd.Vd.Pd;
      for (a = a.Jd.Rd; b.$d; ) (d = b.Oe(d)), (b = b.$d);
      for (; f.$d; ) (a = f.Oe(a)), (f = f.$d);
      return b === f && d === a;
    };
    fc.prototype.clone = function () {
      this.Jd.Rd || Nb(this);
      if (this.Jd.Me) return (this.Jd.count.value += 1), this;
      var a = ec,
        b = Object,
        d = b.create,
        f = Object.getPrototypeOf(this),
        h = this.Jd;
      a = a(
        d.call(b, f, { Jd: { value: { count: h.count, Ee: h.Ee, Me: h.Me, Rd: h.Rd, Vd: h.Vd, Yd: h.Yd, ee: h.ee } } }),
      );
      a.Jd.count.value += 1;
      a.Jd.Ee = !1;
      return a;
    };
    fc.prototype["delete"] = function () {
      this.Jd.Rd || Nb(this);
      this.Jd.Ee && !this.Jd.Me && Lb("Object already scheduled for deletion");
      Pb(this);
      Qb(this.Jd);
      this.Jd.Me || ((this.Jd.Yd = void 0), (this.Jd.Rd = void 0));
    };
    fc.prototype.isDeleted = function () {
      return !this.Jd.Rd;
    };
    fc.prototype.deleteLater = function () {
      this.Jd.Rd || Nb(this);
      this.Jd.Ee && !this.Jd.Me && Lb("Object already scheduled for deletion");
      Tb.push(this);
      1 === Tb.length && Vb && Vb(Ub);
      this.Jd.Ee = !0;
      return this;
    };
    w.getInheritedInstanceCount = function () {
      return Object.keys(Wb).length;
    };
    w.getLiveInheritedInstances = function () {
      var a = [],
        b;
      for (b in Wb) Wb.hasOwnProperty(b) && a.push(Wb[b]);
      return a;
    };
    w.flushPendingDeletes = Ub;
    w.setDelayFunction = function (a) {
      Vb = a;
      Tb.length && Vb && Vb(Ub);
    };
    rc.prototype.Sf = function (a) {
      this.vf && (a = this.vf(a));
      return a;
    };
    rc.prototype.nf = function (a) {
      this.ke && this.ke(a);
    };
    rc.prototype.argPackAdvance = 8;
    rc.prototype.readValueFromPointer = yb;
    rc.prototype.deleteObject = function (a) {
      if (null !== a) a["delete"]();
    };
    rc.prototype.fromWireType = function (a) {
      function b() {
        return this.Ve ? dc(this.Pd.Fe, { Vd: this.bg, Rd: d, ee: this, Yd: a }) : dc(this.Pd.Fe, { Vd: this, Rd: a });
      }
      var d = this.Sf(a);
      if (!d) return this.nf(a), null;
      var f = cc(this.Pd, d);
      if (void 0 !== f) {
        if (0 === f.Jd.count.value) return (f.Jd.Rd = d), (f.Jd.Yd = a), f.clone();
        f = f.clone();
        this.nf(a);
        return f;
      }
      f = this.Pd.Rf(d);
      f = Sb[f];
      if (!f) return b.call(this);
      f = this.Ue ? f.If : f.pointerType;
      var h = Rb(d, this.Pd, f.Pd);
      return null === h
        ? b.call(this)
        : this.Ve
          ? dc(f.Pd.Fe, { Vd: f, Rd: h, ee: this, Yd: a })
          : dc(f.Pd.Fe, { Vd: f, Rd: h });
    };
    vc = w.UnboundTypeError = (function (a, b) {
      var d = hc(b, function (f) {
        this.name = b;
        this.message = f;
        f = Error(f).stack;
        void 0 !== f && (this.stack = this.toString() + "\n" + f.replace(/^Error(:[^\n]*)?\n/, ""));
      });
      d.prototype = Object.create(a.prototype);
      d.prototype.constructor = d;
      d.prototype.toString = function () {
        return void 0 === this.message ? this.name : `${this.name}: ${this.message}`;
      };
      return d;
    })(Error, "UnboundTypeError");
    Object.assign(Hc.prototype, {
      get(a) {
        return this.je[a];
      },
      has(a) {
        return void 0 !== this.je[a];
      },
      Af(a) {
        var b = this.sf.pop() || this.je.length;
        this.je[b] = a;
        return b;
      },
      Bf(a) {
        this.je[a] = void 0;
        this.sf.push(a);
      },
    });
    Ic.je.push({ value: void 0 }, { value: null }, { value: !0 }, { value: !1 });
    Ic.Ge = Ic.je.length;
    w.count_emval_handles = function () {
      for (var a = 0, b = Ic.Ge; b < Ic.je.length; ++b) void 0 !== Ic.je[b] && ++a;
      return a;
    };
    for (var X, $d = 0; 32 > $d; ++$d) Gd.push(Array($d));
    var ae = new Float32Array(288);
    for ($d = 0; 288 > $d; ++$d) Rd[$d] = ae.subarray(0, $d + 1);
    var be = new Int32Array(288);
    for ($d = 0; 288 > $d; ++$d) Sd[$d] = be.subarray(0, $d + 1);
    var re = {
      U: function () {
        return 0;
      },
      Bb: () => {},
      Db: function () {
        return 0;
      },
      yb: () => {},
      zb: () => {},
      V: function () {},
      Ab: () => {},
      E: function (a) {
        var b = wb[a];
        delete wb[a];
        var d = b.hf,
          f = b.ke,
          h = b.rf,
          m = h.map(u => u.Vf).concat(h.map(u => u.fg));
        Fb([a], m, u => {
          var n = {};
          h.forEach((p, v) => {
            var E = u[v],
              G = p.Tf,
              L = p.Uf,
              y = u[v + h.length],
              M = p.eg,
              T = p.gg;
            n[p.Of] = {
              read: R => E.fromWireType(G(L, R)),
              write: (R, ta) => {
                var ma = [];
                M(T, R, y.toWireType(ma, ta));
                xb(ma);
              },
            };
          });
          return [
            {
              name: b.name,
              fromWireType: function (p) {
                var v = {},
                  E;
                for (E in n) v[E] = n[E].read(p);
                f(p);
                return v;
              },
              toWireType: function (p, v) {
                for (var E in n) if (!(E in v)) throw new TypeError(`Missing field: "${E}"`);
                var G = d();
                for (E in n) n[E].write(G, v[E]);
                null !== p && p.push(f, G);
                return G;
              },
              argPackAdvance: 8,
              readValueFromPointer: yb,
              de: f,
            },
          ];
        });
      },
      qb: function () {},
      Hb: function (a, b, d, f, h) {
        var m = Hb(d);
        b = Jb(b);
        Gb(a, {
          name: b,
          fromWireType: function (u) {
            return !!u;
          },
          toWireType: function (u, n) {
            return n ? f : h;
          },
          argPackAdvance: 8,
          readValueFromPointer: function (u) {
            if (1 === d) var n = Qa;
            else if (2 === d) n = Ra;
            else if (4 === d) n = Q;
            else throw new TypeError("Unknown boolean type size: " + b);
            return this.fromWireType(n[u >> m]);
          },
          de: null,
        });
      },
      o: function (a, b, d, f, h, m, u, n, p, v, E, G, L) {
        E = Jb(E);
        m = uc(h, m);
        n && (n = uc(u, n));
        v && (v = uc(p, v));
        L = uc(G, L);
        var y = gc(E);
        jc(y, function () {
          Ec(`Cannot construct ${E} due to unbound types`, [f]);
        });
        Fb([a, b, d], f ? [f] : [], function (M) {
          M = M[0];
          if (f) {
            var T = M.Pd;
            var R = T.Fe;
          } else R = fc.prototype;
          M = hc(y, function () {
            if (Object.getPrototypeOf(this) !== ta) throw new Kb("Use 'new' to construct " + E);
            if (void 0 === ma.ne) throw new Kb(E + " has no accessible constructor");
            var jb = ma.ne[arguments.length];
            if (void 0 === jb)
              throw new Kb(
                `Tried to invoke ctor of ${E} with invalid number of parameters (${
                  arguments.length
                }) - expected (${Object.keys(ma.ne).toString()}) parameters instead!`,
              );
            return jb.apply(this, arguments);
          });
          var ta = Object.create(R, { constructor: { value: M } });
          M.prototype = ta;
          var ma = new kc(E, M, ta, L, T, m, n, v);
          ma.$d && (void 0 === ma.$d.Pe && (ma.$d.Pe = []), ma.$d.Pe.push(ma));
          T = new rc(E, ma, !0, !1, !1);
          R = new rc(E + "*", ma, !1, !1, !1);
          var ib = new rc(E + " const*", ma, !1, !0, !1);
          Sb[a] = { pointerType: R, If: ib };
          sc(y, M);
          return [T, R, ib];
        });
      },
      i: function (a, b, d, f, h, m, u) {
        var n = Gc(d, f);
        b = Jb(b);
        m = uc(h, m);
        Fb([], [a], function (p) {
          function v() {
            Ec(`Cannot call ${E} due to unbound types`, n);
          }
          p = p[0];
          var E = `${p.name}.${b}`;
          b.startsWith("@@") && (b = Symbol[b.substring(2)]);
          var G = p.Pd.constructor;
          void 0 === G[b] ? ((v.Ce = d - 1), (G[b] = v)) : (ic(G, b, E), (G[b].Xd[d - 1] = v));
          Fb([], n, function (L) {
            L = [L[0], null].concat(L.slice(1));
            L = Fc(E, L, null, m, u);
            void 0 === G[b].Xd ? ((L.Ce = d - 1), (G[b] = L)) : (G[b].Xd[d - 1] = L);
            if (p.Pd.Pe) for (const y of p.Pd.Pe) y.constructor.hasOwnProperty(b) || (y.constructor[b] = L);
            return [];
          });
          return [];
        });
      },
      G: function (a, b, d, f, h, m) {
        var u = Gc(b, d);
        h = uc(f, h);
        Fb([], [a], function (n) {
          n = n[0];
          var p = `constructor ${n.name}`;
          void 0 === n.Pd.ne && (n.Pd.ne = []);
          if (void 0 !== n.Pd.ne[b - 1])
            throw new Kb(
              `Cannot register multiple constructors with identical number of parameters (${b - 1}) for class '${
                n.name
              }'! Overload resolution is currently only performed using the parameter count, not actual type info!`,
            );
          n.Pd.ne[b - 1] = () => {
            Ec(`Cannot construct ${n.name} due to unbound types`, u);
          };
          Fb([], u, function (v) {
            v.splice(1, 0, null);
            n.Pd.ne[b - 1] = Fc(p, v, null, h, m);
            return [];
          });
          return [];
        });
      },
      b: function (a, b, d, f, h, m, u, n) {
        var p = Gc(d, f);
        b = Jb(b);
        m = uc(h, m);
        Fb([], [a], function (v) {
          function E() {
            Ec(`Cannot call ${G} due to unbound types`, p);
          }
          v = v[0];
          var G = `${v.name}.${b}`;
          b.startsWith("@@") && (b = Symbol[b.substring(2)]);
          n && v.Pd.cg.push(b);
          var L = v.Pd.Fe,
            y = L[b];
          void 0 === y || (void 0 === y.Xd && y.className !== v.name && y.Ce === d - 2)
            ? ((E.Ce = d - 2), (E.className = v.name), (L[b] = E))
            : (ic(L, b, G), (L[b].Xd[d - 2] = E));
          Fb([], p, function (M) {
            M = Fc(G, M, v, m, u);
            void 0 === L[b].Xd ? ((M.Ce = d - 2), (L[b] = M)) : (L[b].Xd[d - 2] = M);
            return [];
          });
          return [];
        });
      },
      u: function (a, b, d) {
        a = Jb(a);
        Fb([], [b], function (f) {
          f = f[0];
          w[a] = f.fromWireType(d);
          return [];
        });
      },
      Gb: function (a, b) {
        b = Jb(b);
        Gb(a, {
          name: b,
          fromWireType: function (d) {
            var f = Kc(d);
            Jc(d);
            return f;
          },
          toWireType: function (d, f) {
            return pc(f);
          },
          argPackAdvance: 8,
          readValueFromPointer: yb,
          de: null,
        });
      },
      l: function (a, b, d, f) {
        function h() {}
        d = Hb(d);
        b = Jb(b);
        h.values = {};
        Gb(a, {
          name: b,
          constructor: h,
          fromWireType: function (m) {
            return this.constructor.values[m];
          },
          toWireType: function (m, u) {
            return u.value;
          },
          argPackAdvance: 8,
          readValueFromPointer: Lc(b, d, f),
          de: null,
        });
        jc(b, h);
      },
      d: function (a, b, d) {
        var f = Mc(a, "enum");
        b = Jb(b);
        a = f.constructor;
        f = Object.create(f.constructor.prototype, {
          value: { value: d },
          constructor: { value: hc(`${f.name}_${b}`, function () {}) },
        });
        a.values[d] = f;
        a[b] = f;
      },
      X: function (a, b, d) {
        d = Hb(d);
        b = Jb(b);
        Gb(a, {
          name: b,
          fromWireType: function (f) {
            return f;
          },
          toWireType: function (f, h) {
            return h;
          },
          argPackAdvance: 8,
          readValueFromPointer: Nc(b, d),
          de: null,
        });
      },
      y: function (a, b, d, f, h, m) {
        var u = Gc(b, d);
        a = Jb(a);
        h = uc(f, h);
        jc(
          a,
          function () {
            Ec(`Cannot call ${a} due to unbound types`, u);
          },
          b - 1,
        );
        Fb([], u, function (n) {
          n = [n[0], null].concat(n.slice(1));
          sc(a, Fc(a, n, null, h, m), b - 1);
          return [];
        });
      },
      I: function (a, b, d, f, h) {
        b = Jb(b);
        -1 === h && (h = 4294967295);
        h = Hb(d);
        var m = n => n;
        if (0 === f) {
          var u = 32 - 8 * d;
          m = n => (n << u) >>> u;
        }
        d = b.includes("unsigned")
          ? function (n, p) {
              return p >>> 0;
            }
          : function (n, p) {
              return p;
            };
        Gb(a, {
          name: b,
          fromWireType: m,
          toWireType: d,
          argPackAdvance: 8,
          readValueFromPointer: Oc(b, h, 0 !== f),
          de: null,
        });
      },
      v: function (a, b, d) {
        function f(m) {
          m >>= 2;
          var u = Ta;
          return new h(u.buffer, u[m + 1], u[m]);
        }
        var h = [Int8Array, Uint8Array, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array][
          b
        ];
        d = Jb(d);
        Gb(a, { name: d, fromWireType: f, argPackAdvance: 8, readValueFromPointer: f }, { Wf: !0 });
      },
      s: function (a, b, d, f, h, m, u, n, p, v, E, G) {
        d = Jb(d);
        m = uc(h, m);
        n = uc(u, n);
        v = uc(p, v);
        G = uc(E, G);
        Fb([a], [b], function (L) {
          L = L[0];
          return [new rc(d, L.Pd, !1, !1, !0, L, f, m, n, v, G)];
        });
      },
      W: function (a, b) {
        b = Jb(b);
        var d = "std::string" === b;
        Gb(a, {
          name: b,
          fromWireType: function (f) {
            var h = Ta[f >> 2],
              m = f + 4;
            if (d)
              for (var u = m, n = 0; n <= h; ++n) {
                var p = m + n;
                if (n == h || 0 == K[p]) {
                  u = u ? vb(K, u, p - u) : "";
                  if (void 0 === v) var v = u;
                  else (v += String.fromCharCode(0)), (v += u);
                  u = p + 1;
                }
              }
            else {
              v = Array(h);
              for (n = 0; n < h; ++n) v[n] = String.fromCharCode(K[m + n]);
              v = v.join("");
            }
            yc(f);
            return v;
          },
          toWireType: function (f, h) {
            h instanceof ArrayBuffer && (h = new Uint8Array(h));
            var m = "string" == typeof h;
            m ||
              h instanceof Uint8Array ||
              h instanceof Uint8ClampedArray ||
              h instanceof Int8Array ||
              Lb("Cannot pass non-string to std::string");
            var u = d && m ? oa(h) : h.length;
            var n = Ld(4 + u + 1),
              p = n + 4;
            Ta[n >> 2] = u;
            if (d && m) pa(h, K, p, u + 1);
            else if (m)
              for (m = 0; m < u; ++m) {
                var v = h.charCodeAt(m);
                255 < v && (yc(p), Lb("String has UTF-16 code units that do not fit in 8 bits"));
                K[p + m] = v;
              }
            else for (m = 0; m < u; ++m) K[p + m] = h[m];
            null !== f && f.push(yc, n);
            return n;
          },
          argPackAdvance: 8,
          readValueFromPointer: yb,
          de: function (f) {
            yc(f);
          },
        });
      },
      O: function (a, b, d) {
        d = Jb(d);
        if (2 === b) {
          var f = Qc;
          var h = Rc;
          var m = Sc;
          var u = () => Sa;
          var n = 1;
        } else 4 === b && ((f = Tc), (h = Uc), (m = Vc), (u = () => Ta), (n = 2));
        Gb(a, {
          name: d,
          fromWireType: function (p) {
            for (var v = Ta[p >> 2], E = u(), G, L = p + 4, y = 0; y <= v; ++y) {
              var M = p + 4 + y * b;
              if (y == v || 0 == E[M >> n])
                (L = f(L, M - L)), void 0 === G ? (G = L) : ((G += String.fromCharCode(0)), (G += L)), (L = M + b);
            }
            yc(p);
            return G;
          },
          toWireType: function (p, v) {
            "string" != typeof v && Lb(`Cannot pass non-string to C++ string type ${d}`);
            var E = m(v),
              G = Ld(4 + E + b);
            Ta[G >> 2] = E >> n;
            h(v, G + 4, E + b);
            null !== p && p.push(yc, G);
            return G;
          },
          argPackAdvance: 8,
          readValueFromPointer: yb,
          de: function (p) {
            yc(p);
          },
        });
      },
      F: function (a, b, d, f, h, m) {
        wb[a] = { name: Jb(b), hf: uc(d, f), ke: uc(h, m), rf: [] };
      },
      f: function (a, b, d, f, h, m, u, n, p, v) {
        wb[a].rf.push({ Of: Jb(b), Vf: d, Tf: uc(f, h), Uf: m, fg: u, eg: uc(n, p), gg: v });
      },
      Ib: function (a, b) {
        b = Jb(b);
        Gb(a, { Yf: !0, name: b, argPackAdvance: 0, fromWireType: function () {}, toWireType: function () {} });
      },
      Fb: () => !0,
      ub: () => {
        throw Infinity;
      },
      C: function (a, b, d) {
        a = Kc(a);
        b = Mc(b, "emval::as");
        var f = [],
          h = pc(f);
        Ta[d >> 2] = h;
        return b.toWireType(f, a);
      },
      K: function (a, b, d, f, h) {
        a = Yc[a];
        b = Kc(b);
        d = Xc(d);
        var m = [];
        Ta[f >> 2] = pc(m);
        return a(b, d, m, h);
      },
      r: function (a, b, d, f) {
        a = Yc[a];
        b = Kc(b);
        d = Xc(d);
        a(b, d, null, f);
      },
      c: Jc,
      J: function (a) {
        if (0 === a) return pc(Zc());
        a = Xc(a);
        return pc(Zc()[a]);
      },
      p: function (a, b) {
        var d = ad(a, b),
          f = d[0];
        b =
          f.name +
          "_$" +
          d
            .slice(1)
            .map(function (u) {
              return u.name;
            })
            .join("_") +
          "$";
        var h = bd[b];
        if (void 0 !== h) return h;
        var m = Array(a - 1);
        h = $c((u, n, p, v) => {
          for (var E = 0, G = 0; G < a - 1; ++G)
            (m[G] = d[G + 1].readValueFromPointer(v + E)), (E += d[G + 1].argPackAdvance);
          u = u[n].apply(u, m);
          for (G = 0; G < a - 1; ++G) d[G + 1].Lf && d[G + 1].Lf(m[G]);
          if (!f.Yf) return f.toWireType(p, u);
        });
        return (bd[b] = h);
      },
      B: function (a, b) {
        a = Kc(a);
        b = Kc(b);
        return pc(a[b]);
      },
      m: function (a) {
        4 < a && (Ic.get(a).wf += 1);
      },
      L: function (a, b, d, f) {
        a = Kc(a);
        var h = dd[b];
        h || ((h = cd(b)), (dd[b] = h));
        return h(a, d, f);
      },
      D: function () {
        return pc([]);
      },
      e: function (a) {
        return pc(Xc(a));
      },
      x: function () {
        return pc({});
      },
      kb: function (a) {
        a = Kc(a);
        return !a;
      },
      z: function (a) {
        var b = Kc(a);
        xb(b);
        Jc(a);
      },
      g: function (a, b, d) {
        a = Kc(a);
        b = Kc(b);
        d = Kc(d);
        a[b] = d;
      },
      h: function (a, b) {
        a = Mc(a, "_emval_take_value");
        a = a.readValueFromPointer(b);
        return pc(a);
      },
      nb: function () {
        return -52;
      },
      ob: function () {},
      a: () => {
        Ka("");
      },
      Eb: () => performance.now(),
      vb: a => {
        var b = K.length;
        a >>>= 0;
        if (2147483648 < a) return !1;
        for (var d = 1; 4 >= d; d *= 2) {
          var f = b * (1 + 0.2 / d);
          f = Math.min(f, a + 100663296);
          var h = Math;
          f = Math.max(a, f);
          a: {
            h = (h.min.call(h, 2147483648, f + ((65536 - (f % 65536)) % 65536)) - La.buffer.byteLength + 65535) >>> 16;
            try {
              La.grow(h);
              Va();
              var m = 1;
              break a;
            } catch (u) {}
            m = void 0;
          }
          if (m) return !0;
        }
        return !1;
      },
      lb: function () {
        return A ? A.handle : 0;
      },
      wb: (a, b) => {
        var d = 0;
        Cd().forEach(function (f, h) {
          var m = b + d;
          h = Ta[(a + 4 * h) >> 2] = m;
          for (m = 0; m < f.length; ++m) Qa[h++ >> 0] = f.charCodeAt(m);
          Qa[h >> 0] = 0;
          d += f.length + 1;
        });
        return 0;
      },
      xb: (a, b) => {
        var d = Cd();
        Ta[a >> 2] = d.length;
        var f = 0;
        d.forEach(function (h) {
          f += h.length + 1;
        });
        Ta[b >> 2] = f;
        return 0;
      },
      Jb: a => {
        if (!noExitRuntime) {
          if (w.onExit) w.onExit(a);
          Pa = !0;
        }
        va(a, new sb(a));
      },
      N: () => 52,
      mb: function () {
        return 52;
      },
      Cb: () => 52,
      pb: function () {
        return 70;
      },
      T: (a, b, d, f) => {
        for (var h = 0, m = 0; m < d; m++) {
          var u = Ta[b >> 2],
            n = Ta[(b + 4) >> 2];
          b += 8;
          for (var p = 0; p < n; p++) {
            var v = K[u + p],
              E = Dd[a];
            0 === v || 10 === v ? ((1 === a ? Ga : Ha)(vb(E, 0)), (E.length = 0)) : E.push(v);
          }
          h += n;
        }
        Ta[f >> 2] = h;
        return 0;
      },
      aa: function (a) {
        X.activeTexture(a);
      },
      ba: function (a, b) {
        X.attachShader(kd[a], nd[b]);
      },
      ca: function (a, b, d) {
        X.bindAttribLocation(kd[a], b, d ? vb(K, d) : "");
      },
      da: function (a, b) {
        35051 == a ? (X.ef = b) : 35052 == a && (X.De = b);
        X.bindBuffer(a, jd[b]);
      },
      $: function (a, b) {
        X.bindFramebuffer(a, ld[b]);
      },
      fc: function (a, b) {
        X.bindRenderbuffer(a, md[b]);
      },
      Rb: function (a, b) {
        X.bindSampler(a, pd[b]);
      },
      ea: function (a, b) {
        X.bindTexture(a, ha[b]);
      },
      zc: Ed,
      Cc: Ed,
      fa: function (a, b, d, f) {
        X.blendColor(a, b, d, f);
      },
      ga: function (a) {
        X.blendEquation(a);
      },
      ha: function (a, b) {
        X.blendFunc(a, b);
      },
      $b: function (a, b, d, f, h, m, u, n, p, v) {
        X.blitFramebuffer(a, b, d, f, h, m, u, n, p, v);
      },
      ia: function (a, b, d, f) {
        2 <= A.version
          ? d && b
            ? X.bufferData(a, K, f, d, b)
            : X.bufferData(a, b, f)
          : X.bufferData(a, d ? K.subarray(d, d + b) : b, f);
      },
      ja: function (a, b, d, f) {
        2 <= A.version ? d && X.bufferSubData(a, b, K, f, d) : X.bufferSubData(a, b, K.subarray(f, f + d));
      },
      gc: function (a) {
        return X.checkFramebufferStatus(a);
      },
      R: function (a) {
        X.clear(a);
      },
      _: function (a, b, d, f) {
        X.clearColor(a, b, d, f);
      },
      S: function (a) {
        X.clearStencil(a);
      },
      sb: function (a, b, d, f) {
        return X.clientWaitSync(qd[a], b, (d >>> 0) + 4294967296 * f);
      },
      ka: function (a, b, d, f) {
        X.colorMask(!!a, !!b, !!d, !!f);
      },
      la: function (a) {
        X.compileShader(nd[a]);
      },
      ma: function (a, b, d, f, h, m, u, n) {
        2 <= A.version
          ? X.De || !u
            ? X.compressedTexImage2D(a, b, d, f, h, m, u, n)
            : X.compressedTexImage2D(a, b, d, f, h, m, K, n, u)
          : X.compressedTexImage2D(a, b, d, f, h, m, n ? K.subarray(n, n + u) : null);
      },
      na: function (a, b, d, f, h, m, u, n, p) {
        2 <= A.version
          ? X.De || !n
            ? X.compressedTexSubImage2D(a, b, d, f, h, m, u, n, p)
            : X.compressedTexSubImage2D(a, b, d, f, h, m, u, K, p, n)
          : X.compressedTexSubImage2D(a, b, d, f, h, m, u, p ? K.subarray(p, p + n) : null);
      },
      Zb: function (a, b, d, f, h) {
        X.copyBufferSubData(a, b, d, f, h);
      },
      oa: function (a, b, d, f, h, m, u, n) {
        X.copyTexSubImage2D(a, b, d, f, h, m, u, n);
      },
      pa: function () {
        var a = ca(kd),
          b = X.createProgram();
        b.name = a;
        b.Ye = b.We = b.Xe = 0;
        b.kf = 1;
        kd[a] = b;
        return a;
      },
      qa: function (a) {
        var b = ca(nd);
        nd[b] = X.createShader(a);
        return b;
      },
      ra: function (a) {
        X.cullFace(a);
      },
      sa: function (a, b) {
        for (var d = 0; d < a; d++) {
          var f = Q[(b + 4 * d) >> 2],
            h = jd[f];
          h && (X.deleteBuffer(h), (h.name = 0), (jd[f] = null), f == X.ef && (X.ef = 0), f == X.De && (X.De = 0));
        }
      },
      hc: function (a, b) {
        for (var d = 0; d < a; ++d) {
          var f = Q[(b + 4 * d) >> 2],
            h = ld[f];
          h && (X.deleteFramebuffer(h), (h.name = 0), (ld[f] = null));
        }
      },
      ta: function (a) {
        if (a) {
          var b = kd[a];
          b ? (X.deleteProgram(b), (b.name = 0), (kd[a] = null)) : ud(1281);
        }
      },
      ic: function (a, b) {
        for (var d = 0; d < a; d++) {
          var f = Q[(b + 4 * d) >> 2],
            h = md[f];
          h && (X.deleteRenderbuffer(h), (h.name = 0), (md[f] = null));
        }
      },
      Sb: function (a, b) {
        for (var d = 0; d < a; d++) {
          var f = Q[(b + 4 * d) >> 2],
            h = pd[f];
          h && (X.deleteSampler(h), (h.name = 0), (pd[f] = null));
        }
      },
      ua: function (a) {
        if (a) {
          var b = nd[a];
          b ? (X.deleteShader(b), (nd[a] = null)) : ud(1281);
        }
      },
      _b: function (a) {
        if (a) {
          var b = qd[a];
          b ? (X.deleteSync(b), (b.name = 0), (qd[a] = null)) : ud(1281);
        }
      },
      va: function (a, b) {
        for (var d = 0; d < a; d++) {
          var f = Q[(b + 4 * d) >> 2],
            h = ha[f];
          h && (X.deleteTexture(h), (h.name = 0), (ha[f] = null));
        }
      },
      Ac: Fd,
      Dc: Fd,
      wa: function (a) {
        X.depthMask(!!a);
      },
      xa: function (a) {
        X.disable(a);
      },
      ya: function (a) {
        X.disableVertexAttribArray(a);
      },
      za: function (a, b, d) {
        X.drawArrays(a, b, d);
      },
      xc: function (a, b, d, f) {
        X.drawArraysInstanced(a, b, d, f);
      },
      vc: function (a, b, d, f, h) {
        X.pf.drawArraysInstancedBaseInstanceWEBGL(a, b, d, f, h);
      },
      tc: function (a, b) {
        for (var d = Gd[a], f = 0; f < a; f++) d[f] = Q[(b + 4 * f) >> 2];
        X.drawBuffers(d);
      },
      Aa: Hd,
      yc: function (a, b, d, f, h) {
        X.drawElementsInstanced(a, b, d, f, h);
      },
      wc: function (a, b, d, f, h, m, u) {
        X.pf.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a, b, d, f, h, m, u);
      },
      nc: function (a, b, d, f, h, m) {
        Hd(a, f, h, m);
      },
      Ba: function (a) {
        X.enable(a);
      },
      Ca: function (a) {
        X.enableVertexAttribArray(a);
      },
      Xb: function (a, b) {
        return (a = X.fenceSync(a, b)) ? ((b = ca(qd)), (a.name = b), (qd[b] = a), b) : 0;
      },
      Da: function () {
        X.finish();
      },
      Ea: function () {
        X.flush();
      },
      jc: function (a, b, d, f) {
        X.framebufferRenderbuffer(a, b, d, md[f]);
      },
      kc: function (a, b, d, f, h) {
        X.framebufferTexture2D(a, b, d, ha[f], h);
      },
      Fa: function (a) {
        X.frontFace(a);
      },
      Ga: function (a, b) {
        Id(a, b, "createBuffer", jd);
      },
      lc: function (a, b) {
        Id(a, b, "createFramebuffer", ld);
      },
      mc: function (a, b) {
        Id(a, b, "createRenderbuffer", md);
      },
      Tb: function (a, b) {
        Id(a, b, "createSampler", pd);
      },
      Ha: function (a, b) {
        Id(a, b, "createTexture", ha);
      },
      Bc: Jd,
      Ec: Jd,
      bc: function (a) {
        X.generateMipmap(a);
      },
      Ia: function (a, b, d) {
        d ? (Q[d >> 2] = X.getBufferParameter(a, b)) : ud(1281);
      },
      Ja: function () {
        var a = X.getError() || vd;
        vd = 0;
        return a;
      },
      Ka: function (a, b) {
        Kd(a, b, 2);
      },
      cc: function (a, b, d, f) {
        a = X.getFramebufferAttachmentParameter(a, b, d);
        if (a instanceof WebGLRenderbuffer || a instanceof WebGLTexture) a = a.name | 0;
        Q[f >> 2] = a;
      },
      M: function (a, b) {
        Kd(a, b, 0);
      },
      La: function (a, b, d, f) {
        a = X.getProgramInfoLog(kd[a]);
        null === a && (a = "(unknown error)");
        b = 0 < b && f ? pa(a, K, f, b) : 0;
        d && (Q[d >> 2] = b);
      },
      Ma: function (a, b, d) {
        if (d)
          if (a >= hd) ud(1281);
          else if (((a = kd[a]), 35716 == b))
            (a = X.getProgramInfoLog(a)), null === a && (a = "(unknown error)"), (Q[d >> 2] = a.length + 1);
          else if (35719 == b) {
            if (!a.Ye)
              for (b = 0; b < X.getProgramParameter(a, 35718); ++b)
                a.Ye = Math.max(a.Ye, X.getActiveUniform(a, b).name.length + 1);
            Q[d >> 2] = a.Ye;
          } else if (35722 == b) {
            if (!a.We)
              for (b = 0; b < X.getProgramParameter(a, 35721); ++b)
                a.We = Math.max(a.We, X.getActiveAttrib(a, b).name.length + 1);
            Q[d >> 2] = a.We;
          } else if (35381 == b) {
            if (!a.Xe)
              for (b = 0; b < X.getProgramParameter(a, 35382); ++b)
                a.Xe = Math.max(a.Xe, X.getActiveUniformBlockName(a, b).length + 1);
            Q[d >> 2] = a.Xe;
          } else Q[d >> 2] = X.getProgramParameter(a, b);
        else ud(1281);
      },
      dc: function (a, b, d) {
        d ? (Q[d >> 2] = X.getRenderbufferParameter(a, b)) : ud(1281);
      },
      Na: function (a, b, d, f) {
        a = X.getShaderInfoLog(nd[a]);
        null === a && (a = "(unknown error)");
        b = 0 < b && f ? pa(a, K, f, b) : 0;
        d && (Q[d >> 2] = b);
      },
      Ob: function (a, b, d, f) {
        a = X.getShaderPrecisionFormat(a, b);
        Q[d >> 2] = a.rangeMin;
        Q[(d + 4) >> 2] = a.rangeMax;
        Q[f >> 2] = a.precision;
      },
      Oa: function (a, b, d) {
        d
          ? 35716 == b
            ? ((a = X.getShaderInfoLog(nd[a])),
              null === a && (a = "(unknown error)"),
              (Q[d >> 2] = a ? a.length + 1 : 0))
            : 35720 == b
              ? ((a = X.getShaderSource(nd[a])), (Q[d >> 2] = a ? a.length + 1 : 0))
              : (Q[d >> 2] = X.getShaderParameter(nd[a], b))
          : ud(1281);
      },
      Q: function (a) {
        var b = rd[a];
        if (!b) {
          switch (a) {
            case 7939:
              b = X.getSupportedExtensions() || [];
              b = b.concat(
                b.map(function (f) {
                  return "GL_" + f;
                }),
              );
              b = Md(b.join(" "));
              break;
            case 7936:
            case 7937:
            case 37445:
            case 37446:
              (b = X.getParameter(a)) || ud(1280);
              b = b && Md(b);
              break;
            case 7938:
              b = X.getParameter(7938);
              b = 2 <= A.version ? "OpenGL ES 3.0 (" + b + ")" : "OpenGL ES 2.0 (" + b + ")";
              b = Md(b);
              break;
            case 35724:
              b = X.getParameter(35724);
              var d = b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);
              null !== d && (3 == d[1].length && (d[1] += "0"), (b = "OpenGL ES GLSL ES " + d[1] + " (" + b + ")"));
              b = Md(b);
              break;
            default:
              ud(1280);
          }
          rd[a] = b;
        }
        return b;
      },
      jb: function (a, b) {
        if (2 > A.version) return ud(1282), 0;
        var d = sd[a];
        if (d) return 0 > b || b >= d.length ? (ud(1281), 0) : d[b];
        switch (a) {
          case 7939:
            return (
              (d = X.getSupportedExtensions() || []),
              (d = d.concat(
                d.map(function (f) {
                  return "GL_" + f;
                }),
              )),
              (d = d.map(function (f) {
                return Md(f);
              })),
              (d = sd[a] = d),
              0 > b || b >= d.length ? (ud(1281), 0) : d[b]
            );
          default:
            return ud(1280), 0;
        }
      },
      Pa: function (a, b) {
        b = b ? vb(K, b) : "";
        if ((a = kd[a])) {
          var d = a,
            f = d.Ne,
            h = d.yf,
            m;
          if (!f)
            for (d.Ne = f = {}, d.xf = {}, m = 0; m < X.getProgramParameter(d, 35718); ++m) {
              var u = X.getActiveUniform(d, m);
              var n = u.name;
              u = u.size;
              var p = Nd(n);
              p = 0 < p ? n.slice(0, p) : n;
              var v = d.kf;
              d.kf += u;
              h[p] = [u, v];
              for (n = 0; n < u; ++n) (f[v] = n), (d.xf[v++] = p);
            }
          d = a.Ne;
          f = 0;
          h = b;
          m = Nd(b);
          0 < m && ((f = parseInt(b.slice(m + 1)) >>> 0), (h = b.slice(0, m)));
          if ((h = a.yf[h]) && f < h[0] && ((f += h[1]), (d[f] = d[f] || X.getUniformLocation(a, b)))) return f;
        } else ud(1281);
        return -1;
      },
      Pb: function (a, b, d) {
        for (var f = Gd[b], h = 0; h < b; h++) f[h] = Q[(d + 4 * h) >> 2];
        X.invalidateFramebuffer(a, f);
      },
      Qb: function (a, b, d, f, h, m, u) {
        for (var n = Gd[b], p = 0; p < b; p++) n[p] = Q[(d + 4 * p) >> 2];
        X.invalidateSubFramebuffer(a, n, f, h, m, u);
      },
      Yb: function (a) {
        return X.isSync(qd[a]);
      },
      Qa: function (a) {
        return (a = ha[a]) ? X.isTexture(a) : 0;
      },
      Ra: function (a) {
        X.lineWidth(a);
      },
      Sa: function (a) {
        a = kd[a];
        X.linkProgram(a);
        a.Ne = 0;
        a.yf = {};
      },
      rc: function (a, b, d, f, h, m) {
        X.uf.multiDrawArraysInstancedBaseInstanceWEBGL(a, Q, b >> 2, Q, d >> 2, Q, f >> 2, Ta, h >> 2, m);
      },
      sc: function (a, b, d, f, h, m, u, n) {
        X.uf.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(
          a,
          Q,
          b >> 2,
          d,
          Q,
          f >> 2,
          Q,
          h >> 2,
          Q,
          m >> 2,
          Ta,
          u >> 2,
          n,
        );
      },
      Ta: function (a, b) {
        3317 == a && (td = b);
        X.pixelStorei(a, b);
      },
      uc: function (a) {
        X.readBuffer(a);
      },
      Ua: function (a, b, d, f, h, m, u) {
        if (2 <= A.version)
          if (X.ef) X.readPixels(a, b, d, f, h, m, u);
          else {
            var n = Od(m);
            X.readPixels(a, b, d, f, h, m, n, u >> (31 - Math.clz32(n.BYTES_PER_ELEMENT)));
          }
        else (u = Pd(m, h, d, f, u)) ? X.readPixels(a, b, d, f, h, m, u) : ud(1280);
      },
      ec: function (a, b, d, f) {
        X.renderbufferStorage(a, b, d, f);
      },
      ac: function (a, b, d, f, h) {
        X.renderbufferStorageMultisample(a, b, d, f, h);
      },
      Ub: function (a, b, d) {
        X.samplerParameterf(pd[a], b, d);
      },
      Vb: function (a, b, d) {
        X.samplerParameteri(pd[a], b, d);
      },
      Wb: function (a, b, d) {
        X.samplerParameteri(pd[a], b, Q[d >> 2]);
      },
      Va: function (a, b, d, f) {
        X.scissor(a, b, d, f);
      },
      Wa: function (a, b, d, f) {
        for (var h = "", m = 0; m < b; ++m) {
          var u = f ? Q[(f + 4 * m) >> 2] : -1,
            n = Q[(d + 4 * m) >> 2];
          u = n ? vb(K, n, 0 > u ? void 0 : u) : "";
          h += u;
        }
        X.shaderSource(nd[a], h);
      },
      Xa: function (a, b, d) {
        X.stencilFunc(a, b, d);
      },
      Ya: function (a, b, d, f) {
        X.stencilFuncSeparate(a, b, d, f);
      },
      Za: function (a) {
        X.stencilMask(a);
      },
      _a: function (a, b) {
        X.stencilMaskSeparate(a, b);
      },
      $a: function (a, b, d) {
        X.stencilOp(a, b, d);
      },
      ab: function (a, b, d, f) {
        X.stencilOpSeparate(a, b, d, f);
      },
      bb: function (a, b, d, f, h, m, u, n, p) {
        if (2 <= A.version)
          if (X.De) X.texImage2D(a, b, d, f, h, m, u, n, p);
          else if (p) {
            var v = Od(n);
            X.texImage2D(a, b, d, f, h, m, u, n, v, p >> (31 - Math.clz32(v.BYTES_PER_ELEMENT)));
          } else X.texImage2D(a, b, d, f, h, m, u, n, null);
        else X.texImage2D(a, b, d, f, h, m, u, n, p ? Pd(n, u, f, h, p) : null);
      },
      cb: function (a, b, d) {
        X.texParameterf(a, b, d);
      },
      db: function (a, b, d) {
        X.texParameterf(a, b, S[d >> 2]);
      },
      eb: function (a, b, d) {
        X.texParameteri(a, b, d);
      },
      fb: function (a, b, d) {
        X.texParameteri(a, b, Q[d >> 2]);
      },
      oc: function (a, b, d, f, h) {
        X.texStorage2D(a, b, d, f, h);
      },
      gb: function (a, b, d, f, h, m, u, n, p) {
        if (2 <= A.version)
          if (X.De) X.texSubImage2D(a, b, d, f, h, m, u, n, p);
          else if (p) {
            var v = Od(n);
            X.texSubImage2D(a, b, d, f, h, m, u, n, v, p >> (31 - Math.clz32(v.BYTES_PER_ELEMENT)));
          } else X.texSubImage2D(a, b, d, f, h, m, u, n, null);
        else (v = null), p && (v = Pd(n, u, h, m, p)), X.texSubImage2D(a, b, d, f, h, m, u, n, v);
      },
      hb: function (a, b) {
        X.uniform1f(Qd(a), b);
      },
      ib: function (a, b, d) {
        if (2 <= A.version) b && X.uniform1fv(Qd(a), S, d >> 2, b);
        else {
          if (288 >= b) for (var f = Rd[b - 1], h = 0; h < b; ++h) f[h] = S[(d + 4 * h) >> 2];
          else f = S.subarray(d >> 2, (d + 4 * b) >> 2);
          X.uniform1fv(Qd(a), f);
        }
      },
      Zc: function (a, b) {
        X.uniform1i(Qd(a), b);
      },
      _c: function (a, b, d) {
        if (2 <= A.version) b && X.uniform1iv(Qd(a), Q, d >> 2, b);
        else {
          if (288 >= b) for (var f = Sd[b - 1], h = 0; h < b; ++h) f[h] = Q[(d + 4 * h) >> 2];
          else f = Q.subarray(d >> 2, (d + 4 * b) >> 2);
          X.uniform1iv(Qd(a), f);
        }
      },
      $c: function (a, b, d) {
        X.uniform2f(Qd(a), b, d);
      },
      ad: function (a, b, d) {
        if (2 <= A.version) b && X.uniform2fv(Qd(a), S, d >> 2, 2 * b);
        else {
          if (144 >= b)
            for (var f = Rd[2 * b - 1], h = 0; h < 2 * b; h += 2)
              (f[h] = S[(d + 4 * h) >> 2]), (f[h + 1] = S[(d + (4 * h + 4)) >> 2]);
          else f = S.subarray(d >> 2, (d + 8 * b) >> 2);
          X.uniform2fv(Qd(a), f);
        }
      },
      Yc: function (a, b, d) {
        X.uniform2i(Qd(a), b, d);
      },
      Xc: function (a, b, d) {
        if (2 <= A.version) b && X.uniform2iv(Qd(a), Q, d >> 2, 2 * b);
        else {
          if (144 >= b)
            for (var f = Sd[2 * b - 1], h = 0; h < 2 * b; h += 2)
              (f[h] = Q[(d + 4 * h) >> 2]), (f[h + 1] = Q[(d + (4 * h + 4)) >> 2]);
          else f = Q.subarray(d >> 2, (d + 8 * b) >> 2);
          X.uniform2iv(Qd(a), f);
        }
      },
      Wc: function (a, b, d, f) {
        X.uniform3f(Qd(a), b, d, f);
      },
      Vc: function (a, b, d) {
        if (2 <= A.version) b && X.uniform3fv(Qd(a), S, d >> 2, 3 * b);
        else {
          if (96 >= b)
            for (var f = Rd[3 * b - 1], h = 0; h < 3 * b; h += 3)
              (f[h] = S[(d + 4 * h) >> 2]),
                (f[h + 1] = S[(d + (4 * h + 4)) >> 2]),
                (f[h + 2] = S[(d + (4 * h + 8)) >> 2]);
          else f = S.subarray(d >> 2, (d + 12 * b) >> 2);
          X.uniform3fv(Qd(a), f);
        }
      },
      Uc: function (a, b, d, f) {
        X.uniform3i(Qd(a), b, d, f);
      },
      Tc: function (a, b, d) {
        if (2 <= A.version) b && X.uniform3iv(Qd(a), Q, d >> 2, 3 * b);
        else {
          if (96 >= b)
            for (var f = Sd[3 * b - 1], h = 0; h < 3 * b; h += 3)
              (f[h] = Q[(d + 4 * h) >> 2]),
                (f[h + 1] = Q[(d + (4 * h + 4)) >> 2]),
                (f[h + 2] = Q[(d + (4 * h + 8)) >> 2]);
          else f = Q.subarray(d >> 2, (d + 12 * b) >> 2);
          X.uniform3iv(Qd(a), f);
        }
      },
      Sc: function (a, b, d, f, h) {
        X.uniform4f(Qd(a), b, d, f, h);
      },
      Rc: function (a, b, d) {
        if (2 <= A.version) b && X.uniform4fv(Qd(a), S, d >> 2, 4 * b);
        else {
          if (72 >= b) {
            var f = Rd[4 * b - 1],
              h = S;
            d >>= 2;
            for (var m = 0; m < 4 * b; m += 4) {
              var u = d + m;
              f[m] = h[u];
              f[m + 1] = h[u + 1];
              f[m + 2] = h[u + 2];
              f[m + 3] = h[u + 3];
            }
          } else f = S.subarray(d >> 2, (d + 16 * b) >> 2);
          X.uniform4fv(Qd(a), f);
        }
      },
      Fc: function (a, b, d, f, h) {
        X.uniform4i(Qd(a), b, d, f, h);
      },
      Gc: function (a, b, d) {
        if (2 <= A.version) b && X.uniform4iv(Qd(a), Q, d >> 2, 4 * b);
        else {
          if (72 >= b)
            for (var f = Sd[4 * b - 1], h = 0; h < 4 * b; h += 4)
              (f[h] = Q[(d + 4 * h) >> 2]),
                (f[h + 1] = Q[(d + (4 * h + 4)) >> 2]),
                (f[h + 2] = Q[(d + (4 * h + 8)) >> 2]),
                (f[h + 3] = Q[(d + (4 * h + 12)) >> 2]);
          else f = Q.subarray(d >> 2, (d + 16 * b) >> 2);
          X.uniform4iv(Qd(a), f);
        }
      },
      Hc: function (a, b, d, f) {
        if (2 <= A.version) b && X.uniformMatrix2fv(Qd(a), !!d, S, f >> 2, 4 * b);
        else {
          if (72 >= b)
            for (var h = Rd[4 * b - 1], m = 0; m < 4 * b; m += 4)
              (h[m] = S[(f + 4 * m) >> 2]),
                (h[m + 1] = S[(f + (4 * m + 4)) >> 2]),
                (h[m + 2] = S[(f + (4 * m + 8)) >> 2]),
                (h[m + 3] = S[(f + (4 * m + 12)) >> 2]);
          else h = S.subarray(f >> 2, (f + 16 * b) >> 2);
          X.uniformMatrix2fv(Qd(a), !!d, h);
        }
      },
      Ic: function (a, b, d, f) {
        if (2 <= A.version) b && X.uniformMatrix3fv(Qd(a), !!d, S, f >> 2, 9 * b);
        else {
          if (32 >= b)
            for (var h = Rd[9 * b - 1], m = 0; m < 9 * b; m += 9)
              (h[m] = S[(f + 4 * m) >> 2]),
                (h[m + 1] = S[(f + (4 * m + 4)) >> 2]),
                (h[m + 2] = S[(f + (4 * m + 8)) >> 2]),
                (h[m + 3] = S[(f + (4 * m + 12)) >> 2]),
                (h[m + 4] = S[(f + (4 * m + 16)) >> 2]),
                (h[m + 5] = S[(f + (4 * m + 20)) >> 2]),
                (h[m + 6] = S[(f + (4 * m + 24)) >> 2]),
                (h[m + 7] = S[(f + (4 * m + 28)) >> 2]),
                (h[m + 8] = S[(f + (4 * m + 32)) >> 2]);
          else h = S.subarray(f >> 2, (f + 36 * b) >> 2);
          X.uniformMatrix3fv(Qd(a), !!d, h);
        }
      },
      Jc: function (a, b, d, f) {
        if (2 <= A.version) b && X.uniformMatrix4fv(Qd(a), !!d, S, f >> 2, 16 * b);
        else {
          if (18 >= b) {
            var h = Rd[16 * b - 1],
              m = S;
            f >>= 2;
            for (var u = 0; u < 16 * b; u += 16) {
              var n = f + u;
              h[u] = m[n];
              h[u + 1] = m[n + 1];
              h[u + 2] = m[n + 2];
              h[u + 3] = m[n + 3];
              h[u + 4] = m[n + 4];
              h[u + 5] = m[n + 5];
              h[u + 6] = m[n + 6];
              h[u + 7] = m[n + 7];
              h[u + 8] = m[n + 8];
              h[u + 9] = m[n + 9];
              h[u + 10] = m[n + 10];
              h[u + 11] = m[n + 11];
              h[u + 12] = m[n + 12];
              h[u + 13] = m[n + 13];
              h[u + 14] = m[n + 14];
              h[u + 15] = m[n + 15];
            }
          } else h = S.subarray(f >> 2, (f + 64 * b) >> 2);
          X.uniformMatrix4fv(Qd(a), !!d, h);
        }
      },
      Kc: function (a) {
        a = kd[a];
        X.useProgram(a);
        X.Kf = a;
      },
      Lc: function (a, b) {
        X.vertexAttrib1f(a, b);
      },
      Mc: function (a, b) {
        X.vertexAttrib2f(a, S[b >> 2], S[(b + 4) >> 2]);
      },
      Nc: function (a, b) {
        X.vertexAttrib3f(a, S[b >> 2], S[(b + 4) >> 2], S[(b + 8) >> 2]);
      },
      Oc: function (a, b) {
        X.vertexAttrib4f(a, S[b >> 2], S[(b + 4) >> 2], S[(b + 8) >> 2], S[(b + 12) >> 2]);
      },
      pc: function (a, b) {
        X.vertexAttribDivisor(a, b);
      },
      qc: function (a, b, d, f, h) {
        X.vertexAttribIPointer(a, b, d, f, h);
      },
      Pc: function (a, b, d, f, h, m) {
        X.vertexAttribPointer(a, b, d, !!f, h, m);
      },
      Qc: function (a, b, d, f) {
        X.viewport(a, b, d, f);
      },
      rb: function (a, b, d, f) {
        X.waitSync(qd[a], b, (d >>> 0) + 4294967296 * f);
      },
      j: ce,
      q: de,
      k: ee,
      H: fe,
      Lb: ge,
      Z: he,
      Y: ie,
      P: je,
      n: ke,
      A: le,
      w: me,
      t: ne,
      Kb: oe,
      Mb: pe,
      Nb: qe,
      tb: (a, b, d, f) => Xd(a, b, d, f),
    };
    (function () {
      function a(d) {
        Ma = d = d.exports;
        La = Ma.bd;
        Va();
        Wa = Ma.ed;
        Ya.unshift(Ma.cd);
        eb--;
        w.monitorRunDependencies && w.monitorRunDependencies(eb);
        if (0 == eb && (null !== fb && (clearInterval(fb), (fb = null)), gb)) {
          var f = gb;
          gb = null;
          f();
        }
        return d;
      }
      var b = { a: re };
      eb++;
      w.monitorRunDependencies && w.monitorRunDependencies(eb);
      if (w.instantiateWasm)
        try {
          return w.instantiateWasm(b, a);
        } catch (d) {
          Ha("Module.instantiateWasm callback failed with error: " + d), ba(d);
        }
      rb(b, function (d) {
        a(d.instance);
      }).catch(ba);
      return {};
    })();
    var Ld = (w._malloc = a => (Ld = w._malloc = Ma.dd)(a)),
      yc = (w._free = a => (yc = w._free = Ma.fd)(a)),
      xc = a => (xc = Ma.gd)(a);
    w.__embind_initialize_bindings = () => (w.__embind_initialize_bindings = Ma.hd)();
    var se = (a, b) => (se = Ma.id)(a, b),
      te = () => (te = Ma.jd)(),
      ue = a => (ue = Ma.kd)(a);
    w.dynCall_viji = (a, b, d, f, h) => (w.dynCall_viji = Ma.ld)(a, b, d, f, h);
    w.dynCall_vijiii = (a, b, d, f, h, m, u) => (w.dynCall_vijiii = Ma.md)(a, b, d, f, h, m, u);
    w.dynCall_viiiiij = (a, b, d, f, h, m, u, n) => (w.dynCall_viiiiij = Ma.nd)(a, b, d, f, h, m, u, n);
    w.dynCall_jii = (a, b, d) => (w.dynCall_jii = Ma.od)(a, b, d);
    w.dynCall_vij = (a, b, d, f) => (w.dynCall_vij = Ma.pd)(a, b, d, f);
    w.dynCall_iiij = (a, b, d, f, h) => (w.dynCall_iiij = Ma.qd)(a, b, d, f, h);
    w.dynCall_iiiij = (a, b, d, f, h, m) => (w.dynCall_iiiij = Ma.rd)(a, b, d, f, h, m);
    w.dynCall_viij = (a, b, d, f, h) => (w.dynCall_viij = Ma.sd)(a, b, d, f, h);
    w.dynCall_viiij = (a, b, d, f, h, m) => (w.dynCall_viiij = Ma.td)(a, b, d, f, h, m);
    w.dynCall_jiiiii = (a, b, d, f, h, m) => (w.dynCall_jiiiii = Ma.ud)(a, b, d, f, h, m);
    w.dynCall_jiiiiii = (a, b, d, f, h, m, u) => (w.dynCall_jiiiiii = Ma.vd)(a, b, d, f, h, m, u);
    w.dynCall_jiiiiji = (a, b, d, f, h, m, u, n) => (w.dynCall_jiiiiji = Ma.wd)(a, b, d, f, h, m, u, n);
    w.dynCall_ji = (a, b) => (w.dynCall_ji = Ma.xd)(a, b);
    w.dynCall_iijj = (a, b, d, f, h, m) => (w.dynCall_iijj = Ma.yd)(a, b, d, f, h, m);
    w.dynCall_iiiji = (a, b, d, f, h, m) => (w.dynCall_iiiji = Ma.zd)(a, b, d, f, h, m);
    w.dynCall_iiji = (a, b, d, f, h) => (w.dynCall_iiji = Ma.Ad)(a, b, d, f, h);
    w.dynCall_iijjiii = (a, b, d, f, h, m, u, n, p) => (w.dynCall_iijjiii = Ma.Bd)(a, b, d, f, h, m, u, n, p);
    w.dynCall_iij = (a, b, d, f) => (w.dynCall_iij = Ma.Cd)(a, b, d, f);
    w.dynCall_vijjjii = (a, b, d, f, h, m, u, n, p, v) => (w.dynCall_vijjjii = Ma.Dd)(a, b, d, f, h, m, u, n, p, v);
    w.dynCall_jiji = (a, b, d, f, h) => (w.dynCall_jiji = Ma.Ed)(a, b, d, f, h);
    w.dynCall_viijii = (a, b, d, f, h, m, u) => (w.dynCall_viijii = Ma.Fd)(a, b, d, f, h, m, u);
    w.dynCall_iiiiij = (a, b, d, f, h, m, u) => (w.dynCall_iiiiij = Ma.Gd)(a, b, d, f, h, m, u);
    w.dynCall_iiiiijj = (a, b, d, f, h, m, u, n, p) => (w.dynCall_iiiiijj = Ma.Hd)(a, b, d, f, h, m, u, n, p);
    w.dynCall_iiiiiijj = (a, b, d, f, h, m, u, n, p, v) => (w.dynCall_iiiiiijj = Ma.Id)(a, b, d, f, h, m, u, n, p, v);
    function ce(a, b) {
      var d = te();
      try {
        return Wa.get(a)(b);
      } catch (f) {
        ue(d);
        if (f !== f + 0) throw f;
        se(1, 0);
      }
    }
    function de(a, b, d) {
      var f = te();
      try {
        return Wa.get(a)(b, d);
      } catch (h) {
        ue(f);
        if (h !== h + 0) throw h;
        se(1, 0);
      }
    }
    function me(a, b, d, f) {
      var h = te();
      try {
        Wa.get(a)(b, d, f);
      } catch (m) {
        ue(h);
        if (m !== m + 0) throw m;
        se(1, 0);
      }
    }
    function le(a, b, d) {
      var f = te();
      try {
        Wa.get(a)(b, d);
      } catch (h) {
        ue(f);
        if (h !== h + 0) throw h;
        se(1, 0);
      }
    }
    function fe(a, b, d, f, h) {
      var m = te();
      try {
        return Wa.get(a)(b, d, f, h);
      } catch (u) {
        ue(m);
        if (u !== u + 0) throw u;
        se(1, 0);
      }
    }
    function ee(a, b, d, f) {
      var h = te();
      try {
        return Wa.get(a)(b, d, f);
      } catch (m) {
        ue(h);
        if (m !== m + 0) throw m;
        se(1, 0);
      }
    }
    function ke(a, b) {
      var d = te();
      try {
        Wa.get(a)(b);
      } catch (f) {
        ue(d);
        if (f !== f + 0) throw f;
        se(1, 0);
      }
    }
    function ne(a, b, d, f, h) {
      var m = te();
      try {
        Wa.get(a)(b, d, f, h);
      } catch (u) {
        ue(m);
        if (u !== u + 0) throw u;
        se(1, 0);
      }
    }
    function qe(a, b, d, f, h, m, u, n, p, v) {
      var E = te();
      try {
        Wa.get(a)(b, d, f, h, m, u, n, p, v);
      } catch (G) {
        ue(E);
        if (G !== G + 0) throw G;
        se(1, 0);
      }
    }
    function je(a) {
      var b = te();
      try {
        Wa.get(a)();
      } catch (d) {
        ue(b);
        if (d !== d + 0) throw d;
        se(1, 0);
      }
    }
    function pe(a, b, d, f, h, m, u) {
      var n = te();
      try {
        Wa.get(a)(b, d, f, h, m, u);
      } catch (p) {
        ue(n);
        if (p !== p + 0) throw p;
        se(1, 0);
      }
    }
    function ge(a, b, d, f, h, m) {
      var u = te();
      try {
        return Wa.get(a)(b, d, f, h, m);
      } catch (n) {
        ue(u);
        if (n !== n + 0) throw n;
        se(1, 0);
      }
    }
    function he(a, b, d, f, h, m, u) {
      var n = te();
      try {
        return Wa.get(a)(b, d, f, h, m, u);
      } catch (p) {
        ue(n);
        if (p !== p + 0) throw p;
        se(1, 0);
      }
    }
    function oe(a, b, d, f, h, m) {
      var u = te();
      try {
        Wa.get(a)(b, d, f, h, m);
      } catch (n) {
        ue(u);
        if (n !== n + 0) throw n;
        se(1, 0);
      }
    }
    function ie(a, b, d, f, h, m, u, n, p, v) {
      var E = te();
      try {
        return Wa.get(a)(b, d, f, h, m, u, n, p, v);
      } catch (G) {
        ue(E);
        if (G !== G + 0) throw G;
        se(1, 0);
      }
    }
    var ve;
    gb = function we() {
      ve || xe();
      ve || (gb = we);
    };
    function xe() {
      function a() {
        if (!ve && ((ve = !0), (w.calledRun = !0), !Pa)) {
          tb(Ya);
          aa(w);
          if (w.onRuntimeInitialized) w.onRuntimeInitialized();
          if (w.postRun)
            for ("function" == typeof w.postRun && (w.postRun = [w.postRun]); w.postRun.length; ) {
              var b = w.postRun.shift();
              Za.unshift(b);
            }
          tb(Za);
        }
      }
      if (!(0 < eb)) {
        if (w.preRun) for ("function" == typeof w.preRun && (w.preRun = [w.preRun]); w.preRun.length; ) db();
        tb(Xa);
        0 < eb ||
          (w.setStatus
            ? (w.setStatus("Running..."),
              setTimeout(function () {
                setTimeout(function () {
                  w.setStatus("");
                }, 1);
                a();
              }, 1))
            : a());
      }
    }
    if (w.preInit)
      for ("function" == typeof w.preInit && (w.preInit = [w.preInit]); 0 < w.preInit.length; ) w.preInit.pop()();
    xe();

    return moduleArg.ready;
  };
})();
if (typeof exports === "object" && typeof module === "object") module.exports = CanvasKitInit;
else if (typeof define === "function" && define["amd"]) define([], () => CanvasKitInit);
