
import { Component, Prop, Vue } from "@feathers-client";

@Component
export default class HeaderSecondScreen extends Vue {
  paymentSetup = false;

  get screenStatus() {
    if (this.$shop.secondScreen) {
      return this.$config.colors.green000;
    } else if (this.$shop.localOptions.secondScreenCfg) {
      return this.$config.colors["dark-action-danger-default"];
    } else {
      return this.$config.colors.grey300;
    }
  }
}
