
import { Vue, Component, Watch, Prop, FindType } from "@feathers-client";
import { TableSession } from "../../plugins/table/session";
import { TableView } from "~/plugins/table";
import _ from "lodash";
import moment from "moment-timezone";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { checkTime } from "~/plugins/table/utils";

const customCompare = (field, type) => (a, b) => {
  const aVal = _.get(a, field) ? new Date(_.get(a, field)).getTime() : Number.NEGATIVE_INFINITY;
  const bVal = _.get(b, field) ? new Date(_.get(b, field)).getTime() : Number.NEGATIVE_INFINITY;
  if (type === "asc") {
    return aVal - bVal;
  } else {
    return bVal - aVal;
  }
};

@Component({
  components: {
    QrcodeStream,
  },
})
export default class TakeawayTable extends Vue {
  @Prop()
  tableView: TableView;

  tab: string = "all";

  orderList = [
    { key: "pickupAsc", name: this.$t("takeaway.sortByPickupAsc") },
    { key: "pickupDesc", name: this.$t("takeaway.sortByPickupDesc") },
    { key: "orderAsc", name: this.$t("takeaway.sortByOrderAsc") },
  ];

  paymentStatus = [
    { key: "allStatus", name: this.$t("takeaway.allStatus") },
    { key: "unpaid", name: this.$t("takeaway.unpaid") },
    { key: "partialPaid", name: this.$t("takeaway.partialPaid") },
    { key: "paid", name: this.$t("takeaway.paid") },
  ];

  filterByPaymentStatus: string = "allStatus";

  orderBy: string = "pickupAsc";

  status = [
    { type: "hold", color: "red100", active: true },
    { type: "sent", color: "blue100", active: true },
    { type: "done", color: "purple400", active: true },
    { type: "finish", color: "green000", active: false },
  ];

  orderSystem: boolean = false;

  searchWord: string = "";

  calendarDialog: boolean = false;

  datetimeCondition = { pickedDate: null, startTime: null, endTime: null };

  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  confirmedDate: number = 0;

  confirmedSession: number = 0;

  scanning: boolean = false;

  session: TableSession = null;

  get orderSystemActive() {
    return this.orderSystem && !!this.session;
  }

  set orderSystemActive(v) {
    if (!v) {
      this.orderSystem = false;
      this.session = null;
      if (this.curSession) {
        this.curSession.$destroy();
        this.curSession = null;
      }
    }
  }

  get orderSystemSession() {
    return this.$tableManager.takeAwaySession;
  }

  fetch({ store }) {
    store.commit("SET_TITLE", { fullPage: true, tableMode: true, disableTextSelect: true, dark: true });
  }

  onCalendarClick() {
    this.calendarDialog = true;
  }

  isAvailableSection(item: FindType<"sections">) {
    return (
      item.timeRange && !!item.timeRange.find(range => checkTime(range.weekdays, range.from, range.to, new Date()))
    );
  }

  curSession: TableSession = null;
  async createSession(type = "takeAway") {
    if (this.tab === "dineInNoTable") type = "dineInNoTable";
    const staff = await this.$shop.checkPermission(["tableManage/tableSessionCreateOrder"]);
    if (staff === false) return;
    if (this.curSession) {
      this.curSession.$destroy();
      this.curSession = null;
    }
    const session = new TableSession({
      parent: this,
    });
    this.curSession = session;
    session.init({
      startTime: new Date(),
      type: type as any,
      tables: [],
    });

    session.item.createStaff = staff?._id || this.$shop.staffId;
    session.section = this.$shop.sections.find(it => this.isAvailableSection(it))?._id;
    session.pauseSave();
    this.session = session;
    this.orderSystem = true;
  }

  onFilterClick(event) {
    this.calendarDialog = false;
    this.datetimeCondition.pickedDate = event[0];
    this.datetimeCondition.startTime = event[1];
    this.datetimeCondition.endTime = event[2];
  }

  resetTimeFilter() {
    this.confirmedDate = 0;
    this.confirmedSession = 0;
    this.datetimeCondition = { pickedDate: null, startTime: null, endTime: null };
  }

  convertTimestampInTimeZone(timestamp) {
    const formattedDate = moment(timestamp).tz(this.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    return formattedDate;
  }

  filterOrdersByTag(status, orders) {
    const activeFilters = _.filter(status, item => {
      return item.active;
    });
    return _.filter(orders, order => {
      for (let filter of activeFilters) {
        if (filter.type === order.takeawayStatus) {
          return true;
        }
      }
    });
  }

  filterOrdersByTab(tab) {
    switch (tab) {
      case "all":
        return [
          ...(this.$shop.shopData.openTakeAway ? this.$tableManager.takeAways : []),
          ...(this.$shop.shopData.openDineInNoTable ? this.$tableManager.dineInNoTables : []),
        ];
      case "pickup":
        return _.filter(this.$tableManager.takeAways, order => {
          return order.isTakeAway;
        });
      case "delivery":
        return _.filter(this.$tableManager.takeAways, order => {
          return order.isDelivery;
        });
      case "dineInNoTable":
        return this.$tableManager.dineInNoTables;
      case "phoneOrder":
        return this.$tableManager.takeAways.filter(order => order.sessionData.phoneOrder && order.userPhone);
    }
  }

  filterOrdersByDatetime(datetimeCondition, orders) {
    if (datetimeCondition.pickedDate && this.confirmedDate != 0) {
      orders = _.filter(orders, order => {
        return (
          this.convertTimestampInTimeZone(order.sessionData.pickUpTime).substr(0, 10) === datetimeCondition.pickedDate
        );
      });
    }
    if (datetimeCondition.startTime && this.confirmedSession != 0) {
      orders = _.filter(orders, order => {
        return (
          this.convertTimestampInTimeZone(order.sessionData.pickUpTime).substr(11, 5) >= datetimeCondition.startTime
        );
      });
    }
    if (datetimeCondition.endTime && this.confirmedSession != 0) {
      orders = _.filter(orders, order => {
        return this.convertTimestampInTimeZone(order.sessionData.pickUpTime).substr(11, 5) <= datetimeCondition.endTime;
      });
    }
    return orders;
  }

  sortByTime(orderBy, orders) {
    switch (orderBy) {
      case "pickupAsc":
        return orders.sort(customCompare("sessionData.pickUpTime", "asc"));
      case "pickupDesc":
        return orders.sort(customCompare("sessionData.pickUpTime", "desc"));
      case "orderAsc":
        return _.orderBy(orders, ["createTime"], ["asc"]);
    }
  }

  filterOrdersByPaymentStatus(filterByPaymentStatus, orders) {
    switch (filterByPaymentStatus) {
      case "allStatus":
        return orders;
      case "unpaid":
        return _.filter(orders, order => {
          return order.sessionData.payments.length === 0 && !order.isFinish;
        });
      case "partialPaid":
        return _.filter(orders, order => {
          return order.sessionData.payments.length > 0 && !order.isFinish;
        });
      case "paid":
        return _.filter(orders, order => {
          return order.isFinish && !order.isCancel && !order.isVoid;
        });
    }
  }

  async beforeMount() {
    await this.$tableManager.ready;
  }

  get selectedOrders() {
    if (!this.$store.state.user._id) return [];
    return this.filterOrdersByTab(this.tab);
  }

  get filteredOrders() {
    let orders = this.selectedOrders;
    orders = this.filterOrdersByTag(this.status, orders);
    orders = this.filterOrdersByPaymentStatus(this.filterByPaymentStatus, orders);
    orders = this.filterOrdersByDatetime(this.datetimeCondition, orders);
    orders = this.sortByTime(this.orderBy, orders);
    if (this.searchWord) {
      orders = _.filter(orders, order => {
        return order.sessionData.sessionName.includes(this.searchWord);
      });
    }
    return orders;
  }

  get isActive() {
    return this.confirmedDate != 0 || this.confirmedSession != 0;
  }

  get modeActived() {
    return this.$shop.shopData?.openTakeAway || this.$shop.shopData?.openDineInNoTable;
  }

  onDecode(code) {
    let order = _.filter(this.filteredOrders, order => {
      return order.sessionData._id === code;
    });
    let myTextField = (this.$refs.myTextField as any).$el.querySelector("input");
    this.searchWord = order[0].sessionData.sessionName;
    setTimeout(() => {
      myTextField.select();
      this.scanning = false;
    }, 200);
  }

  editOrder(e: TableSession, owned = false) {
    if (e && e instanceof TableSession && e !== this.session) {
      this.session = e;
      if (this.curSession) {
        this.curSession.$destroy();
        this.curSession = null;
      }
      if (owned) {
        this.curSession = e;
      }
    }
    this.orderSystem = true;
  }
}
