import { WrappedContext } from "../common";
import { PrintJob } from "../printJob";
import { convertImage } from "../printSequence/escpos/image";
import { PrinterBaseConf } from "../printers/baseConf";
import { PrintSequence, TextAlign } from "./index";
import { RawLine, TextLine } from "./rawLine";
import { PrintTable } from "./table";

export class EPosSequence extends PrintSequence {
  cmds: string[] = [];

  constructor(
    public context: WrappedContext,
    printer: PrinterBaseConf,
  ) {
    super(context, printer);
    const codePage = printer?.conf?.opts?.codePage ?? "gbk";
    switch(codePage) {
      case "gbk":
        this.raw(Buffer.from(`<text lang="zh-hans" />`));
        break;
      case "big5":
        this.raw(Buffer.from(`<text lang="zh-hant" />`));
        break;
      case "shift_jis":
        this.raw(Buffer.from(`<text lang="ja" />`));
        break;
      case "euc-kr":
        this.raw(Buffer.from(`<text lang="ko" />`));
        break;
      default:
        this.raw(Buffer.from(`<text lang="en" />`));
        break;
    }
  }

  getText(text: string) {
    return Buffer.from(text, "utf-8");
  }

  imageAsBase64 = false;

  rawText(array: Buffer | number[]) {
    const text = Buffer.from(array).toString();
    const escaped = text.replace(
      /([&<>"'\t\n])/g,
      m =>
        ({
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&apos;",
          "\t": "&#9;",
          "\n": "&#10;",
        })[m],
    );
    this.raw(Buffer.from(escaped));
    return this;
  }

  async printImage(url: string, width: number, hiRes?: boolean | number) {
    hiRes = this.fixLineImageResolution(hiRes);

    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    const pxPerChar = this.useBitmapImage ? (hHi ? 2 : 1) : hHi ? 12 : 6;
    const pxPerLine = this.useBitmapImage ? (vHi ? 2 : 1) : vHi ? 24 : 8;
    const pxRatio = (pxPerChar / pxPerLine) * (this.useBitmapImage ? 1 : 2) * (this.fontWidth / this.fontHeight);

    const img = await this.convertImage(url, Math.ceil(width / 8) * 8, {
      pxPerLine,
      pxRatio,
    });
    if (!img) {
      console.warn(`Failed to load image: ${url}`);
      return this;
    }
    this.printImageData(img.buffer, img.width, img.height, hiRes);
    return this;
  }

  async printImageData(
    buffer: Uint8ClampedArray,
    width: number,
    height: number,
    hiRes?: boolean | number,
  ): Promise<this> {
    const originalWidth = width;
    width = Math.ceil(width / 8) * 8;

    const rows: Buffer[] = [];
    const b = width / 8;
    for (let i = 0; i < height; i++) {
      const buf = Buffer.alloc(b);
      let ofs = originalWidth * i * 4;
      let eofs = ofs + originalWidth * 4;

      for (let j = 0; j < b; j++) {
        let v = 0;
        for (let k = 0; k < 8; k++) {
          const mv = 128 >> k;
          const color = ofs >= eofs ? 255 : buffer[ofs];
          v |= color ? 0 : mv;
          ofs += 4;
        }
        buf[j] = v;
      }

      rows.push(buf);
    }

    this.raw(
      Buffer.from(`<image width="${width}" height="${height}">${Buffer.concat(rows).toString("base64")}</image>`),
    );
    return this;
  }

  printQR(url: string, numChars: number, w = 6) {
    const escaped = url.replace(
      /([&<>"'\t\n])/g,
      m =>
        ({
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&apos;",
          "\t": "&#9;",
          "\n": "&#10;",
        })[m],
    );
    this.raw(Buffer.from(`<symbol type="qrcode_model_2" width="${w}">${escaped}</symbol>`));
    return this;
  }

  feed(n: number) {
    this.raw(Buffer.from(`<feed unit="${n || 0}"/>`));
    return this;
  }

  cut() {
    this.raw(Buffer.from(`<cut/>`));
    return this;
  }

  cashBox(which?: number, time?: number): this {
    if (which >= 48) {
      which -= 48;
    }
    which = which || 0;
    time = time || 100;
    if (time < 100) time = 100;
    if (time > 500) time = 500;
    time = Math.floor(time / 100) * 100;

    this.raw(Buffer.from(`<pulse drawer="drawer_${which + 1}" time="pulse_${time}"/>`));
    return this;
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  align(n: TextAlign) {
    this.raw(
      Buffer.from(
        `<text align="${
          {
            [TextAlign.Left]: "left",
            [TextAlign.Center]: "center",
            [TextAlign.Right]: "right",
          }[n] ?? "left"
        }"/>`,
      ),
    );
    return this;
  }

  bold(b) {
    this.raw(Buffer.from(`<text em="${b ? "true" : "false"}"/>`));
    return this;
  }

  fontSize(w: number = 0, h: number = 0) {
    this.raw(Buffer.from(`<text width="${(w || 0) + 1}" height="${(h || 0) + 1}"/>`));
    return this;
  }

  color(n: number): this {
    this.raw(Buffer.from(`<text color="${n ? "color_2" : "color_1"}"/>`));
    return this;
  }

  getJobOpts() {
    return {
      ...super.getJobOpts(),
      type: "epos",
    };
  }

  getLine() {
    return Buffer.alloc(0);
  }

  getPreLine() {
    return Buffer.from(`<text>`);
  }

  getPostLine() {
    return Buffer.from("&#10;</text>");
  }
}
