
import { Vue, Component, Watch, FindType, getID, Prop, checkID, sortedIndexBy, Ref, mixins } from "@feathers-client";
import type OdsCarousel from "./carousel.vue";
import _ from "lodash";
import BaseShop, { OdsShop } from "./baseShop";

type TvOrderStatus = FindType<"tvOrderStatuses"> & { newItem?: boolean; marked?: boolean };
type OdsSetting = FindType<"posDevices/posLogin">["odsSetting"];

export interface CarouselItem {
  item: TvOrderStatus;
  shops?: Set<OdsShop>;
  newItem?: boolean;
}

@Component
export default class MultiShop extends mixins(BaseShop) {
  @Ref()
  carousel: OdsCarousel;

  get placement() {
    return this.odsSetting.multiSetting.placement || "horizontal";
  }

  items: CarouselItem[] = [];
  itemDict: Record<string, CarouselItem> = {};

  matchItem(item: TvOrderStatus) {
    return (
      (Date.now() - new Date(item.updateTime).getTime()) / 60000 < this.odsSetting.multiSetting.carouselKeepMinutes
    );
  }

  addCarouselItem(item: TvOrderStatus, shop: OdsShop, newItem?: boolean) {
    if (!this.odsSetting.multiSetting.carousel) return;
    if (!this.matchItem(item)) {
      return;
    }
    const cur = this.itemDict[getID(item)];
    if (cur) {
      cur.shops.add(shop);
      this.carousel?.scheduleUpdate();
    } else {
      const idx = sortedIndexBy(this.items, { item }, this.sortItem);
      const result = { item, shops: new Set([shop]), newItem: !!newItem };
      this.items.splice(idx, 0, result);
      this.itemDict[getID(item)] = result;
      this.carousel?.refresh();
    }
  }

  removeCarouselItem(item: TvOrderStatus, shop: OdsShop) {
    if (!this.odsSetting.multiSetting.carousel) return;
    const cur = this.itemDict[getID(item)];
    if (cur) {
      cur.shops.delete(shop);
      if (!cur.shops.size) {
        const idx = this.items.indexOf(cur);
        this.items.splice(idx, 1);
        delete this.itemDict[getID(item)];
        this.carousel?.scheduleUpdate();
      }
    }
  }

  updateCarouselItem(item: TvOrderStatus, shop: OdsShop, reorder: boolean) {
    if (!this.odsSetting.multiSetting.carousel) return;
    const cur = this.itemDict[getID(item)];
    if (cur) {
      const newMatch = this.matchItem(item);

      if (!newMatch || reorder) {
        const idx = this.items.indexOf(cur);
        this.items.splice(idx, 1);
      }

      if (newMatch && reorder) {
        const nidx = sortedIndexBy(this.items, cur, this.sortItem);
        this.items.splice(nidx, 0, cur);
      }
      this.carousel?.scheduleUpdate();
    } else {
      this.addCarouselItem(item, shop);
    }
  }

  sortItem(a: CarouselItem, b: CarouselItem) {
    if (a.item.updateTime === b.item.updateTime) {
      return a.item._id < b.item._id ? 1 : a.item._id > b.item._id ? -1 : 0;
    }
    return a.item.updateTime < b.item.updateTime ? 1 : a.item.updateTime > b.item.updateTime ? -1 : 0;
  }

  cleanUp() {
    if (!this.odsSetting.multiSetting.carousel) return;
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = this.items[i];
      if (!this.matchItem(item.item)) {
        this.items.splice(i, 1);
        delete this.itemDict[getID(item.item)];
      }
    }
  }
}
