
import { Component, Prop, Vue, Watch, mixins } from "@feathers-client";
import Version from "~/mixins/version";
import { getOfflineInfo, supportedOffline } from "pos-printer/utils/app";

@Component
export default class OfflineSetupDialog extends mixins(Version) {
  offlineActive = false;
  offlinePrefix = "";
  serviceWorker = false;

  formatVersion(buildInfo: string | any) {
    if (!buildInfo) return "-";
    try {
      let json = typeof buildInfo === 'string' ? JSON.parse(buildInfo) : buildInfo;
      return json.CI_PIPELINE_ID ?? "?";
    } catch (e) {
      console.warn(e);
    }
  }

  get draft() {
    return (
      this.offlineActive !== (this.$shop.device?.offlineActive ?? false) ||
      this.offlinePrefix !== (this.$shop.device?.offlinePrefix ?? "")
    );
  }

  async mounted() {
    this.reset();
    try {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then(() => {
          this.serviceWorker = true;
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  @Watch("$shop.device")
  reset() {
    this.offlineActive = this.$shop.device?.offlineActive ?? false;
    this.offlinePrefix = this.$shop.device?.offlinePrefix ?? "";
  }

  async save() {
    try {
      const active = this.offlineActive;
      await this.$feathers.service("posDevices/update").create({
        offlineActive: this.offlineActive,
        offlinePrefix: this.offlinePrefix,
      });
      this.reset();
      if (active) {
        await this.$shop.reload();
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async makeMain() {
    try {
      await this.$feathers.service("posDevices/update").create({
        offlineMain: true,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async resetOfflineData() {
    try {
      await this.$offline.cleanData();
      await this.$shop.reload();
      await this.$schemas.init(true);
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
