
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import _ from "lodash";
@Component({})
export default class MobileRecordOption extends Vue {
  initialOption = this.$shop.localOptions.handheldRecordOptions;
  selectingOption = [...this.initialOption];
  selectedOption = [...this.initialOption];
  resetOption = ["all", "all", "byUpdateTime", ["ongoing", "done", "cancelled", "void", "toPay"]];

  async mounted() {
    this.fetchTableViewData();
  }

  tableViewOption = [];

  async fetchTableViewData() {
    const result = await this.$feathers.service("tableViews").find();

    result["data"].map((item, index) => {
      if (this.tableViewOption[index] !== item._id) {
        const { _id, name } = item;
        this.tableViewOption[index] = Object.freeze({ _id, name });
      }
    });
    this.tableViewOption.unshift(
      Object.freeze({
        _id: "all",
        name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.tableView.all"),
      }),
    );
  }

  get optionList() {
    return [
      {
        _id: "tableView",
        title: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.tableView.$"),
        options: this.tableViewOption.map((item, index) => {
          return { _id: item._id, name: item.name };
        }),
      },
      {
        _id: "orderType",
        title: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.orderType.$"),
        options: [
          { _id: "all", name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.orderType.all") },
          {
            _id: "dineIn",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.orderType.dineIn"),
          },
          {
            _id: "takeAway",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.orderType.takeAway"),
          },
          {
            _id: "dineInNoTable",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.orderType.dineInNoTableNumber"),
          },
        ],
      },
      {
        _id: "recordSeq",
        title: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.recordSeq.$"),
        options: [
          {
            _id: "byCreateTime",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.recordSeq.byCreateTime"),
          },
          {
            _id: "byUpdateTime",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.recordSeq.byUpdateTime"),
          },
        ],
      },
      {
        _id: "status",
        title: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.$"),
        options: [
          {
            _id: "ongoing",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.ongoing"),
            icon: "$purpleTick",
          },
          {
            _id: "done",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.done"),
            icon: "$purpleTick",
          },
          {
            _id: "cancelled",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.cancelled"),
            icon: "$purpleTick",
          },
          {
            _id: "void",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.invalid"),
            icon: "$purpleTick",
          },
          {
            _id: "toPay",
            name: this.$t("pos.mobile.handheld.orderRecord.optionDialog.optionsTitle.status.unpay"),
            icon: "$purpleTick",
          },
        ],
      },
    ];
  }

  confirmOptionSelected() {
    const confirm = this.selectingOption.map((item, index) => {
      if (item !== this.selectedOption[index]) {
        return (this.selectedOption[index] = item);
      } else {
        return (this.selectedOption[index] = this.selectingOption[index]);
      }
    });
    this.selectingOption = confirm;
    this.$shop.localOptions.handheldRecordOptions = confirm;
  }

  resetSelectedOption() {
    this.selectingOption = this.resetOption;
  }

  get changeChecking() {
    const check = this.selectingOption.map((item, index) => {
      return item === this.selectedOption[index];
    });
    return _.every(check);
  }

  get initialOptionChecking() {
    const check = this.selectingOption.map((item, index) => {
      return item === this.resetOption[index];
    });
    return _.every(check);
  }

  disableTableView(index) {
    return (
      this.optionList[index]._id === "tableView" &&
      (this.selectingOption[1] === "takeAway" || this.selectingOption[1] === "dineInNoTable")
    );
  }

  @Watch("selectingOption")
  onWatchForDisableTableView() {
    if (this.selectingOption[1] === "takeAway" || this.selectingOption[1] === "dineInNoTable") {
      this.selectingOption[0] = "all";
    }
  }
}
