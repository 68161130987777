
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ReservationPlateLayout } from '~/plugins/table/view';
import moment from 'moment';

const oneHour = moment.duration(60, 'minutes').asMinutes();
const halfHour = moment.duration(30, 'minutes').asMinutes();

@Component
export default class ReservationPlate extends Vue {
    @Prop({ required: true })
    layout : ReservationPlateLayout;
    @Prop({ type: Number, default: 85 })
    width : number;
    @Prop({ type: Number, default: 39 })
    height : number;

    get name() {
        // TODO: Get Data from item or something
        return 'Mr. Hoge';
    }

    get time() {
        // TODO: Get Data from item or something
        return moment().add(300, 'minutes').toDate();
    }

    get timeDiff() {
        return moment(this.time).diff(moment(), 'minutes');
    }

    get bgColor() {
        if (this.timeDiff > oneHour) {
            return 'none';
        } else if (this.timeDiff > halfHour) {
            return '#DF6D6D';
        }
        return '#C44D4D';
    }

    get bgOpacity() {
        return this.timeDiff > oneHour ? '0.0' : '1.0';
    }

    get groupConfig() {
        return {
            x: -this.layout.w / 2,
            y: -this.layout.h / 2,
        }
    }

    get configPlate() {
        const w = this.layout.w / 1.25;
        const h = this.layout.h / 1.25;

        return {
            id: 'reservation-plate',
            w: w,
            h: h,
            x: this.layout.item.w / 2,
            y: this.layout.item.h / 2,
            offset: {
                x: - w / 3,
                y: - h / 2
            },
            cache: true,
            perfectDrawEnabled: false,
            listening: false,
        };
    }

    get configName() {
        const w = this.layout.w / 1.3;
        const h = this.layout.h / 1.3;

        return {
            x: this.layout.item.w / 2,
            y: this.layout.item.h / 2,
            offset: {
                x: - w / 3,
                y: - h / 1.5
            },
            width: w,
            height: h / 3,
            align: 'center',
            text: this.name,
            fontSize: Math.min(w / 5, h / 4, 24),
            perfectDrawEnabled: false,
            listening: false,
            fill: 'white'
        }
    }

    get configTime() {
        const w = this.layout.w / 1.2;
        const h = this.layout.h / 1.2;

        return {
            x: this.layout.item.w / 2,
            y: this.layout.item.h / 2,
            offset: {
                x: - w / 2,
                y: - h
            },
            align: 'center',
            text: moment(this.time).format('HH:mm'),
            fontSize: Math.min(w / 4, h / 2.5, 36),
            fontStyle: 'bold',
            perfectDrawEnabled: false,
            listening: false,
            fill: 'white'
        }
    }
}
