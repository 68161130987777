var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"w-full whitespace-nowrap",class:[
      _vm.wrap || _vm.grid ? '' : 'md:overflow-x-auto md:overflow-y-hidden scrollable',
      _vm.expandable && !_vm.expanded ? 'overflow-hidden' : '',
    ],style:({
      '--item-height': _vm.grid ? '100%' : _vm.itemHeight,
      height: _vm.expandable && !_vm.expanded ? `calc(2*${_vm.itemHeight} + 1.5rem) ` : '100%',
    })},[_c('div',{staticClass:"items-center btn-container <md:flex-wrap gap-y-4",class:[{ 'flex-wrap': _vm.wrap, light: _vm.light }, _vm.grid ? `grid h-full ${_vm.grid}` : 'd-flex'],attrs:{"tag":"div"}},[_vm._t("items",function(){return _vm._l((_vm.items),function(item,idx){return _c('div',{key:item._id || idx,staticClass:"h-$item-height",class:[{ selected: _vm.isSelected(item), disabled: _vm.disabled }, _vm.itemClass],on:{"click":function($event){return _vm.toggleSelected(item)}}},[_c('div',{staticClass:"list-item",class:{ disabled: _vm.disabled },attrs:{"role":"button"}},[(_vm.radio)?_c('radio-icon',{staticClass:"mr-2",attrs:{"value":_vm.isSelected(item)},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSelected(item, true)}}}):_vm._e(),(_vm.check)?_c('check-icon',{staticClass:"mr-2",attrs:{"value":_vm.isSelected(item)},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSelected(item, true)}}}):_vm._e(),_vm._t("itemContent",function(){return [_vm._v(" "+_vm._s(_vm.$td(item.name))+" ")]},{"item":item,"index":idx})],2)])})}),_vm._t("after"),(_vm.clearable)?_c('div',{key:"after",staticClass:"d-flex self-end space-x-1.5 py-2 px-1.5"},[_c('div',{key:"clear",staticClass:"text-sm btn-container text-center p-2",class:[{ disabled: _vm.disabled, light: _vm.light }, _vm.itemClass],on:{"click":_vm.clearSelected}},[_c('div',{staticClass:"text-sm text-center rounded-lg px-3 h-$item-height not-item d-flex items-center",attrs:{"role":"button"}},[_vm._v(" "+_vm._s(_vm.$t("import.clear"))+" ")])])]):_vm._e()],2)]),(_vm.expandable)?_c('div',{staticStyle:{"color":"#7369de","padding":"0 0.25rem","margin-top":"0.25rem","text-decoration-line":"underline"},attrs:{"role":"button"},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v(" "+_vm._s(!_vm.expanded ? _vm.$t("basic.showMore") : _vm.$t("basic.showLess"))+" ")]):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }