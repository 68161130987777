
import { Prop, Component, Watch, mixins, FindType, FindPopRawType, getID, Ref } from "@feathers-client";
import "~/plugins/table";
import { TableSession } from "~/plugins/table/session";
import "pos-printer/scanner";
import _ from "lodash";
import OrderSystemMobileBase from "~/components/table/orderSystem/mobile/mobileBase";
import moment from "moment";
import { getSplitName } from "~/plugins/table/utils";
import { ScannerEvent } from "pos-printer/scanner";

type CouponType = FindPopRawType<["coupon"], "userCoupons">;

@Component({})
export default class Mobile extends mixins(OrderSystemMobileBase) {
  @Prop()
  posMode: String;

  // NOTE: Merchant Portal CRM
  get point() {
    return this.$shop.shopPoints?.find(it => it.tag === "point");
  }

  get dollar() {
    return this.$shop.shopPoints?.find(it => it.tag === "dollar");
  }

  loadedShop = false;
  userId: string = "";
  topUpUser: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers"> = null;
  useDollarUser: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers"> = null;

  topUpAmount: number = 0;
  topUpAmountString = "";

  scannedCoupon: CouponType = null;

  couponConfirm = false;

  payingAmount: number = 0;
  payingAmountString = "";

  @Watch("session.status")
  onWatchSessionStatus() {
    console.log("status : ", this.session?.status);
  }

  async mounted() {
    await this.initMerchantPortal();
  }

  beforeDestroy() {
    this.$scanner.unregisterHandler(this.onScanner);
  }

  head() {
    return {
      meta: [
        {
          hid: "theme-color",
          name: "theme-color",
          content: this.$config.colors.blue000,
        },
      ],
    };
  }

  @Watch("currentPage")
  onWatchCurrentPage() {
    if (this.currentPage !== "record") {
      if (this.recordOption) {
        this.recordOption = false;
      }
    }
  }

  async initMerchantPortal() {
    await this.$shop.loadShopTask;
    if (this.router != null) {
      if (this.$shop?.shopData?.enableMerchantPortal) {
        this.router?.reset?.("merchantPortal");
        this.orderType = "crm";
        this.merchantPortalMode = true;
      } else {
        this.router?.reset?.("tables");
        this.setOrderType("dineIn");
      }
    }
  }

  async goTab(tab: string) {
    if (this.totalQuantity > 0) {
      const confirm = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog2.vue"),
        {
          title: this.$t("mobile.backButtonPrompt.$"),
          content: this.$t("mobile.backButtonPrompt.content"),
          dialogClass: "bg-dark-surface-default",
          cancelText: "dark-action-primary-default",
        },
        {
          maxWidth: "80%",
        },
      );
      if (!confirm) return;
    }

    await this.setOrderType("dineIn");
    if (this.currentPage === tab) return;
    this.currentPage = tab;
    this.router?.reset?.(tab, true);
  }

  async setOrderType(type: string) {
    this.orderType = type;
    if (type === "dineIn") {
      this.router?.reset?.("tables");
    } else {
      this.createTakeawaySession();
    }
  }

  async openSessionId(id: string) {
    const session = await this.$feathers.service("tableSessions").get(id);
    await this.pickSession(session);
  }

  async pickSessionById(id: string) {
    try {
      const session = await this.$feathers.service("tableSessions").get(id);
      await this.pickSession(session);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  async confirm() {
    if (!this.code) return;
    const code = this.code.padStart(3, "0");
    const sessions = [
      this.$tableManager.sessions.filter(s => s.sessionNameNumber === code),
      this.$tableManager.takeAways.filter(s => s.sessionNameNumber === code),
      this.$tableManager.dineInNoTables.filter(s => s.sessionNameNumber === code),
    ].flat();
    if (sessions.length === 1) {
      this.pickSessionById(sessions[0]._id);
      this.searchRecordNumPad = false;
    } else {
      this.sessions = sessions;
      this.code = "";
    }
  }

  async createTakeawaySession() {
    await this.openSession();
    this.takeawayMode = true;
    this.openTable = true;
    this.autoSelectTimeSection;
  }

  async openSession(session?: FindType<"tableSessions">) {
    if (this.session) {
      this.session.$destroy();
      this.session = null;
    }
    this.lastCart = null;
    this.session = new TableSession({
      parent: this,
    });
    this.session.detach();
    this.session.init(
      session || {
        tables: [],
        type: this.orderType as any,
        startTime: new Date(),
        view: null,
      },
      true,
    );
    if (!session) {
      this.session.pauseSave();
    } else {
      this.session.startSubscribe();
    }
  }

  async pickTable(table: FindType<"tableViewItems">) {
    this.$shop.localOptions.simpleOrderingFlow = true;
    this.selectedTable = table;
    const activeSessions = (await this.$feathers.service("tableSessions").find({
      query: {
        ...this.filterSessionByTable,
      },
    } as any)) as any;
    if (!activeSessions.total) {
      await this.createNewOrderInner([]);
      return;
    }
    if (activeSessions.total === 1) {
      await this.pickSession(activeSessions.data[0]);
    } else {
      this.filteringByTable = true;
      this.activeSessionsTable = true;
    }
  }

  async showTableSessions(table: FindType<"tableViewItems">) {
    this.selectedTable = table;
    const activeSessions = await this.$feathers.service("tableSessions").find({
      query: {
        ...this.filterSessionByTable,
      },
    } as any);

    if (activeSessions["total"] === 0) {
      this.selectedTable = null;
      return;
    }
    this.filteringByTable = true;
    this.activeSessionsTable = true;
  }

  isSessionFinished(session: FindType<"tableSessions">) {
    return session.status === "done" || session.status === "cancelled" || session.status === "void" ? true : false;
  }

  async pickSession(session: FindType<"tableSessions">, makeOrder: boolean = true) {
    await this.openSession(session);
    if (this.isSessionFinished(session)) {
      this.router?.reset?.("done");
    } else if (this.session.isPaying) {
      if (this.$shop?.shopData?.handheldPaymentEnabled) {
        this.router?.navigate?.("picker");
        this.router?.navigate?.("cart");
        this.router?.navigate?.("checkout");
        return;
      } else {
        this.$store.commit(
          "SET_ERROR",
          this.$t("pos.mobile.handheld.payment.paymentFunctionNotEnable", {
            status: this.$t("pos.mobile.handheld.payment.pay"),
          }),
        );
      }
    } else {
      if (makeOrder) {
        this.router?.navigate?.("categories");
      } else {
        this.router?.navigate?.("cart");
      }
    }
    this.activeSessionsTable = false;
  }

  async createNewOrder() {
    const activeSessions = (await this.$feathers.service("tableSessions").find({
      query: {
        ...this.filterSessionByTable,
        $paginate: false,
      },
    } as any)) as any;
    await this.createNewOrderInner(activeSessions);
    this.activeSessionsTable = false;
  }

  async createNewOrderInner(sessions: FindType<"tableSessions">[]) {
    await this.openSession();
    this.session.sessionData.view = this.selectedTable.view;
    const split = this.nextSessionSplit(sessions);
    this.session.tables = [
      {
        item: this.selectedTable._id,
        capacity: 1, // NOTE: amended to "1" as UX flow want to initial the table start with 1 person
        split,
      },
    ];
    this.session.sessionData.tableRefName = this.selectedTable.name + getSplitName(split);
    this.openTable = true;
    this.autoSelectTimeSection();
    // this.router?.navigate?.("orderType");
    return;
  }
  async resetAll() {
    this.selectedCat = null;
    this.selectedSubCat = null;
    this.selectedTable = null;
    this.lastCart = null;
    this.takeawayMode = false;

    if (this.session) {
      this.session.$destroy();
      this.session = null;
    }

    await this.setOrderType("dineIn");
  }

  async cancelOrderHandler() {
    // this.session.tableRefs = [{ session: this.session, split: this.session.tables[0].split }];
    if ((await this.cancelOrder()) == true) {
      this.currentPage = "mode";
      this.resetAll();

      this.pickerOption = false;
    }
  }

  removeItem() {
    if (this.editingCart) {
      const cart = this.editingCart;
      cart.remove();
      this.editingCart = null;
      if (this.lastCart == cart) {
        this.lastCart = this.currentCart.filter(it => !it.fromProduct && !it.fromCoupon).slice(-1)[0] || null;
      }
    }
    this.goBack();
  }

  async goBack() {
    if (this.currentPage === "merchantPortalDone") {
      this.router?.reset?.("merchantPortal");
    }
    if (this.currentPage === "merchantPortal" || this.currentPage === "tables" || this.currentPage === "record") {
      this.$root.$emit("toggleDrawer");
    }
    if (this.currentPage === "checkout") return this.cancelBilling();

    if (
      (this.session?.status === "done" || this.session?.status === "cancelled" || this.session?.status === "void") &&
      this.currentPage === "done"
    )
      return this.router?.reset("tables");
    if (this.currentPage === "categories" || this.currentPage === "picker" || this.currentPage === "cart") {
      if (this.currentPage === "picker") {
        if (this.selectedSubCat) {
          this.selectedSubCat = null;
          this.router?.navigate?.();
          return;
        } else if (this.selectedCat) {
          this.selectedCat = null;
          this.router?.reset?.("categories");
          return;
        }
      } else if (this.currentPage === "categories") {
        if (this.totalQuantity > 0) {
          const confirm = await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog2.vue"),
            {
              title: this.$t("mobile.backButtonPrompt.$"),
              content: this.$t("mobile.backButtonPrompt.content"),
              dialogClass: "bg-dark-surface-default",
              cancelText: "dark-action-primary-default",
            },
            {
              maxWidth: "80%",
            },
          );
          if (!confirm) return;
        }
        this.resetAll();
      } else if (this.currentPage === "cart") {
        this.router?.reset?.("categories");
        this.router?.navigate?.("picker");
      }
    }
    this.router?.navigate?.();
  }

  async applyUserInfo(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers">) {
    if (!user) return;
    if (user?.vipLevel && typeof user.vipLevel === "object" && user.status === "active") {
      const vipLevel: FindType<"vipLevels"> = user.vipLevel as any;
      await this.session.tryApplyVip(vipLevel);
    }

    if (user?.ranks && user?.ranks.length) {
      for (let rank of user?.ranks) {
        await this.session.tryApplyRank(rank.rank);
      }
    }

    this.session.item.userName = user.name ?? this.session.userName;
    this.session.item.userPhone = user.phone ?? this.session.userPhone;
    this.session.item.userGender = user.gender ?? this.session.userGender;
    this.session.delaySave({
      user: this.session.user,
      discounts: this.session.discounts,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  flushUpdates() {
    this.session.updateCoupons();
    this.session.delaySave({
      section: this.session.section,
      discounts: this.session.discounts,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  async applyUser(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers">) {
    if (this.session.user !== (user as any)?._id ?? null) {
      this.session.user = user?._id ?? null;
      this.session.setUser(user?._id ?? (null as any));

      if (user?.vipLevel && user.status === "active") {
        const vipLeve = user.vipLevel;
        await this.session.tryApplyVip(user.vipLevel);
      }

      if (user?.ranks && user?.ranks.length) {
        for (let rank of user?.ranks) {
          await this.session.tryApplyRank(rank.rank);
        }
      }

      await this.session.atomic({
        user: user?._id ?? null,
        discounts: this.session.discounts,
        userName: this.session.userName ? this.session.userName : user.name ?? "",
        userPhone: this.session.userPhone ? this.session.userPhone : user.phone ?? "",
        userGender: user.gender ?? this.session.userGender,
        ...this.session.cachedPriceDetails,
      });

      await this.$feathers.service("actionLogs").create({
        session: getID(this.session.item._id),
        view: getID(this.session.item.view),
        staff: this.$shop.staffId,
        type: user ? "orderManage/tableSessionSelectMember" : "orderManage/tableSessionDeselectMember",
        detail: { user },
      });

      this.session.syncOrder();
    }

    if (this.currentAction === "useDollar") {
      this.useDollarUser = user;
    }
  }

  removeUser() {
    this.applyUser(null);
  }

  get transactionTabs() {
    return [
      { id: "coupon", name: this.$t(`merchantPortal.pages.transaction.useCoupon`) },
      ...(this.point
        ? [
            {
              id: "point",
              name: this.point ? this.$td(this.point.name) : this.$t(`merchantPortal.pages.transaction.point`),
            },
          ]
        : []),
      ...(this.dollar
        ? [
            {
              id: "dollar",
              name: this.dollar ? this.$td(this.dollar.name) : this.$t(`merchantPortal.pages.transaction.dollar`),
            },
          ]
        : []),
    ];
  }

  get availDollar() {
    return Math.max(0, this.session?.availPoints?.[this.dollar?._id] ?? 0);
  }

  get pointEarned() {
    return _.sumBy(
      this.session.coupons.filter(c => !!c.pointValue && c.valid && c.rule.point === this.point?._id),
      it => it.pointValue,
    );
  }

  get shopLogo() {
    return this.$shop?.shopData?.images[0];
  }

  onDecode(code) {
    this.$root.$emit("scanner", { code });
    setTimeout(() => {
      this.scanning = false;
    }, 500);
  }
  async onScanner(item: ScannerEvent) {
    const code = `${item.code || ""}`.trim();
    if (!code) return;

    const idMatches = code.match(/^([a-f\d]{24})(?:\/([\d]+))?$/i);
    if (idMatches) {
      item.handled = true;
      await this.pickSessionById(idMatches[1]);
      return;
    }
    if (code.startsWith(this.$shop.selfOrder)) {
      // self order url
      const u = code.substr(this.$shop.selfOrder.length);
      const match = u.match(/\/t\/(.+)\/(.+)?/);
      if (match) {
        item.handled = true;
        await this.pickSessionById(match[1]);
      }
    }

    if (this.session && !this.isPaying && !this.isOngoing) {
      return;
    }

    if (this.handlingCode) {
      return;
    }

    this.handlingCode = true;
    const imeActive = item.imeActive;

    try {
      if (
        !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,8}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
          code,
        )
      ) {
        this.$store.commit("SET_ERROR", this.$t("scanner.invalidCode"));
        return;
      }

      const domains = [this.$config.selfOrder, this.$config.appDomain].filter(it => !!it);

      const domain = domains.find(d => code.startsWith(d));

      const supacityUrl = "https://wallet.okinawa/u/";
      if (code.startsWith(supacityUrl)) {
        const resp = await this.$feathers.service("payments/supacity/validate").create({
          token: code.substring(supacityUrl.length),
        });
        const user = await this.$feathers.service("appUsers").get(resp.user, {
          query: {
            $populate: ["vipLevel", "ranks.rank"],
          },
        } as const);
        await this.applyUser(user);
        this.session.supacityInfo = resp.info;
        return;
      }

      if (!domain) {
        console.log("invalid domain", domains);
        this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidDomain"));
        return;
      }
      // self order url
      const u = code.substr(domain.length);
      const match = u.match(/\/(u|c|g)\/(.+)(?:\/)?(.+)?/);
      if (!match) {
        console.log("invalid match");
        this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidCodeType"));
        return;
      }

      const buf = Buffer.from(urlbase64(match[2]), "base64");
      const ts = moment.unix(buf.readUInt32LE(0));

      if (moment().subtract(5, "minutes").isAfter(ts)) {
        console.log("expired");
        this.$store.commit("SET_ERROR", this.$t("basic.qrCodeTimeout"));
        return;
      }

      const isCoupon = match[1] === "c";
      const isGift = match[1] === "g";

      let ofs = 4;
      const uid = buf.slice(ofs, (ofs += 12));
      const cid = isCoupon ? buf.slice(ofs, (ofs += 12)) : null;
      const gid = isGift ? buf.slice(ofs, (ofs += 12)) : null;
      const hash = buf.slice(ofs);

      const [userP, couponP] = await Promise.allSettled([
        (async () => {
          return await this.$feathers.service("appUsers").get(uid.toString("hex"), {
            query: {
              $populate: ["vipLevel", "ranks.rank"],
            },
          } as const);
        })(),
        (async () => {
          if (!cid) return;
          return await this.$feathers.service("userCoupons").get(cid.toString("hex"), {
            query: {
              $populate: ["coupon"],
            } as const,
          });
        })(),
      ]);

      const user = userP.status === "fulfilled" ? userP.value : null;
      const coupon = couponP.status === "fulfilled" ? couponP.value : null;

      if (!user) {
        this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidCode"));
        return;
      }

      const supk: string = user.publicKey;
      if ((isCoupon && !coupon) || !user || !supk) {
        this.$store.commit("SET_ERROR", this.$t("scanner.invalidCode"));
        return;
      }

      if (user.vipLevel && user.status != "active") {
        this.$store.commit("SET_ERROR", this.$t("scanner.vipOutdate"));
        return;
      }

      // verify code sign

      if (!supk) {
        this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidUser"));
        return;
      }
      const pk = supk
        .slice(
          supk.indexOf("-----BEGIN PUBLIC KEY-----") + "-----BEGIN PUBLIC KEY-----".length,
          supk.indexOf("-----END PUBLIC KEY-----"),
        )
        .replace(/\n|\r/g, "");

      const upk = await crypto.subtle.importKey(
        "spki",
        Buffer.from(pk, "base64"),
        {
          name: "ECDSA",
          namedCurve: "P-256",
          hash: "SHA-256",
        },
        true,
        ["verify"],
      );

      if (
        !(await crypto.subtle.verify(
          {
            name: "ECDSA",
            hash: "SHA-256",
          },
          upk,
          hash,
          buf.slice(0, ofs),
        ))
      ) {
        console.log("invalid sign");
        this.$store.commit("SET_ERROR", imeActive ? this.$t("scanner.imeActive") : this.$t("scanner.invalidUserSign"));
        return;
      }

      await this.applyUser(user);

      // verify coupon status
      if (coupon) {
        await this.addCoupon(coupon);
      }

      if (isGift) {
        try {
          const gift = await this.$feathers.service("gifts").get(gid.toString("hex"), {
            query: {
              $populate: ["coupon"],
            },
          } as const);
          const resp = await this.$openDialog(
            import("../redeemGift.vue"),
            {
              gift,
              user,
              session: this.session,
            },
            {
              maxWidth: "max(50vw,500px)",
            },
          );
          if (resp) {
            if (resp.type === "redeem") {
              this.session.tempGifts.push(gift as any);
            } else if (resp.type === "coupon") {
              await this.addGift(gift);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
      this.$store.commit("SET_ERROR", this.$t("scanner.notTheShop"));
    } finally {
      this.handlingCode = false;
      this.scanning = false;
    }
  }

  async changeLocale(item) {
    await this.$i18n.loadLocale(item.code);
    this.$i18n.locale = item.code;
    this.lang = false;
  }

  async backToMemberDetail() {
    this.userId = getID(this.session.user);
    this.router?.reset?.("merchantPortal");
    this.router?.navigate?.("memberDetail");
  }

  async openEarnPointSession() {
    this.orderType = "crm";
    this.payingAmount = 0;
    this.payingAmountString = "";
    this.router?.navigate?.("earnPoint");
    this.currentAction = "earnPoint";
  }

  async openUseDollarSession() {
    this.orderType = "crm";
    this.payingAmount = 0;
    this.payingAmountString = "";
    this.useDollarUser = null;
    await this.openSession();
    this.router?.navigate?.("merchantConfirm");
    this.currentAction = "useDollar";
  }

  async openTopUpSession(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers"> = null) {
    this.orderType = "topUp";
    this.currentAction = "topUp";
    this.router?.navigate?.("topUp");
    this.topUpUser = user;
    this.topUpAmount = 0;
    this.topUpAmountString = "";
  }

  async openCouponSession() {
    this.orderType = "hidden";
    this.openSession();
    this.currentAction = "useCoupon";
    this.router?.navigate?.("useCoupon");
    this.couponConfirm = false;
  }

  async openTransaction() {
    this.router?.navigate?.("transaction");
  }

  async onConfirmCouponUse() {
    if (this.couponConfirm) {
      this.session.addToCart(
        {
          quantity: 1,
        },
        {
          customProduct: { name: "couponOpenKey", price: 0 } as any,
        },
      );

      await this.placeOrder();
      await this.goCheckBill();
    } else {
      this.couponConfirm = true;
    }
  }

  async addTopUpItem() {
    this.openSession().then(async () => {
      if (this.topUpUser) this.applyUser(this.topUpUser);
      this.topUpUser = null;

      if (!this.dollar?.topUpRatioInt) {
        return;
      }

      this.session.addToCart(
        {
          quantity: 1,
        },
        {
          customProduct: { name: "dollarOpenKey", price: this.topUpAmount * this.dollar.topUpRatioInt } as any,
        },
      );

      this.session.topUp = { point: this.dollar._id, value: this.topUpAmount };

      await this.placeOrder();

      this.router?.navigate?.("merchantConfirm");
    });
  }

  async addPointItem() {
    this.openSession().then(async () => {
      this.session.addToCart(
        {
          quantity: 1,
        },
        {
          customProduct: { name: "pointOpenKey", price: this.payingAmount } as any,
        },
      );

      await this.placeOrder();

      this.router?.navigate?.("merchantConfirm");
    });
  }

  async addDollarItem() {
    this.openSession().then(async () => {
      await this.applyUser(this.useDollarUser);

      this.session.addToCart(
        {
          quantity: 1,
        },
        {
          customProduct: { name: "useDollarOpenKey", price: this.payingAmount } as any,
        },
      );

      await this.placeOrder();
      await this.goCheckBill();
    });
  }

  async merchantConfirm() {
    if (this.currentAction === "useDollar") {
      this.router?.navigate?.("useDollar");
    } else {
      await this.goCheckBill();
    }
  }

  async goCheckBill() {
    await this.session.confirmOrder();
    if (this.session.type === "crm" && this.session.totalPriceBeforeDiscount !== this.session.totalPrice) {
      this.$store.commit("SET_ERROR", "Discount Detected");
    }
    if (this.$config.features.turnCloud && this.merchantPortalMode) {
      this.session.delaySave({ twTaxType: "none" });
    }
    if (this.session.isPaying) {
      this.router?.navigate?.("checkout");
    }
  }
}

function urlbase64(buf: Buffer | string) {
  return (typeof buf === "string" ? buf : buf.toString("base64")).replace(/\-/g, "+").replace(/_/g, "/");
}
