
import { FindType } from "@feathers-client";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import Dialog from "~/mixins/DialogTS";

@Component({})
export default class PaymentMethodTemplate extends mixins(Dialog) {
  availableTemplates: FindType<"paymentMethods/availbleTemplates"> = [];

  async mounted() {
    this.availableTemplates = await this.$feathers.service("paymentMethods/availbleTemplates").find({});
  }
}
