import { convertImage } from "../../printSequence/escpos/image";
import { LabelSequenceBase, fonts } from "../base";
import { CanvasPrintJob, LabelCanvasJob } from "./job";

export class CanvasLabelSequence extends LabelSequenceBase {
  job: LabelCanvasJob;
  jobs: LabelCanvasJob[] = [];
  imageMode: "dither" | "colorAlpha" | "color" | "gray" | "mono" = "dither";
  imageAsBase64 = false;

  initInner() {
    this.reset();
  }

  size(w: number, h: number) {
    this._curWidth = w;
    this._curHeight = h;
    this.job.w = w;
    this.job.h = h;
    return this;
  }

  gap(w: number, h: number) {
    return this;
  }

  feed(n: number) {
    return this;
  }

  rect(x: number, y: number, w: number, h: number) {
    this.job.cmds.push({
      type: "rect",
      x,
      y,
      w,
      h,
    });
    return this;
  }

  async textRaw(x: number, y: number, text: string, font = "1", sizeW = 1, sizeH = 1, r = 0) {
    this.job.cmds.push({
      type: "text",
      x,
      y,
      text,
      font,
      sizeW,
      sizeH,
      r,
    });
    return this;
  }

  async vtext(text: string, x: number, y: number, font = "1", size = 1) {
    this.job.cmds.push({
      type: "vtext",
      x,
      y,
      text,
      font,
      sizeW: size,
      sizeH: size,
      r: this._r,
    });
    return this;
  }

  async printImageData(x: number, y: number, buffer: Uint8ClampedArray, width: number, height: number): Promise<this> {
    this.job.cmds.push({
      type: "image",
      w: width,
      h: height,
      x,
      y,
      ...(this.imageAsBase64 ? { base64: Buffer.from(buffer).toString("base64") } : { data: buffer }),
    });
    return this;
  }

  qrcode(text: string, x: number, y: number, ecc = "H", w = 4) {
    this.job.cmds.push({
      type: "qrcode",
      x,
      y,
      ecc,
      w,
      r: this._r,
      text,
    });
    return this;
  }

  barcode(text: string, x: number, y: number, h = 10) {
    this.job.cmds.push({
      type: "barcode",
      x,
      y,
      h,
      text,
    });
    return this;
  }

  reset() {
    super.reset();
    this.job = {
      w: this.job?.w,
      h: this.job?.h,
      cmds: [],
      count: 1,
      counters: {},
    };
    return this;
  }

  print(n: number) {
    this.job.count = +n || 1;
    this.jobs.push(this.job);
    this.reset();
    return this;
  }

  counter(id: number, step: number, start: string) {
    this.job.counters[id] = {
      step,
      start,
      id,
    };
    return this;
  }

  getJob(name?: string, id?: string) {
    return new CanvasPrintJob(name, this.jobs, this.dpi, id, {
      type: "label",
    });
  }

  async printImage(x: number, y: number, url: string, width: number) {
    const img = await convertImage(url, Math.ceil(width / 8) * 8, this.imageMode);
    this.job.cmds.push({
      type: "image",
      x,
      y,
      w: img.width,
      h: img.height,
      data: img.buffer,
    });
    return this;
  }
}
