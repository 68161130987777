
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";

@Component
export default class StockWarnDialog extends mixins(Dialog) {

  @Prop()
  values;

  get outStockList() {
    return this.values.filter((item) => item.stock);
  }

  get outIngredientList() {
    return this.values.filter((item) => item.ingredients);
  }

  image(img) {
    return img ? this.$thumb(img) : require('~/assets/images/logo.png')
  }

  mounted() {
    // setTimeout(() => this.modalResult(true), 2000);
  }
}
