
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { getID } from "@feathers-client";
import { roundWithFactor } from "~/common/table/util";

@Component
export default class OrderSystemDiscount extends Vue {
  @Prop()
  session: TableSession;

  numPad = false;

  manualDir: "decrease" | "increase" = "decrease";

  tempByPercentage = 0;
  byManual = "";

  tab: string = "manual";
  selectedPercentage: string = "";

  tempAdjustAmount = 0;
  saving = false;

  reset() {
    this.manualDir = this.session.adjusts < 0 ? "decrease" : "increase";
  }

  @Watch("tab")
  onTabChanged() {
    if (this.tab === "manual") {
      this.tempByPercentage = 0;
      this.selectedPercentage = "";
    }
  }

  async applyPercentage(item) {
    this.selectedPercentage = item._id;
    if (item._id !== "manual") {
      this.tempByPercentage = item.value;
      await this.confirmPercentage();
    }
  }

  roundDiscount(amount: number) {
    return roundWithFactor(amount, this.$shop.shopData?.discountRoundFactor, this.$shop.shopData?.discountRoundMethod);
  }

  async confirmPercentage() {
    this.tempAdjustAmount = this.roundDiscount(-(this.tempByPercentage / 100) * this.session.adjustablePrice);
    const staff = await this.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
    if (staff === false) return;

    await this.$feathers.service("actionLogs").create({
      session: this.session.item._id,
      view: getID(this.session.item.view),
      staff: staff?._id || this.$shop.staffId,
      type: `paymentManage/tableSessionDiscount`,
      detail: { adjust: 100 - this.tempByPercentage, percentage: true },
    });
    await this.flushUpdates();
  }

  async removePercentage() {
    const staff = await this.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
    if (staff === false) return;

    await this.$feathers.service("actionLogs").create({
      session: this.session.item._id,
      view: getID(this.session.item.view),
      staff: staff?._id || this.$shop.staffId,
      type: `paymentManage/tableSessionDiscount`,
      detail: { adjust: 100, percentage: true },
    });

    this.selectedPercentage = "";
    this.tempAdjustAmount = 0;

    await this.flushUpdates();
  }

  get amountStr() {
    if (this.numPad || this.saving) {
      const parts = this.byManual.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.session.adjusts * (this.manualDir === "decrease" ? -1 : 1));
    }
  }

  get amountPadding() {
    if (this.numPad || this.saving) {
      if (!this.byManual) return "0.00";
      const parts = this.byManual.split(".");
      if (parts.length === 1) return ".00";
      else if (parts[1].length === 0) return "00";
      else if (parts[1].length === 1) return "0";
    }
    return "";
  }

  async adjustAction(action: number | string) {
    if (action === "$tick") {
      await this.confirmAmount();
    } else if (action === "C" || this.byManual === "") {
      this.tempAdjustAmount = 0;
      this.byManual = "";
    }
  }

  async confirmAmount() {
    if (!isNaN(+this.byManual)) {
      this.tempAdjustAmount = +this.byManual * (this.manualDir === "decrease" ? -1 : 1);
    }

    this.saving = true;
    try {
      const staff = await this.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
      if (staff === false) {
        return;
      }

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: `paymentManage/tableSessionDiscount`,
        detail: { adjust: this.tempAdjustAmount * (this.manualDir === "decrease" ? -1 : 1) },
      });

      await this.flushUpdates();
    } finally {
      this.saving = false;
    }
  }

  get manualAdjusts() {
    return [
      { _id: "decrease", name: this.$t("pos.mobile.checkout.tabs.decrease") },
      { _id: "increase", name: this.$t("pos.mobile.checkout.tabs.increase") },
    ];
  }

  get items() {
    return [
      { _id: "manual", name: this.$t("pos.mobile.checkout.tabs.manual"), icon: "$discountManual" },
      { _id: "percent", name: this.$t("pos.mobile.checkout.tabs.percentage"), icon: "$discountPercent" },
    ];
  }

  get presetPercentage() {
    return [
      { _id: "manual", name: this.$t("pos.mobile.checkout.adjustByPercentage.manual"), value: this.tempByPercentage },
      { _id: "allFree", name: this.$t("pos.mobile.checkout.adjustByPercentage.allFree"), value: 100 },
      { _id: "90off", name: this.$t("pos.mobile.checkout.adjustByPercentage.90off"), value: 10 },
      { _id: "75off", name: this.$t("pos.mobile.checkout.adjustByPercentage.75off"), value: 25 },
      { _id: "50off", name: this.$t("pos.mobile.checkout.adjustByPercentage.50off"), value: 50 },
      { _id: "25off", name: this.$t("pos.mobile.checkout.adjustByPercentage.25off"), value: 75 },
      { _id: "10off", name: this.$t("pos.mobile.checkout.adjustByPercentage.10off"), value: 90 },
      { _id: "0", name: this.$t("pos.mobile.checkout.adjustByPercentage.clear"), value: 0 },
    ].reverse();
  }

  async flushUpdates() {
    this.session.applyAdjusts(this.tempAdjustAmount);
    this.session.updateCoupons();
    await this.session.atomic({
      ...this.session.cachedPriceDetails,
    });
    await this.session.syncOrder();
    this.tempAdjustAmount = 0;
    this.byManual = "";
  }
}
