
import { Component, Prop, Vue, Watch, mixins, Ref, FindType, PropSync } from "@feathers-client";
import { format, fromNumber, fromHumanNumber } from "@feathers-client/currency";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import SessionSummary from "~/components/table/orderSystem/session/summary.vue";
import SummaryBase from "~/components/table/summaryBase.vue";
import _, { values } from "lodash";
import { openCashBox } from "~/plugins/printer/invoiceSequencer";
import { getID } from "@feathers-client";

@Component({
  components: {
    EditorDatePicker,
    EditorTextField,
    EditorObjectPickerNew,
    SessionSummary,
    EditorCheckbox,
  },
})
export default class OpeningClosingInner extends mixins(SummaryBase) {
  fetch({ store }) {
    store.commit("SET_TITLE", { fullPage: true, tableMode: true, disableTextSelect: true, dark: true });
  }

  reloadInterval = null;
  reloadCounter = 60;
  // @PropSync("needAskBeforeLeave")
  // get needAskBeforeLeave() {
  //   return ["open", "middle", "close"].includes(this.status)
  // }

  @Watch("status")
  onStatus() {
    this.$emit("update:openCloseStatus", this.status);
  }

  status: "loading" | "init" | "open" | "active" | "middle" | "close" | "error" = "loading";

  currentSession: FindType<"shopSessions"> = null;
  lastCheck: FindType<"cashboxLogs"> = null;
  lastSession: FindType<"shopSessions"> = null;
  cashSummary = null;
  otherMethodSummary = null; // payment methods except cash
  allSummary = null;
  currentCashSessionSummary = null;
  currentAllSessionSummary = null;
  currentOtherMethodSummary = null; // payment methods except cash
  checks: FindType<"cashboxLogs">[] = [];

  openTime = new Date();
  openAmountNum: number = 0;
  openAmount = "";
  openAmountClear = true;
  reason = "bank";
  comment = "";
  loading = false;
  staff = null;
  numPad = false;
  reservedCashboxAmount: String | Number = null;
  openQueueing = true;

  hasHistoryDialog: boolean = false;
  currentSessionDialog: boolean = false;
  lastSessionDialog: boolean = false;

  accessStaff: FindType<"staffs"> = null;

  // For action on orders created from the previous sessions
  voidCashPaymentAmt: number = 0; // this is amount of payment created before middle / end check but void after that
  voidCashPaymentCount: number = 0; // this is amount of payment created before middle / end check but void after that
  voidPaymentAmt: number = 0; // this is amount of payment created before middle / end check but void after that
  voidPaymentCount: number = 0; // this is amount of payment created before middle / end check but void after that

  newCashPaymentAmt: number = 0; // this is amount of payment created after middle / end check
  newCashPaymentCount: number = 0; // this is amount of payment created after middle / end check
  newPaymentAmt: number = 0; // this is amount of payment created after middle / end check
  newPaymentCount: number = 0; // this is amount of payment created after middle / end check

  get needStaff() {
    switch (this.status) {
      case "open":
      case "middle":
      case "close":
        return true;
    }
  }

  async cashbox() {
    const staff = await this.$shop.checkPermission(["withDrawDeposit/openCashBox"]);
    if (staff === false) return;
    await openCashBox(this, staff);
  }

  async mounted() {
    if (this.$network.offlineActivated) {
      return;
    }
    // const staff = await this.$shop.checkPermission(["openingClosing/viewRecord"], true);

    // if (staff === false) this.$router.replace("/profile");

    // this.staff = staff;

    await this.$feathers.service("actionLogs").create({
      staff: this.staff?._id,
      type: `openingClosing/viewRecord`,
    });

    await this.reset();
    this.openTime = new Date();
    this.expectedBalanceInt = await this.getExpectedBalanceInt();
    this.updateToggle();

    this.reloadInterval = setInterval(() => {
      this.reloadCounter--;
      if (this.reloadCounter === 0) {
        this.reloadCounter = 60;
        this.reloadCashboxLogs();
      }
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.reloadInterval);
  }

  @Watch("$shop.shopData.currentSession")
  async reset() {
    if (this.$network.offlineActivated) return;
    //this.status = "loading";
    this.loading = true;

    // 1-1. get current log
    this.lastCheck = (
      await this.$feathers.service("cashboxLogs").find({
        query: {
          shop: this.$shop.shopId,
          type: { $nin: ["void"] },
          ...(this.status === "open" ? { isBuffer: { $nin: [true] } } : {}),
          $paginate: false,
          $limit: 1,
          $sort: { date: -1 },
        },
        paginate: false,
      })
    )[0];

    // 1-2. get current session
    this.currentSession = (
      await this.$feathers.service("shopSessions").find({
        query: {
          shop: this.$shop.shopId,
          active: true,
          $paginate: false,
          $limit: 1,
          $sort: { startTime: -1 },
        },
        paginate: false,
      })
    )[0];

    if (this.currentSession) {
      // 2-1. if current session is vaild,  status = active,
      this.status = "active";

      // 2-1-2. get payment session from last check
      if (
        this.lastCheck &&
        this.lastCheck.session === this.currentSession._id &&
        this.lastCheck.type !== "beginCheck"
      ) {
        const _currentAllSessionSummary = await this.$feathers.service("orderPaymentSummary").create({
          startDate: this.lastCheck.date,
          endDate: new Date(),
          shopSession: this.currentSession._id,
          filter: {
            shop: this.$shop.shopId,
          },
          timeField: "paidTime",
        });
        await this.setVoidCashPaymentAmt(this.lastCheck.date);
        await this.setNewCashPaymentAmt(this.lastCheck.date, this.currentSession._id);

        const _currentCashSessionSummary = _currentAllSessionSummary.filter(el => el.paymentMethod?.type === "cash");

        this.currentCashSessionSummary = {
          paids: {
            amountInt: (_.sumBy(_currentCashSessionSummary, (s: any) => s.amount) ?? 0) + this.newCashPaymentAmt,
            total: (_.sumBy(_currentCashSessionSummary, (s: any) => s.count) ?? 0) + this.newCashPaymentCount,
          },
          refunds: {
            amountInt: (_.sumBy(_currentCashSessionSummary, (s: any) => s.voidAmount) ?? 0) + this.voidCashPaymentAmt,
            total: (_.sumBy(_currentCashSessionSummary, (s: any) => s.voidCount) ?? 0) + this.voidCashPaymentCount,
          },
        };

        this.currentAllSessionSummary = {
          paids: {
            amountInt: (_.sumBy(_currentAllSessionSummary, (s: any) => s.amount) ?? 0) + this.newPaymentAmt,
            total: (_.sumBy(_currentAllSessionSummary, (s: any) => s.count + s.voidCount) ?? 0) + this.newPaymentCount,
          },
          refunds: {
            amountInt: (_.sumBy(_currentAllSessionSummary, (s: any) => s.voidAmount) ?? 0) + this.voidPaymentAmt,
            total: (_.sumBy(_currentAllSessionSummary, (s: any) => s.voidCount) ?? 0) + this.voidPaymentCount,
          },
        };

        this.currentOtherMethodSummary = _currentAllSessionSummary.filter(
          el => el.paymentMethod?.type !== "cash" && (el.count ?? 0) + (el.voidCount ?? 0) > 0,
        );
      } else {
        await this.setVoidCashPaymentAmt(this.currentSession.startTime);
        await this.setNewCashPaymentAmt(this.currentSession.startTime, this.currentSession._id);

        this.currentCashSessionSummary = null;
        this.currentAllSessionSummary = null;
        this.currentOtherMethodSummary = null;
      }
      const _allSummary = await this.$feathers.service("orderPaymentSummary").create({
        shopSession: this.currentSession._id,
        filter: {
          shop: this.$shop.shopId,
        },
      });

      const _cashSummary = _allSummary.filter(el => el.paymentMethod?.type === "cash");

      this.otherMethodSummary = _allSummary.filter(
        el => el.paymentMethod?.type !== "cash" && (el.count ?? 0) + (el.voidCount ?? 0) > 0,
      );

      this.cashSummary = {
        paids: {
          amountInt: (_.sumBy(_cashSummary, (s: any) => s.amount) ?? 0) + this.newCashPaymentAmt,
          total: (_.sumBy(_cashSummary, (s: any) => s.count) ?? 0) + this.newCashPaymentCount,
        },
        refunds: {
          amountInt: (_.sumBy(_cashSummary, (s: any) => s.voidAmount) ?? 0) + this.voidCashPaymentAmt,
          total: (_.sumBy(_cashSummary, (s: any) => s.voidCount) ?? 0) + this.voidCashPaymentCount,
        },
      };

      this.allSummary = {
        paids: {
          amountInt: (_.sumBy(_allSummary, (s: any) => s.amount) ?? 0) + this.newPaymentAmt,
          total: (_.sumBy(_allSummary, (s: any) => s.count) ?? 0) + this.newPaymentCount,
        },
        refunds: {
          amountInt: (_.sumBy(_allSummary, (s: any) => s.voidAmount) ?? 0) + this.voidPaymentAmt,
          total: (_.sumBy(_allSummary, (s: any) => s.voidCount) ?? 0) + this.voidPaymentCount,
        },
      };

      // 2-1-3. get all cash log by session
      this.checks = await this.$feathers.service("cashboxLogs").find({
        query: {
          session: this.currentSession._id,
          type: { $nin: ["void"] },
          isBuffer: { $nin: [true] },
          shop: this.$shop.shopId,
          $paginate: false,
          $sort: { date: -1 },
          $populate: "staff",
        },
        paginate: false,
      });
    } else {
      // 2-2. if no current session, status = init
      this.status = "init";
      this.openTime = new Date();

      // 2-2-1. get last session
      this.lastSession = (
        await this.$feathers.service("shopSessions").find({
          query: {
            shop: this.$shop.shopId,
            $paginate: false,
            $limit: 1,
            $sort: { startTime: -1 },
          },
          paginate: false,
        })
      )[0];
    }

    this.resetOpenAmount();
    this.openAmountClear = true;
    this.loading = false;
  }

  @Ref()
  log: any;

  cashboxLogsSort = { date: -1 };

  get cashboxLogsQuery() {
    return {
      session: this.currentSession._id,
      shop: this.$shop.shopId,
      $paginate: false,
      $sort: { date: -1 },
      $populate: "staff",
    };
  }

  async reloadCashboxLogs() {
    if (this.currentSession) {
      this.checks = await this.$feathers.service("cashboxLogs").find({
        query: {
          session: this.currentSession._id,
          type: { $nin: ["void"] },
          isBuffer: { $nin: [true] },
          shop: this.$shop.shopId,
          $paginate: false,
          $sort: { date: -1 },
          $populate: "staff",
        },
        paginate: false,
      });
    }

    this.log?.reload();
    this.openTime = new Date();
    this.expectedBalanceInt = await this.getExpectedBalanceInt();
    this.reloadCounter = 60;
  }

  async printSummary(session: FindType<"shopSessions">) {
    const staff = await this.$shop.checkPermission(["openingClosing/printSummary"]);
    if (staff === false) return;
    this.shop = await this.$feathers.service("shops").get(this.$shop.shopId);
    this.startDate = session.startTime;
    this.endDate = session.endTime ?? new Date();
    this.cashlogSummary = this.checks.slice().reverse();
    await this.exportSummary();
    await this.$feathers.service("actionLogs").create({
      staff: staff?._id,
      type: `openingClosing/printSummary`,
    });
  }

  sessionSort = { startTime: -1 };

  get sessionQuery() {
    return {
      shop: this.$shop.shopId,
    };
  }

  get hasHistory() {
    switch (this.status) {
      case "open":
      case "active":
        return true;
    }
  }

  @Watch("status")
  statusOnChangeAutoCashBox() {
    if (this.status === "open" || this.status === "close") {
      if (this.$shop.localOptions.autoCashBox) {
        this.autoCashBoxOpen();
      }
    }
  }

  async startOpen() {
    const staff = await this.$shop.checkPermission(["openingClosing/opening"]);
    if (staff === false) return;

    if (staff) this.staff = staff;

    this.status = "open";
    await this.reloadCashboxLogs();
  }

  async startMiddle() {
    const staff = await this.$shop.checkPermission(["openingClosing/middleCheck"]);
    if (staff === false) return;

    if (staff) this.staff = staff;

    this.status = "middle";
  }

  async startClose() {
    if (this.$shop.shopData.forceCheckoutBeforeClose) {
      const ongoingOrder = await this.$feathers.service("tableSessions").find({
        query: {
          type: { $in: ["dineIn", "dineInNoTable", "takeAway", "delivery"] },
          status: { $in: ["ongoing", "toPay"] },
        },
      });

      if (ongoingOrder.total > 0) {
        this.$router.replace("/table");
        this.$store.commit("SET_ERROR", this.$t("pos.openClose.pleaseCheckout"));
        return;
      }
    }
    const staff = await this.$shop.checkPermission(["openingClosing/closing"]);
    if (staff === false) return;

    if (staff) this.staff = staff;

    this.status = "close";

    await this.reloadCashboxLogs();
  }

  async autoCashBoxOpen() {
    await this.cashbox();
  }

  get reasons() {
    return [
      {
        _id: "bank",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.bank" },
      },
      {
        _id: "misc",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.misc" },
      },
      {
        _id: "unknown",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.unknown" },
      },
      {
        _id: "loss",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.loss" },
      },
      {
        _id: "others",
        name: { $t: "enum.pages.shop/pos/cashboxLog.reason.others" },
      },
      // {
      //   _id: "closeShopExport",
      //   name: { $t: "enum.pages./shop/pos/cashboxLog.reason.closeShopExport" },
      // },
    ];
  }

  get smAndDown() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get expectedDeltaInt() {
    if (this.currentCashSessionSummary) {
      return fromNumber(
        this.currentCashSessionSummary?.paids?.amountInt ?? 0, //-
        //(this.currentCashSessionSummary?.refunds?.amountInt ?? 0),
        this.$shop.currency,
      ).amount;
    } else {
      return fromNumber(
        this.cashSummary?.paids?.amountInt ?? 0, //- (this.cashSummary?.refunds?.amountInt ?? 0),
        this.$shop.currency,
      ).amount;
    }
    return 0;
  }

  get diffInt() {
    return this.openAmountNumInt - this.expectedBalanceInt;
  }

  expectedBalanceInt = 0;

  // get expectedBalanceInt() {
  //   return (this.lastCheck?.amountInt ?? 0) + this.expectedDeltaInt;
  // }

  async getExpectedBalanceInt() {
    return (await this.$feathers.service("cashboxLogs/expectedAmount").create({})).amount;
  }

  get openAmountNumInt() {
    if (this.openAmount && !isNaN(+this.openAmount)) {
      return fromNumber(+this.openAmount, this.$shop.currency).amount;
    } else if (this.openAmountClear) {
      return this.expectedBalanceInt;
    } else {
      return fromNumber(this.openAmountNum, this.$shop.currency).amount;
    }
  }

  get priceStr() {
    if (this.openAmount) {
      const parts = this.openAmount.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.openAmountNumInt);
    }
  }

  resetOpenAmount() {
    this.openAmount = "";
    this.openAmountNum = 0;
    this.reason = "bank";
    this.comment = "";
  }

  doAction(action: number | string) {
    if (action === "C" || this.openAmount === "") {
      this.resetOpenAmount();
      this.openAmountClear = true;
    } else {
      this.openAmountClear = false;
    }
  }

  async setVoidCashPaymentAmt(date: Date) {
    const voidCashPayments = await this.$feathers.service("orderPaymentSummary").create({
      filter: {
        paidTime: {
          $lt: date,
        },
        refundTime: {
          $exists: true,
          $gt: date,
        },
        status: "refunded",
        shop: this.$shop.shopId,
        // methodType: "cash",
      },
      timeField: "paidTime",
    });
    this.voidCashPaymentAmt =
      _.sumBy(
        voidCashPayments.filter(it => it.paymentMethod?.type === "cash"),
        (s: any) => s.voidAmount,
      ) ?? 0;
    this.voidCashPaymentCount =
      _.sumBy(
        voidCashPayments.filter(it => it.paymentMethod?.type === "cash"),
        (s: any) => s.voidCount,
      ) ?? 0;
    this.voidPaymentAmt = _.sumBy(voidCashPayments, (s: any) => s.voidAmount) ?? 0;
    this.voidPaymentCount = _.sumBy(voidCashPayments, (s: any) => s.voidCount) ?? 0;
  }

  async setNewCashPaymentAmt(date: Date, currentSession: string) {
    const newCashPayments = await this.$feathers.service("orderPaymentSummary").create({
      filter: {
        orderSession: {
          $ne: getID(currentSession),
        },
        paidTime: {
          $exists: true,
          $gt: date,
        },
        shop: getID(this.shop),
      },
    });
    this.newCashPaymentAmt =
      _.sumBy(
        newCashPayments.filter(it => it.paymentMethod?.type === "cash"),
        (s: any) => s.amount,
      ) ?? 0;
    this.newCashPaymentCount =
      _.sumBy(
        newCashPayments.filter(it => it.paymentMethod?.type === "cash"),
        (s: any) => s.count,
      ) ?? 0;
    this.newPaymentAmt = _.sumBy(newCashPayments, (s: any) => s.amount) ?? 0;
    this.newPaymentCount = _.sumBy(newCashPayments, (s: any) => s.count) ?? 0;
  }

  async confirmOpen() {
    if (this.loading) return;
    this.loading = true;
    try {
      switch (this.status) {
        case "open": {
          const { session, cashbox } = await this.$feathers.service("shopSessions/open").create({
            shop: this.$shop.shopId,
            session: {
              staff: this.staff?._id,
            },
            cashbox: {
              amountInt: this.openAmountNumInt,
              // deltaInt: this.expectedDeltaInt + this.diffInt,
              reason: this.reason,
              comment: this.comment,
            },
            ...(this.$config.features.queneing || this.$config.features.queueingClinic
              ? { openQueueing: this.openQueueing }
              : {}),
          });

          await this.$feathers.service("actionLogs").create({
            staff: this.staff?._id,
            type: `openingClosing/opening`,
            detail: { session, cashbox },
          });

          new Promise(resolve => {
            setTimeout(() => {
              resolve(this.$router.replace("/table"));
            }, 200);
          });
          // this.$router.replace("/table");
          break;
        }

        case "middle": {
          const { session, cashbox } = await this.$feathers.service("shopSessions/update").create({
            shop: this.$shop.shopId,
            session: {
              staff: this.staff?._id,
            },
            cashbox: {
              amountInt: this.openAmountNumInt,
              // deltaInt: this.expectedDeltaInt + this.diffInt,
              reason: this.reason,
              comment: this.comment,
            },
          });

          await this.$feathers.service("actionLogs").create({
            staff: this.staff?._id,
            type: `openingClosing/middleCheck`,
            detail: { session, cashbox },
          });

          break;
        }

        case "close": {
          await this.$shopSession.resetQueueingGroup(true);
          const { session, cashbox } = await this.$feathers.service("shopSessions/close").create({
            shop: this.$shop.shopId,
            session: {
              staff: this.staff?._id,
            },
            cashbox: {
              amountInt: +this.reservedCashboxAmount,
              // deltaInt: +this.reservedCashboxAmount - this.openAmountNumInt,
              reason: "closeShopExport",
              comment: this.comment,
            },
          });

          // if(xeroMessage.status === 0) {
          //   this.$store.commit("SET_ERROR", `xero error: ${xeroMessage.message}`)
          // }

          await this.$feathers.service("actionLogs").create({
            staff: this.staff?._id,
            type: `openingClosing/closing`,
            detail: { session, cashbox },
          });
          this.currentSession = null;
          this.currentAllSessionSummary = null;
          this.currentCashSessionSummary = null;
          this.allSummary = null;
          this.cashSummary = null;
          this.status = "loading";
          break;
        }
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }

    await this.reset();
  }

  async recoverPrevious() {
    const staff = await this.$shop.checkPermission(["openingClosing/recover"]);
    if (staff === false) return;

    const { session, cashbox } = await this.$feathers.service("shopSessions/recover").create({
      shop: this.$shop.shopId,
      session: {
        _id: this.lastSession._id,
      },
    } as any);

    await this.$feathers.service("actionLogs").create({
      staff: staff?._id,
      type: `openingClosing/recover`,
      detail: { session, cashbox },
    });

    await this.reset();
  }
  async sendAllPayment() {
    await this.$feathers.service("shopSessions/close").create({
      shop: this.$shop.shopId,
    });
  }

  // beforeMount() {
  //   this.loadTaxSetting()
  // }

  @Watch("smAndDown")
  updateToggle() {
    if (this.smAndDown) {
      this.hasHistoryDialog = false;
      this.currentSessionDialog = false;
      this.lastSessionDialog = false;
    } else {
      this.hasHistoryDialog = true;
      this.currentSessionDialog = true;
      this.lastSessionDialog = true;
    }
  }
}
