import { ns } from "pos-printer/messageQueue";
import { getVersion } from "pos-printer/nativeIntegrations";

let lastVoice: SpeechSynthesisVoice = null;

export function supported(): Promise<boolean> {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race<boolean>([
    ns("speech").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]);
}

export async function speak(
  text: string,
  voiceURI: string = null,
  rate: number = 1,
  volume: number = 1,
  locale?: string,
): Promise<SpeechSynthesisVoice[]> {
  if (typeof speechSynthesis !== "undefined") {
    try {
      const voice = new SpeechSynthesisUtterance(text);
      if (voiceURI) {
        if (lastVoice && lastVoice.voiceURI === voiceURI) {
          voice.voice = lastVoice;
        } else {
          const voices = speechSynthesis.getVoices();
          voice.voice = lastVoice = voices.find(it => it.voiceURI === voiceURI) || voices[0];
        }
      } else if (locale) {
        if (lastVoice && lastVoice.lang.toLowerCase() === locale.toLowerCase()) {
          voice.voice = lastVoice;
        } else {
          const voices = speechSynthesis.getVoices();
          lastVoice = voice.voice =
            voices.find(it => it.lang.toLowerCase() === locale.toLowerCase() && it.localService) ||
            voices.find(it => it.lang.toLowerCase() === locale.toLowerCase()) ||
            voices[0];
        }
      } else if (!voice.voice) {
        if (lastVoice) {
          voice.voice = lastVoice;
        } else {
          const voices = speechSynthesis.getVoices();
          lastVoice = voice.voice = voices.find(it => it.localService) || voices[0];
        }
      }
      voice.rate = rate;
      voice.volume = volume;
      const p = new Promise<any>((resolve, reject) => {
        voice.onend = resolve;
        voice.onerror = reject;
      });
      speechSynthesis.speak(voice);
      await p;
    } catch (e) {
      console.error(e);
    }
  } else if (await supported()) {
    return await ns("speech").call("speak", text, {
      voiceURI,
      rate,
      volume,
    });
  } else {
    console.warn("SpeechSynthesis is not supported");
    return [];
  }
}

export async function getVoices() {
  if (typeof speechSynthesis !== "undefined") {
    return speechSynthesis.getVoices();
  } else if (await supported()) {
    return await ns("speech").call("getVoices");
  } else {
    console.warn("SpeechSynthesis is not supported");
  }
}

export function formatCharacters(text: string, ssml?: boolean) {
  text = text.replace(/\-/g, "");
  if (ssml) {
    text = text
      .split("")
      .map(c => {
        return `<say-as interpret-as="characters">${c}</say-as>`;
      })
      .join("");

    if (typeof speechSynthesis !== "undefined") {
      text = `<?xml version="1.0"?>\r\n<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">${text}</speak>`;
    }

    console.log(text);

    return text;
  } else {
    return text.split("").join(" ");
  }
}
