import { EventEmitter } from 'events'

export interface DeviceConf {
    opts? : any
    type? : string
    port? : string
    name? : string
    address? : string
    id? : string
    shared? : boolean
    tags? : string[]
}

export abstract class DeviceBase<T = any, TConf extends DeviceConf = DeviceConf> extends EventEmitter {
    device : T;
    session = 0;
    printing = false;
    connected = false;
    autoDisconnected = false;
    autoReconnect = true;
    autoReconnecting = false;
    onDisconnected : () => void;

    constructor(public conf : TConf) {
        super();

        this.onDisconnected = () => {
            if(!this.device) return;
            this.connected = false;
            this.autoDisconnected = true;
            this.emit('statusChanged');
            if(this.autoReconnect && !this.autoReconnecting) {
                this.exponentialBackoff(10000, 2);
            }
            if(this.onDisconnectedCore) this.onDisconnectedCore();
        };
    }

    abstract onDisconnectedCore() : void;
    abstract initCore() : Promise<void>;
    abstract tryConnectCore() : Promise<void>;
    abstract disconnectCore() : Promise<void>;
    abstract requestNewDeviceCore() : Promise<T>;

    async exponentialBackoff(max : number, delay : number) {
        let s = ++this.session;
        while(max > 0 && this.session === s && this.autoReconnect) {
            try {
                if(this.session !== s) return;
                if(this.autoReconnecting) throw new Error("Already connecting");
                this.autoReconnecting = true;
                console.log('Retry connect');
                return await this.tryConnect();
            } catch(e) {
                console.log(e);
                if (max === 0) {
                    return;
                }
                this.time(
                    "Retrying in " + delay + "s... (" + max + " tries left)"
                );
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
                if(this.session !== s) {
                    console.log('session expired');
                    return;
                }
                --max;
                delay = Math.min(15, delay * 1.5);
            } finally {
                this.autoReconnecting = false;
            }
        }
        if(this.session !== s) {
            console.log('Auto retry cancelled');
        }
    }

    time(text : string) {
        console.log("[" + new Date().toJSON().substr(11, 8) + "] " + text);
    }

    async init() {
        await this.tryConnect();
        await this.initCore();
    }

    _tryConnect : Promise<void>;

    tryConnect() {
        if(this.connected) return;
        return this._tryConnect || (this._tryConnect = this.mtryConnectCore());
    }

    get connecting() {
        return !!this._tryConnect;
    }

    async mtryConnectCore() {
        try {
            if(this.connected) return;
            if(!this.device) await this.requestNewDevice();
            await this.tryConnectCore();
            this.connected = true;
            this.autoReconnecting = false;
            this.emit('statusChanged');
            this.session++;
            console.log({session: this.session}, 'mtryConnectCore')
        } finally {
            await new Promise(resolve => setTimeout(resolve, 10));
            this._tryConnect = null;
        }
    }

    async disconnect(manualReconnect? : boolean) {
        this.session++;
        console.log({session: this.session}, 'disconnect')
        if(manualReconnect) {
            this.autoReconnect = false;
        }
        if(this.device) {
            await this.disconnectCore();
            if(manualReconnect) {
                this.device = null;
            }
        }
        this.connected = false;
        this.emit('statusChanged');
        this.emit('disconnected');
        this._tryConnect = null;
    }

    async requestNewDevice() {
        this.session++;
        console.log({session: this.session}, 'requestNewDevice')
        this.autoReconnect = true;
        await this.disconnect();
        this.device = await this.requestNewDeviceCore();
        // await this.init();
    }
}

