import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

import type { TableSession, ProductLine } from "./session";

@Component
export class OrderGroup extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  seq: number;

  @Prop()
  type: string;

  @Prop()
  status: string;

  items: ProductLine[] = [];
  chunk: OrderGroupChunk[] = [];
  focused = false;
  verify: string[] = [];

  addItem(product: ProductLine) {
    const pidx = this.items.findIndex(it => it.id === product.id);
    if (pidx === -1) {
      this.items.push(product);
      if (!this.chunk.length) {
        this.chunk.push({
          group: this,
          items: [],
          id: `${this.id}_${this.chunk.length}`,
          idx: this.chunk.length,
        });
      }
      if (this.isItemShown(product)) {
        this.chunk[this.chunk.length - 1].items.push(product);
      }
    } else {
      // for (let c of this.chunk) {
      //   const idx = c.items.findIndex(it => it.id === product.id);
      //   if (idx !== -1) c.items[idx] = product;
      // }
      // this.items[pidx] = product;
      const newReflow = this.items[pidx].status !== product.status;
      Object.assign(this.items[pidx], product);
      return newReflow;
    }
  }

  isItemShown(item: ProductLine) {
    if (this.status === "pending" && this.$tableManager.kdsHideDone) {
      return item.kdsStatus !== "done" && item.kdsStatus !== "partialDone";
    } else {
      return true;
    }
  }

  @Watch("$tableManager.kdsHideDone")
  resetView() {
    this.chunk = [];
    this.chunk.push({
      group: this,
      items: this.items.filter(it => this.isItemShown(it)),
      id: `${this.id}_${this.chunk.length}`,
      idx: this.chunk.length,
    });
  }

  warnStatus: "normal" | "warn" | "urgent" = "normal";

  get id() {
    return `${this.session._id}_${this.seq}`;
  }

  get date() {
    return this.items[0]?.date;
  }

  updateTimer() {
    const timeDiff = Math.max(0, Math.floor((Date.now() - +new Date(this.date)) / 1000 / 60));
    let status: "normal" | "warn" | "urgent" = "normal";

    if (timeDiff >= 10) {
      status = "urgent";
    } else if (timeDiff >= 5) {
      status = "warn";
    }

    if (status !== this.warnStatus) {
      this.warnStatus = status;
    }
  }

  splitAt(chunk: OrderGroupChunk, idx: number) {
    const remain = chunk.items.splice(idx, chunk.items.length - idx);
    if (chunk.idx === this.chunk.length - 1) {
      this.chunk.push({
        group: this,
        items: remain,
        id: `${this.id}_${this.chunk.length}`,
        idx: this.chunk.length,
      });
    } else {
      const nextChunk = this.chunk[chunk.idx + 1];
      nextChunk.items.splice(0, 0, ...remain);
    }
  }
}

export interface OrderGroupChunk {
  group: OrderGroup;
  items: ProductLine[];
  id: string;
  idx: number;
}
