import { Component, Prop, Vue, Watch, getOptions, checkID, getID, FindType, FindPopRawType } from "@common";
import { CartItem, ProductOptionSelect, ProductLine, ProductLineOption, TableRef, LangArrType } from "./cart";
import _ from "lodash";
import { checkTime, StockLevel, StockInfo, computeLineTaxAndSurcharge, checkTwCenterNo } from "./util";
import { AppApplication, AdminApplication } from "serviceTypes";
import { MApplication } from "@feathersjs/feathers";
import moment from "moment";
import { CouponItem, CouponType, DiscountType, GiftType, ProductLineCompute, DiscountComputeContext, DiscountHint } from "./coupon";
import { v4 as uuid } from "uuid";
import type DB from "@db";
import { AutoMerger } from "./autoMerger";

export interface AddCartOpts {
  // move the cart item or not
  swap?: boolean | number;
  mustInsert?: boolean;
  deferUpdate?: boolean;
  tempCart?: CartItem[];
  customProduct?: typeof DB.CustomProduct._mongoType;
  staff?: string;
  openKey?: number;
  overridePrice?: number;
  predestinedDiscount?: string;
  predestinedDiscountName?: LangArrType;
  manualPercentAdjust?: number;
  fromLine?: ProductLine;
  coupon?: CouponItem;
}

export type SessionType = FindType<"tableSessions">;
export type SessionSurchargeType = SessionType["surcharges"][number];
export type SessionTaxType = SessionType["taxes"][number];

@Component
export class SessionBase extends Vue {
  useDefaultsForSetItem = false;

  // #region abstract functions
  get sessionData(): SessionType {
    return null;
  }
  get availableSections(): FindType<"sections">[] {
    return [];
  }

  get sectionData(): FindType<"sections"> {
    return null;
  }

  get targetRef(): TableRef {
    return null;
  }

  get shopData(): FindType<"shops"> {
    return null;
  }

  get feathers() {
    return (this as any).$feathers as any as MApplication<AppApplication | AdminApplication>;
  }

  get taxSettings(): FindType<"taxSettings">[] {
    return [];
  }

  get surchargeSettings(): FindType<"surchargeSettings">[] {
    return [];
  }

  queryStock(): Record<string, StockInfo> {
    return {};
  }

  async queryStockAsync(): Promise<Record<string, StockLevel>> {
    return {};
  }

  get selfService() {
    return this.sessionData?.source === "selfOrder";
  }

  get autoMerger(): AutoMerger {
    return null;
  }

  get categoriesDict(): Record<string, FindType<"categories">> {
    return null;
  }

  get subCategoriesDict(): Record<string, FindType<"subCategories">> {
    return null;
  }

  get availableTimeConditionDict(): Record<string, FindType<'timeConditions'>> {
    return null;
  }

  get checkAvailability() {
    return true;
  }

  get computeDiscountHint() {
    return false;
  }

  // #endregion

  // #region helper functions

  async getProductWithOptions(
    product: string,
    getCfg?: {
      useDefaults?: boolean;
      productCache?: Record<string, FindType<"products">>;
      productOptionsCache?: Record<string, FindType<"productOptions">>;
      ref?: Partial<CartItem>;
      options?: ProductLine["options"];
      fromProduct?: string;
    },
  ): Promise<Partial<CartItem>> {
    getCfg = getCfg || {};
    if (getCfg.useDefaults === undefined && getCfg?.fromProduct) {
      getCfg.useDefaults = this.useDefaultsForSetItem;
    }

    if (getCfg.ref) {
      if (!getCfg.productCache) {
        getCfg.productCache = {
          [getID(getCfg.ref.product)]: getCfg.ref.product,
        };
      }
      if (!getCfg.productOptionsCache) {
        getCfg.productOptionsCache = Object.fromEntries(getCfg.ref.options.map(it => [getID(it.options), it.options]));
      }
    }

    const productInfo = getCfg.productCache?.[getID(product)] || (await this.feathers.service("products").get(product));

    const needToFind = productInfo.options.filter(it => !getCfg.productOptionsCache?.[getID(it)]);

    let optionsDict: Record<string, FindType<"productOptions">> = {};

    if (needToFind.length) {
      const result = await this.feathers.service("productOptions").find({
        query: {
          _id: {
            $in: needToFind,
          },
          $paginate: false,
        },
        paginate: false,
      });
      optionsDict = Object.fromEntries(result.map(it => [getID(it), it]));
    }

    const options = productInfo.options.map(it => {
      const opt = optionsDict[getID(it)] || getCfg.productOptionsCache?.[getID(it)];
      if (!opt) {
        console.warn("Option", it, "is not found");
      }
      return opt;
    });

    return {
      product: productInfo,
      options: this.getOptionsWithProductInfo(options, getCfg),
    };
  }

  getOptionsWithProductInfo(options: FindType<"productOptions">[], getCfg?: any) {
    return options
      .filter(it => !!it)
      .map(opt => {
        const it = {
          options: opt,
          values: [],
          selections: [],
          fixed: false,
          free: false,
        };

        const src =
          getCfg?.ref?.options?.find?.(it => checkID(it.options, opt)) ||
          getCfg?.options?.find?.(it => checkID(it.option, opt));
        if (src) {
          it.values = src.values;
          it.selections = src.selections;
          it.fixed = src.fixed;
          it.free = src.free;
          return it;
        }

        const hasDefaults = opt.options?.find?.(it => it.defaultSelected);
        if (hasDefaults) {
          for (let option of opt.options) {
            if (option.defaultSelected) {
              it.selections.push({
                _id: option._id,
                id: getID(option),
                quantity: option.defaultSelectedQuantity || 1,
              });
              it.values.push(option._id);
            }
          }
        } else if (getCfg.useDefaults) {
          if (opt.required && !it.selections.length && opt.options?.[0]?._id) {
            it.selections.push({
              _id: it.options.options[0]._id,
              id: getID(it.options.options[0]),
              quantity: 1,
            });
            it.values.push(it.options.options[0]);
          }
        }

        return it;
      });
  }

  async getAutomaticDiscounts(): Promise<FindType<"orderDiscounts">[]> {
    return await this.feathers.service("orderDiscounts").find({
      query: {
        $paginate: false,
        source: "automatic",
        $sort: { priority: 1 },
        $or: [
          {
            startDate: { $lte: new Date() },
            endDate: { $gte: new Date() },
          },
          {
            startDate: { $exists: false },
            endDate: { $exists: false },
          },
        ],
        $and: [
          {
            $or: [{ shops: { $exists: false } }, { shops: { $size: 0 } }, { shops: this.shopId }],
          },
        ] as any,
      },
      paginate: false,
    });
  }

  // #endregion

  // #region Cart functions

  saveCart(opt?: any) {
    this.updateCoupons();
    this.$emit("updated");
  }

  cart: CartItem[] = [];

  get cartEmpty() {
    return !this.cart.length;
  }

  get cartValid() {
    return !this.cartEmpty && !this.cart.find(it => !it.isValid);
  }

  get orderEmpty() {
    return !_.filter(this.sessionData.products, p => p.status !== "cancel")?.length;
  }

  get orderComfirmed() {
    return !_.filter(this.sessionData.products, p => p.status === "pending")?.length;
  }

  get shopId() {
    return getID(this.shopData);
  }

  get cartNum() {
    return _.sumBy(this.cart, c => c.quantity);
  }

  addToCart(addCart: Partial<CartItem>, addCfg?: AddCartOpts) {
    addCfg = addCfg ?? {};
    let swap = addCfg.swap ?? false;
    const mustInsert = addCfg.mustInsert ?? false;

    const product: FindType<"products"> = addCart.product;
    const options: ProductOptionSelect[] = addCart.options || [];
    const quantity = addCart.quantity ?? 1;
    let tableRef = addCart.tableRef || this.targetRef;
    if (!tableRef && this.sessionData?.tables?.length) {
      console.warn("No table ref for product", addCart);
      tableRef = {
        table: this.sessionData.tables[0].item,
        split: this.sessionData.tables[0].split,
      } as any;
    }
    let section: string = addCart.section || this.sectionData?._id;
    let customRemark = addCart.customRemark || "";
    let fromProduct = addCart.fromProduct || null;
    let fromCoupon = getID(addCfg?.coupon?._id) || addCart.fromCoupon || null;
    let kitchenOptions = addCart.kitchenOptions || null;

    if (!customRemark && addCfg.customProduct?.remarks) {
      customRemark = addCfg.customProduct?.remarks;
    }

    const cartList = addCfg.tempCart || this.cart;

    if (fromProduct && !cartList.find(it => it.id === fromProduct)) {
      fromProduct = null;
    }

    if (fromCoupon && !this.coupons.find(it => (it.coupon || it.gift || it.discount) && checkID(it._id, fromCoupon))) {
      fromCoupon = null;
      options.forEach(opt => {
        opt.free = false;
        opt.fixed = false;
      });
    }

    if (product) {
      if (
        !this.availableSections.find(it => checkID(it, section)) ||
        !product.sections.find(s => checkID(s, section))
      ) {
        section =
          (product.sections || []).find(it => this.availableSections.find(s => checkID(s, it))) ??
          this.sectionData?._id ??
          product.sections?.[0];
      }
    }

    let cart =
      mustInsert || addCfg.customProduct || addCfg.openKey || addCfg.overridePrice != null || addCfg.manualPercentAdjust
        ? null
        : _.findLast(
            cartList.filter(it => it.overridePrice == null && !it.manualDiscount),
            it => !it.fromProduct && !it.fromCoupon && it.isSame(product, options, tableRef),
          );
    if (!cart) {
      cart = new CartItem(
        getOptions(this, {
          product,
          fromLine: addCfg.fromLine,

          initData: {
            id: addCart.id || uuid(),
            fromProduct,
            fromCoupon,
            section,
            kitchenOptions,
            tempCart: addCfg.tempCart,
            staff: addCfg.staff,
            customRemark: customRemark,
            tableRef: tableRef,
            customProduct: _.cloneDeep(addCfg.customProduct),
            quantity: quantity,
            options,
            mergeStatus: addCart.mergeStatus,
            date: addCart.date,
          },
        }),
      );
      if (addCart.status) {
        cart.status = addCart.status;
      }
      if (addCfg.openKey) {
        cart.openKey = addCart.openKey;
      }
      if (addCfg.overridePrice != null) {
        cart.overridePrice = addCart.overridePrice;
      }
      if (addCfg.predestinedDiscount != null) {
        cart.predestinedDiscount = addCart.predestinedDiscount;
      }
      if (addCfg.predestinedDiscountName != null) {
        cart.predestinedDiscountName = addCart.predestinedDiscountName;
      }
      if (addCfg.manualPercentAdjust) {
        cart.manualPercentAdjust = addCart.manualPercentAdjust;
      }
      if (addCart.takeAway) {
        cart.takeAway = addCart.takeAway;
      }
      if (addCart.mergeSource) {
        cart.mergeSource = addCart.mergeSource;
      }
      if (typeof swap === "number") {
        cartList.splice(swap, 0, cart);
      } else {
        cartList.push(cart);
      }
      cart.coupon = addCfg?.coupon;
      if (!addCfg.deferUpdate) {
        cart.updateDeps();
      }
    } else {
      if (swap) {
        cart.moveTo(swap === true ? cartList.length - 1 : swap);
      }
      cart.quantity += quantity;
    }

    if (cart && !cart.customRemark && customRemark) {
      cart.customRemark = customRemark;
    }

    if (cart && !cart.kitchenOptions?.length && kitchenOptions?.length) {
      cart.kitchenOptions = kitchenOptions;
    }

    cart.quantityRatio = addCart?.quantityRatio ?? 1;
    if (!addCfg.tempCart && !addCfg.deferUpdate) {
      this.saveCart();
    }

    return cart;
  }

  async tryMergeCart() {
    if (this.autoMerger) {
      await this.autoMerger.tryMergeCart(this);
      await this.autoMerger.tryMergeAddons(this);
    }
  }

  async tryMergeOrderedCart() {
    if (this.autoMerger) {
      return await this.autoMerger.tryMergeOrderedItems(this);
    }
  }

  async confirmTempItem(item: CartItem, toIndex?: number, targetCart: CartItem[] = null) {
    if (targetCart === this.cart) {
      targetCart = null;
    }
    if (item.tempCart === targetCart) return;

    const cartList = targetCart || this.cart;

    const relatedTree = item.relatedTree;
    if (toIndex === -1) toIndex = undefined;
    cartList.splice(toIndex ?? cartList.length, 0, ...relatedTree);
    for (let cart of relatedTree) {
      cart.tempCart = targetCart;
    }
    await item.updateDeps();
    this.saveCart();
  }

  async cloneItem(item: CartItem, toTempCart?: CartItem[], toIndex?: number) {
    const tempCart: CartItem[] = toTempCart || [];
    const mappedIds: Record<string, string> = {};

    for (let subItem of item.relatedTree) {
      mappedIds[subItem.id] = `${uuid()}`;
    }

    for (let subItem of item.relatedTree) {
      const j = subItem.toJSON();
      j.id = mappedIds[j.id];
      j.fromProduct = mappedIds[j.fromProduct];
      j.fromCoupon = null;
      await this.addFromJSON(j, {
        tempCart,
        ref: subItem,
      });
    }

    for (let item of tempCart.slice().reverse()) {
      await item.updateDeps();
    }

    // should be index 0, but somehow bugged
    const newID = mappedIds[item.id];
    const newItem = tempCart.find(it => it.id === newID);

    if (!toTempCart) {
      await this.confirmTempItem(newItem, toIndex);
    }

    return newItem;
  }

  async addFromJSON(
    item: any,
    addCfg?: {
      ref?: CartItem;
      tempCart?: CartItem[];
      fromLine?: ProductLine;
      replacing?: boolean;
      swap?: boolean | number;
    },
  ) {
    addCfg = addCfg || {};
    if (item.customProduct || !item.product) {
      const addCart = {
        ...item,
        product: null,
        options: [],
        tableRef: item.table
          ? {
              table: item.table,
              split: item.tableSplit,
            }
          : null,
      };
      return await this.addToCart(addCart, {
        mustInsert: true,
        tempCart: addCfg.tempCart,
        deferUpdate: true,
        customProduct:
          item.customProduct && !item.customProduct._bsontype && typeof item.customProduct === "object"
            ? item.customProduct
            : {
                _id: typeof item.customProduct === "string" ? item.customProduct : item.customProduct?._id || null,
                name: addCart.name?.[0]?.value,
                remarks: addCart.customRemark,
                kitchenPrinters: addCart.kitchenPrinters,
                kitchenOptions: addCart.kitchenOptions,
                waterBars: addCart.waterBars,
                category: addCart.category,
                subCategory: addCart.subCategory,
                price: addCart.actualPrice,
              },
        openKey: addCart.openKey,
        overridePrice: addCart.overridePrice,
        predestinedDiscount: addCart.predestinedDiscount,
        predestinedDiscountName: addCart.predestinedDiscountName,
        manualPercentAdjust: addCart.manualPercentAdjust,
        fromLine: addCfg.replacing ? addCfg.fromLine || (item as any) : null,
      });
    } else {
      const { product, options } = await this.getProductWithOptions(item.product, {
        ref: addCfg.ref,
        options: item.options,
      });
      const addCart = {
        ...item,
        product,
        options: options.map(it => ({
          ...it,
          values: it.values ? it.values.slice() : [],
          selections: it.selections ? it.selections.map(s => ({ ...s })) : [],
        })),
      };

      if (addCart.table && !addCart.tableRef && this.sessionData?.type === "dineIn") {
        addCart.tableRef = {
          table: addCart.table,
          split: addCart.tableSplit,
        };
      }

      return await this.addToCart(addCart, {
        mustInsert: true,
        tempCart: addCfg.tempCart,
        deferUpdate: true,
        swap: addCfg?.swap ?? false,
        openKey: item.openKey,
        overridePrice: item.overridePrice,
        predestinedDiscount: item.predestinedDiscount,
        predestinedDiscountName: item.predestinedDiscountName,
        manualPercentAdjust: item.manualPercentAdjust,
        fromLine: addCfg.replacing ? (item as any) : null,
      });
    }
  }

  async addFromJSONs(items: any[], opts?: { replacing?: boolean; clone?: boolean; temp?: boolean }) {
    const tempCart: CartItem[] = [];
    for (let item of items) {
      await this.addFromJSON(item, { tempCart, replacing: opts?.replacing });
    }
    for (let item of tempCart.slice().reverse()) {
      await item.updateDeps();
    }
    if (opts?.temp) {
      return tempCart;
    }
    for (let item of tempCart) {
      if (item.fromProduct) continue;
      if (opts?.clone) {
        await this.cloneItem(item);
      } else {
        await this.confirmTempItem(item);
      }
    }
    if (opts?.clone) {
      for (let item of tempCart) {
        item.$destroy();
      }
      return [];
    }
    return tempCart;
  }

  clearCart(opts: { cart?: boolean; coupons?: boolean } = {}) {
    if (opts.cart ?? true) {
      this.cart.forEach(cart => cart.$destroy());
      this.cart = [];
    }

    if (opts.coupons ?? true) {
      this.coupons.forEach(cart => cart.$destroy());
      this.coupons = [];
    }

    this.saveCart();
  }

  clearCouponProducts() {
    const droppedIdx = [];
    for (let idx in this.cart) {
      if (this.cart[idx].fromCoupon) {
        this.cart[idx].$destroy();
        droppedIdx.push(parseInt(idx));
      }
    }
    this.cart = this.cart.filter((_, idx) => !droppedIdx.includes(idx));
    this.saveCart();
  }

  // #endregion

  // #region Section

  get sectionId() {
    return this.sessionData?.section;
  }
  set sectionId(v) {
    this.sessionData.section = v;
  }

  // section settings of current section
  get sectionSettings() {
    return this.getCurrentSetting(this.sectionData, this.sessionData?.startTime ?? new Date());
  }

  getCurrentSetting(section: FindType<"sections">, time: Date) {
    if (!time) return null;
    if (section?.sectionSettings) {
      const setting = section.sectionSettings.find(it => checkTime(it.weekdays, it.from, it.to, time));
      return setting;
    }
  }

  // #endregion

  // #region price calculations

  get _id() {
    return this.sessionData?._id ?? null;
  }

  get sumOfCartProducts() {
    return _.sumBy(this.cart, p => p.price * p.quantity);
  }

  get sumOfOrderedProducts() {
    return _.sumBy(this.sessionData?.products, p => ((p as any).replacing ? 0 : p.price * p.quantity));
  }

  get sumOfProducts() {
    return this.sumOfCartProducts + this.sumOfOrderedProducts;
  }

  get totalCartQuantity() {
    return _.sumBy(
      this.cart.filter(p => !p.fromProduct),
      p => (p.status === "cancel" ? 0 : p.quantity),
    );
  }

  get totalOrderedQuantity() {
    return _.sumBy(
      this.sessionData.products.filter(p => !p.fromProduct),
      p => (p.status === "cancel" ? 0 : p.quantity),
    );
  }

  get totalQuantity() {
    return (
      this.totalCartQuantity + this.totalOrderedQuantity
      //   _.sumBy(this.sessionData.products, p => (p.status === "cancel" ? 0 : p.quantity)) +
      //   _.sumBy(this.cart, p => (p.status === "cancel" ? 0 : p.quantity))
    );
  }

  get surcharges() {
    return this.sessionData.surcharges || [];
  }

  get lineTaxAndSurcharge() {
    return computeLineTaxAndSurcharge(this.sessionData);
  }

  get sumOfSurcharges() {
    return _.sumBy(this.sessionData.surcharges, it => (it.disabled ? 0 : it.amount));
  }

  get taxes() {
    return this.sessionData.taxes;
  }

  get totalIncTax() {
    return _.sumBy(this.taxes, t => (t.disabled || !t.included ? 0 : t.amount));
  }

  get totalExtTax() {
    return _.sumBy(this.taxes, t => (t.disabled || t.included ? 0 : t.amount));
  }

  get capacity() {
    return _.sumBy(this.sessionData.tables, it => it.capacity) || 0;
  }

  get totalPriceBeforeDiscount() {
    return this.sumOfProducts + this.sumOfSurcharges + this.totalExtTax;
  }

  get totalDiscounts() {
    return _.sumBy(this.sessionData.discounts || [], r => +r.discountValue);
  }

  get adjustablePrice() {
    const totalExtTax = this.sessionData.adjusts ? this.beforeAdjustExtTax : this.totalExtTax;
    const totalPriceBeforeDiscount = this.sumOfProducts + this.sumOfSurcharges + totalExtTax;
    return Math.max(0, totalPriceBeforeDiscount + this.totalDiscounts);
  }

  get totalPriceBeforeRound() {
    let final =
      this.totalPriceBeforeDiscount +
      this.totalDiscounts +
      ((this.sessionData.tipsWithoutTaxAdj ?? this.sessionData.tips) || 0) +
      (this.sessionData.adjusts || 0);
    if (final < 0) final = 0;
    return Math.round(final * 100) / 100;
  }

  get totalPrice() {
    return this.roundPrice(this.totalPriceBeforeRound);
  }

  roundPrice(p: number) {
    let roundingFactor = Math.round(1 / (this.shopData?.priceRoundFactor ?? 1));
    if (isNaN(roundingFactor) || roundingFactor < 1) roundingFactor = 1;
    switch (this.shopData?.priceRoundMethod) {
      case "floor":
        return Math.floor(p * roundingFactor) / roundingFactor;
      case "round":
        return Math.round(p * roundingFactor) / roundingFactor;
      case "no":
        return +p.toFixed(2);
    }
    return Math.ceil(p * roundingFactor) / roundingFactor;
  }

  get totalPayment() {
    return _.sumBy(this.sessionData?.payments ?? [], p => p.amount) ?? 0;
  }

  get totalPaymentTips() {
    return _.sumBy(this.sessionData?.payments ?? [], p => p.tips ?? 0) ?? 0;
  }

  get outstanding() {
    return +(this.totalPrice - this.totalPayment).toFixed(2);
  }

  get outstandingTips() {
    return +((this.sessionData.tips ?? 0) - this.totalPaymentTips).toFixed(2);
  }

  get paymentSurcharge() {
    return _.sumBy(this.sessionData?.payments ?? [], p => p.surcharge) ?? 0;
  }

  get paymentSurchargeWithoutTaxAdj() {
    return _.sumBy(this.sessionData?.payments ?? [], p => p.surchargeWithoutTax) ?? 0;
  }

  tempPaymentSurcharge = 0;

  get totalPaymentSurcharge() {
    return this.paymentSurcharge + this.tempPaymentSurcharge;
  }

  get cachedPriceDetails() {
    return {
      amount: this.totalPrice,
      sumOfSurcharges: this.sumOfSurcharges,
      tips: this.sessionData.tips,
      adjusts: this.sessionData.adjusts,

      totalPriceBeforeRound: this.totalPriceBeforeRound,
      sumOfProducts: this.sumOfProducts,
      outstanding: this.outstanding,
      totalQuantity: this.totalQuantity,
      totalDiscounts: this.totalDiscounts,

      actualCapacity: this.capacity,

      ...(this.couponsLoaded
        ? {
            discounts: this.sessionData.discounts,
            surcharges: this.sessionData.surcharges as any,
            taxes: this.sessionData.taxes,
            totalIncTax: this.totalIncTax,
            totalExtTax: this.totalExtTax,
            taxFree: this.sessionData.taxFree,

            tipsWithoutTaxAdj: this.sessionData.tipsWithoutTaxAdj,
            tipsTax: this.sessionData.tipsTax,
            tipsWithoutTax: this.sessionData.tipsWithoutTax,

            paymentSurcharge: this.paymentSurcharge,
            paymentSurchargeWithoutTaxAdj: this.paymentSurchargeWithoutTaxAdj,
            paymentSurchargeTax: this.sessionData.paymentSurchargeTax,
            paymentSurchargeWithoutTax: this.sessionData.paymentSurchargeWithoutTax,

            lineAdjusts: this.sessionData.lineAdjusts,
          }
        : {}),
    };
  }

  // #endregion

  // #region coupon

  coupons: CouponItem[] = [];
  couponsLoaded = false;
  discountHints: Record<string, DiscountHint> = {};

  get validDiscounts() {
    return this.coupons.filter(it => it.valid && it.discountSource !== "tax" && it.discountSource !== "surcharge");
  }

  insertCoupon(couponItem: CouponItem) {
    const insertIdx = _.sortedLastIndexBy(this.coupons, couponItem, p => [
      p.allRule.phase ?? 0,
      p.tax ? 2 : p.surcharge ? 1 : 0,
      p.allRule.priority,
      String(p.allRule._id),
    ]);
    this.coupons.splice(insertIdx, 0, couponItem);
  }

  async tryApplyCoupon(coupon: CouponType, props?: any) {
    if (!this.couponsLoaded) {
      await this.restoreCoupons();
    }
    if (!checkID(coupon.user, this.sessionData.user)) {
      return { $t: "couponErrors.invalidCode" };
    }

    if (coupon.status !== "valid" && !checkID(this.sessionData?._id, coupon.session)) {
      return { $t: "couponErrors.alreadyUsed" };
    }

    if (moment(this.sessionData.paidTime || new Date()).isAfter(coupon.endDate)) {
      return { $t: "couponErrors.couponOutdate" };
    }

    if (this.coupons.find(it => checkID(it.coupon, coupon))) {
      return { $t: "couponErrors.alreadyAdded" };
    }

    if (coupon.shops?.length && !coupon.shops.find(s => checkID(s, this.shopId))) {
      return { $t: "couponErrors.notCurrentShop" };
    }

    const validateResp = await this.feathers.service("userCoupons/validate").create({
      coupon: coupon._id,
      session: this.sessionData?._id,
    });
    if (validateResp.status !== "valid") {
      return { $t: "couponErrors." + validateResp.status };
    }

    if (this.coupons.find(it => checkID(it.coupon, coupon))) {
      return { $t: "couponErrors.alreadyAdded" };
    }

    const couponItem = new CouponItem({
      propsData: {
        coupon,
        discountSource: "coupon",
      },
      parent: this,
    });

    this.insertCoupon(couponItem);

    if (!props?.deferUpdate) {
      this.updateCoupons(true);
      this.$emit("updated");
    }

    return couponItem;
  }

  async tryApplyGift(gift: GiftType, giftRecord?: string, props?: any) {
    if (!this.couponsLoaded) {
      await this.restoreCoupons();
    }
    const couponItem = new CouponItem({
      propsData: {
        discountSource: "gift",
        gift,
        ...props,
      },
      parent: this,
    });

    if (giftRecord) {
      couponItem.giftRecord = giftRecord;
    }

    this.insertCoupon(couponItem);

    if (!props?.deferUpdate) {
      this.updateCoupons(true);
      this.$emit("updated");
    }

    return couponItem;
  }

  async tryApplyDiscount(discount: DiscountType, props?: any) {
    if (!this.couponsLoaded) {
      await this.restoreCoupons();
    }
    let couponItem = this.coupons.find(it => checkID(it.discount, discount));

    if (couponItem) return;

    couponItem = new CouponItem({
      propsData: {
        discount,
        ...props,
      },
      parent: this,
    });
    if (props?.disabled) {
      couponItem.disabled = true;
    }

    this.insertCoupon(couponItem);

    if (!props?.deferUpdate) {
      this.updateCoupons(true);
      this.$emit("updated");
    }

    return couponItem;
  }

  async tryApplyTax(tax: FindType<"taxSettings">, props?: any) {
    if (!this.couponsLoaded) {
      await this.restoreCoupons();
    }
    let couponItem = this.coupons.find(it => checkID(it.tax, tax));

    if (couponItem) return;

    couponItem = new CouponItem({
      propsData: {
        tax,
        discountSource: "tax",
      },
      parent: this,
    });
    if (props?.disabled) {
      couponItem.disabled = true;
    }

    this.insertCoupon(couponItem);

    if (!props?.deferUpdate) {
      this.updateCoupons(true);
      this.$emit("updated");
    }

    return couponItem;
  }

  async tryApplySurcharge(surcharge: FindType<"surchargeSettings">, props?: {
    disabled?: boolean;
    enabled?: boolean;
    deferUpdate?: boolean;
  }) {
    if (!this.couponsLoaded) {
      await this.restoreCoupons();
    }
    let couponItem = this.coupons.find(it => checkID(it.surcharge, surcharge));

    if (couponItem) return;

    couponItem = new CouponItem({
      propsData: {
        surcharge,
        discountSource: "surcharge",
      },
      parent: this,
    });
    if (props?.disabled) {
      couponItem.disabled = true;
    }
    
    if (surcharge?.manual && !props?.enabled) {
      couponItem.active = false;
    }

    this.insertCoupon(couponItem);

    if (!props?.deferUpdate) {
      this.updateCoupons(true);
      this.$emit("updated");
    }

    return couponItem;
  }

  async tryApplyVip(vipLevel: FindType<"vipLevels">) {
    if (!vipLevel.discountLists) return;
    for (let item of vipLevel.discountLists) {
      if (item.shops?.length && !item.shops.find(j => checkID(j, this.shopId))) continue;

      for (let d of item.discounts) {
        const item = (
          await this.feathers.service("orderDiscounts").find({
            query: { _id: d, $paginate: false },
            paginate: false,
          })
        )[0];
        await this.tryApplyDiscount(item, {
          discountSource: "vip",
          vipLevel: getID(vipLevel),
        });
      }
    }
  }

  async tryApplyAutoFreeProducts() {
    for (let coupon of this.coupons) {
      if (!coupon.valid && coupon.error.$t === "errors.ruleMissingFree" && coupon.rule.automaticAddFreeProduct) {
        await coupon.tryFix();
      }
    }
  }

  _cachedContext: DiscountComputeContext;
  beforeAdjustExtTax = 0;
  async tryApplyRank(rank: FindType<"shopRanks">) {
    const rankDiscounts = await this.feathers.service("orderDiscounts").find({
      query: { source: "memberRank", "memberRank.ranks": { $in: getID(rank) }, $paginate: false },
      paginate: false,
    });
    for (let discount of rankDiscounts) {
      const cur = this.coupons.find(it => checkID(it.discount, discount));
      if (!cur) {
        const result = await this.tryApplyDiscount(discount, {
          rank: rank,
          discountSource: discount?.source,
        });
      }
    }
  }

  updateCoupons(noRemove = true) {
    if (!this.couponsLoaded) {
      console.warn("Coupon is not loaded");
      return [];
    }

    const context = new DiscountComputeContext(this);

    if (this.sessionData.preAdjusts) {
      context.applyAdjusts(this.sessionData.preAdjusts);
    }

    for (let coupon of this.coupons) {
      if (coupon.discountSource === "tax") {
        coupon.updateTax(context);
      } else if (coupon.discountSource === "surcharge") {
        coupon.updateSurcharge(context);
      } else {
        coupon.updateCoupon(context);
      }
    }

    const calList = context.list;

    for (let coupon of this.coupons.slice()) {
      if (coupon.valid) {
      } else if (!noRemove) {
        coupon.$destroy();
        const idx = this.coupons.indexOf(coupon);
        idx !== -1 && this.coupons.splice(idx, 1);
      }
    }

    const activeRules = this.coupons.filter(it => it.valid || it.disabled);
    const activeDiscounts = activeRules.filter(it => it.discountSource !== "tax" && it.discountSource !== "surcharge");
    const activeSurcharges = activeRules.filter(it => it.discountSource === "surcharge");
    const activeTaxes = activeRules.filter(it => it.discountSource === "tax");

    if (this.sessionData.adjusts) {
      const { taxes } = context.toTaxItems(activeTaxes);
      this.beforeAdjustExtTax = _.sumBy(taxes, it => (it.disabled || it.included ? 0 : it.amount));
      context.applyAdjusts(this.sessionData.adjusts);
    }

    context.updateTips();
    context.updatePaymentSurcharge();

    this.sessionData.surcharges = context.toSurchargeItems(activeSurcharges);
    this.sessionData.discounts = context.toDiscountItems(activeDiscounts);

    const finalTax = context.toTaxItems(activeTaxes);

    this.sessionData.taxes = finalTax.taxes;
    this.sessionData.taxFree = finalTax.taxFree;

    if (context.tipsFee) {
      this.sessionData.tipsWithoutTaxAdj = context.tipsFee.computeTotal(["tips"], undefined, true);
      const tipsTax = context.tipsFee.computeTotalTax();
      this.sessionData.tipsTax = tipsTax.totalIncTax + tipsTax.totalExcTax;
      this.sessionData.tipsWithoutTax = this.sessionData.tips - this.sessionData.tipsTax;
    } else {
      this.sessionData.tipsWithoutTaxAdj = 0;
      this.sessionData.tipsTax = 0;
      this.sessionData.tipsWithoutTax = 0;
    }

    if (context.paymentSurchargeFee) {
      const paymentSurchargeTax = context.paymentSurchargeFee.computeTotalTax(true);
      this.sessionData.paymentSurchargeTax = paymentSurchargeTax.totalIncTax + paymentSurchargeTax.totalExcTax;
      this.sessionData.paymentSurchargeWithoutTax = this.totalPaymentSurcharge - this.sessionData.paymentSurchargeTax;
    } else {
      this.sessionData.paymentSurchargeTax = 0;
      this.sessionData.paymentSurchargeWithoutTax = 0;
    }

    this.sessionData.lineAdjusts = context.list
      .filter(it => it.adjusts)
      .map(it => ({
        id: it.id,
        amount: it.adjusts,
      }));

    this._cachedContext = context;

    if (this.computeDiscountHint) {
      const discountRules = this.coupons.filter(it => it.discountSource !== "tax" && it.discountSource !== "surcharge");
      this.discountHints = context.calculateDiscountHints(discountRules);
    }

    return calList;
  }

  previewPaymentSurcharge(surcharge: number) {
    if (!this._cachedContext) return 0;
    return this._cachedContext.previewPaymentSurcharge(surcharge);
  }

  applyAdjusts(adjust: number) {
    const cachedCalList = this._cachedContext?.list;
    if (this.couponsLoaded && cachedCalList?.length && this.taxes?.length) {
      let finalAdjust = 0;
      for (let item of cachedCalList) {
        item.adjusts = 0;
      }

      const total = _.sumBy(cachedCalList, it => it.total);
      const ratio = total ? adjust / total : 0;

      for (let item of cachedCalList) {
        let adj = item.total * ratio;
        const excPercent = _.sumBy(item.excludedTaxes, it => it.percent);
        adj = adj / (1 + excPercent / 100);
        item.adjusts = adj;
        finalAdjust += adj;
      }

      this.sessionData.adjusts = finalAdjust;
    } else {
      this.sessionData.adjusts = adjust;
    }
  }

  async restoreCoupons(noRemove: boolean = false) {
    let removedCoupons = 0;
    this.couponsLoaded = true;

    const updated: CouponItem[] = [];

    const needFindGifts = (this.sessionData.discounts || [])
      .filter(it => it.source === "gift" && it.giftRecord)
      .map(it => getID(it.gift))
      .filter(c => !this.coupons.find(it => checkID(it.gift, c)));
    const needFindCoupons = (this.sessionData.discounts || [])
      .filter(it => it.source === "coupon")
      .map(getID)
      .filter(c => !this.coupons.find(it => checkID(it.coupon, c)));
    const needFindDiscounts = (this.sessionData.discounts || [])
      .filter(it => it.source !== "coupon" && it.source !== "gift")
      .map(getID)
      .filter(c => !this.coupons.find(it => checkID(it.discount, c)));

    const gifts = needFindGifts?.length
      ? await this.feathers.service("gifts").find({
          query: {
            _id: {
              $in: needFindGifts,
            },
            $populate: ["coupon"],
            $paginate: false,
          },
          paginate: false,
        })
      : [];

    const coupons = needFindCoupons?.length
      ? await this.feathers.service("userCoupons").find({
          query: {
            _id: {
              $in: needFindCoupons,
            },
            $populate: ["coupon"],
            $paginate: false,
          },
          paginate: false,
        })
      : [];

    const discounts = needFindDiscounts.length
      ? await this.feathers.service("orderDiscounts").find({
          query: {
            _id: {
              $in: needFindDiscounts,
            },
            $paginate: false,
          },
          paginate: false,
        })
      : [];

    for (let info of this.sessionData.discounts) {
      if (info.source === "coupon") {
        const coupon = coupons.find(it => checkID(it, info.coupon));
        if (!coupon) {
          removedCoupons++;
          continue;
        }
        const cur = this.coupons.find(it => checkID(it.coupon, coupon));
        if (!cur) {
          const result = await this.tryApplyCoupon(coupon, {
            deferUpdate: true,
          });
          if (!noRemove && result instanceof CouponItem) {
            updated.push(result);
          } else {
            removedCoupons++;
          }
        }
      } else if (info.source === "gift") {
        const coupon = gifts.find(it => checkID(it, info.gift));
        if (!coupon) {
          removedCoupons++;
          continue;
        }
        const cur = this.coupons.find(it => checkID(it.giftRecord, info.giftRecord));
        if (!cur) {
          const result = await this.tryApplyGift(coupon, getID(info.giftRecord), {
            deferUpdate: true,
          });
          if (!noRemove && result instanceof CouponItem) {
            updated.push(result);
          } else {
            removedCoupons++;
          }
        }
      } else {
        const discount = discounts.find(it => checkID(it, info.discount));
        if (!discount) {
          removedCoupons++;
          continue;
        }
        const cur = this.coupons.find(it => checkID(it.discount, discount));
        if (!cur) {
          const result = await this.tryApplyDiscount(discount, {
            rank: info?.rank,
            vipLevel: info?.vipLevel,
            disabled: info?.disabled,
            discountSource: info?.source,
            deferUpdate: true,
          });
          if (!noRemove && result instanceof CouponItem) {
            updated.push(result);
          } else {
            removedCoupons++;
          }
        }
      }
    }

    const automaticDiscounts = await this.getAutomaticDiscounts();
    for (let discount of automaticDiscounts) {
      const cur = this.coupons.find(it => checkID(it.discount, discount));
      if (!cur) {
        const result = await this.tryApplyDiscount(discount, {
          deferUpdate: true,
          discountSource: discount?.source,
        });
      }
    }

    const taxSetting = this.taxSettings;
    const disabledRules = new Set((this.sessionData.taxes || []).filter(it => it.disabled).map(it => getID(it)));
    for (let tax of taxSetting) {
      await this.tryApplyTax(tax, {
        deferUpdate: true,
        disabled: disabledRules.has(getID(tax)),
      });
    }

    const surchargeSetting = this.surchargeSettings;
    const disabledSurcharge = new Set((this.sessionData.surcharges || []).filter(it => it.disabled).map(it => getID(it)));
    const enabledSurcharge = new Set((this.sessionData.surcharges || []).filter(it => !it.disabled).map(it => getID(it)));
    for (let surcharge of surchargeSetting) {
      await this.tryApplySurcharge(surcharge, {
        deferUpdate: true,
        disabled: disabledSurcharge.has(getID(surcharge)),
        enabled: enabledSurcharge.has(getID(surcharge)),
      });
    }

    this.updateCoupons(true);

    for (let result of updated) {
      if (!noRemove && result.error && !result.disabled) {
        removedCoupons++;
        result.remove();
      }
    }

    this.$emit("updated");

    return {
      removedCoupons,
    };
  }

  async redeemGifts() {
    let dirty = false;
    for (let coupon of this.coupons) {
      if (coupon.discountSource === "gift" && !coupon.giftRecord && coupon.active && coupon.valid) {
        const record = await this.feathers.service("gifts/redeem").create({
          gift: getID(coupon.gift),
          user: getID(this.sessionData.user),
          shop: getID(this.shopData),
          session: getID(this.sessionData._id),
        } as any);
        coupon.giftRecord = getID(record);
        for (let item of this.cart) {
          if (item.coupon === coupon) {
            item.fromCoupon = getID(coupon);
          }
        }
        dirty = true;
      }
    }
    return dirty;
  }

  // #endregion

  // #region stock

  get stockStatus() {
    const levels: Record<string, StockLevel> = {};
    for (let item of this.cart) {
      if (!item.product) continue;
      levels[getID(item.product)] = item.stockLevel;
    }
    for (let [k, v] of Object.entries(this.queryStock())) {
      levels[k] = Math.min(levels[k], (v as any).stockLevel);
    }
    return levels;
  }

  // #endregion

  // #region enum helpers

  get readonly() {
    return this.sessionData?.status !== "ongoing";
  }

  get isOngoing() {
    return this.sessionData?.status === "ongoing";
  }

  get isPaying() {
    return this.sessionData?.status === "toPay";
  }

  get isDone() {
    return this.sessionData?.status === "done" || this.sessionData?.status === "test";
  }

  get isVoid() {
    return this.sessionData?.status === "void";
  }

  get isCancel() {
    return this.sessionData?.status === "cancelled";
  }

  get isNoTable() {
    return this.isTakeAway || this.isDineInNoTable || this.isDelivery;
  }

  get isDineIn() {
    return this.sessionData?.type === "dineIn";
  }

  get isDineInNoTable() {
    return this.sessionData?.type === "dineInNoTable";
  }

  get isTakeAway() {
    return this.sessionData?.type === "takeAway";
  }

  get isDelivery() {
    return this.sessionData?.type === "delivery";
  }

  get hasHold() {
    return !!this.sessionData?.products?.find?.(it => it.status === "hold");
  }

  get isFinish() {
    return this.isDone || this.isCancel || this.isVoid;
  }

  // #endregion

  // #region tw tax

  get twTaxNumValid() {
    let canPay = true;
    if (this.sessionData.twTaxType === "electronic") {
      if (
        /^\/[0-9A-Z\.\-\+]{7}$/.test(this.sessionData.twTaxVehicle) ||
        /^[A-Z]{2}[0-9]{14}$/.test(this.sessionData.twTaxVehicle)
      ) {
        canPay = true;
      } else {
        canPay = false;
      }
    } else if (this.sessionData.twTaxType === "company") {
      if (/^[0-9]{8}$/.test(this.sessionData.twTaxComp)) {
        canPay = checkTwCenterNo(this.sessionData.twTaxComp);
      } else {
        canPay = false;
      }
    } else if (this.sessionData.twTaxType === "donate") {
      if (/^[0-9]+$/.test(this.sessionData.twDonate)) {
        canPay = true;
      } else {
        canPay = false;
      }
    } else if (this.sessionData.twTaxType === "nrt") {
      if (/^[a-zA-Z0-9]{10}$/.test(this.sessionData.twTaxNrt)) {
        canPay = true;
      } else {
        canPay = false;
      }
    }
    return canPay;
  }

  // #endregion

  availPoints: {
    [key: string]: number;
  } = {};

  async setUser(v: string) {
    this.sessionData.user = v as any;
    for (let coupon of this.coupons.slice()) {
      if ((coupon.coupon && !checkID(coupon.coupon.user, v)) || coupon.gift || coupon.vipLevel || coupon.rank) {
        coupon.$destroy();
        const idx = this.coupons.indexOf(coupon);
        if (idx !== -1) this.coupons.splice(idx, 1);
      }
    }

    this.updateCoupons();
    this.updateAvailPoints();
    if (this.isDineIn) {
      try {
        const s = this.feathers.service("tableSessions/setUser");
        if (s) {
          await s.create({
            session: this.sessionData._id,
          });
        }
      } catch (e) {
        console.log(e.message);
      }
    }
    this.$emit("updated");
  }

  async updateAvailPoints() {
    if (this.sessionData.user) {
      const resp = await this.feathers.service("users/points/available").find({
        query: {
          user: String(this.sessionData.user),
          shop: this.shopId,
          $pagniate: false,
        },
        paginate: false,
      });
      this.availPoints = Object.fromEntries(resp.map(it => [it._id, it.amount]));
    }
  }
  destroyed() {
    for (let child of this.$children) {
      child.$destroy();
    }
  }
}
