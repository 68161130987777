import { render, staticRenderFns } from "./image.vue?vue&type=template&id=101809f0"
import script from "./image.vue?vue&type=script&lang=ts"
export * from "./image.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('101809f0')) {
      api.createRecord('101809f0', component.options)
    } else {
      api.reload('101809f0', component.options)
    }
    module.hot.accept("./image.vue?vue&type=template&id=101809f0", function () {
      api.rerender('101809f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/printerEditor/image.vue"
export default component.exports