import { CartItem, ProductOptionSelect, ProductLineOption } from "./cart";
import _ from "lodash";
import { checkID, getID } from "@common";
import { v4 as uuid } from "uuid";

// export interface ProductOptionSelectWithPriceItem {
//   item: FindType<"productOptions">["options"][number];
//   readonly price: number;
//   active: boolean;
//   targetProduct: CartItem;
//   setActive(v: boolean): Promise<CartItem>;
// }

// export interface ProductOptionSelectWithPrice {
//   options: FindType<"productOptions">;
//   values: ProductLineOption["values"];
//   fixed: boolean;
//   free: boolean;
//   items: ProductOptionSelectWithPriceItem[];
// }

const ParentSymbol = Symbol("Parent");
export const TargetSymbol = Symbol("Target");

export class ProductOptionSelectWithPrice {
  [ParentSymbol]: CartItem;
  [TargetSymbol]: ProductOptionSelect;

  constructor(parent: CartItem, target: ProductOptionSelect, public idx: number) {
    this[ParentSymbol] = parent;
    this[TargetSymbol] = target;

    this.items = (target.options.options || [])
      .filter(it => !it.draft)
      .map(item => new ProductOptionSelectWithPriceItem(this, item));
  }

  get allowedOrderType() {
    return this.options?.allowedOrderType || [];
  }

  get activeOptions() {
    const values = this.values;
    return this.options.options.filter(it => values.find(j => checkID(j, it)));
  }

  get options() {
    return this[TargetSymbol].options;
  }

  get productOptionStock() {
    return this.options.stock;
  }
  
  get fixed() {
    return this[TargetSymbol].fixed;
  }
  get disabled() {
    return (
      this.fixed ||
      (this.options.multiple && this.options.maxPick && this.selections.length >= this.options.maxPick) ||
      (this.options.allowQuantity && this.options.maxQuantity && this.totalQuantity >= this.options.maxQuantity)
    );
  }
  get invalid() {
    const checkAvailability = this[ParentSymbol]?.parent?.checkAvailability;
    if (checkAvailability && !this.available) {
      // not available will be filtered out from parent
      return false;
    }
    return (
      (this.options.required && this.selections.length === 0) ||
      (this.selections.length &&
        ((this.options.multiple && this.options.maxPick && this.selections.length > this.options.maxPick) ||
          (this.options.allowQuantity && this.options.maxQuantity && this.totalQuantity > this.options.maxQuantity) ||
          (this.options.multiple && this.options.minPick && this.selections.length < this.options.minPick) ||
          (this.options.allowQuantity && this.options.minQuantity && this.totalQuantity < this.options.minQuantity) ||
          this.items.some(it => it.item.required && !this.optionQuantity[getID(it.item._id)]) ||
          this.selectionItems.some(it => it.targetProduct?.productOptionsWithPrice?.some(it => it.invalid)) ||
          Object.entries(this.optionQuantity)
            .map(k => {
              const targetLimit = this.optionLimitation[k[0]];
              const optionMinQuantity = targetLimit?.min;
              const optionMaxQuantity = targetLimit?.max;
              const optionQuantity = k[1];
              return (
                (optionMinQuantity && optionQuantity < optionMinQuantity) ||
                (optionMaxQuantity && optionQuantity > optionMaxQuantity)
              );
            })
            .some(it => it)))
    );
  }
  get free() {
    return this[TargetSymbol].free;
  }
  get values() {
    let selections = this[TargetSymbol].selections;
    if (selections) {
      return Array.from(new Set(selections.map(it => it._id)));
    }
    return this[TargetSymbol].values;
  }
  get selections() {
    let selections = this[TargetSymbol].selections;
    if (!selections) {
      const values = this[TargetSymbol].values || [];
      selections = this[TargetSymbol].selections = values.map(it => ({
        _id: it,
        id: getID(it),
        quantity: 1,
      }));
    }
    return selections;
  }
  get totalQuantity() {
    return _.sumBy(this.selections, it => it.quantity);
  }
  get optionQuantity() {
    return _.mapValues(
      _.groupBy(this.selections, it => it._id),
      it => _.sumBy(it, it => it.quantity),
    );
  }
  get optionLimitation(): { [key: string]: { min: number; max: number } } {
    return Object.fromEntries(
      this.items.map(it => {
        const optionMinQuantity = it?.item?.minQuantity;
        const optionMaxQuantity = it?.item?.maxQuantity;
        return [it.item._id, { min: optionMinQuantity, max: optionMaxQuantity }];
      }),
    );
  }

  set selections(v) {
    this.setSelections(v);
  }

  get nonSetItems() {
    return this.items.filter(it => it.item?.type !== "product");
  }

  get selectionItems() {
    return this.selections
      .map((it, idx) => {
        const item = this.items.find(item => checkID(item.item, it));
        if (!item) return null;
        return new ProductOptionSelectWithPriceSelection(this, item, idx, it);
      })
      .filter(it => !!it);
  }

  get nonSetSelectionItems() {
    return this.selectionItems.filter(it => it.item?.item?.type !== "product");
  }

  get remainingQuantity() {
    return this.options.maxQuantity ? this.options.maxQuantity - this.totalQuantity : 99;
  }

  async setSelections(v: ProductLineOption["selections"]) {
    this[TargetSymbol].selections = v;
    this[TargetSymbol].values = Array.from(new Set(v.map(it => it._id)));
    await this[ParentSymbol].updateDeps();
    this[ParentSymbol].notifyCartChanged();
  }

  items: ProductOptionSelectWithPriceItem[] = [];

  get availableItems() {
    return this.items.filter(it => it.available);
  }

  get available() {
    return this.timeAvailable && this.orderAvailable;
  }

  get orderAvailable() {
    return (
      !this.options?.allowedOrderType?.length ||
      this.options.allowedOrderType.includes(this[ParentSymbol]?.parent?.sessionData?.type)
    )
  }

  get timeAvailable() {
    const availableTimeConditionDict = this[ParentSymbol]?.parent?.availableTimeConditionDict;
    return (
      !this.options?.timeConditions?.length ||
      !availableTimeConditionDict ||
      this.options.timeConditions.some(it => availableTimeConditionDict[getID(it)])
    );
  }

  validate() {
    const checkAvailability = this[ParentSymbol]?.parent?.checkAvailability;
    if (!checkAvailability) return true;
    if (!this.available) {
      // not available will be filtered out from parent
      return true;
    } else {
      let valid = true;
      for (let item of this.items) {
        if (!item.validate()) {
          valid = false;
        }
      }
      return valid;
    }
  }
}

export class ProductOptionSelectWithPriceItem {
  constructor(
    public select: ProductOptionSelectWithPrice,
    public item: ProductOptionSelect["options"]["options"][number],
  ) {}

  get price() {
    if (this.select.free) return 0;
    let price = this.select[ParentSymbol].calculateOptionPrice(this.item);
    if (this.select.options.enjoyDiscount) {
      return price * this.select[ParentSymbol].finalDiscount;
    }
    return price;
  }

  get originalPrice() {
    if (this.select.free) return 0;
    return this.select[ParentSymbol].calculateOptionPrice(this.item);
  }

  get active() {
    return this.selections.length > 0;
  }
  set active(v) {
    this.setActive(v);
  }
  get targetProduct() {
    return this.targetProducts?.[0];
  }
  get targetProducts() {
    return (
      this.item.product &&
      this.select[ParentSymbol].cart.filter(
        it =>
          it.fromProduct === this.select[ParentSymbol].id &&
          checkID(it.fromOption, this.select.options) &&
          checkID(it.product, this.item.product),
      )
    );
  }
  async setActive(v: boolean) {
    const checkAvailability = this[ParentSymbol]?.parent?.checkAvailability;
    if (checkAvailability && (!this.available || !this.select.available)) return;

    let selections = this.select.selections;
    if (v !== this.active) {
      if (v) {
        const newItem: ProductOptionSelect["selections"][number] = {
          _id: this.item._id,
          id: uuid(),
          quantity: this.incrementQuantity || 1,
        };
        selections = this.select.options.multiple ? [...selections, newItem] : [newItem];
        await this.select.setSelections(selections);
        return newItem;
      } else {
        selections = _.filter(selections, val => val._id !== this.item._id);
        await this.select.setSelections(selections);
      }
    }
  }

  get selections() {
    return this.select.selections.filter(it => checkID(it, this.item));
  }

  get sumQuantity() {
    return _.sumBy(this.selections, it => it.quantity);
  }

  get incrementQuantity() {
    return !this.select.optionQuantity[getID(this.item._id)] && this.item.minQuantity ? this.item.minQuantity : 1;
  }

  get selectable() {
    if (this.select.disabled) return false;
    if (!this.item.minQuantity) return true;
    if (
      this.item.maxQuantity &&
      (this.select.optionQuantity[getID(this.item._id)] || 0) + this.incrementQuantity > this.item.maxQuantity
    )
      return false;
    if (
      this.select.options.maxQuantity &&
      (this.select.totalQuantity || 0) + this.incrementQuantity > this.select.options.maxQuantity
    )
      return false;
    return true;
  }

  async addSelection(quantity = null) {
    const checkAvailability = this[ParentSymbol]?.parent?.checkAvailability;
    if (checkAvailability && (!this.available || !this.select.available)) return;

    if (quantity == null) quantity = this.incrementQuantity;
    if (!this.selectable) return null;
    if (this.active && !this.select.options.allowSameValue) {
      const selectionItem = this.select.selectionItems.find(it => it.item === this);
      if (selectionItem) {
        // if don't allow same value to be chosen, increase quantity
        selectionItem.quantity += quantity;
      }
      return selectionItem.selection;
    }
    const newItem: ProductOptionSelect["selections"][number] = {
      _id: this.item._id,
      id: uuid(),
      quantity,
    };
    let selections = this.select.selections;
    selections = this.select.options.multiple ? [...selections, newItem] : [newItem];
    await this.select.setSelections(selections);
    return newItem;
  }

  async toggleSelection() {
    if (this.select.options.allowQuantity) {
      return await this.addSelection();
    } else {
      return await this.setActive(!this.active);
    }
  }

  async removeSelection(id: string) {
    await this.select.setSelections(this.select.selections.filter(it => it.id !== id));
  }

  get available() {
    const availableTimeConditionDict = this.select[ParentSymbol]?.parent?.availableTimeConditionDict;
    return (
      !this.item.timeConditions?.length ||
      !availableTimeConditionDict ||
      this.item.timeConditions.some(it => availableTimeConditionDict[getID(it)])
    );
  }

  validate() {
    const checkAvailability = this[ParentSymbol]?.parent?.checkAvailability;
    if (!checkAvailability) return true;
    if (!this.available) {
      if (this.selections.length) {
        this.select.setSelections(this.select.selections.filter(it => !checkID(it, this.item)));
      }
      return false;
    }
    return true;
  }
}

export class ProductOptionSelectWithPriceSelection {
  constructor(
    public select: ProductOptionSelectWithPrice,
    public item: ProductOptionSelectWithPriceItem,
    public idx: number,
    public selection: ProductLineOption["selections"][number],
  ) {}

  get quantity() {
    return this.selection.quantity;
  }

  set quantity(v) {
    this.selection.quantity = v;
    if (!this.selection.quantity) {
      this.item.removeSelection(this.selection.id);
    } else {
      this.select[ParentSymbol].updateDeps().finally(() => {
        this.select[ParentSymbol].notifyCartChanged();
      });
    }
  }

  remove() {
    this.item.removeSelection(this.selection.id);
  }

  get minQuantity() {
    return this.item.item.minQuantity - this.select.optionQuantity[getID(this.selection._id)] + this.quantity || 0;
  }

  get maxQuantity() {
    const groupMaxQuantity = this.select.options.maxQuantity
      ? this.select.options.maxQuantity - this.select.totalQuantity + this.quantity
      : undefined;
    const optionMaxQuantity = this.item.item.maxQuantity
      ? this.item.item.maxQuantity - this.select.optionQuantity[getID(this.selection._id)] + this.quantity
      : undefined;
    return groupMaxQuantity != undefined && optionMaxQuantity != undefined
      ? Math.min(groupMaxQuantity, optionMaxQuantity)
      : groupMaxQuantity != undefined
      ? groupMaxQuantity
      : optionMaxQuantity;
  }

  get required() {
    return this.item.item.required;
  }

  get targetProduct() {
    return (
      this.item.item.product &&
      this.select[ParentSymbol].cart.find(
        it =>
          it.fromProduct === this.select[ParentSymbol].id &&
          it.fromSelection === this.selection.id &&
          checkID(it.fromOption, this.select.options) &&
          checkID(it.product, this.item.item.product),
      )
    );
  }
}
