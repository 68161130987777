
import { Component, Vue, FindType, Prop, PropSync, checkID, Watch, mixins } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import Dialog from "domore-table/mixins/Dialog";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class CategoriesFilter extends mixins(Dialog()) {
  @Prop()
  cat;
  @Prop()
  sorting;
  @Prop()
  status;

  mounted() {
    this.sCat = this.cat;
    this.sSortingValue = this.sorting;
    this.sStatus = this.status;
  }

  sCat = null;
  sSortingValue: "newest" | "oldest" = "newest";
  sStatus = null;

  toggleSorting() {
    this.sSortingValue = this.sortings.find(x => x.value !== this.sSortingValue)?.value as "newest" | "oldest";
  }

  clear() {
    this.sCat = null;
    this.sSortingValue = "newest";
    this.sStatus = null;
  }

  submit() {
    this.modalResult({ cat: this.sCat, sorting: this.sSorting.value, status: this.sStatus });
  }
  get paymentStatus() {
    return [
      {
        _id: "unPaid",
        name: this.$t("paymentStatus.unPaid"),
      },
      {
        _id: "paid",
        name: this.$t("paymentStatus.paid"),
      },
    ];
  }

  get sSorting() {
    return this.sortings.find(x => x.value === this.sSortingValue) || this.sortings[0];
  }

  get sortings() {
    return [
      { name: this.$t("pages.ingredients.expenses.sorting.newest"), value: "newest", icon: "fa fa-angle-down" },
      { name: this.$t("pages.ingredients.expenses.sorting.oldest"), value: "oldest", icon: "fa fa-angle-up" },
    ];
  }
}
