var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"queueing-card bg-[#3D3D3D]",class:_vm.cardDetails.currentCallingTicket && _vm.cardDetails.currentCallingTicket.status === 'calling'
      ? 'group-color-animation'
      : '',style:([
    _vm.cardDetails.currentCallingTicket ? { '--card-color': '#F5B800' } : '',
    { width: _vm.width + 'px' },
    { height: _vm.height + 'px' },
  ])},[_c('div',{staticClass:"room-number"},[_c('div',{staticClass:"font-sans",class:_vm.$i18n.locale === 'en' ? '!text-40px' : '!text-48px'},[_vm._v(" "+_vm._s(_vm.$t("queueing.roomNo"))+" ")]),_c('div',{staticClass:"!text-110px text-white"},[_vm._v(_vm._s(_vm.cardDetails.room))])]),_c('div',{staticClass:"ticket-group"},[_c('div',{staticClass:"text-white text-4xl"},[_vm._v(" "+_vm._s(_vm.cardDetails.currentCallingTicket && (_vm.cardDetails.currentCallingTicket.status === "called" || _vm.cardDetails.currentCallingTicket.status === "calling") ? _vm.cardDetails.currentCallingTicket.ticketNumber === _vm.cardDetails.currentTicketName ? _vm.$t("queueing.clinic.currentCalling") : _vm.$t("queueing.reCall") : _vm.cardDetails.status === "checked" ? _vm.$t("queueing.clinic.currentCheckedNumber") : _vm.$t("queueing.clinic.currentNumber"))+" ")]),_c('div',{staticClass:"ticket-group-text font-barlow-bold",class:_vm.cardDetails.currentCallingTicket && _vm.cardDetails.currentCallingTicket.status === 'called'
          ? 'text-[#F5B800]'
          : _vm.cardDetails.currentCallingTicket && _vm.cardDetails.currentCallingTicket.status === 'calling'
          ? 'text-white'
          : _vm.cardDetails.status === 'called'
          ? 'text-[#F5B800]'
          : 'text-[#4BC77F]'},[_vm._v(" "+_vm._s(_vm.cardDetails.currentCallingTicket ? _vm.cardDetails.currentCallingTicket.ticketNumber : _vm.cardDetails.currentTicketName)+" ")])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }