
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    // EditorObjectPickerList,
    EditorTextField,
    // Editor
  }
})
export default class EmailDialog extends mixins(Dialog) {

  @Prop()
  pName: string

  @Prop()
  pEmail: string

  name: string = ""
  email: string = ""

  validEmail: boolean = null

  get edit() {
    return !!(this.pName || this.pEmail)
  }

  @Watch('email')
  onEmail(n, o) {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log((n))
    console.log(emailRegex.test(n))
    this.validEmail = emailRegex.test(n);
  }

  returnValue() {
    if(!this.email?.length || !this.name?.length || !this.validEmail) return;
    
    this.modalResult({
      name: this.name,
      email: this.email
    })
  }

  beforeMount() {
    this.name = this.pName ?? ""
    this.email = this.pEmail ?? ""
  }
}
