import { render, staticRenderFns } from "./subMethodInfos.vue?vue&type=template&id=1061b668"
import script from "./subMethodInfos.vue?vue&type=script&lang=ts"
export * from "./subMethodInfos.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1061b668')) {
      api.createRecord('1061b668', component.options)
    } else {
      api.reload('1061b668', component.options)
    }
    module.hot.accept("./subMethodInfos.vue?vue&type=template&id=1061b668", function () {
      api.rerender('1061b668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/payment/subMethodInfos.vue"
export default component.exports