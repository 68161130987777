
import { Component, PropSync, Vue, Watch, mixins, checkID, VModel } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";

@Component
export default class ConfirmReset extends mixins(Dialog()) {
  @VModel() openConfirmReset: boolean;
  @PropSync("isClose") mIsClose: boolean;

  isReset = false;

  confirmReset() {
    this.$emit("confirmReset");
    this.isReset = true;
    this.onClickOutside();
  }
  onClickOutside() {
    this.openConfirmReset = false;
    if (!this.isReset) {
      this.mIsClose = false;
    }
  }
}
