<template>
  <div class="bg-white">
    <img class="qrcode" v-if="qrcode" :src="qrcode" />
  </div>
    <!-- <div ref="qr" class="qrcode"/> -->
</template>


<script>

// import url from 'url'
// import QRCode from '~/plugins/easy-qrcode'
import { imageSync } from "qr-image";

export default {
    props: {
        url: {},
        logo: {},
        type: { type: String, default: 'logo' },
    },

    computed: {
      qrcode() {
        if (!this.url) return '';
        return "data:image/svg+xml;base64," + Buffer.from(imageSync(this.url, { type: "svg" })).toString("base64");
      }
    }

    // mounted() {
    //     new QRCode(this.$el, this.options);
    // },

    // computed: {
    //     options() {
    //         return {
    //             text: this.url,
    //             logo: this.type === 'logo' ? this.logo ? this.$image(this.logo) : this.$config.appLogo || require('~/assets/images/logo.png') : undefined,
    //             // colorDark: (ctx, x, y, w, h) => {
    //             //     const gradient = ctx.createLinearGradient(x, y, w, y);
    //             //     gradient.addColorStop(0, '#F45293');
    //             //     gradient.addColorStop(1, '#FDB955');
    //             //     return gradient;
    //             // },
    //             colorDark: this.type === 'logo' ? '#a38a41' : 'black',
    //             correctLevel: QRCode.CorrectLevel.M,
    //             width: 1024,
    //             height: 1024,
    //             quietZone: 64,
    //         }
    //     }
    // },
}
</script>

<style scoped>
</style>
