import { PrintQueue, PrintJob } from "../printQueue";
import { PrinterServer, wrapVue } from "..";
import { EscPosPrintSequence } from "../printSequence/escpos";
import { LabelSequence } from "../labelSequence";
import Vue from "vue";
import { PrinterBaseConf } from "./baseConf";
import type { PrinterConf, PrinterOpts } from "./baseConf";
import type { PrintSequence } from "../printSequence";
import { StarPrintSequence } from "../printSequence/star";
import { CanvasSequence } from "../printSequence/canvas";
import { ZPLLabelSequence } from "../labelSequence/zpl";
import moment from "moment";
export type { PrinterConf, PrinterOpts } from "./baseConf";

export abstract class PrinterBase<T = any, TConf extends PrinterConf = PrinterConf> extends PrinterBaseConf<TConf> {
  device: T;
  session = 0;
  printing = false;
  connected = false;
  autoReconnect = true;
  autoReconnecting = false;
  queue: PrintQueue;
  onDisconnected: (reason?: string) => void;
  noPaper = false;

  lastDisconnectReason: string = null;
  lastProbe: Date = null;

  get retryable() {
    return true;
  }

  constructor(public parent: PrinterServer, public conf: TConf) {
    super(conf);

    this.onDisconnected = reason => {
      if (!this.device) return;
      if (reason) {
        this.lastDisconnectReason = `${moment().format("YYYY-MM-DD HH:mm:ss")} ${reason}`;
      }
      this.connected = false;
      this.emit("statusChanged");
      this.emit("disconnected");
      const qs = this._queues;
      if (qs) {
        this._queues = null;
        for (const q of qs) {
          q.reject(new Error("Disconnected"));
        }
      }
      if (this.onDisconnectedCore) this.onDisconnectedCore();
      this.updateReconnect();
    };
  }

  updateReconnect() {
    if (this.autoReconnect && !this.autoReconnecting) {
      this.exponentialBackoff();
    }
  }

  get iconv() {
    return this.parent.iconv;
  }

  ensurePrinter() {
    return !!this.parent;
  }

  get bitmapList() {
    return this.parent?.bitmapList || {};
  }

  get fontsInfo() {
    return this.parent?.fontsInfo;
  }

  abstract onDisconnectedCore(): void;
  abstract initCore(): Promise<void>;
  abstract tryConnectCore(): Promise<void>;
  abstract printCore(job: PrintJob): void;
  abstract disconnectCore(): Promise<void>;
  abstract requestNewDeviceCore(): Promise<T>;

  createSequence<T>(context?: Vue): T {
    switch (this.conf.type) {
      case "thermal":
        if (this.conf.opts?.escpos === "star") {
          return new StarPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
        } else if (this.conf.opts?.escpos === "starGraph" || this.conf.opts?.escpos === "escposGraph") {
          return new CanvasSequence(wrapVue(context ?? this.parent.context), this) as any;
        } else {
          return new EscPosPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
        }
      case "label":
        if (this.conf.opts?.escpos === "zpl") {
          return new ZPLLabelSequence(wrapVue(context ?? this.parent.context), this) as any;
        } else {
          return new LabelSequence(wrapVue(context ?? this.parent.context), this) as any;
        }
    }
  }

  get printerOpts() {
    switch (this.conf.type) {
      case "thermal":
        return [
          // @ts-ignore
          () => import("../components/EscposOptions.vue"),
        ];
      case "label":
        return [
          // @ts-ignore
          () => import("../components/LabelOptions.vue"),
        ];
    }
  }

  async backoffWait(delay: number) {
    await new Promise(resolve => setTimeout(resolve, delay));
  }

  backoffSession: number;
  get initDelay() {
    return 2;
  }

  get maxDelay() {
    return 15;
  }

  async exponentialBackoff() {
    let s = ++this.session;
    this.backoffSession = s;
    let delay = this.initDelay;
    let tries = 0;
    while (this.session === s && this.autoReconnect) {
      try {
        if (this.session !== s) return;
        if (this.autoReconnecting) throw new Error("Already connecting");
        tries++;
        this.autoReconnecting = true;
        return await this.tryConnect();
      } catch (e) {
        if (this.backoffSession !== s) {
          console.log("session expired");
          return;
        }
        this.backoffSession = s = this.session;
        if (this.queue) {
          this.queue.lastError = `${moment().format("YYYY-MM-DD HH:mm:ss")} Failed to connect: ${e.message}`;
        }
        if (tries <= 3) {
          console.log(e);
        }
        const ms = delay * 1000 * (0.5 + Math.random() * 0.5);
        this.time("Retrying in " + ms.toFixed() + "ms...");
        await this.backoffWait(ms);
        if (this.backoffSession !== s || this.session !== s) {
          console.log("session expired");
          return;
        }
        delay = Math.min(this.maxDelay, delay * 1.5);
      } finally {
        this.autoReconnecting = false;
      }
    }
    if (this.session !== s) {
      console.log("Auto retry cancelled");
    }
  }

  time(text: string) {
    console.log("[" + new Date().toJSON().substr(11, 8) + "] " + text);
  }

  async init() {
    await this.tryConnect();
    await this.initCore();
  }

  _tryConnect: Promise<void>;

  tryConnect() {
    if (this.connected) return;
    return this._tryConnect || (this._tryConnect = this.mtryConnectCore());
  }

  get connecting() {
    return !!this._tryConnect;
  }

  async mtryConnectCore() {
    try {
      if (this.connected) return;
      this.endIdle();
      if (!this.device) await this.requestNewDevice();
      await this.tryConnectCore();
      this.connected = true;
      this.autoReconnecting = false;
      this.emit("statusChanged");
      this.session++;
      console.log({ session: this.session }, "mtryConnectCore");
      this.beginIdle();
    } finally {
      await new Promise(resolve => setTimeout(resolve, 10));
      this._tryConnect = null;
    }
  }

  async waitReady() {
    await this.tryConnect();
    return await this.waitReadyCore();
  }

  async waitReadyCore() {
    return true;
  }

  async waitDone(job: PrintJob) {
    if (!this.connected) return false;
    return await this.waitDoneCore(job);
  }

  async waitDoneCore(job: PrintJob) {
    return true;
  }

  async print(job: PrintJob) {
    await this.tryConnect();
    try {
      if (this.printing) return false;
      this.printing = true;
      await this.printCore(job);
    } catch (e) {
      throw e;
    } finally {
      this.printing = false;
    }
  }

  async cashBox(context?: Vue, which?: number, time?: number) {
    const sequence = await this.createSequence<PrintSequence>(context);
    sequence.cashBox(which, time);
    await this.queue.print(sequence.getJob("cashbox"));
  }

  async disconnect(manualReconnect?: boolean) {
    this.endIdle();
    this.session++;
    console.log({ session: this.session }, "disconnect");
    if (manualReconnect) {
      this.autoReconnect = false;
    }
    if (this.device) {
      await this.disconnectCore();
      if (manualReconnect) {
        this.device = null;
      }
    }
    this.connected = false;
    this.emit("statusChanged");
    this.emit("disconnected");
    this._tryConnect = null;
  }

  async requestNewDevice() {
    this.session++;
    console.log({ session: this.session }, "requestNewDevice");
    this.autoReconnect = true;
    await this.disconnect();
    this.device = await this.requestNewDeviceCore();
    // await this.init();
  }

  async clear() {}

  setConf(key: string, v: any) {
    Vue.set(this.conf, key, v);
    this.parent.savePrinters();
  }

  startPollingUpdate(job: PrintJob, queue: PrintQueue): void {}

  async send(buf: Buffer) {}

  sendSupported = false;
  probeSupported = false;

  async preAsbCheck(waitReady = true) {
    if (!this.sendSupported) return true;
    if (this.conf.type === "label") {
      if (this.conf.opts?.escpos) {
        if (this.conf.opts?.escpos === "zpl") {
          return await this._preAsbCheckZPL(waitReady);
        }
      }

      return await this._preAsbCheckTSPL(waitReady);
    } else if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos) {
        if (this.conf.opts?.escpos === "star" || this.conf.opts?.escpos === "starGraph") {
          return true;
        }
      }
      return await this._preAsbCheckEsc(waitReady);
    }
  }

  async _preAsbCheckTSPL(waitReady: boolean) {
    if (this.conf.opts?.asb) {
      this.resetRead();
      let statusTimeout = 0;
      const s = this.session;
      while (statusTimeout < 10 && s === this.session) {
        const dataToSend = Buffer.from([0x1b, 0x21, 0x3f]);
        await this.send(dataToSend);
        let resp = await this.readWithTimeout(1000);
        if (resp === false) {
          statusTimeout++;
          break;
        }
        if (!resp) {
          throw new Error("Disconnected");
        }
        statusTimeout = 0;
        if (resp[0] & 0x02 || resp[0] & 0x04) {
          if (!this.noPaper) {
            this.noPaper = true;
            this.emit("noPaperChanged", this.noPaper);
          }
        } else {
          if (this.noPaper) {
            this.noPaper = false;
            this.emit("noPaperChanged", this.noPaper);
          }
        }
        switch (resp[0]) {
          case 0x00:
          case 0x20:
            return true;
          default:
            if (!waitReady) return "notReady";
            await new Promise(resolve => setTimeout(resolve, 500));
            break;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  async _preAsbCheckZPL(waitReady: boolean) {
    if (this.conf.opts?.asb) {
      let statusTimeout = 0;
      this.resetRead();
      const s = this.session;
      while (statusTimeout < 10 && s === this.session) {
        const dataToSend = Buffer.from("~HS\r\n");
        await this.send(dataToSend);
        let resp = await this.readBufferedWithTimeout(buf => {
          let stxCount = 0;
          let stx = buf.indexOf(0x02);
          let firstStx = stx;
          while (stx >= 0) {
            const etx = buf.indexOf(0x03, stx);
            if (etx === -1) {
              break;
            }
            if (!stxCount) {
              const str = buf
                .slice(stx + 1, etx)
                .toString()
                .split(",");
              if (str.length >= 11) {
                firstStx = stx;
                stxCount++;
              }
            } else {
              stxCount++;
            }
            stx = buf.indexOf(0x02, etx);
          }
          if (stxCount >= 3) return true;
          if (firstStx) {
            return buf.slice(firstStx);
          }
          return false;
        }, 1000);
        if (resp === false) {
          statusTimeout++;
          break;
        }
        if (!resp) {
          throw new Error("Disconnected");
        }
        const strs: string[] = [];
        let stx = resp.indexOf(0x02);
        while (stx >= 0 && strs.length < 3) {
          const etx = resp.indexOf(0x03, stx);
          if (etx === -1) {
            break;
          }
          strs.push(resp.slice(stx + 1, etx).toString());
          stx = resp.indexOf(0x02, etx);
        }
        if (stx !== -1) {
          this.pushData(resp.slice(stx));
        }

        const status = strs[0].split(",");
        if (status[1] === "1") {
          if (!this.noPaper) {
            this.noPaper = true;
            this.emit("noPaperChanged", this.noPaper);
          }
        } else {
          if (this.noPaper) {
            this.noPaper = false;
            this.emit("noPaperChanged", this.noPaper);
          }
        }

        if (status[1] === "0" && status[2] === "0") {
          return true;
        }

        if (!waitReady) return "notReady";
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      return false;
    } else {
      return true;
    }
  }

  async _preAsbCheckEsc(waitReady: boolean) {
    const dataToSend: Buffer[] = [];
    let asb = false;
    let statusCheck = false;
    if (this.conf.opts?.clearBefore) {
      // DLE DC4 8 1 3 20 1 6 2 8 (clear buffer)
      dataToSend.push(Buffer.from([16, 20, 8, 1, 3, 20, 1, 6, 2, 8]));
      // add some padding to fix bixolon
      dataToSend.push(Buffer.from([0, 0]));
    }
    if (this.conf.opts?.asb) {
      // ESC @ (initialize)
      dataToSend.push(Buffer.from([27, 64]));
      // GS a 14 (8 roll paper + 4 error status + 2 offline status)
      dataToSend.push(Buffer.from([29, 97, 14]));
      asb = true;
    }

    if (this.conf.opts?.statusCheck) {
      // DLE EOT 2(offline status)
      dataToSend.push(Buffer.from([16, 4, 2]));
      statusCheck = true;
    }

    if (!dataToSend.length || !statusCheck) return true;

    this.resetRead();
    await this.send(Buffer.concat(dataToSend));

    let statusTimeout = 0;
    const s = this.session;
    while (statusTimeout < 10 && s === this.session) {
      let isWaiting = true;
      let waitMillis = 100;
      while (isWaiting) {
        let resp = await this.readWithTimeout(waitMillis);
        if (resp === false) {
          statusTimeout++;
          break;
        }
        if (!resp) {
          throw new Error("Disconnected");
        }
        while (resp.length) {
          if ((resp[0] & 0x93) === 0x10 && resp.length >= 4) {
            const cur = resp.slice(0, 4);
            resp = resp.slice(4);

            const newNoPaper = (cur[0] & 8) === 8 && ((cur[0] & 4) !== 4 || (cur[2] & 12) === 12);
            if (newNoPaper !== this.noPaper) {
              this.noPaper = newNoPaper;
              this.emit("noPaperChanged", this.noPaper);
            }

            if ((cur[0] & 8) !== 8) {
              return true;
            }
          } else if ((resp[0] & 0x93) === 0x12) {
            const cur = resp.slice(0, 1);
            resp = resp.slice(1);
            statusTimeout = 0;
            if (cur[0] & 0x6c) {
              if (!this.noPaper) {
                this.noPaper = true;
                this.emit("noPaperChanged", this.noPaper);
              }

              if (!waitReady) return "notReady";

              if (asb) {
                waitMillis = 5000;
              } else {
                await new Promise(resolve => setTimeout(resolve, 500));
                isWaiting = false;
                break;
              }
            } else {
              if (this.noPaper) {
                this.noPaper = false;
                this.emit("noPaperChanged", this.noPaper);
              }

              return true;
            }
          } else {
            console.log("Unknown response", resp);
            break;
          }
        }
      }
      // DLE EOT 2(offline status)
      await this.send(Buffer.from([16, 4, 2]));
    }

    return false;
  }

  async postAsbCheck() {
    if (!this.sendSupported) return true;
    if (this.conf.type === "label") {
      if (this.conf.opts?.escpos) {
        if (this.conf.opts?.escpos === "zpl") {
          return await this._preAsbCheckZPL(false);
        }
      }

      // for tspl, use same command as preAsbCheck
      return await this._preAsbCheckTSPL(false);
    } else if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos) {
        if (this.conf.opts?.escpos === "star" || this.conf.opts?.escpos === "starGraph") {
          return true;
        }
      }
      return await this._postAsbCheckESC();
    }
  }

  async _postAsbCheckESC() {
    if (this.conf.opts?.statusCheck) {
      const asb = this.conf.opts?.asb;
      this.resetRead();
      let statusTimeout = 0;
      let failed = false;
      const s = this.session;
      while (statusTimeout < 10 && s === this.session) {
        await this.send(Buffer.from([29, 114, 1, 16, 4, 2]));
        let waitMillis = 100;
        while (true) {
          let resp = await this.readWithTimeout(waitMillis);
          if (resp === false) {
            statusTimeout++;
            break;
          }
          if (!resp) {
            throw new Error("Disconnected");
          }
          while (resp.length) {
            if ((resp[0] & 0x93) === 0x10 && resp.length >= 4) {
              const cur = resp.slice(0, 4);
              resp = resp.slice(4);

              if ((cur[0] & 8) === 8 && ((cur[0] & 4) !== 4 || (cur[2] & 12) === 12)) {
                failed = true;
                this.noPaper = true;
                this.emit("noPaperChanged", this.noPaper);
              }
            } else if ((resp[0] & 0x93) === 0x12) {
              const cur = resp.slice(0, 1);
              resp = resp.slice(1);
              statusTimeout = 0;
              if (cur[0] & 0x6c) {
                failed = true;
                this.noPaper = true;
                this.emit("noPaperChanged", this.noPaper);
                if (asb) {
                  waitMillis = 5000;
                }
              } else {
                await new Promise(resolve => setTimeout(resolve, 500));
              }
            } else if ((resp[0] & 0x90) === 0) {
              const cur = resp.slice(0, 1);
              resp = resp.slice(1);
              if ((cur[0] & 12) === 12 || failed) {
                this.noPaper = true;
                this.emit("noPaperChanged", this.noPaper);
                throw new Error("No paper");
              } else {
                this.noPaper = false;
                this.emit("noPaperChanged", this.noPaper);
                return true;
              }
            } else {
              console.log("Unknown response", resp);
              break;
            }
          }
        }
      }
      return false;
    }
    return true;
  }

  pushData(buf: Buffer, unshift = false) {
    // console.log(buf.toString("hex").split(/(..)/g).join(" "), this.readers.length);
    if (this.readers.length) {
      this.readers.shift()(buf);
      return;
    }
    if (unshift) {
      this.buffers.unshift(buf);
    } else {
      this.buffers.push(buf);
    }
  }

  resetRead() {
    for (let r of this.readers) {
      r(null);
    }
    this.readers = [];
    this.buffers = [];
  }

  readers: ((data: Buffer) => void)[] = [];
  buffers: Buffer[] = [];

  async readWithTimeout(t = 1000) {
    return new Promise<Buffer | false>(resolve => {
      if (this.buffers.length) {
        resolve(this.buffers.shift());
        return;
      }
      const dataHandler = data => {
        cleanup();
        resolve(data);
      };
      const disconnectHandler = () => {
        cleanup();
        resolve(null);
      };
      let timeout = setTimeout(() => {
        timeout = null;
        cleanup();
        resolve(false);
      }, t);
      const cleanup = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        const i = this.readers.indexOf(dataHandler);
        if (i >= 0) {
          this.readers.splice(i, 1);
        }
        this.off("disconnected", disconnectHandler);
      };

      this.readers.push(dataHandler);
      this.once("disconnected", disconnectHandler);
    });
  }

  async readBufferedWithTimeout(cb: (buf: Buffer) => boolean | Buffer, t = 1000) {
    let buf = Buffer.alloc(0);
    do {
      const data = await this.readWithTimeout(t);
      if (!data) return false;
      buf = Buffer.concat([buf, data]);
      const r = cb(buf);
      if (!r) {
        continue;
      }
      if (r === true) return buf;
      if (r instanceof Buffer) buf = r;
    } while (true);
  }

  idleTimeout: any;
  statusTimeout: any;
  inIdleState = false;

  async statusProbe() {
    this.statusTimeout = null;
    try {
      await this.queuePrinter(async () => {
        await this.statusProbeCore();
        this.lastProbe = new Date();
      });
    } catch (e) {
      console.warn(e);
      this.onDisconnected?.(`Failed to probe status: ${e.message}`);
    }
    if (!this.statusTimeout && this.inIdleState) {
      this.statusTimeout = setTimeout(this.statusProbe.bind(this), 15000);
    }
  }

  async statusProbeCore() {
    if (!this.conf.opts?.statusProbe) return;
    const asb = await this.preAsbCheck(false);

    if (!asb) {
      this.onDisconnected("Printer failed to respond to ASB check");
    }
  }

  beginIdle(): void {
    if (!this.conf.opts?.statusProbe || (!this.sendSupported && !this.probeSupported)) return;
    if (this.idleTimeout || !this.connected) return;
    if (this.statusTimeout) {
      clearTimeout(this.statusTimeout);
      this.statusTimeout = null;
    }
    const c = this.session;
    this.idleTimeout = setTimeout(async () => {
      this.idleTimeout = null;
      if (this.session === c) {
        this.inIdleState = true;
        await this.idleTimeoutCore();
        this.statusTimeout = setTimeout(this.statusProbe.bind(this), 5000);
      }
    }, 500);
  }

  async idleTimeoutCore() {}

  endIdle(): void {
    this.inIdleState = false;
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = null;
    }
    if (this.statusTimeout) {
      clearTimeout(this.statusTimeout);
      this.statusTimeout = null;
    }
  }

  _queues: {
    resolve: () => void;
    reject: (e: Error) => void;
  }[];

  async queuePrinter(cb: () => Promise<void>) {
    if (this._queues) {
      await new Promise<void>((resolve, reject) => {
        this._queues.push({ resolve, reject });
      });
    } else {
      this._queues = [];
    }
    try {
      await this.queuePrinterCore();
      await cb();
    } finally {
      if (this._queues?.length) {
        this._queues.shift()?.resolve?.();
      } else {
        this._queues = null;
      }
    }
  }

  async queuePrinterCore() {}

  dispose() {
    this.conf = null;
    if (this.queue) {
      this.queue.printer = null;
    }
    this.queue = null;
    this.endIdle();
    this.session++;
    this.backoffSession++;
    this.device = null;
    this.onDisconnected = null;
  }
}
