
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { ProductType } from "~/plugins/shop";
import { CartItem } from "@common/table/cart";
import { getProductStockLevel, StockLevel } from "~/common/table/util";

@Component({})
export default class MobileCategories extends Vue {
  @Prop()
  categories;

  @Prop()
  showingDialog: boolean;

  @PropSync("selectedCat")
  selectedCatSync: FindType<"categories">;

  @PropSync("selectedSubCat")
  selectedSubCatSync: FindType<"subCategories">;
}
