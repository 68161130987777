
import { Vue, Component, Prop, Watch, Ref, FindType } from "@feathers-client";
import { CarouselItem } from "./multiShop.vue";

@Component
export default class Carousel extends Vue {
  @Prop()
  items: CarouselItem[];

  cachedItems: CarouselItem[] = [];

  @Prop()
  maxItems: number;

  @Prop({ type: Number, default: 50 })
  speed: number;

  @Prop({ type: Number, default: 1 })
  delay: number;

  @Ref("slide")
  slide!: HTMLElement;

  scrollableWidth = 0;

  get style() {
    return {
      "--animation-speed": `${this.scrollableWidth / this.speed}s`,
      "--scrollable-width": `${this.scrollableWidth}px`,
      "--ods-carousel-delay": `${this.delay}s`,
    };
  }

  dirty = false;
  refreshTimeout: any;

  mounted() {
    this.refresh();
  }

  scheduleUpdate() {
    if (this.scrollableWidth) {
      this.dirty = true;
    } else {
      this.refresh();
    }
  }

  onAnimationEnd() {
    if (this.dirty) {
      this.refresh();
    }
  }

  refresh() {
    if (this.refreshTimeout) {
      return;
    }
    this.refreshTimeout = setTimeout(() => {
      this.refreshTimeout = null;
      this.dirty = false;
      this.cachedItems = this.items.slice(0, this.maxItems);
      this.onResize();
    }, 1);
  }

  onResize() {
    if (!this.slide) return;
    this.scrollableWidth = 0;
    Vue.nextTick(() => {
      this.scrollableWidth = this.slide.scrollWidth / 2;
      if (this.scrollableWidth < this.slide.clientWidth) {
        this.scrollableWidth = 0;
      }
    });
  }
}
