
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeBarcode, compile, compileLabel } from "pos-printer-template";
import { PrintGraph } from "pos-printer/printSequence/graph";
import { imageSync } from "qr-image";

@Component
export default class PrinterEditorBarcode extends Vue {
  @Prop() template!: PrinterTemplateNodeBarcode;

  @Prop() context: any;

  @Prop()
  svg: boolean;

  get content() {
    if (this.context) {
      return this.template.getTextValue(this.context);
    }
    return "*HELLO*";
  }

  get x() {
    return +(this.template.props["x"]?.eval?.(this.context) ?? "0");
  }

  get y() {
    return +(this.template.props["y"]?.eval?.(this.context) ?? "0");
  }

  get w() {
    return +(this.template.props["w"]?.eval?.(this.context) ?? "0");
  }

  get h() {
    return +(this.template.props["h"]?.eval?.(this.context) ?? "0");
  }

  get s() {
    return +(this.template.props["s"]?.eval?.(this.context) ?? "0");
  }

  get type() {
    return this.template.props["type"]?.eval?.(this.context) ?? "code39";
  }

  get qrcode() {
    const graph = new PrintGraph(null, this.w, this.h);
    graph.barCode(this.content === "**" ? "*HELLO*" : this.content, this.type, this.x, this.y, this.w, this.h, this.s);
    const canvas = document.createElement("canvas");
    canvas.width = this.w;
    canvas.height = this.h;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(new ImageData(new Uint8ClampedArray(graph.buffer), this.w, this.h), 0, 0);
    return canvas.toDataURL("image/png");
  }

  get style() {
    return {
      ...(this.align !== undefined
        ? {
            alignSelf: {
              left: "flex-start",
              center: "center",
              right: "flex-end",
            }[this.align],
          }
        : {}),
    };
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }
}
