
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";

@Component
export default class ConfirmDialog extends mixins(Dialog) {
  @Prop()
  title!: string;

  @Prop()
  desc: string;

  @Prop({ default: "text-light-text-secondary" })
  descClass: string;

  @Prop({ default: "w-6 h-6" })
  iconClass: string;

  @Prop()
  icon: string;

  @Prop()
  confirmText: string;

  @Prop({ default: "bg-$action-primary-default text-primary" })
  confirmBtnClass: string;

  @Prop({ default: "w-6 h-6" })
  confirmIconClass: string;

  @Prop()
  confirmIcon: string;

  @Prop()
  cancelText: string;

  @Prop({ default: "text-$action-primary-default border-1 border-solid border-$action-primary-default" })
  cancelBtnClass: string;

  @Prop({ default: "w-6 h-6" })
  cancelIconClass: string;

  @Prop()
  cancelIcon: string;

  @Prop({ type: Boolean, default: true })
  hasCancel: boolean;

  mounted() {
    // setTimeout(() => this.modalResult(true), 2000);
  }
  get posMode() {
    switch (this.$shop.localOptions?.posMode ?? "auto") {
      case "auto":
        return this.$breakpoint.mdAndUp ? "tablet" : "mobile";
      case "tablet":
        return "tablet";
      case "mobile":
        return "mobile";
    }
  }
}
