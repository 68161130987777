
import { Component, Vue, Prop } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { VModel, Watch } from "vue-property-decorator";
import { LangArrType } from "@feathers-client/i18n";
import { BOXS_CLOUD_URL } from "pos-printer/ports/cloud";

export type AddressBase = {
  _id: string;
  slug: string;
  name: LangArrType;
  parts: {
    type: string;
  }[];
}[];

export type Addresses = {
  [x: string]: AddressBase;
};

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class AddressPicker extends Vue {
  @VModel()
  inputValue: { _id: string; name: LangArrType };

  @Prop({ type: String, default: "" })
  path: string;

  @Prop()
  label: string;

  @Prop()
  containerClass: string;

  addressList: AddressBase = [];

  parts = [];

  async beforeMount() {
    if (!this.inputValue?._id) {
      this.inputValue = { _id: null, name: [] };
    }
    await this.fetchAddress();
  }

  @Watch("path")
  async fetchAddress() {
    try {
      //const response = await fetch(`${BOXS_CLOUD_URL}addresses/all.json`);

      if (this.path === "") {
        const response = await fetch(`https://cloud.boxs.hk/api/addresses/all.json`);
        if (!response.ok) {
          throw new Error(`Failed to fetch addresses, status: ${response.status}`);
        }
        this.addressList = (await response.json()).map(r => ({ ...r, _id: r.slug }));
      } else {
        const paths = this.path.split("/");
        const response = await fetch(`https://cloud.boxs.hk/api/addresses/${this.path}/all.json`);
        if (!response.ok) {
          throw new Error(`Failed to fetch addresses, status: ${response.status}`);
        }

        const result: Addresses = await response.json();
        const parts = result.country[0].parts.map(p => p.type);

        this.addressList = result[parts[paths.length]]?.map?.(r => ({ ...r, _id: r.slug })) ?? [];
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  }

  updateName(val) {
    console.log(val);
    if (val) {
      this.inputValue.name = val.name;
    } else {
      this.inputValue.name = null;
    }
  }

  get id() {
    return this.inputValue?._id;
  }

  set id(v) {
    this.inputValue._id = v;
  }

  get nameField() {
    return [
      {
        translate: true,
        field: "name",
      },
    ];
  }
}
