import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { MapFields } from "./utils";

@Component
export class Ingredient extends mixins(
  MapFields(
    "ingredients/cached",
    ["name", "currentStock", "type", "unit", "priceQuantity", "lowStockAlert", "code"] as const,
    [],
    {},
    {
      deltaUpdate: true,
    },
  ),
) {}
