
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import canvg, { Element } from 'canvg'
import type { Shape } from "konva/lib/Shape";

let globalContext : CanvasRenderingContext2D;

@Component
class SvgVue extends Vue {
    item : CanvasSvg

    ready = false;
    canvg : canvg;
    svgElement : Element;
    render(_c) {
        Vue.nextTick(this.updateSvg);
        return this.item.$scopedSlots.default?.(this.item.props || {})?.[0];
    }
    mounted() {
        this.updateSvg();
    }
    async updateSvg() {
        this.ready = false;
        if(!this.$el) return;
        if(this.$el.tagName !== 'svg') return;
        const svgString = this.$el.outerHTML;
        if(!globalContext) {
            const elem = document.createElement('canvas');
            globalContext = elem.getContext('2d');
        }
        this.canvg = canvg.fromString(globalContext, svgString);
        await this.canvg.ready();
        this.ready = true;
        this.svgElement = (this.canvg as any).documentElement
        this.$emit('ready');
    }
    renderTo(context : CanvasRenderingContext2D, shape : Shape) {
        if(!this.ready) return;
        this.canvg.screen.viewPort.clear();
        this.canvg.screen.viewPort.setCurrent(shape.attrs.w, shape.attrs.h);
        // this.svgElement.getAttribute('x').setValue(shape.attrs.x || 0);
        // this.svgElement.getAttribute('y').setValue(shape.attrs.y || 0);
        this.svgElement.render(context);
    }
}

function renderSlot(parent : Vue) {
    const svg = new SvgVue({
        data: {
            item: parent,
        }
    });
    const template = document.createElement('template');
    svg.$mount(template);
    return svg;
}

@Component
export default class CanvasSvg extends Vue {

    @Prop()
    config : any

    @Prop()
    props : any

    svg : SvgVue = null;

    render(_c) {
        if(!this.svg) {
            this.svg = renderSlot(this);
            this.svg.$on('ready', () => {
                if(this.config.cache && this.$refs.shape) {
                    (this.$refs.shape as any).getNode().cache({ width: this.config.w, height: this.config.h});
                }
            })
        }
        return _c('v-shape', {
            props: {
                config: {
                    ...this.config,
                    sceneFunc: this.svg.renderTo,
                },
            },
            ref: 'shape'
        })
    }

}
