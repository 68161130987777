import { MHookContext } from "@feathersjs/feathers";
import { escapeRegExp, FindType } from "@feathers-client";
import { AdminApplication } from "serviceTypes";
import moment from "moment";
import type { OfflineManager } from "../";

export async function create(hook: MHookContext<AdminApplication, FindType<"tableSessions">>) {
  let orderPrefix = "";
  if (!hook.data.date) {
    hook.data.date = new Date();
  }

  const shop = await hook.app.service("shops").get(hook.data.shop);

  if (hook.data.type === "takeAway" || hook.data.type === "dineInNoTable" || hook.data.type === "delivery") {
    if (hook.data.type === "takeAway" && shop.takeAwayPrefix) {
      if (hook.data.source === "selfOrder") {
        orderPrefix = shop.selfOrderTakeAwayPrefix ?? shop.takeAwayPrefix;
      } else {
        orderPrefix = shop.takeAwayPrefix;
      }
    }
    if (hook.data.type === "dineInNoTable" && shop.dineInNoTablePrefix) {
      if (hook.data.source === "selfOrder") {
        orderPrefix = shop.selfOrderDineInNoTablePrefix ?? shop.dineInNoTablePrefix;
      } else {
        orderPrefix = shop.dineInNoTablePrefix;
      }
    }
    if (hook.data.type === "delivery" && shop.deliveryPrefix) {
      if (hook.data.source === "selfOrder") {
        orderPrefix = shop.selfOrderDeliveryPrefix ?? shop.deliveryPrefix;
      } else {
        orderPrefix = shop.deliveryPrefix;
      }
    }
  } else {
    const viewSetting = await hook.app.service("tableViews").get(hook.data.view);
    if (viewSetting.orderPrefix) orderPrefix = viewSetting.orderPrefix;
    if (!orderPrefix) {
      const shop = await hook.app.service("shops").get(hook.data.shop);
      if (shop.defaultDineInPrefix) orderPrefix = shop.defaultDineInPrefix;
    }
  }

  const offline: OfflineManager = (hook as any).$offline;
  const offlinePrefix = offline.root?.$shop?.device?.offlinePrefix;

  if (!offlinePrefix) {
    throw new Error("Offline prefix not setup");
  }

  orderPrefix = offlinePrefix + orderPrefix;
  if (!hook.data.shopSession) {
    hook.data.shopSession = offline.root.$shop.shopData.currentSession;
  }

  const prefixSetting = shop.prefixSettings?.find?.(it => (it.prefix || "") === (orderPrefix || ""));
  const prefixCount = prefixSetting?.count ?? 999;
  const prefixStart = prefixSetting?.start ?? 1;
  const prefixDigits = Math.ceil(Math.log10(prefixCount + prefixStart - 1));

  const lastSession = (
    await hook.app.service("tableSessions").find({
      query: {
        sessionName: {
          $regex: `^${escapeRegExp(orderPrefix)}\\d{${prefixDigits}}`,
        },
        shopSession: hook.data.shopSession,
        $sort: { date: -1 },
        $paginate: false,
        $limit: 1,
      } as any as {},
      paginate: false,
    })
  )[0]?.sessionName;

  let num = prefixStart;
  if (lastSession) {
    num = parseInt(lastSession.substring(orderPrefix.length));
    num++;
    if (isNaN(num)) num = prefixCount;
  }

  if (num >= prefixStart + prefixCount) {
    num -= prefixCount;
  }

  if (!hook.data.salesDate) {
    if (!hook.data.shopSession) {
      hook.data.salesDate = moment().format("YYYY-MM-DD");
    } else {
      hook.data.salesDate =
        (
          await hook.app.service("shopSessions").find({
            query: {
              _id: hook.data.shopSession,
              $paginate: false,
            },
            paginate: false,
          })
        )?.[0]?.salesDate ?? moment().format("YYYY-MM-DD");
    }
  }

  // for offline order, we do not wrap the order number, even if it's over 999
  const finalPrefix = prefixSetting?.prefixOverride ? prefixSetting.prefixOverride.trim() : orderPrefix;
  const code = finalPrefix + `${num}`.padStart(prefixDigits, "0");
  hook.data.sessionName = code;
  hook.data.orderId = hook.data.salesDate.replace(/-/g, "") + finalPrefix + `${num}`.padStart(4, "0");
  hook.data.endTime = null;
  if (!hook.data.createStaff) hook.data.createStaff = hook.data.staff;
}

export async function allAfter(hook: MHookContext<AdminApplication, FindType<"tableSessions">>) {
  switch (hook.method) {
    case "create":
    case "patch": {
      const offline: OfflineManager = (hook as any).$offline;
      offline.offlineOrders.add(hook.result._id);
      offline.updateOfflineStat();

      if (hook.method === "create") {
        await hook.app.service("actionLogs").create({
          ...hook.data,
          staff: hook.data.createStaff,
          session: hook.result._id,
          view: hook.result.view,
          type: "tableManage/tableSessionCreateOrder",
        });
      }
      break;
    }
  }
}
