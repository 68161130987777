import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import errors from "@feathersjs/errors";
import { OrderKitchenView, ProductLine, OrderKitchenViewItem } from "@common/table/invoiceSequencer";
import { handlePrintJob, getProductItems, JobEntry } from "./order";
import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { getMapById } from "./order";

export async function create(
  hook: MHookContext<AdminApplication, CreateType<"tableSessions/reprint">, FindType<"tableSessions/reprint">>,
) {
  const session = await hook.app.service("tableSessions").get(hook.data.session);
  hook.result = session;
  const products = _.uniq(hook.data.products);

  const deletedProducts: ProductLine[] = [];

  for (let i = 0; i < products.length; i++) {
    let product = hook.data.products[i];
    const line = session.products.find(p => product === p.id || product._id === p);

    if (!line) {
      throw new errors.BadRequest("Cannot find product");
    }

    deletedProducts.push(line);
  }

  const app = hook.app;

  const jobList: any[] = [];

  const [tables, shopCache, staff] = await Promise.all([
    getMapById(
      app.service("tableViewItems"),
      _.uniq(deletedProducts.map(it => getID(it.table))).filter(it => !!it),
    ),
    app?.$shopCache?.getShop(session.shop),

    hook.data.staff && app.service("staffs").get(hook.data.staff),
  ]);

  const jobFlatten: JobEntry[] = [];
  for (let deleted of deletedProducts) {
    const table = tables[getID(deleted.table)];
    const job: Partial<OrderKitchenView> = {
      session,

      tableName: table?.name,
      tableSplit: deleted.tableSplit,
      chairId: deleted.tableChairId,

      table,

      staffName: staff?.name,
      type: deleted.status === "cancel" ? "cancel" : "reprint",
    };
    jobFlatten.push(...getProductItems(deleted, job, shopCache));
  }
  await handlePrintJob(app, hook.result, jobList, jobFlatten);
}
