

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";

@Component
export default class CurrencyEditor extends Vue {
    @Prop()
    label : string

    @Prop()
    value : any

    @Prop()
    multiple : Boolean

    @Prop(Boolean)
    readonly : boolean

    @Prop(Boolean)
    editor : boolean

    @Prop(Boolean)
    multiLine : boolean

    @Prop({ type: Boolean })
    outlined : boolean

    @Prop({ type: Boolean })
    dense : Boolean

    @Prop({ type: Boolean })
    hideDetails : Boolean

    get inputValue() {
        return this.value ? JSON.stringify(this.value) : ''
    }

    set inputValue(v) {
        try {
            this.$emit('input', JSON.parse(v));
        } catch(e) {

        }
    }


};
