<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :nudge-right="40"
        min-width="290px"
        :return-value.sync="dateString"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-on="on"
                :label="label"
                v-model="dateString"
                prepend-icon="event"
                readonly
                :outlined="outlined"
                :clearable="clearable"
                :hide-details="hideDetails"
            ></v-text-field>
        </template>
        <h-card>
          <v-layout>
              <v-flex>
                  <v-date-picker v-model="date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.menu.save(dateString)">OK</v-btn>
                  </v-date-picker>
              </v-flex>
              <v-flex v-if="dateTime">
                  <v-time-picker v-model="time" scrollable></v-time-picker>
              </v-flex>
          </v-layout>
        </h-card>
    </v-menu>
</template>

<script>
import moment from "moment";

export default {
    props: {
        value: {},
        label: {},
        dateTime: { type: Boolean, default: false },
        utc: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false }
    },
    data() {
        return {
            menu: false
        };
    },
    computed: {
        mutc() {
            return !this.dateTime || this.utc;
        },
        formatString() {
            return this.dateTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
        },
        dateString: {
            get() {
                return this.value
                    ? (this.mutc
                          ? moment(this.value).utcOffset(0)
                          : moment(this.value)
                      ).format(this.formatString)
                    : "";
            },
            set(val) {
                this.$emit(
                    "input",
                    val
                        ? (this.mutc
                              ? moment.utc(val, this.formatString)
                              : moment(val, this.formatString)
                          ).toISOString()
                        : null
                );
            }
        },
        date: {
            get() {
                return this.value
                    ? (this.mutc
                          ? moment(this.value).utcOffset(0)
                          : moment(this.value)
                      ).format("YYYY-MM-DD")
                    : "";
            },
            set(val) {
                const t = this.dateTime ? `${val} ${this.time}` : val;
                this.$emit(
                    "input",
                    val
                        ? (this.mutc
                              ? moment.utc(t, this.formatString)
                              : moment(t, this.formatString)
                          ).toISOString()
                        : null
                );
            }
        },
        time: {
            get() {
                return this.value
                    ? (this.mutc
                          ? moment(this.value).utcOffset(0)
                          : moment(this.value)
                      ).format("HH:mm:ss")
                    : "";
            },
            set(val) {
                const t = `${this.date} ${val}`;
                this.$emit(
                    "input",
                    val
                        ? (this.mutc
                              ? moment.utc(t, this.formatString)
                              : moment(t, this.formatString)
                          ).toISOString()
                        : null
                );
            }
        }
    }
};
</script>