import DB from "@db";
import { MApplication, MHookMap, Id, MHookContext } from "@feathersjs/feathers";
import { ObjectID } from "@mfeathers/db/schemas";
import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import errors from "@feathersjs/errors";
import moment from "moment";

export async function create(
  hook: MHookContext<
    AdminApplication,
    {
      session: ObjectID<any>;
      update?: typeof DB.TableSession._mongoType;
      cancelInventory?: boolean;
      checkPendingPayment?: boolean;
    },
    typeof DB.TableSession._mongoType
  >,
) {
  const session = await hook.app.service("tableSessions").get(hook.data.session);
  if (session.status === "cancelled") {
    hook.result = session;
    return;
  }
  if (session.status !== "toPay" && session.status !== "ongoing") {
    throw new errors.BadRequest("Invalid status");
  }

  if (hook.data.checkPendingPayment) {
    const payments = await hook.app.service("payments").find({
      query: {
        session: session._id,
        date: { $gt: moment().subtract(15, "minutes").toDate() },
        $limit: 1,
      },
    });
    if (payments.total) {
      hook.result = session;
      return;
    }
  }

  // for (let discount of session.discounts) {
  //   if (discount.source === "coupon" && discount.coupon) {
  //     try {
  //       await hook.app.service("userCoupons").patch(
  //         discount.coupon,
  //         {
  //           useDate: null,
  //           status: "valid",
  //           session: null,
  //         },
  //         {
  //           query: {
  //             session: session._id,
  //           },
  //         },
  //       );
  //     } catch (e) {}
  //     delete discount.coupon;
  //   }
  // }

  // const gifts = await hook.app.service("giftRedeemRecords").find({
  //   query: {
  //     session: session._id,
  //     status: "valid",
  //     $populate: ["gift"],
  //   },
  //   paginate: false,
  // } as const);

  // for (let gift of gifts) {
  //   if (!gift.gift) continue;

  //   await hook.app.service("giftRedeemRecords").patch(gift._id, {
  //     status: "cancel",
  //   });

  //   await hook.app.service("memberCoinsRecords").create({
  //     user: session.user,
  //     change: gift.gift.coins,
  //     description: "POS Order Void",
  //     shop: session.shop,
  //     posOrder: session._id,
  //   });
  // }

  hook.result = await hook.app.service("tableSessions").patch(session._id, {
    status: "cancelled",
    discounts: session.discounts,
    ...(hook.data.update || {}),
  });
}
