
import { Component, Vue, Prop, getID, Watch, checkID, mixins, FindType } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import type { PrinterServer } from "pos-printer";
import { initPrinter } from "~/plugins/printer/invoiceSequencer";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";

@Component({
  components: {
    EditorCheckbox,
    MenuListNum,
    EditorObjectPickerList,
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class EditShopPrinterDialog extends mixins(Dialog) {
  @Prop()
  waterBarIdOrKitchenPrinterId: string;

  @Prop()
  printerName: string;

  @Prop()
  type: FindType<"shopPrinters">["type"];

  get finalType() {
    return this.shopPrinter?.type ?? this.type;
  }

  @Prop()
  printerId: string;

  printerServer: PrinterServer = null;

  get locales() {
    return Object.entries(((this as any).$i18n || (this as any).i18n)?.locales || {}).map(([k, v]) => ({
      _id: (v as any).code || k,
      name: (v as any).name,
    }));
  }

  get schema() {
    return this.$schemas.getConfigByApiPath("shopPrinters");
  }

  get printerTypeEnum(): any[] {
    return this.schema?.fields?.find?.(it => it.path === "printerType")?.props?.items ?? [];
  }
  get printerTypeOpt() {
    return this.printerTypeEnum.filter(el => {
      const ell = el._id;
      if (this.finalType === "table-kitchen") return ell === "cloud" || ell === "local";
      if (this.finalType === "table-waterBar") return ell === "cloud" || ell === "local";
      if (this.finalType === "table-receipt") return true; // ell ==='cloud' || ell ==='local'
      if (this.finalType === "table-payment-receipt") return ell === "template"; // true
      if (this.finalType === "table-qr-link") return ell === "template"; // true
      return true;
    });
  }
  set selectedPrinterType(v) {
    if (!this.shopPrinter) return;
    this.shopPrinter.printerType = v;
  }
  get selectedPrinterType() {
    if (!this.shopPrinter) return;
    return this.shopPrinter.printerType;
  }

  get contentTypeEnum(): any[] {
    return this.schema?.fields?.find?.(it => it.path === "contentType")?.props?.items ?? [];
  }
  get contentTypeOpt() {
    return this.contentTypeEnum.filter(el => {
      if (["table-receipt", "table-payment-receipt", "table-qr-link"].includes(this.finalType)) {
        return (el as any)._id !== "label";
      }
      return true;
    });
  }

  get _contentType() {
    return this.shopPrinter.contentType;
  }
  set _contentType(v) {
    this.shopPrinter.localPrinter = null;
    this.shopPrinter.contentType = v as any;
  }

  get sourceEnum(): any[] {
    return this.schema?.fields?.find?.(it => it.path === "source")?.props?.items ?? [];
  }

  get orderTypeEnum(): any[] {
    return this.schema?.fields?.find?.(it => it.path === "orderType")?.props?.items ?? [];
  }

  get jobOptionsList() {
    return Object.keys(this.defaultJobOptions ?? {}) ?? [];
  }

  get enableString() {
    return this.shopPrinter?.disabled ? "disabled" : "enabled";
  }

  get enable() {
    return !this.shopPrinter?.disabled;
  }
  set enable(v) {
    this.shopPrinter.disabled = !v;
  }

  get hasSelectedContentType() {
    return !!this.shopPrinter?.contentType;
  }

  shopPrinter: Partial<FindType<"shopPrinters">> = null;
  // @Watch('shopPrinter')
  // onS() {
  //   if(this.shopPrinter) this.setDevice(this.shopPrinter.device)
  // }

  async mounted() {
    this.printerServer = await initPrinter(this);
  }

  created() {
    let shopPrinter: typeof this.shopPrinter = null;
    if (this.printerId) {
      shopPrinter = this.$shop.shopPrinterDict[this.printerId];
    } else if (this.waterBarIdOrKitchenPrinterId) {
      if (this.type === "table-waterBar") {
        shopPrinter = Object.values(this.$shop.shopPrinterDict).find(
          it => it.type === "table-waterBar" && checkID(it.waterBar, this.waterBarIdOrKitchenPrinterId),
        );
      }
      if (this.type === "table-kitchen") {
        shopPrinter = Object.values(this.$shop.shopPrinterDict).find(
          it => it.type === "table-kitchen" && checkID(it.kitchen, this.waterBarIdOrKitchenPrinterId),
        );
      }
    } else {
      shopPrinter = Object.values(this.$shop.shopPrinterDict).find(
        it => it.type === this.type && it.printerType === "template",
      );
    }
    if (shopPrinter) {
      this.shopPrinter = structuredClone(shopPrinter);
    }
    if (!this.shopPrinter) {
      const templateTypes = ["table-receipt", "table-payment-receipt", "table-qr-link"] as const;

      this.shopPrinter = {
        printerType: templateTypes.includes(this.finalType as any) ? "template" : "local",
        contentType: "thermal",
        jobOptions: this.defaultJobOptions,
        type: this.finalType,
        name: "",
        locale: null,
        printer: null,
        template: null,
        source: [],
        orderType: [],
        ...(this.finalType === "table-waterBar"
          ? ({ waterBar: this.waterBarIdOrKitchenPrinterId } as any as {})
          : this.finalType === "table-kitchen"
            ? ({ kitchen: this.waterBarIdOrKitchenPrinterId } as any as {})
            : {}),
      };
    }
  }

  get defaultJobOptions() {
    return {
      printQR: false,
      preferShortName: false,
      printProductOptionName: false,
      ...(this.finalType === "table-kitchen" || this.finalType === "table-waterBar"
        ? {
            splitQuantity: true,
            printNew: true,
            printMove: true,
            printCancel: true,
            printReprint: true,
            printEdit: false,
          }
        : {}),

      ...(this.finalType === "table-receipt" || this.finalType === "table-payment-receipt"
        ? { showUserInfo: false }
        : {}),

      ...(this.finalType === "table-payment-receipt"
        ? {
            tablePrintToPayWhenCheckBill: false,
            tableDontAutoPrintReceiptCash: false,
            tableDontAutoPrintReceiptAll: false,
            autoPrintCancelReceipt: false,
            dontPrintZeroProduct: false,
          }
        : {}),

      ...(this.finalType === "table-qr-link"
        ? {
            tableAutoPrintOnlineOrder: false,
            tableAutoPrintOnlineOrderSplitTable: false,
            tableOnlineOrderPostfix: "",
          }
        : {}),
    };
  }

  async manageCloud() {
    if (!this.shopPrinter?.printer) return;
    await this.printerServer.manageCloudDevice(getID(this.shopPrinter.printer));
  }

  get canSave() {
    if (!this.shopPrinter?.contentType || !this.shopPrinter?.printerType) return false;
    return true;
  }

  async save() {
    if (!this.shopPrinter) return;
    if (!this.canSave) return;

    if (!this.shopPrinter._id) {
      const item = await this.$feathers.service("shopPrinters").create({
        ...this.shopPrinter,
      });
      this.$shop.shopPrinterDict[getID(item)] = item;
    } else {
      const item = await this.$feathers.service("shopPrinters").patch(this.shopPrinter._id, {
        ...this.shopPrinter,
      });
      this.$shop.shopPrinterDict[getID(item)] = item;
    }

    this.modalResult();
  }
}
