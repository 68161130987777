
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem, TableSessionRef } from "~/plugins/table";
import Dialog from "~/mixins/DialogTS";
import _ from "lodash";
import { getID } from "@feathers-client";
@Component
export default class extends mixins(Dialog) {
  @Prop()
  deleteFloor: any;

  @Prop()
  tableView: TableView;

  async clearAllCurrentOrder() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToDelete"),
      },
      {
        maxWidth: "400px",
      },
    );

    if (!c) return;

    await this.tableView.clearAllCurrentOrder();
  }

  async clearAllFloorAllCurrentOrder() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToDelete"),
      },
      {
        maxWidth: "400px",
      },
    );

    if (!c) return;

    await this.$tableManager.clearAllFloorAllCurrentOrder();
  }

  @Watch("tableView.testing")
  async updateTesting() {
    await this.$feathers.service("actionLogs").create({
      staff: this.$shop.staffId,
      type: `tableManage/enableTestingFloor`,
      view: getID(this.tableView),
      detail: {
        testing: this.tableView.testing,
      },
    });
  }
}
