
import { Vue, Component, Prop, Watch } from "@feathers-client";

@Component
export default class FitText extends Vue {
  @Prop({ default: 1 }) minFontSize!: number;
  @Prop({ default: 100 }) maxFontSize!: number;

  @Prop({ default: 0 })
  offset: number;

  @Prop({ default: 1.5 })
  lineHeight: number;

  @Prop()
  widthChars?: number;

  @Prop({ default: 0 })
  widthOffset: number;

  @Prop({ default: 0 })
  widthOffsetScaleY?: number;

  @Prop({ default: 1 })
  scale: number;

  mounted() {
    this.onResize();
  }

  @Watch("offset")
  @Watch("lineHeight")
  @Watch("widthChars")
  @Watch("widthOffset")
  @Watch("widthOffsetScaleY")
  @Watch("scale")
  onResize() {
    const el = this.$el as HTMLElement;

    let size = ((el.clientHeight + this.offset) / this.lineHeight) * this.scale;
    if (this.widthChars) {
      const calWidth = el.clientWidth + this.widthOffset + (this.widthOffsetScaleY || 0) * el.clientHeight;
      const widthSize = calWidth / this.widthChars;
      // console.log('el.clientWidth', el.clientWidth, ' + ', this.widthOffset, ' + ', (this.widthOffsetScaleY || 0) * el.clientHeight, ' = ', calWidth, ' / ', this.widthChars, ' = ', widthSize);
      size = Math.min(size, widthSize);
    }

    const fontSize = Math.min(this.maxFontSize, Math.max(this.minFontSize, Math.floor(size)));
    el.style.fontSize = `${fontSize}px`;
  }
}
