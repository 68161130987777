
import { Vue, Component, Prop, PropSync, FindType, getID, FindPopRawType, Watch } from "@feathers-client";
import EditorObjectPickerList from "~/dep/schema-editor/EditorObjectPickerList.vue";
import _ from "lodash";
import { ObjectID } from "@db";
import { VModel } from "vue-property-decorator";
import PointCard from "./pointCard.vue";

@Component({
  components: {
    EditorObjectPickerList,
    PointCard,
  },
})
export default class OrderSystemEditUser extends Vue {
  @Prop()
  memberId: string;

  user: FindType<"appUsers"> = null;

  editing = false;
  userSessions = [];
  productMapping: { [key: string]: any } = {};
  originalOrderTags: ObjectID[] = [];
  originalCustomerTags: ObjectID[] = [];
  newUser = false;

  async beforeMount() {
    await this.loadData();
  }

  async loadData() {
    this.user = await this.$feathers
      .service("appUsers")
      .get(this.memberId, { query: { $populate: ["vipLevel"] } })
      .catch(e => {
        return null;
      });

    this.originalCustomerTags = this.user.customerTags;
    this.originalOrderTags = this.user.orderTags;
    this.newUser = false;
    this.userSessions =
      (await this.$feathers.service("tableSessions").find({
        query: {
          user: this.memberId,
          status: "done",
          $paginate: false,
          $sort: {
            date: -1,
          },
        },
        paginate: false,
      })) || [];
  }

  async edit() {
    this.editing = true;
  }

  query = {
    $populate: ["vipLevel"],
  };

  get totalAmount() {
    return this.userSessions?.reduce((total, session) => {
      return total + session.amount;
    }, 0);
  }

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active" && (!user.expiry || new Date(user.expiry) > new Date());
  }

  async onOrderTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalOrderTags)) {
      this.originalOrderTags = this.user.orderTags;
      this.user = await this.$feathers.service("appUsers").patch(getID(this.user), {
        orderTags: e.map(tag => tag._id),
        orderTagsModifiedDate: new Date(),
      });
    }
  }

  async onCustomerTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalCustomerTags)) {
      this.originalCustomerTags = this.user.customerTags;
      this.user = await this.$feathers.service("appUsers").patch(getID(this.user), {
        customerTags: e.map(tag => tag._id),
        customerTagsModifiedDate: new Date(),
      });
    }
  }
  async save() {
    this.editing = false;
    if (!this.newUser) {
      const staff = await this.$shop.checkPermission(["crm/modifyMemberInfo"]);
      if (staff === false) return;

      this.user = await (this.$feathers as any).service("appUsers").patch(getID(this.user), {
        name: this.user.name,
        email: this.user.email?.toLowerCase(),
        phonePrefix: this.user.phonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852"),
        phoneValue: this.user.phoneValue,
        birthday: this.$moment(this.user.birthday).isValid() ? this.$moment(this.user.birthday).format("MM-DD") : "",
      });

      await this.$feathers.service("actionLogs").create({
        staff: staff?._id || this.$shop.staffId,
        type: "crm/modifyMemberInfo",
        detail: {
          id: this.user.userId,
          name: this.user.name,
        },
      });
    } else {
      this.newUser = false;
      this.user = await (this.$feathers as any)
        .service("appUsers")
        .create({
          name: this.user.name,
          email: this.user.email?.toLowerCase(),
          phonePrefix: this.user.phonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852"),
          phoneValue: this.user.phoneValue,
          password: this.user.email?.toLowerCase(),
        })
        .catch(e => {
          this.newUser = true;
          this.editing = true;
        });
      if (this.user._id) {
        const level = await this.$feathers.service("vipLevels").find({
          query: {
            level: 0,
          },
          paginate: false,
        });
        if (level.length) {
          this.user.vipLevel = level[0]._id;
          this.user.status = "active";
          this.user.expiry = this.$moment().add(level[0].lifeDays, "days").toDate();
          this.user = await (this.$feathers as any).service("appUsers").patch(getID(this.user), {
            vipLevel: this.user.vipLevel,
            status: this.user.status,
            expiry: this.user.expiry,
          });
        }
      }
    }
  }

  async back() {
    if (this.editing) {
      const res = await this.$openDialog(
        import("~/components/dialogs/SuccessDialog.vue"),
        {
          title: this.$t("peopleCrm.cancelEdit"),
          desc: this.$t("peopleCrm.cancelEditDesc"),
          descClass: "",
          isEdit: true,
        },
        {
          maxWidth: "400px",
        },
      );
      if (!res) return;
    }
    return;
  }
}
