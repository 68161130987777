
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { checkID, getID, FindType } from "@feathers-client";
import { getCacheLoader } from "@feathers-client/cacheLoader";

@Component
export default class pointCard extends Vue {
  @Prop()
  user: FindType<"appUsers">;
  allPoints: FindType<"users/points/available"> = [];

  selectedTag = "point";

  async beforeMount() {
    this.allPoints = await this.$feathers.service("users/points/available").find({
      query: {
        user: getID(this.user),
        tag: ["point", "dollar"],
      },
    });
    const loader = getCacheLoader(this, "users/points/batchAvailable", {
      tag: ["point", "dollar"],
    });
    if (loader) {
      loader.onPatch({
        _id: getID(this.user) as any,
        points: this.allPoints.map(p => ({
          point: getID(p),
          amount: p.amount,
        })),
      } as any);
    }
  }

  get point() {
    return this.allPoints.find(p => p.tag === "point");
  }
  get dollar() {
    return this.allPoints.find(p => p.tag === "dollar");
  }

  get selectedPoint() {
    return this.selectedTag === "point" ? this.point : this.dollar;
  }
}
