
import { Component, Prop, Vue, Watch, VModel } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({ components: { EditorTextField } })
export default class TimePicker extends Vue {
  @VModel()
  inputValue: string;

  @Prop()
  label: string;

  @Prop({ type: Boolean, default: false })
  clearable: boolean;

  menu = false;

  save() {
    this.menu = false;
  }
}
