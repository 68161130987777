<template>
<div class="pos-popup rounded h-[80vh] d-flex flex-col">
    <img class="object-contain flex-grow basis-0 overflow-hidden" :src="src"/>
    <div class="pa-5 whitespace-nowrap d-flex space-x-3 justify-between">
        <div class="font-bold text-lg">{{title}}</div>
        <menu-list-button :text="confirm || $t('basic.ok')" :bgClass="confirmBg" :textClass="confirmText" :icon="confirmIcon" @click="modalResult(true)"/>
    </div>
</div>
</template>

<script>

import Dialog from '~/mixins/Dialog'

export default {
    mixins: [
        Dialog(
        )
    ],
    props: {
        src: {},
        title: {},
    }

}

</script>
