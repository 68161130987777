
import { Component, FindType, Prop, mixins } from "@feathers-client";
import { TableView } from "~/plugins/table/view";
import { TableItem } from "~/plugins/table";
import Dialog from "~/mixins/DialogTS";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { checkTime } from "~/plugins/table/utils";
@Component({ components: { EditorObjectPickerNew } })
export default class SessionCreate extends mixins(Dialog) {
  @Prop()
  items: TableItem[];

  @Prop()
  tableView: TableView;

  sessionSize = 1;

  section = null;

  beforeMount() {
    if (this.$shop.shopData.autoSelectionTimeSection)
      this.section = this.$shop.sections.find(it => this.isAvailableSection(it));
  }

  isAvailableSection(item: FindType<"sections">) {
    return (
      item.timeRange && !!item.timeRange.find(range => checkTime(range.weekdays, range.from, range.to, new Date()))
    );
  }

  get totalCapacity() {
    return this.items.map(item => item.capacity).reduce((total, value) => total + value, 0);
  }

  get totalUsedCapacity() {
    return this.items.map(item => item.usedCapacity).reduce((total, value) => total + value, 0);
  }
}
