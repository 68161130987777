
import _ from "lodash";

import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { ProductLine, TableSession } from "~/plugins/table/session";
import type { CartItem, ProductOptionSelectWithPrice, ProductOptionSelectWithPriceItem } from "@common/table/cart";
import { Ref } from "@feathers-client";
import { StockLevel, getOptionStockLevel } from "~/common/table/util";
import { ProductOptionType } from "~/plugins/shop";
import ProductOptionBase from "./productOptionBase";

@Component
export default class OrderSystemProductOptionsNew extends mixins(ProductOptionBase, Vue) {
  @PropSync("item")
  itemSync: CartItem | ProductLine;

  selectIndex: number = 0;

  idx: number = 0;
  jdx: number = -1; // set -1 to un-show the sub-item options on right column
}
