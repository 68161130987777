
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { ProductType } from "~/plugins/shop";
import { CartItem } from "@common/table/cart";
import { getProductStockLevel, StockLevel } from "~/common/table/util";
import PickerBase from "~/components/table/orderSystem/mobile/pickerBase";

@Component({})
export default class MobileSubCategories extends mixins(PickerBase) {
  @Prop()
  showingDialog: boolean;
}
