
import { Component, Prop, Vue, mixins } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorPlacement extends mixins(PrinterEditorEditBase) {
  get positionDependent() {
    return this.type === "label" ? this.labelTextNode && this.labelTextNode !== this.selectedNode : true;
  }
}
