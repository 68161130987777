
import { Component, Prop, Vue, Watch, mixins, checkID, VModel, PropSync, FindType } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorObjectPicker from "@schemaEditor/EditorObjectPicker.vue";
import Dialog from "domore-table/mixins/Dialog";
import { queueingCard } from "types/types";
import { getVoices, speak, formatCharacters } from "pos-printer/utils/speech";

@Component({
  components: {
    EditorObjectPickerList,
    EditorObjectPicker,
  },
})
export default class CallingOutSetting extends mixins(Dialog()) {
  @VModel() openSetting: boolean;
  @Prop() cardDetails: queueingCard[];
  @PropSync("openQueueing") openQueueingSync: boolean;
  @Prop({ default: false }) loading: boolean;
  @Prop({ type: Boolean, default: false })
  isClinic: boolean;
  @Prop()
  currentGroup: FindType<"shopQueueingGroups">;

  disable = true;
  openConfirmReset = false;
  selectOpen = false;
  isClose = false;
  maxLength = 6;

  get currentGroupStatus() {
    return this.currentGroup ? this.currentGroup.status : "open";
  }

  set currentGroupStatus(value) {
    this.currentGroup.status = value;
  }

  get repeatCount() {
    return this.$shop.localOptions.callTicketCount || 1;
  }

  set repeatCount(v) {
    this.$shop.localOptions.callTicketCount = Math.max(1, Math.min(v, 5)); // 1~5 count
  }

  groupStatusItems = [
    {
      text: this.$t("queueing.callingSetting.open"),
      value: "open",
    },
    {
      text: this.$t("queueing.callingSetting.hold"),
      value: "hold",
    },
    {
      text: this.$t("queueing.callingSetting.closed"),
      value: "closed",
    },
  ];

  @Watch("currentGroup")
  onCurrentGroupChange() {
    this.currentGroupStatus = this.currentGroup.status;
  }

  @Watch("currentGroupStatus")
  onCurrentGroupStatusChange() {
    this.$emit("updateCurrentGroupStatus", this.currentGroupStatus);
  }

  async openConfirmResetDialog() {
    this.openConfirmReset = true;
  }

  async confirmReset() {
    if (this.isClinic && this.currentGroup) {
      await this.$shopSession.resetQueueingGroup(false, this.currentGroup._id);
      if (this.isClose) {
        this.currentGroupStatus = "closed";
        this.isClose = false;
      }
    } else {
      await this.$shopSession.resetQueueingGroup(false);
    }
  }

  get activeLength() {
    let disables = this.cardDetails.filter(it => it.disableQueueing === false);
    return disables.length;
  }

  updateDisable(index: number) {
    if (!this.openQueueingSync) return;
    const disableQueueingGroup = !this.cardDetails[index].disableQueueing;
    const shouldEmit = this.activeLength < this.maxLength || !this.cardDetails[index].disableQueueing;
    if (shouldEmit) {
      this.$emit("disableQueueingGroup", { index, disableQueueingGroup });
    }
  }

  voicesList: {
    _id: string;
    name: string;
  }[] = [];

  // beforeMount() {
  //   this.checkActiveLength();
  // }

  mounted() {
    if (typeof speechSynthesis !== "undefined") {
      speechSynthesis.addEventListener("voiceschanged", this.updateVoices);
    }
    this.updateVoices();
  }

  // checkActiveLength() {
  //   if (this.activeLength < this.maxLength) {
  //     for (let i = 0; i < this.cardDetails.length; i++) {
  //       if (i >= this.maxLength) {
  //         let disableQueueingGroup = !this.cardDetails[i].disableQueueing;
  //         this.$emit("disableQueueingGroup", { i, disableQueueingGroup });
  //       }
  //     }
  //   }
  // }

  beforeDestroy() {
    if (typeof speechSynthesis !== "undefined") {
      speechSynthesis.removeEventListener("voiceschanged", this.updateVoices);
    }
  }

  async updateVoices() {
    this.voicesList = (await getVoices()).map(it => ({
      _id: it.voiceURI,
      name: `${it.name} (${it.lang})`,
    }));
  }

  async testVoice() {
    const parts = formatCharacters("A001", this.$shop.localOptions.callTicketSSML);
    for (let i = 0; i < this.$shop.localOptions.callTicketCount; ++i) {
      await speak(
        parts,
        this.$shop.localOptions.callTicketVoice,
        this.$shop.localOptions.callTicketRate / 10,
        this.$shop.localOptions.callTicketVolume / 100,
      );
    }
  }
}
