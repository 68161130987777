
import { Component, InjectReactive, Prop, Vue, Watch } from "@feathers-client";
import { PrinterTemplateCustomComponent, PrinterTemplateNodeBase, compile, compileLabel } from "pos-printer-template";
import type TemplateEditor from "~/pages/templateEditor/_.vue";

@Component
export default class PrinterEditorCustomComponent extends Vue {
  @Prop() template!: PrinterTemplateCustomComponent<any>;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @InjectReactive("templateEditor")
  templateEditor: TemplateEditor;

  @Prop()
  path: string;

  subTemplate: PrinterTemplateNodeBase<any> = null;
  nodes: PrinterTemplateNodeBase<any>[];
  nodeParent: number[];

  get componentName() {
    if (this.template.elementNode.tag.startsWith("page-")) {
      return this.template.elementNode.tag.slice(5);
    }
    return this.template.elementNode.tag;
  }

  get finalComponentName() {
    if (this.componentName === "component") {
      return this.template.props["is"]?.eval(this.context);
    }
    return this.componentName;
  }

  @Watch("finalComponentName", { immediate: true })
  async onLoadTemplate() {
    this.subTemplate = null;

    if (!this.templateEditor) return;
    const templateInfo = await this.templateEditor.loadSubTemplate(this.finalComponentName);
    if (!templateInfo) return;
    const { root, nodes, nodeParent } = templateInfo;
    this.subTemplate = root;
    this.nodes = nodes;
    this.nodeParent = nodeParent;
  }

  get innerContext() {
    const innerContext = {
      ...this.context,
    };
    for (let [k, v] of Object.entries(this.template.props)) {
      innerContext[k] = v.eval(this.context);
    }

    return innerContext;
  }
}
