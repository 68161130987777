import { MHookContext } from "@feathersjs/feathers";
import { escapeRegExp, FindType } from "@feathers-client";
import { AdminApplication } from "serviceTypes";

export async function patch(hook: MHookContext<AdminApplication, FindType<"tvOrderStatuses">>) {
  hook.data.updateTime = new Date();
}

export async function afterPatch(hook: MHookContext<AdminApplication, FindType<"tvOrderStatuses">>) {
  const item = hook.result;
  if (item.status === "ready") {
    try {
      const updated = await hook.app.service("tableSessions").patch(item.orderId, {
        tvStatus: "ready",
        readyTime: new Date(),
      });
    } catch (e) {
      console.warn(e);
    }
  } else if (item.status === "taken") {
    try {
      await hook.app.service("tableSessions").patch(item.orderId, {
        tvStatus: "taken",
      });
    } catch (e) {
      console.warn(e);
    }
  } else if (item.status === "making") {
    try {
      await hook.app.service("tableSessions").patch(item.orderId, {
        tvStatus: "making",
      });
    } catch (e) {
      console.warn(e);
    }
  }
}
