

import { Component, Prop, Vue, Watch, mixins,FindType } from "@feathers-client";


@Component
export default class PrinterStatus extends Vue {
    @Prop()
    orderId: string

    jobItem : FindType<'tvOrderStatuses'> = null;

    loading = false;

    get buttonClass() {
        if(!this.jobItem) return '';
        switch(this.jobItem.status) {
            case 'making': return 'bg-blue100'
            case 'taken': return 'bg-green200'
            case 'ready': return 'bg-green100'
        }
    }

    mounted() {
        this.onJobId();
    }

    @Watch('jobId')
    async onJobId() {
        if(!this.orderId) {
            return;
        }
        try {
            this.jobItem = (await this.$feathers.service('tvOrderStatuses').find({
                query: {
                    orderId: this.orderId,
                    $paginate: false,
                },
                paginate: false,
            }))[0];
        } catch(e) {
            console.warn(e)
        }
    }

    onJob(item : FindType<'tvOrderStatuses'>) {
        if(!this.jobItem) return;
        if(item.orderId !== this.jobItem.orderId) return;
        this.jobItem = item;
    }

    async changeStatus() {
        if(!this.jobItem) return;
        this.loading = true;
        try {
            let newStatus = this.jobItem.status;
            switch(newStatus) {
                case 'making': newStatus = 'ready'; break;
                case 'ready': newStatus = 'taken'; break;
                case 'taken': newStatus = 'making'; break;
            }
            if(newStatus === this.jobItem.status) return;
            const changed = await this.$feathers.service('tvOrderStatuses').patch(null, {
                status: newStatus
            }, {
                query: {
                    orderId: this.orderId,
                }
            });
            this.$store.commit('SET_SUCCESS', this.jobItem.code + ' ' + this.$t('basic.newStatus') + ': ' + this.$t('tvOrderStatus.' + newStatus));
            this.jobItem = Array.isArray(changed as any) ? (changed as any)[0] : changed;
        } finally {
            this.loading = false;
        }
    }

    async ensureReady() {
        if(!this.jobItem) return;
        if(this.jobItem.status === 'making') {
            const changed = await this.$feathers.service('tvOrderStatuses').patch(null, {
                status: 'ready',
            }, {
                query: {
                    orderId: this.orderId,
                }
            });
            this.jobItem = Array.isArray(changed as any) ? (changed as any)[0] : changed;
        }
    }
}


