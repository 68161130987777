import { EventEmitter } from "events";
import { PrinterConf, PrinterBase } from "./printers/base";
import { BluetoothPrinter, BluetoothConf } from "./printers/bluetooth";
import { UsbPrinter } from "./printers/usb";
import { NetPrinter } from "./printers/net";
import { PrintQueue, PrintJob } from "./printQueue";
import defConfs from "./conf";
import Vue from "vue";
import _ from "lodash";
import uuid from "uuid/v4";
import { init as initNative } from "./nativeIntegrations";

import { supported as posSupported } from "./ports/posPrint";
import { UsbDeviceInfo, supported as usbSupported } from "./ports/usb";
import { supported as socketSupported } from "./ports/socket";
import { SocketPrinter } from "./printers/socket";
import { CloudPrinter } from "./printers/cloud";
import { ServerConf, ServerPrinter } from "./printers/server";
import { DebugPrinter } from "./printers/debug";
import { StarPrinter, supported as starSupported } from "./printers/star";
import { EpsonPrinter, supported as epsonSupported } from "./printers/epson";

import type iconv from "iconv-lite";

export * from "./common";
import {
  BitmapOpts,
  WrappedContext,
  FontInfo,
  FontItem,
  FontSetting,
  PrinterSettings,
  FontSettingType,
} from "./common";
import { BBPOSPrinter } from "./printers/bbpos";
import { PosPrintPrinter } from "./printers/posPrint";
import { CallApiOpts } from "./bbmsl";
import type { LangType } from "@feathers-client/i18n";
import type { PrintDebugData, PrintJobStatus } from "./printJob";
import type { PrintSequence } from "./printSequence";
import type { LabelSequence } from "./labelSequence";
import type { DeviceScanner, RemoteScannerCommand } from "./deviceScanner/host";
import { PosPrintRemotePrintPrinter } from "./printers/posPrintRemote";
import { EPosPrinter } from "./printers/epos";

export interface PrinterServerOpts {
  localFonts?: FontItem[];
}

export interface PrinterCommand {
  command:
    | "discover" // ask for advertise
    | "discoverServer" // ask for server list
    | "advertise" // advertise printer
    | "advertiseServer" // advertise server
    | "connect" // try connect
    | "status" // reply to connect
    | "print" // add print job
    | "cancel" // cancel print job
    | "retry" // retry print job
    | "clear" // clear print job
    | "getStatus" // get print status
    | "printStatus" // reply to print status
    | "getPrinterStatus" // get printer status
    | "printerStatus" // reply to printer status
    | "ping" // ping
    | "pong" // pong
    | "toggleDevice" // toggle device
    | "removeDevice" // remove device
    | "getConf" // get printer conf
    | "getConfResult" // get printer conf result
    | "setShared" // set shared
    | "setConf" // set conf
    | "listQueue" // list queue
    | "listQueueResult" // list queue result
    | "testPrint" // test print
    | "setTags" // set tags
    | "remoteScan"
    | "remoteScanResp";
  deviceId?: string;
  deviceType?: string;
  deviceName?: string;
  deviceOpts?: any;
  deviceQueue?: number;
  deviceConnected?: boolean;
  serverId?: string;
  clientId?: string;
  status?: "printing" | "ready" | "error" | "offline" | "noPaper";
  jobName?: string;
  jobData?: string;
  jobId?: string;
  jobStatus?: "queued" | "remoteQueued" | "printing" | "done" | "error" | "cancel";
  jobOpts?: any;
  clinic?: string;
  conf?: PrinterConf;
  shared?: boolean;
  queue?: PrintJobStatus[];
  tags?: string[];
  remoteScanCommand?: RemoteScannerCommand;
  serverName?: LangType;
  reconnect?: boolean;
  retryable?: boolean;
  lastError?: string;
  lastDisconnectReason?: string;
  lastProbe?: Date;
  getPrinterStatus?: boolean;
}

export interface LocalMessage {
  type?: "printers" | "settings";
  settings?: PrinterSettings;
  confs?: PrinterConf[];
}

export class PrinterServer extends EventEmitter {
  confs: PrinterConf[] = [];
  settings: PrinterSettings = {};
  posSupported: Promise<boolean>;
  usbSupported: Promise<boolean>;
  socketSupported: Promise<boolean | string[]>;
  starSupported: Promise<boolean>;
  epsonSupported: Promise<boolean>;
  queues: PrintQueue[] = [];
  channel: BroadcastChannel;
  pendingQueues: Record<string, Promise<PrinterBase>> = {};

  callBBPOS: (params: any, posURL?: string, opts?: CallApiOpts) => Promise<any>;
  remotePOSPrint: {
    print: (payload: Buffer, opts?: any) => Promise<void>;
    init: () => Promise<void>;
    info: () => Promise<any>;
    dispose?: () => Promise<void>;
  };
  isBBPay = false;

  id: string;
  serverName: LangType;
  iconv: typeof iconv;

  constructor(
    public context: Vue,
    public storage?: Storage,
    public opts?: PrinterServerOpts,
  ) {
    super();

    this.id = uuid();
    if (!this.storage) {
      try {
        this.storage = (<any>global).localStorage;
      } catch (e) {
        console.warn(e);
      }
    }
    if ("BroadcastChannel" in window) {
      this.channel = new BroadcastChannel("printer");
      this.channel.addEventListener("message", ev => this.onLocalMessage(ev));
    }
    if (context.$feathers) {
      try {
        context.$feathers.service("printerShares").on("created", ev => this.onRemoteMessage(<any>ev));
      } catch (e) {
        console.warn(e);
      }
    }
  }

  updateSupported() {
    this.posSupported = posSupported();
    this.usbSupported = usbSupported();
    this.socketSupported = socketSupported();
    this.starSupported = starSupported();
    this.epsonSupported = epsonSupported();
  }

  async init() {
    this.updateSupported();
    this.iconv = await import("iconv-lite");
    try {
      let c = this.storage.getItem("printers");
      if (c) {
        const j = JSON.parse(c);
        if (Array.isArray(j)) {
          this.confs = j;
        }
      }
    } catch (e) {}
    try {
      let c = this.storage.getItem("printersSettings");
      if (c) {
        const j = JSON.parse(c);
        if (typeof j === "object") {
          this.settings = j || {};
        }
      }
    } catch (e) {}
    try {
      await initNative(this.context);
    } catch (e) {}
    this.querySaved(undefined, undefined, undefined, undefined, true).catch(console.error);
  }

  reset(force = false) {

    if(!force) {
      let newConfgs: typeof this.confs = [];
      try {
        let c = this.storage.getItem("printers");
        if (c) {
          const j = JSON.parse(c);
          if (Array.isArray(j)) {
            newConfgs = j;
          }
        }
      } catch (e) {}

      if(JSON.stringify(this.confs) === JSON.stringify(newConfgs)) {
        return;
      }
    }

    for (let queue of this.queues) {
      queue.close();
    }
    this.queues = [];
    return this.init();
  }

  async querySaved(type?: string, userInteration?: boolean, single?: boolean, tag?: string, parallel?: boolean) {
    for (let conf of this.confs) {
      if (!conf.id) {
        conf.id = uuid();
        this.savePrinters();
      }
      if (type && conf.type !== type) continue;
      if (tag && _.indexOf(conf.tags, tag) === -1) continue;
      if (!userInteration && conf.port === "bluetooth") {
        const impl = await import("./utils/bluetoothPolyfill");
        await impl.install();
        if (!impl.getSupportDirectConnect()) {
          continue;
        }
      }
      if (parallel) {
        this.tryConnect(conf, undefined, tag, true).catch(console.error);
      } else {
        try {
          const device = await this.tryConnect(conf, undefined, undefined, true);
          if (device && single) return device;
        } catch (e) {
          console.warn(e);
        }
      }
    }
    return null;
  }

  async tryConnect(
    conf: PrinterConf,
    reconnect?: boolean,
    tag?: string,
    must = false,
    deviceOpts?: {
      // @ts-ignore
      bluetooth?: BluetoothDevice;
      usb?: UsbDeviceInfo;
    },
  ) {
    if (!conf.tags) {
      conf.tags = [];
    }
    if (tag) {
      if (conf.tags.indexOf(tag) === -1) {
        conf.tags.push(tag);
        if (conf.id) this.savePrinters();
      }
    }

    if (conf.id) {
      const queue = this.queues.find(it => it.id === conf.id);
      if (queue) {
        if (!queue.connected && reconnect) {
          try {
            await queue.printer.disconnect(true);
          } catch (e) {
            console.warn(e);
          }
          await queue.printer.requestNewDevice();
          await queue.printer.init();
        }
        return queue.printer;
      }
      let task = this.pendingQueues[conf.id];
      if (!task) {
        task = this._tryConnectInner(conf, reconnect, tag, must, deviceOpts);
        task.finally(() => {
          delete this.pendingQueues[conf.id];
        });
        this.pendingQueues[conf.id] = task;
      }
      return await task;
    } else {
      return this._tryConnectInner(conf, reconnect, tag, must, deviceOpts);
    }
  }


  async _tryConnectInner(
    conf: PrinterConf,
    reconnect?: boolean,
    tag?: string,
    must = false,
    deviceOpts?: {
      // @ts-ignore
      bluetooth?: BluetoothDevice;
      usb?: UsbDeviceInfo;
    },
  ) {
    let device: PrinterBase;
    try {
      switch (conf.port) {
        case "bluetooth": {
          const impl = await import("./utils/bluetoothPolyfill");
          await impl.install();
          if (!("bluetooth" in navigator)) {
            throw new Error("Bluetooth is not supported");
          }
          const bdevice = new BluetoothPrinter(this, <any>conf);
          device = bdevice;
          if (deviceOpts?.bluetooth) {
            bdevice.device = deviceOpts.bluetooth;
          } else {
            await device.requestNewDevice();
            if (!device.device) {
              throw new Error("Cannot connect to device");
            }
            const bluetoothConf = <BluetoothConf>conf;
            if (
              !bluetoothConf.id ||
              bluetoothConf.address !== bdevice.device.name ||
              (bluetoothConf.identifier && bluetoothConf.identifier !== bdevice.device.id)
            ) {
              // new config or device changed
              const oldConf = this.confs.find(it => {
                const bluetoothConf = <BluetoothConf>it;
                return (
                  it.type === conf.type &&
                  it.port === "bluetooth" &&
                  it.address === bdevice.device.name &&
                  (!bluetoothConf.identifier || bluetoothConf.identifier === bdevice.device.id)
                );
              });
              if (oldConf) {
                device.conf = oldConf;
                conf = oldConf;
                const currentQueue = this.queues.find(it => it.id === oldConf.id);
                if (currentQueue) {
                  currentQueue.reset(bdevice);
                }
              }
            }
          }
          break;
        }
        case "usb": {
          if (!(await this.usbSupported)) {
            throw new Error("Usb is not supported");
          }
          const udevice = new UsbPrinter(this, <any>conf);
          if (deviceOpts?.usb) {
            udevice.device = deviceOpts.usb;
          }
          device = udevice;
          break;
        }
        case "net": {
          device = new NetPrinter(this, <any>conf);
          break;
        }
        case "socket": {
          device = new SocketPrinter(this, <any>conf);
          break;
        }
        case "cloud": {
          device = new CloudPrinter(this, <any>conf);
          break;
        }

        case "server": {
          device = new ServerPrinter(this, <any>conf);
          break;
        }

        case "debug": {
          device = new DebugPrinter(this, <any>conf);
          break;
        }

        case "bbpos": {
          device = new BBPOSPrinter(this, <any>conf);
          break;
        }

        case "posPrint": {
          device = new PosPrintPrinter(this, <any>conf);
          break;
        }

        case "posPrintRemote": {
          device = new PosPrintRemotePrintPrinter(this, <any>conf);
          break;
        }

        case "epos":
          device = new EPosPrinter(this, <any>conf);
          break;

        case "star":
          device = new StarPrinter(this, <any>conf);
          break;

        case "epson":
          device = new EpsonPrinter(this, <any>conf);
          break;
      }
    } catch (e) {
      if (!must) {
        throw e;
      }
    }

    if (!device) return null;
    let tempQueue: PrintQueue;
    if (!device.queue) {
      const curQueue = this.queues.find(it => it.printer?.conf?.id === conf.id);
      if (curQueue) {
        console.warn("Queue already exists");
      }
      this.queues.push((tempQueue = new PrintQueue(device, this)));
    }
    try {
      await device.init();
    } catch (e) {
      if (must) {
        device.updateReconnect();
      } else {
        if (tempQueue) {
          const idx = this.queues.indexOf(tempQueue);
          if (idx !== -1) {
            this.queues.splice(idx, 1);
          }
          tempQueue.printer.disconnect(true);
          tempQueue.close();
        }
        throw e;
      }
    }
    conf = device.conf;
    if (this.confs.indexOf(conf) === -1) {
      if (!conf.id) conf.id = uuid();
      const idx = this.confs.findIndex(it => it.id === conf.id);
      if (idx === -1) {
        this.confs.push(conf);
        try {
          await this.addPrinter?.(conf);
        } catch (e) {
          console.warn(e);
        }
      } else {
        this.confs[idx] = conf;
      }
    }
    this.savePrinters();
    return device;
  }

  createEmptyPrinter(conf: PrinterConf) {
    switch (conf.port) {
      case "bluetooth":
        return new BluetoothPrinter(this, conf as any);
      case "usb":
        return new UsbPrinter(this, conf as any);
      case "net":
        return new NetPrinter(this, conf as any);
      case "socket":
        return new SocketPrinter(this, conf as any);
      case "cloud":
        return new CloudPrinter(this, conf as any);
      case "server":
        return new ServerPrinter(this, conf as any);
      case "debug":
        return new DebugPrinter(this, conf as any);
      case "bbpos":
        return new BBPOSPrinter(this, conf as any);
      case "posPrint":
        return new PosPrintPrinter(this, conf as any);
      default:
        throw new Error("Unknown printer type");
    }
  }

  async removeDevice(conf: PrinterConf) {
    if (!conf.id) return;
    const tags = this.getPrinterTags(conf);
    if (tags.length) {
      await this.updateTags(conf, []);
    }
    const qidx = this.queues.findIndex(it => it.id === conf.id);
    let queue: PrintQueue;
    if (qidx !== -1) {
      queue = this.queues[qidx];
      this.queues.splice(qidx, 1);
    }
    const idx = this.confs.findIndex(it => it.id === conf.id);
    if (idx !== -1) {
      this.confs.splice(idx, 1);
      try {
        await this.removePrinter?.(conf);
      } catch (e) {
        console.warn(e);
      }
      this.savePrinters();
    }
    if (queue) {
      try {
        await queue.close();
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async forceRemoveDevice(conf: PrinterConf, device: DevicePrintersInfo) {
    if (!conf.id) return;
    const tags = this.getPrinterTags(conf, device);
    if (tags.length) {
      for (let tag of tags) {
        await this.removeRemoteTag?.(tag, conf, device);
      }
    }

    await this.removeRemoteDevice?.(conf, device);
  }

  sendLocalEvent(cmd: LocalMessage) {
    if (this.channel) {
      this.channel.postMessage(cmd);
    }
  }

  printShareSupported = true;

  async sendRemoteEvent(cmd: PrinterCommand) {
    if (this.context.$feathers && this.printShareSupported) {
      try {
        await this.context.$feathers.service("printerShares").create(<any>cmd);
      } catch (e) {
        if (e.code === 404) {
          // not supported
          this.printShareSupported = false;
        }
        console.warn(e);
      }
    }
  }

  savePrinters() {
    this.storage.setItem("printers", JSON.stringify(this.confs));
    this.sendLocalEvent({
      type: "printers",
      confs: this.confs,
    });
  }

  saveSettings() {
    this.storage.setItem("printersSettings", JSON.stringify(this.settings));
    this.sendLocalEvent({
      type: "settings",
      settings: this.settings,
    });
  }

  async query(type: string, port?: string, tag?: string) {
    const confs = defConfs.filter(it => it.type === type && (!port || it.port === port));
    for (let conf of confs) {
      try {
        return await this.tryConnect(_.cloneDeep(conf), undefined, tag);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async getQueue(type: string, userInteration?: boolean, tag?: string, printerId?: string) {
    let queue = this.queues.find(
      it =>
        (!type || it.type === type) &&
        it.connected &&
        (!tag || printerId || _.indexOf(it.printer.conf.tags, tag) !== -1 || it.printer.conf.id === tag) &&
        (!printerId || it.printer.conf.id === printerId),
    );
    if (!queue) {
      for (let tryQueue of this.queues.filter(
        q =>
          (!type || q.type === type) &&
          (!tag || printerId || _.indexOf(q.printer.conf.tags, tag) !== -1 || q.printer.conf.id === tag) &&
          (!printerId || q.printer.conf.id === printerId),
      )) {
        try {
          await tryQueue.printer.tryConnect();
          break;
        } catch (e) {
          if (printerId) return tryQueue;
          console.warn(e);
        }
      }
      queue = this.queues.find(
        it =>
          (!type || it.type === type) &&
          it.connected &&
          (!tag || printerId || _.indexOf(it.printer.conf.tags, tag) !== -1 || it.printer.conf.id === tag) &&
          (!printerId || it.printer.conf.id === printerId),
      );
    }

    if (!queue) {
      const d = await this.querySaved(type, userInteration, true, tag);
      if (d) {
        queue = d.queue;
      }
    }

    if (!queue && userInteration && !printerId) {
      // show device setup dialog
      const d: PrinterBase = await this.context.$openDialog(
        // @ts-ignore
        import("./dialogs/DevicePicker.vue"),
        {
          server: this,
          type,
          tag,
        },
        {
          contentClass: "editor-dialog",
          persistent: true,
        },
      );
      if (d) {
        queue = d.queue;
      }
    }

    if (!queue) {
      throw new Error("No printer available");
    }

    return queue;
  }

  getTestQueue(type: string) {
    const device = new DebugPrinter(this, {
      type,
      port: "debug",
      opts: {
        exportData: true,
      },
    } as any);
    const queue = new PrintQueue(device, this);
    device.queue = queue;
    return queue;
  }

  async managePrint(type?: string, tag?: string, manage?: boolean) {
    await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/DevicePicker.vue"),
      {
        server: this,
        manage,
        type,
        tag,
      },
      {
        contentClass: "editor-dialog",
        persistent: true,
      },
    );
  }

  async manageDevice(item: PrinterConf | string) {
    const id = typeof item === "string" ? item : item.id;
    const queue = this.queues.find(it => it.id === id);
    if (!queue) return;
    const d: PrinterBase = await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/DeviceManager.vue"),
      {
        server: this,
        queue,
        item: queue.printer.conf,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async getRemoteConf(device: DevicePrintersInfo, item: PrinterConf | string) {
    const id = typeof item === "string" ? item : item.id;
    const conf = await new Promise<PrinterConf>((resolve, reject) => {
      this.on(`netDeviceConf/${id}/${device._id}`, (ev: PrinterCommand) => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        resolve(ev.conf);
      });
      this.sendRemoteEvent({
        command: "getConf",
        deviceId: id,
        serverId: device._id,
      });
      let timer = setTimeout(() => {
        timer = null;
        reject(new Error("Timeout"));
      }, 15000);
    });
    return conf;
  }

  async manageRemoteDevice(device: DevicePrintersInfo, item: PrinterConf | string) {
    const conf = await this.getRemoteConf(device, item);

    const d: PrinterBase = await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/DeviceManager.vue"),
      {
        server: this,
        device,
        item: conf,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async manageCloudPrint(type?: string) {
    await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/ServerPrinter.vue"),
      {
        server: this,
        type,
      },
      {
        maxWidth: "80%",
        contentClass: "h-full",
      },
    );
  }

  async manageCloudDevice(id: string) {
    try {
      const item = await this.context.$feathers.service("cloudPrinters").get(id);
      await this.context.$openDialog(
        // @ts-ignore
        import("./dialogs/ServerPrinterEdit.vue"),
        {
          server: this,
          conf: this.getServerConf(item),
          item,
        },
        {
          maxWidth: "80%",
          contentClass: "editor-dialog",
        },
      );
    } catch (e) {
      console.warn(e);
    }
  }

  advancedAssignMode = false;
  async assignDevice(item: PrinterConf, device?: DevicePrintersInfo) {
    await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/DeviceAssign.vue"),
      {
        server: this,
        item,
        device,
        advancedAssignMode: this.advancedAssignMode,
      },
      {
        maxWidth: "min(80%, 600px)",
      },
    );
  }

  async assignCloudDevice(item: any) {
    await this.context.$openDialog(
      // @ts-ignore
      import("./dialogs/DeviceAssign.vue"),
      {
        server: this,
        cloud: item,
      },
      {
        maxWidth: "min(80%, 600px)",
      },
    );
  }

  getServerConf(item) {
    return {
      port: "server",
      name: item.name,
      address: item._id,
      type: item.type,
      id: item._id,
      deviceId: item._id,
      opts: {
        lineWidth: 48,
        clineWidth: 60,
        ...(item.opts || {}),
      },
    } as ServerConf;
  }

  async setShared(item: PrinterConf, shared?: boolean) {
    if (item.shared === shared) return;
    item.shared = shared;
    this.savePrinters();
    const queue = this.queues.find(it => it.id === item.id);
    if (shared && !queue) {
      await this.tryConnect(item);
    }
    queue.resetSharing();
  }

  async setRemoteShared(device: DevicePrintersInfo, item: PrinterConf, shared?: boolean) {
    if (item.shared === shared) return;
    this.sendRemoteEvent({
      command: "setShared",
      deviceId: item.id,
      serverId: device._id,
      shared,
    });
  }

  async setRemoteConf(device: DevicePrintersInfo, item: PrinterConf) {
    this.sendRemoteEvent({
      command: "setConf",
      deviceId: item.id,
      serverId: device._id,
      conf: item,
    });
  }

  updateItem(item: PrinterConf, update: Partial<PrinterConf>) {
    let dirty = false;
    for (let [k, v] of Object.entries(update)) {
      if (item[k] !== v) {
        Vue.set(item, k, v);
        dirty = true;
      }
    }
    const queue = this.queues.find(it => it.id === item.id);
    if (queue?.printer) {
      for (let [k, v] of Object.entries(update)) {
        if (queue.printer.conf[k] !== v) {
          Vue.set(queue.printer.conf, k, v);
        }
      }
    }
    if (dirty) {
      this.savePrinters();
    }
  }

  setOpts(item: PrinterConf, key: string, v: any) {
    if (item.opts?.[key] === v) return;
    if (!item.opts) Vue.set(item, "opts", {});
    Vue.set(item.opts, key, v);
    const queue = this.queues.find(it => it.id === item.id);
    if (queue?.printer) {
      if (!queue.printer.conf.opts) Vue.set(queue.printer.conf, "opts", {});
      Vue.set(queue.printer.conf.opts, key, v);
    }
    const conf = this.confs.find(it => it.id === item.id);
    if(conf !== item) {
      conf.opts = item.opts;
    }
    this.savePrinters();
  }

  getStatus(item: PrinterConf) {
    const queue = this.queues.find(it => it.id === item.id);
    if (queue) {
      return queue.printer.connected ? "connected" : "offline";
    } else {
      return "offline";
    }
  }

  async disconnectDevice(item: PrinterConf) {
    const queue = this.queues.find(it => it.id === item.id);
    if (queue) {
      await queue.printer.disconnect(true);
    }
  }

  async onLocalMessage(ev: MessageEvent) {
    console.log(ev);
    const cmd: LocalMessage = ev.data;
    switch (cmd.type) {
      case "settings":
        this.settings = cmd.settings;
        break;
      case "printers":
        cmd.confs.forEach(it => {
          const current = this.confs.find(item => item.id === it.id);
          if (current) _.assign(current, it);
          else this.confs.push(it);
        });
        this.confs.forEach(it => {
          const current = cmd.confs.find(item => item.id === it.id);
          if (!current) {
            const idx = this.queues.findIndex(item => item.id === it.id);
            const jdx = this.confs.indexOf(it);
            if (jdx !== -1) this.confs.splice(jdx, 1);
            if (idx !== -1) {
              this.queues.splice(idx, 1);
              const q = this.queues[idx];
              try {
                q.close();
              } catch (e) {
                console.warn(e);
              }
            }
          }
        });
        this.queues.forEach(q => q.resetSharing());
        break;
    }
  }

  async onRemoteMessage(ev: PrinterCommand) {
    switch (ev.command) {
      case "discover":
        if (ev.clientId === this.id) return;
        this.queues.forEach(q => q.advertise());
        this.sendRemoteEvent({
          command: "advertiseServer",
          serverId: this.id,
          serverName: this.serverName,
        });
        break;
      case "advertise":
        if (ev.serverId === this.id) return;
        this.emit("netDevice", ev);
        this.emit(`netDevice/${ev.deviceId}`, ev);
        this.emit(`netDevice/${ev.deviceId}/${ev.serverId}`, ev);
        break;

      case "discoverServer":
        if (ev.clientId === this.id) return;
        this.sendRemoteEvent({
          command: "advertiseServer",
          serverId: this.id,
          serverName: this.serverName,
        });
        if (ev.getPrinterStatus) {
          for (let queue of this.queues) {
            this.sendRemoteEvent({
              command: "printerStatus",
              ...queue.getDeviceInfo(),
            });
          }
        }
        break;

      case "advertiseServer":
        if (ev.clientId === this.id) return;
        this.emit("netServerList", ev);
        this.emit(`netServerList/${ev.serverId}`, ev);
        break;

      case "connect":
        if (ev.clientId === this.id || (ev.serverId && ev.serverId !== this.id)) return;
        const device = this.queues.find(it => it.id === ev.deviceId && it.printer?.conf?.shared);
        if (device) {
          device.handleConnect(ev);
        } else {
          // maybe popup
        }
        break;
      case "status": {
        if (ev.serverId === this.id) return;
        this.emit(`netDevice/${ev.deviceId}`, ev);
        this.emit(`netDevice/${ev.deviceId}/${ev.serverId}`, ev);
        break;
      }

      case "print": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        queue.print(new PrintJob(ev.jobName, Buffer.from(ev.jobData, "base64"), ev.jobId, ev.jobOpts));
        break;
      }
      case "testPrint":
      case "clear":
      case "cancel": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        if (ev.command === "testPrint") {
          testPrint(queue).catch(console.warn);
        } else if (ev.command === "clear") {
          queue.clear();
        } else {
          const job = queue.allJobs.find(it => it.id === ev.jobId);
          if (job) {
            queue.cancelJob(job);
          }
        }
        break;
      }
      case "getStatus": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        const job = queue.allJobs.find(it => it.id === ev.jobId);
        if (job) {
          queue.updateJobStatus(job);
        }
        break;
      }

      case "printStatus": {
        if (ev.serverId === this.id) return;
        this.emit(`netDevice/${ev.deviceId}`, ev);
        this.emit(`netDevice/${ev.deviceId}/${ev.serverId}`, ev);
        this.emit(`netDevice/${ev.deviceId}/${ev.serverId}/${ev.jobId}`, ev);
        if (ev.jobStatus !== "queued" && ev.jobStatus !== "printing") {
          this.emit(`netDevice/${ev.deviceId}/${ev.serverId}/${ev.jobId}/done`, ev);
        }

        const conf = this.confs.find(it => it.id === ev.deviceId);
        if (!conf) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        queue.updateJob(ev);
        break;
      }

      case "getPrinterStatus": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        this.sendRemoteEvent({
          command: "printerStatus",
          ...queue.getDeviceInfo(),
        });
        break;
      }

      case "printerStatus": {
        if (ev.serverId === this.id) return;
        this.emit(`netDevice`, ev);
        this.emit(`netDevice/${ev.deviceId}`, ev);
        this.emit(`netDevice/${ev.deviceId}/${ev.serverId}`, ev);
        break;
      }

      case "ping": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        this.sendRemoteEvent({
          command: "pong",
          serverId: this.id,
        });
        break;
      }

      case "pong": {
        if (ev.serverId === this.id) return;
        this.emit(`netServer/${ev.serverId}`, ev);
        break;
      }

      case "setTags":
      case "removeDevice":
      case "toggleDevice": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const conf = this.confs.find(it => it.id === ev.deviceId);
        if (!conf) return;
        if (ev.command === "removeDevice") {
          this.removeDevice(conf).catch(console.warn);
        } else if (ev.command === "setTags") {
          this.updateTags(conf, ev.tags).catch(console.warn);
        } else if (ev.command === "toggleDevice") {
          if (ev.reconnect) {
            try {
              await this.disconnectDevice(conf);
              await this.tryConnect(conf, true);
            } catch (e) {
              console.warn(e);
            }
          } else {
            const status = this.getStatus(conf);
            if (status === "connected") {
              this.disconnectDevice(conf).catch(console.warn);
            } else {
              this.tryConnect(conf, true).catch(console.warn);
            }
          }
        }
        break;
      }

      case "getConf": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const conf = this.confs.find(it => it.id === ev.deviceId);
        this.sendRemoteEvent({
          command: "getConfResult",
          deviceId: ev.deviceId,
          serverId: this.id,
          conf,
        });
        break;
      }

      case "setConf": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const conf = this.confs.find(it => it.id === ev.deviceId);
        if (!conf) return;
        for (let [k, v] of Object.entries(ev.conf)) {
          Vue.set(conf, k, v);
        }
        this.savePrinters();
        break;
      }

      case "getConfResult": {
        if (ev.serverId === this.id) return;
        this.emit(`netDeviceConf/${ev.deviceId}`, ev);
        this.emit(`netDeviceConf/${ev.deviceId}/${ev.serverId}`, ev);
        break;
      }

      case "setShared": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const conf = this.confs.find(it => it.id === ev.deviceId);
        if (!conf) return;
        this.setShared(conf, ev.shared)
          .then(() => {
            this.sendRemoteEvent({
              command: "getConfResult",
              deviceId: ev.deviceId,
              serverId: this.id,
              conf,
            });
          })
          .catch(console.warn);
        break;
      }

      case "listQueue": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const queue = this.queues.find(it => it.id === ev.deviceId);
        if (!queue) return;
        this.sendRemoteEvent({
          command: "listQueueResult",
          deviceId: ev.deviceId,
          serverId: this.id,
          queue: queue.allJobs.map(it => ({
            status: it.status,
            id: it.id,
            name: it.name,
            createTime: it.createTime,
            lastError: it.lastError,
            retryable: it.retryable,
            size: it.size,
          })),
        });
        break;
      }

      case "listQueueResult": {
        if (ev.serverId === this.id) return;
        this.emit(`netDeviceQueue/${ev.deviceId}`, ev);
        this.emit(`netDeviceQueue/${ev.deviceId}/${ev.serverId}`, ev);
        break;
      }

      case "remoteScan": {
        if (ev.clientId === this.id || ev.serverId !== this.id) return;
        const host = await this.getRemoteScanHost();
        host.handleEvent(ev);
        break;
      }

      case "remoteScanResp": {
        if (ev.clientId !== this.id) return;
        const host = await this.getRemoteScanHost();
        host.handleRespEvent(ev);
        break;
      }
    }
  }

  _remoteScanHost: Promise<DeviceScanner>;
  getRemoteScanHost() {
    if (!this._remoteScanHost) {
      this._remoteScanHost = this.initRemoteScanHost();
    }
    return this._remoteScanHost;
  }

  async initRemoteScanHost() {
    const hostLib = await import("./deviceScanner/host");
    return new hostLib.DeviceScanner(this);
  }

  bitmapList: {
    [tag: string]: BitmapOpts;
  } = {};
  registerBitmap(opts: BitmapOpts) {
    this.bitmapList[opts.tag] = opts;
  }

  resolveBitmap(key: string, url?: string, context?: WrappedContext) {
    let opts = this.bitmapList[key];
    if (opts) {
      const u = typeof opts.defaultImage === "function" ? opts.defaultImage(context) : opts.defaultImage;
      return u || url;
    } else {
      return url;
    }
  }

  async updateTags(conf: PrinterConf, tags: string[]) {
    const currentTags = this.getPrinterTags(conf);
    const removedTags = currentTags.filter(it => tags.indexOf(it) === -1);
    const addedTags = tags.filter(it => currentTags.indexOf(it) === -1);
    const allTags = (await this.getAllTags?.()) ?? [];
    const tagDict = Object.fromEntries(allTags.map(it => [it.tag, it]));

    for (let tag of addedTags) {
      const curTag = this.confs.find(
        it =>
          it.id !== conf.id &&
          (tag !== "default" || it.type === conf.type) &&
          ((it.tags || []).indexOf(tag) !== -1 || this.getPrinterTags?.(it)?.includes(tag)),
      );
      let addingSub = false;
      if (curTag) {
        if (tagDict[tag]?.multiple) {
          addingSub = true;
        } else {
          await this.removeTag?.(tag, curTag);
          curTag.tags = curTag.tags.filter(it => it !== tag);
        }
      }
      try {
        await this.addTag?.(tag, conf, addingSub);
      } catch (e) {
        console.warn(e);
        addedTags.splice(addedTags.indexOf(tag), 1);
      }
    }

    for (let tag of removedTags) {
      try {
        await this.removeTag?.(tag, conf);
      } catch (e) {
        console.warn(e);
      }
    }

    this.setPrinterTags(conf, tags);
  }

  async updateCloudTags(item: any, tags: string[]) {
    const currentTags = this.getCloudPrinterTags(item);
    const removedTags = currentTags.filter(it => tags.indexOf(it) === -1);
    const addedTags = tags.filter(it => currentTags.indexOf(it) === -1);

    for (let tag of addedTags) {
      try {
        await this.addCloudTag?.(tag, item);
      } catch (e) {
        console.warn(e);
        addedTags.splice(addedTags.indexOf(tag), 1);
      }
    }

    for (let tag of removedTags) {
      try {
        await this.removeCloudTag?.(tag, item);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async updateTagsRemote(device: DevicePrintersInfo, conf: PrinterConf, tags: string[]) {
    await this.sendRemoteEvent({
      command: "setTags",
      deviceId: conf.id,
      serverId: device._id,
      tags,
    });
  }

  // #region fonts

  get allFonts() {
    return [...(this.opts?.localFonts ?? []), ...(this.settings?.fonts ?? [])];
  }

  get allSubsets() {
    return ["latin-ext", "chinese-traditional", "chinese-hongkong", "chinese-simplified", "japanese", "thai", "korean", "emoji"];
  }

  get defaultSubsets() {
    const region = this.context.$config?.region ?? "global";

    const langSubsets: string[] = ["latin-ext"];

    if (["hk", "global"].includes(region)) {
      langSubsets.push("chinese-hongkong");
    }

    if (["hk", "tw", "global"].includes(region)) {
      langSubsets.push("chinese-traditional");
    }

    if (["cn", "global"].includes(region)) {
      langSubsets.push("chinese-simplified");
    }

    if (["jp", "global"].includes(region)) {
      langSubsets.push("japanese");
    }

    if (["th", "global"].includes(region)) {
      langSubsets.push("thai");
    }

    if (["kr", "global"].includes(region)) {
      langSubsets.push("korean");
    }

    return langSubsets;
  }

  get subsets() {
    if (this.settings?.subsets?.length) {
      return this.settings.subsets;
    }
    return this.defaultSubsets;
  }

  set subsets(subsets: string[]) {
    Vue.set(this.settings, "subsets", subsets);
    this.saveSettings();
  }

  get fontsInfo(): FontInfo {
    const settings = { ...(this.settings?.defaultFonts ?? {}) };
    const langSubsets = this.subsets;

    const fontsBySubset = _.groupBy(
      this.allFonts.flatMap(it => it.subsets.map(s => [it, s] as const)),
      it => it[1],
    );

    function applyFont(style: FontSettingType, fontStyle: string, weight: string) {
      const cur = settings[style];
      if (!cur) {
        const fontList = langSubsets.flatMap(subset => fontsBySubset[subset]?.map(it => it[0]) ?? []);
        const family = _.groupBy(fontList, it => it.name);

        const fonts = Object.entries(family).map(
          ([name, fonts]) =>
            fonts.find(it => it.style === fontStyle && it.weight === weight) ||
            fonts.find(it => it.style === fontStyle) ||
            fonts.find(it => it.weight === weight) ||
            fonts[0],
        );

        settings[style] = {
          lists: fonts.map(it => `${it.name} ${it.variant}`),
        };
      }
    }

    applyFont("normal", "normal", "regular");
    applyFont("bold", "normal", "700");
    applyFont("italic", "italic", "regular");
    applyFont("boldItalic", "italic", "700");

    return {
      settings: settings as any,
      fonts: Object.fromEntries(this.allFonts.map(it => [`${it.name} ${it.variant}`, it] as const)),
    };
  }

  // #endregion

  editTag: (tag: string, conf: PrinterConf, device?: DevicePrintersInfo) => Promise<void> = null;
  formatTag: (tag: string) => LangType = null;
  getAllGroups: () => Promise<PrinterTagGroup[]> = null;
  getAllTags: () => Promise<PrinterTag[]> = null;
  getPrinterTags: (conf: PrinterConf, device?: DevicePrintersInfo) => string[] = (conf, device) =>
    this.getPrinterTagsWithDetails(conf, device).map(it => it.tag);
  getPrinterTagsWithDetails: (conf: PrinterConf, device?: DevicePrintersInfo) => PrinterTagInfo[] = conf =>
    (conf.tags || []).map(it => ({
      tag: it,
    }));
  getCloudPrinterTags: (item: any) => string[] = item => [];
  async setPrinterTags(conf: PrinterConf, tags: string[]) {
    Vue.set(conf, "tags", tags);
    this.savePrinters();
  }

  addTag: (tag: string, conf: PrinterConf, addTag?: boolean) => Promise<void> = null;
  removeTag: (tag: string, conf: PrinterConf) => Promise<void> = null;
  addCloudTag: (tag: string, item: any) => Promise<void> = null;
  removeCloudTag: (tag: string, item: any) => Promise<void> = null;
  removeRemoteTag: (tag: string, conf: PrinterConf, device: DevicePrintersInfo) => Promise<void> = null;
  removeRemoteDevice: (conf: PrinterConf, device: DevicePrintersInfo) => Promise<void> = null;
  addPrinter: (conf: PrinterConf) => Promise<void> = null;
  removePrinter: (conf: PrinterConf) => Promise<void> = null;
  queryDevices: (device?: string | string[]) => Promise<DevicePrintersInfo[]> = null;

  openTemplateEditor: (data: PrintDebugData, queue?: PrintQueue) => void = null;
}

export interface DevicePrintersInfo {
  _id: string;
  name: LangType;
  printers: (PrinterConf & { status?: string })[];
  online?: boolean;
}

export interface PrinterTag {
  tag: string;
  name?: LangType;
  type?: string;
  group?: string;
  multiple?: boolean;
}

export interface PrinterTagInfo {
  tag: string;
  index?: number;
}

export interface PrinterTagGroup {
  group: string;
  name?: LangType;
  add?: () => Promise<PrinterTag>;
  type?: string;
  portType?: string;
  adding?: boolean;
}

let server: PrinterServer;

export async function init(context: Vue, storage?: Storage, opts?: PrinterServerOpts) {
  if (!server) {
    server = new PrinterServer(context.$root, storage, opts);
    await server.init();
  }
  return server;
}

export function wrapVue(context: Vue): WrappedContext {
  return context as any;
}

export async function testPrint(
  queue: PrintQueue,
  short = false,
  logo?: string,
  cb?: (seq: any) => Promise<void> | void,
  jobCb?: (job: PrintJob) => Promise<void> | void,
) {
  if (queue.printer.conf.type === "label") {
    const sequence = queue.createSequence<LabelSequence>();

    sequence.reset().size(40, 30).gap(1.5, 0);
    sequence.qrcode(`hello world`, 12, 164, "M");
    await sequence.text("Current: " + sequence.chineseFont + " @ " + sequence.codePage, 20, 20, undefined);
    await sequence.text("中文測試 Test", 20, 44, undefined, undefined, undefined, sequence.chineseFont);

    await sequence.text("TSS24.BF2", 20, 68, undefined);
    await sequence.text("中文測試 Test", 20, 92, undefined, undefined, undefined, "TSS24.BF2");

    await sequence.text("TST24.BF2", 20, 116, undefined);
    await sequence.text("中文測試 Test", 20, 140, undefined, undefined, undefined, "TST24.BF2");

    await cb?.(sequence as any);

    sequence.print(1);
    await queue.print(sequence.getJob("Test"));
  } else {
    const sequence = queue.createSequence<PrintSequence>();

    const bitmapTag = Object.keys(queue.server.bitmapList || {})[0];

    if (bitmapTag) {
      await sequence.printImageTag(bitmapTag);
    }

    if (logo) {
      await sequence.printImage(logo, 200, true);
    }

    sequence.text("Testing");
    sequence.text("中文測試 / 中文测试 / テスト / 테스트 / การทดสอบ / thử nghiệm / 膶煳");

    if (!short) {
      sequence.fill("=");
      sequence.text("UTF8: ");
      sequence.raw([0xe4, 0xb8, 0xad, 0xe6, 0x96, 0x87, 0xe6, 0xb8, 0xac, 0xe8, 0xa9, 0xa6]);
      sequence.text("");

      sequence.text("BIG5: ");
      sequence.raw([0xa4, 0xa4, 0xa4, 0xe5, 0xb4, 0xfa, 0xb8, 0xd5]);
      sequence.text("");

      sequence.text("GB18030: ");
      sequence.raw([0xd6, 0xd0, 0xce, 0xc4, 0x9c, 0x79, 0xd4, 0x87]);
      sequence.text("");

      sequence.text("GBK: ");
      sequence.raw([0xd6, 0xd0, 0xce, 0xc4, 0x9c, 0x79, 0xd4, 0x87]);
      sequence.text("");

      sequence.text("shift_jis: ");
      sequence.raw([0x92, 0x86, 0x95, 0xb6, 0x91, 0xaa, 0x8e, 0x8e]);
      sequence.text("");

      sequence.text("euc-kr: ");
      sequence.raw([0xf1, 0xe9, 0xd9, 0xfe, 0xf6, 0xb4, 0xe3, 0xcb]);
      sequence.text("");

      sequence.fontSize(1, 1).text("Large");
      sequence.reset().fontSize();
      sequence.center().text("Center");
      sequence.right().bold(true).text("Right").bold(false);
      sequence.reset();
      sequence.fill("-");
      sequence.printQR("testing", 24);
    }

    await cb?.(sequence as any);

    sequence.cutWithFeed();
    const job = await sequence.getJob("Test");

    await jobCb?.(job);
    await queue.print(job);
  }
}

export function getPortIcon(port: string) {
  switch (port) {
    case "usb":
      return "usb";
    case "bluetooth":
      return "bluetooth";
    case "cloud":
    case "server":
      return "cloud";
    case "net":
      return "share";
    case "socket":
    case "epos":
    case "star":
    case "epson":
      return "lan";
  }
}
