<template>
  <v-app :light="!dark" :dark="dark" ref="app" :class="{ 'full-page-app': fullPage }">
    <v-content>
      <nuxt></nuxt>
    </v-content>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  head() {
    return {
      htmlAttrs: {
        style: [
          `overflow: hidden; height: 100%;`,
          this.$store.state.fontScale === 100 ? "" : `font-size: ${this.$store.state.fontScale}%;`,
        ].join(""),
        class: "full-page-app",
      },
      bodyAttrs: {
        style: `overflow: hidden; height: 100%`,
      },
    };
  },
  computed: {
    fullPage() {
      return this.$store.state.fullPage;
    },
    dark() {
      return this.$store.state.dark;
    },

    shop() {
      return this.$store.state.shop;
    },
  },
  methods: {
    updateFullPage() {
      if (process.server) return;
      const nuxt = document.getElementById("__nuxt");
      if (this.fullPage) nuxt.classList.add("fill-height");
      else nuxt.classList.remove("fill-height");

      const layout = document.getElementById("__layout");
      if (this.fullPage) layout.classList.add("fill-height");
      else layout.classList.remove("fill-height");
    },
  },
  watch: {
    fullPage(val) {
      this.updateFullPage();
    },
    dark() {
      this.$vuetify.theme.dark = this.dark;
    },
  },
  mounted() {
    this.updateFullPage();
  },
  async beforeMount() {
    this.$vuetify.theme.dark = this.dark;
    try {
      if (typeof navigator.wakeLock !== "undefined") {
        const wakeLock = await navigator.wakeLock.request("screen");
        wakeLock.addEventListener("release", () => {
          console.log("Screen Wake Lock released:", wakeLock.released);
        });
        this.wakeLock = wakeLock;
      }
    } catch (e) {
      console.error("Wake Lock API is not supported", e);
    }
  },

  async beforeDestroy() {
    if (this.wakeLock) {
      await this.wakeLock.release();
      this.wakeLock = null;
    }
  },
};
</script>

<style>
.full-page-app .v-content__wrap,
.full-page-app #app,
.full-page-app .v-application--wrap,
.full-page-app #__layout,
.full-page-app .v-main,
.full-page-app #__nuxt {
  height: 100%;
}
.full-page-app .v-application--wrap {
  min-height: initial !important; /* vuetify */
}
</style>
