var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?[_c('v-progress-circular',{attrs:{"indeterminate":""}})]:[_c('line-chart',{attrs:{"chartData":_vm.chartData,"options":{
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }}})]],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }