
import { ObjectID } from "@db";
import { Vue, Component, Prop, PropSync } from "@feathers-client";
import { queueingCard } from "types/types";

@Component
export default class WaitingListPanel extends Vue {
  @Prop()
  cardDetails: queueingCard;
  // @PropSync("alert") alertSync: any;
  // @PropSync("nextNumber") nextNumberSync: any;
  // @PropSync("seatDialogOpen") seatDialogOpenSync: any;

  @Prop()
  loading: boolean;

  @Prop()
  disabled: boolean;

  get ticketCard() {
    return this.cardDetails.callingTicket && this.cardDetails.callingTicket._id !== this.cardDetails._id
      ? this.cardDetails.callingTicket
      : this.cardDetails;
  }

  callTicket() {
    this.$emit("callTicket", this.ticketCard._id);
  }
  assignTable() {
    this.$emit("assignTable", this.ticketCard);
  }
  updateNextTicket() {
    this.$emit("updateNextTicket", this.cardDetails.queueingGroup);
  }
}
