
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import { ProductLine, TableSession } from '~/plugins/table/session'
import Dialog from "domore-table/mixins/Dialog"

@Component

export default class OrderInfoDialog extends mixins(Dialog()) {

  @Prop()
  session: TableSession

  tempRemark: string = '';

  loading: boolean = false;

  get remarks() {
    return this.session.sessionData.remarks;
  }

  async saveInfo() {
    try {
      this.loading = true;
      this.session.sessionData.remarks = this.tempRemark; 
      if (this.session?.sessionData?._id) {
        await this.$feathers.service('tableSessions').patch(this.session.item._id, {
          remarks: this.tempRemark,
        });
      } 
      this.modalResult(true);
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.mesage)
    } finally {
      this.loading = false;
    }
  }

  beforeMount() {
    this.tempRemark = this.remarks ?? '';
  }
}


