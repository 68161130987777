
import { Component, Vue, Prop, PropSync } from "@feathers-client";

@Component
export default class TimeListPicker extends Vue {
  @Prop() all: any;
  @PropSync("buttonName") buttonNameSync: string | number;

  isOpen: boolean = false;

  update(item: any) {
    this.isOpen = false;
    this.buttonNameSync = item;
  }

  updateToAll() {
    this.isOpen = false;
    this.$emit("updateTime", null);
  }

  onClickOutside() {
    this.isOpen = false;
  }

  get formatHour() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        hours.push(`0${i}:00`);
      } else {
        hours.push(`${i}:00`);
      }
    }
    return hours;
  }
}
