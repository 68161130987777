
import _ from "lodash";
import { DB, ObjectID } from "@db";
import { Component, Prop, Vue, Watch, mixins, PropSync, Ref, escapeRegExp } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import MemberDetail from "./memberDetail.vue";
import CreateMember from "./createMember.vue";
import Topup from "./Topup.vue";
import MemberCard from "./memberCard.vue";
import MemberDetailRow from "./memberDetailRow.vue";

@Component({
  components: {
    MemberDetail,
    CreateMember,
    MemberCard,
    Topup,
    MemberDetailRow,
  },
})
export default class memberList extends Vue {
  @Ref()
  search: HTMLInputElement;

  @Prop({ type: Boolean })
  fullScreen: boolean;

  @Prop({ type: Boolean })
  mobile: boolean;

  @Prop({ type: Boolean })
  simple: boolean;

  @Prop({ type: Boolean, default: true })
  addMember: boolean;

  @Prop({ type: String })
  scannedMemberId: string;

  searchKeyword = "";

  memberId: string = null;

  createMember = false;
  detailList = false;
  topUp = false;

  close() {
    this.detailList = false;
    this.$emit("closeDetail");
  }

  async reload() {
    this.selectedSortingMethod = "joinedTime";
    this.searchKeyword = "";
    (this.$refs.dataList as any).reload();
  }

  get currentMemberId() {
    return this.scannedMemberId || this.memberId;
  }

  @Watch("searchKeyword")
  toLowerCase() {
    return (this.searchKeyword = this.searchKeyword.toLowerCase());
  }

  async mounted() {
    if (this.mobile) this.selectedSortingMethod = "joinedTime";
  }

  async openScanner() {
    const code = await this.$openDialog(
      import("pos-printer/dialogs/QrScanner.vue"),
      {
        title: this.$t("memberList.scanMemberQrCode"),
      },
      {
        maxWidth: "80%",
      },
    );
    if (!code) return;
    this.$emit("decode", { code });
  }

  get userQuery() {
    const trim = (this.searchKeyword || "").trim().toLowerCase();
    if (!trim) return {};
    const escaped = escapeRegExp(trim);
    return {
      $or: [
        {
          email: {
            $regex: escaped,
            $options: "i",
          },
        },
        {
          phone: {
            $regex: escaped,
            $options: "i",
          },
        },
        {
          name: {
            $regex: escaped,
            $options: "i",
          },
        },
        {
          userId: {
            $regex: escaped,
            $options: "i",
          },
        },
      ],
    };
  }

  get userSort() {
    return this.selectedSortingMethod === "consumptionTime"
      ? {
          lastPurchaseDate: -1,
          _id: -1,
        }
      : {
          date: -1,
          _id: -1,
        };
  }

  selectedSortingMethod = "consumptionTime";
  sortingMethod = [
    { _id: "consumptionTime", name: this.$t("vipMemberships.memberList.sorting.byConsumptionTime") },
    { _id: "joinedTime", name: this.$t("vipMemberships.memberList.sorting.byJoinedTime") },
  ];

  session: TableSession = null;

  get dollar() {
    return this.$shop.pointOrDollar.find(p => p.tag === "dollar");
  }

  openTopUpSession() {
    if (this.session) {
      this.session.$destroy();
      this.session = null;
    }
    this.session = new TableSession({
      parent: this,
    });
    this.session.detach();
    this.session.init(
      {
        tables: [],
        type: "topUp",
        startTime: new Date(),
        view: null,
        user: this.currentMemberId as any,
      },
      true,
    );

    this.session.pauseSave();

    this.topUp = true;
  }

  openDetail(userId: string) {
    this.memberId = userId;
    this.detailList = true;
  }
}
