import { MHookContext } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";

export async function create(hook: MHookContext<AdminApplication, any, any>) {
  hook.result = {
    accessToken: hook.data.accessToken,
    user: (hook as any).$offline.user,
  };
}

