import { boxsCloudPost } from "../ports/cloud";
import { sign, init, jwk } from "../utils/cloudAuth";
import { ChaCha20Poly1305 } from "@stablelib/chacha20poly1305";
import { BLECmdRoot, BLECmdVersion0, OperationMode } from "./struct";
import { EventEmitter } from "events";
import Vue from "vue";
import { SocketDevice, connect, MDNSResponse, mdns } from "../ports/socket";
import { directConnect } from "../utils/bluetoothPolyfill";
import { EspPairing } from "./pairing";

export abstract class EspDevice {
  constructor(
    public config: EspDeviceConfig = {},
    public context: Vue,
    public log?: (msg: string) => void,
  ) {}

  static async create(config: EspDeviceConfig, context: Vue, log?: (msg: string) => void): Promise<EspDevice> {
    const device =
      config.mode === "lan" ? new EspDeviceLan(config, context, log) : new EspDeviceBluetooth(config, context, log);
    return device;
  }

  async connect() {
    if (!this.config.key) {
      this.log?.("[ESP] going to pair");
      await this.pair();
    }

    await this.updateInfo();
  }

  async updateInfo() {
    this.log?.("[ESP] getting info");
    const info = await this.sendCmd({
      Info: {},
    });
    this.operationMode = OperationMode.getEnumKey(info.InfoResponse.operationMode);
    if (info.InfoResponse.ports && info.InfoResponse.portData?.length) {
      this.pid = info.InfoResponse.portData[0].idProduct;
      this.vid = info.InfoResponse.portData[0].idVendor;
      this.config.pid = this.pid;
      this.config.vid = this.vid;
    }
    if (
      info.InfoResponse.major !== undefined &&
      info.InfoResponse.minor !== undefined &&
      info.InfoResponse.patch !== undefined
    ) {
      this.version = `${info.InfoResponse.major}.${info.InfoResponse.minor}.${info.InfoResponse.patch}`;
    } else {
      this.version = "0.1.5";
    }
    this.log?.(`[ESP] got info ${JSON.stringify(info.InfoResponse)}`);
  }

  async pair() {
    const payload = await boxsCloudPost("devicePair", {
      token: await sign(
        {},
        {
          mac: this.config.address,
          deviceName: this.config.deviceName,
        },
      ),
    });

    console.log(payload);
    this.config.key = payload.pairKey;
    if (payload.lanIp) {
      this.config.lanIp = payload.lanIp;
    }
  }

  currentCmd: Promise<BLECmdVersion0>;
  currentCmdResp: { resolve: (payload?: BLECmdVersion0) => void; reject: (err: Error) => void };
  otaCb: (status: string) => void;
  version: string;

  async sendCmd(input: BLECmdVersion0, checkError = false) {
    while (this.currentCmd) {
      await this.currentCmd;
    }
    if (this.disconnected) {
      throw new Error("device closed");
    }
    const task = this.sendCmdInner(input, checkError);
    this.currentCmd = task;
    try {
      return await task;
    } finally {
      if (this.currentCmd === task) {
        this.currentCmd = null;
      }
    }
  }

  async sendCmdInner(input: BLECmdVersion0, checkError = false) {
    const payload = Buffer.concat(
      BLECmdRoot.write({
        payload: {
          Version0: input,
        },
      }),
    );
    const key = BLECmdVersion0.getEnumKey(input);
    const hasResponse = BLECmdVersion0.hasEnum(key + "Response");
    let resp: Promise<BLECmdVersion0> = null;
    if (hasResponse) {
      resp = new Promise<BLECmdVersion0>((resolve, reject) => {
        this.currentCmdResp = { resolve, reject };
      });
    }
    const nounce = Buffer.alloc(12);
    crypto.getRandomValues(nounce);
    const encrypted = Buffer.from(this.enc.seal(nounce, payload, null));

    const packet = Buffer.concat([nounce, encrypted]);

    await this.sendPacket(packet);
    const r = await resp;
    if (checkError) {
      if (r.Error) {
        let err = "Unknown error";
        if (r.Error) {
          err = r.Error.message?.toString?.();
        }
        throw new Error(`Pairing failed: ${err}`);
      }
    }
    return r;
  }

  get enc() {
    if (!this.config.key) return null;
    return new ChaCha20Poly1305(Buffer.from(this.config.key, "hex"));
  }

  abstract sendPacket(buf: Buffer): Promise<void>;

  serial: EspDeviceSerial;
  serialPromise: Promise<EspDeviceSerial>;
  operationMode: keyof OperationMode = "Auto";
  disconnected = false;
  pid = 0;
  vid = 0;

  getSerial() {
    if (this.disconnected) {
      throw new Error("device closed");
    }
    if (!this.serial) {
      this.serial = new EspDeviceSerial(this);
      this.sendCmd({
        UartStartRead: {},
      }).catch(console.error);
    }
    return this.serial;
  }

  async getSerialAsync() {
    if (this.disconnected) {
      throw new Error("device closed");
    }
    if (!this.serialPromise) {
      const promise = (this.serialPromise = (async () => {
        this.serial = new EspDeviceSerial(this);
        if (this.version >= "0.1.7") {
          const resp = await this.sendCmd({
            UartStartReadExt: {},
          });
          if (resp.UartStartReadExtResponse.status !== 1) {
            throw new Error("failed to start read");
          }
        } else {
          this.sendCmd({
            UartStartRead: {},
          }).catch(console.error);
        }
        return this.serial;
      })());
      promise.catch(() => {
        this.serialPromise = null;
      });
      return promise;
    }
    return this.serialPromise;
  }

  disconnect() {
    this.disconnected = true;
    if (this.currentCmdResp) {
      this.currentCmdResp.reject(new Error("device closed"));
      this.currentCmdResp = null;
    }
    if (this.serial) {
      const serial = this.serial;
      this.serial = null;
      serial.close();
    }
  }

  processPayload(buf: Buffer) {
    const decrypted = Buffer.from(this.enc.open(buf.slice(0, 12), buf.slice(12)));

    const [root] = BLECmdRoot.read(decrypted, 0);
    const payload = root.payload.Version0;
    if (payload.UartInResponse) {
      const data = payload.UartInResponse.jobData;
      this.getSerial().emit("data", data);
    } else if (payload.OtaProgress) {
      if (this.otaCb) {
        this.otaCb(payload.OtaProgress.message.toString());
      }
    } else if (payload.UartStartReadExtResponse?.status === 2) {
      this.log?.("[ESP] uart read ext stopped");
      this.serialPromise = null;
      if (this.serial) {
        this.serial.detached();
        this.serial = null;
      }
    } else {
      if (this.currentCmdResp) {
        this.currentCmdResp.resolve(payload);
        this.currentCmdResp = null;
      }
    }
  }

  async getInfo() {
    const resp = await this.sendCmd(
      {
        Info: {},
      },
      true,
    );
    return resp.InfoResponse;
  }

  async connectWifi(ssid: string, password: string) {
    await this.sendCmd({
      SetWifi: {
        ssid,
        password,
      },
    });
  }

  async getCurrentWifis() {
    const resp = await this.sendCmd({
      GetWifiInfo: {},
    });
    return resp.GetWifiInfoResponse;
  }

  async clearWifi() {
    await this.sendCmd({
      ClearWifi: {},
    });
  }

  async deleteWifi(ssid: string) {
    await this.sendCmd({
      DeleteWifi: {
        ssid,
      },
    });
  }

  async ota(otaCb: (status: string) => void) {
    this.otaCb = otaCb;
    await this.sendCmd({
      Ota: {},
    });
  }

  async getStats() {
    const resp = await this.sendCmd({
      Stats: {},
    });
    return resp.StatsResponse;
  }

  async restartPort() {
    await this.sendCmd({
      RestartPort: {},
    });
  }

  async setOperationMode(mode: OperationMode) {
    await this.sendCmd({
      SetOperationMode: mode,
    });
  }
}

export class EspDeviceBluetooth extends EspDevice {
  // @ts-ignore
  device: BluetoothDevice;
  // @ts-ignore
  server: BluetoothRemoteGATTServer;
  // @ts-ignore
  service: BluetoothRemoteGATTService;
  // @ts-ignore
  readChar: BluetoothRemoteGATTCharacteristic;
  // @ts-ignore
  notifyChar: BluetoothRemoteGATTCharacteristic;
  // @ts-ignore
  writeChar: BluetoothRemoteGATTCharacteristic;
  mtu: number = 20;

  constructor(config: EspDeviceConfig, context: Vue, log?: (msg: string) => void) {
    super(config, context, log);
    this.onDisconnect = this.onDisconnect.bind(this);
    this.onData = this.onData.bind(this);
  }

  async connect() {
    this.device = await directConnect(this.context, this.config.bluetoothId, {
      filters: [
        {
          services: ["0000b035-0000-1000-8000-00805f9b34fb"],
        },
      ],
    });

    await this._connectInner();
  }

  async upgrade(pair: EspPairing) {
    this.device = pair.device;
    await this._connectInner();
  }

  onDisconnect() {
    this.log?.("[ESP] bluetooth disconnected");
    this.disconnect();
  }

  onData() {
    const value = this.notifyChar.value as DataView;
    const buf = Buffer.from(value.buffer);
    // console.log(buf);

    this.processPayload(buf);
  }

  async _connectInner() {
    this.log?.("[ESP] going to connect bluetooth");
    this.device.addEventListener("gattserverdisconnected", this.onDisconnect);
    this.server = await this.device.gatt.connect();

    this.log?.("[ESP] connected to gatt server");
    this.service = await this.server.getPrimaryService("0000b035-0000-1000-8000-00805f9b34fb");

    this.notifyChar = await this.service.getCharacteristic("0000b036-0000-1000-8000-00805f9b34fb");
    try {
      await this.notifyChar.stopNotifications();
    } catch (e) {
      console.warn(e);
    }
    await this.notifyChar.startNotifications();

    this.readChar = await this.service.getCharacteristic("0000b035-0000-1000-8000-00805f9b34fb");

    const deviceInfo = await this.readChar.readValue();
    const info = Buffer.from((deviceInfo as DataView).buffer);
    const versionBuf = info.length >= 12 ? info.slice(9, 12) : Buffer.from([0, 1, 5]);
    const version = [versionBuf.readUInt8(0), versionBuf.readUInt8(1), versionBuf.readUInt8(2)].join(".");
    this.version = version;

    this.mtu = info.readInt16LE(0);
    const mac = info.slice(2, 8);
    if (mac.toString("hex") !== this.config.address) {
      this.config.key = null;
    }
    this.config.address = mac.toString("hex");
    this.config.deviceName = this.device.name;
    this.config.bluetoothId = this.device.id;

    this.writeChar = await this.service.getCharacteristic("0000b037-0000-1000-8000-00805f9b34fb");

    this.notifyChar.addEventListener("characteristicvaluechanged", this.onData);

    await super.connect();
  }

  async sendPacket(packet: Buffer) {
    let mtu = this.mtu;
    let maxPacket = mtu - 5;

    if (packet.length < maxPacket) {
      await this.writeChar.writeValue(Buffer.concat([Buffer.from([0, 0x40]), packet]));
    } else {
      let j = 0;
      for (let i = 0; i < packet.length; i += maxPacket, j++) {
        const slice = packet.slice(i, i + maxPacket);
        const head = Buffer.alloc(2);
        head.writeUInt16LE(j, 0);
        await this.writeChar.writeValueWithoutResponse(Buffer.concat([head, slice]));
      }
      const head = Buffer.alloc(2);
      head.writeUInt16LE(j | 0x8000, 0);
      await this.writeChar.writeValue(head);
    }
  }

  disconnect(downgrading = false) {
    super.disconnect();
    if (this.device) {
      if (this.notifyChar) {
        this.notifyChar.stopNotifications().catch(console.error);
        this.notifyChar.removeEventListener("characteristicvaluechanged", this.onData);
        this.notifyChar = null;
      }
      if (!downgrading) {
        this.device.gatt.disconnect();
      }
      if (this.device) {
        this.device.removeEventListener("gattserverdisconnected", this.onDisconnect);
      }
      this.device = null;
      this.server = null;
      this.service = null;
      this.readChar = null;
      this.writeChar = null;
    }
  }
}

export class EspDeviceLan extends EspDevice {
  socket: SocketDevice;

  async sendPacket(buf: Buffer) {
    const len = Buffer.alloc(4);
    len.writeUInt32LE(buf.length, 0);
    await this.socket.send(Buffer.concat([len, buf]));
  }

  async connect() {
    if (!this.config.deviceName) {
      const resp: MDNSResponse = await this.context.$openDialog(
        // @ts-ignore
        import("../dialogs/MDNSDevicePicker.vue"),
        {
          type: "serial",
        },
        {
          maxWidth: "400px",
        },
      );

      if (!resp) {
        throw new Error("no device selected");
      }

      const domainName = resp.host;
      const deviceName = (domainName || "").replace(".local", "").split("-")[1].toUpperCase();
      const mac = resp.txt?.mac;
      const port = resp.port;

      this.config.deviceName = deviceName;
      this.config.address = mac;
      if (port) {
        this.config.port = port;
      }
    }

    console.log(this.config);

    try {
      this.log?.("[ESP] going to resolve lan");
      const ip = await Promise.race([
        (async () => {
          try {
            const result = await mdns({
              name: `boxs-${this.config.deviceName.toLowerCase()}.local`,
            });
            return result[0]?.ip;
          } catch (e) {
            return null;
          }
        })(),
        new Promise<string>(resolve => setTimeout(() => resolve(null), 5000)),
      ]);
      if (!ip) {
        throw new Error("failed to resolve ip");
      }
      this.log?.(`[ESP] going to connect lan, through ip ${ip}`);
      this.socket = await connect(ip, this.config.port || 8081);
    } catch (e) {
      console.warn(e);
      if (!this.config.lanIp) {
        this.log?.(`[ESP] going to resolve lan ip`);
        await this.pair();
      }
      this.log?.(`[ESP] going to connect lan via ip ${this.config.lanIp}`);
      if (this.config.lanIp) {
        try {
          this.socket = await connect(this.config.lanIp, this.config.port || 8081);
        } catch (e) {
          console.warn(e);
        }
        if (!this.socket) {
          this.log?.("[ESP] going to refresh ip");
          await this.pair();
          this.log?.(`[ESP] going to connect lan via ip ${this.config.lanIp}`);
          try {
            this.socket = await connect(this.config.lanIp, this.config.port || 8081);
          } catch (e) {
            console.warn(e);
          }
        }
      }
      if (!this.socket) {
        throw e;
      }
    }

    this.log?.("[ESP] connected to lan");

    this.socket.on("close", () => {
      this.log?.("[ESP] lan disconnected");
      this.disconnect();
    });

    const buf = Buffer.alloc(4096);
    let offset = 0;
    this.socket.on("data", (data: Buffer) => {
      try {
        // console.log(data, data.length, offset);
        data.copy(buf, offset);
        offset += data.length;
        while (offset >= 4) {
          const packetLen = buf.readUInt32LE(0);
          if (packetLen + 4 > buf.length) {
            throw new Error("Packet too large: " + packetLen);
          }
          if (offset >= packetLen + 4) {
            const packet = buf.slice(4, packetLen + 4);
            this.processPayload(packet);

            // console.log('before', buf.slice(0, offset).toString('hex'))
            buf.copyWithin(0, packetLen + 4, offset);
            offset -= packetLen + 4;

            // console.log('after', buf.slice(0, offset).toString('hex'))
          } else {
            break;
          }
        }
      } catch (e) {
        console.error(e);
        this.disconnect();
      }
    });

    await super.connect();
  }

  disconnect() {
    super.disconnect();
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

export class EspDeviceSerial extends EventEmitter {
  constructor(public device: EspDevice) {
    super();
  }

  queue: {
    buf: Buffer;
    resolve: () => void;
    reject: (err: Error) => void;
  }[] = [];

  async write(buf: Buffer) {
    if (!this.device) {
      throw new Error("device closed");
    }
    return new Promise<void>((resolve, reject) => {
      this.queue.push({ buf, resolve, reject });
      this.processQueue();
    });
  }

  processQueueTask: Promise<void>;
  processQueue() {
    if (!this.processQueueTask) {
      this.processQueueTask = this.processQueueInner();
    }
  }

  async processQueueInner() {
    while (this.queue.length && this.device) {
      const { buf, resolve, reject } = this.queue.shift();
      try {
        await this.device.sendCmd({
          UartOut: {
            jobData: buf,
          },
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    }
    this.processQueueTask = null;
  }

  _deteached = false;

  close() {
    if (this.device) {
      this.device.disconnect();
      this.device = null;
      if (!this._deteached) {
        this.emit("close");
      }
    }
    while (this.queue.length) {
      const { reject } = this.queue.shift();
      reject(new Error("device closed"));
    }
  }

  detached() {
    this._deteached = true;
    this.emit("close", "detached");
    while (this.queue.length) {
      const { reject } = this.queue.shift();
      reject(new Error("device closed"));
    }
  }
}

export interface EspDeviceConfig {
  key?: string;
  deviceName?: string;
  address?: string;
  bluetoothId?: string;
  mode?: "bluetooth" | "lan";
  port?: number;
  lanIp?: string;
  pid?: number;
  vid?: number;
}
