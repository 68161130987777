
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeBase, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorLoop extends Vue {
  @Prop() template!: PrinterTemplateNodeBase<any>;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  @Prop(Boolean)
  svg: boolean;

  get list() {
    let list = this.template.loop.eval(this.context);
    if (list) {
      if (typeof list === "number") {
        list = new Array(list).fill(null).map((it, idx) => [idx, idx + 1]);
      } else if (Array.isArray(list)) {
        list = list.map((it, idx) => [idx, it]);
      } else if (typeof list === "object") {
        list = Object.entries(list);
      }

      if (list.length) {
        return {
          condition: true,
          list: list.map(([k, v]) => {
            const newContext = { ...this.context };
            if (this.template.loopVar) newContext[this.template.loopVar] = v;
            if (this.template.loopIndex) newContext[this.template.loopIndex] = k;
            return newContext;
          }),
        };
      }
    }

    return {
      condition: false,
      list: [
        {
          ...this.context,
          ...(this.template.loopVar ? { [this.template.loopVar]: undefined } : {}),
          ...(this.template.loopIndex ? { [this.template.loopIndex]: "###" } : {}),
        },
      ],
    };
  }
}
