
import { Component, InjectReactive, Prop, Vue } from "@feathers-client";
import type { PrinterServer } from "pos-printer";
import { PrinterTemplateNodeImage, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorImage extends Vue {
  @Prop() template!: PrinterTemplateNodeImage;

  @Prop() context: any;

  @InjectReactive("printerServer")
  printerServer: PrinterServer;

  @Prop()
  blockStyle: any;

  get tag() {
    return this.template.props["tag"]?.eval?.(this.context);
  }

  get qrcode() {
    return this.printerServer?.resolveBitmap?.(this.tag);
  }

  get style() {
    return {
      ...this.blockStyle,
      ...(this.align !== undefined
        ? {
            alignSelf: {
              left: "flex-start",
              center: "center",
              right: "flex-end",
            }[this.align],
          }
        : {}),
    };
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }
}
