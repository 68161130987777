
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType, FindPopRawType } from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import type { TableSessionRef } from "~/plugins/table";

@Component
export default class People extends Vue {
  @VModel()
  menu: boolean;

  @Prop()
  session: TableSession;

  get customerName(){
    return this.session.userName ?? "";
  }

  get customerPhone(){
    return this.session.userPhone ?? "";
  }

  compareRef(a: TableSessionRef, b: TableSessionRef) {
    return !!a === !!b && a && b && a.table === b.table && a.split === b.split;
  }

  async updateUserName(input: string){
    console.log(input)
    // this.session.userName = input;
    await this.session.atomic({
      userName: input ?? "",
    });

  }

  updateUserPhone(input: string){
    // this.session.userPhone = input;
    this.session.atomic({
      userPhone: input ?? "",
    });

  }

  get targetName() {
    return this.session.target ? this.session.target.session.getSplitName(this.session.target) : "";
  }

  get readonly() {
    return this.session.status !== "ongoing" && this.session.status !== "toPay";
  }

  query = {
    $populate: ["vipLevel", "ranks.rank"],
  };

  @Watch("customerName")
  onCustomerNameChange() {
    this.session.userName = this.customerName;
  }

  @Watch("customerPhone")
  onChangeCustomerPhone() {
    this.session.userPhone = this.customerPhone;
  }

  async applyUserInfo(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers">) {
    if (!user) {
      await this.session.atomic({
        user: null,
        ...this.session.cachedPriceDetails,
      });
      this.session.syncOrder();
      return;
    }
    this.session.userName = user.name ?? "";
    this.session.userPhone = user.phone ?? "";
    if (user?.vipLevel && typeof user.vipLevel === "object" && user.status === "active") {
      const vipLevel: FindType<"vipLevels"> = user.vipLevel as any;
      await this.session.tryApplyVip(vipLevel);
    }
    if (user?.ranks && user?.ranks.length) {
      for (let rank of user?.ranks) {
        await this.session.tryApplyRank(rank.rank);
      }
    }
    await this.session.atomic({
      userName: this.session.userName ? this.session.userName : user.name ?? "",
      userPhone: this.session.userPhone ? this.session.userPhone : user.phone ?? "",
      userGender: user.gender ?? this.session.userGender,
      user: user._id,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  get user() {
    return this.session.user;
  }

  set user(v) {
    this.session.setUser(v as any);
  }

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active" && (!user.expiry || new Date(user.expiry) > new Date());
  }

  flushUpdates() {
    // this.session.updateCoupons();
    // this.session.delaySave({
    //   ...this.session.cachedPriceDetails,
    // });
    // this.session.syncOrder();
  }
}
