

import { Component, Vue, Prop, VModel } from "@feathers-client"
import { Currencies } from "@feathers-client/currency";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    EditorObjectPickerList,
  }
})
export default class CurrencyPicker extends Vue {

  @Prop()
  label: string

  @VModel()
  inputValue: string

  get currencies() {
    return Object.entries(Currencies).filter(it => !(it[1] as any).hidden).map(it => ({
      name: it[1].name,
      _id: (it[1] as any).alias?.[0] ?? it[0],
    }));
  }

}

