
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType } from "@feathers-client";
import moment from "moment";
import DetailDialog from "./detailDialog.vue";

const populate = [
  {
    path: "tables.item",
    populate: [
      {
        path: "view",
      },
    ],
  },
] as const;

@Component({
  components: {
    DetailDialog,
  },
})
export default class ReservationItem extends Vue {
  @Prop()
  item: FindPopRawType<typeof populate, "tableSessions">;

  @Prop(Boolean)
  details: boolean;

  @Prop({ default: false })
  history: boolean;

  get cancelled() {
    return this.item.status === "cancelledBooking";
  }

  get isBooking() {
    return this.item.status === "booking";
  }

  get removing() {
    return (this.item as any).removing as boolean;
  }

  set removing(v: boolean) {
    Vue.set(this.item as any, "removing", v);
  }

  get adding() {
    return (this.item as any).adding as boolean;
  }

  set adding(v: boolean) {
    Vue.set(this.item as any, "adding", v);
  }

  get viewName() {
    return this.item.tables?.[0]?.item?.view?.name;
  }

  get tableName() {
    return this.item.tables?.[0]?.item?.name;
  }

  get floor() {
    return this.item.tables?.[0]?.item?.view?.name;
  }

  formatDate(time) {
    const m = moment(time);
    const today = moment().startOf("day");
    const tmr = moment(today).add(1, "day");
    const tmrtmr = moment(tmr).add(1, "day");

    if (m.isSameOrAfter(tmrtmr) || m.isBefore(today)) {
      return m.format("YYYY-MM-DD");
    } else if (m.isBefore(tmr)) {
      return this.$t("time.today");
    } else {
      return this.$t("time.tomorrow");
    }
  }

  formatTime(time) {
    return moment(time).format(this.details ? "YYYY-MM-DD HH:mm" : "HH:mm");
  }

  menu = false;

  toggleMenu(event) {
    if (this.removing) return;
    if (this.checkingIn) return;
    this.menu = !this.menu;
  }

  async beginEdit() {
    this.menu = false;
    const item = await this.$feathers.service("tableSessions").get(this.item._id);
    const resp = await this.$openDialog(
      import("~/components/table/reservation/addDialog.vue"),
      {
        currentItem: item,
      },
      {
        maxWidth: "50vw",
        contentClass: "h-80vh",
      },
    );
    if (resp) {
      await this.reload();
    }
  }

  // shit code but works
  async reload() {
    const oldItem = this.item;
    const item = await this.$feathers.service("tableSessions").get(oldItem._id, {
      query: {
        $populate: populate,
      },
    });
    Object.assign(oldItem, item);
    this.$emit("update", this.item);
  }

  checkingIn = false;
  async checkIn() {
    this.checkingIn = true;
    const oldItem = this.item;
    const item = await this.$feathers.service("tableSessions").get(oldItem._id);
    this.menu = false;
    let resp = null;

    if (this.item.tables.length) {
      resp = await this.$feathers.service("tableSessions/checkIn").create({
        session: item,
      });
    } else {
      resp = await this.$openDialog(
        import("./pickDialog.vue"),
        {
          item,
        },
        {
          maxWidth: "50vw",
          contentClass: "h-80vh",
        },
      );
    }
    if (resp) {
      Vue.set(oldItem, "removing", true);
    }
    this.checkingIn = false;
  }

  async absent() {
    const res = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("tableView.reservations.absentConfirm"),
        confirm: this.$t("tableView.reservations.absent"),
        confirmText: "text-white",
        confirmIcon: "$trash",
        confirmIconClass: "!text-white w-6 h-6",
        confirmBg: "bg-red300",
      },
      {
        maxWidth: "400px",
      },
    );
    if (!res) return;
    try {
      const oldItem = this.item;
      await this.$feathers.service("tableSessions").patch(oldItem._id, {
        status: "absent",
      });
      this.menu = false;
      //Vue.set(oldItem, "removing", true);
      await this.reload();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async cancelReservation() {
    const res = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("tableView.reservations.cancelConfirm"),
        confirm: this.$t("tableView.reservations.cancel"),
        confirmText: "text-white",
        confirmIcon: "$trash",
        confirmIconClass: "!text-white w-6 h-6",
        confirmBg: "bg-red300",
        lock: !this.$shop.hasPermission(["reservation/tableSessionCancelBooking"]),
        paddedLock: true,
      },
      {
        maxWidth: "400px",
      },
    );
    if (!res) return;

    const staff = await this.$shop.checkPermission(["reservation/tableSessionCancelBooking"]);
    if (staff === false) return;
    try {
      const oldItem = this.item;
      const updatedBooking = await this.$feathers.service("tableSessions").patch(oldItem._id, {
        status: "cancelledBooking",
      });

      await this.$feathers.service("actionLogs").create({
        session: this.item._id,
        staff: staff?._id || this.$shop.staffId,
        type: `reservation/tableSessionCancelBooking`,
        detail: { booking: updatedBooking },
      });

      this.menu = false;
      // Vue.set(oldItem, "removing", true);
      await this.reload();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  handleRemove() {
    if (this.removing) {
      this.$emit("remove", this.item);
    }
  }

  handleAdd() {
    if (this.adding) {
      this.adding = false;
    }
  }
}
