
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import { CartItem } from "@common/table/cart";
import { getProductTree } from "@common/table/util";
import Dialog from "domore-table/mixins/Dialog";
import type { LangArrType } from "@feathers-client/i18n";

@Component
export default class NumPadDialog extends mixins(Dialog()) {

  @Prop()
  title: string

  @Prop()
  label: string

  @Prop()
  originNumber: number

  loading = false;

  inputNum = 0
  grey = false

  inputNumStr = ''
  @Watch('inputNumStr')
  onInputNumStr(v) {
    v = v === null || v === "" || isNaN(+v) ? null : +v;
    this.inputNum = v;
  }

  doAction(action: number | string) {
    if (action === "C" || this.inputNumStr === "") {
      this.inputNum = null;
      this.grey = true;
    }
    //  else if (!this.isCustomReceived) {
    //   this.session.received = null;
    //   this.userClear = false;
    // } 
    else {
      this.grey = false;
    }
  }

  get priceStr() {
    if (this.inputNumStr) {
      const parts = this.inputNumStr.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    }
    // else {
    //   return this.$price(this.session.received);
    // }
  }

  get pricePadding() {
    if (this.inputNumStr) {
      const parts = this.inputNumStr.split(".");
      if (parts.length === 1) {
        return ".00";
      } else if (parts[1].length === 0) {
        return "00";
      } else if (parts[1].length === 1) {
        return "0";
      }
    }
    return "";
  }

  beforeMount() {
    const origin = this.originNumber ?? 0
    this.inputNum = origin
  }

}
