
import { FindType, Watch, checkID, getID } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";
import type { DevicePrintersInfo, PrinterCommand, PrinterServer, PrinterTagInfo } from "pos-printer";
import type { PrinterConf } from "pos-printer/printers/base";
import type { ServerConf } from "pos-printer/printers/server";
import { managePrint } from "~/plugins/printer/invoiceSequencer";
import { getPortIcon } from "pos-printer";
import _ from "lodash";

@Component
export default class OrderSystemHeader extends Vue {
  getPortIcon = getPortIcon;

  get deviceId() {
    return this.$shop.device?._id;
  }

  @Prop()
  printerServer: PrinterServer;
  printerSetup = false;

  cloudPrinters: FindType<"cloudPrinters">[] = [];

  loading = false;
  netTimer: any;

  beforeDestroy() {
    this.disableTimer();
  }

  @Watch("printerSetup")
  onMenu() {
    if (this.printerSetup) {
      this.updateStats();
    } else {
      this.disableTimer();
    }
  }

  disableTimer() {
    if (this.netTimer) {
      clearInterval(this.netTimer);
      this.netTimer = null;
      this.printerServer.off("netServerList", this.handleDeviceServer);
      this.printerServer.off(`netDevice`, this.handleDeviceStatus);
    }
  }

  handleDeviceStatus(data: PrinterCommand) {
    const device = this.printerInfos.find(it => it._id === data.serverId);
    if (!device) return;
    device.online = true;
    const printer = device.printers.find(it => it.id === data.deviceId);
    if (!printer) return;
    printer.status = data.status;
  }

  handleDeviceServer(data: PrinterCommand) {
    const device = this.printerInfos.find(it => it._id === data.serverId);
    if (!device) return;
    device.online = true;
  }

  discoveryNet() {
    this.printerServer.sendRemoteEvent({
      command: "discoverServer",
      clientId: this.printerServer.id,
      getPrinterStatus: true,
    });
  }

  get printerCount() {
    return this.printerServer?.queues?.length ?? 0;
  }

  get printerOnline() {
    return this.printerServer?.queues?.filter?.(it => it.status === "printing" || it.status === "ready")?.length ?? 0;
  }

  get printerError() {
    return !!this.printerServer?.queues?.find?.(it => it.status === "offline" || it.status === "error");
  }

  async managePrint() {
    await this.printerServer.managePrint(null, undefined, true);
  }

  async managePrinter(item: PrinterConf, device?: DevicePrintersInfo) {
    if (device) {
      await this.printerServer.manageRemoteDevice(device, item);
    } else {
      await this.printerServer.manageDevice(item);
    }
  }

  async manageCloud(conf: FindType<"cloudPrinters">) {
    await this.printerServer.manageCloudDevice(getID(conf));
  }

  getServerConf(item: FindType<"cloudPrinters">) {
    return {
      port: "server",
      name: item.name,
      address: item._id,
      type: item.type,
      id: item._id,
      deviceId: item._id,
      opts: {
        lineWidth: 48,
        clineWidth: 60,
        ...(item.opts || {}),
      },
    } as ServerConf;
  }

  async addPrinter() {
    managePrint(this, null, undefined, true);
  }
  async manageCloudPrintNew(type: string, id?: string, name?: any, job?: string) {
    await this.$openDialog(
      import("~/components/dialogs/EditShopPrinterDialogNew.vue"),
      {
        type: type,
        waterBarIdOrKitchenPrinterId: id,
        printerName: name,
        printerId: job,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async manageJob(job: FindType<"shopPrinters">) {
    await this.$openDialog(
      import("~/components/dialogs/EditShopPrinterDialogNew.vue"),
      {
        type: job.type,
        waterBarIdOrKitchenPrinterId: job.waterBar || job.kitchen,
        printerName: this.$td(this.$shop.formatJobName(job)),
        printerId: job._id,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  formatPrinter(tags: string[] = []) {
    return tags
      .map(tag => {
        if (tag === "default") {
          return this.$t("printerType.receipt");
        }
        return this.$td(
          (this.$shop.kitchenPrinters.find(it => it._id === tag) || this.$shop.waterBars.find(it => it._id === tag))
            ?.name,
        );
      })
      .filter(it => !!it)
      .join(", ");
  }

  getPrintJobs(conf: PrinterConf) {
    const infos = this.printerServer.getPrinterTagsWithDetails(conf);
    return infos
      .filter(i => i.tag !== "default")
      .map(info => {
        const job = this.$shop.shopPrinterDict[info.tag];
        return {
          ...info,
          job,
        };
      })
      .filter(i => !!i.job);
  }

  stats: FindType<"sharePrintJobs/stats"> = null;
  printJobStats: Record<
    string,
    {
      pending: number;
      done: number;
      error: number;
      total: number;
      byPrinter: Record<
        string,
        Record<
          string,
          {
            pending: number;
            done: number;
            error: number;
            total: number;
          }
        >
      >;
      byCloudPrinter: Record<
        string,
        {
          pending: number;
          done: number;
          error: number;
          total: number;
        }
      >;
    }
  > = {};

  byDevicePrinter: Record<string, Record<string, { pending: number; done: number; error: number; total: number }>> = {};
  byCloudPrinter: Record<string, { pending: number; done: number; error: number; total: number }> = {};
  printerInfos: DevicePrintersInfo[] = [];

  async updateStats() {
    try {
      this.loading = true;
      this.stats = await this.$feathers.service("sharePrintJobs/stats").create({});
      const printJobStats: typeof this.printJobStats = {};
      const byDevicePrinter: typeof this.byDevicePrinter = {};
      const byCloudPrinter: typeof this.byCloudPrinter = {};

      for (let stat of this.stats) {
        let targetStatus: "pending" | "done" | "error" = "pending";
        switch (stat._id.status) {
          case "error":
            targetStatus = "error";
            break;
          case "done":
            targetStatus = "done";
            break;
        }
        let target =
          printJobStats[stat._id.shopPrinter] ||
          (printJobStats[stat._id.shopPrinter] = {
            pending: 0,
            done: 0,
            error: 0,
            total: 0,
            byPrinter: {},
            byCloudPrinter: {},
          });
        target[targetStatus] += stat.count;
        target.total += stat.count;

        if (stat._id.localPrinter) {
          let targetDevicePrinter = target.byPrinter[stat._id.device] || (target.byPrinter[stat._id.device] = {});
          let targetPrinter =
            targetDevicePrinter[stat._id.localPrinter] ||
            (targetDevicePrinter[stat._id.localPrinter] = {
              pending: 0,
              done: 0,
              error: 0,
              total: 0,
            });

          targetPrinter[targetStatus] += stat.count;
          targetPrinter.total += stat.count;

          let device = byDevicePrinter[stat._id.device] || (byDevicePrinter[stat._id.device] = {});
          let printer =
            device[stat._id.localPrinter] ||
            (device[stat._id.localPrinter] = { pending: 0, done: 0, error: 0, total: 0 });
          printer[targetStatus] += stat.count;
          printer.total += stat.count;
        }

        if (stat._id.cloudPrinter) {
          let targetCloudPrinter =
            target.byCloudPrinter[stat._id.cloudPrinter] ||
            (target.byCloudPrinter[stat._id.cloudPrinter] = {
              pending: 0,
              done: 0,
              error: 0,
              total: 0,
            });

          targetCloudPrinter[targetStatus] += stat.count;
          targetCloudPrinter.total += stat.count;

          let cloudPrinter =
            byCloudPrinter[stat._id.cloudPrinter] ||
            (byCloudPrinter[stat._id.cloudPrinter] = { pending: 0, done: 0, error: 0, total: 0 });
          cloudPrinter[targetStatus] += stat.count;
          cloudPrinter.total += stat.count;
        }
      }

      this.printJobStats = printJobStats;
      this.byDevicePrinter = byDevicePrinter;
      this.byCloudPrinter = byCloudPrinter;
      this.cloudPrinters = await this.$feathers.service("cloudPrinters").find({
        query: {
          $paginate: false,
        },
        paginate: false,
      });

      this.printerInfos = (await this.printerServer.queryDevices()).map(d => ({
        ...d,
        online: false,
        printers: d.printers.map(p => ({
          ...p,
          status: "offline",
        })),
      }));

      if (!this.netTimer) {
        this.netTimer = setInterval(() => this.discoveryNet(), 15 * 1000);
        this.printerServer.on("netDevice", this.handleDeviceStatus);
        this.printerServer.on("netServerList", this.handleDeviceServer);
        this.discoveryNet();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  getJobCount(
    shopPrinter: string,
    status: "pending" | "done" | "error" | "total",
    localPrinter?: string,
    device?: string,
  ) {
    if (localPrinter) {
      return this.printJobStats[shopPrinter]?.byPrinter?.[device ?? this.deviceId]?.[localPrinter]?.[status] ?? 0;
    }
    return this.printJobStats[shopPrinter]?.[status] ?? 0;
  }

  getPrinterJobCount(device: string, localPrinter: string, status: "pending" | "done" | "error" | "total") {
    return this.byDevicePrinter[device]?.[localPrinter]?.[status] ?? 0;
  }

  getCloudPrinterJobCount(cloudPrinter: string, status: "pending" | "done" | "error" | "total") {
    return this.byCloudPrinter[cloudPrinter]?.[status] ?? 0;
  }

  get printTypeList(): {
    _id: string;
    name: string;
    type: string;
    appendText?: string;
    shopPrinter?: FindType<"shopPrinters">;
  }[] {
    let hasReceipt = false;
    let hasReview = false;
    let hasQrLink = false;
    const waterBarsOrKitchen = new Set<string>();
    const unassignedJobs: FindType<"shopPrinters">[] = [];

    for (let shopPrinter of Object.values(this.$shop.shopPrinterDict)) {
      if (shopPrinter.printerType === "template") {
        // check device list
        const cur = shopPrinter.templateSetting?.find?.(d => d.device === this.deviceId);
        if (!cur) {
          continue;
        }
        const d = this.printerServer.confs.find(it => it.id === cur.printer);
        if (!d) {
          continue;
        }
        if (shopPrinter.type === "table-receipt") {
          hasReview = true;
        } else if (shopPrinter.type === "table-qr-link") {
          hasQrLink = true;
        } else if (shopPrinter.type === "table-payment-receipt") {
          hasReceipt = true;
        } else {
        }
      }

      if (shopPrinter.type === "table-kitchen" || shopPrinter.type === "table-waterBar") {
        waterBarsOrKitchen.add(getID(shopPrinter.waterBar || shopPrinter.kitchen));

        if (shopPrinter.printerType === "local") {
          if (
            (shopPrinter.device === this.deviceId &&
              !this.printerServer.confs.find(it => it.id === shopPrinter.localPrinter)) ||
            !shopPrinter.device
          ) {
            unassignedJobs.push(shopPrinter);
          }
        } else if (shopPrinter.printerType === "cloud" && !shopPrinter.printer) {
          unassignedJobs.push(shopPrinter);
        }
      }
    }

    return [
      ...(hasReceipt
        ? []
        : [
            {
              name: this.$t("enum.pages.shopPrinters.type.table-payment-receipt"),
              type: "table-payment-receipt",
              appendText: null,
              _id: undefined,
            },
          ]),
      ...(hasQrLink
        ? []
        : [
            {
              name: this.$t("enum.pages.shopPrinters.type.table-qr-link"),
              type: "table-qr-link",
              appendText: null,
              _id: undefined,
            },
          ]),
      ...(hasReview
        ? []
        : [
            {
              name: this.$t("enum.pages.shopPrinters.type.table-receipt"),
              type: "table-receipt",
              appendText: null,
              _id: undefined,
            },
          ]),
      ...this.$shop.kitchenPrinters
        .filter(t => !waterBarsOrKitchen.has(getID(t)))
        .map(el => ({
          _id: el._id,
          name: this.$td(el.name),
          type: "table-kitchen",
          appendText: this.$t("enum.pages.shopPrinters.type.table-kitchen"),
        })),
      ...this.$shop.waterBars
        .filter(t => !waterBarsOrKitchen.has(getID(t)))
        .map(el => ({
          _id: el._id,
          name: this.$td(el.name),
          type: "table-waterBar",
          appendText: this.$t("enum.pages.shopPrinters.type.table-waterBar"),
        })),
      ...unassignedJobs.map(el => ({
        _id: el.printer || el.waterBar || el._id,
        name: this.$td(this.$shop.formatJobName(el)),
        type: el.type,
        shopPrinter: el,
      })),
    ] as any;
  }

  get remoteLocalPrinterInfos() {
    const shopPrinters = Object.values(this.$shop.shopPrinterDict).filter(
      it => it.printerType === "local" && it.device !== this.deviceId && it.device && it.localPrinter,
    );
    const grouppedByDevice = _.groupBy(shopPrinters, it => it.device);
    const printerInfos: {
      conf: PrinterConf & { status?: string };
      shopPrinters: (FindType<"shopPrinters"> & { index?: number })[];
      device: DevicePrintersInfo;
      isFirst: boolean;
    }[] = [];
    const deviceInfoDict = Object.fromEntries(this.printerInfos.map(it => [it._id, it] as const));
    for (let [device, printers] of Object.entries(grouppedByDevice)) {
      const deviceInfo = deviceInfoDict[device];
      if (!deviceInfo) continue;
      let isFirst = true;

      const printerIds = Array.from(
        new Set(
          printers.flatMap(it => [
            it.localPrinter,
            ...(it.subPrinters || []).filter(it => checkID(it.device, deviceInfo)).map(it => it.printer),
          ]),
        ),
      );

      for (let id of printerIds) {
        const printer = deviceInfo.printers.find(it => it.id === id);
        const printers = shopPrinters
          .filter(it => it.localPrinter === id || it.subPrinters?.some(it => it.printer === id))
          .map(it => ({
            ...it,
            index: (it.subPrinters || []).findIndex(it => it.printer === id) + 1,
          }));
        if (printer) {
          printerInfos.push({
            isFirst,
            conf: printer,
            shopPrinters: printers,
            device: deviceInfo,
          });
          isFirst = false;
        }
      }
    }
    return printerInfos;
  }

  get cloudPrinterInfos() {
    const cloudPrinterDict = Object.fromEntries(this.cloudPrinters.map(it => [it._id, it] as const));

    const shopPrinters = Object.values(this.$shop.shopPrinterDict).filter(
      it => it.printerType === "cloud" && it.printer && cloudPrinterDict[getID(it.printer)],
    );

    const printerIds = Array.from(new Set(shopPrinters.map(it => getID(it.printer))));
    const printerInfos: {
      conf: FindType<"cloudPrinters">;
      shopPrinters: (FindType<"shopPrinters"> & { index?: number })[];
    }[] = [];

    for (let id of printerIds) {
      const printer = cloudPrinterDict[id];
      const printers = shopPrinters
        .filter(it => checkID(it.printer, id))
        .map(it => ({
          ...it,
          index: (it.subPrinters || []).findIndex(it => it.printer === id) + 1,
        }));
      if (printer) {
        printerInfos.push({
          conf: printer,
          shopPrinters: printers,
        });
      }
    }

    return printerInfos;
  }
}
