import { PrintSequence, TextAlign } from "..";
import { LabelSequenceBase } from "../../labelSequence/base";
import { ThermalText } from "../canvas/job";
import { TextLine } from "../rawLine";
import { PrintTable } from "../table";

export abstract class BaseRenderer {
  constructor(public printOrLabel: PrintSequence | LabelSequenceBase) {
    if (printOrLabel instanceof PrintSequence) {
      this.parent = printOrLabel;
    } else {
      this.label = printOrLabel;
    }
  }

  parent: PrintSequence;
  label: LabelSequenceBase;

  protected _dpi = 12;

  protected get _canvasWidth() {
    if (this.label) {
      return this.label._curWidth * this.label.dpi;
    }
    return this.parent.lineWidth * 12;
  }

  protected get _canvasHeight() {
    if (this.label) {
      return this.label._curHeight * this.label.dpi;
    }
    return this._dpi * 16;
  }

  protected get _pxWidth() {
    return this._dpi * this.parent.normalLineWidth;
  }

  fixLineImageResolution(hiRes: boolean | number) {
    if (hiRes === true || hiRes === 33) {
      return this.parent.doubleVDensity ? hiRes : 1;
    } else if (hiRes === 32) {
      return this.parent.doubleVDensity ? hiRes : 0;
    }
    return hiRes;
  }

  /**
   * Print bitmap text
   */
  async bitmapText(opts: {
    text: string;
    align?: "left" | "center" | "right";
    size?: number;
    fontFamily?: string;
    bold?: boolean;
    color?: number;
    scaleY?: number;
    hiRes?: boolean | number;
    tight?: boolean;
    italic?: boolean;
  }) {
    if (opts?.color !== undefined) {
      this.parent.color(opts.color);
    }
    let size = opts.size || 24;
    const fontFamily = opts.fontFamily || this.parent.fontFamily;
    const text = opts.text || "";
    if (!text) return;

    const align = opts.align || "left";
    this.parent.align(align === "center" ? TextAlign.Center : align === "right" ? TextAlign.Right : TextAlign.Left);

    const context = await this.prepareContext();
    let hiRes = opts.hiRes ?? true;
    hiRes = this.fixLineImageResolution(hiRes);
    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    let scaleY = opts.scaleY || (vHi ? 1 : 0.5);

    if (!context) {
      const fx = Math.max(0, Math.ceil((size - 24) / 12));
      const fy = Math.ceil(fx * scaleY);
      this.parent.fontSize(fx, fy);
      this.parent.text(text);
      return;
    }

    const pxWidth = (hHi ? this._dpi : this._dpi / 2) * this.parent.lineWidth;
    if (!hHi) {
      size /= 2;
    }
    await this.setFont(fontFamily, size, fontFamily === this.parent.fontFamily, opts);

    let x = 0;
    let top = this._canvasHeight;
    let bottom = 0;

    const printLine = async () => {
      // print empty line
      if (!x && bottom - top) x = 8;
      if (x) {
        top = Math.floor(top);
        bottom = Math.ceil(bottom);
        const height = bottom - top;
        x = (((x + 7) / 8) | 0) * 8;
        const imgData = this.getImageData(0, top, x, height);
        this.parent.fontSize(0, 0);
        if (imgData) {
          await this.parent.printImageData(imgData.data, imgData.width, imgData.height, hiRes, opts.color);
        }
        await this.prepareContext();
      }
      x = 0;
      top = this._canvasHeight;
      bottom = 0;
    };

    await this.prepareContext();
    const parts = text.split("\n");
    for (let i = 0; i < parts.length; i++) {
      let t = parts[i];
      if (i) {
        await printLine();
      }
      if (!t && i !== parts.length - 1) {
        t = " ";
      }
      while (t) {
        const resp = this.drawText(t, x, pxWidth - x, size, scaleY, undefined, opts.tight);
        t = t.slice(resp.charsToDraw);
        x = resp.x;
        top = Math.min(top, resp.top);
        bottom = Math.max(bottom, resp.top + resp.height);
        if (t) {
          await printLine();
        }
      }
    }
    await printLine();
  }

  /**
   * Print bitmap table
   */
  async bitmapTable(
    table: PrintTable,
    opts: {
      fontSize?: number;
      fontFamily?: string;
      bold?: boolean;
      color?: number;
      scaleY?: number;
      hiRes?: boolean | number;
      tight?: boolean;
      italic?: boolean;
      colPadding?: number;
    },
  ) {
    if (opts?.color !== undefined) {
      this.parent.color(opts.color);
    }
    let size = opts.fontSize || 24;
    const fontFamily = opts.fontFamily || this.parent.fontFamily;

    const context = await this.prepareContext();
    let hiRes = opts.hiRes ?? true;
    hiRes = this.fixLineImageResolution(hiRes);
    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    let scaleY = (opts.scaleY ?? 1) * (vHi ? 1 : this.parent.useBitmapImage ? 0.5 : 1 / 3);

    if (!context) {
      const fx = Math.max(0, Math.ceil((size - 24) / 12));
      const fy = Math.ceil(fx * scaleY);
      this.parent.fontSize(fx, fy);
      this.parent.printTable(table);
      return;
    }

    if (!hHi) {
      size /= 2;
    }
    const lineWidth = Math.floor(this.parent.lineWidth / ((opts.fontSize || 24) / 24));
    table.fit(lineWidth);

    this.parent.align(TextAlign.Left);
    const cols = table.columns.map(col => ({
      lines: col.content.map(c => (c instanceof TextLine ? c.text : c.buf.toString())),
      left: 0,
      width: 0,
    }));

    const charWidth = (opts.fontSize || 24) / 2;

    let accum = 0;
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      const colInfo = table.columns[i];
      col.width = colInfo.size * charWidth;
      col.left = accum * charWidth;
      accum += colInfo.size;
    }

    const lines = cols.reduce((acc, col) => Math.max(acc, col.lines.length), 0);
    for (let i = 0; i < lines; i++) {
      while (true) {
        let top = this._canvasHeight;
        let bottom = 0;
        let x = 0;
        for (let j = 0; j < cols.length; j++) {
          const colInfo = table.columns[j];
          const col = cols[j];
          const line = col.lines[i];
          if (!line) continue;

          const curFontFamily = colInfo.fontFamily ?? fontFamily;

          await this.setFont(curFontFamily, colInfo.fontSize ?? size, curFontFamily === this.parent.fontFamily, {
            bold: colInfo.bold ?? opts.bold,
            italic: colInfo.italic ?? opts.italic,
            inverted: colInfo.inverted ?? false,
          });

          const resp = this.drawText(
            line,
            col.left,
            col.width,
            opts.fontSize,
            scaleY,
            cols.length === 1 ? "left" : (colInfo.align as any),
            opts.tight,
            colInfo.inverted ?? false,
            opts?.colPadding,
          );

          top = Math.min(top, resp.top);
          bottom = Math.max(bottom, resp.top + resp.height);
          x = Math.max(x, resp.x);
          if(colInfo.inverted) {
            x = Math.max(x, col.left + col.width);
          }

          col.lines[i] = line.slice(resp.charsToDraw);
        }
        if (bottom) {
          top = Math.floor(top);
          bottom = Math.ceil(bottom);
          const height = bottom - top;
          x = (((x + 7) / 8) | 0) * 8;
          x = Math.min(this.parent.pxWidth, x);
          const imgData = this.getImageData(0, top, x, height);
          if (cols.length === 1) {
            this.parent.align(
              table.columns[0].align === "center"
                ? TextAlign.Center
                : table.columns[0].align === "right"
                  ? TextAlign.Right
                  : TextAlign.Left,
            );
          }
          if (imgData) {
            await this.parent.printImageData(imgData.data, imgData.width, imgData.height, hiRes, opts.color);
          }
          await this.prepareContext();
        } else {
          break;
        }
      }
    }
  }

  async printTextCmd(parts: ThermalText[]) {
    let x = 0;
    let top = this._canvasHeight;
    let height = 0;
    let color = 0;
    let align: "left" | "right" | "center" = "left";

    let hiRes: boolean | number = true;
    hiRes = this.fixLineImageResolution(hiRes);
    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    let scaleY = vHi ? 1 : this.parent.useBitmapImage ? 0.5 : 1 / 3;

    const printLine = async () => {
      // print empty line
      if (!x && height) x = 8;

      if (x) {
        height = Math.ceil(height);
        x = (((x + 7) / 8) | 0) * 8;
        x = Math.min(this.parent.pxWidth, x);
        const imgData = this.getImageData(0, top, x, height);
        this.parent.align(align === "center" ? TextAlign.Center : align === "right" ? TextAlign.Right : TextAlign.Left);
        this.parent.color(color);
        if (imgData) {
          await this.parent.printImageData(imgData.data, imgData.width, imgData.height, hiRes, color);
        }
        await this.prepareContext();
      }
      x = 0;
      top = this._canvasHeight;
      height = 0;
      align = "left";
    };

    for (let line of parts) {
      if (line.type === "text") {
        const size = line.pxSize;
        const fontFamily = line.fontFamily || this.parent.fontFamily;
        await this.setFont(fontFamily, size, fontFamily === this.parent.fontFamily, line);
        const iscaleY = line.yScale || 1;

        const parts = line.text.split("\n");
        for (let i = 0; i < parts.length; i++) {
          let t = parts[i];
          if (i) {
            await printLine();
          }
          if (!t && i !== parts.length - 1) {
            t = " ";
          }
          while (t) {
            if (!x) {
              align = line.align;
            }
            if (!color) {
              color = line.color;
            }
            const resp = this.drawText(t, x, this._pxWidth - x, line.fontX, iscaleY * scaleY);
            t = t.slice(resp.charsToDraw);
            x = resp.x;
            top = Math.min(top, resp.top);
            height = Math.max(height, resp.height);
            if (t) {
              await printLine();
            }
          }
        }
      }
    }
    await printLine();
  }

  abstract getImageData(x: number, y: number, w: number, h: number): ImageData;

  abstract drawText(
    text: string,
    x: number,
    w: number,
    size: number,
    yScale?: number,
    align?: "left" | "right" | "center",
    tight?: boolean,
    inverted?: boolean,
    colPadding?: number,
  ): {
    x: number;
    top: number;
    height: number;
    charsToDraw: number;
  };

  abstract setFont(
    fontFamily: string,
    size: number,
    isDefault: boolean,
    props?: {
      bold?: boolean;
      italic?: boolean;
      inverted?: boolean;
      maxLines?: number;
    },
  ): Promise<void>;

  abstract prepareContext(): Promise<any>;

  abstract disposeContext();
}
