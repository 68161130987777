
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import { PaymentMethodType } from "~/common/table";

@Component
export default class Point extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  paymentMethod: PaymentMethodType;

  amount = 0;
  amountStr: string = "";

  async beforeMount() {
    await this.session.updateAvailPoints();
  }

  mounted() {
    this.amount = this.maxUse;
    this.amountStr = `${this.amount}`;
  }

  @Watch("amount")
  onAmount() {
    this.$emit("update:pointValid", this.amount > 0 && this.amount <= this.availPoint);
  }

  @Watch("availPoint")
  methodChange() {
    this.amount = this.maxUse;
    this.amountStr = `${this.amount}`;
  }

  get point() {
    return this.paymentMethod.props?.point;
  }

  get availPoint() {
    return Math.max(0, this.session.availPoints[this.point] ?? 0);
  }

  get maxPoint() {
    let amount = this.session.payingAmount;
    return Math.ceil(amount / (this.paymentMethod.props.usePointRatioInt || 1));
  }

  get maxUse() {
    const maxPoint = this.maxPoint;
    if (maxPoint > this.availPoint) {
      return this.availPoint;
    }
    return maxPoint;
  }

  apply() {
    if (this.amountStr) {
      let receivedInt = +this.amountStr;
      if (isNaN(receivedInt)) return null;
      this.amount = receivedInt;
    }
    return this.amount > 0 && this.amount <= this.availPoint ? this.amount : null;
  }
}
