import { render, staticRenderFns } from "./ProductInfo.vue?vue&type=template&id=809c9264"
import script from "./ProductInfo.vue?vue&type=script&lang=ts"
export * from "./ProductInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDivider,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('809c9264')) {
      api.createRecord('809c9264', component.options)
    } else {
      api.reload('809c9264', component.options)
    }
    module.hot.accept("./ProductInfo.vue?vue&type=template&id=809c9264", function () {
      api.rerender('809c9264', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/dialogs/ProductInfo.vue"
export default component.exports