import { Component, Prop, Watch, mixins, Vue } from "nuxt-property-decorator";
import { VueClass } from "vue-class-component/lib/declarations";
import uuid from "uuid/v4";
import _ from "lodash";
import { CurrentApp, FindType, runQuery } from "@feathers-client";
import { getOptions } from "@feathers-client/util";
import type { TableItem } from "./view";
import moment from "moment";

type UnionToIntersection<T> = (T extends any ? (k: T) => void : never) extends (k: infer U) => void ? U : never;

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type TEntity<T> = {
  item: Partial<T>;
  readonly id: string;
  readonly _id: string;
  tempId: string;
  atomic: (item: Partial<T>) => Promise<void>;
  autoSave: (delay?: number) => void;
  save: () => Promise<void>;
  presave: () => Promise<T>;
  postsave: () => Promise<void>;
  delaySave: (item: Partial<T>) => void;
  patch: (item: Partial<T>) => void;
  remove: () => void;
  detach: () => void;
  dirty: boolean;
  _tempParent?: string;
  _savingTask?: Promise<void>;
  _createTask?: Promise<void>;
  pauseSave: () => void;
  resumeSave: () => Promise<void>;
  reload: (id?: string) => Promise<void>;
  subscribe: () => void;
};

export type FieldExtend<TService extends keyof CurrentApp, TKey extends readonly (keyof FindType<TService>)[]> = {
  [K in TKey[number]]: FindType<TService>[K];
} & TEntity<FindType<TService>>;

export function MapFields<TService extends keyof CurrentApp, TKey extends readonly (keyof FindType<TService>)[]>(
  service: TService,
  fields: TKey,
  paths: string[] = [],
  defaults: Partial<FindType<TService>> | (() => Partial<FindType<TService>>) = {},
  opts: { deltaUpdate?: boolean } = {},
) {
  return {
    data() {
      return {
        mitem: null,
        dirty: false,
      };
    },
    computed: {
      ..._.fromPairs(
        fields.map(key => [
          key,
          {
            get(this: any) {
              return this.mitem?.[key];
            },
            set(this: any, v) {
              this.mitem[key] = v;
            },
          },
        ]),
      ),
      id(this: any) {
        return this.mitem?._id || this.tempId || null;
      },
      _id(this: any) {
        return this.mitem?._id || null;
      },
      item: {
        get(this: any) {
          return this.mitem;
        },
        set(this: any, v) {
          _.defaults(v, typeof defaults === "function" ? defaults() : _.cloneDeep(defaults));
          if (!v._id) {
            this.tempId = uuid();
          }
          if (v.__v_skip && v._isVue) {
            delete v.__v_skip;
            delete v._isVue;
          }
          this.mitem = v;
          Vue.nextTick(() => {
            this.dirty = !v._id;
          });
        },
      },
    },
    watch: {
      item: {
        deep: true,
        handler(this: any) {
          this.dirty = true;
        },
      },
    },
    beforeDestroy(this: any) {
      if (this._subscribed) {
        this.$feathers.service(service).off("patched", this.patched);
      }
    },
    methods: {
      async autoSave(this: any, delay = 500, atomic?: boolean) {
        await Vue.nextTick();
        if ((!atomic && !this.dirty) || this._pauseSave) return;
        if (!this.delaySaveDict && atomic) return;
        if (this.saveTimer) {
          clearTimeout(this.saveTimer);
          this.saveTimer = null;
        }
        this.saveTimer = setTimeout(() => {
          this.saveTimer = null;
          this.saving = true;
          const currentDelay = this.delaySaveDict;
          this.delaySaveDict = null;
          this._savingTask = (atomic ? this.atomic(currentDelay) : this.save())
            .then(() => {
              console.log("saved");
            })
            .catch(e => {
              console.warn(e);
            })
            .finally(() => {
              this.saving = false;
            });
        }, delay);
      },
      async save(this: any) {
        if (!this.dirty) return;
        if (this.saveTimer) {
          clearTimeout(this.saveTimer);
          this.saveTimer = null;
        }
        await this.presave();
        if (this._createTask) {
          await this._createTask;
        }
        if (this.item._id) {
          this.item = await this.$feathers.service(service).patch(this.item._id, this.item, {
            query: { $tempId: this.tempId },
          });
        } else {
          const task = (async () => {
            this.item = await this.$feathers.service(service).create(this.item, {
              query: { $tempId: this.tempId },
            });
          })();

          this._createTask = task;
          task.finally(() => {
            if (this._createTask === task) {
              this._createTask = null;
            }
          });
          await task;
        }
        if (this.delaySaveDict) {
          for (let [k, v] of Object.entries(this.delaySaveDict)) {
            Vue.set(this.mitem, k, v);
          }
          this.autoSave(undefined, true);
        }
        await this.postsave();
      },
      async presave(this: any) {
        if (!this._detached && paths.length && this.$parent) {
          await this.$parent.save?.();
          for (let v of paths) {
            this.item[v] = this.$parent.item?._id || null;
          }
        }
        return this.item;
      },
      async postsave(this: any) {
        await Vue.nextTick();
        this.dirty = false;
        if (this._dangling && !this._detached) {
          this.$parent["addEntity" + this._tempParent](this);
          this._dangling = false;
        }
      },
      detach(this: any) {
        this._detached = true;
      },
      async patch(this: any, item: any) {
        if (item.__v_skip && item._isVue) {
          delete item.__v_skip;
          delete item._isVue;
        }
        if (this.mitem && opts.deltaUpdate) {
          Object.assign(this.mitem, item);
        } else {
          this.mitem = item;
        }
        if (this.delaySaveDict) {
          for (let [k, v] of Object.entries(this.delaySaveDict)) {
            Vue.set(this.mitem, k, v);
          }
          this.autoSave();
        } else {
          await Vue.nextTick();
          this.dirty = false;
        }
      },
      async atomic(this: any, item: any) {
        if (this._pauseSave) {
          for (let [k, v] of Object.entries(item)) {
            Vue.set(this.item, k, v);
          }
          return;
        }
        await this.presave();
        if (this._createTask) {
          await this._createTask;
        }
        if (this.item._id) {
          this.item = await this.$feathers.service(service).patch(this.item._id, item, {
            query: { $tempId: this.tempId },
          });
        } else {
          const task = (async () => {
            this.item = await this.$feathers.service(service).create(
              { ...this.item, ...item },
              {
                query: { $tempId: this.tempId },
              },
            );
          })();

          this._createTask = task;
          task.finally(() => {
            if (this._createTask === task) {
              this._createTask = null;
            }
          });
          await task;
        }
        await Vue.nextTick();
        this.dirty = false;
      },
      async remove(this: any) {
        if (this.item._id) {
          await this.$feathers.service(service).remove(this.item._id);
        }
        if (this._tempParent) {
          this.$parent["removeEntity" + this._tempParent](this);
        }
      },
      pauseSave(this: any) {
        this._pauseSave = true;
      },
      async resumeSave(this: any) {
        if (!this._pauseSave) return;
        this._pauseSave = false;
        if (this.dirty) {
          await this.save();
        }
      },
      async reload(this: any, id?: string) {
        if (!id) {
          id = this.item._id;
        }
        if (id) {
          this.item = await this.$feathers.service(service).get(id);
        }
        return this;
      },
      delaySave(this: any, patch: any) {
        for (let [k, v] of Object.entries(patch)) {
          Vue.set(this.item, k, v);
        }
        if (this._pauseSave) return;
        if (!this.delaySaveDict) this.delaySaveDict = {};
        for (let [k, v] of Object.entries(patch)) {
          this.delaySaveDict[k] = _.cloneDeep(v);
        }
        this.autoSave(undefined, true);
      },
      subscribe(this: any) {
        if (this._subscribed) return;
        this._subscribed = true;
        this.$feathers.service(service).on("patched", this.patched);
      },
      patched(this: any, item: any) {
        if (item?._id === this.id) {
          this.item = item;
        }
      },
    },
  } as any as VueClass<FieldExtend<TService, TKey>>;
}

export type MapSubViewVue<TField extends string, TE, TEE> = {
  [T in `${TField}s`]: TE[];
} & {
  [T in `${TField}Dict`]: {
    [key: string]: TE;
  };
} & {
  [T in `add${Capitalize<TField>}`]: (item?: TEE, parent?: TEntity<any>) => TE;
} & {
  [T in `addEntity${Capitalize<TField>}`]: (item: TE) => TE;
} & {
  [T in `removeEntity${Capitalize<TField>}`]: (item: TE) => TE;
};

export function MapSubView<TP extends string, TEE extends TEntity<any>, TC extends string = TP>(
  c: () => VueClass<TEE>,
  parentKey: TP,
  currentKey: TC | TP = parentKey,
) {
  const CcurrentKey = capitalize(currentKey);
  const CparentKey = capitalize(parentKey);

  const symbol = Symbol();
  return {
    created() {
      this[currentKey + "Dict"] = {};
      if (!this.$parent[symbol]) {
        this.$parent[symbol] = true;
        const ctor = this.constructor;
        this.$parent.$on(`add:${parentKey}`, entity => {
          let cur = entity.$parent;
          while (cur && cur !== this.$parent) {
            if (cur instanceof ctor) {
              cur[`addEntityUpdate${CcurrentKey}`](entity);
              break;
            }
            cur = cur.$parent;
          }
        });
        this.$parent.$on(`added:${parentKey}`, entity => {
          let cur = entity.$parent;
          while (cur && cur !== this.$parent) {
            if (cur instanceof ctor) {
              cur[`addEntityUpdate${CcurrentKey}`](entity);
              break;
            }
            cur = cur.$parent;
          }
        });
        this.$parent.$on(`remove:${parentKey}`, entity => {
          let cur = entity.$parent;
          while (cur && cur !== this.$parent) {
            if (cur instanceof ctor) {
              cur[`removeEntityUpdate${CcurrentKey}`](entity);
              break;
            }
            cur = cur.$parent;
          }
        });
      }
    },
    data() {
      return {
        [`${currentKey}s`]: [],
      };
    },
    methods: {
      [`add${CcurrentKey}`](this: any, item, parent) {
        const entity = this.$parent[`add${CparentKey}`](item, parent || this);
        return entity;
      },
      [`addEntity${CcurrentKey}`](this: any, entity: TEntity<any>) {
        return this.$parent[`addEntity${CparentKey}`](entity);
      },
      [`removeEntity${CcurrentKey}`](this: any, entity: TEntity<any>) {
        return this.$parent[`removeEntity${CparentKey}`](entity);
      },

      [`addEntityUpdate${CcurrentKey}`](this: any, entity: TEntity<any>) {
        const dict = this[`${currentKey}Dict`];
        const list = this[`${currentKey}s`];

        const cur =
          dict[entity.item?._id] ||
          ((entity.item?.tempId || entity.tempId) && dict[entity.item?.tempId || entity.tempId]) ||
          (entity.item?.offlineId && dict[entity.item?.offlineId]);
        if (!cur) {
          list.push(entity);
        }
        if (entity.item?._id) dict[entity.item._id] = entity;
        if (entity.item?.tempId) dict[entity.item.tempId] = entity;
        if (entity.item?.offlineId) dict[entity.item.offlineId] = entity;
        if (entity.tempId) dict[entity.tempId] = entity;
        return entity;
      },

      [`removeEntityUpdate${capitalize(currentKey)}`](this: any, entity: TEntity<any>) {
        const dict = this[`${currentKey}Dict`];
        const list = this[`${currentKey}s`];

        const cur =
          dict[entity.item?._id] ||
          ((entity.item?.tempId || entity.tempId) && dict[entity.item?.tempId || entity.tempId]) ||
          (entity.item?.offlineId && dict[entity.item?.offlineId]);
        if (!cur) return;
        const idx = list.indexOf(cur);
        idx !== -1 && list.splice(idx, 1);
        if (cur.item?._id) delete dict[cur.item._id];
        if (cur.item?.tempId) delete dict[cur.item.tempId];
        if (cur.item?.offlineId) delete dict[cur.item.offlineId];
        if (cur.tempId) delete dict[cur.tempId];
        return cur;
      },
    },
  } as any as VueClass<MapSubViewVue<TC, TEE, TEE["item"]>>;
}

export type EntityTableOptBase = {
  service?: keyof CurrentApp;
  field?: string;
  resolveParent?: (self: any, item: any) => any;
  filter?: any;
  sort?: any;
  id?: string;
};

export type EntityTableOptCreater = {
  cb?: () => VueClass<any>;
};

export type EntityTableOpt = EntityTableOptBase | (EntityTableOptBase & EntityTableOptCreater);

export type EntityTableOpts = readonly EntityTableOpt[];

export type ConstructType<T extends VueClass<any>> = T extends VueClass<infer U> ? U : never;
export type EntityTableItemType<TMap extends EntityTableOpt> = TMap extends EntityTableOptCreater
  ? ConstructType<ReturnType<TMap["cb"]>>
  : TEntity<FindType<TMap["service"]>>;

export type EntityTableItem<TMap extends EntityTableOpt> = EntityTableItemType<TMap> extends infer TE
  ? TMap["field"] extends infer TField
    ? TField extends string
      ? {
          [T in `${TField}s`]: TE[];
        } & {
          [T in `${TField}Dict`]: {
            [key: string]: TE;
          };
        } & {
          [T in `add${Capitalize<TField>}`]: (
            item?: Partial<FindType<TMap["service"]>>,
            parent?: TEntity<any>,
            createAsDangling?: boolean,
          ) => TE;
        } & {
          [T in `remove${Capitalize<TField>}`]: (item?: Partial<FindType<TMap["service"]>>) => TE;
        } & {
          [T in `addEntity${Capitalize<TField>}`]: (item: TE) => TE;
        } & {
          [T in `removeEntity${Capitalize<TField>}`]: (item: TE) => TE;
        } & {
          [T in `reload${Capitalize<TField>}`]: () => Promise<void>;
        } & {
          ready: Promise<void>;
          reload(): Promise<void>;
          reloadAll(): Promise<void>;
        }
      : never
    : never
  : never;

export type EntityTableItems<TMaps extends EntityTableOpts> = {
  [TKey in keyof TMaps]: TMaps[TKey] extends EntityTableOpt ? EntityTableItem<TMaps[TKey]> : never;
}[number];

export type EntityTableVue<TMaps extends EntityTableOpts> = UnionToIntersection<EntityTableItems<TMaps>> extends infer U
  ? {
      -readonly [K in keyof U]-?: U[K];
    }
  : never;

export function EntityTable<TMaps extends EntityTableOpts>(maps: TMaps) {
  return {
    created(this: any) {
      this.ready = this.initCore();
      (this.$feathers as any).on("connected", this.reloadAll);
      for (let entry of maps) {
        this[`${entry.field}Dict`] = {};
        this.$feathers.service(entry.service).on("created", this[`add${capitalize(entry.field)}`]);
        this.$feathers.service(entry.service).on("patched", this[`add${capitalize(entry.field)}`]);
        this.$feathers.service(entry.service).on("removed", this[`remove${capitalize(entry.field)}`]);
      }
    },
    data() {
      return _.assign(
        {},
        ..._.map(maps, it => ({
          [`${it.field}s`]: [],
        })),
      );
    },
    beforeDestroy() {
      (this.$feathers as any).off("connected", this.reloadAll);
      for (let entry of maps) {
        this[`${entry.field}Dict`] = {};
        this[`${entry.field}s`] = null;
        this.$feathers.service(entry.service).off("created", this[`add${capitalize(entry.field)}`]);
        this.$feathers.service(entry.service).off("patched", this[`add${capitalize(entry.field)}`]);
        this.$feathers.service(entry.service).off("removed", this[`remove${capitalize(entry.field)}`]);
      }
    },
    methods: {
      async initCore(this: Vue) {
        await this.$offline?.waitPendingRetires?.();
        if (!this.$store.getters.userId) {
          Vue.nextTick(() => {
            this.$destroy();
          });
        }
        try {
          const values = await Promise.all(
            maps.map(async entry => {
              let filter = entry.filter;
              if (typeof filter === "function") filter = filter();
              const items = await (this.$feathers.service(entry.service) as any).find({
                query: {
                  ...(filter || {}),
                  ...(entry.sort ? { $sort: entry.sort } : {}),
                  $paginate: false,
                },
                paginate: false,
              });
              return items;
            }),
          );

          for (let i = 0; i < maps.length; i++) {
            const entry = maps[i];
            const items = values[i];
            this[`reload${capitalize(entry.field)}`](items);
          }
        } catch (e) {
          if (e.className === "timeout") {
            return;
          }
          console.error(e);
        }
        console.log("Load done");
      },
      async reloadAll() {
        this.ready = this.initCore();
      },
      ..._.assign(
        {},
        ..._.map(maps, it => ({
          [`add${capitalize(it.field)}`](this: any, item: any = {}, parent = null, createAsDangling = false) {
            const idField = it.id || "_id";
            const dict = this[`${it.field}Dict`];
            let entity =
              (item[idField] && dict[item[idField]]) ||
              (item.tempId && dict[item.tempId]) ||
              (item.offlineId && dict[item.offlineId]);

            let filter = it.filter;
            if (typeof filter === "function") filter = filter();
            if (filter && item[idField]) {
              if (!runQuery(item, filter)) {
                if (entity) {
                  entity.patch(item);
                  this[`removeEntity${capitalize(it.field)}`](entity);
                }
                return;
              }
            }
            if (entity && entity.$parent && it.resolveParent) {
              const newParent = it.resolveParent(this, item);
              if (newParent !== entity.$parent) {
                this[`removeEntity${capitalize(it.field)}`](entity);
                entity = null;
              }
            }
            if (entity) {
              if (!dict[item[idField]]) {
                dict[item[idField]] = entity;
                this.$emit(`added:${it.field}`, entity);
              }
              entity.patch(item);
              this.$emit(`update:${it.field}`, entity);
              return entity;
            }
            if (!parent && it.resolveParent) {
              parent = it.resolveParent(this, item);
            }
            if (!parent) parent = this;
            const eit: EntityTableOptBase & EntityTableOptCreater = it;
            entity = eit.cb
              ? new (eit.cb())(getOptions(parent))
              : new (MapFields(it.service, [] as any))(getOptions(parent));
            if (entity.init) {
              entity.init(item);
            } else {
              entity.item = item;
            }
            if (entity.tempId && !dict[entity.tempId]) {
              dict[entity.tempId] = entity;
            }
            if (entity.item?.offlineId && !dict[entity.item.offlineId]) {
              dict[entity.item.offlineId] = entity;
            }
            entity._tempParent = `${capitalize(it.field)}`;
            if (createAsDangling) {
              entity._dangling = true;
              return entity;
            } else {
              return this[`addEntity${capitalize(it.field)}`](entity);
            }
          },
          [`addEntity${capitalize(it.field)}`](this: any, entity: TEntity<any>) {
            const idField = it.id || "_id";
            const dict = this[`${it.field}Dict`];
            const list = this[`${it.field}s`];
            entity._tempParent = `${capitalize(it.field)}`;

            if (entity.item?.[idField]) dict[entity.item[idField]] = entity;
            if (entity.item?.tempId) dict[entity.item.tempId] = entity;
            if (entity.tempId) dict[entity.tempId] = entity;
            if (entity.item?.offlineId) dict[entity.item.offlineId] = entity;
            list.push(entity);
            this.$emit(`add:${it.field}`, entity);
            return entity;
          },
          [`remove${capitalize(it.field)}`](this: any, item: any = {}) {
            const idField = it.id || "_id";
            if (!item) return;
            const dict = this[`${it.field}Dict`];
            // const list = this[`${it.field}s`];
            let entity =
              (item[idField] && dict[item[idField]]) ||
              (item.tempId && dict[item.tempId]) ||
              (item.offlineId && dict[item.offlineId]);
            if (entity) {
              return this[`removeEntity${capitalize(it.field)}`](entity);
            }
          },
          [`removeEntity${capitalize(it.field)}`](this: any, entity: TEntity<any>) {
            const idField = it.id || "_id";
            if (!entity) return;
            const dict = this[`${it.field}Dict`];
            const list = this[`${it.field}s`];

            const eid = entity.item[idField];
            if (eid) delete dict[eid];
            const teid = entity.item.tempId;
            if (teid) delete dict[teid];
            if (entity.tempId && entity.tempId !== teid) {
              delete dict[entity.tempId];
            }
            const oeid = entity.item.offlineId;
            if (oeid) delete dict[oeid];
            const idx = list.indexOf(entity);
            if (idx !== -1) {
              list.splice(idx, 1);
            }
            this.$emit(`remove:${it.field}`, entity);
            (entity as any).$destroy();
            return entity;
          },
          async [`reload${capitalize(it.field)}`](this: any, items: TEntity<any>[]) {
            let filter = it.filter;
            if (typeof filter === "function") filter = filter();
            if (filter && this[`${it.field}s`]) {
              for (let item of this[`${it.field}s`]) {
                if (!runQuery(item, filter)) {
                  this[`removeEntity${capitalize(it.field)}`](item);
                  return;
                }
              }
            }

            const oldItems = new Set(this[`${it.field}s`] || []);
            if (!items) {
              items = await (this.$feathers.service(it.service) as any).find({
                query: {
                  ...(filter || {}),
                  $paginate: false,
                },
                paginate: false,
              });
            }

            const k = this[`add${capitalize(it.field)}`];
            for (let item of items) {
              const entity = k(item);
              if (entity && oldItems.size) {
                oldItems.delete(entity);
              }
            }
            for (let item of oldItems) {
              this[`removeEntity${capitalize(it.field)}`](item);
            }
          },
        })),
      ),
    },
  } as any as VueClass<EntityTableVue<TMaps>>;
}

export interface TableShape {
  class: string;
  component: any;
  getConf: (item: TableItem) => any;
}

export function getSplitName(n: number) {
  if (!n || n < 0 || isNaN(n)) return "";
  n = n | 0;
  n--;
  const str: string[] = [];
  while (true) {
    str.unshift(String.fromCharCode(65 + (n % 26)));
    n = Math.floor(n / 26);
    if (!n) break;
  }
  return str.join("");
}

export const shapes: Record<string, TableShape> = {
  rect: {
    class: "shape-rect",
    component: "v-rect",
    getConf: (item: TableItem) => ({
      x: -Math.max(10, item.w) / 2,
      y: -Math.max(10, item.h) / 2,
      width: Math.max(10, item.w),
      height: Math.max(10, item.h),
      cornerRadius: 6,
    }),
  },
  round: {
    class: "shape-round",
    component: "v-ellipse",
    getConf: (item: TableItem) => ({
      radius: {
        x: Math.max(10, item.w) / 2,
        y: Math.max(10, item.h) / 2,
      },
    }),
  },
};

const weekday = {
  Sunday: 7,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export function checkTime(weekdays: string[], from: string, to: string, time: Date) {
  const m = moment(time);
  const days = weekdays.map(day => weekday[day]);
  const ts = m.format("HH:mm");

  if (!days.includes(m.get("isoWeekday")) || ts < from || ts > to) {
    return false;
  }
  return true;
}
