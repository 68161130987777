
import { FindType } from "@feathers-client";
import { Component, Prop, Vue } from "@feathers-client";

@Component
export default class SummaryCategoryRow extends Vue {
  @Prop() categoryCountTotal: number;
  @Prop() categoryTotal: number;

  expanded = false;
  @Prop()
  category: FindType<"tableSessions/summaries/category">[number];
}
