
import { Component, InjectReactive, Prop, Vue } from "@feathers-client";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeLabelText,
  PrinterTemplateNodeRoot,
  PrinterTemplateNodeRow,
  PrinterTemplateNodeTemplate,
  PrinterTemplateCustomComponent,
  PrinterTemplateNodeBarcode,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeCol,
  PrinterTemplateNodeCut,
  PrinterTemplateNodeFeed,
  PrinterTemplateNodeFill,
  PrinterTemplateNodeGraph,
  PrinterTemplateNodeGraphBar,
  PrinterTemplateNodeGraphQR,
  PrinterTemplateNodeImage,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeInterpolationLabel,
  PrinterTemplateNodeLabelPage,
  PrinterTemplateNodeLabelRect,
  PrinterTemplateNodeQR,
  PrinterTemplateNodeScript,
  PrinterTemplateNodeText,
  PrinterTemplateNodeTextLabel,
} from "pos-printer-template";

@Component
export default class PrinterEditorRoot extends Vue {
  @Prop() template!: PrinterTemplateNodeBase<any>;

  @Prop() context: any;

  @Prop(Boolean)
  inBlock: boolean;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop(Boolean)
  inLoop: boolean;

  @Prop(String)
  path: string;

  @Prop(Boolean)
  svg: boolean;

  @Prop()
  blockStyle: any;

  get componentClass() {
    if (!this.parentCondition || this.condition) return undefined;
    return "condition-failed";
  }

  get component() {
    if (this.template.loop && !this.inLoop) {
      return "printer-editor-loop";
    }
    switch (this.template.constructor) {
      case PrinterTemplateNodeBlock:
      case PrinterTemplateNodeLabelText:
        return "printer-editor-block";
      case PrinterTemplateNodeRow:
        return "printer-editor-row";
      case PrinterTemplateNodeCol:
        return "printer-editor-col";
      case PrinterTemplateNodeText:
      case PrinterTemplateNodeTextLabel:
        return "printer-editor-text";
      case PrinterTemplateNodeInterpolation:
      case PrinterTemplateNodeInterpolationLabel:
        return "printer-editor-interpolation";
      case PrinterTemplateNodeQR:
        return "printer-editor-qr";
      case PrinterTemplateNodeCut:
        return "printer-editor-cut";
      case PrinterTemplateNodeFeed:
        return "printer-editor-feed";
      case PrinterTemplateNodeFill:
        return "printer-editor-fill";
      case PrinterTemplateNodeScript:
        return "printer-editor-script";
      case PrinterTemplateNodeImage:
        return "printer-editor-image";
      case PrinterTemplateCustomComponent:
        return "printer-editor-custom-component";
      case PrinterTemplateNodeGraph:
        return "printer-editor-graph";
      case PrinterTemplateNodeGraphQR:
        return "printer-editor-qr";
      case PrinterTemplateNodeBarcode:
      case PrinterTemplateNodeGraphBar:
        return "printer-editor-barcode";

      case PrinterTemplateNodeLabelPage:
        return "printer-editor-page";
      case PrinterTemplateNodeLabelRect:
        return "printer-editor-rect";

      case PrinterTemplateNodeRoot:
      case PrinterTemplateNodeTemplate:
        return "printer-editor-container";
    }
    if (this.template instanceof PrinterTemplateNodeTemplate) {
      return "printer-editor-container";
    }
  }
}
