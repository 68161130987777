
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeQR, compile, compileLabel } from "pos-printer-template";
import { imageSync } from "qr-image";

@Component
export default class PrinterEditorQR extends Vue {
  @Prop() template!: PrinterTemplateNodeQR;

  @Prop() context: any;

  @Prop()
  svg: boolean;

  @Prop()
  blockStyle: any;

  get content() {
    if (this.context) {
      return this.template.getTextValue(this.context);
    }
    return "Hello World";
  }

  get x() {
    return +(this.template.props["x"]?.eval?.(this.context) ?? "0");
  }

  get y() {
    return +(this.template.props["y"]?.eval?.(this.context) ?? "0");
  }

  get w() {
    return +(this.template.props["w"]?.eval?.(this.context) ?? "0");
  }

  get h() {
    return +(this.template.props["h"]?.eval?.(this.context) ?? "0");
  }

  get s() {
    return +(this.template.props["s"]?.eval?.(this.context) ?? "0");
  }

  get v() {
    return +(this.template.props["v"]?.eval?.(this.context) ?? "0");
  }

  get e() {
    return this.template.props["e"]?.eval?.(this.context) ?? "M";
  }

  get qrcode() {
    return "data:image/svg+xml;base64," + Buffer.from(imageSync(this.content, { type: "svg" })).toString("base64");
  }

  get style() {
    return {
      ...this.blockStyle,
      ...(this.align !== undefined
        ? {
            alignSelf: {
              left: "flex-start",
              center: "center",
              right: "flex-end",
            }[this.align],
          }
        : {}),
    };
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }
}
