

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";


interface CartItem {
    product: any
    shop: any
    price? : number
    quantity: number
    remarks? : string
}

@Component
export default class Page extends Vue {

    @Prop()
    product : any;

    @Prop()
    shop : any;

    @Prop()
    cart : CartItem[];

    @Prop({ type: Boolean })
    allowEdit : boolean
    @Prop({ type: Boolean })
    remarks : boolean
    @Prop({ type: Boolean })
    cross : boolean
    @Prop({ type: Boolean })
    clone : boolean
    @Prop()
    item : CartItem

    get price() {
        return this.cartItem && this.cartItem.price || this.product.price;
    }

    get quantity() {
        if(this.cartItem) 
            return this.cartItem.quantity;
        return 0;
    }

    set quantity(v : number | null) {
        if(v === null) {
            const idx = this.cart.findIndex(it => it.product._id === this.product._id);
            idx !== -1 && this.cart.splice(idx, 1);
            this.$emit('changed');
        }
        else if(this.cartItem) {
            this.cartItem.quantity = v;
            this.$emit('changed');
        } else {
            this.cart.push({
                product: this.product,
                quantity: v,
                shop: this.shop,
            })
            this.$emit('changed');
        }
    }

    cloneItem() {
        this.cart.push({
            ...this.cartItem
        })
        this.$emit('changed');
    }

    @Watch('quantity')
    onQuantity() {
        if(this.quantity < 0) {
            Vue.nextTick(() => this.quantity = 0);
        }
    }

    get cartItem() {
        return this.item || this.cart.find(it => it.product._id === this.product._id);
    }

    @Prop({ type: Boolean })
    remove : boolean
}

