import { PrintSequence, TextAlign } from "../index";
import { RawLine, TextLine } from "../rawLine";
import { PrintTable } from "../table";
import { ThermalCanvasJob, ThermalCanvasPrintJob, ThermalTable, ThermalText } from "./job";
import type { WrappedContext } from "../../common";

export class CanvasSequence extends PrintSequence {
  constructor(context: WrappedContext, printer: any) {
    super(context, printer);
    if (printer?.conf?.opts?.lineWidth !== printer?.conf?.opts?.rlineWidth && printer?.conf?.opts?.rlineWidth) {
      this.lineWidth = this.normalLineWidth = printer?.conf?.opts?.rlineWidth;
    }
  }

  job: ThermalCanvasJob = {
    cmds: [],
  };

  getText(text: string) {
    return Buffer.from(text, "utf-8");
  }

  imageAsBase64 = false;

  cachedLines: ThermalText[] = [];

  rawText(array: Buffer | number[]) {
    const text = Buffer.from(array).toString();
    this.cachedLines.push({
      type: "text",
      text,
      align:
        this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
      bold: this.isBold,
      italic: this.isItalic,
      color: this.currentColor,
      fontX: this.fontWidthScale,
      fontY: this.fontHeightScale,
      scaleX: this.currentWidth,
      scaleY: this.currentHeight,
      pxSize: this.pxSize,
      yScale: this.yScale,
    });
    return this;
  }

  flushLine() {
    if (this.cachedLines.length) {
      this.job.cmds.push({
        type: "multiText",
        parts: this.cachedLines,
      });
      this.cachedLines = [];
    }
    return this;
  }

  async printImage(url: string, width: number, hiRes?: boolean | number) {
    hiRes = this.fixLineImageResolution(hiRes);

    const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    const pxPerChar = this.useBitmapImage ? (hHi ? 2 : 1) : hHi ? 12 : 6;
    const pxPerLine = this.useBitmapImage ? (vHi ? 2 : 1) : vHi ? 24 : 8;
    const pxRatio = (pxPerChar / pxPerLine) * (this.useBitmapImage ? 1 : 2) * (this.fontWidth / this.fontHeight);

    const img = await this.convertImage(url, Math.ceil(width / 8) * 8, {
      pxPerLine,
      pxRatio,
    });
    if (!img) {
      console.warn(`Failed to load image: ${url}`);
      return this;
    }
    this.job.cmds.push({
      type: "image",
      w: img.width,
      h: img.height,
      ...(this.imageAsBase64 ? { base64: Buffer.from(img.buffer).toString("base64") } : { data: img.buffer }),
      align:
        this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
      hiRes,
    });
    return this;
  }

  async printImageData(buffer: Uint8ClampedArray, width: number, height: number, hiRes?: boolean): Promise<this> {
    this.job.cmds.push({
      type: "image",
      w: width,
      h: height,
      hiRes,
      ...(this.imageAsBase64 ? { base64: Buffer.from(buffer).toString("base64") } : { data: buffer }),
      align:
        this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
    });
    return this;
  }

  printQR(url: string, numChars: number, w = 6) {
    this.job.cmds.push({
      type: "qrcode",
      text: url,
      size: numChars,
      scale: w,
      align:
        this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
    });
    return this;
  }

  feed(n: number) {
    this.job.cmds.push({ type: "feed", offset: n });
    return this;
  }

  beep(times?: number, duration?: number) {
    this.job.cmds.push({ type: "beep", times, duration });
    return this;
  }

  cut() {
    this.job.cmds.push({ type: "cut" });
    return this;
  }
  
  cashBox(which?: number, time?: number): this {
    this.job.cmds.push({ type: "cashBox", which, time });
    return this;
  }

  currentAlign = TextAlign.Left;
  currentWidth = 0;
  currentHeight = 0;
  isBold = false;
  isItalic = false;
  currentColor: number = 0;
  yScale = 1;

  get fontWidthScale() {
    return (this.currentWidth + 2) / 2;
  }

  get fontHeightScale() {
    return (this.currentHeight + 2) / 2;
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  align(n: TextAlign) {
    this.currentAlign = n;
    return this;
  }

  bold(b) {
    this.isBold = b ?? false;
    return this;
  }

  italic(b) {
    this.isItalic = b ?? false;
    return this;
  }

  fontSize(w: number = 0, h: number = 0) {
    this.currentWidth = w;
    this.currentHeight = h;
    this.pxSize = (w || 0) * 12 + 24;
    this.yScale = ((h || 0) + 1) / ((w || 0) + 1);
    return this;
  }

  color(n: number): this {
    this.currentColor = n;
    return this;
  }

  fill(c?: string, n?: number) {
    // this.center()

    c = c || "-";
    n = n || this.currentLineWidth;
    const cb = Buffer.from(new Uint8Array(Buffer.from(c)));
    const repeat = (n / cb.length) | 0;
    this.tableWithAlign(0, "center", c.repeat(repeat));
    const last = this.job.cmds[this.job.cmds.length - 1];
    if (last?.type === "table") {
      last.fill = c;
    }
    return this;
  }

  text(text: string) {
    this.tableWithAlign(
      0,
      this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
      text,
    );
    return this;
  }

  printTable(table: PrintTable) {
    let maxLine = table.fit(this.currentLineWidth);
    const result: ThermalTable = {
      type: "table",
      cols: [],
      fontX: this.fontWidthScale,
      fontY: this.fontHeightScale,
      scaleX: this.currentWidth,
      scaleY: this.currentHeight,
      bold: this.isBold,
      italic: this.isItalic,
      color: this.currentColor,
      lines: [],
      colPadding: table.colPadding,
      fontSize: this.pxSize,
      yScale: this.yScale,
    };
    let accum = 0;

    for (let j = 0; j < table.columns.length; j++) {
      const column = table.columns[j];

      result.cols.push({
        x: accum,
        w: column.size,
        align: column.align as any,
        text: column.content
          .map(line => {
            if (line instanceof RawLine) {
              return Buffer.from(line.buf);
            } else if (line instanceof TextLine) {
              return line.text;
            }
          })
          .join("\n"),
        bold: column.bold,
        color: column.color,
        italic: column.italic,
        inverted: column.inverted,
        fontFamily: column.fontFamily,
        fontSize: column.fontSize,
        yScale: column.yScale,
      });
      accum += column.size;
    }
    if (this.currentWidth) {
      maxLine = table.fit(Math.floor(this.lineWidth / (this.currentWidth + 1)));
    }
    const lines: string[] = [];
    for (let i = 0; i < maxLine; i++) {
      lines.push(
        table.columns
          .map(it => {
            const line = it.lines[i];
            return line.toString();
          })
          .join(""),
      );
    }
    result.lines = lines;
    this.job.cmds.push(result);

    return this;
  }

  getJob(name?: string, id?: string) {
    return new ThermalCanvasPrintJob(
      name,
      this.job,
      id,
      {
        type: "cmd",
      },
      this.imageAsBase64,
    );
  }

  getLine(): Buffer | number[] {
    return [0x0a];
  }
}
