import { render, staticRenderFns } from "./CallingOutSetting.vue?vue&type=template&id=7534d303&scoped=true"
import script from "./CallingOutSetting.vue?vue&type=script&lang=ts"
export * from "./CallingOutSetting.vue?vue&type=script&lang=ts"
import style0 from "./CallingOutSetting.vue?vue&type=style&index=0&id=7534d303&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7534d303",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu,VSlideYTransition,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7534d303')) {
      api.createRecord('7534d303', component.options)
    } else {
      api.reload('7534d303', component.options)
    }
    module.hot.accept("./CallingOutSetting.vue?vue&type=template&id=7534d303&scoped=true", function () {
      api.rerender('7534d303', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/queueing/CallingOutSetting.vue"
export default component.exports