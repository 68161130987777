import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { getOptions } from "@feathers-client/util";
import { getVersion } from "pos-printer/nativeIntegrations";
import { ns } from "pos-printer/messageQueue";
import { getStorage } from "@feathers-client/storage";
import { supported, clearCache } from "pos-printer/utils/app";
import { getOfflineInfo, installOffline, supportedOffline } from "pos-printer/utils/app";
import { FindType } from "@feathers-client";

@Component
export class NetworkHelper extends Vue {
  monline = false;
  ping: number | null = null;
  lastDisconnect: Date = null;
  dismiss = false;
  asking = false;
  focused = true;
  lastBlur: number = Date.now();
  autoConfirmUpgrade = false;
  offlineMode = false;

  offlineOrders = 0;
  offlineStatus: "none" | "success" | "error" | "pending" | "working" = "none";
  currentBuildInfo: any = null;
  cacheVersion: string = null;
  appVersion: string = null;
  lastFetchData: number = 0;
  slowNetwork = false;

  networkInited: Promise<void>;

  getVersionFromBuildInfo(buildInfo: string) {
    if (!buildInfo) return 0;
    try {
      let json = typeof buildInfo === "string" ? JSON.parse(buildInfo) : buildInfo;
      return json.CI_PIPELINE_ID ? +json.CI_PIPELINE_ID : 0;
    } catch (e) {
      console.warn(e);
    }
  }

  get offlineIcon() {
    return this.offlineStatus === "none"
      ? ""
      : "$upload" + this.offlineStatus[0].toUpperCase() + this.offlineStatus.substring(1);
  }

  async created() {
    if (process.server) return;
    if (process.client && /BOXSPresent/.test(navigator.userAgent)) {
      return;
    }
    this.networkInited = this.initInner();
  }

  async initInner() {
    this.cacheVersion = localStorage["cacheVersion"] || null;
    this.lastFetchData = +(localStorage["lastFetchData"] || 0);
    if (isNaN(this.lastFetchData)) {
      this.lastFetchData = 0;
    }

    this.monline = Boolean(window.navigator.onLine);
    window.addEventListener("offline", event => (this.monline = event.type === "online"));
    window.addEventListener("online", event => (this.monline = event.type === "online"));

    document.addEventListener("visibilitychange", this.handleVisibilityChange, false);

    (this.$feathers as any).on("pong", p => {
      this.ping = p;
      if (p !== null) {
        const slow = p > 200;
        if (this.slowNetwork !== slow) {
          this.slowNetwork = slow;
        }
      }
    });
    (this.$feathers as any).on("disconnected", p => {
      this.lastDisconnect = new Date();
    });
    (this.$feathers as any).on("connected", this.checkUpdate);
    setTimeout(this.checkUpdate, 5000);
    setTimeout(this.bootVersionCheck, 10000);
    if (await supportedOffline()) {
      this.appVersion = (await getOfflineInfo(location.hostname))?.BUILD_INFO ?? "{}";
    }
    await Vue.nextTick();
    this.$offline.init(this.$root);
  }

  async checkUpdate() {
    if (this.$offline?.offline) {
      return false;
    }
    try {
      let resp: FindType<"health">;
      try {
        resp = await this.$feathers.service("health").find({});
        if (resp.BUILD_INFO) {
          try {
            this.currentBuildInfo = JSON.parse(resp.BUILD_INFO);
          } catch (e) {
            this.currentBuildInfo = null;
          }
        }
      } catch (e) {
        console.warn(e);
      }

      const maxBuild = Math.max(
        this.getVersionFromBuildInfo(resp?.BUILD_INFO),
        this.getVersionFromBuildInfo(this.cacheVersion),
        this.getVersionFromBuildInfo(this.appVersion),
      );

      if (maxBuild && localStorage["maxBuild"] && maxBuild < +localStorage["maxBuild"]) {
        await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: this.$t("basic.rollbackPrevention"),
            confirm: this.$t("basic.update"),
            hasCancel: false,
          },
          {
            maxWidth: "400px",
          },
        );
        try {
          await this.clearCache();
        } catch (e) {
          console.warn(e);
        }
        if (this.$offline) {
          try {
            this.$offline.cleanData();
          } catch (e) {
            console.warn(e);
          }
        }
        delete localStorage["maxBuild"];
        await this.fullReload();
        return;
      } else if (maxBuild) {
        localStorage["maxBuild"] = maxBuild;
      }

      if (
        (resp && resp.BUILD_INFO && resp.BUILD_INFO !== process.env.BUILD_INFO) ||
        (this.$config.BUILD_INFO && process.env.BUILD_INFO !== this.$config.BUILD_INFO)
      ) {
        if (!this.focused) {
          await new Promise<void>(resolve => this.$once("focused", resolve));
        }
        if (this.autoConfirmUpgrade) {
          try {
            await this.clearCache();
          } catch (e) {
            console.warn(e);
          }
          window.location.reload();
        }
        if (this.asking || this.dismiss) return true;
        try {
          this.asking = true;
          const c = await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("basic.updateDetected"),
              confirm: this.$t("basic.update"),
            },
            {
              maxWidth: "400px",
            },
          );
          if (c === false) {
            this.dismiss = true;
          } else if (c === true) {
            await this.clearCache();
            await this.fullReload();
          }
        } finally {
          this.asking = false;
        }
        return true;
      } else {
        this.autoConfirmUpgrade = false;
        return false;
      }
    } catch (e) {
      console.warn("Fail to do version check");
    }
  }

  async fullReload() {
    window.location.reload();
  }

  async clearCache() {
    try {
      const array = await supported();
      if (Array.isArray(array) && array.includes("clearCache")) {
        await clearCache();
      }
    } catch (e) {
      console.warn(e);
    }
    try {
      const storage = getStorage();
      await storage.cleanFiles();
    } catch (e) {
      console.warn(e);
    }
    delete localStorage["cachedLocales"];
    try {
      const cacheNames = await caches.keys();
      for (let cache of cacheNames) {
        await caches.delete(cache);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  get online() {
    return this.monline;
  }

  get connected() {
    return this.$store.state.connected;
  }

  get offlineActivated() {
    return this.offlineMode;
  }

  handleVisibilityChange() {
    const focused = document.visibilityState === "visible";
    if (this.focused !== focused) {
      this.focused = focused;
      if (!focused) {
        this.lastBlur = Date.now();
      } else {
        if (Date.now() - this.lastBlur > 60 * 60 * 1000) {
          // if suspend for more then one hour, does automatic upgrade
          this.autoConfirmUpgrade = true;
        } else {
          this.autoConfirmUpgrade = false;
        }
        this.$emit("focused");
      }
    }
  }

  updating = false;
  totalFiles = 0;
  currentFiles = 0;

  async bootVersionCheck() {
    // refresh cache every 30 days
    if (
      (this.cacheVersion && process.env.BUILD_INFO !== this.cacheVersion) ||
      (this.appVersion && process.env.BUILD_INFO !== this.appVersion) ||
      (Date.now() - this.lastFetchData > 30 * 24 * 60 * 60 * 1000 && this.cacheVersion)
    ) {
      await this.update();
    }
  }

  async update() {
    try {
      this.updating = true;
      this.totalFiles = 0;
      this.currentFiles = 0;

      if (await supportedOffline()) {
        await installOffline(location.hostname);
      }

      const resp = await fetch("/_nuxt/pwaManifest.json?" + Math.random());
      if (!resp.ok) {
        throw new Error(`Failed to load metadata: ${resp.statusText}`);
      }
      const j: {
        files: string[];
        zip?: string;
        BUILD_INFO?: string;
      } = await resp.json();

      this.totalFiles = j.files.length;
      const queue = j.files.slice();
      let cur = 0;
      let lastUpdate = Date.now();
      await Promise.all(
        new Array(4).fill(null).map(async i => {
          while (queue.length) {
            const file = queue.pop();
            await fetch("/_nuxt/" + file);
            cur++;
            if (Date.now() - lastUpdate >= 500) {
              lastUpdate = Date.now();
              this.currentFiles = cur;
            }
          }
        }),
      );
      this.currentFiles = cur;

      if (j.BUILD_INFO) {
        this.cacheVersion = localStorage["cacheVersion"] = j.BUILD_INFO;
      }

      if (await supportedOffline()) {
        this.appVersion = (await getOfflineInfo(location.hostname))?.BUILD_INFO ?? "{}";
      }

      this.lastFetchData = Date.now();
      localStorage["lastFetchData"] = this.lastFetchData;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.updating = false;
    }
  }
}

Object.defineProperty(Vue.prototype, "$network", {
  get(this: Vue) {
    return (
      (<any>this.$root.$options).$network ||
      ((<any>this.$root.$options).$network = new NetworkHelper(getOptions(this.$root)))
    );
  },
});

declare module "vue/types/vue" {
  export interface Vue {
    $network: NetworkHelper;
  }
}
