import en from "vuetify/lib/locale/en";
import cht from "vuetify/lib/locale/zh-Hant";
import chs from "vuetify/lib/locale/zh-Hans";

const iconsCtx = require.context("!!vue-svg-loader!~/assets/images/icons", true, /\.svg$/);
const iconsBaseCtx = require.context("!!vue-svg-loader!~/dep/feathers-client/icons", true, /\.svg$/);

const icons = {
  ...Object.fromEntries(
    iconsBaseCtx.keys().map(k => [
      k.slice(2).split(".")[0],
      {
        component: iconsBaseCtx(k),
      },
    ]),
  ),
  ...Object.fromEntries(
    iconsCtx.keys().map(k => [
      k.slice(2).split(".")[0],
      {
        component: iconsCtx(k),
      },
    ]),
  ),
};

export default {
  breakpoint: {
    thresholds: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
  },
  lang: {
    locales: {
      en,
      "zh-hk": cht,
      "zh-cn": chs,
    },
  },
  icons: {
    iconfont: "md",
    values: icons,
  },
};
