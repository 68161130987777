
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";
import type { ProductLine } from "~/plugins/table/session";
import type { LangArrType } from "@feathers-client/i18n";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { CartItem } from "@common/table/cart";
import type { ProductType } from "~/plugins/shop";
import { StockLevel, getProductStockLevel, getStockLevel } from "@common/table/util";
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";

@Component({})
export default class MobilePicker extends Vue {
  @PropSync("session")
  sessionSync;

  @Prop()
  products;

  @Prop()
  productList;

  @Prop()
  subCats;

  @Prop()
  showingDialog: boolean;

  @Prop()
  smallestMobile: boolean;

  @PropSync("selectedCat")
  selectedCatSync: FindType<"categories">;

  @PropSync("selectedSubCat")
  selectedSubCatSync: FindType<"subCategories">;

  checkID = checkID;

  get readonly() {
    return this.sessionSync?.postEditing && this.sessionSync?.status !== "ongoing";
  }
}
