
import { Component, Prop, Vue, Watch, mixins, PropSync } from "nuxt-property-decorator";
import { TableItem, TableSessionRef, TableView } from "~/plugins/table";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import type { Group } from "konva/lib/Group";
import { computePoint } from "~/plugins/table/pointBuilder";
import iconFont from "~/plugins/iconfont";
import moment from "moment";

@Component
export default class TableItemUi extends Vue {
  get editing() {
    return this.placeholder || this.item.view.editing;
  }

  get addButtonConfig() {
    return {
      x: -20,
      y: -20,
      width: 40,
      height: 40,
      fill: this.$config.colors.orange200,
      cornerRadius: 8,
      listening: true,
    };
  }

  get addButtonIconConfig() {
    return {
      data: "M19.875 11C19.3227 11 18.875 11.4477 18.875 12V18.875H12C11.4477 18.875 11 19.3227 11 19.875V20.125C11 20.6773 11.4477 21.125 12 21.125H18.875V28C18.875 28.5523 19.3227 29 19.875 29H20.125C20.6773 29 21.125 28.5523 21.125 28V21.125H28C28.5523 21.125 29 20.6773 29 20.125V19.875C29 19.3227 28.5523 18.875 28 18.875H21.125V12C21.125 11.4477 20.6773 11 20.125 11H19.875Z",
      x: this.addButtonConfig.x,
      y: this.addButtonConfig.y,
      fill: "white",
      listening: true,
    };
  }

  get configCircle() {
    const conf = this.item.shapeOpts.getConf(this.item);

    return {
      ...conf,
      stroke: this.item.dragTo ? "red" : this.item.selected ? "#EA9944" : undefined,
      perfectDrawEnabled: false,
      fill: this.item.tableColor,
    };
  }

  get linkedConfigBackdrop() {
    return {
      x: -40,
      y: 0,
      width: 85,
      height: 30,
      fill: "black",
      cornerRadius: 15,
      opacity: 0.5,
    };
  }

  get linkedConfigLabel() {
    return {
      x: this.linkedConfigBackdrop.x + 10,
      y: this.linkedConfigBackdrop.y + 10,
      height: 20,
      fill: "white",
      cornerRadius: 20,
      text:
        iconFont.linked +
        " " +
        this.item.sessions[0]?.session.tableRefs[0]?.table.name +
        "(" +
        this.item.sessions[0]?.session.sessionName +
        ")",
      fontsize: 20,
      fontFamily: "iconfont," + this.$config.fontFamily,
    };
  }

  get available() {
    return !this.item.sessions[0];
  }

  get isRound() {
    return _.has(this.item.shapeOpts.getConf(this.item), "radius");
  }

  get configLabel() {
    const xOffsetAdjust = this.isRound ? Math.max(this.item.w / (this.item.name?.length > 3 ? 6 : 5), 10) : 10;
    const yOffsetAdjust = this.isRound ? Math.max(this.item.h / 7, 12) : 12;
    return {
      x: -this.item.w / 2 + xOffsetAdjust,
      y: -this.item.h / 2 + yOffsetAdjust,
      width: this.item.w,
      height: 25,
      align: "left",
      verticalAlign: "top",
      text: this.item.name,
      fontFamily: this.$config.fontFamily,
      fontSize: 20,
      fontStyle: "bold",
      fill: "white",
      perfectDrawEnabled: false,
      listening: false,
    };
  }

  get configChairOccupation() {
    const small = (this.isRound && this.item.w < 110) || this.item.w < 90;
    const xOffsetAdjust = small ? (this.isRound ? 15 : 5) : this.isRound ? this.item.w / 4.5 : 12;
    const yOffsetAdjust = this.isRound ? Math.max(this.item.h / 7, 12) : 12;
    return {
      x: -xOffsetAdjust,
      y: -this.item.h / 2 + yOffsetAdjust,
      width: this.item.w / 2,
      height: 15,
      align: "right",
      verticalAlign: "top",
      text: `${this.item.usedCapacity}/${this.item.capacity}`,
      fontFamily: this.$config.fontFamily,
      fontSize: 15,
      fill: "white",
      perfectDrawEnabled: false,
      listening: false,
    };
  }

  get hasAlert() {
    return _.some(this.item?.sessions, s => s.session?.sessionTimeStatus === "due");
  }

  get configAlert() {
    const point = computePoint(this.item, "topRight");
    return {
      id: "alert-badge",
      w: 20,
      h: 20,
      x: point.x - this.item.hw,
      y: point.y - this.item.hh,
      offset: {
        x: this.isRound ? this.item.w / 6 : 10,
        y: this.isRound ? this.item.h / 5 : 10,
      },
      cache: true,
      perfectDrawEnabled: false,
      listening: false,
    };
  }

  chairGroupConfig = {
    listening: false,
  };

  @Prop()
  item: TableItem;

  @Prop(Boolean)
  placeholder: boolean;

  @Prop()
  index: number;

  @Prop()
  isMultiSelect: boolean;

  @Prop()
  tableView: TableView;

  @Prop()
  selectMergeMenu: boolean;

  @Prop()
  selectResizeMenu: boolean;

  @Prop()
  assigningTable: boolean;

  get configLayer() {
    return {
      x: this.item.x, // + this.item.w / 2,
      y: this.item.y, // + this.item.h / 2,
      scaleX: 1,
      scaleY: 1,
    };
  }

  get isLinked() {
    if (this.item.sessions.length > 0 && this.item.sessions[0]?.session?.tableRefs[0].table != this.item) {
      return true;
    }
  }

  mounted() {
    // (<any>this.$refs.group).getNode().cache();
    if (this.item.selected) {
      this.onSelected();
    }
  }

  @Watch("item.selected")
  onSelected() {
    const group: Group = (this.$refs.group as any).getNode();
    if (this.item.selected) {
      group.moveToTop();
    } else if (typeof this.index === "number") {
      group.setZIndex(this.index);
    }
  }

  @Watch("item.dragTo")
  onDragTo() {
    const group: Group = (this.$refs.group as any).getNode();
    if (this.item.dragTo) {
      group.to({
        scaleX: 1.2,
        scaleY: 1.2,
        duration: 0.2,
      });
    } else {
      group.to({
        scaleX: 1,
        scaleY: 1,
        duration: 0.2,
      });
    }
  }

  touchingSameRef = false;
  touchStart = 0;

  onElemDown(e) {
    this.touchStart = Date.now();
    if (this.placeholder) return;
    this.touchingSameRef = false;
    const evt = e.evt;
    if (evt && window.TouchEvent && evt instanceof TouchEvent) {
      if (evt.touches.length > 1) return;
    }
    if (this.item.view.editing) {
      if (this.item.selected) {
        //hide resize menu when dragging
        this.$emit("resizeTableTransform", e.currentTarget, false);
        return;
      }
      e.cancelBubble = true;
      //open select resize menu
      this.$emit("resizeTableTransform", e.currentTarget);
      this.$emit("beginTransform", e.currentTarget, this.item);
    } else {
      // second touch on the same table
      e.cancelBubble = true;
      const ref: TableSessionRef = e.currentSession;
      if (this.item.selected && !ref) {
        if (this.item.sessions.length == 0 && !this.editing && !this.isMultiSelect && !this.assigningTable) {
          this.$emit("createSession");
        } else if (!this.isMultiSelect && !this.isLinked) {
          this.$emit("updateMenu", e.currentTarget);
        }
        this.item.deselect();
        return;
      }

      // selected session
      if (ref) {
        if (ref.session.selected && ref.session.selectedRef === ref) {
          this.touchingSameRef = true;
          return;
        } else {
          ref.session.selected = true;
          ref.session.selectedRef = ref;
        }
      } else if (this.item.view?.selectedSession) {
        this.item.view.selectedSession.selected = false;
      }

      this.item.select();
      if (!this.isMultiSelect && !this.isLinked && this.item.sessions.length > 0) {
        //select the first latest session by default if no session is selected
        if (!this.tableView.selectedSession) {
          const sessions = this.item?.sessions; //_.orderBy(this.item?.sessions, s => s.session.item.startTime, "desc");
          sessions[0].session.selected = true;
          this.item.sessions[0].session.selectedRef = this.item.sessions[0].session.tableRefs[0];
        }
      }
    }
  }

  cancelClick(e) {
    this.touchingSameRef = false;
  }

  onElemTouchUp(e) {
    if (!this.editing) {
      if (
        this.item.sessions.length > 0 &&
        this.item.sessions[0]?.session.tableRefs[0].table._id == this.item._id &&
        !this.selectMergeMenu &&
        !this.selectResizeMenu
      ) {
        // open session expand menu
        this.$emit("updateMenu", e.currentTarget);
      } else if (this.item.sessions.length == 0 && this.touchStart && Date.now() - this.touchStart > 1000) {
        // open merge table menu if touching the empty table for 1 seconds only
        this.touchStart = 0;
        this.$emit("mergeTableTransform", e.currentTarget, this.item, true);
      } else if (!this.isMultiSelect) {
        // hide all menus if it is not multi select mode
        this.$emit("hideMenu");
      }
    } else if (this.item) {
      //open resize menu when editing after move table
      this.$emit("resizeTableTransform", e.currentTarget);
    }
  }

  onTransformend(e) {
    this.item.x = e.target.attrs.x; // - this.item.w * e.target.attrs.scaleX / 2;
    this.item.y = e.target.attrs.y; // - this.item.h * e.target.attrs.scaleY / 2;
    this.item.w *= e.target.attrs.scaleX;
    this.item.h *= e.target.attrs.scaleY;
    e.target.scaleX(1);
    e.target.scaleY(1);
    e.target.rotation(0);
    this.$emit("beginTransform", null);
    Vue.nextTick(() => {
      this.$emit("beginTransform", e.target);
    });
  }
}
