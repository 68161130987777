
import { Vue, Component, Prop } from "@feathers-client";

@Component
export default class TakeawayList extends Vue {
  @Prop()
  takeawayOrders: any;

  editOrder(session: any) {
    this.$emit("editOrder", session);
  }

  beforeCreate() {}

  created() {}

  beforeMount() {}

  mounted() {}

  beforeUpdate() {}

  updated() {}

  beforeUnmount() {}

  unmounted() {}
}
