export * from "./bitmap";
export * from "./vueWrapper";

// TODO: https://www.googleapis.com/webfonts/v1/webfonts?key=
export interface FontItem {
  type: "local" | "web";
  name: string;
  url: string;
  variant: string;
  weight: string;
  style: string;
  subsets: string[];
}

export interface FontSetting {
  lists: string[];
}

export type FontSettingType = "normal" | "bold" | "italic" | "boldItalic";

export interface PrinterSettings {
  fonts?: FontItem[];
  defaultFonts?: {
    [key in FontSettingType]?: FontSetting;
  };
  subsets?: string[];
}

export interface FontInfo {
  settings: {
    [key in FontSettingType]: FontSetting;
  };
  fonts: Record<string, FontItem>;
}
