
import { Component, Prop, Vue } from "@feathers-client";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeRoot,
  compile,
  compileLabel,
} from "pos-printer-template";

@Component
export default class PrinterEditorContainer extends Vue {
  @Prop() template!: PrinterTemplateNodeTemplate<any>;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  @Prop(Boolean)
  svg: boolean;

  isKnownName(name: any) {
    return [PrinterTemplateNodeRoot, PrinterTemplateNodeTemplate].includes(name);
  }

  get children() {
    const children: {
      condition: boolean;
      item: PrinterTemplateNodeBase<any>;
    }[] = [];

    let lastCondFailed = true;
    for (const item of this.template.children) {
      let curCondition = true;

      if (item.condNeedFailed && !lastCondFailed) {
        curCondition = false;
      } else if (item.cond) {
        if (!item.cond.eval(this.context)) {
          curCondition = false;
        }
      }

      lastCondFailed = !curCondition;
      children.push({ condition: curCondition, item });
    }

    return children;
  }
}
