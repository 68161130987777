
import { ProductLine } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { OrderGroupChunk } from "~/plugins/table/kds";
import { StatusType } from "~/pages/table/kds.vue";

@Component
export default class TableKDSOrderGroup extends Vue {
  @Prop()
  chunk: OrderGroupChunk;

  @Prop()
  statusType: StatusType;

  showProductName(product: ProductLine) {
    if (this.$shop.localOptions.kdsShowShortnameDisable) {
      return this.$td(product.name);
    } else {
      return this.$td(product.shortName) ?? this.$td(product.name);
    }
  }

  get group() {
    return this.chunk.group;
  }

  get groupTime() {
    return this.$moment(this.group.date).format("HH:mm");
  }

  get headerColor() {
    if (this.statusType === "Hold") {
      return "bg-purple400";
    }
    if (this.counter !== -1) {
      if (this.statusType === "Done") {
        return "bg-green300";
      }
      return "bg-blue100";
    }
    switch (this.group.warnStatus) {
      case "urgent":
        return "bg-red400";
      case "warn":
        return "bg-orange500";
    }
    return "bg-grey400";
  }

  @Ref()
  container: HTMLElement;
  @Ref()
  scroller: HTMLElement;

  observer: ResizeObserver;

  updateKdsStock = true;

  mounted() {
    if (typeof ResizeObserver !== "undefined") {
      this.observer = new ResizeObserver(this.onResize);
      this.observer.observe(this.container);
      this.observer.observe(this.scroller);
    }
  }

  beforeDestroy() {
    this.stopDoneTimer(true);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onResize(entries: ResizeObserverEntry[], observer: ResizeObserver) {
    if (!this.scroller) return;
    if (this.scroller.scrollHeight > this.scroller.clientHeight && this.chunk.items.length > 1) {
      // divide the chunk if cannot show on one page
      const containerBounds = this.scroller.getBoundingClientRect();
      for (let i = 1; i < this.container.children.length && i < this.chunk.items.length; i++) {
        const child = this.container.children[i];
        const bounds = child.getBoundingClientRect();
        if (bounds.bottom > containerBounds.bottom) {
          // console.log('break at', i);
          this.group.splitAt(this.chunk, i);
          break;
        }
      }
    }
  }

  async toggleItem(item: ProductLine) {
    if (item.status === "cancel") {
      return;
    }
    let updateKdsStock = true;
    if (this.statusType === "Pending") {
      const newStatus = item.kdsStatus === "pending" ? "partialDone" : "pending";
      if (newStatus === "pending" && item.kdsStockUsed) {
        const resp = await this.$openDialog(
          import("./stockCancelDialog.vue"),
          {},
          {
            maxWidth: "max(50vw,500px)",
          },
        );
        if (typeof resp !== "boolean") return;
        updateKdsStock = resp;
      }
      item.kdsStatus = newStatus;
      await this.$feathers.service("tableSessions/order").patch(null, {
        session: this.group.session._id,
        products: [
          {
            ...item,
            kdsStatus: item.kdsStatus,
          },
        ],
        staff: this.$shop.staffId,
        updateKdsStock,
      });
      const pendingItem = this.group.items.find(it => it.kdsStatus === "pending" && it.status !== "cancel");
      if (!pendingItem) {
        this.startDoneTimer();
      } else {
        this.stopDoneTimer();
      }
      if (this.$tableManager.kdsHideDone) {
        this.group.resetView();
      }
    } else if (this.statusType == "Done") {
      const newStatus = item.kdsStatus === "done" ? "pending" : "done";
      if (newStatus === "pending" && item.kdsStockUsed) {
        const resp = await this.$openDialog(
          import("./stockCancelDialog.vue"),
          {},
          {
            maxWidth: "max(50vw,500px)",
          },
        );
        if (typeof resp !== "boolean") return;
        updateKdsStock = resp;
      }
      item.kdsStatus = newStatus;
      await this.$feathers.service("tableSessions/order").patch(null, {
        session: this.group.session._id,
        products: [
          {
            ...item,
            kdsStatus: item.kdsStatus,
          },
        ],
        staff: this.$shop.staffId,
        updateKdsStock,
      });
    } else if (this.statusType === "Hold") {
      item.kdsStatus = "pending";
      if (item.status === "hold") {
        item.status = "init";
      }
      await this.$feathers.service("tableSessions/order").patch(null, {
        session: this.group.session._id,
        products: [
          {
            ...item,
            kdsStatus: item.kdsStatus,
          },
        ],
        staff: this.$shop.staffId,
      });
    }
  }

  async holdItem(item: ProductLine) {
    if (this.chunk.idx) return;
    const resp = await this.$openDialog(
      import("~/components/table/kds/holdDialog.vue"),
      {
        item: item,
        group: this.group,
      },
      {
        maxWidth: "max(50vw,500px)",
        contentClass: "h-[min(25rem,80vh)]",
      },
    );
    if (resp) {
      const pendingItem = this.group.items.find(it => it.kdsStatus === "pending" && it.status !== "cancel");
      if (!pendingItem) {
        this.startDoneTimer();
      }
    }
  }

  doneTimer: any;
  counter = -1;

  startDoneTimer() {
    if (this.doneTimer) {
      return;
    }
    this.doneTimer = setInterval(this.countNext, 1000);
    this.counter = 3;
  }

  stopDoneTimer(forceFinish = false) {
    if (!this.doneTimer) {
      return;
    }
    this.counter = -1;
    clearInterval(this.doneTimer);
    this.doneTimer = null;
    if (forceFinish) {
      this.counter = 1;
      this.countNext();
    }
  }

  async countNext() {
    this.counter--;
    if (this.counter === 0) {
      this.stopDoneTimer();

      if (this.statusType === "Pending") {
        for (let it of this.group.items) {
          if (it.kdsStatus !== "hold") it.kdsStatus = "done";
        }
      } else if (this.statusType === "Done") {
        for (let it of this.group.items) it.kdsStatus = "pending";
      } else if (this.statusType === "Hold") {
        for (let it of this.group.items) {
          it.kdsStatus = "pending";
          it.kdsHoldEnd = null;
          if (it.status === "hold") {
            it.status = "init";
          }
        }
      }
      const session = this.group.session;
      await this.$feathers.service("tableSessions/order").patch(null, {
        session: session._id,
        products: this.group.items,
        staff: this.$shop.staffId,
        updateKdsStock: this.updateKdsStock,
      });
      if (session.sessionData.tvStatus) {
        if (session.sessionData.tvStatus === "making" && this.statusType === "Pending") {
          // set tv status to ready
          if (session.sessionData.tvOrderStatuses) {
            await Promise.all(
              session.sessionData.tvOrderStatuses.map(id =>
                this.$feathers.service("tvOrderStatuses").patch(id, {
                  status: "ready",
                }),
              ),
            );
          } else {
            await this.$feathers.service("tvOrderStatuses").patch(
              null,
              {
                status: "ready",
              },
              {
                query: {
                  orderId: session._id,
                },
              },
            );
          }
        } else if (session.sessionData.tvStatus === "ready" && this.statusType === "Done") {
          // revert tv status
          if (session.sessionData.tvOrderStatuses) {
            await Promise.all(
              session.sessionData.tvOrderStatuses.map(id =>
                this.$feathers.service("tvOrderStatuses").patch(id, {
                  status: "making",
                }),
              ),
            );
          } else {
            await this.$feathers.service("tvOrderStatuses").patch(
              null,
              {
                status: "making",
              },
              {
                query: {
                  orderId: session._id,
                },
              },
            );
          }
        }
      }
    }
  }

  async headerAction() {
    if (this.chunk.idx) return;
    if (this.doneTimer) {
      this.stopDoneTimer();
    } else {
      this.updateKdsStock = true;
      if (this.statusType === "Done") {
        let anyStockUndo = false;
        for (let it of this.group.items) {
          if (it.kdsStockUsed) {
            anyStockUndo = true;
          }
        }
        if (anyStockUndo) {
          const resp = await this.$openDialog(
            import("./stockCancelDialog.vue"),
            {},
            {
              maxWidth: "max(50vw,500px)",
            },
          );
          if (typeof resp !== "boolean") return;
          this.updateKdsStock = resp;
        }
      }
      this.startDoneTimer();
    }
  }

  getProductClass(item: ProductLine) {
    if (item.status === "cancel") {
      return "inner-line-through !text-dark-text-danger bg-dark-surface-hover text-collapse";
    }
    if (item.kitchenOptions?.length && item.kdsStatus !== "done" && item.kdsStatus !== "partialDone") {
      return "!text-dark-text-danger bg-dark-surface-hover";
    }
    if (this.statusType === "Pending") {
      switch (item.kdsStatus) {
        case "partialDone":
          return "inner-line-through opacity-35";
      }
    }
  }

  getCategoryColor(item: ProductLine) {
    return this.$shop.catDict[String(item.category)]?.kdsColorTag;
  }

  isOptionProduct(option: any, value: any) {
    const optionInfo = this.$shop.productOptionDict[String(option)];
    const valueInfo = optionInfo?.options?.find(it => String(it._id) === value);
    return valueInfo?.type === "product";
  }

  getOptionName(option: any) {
    const optionInfo = this.$shop.productOptionDict[String(option)];
    return `${optionInfo ? this.$td(optionInfo.name) : "-"}:`;
  }

  getOptionDetail(option: any, value: any) {
    const optionInfo = this.$shop.productOptionDict[String(option)];
    const valueInfo = optionInfo?.options?.find(it => String(it._id) === value);
    return valueInfo ? this.$td(valueInfo.name) : "-";
  }
}
