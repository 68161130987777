
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";
import VueApexCharts from "vue-apexcharts";
import Percentage from "../Percentage.vue";
import { deepCopy } from "@firebase/util";
import _, { keyBy } from "lodash";
import moment from "moment";

interface dataFromParent {
  [date: string]: {
    value: number;
    name: string;
    hour: number[];
    values: number[];
  }[];
}
interface Series {
  name: string;
  data: { x: string; y: number }[];
}

interface latest3Series {
  date: string;
  values: { hour: number[]; name: string; value: number; values: number[] }[];
}
[];

interface latest4Series {
  hour: number[];
  name: string;
  value: number;
  values: number[];
}
[];

@Component({
  components: {
    Percentage,
    VueApexCharts,
  },
})
export default class StackBarChart extends Vue {
  @Prop() latestStartDate!: Date;
  @Prop() latestEndDate!: Date;
  @Prop() openHour!: number;
  @Prop() closeHour!: number;
  @Prop({ default: {} }) chartData!: dataFromParent;
  @Prop({ default: [] }) salesTypes!: [];
  @Prop() height!: number;
  @Prop() type!: string;
  @Prop({ default: "today" }) mode!: string;
  shop: FindType<"shops"> = null;
  sum = 0;
  sumPrevious = 0;

  get salesProportionOptions() {
    return {
      chart: {
        id: "chart",
        type: "bar",
        stacked: true,
        redrawOnParentResize: true,
        zoom: {
          enabled: this.mode === "customize" ? true : false,
        },
        toolbar: {
          show: this.mode === "customize" ? true : false,
          tools: {
            zoom: true,
            zoomin: true,
            zoomout: true,
            download: false,
          },
        },
      },
      colors: ["#7EC8F1", "#9F99E1", "#FFB466", "#FFD966", "#DB7070"],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 3,
          borderRadiusWhenStacked: "around",
          rangeBarOverlap: true,
          rangeBarGroupRows: true,
          columnWidth:
            this.mode == "month" ? "22%" : this.mode == "today" ? "20%" : this.mode == "week" ? "15%" : "10%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        show: this.$vuetify.breakpoint.mdAndDown ? false : true,
        offsetY: 20,
        markers: {
          width: 52,
          height: 15,
        },
        itemMargin: {
          horizontal: 20,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: "#ccc",
        strokeDashArray: 3,
      },
      noData: {
        text: this.$t("cahrtMessage.noData"),
      },
      series: this.series, // [{ name: '堂食', data: [110, 130,180,160,250,200, 110, 130,180,160,250,120] }, { name: '堂食（無檯號）', data: [110, 130,180,160,250,200, 110, 130,180,160,250,200] }, { name: '外賣', data: [200, 300, 500, 300, 500, 200, 110, 130,180,160,250,] }],
      xaxis: {
        type:
          this.mode == "today"
            ? "category"
            : this.mode === "customize" && this.isTodayBoolean
              ? "category"
              : this.isLessThanFourDaysBoolean
                ? "category"
                : "datetime",
        labels: {
          datetimeUTC: true,
          format:
            this.mode == "month"
              ? "dd/MM"
              : this.mode == "week"
                ? "dd/M"
                : this.mode == "year"
                  ? "MM/yyyy"
                  : "dd/MM/yyyy",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        decimalsInFloat: 2,
      },
      responsive: [
        {
          breakpoint: 1024,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 2,
                columnWidth: this.mode == "today" ? "20%" : this.mode == "week" ? "15%" : "80%",
              },
            },
          },
        },
      ],
    };
  }

  // get options2() {
  //   let max = moment(moment(this.latestEndDate).startOf("day").format("YYYY-MM-DD HH:mm")).valueOf();
  //   let min = moment(this.latestStartDate).valueOf();
  //   return {
  //     chart: {
  //       id: "chart1",
  //       height: 100,
  //       type: "area",
  //       foreColor: "#000",
  //       zoom: {
  //         enabled: false,
  //       },
  //       toolbar: {
  //         show: false,
  //       },
  //       brush: {
  //         target: "chart",
  //         enabled: this.mode === "today" || this.mode === "week" ? false : true,
  //       },
  //       selection: {
  //         enabled: this.mode === "today" || this.mode === "week" ? false : true,
  //         fill: {
  //           color: "#7EC8F1",
  //           opacity: 0.2,
  //         },
  //         xaxis: {
  //           min: min,
  //           max: max,
  //         },
  //       },
  //     },
  //     colors: ["#7EC8F1"],
  //     series: this.series,
  //     stroke: {
  //       width: 2,
  //     },
  //     grid: {
  //       borderColor: "#000",
  //     },
  //     markers: {
  //       size: 0,
  //     },
  //     xaxis: {
  //       type: "datetime",
  //       tooltip: {
  //         enabled: false,
  //       },
  //     },
  //     yaxis: {
  //       show: false,
  //       tickAmount: 2,
  //     },
  //     axisBorder: {
  //       show: false,
  //     },
  //     legend: {
  //       show: false,
  //     },
  //   };
  // }

  get series() {
    if (!this.chartData && !this.chartData.length) {
      return [];
    }
    let dataFromParent = this.chartData;
    let typeArray = this.salesTypes;
    let seriesDataAfterGen = this.genChartSeriesElements(dataFromParent, typeArray);
    let series = this.seriesFactory(seriesDataAfterGen);
    return series;
  }

  get amountLatest() {
    if (!this.chartData || !Object.values(this.chartData).length) {
      return 0;
    }
    return _.sumBy(Object.values(this.chartData), d => Math.abs((d as any).value));
  }

  get isTodayBoolean() {
    let v = moment(this.latestEndDate).valueOf() - moment(this.latestStartDate).valueOf() <= 86400000;
    return v;
  }

  get isHourDataBoolean() {
    let v = moment(this.latestStartDate).hour() - moment(this.latestEndDate).hour() === 0;
    return v;
  }

  get isLessThanFourDaysBoolean() {
    let v = moment(this.latestEndDate).valueOf() - moment(this.latestStartDate).valueOf() <= 345599000;
    return v;
  }

  get isOvernightBoolean() {
    let v = moment(this.latestEndDate).hour() > moment(this.latestStartDate).hour();
    return v;
  }

  get isEndTimeBiggerThanStartTimeBoolean() {
    let v = moment(this.latestStartDate).hour() > moment(this.latestEndDate).hour();
    return v;
  }

  genChartSeriesElements(dataArray, typesArray) {
    if (!dataArray || !typesArray) return [];
    const _dataArray = dataArray;
    const _typesArray = typesArray;
    let latest = _.map(_dataArray, (v, k) => ({
      date: k,
      values: v,
    }));
    let latestSortedByDate = latest.sort((a, b) =>
      new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? 1 : -1,
    );
    let latest2 = this.checkAndAddDate(latestSortedByDate, this.latestStartDate, this.latestEndDate, _typesArray);
    let latest3 = this.checkAndFillDate(latest2);
    let latest4 = this.checkAndFillHour(latest3);
    return latest4;
  }

  checkAndAddDate(oldArray, startDate, endDate, types) {
    if (!oldArray) return [];
    const _oldArray = deepCopy(oldArray);
    const _types = deepCopy(types);
    let _shopOpen = this.openHour;
    let _shopClose = this.closeHour;

    const emptyValue = [];
    for (let i = 0; i < _types.length; i++) {
      emptyValue.push({
        name: this.$t("orderType." + _types[i]),
        hour: [_shopOpen, _shopClose],
        values: [0, 0],
        value: 0,
      });
    }

    const firstDate = moment(_oldArray[0]?.date);
    const lastDate = moment(_oldArray[_oldArray.length - 1]?.date);
    // Check Start Date
    if (!moment(startDate).isSame(firstDate, "day")) {
      const newEntry = {
        date: this.formatDate(startDate),
        values: emptyValue,
      };
      _oldArray.unshift(newEntry);
    }

    // remove overnight issue
    let endDate2 = moment();
    if (this.mode === "today" && this.isOvernightBoolean) {
      endDate2 = moment(endDate).add(1, "day");
    } else if (this.mode === "customize" && this.isOvernightBoolean && this.isTodayBoolean) {
      endDate2 = moment(endDate).add(1, "day");
    } else if (this.mode === "customize" && this.isTodayBoolean) {
      endDate2 = endDate;
    } else {
      endDate2 = endDate;
    }

    // Check End Date
    if (!moment(endDate2).isSame(lastDate, "day")) {
      const newEntry = {
        date: this.formatDate(endDate2),
        values: emptyValue,
      };
      _oldArray.push(newEntry);
    }
    return _oldArray;
  }

  checkAndFillHour(oldArray2: latest3Series[]) {
    if (!this.isTodayBoolean) return oldArray2;
    const array2 = deepCopy(oldArray2);
    let _shopOpen = moment(this.latestStartDate).hour(this.openHour);
    let _shopClose = moment(_shopOpen).add(1, "day").add(-1, "s");
    const finalData = [];
    for (let dataObj of array2) {
      let finalDataObj = {
        date: dataObj.date,
        values: [],
      };
      let cun = 0;
      for (let valuesObj of dataObj.values) {
        let { hour, values, name, value } = valuesObj;

        // Initialize new hour and values array
        let subHour = [];
        let subValues = [];

        let i = moment(this.latestStartDate).hour(this.openHour);
        // const hourIndexMap = _.fromPairs(_.map(hour, (i, idx) => ([i, idx])))
        while (i.valueOf() <= _shopClose.valueOf()) {
          let idx = hour.indexOf(i.hour());
          // let idx = hourIndexMap[i.hour()] || -1
          if (idx === -1) {
            // Add missing hour and 0 value
            subHour.push(i.hour());
            subValues.push(0);
          } else {
            // Add existing hour and value
            subHour.push(hour[idx]);
            subValues.push(values[idx]);
          }

          i = i.add(1, "hour");
        }
        finalDataObj.values.push({
          hour: subHour,
          name: name,
          value: value,
          values: subValues,
        });
      }
      finalData.push(finalDataObj);
    }
    if (finalData.length > 1 && this.isTodayBoolean) {
      finalData.pop();
    }
    return finalData;
  }

  checkAndFillDate(oldArray2) {
    if (!oldArray2) {
      return;
    }
    const array2 = deepCopy(oldArray2);

    let _shopOpen = this.openHour; //moment(this.shop?.openTime, "HH:mm:ss").hour() || 0;
    let _shopClose = this.closeHour; //moment(this.shop?.closeTime, "HH:mm:ss").hour() || 23;
    const emptyValue = [];
    for (let i = 0; i < this.salesTypes.length; i++) {
      emptyValue.push({
        name: this.$t("orderType." + this.salesTypes[i]),
        hour: [_shopOpen, _shopClose],
        values: [0, 0],
        value: 0,
      });
    }

    const finalData = [];
    const start = moment(array2[0]?.date);
    const end = moment(array2[array2.length - 1]?.date);
    for (let date = start; date <= end; date.add(1, "day")) {
      const dateStr = date.format("YYYY-MM-DD");
      const dataObj = array2.find(obj => obj.date === dateStr);
      if (dataObj) {
        finalData.push(dataObj);
      } else {
        finalData.push({
          date: dateStr,
          values: emptyValue,
        });
      }
    }
    return finalData;
  }

  hourFormatSeriesMaker(array) {
    const series: Series[] = [];
    for (const day of array) {
      for (const category of day.values) {
        const name = category.name;
        const data = category.hour.map((hour, i) => ({
          x: `${hour}:00`,
          y: category.values[i],
        }));
        series.push({ name, data });
      }
    }
    return series;
  }

  dayFormatSeriesMaker(array) {
    const series: Series[] = [];
    const categories = array[0]?.values.map(value => ({ name: value.name }));
    if (categories) {
      for (const category of categories) {
        const newData: any = [];
        for (const year of array) {
          const values = year.values;
          const value = values?.find(val => val.name === category.name)?.value || 0;
          newData.push({
            x: moment(year.date).format("YYYY-MM-DD"),
            y: value,
          });
        }
        series.push({ name: category.name, data: newData });
      }
    }
    return series;
  }

  monthFormatSeriesMaker(array) {
    const series: Series[] = [];
    const map = new Map();

    for (const { date, values } of array) {
      const year = moment(date).format("YYYY");
      const month = moment(date).format("YYYY-MM");
      for (const category of values) {
        const name = category.name;
        const key = `${name}-${month}`;

        if (map.has(key)) {
          map.get(key).value += category.value;
        } else {
          map.set(key, {
            name,
            value: category.value,
            month,
          });
        }
      }
    }
    map.forEach(({ name, value, month }, key) => {
      const categoryName = key.split("-")[0];
      if (!series.some(item => item.name === categoryName)) {
        series.push({
          name: categoryName,
          data: [{ x: month, y: value }],
        });
      } else {
        series
          .find(item => item.name === categoryName)
          .data.push({
            x: month,
            y: value,
          });
      }
    });
    return series;
  }

  seriesFactory(array) {
    if (array) {
      let mode = this.mode;
      if (mode === "today") {
        let series = this.hourFormatSeriesMaker(array);
        return series;
      } else if (mode === "year") {
        let series = this.monthFormatSeriesMaker(array);
        return series;
      } else if (mode === "week" || mode === "month") {
        let series = this.dayFormatSeriesMaker(array);
        return series;
      } else {
        if (this.isTodayBoolean) {
          let series = this.hourFormatSeriesMaker(array);
          return series;
        } else {
          let series = this.dayFormatSeriesMaker(array);
          return series;
        }
      }
    }
    return [];
  }

  formatDate(date) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
  }
}
