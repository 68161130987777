
import { Prop, mixins, Component, FindType } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";

@Component
export default class ConfirmDialog extends mixins(Dialog) {
  devices: FindType<"posDevices/limit"> = null;

  @Prop()
  operator: {
    name: any;
    shortId: string;
  };

  async logout() {
    this.$router.replace("/logout");
  }

  async backToDashboard() {
    this.$router.replace("/profile");
  }

  async retry() {
    await this.$shop.loadDevice();
    await this.loadDevices();
  }

  async mounted() {
    await this.loadDevices();
  }

  async loadDevices() {
    this.devices = await this.$feathers.service("posDevices/limit").find({});
  }

  async kill(id: string) {
    await this.$feathers.service("posDevices/limit").remove(id);
    await this.retry();
  }

  detectDeviceType(userAgent: string) {
    if (userAgent.includes("Android")) {
      return "Android";
    } else if (userAgent.includes("iPhone")) {
      return "iPhone";
    } else if (userAgent.includes("iPad")) {
      return "iPad";
    } else if (userAgent.includes("Mac OS X") || userAgent.includes("MacOS")) {
      return "macOS";
    } else if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("Linux")) {
      return "Linux";
    } else {
      return userAgent;
    }
  }
}
