
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import type TvStatus from "~/components/TvStatus.vue";

@Component({})
export default class OrderSystemFinishOrder extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  posMode: String;

  @Prop({ type: Boolean })
  mobile: boolean;

  @Prop({ type: Boolean })
  fromOrder: boolean;

  loading = false;

  currentPayment: any = null;

  mounted() {
    this.currentPayment = this.session.payments?.slice?.(-1)?.[0] ?? null;
  }

  get canEdit() {
    return (this.isDone || this.isTest) && this.$shop.hasStaffRole("refundOrEdit");
  }

  get isDone() {
    return this.session.status === "done";
  }

  get isTest() {
    return this.session.status === "test";
  }

  get isVoid() {
    return this.session.status === "void";
  }

  get isCancel() {
    return this.session.status === "cancelled";
  }

  get kdsDone() {
    return (
      !this.$shop?.shopData?.kdsEnabled ||
      this.session.products.every(
        product => product.kdsStatus === "done" || product.kdsStatus === "partialDone" || product.status === "cancel",
      )
    );
  }

  get parentOrder() {
    if (!this.session.checkoutFrom || this.fromOrder) return null;
    return this.$tableManager.sessionDict[`${this.session.checkoutFrom}`] || null;
  }

  async voidOrder() {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDeleteDialog.vue"),
      {
        title: this.$t("pages.cancelOrder") + " ？ ",
        desc: this.$t("pages.cancelOrderDialog"),
        confirmLock: !this.$shop.hasPermission([
          "orderManage/tableSessionCancelOrder",
          "paymentManage/tableSessionRefundPayment",
        ]),
      },
      {
        maxWidth: "500px",
        contentClass: "editor-dialog",
      },
    );

    if (!c) return;

    const staff = await this.$shop.checkPermission([
      "orderManage/tableSessionCancelOrder",
      "paymentManage/tableSessionRefundPayment",
    ]);
    if (staff === false) return;

    this.loading = true;
    try {
      this.session.voidingPayment = true;

      const paymentItems: FindType<"payments">[] = [];

      if (this.session.payments?.length) {
        for (let payment of this.session.payments) {
          const paymentItem = await this.$paymentManager.refundPayment(getID(payment.payment));
          if (paymentItem.status === "refunded") {
            await this.session?.refundSplittedPayment(paymentItem);
            await this.$feathers.service("actionLogs").create({
              session: this.session.item._id,
              view: getID(this.session.item.view),
              staff: staff?._id || this.$shop.staffId,
              type: "paymentManage/tableSessionRefundPayment",
              detail: { payment: [paymentItem] },
            });
          }
          await this.session.reload();

          paymentItems.push(paymentItem);
        }

        await this.$feathers.service("tableSessions/cancelPending").create({
          session: this.session.item._id,
          update: {
            endTime: new Date(),
            status: "void",
            cancelReason: c.cancelReason,
            staff: staff?._id || this.$shop.staffId,
          },
        });

        await this.$feathers.service("actionLogs").create({
          session: this.session.item._id,
          staff: staff?._id || this.$shop.staffId,
          type: "orderManage/tableSessionCancelOrder",
          detail: { payment: paymentItems },
        });
      } else {
        await this.$feathers.service("tableSessions/void").create({
          session: this.session.item._id,
          staff: staff?._id || this.$shop.staffId,
          cancelReason: c.cancelReason,
        });
      }

      await this.session.reload();
      this.session.receiptPrintTime = 0;
      this.currentPayment = null;
      if (!paymentItems.length) {
        await this.session.printOrder({
          ongoingPayment: true,
          refund: true,
        });
      } else {
        for (let payment of paymentItems ?? []) {
          if (payment.status === "refunded") {
            await this.session.printOrder({
              payment: getID(payment._id),
              ongoingPayment: true,
              refund: true,
            });
          }
        }
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
      this.session.voidingPayment = false;
    }
  }

  @Ref()
  tvStatus: TvStatus;

  async endSession() {
    this.loading = true;
    try {
      if (this.tvStatus && (this.$shop.localOptions.ensureReadyWhenFinish ?? true)) {
        await this.tvStatus.ensureReady();
      }
      await this.session.atomic({
        endTime: new Date(),
      });
      this.$emit("close");

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: this.$shop.staffId,
        type: `tableManage/tableSessionFinishOrder`,
      });
    } finally {
      this.loading = false;
    }
  }

  async openParentSession() {
    if (this.isVoid || this.kdsDone) {
      this.endSession();
    }
    if (!this.parentOrder) return;
    this.$root.$emit("openOrder", this.parentOrder._id);
  }

  async printInvoice() {
    await this.session.printOrder({
      payment: this.currentPayment?.payment,
      user: true,
    });
  }

  async showInvoice() {
    this.session.showingInvoice = true;
    this.session.syncOrder();
  }

  @Watch("editOrder")
  editOrder() {
    this.$emit("editOrder");
  }

  beforeDestroy() {
    if (this.session) {
      this.session.showingInvoice = false;
    }
  }

  get nextSessionType() {
    switch (this.$shop.localOptions.newOrderType) {
      case "takeAway":
        return "takeAway";
      case "dineInNoTable":
        return "dineInNoTable";
      default:
        return this.session?.type ?? "takeAway";
    }
  }
}
