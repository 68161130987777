

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import ProductCartItem from './ProductCartItem.vue'

type ProductType = any;

@Component({
    components: {
        ProductCartItem,
    }
})
export default class Page extends Vue {
    keyword = '';

    localSearch = (items : ProductType[], search: string) => {
        search = (search || '').trim().toLowerCase();
        return items.filter(item => !search || item.name.some(it => it.value.toLowerCase().indexOf(search) !== -1));
    }

    @Prop()
    shop : ProductType

    @Prop()
    cart : any;

    customSort(item : any) {
        return [this.$td(item.category ? item.category.name : ''), item.code, this.$td(item.name)];
    }
}

