
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

@Component
export default class NetworkConnectDialog extends mixins(Dialog) {
  @Prop()
  offlineEnabled: boolean;

  timeLeft = 10;
  timer: any;
  mounted() {
    if (this.offlineEnabled) {
      this.timer = setInterval(this.next, 1000);
    }
    (this.$feathers as any).on("connected", this.connected);
  }

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    (this.$feathers as any).off("connected", this.connected);
  }

  connected() {
    this.modalResult(true);
  }

  next() {
    if (--this.timeLeft === 0) {
      this.modalResult(false);
    }
  }
}
