var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"queueing-card",class:_vm.waitingList
      ? 'bg-dark-surface-default'
      : _vm.alreadySeated
        ? `bg-dark-surface-neutral-subtle`
        : _vm.numCalling
          ? 'flashing-bg'
          : 'bg-grey500'},[_c('div',{staticClass:"number-of-count font-barlow-bold",style:([_vm.alreadySeated ? { color: '#4F4F4F' } : { color: _vm.ticketCard ? _vm.ticketCard.color : '#FFF' }])},[_vm._v(" "+_vm._s(_vm.ticketCard ? _vm.ticketCard.ticketNum : "0")+" ")]),(!_vm.alreadySeated && !_vm.numCalling)?_c('div',{staticClass:"queueing-group"},[_vm._m(0),_c('span',{staticClass:"pl-2 pr-3 font-bold font-barlow-bold",style:({ color: _vm.cardDetails ? _vm.cardDetails.color : '#FFFFFF' })},[_vm._v(_vm._s(_vm.cardDetails ? _vm.cardDetails.numberOfPeople : 0))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.cardDetails ? _vm.cardDetails.time : "00:00"))])]):(_vm.alreadySeated)?_c('span',{staticClass:"already-seated font-barlow-bold"},[_vm._v(_vm._s(_vm.$t("queueing.alreadySeated")))]):(_vm.numCalling)?_c('span',{staticClass:"already-seated font-barlow-bold"},[_vm._v(_vm._s(_vm.$t("queueing.numCalling")))]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-6 h-6"},[_c('div',{staticClass:"icon-people w-6 h-6"})])
}]
render._withStripped = true
export { render, staticRenderFns }