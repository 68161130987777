import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand, wrapVue } from "../index";
import type { PrintJob, PrintQueue } from "../printQueue";
import { ns } from "../messageQueue";
import { getVersion } from "../nativeIntegrations";
import { EventEmitter } from "events";
import { getDevices } from "../ports/usb";

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("star").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]).catch(e => false);
}

export interface StarConf extends PrinterConf {
  port: "star";
  interface: string;
}

export class StarPrinter extends PrinterBase<StarConf, StarConf> {
  constructor(server: PrinterServer, conf: StarConf) {
    super(server, conf);
    this.initStatus();
  }

  initStatus() {
    if (this.statusHandle) {
      ns("star").off(this.statusHandle);
      this.statusHandle = null;
    }
    this.statusHandle = ns("star").on(
      `status/${this.conf.address}`,
      ({ status, error }: { status: string; error?: string }) => {
        switch (status) {
          case "communicationError":
            this.onDisconnected(`Communication error: ${error}`);
            break;
            case "paperReady":
              if (this.noPaper) {
                this.noPaper = false;
                this.emit("noPaperChanged", this.noPaper);
              }
              break;
            case "paperEmpty":
              if (!this.noPaper) {
                this.noPaper = true;
                this.emit("noPaperChanged", this.noPaper);
              }
              break;
          case "error":
            this.ready = false;
            this.emit("ready", false);
            break;
          case "ready":
            this.ready = true;
            this.emit("ready", true);
            break;
        }
      },
    );
  }

  statusHandle: string;
  ready = true;

  onDisconnectedCore() {}

  async initCore() {}

  async tryConnectCore() {
    try {
      await ns("star").call<void>("connect", this.conf.address, this.conf.interface);
    } catch (e) {
      if (e.message && e.message.includes("Device not found")) {
        if (this.conf.interface === "Usb" && this.conf.address.startsWith("/dev/bus/usb")) {
          const devices = await getDevices();
          const firstStar = devices.devices.find(it => it.vendorId === 1305);

          if (firstStar && firstStar.name.startsWith("/dev/bus/usb")) {
            this.conf.address = firstStar.name;
            this.initStatus();
            await ns("star").call<void>("connect", this.conf.address, this.conf.interface);
            return;
          }
        }
      }
      throw e;
    }
  }

  async printCore(job: PrintJob) {
    const buf = Array(job.data.length);
    for (let i = 0; i < job.data.length; i++) buf[i] = job.data[i];
    await ns("star").call("print", this.conf.address, buf);
  }

  async disconnectCore() {
    await ns("star").call("disconnect", this.conf.address);
    this.ready = false;
    this.emit("ready", false);
  }

  async requestNewDeviceCore() {
    return this.conf;
  }

  dispose(): void {
    if (this.statusHandle) {
      ns("star").off(this.statusHandle);
    }
    this.ready = false;
    this.emit("ready", false);
  }

  async waitReadyCore(): Promise<boolean> {
    if (this.ready) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      this.once("ready", r => resolve(r));
    });
  }
}

export class StarDiscovery extends EventEmitter {
  constructor() {
    super();
    this.handle = ns("star").on("printer", (info: StarInfo) => {
      this.emit("printer", info);
    });
  }

  handle: string;

  dispose() {
    this.stop();
    ns("star").off(this.handle);
  }

  async start(options?: StarDiscoveryOptions) {
    await ns("star").call("discovery", options);
  }

  async stop() {
    await ns("star").call("stopDiscovery");
  }
}

export interface StarInfo {
  identifier: string;
  model: string;
  emulation: string;
  interfaceType: string;
}

export interface StarDiscoveryOptions {
  discoveryTime?: number;
}
