
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import _ from "lodash";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { ProductLine } from "~/plugins/table/session";
import { OrderGroup } from "~/plugins/table/kds";
import moment from "moment";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class HoldDialog extends mixins(Dialog()) {
  hold = 0;

  @Prop()
  group: OrderGroup;

  @Prop()
  item: ProductLine;

  get holdOptions() {
    return [
      { _id: 0, name: { $t: "kds.holdOrder.noAuto" } },
      { _id: 5, name: `5${this.$t("kds.holdOrder.min")}` },
      { _id: 10, name: `10${this.$t("kds.holdOrder.min")}` },
      { _id: 15, name: `15${this.$t("kds.holdOrder.min")}` },
      { _id: 30, name: `30${this.$t("kds.holdOrder.min")}` },
      { _id: 45, name: `45${this.$t("kds.holdOrder.min")}` },
      { _id: 60, name: `1${this.$t("kds.holdOrder.hour")}` },
    ];
  }

  async confirmHold() {
    const items = this.item ? [this.item] : this.group.items.filter(it => it.kdsStatus !== "done" && it.kdsStatus !== "partialDone");

    const kdsHoldEnd =  this.hold ? moment().add(this.hold, "minute").toDate() : null;
    for(let item of items) {
      item.kdsHoldEnd = kdsHoldEnd;
      item.kdsStatus = "hold";
    }

    await this.$feathers.service("tableSessions/order").patch(null, {
      session: this.group.session._id,
      products: items.map(item => ({
        ...item,
      })),
    });

    if(this.item) {
      const idx = this.group.items.findIndex(it => it._id === this.item._id);
      idx !== -1 && this.group.items.splice(idx, 1);
      this.group.resetView();
    }

    this.modalResult(true);
  }
}
