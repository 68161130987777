import { RawLine } from "../rawLine";
import qr from "qr-image";

export function getQR(text: string, numChars: number): RawLine[] {
  var matrix = qr.matrix(text);

  if (numChars && matrix[0].length > numChars * 6) {
    throw new Error("Too much data");
  }

  const numLines = Math.ceil((matrix.length * 2) / 8);
  const width = matrix[0].length;
  const lineSize = width * 3;

  numChars = lineSize / 6;

  const lines: RawLine[] = [];
  for (let i = 0; i < numLines; i++) {
    const buf = new Uint8Array(lineSize + 5);
    buf[0] = 0x1b; // ESC
    buf[1] = 0x2a; // *
    buf[2] = 0;
    buf[3] = lineSize % 256 | 0;
    buf[4] = (lineSize / 256) | 0;

    const yofs = i * 4;

    let ofs = 5;
    for (let j = 0; j < width; j++) {
      let v = 0;
      for (let l = 0; l < 8; l++) {
        const mv = 128 >> l;
        const y = l >> 1;
        v |= matrix[yofs + y] && matrix[yofs + y][j] ? mv : 0;
      }
      buf[ofs++] = v;
      buf[ofs++] = v;
      buf[ofs++] = v;
    }
    lines.push(new RawLine(Buffer.from(buf), numChars));
  }

  return lines;
}

// width in chars
export function getImage(
  img: { buffer: Uint8ClampedArray; width: number; height: number },
  numChars: number,
  pxPerLine: number,
  hiRes: boolean | number,
): RawLine[] {
  const width = img.width;
  const height = img.height;
  const js_buffer = img.buffer;

  const lineBytes = pxPerLine / 8;
  const lineSize = lineBytes * width;
  const bitmapLine = img.width * 4;

  const numLines = height / pxPerLine;

  const lines: RawLine[] = [];
  for (let i = 0; i < numLines; i++) {
    const dy = i * pxPerLine;
    const buf = new Uint8Array(lineSize + 5);

    buf[0] = 0x1b; // ESC
    buf[1] = 0x2a; // *
    buf[2] = typeof hiRes === 'number' ? hiRes : hiRes ? 33 : 0;
    buf[3] = width % 256 | 0;
    buf[4] = (width / 256) | 0;

    let ofs = 5;
    for (let j = 0; j < width; j++) {
      let yofs = j * 4 + dy * bitmapLine;

      for (let k = 0; k < lineBytes; k++) {
        let v = 0;
        for (let l = 0; l < 8; l++) {
          const mv = 128 >> l;
          v |= (js_buffer[yofs] ?? 255) ? 0 : mv;
          yofs += bitmapLine;
        }
        buf[ofs++] = v;
      }
    }

    lines.push(new RawLine(Buffer.from(buf), numChars));
  }

  return lines;
}

function js_greyscale(r: number, g: number, b: number) {
  return r * 0.299 + g * 0.587 + b * 0.114;
}
function js_quantize(x: number, levels: number) {
  return Math.min(255, Math.max(0, Math.round(levels * (x / 255.0)) * (255.0 / levels)));
}

function js_err_dist(input: number, error: number, amount: number) {
  const v = input + error * amount;
  if (v < 0) return 0;
  else if (v > 255) return 255;
  else return v;
}

export function js_process_alpha(js_buffer: Uint8ClampedArray, width: number, height: number) {
  let x: number, y: number, buf_off_x: number, buf_off_y: number, v: number, qv: number;
  for (y = 0; y < height; y++) {
    buf_off_y = y * width * 4;
    for (x = 0; x < width; x++) {
      buf_off_x = buf_off_y + x * 4;
      js_buffer[buf_off_x + 0] = js_buffer[buf_off_x + 3] > 0 ? 0 : 255;
      js_buffer[buf_off_x + 1] = js_buffer[buf_off_x + 3] > 0 ? 0 : 255;
      js_buffer[buf_off_x + 2] = js_buffer[buf_off_x + 3] > 0 ? 0 : 255;
      js_buffer[buf_off_x + 3] = 255;
    }
  }
}

export function js_process_nodither(js_buffer: Uint8ClampedArray, width: number, height: number, levels: number) {
  let x: number, y: number, buf_off_x: number, buf_off_y: number, v: number, qv: number;
  for (y = 0; y < height; y++) {
    buf_off_y = y * width * 4;
    for (x = 0; x < width; x++) {
      buf_off_x = buf_off_y + x * 4;
      v = js_greyscale(js_buffer[buf_off_x + 0], js_buffer[buf_off_x + 1], js_buffer[buf_off_x + 2]);
      qv = js_quantize(v, levels);
      js_buffer[buf_off_x + 0] = qv;
      js_buffer[buf_off_x + 1] = qv;
      js_buffer[buf_off_x + 2] = qv;
    }
  }
}

export function js_process_dither(js_buffer: Uint8ClampedArray, width: number, height: number, levels: number) {
  let x: number,
    y: number,
    buf_off_x: number,
    buf_off_y: number,
    v: number,
    qv: number,
    err: number,
    v1: number,
    qv1: number,
    v2: number,
    qv2: number,
    v3: number,
    qv3: number,
    v4: number,
    qv4: number;
  let f1 = 7.0 / 16.0;
  let f2 = 3.0 / 16.0;
  let f3 = 5.0 / 16.0;
  let f4 = 1.0 / 16.0;
  for (y = 0; y < height - 1; y++) {
    buf_off_y = y * width * 4;
    for (x = 1; x < width - 1; x++) {
      buf_off_x = buf_off_y + x * 4;
      v = js_greyscale(js_buffer[buf_off_x + 0], js_buffer[buf_off_x + 1], js_buffer[buf_off_x + 2]);
      qv = js_quantize(v, levels);
      err = v - qv;
      // x,y
      js_buffer[buf_off_x + 0] = qv;
      js_buffer[buf_off_x + 1] = qv;
      js_buffer[buf_off_x + 2] = qv;
      if (err !== 0) {
        // x+1,y
        v1 = js_greyscale(js_buffer[buf_off_x + 4 + 0], js_buffer[buf_off_x + 4 + 1], js_buffer[buf_off_x + 4 + 2]);
        qv1 = js_err_dist(v1, err, f1);
        js_buffer[buf_off_x + 4 + 0] = qv1;
        js_buffer[buf_off_x + 4 + 1] = qv1;
        js_buffer[buf_off_x + 4 + 2] = qv1;
        // x-1,y+1
        v2 = js_greyscale(
          js_buffer[buf_off_x + width * 4 - 4 + 0],
          js_buffer[buf_off_x + width * 4 - 4 + 1],
          js_buffer[buf_off_x + width * 4 - 4 + 2],
        );
        qv2 = js_err_dist(v2, err, f2);
        js_buffer[buf_off_x + width * 4 - 4 + 0] = qv2;
        js_buffer[buf_off_x + width * 4 - 4 + 1] = qv2;
        js_buffer[buf_off_x + width * 4 - 4 + 2] = qv2;
        // x,y+1
        v3 = js_greyscale(
          js_buffer[buf_off_x + width * 4 + 0],
          js_buffer[buf_off_x + width * 4 + 1],
          js_buffer[buf_off_x + width * 4 + 2],
        );
        qv3 = js_err_dist(v3, err, f3);
        js_buffer[buf_off_x + width * 4 + 0] = qv3;
        js_buffer[buf_off_x + width * 4 + 1] = qv3;
        js_buffer[buf_off_x + width * 4 + 2] = qv3;
        // x+1,y+1
        v4 = js_greyscale(
          js_buffer[buf_off_x + width * 4 + 4 + 0],
          js_buffer[buf_off_x + width * 4 + 4 + 1],
          js_buffer[buf_off_x + width * 4 + 4 + 2],
        );
        qv4 = js_err_dist(v4, err, f4);
        js_buffer[buf_off_x + width * 4 + 4 + 0] = qv4;
        js_buffer[buf_off_x + width * 4 + 4 + 1] = qv4;
        js_buffer[buf_off_x + width * 4 + 4 + 2] = qv4;
      }
    }
  }
}

const Floyd8X8 = [
  [-254, -126, -222, -94, -246, -118, -214, -86],
  [-62, -190, -30, -158, -54, -182, -22, -150],
  [-206, -78, -238, -110, -198, -70, -230, -102],
  [-14, -142, -46, -174, -6, -134, -38, -166],
  [-242, -114, -210, -82, -250, -122, -218, -90],
  [-50, -178, -18, -146, -58, -186, -26, -154],
  [-194, -66, -226, -98, -202, -74, -234, -106],
  [-2, -130, -34, -162, -10, -138, -42, -170],
];

export function js_process_floyd(js_buffer: Uint8ClampedArray, width: number, height: number) {
  for (let y = 0; y < height; y++) {
    let buf_off_y = y * width * 4;
    for (let x = 0; x < width; x++) {
      const g = 30 * js_buffer[buf_off_y + 0] + 59 * js_buffer[buf_off_y + 1] + 11 * js_buffer[buf_off_y + 2];
      const v = (g * js_buffer[buf_off_y + 3] + 12800) / 25500 - js_buffer[buf_off_y + 3];
      const qv = v < Floyd8X8[y % 8][x % 8] ? 0 : 255;
      // x,y
      js_buffer[buf_off_y + 0] = qv;
      js_buffer[buf_off_y + 1] = qv;
      js_buffer[buf_off_y + 2] = qv;
      buf_off_y += 4;
    }
  }
}

export async function convertImage(
  url: string,
  width: number,
  mode: "dither" | "color" | "colorAlpha" | "gray" | "mono" | "alpha" = "dither",
  pxRatio = 1,
  pxPerLine = 1,
  fixCorner = true,
  drawWidth = width,
) {
  if (typeof Image === "undefined") {
    return null;
  }

  if(mode !== "dither") {
    fixCorner = false;
  }

  const img = new Image();
  img.crossOrigin = "Anonymous";
  const loaded = await new Promise<boolean>((resolve, reject) => {
    img.onload = () => resolve(true);
    img.onerror = () => {
      console.warn("Image load error: " + url);
      resolve(false);
    };
    img.src = url;
  });
  if (!loaded) {
    return null;
  }

  const canvas = document.createElement("canvas");
  const finalWidth = width;
  canvas.width = width + (fixCorner ? 2 : 0);
  
  const height = Math.ceil((drawWidth / img.width) / pxRatio * img.height);
  const finalHeight = Math.ceil(height / pxPerLine) * pxPerLine;
  canvas.height = finalHeight + (fixCorner ? 2 : 0);

  const ctx = canvas.getContext("2d");
  if (mode !== "colorAlpha" && mode !== "alpha") {
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  ctx.imageSmoothingEnabled = true;

  ctx.drawImage(img, ((canvas.width - width) / 2) | 0 + (fixCorner ? 1 : 0), ((canvas.height - height) / 2) | 0 + (fixCorner ? 1 : 0), drawWidth, height);
  let byte_size = canvas.width * canvas.height * 4;

  let js_buffer = new Uint8ClampedArray(byte_size);
  js_buffer.set(ctx.getImageData(0, 0, canvas.width, canvas.height).data);

  if (mode === "dither") {
    js_process_dither(js_buffer, canvas.width, canvas.height, 1);

    if(fixCorner) {
      ctx.putImageData(new ImageData(js_buffer, canvas.width, canvas.height), 0, 0);

      const src_row_size = canvas.width * 4;
      const row_size = finalWidth * 4;
      for (let i = 0; i < finalHeight; i++) {
        const src = src_row_size * (i + 1) + 4;
        const dst = row_size * i + 4;
        for (let j = 0; j < row_size; j++) {
          js_buffer[dst + j] = js_buffer[src + j];
        }
      }

      js_buffer = js_buffer.slice(0, row_size * finalHeight);
    }
  } else if (mode === "mono") {
    js_process_nodither(js_buffer, canvas.width, canvas.height, 1);
  } else if (mode === "alpha") {
    js_process_alpha(js_buffer, canvas.width, canvas.height);
  }
  return {
    buffer: js_buffer,
    width: finalWidth,
    height: finalHeight,
  };
}

export function dither(ctx: CanvasRenderingContext2D) {
  const canvas = ctx.canvas;
  let byte_size = canvas.width * canvas.height * 4;

  const js_buffer = new Uint8ClampedArray(byte_size);
  js_buffer.set(ctx.getImageData(0, 0, canvas.width, canvas.height).data);

  js_process_dither(js_buffer, canvas.width, canvas.height, 1);
  return {
    buffer: js_buffer,
    width: canvas.width,
    height: canvas.height,
  };
}

export function js_process_nodither_thresold(
  js_buffer: Uint8ClampedArray,
  width: number,
  height: number,
  levels: number,
) {
  let x: number, y: number, buf_off_x: number, buf_off_y: number, v: number, qv: number;
  for (y = 0; y < height; y++) {
    buf_off_y = y * width * 4;
    for (x = 0; x < width; x++) {
      buf_off_x = buf_off_y + x * 4;
      v = js_greyscale(js_buffer[buf_off_x + 0], js_buffer[buf_off_x + 1], js_buffer[buf_off_x + 2]);
      qv = v >= levels ? 255 : 0;
      js_buffer[buf_off_x + 0] = qv;
      js_buffer[buf_off_x + 1] = qv;
      js_buffer[buf_off_x + 2] = qv;
    }
  }
}

export function quantize(ctx: CanvasRenderingContext2D, thresold: number) {
  const canvas = ctx.canvas;
  let byte_size = canvas.width * canvas.height * 4;

  const js_buffer = new Uint8ClampedArray(byte_size);
  js_buffer.set(ctx.getImageData(0, 0, canvas.width, canvas.height).data);

  js_process_nodither_thresold(js_buffer, canvas.width, canvas.height, thresold);
  return {
    buffer: js_buffer,
    width: canvas.width,
    height: canvas.height,
  };
}
