<template>
  <div>
    <div class="text-h5">{{ $t("pages.spendLogs.$") }}</div>
    <data-table
      :data="{
        path: 'userSpends',
        filter: {
          user: user,
        },
        sortBy: ['date'],
        sortDesc: [true],
        name: $t('userSpends.$'),
      }"
      :headers="[
        { text: $t('userSpends.date'), sortable: true, value: 'date', format: 'moment' },
        { text: $t('userSpends.totalIn'), sortable: true, value: 'totalInInt', format: 'currencyInt' },
        { text: $t('userSpends.totalOut'), sortable: true, value: 'totalOutInt', format: 'currencyInt' },
        { text: $t('userSpends.amount'), sortable: true, value: 'amountInt', format: 'currencyInt' },
        { text: $t('userSpends.used'), sortable: true, value: 'used' },
      ]"
      actions
      no-remove
      no-clone
      no-edit
      no-add
      :default="{
        point,
        value: 0,
        user,
        message: 'by admin',
      }"
    >
      <template #post-actions="{ reload }">
        <b-btn :alt-text="$t('basic.add')" @click="adjustSpend(reload)">
          <v-icon>add</v-icon>
        </b-btn>
      </template>
      <template slot="editor" slot-scope="edit">
        <v-text-field :label="$t('point.value')" v-model.number="edit.item.amount" />
        <v-text-field :label="$t('point.message')" v-model="edit.item.message" />
      </template>
    </data-table>
    <data-table
      :data="{
        path: 'userSpendLogs',
        filter: {
          user: user,
        },
        sortBy: ['date'],
        sortDesc: [true],
        name: $t('userSpendLogs.$'),
        populate: ['session'],
      }"
      :headers="[
        { text: $t('userSpendLogs.date'), sortable: true, value: 'date', format: 'moment' },
        { text: $t('userSpendLogs.from'), sortable: true, value: 'fromInt', format: 'currencyInt' },
        { text: $t('userSpendLogs.to'), sortable: true, value: 'toInt', format: 'currencyInt' },
        { text: $t('userSpendLogs.delta'), sortable: true, value: 'deltaInt', format: 'currencyInt' },
      ]"
      itemCanClick
    >
      <template v-slot:expand="{ item: log }">
        <v-card flat v-if="log.session">
          <v-card-text>
            <!-- <order-manager :order="log.order" :addCancel="false" /> -->
          </v-card-text>
        </v-card>
      </template>
    </data-table>
  </div>
</template>

<script>
import nuxtend from "@feathers-client/nuxtend";
import Auth from "~/mixins/auth";
import _ from "lodash";
// import OrderManager from "~/components/OrderManager";

export default nuxtend({
  components: {
    //OrderManager,
  },
  mixins: [Auth],
  props: {
    user: {
      required: true,
    },
  },
  methods: {
    async adjustSpend(reload) {
      const c = await this.$openDialog(
        import("~/components/dialogs/AdjustSpendDialog.vue"),
        {
          user: this.user,
        },
        {
          maxWidth: "50vw",
        },
      );
      reload();
    },
  },
});
</script>
