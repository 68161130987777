
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync, getID } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import { checkID } from "@feathers-client";
import "~/plugins/payments";
import OrderSystemCheckoutBase from "./checkoutBase";
import { FindType } from "@feathers-client";
import { PaymentLike } from "pos-printer/payments/methodBase";

@Component
export default class OrderSystemPaymentRecord extends mixins(OrderSystemCheckoutBase) {
  @Prop()
  payment: string;

  @Prop()
  session: TableSession;

  @Prop(Boolean)
  mobile: boolean;

  @Prop(Boolean)
  posScreen: boolean;

  @Prop({ type: Boolean, default: true })
  hasAction: boolean;

  loading = false;

  cachedValue(payment: FindType<"payments">) {
    if (this.posScreen) return;
    this.session.showingPayment = payment;
    this.session.syncOrder?.();
  }

  beforeDestroy() {
    if (this.posScreen) return;
    this.session.showingPayment = null;
    this.session.syncOrder?.();
  }

  get canEdit() {
    return !this.$features?.disableEditOrder && (this.isDone || this.isTest) && this.$shop.hasStaffRole("refundOrEdit");
  }

  get isDone() {
    return this.session.status === "done";
  }

  get isTest() {
    return this.session.status === "test";
  }

  tipsAdjustable(item) {
    if (["cash", "manual"]) {
      return true;
    }
    const manager = this.$paymentManager.getMethod(item.methodType);
    return manager?.capabilities?.adjust;
  }

  async editOrAddTips(item) {
    const staff = await this.$shop.checkPermission(["paymentManage/editTips"]);
    if (staff === false) return false;

    if (!this.tipsAdjustable(item)) return;

    if (this.loading) return;
    this.loading = true;

    try {
      const newTips: number = await this.$openDialog(
        import("./numPadDialog.vue"),
        {
          title: item.tips && item.tips > 0 ? this.$t("newOrder.editTipsAmount") : this.$t("newOrder.addTipsAmount"),
          label: this.$t("payments.amount"),
          originNumber: item.tips ?? 0,
        },
        {
          maxWidth: "420px",
        },
      );
      if (newTips === null) return;

      const tipsDiff = newTips - (item.tips ?? 0);
      if (tipsDiff === 0) return;

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "paymentManage/editTips",
        detail: { new: newTips, old: item.tips ?? 0 },
      });

      const manager = this.$paymentManager.getMethod(item.methodType);

      if (manager?.capabilities?.adjust) {
        let paymentLike: PaymentLike = {
          _id: item._id,
          amount: item.amount,
          tips: item.tips,
          status: "pending",
          metadata: item.metadata,
          subType: item.methodSubType,
        };
        paymentLike =
          (await manager.adjust(paymentLike, {
            diffAmount: tipsDiff,
          })) || paymentLike;
        await this.$feathers.service("payments").patch(item._id, {
          metadata: paymentLike.metadata,
        });
      }

      await this.session.editOrAddTips(item._id, newTips);

      item.amount = (item.amount ?? 0) + tipsDiff;

      if (item.metadata?.receiveAmount) {
        item.metadata.receiveAmount = (item.metadata?.receiveAmount ?? 0) + tipsDiff;
      }

      item.tips = newTips;
    } catch (e) {
      this.$store.commit("SET_ERROR", e);
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async editOrder() {
    if (this.mobile && !this.$shop.shopData?.handheldPaymentEnabled) {
      this.$store.commit(
        "SET_ERROR",
        this.$t("pos.mobile.handheld.payment.paymentFunctionNotEnable", {
          status: this.$t("pos.mobile.handheld.payment.editOrder"),
        }),
      );
      return;
    }
    const staff = await this.$shop.checkPermission(["orderManage/tableSessionEditOrder"]);
    if (staff === false) return false;

    await this.$feathers.service("actionLogs").create({
      session: this.session.item._id,
      view: getID(this.session.item.view),
      staff: staff?._id || this.$shop.staffId,
      type: "orderManage/tableSessionEditOrder",
    });

    this.$emit("editOrder");
  }

  async refund(type: string) {
    if (this.mobile && !this.$shop.shopData?.handheldPaymentEnabled) {
      this.$store.commit(
        "SET_ERROR",
        this.$t("pos.mobile.handheld.payment.paymentFunctionNotEnable", {
          status: this.$t("pos.mobile.handheld.payment.repay"),
        }),
      );
      return;
    }
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("tableView.repay") + " ？ ",
        content: this.$t("tableView.repayDialog"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        confirm: this.$t("tableView.repay"),
        confirmLock: !this.$shop.hasPermission(["paymentManage/tableSessionRefundPayment"]),
      },
      {
        maxWidth: "500px",
      },
    );
    if (!c) return;
    const staff = await this.$shop.checkPermission(["paymentManage/tableSessionRefundPayment"]);
    if (staff === false) return;
    this.loading = true;
    try {
      const payment = await this.$paymentManager.refundPayment(this.payment);
      await this.session?.refundSplittedPayment(payment);
      await this.session?.reload?.();

      if (payment.status !== "refunded") {
        return;
      }

      await this.session?.printOrder?.({
        payment: getID(payment._id),
        refund: true,
        ongoingPayment: true,
      });

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "paymentManage/tableSessionRefundPayment",
        detail: { payment: [payment] },
      });

      this.$emit("refund");
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }
}
