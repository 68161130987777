
import _, { find } from "lodash";
import { Component, Vue, Watch, PropSync, VModel, FindType, FindPopRawType, getID } from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import type { TableSessionRef } from "~/plugins/table";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { ObjectID } from "@db";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class OrderSystemPeopleCrm extends Vue {
  @VModel()
  menu: boolean;

  @PropSync("session")
  sessionSync: TableSession;

  @PropSync("crmMenu")
  crmMenuSync: boolean;

  @PropSync("editUserScreen")
  editUserScreenSync: boolean;

  @PropSync("editUserId")
  editUserIdSync: string;

  @PropSync("showMessage")
  showMessageSync: boolean;

  @PropSync("message")
  messageSync: string;

  guests: ObjectID[] = [];
  member: FindType<"appUsers"> = null;
  originalOrderTags: ObjectID[] = [];
  originalCustomerTags: ObjectID[] = [];
  tempUserName = "";
  customerName = "";
  customerPhone = "";

  pointRecord = [];

  async loadData() {
    this.customerName = this.sessionSync.userName;
    this.customerPhone = this.sessionSync.userPhone;
    if (this.sessionSync.user) {
      this.member = await this.$feathers.service("appUsers").get(this.sessionSync.user, this.query);
      await this.loadPointRecord(this.sessionSync.user);
    }
  }

  async beforeMount() {
    this.guests = this.sessionSync.guests;
    //this.selectedMemberStoreCoin = this.sessionSync.user;
  }

  onOrderTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalOrderTags)) {
      this.originalOrderTags = this.member.orderTags;
      this.$feathers.service("appUsers").patch(this.sessionSync.user, {
        orderTags: e.map(tag => tag._id),
        orderTagsModifiedDate: new Date(),
      });
    }
  }

  onCustomerTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalCustomerTags)) {
      this.originalCustomerTags = this.member.customerTags;
      this.$feathers.service("appUsers").patch(this.sessionSync.user, {
        customerTags: e.map(tag => tag._id),
        customerTagsModifiedDate: new Date(),
      });
    }
  }

  onGuestsUpdate(e, i) {
    if (this.guests[i] == null) {
      this.guests.splice(i, 1, e);
    }
  }

  async loadPointRecord(user) {
    this.pointRecord = await this.$feathers
      .service("users/points/available")
      .find({ query: { user: getID(user), tag: ["point", "dollar"] } });
  }

  compareRef(a: TableSessionRef, b: TableSessionRef) {
    return !!a === !!b && a && b && a.table === b.table && a.split === b.split;
  }

  get targetName() {
    return this.sessionSync.target ? this.sessionSync.target.session.getSplitName(this.sessionSync.target) : "";
  }

  get readonly() {
    return this.sessionSync.status !== "ongoing" && this.sessionSync.status !== "toPay";
  }

  query = {
    $populate: ["vipLevel", "ranks.rank"],
  };

  async findUser(id: String) {
    try {
      const user = await this.$feathers.service("appUsers").get(getID(id), this.query);
      return user;
    } catch (e) {
      return null;
    }
  }

  async applyUserInfo(user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers">) {
    if (!user) return;
    this.member = await this.$feathers.service("appUsers").get(user._id, this.query);
    this.originalOrderTags = this.member.orderTags;
    this.originalCustomerTags = this.member.customerTags;
    if (user?.vipLevel && typeof user.vipLevel === "object" && user.status === "active") {
      const vipLevel: FindType<"vipLevels"> = user.vipLevel as any;
      await this.sessionSync.tryApplyVip(vipLevel);
    }
    if (user?.ranks && user?.ranks.length) {
      for (let rank of user?.ranks) {
        await this.sessionSync.tryApplyRank(rank.rank);
      }
    }
    // this.sessionSync.userName = this.sessionSync.userName !== "" ? this.sessionSync.userName : user.name;
    // this.sessionSync.userPhone = this.sessionSync.userPhone !== "" ? this.sessionSync.userPhone : user.phone;
    if (this.customerName == "") this.customerName = this.member.name;
    if (this.customerPhone == "") this.customerPhone = this.member.phone;
    this.sessionSync.item.userName = this.sessionSync.userName ?? user.name;
    this.sessionSync.item.userPhone = this.sessionSync.userPhone ?? user.phone;
    this.sessionSync.item.userGender = user.gender ?? this.sessionSync.userGender;

    await this.$feathers.service("actionLogs").create({
      session: getID(this.sessionSync.item._id),
      view: getID(this.sessionSync.item.view),
      staff: this.$shop.staffId,
      type: "orderManage/tableSessionSelectMember",
      detail: { user },
    });

    this.sessionSync.delaySave({
      userName: this.sessionSync.userName !== "" ? this.sessionSync.userName : user.name,
      userPhone: this.sessionSync.userPhone !== "" ? this.sessionSync.userPhone : user.phone,
      userGender: user.gender ?? this.sessionSync.userGender,
      user: user._id,
      discounts: this.sessionSync.discounts,
      ...this.sessionSync.cachedPriceDetails,
    });
    // await this.sessionSync.atomic({
    //   userGender: user.gender ?? this.sessionSync.userGender,
    //   user: user._id,
    //   ...this.sessionSync.cachedPriceDetails,
    // });

    this.sessionSync.syncOrder();
  }

  get user() {
    return this.sessionSync.user;
  }

  set user(v) {
    if (v === this.sessionSync.user) return;
    this.sessionSync.setUser(v as any);
    if (v) {
      this.loadPointRecord(v);
    }
  }

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active" && (!user.expiry || new Date(user.expiry) > new Date());
  }

  flushUpdates() {
    this.sessionSync.updateCoupons();
    this.sessionSync.delaySave({
      discounts: this.sessionSync.discounts,
      ...this.sessionSync.cachedPriceDetails,
    });
    this.sessionSync.syncOrder();
  }

  async confirm() {
    // this.sessionSync.setGuests(this.guests.map(guest => getID(guest)));
    // if (this.selectedMemberStoreCoin !== this.sessionSync.user) {
    //   this.guests = this.guests.filter(guest => guest !== this.selectedMemberStoreCoin);
    //   this.guests.push(this.sessionSync.user);
    //   this.sessionSync.user = this.selectedMemberStoreCoin;
    // }
    this.sessionSync.guests = this.guests;
    // const res = this.$openDialog(
    //   import("~/components/dialogs/SuccessDialog.vue"),
    //   {
    //     title: this.$t("peopleCrm.successfullySaved"),
    //   },
    //   {
    //     maxWidth: "400px",
    //   },
    // );
    this.showMessageSync = true;
    this.messageSync = this.$t("peopleCrm.successfullySaved").toString();
    this.crmMenuSync = false;
  }

  // @Watch("sessionSync.user")
  // onSessionUserUpdated() {
  //   this.selectedMemberStoreCoin = this.sessionSync.user;
  // }

  @Watch("sessionSync.capacity")
  onCapacityUpdate() {}

  @Watch("customerName")
  onCustomerNameUpdate() {
    this.sessionSync.item.userName = this.customerName;
    this.sessionSync.delaySave({
      userName: this.customerName,
    });
    this.sessionSync.syncOrder();
  }

  @Watch("customerPhone")
  onCustomerPhoneUpdate() {
    console.log("customerPhone", this.customerPhone);
    this.sessionSync.item.userPhone = this.customerPhone;
    this.sessionSync.delaySave({
      userPhone: this.customerPhone,
    });
    this.sessionSync.syncOrder();
  }

  async clearMember() {
    // this.sessionSync.user = null;
    this.user = null;

    await this.$feathers.service("actionLogs").create({
      session: getID(this.sessionSync.item._id),
      view: getID(this.sessionSync.item.view),
      staff: this.$shop.staffId,
      type: "orderManage/tableSessionDeselectMember",
    });

    this.sessionSync.delaySave({
      userName: null,
      userPhone: null,
      userGender: null,
      user: null,
      discounts: this.sessionSync.discounts,
      ...this.sessionSync.cachedPriceDetails,
    });
    this.tempUserName = null;
    this.member = null;
  }
}
