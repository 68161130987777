
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import _ from "lodash";
import { type } from "os";
import VueI18n from "vue-i18n/types";

type productDisplayMode = {
  _id: string;
  name: VueI18n.TranslateResult;
  normalIcon: string;
  activeIcon: string;
  displayImage: boolean;
  fullScreenImage: boolean;
};

@Component
export default class DisplaySetting extends mixins(Dialog()) {
  async saveItem() {
    this.modalResult(true);
  }

  async reconnectScreen() {
    await this.$shop.connectScreen(true);
  }

  sortModes = [
    {
      _id: "default",
      name: { $t: "productSort.default" },
    },
    {
      _id: "alphabetFirst",
      name: { $t: "productSort.alphabetFirst" },
    },
    {
      _id: "orderFirst",
      name: { $t: "productSort.orderFirst" },
    },
  ];

  newOrderTypes = [
    {
      _id: "followLast",
      name: { $t: "newOrderType.followLast" },
    },
    {
      _id: "dineInNoTable",
      name: { $t: "newOrderType.dineInNoTable" },
    },
    {
      _id: "takeAway",
      name: { $t: "newOrderType.takeAway" },
    },
  ];

  productDisplayMode: productDisplayMode[] = [
    {
      _id: "textOnly",
      name: this.$t("productDisplay.textOnly"),
      normalIcon: "$textOnlyNormal",
      activeIcon: "$textOnlyActive",
      displayImage: false,
      fullScreenImage: false,
    },
    {
      _id: "smallPic",
      name: this.$t("productDisplay.smallPic"),
      normalIcon: "$smallPicNormal",
      activeIcon: "$smallPicActive",
      displayImage: true,
      fullScreenImage: false,
    },
    {
      _id: "fullPic",
      name: this.$t("productDisplay.bgPic"),
      normalIcon: "$fullPicNormal",
      activeIcon: "$fullPicActive",
      displayImage: true,
      fullScreenImage: true,
    },
  ];

  selectingDisplayMode(item: productDisplayMode) {
    this.$shop.localOptions.productDisplay = item._id;
    this.$shop.localOptions.showProductImage = item.displayImage;
    this.$shop.localOptions.fullScreenProductImage = item.fullScreenImage;
  }

  remoteFontDiv = 5;

  getScale(value) {
    return (value < 0 ? 100 - (Math.abs(value) / this.remoteFontDiv) * 80 : 100 + value * 10).toFixed(0);
  }

  get mscale() {
    return this.$shop.localOptions.fontScale ?? 0;
  }
  set mscale(v) {
    this.$shop.localOptions.fontScale = v;
  }
}
