
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import type { ProductType } from "~/plugins/shop";
import { getOptions } from "@common";
import { CartItem, ProductOptionSelect, ProductOptionSelectWithPriceItem, TableRef } from "@common/table/cart";
import { ProductStock } from "~/plugins/table/stock";
import _ from "lodash";
import { fromDb, toDb } from "@common/table/ingredients";
import EditorSinglePage from "@schemaEditor/EditorSinglePage.vue";
import { getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";

@Component({
  components: {
    EditorSinglePage,
  },
})
export default class ProductInfo extends mixins(Dialog) {
  @Prop()
  product: ProductType;

  fromDb = fromDb;

  stockInfo: ProductStock = null;

  cartItem: CartItem = null;
  get productOptions() {
    return this.cartItem.productOptionsWithPrice;
  }

  get schema() {
    return this.$schemas.getConfigByApiPath("productStocks");
  }

  get modeName() {
    return this.schema?.fields?.find?.(it => it.path === "mode")?.name;
  }
  get stockName() {
    return this.schema?.fields?.find?.(it => it.path === "quantity")?.name;
  }
  get modeEnum() {
    return this.schema?.fields?.find?.(it => it.path === "mode")?.props?.items ?? [];
  }
  get onlineModeName() {
    return this.schema?.fields?.find?.(it => it.path === "onlineMode")?.name;
  }
  get onlineModeEnum() {
    return this.schema?.fields?.find?.(it => it.path === "onlineMode")?.props?.items ?? [];
  }

  beforeMount() {
    this.cartItem = new CartItem({
      parent: this,
      propsData: {
        product: this.product as any,
        initOptions: (this.product.options ?? [])
          .map(
            option =>
              ({
                options: this.$shop.productOptionDict[getID(option)],
                values: [],
              }) as ProductOptionSelect,
          )
          .filter(it => !!it.options),
      },
    });
    this.cartItem.quantity = 1;
    this.stockInfo = new ProductStock({
      parent: this,
    });
    this.stockInfo.item = _.cloneDeep(this.product.stock);
    this.stockInfo.reload();
    this.stockInfo.subscribe();
  }

  get autoStock() {
    let max = null;
    for (let ingredient of this.cartItem.productIngredients) {
      if (!ingredient.amount) continue;

      const amount = this.$tableManager.getIngredientStock(ingredient.ingredient);
      const cur = Math.max(0, Math.floor(amount / ingredient.amount));
      if (max === null || cur < max) max = cur;
    }
    return max;
  }

  async openIngredientInfo(id: any) {
    const item = this.$tableManager.ingredientDict[`${id}`];
    if (!item) return;

    await this.$openDialog(
      import("~/components/dialogs/IngredientInfo.vue"),
      {
        ingredient: item,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  toggleItem(item: ProductOptionSelectWithPriceItem) {
    item.toggleSelection();
  }

  async openProduct(productId: any) {
    const product = this.$shop.productDict[productId];
    if (!product) return;
    await this.$openDialog(
      import("~/components/dialogs/ProductInfo.vue"),
      {
        product,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }
}
