
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync } from "@feathers-client";

@Component
export default class LanguagePicker extends Vue {
  lang = false;
  name = "";

  async mounted() {
    let defaultLocale = (this.$i18n as any).localeProperties.locale;
    let currentLocale = (this.$i18n as any).locales.find(it => it.locale === defaultLocale);
    if (!currentLocale) {
      console.error(`Could not find a locale with the code ${defaultLocale}`);
      return;
    }
    this.changeLocale(currentLocale);
  }

  async changeLocale(item) {
    await this.$i18n.loadLocale(item.locale);
    this.$i18n.locale = item.locale;
    this.name = item.name;
    this.lang = false;
  }
}
