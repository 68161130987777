
import { Component, Prop, Vue, mixins } from "@feathers-client";
import {
  Expression,
  PrinterTemplateNodeBarcode,
  PrinterTemplateNodeBase,
  PrinterTemplateNodeCol,
  PrinterTemplateNodeGraph,
  PrinterTemplateNodeImage,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeInterpolationLabel,
  PrinterTemplateNodeLabelStaticText,
  PrinterTemplateNodeLabelText,
  PrinterTemplateNodeQR,
  PrinterTemplateNodeRoot,
  PrinterTemplateNodeRow,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  PrinterTemplateNodeTextLabel,
  compile,
  compileLabel,
} from "pos-printer-template";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";
import { PrinterTemplateNodeBlock } from "pos-printer-template";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorStyle extends mixins(PrinterEditorEditBase) {
  get textPositions() {
    return [
      {
        _id: "left",
        icon: "$alignLeft",
      },
      {
        _id: "center",
        icon: "$alignCenter",
      },
      {
        _id: "right",
        icon: "$alignRight",
      },
    ];
  }

  get fontVariants() {
    return [
      {
        _id: "normal",
        icon: "$fontNormal",
      },
      {
        _id: "italic",
        icon: "$fontItalic",
      },
      {
        _id: "bold",
        icon: "$fontBold",
      },
    ];
  }

  get textColors() {
    return [
      {
        _id: "black",
        icon: "$textBlack",
      },
      {
        _id: "red",
        icon: "$textRed",
      },
    ];
  }

  get inverteds() {
    return [
      {
        _id: "normal",
        icon: "$textBgNormal",
      },
      {
        _id: "inverted",
        icon: "$textBgInverted",
      },
    ];
  }

  get fontSize() {
    const e = this.fontStyleNode?.props?.["fontSize"];
    if (this.type === "thermal" && !e) {
      const font = this.fontStyleNode?.props?.["size"];
      if (font) {
        const fontVal = font.expression;
        if (fontVal && !isNaN(+fontVal)) {
          return +fontVal * 12 + 24;
        }
      }
    }
    if (!e || !e.isStatic) return undefined;
    if (this.type === "label") {
      const font = this.fontStyleNode?.props?.["font"];
      if (font && font.isStatic) {
        const fontVal = font.expression;
        if (fontVal.endsWith("px")) {
          return parseInt(fontVal.split(",")[1]);
        }
      }
    }
    return +e.expression;
  }

  set fontSize(value: number | string) {
    if (!this.fontStyleNode) return;
    if (!value || value === "0") {
      this.fontStyleNode.deleteProp("size");
      this.fontStyleNode.deleteProp("fontSize");
    } else if (this.type === "label") {
      // const relSize = Math.round((Math.round(+value / 24) - 1) / 0.5 + 1);
      this.fontStyleNode.setProp("fontSize", `1`);
      const font = this.fontStyleNode?.props?.["font"]?.eval?.(this.selectedContext);
      let fontFamily = "TST24.BF2";
      if (font && font.endsWith("px")) {
        fontFamily = font.split(",")[0];
      }
      this.fontStyleNode.setProp("font", `${fontFamily},${value}px`);
    } else {
      const relSize = Math.max(0, Math.floor((+value / 24 - 1) / 0.5));
      this.fontStyleNode.setProp("size", relSize.toString());
      this.fontStyleNode.setProp("fontSize", value.toString());
    }
    this.$emit("update");
  }

  get color() {
    const e = this.colorStyleNode?.props?.["color"];
    if (!e) return undefined;
    if (e.expression === "red" || e.expression === "1" || e.expression === "true" || !e.expression) return "red";
    return "black";
  }

  set color(value: string) {
    if (!this.colorStyleNode) return;
    if (!value) {
      this.colorStyleNode.deleteProp("color");
    } else {
      this.colorStyleNode.setProp("color", value);
    }
    this.$emit("update");
  }

  get variant() {
    const e = this.fontStyleNode?.props?.["weight"];
    const i = this.fontStyleNode?.props?.["fontStyle"];
    if (!e && !i) return [];
    const result: string[] = [];
    if (e && (e.expression === "bold" || e.expression === "1" || e.expression === "true" || !e.expression))
      result.push("bold");
    if (i && (i.expression === "italic" || i.expression === "1" || i.expression === "true" || !i.expression))
      result.push("italic");
    if (!result.length) {
      return ["normal"];
    }
    return result;
  }

  set variant(value: string[]) {
    if (!this.fontStyleNode) return;
    if (!value?.length) {
      this.fontStyleNode.deleteProp("weight");
      this.fontStyleNode.deleteProp("fontStyle");
    } else {
      if (value.at(-1) === "normal") {
        this.fontStyleNode.setProp("weight", "normal");
        this.fontStyleNode.setProp("fontStyle", "normal");
      } else {
        this.fontStyleNode.setProp("weight", value.includes("bold") ? "bold" : "normal");
        this.fontStyleNode.setProp("fontStyle", value.includes("italic") ? "italic" : "normal");
      }
    }
    this.$emit("update");
  }

  get align() {
    const e = this.alignNode?.props?.["align"];
    if (!e) return undefined;
    return e.expression;
  }

  set align(value: string) {
    if (!this.alignNode) return;
    if (!value) {
      this.alignNode.deleteProp("align");
    } else {
      this.alignNode.setProp("align", value);
    }
    this.$emit("update");
  }

  get inverted() {
    const e = this.parentCol?.props?.["inverted"];
    if (!e) return "normal";
    if (e.expression === "1" || e.expression === "true" || !e.expression) return "inverted";
    return "normal";
  }

  set inverted(value: string) {
    if (!this.parentCol) return;
    if (value !== "inverted") {
      this.parentCol.deleteProp("inverted");
    } else {
      this.parentCol.setProp("inverted", "1");
    }
    this.$emit("update");
  }

  get colPadding() {
    const e = this.parentRow?.props?.["colPadding"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set colPadding(value: number | string) {
    if (!this.parentRow) return;
    if (!value || value === "0") {
      this.parentRow.deleteProp("colPadding");
    } else {
      this.parentRow.setProp("colPadding", value.toString());
    }
    this.$emit("update");
  }

  get fontStyleNode() {
    switch (this.selectedNode.constructor) {
      case PrinterTemplateNodeText:
      case PrinterTemplateNodeInterpolation:

      case PrinterTemplateNodeLabelStaticText:
      case PrinterTemplateNodeInterpolationLabel:
      case PrinterTemplateNodeTextLabel:
        return this.selectedParent;
      case PrinterTemplateNodeBlock:
      case PrinterTemplateNodeCol:
      case PrinterTemplateNodeRow:
      case PrinterTemplateNodeLabelText:
        return this.selectedNode;
    }
    return null;
  }

  get colorStyleNode() {
    if (this.type === "label") {
      return null;
    }
    // since bitmap table does not support color change yet, we only apply color to the row
    if (this.parentRow) {
      return this.parentRow;
    }
    return this.fontStyleNode;
  }

  get alignNode() {
    switch (this.selectedNode.constructor) {
      case PrinterTemplateNodeBlock:
      case PrinterTemplateNodeImage:
      case PrinterTemplateNodeQR:
      case PrinterTemplateNodeGraph:
      case PrinterTemplateNodeBarcode:
      case PrinterTemplateNodeCol:

      case PrinterTemplateNodeLabelText:
        return this.selectedNode;

      case PrinterTemplateNodeText:
      case PrinterTemplateNodeInterpolation:

      case PrinterTemplateNodeLabelStaticText:
      case PrinterTemplateNodeInterpolationLabel:
      case PrinterTemplateNodeTextLabel:
        return this.selectedParent;
    }
  }

  get textNode() {
    switch (this.selectedNode.constructor) {
      case PrinterTemplateNodeText:
      case PrinterTemplateNodeInterpolation:
      case PrinterTemplateNodeLabelStaticText:
      case PrinterTemplateNodeInterpolationLabel:
      case PrinterTemplateNodeTextLabel:
        return this.selectedNode;
    }
  }

  get textReadOnly() {
    return (
      !(this.selectedNode instanceof PrinterTemplateNodeText) &&
      !(this.selectedNode instanceof PrinterTemplateNodeTextLabel)
    );
  }

  get textContent() {
    if (
      this.selectedNode instanceof PrinterTemplateNodeText ||
      this.selectedNode instanceof PrinterTemplateNodeTextLabel
    ) {
      return this.selectedNode.content;
    } else if (
      this.selectedNode instanceof PrinterTemplateNodeInterpolation ||
      this.selectedNode instanceof PrinterTemplateNodeInterpolationLabel
    ) {
      if (this.selectedContext) {
        return this.textNode.getTextValue(this.selectedContext);
      }
    } else if (this.selectedNode instanceof PrinterTemplateNodeLabelStaticText) {
      return this.selectedNode.text;
    }
  }

  set textContent(value: string) {
    if (
      this.selectedNode instanceof PrinterTemplateNodeText ||
      this.selectedNode instanceof PrinterTemplateNodeTextLabel
    ) {
      if (value.includes("\n")) {
        // check is pre
        if (this.selectedNode instanceof PrinterTemplateNodeText) {
          if (this.parentTextBlock && !this.parentTextBlock.isPre) {
            this.parentTextBlock.isPre = true;
          }
        } else {
          if (this.labelTextNode && !this.labelTextNode.isPre) {
            this.labelTextNode.isPre = true;
          }
        }
      }
      this.selectedNode.content = value;
    }
    this.$emit("update");
  }

  get textType() {
    if (this.selectedNode instanceof PrinterTemplateNodeText) {
      return "text";
    } else if (this.selectedNode instanceof PrinterTemplateNodeInterpolation) {
      if (this.textTranslateId) {
        return "translate";
      }
      return "interpolation";
    }
  }

  get textTranslateId() {
    if (this.selectedNode instanceof PrinterTemplateNodeInterpolation) {
      const exp = this.selectedNode.expression;
      const str = exp.expression.trim();
      if (str.startsWith("$t(") && str.endsWith(")")) {
        const inner = str.slice(3, -1).trim();
        if ((inner.startsWith('"') && inner.endsWith('"')) || (inner.startsWith("'") && inner.endsWith("'"))) {
          return inner.slice(1, -1);
        }
      }
    }
  }

  get isBitmap() {
    return this.parentRootTextBlockOrRow?.isBitmap;
  }

  set isBitmap(value: boolean) {
    if (this.parentRootTextBlockOrRow) {
      this.parentRootTextBlockOrRow.isBitmap = value;
    }
    this.$emit("update");
  }

  convertToStaticText() {
    if (this.textTranslateId && this.selectedParent) {
      const newNode = PrinterTemplateNodeText.createFromText(this.textContent);
      this.selectedParent.replaceChild(this.selectedNode, newNode);
      this.$emit("replace", newNode);
    }
  }
}
