
import { FindPopRawType } from "@feathers-client";
import _ from "lodash";
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class CouponTicker extends Vue {
  @Prop()
  coupon: FindPopRawType<["coupon"], "userCoupons">;

  get shopLogo() {
    return this.$shop?.shopData?.images[0];
  }
}
