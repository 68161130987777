
import { ProductLine } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { OrderGroupChunk } from "~/plugins/table/kds";
import { StatusType } from "~/pages/table/kds.vue";
import { KdsProductBlock, KdsProductItem } from "~/plugins/table/kdsProduct";
import { getID } from "@feathers-client";

@Component
export default class TableKDSProductBlock extends Vue {
  @Prop()
  block: KdsProductBlock;

  get headerColor() {
    switch (this.block.group.warnStatus) {
      case "urgent":
        return "bg-red400";
      case "warn":
        return "bg-orange500";
    }
    return "bg-grey400";
  }

  get categoryColor() {
    return this.$shop.catDict[getID(this.block.group.productInfo?.category)]?.kdsColorTag;
  }

  getIcon(type: string) {
    switch (type) {
      case "D":
        return "$dineIn";
      case "T":
        return "$ic24-takeaway";
      default:
        return "$dineIn";
    }
  }

  getTime(date: Date) {
    return this.$moment(date).format("HH:mm");
  }

  getCustomRemark(hash: string) {
    return hash.split("|")?.[2];
  }

  async shortpress(product: KdsProductItem) {
    await this.$openDialog(
      import("./productStockNum.vue"),
      {
        product: product.parent.productInfo,
        hash: product.hash,
        initQuantity: product.displayQuantity,
      },
      {
        maxWidth: "min(50vw,360px)",
      },
    );
  }

  get showProductName() {
    if (this.$shop.localOptions.kdsShowShortnameDisable) {
      return this.$td(this.block.group.productInfo.name);
    } else {
      return this.$td(this.block.group.productInfo.shortName) ?? this.$td(this.block.group.productInfo.name);
    }
  }
}
