
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType, FindPopRawType } from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import type { TableSessionRef } from "~/plugins/table";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import moment from "moment";

@Component({
  components: { EditorDatePicker, EditorCheckbox },
})
export default class People extends Vue {
  @VModel()
  menu: boolean;

  @Prop()
  session: TableSession;

  mpickupTime: Date = null;
  contactName = "";
  contactPhone = "";

  mPhoneOrder = false;

  get pickupTime() {
    return this.mpickupTime;
  }

  set pickupTime(value) {
    this.mpickupTime = value;
    this.session.delaySave({
      pickUpTime: value,
    });
    this.session.syncOrder();
  }

  get phoneOrder() {
    return this.mPhoneOrder;
  }

  set phoneOrder(value) {
    this.mPhoneOrder = value;
    this.session.delaySave({
      phoneOrder: value,
    });
    this.session.syncOrder();
  }

  mounted() {
    if (this.session.type == "takeAway" && this.session.sessionData.pickUpTime) {
      this.mpickupTime = this.session.sessionData.pickUpTime;
    }
    this.contactName = this.session.userName;
    this.contactPhone = this.session.userPhone;
    this.mPhoneOrder = this.session.sessionData.phoneOrder;
  }

  get cutOffMinutes() {
    const start = this.session?.sessionData?.startTime;
    const end = this.session?.sessionData?.orderCutoffTime ?? start;
    return moment.duration(moment(end).diff(start)).asMinutes();
  }

  set cutOffMinutes(v) {
    this.session.delaySave({
      orderCutoffTime: v <= 0 ? null : moment(this.session.sessionData.startTime).add(v, "minute").toDate(),
    });
    this.session.syncOrder();
  }

  get leaveMinutes() {
    const start = this.session?.sessionData?.startTime;
    const end = this.session?.sessionData?.orderLeaveTime ?? start;
    return moment.duration(moment(end).diff(start)).asMinutes();
  }

  set leaveMinutes(v) {
    this.session.delaySave({
      orderLeaveTime: v <= 0 ? null : moment(this.session.sessionData.startTime).add(v, "minute").toDate(),
    });
    this.session.syncOrder();
  }

  get orderCutoffTimeText() {
    return this.session?.sessionData?.orderCutoffTime
      ? moment(this.session.sessionData.orderCutoffTime).format("HH:mm")
      : "-";
  }

  get orderCutoffTimeColor() {
    const time = this.session?.sessionData?.orderCutoffTime;
    if (!time) return "";

    const min = moment.duration(moment(time).diff(new Date())).asMinutes();
    if (min < 0) {
      return "text-red300";
    } else if (min < 15) {
      return "text-yellow000";
    }
  }

  get leaveTimeText() {
    return this.session?.sessionData?.orderLeaveTime
      ? moment(this.session.sessionData.orderLeaveTime).format("HH:mm")
      : "-";
  }

  get leaveTimeColor() {
    const time = this.session?.sessionData?.orderLeaveTime;
    if (!time) return "";

    const min = moment.duration(moment(time).diff(new Date())).asMinutes();
    if (min < 0) {
      return "text-red300";
    } else if (min < 15) {
      return "text-yellow000";
    }
  }

  // @Watch("pickupTime")
  // onChangePickupTime() {
  //   this.session.delaySave({
  //     pickUpTime: this.pickupTime,
  //   });
  //   this.session.syncOrder();
  // }
  @Watch("session")
  onSessionUpdate() {
    this.pickupTime = null;
  }

  @Watch("contactName")
  @Watch("contactPhone")
  onContactUpdate() {
    this.session.delaySave({
      userName: this.contactName,
      userPhone: this.contactPhone,
    });
  }

  @Watch("session.userName")
  @Watch("session.userPhone")
  onSessionContactUpdate() {
    this.contactName = this.session.userName;
    this.contactPhone = this.session.userPhone;
  }
}
