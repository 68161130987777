import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { FindType, checkID, getID, FindPopRawType } from "@feathers-client";
import { checkTime, getSplitName, MapFields, MapSubView, shapes } from "./utils";
import { LangArrType } from "@feathers-client/i18n";
import type { TableView, TableSessionRef, TableItem } from "./view";
import _ from "lodash";
import {
  receiptSequencer,
  tableSessionLinkSequencer,
  pickupLocationPrintForTable,
  printTwInvoice,
  ensureTemplateJob,
  tableReceiptOrderOnlySequencer,
} from "../printer/invoiceSequencer";
import moment from "moment";
import { CartItem, ProductOptionSelect, TableRef } from "@common/table/cart";
import { SessionBase, AddCartOpts } from "@common/table/sessionBase";
import type { ProductType } from "../shop";
import { getProductTree, getProductTreeInfo, StockLevel, StockInfo } from "@common/table/util";
import { sumIngredients } from "@common/table/ingredients";
import { CacheLoader, updateCachedValue } from "@feathers-client/cacheLoader";
import type { PrintJob } from "pos-printer/printJob";
import { POSUserInfo } from "@supacity/pos";
import { OrderGroup } from "./kds";
import { KdsProductGroupRef } from "./kdsProduct";
import type { PaymentMethodFull } from "~/components/table/orderSystem/checkoutBase";
import type { ReceiptInput } from "~/dep/typings/common/table/invoiceSequencer";
import { playAudioFile } from "@feathers-client/qrcode-scanner/beep";

const audioFile = require("~/assets/audio/kds.mp4");

export type ProductLine = Partial<FindType<"tableSessions">["products"][number]>;
export type ProductLineOption = ProductLine["options"][number];
export type SplitBill = Partial<FindType<"tableSessions">["splittedPayments"][number]>;

export interface OrderKitchenView {
  order: FindType<"tableSessions">;
  product: ProductLine;
  name: LangArrType;
  index: number;
}

export interface SessionMoveActionTo {
  ref: TableSessionRef;
  capacity: number;
}

export interface SessionMoveAction {
  actions: {
    from?: {
      ref: TableSessionRef;
      x?: number;
      y?: number;
    };
    to?: SessionMoveActionTo;
  }[];
  orders?: {
    line: ProductLine;
    target: TableSessionRef;
    sourceSession?: TableSession;
    chairId?: string;
  }[];
  newSessions?: TableSession[];
}

@Component
export class TableSession extends mixins(
  SessionBase,
  MapFields(
    "tableSessions",
    [
      "status",
      "products",
      "endTime",
      "sessionName",
      "discounts",
      "user",
      "userName",
      "userEmail",
      "userPhone",
      "userGender",
      "section",
      "tips",
      "tipsWithoutTaxAdj",
      "adjusts",
      "entFee",
      "receiptPrintTime",
      "type",
      "modifiers",
      "remarks",
      "startTime",
      "endTime",
      "bookedTime",
      "createTime",
      "paidTime",
      "desiredCapacity",
      "bookingSource",
      "restoreKey",
      "payments",
      "testing",
      "splittedPayments",
      "guests",
      "topUp",
      "beeperNum",
      "checkoutFrom",
      "adult",
      "children",
      "babySeat",
      "situation",
    ] as const,
    ["view"],
    {
      status: "ongoing",
      type: "dineIn",
      discounts: [],
      products: [],
      section: null,
      tips: 0,
      adjusts: 0,
      entFee: 0,
      source: "pos",
      user: null,
      modifiers: [],
      desiredCapacity: 0,
      userPhone: "",
      userName: "",
      userEmail: "",
      userGender: "",
      bookingSource: null,
      testing: false,
      view: null,
      guests: [],
      topUp: null,
      beeperNum: null,
      remarks: null,
      adult: 0,
      children: 0,
      babySeat: 0,
      situation: [],
    },
  ),
) {
  created() {
    this.$on("updated", this.syncOrder);
  }

  beforeDestroy() {
    if (!this.dangling) {
      this.cleanup();
      this.$tableManager.$off("tick", this.updateTimer);
      if (this.$tableManager._kdsProductManager) {
        this.$tableManager._kdsProductManager.removeOrder(this);
      }
    }
    if (this.subscribed) {
      this.$feathers.service("tableSessions").off("patched", this._subscribeUpdated);
    }
  }

  subscribed = false;

  startSubscribe() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.$feathers.service("tableSessions").on("patched", this._subscribeUpdated);
  }

  _subscribeUpdated(item) {
    if (!item?._id || !this.item?._id) return;
    if (checkID(item, this.item)) {
      this.item = item;
    }
  }

  //Boolean For Void All Payment
  voidingPayment = false;

  // #region override getters

  get takeawayStatus() {
    if (this.item.tvStatus === "making") {
      return "sent";
    } else if (this.item.tvStatus === "ready") {
      if (this.$shop?.shopData?.kdsEnabled) {
        return "done";
      } else {
        return "sent";
      }
    } else if (this.item.tvStatus === "taken") {
      return "finish";
    } else if (this.item.status === "done") {
      return "finish";
    } else {
      return "hold";
    }

    // if (this.products.some(product => product.status === "hold")) {
    //   return "hold";
    // } else if (this.products.every(product => product.kdsStatus === "done")) {
    //   return "done";
    // } else {
    //   return "sent";
    // }
  }

  get sessionData(): FindType<"tableSessions"> {
    return this.item as any;
  }
  get availableSections(): FindType<"sections">[] {
    return this.$shop.sections.filter(
      it => it.timeRange && !!it.timeRange.find(range => checkTime(range.weekdays, range.from, range.to, new Date())),
    );
  }

  get availableTimeConditionDict() {
    return this.$shop.availableTimeConditionDict;
  }

  get checkAvailability() {
    return true;
  }

  get computeDiscountHint() {
    return this.$shop.shopData?.openDiscountHint;
  }

  get sectionData(): FindType<"sections"> {
    return this.selectedSection;
  }

  get targetRef(): TableRef {
    return this.target && this.target.table
      ? {
          table: this.target.table.id,
          split: this.target.split,
          chairId: null, // TODO chair id
        }
      : null;
  }

  get shopData(): FindType<"shops"> {
    return this.shop as any;
  }

  get taxSettings() {
    return this.$shop.taxSettings || [];
  }

  get surchargeSettings() {
    return this.$shop.surchargeSettings || [];
  }

  get categoriesDict() {
    return this.$shop.catDict;
  }

  get subCategoriesDict() {
    return this.$shop.subCatDict;
  }

  async getProductWithOptions(product: string, getCfg?: any) {
    getCfg = getCfg || {};
    if (getCfg.useDefaults === undefined && getCfg?.fromProduct) {
      getCfg.useDefaults = this.$shop.localOptions.useDefaultsForSetItem;
    }

    const productInfo = this.$shop.productDict[product];
    if (productInfo) {
      return {
        product: productInfo as any,
        options: this.getOptionsWithProductInfo(
          (productInfo.options ?? []).map(opt => this.$shop.productOptionDict[`${opt}`]).filter(it => !!it),
          getCfg,
        ),
      };
    } else {
      return (SessionBase as any).options.methods.getProductWithOptions.call(this, product, getCfg);
    }
  }

  addToCartFromProduct(product: ProductType, opts?: AddCartOpts) {
    const options = this.getOptionsWithProductInfo(
      (product.options ?? []).map(opt => this.$shop.productOptionDict[`${opt}`]).filter(it => !!it),
      {
        useDefaults: this.$shop.localOptions.useDefaultsForNormalItem,
      },
    );

    return this.addToCart(
      {
        product: product as any,
        options,
      },
      opts,
    );
  }

  // #endregion

  init(item: Partial<FindType<"tableSessions">>, dangling = false) {
    if (!item.shop) {
      item.shop = getID(this.$shop.shopData) as any;
    }
    if (this.$shop.shopData && !item._id) {
      item.modifiers = this.$shop.shopData.modifiers || [];
    }
    this.item = item;
    this.dangling = dangling;
    if (!this.dangling) {
      this.updateRefs();
      this.$tableManager.$on("tick", this.updateTimer);
      this.updateTimer();
    }
    return this;
  }

  dangling = false;

  // #region shop data

  cachedShop: FindType<"shops"> | Promise<FindType<"shops">> = null;
  get asyncCachedShop() {
    if (!this.cachedShop) {
      this.cachedShop = (async () => {
        const result = await this.$feathers.service("shops").get(this.item.shop);
        this.cachedShop = result;
        return result;
      })();
    }
    if (this.cachedShop instanceof Promise) return null;
    return this.cachedShop;
  }
  get shop() {
    return (
      this.$shop.shopData &&
      (checkID(this.$shop.shopData, this.item.shop)
        ? this.$shop.shopData
        : this.item.shop
          ? null
          : this.asyncCachedShop)
    );
  }

  get shopId() {
    return getID(this.item.shop);
  }

  // #endregion

  get name() {
    return this.tableRefs.map(it => this.getSplitName(it)).join("/") || this.sessionData.tableRefName;
  }

  get startTimeText() {
    return this.item?.startTime ? moment(this.item.startTime).format("HH:mm") : "-";
  }

  get capacity() {
    return _.sumBy(this.tables, it => it.capacity) || 0;
  }

  set capacity(v) {
    if (!this.tables.length) return;
    const delta = v - this.capacity;
    const tables = _.cloneDeep(this.tables);
    tables[tables.length - 1].capacity += delta;
    this.tables = tables;
  }

  get totalCapacity() {
    return _.sumBy(this.tableRefs, it => it.capacity + it.table.remainCapacity) || 0;
  }

  get view() {
    // try to fix some invalid data
    return this.item?.view ? (this.$parent as TableView) : this.tableRefs?.[0]?.table?.view;
  }

  get selectedSection() {
    return this.item && this.$shop?.sectionDict?.[`${this.section}`];
  }

  get sessionNameNumber() {
    return this.sessionName?.match(/\d+/)?.[0] || "";
  }

  target: TableSessionRef = null;
  tableRefs: TableSessionRef[] = [];
  mselected = false;
  get selected() {
    return this.mselected;
  }
  set selected(v) {
    if (v && !this.mselected) {
      if (this.view?.selectedSession) {
        this.view.selectedSession.selected = false;
      }
      if (this.view) this.view.selectedSession = this;
    } else if (!v && this.mselected) {
      if (this.view?.selectedSession === this) {
        this.view.selectedSession = null;
      }
    }
    this.mselected = v;
  }
  selectedRef: TableSessionRef = null;

  get tables() {
    return this.item ? this.item.tables : [];
  }

  set tables(newItems: FindType<"tableSessions">["tables"]) {
    this.delaySave({
      tables: newItems,
    });
    // this.updateRefs();
  }

  cleanup() {
    if (this.dangling) return;
    for (let item of this.tableRefs) {
      const idx = item.table.sessions.indexOf(item);
      idx !== -1 && item.table.sessions.splice(idx, 1);
    }
    this.tableRefs = [];
  }

  lastCallId: number = null;

  @Watch("sessionData.prodSeq")
  async alertOrderIn() {
    if (this.sessionData.notifySeq !== undefined) {
      if (
        this.$shop.shopData?.enableBeepForIncomingOrder &&
        !this.$shop.shopData?.beepUntilAllOrderConfirmed &&
        !this.$shop.localOptions.muteSoundForIncomingOrder &&
        this.sessionData.prodSeq !== this.sessionData.notifySeq &&
        this.sessionData.prodSeq !== this.lastCallId
      ) {
        for (let i = 0; i < (this.$shop.shopData.beepCountForIncomingOrder ?? 1); i++) {
          await playAudioFile(audioFile, 100);

          await new Promise(resolve => {
            setTimeout(resolve, 3000);
          });
        }
      }

      if (this.sessionData.prodSeq !== this.sessionData.notifySeq) {
        if (!!this.sessionData.products.find(it => it.status === "pending")) {
          this.$store.commit("SET_SUCCESS", this.$t("pendingOrderList.alert"));
        }

        this.atomic({
          notifySeq: this.sessionData.prodSeq,
        });
      }
    }

    this.lastCallId = this.sessionData.prodSeq;
  }

  @Watch("tables")
  updateRefs() {
    if (this.dangling) return;
    this.cleanup();
    if (!this.startTime || this.endTime) return;
    const tables = this.tables.slice();
    for (let i = 0; i < tables.length; i++) {
      const item = tables[i];
      const table = this.$tableManager.viewItemDict[`${item.item}`];
      if (!table) continue;
      const ref: TableSessionRef = {
        session: this,
        table,
        split: item.split,
        capacity: item.capacity,
        index: i,
      };
      this.$emit("addRef", ref);
      this.$emit("addRef:" + ref.table.id, ref);
      this.tableRefs.push(ref);
      const insertIdx = _.sortedIndexBy(table.sessions, ref, it => -(it.session.startTime?.getTime?.() || 0));
      table.sessions.splice(insertIdx, 0, ref);
    }
    this.target = this.tableRefs[0] || null;
  }

  @Watch("selectedRef")
  onSelectedRef() {
    this.target = this.selectedRef || this.tableRefs[0] || null;
  }

  async printOrder(props?: {
    cashBox?: boolean;
    payment?: string;
    ongoingPayment?: boolean;
    user?: boolean;
    printTwInvoice?: boolean;
    refund?: boolean;
  }) {
    const { cashBox = false, payment: paymentId } = props || {};

    const jobs: PrintJob[] = [];
    // console.log(`deviceid = ${this.$shop.device._id}`)
    let receiptJobs = await this.$shop.getLocalPrinters(
      "table-payment-receipt",
      this.sessionData,
      this.$shop.device._id,
    );

    if (!props?.ongoingPayment) {
      if (this.status !== "done" && this.status !== "cancelled" && this.status !== "void") {
        receiptJobs = receiptJobs.filter(it => it.jobOptions?.tablePrintToPayWhenCheckBill);
      }
      if (cashBox) {
        receiptJobs = receiptJobs.filter(it => !it.jobOptions?.tableDontAutoPrintReceiptCash);
      }
    }
    if (!props?.refund && !props?.user) {
      receiptJobs = receiptJobs.filter(it => !it.jobOptions?.tableDontAutoPrintReceiptAll);
    }
    if (this.status === "cancelled") {
      receiptJobs = receiptJobs.filter(it => it.jobOptions?.autoPrintCancelReceipt);
    }
    if (!receiptJobs.length) {
      if (props?.user) {
        const job = await ensureTemplateJob(this, "table-payment-receipt");
        if (job) {
          receiptJobs.push(job);
        }
      }
      if (!receiptJobs.length) return;
    }

    try {
      const rePrint = !!this.receiptPrintTime;
      const currentPayment = props?.ongoingPayment
        ? null
        : (paymentId || this.item.payment) &&
          (await this.$feathers.service("payments").get(paymentId || this.item.payment));
      const currentMethod =
        currentPayment?.methodRef &&
        (this.$shop.paymentMethodDict[`${currentPayment.methodRef}`] ||
          (
            await this.$feathers.service("paymentMethods").find({
              query: {
                _id: `${currentPayment.methodRef}`,
                $paginate: false,
              },
              paginate: false,
            })
          )[0]);
      const currentShop =
        this.item.shop &&
        (checkID(this.item.shop, this.$shop.shopData)
          ? this.$shop.shopData
          : (
              await this.$feathers.service("shops").find({
                query: {
                  _id: `${this.item.shop}`,
                  $paginate: false,
                },
                paginate: false,
              })
            )[0]);

      if (this.status === "done") {
        this.atomic({
          receiptPrintTime: (this.receiptPrintTime || 0) + 1,
        }).catch(e => console.warn(e));
      }

      let user: FindPopRawType<["vipLevel", "ranks.rank"], "appUsers"> = null;
      if (this.user) {
        try {
          user = await this.$feathers.service("appUsers").get(this.user, {
            query: {
              $populate: ["vipLevel", "ranks.rank"],
            },
          } as const);
        } catch (e) {
          console.warn(e);
        }
      }

      const products = this.item.products.filter(it => it.status !== "cancel");

      const skipProductList = products.length > 60;

      const sessioData = { ...this.item, ...this.cachedPriceDetails } as any;

      const twInvoice = this.item.twInvoice
        ? await this.$feathers.service("twInvoices").get(this.item.twInvoice)
        : null;

      const previousRecords = props?.ongoingPayment
        ? []
        : await this.$feathers.service("payments").find({
            query: {
              _id: {
                $in: (this.item.payments || []).map(it => it.payment),
              },
              $paginate: false,
              ...(currentPayment
                ? {
                    date: {
                      $lt: currentPayment.date,
                    },
                  }
                : {}),
            },
            paginate: false,
          });

      const staff = this.item.staff && (await this.$feathers.service("staffs").get(this.item.staff));

      const paymentStaff = currentPayment?.staff
        ? checkID(currentPayment?.staff, staff?._id)
          ? staff
          : await this.$feathers.service("staffs").get(currentPayment?.staff)
        : null;

      let availPoints: FindType<"users/points/available"> = null;
      if (user) {
        availPoints = await this.feathers.service("users/points/available").find({
          query: {
            user: getID(user),
            tag: ["point", "dollar"],
            shop: getID(currentShop),
            $pagniate: false,
          },
          paginate: false,
        });
      }

      const printInfo: ReceiptInput = {
        job: {
          jobType: "table-payment-receipt",
          session: sessioData,
          twInvoice,
          printTwInvoice: props?.printTwInvoice,
          payment: currentPayment,
          paymentMethod: currentMethod as any,
          user: user as any,
          availPoints,
          staffName: staff?.name,
          paymentStaffName: paymentStaff?.name,
        },
        previousRecords,
        shop: currentShop as any,
        rePrint: rePrint ?? this.receiptPrintTime > 0,
        cashbox: cashBox,
      };
      for (let printJob of receiptJobs) {
        const job = await receiptSequencer({
          context: this,
          ...printInfo,
          pagination: skipProductList ? { skipped: true, total: products.length } : null,
          products: skipProductList ? [] : products,
          shopPrinter: printJob,
        });
        jobs.push(job);
      }

      if (skipProductList) {
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: this.$t("tableView.printRemainProductList"),
          },
          {
            maxWidth: "400px",
          },
        );
        if (c) {
          for (let i = 0; i < products.length; i += 60) {
            const list = products.slice(i, i + 60);
            for (let printJob of receiptJobs) {
              const job = await receiptSequencer({
                context: this,
                ...printInfo,
                pagination: {
                  offset: i + 1,
                  offsetEnd: i + list.length,
                  remain: products.length - list.length - i,
                  total: products.length,
                },
                products: list,
                shopPrinter: printJob,
              });
              printInfo.rePrint = true;
              jobs.push(job);
            }
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    }
    try {
      if (this.status === "done") {
        const pickupJobs = await this.$shop.getLocalPrinters(
          "table-pickup-location",
          this.sessionData,
          this.$shop.device._id,
        );
        // didn't test, afraid will break other things
        for (let printJob of pickupJobs) {
          try {
            const job = await pickupLocationPrintForTable(this, this, this.receiptPrintTime > 0);
            jobs.push(job);
          } catch (e) {
            console.log(e.message);
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    }
    this.syncOrder();
    return jobs;
  }

  twInvoicePrintDetails: boolean | "only" = false;

  togglePrintDetails() {
    this.twInvoicePrintDetails = !this.twInvoicePrintDetails;
    this.$store.commit(
      "SET_SUCCESS",
      this.$t(`turnCloud.${this.twInvoicePrintDetails ? "enablePrintDetails" : "disablePrintDetails"}`),
    );
  }

  async printTwInvoice(print = true) {
    if (this.sessionData.twInvoice) {
      try {
        let invoice: FindType<"twInvoices"> = null;
        if (this.$offline.offline) {
          invoice = await this.$feathers.service("twInvoices").get(this.sessionData.twInvoice);

          invoice = await this.$feathers.service("twInvoices").patch(this.sessionData.twInvoice, {
            printTimes: invoice.printTimes + 1,
          });
        } else {
          invoice = await this.$feathers.service("twInvoices").patch(this.sessionData.twInvoice, {
            $inc: {
              printTimes: 1,
            },
          });
        }

        if (print) {
          if (
            this.twInvoicePrintDetails &&
            this.sessionData.twTaxType !== "paper" &&
            this.sessionData.twTaxType !== "company"
          ) {
            this.twInvoicePrintDetails = "only";
          }
          await printTwInvoice({
            context: this,
            invoice,
            printDetails: this.twInvoicePrintDetails,
          });
        }
      } catch (e) {
        console.log(e);
        this.$store.commit("SET_ERROR", e.message);
      }
    }
  }

  async printReview() {
    const receiptJobs = await this.$shop.getLocalPrinters("table-receipt", this.sessionData, this.$shop.device._id);
    if (!receiptJobs.length) {
      return;
    }

    for (let job of receiptJobs) {
      try {
        await tableReceiptOrderOnlySequencer(
          this,
          {
            jobType: "table-receipt",
            session: this.sessionData,
            actionSource: "pos",
          },
          job,
        );
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  get timeLimit() {
    return this.sectionSettings?.timeLimit ?? this.view?.sessionTimeLimit ?? null;
  }

  sessionTime = -1;
  sessionTimeStatus: "normal" | "nearDue" | "due" = "normal";

  autoUpdating = false;

  get startTimeDate() {
    return moment(this.item.startTime).toDate();
  }

  @Watch("item.status")
  @Watch("timeLimit")
  async updateTimer() {
    let newTime = -1;
    let status: "normal" | "nearDue" | "due" = "normal";
    if (!this.item.endTime) {
      switch (this.item.status) {
        case "toPay":
        case "done":
        case "ongoing":
          newTime = ((Date.now() - this.startTimeDate.getTime()) / 60000) | 0;
          break;
      }
    }
    if (newTime !== this.sessionTime) {
      this.sessionTime = newTime;
    }
    if (newTime !== -1 && this.timeLimit !== null) {
      if (newTime >= this.timeLimit) {
        status = "due";
      } else if (newTime >= this.timeLimit - 5) {
        status = "nearDue";
      }
    }
    if (status !== this.sessionTimeStatus) {
      this.sessionTimeStatus = status;
    }
    if (this.orderGroupInited) {
      for (let group of this.orderGroups) {
        group.updateTimer();
      }
    }
  }

  getSplitName(it: TableSessionRef) {
    return it.table.getSplitName(it.split);
  }

  async handleSessionCreate() {
    await this.printOnlineOrderLinks(undefined, true);
  }

  async printOnlineOrderLinks(table?: TableSessionRef, isAuto = false) {
    const res: PrintJob[] = [];
    if (this.$shop.shopData.openForOnline) {
      const jobs = await this.$shop.getLocalPrinters("table-qr-link", this.sessionData, this.$shop.device._id);
      for (let job of jobs.filter(el => !isAuto || !!el?.jobOptions?.tableAutoPrintOnlineOrder)) {
        if (job.jobOptions?.autoPrintOnlineOrderSplitTable && isAuto) {
          continue;
        }

        if (table) {
          res.push(await this.printOnlineOrderLink(job, table));
        } else if (job.jobOptions?.tableAutoPrintOnlineOrderSplitTable) {
          for (let ref of this.tableRefs) {
            res.push(await this.printOnlineOrderLink(job, ref));
          }
        } else {
          res.push(await this.printOnlineOrderLink(job));
        }
      }
    }
    return res;
  }

  async printOnlineOrderLink(printJob: FindType<"shopPrinters">, table?: TableSessionRef) {
    try {
      if (!this.sessionData._id) {
        throw new Error("Session not created yet");
      }
      const job = await tableSessionLinkSequencer(this, table, printJob);
      return job;
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      console.log(e.message);
    }
  }

  getOrderLink(table?: TableSessionRef) {
    return new URL(`/t/${this.item._id}/${table ? table.index : ""}`, this.$shop.selfOrder);
  }

  async placeAndConfirm(toPay = true) {
    try {
      if (this.selectingCartItem) {
        this.openProductMenu(null);
      }
      if (this.loading) return;
      this.loading = true;
      this.screenOverride = null;
      if (!this.item._id) {
        this.dirty = false;
        this.resumeSave();
        await this.atomic({
          ...this.item,
          ...this.cachedPriceDetails,
          checkBillTime: new Date(),
          staff: this.$shop.staffId as any,
          status: "ongoing",
        });
        if (await this.redeemGifts()) {
          this.updateCoupons();
          await this.atomic({
            ...this.cachedPriceDetails,
          });
        }
        this.startSubscribe();
      }
      if (this.cart.length) {
        const result = await this.placeOrderInner();
        if (result === false) return;
        await this.reload();
      }
      await this.confirmOrder(toPay);
      this.checkoutTab = "method";
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.reload();
    } finally {
      this.loading = false;
    }
  }

  async cancelBilling() {
    await this.atomic({
      status: "ongoing",
      checkBillTime: null,
    });
  }

  async confirmOrder(toPay = true) {
    if (this._createTask) {
      await this._createTask;
    }
    if (this._savingTask) {
      await this._savingTask;
    }
    if (this._id) {
      await this.reload();
    }
    await this.restoreCoupons();
    await this.redeemGifts();
    this.updateCoupons();
    this.updateCachedDetails();
    if (this.orderEmpty) {
      // empty order
      await this.cancelPending(true, {
        checkEmpty: true,
        cancelReason: "Cancel Empty Order",
      });
      this.$emit("close");
      return;
    }
    if (this.status === "done") {
      await this.$feathers.service("tableSessions/edit").create({
        session: this.item._id,
      });
    }
    await this.atomic({
      ...((this.status === "done" && !this.outstanding) || !toPay
        ? {}
        : {
            status: "toPay",
          }),
      ...(toPay
        ? {
            checkBillTime: new Date(),
          }
        : {}),
      ...this.cachedPriceDetails,
      ...(this.name && !this.sessionData.tableRefName
        ? {
            tableRefName: this.name,
          }
        : {}),
      ...(this.$features.turnCloud && this.sessionData.twTaxType
        ? {
            twTaxType: "paper",
          }
        : {}),
      discounts: this.item.discounts,
      staff: this.$shop.staffId as any,
    });

    if (toPay) {
      if (!this.outstanding) {
        this.item = await this.$feathers.service("tableSessions/update").create({
          session: this.item._id,
        });
      }

      if (this.status === "done" && this.isNoTable) {
        await this.$feathers.service("tableSessions/order").patch(null, {
          session: this.item._id,
          products: this.products
            .filter(it => it.status === "hold")
            .map(it => ({
              ...it,
              status: "init",
            })),
          staff: this.$shop.staffId,
        });
      }

      await this.printOrder();
    }
  }

  async updateCachedInfo(noRestore = false) {
    if (!noRestore) {
      await this.restoreCoupons();
      await this.redeemGifts();
    }
    this.updateCoupons();
    this.updateCachedDetails();
    await this.atomic({
      ...this.cachedPriceDetails,
    });
  }

  async cancelPending(
    end = true,
    opts?: {
      checkEmpty?: boolean;
      cancelReason?: string;
    },
  ) {
    if (!this.item._id) return;
    this.item = await this.$feathers.service("tableSessions/cancelPending").create({
      session: this.item._id,
      checkEmpty: opts?.checkEmpty ?? false,
      cancelReason: opts?.cancelReason,
      update: end
        ? {
            endTime: new Date(),
          }
        : {},
    });
  }

  sumProducts(cart: CartItem[]) {
    let totalProducts: Record<string, number> = {};
    if (!cart.length) return totalProducts;
    for (let cur of cart) {
      if (!cur.product) continue;
      totalProducts[getID(cur.product)] = (totalProducts[getID(cur.product)] || 0) + cur.quantity;
    }
    return totalProducts;
  }

  queryStock() {
    const record: Record<string, StockInfo> = {};
    const totalIngredients = sumIngredients([], ...this.cart.map(it => it.productIngredients));
    const totalStockProducts = this.sumProducts(_.filter(this.cart, it => it.product?.stock?.mode != "auto"));
    const totalIngredientProducts = this.sumProducts(_.filter(this.cart, it => it.product?.stock?.mode == "auto"));
    const outOfStock = new Set<string>();
    for (let [product, quantity] of Object.entries(totalStockProducts)) {
      const info = this.$shop.productDict[product];
      if (!info?.stock || info.stock.mode === "disable") continue;
      if (info.stock.quantity < quantity) {
        record[product] = {
          stockLevel: StockLevel.OutOfStock,
          total: quantity,
          image: info.image,
          name: info.name,
          stock: info.stock.quantity,
        };
      }
    }

    for (let ingredient of totalIngredients) {
      if (this.$tableManager.ingredientDict[getID(ingredient.ingredient)]?.currentStock < ingredient.amount) {
        outOfStock.add(getID(ingredient.ingredient));
      }
    }

    for (let [product, quantity] of Object.entries(totalIngredientProducts)) {
      const info = this.$shop.productDict[product];
      if (!info?.ingredients?.length) continue;
      for (let ingredient of info.ingredients) {
        if (outOfStock.has(getID(ingredient.ingredient))) {
          if (record[product]) {
            record[product].ingredients.push(ingredient);
          } else {
            record[product] = {
              stockLevel: StockLevel.OutOfStock,
              total: quantity,
              image: info.image,
              name: info.name,
              ingredients: [ingredient],
            } as any;
          }
        }
      }
    }

    return record;
  }

  // for using in pos screen
  showingInvoice = false;
  showingPendingPayment = false;
  showingPayment: FindType<"payments"> = null;
  showingCheckout: {
    status?: string;
    errorMessage?: string | LangArrType;
    loading?: boolean;
    cancelling?: boolean;
    amount?: number;
  } = null;
  paymentImage: string = null;

  @Watch("cart")
  async syncOrder() {
    if (this.$shop.secondScreen || this.$tableManager.posScreenHandler) {
      const items = [...this.products, ...this.cart.map(it => it.toLine())].map(item => {
        const targetRef =
          item.table && this.tableRefs.find(it => it.table.id === `${item.table}` && it.split === item.tableSplit);
        const productInfo = this.$shop.productDict[`${item.product}`];
        return {
          ...item,
          product: productInfo ? { _id: productInfo._id, image: productInfo.image } : item.product,
          targetName: targetRef?.session?.getSplitName?.(targetRef),
        };
      });

      const lastProduct = items.find(it => it.status !== "cancel" && !it.fromProduct);

      const cartData = {
        items,
        session: {
          ...this.cachedPriceDetails,
          desiredCapacity: this.capacity,
          totalPrice: this.totalPrice,
          status: this.status,
          tvStatus: this.item.tvStatus,
          receiptPrintTime: this.receiptPrintTime,
          showingInvoice: this.showingInvoice,
          showingPendingPayment: this.showingPendingPayment,
          showingPayment: this.showingPayment,
          showingCheckout: this.showingCheckout,
          paymentImage: this.paymentImage,
          shopId: this.shopId,
          _id: this._id,
          restoreKey: this.restoreKey,
          sessionName: this.sessionName,
          discountHints: this.discountHints,
        },
        lastProduct,
      };

      if (this.$shop.secondScreen) {
        this.$shop.secondScreen.queue.ns("posScreen").call("setCart", cartData);
      }

      if (this.$tableManager.posScreenHandler) {
        await this.$tableManager.posScreenHandler.syncPosScreen(cartData);
      }
    }
  }

  get namePrefix() {
    const m = (this.sessionName || "").match(/(?:(?![0-9]).)+|[0-9]+/g) || [];
    return m.length > 1 ? m[0] || "" : "#";
  }

  get nameContent() {
    const m = (this.sessionName || "").match(/(?:(?![0-9]).)+|[0-9]+/g) || [];
    return m.length > 1 ? m.slice(1).join("") : m[0] || "";
  }

  // for lassana redeem gift, temp solution
  tempGifts: FindType<"gifts">[] = [];

  getCartReplacing() {
    const { rootSet, rootDict, productDict } = getProductTreeInfo(this.products);
    const cartReplacing = new Set(
      this.cart
        .filter(it => {
          return !!it.fromLine;
        })
        .flatMap(it => Array.from(rootSet[rootDict[it.fromLine.id]] || [])),
    );
    return Array.from(cartReplacing).map(it => productDict[it]);
  }

  getCartReplacingIds() {
    const { rootSet, rootDict } = getProductTreeInfo(this.products);
    const cartReplacing = new Set(
      this.cart
        .filter(it => {
          return !!it.fromLine;
        })
        .flatMap(it => Array.from(rootSet[rootDict[it.fromLine.id]] || [])),
    );
    return Array.from(cartReplacing);
  }

  saveCart() {
    // update replace status
    const { rootSet, rootDict } = getProductTreeInfo(this.products);

    const productReplacing = new Set(
      this.item.products
        .filter(it => {
          return (it as any).replacing;
        })
        .flatMap(it => Array.from(rootSet[rootDict[it.id]] || [])),
    );

    const cartReplacing = new Set(
      this.cart
        .filter(it => {
          return !!it.fromLine;
        })
        .flatMap(it => Array.from(rootSet[rootDict[it.fromLine.id]] || [])),
    );

    const diffRemove = new Set(Array.from(productReplacing).filter(it => !cartReplacing.has(it)));
    const diffAdd = new Set(Array.from(cartReplacing).filter(it => !productReplacing.has(it)));

    let updated = false;

    for (let item of this.item.products) {
      if (diffRemove.has(item.id)) {
        Vue.set(item, "replacing", false);
        updated = true;
      } else if (diffAdd.has(item.id)) {
        Vue.set(item, "replacing", true);
        updated = true;
      }
    }

    if (updated) {
      this.$emit("updated");
    }
  }

  postEditing = false;
  screenOverride: string = null;
  supacityInfo: POSUserInfo = null;

  updateCachedDetails() {
    Object.assign(this.item, this.cachedPriceDetails);
  }

  // #region print job status

  jobStatusCache: CacheLoader<FindType<"sharePrintJobs">>;

  getJobStatusCache() {
    if (!this.jobStatusCache) {
      this.jobStatusCache = new CacheLoader({
        parent: this,
        propsData: {
          path: "sharePrintJobs",
          subscribe: true,
        },
      });
    }

    return this.jobStatusCache;
  }

  // #endregion

  // #region KDS Helpers

  kdsProductGroup: KdsProductGroupRef;

  private orderGroupsStore: OrderGroup[] = [];
  private orderOrderPendingGroupsStore: OrderGroup[] = [];
  private orderPendingGroupsStore: OrderGroup[] = [];
  private orderDoneGroupsStore: OrderGroup[] = [];
  private orderHoldGroupsStore: OrderGroup[] = [];

  private orderGroupInited: boolean;

  private initGroup() {
    if (!this.orderGroupInited) {
      this.orderGroupInited = true;
      this.updateOrderGroup();
    }
  }

  get orderGroups() {
    this.initGroup();
    return this.orderGroupsStore;
  }

  get orderOrderPendingGroups() {
    this.initGroup();
    return this.orderOrderPendingGroupsStore;
  }

  get orderPendingGroups() {
    this.initGroup();
    return this.orderPendingGroupsStore;
  }

  get orderDoneGroups() {
    this.initGroup();
    return this.orderDoneGroupsStore;
  }

  get orderHoldGroups() {
    this.initGroup();
    return this.orderHoldGroupsStore;
  }

  @Watch("item.products")
  @Watch("$shop.localOptions.kdsWaterbar")
  onProducts() {
    if (this.orderGroupInited) {
      this.updateOrderGroup();
    }
    if (this.$tableManager._kdsProductManager) {
      this.$tableManager._kdsProductManager.updateOrder(this);
    }
  }

  updateOrderGroup() {
    const toDelete = new Set(this.orderGroupsStore);
    const kdsWaterbar = this.$shop.localOptions.kdsWaterbar;
    const reflowGroup = new Set<OrderGroup>();

    for (let product of this.item?.products ?? []) {
      const normalizedStatus =
        product.status === "hold"
          ? "hold"
          : product.status === "pending"
            ? "orderPending"
            : product.kdsStatus === "partialDone"
              ? "pending"
              : product.kdsStatus || "pending";
      const type = product.takeAway ? "takeAway" : this.item.type;
      const groups = this.orderGroupsStore.filter(it => it.seq === product.seq && it.type === type);
      let group = groups.find(it => it.status === normalizedStatus);

      if (kdsWaterbar) {
        if (!product.waterBars?.includes?.(kdsWaterbar as any)) continue;
      } else if (!product.waterBars?.length) continue;

      if (!group) {
        this.orderGroupsStore.push(
          (group = new OrderGroup({
            parent: this,
            propsData: {
              seq: product.seq,
              session: this,
              status: normalizedStatus,
              type,
            },
          })),
        );
        switch (normalizedStatus) {
          case "orderPending":
            this.orderOrderPendingGroupsStore.push(group);
            break;
          case "pending": {
            this.orderPendingGroupsStore.push(group);
            break;
          }
          case "done": {
            this.orderDoneGroupsStore.push(group);
            break;
          }
          case "hold": {
            this.orderHoldGroupsStore.push(group);
            break;
          }
        }
      }
      group.verify.push(product.id);

      toDelete.delete(group);
      if (group.addItem(product)) {
        reflowGroup.add(group);
      }
    }

    for (let group of this.orderGroupsStore) {
      if (group.items.length !== group.verify.length) {
        group.items = group.items.filter(it => group.verify.includes(it.id));
        reflowGroup.add(group);
      }
      group.verify = [];
    }

    for (let item of toDelete) {
      {
        const idx = this.orderGroupsStore.indexOf(item);
        idx !== -1 && this.orderGroupsStore.splice(idx, 1);
      }

      {
        const idx = this.orderOrderPendingGroupsStore.indexOf(item);
        idx !== -1 && this.orderOrderPendingGroupsStore.splice(idx, 1);
      }

      {
        const idx = this.orderPendingGroupsStore.indexOf(item);
        idx !== -1 && this.orderPendingGroupsStore.splice(idx, 1);
      }

      {
        const idx = this.orderDoneGroupsStore.indexOf(item);
        idx !== -1 && this.orderDoneGroupsStore.splice(idx, 1);
      }

      {
        const idx = this.orderHoldGroupsStore.indexOf(item);
        idx !== -1 && this.orderHoldGroupsStore.splice(idx, 1);
      }

      item.$destroy();
    }

    for (let group of reflowGroup) {
      group.resetView();
    }

    for (let item of this.orderGroups) {
      item.updateTimer();
    }
  }
  // #endregion

  // #region split payment

  paymentMethodFull: PaymentMethodFull = null;
  paymentMethodArgs: any = {};
  paymentMethodInfo: any = null;
  customReceived: number = null;

  get received() {
    return this.customReceived === null ? this.payingAmount + this.tempPaymentSurcharge : this.customReceived;
  }
  set received(v) {
    this.customReceived = v;
  }

  partialPaymentType: "fully" | "partial" | "equally" = "fully"; // decide which small tab is active under big "partialPayment" tab
  isPayingSplit: boolean = false;
  splitPeopleNum = 0; // number of splitted bills
  partialPayment = 0; // partial payment amount (xx%)
  partialEqually: boolean = false; // decide which small tab is active under big "partialPayment" tab
  currentSplitPaid: boolean = false;
  payingSplitBill: SplitBill = null; // the splitted bill that is going to be paid

  @Watch("payingAmount")
  onPayingAmount(v, ov) {
    if (v === ov) return;
    if (this.customReceived !== null) {
      this.customReceived = null;
    }
  }

  @Watch("paymentMethodFull")
  onPaymentMethodChanged() {
    this.received = null;
    this.paymentMethodInfo = null;
    this.supacityInfo = null;
  }

  get change() {
    let received = this.received;
    if (isNaN(received)) {
      return null;
    } else {
      return received - this.payingAmount;
    }
  }

  get payingAmount() {
    if (!this.sessionData) return null;
    return this.splitPayment || this.partialPayment || this.outstanding;
  }

  get paymentDetailValid() {
    if (!this.paymentMethodFull) return false;
    const type = this.paymentMethodFull.type ?? this.paymentMethodFull.method?.type;
    switch (type) {
      case "cash": {
        let received = this.received === null ? this.partialPayment || this.outstanding : this.received;
        return received >= this.payingAmount;
      }
      case "supacity": {
        return !!this.supacityInfo;
      }
      case "turnCloud": {
        if (this.paymentMethodFull?.props?.method === "creditI") {
          return this.sessionData.twTaxPeriod > 0;
        }
      }
    }

    return true;
  }

  get splitted() {
    return (this.splittedPayments?.length ?? 0) !== 0;
    // && !this.splittedPayments.every(el=>el.status==="paid"); // use this when splittedPayments have status
  }

  get splitPayment() {
    return this.payingSplitBill ? this.payingSplitBill.amount : 0;
  }

  get canResetSplit() {
    return this.splittedPayments.every(el => el.status === "notPaid");
  }

  get billStatus() {
    if (this.status === "ongoing") {
      const products = this.products.filter(it => it.status !== "cancel" && it.status !== "pending");
      if (products.length > 0) {
        if (products.every(it => it.kdsStatus === "done")) {
          return "productDone";
        } else {
          return "ordered";
        }
      }
    }
    return this.status;
  }

  get color() {
    switch (this.billStatus) {
      case "ordered":
        return "#2D9CDB";
      case "productDone":
        return "#7369DE";
      case "booking":
        return "#828282";
      case "ongoing":
        return "#DF6D6D";
      case "toPay":
        return "#27AE60";
      case "done":
        return "#27AE60";
      case "cancelled":
        return "#828282";
      case "void":
        return "#828282";
    }
  }

  resetSplitBills() {
    // this.splittedBills=[];
    if (this.canResetSplit) {
      // this.splitPayment = 0;
      this.payingSplitBill = null;
      this.atomic({
        splittedPayments: [],
      });
    }
  }

  forceResetSplitBills() {
    // for editOrder function
    this.payingSplitBill = null;
    this.atomic({
      splittedPayments: [],
    });
  }

  calculateSplitBills(noOfPeople, fullAmount) {
    let splittedBills = [];
    while (noOfPeople > 0) {
      const splittedAmount = this.roundPrice(fullAmount / noOfPeople);
      splittedBills.push({ amount: splittedAmount, status: "notPaid", payment: null });
      fullAmount -= splittedAmount;
      noOfPeople--;
    }

    this.atomic({
      // save splitted bills to db
      splittedPayments: splittedBills,
    });
    console.log("cal: ", this.splittedPayments);
  }

  changePayingSplittedBill(bill) {
    // this.splitPayment = bill.amount;
    bill.status = "notPaid";
    bill.payment = null;
    this.payingSplitBill = bill;
    this.isPayingSplit = true;
  }

  doPaySplitBills(amount: number, paidPayment: FindType<"payments">) {
    if (!this.splitted) {
      return;
    }
    let splittedBills = this.splittedPayments;
    // this.payingSplitBill.status = "paid";
    // this.payingSplitBill.payment = paidPayment;
    for (let el of splittedBills) {
      if (el.amount === amount && el._id === this.payingSplitBill._id) {
        el.status = "paid";
        this.payingSplitBill.status = "paid";
        el.payment = paidPayment._id;
        this.payingSplitBill.payment = paidPayment._id;
        // splittedBills.splice(index, 1); // remove bill from splitPayments array
        break;
      }
    }

    this.atomic({
      splittedPayments: splittedBills,
    });
    this.currentSplitPaid = true;
    // this.isPayingSplit = false;
    // console.log("payments", this.splittedPayments)
  }

  refundSplittedPayment(refundedPayment: FindType<"payments">) {
    if (!this.splitted) {
      return;
    }
    let splittedBills = this.splittedPayments;
    console.log("re: ", refundedPayment);
    let refundBill = splittedBills.find(el => el.payment === refundedPayment._id);
    console.log("refundBill before: ", refundBill);
    if (!refundBill) {
      return;
    }
    refundBill.payment = null;
    refundBill.status = "notPaid";
    console.log("refundBill after: ", refundBill);
    console.log("refund object", refundedPayment);
    this.atomic({
      splittedPayments: splittedBills,
    });
    this.currentSplitPaid = false;
    this.isPayingSplit = false;
  }

  // #endregion

  // #region product options

  selectingCartItem: { cart?: CartItem; line?: ProductLine; showOptions?: boolean } = null;
  get selectingCartItemId() {
    return (this.selectingCartItem?.cart || this.selectingCartItem?.line)?.id;
  }
  openProductMenu(cart: { cart?: CartItem; line?: ProductLine; showOptions?: boolean }) {
    if (cart) {
      cart.showOptions = cart.showOptions || false;
    }
    this.selectingCartItem = cart;
  }

  // #endregion

  // #region new / edit tips

  async editOrAddTips(paymentId: string, newTips: number) {
    await this.reload();
    const sessionPayments = this.payments;
    const payment = sessionPayments.find(el => checkID(el.payment, paymentId));

    const oldPayment = await this.$feathers.service("payments").get(paymentId);
    const tipsDiff = newTips - oldPayment.tips;

    const paymentItem = await this.$feathers.service("payments").patch(paymentId, {
      amount: oldPayment.amount + tipsDiff,
      ...(oldPayment.metadata?.receiveAmount !== undefined
        ? {
            metadata: {
              ...oldPayment.metadata,
              receiveAmount: oldPayment.metadata.receiveAmount + tipsDiff,
            },
          }
        : {}),
      tips: newTips,
    });

    for (let [k, v] of Object.entries(paymentItem)) {
      Vue.set(payment as any, k, v);
    }

    payment.tips = newTips;
    const newTotalTips = _.sumBy(sessionPayments, el => el.tips);

    this.sessionData.tips = newTotalTips;
    await this.restoreCoupons();

    /*
     * here should add steps to call credit card gateway to update tips
     */
    await this.atomic({
      amount: this.sessionData.amount + tipsDiff,
      tips: newTotalTips,
      payments: sessionPayments,
      ...this.cachedPriceDetails,
    });
    updateCachedValue(this, "payments", paymentItem);
    return paymentItem;
  }

  // #endregion

  // #region auto merger

  async toggleMergeable(cart: CartItem) {
    if (cart.mergeStatus === "skip") {
      cart.mergeStatus = null;
    } else {
      if (cart.mergeStatus) {
        const sub = await this.undoMerge(cart);
        if (sub) {
          for (let item of sub) {
            item.mergeStatus = "skip";
          }
        }
      }

      cart.mergeStatus = "skip";
    }
    return await this.updateAutoMerge();
  }

  async undoMerge(cart: CartItem) {
    if (cart.mergeStatus === "mergedFrom") {
      if (!cart.fromProductCart) return;
      if (cart.fromProductCart.mergeStatus === "merged") {
        // undo bundle merge
        await this.undoMerge(cart.fromProductCart);
      } else {
        // undo addon merge
        const option = cart.fromProductCart.productOptionsWithPrice.find(it => checkID(it.options, cart.fromOption));
        if (!option) return;
        const selection = option.selectionItems.find(it => checkID(it.selection.id, cart.fromSelection));
        if (!selection) return;
        cart.makeStandalone();
        selection.remove();
      }
    } else if (cart.mergeStatus === "merged") {
      const related = cart.related.slice();
      const tree = cart.relatedTree.slice();
      for (let item of related) {
        item.makeStandalone();
      }
      cart.remove();
      for (let item of tree.reverse()) {
        await item.updateDeps();
      }
      if (this.selectingCartItem?.cart === cart) {
        this.selectingCartItem = null;
      }
      return related;
    }
  }

  cachedMergeResult: ProductLine[] = [];
  cachedMergeResultDict: Record<string, ProductLine> = {};

  async updateAutoMerge() {
    let merged = false;
    this.cachedMergeResult = [];
    this.cachedMergeResultDict = {};

    if (!this.cart.length) return false;

    const curSelected = this.selectingCartItem;
    if (this.$shop.shopData.autoMergeInPOS) {
      merged ||= !!(await this.$shop.autoMerger.tryMergeCart(this));
      merged ||= !!(await this.$shop.autoMerger.tryMergeAddons(this));
    }
    if (this.$shop.shopData.autoMergeOrderedInPOS) {
      const mergeResult = await this.$shop.autoMerger.tryMergeOrderedItems(this, true);
      if (mergeResult) {
        this.cachedMergeResult = mergeResult;
        this.cachedMergeResultDict = Object.fromEntries(mergeResult.map(it => [it.id, it]));
      }
    }
    if (curSelected?.cart && merged && !this.cart.includes(curSelected.cart)) {
      this.selectingCartItem.cart = this.cart.find(it => it.id === curSelected.cart.id);
    }
    return merged;
  }

  async updateAfterOrder() {
    if (this.cart.length) {
      await this.updateAutoMerge();
    } else {
      this.cachedMergeResult = [];
      this.cachedMergeResultDict = {};
    }

    await this.printReview();
  }

  // #endregion

  // #region UI data

  checkoutTab: string = null;
  get checkoutTabs() {
    return [
      // "method",
      this.$shop.hasStaffRole("pos/discount") ? ["discount"] : [],
      this.$shop?.shopData?.enablePartialPayment && !this.testing ? ["partial"] : [],
      this.payments?.length ? ["record"] : [],
      "remarks",
      this.$features.tax ? "taxAndService" : "service",
    ].flat();
  }

  selectingCart = false;
  selectingLine = false;
  singleItemCheckout = false;
  cleanOnUpdated = false;
  loading = false;
  numPadMode = false;

  cancelSelect() {
    this.selectingCart = this.selectingLine = this.singleItemCheckout = false;
  }

  selectAll() {
    if (this.cart.length) {
      this.selectingCart = true;
      for (let item of this.cart) {
        item.selected = false;
      }
    } else {
      this.selectingLine = true;
      for (let item of this.products) {
        if (item.status === "cancel") continue;
        Vue.set(item, "selected", false);
      }
    }
  }

  enableSingleItemCheckout() {
    this.singleItemCheckout = true;
    for (let item of this.products) {
      if (item.status === "cancel") continue;
      Vue.set(item, "selected", false);
    }
  }

  async splitItemCheckout() {
    this.cancelSelect();
    if (this.tableRefs.length <= 0) {
      this.$store.commit("SET_ERROR", this.$t("tableView.singleItemCheckout.notAllow"));
      return;
    }
    if (
      this.products.filter(it => !it.fromProduct).every(it => (it as any).selected) ||
      this.products.filter(it => !it.fromProduct).every(it => !(it as any).selected)
    )
      return;
    const sessionRef = this.tableRefs.find(it => checkID(it.session, this));
    const newSession = this.view.addSession({
      startTime: this.item.startTime,
      section: this.item.section,
      tables: [],
      checkoutFrom: sessionRef.session._id,
      restoreKey: this.item.restoreKey,
    });
    const action = {
      actions: [
        {
          from: {
            ref: sessionRef,
            x: 0,
            y: 0,
          },
          to: {
            ref: sessionRef,
            capacity: sessionRef.capacity - 1 || 1,
          },
        },
        {
          to: {
            ref: {
              session: newSession,
              table: sessionRef.table,
              split: sessionRef.table.nextSessionSplit,
            },
            capacity: 1,
          },
        },
      ],
      orders: [],
      newSessions: [newSession],
    };

    const lines = _.flatMap(
      action.actions.map(action => {
        if (action.from) {
          const ref = action.from.ref;
          // TODO: chair id
          return ref.session.products
            .filter(it => `${it.table}` === ref.table.id && it.tableSplit === ref.split)
            .map(it => ({
              session: ref.session,
              line: it,
            }));
        }
        return [];
      }),
    );

    function getRoot(id: string) {
      let line = sessionRef.session.products.find(it => it.id === id);
      while (line.fromProduct) {
        line = sessionRef.session.products.find(it => it.id === line.fromProduct);
      }
      return line;
    }

    action.orders.push(
      ...lines.map(line => {
        const curRoot = getRoot(line.line.id);
        console.log(curRoot);
        return {
          line: line.line,
          sourceSession: sessionRef.session,
          target: (curRoot as any).selected ? action.actions[1]?.to?.ref : action.actions[0]?.to?.ref,
          // TODO: chair id
          chairId: null,
        };
      }),
    );

    const sessions: any[] = [];
    for (let session of action.newSessions) {
      sessions.push({
        ...(await session.presave()),
        tempId: session.tempId,
      });
    }

    const createObject = {
      actions: action.actions.map(it => ({
        from: it.from
          ? {
              ref: {
                session: it.from.ref.session.id,
                table: it.from.ref.table.id,
                splitId: it.from.ref.split,
              },
            }
          : null,
        to: it.to
          ? {
              ref: {
                session: it.to.ref.session.id,
                table: it.to.ref.table.id,
                splitId: it.to.ref.split,
              },
              capacity: it.to.capacity,
            }
          : null,
      })),

      orders: action.orders.map(it => ({
        id: it.line.id,
        ref: {
          session: it.target.session.id,
          table: it.target.table.id,
          splitId: it.target.split,
          chairId: it.chairId,
        },
      })),

      newSessions: sessions,
      noPrint: true,
      staff: this.$shop.staffId,
    };

    const moved = await this.$feathers.service("tableSessions/move").create(createObject);

    await this.$feathers.service("actionLogs").create({
      session: getID(this.item._id),
      view: getID(this.item.view),
      staff: this.$shop.staffId,
      type: "orderManage/tableSessionSingleItemCheckout",
      detail: {
        newSessionName: moved[1].sessionName,
        movedProducts: moved[1].products,
      },
    });

    return { moved, newSessions: action.newSessions };
  }

  async cancelSingleItemCheckout() {
    const action = {
      actions: [],
      orders: [],
      newSessions: [],
    };

    if (this.tableRefs.length <= 0) {
      this.$store.commit("SET_ERROR", this.$t("tableView.singleItemCheckout.notAllow"));
      return;
    }

    if (this.payments && this.payments?.length >= 1) {
      this.$store.commit("SET_ERROR", this.$t("tableView.singleItemCheckout.cancelNotAllow"));
      return;
    }

    const sessionRef = this.tableRefs.find(it => checkID(it.session, this));
    const targetSessionRef = sessionRef.table.sessions.find(it => checkID(it.session, this.checkoutFrom));

    action.actions.push(
      {
        from: {
          ref: sessionRef,
          x: 0,
          y: 0,
        },
      },
      {
        from: {
          ref: targetSessionRef,
        },
        to: {
          ref: targetSessionRef,
          capacity: targetSessionRef.capacity + sessionRef.capacity,
        },
      },
    );

    const lines = _.flatMap(
      action.actions.map(action => {
        if (action.from) {
          const ref = action.from.ref;
          // TODO: chair id
          return ref.session.products
            .filter(it => `${it.table}` === ref.table.id && it.tableSplit === ref.split)
            .map(it => ({
              session: ref.session,
              line: it,
            }));
        }
        return [];
      }),
    );

    action.orders.push(
      ...lines.map(line => ({
        line: line.line,
        sourceSession: sessionRef.session,
        target: action.actions[1]?.to?.ref,
        // TODO: chair id
        chairId: null,
      })),
    );

    const createObject = {
      actions: action.actions.map(it => ({
        from: it.from
          ? {
              ref: {
                session: it.from.ref.session.id,
                table: it.from.ref.table.id,
                splitId: it.from.ref.split,
              },
            }
          : null,
        to: it.to
          ? {
              ref: {
                session: it.to.ref.session.id,
                table: it.to.ref.table.id,
                splitId: it.to.ref.split,
              },
              capacity: it.to.capacity,
            }
          : null,
      })),

      orders: action.orders.map(it => ({
        id: it.line.id,
        ref: {
          session: it.target.session.id,
          table: it.target.table.id,
          splitId: it.target.split,
          chairId: it.chairId,
        },
      })),

      newSessions: [],
      noPrint: true,
      staff: this.$shop.staffId,
    };

    const moved = await this.$feathers.service("tableSessions/move").create(createObject);

    // await this.$feathers.service("actionLogs").create({
    //   session: getID(this.item._id),
    //   view: getID(this.item.view),
    //   staff: this.$shop.staffId,
    //   type: "orderManage/tableSessionCancelSingleItemCheckout",
    // });

    return moved;
  }

  clearAllItem() {
    this.clearCart();
    this.$root.$emit("cleanCoupon");
  }

  clearSelected() {
    for (let item of this.selectedCartClearable) {
      item.remove();
    }
    if (!this.selectableItems.length) {
      this.cancelSelect();
    }
  }

  toggleSelectAll() {
    if (this.selectableItems.length === this.selectedItems.length) {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", false);
      }
    } else {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", true);
      }
    }
  }

  toggleHold() {
    const hold = this.selectedItems.filter(it => (it as CartItem).status === "hold");

    if (hold.length === this.selectedItems.length) {
      for (let item of this.selectedItems) {
        (item as CartItem).status = "init";
      }
    } else {
      for (let item of this.selectedItems) {
        (item as CartItem).status = "hold";
      }
    }
  }

  get isTest() {
    return this.status === "test";
  }

  get isDone() {
    return this.status === "done";
  }

  get isPaying() {
    return (this.postEditing && this.screenOverride === "checkout") || this.status === "toPay";
  }

  get isOngoing() {
    return this.postEditing || this.status === "ongoing";
  }

  get canCheckBill() {
    return this.isNoTable
      ? (this.isOngoing || this.status === "toPay") && (this.cartEmpty ? !!this.item._id : this.cartValid)
      : this.isOngoing && this.cartEmpty;
  }

  async batchSetKitchenOption() {
    if (!this.selectedItems.length) return;
    await this.$openDialog(
      import("~/components/table/orderSystem/batchSetKitchenOption.vue"),
      {
        ...(this.selectingCart ? { cart: this.selectedItems } : { line: this.selectedItems }),
        session: this,
        defaultIncludeDetails: this.selectedItems.length !== this.selectableItems.length,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }

  async batchResume() {
    try {
      this.loading = true;
      const items = this.selectedItemResume.slice();
      for (let item of items) {
        item.status = "init";
      }
      await this.$feathers.service("tableSessions/order").patch(this.item._id, {
        session: this.item._id,
        products: items,
        staff: this.$shop.staffId,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.reload();
    } finally {
      this.loading = false;
    }
  }

  async batchCancel() {
    if (!this.selectedItems.length) return;
    await this.$openDialog(
      import("~/components/table/orderSystem/cancelReason.vue"),
      {
        line: this.selectedItems,
        session: this,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
    await new Promise(resolve => setTimeout(resolve, 200));
    if (!this.selectableItems.length) {
      this.cancelSelect();
    }
  }

  async placeOrder() {
    try {
      if (this.selectingCartItem) {
        this.openProductMenu(null);
      }
      if (this.loading) return;
      this.loading = true;
      await this.placeOrderInner();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.reload();
    } finally {
      this.loading = false;
    }
  }

  async placeOrderInner() {
    const permissions = [`orderManage/tableSessionPlaceProducts`];
    this.cleanOnUpdated = true;
    try {
      const replaceProducts = this.getCartReplacing();
      const replaceProductsId = replaceProducts.map(it => it.id);

      const newProducts = this.cart.filter(it => !it.fromLine).map(it => it.toLine());
      const products = this.cart.map(it => it.toLine()) as any[];

      if (replaceProducts.length) permissions.push(`orderManage/tableSessionEditProducts`);

      const staff = await this.$shop.checkPermission(permissions);
      if (staff === false) {
        this.cleanOnUpdated = false;
        return false;
      }

      if (this.isNoTable) {
        for (let p of products) {
          p.status = "hold";
        }
      }

      for (let cartItem of this.cart) {
        if (cartItem.editPriceStaff) {
          let productName = null;
          if (cartItem?.product) productName = (await this.$feathers.service("products").get(cartItem?.product)).name;
          await this.$feathers.service("actionLogs").create({
            session: this.item._id,
            view: this.item.view,
            staff: cartItem.editPriceStaff === true ? null : getID(cartItem.editPriceStaff),
            type: "orderManage/tableSessionEditProductPrice",
            detail: { cartItem, originalPrice: cartItem.originalPrice, newPrice: cartItem.manualPrice, productName },
          });

          cartItem.editPriceStaff = null;
        }
      }

      await this.updateCoupons();
      await this.$feathers.service("tableSessions/order").create({
        session: this.item._id,
        products,
        replaceProducts: replaceProductsId,
        staff: staff?._id || this.$shop.staffId,
        noPrint: this.postEditing,
        update: {
          ...this.cachedPriceDetails,
        },
      });

      if (replaceProducts.length) {
        await this.$feathers.service("actionLogs").create({
          session: this.item._id,
          view: this.item.view,
          staff: staff?._id || this.$shop.staffId,
          type: "orderManage/tableSessionEditProducts",
          detail: { replaceProducts },
        });
      }

      if (newProducts.length) {
        await this.$feathers.service("actionLogs").create({
          session: this.item._id,
          view: this.item.view,
          staff: staff?._id || this.$shop.staffId,
          type: "orderManage/tableSessionPlaceProducts",
          detail: { newProducts },
        });
      }

      if (this.cleanOnUpdated) this.cart = [];
      await this.updateAfterOrder();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      try {
        this.cleanOnUpdated = false;
        await this.reload();
        for (let item of this.cart) {
          if (this.products.find(it => it.id === item.id)) {
            await item.remove();
          }
        }
        await this.updateAfterOrder();
      } catch (e) {
        console.error(e);
      }
    } finally {
      this.cleanOnUpdated = false;
    }
  }

  async resumeAll() {
    await this.$feathers.service("tableSessions/order").patch(
      null,
      {
        session: this.item._id,
        products: this.products
          .filter(it => it.status === "hold")
          .map(it => ({
            ...it,
            status: "init",
          })),
        staff: this.$shop.staffId,
      },
      {
        query: {
          addTvStatus: this.type === "takeAway" || this.type === "dineInNoTable" || this.type === "delivery",
        },
      },
    );
    await this.reload();
  }

  async cancelOrder(): Promise<Boolean> {
    const res = await this.$openDialog(
      import("~/components/dialogs/ConfirmDeleteDialog.vue"),
      {
        title: this.$t("cart.cancelOrder"),
        desc: this.$t("cart.cancelOrderConfirm", {
          table: this.tableRefs[0]?.table.name,
          session: this.sessionName,
        }),
      },
      {
        maxWidth: "380px",
      },
    );
    if (res && res.result) {
      if (this.products.length && this.products.every(it => it.status !== "cancel")) {
        const cancelSuccess = await this.$openDialog(
          import("~/components/table/orderSystem/cancelReason.vue"),
          {
            line: this.products.filter(it => it.status !== "cancel"),
            session: this,
          },
          {
            maxWidth: "max(50vw,500px)",
          },
        );
        await new Promise(resolve => setTimeout(resolve, 200));
        if (!cancelSuccess) return false;
      }
      this.clearAllItem();

      await this.cancelPending(true, { cancelReason: res.cancelReason });

      if (res.print) {
        await this.printOrder();
      }
    }
    return res?.result;
  }

  get canMultiSelect() {
    return !!(this.cart?.length || this.products.filter(it => it.status !== "cancel").length);
  }

  get selectableItems(): any[] {
    return this.selectingCart
      ? this.cart
      : this.selectingLine
        ? this.products.filter(it => it.status !== "cancel")
        : [];
  }

  get selectedItems() {
    return this.selectableItems.filter(it => (it as any).selected);
  }

  get selectedCartClearable() {
    return this.cart.filter(it => it.selected && !it.fromProduct);
  }

  get selectedItemResume() {
    return this.selectedItems.filter(it => (it as ProductLine).status === "hold");
  }

  get gifts() {
    return (this.coupons || []).filter(it => it.discountSource === "gift");
  }

  async orderInfoDialog() {
    await this.$openDialog(
      import("~/components/table/orderSystem/orderInfoDialog.vue"),
      {
        session: this,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }

  async sendOrder(item: Partial<OrderGroup>) {
    if (item) {
      await this.$feathers.service("tableSessions/order").create(
        {
          session: this.item._id,
          products: item.items.map(it => ({
            ...it,
            status: "init",
          })),
          replaceProducts: item.items.map(it => it.id),
          staff: this.$shop.staffId,
          device: this.$shop.device._id,
          verify: true,
        },
        {
          query: {
            addTvStatus: this.type === "takeAway" || this.type === "dineInNoTable" || this.type === "delivery",
            acceptOrder: true,
          },
        },
      );

      await this.$feathers.service("actionLogs").create({
        session: this.item._id,
        ...(this.view ? { view: getID(this.view) } : {}),
        staff: this.$shop.staffId,
        type: "orderManage/tableSessionConfirmOrder",
        detail: { products: item.items },
      });

      await this.reload();
    }
  }

  async declineOrder(item: Partial<OrderGroup>) {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDeleteDialog.vue"),
      {
        icon: "$exclamation",
        title: this.$t("pendingOrderList.declineTitle") + " ？ ",
        desc: this.$t("pendingOrderList.declineDesc", {
          order: `${this.name ? `${this.name}-` : ""}${this.sessionData.sessionName}`,
        }),
        reasonTitle: this.$t("pendingOrderList.declineReason"),
        confirmText: this.$t("basic.ok"),
      },
      {
        maxWidth: "500px",
        contentClass: "editor-dialog",
      },
    );

    if (!c) return;

    if (item) {
      await this.$feathers.service("actionLogs").create({
        session: this.item._id,
        ...(this.view ? { view: getID(this.view) } : {}),
        staff: this.$shop.staffId,
        type: "orderManage/tableSessionDeclineOrder",
        detail: { products: item.items },
      });

      await this.$feathers.service("tableSessions/cancel").create({
        session: this.item._id,
        products: item.items.map(it => it.id),
        noPrint: true,
        kdsDone: true,
        staff: this.$shop.staffId,
        cancelReason: { remarks: c.cancelReason },
      });

      if (this.type === "takeAway" || this.type === "dineInNoTable" || this.type === "delivery") {
        if (this.status === "done") {
          await this.$feathers.service("tableSessions/void").create({
            session: this.item._id,
            staff: this.$shop.staffId,
            refund: true,
            cancelReason: c.cancelReason,
          });
        } else {
          await this.cancelPending(true, { cancelReason: c.cancelReason });
        }

        await this.atomic({
          endTime: new Date(),
        });
      }

      await this.reload();
      await this.updateCachedInfo(true);
    }
  }

  // #endregion
}

@Component
export class TableSessionAction extends mixins(
  MapFields(
    "tableSessionActions",
    ["type", "status", "session", "sourcePhone", "reason"] as const,
    [],
    {},
    {
      deltaUpdate: true,
    },
  ),
) {}
