
import { Component, Prop, Vue, Watch, mixins, FindType, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import SingleCurrencyEditor from "~/components/boxs/SingleCurrencyEditor.vue";

@Component({ components: { SingleCurrencyEditor, EditorDatePicker } })
export default class AddPointDialog extends mixins(Dialog) {
  @Prop()
  user: FindType<"users">;

  amount = 0;
  date = new Date();
  message = "by admin";
  loading = false;

  async adjustSpend() {
    try {
      this.loading = true;

      await this.$feathers.service("users/spends/adjust").create({
        user: getID(this.user),
        amountInt: this.amount,
        date: this.date,
      });

      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }
}
