
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeGraph, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorGraph extends Vue {
  @Prop() template!: PrinterTemplateNodeGraph;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  get w() {
    return +(this.template.props["w"]?.eval?.(this.context) ?? "0");
  }

  get h() {
    return +(this.template.props["h"]?.eval?.(this.context) ?? "0");
  }

  get style() {
    return {
      ...(this.align !== undefined
        ? {
            alignSelf: {
              left: "flex-start",
              center: "center",
              right: "flex-end",
            }[this.align],
          }
        : {}),
    };
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }
}
