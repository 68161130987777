
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, getID } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import { CartItem } from "@common/table/cart";
import { getProductTree } from "@common/table/util";
import Dialog from "domore-table/mixins/Dialog";
import type { LangArrType } from "@feathers-client/i18n";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorTextField,
  },
})
export default class BatchSetKitchenOption extends mixins(Dialog()) {
  @Prop()
  line: ProductLine[];

  @Prop()
  session: TableSession;

  loading = false;

  cancelReason: { _id: string; name: LangArrType } = null;
  remarks = "";

  cancelQty = -1;

  get canSetQty() {
    return this.line?.length === 1;
  }

  async confirmSet() {
    const staff = await this.$shop.checkPermission(["orderManage/tableSessionCancelProducts"]);
    if (staff === false) return;
    try {
      this.loading = true;

      let dirty = true;
      let set = new Set<string>();
      let iset = new Set<string>();
      let cancelProducts = new Set<ProductLine>();
      const cancelQtyMap = {};

      for (let item of this.line) {
        const a = item;
        set.add(item.id);
        cancelProducts.add(item);
        if (this.canSetQty && this.cancelQty !== item.quantity) {
          let cur = item;
          while (cur?.fromProduct) {
            cur = this.session.products.find(it => cur.fromProduct === it.id);
          }
          if (!cur) return;
          const tree = getProductTree(this.session, cur).filter(it => it.status !== "cancel");
          const tempCart = await this.session.addFromJSONs(tree, { temp: true });

          const cartReplacing = new Set(tempCart.map(it => it.id));

          const currentCart = tempCart.filter(it => !it.fromProduct)?.[0];
          const remainQuantity = currentCart.quantity - this.cancelQty;
          currentCart.quantity = this.cancelQty;

          const newItem = await this.session.cloneItem(currentCart, tempCart);

          if (newItem) {
            newItem.quantity = remainQuantity;
          }

          await this.$feathers.service("tableSessions/order").create({
            session: this.session.item._id,
            products: tempCart.map(it => it.toLine()),
            replaceProducts: Array.from(cartReplacing) as any[],
            staff: staff?._id || this.$shop.staffId,
            noPrint: true,
            update: {
              ...this.session.cachedPriceDetails,
            },
            device: this.$shop.device._id,
          });
        }
      }
      while (dirty) {
        dirty = false;
        for (let item of this.session.products) {
          if (item.fromProduct && set.has(item.fromProduct) && !set.has(item.id)) {
            set.add(item.id);
            dirty = true;
          }
        }
      }

      const resp = await this.$feathers.service("tableSessions/cancel").create({
        session: this.session.id,
        products: new Array(...set) as any[],
        noPrint: this.session.postEditing,
        staff: staff?._id || this.$shop.staffId,
        cancelReason: {
          ...(this.cancelReason || {}),
          remarks: this.remarks,
        } as any,
      });

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "orderManage/tableSessionCancelProducts",
        detail: {
          products: new Array(...cancelProducts) as any[],
          cancelQty: this.cancelQty,
          cancelReason: {
            ...(this.cancelReason || {}),
            remarks: this.remarks,
          } as any,
        },
      });

      await this.session.updateCachedInfo(true);

      this.modalResult(true);
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.mesage);
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    if (this.canSetQty) {
      this.cancelQty = this.line?.[0]?.quantity;
    }
  }
}
