
import { Component, Vue, Prop } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { VModel } from "vue-property-decorator";
import { categoryColorList, odsColorList } from "../plugins/table/constants";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class KdsColorPicker extends Vue {
  @VModel()
  inputValue: string;

  @Prop()
  label: string;

  @Prop({ default: "kds" })
  listType: "kds" | "ods";

  get nameField() {
    return [
      {
        translate: true,
        field: "name",
      },
    ];
  }

  get colorList() {
    const list = this.listType === "kds" ? categoryColorList : odsColorList;
    return Object.entries(list).map(([k, v]) => ({
      _id: v,
      name: { $t: "colors." + k },
    }));
  }
}
