
import { Component, Prop, Vue, Watch, mixins, FindType } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";

@Component
export default class PrinterStatus extends Vue {
  @Prop()
  jobId: string;

  @Prop()
  session: TableSession;

  @Prop({ type: Boolean, default: true })
  hideDone: boolean;
}
