
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import { CartItem } from "@common/table/cart";
import CartPanelBase from "~/components/table/orderSystem/cartPanelBase";

@Component({})
export default class CartHeader extends mixins(CartPanelBase) {
  @Prop()
  session: TableSession;
}
