import _ from "lodash";
import cookie from "js-cookie";
import Vue from "vue";
import moment from "moment";
import { lowSpec } from "@feathers-client";

const expires = 365;

declare let $nuxt: Vue;

export const state = () => ({
  locales: {
    en: { name: "English", iso: "en-us", code: "en" },
    "zh-hk": { name: "繁體中文", iso: "zh-hk", code: "zh-hk" },
  },
  allLocales: {
    en: { name: "English", iso: "en-us", code: "en" },
    "zh-hk": { name: "繁體中文", iso: "zh-hk", code: "zh-hk" },
  },
  locale: "cht",
  title: "",
  titles: null,
  nestedBack: false,
  args: {},
  actions: [],
  processing: null,
  fullPage: false,
  tableMode: false,
  noDrawer: false,
  dark: false,
  error: "",
  success: "",
  alert: "",
  lowSpec: false,

  user: {
    _id: "",
    shop: "",
  },
  jwt: "",
  profile: "",
  inited: false,

  rePaidOrder: null,
  hasNewFailJob: false,
  tvWaterBars: [],
  tvMenus: [],

  cast: false,
  disableTextSelect: false,
  hydrated: true,
  connected: false,

  fontScale: 100,
  translateLocale: null,
  displaySettings: {
    rotate: 0,
    showWaterBarName: true,
  },
  savedLogins: [],
  adjustAmount: 0,
  hideHeader: false,
  headerHeight: 16,
  secondaryLocale: null,
  performance: lowSpec,
});

export const getters = {
  margs: state => {
    return _.mapValues(state.args, it => it && it.toString());
  },

  isVerified() {
    return true;
  },

  userId: state => {
    return state.user && state.user._id;
  },

  isAdmin: state => {
    return state.user && state.user.role === "admin";
  },

  isStaff: state => {
    return state.user && state.user.role === "staff";
  },

  features: state => {
    const rootFeatures = $nuxt?.$config?.features || {};
    if (rootFeatures.featuresFromGroup) {
      const shopGroup = $nuxt?.$shop?.shopGroup?.features;
      if (shopGroup) {
        return _.merge({}, rootFeatures, shopGroup);
      }
    }
    return null;
  },
};

export const mutations = {
  SET_HAS_FAIL(state, value) {
    state.hasNewFailJob = value;
  },
  SET_REPAID(state, item) {
    state.rePaidOrder = item || null;
  },
  LOGOUT(state) {
    const removeId = state.user?._id;
    state.user = {};
    localStorage.setItem(
      "login",
      JSON.stringify({
        user: {},
        jwt: "",
      }),
    );
    localStorage.removeItem("profile");
    localStorage.removeItem("settings");

    if (removeId) {
      const idx = state.savedLogins.findIndex(it => it.user._id === removeId);
      if (idx !== -1) {
        state.savedLogins.splice(idx, 1);
        localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
      }
    }
  },

  SET_TV(state, list) {
    state.tvWaterBars = list || [];
  },

  SET_LANG(state, locale) {
    state.locale = locale;
  },

  REMOVE_ADD(state) {
    console.log(state.actions);
    state.actions = _.filter(state.actions, it => it.name != "add");
    console.log(state.actions);
  },

  SET_TITLE(
    state,
    {
      title,
      actions,
      fullPage,
      args,
      noDrawer,
      dark,
      tableMode,
      disableTextSelect,
      titles,
      nestedBack,
      langMenu,
      hideHeader,
    },
  ) {
    state.title = title || "";
    state.titles = titles ? titles : null;
    state.nestedBack = nestedBack || false;
    state.fullPage = fullPage || false;
    state.actions = actions || [];
    state.args = args || {};
    state.noDrawer = noDrawer || false;
    state.dark = dark || false;
    state.disableTextSelect = disableTextSelect || false;
    state.tableMode = tableMode || false;
    state.langMenu = langMenu || false;
    state.hideHeader = hideHeader || false;
  },

  SET_TITLE_ARGS(state, args) {
    state.args = args || {};
  },

  SET_THEME(state, dark) {
    state.dark = dark;
  },

  SET_PROCESSING(state, processing) {
    if (state.processing !== processing) state.processing = processing;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_SUCCESS(state, success) {
    state.success = success;
  },

  SET_ALERT(state, alert) {
    state.alert = alert;
  },

  SET_CONNECTED(state, connected) {
    state.connected = connected;
  },

  SET_CAST_MODE(state, mode) {
    state.cast = mode || true;
    state.user = {};
    state.inited = true;
    state.jwt = "";
    state.profile = "";
  },

  INIT(state) {
    if (state.inited) return;
    state.inited = true;
    if (!localStorage) return;

    const login = localStorage.getItem("login");
    if (login) {
      const info = JSON.parse(login);
      state.user = info.user || {};
      state.jwt = info.jwt;
    }
    const profile = localStorage.getItem("profile");
    state.profile = profile;
    const savedLogins = localStorage.getItem("savedLogins");
    if (savedLogins) {
      state.savedLogins = JSON.parse(savedLogins);
    }
  },

  SET_USER(state, user) {
    state.user = user || {};
    if (state.cast) return;
    localStorage.setItem(
      "login",
      JSON.stringify({
        user: state.user,
        jwt: state.jwt,
      }),
    );
  },

  SET_JWT(state, jwt) {
    state.jwt = jwt;
    if (state.cast) return;
    localStorage.setItem(
      "login",
      JSON.stringify({
        user: state.user,
        jwt: state.jwt,
      }),
    );
  },

  ADD_LOGIN(state, loginData) {
    const cur = state.savedLogins.find(it => it.user._id === loginData.user._id);
    if (cur) {
      cur.accessToken = loginData.accessToken;
      cur.user = loginData.user;
      cur.shop = loginData.shop || null;
    } else {
      state.savedLogins.push({
        accessToken: loginData.accessToken,
        user: loginData.user,
        shop: loginData.shop || null,
      });
    }
    localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
  },

  UPDATE_LOGIN(state, loginData) {
    const cur = state.savedLogins.find(it => it.user._id === loginData.user._id);
    if (cur) {
      cur.accessToken = loginData.accessToken;
      cur.user = loginData.user;
      localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
    }
  },

  REMOVE_LOGIN(state, removeId) {
    const idx = state.savedLogins.findIndex(it => it.user._id === removeId);
    if (idx !== -1) {
      state.savedLogins.splice(idx, 1);
      localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
    }
  },

  SET_USER_PROFILE(state, profile) {
    state.profile = profile;
    if (state.cast) return;
    // console.log('set user profile pic to ', (profile.substr(0, 100)), profile.length);
    localStorage.setItem("profile", profile || "");
  },

  SET_TV_MENU(state, menus) {
    state.tvMenus = menus;
  },

  SET_FONT_SCALE(state, scale) {
    state.fontScale = scale || 100;
  },

  SET_DISPLAY_SETTINGS(state, settings) {
    Object.assign(state.displaySettings, settings);
  },

  SET_TRANSLATE_LOCALE(state, locale) {
    state.translateLocale = locale;
  },

  SET_ORDERING_DISPLAY(state, display) {
    state.orderingDisplay = display;
  },

  SET_LOCALES(state, locales) {
    state.locales = locales;
  },

  SET_ALL_LOCALES(state, locales) {
    state.allLocales = locales;
  },

  SET_AMOUNT(state, amount) {
    state.adjustAmount = amount;
  },

  SET_HEADER_HEIGHT(state, height) {
    state.headerHeight = height;
  },

  SET_SECONDARY_LOCALE(state, locale) {
    state.secondaryLocale = locale;
  },

  SET_PERFORMANCE(state, performance) {
    state.performance = performance;
  },

  SET_LOW_SPEC(state, lowSpec) {
    state.lowSpec = lowSpec;
  },
};

export const actions = {
  async changeLang({ commit, state }, { code, $i18n, init }) {
    const item = state.locales[code];
    if (item) {
      if (!init) {
        cookie.set("i18n_redirected", code, { expires });
      }
      commit("SET_LANG", code);
      await $i18n.loadRemoteLocale(code);
      $i18n.locale = code;
      // switch(item.locale) {
      //     case 'en-us':
      //         break;
      //     case 'zh-cn':
      //         await import('moment/locale/zh-cn');
      //         break;
      //     case 'zh-hk':
      //         await import('moment/locale/zh-hk');
      //         break;
      // }
      moment.locale(item.locale);
    }
  },
};
