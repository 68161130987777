import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, getID } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import { CartItem } from "@common/table/cart";
import { getProductTree } from "@common/table/util";
import OrderSystemCheckoutBase from "~/components/table/orderSystem/checkoutBase";

@Component
export default class CartPanelBase extends Vue {
  session!: TableSession;

  @PropSync("loading")
  loadingSync: boolean;

  @PropSync("peopleMenu")
  peopleMenuSync: boolean;

  @PropSync("crmMenu")
  crmMenuSync: boolean;

  get gifts() {
    return (this.session.coupons || []).filter(it => it.discountSource === "gift");
  }

  cleanOnUpdated = false;

  get isPaying() {
    return (
      (this.session?.postEditing && this.session?.screenOverride === "checkout") || this.session?.status === "toPay"
    );
  }

  get isOngoing() {
    return this.session.postEditing || this.session.status === "ongoing";
  }

  clearAllItem() {
    this.session.clearCart();
    this.$root.$emit("cleanCoupon");
  }

  async cancelOrder(): Promise<Boolean> {
    const res = await this.$openDialog(
      import("~/components/dialogs/ConfirmDeleteDialog.vue"),
      {
        title: this.$t("cart.cancelOrder"),
        desc: this.$t("cart.cancelOrderConfirm", {
          table: this.session.tableRefs[0]?.table.name,
          session: this.session.sessionName,
        }),
        confirmLock: !this.$shop.hasPermission(["orderManage/tableSessionCancelOrder"]),
      },
      {
        maxWidth: "380px",
      },
    );
    if (res && res.result) {
      const staff = await this.$shop.checkPermission(["orderManage/tableSessionCancelOrder"]);
      if (staff === false) return;

      if (this.session.products.length && this.session.products.every(it => it.status !== "cancel")) {
        const cancelSuccess = await this.$openDialog(
          import("./cancelReason.vue"),
          {
            line: this.session.products.filter(it => it.status !== "cancel"),
            session: this.session,
          },
          {
            maxWidth: "max(50vw,500px)",
          },
        );
        await new Promise(resolve => setTimeout(resolve, 200));
        if (!cancelSuccess) return false;
      }
      this.clearAllItem();

      await this.session.cancelPending(true, { cancelReason: res.cancelReason });

      if (res.print) {
        await this.session.printOrder();
      }

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: "orderManage/tableSessionCancelOrder",
        detail: { payment: [] },
      });
    }
    return res?.result;
  }

  get canMultiSelect() {
    return !!(this.session.cart?.length || this.session.products.filter(it => it.status !== "cancel").length);
  }

  get selectableItems(): any[] {
    return this.session.selectingCart
      ? this.session.cart
      : this.session.selectingLine
        ? this.session.products.filter(it => it.status !== "cancel")
        : [];
  }

  get selectedItems() {
    return this.selectableItems.filter(it => (it as any).selected);
  }

  get selectedCartClearable() {
    return this.session.cart.filter(it => it.selected && !it.fromProduct);
  }

  get selectedItemResume() {
    return this.selectedItems.filter(it => (it as ProductLine).status === "hold");
  }

  get selectedItemReprint() {
    return this.selectedItems.filter(it => (it as ProductLine).status === "sent");
  }

  clearSelected() {
    for (let item of this.selectedCartClearable) {
      item.remove();
    }
    if (!this.selectableItems.length) {
      this.session.cancelSelect();
    }
  }

  toggleSelectAll() {
    if (this.selectableItems.length === this.selectedItems.length) {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", false);
      }
    } else {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", true);
      }
    }
  }

  toggleHold() {
    const hold = this.selectedItems.filter(it => (it as CartItem).status === "hold");

    if (hold.length === this.selectedItems.length) {
      for (let item of this.selectedItems) {
        (item as CartItem).status = "init";
      }
    } else {
      for (let item of this.selectedItems) {
        (item as CartItem).status = "hold";
      }
    }
  }

  async orderInfoDialog() {
    await this.$openDialog(
      import("./orderInfoDialog.vue"),
      {
        session: this.session,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }

  async batchSetKitchenOption() {
    if (!this.selectedItems.length) return;
    await this.$openDialog(
      import("./batchSetKitchenOption.vue"),
      {
        ...(this.session.selectingCart ? { cart: this.selectedItems } : { line: this.selectedItems }),
        session: this.session,
        defaultIncludeDetails: this.selectedItems.length !== this.selectableItems.length,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }

  async batchSendToKitchen() {
    try {
      this.loadingSync = true;
      const items = this.selectedItemResume.slice();
      for (let item of items) {
        item.status = "init";
      }
      await this.$feathers.service("tableSessions/reprint").create({
        session: this.session.id,
        products: this.selectedItemReprint.map(it => it.id),
        staff: this.$shop.staffId,
      });

      await this.$feathers.service("tableSessions/order").patch(null, {
        session: this.session.item._id,
        products: items,
        staff: this.$shop.staffId,
        device: this.$shop.device._id,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loadingSync = false;
    }
  }

  async batchCancel() {
    if (!this.selectedItems.length) return;
    await this.$openDialog(
      import("./cancelReason.vue"),
      {
        line: this.selectedItems,
        session: this.session,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
    await new Promise(resolve => setTimeout(resolve, 200));
    if (!this.selectableItems.length) {
      this.session.cancelSelect();
    }
  }

  async placeOrder() {
    //check Stock
    const values = Object.values(this.session.queryStock());
    if (values.length) {
      const result = await this.$openDialog(
        import("~/components/dialogs/StockWarnDialog.vue"),
        {
          values,
        },
        {
          maxWidth: "520px",
        },
      );
      if (!result) return;
    }
    try {
      if (this.session.selectingCartItem) {
        this.session.openProductMenu(null);
      }
      if (this.loadingSync) return;
      this.loadingSync = true;
      await this.placeOrderInner();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      await this.session.reload();
    } finally {
      this.loadingSync = false;
    }
  }

  async placeOrderInner() {
    const permissions = [`orderManage/tableSessionPlaceProducts`];
    this.cleanOnUpdated = true;
    try {
      const replaceProducts = this.session.getCartReplacing();
      const replaceProductsId = replaceProducts.map(it => it.id);

      const newProducts = this.session.cart.filter(it => !it.fromLine).map(it => it.toLine());
      const products = this.session.cart.map(it => it.toLine()) as any[];

      if (replaceProducts.length) permissions.push(`orderManage/tableSessionEditProducts`);

      const staff = await this.$shop.checkPermission(permissions);
      if (staff === false) {
        this.cleanOnUpdated = false;
        return false;
      }

      if (this.session.isNoTable) {
        for (let p of products) {
          p.status = "hold";
        }
      }

      for (let cartItem of this.session.cart) {
        if (cartItem.editPriceStaff) {
          let productName = null;
          if (cartItem?.product) productName = (await this.$feathers.service("products").get(cartItem?.product)).name;
          await this.$feathers.service("actionLogs").create({
            session: this.session.item._id,
            view: getID(this.session.item.view),
            staff: cartItem.editPriceStaff === true ? null : getID(cartItem.editPriceStaff),
            type: "orderManage/tableSessionEditProductPrice",
            detail: { cartItem, originalPrice: cartItem.originalPrice, newPrice: cartItem.manualPrice, productName },
          });

          cartItem.editPriceStaff = null;
        }
      }

      await this.$feathers.service("tableSessions/order").create({
        session: this.session.item._id,
        products,
        replaceProducts: replaceProductsId,
        staff: staff?._id || this.$shop.staffId,
        noPrint: this.session.postEditing,
        update: {
          ...this.session.cachedPriceDetails,
        },
        device: this.$shop.device._id,
      });

      if (replaceProducts?.length) {
        await this.$feathers.service("actionLogs").create({
          session: this.session.item._id,
          view: getID(this.session.item.view),
          staff: staff?._id || this.$shop.staffId,
          type: "orderManage/tableSessionEditProducts",
          detail: { replaceProducts },
        });
      }

      if (newProducts?.length) {
        await this.$feathers.service("actionLogs").create({
          session: this.session.item._id,
          view: getID(this.session.item.view),
          staff: staff?._id || this.$shop.staffId,
          type: "orderManage/tableSessionPlaceProducts",
          detail: { newProducts },
        });
      }

      if (this.cleanOnUpdated) this.session.clearCart({ coupons: false });
      await this.session.updateCachedInfo(true);
      await this.session.updateAfterOrder();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      try {
        this.cleanOnUpdated = false;
        await this.session.reload();
        for (let item of this.session.cart) {
          if (this.session.products.find(it => it.id === item.id)) {
            await item.remove();
          }
        }
        await this.session.updateAfterOrder();
      } catch (e) {
        console.error(e);
      }
    } finally {
      this.cleanOnUpdated = false;
    }
  }

  async resumeAll() {
    await this.$feathers.service("tableSessions/order").patch(
      null,
      {
        session: this.session.item._id,
        products: this.session.products
          .filter(it => it.status === "hold")
          .map(it => ({
            ...it,
            status: "init",
          })),
        staff: this.$shop.staffId,
        device: this.$shop.device._id,
      },
      {
        query: {
          addTvStatus:
            this.session.type === "takeAway" ||
            this.session.type === "dineInNoTable" ||
            this.session.type === "delivery",
        },
      },
    );
    await this.session.reload();
  }
}
