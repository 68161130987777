
import { Vue, Component, Prop, PropSync, FindType, getID, FindPopRawType } from "@feathers-client";
import EditorObjectPickerList from "~/dep/schema-editor/EditorObjectPickerList.vue";
import _ from "lodash";
import { ObjectID } from "@db";
import type { TableSession } from "~/plugins/table/session";
import { SchemaHelper } from "@schemaEditor/plugin";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorObjectPickerList,
    EditorTextField,
  },
})
export default class OrderSystemEditUser extends Vue {
  @PropSync("crmMenu")
  crmMenuSync: boolean;

  @PropSync("editUserScreen")
  editUserScreenSync: boolean;

  @PropSync("editUserId")
  editUserIdSync: string;

  @PropSync("session")
  sessionSync: TableSession;

  editing = false;
  user: FindType<"appUsers"> = null;
  userSessions = [];
  productMapping: { [key: string]: any } = {};
  originalOrderTags: ObjectID[] = [];
  originalCustomerTags: ObjectID[] = [];
  pendingSaveOrderTags: ObjectID[] = [];
  pendingSaveCustomerTags: ObjectID[] = [];
  newUser = false;

  customerName = "";
  customerPhonePrefix = this.$config?.regionalConfig?.phonePrefix ?? "852";
  customerPhone = "";
  customerEmail = "";

  nameEmpty = false;
  phoneEmpty = false;
  emailEmpty = false;

  pointRecord = [];

  async beforeMount() {
    await this.loadData();
  }
  async loadData() {
    this.user = await this.$feathers
      .service("appUsers")
      .get(getID(this.editUserIdSync), { query: { $populate: ["vipLevel", "ranks.rank"] } })
      .catch(e => {
        return null;
      });
    if (this.user == null) {
      this.editing = true;
      this.newUser = true;

      const schemas: SchemaHelper = this.$schemas;
      await schemas.init();
      const config = schemas.getConfigByApiPath("appUsers");
      this.user = _.defaultsDeep(this.user || {}, config.defaultValue || {});
      this.customerName = this.editUserIdSync;
      this.customerPhonePrefix = this.$config?.regionalConfig?.phonePrefix ?? "852";
    } else {
      this.customerName = this.user.name;
      this.customerPhonePrefix = this.user.phonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852");
      this.customerPhone = this.user.phoneValue;
      this.customerEmail = this.user.email;
      this.originalCustomerTags = this.user.customerTags;
      this.originalOrderTags = this.user.orderTags;
      this.newUser = false;
      this.userSessions =
        (await this.$feathers.service("tableSessions").find({
          query: {
            user: getID(this.editUserIdSync),
            status: "done",
            $paginate: false,
            $sort: {
              date: -1,
            },
          },
          paginate: false,
        })) || [];
      if (this.userSessions.length) {
        this.productMapping = this.lastSalesMapping();
      }

      await this.loadPointRecord(this.sessionSync.user);
    }
  }

  getLastSalesItem(id) {
    const item = _.get(this.productMapping, id);
    return item;
  }

  query = {
    $populate: ["ranks.rank", "vipLevel"],
  };

  get totalAmount() {
    return this.userSessions?.reduce((total, session) => {
      return total + session.amount;
    }, 0);
  }

  lastSalesMapping() {
    if (!this.userSessions || !this.userSessions.length) return {};
    var result: { [key: string]: any } = {};
    _.forEach(this.userSessions[0].products, it => {
      if (result[it.product] == null) {
        result[it.product] = this.$shop.productDict[it.product];
        if (!result[it.product]) return;
        if (it.options) {
          let options = it.options.map(option => {
            const optObj = this.$shop.productOptionDict[option.option];
            let optName = option.values
              .map(value => {
                let name = this.$td(optObj.options.find(opt => opt._id === value).name);
                return name;
              })
              .join(", ");

            if (optName) {
              return optName;
            }
          });
          options = options.filter(it => it);
          result[it.product]["memberSelectedOptions"] = options.join(", ");
        }
        result[it.product]["quantity"] = 0;
        result[it.product]["amount"] = 0;
      }
      result[it.product]["quantity"] += it.quantity;
      result[it.product]["amount"] += it.salesPrice;
    });
    return result;
  }

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active" && (!user.expiry || new Date(user.expiry) > new Date());
  }

  onOrderTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalOrderTags)) {
      this.originalOrderTags = this.user.orderTags;
      this.pendingSaveOrderTags = e.map(tag => tag._id);
    }
  }

  onCustomerTagsUpdate(e) {
    const tagsInput = JSON.stringify(e.map(tag => tag._id));
    if (tagsInput !== JSON.stringify(this.originalCustomerTags)) {
      this.originalCustomerTags = this.user.customerTags;
      this.pendingSaveCustomerTags = e.map(tag => tag._id);
    }
  }

  async loadPointRecord(user) {
    this.pointRecord = await this.$feathers
      .service("users/points/available")
      .find({ query: { user: getID(user), tag: ["point", "dollar"] } });
  }

  async cancelEdit() {
    const res = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog2.vue"),
      {
        icon: "$editNotSave",
        iconClass: "w-12 h-12",
        title: this.$t("peopleCrm.cancelEdit"),
        desc: this.$t("peopleCrm.cancelEditDesc"),
        cancelText: this.$t("basic.goBack"),
        confirmText: this.$t("basic.notSave"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!res) return;

    if (this.newUser) return this.back();

    this.editing = false;
    this.customerName = this.user.name;
    this.customerPhonePrefix = this.user.phonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852");
    this.customerPhone = this.user.phoneValue;
    this.customerEmail = this.user.email;
    this.originalCustomerTags = this.user.customerTags;
    this.originalOrderTags = this.user.orderTags;
  }

  async save() {
    if (!this.newUser) {
      const staff = await this.$shop.checkPermission(["crm/modifyMemberInfo"]);
      if (staff === false) return;

      this.user = await (this.$feathers as any).service("appUsers").patch(this.user._id, {
        name: this.customerName,
        email: this.customerEmail.toLowerCase(),
        phonePrefix: this.customerPhonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852"),
        phoneValue: this.customerPhone,
        ...(this.originalCustomerTags !== this.pendingSaveCustomerTags
          ? {
              customerTags: this.pendingSaveCustomerTags,
              customerTagsModifiedDate: new Date(),
            }
          : {}),
        ...(this.originalOrderTags !== this.pendingSaveOrderTags
          ? {
              orderTags: this.pendingSaveOrderTags,
              orderTagsModifiedDate: new Date(),
            }
          : {}),
      });

      await this.$feathers.service("actionLogs").create({
        staff: staff?._id || this.$shop.staffId,
        type: "crm/modifyMemberInfo",
        detail: {
          id: this.user.userId,
          name: this.user.name,
        },
      });
    } else {
      const staff = await this.$shop.checkPermission(["crm/createMember"]);
      if (staff === false) return;

      this.nameEmpty = false;
      this.phoneEmpty = false;
      this.emailEmpty = false;

      if (!this.customerName) {
        this.nameEmpty = true;
      }
      if (!this.customerPhone || !this.customerPhonePrefix) {
        this.phoneEmpty = true;
      }
      if (!this.customerEmail) {
        this.emailEmpty = true;
      }

      if (this.nameEmpty || this.phoneEmpty || this.emailEmpty) return console.error("Input Field Empty!!");

      const duplicated = await this.checkInput(
        this.customerName,
        this.customerPhonePrefix + this.customerPhone,
        this.customerEmail?.toLocaleLowerCase(),
      );
      if (duplicated) return console.error("invalid input!!");
      this.newUser = false;
      this.user = await (this.$feathers as any)
        .service("appUsers")
        .create({
          name: this.customerName,
          email: this.customerEmail?.toLowerCase(),
          phonePrefix: this.customerPhonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852"),
          phoneValue: this.customerPhone,
          password: this.customerPhone || this.customerEmail?.toLowerCase(),
        })
        .catch(e => {
          this.newUser = true;
          this.editing = true;
        });

      if (this.user._id) {
        await this.$feathers.service("actionLogs").create({
          staff: staff?._id || this.$shop.staffId,
          type: "crm/createMember",
          detail: {
            id: this.user.userId,
            name: this.user.name,
          },
        });

        this.sessionSync.user = this.user._id;
        const level = await this.$feathers.service("vipLevels").find({
          query: {
            level: 0,
          },
          paginate: false,
        });
        if (level.length) {
          this.user.vipLevel = level[0]._id;
          this.user.status = "active";
          this.user.expiry = this.$moment().add(level[0].lifeDays, "days").toDate();
          await (this.$feathers as any).service("appUsers").patch(this.user._id, {
            vipLevel: this.user.vipLevel,
            status: this.user.status,
            expiry: this.user.expiry,
          });
        }
        this.editUserIdSync = this.user._id.toString();
      }
    }

    this.editing = false;
  }

  async checkInput(name, phone, email) {
    try {
      const orList = [];
      if (name) {
        orList.push({ name: name });
      }
      if (phone) {
        orList.push({ phone: phone });
      }
      if (email) {
        orList.push({ email: email });
      }
      const memberData = await this.$feathers.service("appUsers").find({
        query: {
          $select: ["name", "phone", "email"],
          $or: orList,
          $limit: 1,
        },
      });

      let existLength = [];
      const checkDuplicate = memberData?.data.map(item => {
        if (item.name === name) {
          existLength.push(item.name);
        }
        if (item.phone === phone) {
          existLength.push(item.phone);
        }
        if (item.email === email) {
          existLength.push(item.email);
        }
      });

      if (existLength.length > 0) {
        await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: this.$t("orderSystem.editUser.basicInfo.errorMemberDuplicated.$", {
              string: existLength.toString(),
            }),
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            confirmText: "text-sm font-medium",
            confirm: this.$t("pages.shops.selectedMethod.confirm"),
            hasCancel: false,
          },
          {
            maxWidth: "500px",
          },
        );

        return true;
      } else {
        return false;
      }
    } catch (e) {
      return console.error(e);
    }
  }

  async reOrder() {
    await this.sessionSync.addFromJSONs(this.userSessions[0].products, { clone: true });
  }

  async back() {
    this.crmMenuSync = true;
    this.editUserScreenSync = false;
    return;
  }
}
