import { PrinterBase, PrinterConf } from './base'
import { PrinterServer, PrinterCommand, wrapVue } from '../index'
import type { PrintJob, PrintQueue } from '../printQueue';
import { supported, init, dispose, print, getPrinterInfo } from '../ports/posPrint'
import { Vue } from 'vue-property-decorator';
import { CanvasSequence } from '../printSequence/canvas';

export interface PosPrintConf extends PrinterConf {
    port : 'posPrint'
}

export class PosPrintPrinter extends PrinterBase<PosPrintConf, PosPrintConf> {
    constructor(server: PrinterServer, conf : PosPrintConf) {
        super(server, conf);
    }

    createSequence<T>(context?: Vue): T {
      if(this.conf.opts.escpos === 'bitmap') {
        throw new Error('Bitmap mode not supported');
      } else if(this.conf.opts.escpos === 'json') {
        const seq = new CanvasSequence(wrapVue(context ?? this.parent.context), this);
        seq.imageAsBase64 = true;
        return seq as any;
      } else {
        return super.createSequence<T>(context);
      }
    }
    
    onDisconnectedCore() {
    }

    async initCore() {
    }

    async tryConnectCore() {
        await init();
    }

    async printCore(job : PrintJob) {
        try {
            await print(job.data, job.opts);
        }
        catch(e) {
            if(e.response && e.response.data) {
                throw new Error(e.response.data.message)
            } else {
                throw e;
            }
        }
    }

    async disconnectCore() {
      await dispose();
    }

    async requestNewDeviceCore() {
        await init();
        const info = getPrinterInfo();
        return {
          ...this.conf,
          opts: {
            ...(this.conf.opts || {}),
            ...info,
          }
        }
    }
}
