
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";
declare const Terminal: any;

@Component({})
export default class PosDeviceControl extends Vue {
  @Prop()
  item: FindType<"posDevices">;

  octopusControl = false;
  remoteDebug = false;
  terminal: any;

  async openSettings() {
    await this.$feathers.service("posCommands").create({
      device: this.item._id as any,
      command: "openSettings",
    });
  }
  async reload() {
    await this.$feathers.service("posCommands").create({
      device: this.item._id as any,
      command: "reload",
    });
  }
  async testPrint() {
    await this.$feathers.service("posCommands").create({
      device: this.item._id as any,
      command: "testPrint",
    });
  }

  async setupOctopus(type: string) {
    await this.$feathers.service("posCommands").create({
      device: this.item._id as any,
      command: "octopus",
      data: {
        type,
      },
    });
  }

  @Ref()
  container: HTMLElement;

  async enableDebug() {
    await this.$feathers.service("posCommands").create({
      device: this.item._id as any,
      command: "enableDebug",
    });
  }

  async toggleDebug() {
    this.remoteDebug = !this.remoteDebug;
    if (this.remoteDebug) {
      await Vue.nextTick();
      if (!this.terminal) {
        if (!document.getElementById("xterm-css")) {
          var link = document.createElement("link");
          link.id = "xterm-css";
          link.setAttribute("rel", "stylesheet");
          link.setAttribute("href", "https://cdn.jsdelivr.net/npm/xterm@5.2.1/css/xterm.min.css");
          document.head.appendChild(link);
        }
        const loadScript = require("simple-load-script");
        await loadScript("https://cdn.jsdelivr.net/npm/xterm@5.2.1/lib/xterm.min.js");

        this.terminal = new Terminal({
          convertEol: true,
        });
        const elems = this.$el.querySelector(".xterm");
        if (elems) elems.remove();
      }
      this.terminal.open(this.container);
      await this.$feathers.service("posCommands").create({
        device: this.item._id as any,
        command: "remoteDebug",
      });
    }
  }
}
