
import { Component, Prop, Vue, Watch, mixins, FindType } from "@feathers-client";
import Dialog from "~/mixins/DialogTS";
import { TableSessionAction } from "~/plugins/table/session";

@Component
export default class PendingActionDialog extends mixins(Dialog) {
  @Prop()
  session: FindType<"tableSessions">;

  @Prop()
  pendingAction: TableSessionAction;

  actionReason = "";

  async submit(status: string) {
    await this.$feathers.service("tableSessionActions/update").create({
      action: this.pendingAction._id,
      update: {
        status,
        actionReason: this.actionReason,
        actionStaff: this.$shop.staffId,
      },
    });
    this.modalResult(true);
  }
}
