
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem, TableSessionRef } from "~/plugins/table";
import Dialog from "~/mixins/DialogTS";
import { TableSession, SessionMoveAction } from "~/plugins/table/session";
import _ from "lodash";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { getID } from "@feathers-client";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class extends mixins(Dialog) {
  @Prop()
  session: TableSession;

  @Prop()
  initAction: SessionMoveAction;

  @Prop()
  initOperation: "move" | "split" | "merge";

  @Prop()
  mergeSource: {
    ref: TableSessionRef;
    x?: number;
    y?: number;
  };

  @Prop()
  initMergeTarget: TableItem;

  mergeView: TableView = null;
  mergeTarget: TableItem = null;

  operation: "move" | "split" | "merge" = "move";

  action: SessionMoveAction = null;
  noPrint = false;

  pendingSessions: TableSession[] = [];
  tempMergeSession: TableSessionRef = null;

  salesDate = "";

  get mergeViewId() {
    return this.mergeView?._id;
  }

  set mergeViewId(v) {
    this.mergeView = this.$tableManager.viewDict[v];
  }

  get mergeTargetId() {
    return this.mergeTarget?._id;
  }

  set mergeTargetId(v) {
    this.mergeTarget = this.$tableManager.viewItemDict[v];
  }

  get mergeAvailableSession() {
    return this.mergeTarget.sessions.filter(it => it.session.status !== "done");
  }

  get views() {
    return this.$tableManager.views
      .filter(it => !it.testing)
      .map(view => ({
        _id: view._id,
        name: view.name,
      }));
  }

  get viewItems() {
    return (this.mergeView?.sortedViewItems || []).map(item => ({
      _id: item._id,
      name: item.name,
    }));
  }

  async setToMerge(item?: TableSessionRef) {
    this.clearPending();
    if (item) {
      this.tempMergeSession = item;
    } else {
      item = this.tempMergeSession;
      // this.mergeSource.ref.session.tables = [{ ...this.mergeSource.ref.session.tables[0] }];
      await this.mergeSource.ref.session.atomic({
        tables: [{ ...this.mergeSource.ref.session.tables[0] }],
      });
      // await Vue.nextTick();
    }
    this.action = {
      actions: [],
      orders: [],
      newSessions: [],
    };

    this.action.actions.push({
      from: this.mergeSource,
    });

    this.action.actions.push({
      from: {
        ref: item,
      },
      to: {
        ref: item,
        capacity: item.capacity + this.mergeSource.ref.capacity,
      },
    });

    this.operation = "merge";
    this.updateActions();
  }

  async setToSplit() {
    this.clearPending();
    this.action = {
      actions: [],
      orders: [],
      newSessions: [],
    };

    const halfCap = (this.mergeSource.ref.capacity / 2) | 0;
    const rhalfCap = this.mergeSource.ref.capacity - halfCap;

    const newSession = this.mergeSource.ref.session.view.addSession({
      startTime: this.mergeSource.ref.session.item.startTime,
      section: this.mergeSource.ref.session.item.section,
      tables: [],
    });
    this.pendingSessions.push(newSession);
    this.action.newSessions.push(newSession);

    this.action.actions.push({
      from: this.mergeSource,
      to: {
        ref: this.mergeSource.ref,
        capacity: halfCap,
      },
    });

    this.action.actions.push({
      to: {
        ref: {
          session: newSession,
          table: this.mergeTarget,
          split: this.mergeTarget.nextSessionSplit,
        },
        capacity: rhalfCap,
      },
    });

    this.operation = "split";
    this.updateActions();
  }

  beforeMount() {
    this.mergeTarget = this.initMergeTarget;
    this.mergeView = this.initMergeTarget?.view;
    this.revertMerge();
    if (this.initOperation === "split") {
      this.setToSplit();
    }
  }

  updateMergeTable() {
    this.mergeTarget = this.mergeView?.sortedViewItems?.[0] || null;
    this.revertMerge();
    if (this.initOperation === "split") {
      this.setToSplit();
    }
    this.updateMergeTarget();
  }

  updateMergeTarget() {
    let targetRef: TableSessionRef = null;
    for (let item of this.action.actions) {
      if (item.to) {
        if (
          this.action.actions.length !== 1 &&
          this.mergeSource &&
          item.to.ref.table === this.mergeSource.ref.table &&
          item.to.ref.split === this.mergeSource.ref.split
        ) {
          continue;
        }
        targetRef = item.to.ref = {
          session: item.to.ref.session,
          table: this.mergeTarget,
          split: this.mergeTarget.nextSessionSplit,
        };
      }
    }
    if (this.mergeSource) {
      for (let item of this.action.orders) {
        if (
          this.action.actions.length !== 1 &&
          item.target.table === this.mergeSource.ref.table &&
          item.target.split === this.mergeSource.ref.split
        ) {
          continue;
        }
        item.target = targetRef;
      }
    }
  }

  get canSwitchView() {
    return (this.action.actions?.[0]?.from?.ref?.session?.tables?.length ?? 0) <= 1;
  }

  revertMerge() {
    this.clearPending();
    this.action = {
      actions: [],
      orders: [],
      newSessions: [],
    };
    if (this.initAction) {
      this.action.actions.push(...(this.initAction.actions || []));
      this.action.newSessions.push(...(this.initAction.newSessions || []));
    }
    this.operation = "move";
    this.updateActions();
  }

  updateActions() {
    const lines = _.flatMap(
      this.action.actions.map(action => {
        if (action.from) {
          const ref = action.from.ref;
          // TODO: chair id
          return ref.session.products
            .filter(it => `${it.table}` === ref.table.id && it.tableSplit === ref.split)
            .map(it => ({
              session: ref.session,
              line: it,
            }));
        }
        return [];
      }),
    );
    const firstTarget = this.action.actions.find(it => !!it.to?.ref)?.to?.ref;
    this.action.orders.push(
      ...lines.map(line => ({
        line: line.line,
        sourceSession: line.session,
        target: firstTarget,
        // TODO: chair id
        chairId: null,
      })),
    );
  }

  get tos() {
    return this.action.actions.filter(it => !!it.to?.ref).map(it => it.to.ref);
  }

  get tosList() {
    return this.tos.map(it => ({
      _id: `${it.session.id}-${it.split}`,
      name: it.session.getSplitName(it),
    }));
  }

  get tosDict() {
    return Object.fromEntries(this.tos.map(it => [`${it.session.id}-${it.split}`, it]));
  }

  setTarget(order: SessionMoveAction["orders"][number], target: string) {
    const ref = this.tosDict[target];
    order.target = ref || null;
    const dict = Object.fromEntries(this.action.orders.map(it => [it.line.id, it.line]));

    function getRoot(id: string) {
      let line = dict[id];
      while (line.fromProduct) {
        line = dict[line.fromProduct];
      }
      return line.id;
    }

    const curRoot = getRoot(order.line.id);
    for (let item of this.action.orders) {
      const root = getRoot(item.line.id);
      if (root === curRoot) {
        item.target = order.target;
      }
    }
  }

  get sessions() {
    return Object.values(
      _.groupBy(
        _.flatMap(
          this.action.actions.map(it => {
            if (it.to && it.from) {
              return it.to.ref.session.id === it.from.ref.session.id
                ? [{ id: it.to.ref.session, it, table: it.from.ref.table, split: it.from.ref.split }]
                : [
                    { id: it.from.ref.session, it, table: it.from.ref.table, split: it.from.ref.split },
                    { id: it.to.ref.session, it, table: it.to.ref.table, split: it.to.ref.split },
                  ];
            } else if (it.to) {
              return [{ id: it.to.ref.session, it, table: it.to.ref.table, split: it.to.ref.split }];
            } else if (it.from) {
              return [{ id: it.from.ref.session, it, table: it.from.ref.table, split: it.from.ref.split }];
            } else return [];
          }),
        ),
        it => it.id.id,
      ),
    ).map(it => ({
      session: it[0].id,
      actions: it.map(it => it.it),
      keep: it[0].id.tableRefs.filter(table => !it.find(jt => jt.table === table.table && jt.split === table.split)),
    }));
  }

  get allowMerge() {
    return this.mergeTarget && !!this.mergeTarget.sessions.length && !!this.mergeSource;
  }

  get allowSplit() {
    return this.mergeSource && !!this.mergeTarget;
  }

  beforeDestroy() {
    if (!this.success) {
      for (let session of this.action.newSessions) {
        if (!this.pendingSessions.includes(session)) {
          this.pendingSessions.push(session);
        }
      }
      this.clearPending();
    }
  }

  loading = false;
  success = false;

  async confirmMove() {
    if (this.loading) return;
    this.loading = true;
    const staff = await this.$shop.checkPermission([
      `tableManage/tableSession${
        this.operation === "merge" ? "CombineOrder" : this.operation === "move" ? "MoveOrder" : "SplitOrder"
      }`,
    ]);

    if (staff === false) return;
    this.loading = true;
    if (this.operation === "merge") {
      await this.setToMerge();
    }
    try {
      for (let item of this.action.actions) {
        if (item.from) {
          item.from.ref.table.deselect();
        }
        if (item.from && item.from.x !== undefined && item.to) {
          item.to.ref.session.$once("addRef:" + item.to.ref.table.id, (ref: TableSessionRef) => {
            ref._x = item.from.x;
            ref._y = item.from.y;
          });
        }
        if (item.to?.ref) {
          Vue.set(item.to.ref, "name", item.to.ref.table.nextSessionName);
        }
      }

      // const tables = [
      //     ...session.tables.filter(it => it.item !== this.item.id),
      //     {
      //         item: dragTo.id,
      //         capacity: session.capacity,
      //         split: dragTo.nextSessionSplit,
      //     }
      // ];
      const sessions: any[] = [];
      for (let session of this.action.newSessions) {
        sessions.push({
          ...(await session.presave()),
          tempId: session.tempId,
        });
      }

      const createObject = {
        actions: this.action.actions.map(it => ({
          from: it.from
            ? {
                ref: {
                  session: it.from.ref.session.id,
                  table: it.from.ref.table.id,
                  splitId: it.from.ref.split,
                },
              }
            : null,
          to: it.to
            ? {
                ref: {
                  session: it.to.ref.session.id,
                  table: it.to.ref.table.id,
                  splitId: it.to.ref.split,
                },
                capacity: it.to.capacity,
              }
            : null,
        })),

        orders: this.action.orders.map(it => ({
          id: it.line.id,
          ref: {
            session: it.target.session.id,
            table: it.target.table.id,
            splitId: it.target.split,
            chairId: it.chairId,
          },
        })),

        newSessions: sessions,
        noPrint: this.noPrint,
        staff: this.$shop.staffId,
      };

      const moved = await this.$feathers.service("tableSessions/move").create(createObject);

      let changedSessions: TableSession[] = [];
      for (let sesisonData of moved) {
        let newSession = this.$tableManager.addSession(sesisonData);
        if (newSession) {
          changedSessions.push(newSession);
        }
      }

      let tableA = "";
      let tableB = "";
      if (this.operation === "move") {
        tableA = (await this.$feathers.service("tableViewItems").get(createObject.actions?.[0].from.ref.table)).name;
        tableB = (await this.$feathers.service("tableViewItems").get(createObject.actions?.[0].to.ref.table)).name;
      }

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: staff?._id || this.$shop.staffId,
        type: `tableManage/tableSession${
          this.operation === "merge" ? "CombineOrder" : this.operation === "move" ? "MoveOrder" : "SplitOrder"
        }`,
        detail: { actions: createObject, result: moved, tableA, tableB },
      });

      for (let session of this.action.newSessions) {
        await session.postsave();
        await session.handleSessionCreate();
      }

      for (let sessionData of changedSessions) {
        await sessionData.updateCachedInfo();
      }

      Vue.nextTick(() => {
        for (let action of this.action.actions) {
          if (action.to) {
            if (this.operation === "split" && this.mergeSource && action.to.ref.table === this.mergeSource.ref.table)
              continue;
            action.to.ref.table.select();
            action.to.ref.session.selected = true;
            const newRef = action.to.ref.session.tableRefs.find(
              it => it.table === action.to.ref.table && it.split === action.to.ref.split,
            );
            action.to.ref.session.selectedRef = newRef;
          }
        }
      });
      this.success = true;
      this.modalResult(this.operation);
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
      this.modalResult(false);
    }
    this.loading = false;
  }

  clearPending() {
    if (this.pendingSessions.length) {
      for (let session of this.pendingSessions) {
        session.$destroy();
      }
      this.pendingSessions = [];
    }
  }
}
