
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
  value = null;

  onClick(it) {
    if (this.value === it.value) this.value = null;
    else this.value = it.value;
  }

  get restaurantTypes() {
    return [
      {
        name: "中或西餐廳",
        icon: require("~/assets/images/table/Restaurant.png"),
        color: "#E79536",
        value: "0",
      },
      {
        name: "日式餐廳",
        icon: require("~/assets/images/table/Sushi.png"),
        color: "#904DA5",
        value: "1",
      },
      {
        name: "咖啡店",
        icon: require("~/assets/images/table/Coffee.png"),
        color: "#D48E71",
        value: "2",
      },
      {
        name: "酒吧",
        icon: require("~/assets/images/table/Beer.png"),
        color: "#C65259",
        value: "3",
      },
    ];
  }
}
