
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import PresetDatePicker from "~/components/PresetDatePicker.vue";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import { FindType, checkID } from "@feathers-client";

@Component({
  components: {
    EditorObjectPickerList,
    PresetDatePicker,
    EditorDatePicker,
  },
})
export default class ResendEmailDialog extends mixins(Dialog) {
  @Prop()
  title!: string;

  @Prop()
  desc: string;

  @Prop({ default: "text-light-text-secondary" })
  descClass: string;

  @Prop({ default: "!bg-light-action-primary-default !text-white" })
  confirmBtnClass: string;

  @Prop()
  emailSettingId: FindType<"reportSendingSettings">["_id"];

  @Prop()
  shops: {
    title: string;
    value: string;
  }[];

  loading = false;

  // shop: (typeof this.filterShops)[number] = null;
  shop: string = null;

  from: Date = null;
  to: Date = null;

  shopSessions: FindType<"shopSessions">[] = null;
  shopSession: FindType<"shopSessions"> = null;

  emailSetting: FindType<"reportSendingSettings"> = null;

  get mappedSessions() {
    return (this.shopSessions ?? []).map(el => ({
      title: `${this.getShopName(el)}: ${this.getTimeRange(el)}`,
      value: el,
    }));
  }

  get filterShops() {
    return this.shops.filter(
      el => this.emailSetting?.shops.includes(el.value as any) || this.emailSetting?.applyToAllShop,
    );
  }

  async querySession() {
    this.shopSessions = await this.$feathers.service("shopSessions").find({
      query: {
        shop: this.shop,
        active: false,
        $or: [
          {
            salesDate: {
              $gte: this.$moment(this.from).format("YYYY-MM-DD"),
              $lte: this.$moment(this.to).format("YYYY-MM-DD"),
            },
          },
          {
            startTime: {
              $gte: this.from,
              $lte: this.to,
            },
          },
        ],
        $sort: { startTime: -1 },
        $paginate: false,
      },
      paginate: false,
    });
  }

  async send() {
    if (!this.shopSession) return;
    if (this.loading) return;
    this.loading = true;
    try {
      await this.$feathers.service("reportEmail/report").create({
        shop: this.shopSession?.shop as unknown as string,
        shopSessionId: this.shopSession?._id as unknown as string,
        summarizedBy: this.emailSetting?.summarizedBy,
        startDate: this.shopSession?.startTime,
        endDate: this.shopSession?.endTime,
        ...(this.emailSetting?.summarizedBy === "salesDay"
          ? {
              salesDate: this.shopSession?.salesDate,
            }
          : {}),
      });
      this.$store.commit("SET_SUCCESS", this.$t("basic.sent"));
    } catch (error) {
      this.$store.commit("SET_ERROR", error.message);
    } finally {
      this.loading = false;
    }
  }

  getShopName(shopSession: typeof this.shopSession) {
    return (this.filterShops ?? []).find(it => checkID(it.value, shopSession?.shop))?.title ?? "-";
  }

  getTimeRange(shopSession: typeof this.shopSession) {
    if (shopSession?.startTime && shopSession?.endTime) {
      return `${this.$moment(shopSession?.startTime).format("YYYY-MM-DD HH:mm")} - ${this.$moment(
        shopSession?.endTime,
      ).format("YYYY-MM-DD HH:mm")}`;
    } else return "-";
  }

  async mounted() {
    this.loading = true;
    try {
      this.emailSetting = await this.$feathers.service("reportSendingSettings").get(this.emailSettingId);
    } catch (error) {
      this.$store.commit("SET_ERROR", error.message);
    } finally {
      this.loading = false;
    }
  }
}
