
import { Component, Vue, Prop, VModel, getID, FindType } from "@feathers-client";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class RankPicker extends Vue {
  @VModel()
  inputValue: any;

  rankGroupItem: FindType<"shopRankGroups"> = null;

  async beforeMount() {
    if (this.inputValue?.rankGroup) {
      this.rankGroupItem = await this.$feathers.service("shopRankGroups").get(this.inputValue.rankGroup);
    }
  }

  get availRanks() {
    return this.rankGroupItem?.ranks?.map?.(r => r.rank) ?? [];
  }

  get rankGroup() {
    return this.rankGroupItem ?? null;
  }
  set rankGroup(v) {
    this.rankGroupItem = v;
    this.inputValue = {
      ...this.inputValue,
      rankGroup: getID(v) || null,
      ranks: [],
    };
  }

  get ranks() {
    return this.inputValue?.ranks ?? [];
  }

  set ranks(v) {
    this.inputValue = {
      ...this.inputValue,
      ranks: v || [],
    };
  }
}
