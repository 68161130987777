let gain: GainNode;
let context: AudioContext;
let audioBuffers: Record<string, Promise<AudioBuffer>> = {};
let playingNodes: Set<AudioScheduledSourceNode> = new Set();

export function ensureContext(resume = true) {
  if (!context) {
    context = new AudioContext();
    gain = context.createGain();

    gain.connect(context.destination);
  } else if (context.state === "suspended" && resume) {
    try {
      context.resume();
    } catch (e) {}
    if (context.state === "suspended") {
      window.addEventListener("click", () => {
        context.resume();
      }, { once: true });
    }
  }
}

export type StopHandle = Promise<void> & { stop?: () => void };

function handleNodeEnd(node: AudioScheduledSourceNode): StopHandle {
  playingNodes.add(node);
  let resolver: () => void;
  const ret: StopHandle = new Promise<void>(resolve => {
    resolver = resolve;
    node.onended = () => {
      node.disconnect();
      playingNodes.delete(node);
      if (playingNodes.size === 0) {
        context.suspend();
      }
      resolve();
    };
  });

  ret.stop = () => {
    node.stop();
    node.disconnect();
    playingNodes.delete(node);
    if (playingNodes.size === 0) {
      context.suspend();
    }
    if (resolver) {
      resolver();
    }
  };

  return ret;
}

export function beep(freq = 520, duration = 200, vol = 100) {
  ensureContext();
  gain.gain.value = vol * 0.01;
  const oscillator = context.createOscillator();
  oscillator.connect(gain);
  oscillator.frequency.value = freq;
  oscillator.type = "square";
  oscillator.start(context.currentTime);
  oscillator.stop(context.currentTime + duration * 0.001);
  return handleNodeEnd(oscillator);
}

export async function loadAudioFile(audioFile: string) {
  let audioBufferTask = audioBuffers[audioFile];
  if (!audioBufferTask) {
    audioBufferTask = audioBuffers[audioFile] = (async () => {
      const response = await fetch(audioFile);
      const arrayBuffer = await response.arrayBuffer();
      ensureContext(false);
      return await context.decodeAudioData(arrayBuffer);
    })();
  }
  const audioBuffer = await audioBufferTask;
  return audioBuffer;
}

export async function playAudioFile(audioFile: string, vol = 100, loop?: number) {
  const audioBuffer = await loadAudioFile(audioFile);
  return playAudioBuffer(audioBuffer, vol, loop);
}

export function playAudioBuffer(audioBuffer: AudioBuffer, vol = 100, loop?: number) {
  ensureContext();
  const sourceNode = context.createBufferSource();
  sourceNode.connect(gain);
  sourceNode.buffer = audioBuffer;
  sourceNode.loop = !!loop;
  gain.gain.value = vol * 0.01;
  if (loop > 0) {
    sourceNode.start(0, 0, audioBuffer.duration * loop);
  } else {
    sourceNode.start();
  }
  return handleNodeEnd(sourceNode);
}
