
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import "~/plugins/payments";

@Component({})
export default class SplitBillPaid extends Vue {
  @Prop()
  session: TableSession;

  get paymentString() {
    return `${this.$t("basic.orderFinished")}: ${this.$t("tableView.splitPayment.bill")}
${this.session?.name ? this.session?.name + " - " : ""}${this.session?.namePrefix}${this.session?.nameContent} -
${this.billNum}`;
  }

  get query() {
    return {
      $populate: ["methodRef"],
    };
  }

  get billNum() {
    for (let el of this.session?.splittedPayments) {
      if (el._id === this.session?.payingSplitBill?._id) {
        return this.session?.splittedPayments.indexOf(el) + 1;
      }
    }
  }

  finishBill() {
    this.session.isPayingSplit = false;
    this.session.currentSplitPaid = false;
  }

  async refund() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToCancel"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    // this.loading = true;
    const refundedPayment = this.session.payingSplitBill.payment;
    if (!refundedPayment) {
      console.log("splitBillPaidSomethingWrong");
      return;
    }
    try {
      console.log("sth", refundedPayment);
      const payment = await this.$paymentManager.refundPayment(`${refundedPayment}`);
      await this.session?.refundSplittedPayment(payment);

      console.log("refundPayment: ", payment);
      await this.session?.reload?.();
      await this.session?.printOrder?.({
        payment: getID(payment._id),
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.session.isPayingSplit = false;
      this.session.currentSplitPaid = false;
    }
  }
}
