
import { Component, PropSync, Vue } from "@feathers-client";

@Component
export default class Default extends Vue {
  @PropSync("staffIdle")
  isIdle: Boolean;
  actionTimer: any;

  async mounted() {
    document.addEventListener("gesturestart", this.gesture);
    document.addEventListener("mousedown", this.move, true);
    document.addEventListener("mousemove", this.move, true);
    document.addEventListener("touchstart", this.move, true);
    document.addEventListener("touchmove", this.move, true);

    this.actionTimer = setInterval(this.tick, 1000);
  }

  beforeDestroy() {
    document.removeEventListener("gesturestart", this.gesture);
    document.removeEventListener("mousedown", this.move, true);
    document.removeEventListener("mousemove", this.move, true);
    document.removeEventListener("touchstart", this.move, true);
    document.removeEventListener("touchmove", this.move, true);

    if (this.actionTimer) clearInterval(this.actionTimer);
  }

  move(e) {
    if (this.isIdle) return;
    if (this.$shop.staff) this.$shop.staffLoginTime = Date.now();
  }

  gesture(e) {
    e.preventDefault();
  }

  tick() {
    if (this.$shop.shopData?.enablePermissionLock && this.$shop.staff) {
      if (Date.now() - this.$shop.staffLoginTime > this.$shop.shopData?.permissionLockTimeout * 1000) {
        this.isIdle = true;
      }
    }
  }
}
