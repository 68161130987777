export { default as AsyncPopulate } from '../../dep/feathers-client/components/AsyncPopulate.vue'
export { default as BackToTopBtn } from '../../dep/feathers-client/components/BackToTopBtn.vue'
export { default as BoxsAppContainer } from '../../dep/feathers-client/components/BoxsAppContainer.vue'
export { default as BoxsProfileBtn } from '../../dep/feathers-client/components/BoxsProfileBtn.vue'
export { default as DialogHost } from '../../dep/feathers-client/components/DialogHost.vue'
export { default as DropDownMenu } from '../../dep/feathers-client/components/DropDownMenu.vue'
export { default as FullscreenHost } from '../../dep/feathers-client/components/FullscreenHost.vue'
export { default as HCard } from '../../dep/feathers-client/components/HCard.vue'
export { default as IdleDialog } from '../../dep/feathers-client/components/IdleDialog.vue'
export { default as ItemPicker } from '../../dep/feathers-client/components/ItemPicker.vue'
export { default as ItemPickerNew } from '../../dep/feathers-client/components/ItemPickerNew.vue'
export { default as LazyRender } from '../../dep/feathers-client/components/LazyRender.vue'
export { default as ListTree } from '../../dep/feathers-client/components/ListTree.vue'
export { default as MenuList } from '../../dep/feathers-client/components/MenuList.vue'
export { default as MenuListButton } from '../../dep/feathers-client/components/MenuListButton.vue'
export { default as MenuListCheckbox } from '../../dep/feathers-client/components/MenuListCheckbox.vue'
export { default as MenuListInput } from '../../dep/feathers-client/components/MenuListInput.vue'
export { default as MenuListItem } from '../../dep/feathers-client/components/MenuListItem.vue'
export { default as MenuListNum } from '../../dep/feathers-client/components/MenuListNum.vue'
export { default as MenuListNumOnClick } from '../../dep/feathers-client/components/MenuListNumOnClick.vue'
export { default as MenuListPicker } from '../../dep/feathers-client/components/MenuListPicker.vue'
export { default as MenuListPickerDropdown } from '../../dep/feathers-client/components/MenuListPickerDropdown.vue'
export { default as MenuListPickerItem } from '../../dep/feathers-client/components/MenuListPickerItem.vue'
export { default as MenuListSwitch } from '../../dep/feathers-client/components/MenuListSwitch.vue'
export { default as MultiLangText } from '../../dep/feathers-client/components/MultiLangText.vue'
export { default as NestedRouter } from '../../dep/feathers-client/components/NestedRouter.vue'
export { default as NumPad } from '../../dep/feathers-client/components/NumPad.vue'
export { default as NumPadNew } from '../../dep/feathers-client/components/NumPadNew.vue'
export { default as NumPadSimply } from '../../dep/feathers-client/components/NumPadSimply.vue'
export { default as ResizeSensor } from '../../dep/feathers-client/components/ResizeSensor.vue'
export { default as SlotRender } from '../../dep/feathers-client/components/SlotRender.vue'
export { default as StaticRender } from '../../dep/feathers-client/components/StaticRender.vue'
export { default as StatusHost } from '../../dep/feathers-client/components/StatusHost.vue'
export { default as SwipeAction } from '../../dep/feathers-client/components/SwipeAction.vue'
export { default as TabPicker } from '../../dep/feathers-client/components/TabPicker.vue'
export { default as TeleportMenu } from '../../dep/feathers-client/components/TeleportMenu.vue'
export { default as Highlighter } from '../../dep/feathers-client/components/highlighter.vue'
export { default as BoxsNavBarItem } from '../../dep/feathers-client/components/BoxsNavBar/Item.vue'
export { default as BoxsNavBar } from '../../dep/feathers-client/components/BoxsNavBar/index.vue'
export { default as BoxsNavBarNewNavItem } from '../../dep/feathers-client/components/BoxsNavBar/newNavItem.vue'
export { default as AddressPicker } from '../../components/AddressPicker.vue'
export { default as AppQrCode } from '../../components/AppQrCode.vue'
export { default as BBtn } from '../../components/BBtn.vue'
export { default as CashBoxInner } from '../../components/CashBoxInner.vue'
export { default as CloseShop } from '../../components/CloseShop.vue'
export { default as ColorPicker } from '../../components/ColorPicker.vue'
export { default as CurrencyPicker } from '../../components/CurrencyPicker.vue'
export { default as CustomPickerEditorMenu } from '../../components/CustomPickerEditorMenu.vue'
export { default as CustomTimeRangePicker } from '../../components/CustomTimeRangePicker.vue'
export { default as DatePicker } from '../../components/DatePicker.vue'
export { default as DeviceLimitDialog } from '../../components/DeviceLimitDialog.vue'
export { default as EditableLabel } from '../../components/EditableLabel.vue'
export { default as FilePicker } from '../../components/FilePicker.vue'
export { default as FitText } from '../../components/FitText.vue'
export { default as IdleChecker } from '../../components/IdleChecker.vue'
export { default as ImagePicker } from '../../components/ImagePicker.vue'
export { default as ItemPickerList } from '../../components/ItemPickerList.vue'
export { default as ItemPickerListBase } from '../../components/ItemPickerListBase.vue'
export { default as JwkEditor } from '../../components/JwkEditor.vue'
export { default as KdsColorPicker } from '../../components/KdsColorPicker.vue'
export { default as LanguagePicker } from '../../components/LanguagePicker.vue'
export { default as ListPicker } from '../../components/ListPicker.vue'
export { default as ListPickerNew } from '../../components/ListPickerNew.vue'
export { default as LocalPrinterPicker } from '../../components/LocalPrinterPicker.vue'
export { default as LoginMenu } from '../../components/LoginMenu.vue'
export { default as MediaLibrary } from '../../components/MediaLibrary.vue'
export { default as NetworkConnectDialog } from '../../components/NetworkConnectDialog.vue'
export { default as ObjectPicker } from '../../components/ObjectPicker.vue'
export { default as OfflineSetupDialog } from '../../components/OfflineSetupDialog.vue'
export { default as OpeningClosingInner } from '../../components/OpeningClosingInner.vue'
export { default as Overview } from '../../components/Overview.vue'
export { default as PaymentButton } from '../../components/PaymentButton.vue'
export { default as Percentage } from '../../components/Percentage.vue'
export { default as PointManager } from '../../components/PointManager.vue'
export { default as PopularProduct } from '../../components/PopularProduct.vue'
export { default as PopularProductPercentage } from '../../components/PopularProductPercentage.vue'
export { default as PopupDialogList } from '../../components/PopupDialogList.vue'
export { default as PopupDialogPicker } from '../../components/PopupDialogPicker.vue'
export { default as PosDeviceControl } from '../../components/PosDeviceControl.vue'
export { default as PresetDatePicker } from '../../components/PresetDatePicker.vue'
export { default as PriceRow } from '../../components/PriceRow.vue'
export { default as PrintJobOptions } from '../../components/PrintJobOptions.vue'
export { default as PrinterStatus } from '../../components/PrinterStatus.vue'
export { default as ProductOptionValue } from '../../components/ProductOptionValue.vue'
export { default as RankGroupItemEditor } from '../../components/RankGroupItemEditor.vue'
export { default as RankPicker } from '../../components/RankPicker.vue'
export { default as ShopCategoryPicker } from '../../components/ShopCategoryPicker.vue'
export { default as ShopQrcodeGenerator } from '../../components/ShopQrcodeGenerator.vue'
export { default as SimpleList } from '../../components/SimpleList.vue'
export { default as SpendManager } from '../../components/SpendManager.vue'
export { default as SummaryCategoryRow } from '../../components/SummaryCategoryRow.vue'
export { default as TableDatePicker } from '../../components/TableDatePicker.vue'
export { default as TelCodeSelector } from '../../components/TelCodeSelector.vue'
export { default as TeleportMenuButton } from '../../components/TeleportMenuButton.vue'
export { default as TimeListPicker } from '../../components/TimeListPicker.vue'
export { default as TimePicker } from '../../components/TimePicker.vue'
export { default as TimeRangePicker } from '../../components/TimeRangePicker.vue'
export { default as TranslateBox } from '../../components/TranslateBox.vue'
export { default as TvStatus } from '../../components/TvStatus.vue'
export { default as UserPointCell } from '../../components/UserPointCell.vue'
export { default as UserQrCode } from '../../components/UserQrCode.vue'
export { default as BoxsMobilePageView } from '../../components/boxs/MobilePageView.vue'
export { default as BoxsNavTitle } from '../../components/boxs/NavTitle.vue'
export { default as BoxsSingleCurrencyEditor } from '../../components/boxs/SingleCurrencyEditor.vue'
export { default as CenterMenu } from '../../components/center/Menu.vue'
export { default as CenterMenuItem } from '../../components/center/MenuItem.vue'
export { default as ChartsBarChart } from '../../components/charts/BarChart.vue'
export { default as ChartsDoughnutChart } from '../../components/charts/DoughnutChart.vue'
export { default as ChartsFrequencyChart } from '../../components/charts/FrequencyChart.vue'
export { default as ChartsLineChart } from '../../components/charts/LineChart.vue'
export { default as ChartsNewLineChart } from '../../components/charts/NewLineChart.vue'
export { default as ChartsPie } from '../../components/charts/Pie.vue'
export { default as ChartsReactive } from '../../components/charts/reactive.js'
export { default as ChartsSmallBarChart } from '../../components/charts/smallBarChart.vue'
export { default as ChartsStackBarChart } from '../../components/charts/stackBarChart.vue'
export { default as DialogsAddPointDialog } from '../../components/dialogs/AddPointDialog.vue'
export { default as DialogsAdjustSpendDialog } from '../../components/dialogs/AdjustSpendDialog.vue'
export { default as DialogsConfirmDeleteDialog } from '../../components/dialogs/ConfirmDeleteDialog.vue'
export { default as DialogsConfirmDialog } from '../../components/dialogs/ConfirmDialog.vue'
export { default as DialogsConfirmDialog2 } from '../../components/dialogs/ConfirmDialog2.vue'
export { default as DialogsDelEmailDialog } from '../../components/dialogs/DelEmailDialog.vue'
export { default as DialogsEditShopPrinterDialogNew } from '../../components/dialogs/EditShopPrinterDialogNew.vue'
export { default as DialogsEmailDialog } from '../../components/dialogs/EmailDialog.vue'
export { default as DialogsHCard } from '../../components/dialogs/HCard.vue'
export { default as DialogsImageDialog } from '../../components/dialogs/ImageDialog.vue'
export { default as DialogsIngredientInfo } from '../../components/dialogs/IngredientInfo.vue'
export { default as DialogsPaymentMethodTemplate } from '../../components/dialogs/PaymentMethodTemplate.vue'
export { default as DialogsProductCartItem } from '../../components/dialogs/ProductCartItem.vue'
export { default as DialogsProductInfo } from '../../components/dialogs/ProductInfo.vue'
export { default as DialogsProductStockNum } from '../../components/dialogs/ProductStockNum.vue'
export { default as DialogsResendEmail } from '../../components/dialogs/ResendEmail.vue'
export { default as DialogsShopProductView } from '../../components/dialogs/ShopProductView.vue'
export { default as DialogsStaffCodeDialog } from '../../components/dialogs/StaffCodeDialog.vue'
export { default as DialogsStaffPasswordDialog } from '../../components/dialogs/StaffPasswordDialog.vue'
export { default as DialogsStockWarnDialog } from '../../components/dialogs/StockWarnDialog.vue'
export { default as DialogsSuccessDialog } from '../../components/dialogs/SuccessDialog.vue'
export { default as DialogsKioskPasswordDialog } from '../../components/dialogs/kioskPasswordDialog.vue'
export { default as IngredientCategoriesFilter } from '../../components/ingredient/CategoriesFilter.vue'
export { default as IngredientCostForm } from '../../components/ingredient/CostForm.vue'
export { default as IngredientDatesSelector } from '../../components/ingredient/DatesSelector.vue'
export { default as IngredientDayRecords } from '../../components/ingredient/DayRecords.vue'
export { default as IngredientProductSummaryRow } from '../../components/ingredient/ProductSummaryRow.vue'
export { default as IngredientSemiProduct } from '../../components/ingredient/SemiProduct.vue'
export { default as IngredientSemiProductSummaryRow } from '../../components/ingredient/SemiProductSummaryRow.vue'
export { default as IngredientSummaryRow } from '../../components/ingredient/SummaryRow.vue'
export { default as OdsBanners } from '../../components/ods/banners.vue'
export { default as OdsBaseShop } from '../../components/ods/baseShop.ts'
export { default as OdsCarousel } from '../../components/ods/carousel.vue'
export { default as OdsCarouselItem } from '../../components/ods/carouselItem.vue'
export { default as OdsMultiShop } from '../../components/ods/multiShop.vue'
export { default as OdsOrderItem } from '../../components/ods/orderItem.vue'
export { default as OdsSetup } from '../../components/ods/setup.vue'
export { default as OdsShopRow } from '../../components/ods/shopRow.vue'
export { default as OdsSingleShop } from '../../components/ods/singleShop.vue'
export { default as OdsSingleShopRow } from '../../components/ods/singleShopRow.vue'
export { default as PaymentMethodOptions } from '../../components/payment/methodOptions.vue'
export { default as PaymentMethodSecrets } from '../../components/payment/methodSecrets.vue'
export { default as PaymentSubMethodInfos } from '../../components/payment/subMethodInfos.vue'
export { default as PrinterEditorBarcode } from '../../components/printerEditor/barcode.vue'
export { default as PrinterEditorBlock } from '../../components/printerEditor/block.vue'
export { default as PrinterEditorCol } from '../../components/printerEditor/col.vue'
export { default as PrinterEditorContainer } from '../../components/printerEditor/container.vue'
export { default as PrinterEditorCustomComponent } from '../../components/printerEditor/customComponent.vue'
export { default as PrinterEditorCut } from '../../components/printerEditor/cut.vue'
export { default as PrinterEditorFeed } from '../../components/printerEditor/feed.vue'
export { default as PrinterEditorFill } from '../../components/printerEditor/fill.vue'
export { default as PrinterEditorGraph } from '../../components/printerEditor/graph.vue'
export { default as PrinterEditorImage } from '../../components/printerEditor/image.vue'
export { default as PrinterEditorInterpolation } from '../../components/printerEditor/interpolation.vue'
export { default as PrinterEditorLoop } from '../../components/printerEditor/loop.vue'
export { default as PrinterEditorPage } from '../../components/printerEditor/page.vue'
export { default as PrinterEditorQr } from '../../components/printerEditor/qr.vue'
export { default as PrinterEditorRect } from '../../components/printerEditor/rect.vue'
export { default as PrinterEditorRoot } from '../../components/printerEditor/root.vue'
export { default as PrinterEditorRow } from '../../components/printerEditor/row.vue'
export { default as PrinterEditorScript } from '../../components/printerEditor/script.vue'
export { default as PrinterEditorText } from '../../components/printerEditor/text.vue'
export { default as QueueingCallingOutSetting } from '../../components/queueing/CallingOutSetting.vue'
export { default as QueueingConfirmReset } from '../../components/queueing/ConfirmReset.vue'
export { default as QueueingInputGroup } from '../../components/queueing/InputGroup.vue'
export { default as QueueingInputPeopleNum } from '../../components/queueing/InputPeopleNum.vue'
export { default as QueueingPosSideBarTab } from '../../components/queueing/PosSideBarTab.vue'
export { default as QueueingPosSidebar } from '../../components/queueing/PosSidebar.vue'
export { default as QueueingPosView } from '../../components/queueing/PosView.vue'
export { default as QueueingCard } from '../../components/queueing/QueueingCard.vue'
export { default as QueueingCardClinic } from '../../components/queueing/QueueingCardClinic.vue'
export { default as QueueingWaitingListCard } from '../../components/queueing/WaitingListCard.vue'
export { default as QueueingWaitingListPanel } from '../../components/queueing/WaitingListPanel.vue'
export { default as QueueingMobilePanel } from '../../components/queueing/mobilePanel.vue'
export { default as TableAlertBadge } from '../../components/table/AlertBadge.vue'
export { default as TableCanvasSvg } from '../../components/table/CanvasSvg.vue'
export { default as TableChairSvg } from '../../components/table/ChairSvg.vue'
export { default as TableConfigs } from '../../components/table/Configs.vue'
export { default as TableEditor } from '../../components/table/Editor.vue'
export { default as TableNumPadButton } from '../../components/table/NumPadButton.vue'
export { default as TableNumStepper } from '../../components/table/NumStepper.vue'
export { default as TablePendingOrderList } from '../../components/table/PendingOrderList.vue'
export { default as TableReservationPlate } from '../../components/table/ReservationPlate.vue'
export { default as TableRestaurantType } from '../../components/table/RestaurantType.vue'
export { default as TableScanner } from '../../components/table/Scanner.vue'
export { default as TableSessionMoveDialog } from '../../components/table/SessionMoveDialog.vue'
export { default as TableList } from '../../components/table/TableList.vue'
export { default as TableOrChair } from '../../components/table/TableOrChair.vue'
export { default as TableViewSettingDialog } from '../../components/table/ViewSettingDialog.vue'
export { default as TableOrderList } from '../../components/table/orderList.vue'
export { default as TablePendingAction } from '../../components/table/pendingAction.vue'
export { default as TablePendingActionDialog } from '../../components/table/pendingActionDialog.vue'
export { default as TableSummaryBase } from '../../components/table/summaryBase.vue'
export { default as TakeawayCalendarFilter } from '../../components/takeaway/CalendarFilter.vue'
export { default as TakeawayFilter } from '../../components/takeaway/Filter.vue'
export { default as TakeawayList } from '../../components/takeaway/List.vue'
export { default as TakeawayListItem } from '../../components/takeaway/ListItem.vue'
export { default as TakeawayTable } from '../../components/takeaway/Table.vue'
export { default as DevVerifyPaymentDateGroup } from '../../components/dev/verify/PaymentDateGroup.vue'
export { default as DevVerifySession } from '../../components/dev/verify/Session.vue'
export { default as DevVerifyStatusGroup } from '../../components/dev/verify/StatusGroup.vue'
export { default as DevVerifyTypeGroup } from '../../components/dev/verify/TypeGroup.vue'
export { default as PrinterEditorEditBase } from '../../components/printerEditor/edit/base.ts'
export { default as PrinterEditorEditFeed } from '../../components/printerEditor/edit/feed.vue'
export { default as PrinterEditorEditFill } from '../../components/printerEditor/edit/fill.vue'
export { default as PrinterEditorEditPicker } from '../../components/printerEditor/edit/picker.vue'
export { default as PrinterEditorEditPlacement } from '../../components/printerEditor/edit/placement.vue'
export { default as PrinterEditorEditPosition } from '../../components/printerEditor/edit/position.vue'
export { default as PrinterEditorEditStyle } from '../../components/printerEditor/edit/style.vue'
export { default as PrinterEditorEditVariable } from '../../components/printerEditor/edit/variable.vue'
export { default as TableAccount } from '../../components/table/account/index.vue'
export { default as TableBubbleConfig } from '../../components/table/bubble/config.vue'
export { default as TableBubbleCreateSession } from '../../components/table/bubble/createSession.vue'
export { default as TableBubble } from '../../components/table/bubble/index.vue'
export { default as TableBubbleMenu } from '../../components/table/bubble/menu.vue'
export { default as TableBubbleMenuRow } from '../../components/table/bubble/menuRow.vue'
export { default as TableBubbleSession } from '../../components/table/bubble/session.vue'
export { default as TableItemAvailableContainer } from '../../components/table/item/availableContainer.vue'
export { default as TableItem } from '../../components/table/item/index.vue'
export { default as TableItemResize } from '../../components/table/item/resize.vue'
export { default as TableItemSession } from '../../components/table/item/session.vue'
export { default as TableItemSessionAssignTicket } from '../../components/table/item/sessionAssignTicket.vue'
export { default as TableItemSessionContainer } from '../../components/table/item/sessionContainer.vue'
export { default as TableItemSessionCreate } from '../../components/table/item/sessionCreate.vue'
export { default as TableItemSessionExpand } from '../../components/table/item/sessionExpand.vue'
export { default as TableItemSessionMerge } from '../../components/table/item/sessionMerge.vue'
export { default as TableItemSessionMulti } from '../../components/table/item/sessionMulti.vue'
export { default as TableItemTakeAway } from '../../components/table/item/takeAway.vue'
export { default as TableKdsDisplaySetting } from '../../components/table/kds/displaySetting.vue'
export { default as TableKdsHoldDialog } from '../../components/table/kds/holdDialog.vue'
export { default as TableKdsHoldTimer } from '../../components/table/kds/holdTimer.vue'
export { default as TableKdsOrderGroup } from '../../components/table/kds/orderGroup.vue'
export { default as TableKdsProductBlock } from '../../components/table/kds/productBlock.vue'
export { default as TableKdsProductChunk } from '../../components/table/kds/productChunk.vue'
export { default as TableKdsProductStockNum } from '../../components/table/kds/productStockNum.vue'
export { default as TableKdsStockCancelDialog } from '../../components/table/kds/stockCancelDialog.vue'
export { default as TableKdsStockGroup } from '../../components/table/kds/stockGroup.vue'
export { default as TableKdsStockPanel } from '../../components/table/kds/stockPanel.vue'
export { default as TableMemberSystemTopup } from '../../components/table/memberSystem/Topup.vue'
export { default as TableMemberSystemCreateMember } from '../../components/table/memberSystem/createMember.vue'
export { default as TableMemberSystemDashboard } from '../../components/table/memberSystem/dashboard.vue'
export { default as TableMemberSystemIcon } from '../../components/table/memberSystem/icon.vue'
export { default as TableMemberCard } from '../../components/table/memberSystem/memberCard.vue'
export { default as TableMemberDetail } from '../../components/table/memberSystem/memberDetail.vue'
export { default as TableMemberDetailRow } from '../../components/table/memberSystem/memberDetailRow.vue'
export { default as TableMemberHistory } from '../../components/table/memberSystem/memberHistory.vue'
export { default as TableMemberInfo } from '../../components/table/memberSystem/memberInfo.vue'
export { default as TableMemberList } from '../../components/table/memberSystem/memberList.vue'
export { default as TableMemberSystemPointAdjustPanel } from '../../components/table/memberSystem/pointAdjustPanel.vue'
export { default as TableMemberSystemPointCard } from '../../components/table/memberSystem/pointCard.vue'
export { default as TableMemberSystemPointRecord } from '../../components/table/memberSystem/pointRecord.vue'
export { default as TableMemberSystemSelectMemberList } from '../../components/table/memberSystem/selectMemberList.vue'
export { default as TableMemberSystemTransactionList } from '../../components/table/memberSystem/transactionList.vue'
export { default as TableOrderSystemAdjustAmount } from '../../components/table/orderSystem/adjustAmount.vue'
export { default as TableOrderSystemBatchSetKitchenOption } from '../../components/table/orderSystem/batchSetKitchenOption.vue'
export { default as TableOrderSystemCancelReason } from '../../components/table/orderSystem/cancelReason.vue'
export { default as TableOrderSystemCart } from '../../components/table/orderSystem/cart.vue'
export { default as TableOrderSystemCartList } from '../../components/table/orderSystem/cartList.vue'
export { default as TableOrderSystemCartPanel } from '../../components/table/orderSystem/cartPanel.vue'
export { default as TableOrderSystemCartPanelBase } from '../../components/table/orderSystem/cartPanelBase.ts'
export { default as TableOrderSystemCartPriceDetails } from '../../components/table/orderSystem/cartPriceDetails.vue'
export { default as TableOrderSystemCartPrinter } from '../../components/table/orderSystem/cartPrinter.vue'
export { default as TableOrderSystemCheckout } from '../../components/table/orderSystem/checkout.vue'
export { default as TableOrderSystemCheckoutBase } from '../../components/table/orderSystem/checkoutBase.ts'
export { default as TableOrderSystemCoupon } from '../../components/table/orderSystem/coupon.vue'
export { default as TableOrderSystemCouponTicker } from '../../components/table/orderSystem/couponTicker.vue'
export { default as TableOrderSystemDiscount } from '../../components/table/orderSystem/discount.vue'
export { default as TableOrderSystemDisplaySetting } from '../../components/table/orderSystem/displaySetting.vue'
export { default as TableOrderSystemEditCartItem } from '../../components/table/orderSystem/editCartItem.vue'
export { default as TableOrderSystemEditUser } from '../../components/table/orderSystem/editUser.vue'
export { default as TableOrderSystemFinishOrder } from '../../components/table/orderSystem/finishOrder.vue'
export { default as TableOrderSystemHeader } from '../../components/table/orderSystem/header.vue'
export { default as TableOrderSystemHeaderPayment } from '../../components/table/orderSystem/headerPayment.vue'
export { default as TableOrderSystemHeaderPrinter } from '../../components/table/orderSystem/headerPrinter.vue'
export { default as TableOrderSystemHeaderSecondScreen } from '../../components/table/orderSystem/headerSecondScreen.vue'
export { default as TableOrderSystem } from '../../components/table/orderSystem/index.vue'
export { default as TableOrderSystemInvoiceQrCode } from '../../components/table/orderSystem/invoiceQrCode.vue'
export { default as TableOrderSystemManualAdjustDialog } from '../../components/table/orderSystem/manualAdjustDialog.vue'
export { default as TableOrderSystemMerchantCart } from '../../components/table/orderSystem/merchantCart.vue'
export { default as TableOrderSystemMerchantConfirm } from '../../components/table/orderSystem/merchantConfirm.vue'
export { default as TableOrderSystemModifier } from '../../components/table/orderSystem/modifier.vue'
export { default as TableOrderSystemMore } from '../../components/table/orderSystem/more.vue'
export { default as TableOrderSystemNumPadDialog } from '../../components/table/orderSystem/numPadDialog.vue'
export { default as TableOrderInfoDialog } from '../../components/table/orderSystem/orderInfoDialog.vue'
export { default as TableOrderSystemPartialPayment } from '../../components/table/orderSystem/partialPayment.vue'
export { default as TableOrderSystemPaymentRecord } from '../../components/table/orderSystem/paymentRecord.vue'
export { default as TableOrderSystemPeople } from '../../components/table/orderSystem/people.vue'
export { default as TableOrderSystemPeopleCrm } from '../../components/table/orderSystem/peopleCrm.vue'
export { default as TableOrderSystemPicker } from '../../components/table/orderSystem/picker.vue'
export { default as TableOrderSystemProduct } from '../../components/table/orderSystem/product.vue'
export { default as TableOrderSystemProductLine } from '../../components/table/orderSystem/productLine.vue'
export { default as TableOrderSystemProductLineSide } from '../../components/table/orderSystem/productLineSide.vue'
export { default as TableOrderSystemProductLineSideFooter } from '../../components/table/orderSystem/productLineSideFooter.vue'
export { default as TableOrderSystemProductNumPad } from '../../components/table/orderSystem/productNumPad.vue'
export { default as TableOrderSystemProductOptionBase } from '../../components/table/orderSystem/productOptionBase.ts'
export { default as TableOrderSystemProductOptionItem } from '../../components/table/orderSystem/productOptionItem.vue'
export { default as TableOrderSystemProductOptionItemNew } from '../../components/table/orderSystem/productOptionItemNew.vue'
export { default as TableOrderSystemProductOptions } from '../../components/table/orderSystem/productOptions.vue'
export { default as TableOrderSystemProductOptionsFooter } from '../../components/table/orderSystem/productOptionsFooter.vue'
export { default as TableOrderSystemProductOptionsNew } from '../../components/table/orderSystem/productOptionsNew.vue'
export { default as TableOrderSystemRedeemGift } from '../../components/table/orderSystem/redeemGift.vue'
export { default as TableOrderSystemRemarks } from '../../components/table/orderSystem/remarks.vue'
export { default as TableOrderSystemSection } from '../../components/table/orderSystem/section.vue'
export { default as TableOrderSystemSplitBillPaid } from '../../components/table/orderSystem/splitBillPaid.vue'
export { default as TableOrderSystemSplitPayment } from '../../components/table/orderSystem/splitPayment.vue'
export { default as TableOrderSystemStaff } from '../../components/table/orderSystem/staff.vue'
export { default as TableOrderSystemStaffIcon } from '../../components/table/orderSystem/staffIcon.vue'
export { default as TableOrderSystemStaffPicker } from '../../components/table/orderSystem/staffPicker.vue'
export { default as TableOrderSystemTax } from '../../components/table/orderSystem/tax.vue'
export { default as TableOrderSystemTime } from '../../components/table/orderSystem/time.vue'
export { default as TableOrderSystemTips } from '../../components/table/orderSystem/tips.vue'
export { default as TableReservationAddDialog } from '../../components/table/reservation/addDialog.vue'
export { default as TableReservationDetailDialog } from '../../components/table/reservation/detailDialog.vue'
export { default as TableReservationHistoryDialog } from '../../components/table/reservation/historyDialog.vue'
export { default as TableReservation } from '../../components/table/reservation/index.vue'
export { default as TableReservationItem } from '../../components/table/reservation/item.vue'
export { default as TableReservationPickDialog } from '../../components/table/reservation/pickDialog.vue'
export { default as TableReservationPickTable } from '../../components/table/reservation/pickTable.vue'
export { default as TableSvgTakeAway } from '../../components/table/svg/takeAway.vue'
export { default as TableOrderSystemCustomProductAdd } from '../../components/table/orderSystem/customProduct/add.vue'
export { default as TableOrderSystemCustomProductAddDialog } from '../../components/table/orderSystem/customProduct/addDialog.vue'
export { default as TableOrderSystemCustomProductItem } from '../../components/table/orderSystem/customProduct/item.vue'
export { default as TableOrderSystemMobileBottomButtonBar } from '../../components/table/orderSystem/mobile/bottomButtonBar.vue'
export { default as TableOrderSystemMobileCart } from '../../components/table/orderSystem/mobile/cart.vue'
export { default as TableOrderSystemMobileCartHeader } from '../../components/table/orderSystem/mobile/cartHeader.vue'
export { default as TableOrderSystemMobileCartItem } from '../../components/table/orderSystem/mobile/cartItem.vue'
export { default as TableOrderSystemMobileCategories } from '../../components/table/orderSystem/mobile/categories.vue'
export { default as TableOrderSystemMobileCheckout } from '../../components/table/orderSystem/mobile/checkout.vue'
export { default as TableOrderSystemMobileFinishOrder } from '../../components/table/orderSystem/mobile/finishOrder.vue'
export { default as TableOrderSystemMobile } from '../../components/table/orderSystem/mobile/index.vue'
export { default as TableOrderSystemMobileBase } from '../../components/table/orderSystem/mobile/mobileBase.ts'
export { default as TableOrderType } from '../../components/table/orderSystem/mobile/orderType.vue'
export { default as TableOrderSystemMobilePicker } from '../../components/table/orderSystem/mobile/picker.vue'
export { default as TableOrderSystemMobilePickerBase } from '../../components/table/orderSystem/mobile/pickerBase.ts'
export { default as TableOrderSystemMobilePickerOption } from '../../components/table/orderSystem/mobile/pickerOption.vue'
export { default as TableOrderSystemMobileRecord } from '../../components/table/orderSystem/mobile/record.vue'
export { default as TableOrderSystemMobileRecordOption } from '../../components/table/orderSystem/mobile/recordOption.vue'
export { default as TableOrderSystemMobileSessions } from '../../components/table/orderSystem/mobile/sessions.vue'
export { default as TableOrderSystemMobileSubCategories } from '../../components/table/orderSystem/mobile/subCategories.vue'
export { default as TableOrderSystemMobileViewPicker } from '../../components/table/orderSystem/mobile/viewPicker.vue'
export { default as TableOrderSystemPaymentMethodsCash } from '../../components/table/orderSystem/paymentMethods/cash.vue'
export { default as TableOrderSystemPaymentMethodsPoint } from '../../components/table/orderSystem/paymentMethods/point.vue'
export { default as TableOrderSystemPaymentMethodsSupacity } from '../../components/table/orderSystem/paymentMethods/supacity.vue'
export { default as TableOrderSystemPaymentMethodsTwTax } from '../../components/table/orderSystem/paymentMethods/twTax.vue'
export { default as TableOrderSystemPaymentMethodsTwTaxCreditCardInstallment } from '../../components/table/orderSystem/paymentMethods/twTaxCreditCardInstallment.vue'
export { default as TableOrderSystemSessionSummary } from '../../components/table/orderSystem/session/summary.vue'
export { default as TableOrderSystemMobileCrmEarnPoint } from '../../components/table/orderSystem/mobile/crm/earnPoint.vue'
export { default as TableOrderSystemMobileCrmMerchantConfirm } from '../../components/table/orderSystem/mobile/crm/merchantConfirm.vue'
export { default as TableOrderSystemMobileCrmMerchantPortal } from '../../components/table/orderSystem/mobile/crm/merchantPortal.vue'
export { default as TableOrderSystemMobileCrmMerchantPortalDone } from '../../components/table/orderSystem/mobile/crm/merchantPortalDone.vue'
export { default as TableOrderSystemMobileCrmTopUp } from '../../components/table/orderSystem/mobile/crm/topUp.vue'
export { default as TableOrderSystemMobileCrmTransaction } from '../../components/table/orderSystem/mobile/crm/transaction.vue'
export { default as TableOrderSystemMobileCrmUseCoupon } from '../../components/table/orderSystem/mobile/crm/useCoupon.vue'
export { default as TableOrderSystemMobileCrmUseDollar } from '../../components/table/orderSystem/mobile/crm/useDollar.vue'
export { default as TableOrderSystemMobileCrmUseDollarConfirm } from '../../components/table/orderSystem/mobile/crm/useDollarConfirm.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
