
import { Vue, Component, Prop } from "@feathers-client";
import { OdsShop } from "./baseShop";

@Component
export default class ShopRow extends Vue {
  @Prop()
  shop: OdsShop;

  @Prop()
  columns: number;

  @Prop()
  placement: "horizontal" | "vertical";

  get style() {
    return {
      "--shop-color": this.shop.color || "#3B3838",
    };
  }

  get widthChars() {
    if (this.placement === "horizontal") {
      return this.columns * 3 * 0.75;
    } else {
      return 3 * 0.75;
    }
  }

  get widthOffset() {
    if (this.placement === "horizontal") {
      return -(24 * this.columns);
    } else {
      return -24;
    }
  }

  get offsetScaleY() {
    if (this.placement === "horizontal") {
      return -2;
    } else {
      return 0;
    }
  }

  get scale() {
    if (this.placement === "horizontal") {
      return 1;
    } else {
      return 1 / this.columns;
    }
  }

  get offset() {
    if (this.placement === "horizontal") {
      return -8;
    } else {
      return -8 * this.columns;
    }
  }
}
