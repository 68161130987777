
import {
  Component,
  FindPopType,
  FindPopRawType,
  Prop,
  PropSync,
  Vue,
  Watch,
  VModel,
  Ref,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import _ from "lodash";
import ListPicker from "~/components/ListPicker.vue";
import type VirtualDataList from "domore-table/VirtualDataList.vue";
import type { SearchConfig } from "@schemaEditor/plugin/defs";

@Component({
  components: {
    ListPicker,
    EditorTextField,
  },
})
export default class MenuListDialogPicker extends Vue {
  /**
   * Display fields
   */
  @Prop({ type: Array, default: () => ["name"] }) fields: (string | SearchConfig)[];

  /**
   * Searchable fields
   */
  @Prop({ type: Array, default: null }) searchFields: (string | SearchConfig)[];

  @Prop() session: TableSession;
  @Prop() path: string;
  @Prop() query: any;
  @Prop() populateValue: any;
  @Prop() enterkeyhint: string;
  @Prop() placeholder: string;
  @Prop() searchBarPlaceholder: string;
  @Prop() rowIcon: string;
  @Prop() items: any[];
  @Prop() createNameField: string | SearchConfig;
  @PropSync("inputValue") inputValueSync: string | string[];

  @Prop() textStyle: string;
  @Prop() borderStyle: string;
  @Prop() inactiveClass: string;
  @Prop() activeClass: string;
  @Prop() activeIcon: string;
  @Prop() label: string;
  @Prop() initialBtnName: string;

  @Prop() serverSearch: boolean;
  @Prop() combo: boolean;
  @Prop() multiple: boolean;
  @Prop() ordered: boolean;
  @Prop() readonly: boolean;
  @Prop() mandatory: boolean;
  @Prop() stringOnly: boolean;

  @Prop() searchable: boolean;
  @Prop() preferSingle: boolean;
  @Prop() returnObject: boolean;
  @Prop() border: boolean;
  @Prop() nowrap: boolean;
  @Prop() scanIcon: string;
  @PropSync("scanning") scanningSync: boolean;
  @Prop() isSmallMobile: boolean;

  removeOnClick = false;
  onSelected = false;
  openDialog = false;
}
