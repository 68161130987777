import { MHookContext } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";

export async function all(hook: MHookContext<AdminApplication, any, any>) {
  const device = localStorage[`cachedDevice`];
  if(device) {
    hook.result = {
      device: JSON.parse(device),
    }
  } else {
    hook.result = {
      device: {},
    };
  }
}


