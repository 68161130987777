import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

import type { TableSession, ProductLine } from "./session";
import { FindType } from "@feathers-client";
import _ from "lodash";
import { ProductType } from "../shop";

export type KdsProductBlock = { items: KdsProductItem[]; group: KdsProductGroup; idx: number };
export type KdsProductChunk = KdsProductBlock[];

@Component
export class KdsProductManager extends Vue {
  products: KdsProductGroup[] = [];
  productDict: Record<string, KdsProductGroup> = {};

  chunks: KdsProductChunk[] = [];

  created() {
    this.reset();
  }

  get orders() {
    const orderTypes = this.$shop.localOptions.kdsOrderTypes || [];
    if (!orderTypes.length) {
      return [...this.$tableManager.sessions, ...this.$tableManager.takeAways, ...this.$tableManager.dineInNoTables];
    } else {
      return [
        orderTypes.includes("dineIn") ? this.$tableManager.sessions : [],
        orderTypes.includes("takeAway") ? this.$tableManager.takeAways : [],
        orderTypes.includes("dineInNoTable") ? this.$tableManager.dineInNoTables : [],
      ].flat();
    }
  }

  @Watch("$shop.localOptions.kdsKitchen")
  reset() {
    for (let order of this.orders) {
      this.updateOrder(order);
    }
  }

  getProductGroup(product: any, create = true) {
    let cur = this.productDict[String(product)];
    if (!cur && create) {
      cur = this.productDict[String(product)] = new KdsProductGroup({
        parent: this,
        propsData: {
          product: String(product),
        },
      });
      this.products.push(cur);
    }
    return cur;
  }

  updateOrder(session: TableSession) {
    const newHash: KdsProductGroupRef = {};
    const oldHash = session.kdsProductGroup || {};
    const kitchen = this.$shop.localOptions.kdsKitchen;
    for (let line of session.products) {
      if (
        !line.kitchenPrinters?.length ||
        (kitchen && !line.kitchenPrinters.includes(kitchen as any)) ||
        line.status === "cancel" ||
        line.kdsStatus === "done" ||
        line.kdsStatus === "partialDone" ||
        !line.product
      ) {
        continue;
      }
      const hash = optionHash(line, session.type === "takeAway");
      const fullHash = `${line.product}/${hash}/${line.id}`;
      const oldValue = oldHash[fullHash];
      delete oldHash[fullHash];
      newHash[fullHash] = line.quantity;

      if (oldValue !== line.quantity) {
        const productGroup = this.getProductGroup(line.product);
        productGroup.add(hash, session._id, line);
      }
    }
    session.kdsProductGroup = newHash;

    for (let [k, v] of Object.entries(oldHash)) {
      const [product, hash, line] = k.split("/");
      const productGroup = this.getProductGroup(product, false);
      if (!productGroup) continue;
      productGroup.remove(hash, session._id, line);
    }
  }

  removeOrder(session: TableSession) {
    if (session.kdsProductGroup) {
      for (let [k, v] of Object.entries(session.kdsProductGroup)) {
        const [product, hash, line] = k.split("/");
        const productGroup = this.getProductGroup(product, false);
        if (!productGroup) continue;
        productGroup.remove(hash, session._id, line);
      }
    }
  }

  splitAt(chunk: KdsProductChunk, i: number, j: number) {
    const currentIndex = this.chunks.indexOf(chunk);
    if (currentIndex === -1) return;

    if (j) {
      const block = chunk[i];
      const blockIndex = block.group.itemBlock.indexOf(block);
      if (blockIndex !== -1) {
        const remainItems = block.items.slice(j);
        if (remainItems.length) {
          block.items.splice(j, block.items.length);
          if (blockIndex === block.group.itemBlock.length - 1) {
            const newBlock = {
              items: remainItems,
              group: block.group,
              idx: block.group.items.length,
            };
            block.group.itemBlock.push(newBlock);
            chunk.splice(i + 1, 0, newBlock);
            i++;
          } else {
            block.group.itemBlock[blockIndex + 1].items.unshift(...remainItems);
          }
        }
      }
    }

    const remain = chunk.slice(i);
    chunk.splice(i, chunk.length);
    if (currentIndex === this.chunks.length - 1) {
      this.chunks.push(remain);
    } else {
      this.chunks[currentIndex + 1].unshift(...remain);
    }
  }

  lastResize: number;
  lastResizeTimer: any;

  resetSplit() {
    this.lastResizeTimer = null;
    this.lastResize = Date.now();
    for (let product of this.products) {
      product.itemBlock = [
        {
          items: product.items.slice(),
          group: product,
          idx: 0,
        },
      ];
    }
    this.products.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
    this.chunks = [this.products.flatMap(it => it.itemBlock)];
  }

  scheduleResetSplit() {
    if (this.lastResizeTimer) {
      return;
    }
    if ((this.lastResize && Date.now() - this.lastResize > 100) || !this.lastResize) {
      this.resetSplit();
    } else {
      this.lastResizeTimer = setTimeout(this.resetSplit, 500);
    }
  }
}

@Component
export class KdsProductGroup extends Vue {
  @Prop()
  product: string;

  created() {
    this.$tableManager.$on("tick", this.updateTimer);
  }

  get productInfo() {
    return this.$shop.productDict[this.product];
  }

  get stock() {
    return this.productInfo?.stock;
  }

  items: KdsProductItem[] = [];
  itemDict: Record<string, KdsProductItem> = {};

  itemBlock: KdsProductBlock[] = [];

  quantity = 0;
  // displayQuantity = 0;

  get displayQuantity() {
    return this.items.reduce((a, b) => a + b.displayQuantity, 0);
  }
  get parent() {
    return this.$parent as KdsProductManager;
  }

  getProductItem(hash: string, line: ProductLine = null) {
    let cur = this.itemDict[hash];
    if (!cur && line) {
      cur = this.itemDict[hash] = new KdsProductItem({
        parent: this,
        propsData: {
          hash,
        },
      });
      this.items.push(cur);
      if (!this.itemBlock.length) {
        const block = {
          items: [cur],
          group: this,
          idx: 0,
        };
        this.itemBlock.push(block);
        if (!this.parent.chunks.length) {
          this.parent.chunks.push([block]);
        } else {
          this.parent.chunks[this.parent.chunks.length - 1].push(block);
        }
      } else {
        this.itemBlock[this.itemBlock.length - 1].items.push(cur);
      }
    }
    return cur;
  }

  add(hash: string, session: string, line: ProductLine) {
    const item = this.getProductItem(hash, line);
    const oldDate = this.date;
    item.add(session, line);
    if (+oldDate !== +this.date) {
      this.items.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
      // TODO: optimize
      this.parent.scheduleResetSplit();
    }
  }

  remove(hash: string, session: string, line: string) {
    const item = this.getProductItem(hash);
    if (!item) return;
    item.remove(session, line);
  }

  updateQuantity() {
    if (!this.items.length) {
      this.$destroy();
    }
  }

  beforeDestroy() {
    this.$tableManager.$off("tick", this.updateTimer);
    const p = this.parent;
    if (!p) return;
    delete p.productDict[this.product];
    const idx = p.products.indexOf(this);
    if (idx !== -1) {
      p.products.splice(idx, 1);
    }
    for (let chunk of p.chunks) {
      const idx = chunk.findIndex(it => it.group === this);
      if (idx !== -1) {
        chunk.splice(idx, 1);
      }
    }
  }

  warnStatus: "normal" | "warn" | "urgent" = "normal";

  get date() {
    let date: Date = null;
    for (let item of this.items) {
      if (item.date && (!date || date > item.date)) {
        date = item.date;
      }
    }
    return date;
  }

  updateTimer() {
    const timeDiff = Math.max(0, Math.floor((Date.now() - +new Date(this.date)) / 1000 / 60));
    let status: "normal" | "warn" | "urgent" = "normal";

    if (timeDiff >= 10) {
      status = "urgent";
    } else if (timeDiff >= 5) {
      status = "warn";
    }

    if (status !== this.warnStatus) {
      this.warnStatus = status;
    }
  }

  @Watch("stock.kdsStock")
  resetStock() {
    for (let item of this.items) {
      item.resetStock();
    }
    this.parent.scheduleResetSplit();
  }
}

export interface KdsProductItemOption {
  options: FindType<"productOptions">;
  id: string;
  type: string;
  values: KdsProductItemOptionValue[];
}

export interface KdsProductItemOptionValue {
  item: FindType<"productOptions">["options"][number];
  quantity: number;
  id: string;
}

@Component
export class KdsProductItem extends Vue {
  @Prop()
  hash: string;

  quantity = 0;
  displayQuantity = 0;

  lines: KdsProductItemLine[] = [];

  _stocks: { quantity: number }[];
  _outOfStockIndex = -1;
  kitchenOptions: Record<string, { quantity: number; values: string[] }> = {};

  date: Date = null;

  get parent() {
    return this.$parent as KdsProductGroup;
  }

  get type() {
    return this.options[0]?.type || "D";
  }

  created() {
    this._stocks = structuredClone(this.parent.stock?.kdsStock?.filter?.(it => it.hash === this.hash) || []);
  }

  get options(): KdsProductItemOption[] {
    const options = this.hash ? this.hash.split(";") : [];

    return options.map(opt => {
      const info = this.parent.productInfo;
      const [typeOptId, optValues] = opt.split(":");
      const [type, optId] = (typeOptId.includes("|") ? typeOptId : `D|${typeOptId}`).split("|");
      const values = (optValues || "").split(",").filter(it => !!it);
      const optInfo = this.$shop.productOptionDict[optId];

      return {
        options: optInfo,
        id: optId,
        type,
        values: values.map(v => {
          const [id, quantity] = v.split("@");
          return {
            item: optInfo?.options?.find?.(it => String(it._id) === id),
            quantity: +quantity,
            id,
          };
        }),
      };
    });
  }

  get optionsWithValue() {
    // return this.options.filter(it => it.values.length);
    return _.filter(this.options as any, option => {
      const values = option.values;
      option.filteredValues = _.filter(values, value => value.item?.type != "product");
      return option.filteredValues.length;
    });
  }

  add(session: string, line: ProductLine) {
    const oldIdx = this.lines.findIndex(it => it.id === line);
    const old = this.lines[oldIdx];
    if (old?.quantity === line.quantity) return;
    const delta = line.quantity - (old?.quantity ?? 0);
    let newItem: typeof old;
    let insertIdx = 0;
    if (!old) {
      newItem = {
        id: line.id,
        date: line.date,
        quantity: line.quantity,
        kitchen: kitchenHash(line),
      };
      insertIdx = _.sortedIndexBy(this.lines, newItem, it => it.date);
      this.lines.splice(insertIdx, 0, newItem);
    } else {
      insertIdx = oldIdx;
      old.quantity = line.quantity;
    }
    this.quantity += delta;
    this.parent.quantity += delta;
    this.adjustStock(old, newItem || old, delta, line, insertIdx);
  }

  remove(session: string, line: string) {
    const oldIdx = this.lines.findIndex(it => it.id === line);
    if (oldIdx === -1) return;
    const old = this.lines[oldIdx];
    this.lines.splice(oldIdx, 1);
    this.quantity -= old.quantity;
    this.parent.quantity -= old.quantity;
    if (!this.quantity) {
      this.$destroy();
    }
    this.resetStock();
  }

  beforeDestroy() {
    const p = this.parent;
    if (!p) return;
    delete p.itemDict[this.hash];
    const idx = p.items.indexOf(this);
    if (idx !== -1) {
      p.items.splice(idx, 1);
    }
    for (let chunk of p.itemBlock) {
      const idx = chunk.items.indexOf(this);
      if (idx !== -1) {
        chunk.items.splice(idx, 1);
      }
    }
    p.updateQuantity();
  }

  adjustStock(
    old: KdsProductItemLine,
    newItem: KdsProductItemLine,
    delta: number,
    line: ProductLine,
    insertIdx: number,
  ) {
    if (!delta) return;
    if (delta > 0) {
      // use stock
      let cur = delta;
      while (this._stocks.length && cur) {
        const item = this._stocks[0];
        const stockToUse = Math.min(cur, item.quantity);
        item.quantity -= stockToUse;
        cur -= stockToUse;
        if (!item.quantity) {
          this._stocks.shift();
        }
      }
      if (cur) {
        // not enough stock
        if (this._outOfStockIndex === -1 || insertIdx >= this._outOfStockIndex) {
          if (this._outOfStockIndex === -1) {
            this._outOfStockIndex = insertIdx;
          }
          let curItem = this.kitchenOptions[newItem.kitchen];
          if (!curItem) {
            this.kitchenOptions[newItem.kitchen] = curItem = {
              quantity: cur,
              values: newItem.kitchen ? newItem.kitchen.split(",") : [],
            };
          } else {
            curItem.quantity += cur;
          }
          if (!this.date || this.date > line.date) {
            this.date = line.date;
          }
          // this.parent.displayQuantity += cur;
          this.displayQuantity += cur;
        } else {
          // need to recompute priority
          this.resetStock();
          return false;
        }
      } else {
        // stock enough
        return true;
      }
    } else {
      this.resetStock();
    }
  }

  resetStock() {
    this._stocks = structuredClone(this.parent.stock?.kdsStock?.filter?.(it => it.hash === this.hash) || []);
    this.kitchenOptions = {};
    // this.parent.displayQuantity = 0;
    this.displayQuantity = 0;
    this.date = null;
    for (let i = 0; i < this.lines.length; i++) {
      const line = this.lines[i];
      let cur = line.quantity;
      while (this._stocks.length && cur) {
        const item = this._stocks[0];
        const stockToUse = Math.min(cur, item.quantity);
        item.quantity -= stockToUse;
        cur -= stockToUse;
        if (!item.quantity) {
          this._stocks.shift();
        }
      }
      if (cur) {
        // not enough stock
        if (this._outOfStockIndex === -1) {
          this._outOfStockIndex = i;
        }
        let curItem = this.kitchenOptions[line.kitchen];
        if (!curItem) {
          this.kitchenOptions[line.kitchen] = curItem = {
            quantity: cur,
            values: line.kitchen ? line.kitchen.split(",") : [],
          };
        } else {
          curItem.quantity += cur;
        }
        if (!this.date || this.date > line.date) {
          this.date = line.date;
        }
        // this.parent.displayQuantity += cur;
        this.displayQuantity += cur;
      }
    }
  }
}

export interface KdsProductItemLine {
  id: string;
  date: Date;
  quantity: number;
  kitchen: string;
}

export function optionHash(line: ProductLine, isTakeAway: boolean) {
  const type = line.takeAway || isTakeAway ? "T" : "D";
  const options = (line.options || []).slice().sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  const hash = options
    .map(opt => {
      const optNum = opt.selections
        .slice()
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .map(opt => [opt._id, opt.quantity] as [any, number])
        .reduce(
          (a, b) => {
            if (String(a[a.length - 1]?.[0]) === String(b[0])) {
              a[a.length - 1][1] = b[1];
            } else {
              a.push(b);
            }
            return a;
          },
          [] as [any, number][],
        );

      return `${opt.option}:${optNum.map(opt => `${opt[0]}@${opt[1]}`).join(",")}`;
    })
    .join(";");
  return `${type}|${hash}|${line.customRemark}`;
}

export function kitchenHash(line: ProductLine) {
  return (line.kitchenOptions || []).map(it => it._id).join(",");
}

export function parseHash(parent: Vue, hash: string): KdsProductItemOption[] {
  const optionsStr = hash ? hash.split(";") : [];

  const options = optionsStr.map(opt => {
    const [typeOptId, optValues] = opt.split(":");
    const [type, optId] = typeOptId.split("|");
    const values = (optValues || "").split(",").filter(it => !!it);
    const optInfo = parent.$shop.productOptionDict[optId];

    return {
      options: optInfo,
      id: optId,
      type,
      values: values.map(v => {
        const [id, quantity] = v.split("@");
        return {
          item: optInfo?.options?.find?.(it => String(it._id) === id),
          quantity: +quantity,
          id,
        };
      }),
    };
  });
  return options;
}

export type KdsProductGroupRef = Record<string, number>;
