import { render, staticRenderFns } from "./NumPadNew.vue?vue&type=template&id=31ffda0f&scoped=true"
import script from "./NumPadNew.vue?vue&type=script&lang=ts"
export * from "./NumPadNew.vue?vue&type=script&lang=ts"
import style0 from "./NumPadNew.vue?vue&type=style&index=0&id=31ffda0f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ffda0f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31ffda0f')) {
      api.createRecord('31ffda0f', component.options)
    } else {
      api.reload('31ffda0f', component.options)
    }
    module.hot.accept("./NumPadNew.vue?vue&type=template&id=31ffda0f&scoped=true", function () {
      api.rerender('31ffda0f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/feathers-client/components/NumPadNew.vue"
export default component.exports