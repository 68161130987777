
import _, { cond } from "lodash";
import moment from "moment";
import { Vue, Component, Watch, medSpec, lowSpec } from "@feathers-client";
import StatusHost from "@feathers-client/components/StatusHost.vue";
import DialogHost from "@feathers-client/components/DialogHost.vue";
import PlanInfoBanner from "@feathers-client/planInfo/banner.vue";
import SlideEditorHost from "@schemaEditor/slide/SlideEditorHost.vue";

import { adminMenu, staffMenu, posMenu, guestMenu, MenuType, type FeatureKey } from "~/plugins/routes";

import {
  supported,
  getLogin,
  setLogin,
  logout,
  reportLogin,
  loginLoader,
  switchOtherLogin,
  switchOtherServer,
} from "pos-printer/utils/nativeAuth";
import url from "url";
import { supported as appSupported, setStatusBarHidden } from "pos-printer/utils/app";

@Component({
  components: {
    StatusHost,
    DialogHost,
    PlanInfoBanner,
    SlideEditorHost,
  },
  provide: {
    __reactiveInject__: null,
  },
})
export default class Default extends Vue {
  drawer = null;
  oldDrawer = null;
  mini = false;
  lang = false;
  chatManager = null;
  shop = null;
  schemas = null;

  head() {
    const title = this.$td(
      this.$store.state.title && this.$store.state.title.$t
        ? { ...this.$store.state.title, $ta: this.$store.getters.margs }
        : this.$store.state.title,
    );
    const titleParts = [title, this.$config.appName || "boxsRestaurant"].filter(it => !!it).join(" | ");
    return {
      title: titleParts,
      htmlAttrs: {
        style: [
          this.fullPage ? "overflow: hidden; height: 100%" : "overflow: auto",
          this.$config.appLogo
            ? `--app-logo: url(${JSON.stringify(this.$config.appLogo)})`
            : `--app-logo: url("${require("~/assets/images/logo.png")}`,
          this.$store.state.tableMode ? `font-size: ${(this.$shop && this.$shop.currentFontSize) || 100}%` : "",
          `--header-height: ${this.$store.state.headerHeight}px`,
          `--cart-picker-grid-count: ${(this.$shop && this.$shop.currentCartPickerGridCount) || 8}`,
          `--cart-picker-grid-font-size: ${(this.$shop && this.$shop.currentCartPickerGridFontSize) || 16}px`,
        ].join(";"),
        class: [
          this.$store.state.disableTextSelect ? "disable-text-select" : "",
          this.$store.state.performance ? "performance" : "best-graphics",
          this.fullPage ? "full-page-app" : "",
          lowSpec || this.$store.state.lowSpec ? "low-gpu" : "",
          medSpec ? "med-gpu" : "",
        ].join(" "),
        lang: this.$i18n.locale,
        translate: "no",
      },
      bodyAttrs: {
        style: this.fullPage ? `overflow: hidden; height: 100%` : "",
      },
    };
  }
  get fullPage() {
    return this.$store.state.fullPage;
  }

  get rawMenus() {
    return this.$store.getters["userId"]
      ? this.$store.getters["isAdmin"]
        ? adminMenu
        : this.$store.state.tableMode
          ? posMenu
          : staffMenu
      : guestMenu;
  }

  get fitems() {
    return this.filterItems(this.rawMenus);
  }
  get dark() {
    return this.$store.state.dark;
  }

  get error() {
    return !!this.$store.state.error;
  }
  set error(v) {
    if (!v) {
      this.$store.commit("SET_ERROR");
    }
  }

  get errorMessage() {
    return this.$store.state.error;
  }

  get attributes() {
    return this.$store.getters.attributes;
  }
  get role() {
    return this.$store.getters.role;
  }

  beforeMount() {
    if (this.$store.state.tableMode) {
      this.drawer = false;
    }
    this.$vuetify.theme.dark = this.dark;
    this.schemas = this.$schemas;

    this.shop = this.$shop;
  }
  async mounted() {
    if (this.$store.state.cast) return;

    this.updateFullPage();
    this.$schemas.init();

    if (await supported()) {
      this.nativeAuthSupported = true;
    }
  }
  filterFeature(feature: FeatureKey | FeatureKey[]) {
    if (!feature) return true;
    if (!this.$features) return false;
    if (typeof feature === "string") return !!this.$features[feature];
    if (Array.isArray(feature)) {
      return !!feature.find(it => this.$features[it]);
    }
    return false;
  }
  filterItems(item: MenuType[]): MenuType[] {
    if (!item) return [];

    return item
      .map(it => {
        if (!this.filterFeature(it.feature) || (it.excludeFeature && this.filterFeature(it.excludeFeature))) {
          return null;
        }
        if (it.shopData) {
          if (!this.$shop.shopData) return null;
          const list = typeof it.shopData === "string" ? [it.shopData] : it.shopData;
          if (!list.some(it => this.$shop.shopData[it])) {
            return null;
          }
        }
        if (it.cond) {
          const m = it.cond.find(
            c =>
              (!c.role || this.$store.state.user.role === c.role) &&
              (!c.roles || this.$store.state.user.roles?.includes?.(c.roles)) &&
              (!c.root || !this.$store.state.user.shop) &&
              (!c.shopGroup ||
                !this.$store.state.user.shop ||
                (this.$store.state.user.shopGroup &&
                  this.$store.state.user.shopGroup === this.$shop?.shopData?.shopGroup)),
          );
          if (!m) return null;
        }
        if (it.items) {
          const children = this.filterItems(it.items);
          if (!children.length) {
            return null;
          }
          return {
            ...it,
            items: children,
          };
        } else return it;
      })
      .filter(it => !!it);
  }
  formatDate(date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  updateFullPage() {
    if (process.server) return;
    const nuxt = document.getElementById("__nuxt");
    if (this.fullPage) nuxt.classList.add("fill-height");
    else nuxt.classList.remove("fill-height");

    const layout = document.getElementById("__layout");
    if (this.fullPage) layout.classList.add("fill-height");
    else layout.classList.remove("fill-height");
  }

  updateLocale(locale) {
    this.$feathers.updateSession({
      locale,
    });
  }

  @Watch("fullPage")
  onFullPage(val) {
    this.updateFullPage();
  }

  @Watch("dark")
  onDark(val) {
    this.$vuetify.theme.dark = val;
    if (val) {
      this.mini = true;
    } else {
      this.mini = false;
    }
  }

  async goDashboard() {
    if (this.$network.offlineActivated) {
      await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("offline.needOnlineToUseCMS"),
          hasCancel: false,
        },
        {
          maxWidth: "400px",
        },
      );
      return;
    }
    if (this.fitems.find(it => it.href === "/table/orders")) {
      this.$router.push("/table/orders");
    } else {
      this.$router.push("/profile");
    }
  }

  loginLoader = loginLoader;
  switchOtherLogin = switchOtherLogin;
  switchOtherServer = switchOtherServer;
  nativeAuthSupported = false;

  async switchLogin(auth: any) {
    if (await supported()) {
      try {
        await setLogin({
          jwt: auth.accessToken,
          startURL: url.resolve(location.toString(), this.$router.resolve("/dashboard").href),
          username: auth.user?.email?.toLowerCase?.() ?? "",
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async changeLocale(item) {
    this.$store.dispatch("changeLang", { code: item.locale || item.code, $i18n: this.$i18n });
    this.lang = false;
  }

  appSupported: boolean | string[] = null;
  appHideSupported = false;
  @Watch("$store.state.tableMode", { immediate: true })
  async onTableMode(v: boolean) {
    if (this.appSupported === null) {
      this.appSupported = await appSupported();
    }
    if (this.appSupported && Array.isArray(this.appSupported) && this.appSupported.includes("setStatusBarHidden")) {
      try {
        this.appHideSupported = await setStatusBarHidden(v);
      } catch (e) {
        this.appSupported = false;
        console.warn(e);
      }
    }
  }

  get isDefaultName() {
    return !this.$config.appName || this.$config.appName === "boxsRestaurant" || this.$config.appName === "BOXS";
  }
}
