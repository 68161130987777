
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";

@Component({})
export default class Cash extends Vue {
  @Prop()
  session: TableSession;

  @Prop(Boolean)
  noTips: boolean;

  @Prop(Boolean)
  nonCash: boolean;

  clickReceive() {
    if (this.nonCash) return;
    this.apply();
    this.input = "receive";
  }

  clickTips() {
    if (this.noTips) return;
    this.apply();
    this.input = "tips";
  }

  get items() {
    const amount = this.session.payingAmount;

    const roundTo10 = Math.ceil(amount / 10) * 10;
    const roundTo20 = Math.ceil(amount / 20) * 20;
    const roundTo50 = Math.ceil(amount / 50) * 50;
    const roundTo100 = Math.ceil(amount / 100) * 100;
    const roundTo500 = Math.ceil(amount / 500) * 500;

    const items = _.uniqBy(
      [
        {
          _id: amount,
          name: this.$t("payCash.noChange"),
        },
        {
          _id: roundTo10,
          name: this.$price(roundTo10, true),
        },
        {
          _id: roundTo20,
          name: this.$price(roundTo20, true),
        },
        {
          _id: roundTo50,
          name: this.$price(roundTo50, true),
        },
        {
          _id: roundTo100,
          name: this.$price(roundTo100, true),
        },
        {
          _id: roundTo500,
          name: this.$price(roundTo500, true),
        },
      ],
      it => it._id,
    );
    const noChange = items.shift();
    while (items.length < 6) {
      items.push({
        _id: -items.length,
        name: "",
      });
    }
    items.push(noChange);

    items.push({
      _id: -7,
      name: this.noTips ? "" : this.$t("tableView.markAsTips"),
    });

    return items;
  }

  setReceived(received: number) {
    if (received < 0) {
      if (received === -7) {
        this.markAsTips();
      }
    } else {
      this.session.received = received;
    }
  }

  input: "tips" | "receive" = null;

  created() {
    if (!this.nonCash) {
      this.input = "receive";
    }
  }

  customReceived = "";
  userClear = false;

  get isCustomReceived() {
    return !this.items.find(it => checkID(it, this.session.received));
  }

  @Watch("customReceived")
  onCustomRecevied(v) {
    v = v === null || v === "" || isNaN(+v) ? null : +v;
    this.session.received = v;
  }

  @Watch("session.customReceived")
  onPayments(v, ov) {
    if (v === ov) return;
    if (v === null) {
      this.customReceived = "";
    }
  }

  doAction(action: number | string) {
    if (action === "C" || this.customReceived === "") {
      this.session.received = null;
      this.userClear = true;
    } else if (!this.isCustomReceived) {
      this.session.received = null;
      this.userClear = false;
    } else {
      this.userClear = false;
    }
  }

  get priceStr() {
    if (this.customReceived) {
      const parts = this.customReceived.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.session.received);
    }
  }

  get pricePadding() {
    if (this.customReceived) {
      const parts = this.customReceived.split(".");
      if (parts.length === 1) {
        return ".00";
      } else if (parts[1].length === 0) {
        return "00";
      } else if (parts[1].length === 1) {
        return "0";
      }
    }
    return "";
  }

  customTips = "";
  tipsUserClear = false;

  @Watch("tips")
  onTips(v) {
    if (v !== null) {
      this.customTips = "";
    }
  }

  get tipsStr() {
    if (this.customTips) {
      const parts = this.customTips.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.tips);
    }
  }

  get tipsPadding() {
    if (this.customTips) {
      const parts = this.customTips.split(".");
      if (parts.length === 1) return ".00";
      else if (parts[1].length === 0) return "00";
      else if (parts[1].length === 1) return "0";
    }
    return "";
  }

  doTipsAction(action: number | string) {
    if (action === "C" || this.customTips === "") {
      this.tips = 0;
      this.tipsUserClear = true;
    } else {
      this.tipsUserClear = false;
    }
  }

  confirmTips() {
    if (this.nonCash) {
      this.input = null;
    }
  }

  get changeStr() {
    let change = this.nonCash ? this.session.tempPaymentSurcharge : this.session.change;
    if (change === null) {
      return "-";
    } else {
      return this.$price(change);
    }
  }

  async apply() {
    if (this.customTips) {
      let received = +this.customTips;
      if (!isNaN(received)) {
        this.session.tips = received;
        this.session.delaySave({
          discounts: this.session.discounts,
          ...this.session.cachedPriceDetails,
        });
        await this.session.syncOrder();
      }
    }
    if (this.customReceived) {
      let received = +this.customReceived;
      if (isNaN(received)) return null;
      this.session.received = received;
    }

    return this.session.received >= this.session.payingAmount ? this.session.received : null;
  }

  get tips() {
    return this.session.tips;
  }

  set tips(v) {
    this.session.tips = v;
    this.session.updateCoupons();
    this.session.delaySave({
      discounts: this.session.discounts,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  async markAsTips() {
    let change = this.session.change;
    if (change !== null) {
      this.session.tips += change;
      this.session.updateCoupons();
      this.session.delaySave({
        discounts: this.session.discounts,
        ...this.session.cachedPriceDetails,
      });
      await this.session.syncOrder();
    }
  }
}
