import Vue from "vue";
import _ from "lodash";
import {
  FindType,
  FindPopType,
  getOptions,
  Component,
  Watch,
  CachedList,
  CachedDict,
  flushCached,
  checkID,
  FindPopRawType,
  loadCached,
  loadCachedItem,
  getID,
} from "@feathers-client";
import { createScreen, createScreenWithDialog, Screen } from "pos-printer/multiscreen";
import { isBBPOS } from "@feathers-client";
import { sign, init, jwk, keyHash } from "pos-printer/utils/cloudAuth";
import { EventEmitter } from "events";
import uuid from "uuid/v4";
import { LocalPrinterManager } from "./printer/local";
import { Currencies } from "@feathers-client/currency";
import type { OctopusConfig } from "pos-printer/octopus";
import type { HASEConfig } from "pos-printer/hase";
import type { BBMSLConfig } from "pos-printer/bbmsl";
import type { TurnCloudConfig } from "pos-printer/turncloud";
import type { SimplePaymentIntegrationConfig } from "pos-printer/simplePaymentIntegration";
import { RazerPayConfig } from "pos-printer/razerpay";
import type { AllPrintJobData, PrintJobBaseData } from "~/common/table/invoiceSequencer";
import { AutoMerger } from "@common/table/autoMerger";
import moment from "moment";
import { getPrinterServer } from "./printer/invoiceSequencer";
import { TableSession } from "./table/session";
import { init as initUsb, UsbDeviceInfo } from "pos-printer/ports/usb";
import { compileCondition } from "~/common/table/util";
import { supported as authSupported, updateLogin } from "pos-printer/utils/nativeAuth";

export type ProductType = FindType<"products">;
export type ProductOptionType = FindType<"productOptions">;
export type ProductOptionValueType = FindType<"productOptions">["options"][number];
export type SharePrintJob = FindType<"sharePrintJobs">;

const shopQuery = {
  query: {
    $populate: [{ path: "storePaymentMethods" }, { path: "shopGroup" }],
  },
} as const;

export type ShopType = FindPopType<typeof shopQuery, "shops">;
export type PaymentMethodType = FindType<"paymentMethods">;

function cacheQuery(self: any) {
  return {
    query: {
      $paginate: false,
      ...self.impersonateShopQuery,
    },
  };
}

function cacheQueryExtend(extend: any) {
  return (self: any) => ({
    query: {
      ...cacheQuery(self).query,
      ...extend,
    },
  });
}

@Component
class Shop extends Vue {
  inited = false;
  loadShopTask: Promise<void> = null;

  init() {
    if (this.inited) return this;
    this.inited = true;

    this.$feathers.service("posDevices").on("patched", this.updateDevice);
    this.$feathers.service("posCommands").on("created", this.onCommand);

    if (this.$store.state.cast) {
      this.initCast();
      return this;
    }
    this.localPrinter = new LocalPrinterManager({ parent: this });
    this.$feathers.on("connected" as any, this.onConnected);
    this.$feathers.service("shops").on("patched", shop => {
      if (checkID(shop, this.shopData)) {
        for (let [k, v] of Object.entries(shop)) {
          if (k === "shopGroup" || k === "storePaymentMethods") {
            continue;
          }
          Vue.set(this.shopData, k, v);
        }
      }
    });
    this.$feathers.service("productStocks").on("patched", stock => {
      const product = this.productDict[`${stock.product}`];
      if (product) {
        product.stock = stock;
      }
    });
    this.$feathers.service("productOptionStocks").on("created", stock => {
      const product = this.productOptionDict[`${stock.option}`];
      if (product && checkID(stock.shop, this.shopId)) {
        product.stock = stock;
      }
    });
    this.$feathers.service("productOptionStocks").on("patched", stock => {
      const product = this.productOptionDict[`${stock.option}`];
      if (product && checkID(stock.shop, this.shopId)) {
        product.stock = stock;
      }
    });
    this.$feathers.service("customProducts").on("created", product => {
      Vue.set(this.customProducts, product._id, product);
    });
    this.$feathers.service("customProducts").on("patched", product => {
      Vue.set(this.customProducts, product._id, product);
    });
    this.$feathers.service("customProducts").on("removed", product => {
      Vue.delete(this.customProducts, product._id);
    });
    this.$feathers.service("shopNotifications").on("created", notification => {
      if (!checkID(notification.shop, this.shopId) || notification.read) return;
      const notiIdx = this.notifications.findIndex(it => it._id === notification._id);
      if (notiIdx !== -1) {
        this.notifications[notiIdx] = notification;
      } else {
        this.notifications.push(notification);
      }
    });
    this.$feathers.service("shopNotifications").on("patched", notification => {
      if (!checkID(notification.shop, this.shopId)) return;
      const notiIdx = this.notifications.findIndex(it => it._id === notification._id);
      if (notification.read) {
        if (notiIdx !== -1) {
          this.notifications.splice(notiIdx, 1);
        }
      } else {
        if (notiIdx !== -1) {
          this.notifications[notiIdx] = notification;
        } else {
          this.notifications.push(notification);
        }
      }
    });
    this.$feathers.service("shopNotifications").on("removed", notification => {
      if (!checkID(notification.shop, this.shopId)) return;
      const notiIdx = this.notifications.findIndex(it => it._id === notification._id);
      if (notification.read) {
        if (notiIdx !== -1) {
          this.notifications.splice(notiIdx, 1);
        }
      }
    });
    this.$feathers.service("printerTemplates").on("created", template => {
      if (!template?.shops?.length || template.shops.includes(this.shopData._id)) {
        this.printerTemplateDict[template._id] = template;
        this.printerTemplateDict[template.tag] = template;
        this.printerTemplateDict[template.type + "/" + template.tag] = template;
      }
    });
    this.$feathers.service("printerTemplates").on("patched", template => {
      if (!template?.shops?.length || template.shops.includes(this.shopData._id)) {
        this.printerTemplateDict[template._id] = template;
        this.printerTemplateDict[template.tag] = template;
        this.printerTemplateDict[template.type + "/" + template.tag] = template;
      } else {
        delete this.printerTemplateDict[template._id];
        delete this.printerTemplateDict[template.tag];
        delete this.printerTemplateDict[template.type + "/" + template.tag];
      }
    });
    this.$feathers.service("printerTemplates").on("removed", template => {
      delete this.printerTemplateDict[template._id];
      delete this.printerTemplateDict[template.tag];
      delete this.printerTemplateDict[template.type + "/" + template.tag];
    });
    this.$feathers.service("posDevices/limit").on("removed", this.onDeviceRemoved);
    this.storage.on("updateStore", this.updateStore);
    this.storage.on("setStore", this.setStore);
    this.loadShopTask = this.loadShopData();
    return this;
  }

  async initCast() {
    (this.$feathers as any).customAuthPath = "posDevices/posLogin";
    (this.$feathers as any).customAuth = this.customAuth;
    this.$feathers.service("posDevices").on("created", this.onNewDevice as any);
    this.$feathers.service("odsSettings").on("patched", this.onOdsSetting as any);
  }

  castSetup = false;
  odsSetting: FindType<"posDevices/posLogin">["odsSetting"] = null;

  async customAuth() {
    const resp = await this.$feathers.service("posDevices/posLogin").create({
      token: await sign(
        {
          deviceType: typeof this.$store.state.cast === "string" ? this.$store.state.cast : null,
        },
        undefined,
        undefined,
        typeof this.$store.state.cast === "string" ? this.$store.state.cast + "Key" : undefined,
      ),
      userAgent: navigator.userAgent,
    });
    if (!resp.device) {
      this.castSetup = false;
    } else {
      this.device = resp.device;
      this.odsSetting = resp.odsSetting;
      this.shopData = resp.shop as any;
      this.castSetup = true;
      this.$root.$emit("castSetup");
    }
  }

  onNewDevice(device) {
    if (this.device) return;
    this.customAuth();
  }

  onOdsSetting(item) {
    if (this.odsSetting && checkID(item, this.odsSetting)) {
      this.customAuth();
    }
  }

  localPrinter: LocalPrinterManager = null;

  sessionId = uuid();
  device: FindType<"posDevices"> = null;
  staff: FindType<"staffs"> = null;
  staffLoginTime: number = 0;
  staffIdle = false;

  secondScreen: Screen = null;

  get staffId() {
    return this.staff?._id ?? null;
  }

  sections: FindType<"sections">[] = [];
  sectionDict: {
    [key: string]: FindType<"sections">;
  } = {};
  cats: FindType<"categories">[] = [];
  subCats: FindType<"subCategories">[] = [];
  customProducts: Record<string, FindType<"customProducts">> = {};
  products: ProductType[] = [];
  productDict: {
    [key: string]: ProductType;
  } = {};
  productOptions: ProductOptionType[] = [];
  productOptionDict: {
    [key: string]: ProductOptionType;
  } = {};
  discounts: FindType<"orderDiscounts">[] = [];
  automaticDiscounts: FindType<"orderDiscounts">[] = [];
  taxSettings: FindType<"taxSettings">[] = [];
  surchargeSettings: FindType<"surchargeSettings">[] = [];
  vipDiscounts: FindType<"orderDiscounts">[] = [];
  notifications: FindType<"shopNotifications">[] = [];
  printerTemplateDict: Record<string, FindType<"printerTemplates">> = {};
  autoMerger: AutoMerger;
  shopOptions: Record<string, any> = {};

  get currency() {
    return this.shopData?.currency || this.$config.currency || "HKD";
  }

  get currencySymbol() {
    return Currencies[this.currency]?.symbol ?? "$";
  }

  @CachedList("shopModifiers", cacheQuery)
  modifiers: FindType<"shopModifiers">[];

  @CachedList("kitchenPrinters", cacheQuery)
  kitchenPrinters: FindType<"kitchenPrinters">[];

  @CachedList("waterBars", cacheQuery)
  waterBars: FindType<"waterBars">[];

  @CachedList("kitchenOptions", cacheQuery)
  kitchenOptions: FindType<"kitchenOptions">[];

  @CachedDict("shopPrinters", cacheQueryExtend({}), undefined, true)
  shopPrinterDict: Record<string, FindType<"shopPrinters">>;

  async cacheLoadPrinters() {
    await Promise.all([
      loadCachedItem(this, "shopPrinterDict"),
      loadCachedItem(this, "kitchenPrinters"),
      loadCachedItem(this, "waterBars"),
    ]);
  }

  async getLocalPrinters(
    type: FindType<"shopPrinters">["type"],
    session: FindType<"tableSessions">,
    deviceId?: string,
  ) {
    await this.cacheLoadPrinters();
    const printers = Object.values(this.shopPrinterDict).filter(
      it =>
        !it.disabled &&
        it.printerType === "template" &&
        (deviceId ? it.templateSetting.find(el => checkID(el.device, deviceId)) : true) &&
        it.type === type &&
        (!it.source?.length || it.source.includes(session.source)) &&
        (!it.orderType?.length || it.orderType.includes(session.type)),
    );
    return printers;
  }

  async getLocalPrinter(type: FindType<"shopPrinters">["type"], session: FindType<"tableSessions">) {
    const printers = await this.getLocalPrinters(type, session);
    return printers[0];
  }

  @CachedList("shopPoints", cacheQuery)
  shopPoints: FindType<"shopPoints">[];

  get pointOrDollar() {
    return this.shopPoints.filter(it => it.tag === "dollar" || it.tag === "point");
  }

  @CachedDict("shopRanks", cacheQuery, undefined, true)
  shopRanks: Record<string, FindType<"shopRanks">>;

  get kitchenOptionDict() {
    return Object.fromEntries(this.kitchenOptions.map(it => [String(it._id), it] as const));
  }

  @CachedList("cancelReasons", cacheQuery)
  cancelReasons: FindType<"cancelReasons">[];

  @CachedDict("ingredients", cacheQuery, undefined, true)
  ingredientDict: Record<string, FindType<"ingredients">>;

  @CachedDict("ingredientCats", cacheQuery, undefined, true)
  ingredientCatDict: Record<string, FindType<"ingredientCats">>;

  get catDict() {
    return Object.fromEntries(this.cats.map(it => [it._id as string, it] as const));
  }

  get subCatDict() {
    return Object.fromEntries(this.subCats.map(it => [it._id as string, it] as const));
  }

  storage = new ShopStorage();

  get disablePaymentMethods() {
    return this.shopData?.disablePaymentMethods || [];
  }

  get shopRemarks() {
    return this.$en(this.shopData?.remark) || this.$td(this.shopData?.remark);
  }

  shopGroup: FindType<"shopGroups"> = null;
  shopData: ShopType = null;
  impersonateShop: string = null;
  posAuthorized = false;

  get impersonateShopQuery() {
    return this.$store.state.user.shop
      ? {}
      : this.impersonateShop
        ? {
            shop: this.impersonateShop,
          }
        : {};
  }

  get paymentMethods() {
    return _.sortBy(this.shopData?.storePaymentMethods ?? [], it => it.order);
  }

  get paymentMethodDict(): { [key: string]: PaymentMethodType } {
    return Object.fromEntries(this.paymentMethods.map(m => [m._id, m]));
  }

  async adminImpersonateShop(shop: string) {
    if (checkID(this.shopData, shop) || checkID(this.impersonateShop, shop)) return;
    await this.$feathers.service("users/impersonate").create({
      shop,
    });
    this.impersonateShop = shop;
    await (this.loadShopTask = this.loadShopData());
  }

  get shopId() {
    const shopId = this.$store.state.user.shop ? this.$store.state.user.shop : this.impersonateShop;
    return shopId;
  }

  get shopGroupId() {
    return this?.shopGroup?._id;
  }

  get cachedId() {
    return this.shopId || this.shopGroupId || this.$store.getters.userId;
  }

  @Watch("$store.state.user.shop")
  onShopChanged() {
    this.loadShopTask = this.loadShopData();
  }

  private async loadShopData() {
    try {
      this.reset();
      if (!this.$store.state.user) return;

      if (this.shopId) {
        this.storage.loadStoreString(localStorage[`cachedSettings/${this.shopId}`]);
      }

      await init();

      if (this.impersonateShop) {
        await this.$feathers.service("users/impersonate").create({
          shop: this.impersonateShop,
        });
      }

      if (!this.shopId) {
        if (this.$store.state.user.shopGroup) {
          this.shopGroup = await this.$feathers.service("shopGroups").get(this.$store.state.user.shopGroup);
          if (this.shopGroup?.features?.loadAllLocales !== undefined) {
            localStorage["loadAllLocales"] = `${this.shopGroup.features.loadAllLocales}`;
          }
        }
        await this.loadDevice();
        this.loadStoreAndSave();
        return;
      }

      await this.loadDevice();
      this.loadStoreAndSave();

      const [
        sections,
        timeConditions,
        cats,
        subCats,
        discountList,
        shopData,
        unsortedList,
        productOptions,
        customProducts,
        taxSettings,
        surchargeSettings,
        notifications,
        printerTemplates,
        shopOptions,
      ] = await Promise.all([
        this.$feathers.service("sections").find({
          query: {
            $sort: { showOrder: 1, _id: 1 },
            $paginate: false,
          },
          paginate: false,
        }),
        this.$feathers.service("timeConditions").find({
          query: {
            $paginate: false,
          },
          paginate: false,
        }),
        this.$feathers.service("categories").find({
          query: {
            $paginate: false,
            $sort: { order: 1, _id: 1 },
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("subCategories").find({
          query: {
            $paginate: false,
            $sort: { order: 1, _id: 1 },
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("orderDiscounts").find({
          query: {
            $paginate: false,
            $sort: { priority: 1, _id: 1 },
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("shops").get(this.shopId, shopQuery),
        this.$feathers.service("products").find({
          query: {
            $paginate: false,
          },
          paginate: false,
        }),
        this.$feathers.service("productOptions").find({
          query: {
            $paginate: false,
          },
          paginate: false,
        }),
        this.$feathers.service("customProducts").find({
          query: {
            $paginate: false,
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("taxSettings").find({
          query: {
            $paginate: false,
            $sort: { priority: 1 },
            status: "active",
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("surchargeSettings").find({
          query: {
            $paginate: false,
            $sort: { priority: 1 },
            status: "active",
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("shopNotifications").find({
          query: {
            $paginate: false,
            $sort: { date: -1 },
            read: false,
            ...this.impersonateShopQuery,
          },
          paginate: false,
        }),
        this.$feathers.service("printerTemplates").find({
          query: {
            $paginate: false,
            $or: [{ shops: { $exists: false } }, { shops: { $size: 0 } }, { shops: this?.shopData?._id }] as any[],
            tag: { $gt: "" },
          },
          paginate: false,
        }),
        this.$feathers.service("shopOptions").find({
          query: {
            $paginate: false,
          },
          paginate: false,
        }),
      ]);

      if (this.$offline.offlineEnabled) {
        loadCached(this);
      }

      this.sections = sections;
      this._timeConditionCache = timeConditions
        .map(timeCondition => {
          const conds = timeCondition.timeRange
            .map(timeRange =>
              compileCondition(
                timeRange.weekdays,
                timeRange.from,
                timeRange.to,
                timeRange.startDate,
                timeRange.endDate,
              ),
            )
            .filter(Boolean);

          if (!conds.length) {
            return null;
          }

          return {
            timeCondition,
            conds,
            prev: false,
          };
        })
        .filter(Boolean);

      this.cats = cats;
      this.subCats = subCats;
      this.shopData = shopData;
      this.shopGroup = shopData.shopGroup;
      if (this.shopData?.shopGroup?.features?.loadAllLocales !== undefined) {
        localStorage["loadAllLocales"] = `${this.shopData.shopGroup.features.loadAllLocales}`;
      }
      this.customProducts = Object.fromEntries(customProducts.map(it => [`${it._id}`, it] as [string, any]));
      this.taxSettings = taxSettings;
      this.surchargeSettings = surchargeSettings;
      this.notifications = notifications;
      this.printerTemplateDict = Object.fromEntries(printerTemplates.map(it => [it.tag, it]));
      this.printerTemplateDict = {
        ...this.printerTemplateDict,
        ...Object.fromEntries(printerTemplates.map(it => [it._id, it])),
      };

      this.shopOptions = Object.fromEntries(shopOptions.map(it => [it.name, it.value]));
      this.products = this.sortProducts(unsortedList);

      for (let p of this.products) {
        this.productDict[`${p._id}`] = p;
      }
      this.productDict = Object.assign({}, this.productDict);
      for (let p of productOptions) {
        this.productOptionDict[`${p._id}`] = p;
      }
      this.productOptions = productOptions;
      const dict: Shop["sectionDict"] = {};
      for (let p of this.sections) {
        dict[`${p._id}`] = p;
      }
      this.sectionDict = Object.freeze(dict);
      this.discounts = discountList.filter(it => it.source === "manual");
      this.automaticDiscounts = discountList.filter(it => it.source === "automatic");
      this.vipDiscounts = discountList.filter(it => it.source === "vip");

      if (this.$store.state.user.staff) {
        this.staff = await this.$feathers.service("staffs").get(this.$store.state.user.staff);
      }
      this.syncShop();

      if (this.shopData.autoMergeInPOS || this.shopData.autoMergeOrderedInPOS) {
        this.autoMerger = new AutoMerger();

        for (let product of this.products) {
          this.autoMerger.addProduct(product);
        }
        for (let option of this.productOptions) {
          this.autoMerger.addOption(option);
        }
      } else {
        this.autoMerger = null;
      }

      if (
        this.$features.turnCloud &&
        this.$offline &&
        !this.$offline.offline &&
        this.$offline.masterSwitch &&
        this.$offline.offlineEnabled
      ) {
        (async () => {
          try {
            const assignedRolls = await this.$feathers
              .service("assignTwInvoiceRoll")
              .create({ rolls: await this.$offline.fetchData("twInvoiceRolls", {}), shop: this.shopId });

            this.$offline.updateTwInvoiceRolls(assignedRolls);
          } catch (e) {
            console.error(e);
          }
        })();
      }

      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = null;
      }
      this.doUpdate();
    } catch (e) {
      console.warn(e);
      this.$root.$store.commit("SET_ERROR", e.message);
    }
  }

  get hasAnyAutoMerge() {
    return (
      this.shopData?.autoMergeInPOS ||
      this.shopData?.autoMergeOrderedInPOS ||
      this.shopData?.autoMergeWhenCheckout ||
      this.shopData?.autoMergeOrderedWhenCheckout
    );
  }

  reset() {
    this.discounts = [];
    this.sections = [];
    this.cats = [];
    this.subCats = [];
    this.products = [];
    this.productDict = {};
    this.shopData = null;
    this.shopGroup = null;
    flushCached(this);
  }

  async reload() {
    try {
      this.reset();
      await this.loadShopData();
      await this.$tableManager.reloadAll();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  localSettings: Record<string, any> = {};

  get localOptions() {
    const defaultValues = {
      autoShowInvoice: false,
      autoShowPendingPayment: false,
      showProductImage: true,
      fullScreenProductImage: false,
      showProductCode: false,
      showProductStock: true,
      showProductPrice: true,

      autoCombineSameProduct: true,

      showSummaryBreakdown: false,
      showCategoryBreakdown: false,
      ensureReadyWhenFinish: true,
      showProductOrderTime: false,
      fontScale: 0,
      useDefaultsForNormalItem: false,
      useDefaultsForSetItem: false,

      muteSoundForIncomingOrder: false,

      cartPickerGridCount: 8,
      cartPickerGridFontSize: 16,

      autoPickFirstPayment: true,
      newOrderType: "followLast" as "followLast" | "dineInNoTable" | "takeAway",
      posMode: "auto" as "auto" | "tablet" | "mobile",
      posURL: isBBPOS ? "http://localhost:8080" : "",
      posUser: "",
      posPass: "",

      allinpayURL: "",
      adyenPos: "",
      mypay: "",

      hideFloorPlan: false,

      enableSecondScreen: false,
      secondScreenCfg: null,
      secondScreenRotate: 0,
      secondScreenPrimaryLocale: null,
      secondScreenSecondaryLocale: null,

      tableDontAutoPrintReceiptAll: false,
      productSort: "default" as "default" | "alphabetFirst" | "productSort" | "orderFirst",
      productDisplay: "textOnly" as "textOnly" | "smallPic" | "fullPic" as string,

      remoteFontScale: 0,
      remoteRotate: 0,
      remoteShowWaterBarName: true,

      printerOptions: {} as Record<string, any>,
      tableView: {} as Record<string, any>,

      kdsCols: Math.max(1, Math.floor(window.innerWidth / 350)),
      kdsRows: Math.max(1, Math.floor(window.innerHeight / 400)),
      kdsMode: "order" as "order" | "product",
      kdsOrderTypes: this.orderTypes as string[],
      kdsStatusTypes: ["normal", "warn", "urgent"] as string[],
      kdsWaterbar: null as string,
      kdsKitchen: null as string,
      kdsShowOptionName: false as boolean,
      kdsBeepWhenNewOrder: false as boolean,
      kdsShowOrderNumber: false as boolean,
      kdsShowShortnameDisable: false as boolean,

      exportNested: false,
      exportFlatten: false,

      orderingDisplay: "order",
      simpleOrderingFlow: false,
      onlyShowProductFullName: true,
      handheldPaymentEnabled: false,

      octopus: null as OctopusConfig,
      bbmsl: null as BBMSLConfig,
      hase: null as HASEConfig,

      turncloud: null as TurnCloudConfig,
      razer: null as RazerPayConfig,
      simplePaymentIntegration: null as SimplePaymentIntegrationConfig,
      autoCashBox: false,
      noOpenCashBox: false,
      callTicketVolume: 100,
      callTicketRate: 10,
      callTicketCount: 1,
      callTicketVoice: "",

      handheldRecordOptions: ["all", "all", "byUpdateTime", ["ongoing", "done", "cancelled", "void", "toPay"]],

      methodConfigs: {} as Record<string, any>,
      queueingKioskPassword: "",
      callTicketSSML: false,
      printerActive: false,
      queueingKioskNumberOfTicketWaiting: true,
      queueingKioskShowFullScreen: true,
      queueingKioskShowLogo: true,
      queueingKioskShowTicketsSection: true,
      queueingKioskShowBanner: true,
      queueingKioskShowNumInputSection: true,
      numberOfTicketWaiting: false,

      holdTriggerTime: null,
    };
    const keyList = Object.keys(defaultValues);

    const options: typeof defaultValues = {} as any;
    const self = this;
    Object.defineProperties(
      options,
      Object.fromEntries(
        keyList.map(key => [
          key,
          {
            get() {
              return self.localSettings?.[key] ?? defaultValues[key];
            },
            set(v) {
              Vue.set(self.localSettings, key, v);
              self.storage.setJson("settings", self.localSettings);
            },
          },
        ]),
      ),
    );
    return options;
  }

  updateLocalOption(path: string, value: any) {
    let cur = this.localSettings;
    const keys = path.split(".");
    const lastKey = keys.pop();
    for (let key of keys) {
      if (!cur[key]) {
        Vue.set(cur, key, {});
      }
      cur = cur[key];
    }
    Vue.set(cur, lastKey, value);
    this.storage.setJson("settings", this.localSettings);
  }

  cacheSettings: Record<string, any> = {};

  get cacheOptions() {
    const defaultValues = {
      lastSession: null as string,
    };
    const keyList = Object.keys(defaultValues);

    const options: typeof defaultValues = {} as any;
    const self = this;
    Object.defineProperties(
      options,
      Object.fromEntries(
        keyList.map(key => [
          key,
          {
            get() {
              return self.cacheSettings?.[key] ?? defaultValues[key];
            },
            set(v) {
              Vue.set(self.cacheSettings, key, v);
              if (!self.shopId) return;
              localStorage[`cache/${self.shopId}`] = JSON.stringify(self.cacheSettings);
            },
          },
        ]),
      ),
    );
    return options;
  }

  _connectScreenPromise: Promise<boolean> = null;

  async connectScreen(reconnect?: boolean) {
    if (this._connectScreenPromise) return this._connectScreenPromise;
    const promise = (this._connectScreenPromise = this.connectScreenInner(reconnect));
    promise.finally(() => {
      this._connectScreenPromise = null;
    });
    return promise;
  }

  async connectScreenInner(reconnect?: boolean) {
    if (reconnect) {
      await this.disconnectScreen();
    }
    if (this.localOptions.enableSecondScreen) {
      this.initUsbListener();
      if (!this.secondScreen) {
        if (reconnect || !this.localOptions.secondScreenCfg) {
          try {
            this.secondScreen = await createScreenWithDialog(this, "/posScreen", this.$config.castId);
            if (!this.secondScreen) return false;
            this.closeScreenDialog();
            this.secondScreen.on("loaded", this.secondScreenLoaded);
            this.secondScreen.on("close", this.onDisconnectScreen);
            this.localOptions.secondScreenCfg = this.secondScreen.opts;
            this.$emit("updateScreen", this.secondScreen);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        } else {
          try {
            this.secondScreen = await createScreen(
              "/posScreen",
              this.$config.castId,
              this.localOptions.secondScreenCfg,
            );
            if (!this.secondScreen) return false;
            this.closeScreenDialog();
            this.secondScreen.on("loaded", this.secondScreenLoaded);
            this.secondScreen.on("close", this.onDisconnectScreen);
            this.$emit("updateScreen", this.secondScreen);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        }
      }
    }
  }

  secondScreenLoaded() {
    this.syncShop();
    this.syncFontScale();
    this.syncSecondScreenLocale();
  }

  onDisconnectScreen() {
    this.disconnectScreen(true);
  }

  closeScreenDialog() {
    this.$root.$emit("modalResult", { result: false, id: this._screenDialog });
  }

  _screenDialog: any = null;

  _initUsbListener = false;
  initUsbListener() {
    if (this._initUsbListener) return;
    this._initUsbListener = true;
    initUsb(this.$root);
    this.$root.$on("deviceAttach", this.onDeviceAttach);
  }

  onDeviceAttach(device: UsbDeviceInfo) {
    if (!this.localOptions.enableSecondScreen || this.secondScreen || !this.localOptions.secondScreenCfg) return;
    // auto reconnect imin screen
    if (device.vendorId === 10182 && device.productId === 38656) {
      setTimeout(() => {
        this.connectScreen();
      }, 3000);
    }
  }

  async disconnectScreen(autoDisconnect = false) {
    if (this.secondScreen) {
      this.secondScreen.off("loaded", this.secondScreenLoaded);
      this.secondScreen.off("close", this.onDisconnectScreen);
      await this.secondScreen.close();
      this.secondScreen = null;
      this.$emit("updateScreen", null);

      if (autoDisconnect) {
        this.closeScreenDialog();
        while (
          await this.$confirm(
            this.$t("tableView.secondScreenDisconnected") as string,
            {
              confirm: this.$t("tableView.reconnectScreen"),
              persistent: true,
            },
            s => {
              this._screenDialog = s;
            },
          )
        ) {
          this._screenDialog = null;
          if (await this.connectScreen()) {
            break;
          }
        }
        this._screenDialog = null;
      }
    }
  }

  syncShop() {
    if (this.secondScreen) {
      this.secondScreen.queue.ns("posScreen").call("setShop", this.shopData);
    }
  }

  syncFontScale() {
    if (this.secondScreen) {
      this.secondScreen.queue.ns("posScreen").call("setFontScale", this.getScale(this.localOptions.remoteFontScale));
    }
  }

  syncSecondScreenLocale() {
    if (this.secondScreen) {
      this.secondScreen.queue.ns("posScreen").call("setLocale", {
        locale: this.localOptions.secondScreenPrimaryLocale || this.$i18n.locale,
        secondaryLocale: this.localOptions.secondScreenSecondaryLocale,
      });
    }
  }

  @Watch("displayOptions.productSort")
  resortProducts() {
    this.products = this.sortProducts(this.products);
  }

  sortProducts(products: ProductType[]) {
    return _.orderBy(
      products,
      this.localOptions.productSort === "alphabetFirst"
        ? [it => this.$en(it.name), "code", "order"]
        : this.localOptions.productSort === "orderFirst"
          ? ["order", "code", it => this.$en(it.name)]
          : ["code", it => this.$en(it.name), "order"],

      ["asc", "asc"],
    );
  }

  permissionEnabled(permissions: string[]) {
    return (
      this.shopData?.enableShopPermission &&
      permissions.some(permission => this.shopData?.shopPermissions?.filter(p => p === permission).length)
    );
  }

  hasPermission(permissions: string[]) {
    if (!this.permissionEnabled(permissions)) return true;
    const enabledPermissions = permissions.filter(permission => this.shopData?.shopPermissions.includes(permission));
    return enabledPermissions.every(permission => this.staff?.permissions?.filter(p => p === permission).length);
  }

  async checkPermission(permissions: string[], persistent?: boolean) {
    await this.loadShopTask;
    if (!this.permissionEnabled(permissions)) return this.staff;
    if (this.hasPermission(permissions)) {
      if (
        !this.shopData?.enablePermissionLock ||
        (this.staff && Date.now() - this.staffLoginTime < this.shopData?.permissionLockTimeout * 1000)
      ) {
        this.staffLoginTime = Date.now();
        this.staffIdle = false;
        return this.staff;
      } else {
        const staff = await this.$openDialog(
          import("~/components/dialogs/StaffCodeDialog.vue"),
          { permissions },
          {
            maxWidth: "380px",
            persistent,
          },
        );

        if (!staff) return false;

        this.staff = staff;
        this.staffLoginTime = Date.now();
        this.staffIdle = false;

        return staff;
      }
    } else {
      const staff = await this.$openDialog(
        import("~/components/dialogs/StaffCodeDialog.vue"),
        { permissions },
        {
          maxWidth: "380px",
          persistent,
        },
      );

      if (!staff) return false;

      this.staffIdle = false;

      return staff;
    }
  }

  hasStaffRole(role: string) {
    return (
      this.$store.state.user.role === "admin" ||
      this.$store.state.user.role === "shop" ||
      this.$store.state.user.roles?.includes?.(role)
    );
  }

  hasStaffOptionalRole(role: string) {
    return (
      this.$store.state.user.role === "admin" ||
      this.$store.state.user.roles?.includes?.(role) ||
      (this.shopData?.permissions?.[role] ?? true)
    );
  }

  remoteFontDiv = 10;

  getScale(value) {
    return (value < 0 ? 100 - (Math.abs(value) / this.remoteFontDiv) * 80 : 100 + value * 10).toFixed(0);
  }

  get currentFontSize() {
    return this.getScale(this.localOptions.fontScale ?? 0);
  }

  get currentCartPickerGridCount() {
    return this.localOptions.cartPickerGridCount ?? Math.max(4, Math.floor((window.innerWidth - 400) / 125));
  }

  get currentCartPickerGridFontSize() {
    return this.localOptions.cartPickerGridFontSize ?? 16;
  }

  getIngredientInfo(id: any) {
    return this.ingredientDict[`${id}`];
  }

  getIngredientName(id: any) {
    return this.ingredientDict[`${id}`]?.name ?? null;
  }

  getIngredientUnit(id: any) {
    return this.ingredientDict[`${id}`]?.unit ?? "";
  }

  setStore() {
    this.localSettings = this.storage.getJson("settings") || {};
  }

  async updateStore() {
    if (!this.device) return;
    try {
      await this.$feathers.service("posDevices/update").create({
        localStorage: this.storage.store,
        printerActive: this.localOptions.printerActive,
        sessionId: this.sessionId,
      } as any);
    } catch (e) {
      console.warn(e);
    }
  }

  updateDevice(device: FindType<"posDevices"> & { sessionId?: string }) {
    if (!checkID(device, this.device)) return;

    if (this.$store.state.cast) {
      if (this.$store.state.cast === "ods" && !checkID(device?.odsSetting, this.device?.odsSetting)) {
        Vue.nextTick(this.customAuth);
      }
      this.device = device;
      return;
    }

    if (!this.cachedId) return;
    this.device = device;
    localStorage[`cachedDevice`] = JSON.stringify(device);
    localStorage[`cachedSettings/${this.cachedId}`] = JSON.stringify(device.localStorage || {});
    if (device.sessionId === this.sessionId) return;
    this.loadStoreAndSave();
    this.$offline.offlineEnabled = device.offlineActive || false;
    this.$offline.updateOfflineStat();
  }

  async applyDeviceInfo() {
    try {
      if (await authSupported()) {
        await this.loadShopTask;
        await updateLogin({
          name: this.$store.state.user?.name,
          username: this.$store.state.user?.email,
          userId: this.$store.state.user?._id,
          serverName: this.$config.appName || "boxsRestaurant",
          shopName: this.$td(this.shopData?.name),
          shopId: this.shopData?._id,
          deviceId: this.device?._id,
          deviceName: this.$td(this.device?.name),
          deviceShortId: this.device?.shortId,
          serverVersion: this.localBuildInfo?.CI_PIPELINE_ID,
          appName: (this.$i18n as any).appName,
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  get localBuildInfo() {
    if (process.env.BUILD_INFO) {
      try {
        return JSON.parse(process.env.BUILD_INFO);
      } catch (e) {}
    }
    return null;
  }

  async onCommand(command: FindType<"posCommands">) {
    if (!this.device || command.device !== this.device._id) return;
    switch (command.command) {
      case "reload":
        location.reload();
        break;
      case "octopus": {
        const manager = this.$paymentManager.getOctopus(true);
        switch (command.data?.type) {
          case "manage": {
            manager.openSettings();
            break;
          }
          case "upload": {
            await manager.scheduleHousekeeping(async () => {
              await manager.upload();
            });
            break;
          }
          case "download": {
            await manager.scheduleHousekeeping(async () => {
              await manager.download();
            });
            break;
          }
          case "restart": {
            await manager.scheduleHousekeeping(async () => {
              await manager.reset(true);
            });
            break;
          }
          case "uploadLogs": {
            await manager.syncLogs();
            break;
          }
        }
        break;
      }
      case "enableDebug": {
        this.enableDebug();
        break;
      }
    }
  }

  _debugging = false;
  enableDebug() {
    if (this._debugging) return;
    this._debugging = true;
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/eruda";
    document.body.appendChild(script);
    script.onload = () => {
      //@ts-ignore
      eruda.init();
    };
  }

  loadStoreAndSave() {
    if (!this.cachedId) return;
    const cacheJson = localStorage[`cache/${this.cachedId}`];
    if (cacheJson) {
      try {
        this.cacheSettings = JSON.parse(localStorage[`cache/${this.cachedId}`]);
      } catch (e) {
        console.warn(e);
      }
    }

    if (Object.keys(this.device.localStorage || {}).length === 0) {
      // try load old config
      try {
        this.storage.loadStore(
          (this.device.localStorage = {
            settings: JSON.parse(localStorage["settings"] || "{}"),
            printers: JSON.parse(localStorage["printers"] || "[]"),
          }),
        );
        this.updateStore();
      } catch (e) {
        console.warn(e);
      }
    }
    this.storage.loadStore(this.device.localStorage);
    localStorage[`cachedSettings/${this.cachedId}`] = JSON.stringify(this.storage.store);
  }

  async onConnected() {
    if (this.$offline?.offline) {
      // if offline is enabled, wait for connection stable event
      return;
    }
    if (await this.loadDevice()) {
      localStorage[`cachedDevice`] = JSON.stringify(this.device);
      localStorage[`cachedSettings/${this.cachedId}`] = JSON.stringify(this.device.localStorage || {});
      this.storage.setStore(this.device?.localStorage || {});
    }
  }

  @Watch("$store.state.tableMode")
  async loadDevice() {
    if (this.$store.state.cast) {
      return;
    }
    try {
      let resp: FindType<"posDevices/auth">;
      try {
        resp = await this.$feathers.service("posDevices/auth").create({
          token: await sign({ shop: this.shopId }, {}),
          sessionId: this.sessionId,
          userAgent: navigator.userAgent,
          posMode: this.$store.state.tableMode,
        });
      } catch (e) {
        if (e.data?.error?.name === "TokenExpiredError") {
          resp = await this.$feathers.service("posDevices/auth").create({
            token: await sign({ shop: this.shopId }, {}, e.data.error.time),
            sessionId: this.sessionId,
            userAgent: navigator.userAgent,
            posMode: this.$store.state.tableMode,
          });
        }
      }

      if (resp) {
        if (!this.$offline?.offline) {
          this.posAuthorized = resp.posAuthorized;
          this.updateDeviceLimit();
        }
        const deviceChanged = !checkID(this.device, resp.device);
        this.device = resp.device || null;
        if (deviceChanged) {
          const server = getPrinterServer();
          if (server) {
            server.reset().catch(console.warn);
          }
        }
        this.applyDeviceInfo();
        return true;
      }
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  @Watch("staff")
  async staffChanged() {
    await this.$feathers.service("actionLogs").create({
      staff: this.staffId,
      type: this.staff ? "others/loginStaff" : "others/logoutStaff",
    });
  }

  get orderTypes() {
    return [
      this.$shop?.shopData?.openDineInNoTable ? ["dineInNoTable"] : [],
      this.$shop?.shopData?.openTakeAway ? ["takeAway"] : [],
      !this.$shop?.shopData?.hideFloorPlan ? ["dineIn"] : [],
    ].flat();
  }

  get selfOrder(): string {
    return this.getShopUrl(this.shopData);
  }

  getShopUrl(shopData: ShopType): string {
    const domain = shopData?.shopGroup?.domain;
    if (domain) {
      if (domain.includes(".") || !this.$config.selfOrder) {
        return `https://${domain}`;
      } else {
        const url = new URL(this.$config.selfOrder);
        const rootDomain = url.hostname;
        return `https://${domain}-${rootDomain}`;
      }
    }
    return this.$config.selfOrder;
  }

  normalizedDay(date: Date, shop?: FindType<"shops">) {
    if (!shop) {
      shop = this.shopData as any;
    }
    let openHour = shop?.openTime || "00:00";

    const time = openHour?.split?.(":");
    const hh = isNaN(+time?.[0]) ? 0 : +time?.[0];
    const mm = isNaN(+time?.[1]) ? 0 : +time?.[1];

    const now = moment();
    const day = moment().startOf("day").add(hh, "hours").add(mm, "minutes");
    const cur = moment(date).add(hh, "hours").add(mm, "minutes");

    if (day.isAfter(now)) {
      return cur.subtract(1, "day").toDate();
    }
    return cur.toDate();
  }

  async dismissNotification(notification: FindType<"shopNotifications">) {
    const notiIdx = this.notifications.findIndex(it => it._id === notification._id);
    if (notiIdx !== -1) {
      this.notifications.splice(notiIdx, 1);
    }
    await this.$feathers.service("shopNotifications").patch(notification._id, {
      read: true,
    });
  }

  formatJobName(shopPrinter: FindType<"shopPrinters">) {
    if (!shopPrinter.type) return "";
    if (shopPrinter.name) return shopPrinter.name;
    if (shopPrinter.printerType === "template") {
      return this.$t(`enum.pages.shopPrinters.type.${shopPrinter.type}`) as string;
    }
    if (shopPrinter.type === "table-kitchen") {
      return this.kitchenPrinters.find(it => it._id === getID(shopPrinter.kitchen))?.name || "";
    }
    if (shopPrinter.type === "table-waterBar") {
      return this.waterBars.find(it => it._id === getID(shopPrinter.waterBar))?.name || "";
    }
    return this.$t(`enum.pages.shopPrinters.type.${shopPrinter.type}`) as string;
  }

  async queuePrintJobs(job: AllPrintJobData, device?: String, filter?: PrintJobBaseData["filter"]) {
    const printJob: Partial<SharePrintJob> = {
      type: job.jobType,
      job,
      shop: this.shopId,
      shopGroup: this.shopData?.shopGroup?._id,
      actionSource: job.actionSource,
      orderType: job.session?.type,
      filter: job.filter,
      sessionName: job.session?.sessionName,
    };

    const jobToPrints = Object.entries(this.shopPrinterDict).filter(([k, v]) => {
      if (v.disabled) return false;
      if (v.printerType === "local" && !v.device) return false;
      if (v.printerType === "cloud" && !v.printer) return false;
      if (v.printerType === "template") return false;
      if (job.filter) {
        for (let [key, value] of Object.entries(job.filter)) {
          if (v[key] !== value) {
            return false;
          }
        }
      }
      if (v.type !== printJob.type) {
        return false;
      }
      if (printJob.actionSource && v.source?.length && !v.source.includes(printJob.actionSource)) {
        return false;
      }
      if (printJob.orderType && v.orderType?.length && !v.orderType.includes(printJob.orderType)) {
        return false;
      }
      if (v.kitchen && getID(v.kitchen) !== filter.kitchen) {
        return false;
      }
      if (v.waterBar && getID(v.waterBar) !== filter.waterBar) {
        return false;
      }

      if (job.jobType === "table-kitchen" || job.jobType === "table-waterBar") {
        switch (job.type) {
          case "new":
            if (v.jobOptions?.printNew === false) return false;
            break;
          case "move":
            if (v.jobOptions?.printMove === false) return false;
            break;
          case "cancel":
            if (v.jobOptions?.printCancel === false) return false;
            break;
          case "reprint":
            if (v.jobOptions?.printReprint === false) return false;
            break;
          case "edit":
            if (!v.jobOptions?.printEdit) return false;
            break;
        }
      }

      return true;
    });

    if (jobToPrints.length) {
      return await Promise.all(
        jobToPrints.map(async ([k, v]) => {
          return await this.$feathers.service("sharePrintJobs").create({
            ...printJob,
            shopPrinter: k,
            ...(v.printerType === "local"
              ? {
                  device: v.device,
                  localPrinter: v.localPrinter,
                }
              : v.printerType === "cloud"
                ? {
                    cloudPrinter: v.printer,
                  }
                : {}),
          });
        }),
      );
    }

    return [];
  }

  onDeviceRemoved(data) {
    this.posAuthorized = false;
    this.updateDeviceLimit(data.operator);
  }

  _deviceLimitDialog: any;
  updateDeviceLimit(operator?: any) {
    const finalAuthorized = this.posAuthorized || this.$offline.offline || !this.$store.state.tableMode;
    if (finalAuthorized) {
      if (this._deviceLimitDialog) {
        this.$root.$emit("modalResult", { result: false, id: this._deviceLimitDialog });
        this._deviceLimitDialog = null;
      }
    } else {
      if (this._deviceLimitDialog) {
        this.$root.$emit("updateDialog", {
          id: this._deviceLimitDialog,
          props: {
            operator,
          },
        });
        return;
      }
      this.$openDialog(
        import("~/components/DeviceLimitDialog.vue"),
        {
          operator,
        },
        {
          maxWidth: "500px",
          persistent: true,
        },
        dialogId => {
          this._deviceLimitDialog = dialogId;
        },
      ).then(() => {
        this._deviceLimitDialog = null;
      });
    }
  }

  editingOrder = false;
  async editOrder(item: any) {
    if (this.editingOrder) return;
    const dark = this.$store.state.dark;
    let session: TableSession;
    try {
      const sessionData = await this.$feathers.service("tableSessions").get(item._id);
      if (sessionData.checkoutFrom && sessionData.status === "toPay") {
        this.$store.commit("SET_ERROR", this.$t("tableView.singleItemCheckout.notAllow"));
        return;
      }
      this.editingOrder = true;
      if (!this.$store.state.user.shop) {
        await this.$shop.adminImpersonateShop(getID(sessionData.shop));
      }
      session = new TableSession({
        parent: this,
      });
      await session.init(sessionData, true);
      session.startSubscribe();
      this.$store.commit("SET_THEME", true);
      await this.$openDialog(
        import("~/components/table/orderSystem/cart.vue"),
        {
          session,
          fromOrder: true,
        },
        {
          contentClass: "h-full",
        },
      );

      for (let [k, v] of Object.entries(session.item)) {
        Vue.set(item, k, v);
      }
      session.$destroy();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.$store.commit("SET_THEME", dark);
      this.editingOrder = false;
      if (session) {
        session.$destroy();
      }
    }
  }

  availableTimeConditions: FindType<"timeConditions">[] = [];
  availableTimeConditionDict: Record<string, FindType<"timeConditions">> = {};
  _timeConditionCache: {
    timeCondition: FindType<"timeConditions">;
    conds: ((ts: string, date: string, weekday: number) => boolean)[];
    prev: boolean;
  }[];
  updateTimeout: any;

  doUpdate() {
    this.updateTimeout = null;

    const time = moment().startOf("minute");
    const ts = time.format("HH:mm");
    const date = time.format("YYYY-MM-DD");
    const weekday = time.get("isoWeekday");

    {
      let dirty = false;
      for (let cache of this._timeConditionCache) {
        const prev = cache.prev;
        cache.prev = cache.conds.length ? cache.conds.some(cond => cond(ts, date, weekday)) : true;
        if (prev !== cache.prev) {
          dirty = true;
        }
      }

      if (dirty) {
        this.availableTimeConditions = this._timeConditionCache
          .filter(cache => cache.prev)
          .map(cache => cache.timeCondition);
        this.availableTimeConditionDict = Object.fromEntries(
          this.availableTimeConditions.map(timeCondition => [getID(timeCondition), timeCondition]),
        );
      }
    }

    const nextMinute = time.add(1, "minute").toDate();
    let wait = Date.now() - nextMinute.getTime();
    wait = Math.max(0, wait);

    this.updateTimeout = setTimeout(this.doUpdate, wait);
  }
}

class ShopStorage extends EventEmitter implements Storage {
  constructor() {
    super();
  }

  get length() {
    return Object.keys(this.store).length;
  }

  store: Record<string, any> = {};

  clear() {
    this.store = {};
    this.emit("updateStore");
  }
  getItem(key: string) {
    const v = this.store[key];
    return typeof v === "object" ? JSON.stringify(v) : v;
  }
  getJson(key: string) {
    return this.store[key];
  }
  key(index: number) {
    return Object.keys(this.store)[index];
  }
  removeItem(key: string) {
    delete this.store[key];
    this.emit("updateStore");
  }
  setItem(key: string, value: string) {
    try {
      const v = JSON.parse(value);
      this.store[key] = v;
      this.emit("updateStore");
      return;
    } catch (e) {}
    this.store[key] = value;
    this.emit("updateStore");
  }
  setJson(key: string, value: any) {
    this.store[key] = value;
    this.emit("updateStore");
  }

  setStore(store: any) {
    this.store = store;
    this.emit("setStore");
  }

  loadStore(store: any = {}) {
    if (JSON.stringify(this.store) !== JSON.stringify(store)) {
      this.setStore(store);
    }
  }

  loadStoreString(store: string) {
    if (!store) return;
    try {
      if (JSON.stringify(this.store) !== store) {
        this.setStore(JSON.parse(store));
      }
    } catch (e) {}
  }

  static create() {
    const storage = new ShopStorage();
    const proxy = new Proxy(storage, {
      get(target, p, receiver) {
        if (p in target || typeof p !== "string") {
          return Reflect.get(target, p, receiver);
        } else {
          return target.getItem(p);
        }
      },
      set(target, p, newValue, receiver) {
        if (typeof p !== "string") {
          return Reflect.set(target, p, newValue, receiver);
        } else {
          target.setItem(p, newValue);
          return true;
        }
      },
    });
    return proxy;
  }
}

declare module "vue/types/vue" {
  export interface Vue {
    $shop: Shop;
  }
}

if (!Vue.prototype.hasOwnProperty(`$shop`)) {
  Object.defineProperty(Vue.prototype, "$shop", {
    get(this: Vue) {
      return (
        (<any>this.$root.$options).$shop || ((<any>this.$root.$options).$shop = new Shop(getOptions(this.$root)).init())
      );
    },
  });
}
