import { PrintJob } from "../printJob";
import { WrappedContext, BitmapOpts } from "../common";
import type { PrinterBaseConf } from "../printers/baseConf";
import { TextLine, RawLine } from "../printSequence/rawLine";
import { BaseRenderer } from "../printSequence/raster/base";
import { SkiaRenderer } from "../printSequence/raster/skia";
import { CanvasRenderer } from "../printSequence/raster/canvas";

export const fonts = {
  "1": [10, 12],
  "2": [12, 20],
  "3": [16, 24],
  "4": [24, 32],
  "5": [32, 48],
  "6": [14, 19],
  "7": [14, 25],
  "8": [21, 27],
  "9": [12, 24],
  "MSYH.TTF": [12, 24],
  "TST24.BF2": [12, 24],
  "TSS24.BF2": [12, 24],
  K: [12, 24],
} as Record<string, [number, number]>;

export function parseFont(font: string) {
  if (font.endsWith("px")) {
    const [size, height] = font.split(",");
    return [+size.slice(0, -2) / 2, height ? +height.slice(0, -2) : +size.slice(0, -2) * 1.25];
  } else {
    return fonts[font] || [12, 24];
  }
}

export abstract class LabelSequenceBase {
  chunks: Buffer[] = [];
  dpi = 8;
  _stroke = false;

  _curWidth = 40;
  _curHeight = 30;

  debugData?: any;
  debugLoc?: any;

  constructor(
    public context: WrappedContext,
    public printer: PrinterBaseConf,
  ) {
    this.initInner();
  }

  get chineseFont(): string {
    return this.printer?.conf?.opts?.chineseFont ?? "TST24.BF2";
  }

  get codePage() {
    return this.printer?.conf?.opts?.codePage ?? "gbk";
  }

  get bitmapMode(): string {
    return this.printer?.conf?.opts?.bitmapMode ?? false;
  }

  initInner() {}

  finish() {
    this._disposeContext();
  }

  getJob(name?: string, id?: string): PrintJob {
    this.finish();
    return new PrintJob(name, Buffer.concat(this.chunks), id, {
      type: "label",
    });
  }

  getText(text: string): Buffer | number[] {
    return Buffer.from(text);
  }

  decodeText(buf: Buffer) {
    return buf.toString();
  }

  raw(array: number[] | Buffer | Uint8Array) {
    this.chunks.push(Buffer.from(new Uint8Array(array)));
    return this;
  }

  cmd(line: string) {
    this.raw(Buffer.from(line + "\n"));
    return this;
  }

  size(w: number, h: number) {
    console.warn("Not supported: size");
    return this;
  }

  gap(w: number, h: number) {
    console.warn("Not supported: gap");
    return this;
  }

  feed(n: number) {
    console.warn("Not supported: feed");
    return this;
  }

  stroke(s: boolean) {
    this._stroke = s;
    return this;
  }

  _r = 0;

  rotate(r: number) {
    this._r = r;
    return this;
  }

  direction(n: number) {
    console.warn("Not supported: direction");
    return this;
  }

  page() {
    return this;
  }

  _align: "left" | "center" | "right" = "left";
  align(align: "left" | "center" | "right") {
    this._align = align;
  }

  rect(x: number, y: number, w: number, h: number) {
    console.warn("Not supported: rect");
    return this;
  }

  get seq() {
    return this;
  }

  async textRaw(x: number, y: number, text: string, font = "1", sizeW = 1, sizeH = 1, r = 0) {
    console.warn("Not supported: textRaw");
    return this;
  }

  lastX = 0;
  lastY = 0;

  async text(text: string, x: number, y: number, width = 0, align = "left", line = 1, font = "1", size = 1) {
    if (this.bitmapMode) {
      const ctx = this._prepareContext();
      if (ctx) {
        await ctx.prepareContext();

        const sizes = parseFont(font);
        const fontSize = `${size}`.endsWith("px") ? parseFloat(`${size}`.split(",")[1]) : sizes[1] * parseFloat(`${size}`);

        await ctx.setFont(this.fontFamily, fontSize, true, {
          maxLines: line,
        });

        const finalWidth  = width ? width : this._curWidth * this.dpi - x;

        const drawn = await ctx.drawText(text, 0, finalWidth, fontSize, 1, align as any, undefined, undefined, undefined);
        const imageData = ctx.getImageData(0, 0, drawn.x, drawn.height);

        if(imageData) {
          await this.printImageData(x, y, imageData.data, imageData.width, imageData.height);
        }

        this.lastX = x;
        this.lastY = y + drawn.height;
        return this;
      }
    }

    let lines: RawLine[];
    const fontSize = parseFont(font);
    const hh = Math.floor(fontSize[1] / 2);
    const textLine = new TextLine(<any>this, text, {
      align,
    });
    let addEllipsis = false;
    if (width) {
      const wordPerLine = Math.floor(width / fontSize[0]);
      const oriLines = textLine.split(wordPerLine);
      lines = oriLines.slice(0, line);
      if (oriLines.length > line) {
        addEllipsis = true;
      }
    } else {
      lines = textLine.split(text.length * 2);
    }

    for (let i = 0; i < lines.length; i++) {
      const it = lines[i];
      let ox = 0,
        oy = (this._r === 90 ? -i : i) * fontSize[1];
      if (width) {
        switch (it.align) {
          case "center":
            ox = ((width - it.buf.length * fontSize[0]) / 2) | 0;
            break;
          case "right":
            ox = width - it.buf.length * fontSize[0];
            break;
        }
        switch (this._r) {
          case 90: {
            const v = oy;
            oy = ox;
            ox = v;
            break;
          }
          case 270: {
            const v = oy;
            oy = ox;
            ox = v;
            break;
          }
        }
      }
      let textLine = this.decodeText(it.buf);
      if (i === lines.length - 1 && addEllipsis) {
        textLine = textLine.slice(0, -3) + "...";
      }
      await this.textRaw(x + ox, y + oy, textLine, font, size, size, this._r);
      this.lastX = x + ox;
      this.lastY = y + (this._r === 90 ? -(i + 1) : i + 1) * fontSize[1];
      if (this._stroke) {
        switch (this._r) {
          case 90:
            this.rect(x + ox, y + oy + hh + 1, +size * 2, it.buf.length * fontSize[1]);
            break;
          case 270:
            this.rect(x + ox, y + oy + hh + 1, +size * 2, it.buf.length * fontSize[1]);
            break;
          default:
            this.rect(x + ox, y + oy + hh + 1, it.buf.length * fontSize[1], +size * 2);
            break;
        }
      }
    }

    return this;
  }

  async vtext(text: string, x: number, y: number, font = "1", size = 1) {
    console.warn("Not supported: vtext");
    return this;
  }

  qrcode(text: string, x: number, y: number, ecc = "H", width = 4) {
    console.warn("Not supported: qrcode");
    return this;
  }

  barcode(text: string, x: number, y: number, height = 10, type: "128" | "ean8" | "ean13" = "ean13", width = 2) {
    console.warn("Not supported: barcode");
    return this;
  }

  reset() {
    this._stroke = false;
    return this;
  }

  /**
   * Print image at url
   * @param url image url
   * @param width size
   * @param hiRes high resolution
   * @returns
   */
  async printImage(x: number, y: number, url: string, width: number) {
    console.warn("Not supported: printImage");
    return this;
  }

  async printImageTagInner(x: number, y: number, opts: BitmapOpts, url: string, width: number) {
    return await this.printImage(x, y, url, width);
  }

  async printImageTag(x: number, y: number, tag: string, url?: string) {
    const opts = this.printer?.bitmapList?.[tag];
    if (!opts) {
      console.warn(`Invalid image tag: ${tag}`);
      if (url) {
        return await this.printImage(x, y, url, this.dpi * 6);
      }
    }

    url = this.printer.resolveBitmap(tag, url, this.context);
    return await this.printImageTagInner(x, y, opts, url, opts?.width ?? this.dpi * 6);
  }

  async printImageData(x: number, y: number, buffer: Uint8ClampedArray, width: number, height: number) {
    console.warn("Not supported: printImageData");
    return this;
  }

  print(n: number) {
    console.warn("Not supported: print");
    return this;
  }

  counter(id: number, step: number, start: string) {
    console.warn("Not supported: counter");
    return this;
  }

  fontFamily = "'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Roboto', sans-serif";
  _renderer: BaseRenderer;

  protected _prepareContext() {
    if (!this._renderer) {
      if (this.printer?.fontsInfo && Object.keys(this.printer?.fontsInfo?.fonts || {}).length && (this.printer?.conf?.opts?.bitmapRenderer ?? "skia") === "skia") {
        this._renderer = new SkiaRenderer(this);
      } else if (typeof document !== "undefined") {
        this._renderer = new CanvasRenderer(this);
      }
    }
    return this._renderer;
  }

  protected _disposeContext() {
    if (this._renderer) {
      this._renderer.disposeContext();
      this._renderer = null;
    }
  }
}
