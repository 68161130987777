
import { Component, Vue } from "@feathers-client";

@Component
export default class Version extends Vue {


  get localBuildInfo() {
    if(process.env.BUILD_INFO) {
      try {
        return JSON.parse(process.env.BUILD_INFO);
      } catch(e) {
      }
    }
    return null;
  }

  get remoteBuildInfo() {
    if(this.$config.BUILD_INFO) {
      try {
        return JSON.parse(this.$config.BUILD_INFO);
      } catch(e) {
      }
    }
    return null;
  }

  get buildNumber() {
    return (this.remoteBuildInfo || this.localBuildInfo)?.CI_PIPELINE_ID ?? '-';
  }

  get versionNumber() {
    const slug = (this.remoteBuildInfo || this.localBuildInfo)?.CI_COMMIT_REF_SLUG || '';
    if(slug.startsWith('release-')) {
      return slug.substring('release-'.length).replace(/\-/g, '.');
    }
    if(slug.startsWith('feature-')) {
      return slug.substring('feature-'.length);
    }
    return (this.remoteBuildInfo || this.localBuildInfo)?.PACKAGE || '-';
  }

}
