
import { Component, Vue, Prop, Watch } from "@feathers-client";

@Component
export default class PopularProductPercentage extends Vue {
  @Prop({ type: Number, required: true, default: 0 }) previousValue!: number;
  @Prop({ type: Number, required: true, default: 0 }) latestValue!: number;

  get percentageChange() {
    if (!this.latestValue && !this.previousValue) {
      return
    }
    if (this.latestValue - this.previousValue === 0) {
      return 0;
    }
    const values = ((this.latestValue - this.previousValue) / this.previousValue) * 100;
    return parseFloat(values.toFixed(1));
  }
}
