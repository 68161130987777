
import { Component, Prop, Vue, mixins } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { LangType } from "domore-table";
import PrinterEditorEditBase from "./base";
import {
  PrinterTemplateNodeCol,
  PrinterTemplateNodeRow,
  PrinterTemplateNodeText,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeFeed,
  PrinterTemplateNodeCut,
  PrinterTemplateNodeBase,
  PrinterTemplateNodeFill,
  PrinterTemplateNodeTextLabel,
  PrinterTemplateNodeLabelText,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeInterpolationLabel,
  PrinterTemplateNodeLabelStaticText,
} from "pos-printer-template";

export interface DesignComponent {
  name: LangType;
  _id: string;
  place?: (component: DesignComponent) => void;
}

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorPicker extends mixins(PrinterEditorEditBase) {
  get designComponents() {
    const components: DesignComponent[] = [];

    if (this.type === "label") {
      switch (this.selectedNode.constructor) {
        case PrinterTemplateNodeLabelText:
        case PrinterTemplateNodeLabelStaticText:
        case PrinterTemplateNodeTextLabel:
        case PrinterTemplateNodeInterpolationLabel:
          components.push({
            name: { $t: "printerEditor.components.text" },
            _id: "PrinterTemplateNodeTextLabel",
            place: this.placeLabelStaticText,
          });
          break;
      }

      components.push({
        name: { $t: "printerEditor.components.textBlock" },
        _id: "PrinterTemplateNodeLabelText",
        place: this.placeLabelBlock,
      });
    } else {
      switch (this.selectedNode.constructor) {
        case PrinterTemplateNodeText:
        case PrinterTemplateNodeInterpolation:
        case PrinterTemplateNodeBlock:
        case PrinterTemplateNodeCol:
          components.push({
            name: { $t: "printerEditor.components.text" },
            _id: "PrinterTemplateNodeText",
            place: this.placeText,
          });
          break;
      }

      if (this.parentCol || this.selectedNode instanceof PrinterTemplateNodeRow) {
        components.push({
          name: { $t: "printerEditor.components.column" },
          _id: "PrinterTemplateNodeCol",
          place: this.placeCol,
        });
      }

      if (this.parentPreContainer) {
        components.push({
          name: { $t: "printerEditor.components.textBlock" },
          _id: "PrinterTemplateNodeBlock",
          place: this.placeBlock,
        });

        components.push({
          name: { $t: "printerEditor.components.row" },
          _id: "PrinterTemplateNodeRow",
          place: this.placeRow,
        });

        components.push({
          name: { $t: "printerEditor.components.cut" },
          _id: "PrinterTemplateNodeCut",
          place: this.placeTag,
        });

        components.push({
          name: { $t: "printerEditor.components.feed" },
          _id: "PrinterTemplateNodeFeed",
          place: this.placeTag,
        });

        components.push({
          name: { $t: "printerEditor.components.fill" },
          _id: "PrinterTemplateNodeFill",
          place: this.placeTag,
        });
      }
    }

    return components;
  }

  placeNode(component: DesignComponent) {
    if (component.place) {
      component.place(component);
    }
  }

  placeText() {
    switch (this.selectedNode.constructor) {
      case PrinterTemplateNodeText:
      case PrinterTemplateNodeInterpolation:
        this.insertAfter(PrinterTemplateNodeText.createFromText("Text"));
        break;
      case PrinterTemplateNodeBlock:
      case PrinterTemplateNodeCol:
        this.insertChild(PrinterTemplateNodeText.createFromText("Text"));
        break;
    }
  }

  placeCol() {
    const node = PrinterTemplateNodeCol.createFromText("Text");
    if (this.selectedNode instanceof PrinterTemplateNodeRow) {
      this.insertChild(node, [0]);
    } else {
      if (!this.parentCol) return;
      this.insertAfter(node, this.parentCol, [0]);
    }
  }

  placeRow() {
    const node = PrinterTemplateNodeRow.createFromText(["Text", "Text"]);
    if (!this.parentPreContainer) return;
    this.insertAfter(node, this.parentPreContainer, [0, 0]);
  }

  placeBlock() {
    const node = PrinterTemplateNodeBlock.createFromText("Text");
    if (!this.parentPreContainer) return;
    this.insertAfter(node, this.parentPreContainer, [0]);
  }

  placeTag(component: DesignComponent) {
    if (!this.parentPreContainer) return;
    let node: PrinterTemplateNodeBase<any>;
    switch (component._id) {
      case "PrinterTemplateNodeCut":
        node = PrinterTemplateNodeCut.create();
        break;
      case "PrinterTemplateNodeFeed":
        node = PrinterTemplateNodeFeed.create();
        break;
      case "PrinterTemplateNodeFill":
        node = PrinterTemplateNodeFill.create();
        break;
    }
    if (!node) return;
    this.insertAfter(node, this.parentPreContainer);
  }

  placeLabelStaticText() {
    switch (this.selectedNode.constructor) {
      case PrinterTemplateNodeLabelStaticText:
      case PrinterTemplateNodeTextLabel:
      case PrinterTemplateNodeInterpolationLabel:
        this.insertAfter(PrinterTemplateNodeTextLabel.createFromText("Text"));
        break;
      case PrinterTemplateNodeLabelText:
        this.insertChild(PrinterTemplateNodeTextLabel.createFromText("Text"));
        break;
    }
  }

  placeLabelBlock() {
    const node = PrinterTemplateNodeLabelText.createFromText("Text");
    if (!this.parentPreContainer) return;
    this.insertAfter(node, this.parentPreContainer, [0]);
  }
}
