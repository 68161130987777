
import { Component, Prop, Vue, Watch, mixins, FindType, VModel } from "@feathers-client";

@Component
export default class TeleportMenuButton extends Vue {
  @Prop(Boolean)
  hidden: boolean;

  @Prop()
  text: string

  menu = false;
  
}
