
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import type { CartItem } from "@common/table/cart";

@Component
export default class EditCartItem extends Vue {
  @VModel()
  editCartItem: boolean;

  @Prop()
  session: TableSession;

  @Prop()
  sessionTypes: string;

  @Prop()
  navigate: any;

  @Prop()
  line: ProductLine;

  @Prop()
  cart: CartItem;

  @Prop()
  item: CartItem | ProductLine;

  get isGenerated() {
    return this.item.fromProduct || this.item.fromCoupon;
  }

  splitItem() {
    return this.$emit("splitItem");
  }
}
