
import { Component, Prop, Vue, Watch, mixins, FindType } from "@feathers-client";
import moment from "moment";
import { getKey, signBuf, init } from "pos-printer/utils/cloudAuth";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    EditorObjectPickerNew,
    EditorObjectPickerList,
  },
})
export default class UserQrCode extends Vue {
  @Prop()
  user: string;

  @Prop()
  target: string;

  @Prop({ default: "u" })
  type: string;

  finalType = "u";
  finalTarget = "";

  get types() {
    return [
      {
        _id: "u",
        name: "User",
      },
      {
        _id: "c",
        name: "Coupon",
      },
      {
        _id: "g",
        name: "Redeem",
      },
    ];
  }

  beforeMount() {
    this.onType();
    this.onTarget();
  }

  @Watch("type")
  onType() {
    this.finalType = this.type;
  }

  @Watch("target")
  onTarget() {
    this.finalTarget = this.target;
  }

  url = "";

  async setQRCode() {
    await init();
    const buf = [
      Buffer.alloc(4),
      Buffer.from(this.user, "hex"),
      this.finalType === "u" ? Buffer.alloc(0) : Buffer.from(this.finalTarget || "", "hex"),
    ];

    const key = await getKey("user_" + this.user);
    const spki = await crypto.subtle.exportKey("spki", key.publicKey);
    const pk = ["-----BEGIN PUBLIC KEY-----", Buffer.from(spki).toString("base64"), "-----END PUBLIC KEY-----"].join(
      "\n",
    );
    const user = await this.$feathers.service("appUsers").get(this.user);
    if (user.publicKey !== pk) {
      await this.$feathers.service("appUsers").patch(this.user, {
        publicKey: pk,
      });
    }

    buf[0].writeUInt32LE(moment().unix(), 0);
    const sign = await signBuf(Buffer.concat(buf), key.privkey);
    buf.push(sign);

    this.url = `${this.$config.appDomain || this.$config.selfOrder}/${this.finalType}/${base64url(
      Buffer.concat(buf).toString("base64"),
    )}`;
  }
}

export function base64url(buf: Buffer | string) {
  return (typeof buf === "string" ? buf : buf.toString("base64"))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}
