
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "@feathers-client/mixins/Dialog";

@Component
export default class SuccessDialog extends mixins(Dialog) {
  @Prop()
  title!: string;

  @Prop()
  desc: string;

  @Prop({ default: "text-light-text-secondary" })
  descClass?: string;

  @Prop({ default: "!bg-light-action-primary-default !text-white" })
  confirmBtnClass: string;

  @Prop({ type: Boolean, default: false })
  isEdit: Boolean;

  mounted() {
    // setTimeout(() => this.modalResult(true), 2000);
  }
}
