
import _, { find } from "lodash";
import {
  Component,
  Prop,
  Vue,
  Watch,
  mixins,
  Ref,
  PropSync,
  VModel,
  FindType,
  FindPopRawType,
  getID,
} from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import type { TableSessionRef } from "~/plugins/table";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { ObjectID } from "@db";
import { ResultType } from "@adyen/api-library/lib/src/typings/terminal/resultType";

@Component({
  components: {
    EditorObjectPickerList,
    EditorTextField,
  },
})
export default class OrderSystemPeopleCrm extends Vue {
  @Prop()
  staff: FindType<"staffs">;

  customerTags = [];
  orderTags = [];

  customerName = "";
  customerPhonePrefix = this.$config?.regionalConfig?.phonePrefix ?? "852";
  customerPhone = "";
  customerEmail = "";
  selectedCustomerTags = [];
  selectedOrderTags = [];

  nameEmpty = false;
  phoneEmpty = false;
  emailEmpty = false;

  async checkInput(name, phone, email) {
    try {
      const memberData = await this.$feathers.service("appUsers").find({
        query: {
          $select: ["name", "phone", "email"],
          $or: [{ name: name }, { phone: phone }, { email: email }],
          $limit: 1,
        },
      });

      let existLength = [];
      const checkDuplicate = memberData?.data.map(item => {
        if (item.name === name) {
          existLength.push(item.name);
        }
        if (item.phone === phone) {
          existLength.push(item.phone);
        }
        if (item.email === email) {
          existLength.push(item.email);
        }
      });

      if (existLength.length > 0) {
        await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: this.$t("orderSystem.editUser.basicInfo.errorMemberDuplicated.$", {
              string: existLength.toString(),
            }),
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            confirmText: "text-sm font-medium",
            confirm: this.$t("pages.shops.selectedMethod.confirm"),
            hasCancel: false,
          },
          {
            maxWidth: "500px",
          },
        );

        return true;
      } else {
        return false;
      }
    } catch (e) {
      return console.error(e);
    }
  }

  async confirm() {
    const staff = await this.$shop.checkPermission(["crm/createMember"]);
    if (staff === false) return;

    this.nameEmpty = false;
    this.phoneEmpty = false;
    this.emailEmpty = false;

    if (this.customerName === "") {
      this.nameEmpty = true;
    }
    if (this.customerPhone === "" || this.customerPhonePrefix === "") {
      this.phoneEmpty = true;
    }
    if (this.customerEmail === "") {
      this.emailEmpty = true;
    }

    if (this.nameEmpty || this.phoneEmpty || this.emailEmpty) return console.error("Input Field Empty!!");

    const duplicated = await this.checkInput(
      this.customerName,
      this.customerPhonePrefix + this.customerPhone,
      this.customerEmail.toLocaleLowerCase(),
    );

    if (duplicated) {
      return console.error("invalid input!!");
    }
    try {
      const newMember: FindType<"appUsers"> = await this.$feathers.service("appUsers").create({
        name: this.customerName,
        phonePrefix: this.customerPhonePrefix || (this.$config?.regionalConfig?.phonePrefix ?? "852"),
        phoneValue: this.customerPhone,
        email: this.customerEmail.toLocaleLowerCase(),
        password: this.customerPhone || this.customerEmail.toLocaleLowerCase(),
        registerDate: new Date(),
        date: new Date(),

        customerTags: this.selectedCustomerTags.map(tag => tag),
        customerTagsModifiedDate: new Date(),
        orderTags: this.selectedOrderTags.map(tag => tag),
        orderTagsModifiedDate: new Date(),
      });

      await this.$feathers.service("actionLogs").create({
        staff: this.staff?._id || this.$shop.staffId,
        type: "crm/createMember",
        detail: {
          id: newMember.userId,
          name: newMember.name,
        },
      });

      const confirmSuceess = await this.$openDialog(
        import("~/components/dialogs/SuccessDialog.vue"),
        {
          title: this.$t("peopleCrm.successfullySaved"),
        },
        {
          maxWidth: "400px",
        },
      );

      if (confirmSuceess) {
        this.nameEmpty = false;
        this.phoneEmpty = false;
        this.emailEmpty = false;
        this.customerName = "";
        this.customerPhone = "";
        this.customerEmail = "";
        this.selectedCustomerTags = [];
        this.selectedOrderTags = [];
        this.$emit("reload");
        this.$emit("close");
      }
    } catch (err) {
      console.error(err);
    }
  }

  async exitCreateMember() {
    if (
      this.customerName !== "" ||
      this.customerPhone !== "" ||
      this.customerEmail !== "" ||
      this.selectedCustomerTags.length > 0 ||
      this.selectedOrderTags.length > 0
    ) {
      const confirm = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog2.vue"),
        {
          title: this.$t("orderSystem.editUser.basicInfo.confirmExit.$"),
          content: this.$t("pages.shops.selectedMethod.confirmExitContent.$"),
          titleClass: "text-xl font-medium",
          contentClass: "text-mbase font-normal",
          dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
          confirmText: "text-sm font-medium",
          cancelText: "text-sm text-black font-medium",
          confirm: this.$t("pages.shops.selectedMethod.confirm"),
        },
        {
          maxWidth: "500px",
        },
      );
      if (confirm) {
        this.customerName = "";
        this.customerPhone = "";
        this.customerEmail = "";
        this.selectedCustomerTags = [];
        this.selectedOrderTags = [];
        return this.$emit("close");
      }
    } else {
      return this.$emit("close");
    }
  }
}
