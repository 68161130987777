
import { Component, FindType, Prop, Watch, checkID, getID, mixins } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import NumPadNew from "@feathers-client/components/NumPadNew.vue";
import { CartItem } from "~/common/table/cart";
import { TableSession } from "~/plugins/table/session";

@Component({ components: { NumPadNew } })
export default class ManualAdjustDialog extends mixins(Dialog()) {
  @Prop()
  cart: CartItem;

  @Prop()
  session: TableSession;

  selectedTab = "predestined";
  adjustment = "";
  placeholder = "";

  predestinedDiscounts: FindType<"productDiscounts">[] = [];
  predestinedDiscount: FindType<"productDiscounts"> = null;

  discountTab = "predestinedDiscount";

  staff = null;

  permission = "paymentManage/tableSessionDiscount";

  async beforeMount() {
    this.predestinedDiscounts = await this.$feathers.service("productDiscounts").find({
      query: {
        status: "active",
        $paginate: false,
        $sort: { order: 1 },
      },
      paginate: false,
    });

    this.predestinedDiscount = this.predestinedDiscounts.find(d => checkID(d._id, this.cart?.predestinedDiscount));

    if (!this.$shop.shopData.openPredestinedDiscountInProduct && !this.predestinedDiscounts.length) {
      this.discountTab = "orderDiscount";
    }

    if (!this.$shop.shopData.openPredestinedDiscountInProduct && !this.$shop.shopData.openOrderDiscountInProduct) {
      this.selectedTab = "singlePrice";
      this.permission = "orderManage/tableSessionEditProductPrice";
    }
  }

  mounted() {
    this.reset();
  }

  get items() {
    return [
      { _id: "predestinedDiscount", name: this.$t("pos.manualAdjust.predestinedDiscount") },
      { _id: "orderDiscount", name: this.$t("pos.manualAdjust.orderDiscount") },
    ];
  }

  async onChangeTab(tab) {
    let staff = null;
    const tempPermission =
      tab === "predestined" ? "paymentManage/tableSessionDiscount" : "orderManage/tableSessionEditProductPrice";
    if (!this.$shop.hasPermission([tempPermission])) {
      staff = await this.$shop.checkPermission([tempPermission]);
      if (staff === false) return;

      this.staff = staff;
    } else {
      this.staff = this.$shop.staff;
    }

    this.selectedTab = tab;
    this.permission = tempPermission;
  }

  updatedDiscountList = [];

  clearDiscount = false;

  onClearDiscount() {
    this.clearDiscount = true;
    this.updatedDiscountList = this.$shop.discounts.filter(
      d => d.for === "order" && this.session.coupons.find(it => checkID(d, it)),
    );
  }

  get discounts() {
    const self = this;
    return this.$shop.discounts
      .filter(d => d.for === "order")
      .map(d => ({
        ...d,
        get active() {
          return self.updatedDiscountList.find(it => checkID(d, it))
            ? !self.session.coupons.find(it => checkID(d, it.discount))
            : !!self.session.coupons.find(it => checkID(d, it.discount));
        },
        set active(v) {
          if (self.clearDiscount) return;
          (async () => {
            const staff = await self.$shop.checkPermission([`paymentManage/tableSessionDiscount`]);
            if (staff === false) return;
            const foundIdx = self.updatedDiscountList.findIndex(it => checkID(d, it));
            if (foundIdx >= 0) {
              self.updatedDiscountList.splice(foundIdx, 1);
            } else {
              self.updatedDiscountList.push({ ...d, staff });
            }
          })();
        },
      }));
  }

  async flushUpdates() {
    this.session.updateCoupons();
    await this.session.atomic({
      ...this.session.cachedPriceDetails,
    });
  }

  get tabs() {
    return [
      ...(this.$shop.shopData.openPredestinedDiscountInProduct || this.$shop.shopData.openOrderDiscountInProduct
        ? ["predestined"]
        : []),
      "singlePrice",
      "fixedAdjust",
      "percentAdjust",
    ];
  }

  get max() {
    switch (this.selectedTab) {
      case "singlePrice":
        return 10000000;
      case "fixedAdjust":
        return this.cart.priceWithoutOpenKey;
      case "percentAdjust":
        return 100;
    }
  }

  @Watch("selectedTab")
  reset() {
    this.adjustment = "";
    this.predestinedDiscount = this.predestinedDiscounts.find(d => checkID(d._id, this.cart?.predestinedDiscount));
    switch (this.selectedTab) {
      case "singlePrice":
        this.placeholder = String(this.cart?.unitPrice);
        break;
      case "fixedAdjust":
        this.placeholder = String(Math.abs(this.cart?.openKey));
        break;
      case "percentAdjust":
        this.placeholder = String(this.cart?.manualPercentAdjust);
        break;
    }
  }

  async confirm() {
    if (!this.adjustment && this.selectedTab !== "predestined") {
      return this.modalResult(false);
    }

    let staff = null;
    if (!this.$shop.hasPermission([this.permission])) {
      staff = await this.$shop.checkPermission([this.permission]);
      if (staff === false) return;

      this.staff = staff;
    } else {
      this.staff = this.$shop.staff;
    }

    switch (this.selectedTab) {
      case "predestined":
        if (this.predestinedDiscount) {
          this.cart.predestinedDiscount = this.predestinedDiscount._id;
          this.cart.predestinedDiscountName = this.$td(this.predestinedDiscount.name)
            ? this.predestinedDiscount.name
            : this.predestinedDiscount.type === "percent"
              ? [{ lang: this.$store.state.locale, value: `${this.predestinedDiscount.discount * 100}%` }]
              : [{ lang: this.$store.state.locale, value: `${this.predestinedDiscount.discount}` }];
          if (this.predestinedDiscount.type === "percent") {
            this.cart.manualPercentAdjust = Math.round((1 - this.predestinedDiscount.discount) * 100);
            this.cart.openKey = 0;
          } else {
            this.cart.openKey = this.predestinedDiscount.discount;
            this.cart.manualPercentAdjust = 0;
          }
        } else {
          this.cart.predestinedDiscount = null;
          this.cart.predestinedDiscountName = null;
          this.cart.manualPercentAdjust = 0;
          this.cart.openKey = 0;
        }
        for (let discount of this.updatedDiscountList) {
          if (!this.session.coupons.find(it => checkID(discount, it.discount))) {
            (async () => {
              await this.session.tryApplyDiscount(discount);
              await this.flushUpdates();

              await this.$feathers.service("actionLogs").create({
                session: this.session.item._id,
                view: getID(this.session.item.view),
                staff: discount.staff?._id || this.$shop.staffId,
                type: `paymentManage/tableSessionDiscount`,
                detail: { discount, enabled: true },
              });
            })();
          } else {
            (async () => {
              const tempDiscount = this.session.coupons.find(it => checkID(discount, it.discount));
              this.session.coupons.find(it => checkID(discount, it.discount))?.remove?.();
              await this.flushUpdates();

              if (tempDiscount) {
                await this.$feathers.service("actionLogs").create({
                  session: this.session.item._id,
                  view: getID(this.session.item.view),
                  staff: discount.staff?._id || this.$shop.staffId,
                  type: `paymentManage/tableSessionDiscount`,
                  detail: { discount, enabled: false },
                });
              }
            })();
          }
        }
        break;
      case "singlePrice":
        if (Number(this.adjustment) !== this.cart?.unitPrice) {
          this.cart.overridePrice = Number(this.adjustment);
        }
        break;
      case "fixedAdjust":
        this.cart.openKey = this.adjustment ? Number(-this.adjustment) : 0;
        this.cart.predestinedDiscount = null;
        this.cart.predestinedDiscountName = null;
        this.cart.manualPercentAdjust = 0;
        break;
      case "percentAdjust":
        this.cart.manualPercentAdjust = this.adjustment ? Number(this.adjustment) : 0;
        this.cart.predestinedDiscount = null;
        this.cart.predestinedDiscountName = null;
        this.cart.openKey = 0;
        break;
    }

    if (
      (this.$shop.shopData.autoMergeInPOS || this.$shop.shopData.autoMergeOrderedInPOS) &&
      (this.selectedTab !== "predestined" || this.discountTab !== "orderDiscount")
    ) {
      this.$store.commit("SET_SUCCESS", this.$t("pos.manualAdjust.alert"));
    }

    this.cart.editPriceStaff = this.staff || true;

    this.modalResult({ item: this.cart, staff: getID(this.staff) });
  }
}
