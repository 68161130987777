
import { Vue, Component, Prop, Watch, Ref, FindType } from "@feathers-client";
import { OdsShop } from "./baseShop";

type TvOrderStatus = FindType<"tvOrderStatuses"> & { newItem?: boolean };

@Component
export default class Carousel extends Vue {
  @Prop()
  order: TvOrderStatus;

  @Prop()
  shop: OdsShop;

  @Prop(Boolean)
  fill: boolean;

  @Prop({ type: Boolean, default: true })
  flashing: boolean;

  appear = false;

  @Watch("order.newItem", { immediate: true })
  onItem() {
    if (this.flashing && this.order.newItem) {
      this.appear = true;
      this.order.newItem = false;
    }
  }

  get code() {
    if (this.shop.prefix && this.order.code.startsWith(this.shop.prefix)) {
      let code = this.order.code.substring(this.shop.prefix.length);
      while (code[0] === " " || code[0] === "-") {
        code = code.substring(1);
      }
      return code;
    }
    return this.order.code;
  }
}
