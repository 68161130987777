import DB from "@db";
import { MApplication, MHookMap, Id, MHookContext } from "@feathersjs/feathers";
import { ObjectID } from "@mfeathers/db/schemas";
import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import { getID } from "@util";

export async function create(
  hook: MHookContext<
    AdminApplication,
    {
      session: ObjectID<any>;
    },
    typeof DB.TableSession._mongoType
  >,
) {
  const session = await hook.app.service("tableSessions").get(hook.data.session);
  // if (session.status !== "toPay") {
  hook.result = session;
  // return;
  // }
  // hook.result = await hook.services("tableSessions/pay", true).create({
  //   session: getID(session),
  // });
}
