
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem } from "~/plugins/table";
import { SessionMoveAction } from "~/plugins/table/session";

@Component
export default class extends Vue {
    @Prop()
    tableView : TableView

    get session() {
        return this.tableView.selectedSession;
    }

    async clearSession() {
        await this.session.atomic({
            endTime: new Date(),
        })
        this.tableView.clearSessionSelect();
    }

    async reprintQR() {
        await this.session.printOnlineOrderLinks(this.session.selectedRef);
    }

    async splitOrder() {
        const session = this.session;
        const ref = session.selectedRef;
        if(!ref) return;
        const initAction : SessionMoveAction = {
            actions: [
                {
                    from: {
                        ref: ref
                    },
                    to: {
                        ref: ref,
                        capacity: ref.capacity,
                    }
                },
            ],
            newSessions: [],
        }
        const res = await this.$openDialog(import('~/components/table/SessionMoveDialog.vue'), {
            session,
            initAction,
            initOperation: 'split',
            initMergeTarget: ref.table,
            mergeSource: {
                ref: ref,
                x: 0,
                y: 0,
            },
        }, {
            contentClass: 'editor-dialog'
        })
        if(res) return;
    }
}
