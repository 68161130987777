
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";

@Component
export default class CartPrinterPopup extends Vue {
  @VModel()
  print: boolean;

  @Prop()
  session: TableSession;
}
