import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import errors from "@feathersjs/errors";
import _ from "lodash";
import { OrderKitchenView, OrderKitchenViewItem } from "@common/table/invoiceSequencer";
import { getMapById, JobEntry, ProductLine, handlePrintJob, getProductItems } from "./order";

export async function create(
  hook: MHookContext<AdminApplication, CreateType<"tableSessions/cancel">, FindType<"tableSessions">>,
) {
  const session = await hook.app.service("tableSessions").get(hook.data.session);
  const products: string[] = _.uniq(hook.data.products);

  const deletedProducts: ProductLine[] = [];

  for (let i = 0; i < products.length; i++) {
    let product = hook.data.products[i];
    const line = session.products.find(p => product === p.id);

    if (!line) {
      throw new errors.BadRequest("Cannot find product");
    }

    if (line.status === "cancel") {
      continue;
    }

    if (line.status !== "hold") {
      line.status = "cancel";
      if (hook.data.cancelReason) {
        line.cancelReason = hook.data.cancelReason as any;
      }
      deletedProducts.push(line);
    }

    line.status = "cancel";
    line.price = 0;
    line.cancelReason = hook.data.cancelReason || null;
  }

  hook.result = await hook.app.service("tableSessions").patch(hook.data.session, {
    products: session.products,
  });

  // if ((hook.data.cancelInventory ?? true) && !session.testing) {
  //   const manager = await hook.app.$shopCache.getShop(`${session.shop}`);
  //   await manager.cancelStocks(
  //     hook,
  //     session,
  //     session.products.filter(it => hook.data.products.includes(it.id)),
  //   );
  // }

  if (!deletedProducts.length) {
    return;
  }
  const jobList: any[] = [];
  const [tables, shopCache, staff] = await Promise.all([
    getMapById(
      hook.app.service("tableViewItems"),
      _.uniq(deletedProducts.map(it => getID(it.table))).filter(it => !!it),
    ),
    hook.app?.$shopCache?.getShop(session.shop),
    hook.data.staff && hook.app.service("staffs").get(hook.data.staff),
  ]);

  const jobFlatten: JobEntry[] = [];

  for (let deleted of deletedProducts) {
    const table = tables[getID(deleted.table)];
    const job: Partial<OrderKitchenView> = {
      session: session,

      tableName: table?.name,
      tableSplit: deleted.tableSplit,
      chairId: deleted.tableChairId,

      table,
      staffName: staff?.name,

      type: "cancel",
    };

    jobFlatten.push(...getProductItems(deleted, job, shopCache));
  }

  if (!hook.data.noPrint) {
    await handlePrintJob(hook.app, hook.result, jobList, jobFlatten, {
      noSplit: true,
    });
  }
}
