var alphabet = "0123456789abcdefghjkmnpqrtuvwxyz";
var alias = { o: 0, i: 1, l: 1, s: 5 };

var lookup = function () {
  var table = {};
  // Invert 'alphabet'
  for (var i = 0; i < alphabet.length; i++) {
    table[alphabet[i]] = i;
  }
  // Splice in 'alias'
  for (var key in alias) {
    if (!alias.hasOwnProperty(key)) continue;
    table[key] = table["" + alias[key]];
  }
  lookup = function () {
    return table;
  };
  return table;
};

export function encodeBase32(buffer: Buffer): string {
  var length = buffer.length;
  var bits = 0;
  var value = 0;
  var output = "";
  for (var i = 0; i < length; i++) {
    value = (value << 8) | buffer[i];
    bits += 8;
    while (bits >= 5) {
      output += alphabet[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }
  if (bits > 0) {
    output += alphabet[(value << (5 - bits)) & 31];
  }
  return output;
}

export function decodeBase32(s: string) {
  let skip = 0;
  let byte = 0;
  const output: number[] = [];
  for (let c of s) {
    c = c.toLowerCase();
    var val = lookup()[c];
    if (typeof val == "undefined") {
      // character does not exist in our lookup table
      return; // skip silently. An alternative would be:
      // throw Error('Could not find character "' + char + '" in lookup table.')
    }
    val <<= 3; // move to the high bits
    byte |= val >>> skip;
    skip += 5;
    if (skip >= 8) {
      // we have enough to preduce output
      output.push(byte);
      skip -= 8;
      if (skip > 0) byte = (val << (5 - skip)) & 255;
      else byte = 0;
    }
  }
  return Buffer.from(output);
}
