import { Vue, Component, FindType, getID, checkID, loadCachedItem } from "@feathers-client";
import {
  clearSelection,
  kitchenSequencer,
  receiptSequencer,
  tableReceiptOrderOnlySequencer,
  wrapJob,
} from "~/plugins/printer/invoiceSequencer";
import uuid from "uuid/v4";
import type { AllPrintJobData } from "@common/table/printJobTypes";
import { initPrinter, attachHandler } from "~/plugins/printer/invoiceSequencer";
// @ts-ignore
import { testPrint } from "pos-printer";
import LRUCache from "lru-cache";
import { PrintJob } from "pos-printer/printJob";

@Component
export class LocalPrinterManager extends Vue {
  channel: BroadcastChannel;
  createdTime = Date.now();
  id = uuid();
  running = true;
  isLeader = true;
  retryMissingTimer = null;

  async created() {
    await Vue.nextTick();

    if (BroadcastChannel !== undefined) {
      this.isLeader = false;
      this.channel = new BroadcastChannel("printerLock");
      this.channel.addEventListener("message", this.onMessage);
      this.channel.postMessage({ type: "ping", createdTime: this.createdTime, id: this.id, shop: this.$shop.shopId });
      this.startPolling();
    } else {
      this.retryMissingJobs();
    }

    this.$feathers.service("sharePrintJobs").on("created", this.printJobHandler);
    this.$feathers.on("connected" as any, this.retryMissingJobs);
    window.addEventListener("beforeunload", this.unload);
    this.retryMissingTimer = setInterval(this.retryMissingJobs, 60 * 1000 * 1);
  }

  beforeDestroy() {
    this.unload();
    window.removeEventListener("beforeunload", this.unload);
    if (this.retryMissingTimer) {
      clearInterval(this.retryMissingTimer);
    }
  }

  unload() {
    if (this.isLeader) {
      this.channel.postMessage({ type: "unload", createdTime: this.createdTime, id: this.id, shop: this.$shop.shopId });
    }
    this.running = false;
    this.isLeader = false;
    console.log("close listen");
    this.$feathers.service("sharePrintJobs").off("created", this.printJobHandler);
    this.$feathers.off("connected", this.retryMissingJobs);
    if (this.channel) {
      this.channel.close();
    }
  }

  async startPolling() {
    while (this.running) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (!this.remoteClients.length) {
        console.log("Start printer leader");
        this.isLeader = true;

        this.retryMissingJobs();
        while (!this.remoteClients.length) {
          if (!this.running) return;

          await new Promise(resolve => setTimeout(resolve, 5000));
          this.channel.postMessage({
            type: "pong",
            createdTime: this.createdTime,
            id: this.id,
            shop: this.$shop.shopId,
          });
        }
        console.log("Stop printer leader");
      }
      this.isLeader = false;
      while (this.remoteClients.length) {
        if (!this.running) return;
        this.remoteClients = [];
        await new Promise(resolve => setTimeout(resolve, 15000));
      }

      this.channel.postMessage({ type: "ping", createdTime: this.createdTime, id: this.id, shop: this.$shop.shopId });
    }
  }

  remoteClients: any[] = [];
  onMessage(data: MessageEvent) {
    if (!data.data || typeof data.data !== "object") return;
    if (data.data.id === this.id || data.data.shop !== this.$shop.shopId) return;

    switch (data.data.type) {
      case "ping": {
        this.channel.postMessage({ type: "pong", createdTime: this.createdTime, id: this.id, shop: this.$shop.shopId });
        if (data.data.createdTime < this.createdTime) {
          this.remoteClients.push(data.data);
        }
        break;
      }

      case "pong": {
        if (data.data.createdTime < this.createdTime) {
          this.remoteClients.push(data.data);
        }
        break;
      }

      case "unload": {
        this.remoteClients = [];
        break;
      }
    }
  }

  numOfFailJobs = 0;
  printing = false;

  lruStatus: LRUCache<string, Promise<PrintJob>> = new LRUCache({
    max: 200,
  });

  printJobsQueue: FindType<"sharePrintJobs">[] = null;

  async printJobHandler(data: FindType<"sharePrintJobs">) {
    if (!this.isLeader || !this.$shop.device) return;
    if (
      data.shop != this.$store.state.user.shop ||
      data.device !== this.$shop.device._id ||
      data.debugPlaceholder ||
      this.$isAdmin ||
      (data.status && data.status !== "pending" && data.status !== "printing" && data.status !== "queued")
    )
      return;

    if (data.doneDate) {
      await this.$feathers.service("sharePrintJobs").patch(getID(data), {
        status: "done",
        $push: {
          history: { status: "done", date: new Date(), error: "Previous done job found" },
        },
      });
      return;
    }

    const jobId = data.offlineId || getID(data);
    if (this.lruStatus.has(jobId)) return;

    if (!this.printJobsQueue) {
      this.printJobsQueue = [data];
      this.flushPrintJobs();
    } else {
      this.printJobsQueue.push(data);
    }
  }

  async flushPrintJobs() {
    let lastSessionId: string = null;
    let flusherId = uuid();
    while (this.printJobsQueue.length) {
      const data = this.printJobsQueue.shift();
      try {
        await loadCachedItem(this.$shop, "shopPrinterDict");
        const shopPrinter = this.$shop.shopPrinterDict[getID(data.shopPrinter)];
        console.log("New Print Job Comes", data.type);
        if (!data.job || shopPrinter?.disabled) continue;
        if (!shopPrinter && data.shopPrinter) {
          console.warn("No printer found");
          await this.$feathers.service("sharePrintJobs").patch(getID(data), {
            status: "error",
            lastError: "No printer found",
            errorDate: new Date(),
            $push: {
              history: { status: "error", date: new Date(), error: "No printer found", id: `${flusherId}` },
            },
          });
          continue;
        }
        const curId = getID(data.job?.session);
        if (curId !== lastSessionId) {
          clearSelection();
          lastSessionId = curId;
        }
        const jobId = data.offlineId || getID(data);
        let task = this.lruStatus.get(jobId);
        if (!task) {
          this.lruStatus.set(
            jobId,
            (task = this.printJobHandlerInner(data, shopPrinter, this.$shop.device._id, flusherId)),
          );
        }
        await task;
      } catch (e) {
        console.log(e);
      }
      if (!this.printJobsQueue.length) {
        await new Promise(resolve => setTimeout(resolve, 50));
      }
    }
    clearSelection();
    this.printJobsQueue = null;
  }

  async printJobHandlerInner(
    data: FindType<"sharePrintJobs">,
    shopPrinter: FindType<"shopPrinters">,
    device?: string,
    flusherId?: string,
  ) {
    const it: AllPrintJobData = data.job;
    try {
      switch (it.jobType) {
        case "table-kitchen":
        case "table-waterBar": {
          return await kitchenSequencer(
            this,
            data.job,
            data,
            shopPrinter,
            `${it.session._id}_${shopPrinter?._id}_${it.items
              .map(it => `${it.product.seq}_${it.product.prodSeq}`)
              .join(",")}`,
            flusherId,
          );
        }

        case "table-receipt": {
          return await tableReceiptOrderOnlySequencer(this, it, shopPrinter, data, flusherId);
        }

        case "table-payment-receipt": {
          return await receiptSequencer({
            context: this,
            job: it,
            shopPrinter,
            printJob: data,
            flusherId,
          });
        }

        case "test": {
          const printerServer = await initPrinter(this);
          const queue = await printerServer.getQueue(undefined, undefined, data.localPrinter);
          await testPrint(queue as any, undefined, undefined, undefined, job => {
            attachHandler(this, job, queue, getID(data));
          });

          break;
        }
      }
    } catch (e) {
      console.log(e.message);
      if (data._id) {
        await this.$feathers.service("sharePrintJobs").patch(getID(data), {
          status: "error",
          lastError: e.message,
          errorDate: new Date(),
          $push: {
            history: { status: "error", date: new Date(), error: e.message, id: `${flusherId}_inner` },
          },
        });
      }
    }
  }

  _retryJobConfirm: Promise<boolean>;

  retryJobConfirm(jobs: FindType<"sharePrintJobs">[]) {
    if (!this._retryJobConfirm) {
      const retry = (this._retryJobConfirm = this.retryJobConfirmInner(jobs));
      retry.finally(() => {
        this._retryJobConfirm = null;
      });
      return retry;
    }
    return this._retryJobConfirm;
  }

  async retryJobConfirmInner(jobs: FindType<"sharePrintJobs">[]): Promise<boolean> {
    let timeout: any;
    // if no response in 1 minute, auto confirm
    const res = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("printer.retryMissingJobs", {
          jobs: jobs.length,
          fromNow: this.$moment(new Date(jobs[0]?.date)).fromNow(),
        }),
      },
      {
        maxWidth: "400px",
        persistent: true,
      },
      id => {
        timeout = setTimeout(() => {
          timeout = null;
          this.$root.$emit("modalResult", { result: true, id });
        }, 1000 * 60);
      },
    );
    if (timeout) {
      clearTimeout(timeout);
    }
    return res;
  }

  async retryMissingJobs(ids: string[] = []) {
    if (!this.isLeader || !this.$shop.device?._id) {
      return;
    }
    if (this._retryJobConfirm) {
      // if waiting for confirm, skip
      return;
    }
    if (!Array.isArray(ids)) {
      ids = [];
    }
    if (!ids.length) {
      ids = [...this.lruStatus.keys(), ...(this.printJobsQueue || []).map(it => String(it._id))];
    }
    let jobs = await this.$feathers.service("sharePrintJobs").find({
      query: {
        device: this.$shop.device._id,
        status: { $in: ["pending", "printing", "queued"] },
        $paginate: false,
        $limit: 100,
        ...(ids.length ? { _id: { $nin: ids } } : {}),
        $sort: { date: 1 },
      },
      paginate: false,
    });

    if (!jobs.length) {
      return;
    }
    if (!this.isLeader || !this.$shop.device?._id) {
      return;
    }
    console.log("retry missing jobs");

    const checkNext = jobs.length === 100;

    const jobsWithin5m: typeof jobs = [];
    const jobsWithin30m: typeof jobs = [];
    const jobsMoreThan30m: typeof jobs = [];

    ids.push(...jobs.map(getID));

    for (let job of jobs) {
      const diff = Date.now() - new Date(job.date).getTime();
      if (diff < 1000 * 60 * 5) {
        jobsWithin5m.push(job);
      } else if (diff < 1000 * 60 * 30) {
        jobsWithin30m.push(job);
      } else {
        jobsMoreThan30m.push(job);
      }
    }

    if (jobsMoreThan30m.length || jobsWithin30m.length + jobsMoreThan30m.length >= 30) {
      const confirmingJobs = [...jobsMoreThan30m, ...jobsWithin30m];
      const c = await this.retryJobConfirm(confirmingJobs);
      if (!c) {
        for (let job of confirmingJobs) {
          await this.$feathers.service("sharePrintJobs").patch(getID(job), {
            status: "error",
            lastError: "User Cancelled",
            errorDate: new Date(),
            $push: {
              history: { status: "error", date: new Date(), error: "User Cancelled", id: "user_cancel" },
            },
          });
        }
        jobs = jobsWithin5m;
      }
    }

    if (!this.isLeader || !this.$shop.device?._id) {
      return;
    }

    for (let job of jobs) {
      await this.printJobHandler(job);
    }
    if (checkNext) {
      return this.retryMissingJobs(ids);
    }
    console.log("missing job done");
  }
}
