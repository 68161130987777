var render, staticRenderFns
import script from "./TranslateBox.vue?vue&type=script&lang=ts"
export * from "./TranslateBox.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4730fc10')) {
      api.createRecord('4730fc10', component.options)
    } else {
      api.reload('4730fc10', component.options)
    }
    
  }
}
component.options.__file = "components/TranslateBox.vue"
export default component.exports