
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableItem } from "~/plugins/table";
import { SessionConainterLayout } from "~/plugins/table/view";
import _ from "lodash";
import type { Group } from "konva/lib/Group";
import ItemSessionExpand from "./sessionExpand.vue";

@Component
export default class ItemSession extends Vue {
  @Prop()
  item: TableItem;

  get expandLayout() {
    const item = this.items[0];
    return item?.multiple ? item : null;
  }

  get sessions() {
    return this.item.sessions;
  }

  get groupConfig() {
    return {
      x: -this.item.w / 2,
      y: -this.item.h / 2,
    };
  }

  get layoutConfig() {
    const itemOverlap = 0.5;
    const paddingX = this.item.w > 100 ? 10 : -5;
    const layoutTop = 40;

    const w = this.item.w - paddingX * 2;
    const h = this.item.h - layoutTop - (this.item.h > 100 ? 10 : -5);
    const itemWidth = w < 70 ? w : 70;
    const itemHeight = h < 80 ? h : 80;

    const maxItemPerCol = w >= itemWidth ? Math.floor((w - itemWidth) / (itemWidth * itemOverlap)) + 1 : 1;
    const minItemPerCol =
      w >= itemWidth ? Math.floor((w - itemWidth) / (itemWidth + (this.item.w > 100 ? 10 : 5))) + 1 : 1;
    const rows = Math.max(1, h / itemHeight) | 0;
    const rowSep = Math.max(itemHeight, h / rows - itemHeight) | 0;

    return {
      itemWidth,
      itemHeight,
      w,
      h,
      maxItemPerCol,
      minItemPerCol,
      rows,
      rowSep,
      offsetTop: layoutTop,
      offsetLeft: paddingX,
    };
  }

  get items() {
    if (!this.sessions.length) return [];
    const items: SessionConainterLayout[] = [];

    const { itemWidth, itemHeight, w, maxItemPerCol, minItemPerCol, rows, offsetTop, offsetLeft, rowSep } =
      this.layoutConfig;

    const sessions = this.sessions; //_.orderBy(this.sessions, [s => s.session.item.startTime, s => s.split], ["desc", "desc"]);
    const itemCount = sessions.length;
    let cur = 0;
    for (let row = 0; row < rows && cur < itemCount; row++) {
      const remainRow = rows - row;
      const remainItem = itemCount - cur;
      const colInCurrentRow = Math.min(maxItemPerCol, Math.max(minItemPerCol, Math.ceil(remainItem / remainRow)));
      const colSep = colInCurrentRow > 1 ? (w - itemWidth) / (colInCurrentRow - 1) : 0;
      const offsetLeftWithPadding = colInCurrentRow > 1 ? offsetLeft : offsetLeft + (w - itemWidth) / 2;

      const isLastRow = row === rows - 1;

      for (let col = 0; col < colInCurrentRow && cur < itemCount; col++) {
        const isLastCol = col === colInCurrentRow - 1;
        if (isLastRow && isLastCol && cur !== itemCount - 1) {
          items.push({
            multiple: true,
            x: colSep * col + offsetLeftWithPadding,
            y: row * rowSep + offsetTop,
            w: itemWidth,
            h: itemHeight,
            items: sessions.slice(cur),
          });
          cur = sessions.length;
        } else {
          items.push({
            multiple: false,
            x: colSep * col + offsetLeftWithPadding,
            y: row * rowSep + offsetTop,
            w: itemWidth,
            h: itemHeight,
            item: sessions[cur++],
          });
        }
      }
    }

    return items.reverse();
  }
}
