import { PrintSequence, TextAlign } from ".";
import { RawLine } from "./rawLine";
import _ from "lodash";
import { WrappedContext } from "../common";
import type { PrinterBaseConf } from "../printers/baseConf";
import qr from "qr-image";
import zlib from "zlib";
import crc32 from "qr-image/lib/crc32";
import { PrintTable } from "./table";
// @ts-ignore
import LRUCache from "lru-cache";

const PNG_HEAD = Buffer.from([137, 80, 78, 71, 13, 10, 26, 10]);
const PNG_IHDR = Buffer.from([0, 0, 0, 13, 73, 72, 68, 82, 0, 0, 0, 0, 0, 0, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0]);
const PNG_IDAT = Buffer.from([0, 0, 0, 0, 73, 68, 65, 84]);
const PNG_IEND = Buffer.from([0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]);

function png(bitmap, stream) {
  stream.push(PNG_HEAD);

  var IHDR = Buffer.concat([PNG_IHDR]);
  IHDR.writeUInt32BE(bitmap.width ?? bitmap.size, 8);
  IHDR.writeUInt32BE(bitmap.height ?? bitmap.size, 12);
  IHDR.writeUInt32BE(crc32(IHDR.slice(4, -4)), 21);
  stream.push(IHDR);

  var IDAT = Buffer.concat([PNG_IDAT, zlib.deflateSync(bitmap.data, { level: 9 }), Buffer.alloc(4)]);
  IDAT.writeUInt32BE(IDAT.length - 12, 0);
  IDAT.writeUInt32BE(crc32(IDAT.slice(4, -4)), IDAT.length - 4);
  stream.push(IDAT);

  stream.push(PNG_IEND);
  stream.push(null);
}

function bitmap(matrix, size: number, marginL: number, marginR: number) {
  var N = matrix.length;
  var X = N * size + marginL + marginR;
  var data = Buffer.alloc((X + 1) * X);
  data.fill(255);
  for (var i = 0; i < X; i++) {
    data[i * (X + 1)] = 0;
  }

  for (var i = 0; i < N; i++) {
    for (var j = 0; j < N; j++) {
      if (matrix[i][j]) {
        var offset = (marginL + i * size) * (X + 1) + (marginL + j * size) + 1;
        data.fill(0, offset, offset + size);
        for (var c = 1; c < size; c++) {
          data.copy(data, offset + c * (X + 1), offset, offset + size);
        }
      }
    }
  }

  return {
    data: data,
    size: X,
  };
}

function qr_png_with_size(url: string, maxSize: number, size?: number, scale?: number) {
  const matrix = qr.matrix(url);

  if (!size) {
    if (scale) {
      size = Math.ceil(((matrix.length + 2) * scale) / 8);
    } else {
      size = maxSize;
    }
  }

  if (!scale) {
    scale = Math.floor((size * 8 - 16) / matrix.length);
  }

  if (scale <= 0) {
    throw new Error("Scale too small");
  }

  const realPixels = matrix.length * scale;
  let marginAll = size * 8 - realPixels;

  const marginL = Math.floor(marginAll / 2);
  const marginR = marginAll - marginL;

  const bmp = bitmap(matrix, scale, marginL, marginR);
  const buffers: Buffer[] = [];
  const stream = {
    push(buf: Buffer) {
      if (buf) {
        buffers.push(buf);
      }
    },
  };
  png(bmp, stream);
  return {
    buf: Buffer.concat(buffers),
    width: realPixels,
    chars: size,
  };
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

let cache = new LRUCache<string, Promise<Buffer>>({
  max: 100,
  maxAge: 3600,
});

export class HTMLPrintSequence extends PrintSequence {
  constructor(
    public context: WrappedContext,
    printer: PrinterBaseConf,
  ) {
    super(context, printer);
    this._useAttach = printer?.conf?.opts?.useAttach ?? false;
    this._useNativeLayout = printer?.conf?.opts?.useNativeLayout ?? true;
    this._bodyMargin = printer?.conf?.opts?.bodyMargin ?? 2;
    if (this._useNativeLayout) {
      this.htmlStyle = "padding:0;margin:0;border-spacing:0;";
      this.raw(
        (this.firstChunk = Buffer.from(
          `<div style='font-size:3vw;margin:auto;width:${(this.lineWidth * this.htmlMultipler) / 100}em;'>`,
        )),
      );
    } else {
      this.raw(
        (this.firstChunk = Buffer.from(
          `<div style='text-align: center; width: ${(this.lineWidth * this.htmlMultipler) / 100}em;'>`,
        )),
      );
    }
  }

  firstChunk: Buffer;

  _useAttach = false;
  _useNativeLayout = true;
  _bodyMargin = 2;
  useAttach() {
    this._useAttach = true;
    return this;
  }

  get bodyMarginEm() {
    return `${(this._bodyMargin * this.htmlMultipler) / 100}em`;
  }

  attachments: {
    filename: string;
    contentType: string;
    cid: string;
    content: string;
  }[] = [];

  get htmlLineHeight() {
    return this.currentFont === 0 ? 1.3 : 0.92;
  }

  get htmlCharSize() {
    return this.currentFont === 0 ? 0.65 : 0.4875;
  }

  htmlMultipler = 65;
  htmlStyle = "";

  fill(c?: string, n?: number) {
    if (c === "=") {
      this.raw(Buffer.from("<hr style='background-color:white; border-width:0; height:6px; border-top:1px solid black; border-bottom:1px solid black;' />"));
    } else {
      this.raw(Buffer.from("<hr/>"));
    }
    return this;
  }

  finish(): void {
    if (this._chunks.length === 1 && this._chunks[0] === this.firstChunk) {
      this._chunks = [];
      super.finish();
      return;
    }
    this.raw(Buffer.from("</div>"));
    super.finish();
  }

  async getImage(url: string, numChars: number, hiRes?: boolean): Promise<RawLine[]> {
    console.warn("Not supported: getImage");
    return [new RawLine(Buffer.from(`<img src="${url}"/>`), this.currentLineWidth)];
  }

  async printImage(url: string, height: number, hiRes?: boolean) {
    if (!url) return;

    let p = cache.get(url);
    if (!p) {
      p = (async () => {
        try {
          const resp = await fetch(url);
          if (!resp.ok) throw new Error("Failed to fetch image");
          return Buffer.from(await resp.arrayBuffer());
        } catch (e) {
          console.error("Failed to fetch image", e);
          return null;
        }
      })();
      cache.set(url, p);
    }
    try {
      const buf = await p;
      if (buf) {
        url = this.attachOrInline(buf);
      }
    } catch (e) {}

    this.raw(this.getPreLine());
    if (this._useNativeLayout) {
      this.raw(
        Buffer.from(
          `<td style="${this.htmlStyle}width: ${
            ((this.lineWidth / (this.currentWidth + 1)) * this.htmlMultipler) / 100
          }em; text-align: ${["left", "center", "right"][this.currentAlign]}">`,
        ),
      );
    }
    this.raw(
      Buffer.from(
        `<img style="width: ${(height / 8) * this.htmlCharSize}em; ${
          this._useNativeLayout ? "display: inline-block;" : ""
        }" src="${url}"/>`,
      ),
    );
    if (this._useNativeLayout) {
      this.raw(Buffer.from("</td>"));
    }
    this.raw(this.getPostLine());
    return this;
  }

  printQR(url: string, numChars: number, w: number = 6) {
    this.raw(this.getPreLine());
    const { buf, chars } = qr_png_with_size(url, this.currentLineWidth, numChars, w);

    if (this._useNativeLayout) {
      this.raw(
        Buffer.from(
          `<td style="${this.htmlStyle}width: ${
            ((this.lineWidth / (this.currentWidth + 1)) * this.htmlMultipler) / 100
          }em; text-align: ${["left", "center", "right"][this.currentAlign]}">`,
        ),
      );
    }
    this.raw(
      Buffer.from(
        `<img style="width: ${(chars * this.htmlMultipler) / 100}em; ${
          this._useNativeLayout ? "display: inline-block;" : ""
        }" src="${this.attachOrInline(buf)}"/>`,
      ),
    );
    if (this._useNativeLayout) {
      this.raw(Buffer.from("</td>"));
    }
    this.raw(this.getPostLine());
    return this;
  }

  async printImageData(
    buffer: Uint8ClampedArray,
    width: number,
    height: number,
    hiRes?: boolean | number,
    color?: number,
  ) {
    const currentWidth = this.currentWidth;
    this.currentWidth = 0;

    let output: Buffer;

    // @ts-ignore
    if (typeof OffscreenCanvas !== "undefined") {
      // @ts-ignore
      const canvas = new OffscreenCanvas(width, height);
      const ctx = canvas.getContext("2d");
      const imageData = ctx.createImageData(width, height);
      if (color === 1) {
        // for red color
        for (let i = 0; i < buffer.length; i += 4) {
          const r = buffer[i];
          const isBitSet = r === 0;
          buffer[i] = isBitSet ? 255 : 255;
          buffer[i + 1] = isBitSet ? 0 : 255;
          buffer[i + 2] = isBitSet ? 0 : 255;
        }
      }
      imageData.data.set(buffer);
      ctx.putImageData(imageData, 0, 0);
      const blob = await canvas.convertToBlob();
      output = Buffer.from(await blob.arrayBuffer());
    } else {
      // encode with png, grayscale
      const buf = Buffer.alloc((width + 1) * height);
      let offset = 1;
      for (let i = 0; i < height; i++) {
        let row = i * (width + 1);
        buf[row++] = 0;
        for (let j = 0; j < width; j++) {
          buf[row++] = buffer[offset];
          offset += 4;
        }
      }
      const buffers: Buffer[] = [];
      const stream = {
        push(buf: Buffer) {
          if (buf) {
            buffers.push(buf);
          }
        },
      };
      png(
        {
          data: buf,
          width,
          height,
        },
        stream,
      );
      output = Buffer.concat(buffers);
    }
    if (output) {
      this.raw(this.getPreLine());
      if (this._useNativeLayout) {
        this.raw(
          Buffer.from(
            `<td style="${this.htmlStyle}width: ${
              ((this.lineWidth / (this.currentWidth + 1)) * this.htmlMultipler) / 100
            }em; text-align: ${["left", "center", "right"][this.currentAlign]}">`,
          ),
        );
      }
      const vHi = hiRes === true || hiRes === 32 || hiRes === 33;
      const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
      const charSize = hHi ? 12 : 6;
      const aspect =
        ((width / height) * (vHi ? 1 : this.useBitmapImage ? 0.5 : 1 / 3)) /
        (hHi ? 1 : 0.5) /
        (this.fontWidth / this.fontHeight);
      this.raw(
        Buffer.from(
          `<img style="aspect-ratio: ${aspect}; width: ${(width / charSize) * this.htmlCharSize}em; object-fit: fill; ${
            this._useNativeLayout ? "display: inline-block;" : ""
          }" src="${this.attachOrInline(output)}"/>`,
        ),
      );
      if (this._useNativeLayout) {
        this.raw(Buffer.from("</td>"));
      }
      this.raw(this.getPostLine());
    }
    this.currentWidth = currentWidth;
    return this;
  }

  attachOrInline(buf: Buffer) {
    if (this._useAttach) {
      const cid = "img" + this.attachments.length;
      this.attachments.push({
        cid,
        filename: cid + ".png",
        contentType: "image/png",
        content: buf.toString("base64"),
      });

      return `cid:${cid}`;
    }
    return `data:image/png;base64,${buf.toString("base64")}`;
  }

  getQR(url: string, size: number) {
    return [new RawLine(Buffer.from(`<QR>${url}</QR>`), size)];
  }

  feed(n: number) {
    n = n || 1;
    this.raw(Buffer.from(`<div style='height: ${(n * this.htmlMultipler * 2) / 500}em'></div>`));
    return this;
  }

  cut() {
    return this;
  }

  currentAlign = TextAlign.Left;
  currentWidth = 0;
  currentHeight = 0;
  isBold = false;
  curColor = 0;
  currentFont = 0;

  align(n: TextAlign) {
    this.currentAlign = n;
    return this;
  }

  bold(b) {
    this.isBold = b ?? false;
    return this;
  }

  color(n: number = 0) {
    this.curColor = n;
    return this;
  }

  fontSize(w: number = 0, h: number = 0) {
    this.currentWidth = w;
    this.currentHeight = h;
    return this;
  }

  get fontWidthScale() {
    return this.currentWidth + 1;
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  getText(text: string) {
    return Buffer.from(text, "utf-8");
  }

  rawText(buf: Buffer) {
    if (this._useNativeLayout) {
      if (this._beginLine) {
        this.raw(
          Buffer.from(
            `<td style="${this.htmlStyle}width: ${
              ((this.lineWidth / (this.currentWidth + 1)) * this.htmlMultipler) / 100
            }em; text-align: ${["left", "center", "right"][this.currentAlign]}">` + escapeHtml(buf.toString()),
          ),
        );
        this._beginLine = false;
      } else {
        this.raw(Buffer.from(escapeHtml(buf.toString())));
      }
      return this;
    } else {
      const styledChanged = this._lineBold !== this.isBold || this._lineColor !== this.curColor;
      let debugInfo =
        this.includeDebug && this.debugLoc
          ? `data-debug='${typeof this.debugLoc === "string" ? this.debugLoc : JSON.stringify(this.debugLoc)}'`
          : "";
      if (debugInfo || styledChanged) {
        this.raw(
          Buffer.from(
            `<div ${debugInfo} style="display: contents; ${this.curColor ? "color: red;" : ""} font-weight: ${
              this.isBold ? "bold" : "normal"
            }">`,
          ),
        );
      }
      let lastNewLine = false;
      const text = buf
        .toString()
        .split("")
        .map(it => {
          const prev = lastNewLine;
          if (it === "\n") {
            lastNewLine = true;
            return `<div style='flex-basis: 100%; ${prev ? `min-height: ${this.htmlLineHeight}em;` : ""}'></div>`;
          }
          lastNewLine = false;
          let cw = Buffer.from(it);
          return `<div style="${
            cw.length > 1
              ? `width: ${this.htmlLineHeight}em`
              : `width: ${this.htmlCharSize}em${this.currentFont !== 0 ? "; font-size: 70%" : ""}${
                  prev ? `; min-height: ${this.htmlLineHeight}em;` : ""
                }`
          }">${escapeHtml(it)}</div>`;
        })
        .join("");
      this.raw(Buffer.from(text, "utf8"));
      if (lastNewLine) {
        this.raw(Buffer.from(`<div style='flex-basis: 100%; min-height: ${this.htmlLineHeight}em;'></div>`));
      }

      if (debugInfo || styledChanged) {
        this.raw(Buffer.from(`</div>`));
      }
      return this;
    }
  }

  printTable(table: PrintTable) {
    if (this._useNativeLayout) {
      this.raw(this.getPreLine());
      table.fit(this.currentLineWidth);
      for (let col of table.columns) {
        this.raw(
          Buffer.from(
            `<td style='${this.htmlStyle}width: ${
              ((col.size / (this.currentWidth + 1)) * this.htmlMultipler) / 100
            }em'>`,
          ),
        );

        for (let line of col.content) {
          this.raw(Buffer.from(`<div style="text-align: ${line.align || col.align || "left"}">`));
          this.raw(line instanceof RawLine ? line.buf : Buffer.from(escapeHtml(line.text)));
          this.raw(Buffer.from(`</div>`));
        }

        this.raw(Buffer.from("</td>"));
      }
      this.raw(this.getPostLine());
    } else if (this.includeDebug) {
      const maxLine = table.fit(this.currentLineWidth);

      const columnOfs = table.columns.map(col => ({
        offset: 0,
        content: col.content
          .map(c => ({
            text: c instanceof RawLine ? c.buf.toString() : c.text,
            debugLocs: (c.opts.debugLocs || []).slice(),
          }))
          .filter(c => c.text.length),
      }));
      for (let i = 0; i < maxLine; i++) {
        this.raw(this.getPreLine());
        for (let col = 0; col < table.columns.length; col++) {
          const column = table.columns[col];

          if (column.bold !== undefined) {
            this.bold(column.bold);
          }
          if (column.color !== undefined) {
            this.color(column.color);
          }

          if (column.debugLoc) {
            this.debugLoc = column.debugLoc;
          }
          const line = column.lines[i].toString();
          const state = columnOfs[col];
          const lineEnd = line.length - (column.linesPadRighht[i] || 0);

          let cur = 0;
          let skip = false;
          while (!skip && cur < lineEnd && state.content.length) {
            const curContent = state.content[0];
            let c = curContent.text[state.offset];

            if (!cur && (c === " " || c === "\t" || c === "\n" || c === "\r")) {
              // skip one space
              if (state.offset + 1 === curContent.text.length) {
                state.content.shift();
                continue;
              }
              c = curContent.text[++state.offset];
            }

            const nextIdx = line.indexOf(c, cur === 0 ? column.linesPadLeft[i] || 0 : cur);
            if (nextIdx === -1) {
              break;
            }
            if (cur !== nextIdx) {
              const prev = line.substring(cur, nextIdx);
              this.debugLoc = column.debugLoc;
              this.rawText(Buffer.from(prev));
              cur = nextIdx;
            }

            const charsToRun = Math.min(curContent.text.length - state.offset, lineEnd - cur);
            let debugLoc = curContent.debugLocs[0];
            let from = cur;

            while (debugLoc && state.offset >= debugLoc[1]) {
              curContent.debugLocs.shift();
              debugLoc = curContent.debugLocs[0];
            }
            if (debugLoc && state.offset >= debugLoc[0]) {
              this.debugLoc = debugLoc[2];
            }

            let j = 0;
            const flushPrev = () => {
              if (from !== cur + j) {
                const prev = line.substring(from, cur + j);
                if (prev) {
                  this.rawText(Buffer.from(prev));
                }
                from = cur + j;
              }
            };

            for (j = 0; j < charsToRun; j++) {
              const charOffset = state.offset + j;
              const c = curContent.text[charOffset];
              if (c !== line[cur + j]) {
                console.warn("Unable to match debug info");
                skip = true;
                break;
              }
              if (debugLoc && charOffset === debugLoc[1]) {
                flushPrev();
                this.debugLoc = column.debugLoc;
                curContent.debugLocs.shift();
                debugLoc = curContent.debugLocs[0];
              }
              if (debugLoc && charOffset === debugLoc[0]) {
                flushPrev();
                this.debugLoc = debugLoc[2];
              }
            }
            flushPrev();
            cur = from;
            if (skip) {
              break;
            }
            state.offset += charsToRun;
            if (state.offset === curContent.text.length) {
              state.content.shift();
              state.offset = 0;
            }
          }

          if (cur !== line.length) {
            const prev = line.substring(cur, line.length);
            this.debugLoc = column.debugLoc;
            this.rawText(Buffer.from(prev));
          }

          if (column.bold !== undefined) {
            this.bold(false);
          }
          if (column.color !== undefined) {
            this.color(0);
          }
        }
        this.raw(this.getPostLine());
        this.raw(this.getLine());
      }
    } else {
      super.printTable(table);
    }
    return this;
  }

  getLine() {
    return Buffer.alloc(0);
  }

  _lineBold = false;
  _lineColor = 0;
  _beginLine = false;

  getPreLine() {
    let debugInfo =
      this.includeDebug && this.debugLoc
        ? `data-debug='${typeof this.debugLoc === "string" ? this.debugLoc : JSON.stringify(this.debugLoc)}'`
        : "";
    this._lineBold = this.isBold;
    this._lineColor = this.curColor;
    this._beginLine = true;
    if (this._useNativeLayout) {
      return Buffer.from(
        `<table style='${this.htmlStyle}font-size: ${(this.currentWidth + 1) * 100}%; line-height: ${
          this.htmlLineHeight
        }em; font-weight: ${this.isBold ? "bold" : "normal"}; ${
          this.curColor ? "color: red;" : ""
        } table-layout: fixed; width: ${
          ((this.lineWidth / (this.currentWidth + 1)) * this.htmlMultipler) / 100
        }em; ' ${debugInfo}><tr style="${this.htmlStyle}">`,
      );
    } else {
      return Buffer.from(
        `<div style='display: flex; flex-wrap: wrap; place-content: ${
          ["start", "center", "end"][this.currentAlign]
        }; font-size: ${(this.currentWidth + 1) * 100}%; ${this.curColor ? "color: red;" : ""} line-height: ${
          this.htmlLineHeight
        }em; min-height: ${this.htmlLineHeight}em; font-weight: ${this.isBold ? "bold" : "normal"}' ${debugInfo}>`,
      );
    }
  }

  getPostLine() {
    if (this._useNativeLayout) {
      if (!this._beginLine) {
        return Buffer.from("</td></tr></table>");
      }
      return Buffer.from("</tr></table>");
    } else {
      return Buffer.from("</div>");
    }
  }

  printCode(type: string, code: string) {
    this.raw(Buffer.from(`Barcode ${type}: ${code}`));
    return this;
  }

  getJobOpts() {
    return {
      bodyMarginEm: this.bodyMarginEm,
    };
  }

  toHTML() {
    return this.getJob("").data.toString();
  }

  font(n: number) {
    this.currentFont = n;
    if (n === 0) {
      this.lineWidth = this.normalLineWidth;
    } else if (n === 1) {
      this.lineWidth = this.cnormalLineWidth;
    }
    return this;
  }
}
