import { getVersion } from "./nativeIntegrations";
import { ns } from "./messageQueue";

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("posScreen").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]).catch(e => false);
}

export function init() {
  return ns("posScreen").call<void>("init");
}

export function getScreenInfo(): Promise<PosScreenConfig> {
  return ns("posPrint").call<PosScreenConfig>("getScreenInfo");
}

export async function showImage(pngBase64: string) {
  return ns("posScreen").call<void>("showImage", pngBase64);
}

export interface PosScreenConfig {
  width: number;
  height: number;
  type: string;
  color: 'mono' | 'color'
}
