
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import OrderSystemCheckoutBase from "./checkoutBase";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
    QrcodeStream,
  },
})
export default class OrderSystemCheckout extends mixins(OrderSystemCheckoutBase) {
  @Prop()
  session: TableSession;

  async mounted() {
    this.paymentDialog = true;
    this.paymentMethodFull = null;
    this.initCheckout();
  }

  get legacyMode() {
    return this.$shop?.shopData?.posPaymentMethodsLegacyLayout;
  }

  checkoutTab = "method";

  get tabSubtitle() {
    return this.isPartial
      ? this.$t("tableView.splitPayment.partial")
      : this.session.partialEqually
        ? this.$t("tableView.splitPayment.split")
        : this.$t("tableView.splitPayment.payOutstanding");
  }

  get checkoutTabs() {
    return [
      "method",
      this.$shop.hasStaffRole("pos/discount") ? ["discount"] : [],
      this.session?.user ? ["coupon"] : [],
      this.$shop?.shopData?.enablePartialPayment && !this.session.testing ? ["partial"] : [],
      this.session?.payments?.length ? ["record"] : [],
      "remarks",
      this.$features.tax ? "taxAndService" : "service",
    ].flat();
  }

  async navigateAndPay() {
    this.manualConfirming = null;
    this.checkoutTab = "method";
    await Vue.nextTick();
    return await this.tryPay();
  }
}
