
import { ObjectID } from "@db";
import { Vue, Component, Prop, Watch, getID, FindType } from "@feathers-client";

interface CardDetails {
  queueingGroup: string;
  color: string;
  currentTicketName: string;
  currentCallingTicket: FindType<"shopQueueTickets">;
  waitingLength: number;
  room?: string;
  status: string;
  groupStatus: string;
}

@Component
export default class QueueingCardClinic extends Vue {
  @Prop() cardDetails: CardDetails;
  @Prop() width: number;
  @Prop() height: number;
  @Prop() noOfRows: number;

  @Watch("cardDetails.currentCallingTicket", { deep: true, immediate: true })
  async onCardDetailsUpdated() {
    if (this.cardDetails.currentCallingTicket && this.cardDetails.currentCallingTicket.status === "calling") {
      setTimeout(async () => {
        await this.$feathers.service("shopQueueTickets").patch(this.cardDetails.currentCallingTicket._id, {
          status: "called",
        });

        //update nothing to force the UI update only
        let ticketGroups = this.$shopSession.shopSessionData.queueingTicketGroups;
        if (ticketGroups) {
          this.$feathers.service("shopSessions").patch(this.$shopSession.shopSessionData._id, {
            queueingTicketGroups: ticketGroups,
          });
        }
      }, 5000); // 5 seconds
    }
  }
}
