
import { ObjectID } from "@db";
import { Vue, Component, Prop, VModel, Watch } from "@feathers-client";
import { queueingCard } from "types/types";

@Component
export default class WaitingListCard extends Vue {
  @VModel() calling: boolean;
  @Prop() cardDetails: queueingCard;
  @Prop() width: any;
  @Prop() height: any;
  @Prop() waitingList: boolean;
  @Prop() nextTicket: boolean;

  get ticketCard() {
    return !this.waitingList &&
      this.cardDetails.callingTicket &&
      this.cardDetails.callingTicket._id !== this.cardDetails._id
      ? this.cardDetails.callingTicket
      : this.cardDetails;
  }

  mounted() {}

  get alreadySeated() {
    return this.ticketCard ? this.ticketCard.alreadySeated : false;
  }

  get numCalling() {
    return this.ticketCard ? this.ticketCard.numCalling : false;
  }

  @Watch("numCalling")
  updateNextTicket() {
    this.calling = this.numCalling;
  }
}
