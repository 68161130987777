
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { TableView, TableItem, TableSessionRef } from "~/plugins/table";
import Dialog from "~/mixins/DialogTS";
import { TableSession } from "~/plugins/table/session";
import { SessionMoveAction } from "~/plugins/table/session";

@Component
export default class TableList extends mixins(Dialog) {
  @Prop()
  tableView: TableView;

  @Prop()
  openOrder: () => void;

  salesDate = ""

  openOrderItem(item: TableItem, session: TableSession) {
    item.select();
    session.selected = true;
    this.openOrder();
  }

  async clearSession(session: TableSession) {
    await session.atomic({
      endTime: new Date(),
    });
    this.tableView.clearSessionSelect();
  }

  async splitOrder(session: TableSession, ref: TableSessionRef) {
    const halfCap = (ref.capacity / 2) | 0;
    const rhalfCap = ref.capacity - halfCap;
    const newSession = this.tableView.addSession({
      startTime: session.item.startTime,
      section: session.item.section,
      tables: [],
      type: "dineIn",
    });
    const initAction: SessionMoveAction = {
      actions: [
        {
          from: {
            ref: ref,
          },
          to: {
            ref: ref,
            capacity: halfCap,
          },
        },
        {
          to: {
            ref: {
              session: newSession,
              table: ref.table,
              split: ref.table.nextSessionSplit,
            },
            capacity: rhalfCap,
          },
        },
      ],
      newSessions: [newSession],
    };
    const res = await this.$openDialog(
      import("~/components/table/SessionMoveDialog.vue"),
      {
        session,
        initAction,
        initOperation: "split",
      },
      {
        contentClass: "editor-dialog",
      },
    );
    if (res) return;
  }

  async moveTable(dragTo: TableItem, ref: TableSessionRef) {
    const toMerge = ref.session.tableRefs.find(it => it.table === dragTo);
    const totalCap = (toMerge?.capacity ?? 0) + ref.session.capacity;
    const newSplit: TableSessionRef = {
      session: ref.session,
      table: dragTo,
      split: dragTo.nextSessionSplit,
    };
    const initAction: SessionMoveAction = {
      actions: [
        {
          from: {
            ref: toMerge ? toMerge : ref,
          },
          to: {
            ref: toMerge ? toMerge : newSplit,
            capacity: totalCap,
          },
        },
      ],
    };
    if (toMerge) {
      initAction.actions.push({
        from: {
          ref: ref,
        },
      });
    }
    const res = await this.$openDialog(
      import("~/components/table/SessionMoveDialog.vue"),
      {
        session: ref.session,
        initAction,
        initOperation: toMerge ? "merge" : "move",
        mergeSource: {
          ref: ref,
        },
        initMergeTarget: dragTo,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  async createOrder(item: TableItem) {
    const capacity = Math.max(1, item.remainCapacity);

    const session = this.tableView.addSession({
      startTime: new Date(),
      tables: [
        {
          item: item.id as any,
          capacity,
          split: item.nextSessionSplit,
        },
      ],
      type: "dineIn",
    });

    await Vue.nextTick();
    session.sessionData.tableRefName = session.name;
    if (this.$shop.shopData.autoSelectionTimeSection) {
      if (session.availableSections.length) {
        session.delaySave({ section: session.availableSections[0]._id });
      } else {
        console.warn("No available sections");
      }
    }
    session.updateCachedDetails();
    await session.save();
    await session.handleSessionCreate();
  }
}
