import uuid from "uuid/v4";
import { EventEmitter } from "events";
import type iconv from "iconv-lite";

export interface PrintJobStatus {
  id: string;
  status: "queued" | "remoteQueued" | "printing" | "done" | "error" | "cancel";
  name: string;
  lastError?: string;
  createTime: Date;
  retryable: boolean;
  debugData?: PrintDebugData;
  size?: number;
}

export interface PrintDebugData {
  data: any;
  template: string;
  templateTag: string;
}

export class PrintJob extends EventEmitter {
  constructor(
    public name: string,
    public data: Buffer,
    id?: string,
    public opts?: any,
  ) {
    super();
    this.id = id || uuid();
  }

  id: string;
  status: "queued" | "remoteQueued" | "printing" | "done" | "error" | "cancel" = "queued";
  remoteId?: string;
  remoteOpts?: {
    pollingCheck?: boolean;
    autoRetry?: boolean;
    jobTimeout?: number;
    [key: string]: any;
  };
  uniqueJobId?: string;
  retryable = true;
  retryCount = 0;
  expires?: number;
  lastError: string = null;

  createTime = new Date();
  lastPreFailed = false;
  cashBoxOnly = false;

  debugData?: PrintDebugData;

  get size() {
    return this.data?.length ?? 0;
  }

  get type(): string {
    return this.opts?.type ?? "escpos";
  }

  getDataWithRetry(converter: typeof iconv) {
    if (this.createTime && this.expires) {
      if (Date.now() - this.createTime.getTime() > this.expires) {
        this.status = "cancel";
        this.lastError = "Print job expired";
        throw new Error("Print job expired");
      }
    }
    if (this.retryCount <= 1) return this.data;
    if (this.opts?.type === "escpos" && !this.opts?.epos) {
      const pre = converter.encode(`[重 Re/${this.retryCount}]\n`, this.opts?.codePage ?? "gbk", {
        stripBOM: true,
      });

      return Buffer.concat([
        Buffer.from([0x1b, 0x40, 0x1c, 0x57, 1, 0x1b, 0x21, 0x30]),
        pre,
        Buffer.from([0x1b, 0x40]),
        this.data,
      ]);
    }
    return this.data;
  }
}
