
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, getID } from "@feathers-client";
import ItemPickerListBase from "./ItemPickerListBase.vue";
import RadioIcon from "~/dep/feathers-client/components-internal/RadioIcon.vue";
import CheckIcon from "~/dep/feathers-client/components-internal/CheckIcon.vue";

@Component({
  components: {
    RadioIcon,
    CheckIcon,
    ItemPickerListBase,
  },
})
export default class ItemPickerList extends mixins(ItemPickerListBase) {
  @Prop({ type: Boolean })
  bottomBar: boolean;

  @Prop(Boolean)
  wrap: boolean;

  @Prop(Boolean)
  light: boolean;

  @Prop(Boolean)
  radio: boolean;

  @Prop(Boolean)
  check: boolean;

  @Prop({ type: String, default: "2.5rem" })
  itemHeight: string;

  @Prop()
  grid: string;

  isOpenItemPicker = false;

  onClickOutside() {
    this.isOpenItemPicker = false;
  }
}
