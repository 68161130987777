import { Component, Prop, Vue, Watch, mixins } from "@feathers-client";
import { SocketDevice, connect, supported } from "../ports/socket";
import {
  BaseRetrieval,
  BaseSaleRequest,
  BaseVoidRequest,
  EDCSaleOfflineRequest,
  FPSSaleRequest,
  HasePayType,
  OctopusSaleRequest,
  QREnquiry,
  TransactionType,
  decodePacket,
  EDCLastTransactionTrace,
  EDCSaleResponse,
  QRResponse,
  transactionStatus,
  CupSaleResponse,
  OctopusResponse,
  SettlementRequest,
  SettlementResponse,
} from "./struct";
import { Logger } from "../payments/logger";
import SerialDevice, { connect as connectSerial, portPicker, SerialPortSelection } from "../ports/serial";
import { EspDevice, EspDeviceSerial } from "../esp32";

export interface HASEConfig {
  posURL?: string;
  serial?: SerialPortSelection;
  refundPassword?: string;
  voidPassword?: string;
  password?: string;
  settlementPassword?: string;
  settlementHosts?: string;
  autoSettlement?: boolean;
}

export class HASEError extends Error {
  constructor(
    message: string,
    public responseReceived: boolean = false,
    public responseProcessed: boolean = false,
    public innerError?: Error,
    public buffer?: Buffer,
    public code?: string,
  ) {
    super(message);
  }
}

export type FetchResponseType = EDCSaleResponse | QRResponse | CupSaleResponse | OctopusResponse;
export type PayResponseType = EDCSaleResponse | QRResponse | CupSaleResponse | OctopusResponse;

@Component
export class HASEManager extends mixins(
  Logger({
    name: "HASE",
  }),
) {
  callSequence = 0;
  conn: SocketDevice = null;
  status: "notSetup" | "connected" | "connecting" | "disconnected" | "error" = "notSetup";

  needReconnect: boolean;
  serial: SerialDevice = null;
  webSerial: any = null;
  webSerialWriter: any = null;
  espSerial: EspDeviceSerial = null;

  get serialConfig() {
    return this.settings?.serial;
  }

  set serialConfig(s) {
    this.settings = {
      ...(this.settings || {}),
      serial: s,
    };
  }

  get hasConnection() {
    return !!(this.serial || this.webSerial || this.espSerial || this.conn);
  }

  incrementSequence() {
    this.log("[hase] increment sequence");
    this.callSequence = (this.callSequence + 1) % 10;
    localStorage.setItem("boxs_hase_callSequence", this.callSequence.toString());
  }

  restoreSequenceFromLocalStorage() {
    const sequence = localStorage.getItem("boxs_hase_callSequence");
    const sequenceNumber = sequence ? parseInt(sequence) : 0;
    this.callSequence = Number.isNaN(sequenceNumber) ? 0 : sequenceNumber;
  }

  _haseSupported: Promise<boolean | string[]>;
  haseSupported() {
    if (!this._haseSupported) {
      return (this._haseSupported = supported());
    }
    return this._haseSupported;
  }

  async checkHASESupported() {
    if (!(await this.haseSupported())) {
      const e = new Error(`${this.$t("bbpos.notSupported")}`);
      (e as any).className = "notSetup";
      throw e;
    }
  }

  async checkHASESetuped() {
    if(this.hasConnection) return;
    await this.checkHASESupported();
    if (!this.settings.posURL && !this.settings.serial) {
      const e = new Error(`${this.$t("bbpos.noUrl")}`);
      (e as any).className = "notSetup";
      throw e;
    }
  }

  @Watch("posURL")
  async onHASEUrl(v) {
    this.status = "disconnected";
    await this.disconnect();
    await this.tryConnect(false, true);
    // HASE URL Updated
  }

  async tryConnect(user?: boolean, reconnect?: boolean) {
    if (this.posURL) {
      if (!reconnect && this.conn) {
        return;
      }
      if(this.conn) {
        this.disconnect();
      }
      const url = this.posURL.split(":");
      const con = await connect(url[0], parseInt(url[1]));
      con.once("close", () => {
        if (this.conn === con) {
          this.disconnect();
          this.status = "disconnected";
        }
      });
      con.on("data", this._onData);
      this.conn = con;
      this.status = "connecting";
      await this.checkStatus();
    } else {
      if (!reconnect && this.serial) {
        return;
      }
      const device = await portPicker(this, reconnect ? undefined : this.serialConfig, user, undefined, "TurnCloud", "HASE");
      if (device) {
        this.disconnect();
        switch (device.type) {
          case "serial": {
            this.serialConfig = device;
            const serial = (this.serial = await connectSerial(device.port, {
              baudRate: 9600,
            }));
            this.serial.on("data", buf => {
              this._onData(buf);
            });
            this.serial.on("close", () => {
              if (this.serial === serial) {
                this.disconnect();
              }
            });
            break;
          }
          case "webSerial": {
            this.serialConfig = {
              type: "webSerial",
            };
            this.webSerial = device.device;
            try {
              this.webSerial.addEventListener("disconnect", () => {
                if (this.webSerial === device.device) {
                  this.disconnect();
                }
              });
              await this.webSerial.open({
                baudRate: 9600,
              });
              this.webSerialWriter = this.webSerial.writable.getWriter();
              const reader = this.webSerial.readable.getReader();
              const task = (async () => {
                while (true) {
                  const data = await reader.read();
                  this._onData(data.value);
                }
              })();
              task.catch(e => {
                console.error(e);
                if (this.webSerial === device.device) {
                  this.disconnect();
                }
              });
            } catch (e) {
              if (this.webSerial === device.device) {
                this.disconnect();
              }
            }
            break;
          }
          case "espSerial": {
            this.serialConfig = {
              type: "espSerial",
              espConfig: device.espConfig,
            };
            const serial = (this.espSerial = (device.device as EspDevice).getSerial());
            this.espSerial.on("data", buf => {
              if (this.espSerial === serial) {
                this._onData(buf);
              }
            });
            this.espSerial.on("close", () => {
              if (this.espSerial === serial) {
                this.disconnect();
              }
            });
            break;
          }
        }
        if (this.hasConnection) {
          this.status = "connected";
        }
      }
    }
  }

  async disconnect(save?: boolean) {
    if (this.conn) {
      this.conn.removeAllListeners("data");
      const conn = this.conn;
      this.conn = null;
      await conn.close();
    }
    if (this.serial) {
      this.serial.removeAllListeners("data");
      this.serial.close();
      this.serial = null;
    }
    if (this.webSerial) {
      try {
        await this.webSerial.close();
      } catch (e) {
        console.warn(e);
      }
      try {
        await this.webSerial.forget();
      } catch (e) {
        console.warn(e);
      }
      this.webSerial = null;
      this.webSerialWriter = null;
    }
    if (this.espSerial) {
      this.espSerial.removeAllListeners("data");
      this.espSerial.close();
      this.espSerial = null;
    }
    if (save) {
      this.serialConfig = null;
      this.posURL = null;
    }
    if (this.status === "connected") {
      this.status = "disconnected";
      this.needReconnect = true;
    }
  }

  currentTask: Promise<any>;

  async checkStatus() {
    if (!this.conn) {
      await this.tryConnect();
    }
    for (let i = 0; i < 10; i++) {
      try {
        const request = new EDCLastTransactionTrace();
        request.transactionType = "C";
        const buf = request.toBuffer();
        await this.callPOS(buf, 1000);
        this.status = "connected";
        return true;
      } catch (e) {
        if (e instanceof HASEError) {
          if (e.responseReceived) {
            this.status = "connected";
            return true;
          }
        }
        this.log(e);
      }
    }

    this.status = "error";
    return false;
  }

  @Prop()
  getSetting: () => HASEConfig;

  @Prop()
  setSetting: (v: HASEConfig) => void;

  @Prop()
  whenDestroy: () => void;

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get posURL() {
    return this.settings?.posURL;
  }
  set posURL(v) {
    this.settings = {
      ...(this.settings || {}),
      posURL: v,
    };
  }
  get refundPassword() {
    return this.settings?.refundPassword;
  }
  set refundPassword(v) {
    this.settings = {
      ...(this.settings || {}),
      refundPassword: v,
    };
  }
  get voidPassword() {
    return this.settings?.voidPassword;
  }
  set voidPassword(v) {
    this.settings = {
      ...(this.settings || {}),
      voidPassword: v,
    };
  }
  get password() {
    return this.settings?.password;
  }
  set password(v) {
    this.settings = {
      ...(this.settings || {}),
      password: v,
    };
  }

  get settlementPassword() {
    return this.settings?.settlementPassword ?? "000000";
  }
  set settlementPassword(v) {
    this.settings = {
      ...(this.settings || {}),
      settlementPassword: v,
    };
  }

  get settlementHosts() {
    return this.settings?.settlementHosts || "HANG_SENG,AMEX,HASE_CUP,CUP_QR,AWOP";
  }
  set settlementHosts(v) {
    this.settings = {
      ...(this.settings || {}),
      settlementHosts: v,
    };
  }

  get autoSettlement() {
    return this.settings?.autoSettlement ?? true;
  }

  set autoSettlement(v) {
    this.settings = {
      ...(this.settings || {}),
      autoSettlement: v,
    };
  }

  created() {
    this.onHASEUrl(this.posURL);
    this.restoreSequenceFromLocalStorage();
  }

  beforeDestroy() {
    this.disconnect();
    this.whenDestroy?.();
  }

  async cancelPOS() {}

  async refundHASEPayment(paymentId: string) {}

  async pay(data: { amount: number; tips: number; reference: string; type: HasePayType }): Promise<PayResponseType> {
    if (!this.hasConnection) {
      await this.tryConnect();
    }
    this.log("[hase] pay", JSON.stringify(data));
    const ecrRefNo = data.reference.padEnd(16, " ").slice(0, 16);
    const amount = Math.round(data.amount * 100)
      .toString()
      .padStart(12, "0")
      .slice(0, 12);
    const tips = Math.round(data.tips * 100)
      .toString()
      .padStart(12, "0")
      .slice(0, 12);
    const amountTips = (Math.round(data.amount + data.tips) * 100).toString().padStart(12, "0").slice(0, 12);

    const HasePayTypeToTransactionType = {
      [HasePayType.EDC]: TransactionType.EDC_SALE,
      // [HasePayType.EDC_OFFLINE]: TransactionType.EDC_OFFLINE,
      [HasePayType.CUP]: TransactionType.CUP_ONLINE_SALE,
      [HasePayType.QR]: TransactionType.QR_SALE,
      // [HasePayType.OCTOPUS]: TransactionType.OCTOPUS_SALE,
      // [HasePayType.FPS]: TransactionType.FPS_SALE,
    };

    if (data.type === HasePayType.EDC || data.type === HasePayType.CUP || data.type === HasePayType.QR) {
      const request = new BaseSaleRequest();
      request.type = HasePayTypeToTransactionType[data.type];
      request.ecrRefNo = ecrRefNo;
      request.amount = amount;
      request.tips = tips;
      const buf = request.toBuffer();
      return this.callPOS(buf) as any;
      //   } else if (
      //   data.type === HasePayType.EDC_OFFLINE
      // ) {
      //   const request = new EDCSaleOfflineRequest();
      //   request.type = HasePayTypeToTransactionType[data.type];
      //   request.ecrRefNo = ecrRefNo;
      //   request.amount = amount;
      //   request.tips = tips;
      //   const buf = request.toBuffer();
      //   return this.callPOS(buf);
      // } else if (data.type === HasePayType.FPS) {
      //   const request = new FPSSaleRequest();
      //   request.ecrRefNo = ecrRefNo;
      //   request.amount = amountTips;
      //   const buf = request.toBuffer();
      //   return this.callPOS(buf);
    } else if (data.type === HasePayType.OCTOPUS) {
      const request = new OctopusSaleRequest();
      request.ecrRefNo = ecrRefNo;
      request.amount = amountTips;
      const buf = request.toBuffer();
      return this.callPOS(buf) as any;
    }
  }

  async fetchPayment(meta: {
    methodSubType: HasePayType;
    reference: string;
    traceNumber: string;
  }): Promise<FetchResponseType> {
    if (!this.conn) {
      await this.tryConnect();
    }
    const TransactionTypeToRetrieve = {
      [HasePayType.EDC]: TransactionType.EDC_TRANSACTION_RETRIEVAL,
      // [HasePayType.EDC_OFFLINE]: TransactionType.EDC_TRANSACTION_RETRIEVAL,
      [HasePayType.CUP]: TransactionType.CUP_RETRIEVAL,
    };
    if (!meta.traceNumber) {
      throw new Error("Unable to fetch payment: Trace Number is not provided");
    }
    if (
      [
        HasePayType.EDC,
        // HasePayType.EDC_OFFLINE,
        HasePayType.CUP,
      ].includes(meta.methodSubType)
    ) {
      const request = new BaseRetrieval();
      request.type = TransactionTypeToRetrieve[meta.methodSubType];
      request.traceNumber = meta.traceNumber;
      const buf = request.toBuffer();
      return this.callPOS(buf) as any;
    } else if ([HasePayType.QR].includes(meta.methodSubType)) {
      const request = new QREnquiry();
      request.password = this.password;
      const buf = request.toBuffer();
      return this.callPOS(buf) as any;
    } else {
      throw new Error(`Unable to fetch payment: Unsupported Payment method ${meta.methodSubType}`);
    }
  }

  async refundPayment(data: { amount: number; tips: number; traceNumber: string; methodSubType: HasePayType }) {
    if (!this.conn) {
      await this.tryConnect();
    }
    const HasePayTypeToRefund = {
      [HasePayType.EDC]: TransactionType.EDC_VOID_REQUEST,
      [HasePayType.CUP]: TransactionType.CUP_VOID_TRANSACTION,
      [HasePayType.QR]: TransactionType.QR_VOID,
      // [HasePayType.EDC_OFFLINE]: TransactionType.EDC_VOID_REQUEST,
      // [HasePayType.OCTOPUS]: TransactionType.OCTOPUS_SALE,
      // [HasePayType.FPS]: TransactionType.FPS_SALE,
    };

    if (
      [
        HasePayType.EDC,
        HasePayType.CUP,
        HasePayType.QR,
        // HasePayType.EDC_OFFLINE,
      ].includes(data.methodSubType)
    ) {
      const request = new BaseVoidRequest();
      request.type = HasePayTypeToRefund[data.methodSubType];
      request.traceNumber = data.traceNumber?.padEnd(6, " ")?.slice(0, 6);
      request.voidPassword = this.voidPassword;
      const buf = request.toBuffer();
      const response = await this.callPOS(buf);
      if (
        response instanceof EDCSaleResponse ||
        response instanceof QRResponse ||
        response instanceof CupSaleResponse
      ) {
        if (response.responseCode === "00" || response.responseCode === "VD") {
          return response;
        } else {
          throw new Error("Failed to cancel payment: " + response.responseText);
        }
      } else {
        throw new Error(`Unable to cancel payment: Unsupported Payment method ${response.constructor.name}`);
      }
    } else {
      throw new Error(`Unable to cancel payment: Unsupported Payment method ${data.methodSubType}`);
    }
  }

  async settlement(host: string, retry = false, retryBusyCount = 0) {
    if (!this.conn) {
      this.log("[hase] settlement: no connection, try connect first");
      await this.tryConnect();
      this.log("[hase] settlement: connected");
    }
    this.log("[hase] settlement", host)
    const request = new SettlementRequest();
    request.type = TransactionType.EDC_SETTLEMENT_REQUEST;
    request.hostName = host;
    request.password = this.settlementPassword;
    const buf = request.toBuffer();

    try {
      const resp = await this.callPOS(buf) as SettlementResponse;

      if(resp.responseCode !== "00" && resp.responseCode !== "NT") {
        this.log("[hase] settlement failed", resp.responseText);
        throw new Error(`Settlement Failed: ${resp.responseText}`);
      }

      this.log("[hase] settlement success", host, resp.responseText);

      return resp;
    } catch(e) {
      this.log("[hase] settlement failed", host, e.message);
      if(e instanceof HASEError) {
        if(e.code === "X") {
          if(retryBusyCount < (retry ? 30 : 5)) {
            this.log("[hase] retry settlement (X)", host, `${retryBusyCount + 1}`)
            await new Promise(resolve => setTimeout(resolve, 5000));
            return this.settlement(host, retry, retryBusyCount + 1);
          }
        } else if(!e.code && e.message?.includes("Timeout")) {
          if(retry && retryBusyCount < 5) {
            await new Promise(resolve => setTimeout(resolve, 5000));
            this.log("[hase] retry settlement (timeout)", host, `${retryBusyCount + 1}`)
            return this.settlement(host, retry, retryBusyCount + 1);
          }
        }
      }

      throw e;
    }
  }

  async settlementAll(retry = false) {
    const hosts = this.settlementHosts.split(",");
    this.log("[hase] settlement all", this.settlementHosts);
    let errors: any[] = [];
    for (const host of hosts) {
      try {
        await this.settlement(host, retry);
      } catch(e) {
        this.log(`[hase] settlement ${host} failed`, e.message);
        errors.push(e);
      }
      await new Promise(resolve => setTimeout(resolve, 10000));
    }
    this.log("[hase] settlement all done");
    if(errors.length > 0) {
      throw new Error("Settlement Failed: " + errors.map(e => e.message).join(", "));
    }
  }

  async callPOS(content: Buffer, timeout?: number) {
    while (this.currentTask) {
      try {
        await this.currentTask;
      } catch (e) {
        this.log(e);
      }
    }
    const inner = this.callPOSInner(content);
    this.currentTask = inner;
    inner.finally(() => {
      if (this.currentTask === inner) {
        this.currentTask = null;
      }
    });
    return inner;
  }

  async callPOSInner(content: Buffer, timeout?: number) {
    let responseReceived = false;
    try {
      const response = await this._sendPacket(content, () => (responseReceived = true), timeout, );
      const responseObject = decodePacket(response);
      this.log("[hase] response", JSON.stringify(responseObject));
      return responseObject;
    } catch (error) {
      if (error instanceof HASEError) {
        throw error;
      }
      throw new HASEError(error.message, responseReceived, responseReceived, error);
    } finally {
      this.incrementSequence();
    }
  }

  private _generatePacket(content: Buffer): Buffer {
    const stx = Buffer.from([0x02]);
    const sequence = Buffer.from([this.callSequence]);
    const etx = Buffer.from([0x03]);
    const lrc = this._calculateLRC(Buffer.concat([sequence, content, etx]));
    return Buffer.concat([stx, sequence, content, etx, lrc]);
  }

  private async _sendPacket(content: Buffer, cb?: () => void, timeout?: number): Promise<Buffer> {
    let packet = this._generatePacket(content);
    this.log("[hase] generated packet", JSON.stringify(packet.toString()));

    if (!this.hasConnection) {
      throw new Error("Send Packet Failed: Connection not found.");
    }
    if (this._prevBuf) {
      throw new Error("Send Packet Failed: Previous Packet not processed.");
    }
    const abort = new AbortController();
    const result = this._waitResult(abort.signal, timeout);
    result.catch(() => {});
    let acked = false;
    try {
      // spec is 3, but we try 5
      for (let retryCount = 0; retryCount < 5; retryCount++) {

        this.log("[hase] send packet", JSON.stringify(packet.toString()));
        const ack = this._waitResponse();
        await this.serialSend(packet);
        if (!(await ack)) {
          this.incrementSequence();
          packet = this._generatePacket(content);
          this.log("[hase] generated packet", JSON.stringify(packet.toString()));
          continue;
        } else {
          cb?.();
          acked = true;
        }
        return await result;
      }
    } finally {
      if (!acked) {
        abort?.abort();
      }
    }
    throw new Error("Send Packet Failed: Retry Count Exceeded, Please restart the terminal.");
  }

  _waitResponse(): Promise<boolean> {
    return new Promise(resolve => {
      let timer = setTimeout(() => {
        timer = null;
        this.$off("response", handler);
        resolve(false);
      }, 1000);
      const handler = data => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        resolve(data === 0x06);
      };
      this.$once("response", handler);
    });
  }

  _waitResultCB: (data: Buffer) => void;
  _waitResult(signal?: AbortSignal, timeout: number = 15000, packetCb?: (buf: Buffer) => void): Promise<Buffer> {
    return new Promise((resolve, reject) => {
      let timer: any;

      const resetTimer = () => {
        clearTimer();
        timer = setTimeout(() => {
          timer = null;
          this._waitResultCB = null;
          reject(new Error("Timeout"));
        }, timeout);
      };

      function clearTimer() {
        timer && clearTimeout(timer);
        timer = null;
      }

      const cleanUp = () => {
        clearTimer();
        signal?.removeEventListener("abort", abortHandler);
        this._waitResultCB = null;
      };

      this._waitResultCB = buf => {
        packetCb?.(buf);
        switch (String.fromCharCode(buf[2])) {
          case TransactionType.PROCESSING: {
            resetTimer();
            break;
          }
          case TransactionType.TERMINAL_BUSY: {
            cleanUp();
            reject(new HASEError("Terminal Busy", true, false, null, buf, String.fromCharCode(buf[2])));
            break;
          }
          default: {
            cleanUp();
            resolve(buf);
            break;
          }
        }
      };

      const abortHandler = () => {
        cleanUp();
        reject(new Error("Aborted"));
      };

      resetTimer();
    });
  }

  _prevBuf: number[] = null;
  _recvTimeout: any;
  _expectLRC: boolean = false;
  private _onData(buf: Buffer) {
    for (let i = 0; i < buf.length; i++) {
      const c = buf[i];
      if (this._prevBuf) {
        this.resetTimeout();
        // receving data
        this._prevBuf.push(c);
        if (this._expectLRC) {
          // LRC
          this._expectLRC = false;
          const prevBuf = Buffer.from(this._prevBuf);
          this._prevBuf = null;
          this.log("[hase] received packet", JSON.stringify(prevBuf.toString()));
          if (this._waitResultCB && this._checkMessage(prevBuf)) {
            this._recvTimeout && clearTimeout(this._recvTimeout);
            this._recvTimeout = null;
            this._waitResultCB(prevBuf);
            this.ack();
          } else {
            if (this._waitResultCB) {
              console.warn("Invalid LRC", prevBuf.toString("hex"));
            }
            this._recvTimeout && clearTimeout(this._recvTimeout);
            this._recvTimeout = null;
            this.nak();
          }
        } else if (c === 0x02 || c === 0x06 || c === 0x15) {
          console.warn("Unexpected ", c, this._prevBuf);
        } else if (c === 0x03) {
          // ETX
          this._expectLRC = true;
        }
      } else if (c === 0x02) {
        this._prevBuf = [c];
        this.resetTimeout();
      } else if (c === 0x06 || c === 0x15) {
        this.log("[hase] ", c === 0x06 ? "ACK" : "NAK");
        this.$emit("response", c);
      }
    }
  }

  async resetTimeout() {
    this._recvTimeout && clearTimeout(this._recvTimeout);
    this._recvTimeout = setTimeout(() => {
      this._prevBuf = null;
      this._expectLRC = false;
      this.nak();
    }, 1000);
  }

  async serialSend(buf: Buffer) {
    if (this.conn) {
      await this.conn.send(buf);
    }
    if (this.serial) {
      await this.serial.send(buf);
    }
    if (this.webSerialWriter) {
      await this.webSerialWriter.write(buf);
    }
    if (this.espSerial) {
      await this.espSerial.write(buf);
    }
  }

  async ack() {
    try {
      const ack = Buffer.from([0x06]);
      await this.serialSend(ack);
    } catch (error) {
      this.log(error);
    }
  }

  async nak() {
    try {
      const nak = Buffer.from([0x15]);
      await this.serialSend(nak);
    } catch (error) {
      this.log(error);
    }
  }

  private _checkMessage(buf: Buffer): boolean {
    const content = buf.slice(1, buf.length - 1);
    const lrc = this._calculateLRC(content);
    return !!(lrc[0] === buf[buf.length - 1]);
  }

  private _calculateLRC(buffer: Buffer): Buffer {
    let lrc = 0;
    for (let i = 0; i < buffer.length; i++) {
      lrc = (lrc ^ buffer[i]) & 0xff;
    }
    return Buffer.from([lrc]);
  }

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./HASEDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }
}
