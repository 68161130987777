import { Vue, Component, Watch, FindType, getID, Prop, checkID, sortedIndexBy, Ref } from "@feathers-client";
import _ from "lodash";
import { formatCharacters, speak } from "pos-printer/utils/speech";

type TvOrderStatus = FindType<"tvOrderStatuses"> & { newItem?: boolean; marked?: boolean };
export type OdsSetting = FindType<"posDevices/posLogin">["odsSetting"];
type OdsMultiSetting = OdsSetting["multiSetting"]["items"][number];
type OdsSingleSetting = OdsSetting["singleSetting"]["items"][number];

@Component
export default class BaseShop extends Vue {
  get odsSetting() {
    return this.$shop.odsSetting;
  }

  get settings() {
    if (this.odsSetting.displayMode === "single") {
      return this.odsSetting.singleSetting;
    } else {
      return this.odsSetting.multiSetting;
    }
  }

  get singleSetting() {
    return this.odsSetting.displayMode === "single" ? this.odsSetting.singleSetting : null;
  }

  get multiSetting() {
    return this.odsSetting.displayMode === "multi" ? this.odsSetting.multiSetting : null;
  }

  get columns() {
    return this.settings.columns || 6;
  }

  get rows() {
    return this.settings.rows || 7;
  }

  get style() {
    return {
      "--ods-background": this.odsSetting.background || "#000",
      "--ods-text-color": this.odsSetting.textColor || "#fff",
      color: "var(--ods-text-color)",
      "--ods-column-size": `${100 / this.columns}%`,
      "--ods-shop-rows": this.pageSize + this.bannerSize,
      "--ods-shop-content-columns": this.columns,
      "--ods-shop-content-rows": this.rows,
      "--ods-flashing-speed": `${this.odsSetting.flashingSpeed || 0.5}s`,
      "--ods-flashing-count": this.odsSetting.flashingCount ? `${this.odsSetting.flashingCount * 2}` : "6",
    };
  }

  get pageSize() {
    if (this.singleSetting) {
      return Math.min(this.singleSetting.pageSize || 3, this.shops.length);
    } else {
      return this.multiSetting.rows;
    }
  }

  get bannerSize() {
    if (this.singleSetting) {
      return !this.singleSetting.showBanners || this.singleSetting.showBanners === "none" ? 0 : 1;
    } else {
      return 0;
    }
  }

  cleanupTimer = null;
  refreshTimer = null;

  mounted() {
    this.cleanupTimer = setInterval(() => {
      for (let shop of this.shops) {
        shop.cleanUp();
      }
      this.cleanUp();
    }, 1000);
    this.refreshTimer = setInterval(() => {
      this.reload();
    }, 15 * 60000);
  }

  beforeDestroy() {
    clearInterval(this.cleanupTimer);
    if (this.pageTimer) {
      clearInterval(this.pageTimer);
    }
    clearInterval(this.refreshTimer);
  }

  shops: OdsShop[] = [];
  shopPageIndex = 0;
  pageTimer = null;

  get shopPage() {
    return this.shopChunks[this.shopPageIndex] || [];
  }

  get shopChunks() {
    return _.chunk(this.shops, this.pageSize);
  }

  @Watch("odsSetting", { immediate: true })
  initOds() {
    if (this.pageTimer) {
      clearInterval(this.pageTimer);
      this.pageTimer = null;
    }
    for (const shop of this.shops) {
      shop.$destroy();
    }
    this.initLocale();
    if (this.singleSetting) {
      this.shops = this.singleSetting.items.map(
        item =>
          new OdsShop({
            propsData: {
              singleItem: item,
              odsSetting: this.odsSetting,
            },
            parent: this,
          }),
      );
    } else {
      this.shops = this.odsSetting.multiSetting.items.map(
        item =>
          new OdsShop({
            propsData: {
              multiItem: item,
              odsSetting: this.odsSetting,
            },
            parent: this,
          }),
      );
    }

    this.reload();

    if (this.shopChunks.length > 1) {
      this.pageTimer = setInterval(this.nextPage, (this.odsSetting.pageTime || 15) * 1000);
    }
  }

  async initLocale() {
    if (this.odsSetting.locale && this.odsSetting.locale !== this.$i18n.locale) {
      await this.$i18n.loadLocale(this.odsSetting.locale);
      this.$i18n.locale = this.odsSetting.locale;
    }

    if (!this.odsSetting.secondaryLocale || this.odsSetting.secondaryLocale === this.$i18n.locale) {
      this.$store.commit("SET_SECONDARY_LOCALE", null);
    } else if (this.odsSetting.secondaryLocale !== this.$store.state.secondaryLocale) {
      await this.$i18n.loadLocale(this.odsSetting.secondaryLocale);
      this.$store.commit("SET_SECONDARY_LOCALE", this.odsSetting.secondaryLocale);
    }
  }

  nextPage() {
    this.shopPageIndex = (this.shopPageIndex + 1) % this.shopChunks.length;
  }

  async reload() {
    for (let shop of this.shops) {
      shop.mark();
    }
    const items = await this.$feathers.service("posDevices/odsFetch").create({});

    if (this.singleSetting) {
      for (let item of items) {
        this.$emit(`waterBars/${item.waterBar}`, item);
      }
    } else {
      for (let item of items) {
        this.$emit(`shops/${item.shop}`, item);
      }
    }

    for (let shop of this.shops) {
      shop.cleanUp();
    }
  }

  onItem(item: TvOrderStatus) {
    if (this.singleSetting) {
      this.$emit(`waterBars/${item.waterBar}`, item, true);
    } else {
      this.$emit(`shops/${item.shop}`, item, true);
    }
  }

  async callTicket(item: TvOrderStatus, shop: OdsShop) {
    const code = shop.prefix && !item.code.startsWith(shop.prefix) ? `${shop.prefix}${item.code}` : item.code;
    const parts = formatCharacters(code, this.odsSetting.callTicketSSML);
    for (let i = 0; i < this.$shop.localOptions.callTicketCount; ++i) {
      await speak(
        parts,
        this.odsSetting.callTicketVoice,
        this.odsSetting.callTicketRate / 10,
        this.odsSetting.callTicketVolume / 100,
        this.$i18n.locale,
      );
    }
  }

  addCarouselItemOrCall(item: TvOrderStatus, shop: OdsShop, newItem?: boolean) {
    if (this.odsSetting.callTicket && newItem && item.status === "ready") {
      this.callTicket(item, shop);
    }
    this.addCarouselItem(item, shop, newItem);
  }

  addCarouselItem(item: TvOrderStatus, shop: OdsShop, newItem?: boolean) {}

  removeCarouselItem(item: TvOrderStatus, shop: OdsShop) {}

  updateCarouselItem(item: TvOrderStatus, shop: OdsShop, reorder: boolean) {}

  cleanUp() {}
}

@Component
export class OdsShop extends Vue {
  @Prop()
  singleItem: OdsSingleSetting;

  @Prop()
  multiItem: OdsMultiSetting;

  @Prop()
  odsSetting: OdsSetting;

  items: TvOrderStatus[] = [];
  itemDict: Record<string, TvOrderStatus> = {};

  get id() {
    return getID(this.singleItem || this.multiItem);
  }

  get logo() {
    return this.multiItem?.shop?.logo;
  }

  get prefix() {
    return this.multiItem?.prefix || "";
  }

  get color() {
    return (this.singleItem || this.multiItem)?.color || "#3B3838";
  }

  get parent() {
    return this.$parent as BaseShop;
  }

  created() {
    if (this.singleItem) {
      this.$parent.$on(`waterBars/${getID(this.singleItem.waterBar)}`, this.onItem);
    } else {
      this.$parent.$on(`shops/${getID(this.multiItem.shop)}`, this.onItem);
    }
  }

  beforeDestroy() {
    if (this.singleItem) {
      this.$parent.$off(`waterBars/${getID(this.singleItem.waterBar)}`, this.onItem);
    } else {
      this.$parent.$off(`shops/${getID(this.multiItem.shop)}`, this.onItem);
    }
  }

  sortItem(a: TvOrderStatus, b: TvOrderStatus) {
    if (a.updateTime === b.updateTime) {
      return a._id < b._id ? 1 : a._id > b._id ? -1 : 0;
    }
    return a.updateTime < b.updateTime ? 1 : a.updateTime > b.updateTime ? -1 : 0;
  }

  mark() {
    for (let item of this.items) {
      item.marked = true;
    }
  }

  onItem(item: TvOrderStatus, newItem?: boolean) {
    const cur = this.itemDict[getID(item)];
    const flag = this.matchItem(item);
    const idx = cur ? this.items.indexOf(cur) : -1;
    item.newItem = false;
    item.marked = false;

    if (flag) {
      if (cur) {
        let reorder = cur.updateTime !== item.updateTime;
        if (
          cur.updateTime !== item.updateTime &&
          (!idx || this.sortItem(this.items[idx - 1], item) !== 1) &&
          (idx === this.items.length - 1 || this.sortItem(this.items[idx + 1], item) !== -1)
        ) {
          if (newItem) {
            item.newItem = true;
          }
          this.items.splice(idx, 1);
          const nidx = sortedIndexBy(this.items, item, this.sortItem);
          this.items.splice(nidx, 0, item);
        }
        for (let [k, v] of Object.entries(item)) {
          Vue.set(cur, k, v);
        }
        this.parent.updateCarouselItem(cur, this, reorder);
      } else {
        if (newItem) {
          item.newItem = true;
        }
        const nidx = sortedIndexBy(this.items, item, this.sortItem);
        this.items.splice(nidx, 0, item);
        this.itemDict[getID(item)] = item;
        this.parent.addCarouselItemOrCall(item, this, newItem);
      }
    } else {
      if (cur) {
        this.items.splice(idx, 1);
        delete this.itemDict[getID(item)];
        this.parent.removeCarouselItem(item, this);
      }
    }
  }

  matchItem(item: TvOrderStatus) {
    if (!this.matchTimeItem(item)) {
      return false;
    }
    if (this.singleItem) {
      if ((this.singleItem.status || "ready") !== item.status) {
        return false;
      }
      return true;
    } else {
      if (!this.multiItem.filters?.length) {
        return item.status === "ready";
      }
      return this.multiItem.filters.some(filter => {
        if ((filter.status || "ready") !== item.status) {
          return false;
        }
        if (filter.waterBar && !checkID(item.waterBar, filter.waterBar)) {
          return false;
        }
        return true;
      });
    }
  }

  matchTimeItem(item: TvOrderStatus) {
    switch (item.status) {
      case "ready":
        if ((Date.now() - new Date(item.updateTime).getTime()) / 60000 >= this.odsSetting.readyKeepMinutes) {
          return false;
        }
        break;
      case "making":
        if ((Date.now() - new Date(item.updateTime).getTime()) / 60000 >= this.odsSetting.makingKeepMinutes) {
          return false;
        }
        break;
    }
    return true;
  }

  cleanUp() {
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = this.items[i];
      if (!this.matchTimeItem(item) || item.marked) {
        this.items.splice(i, 1);
        delete this.itemDict[getID(item)];
        this.parent.removeCarouselItem(item, this);
      }
    }
  }
}
