
import { Component, Prop, Vue, Watch, FindType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import Editor from "@schemaEditor/Editor.vue";

@Component({
  components: {
    Editor,
  },
})
export default class OrderList extends Vue {
  orderList = false;

  defaultSort = ["hasPendingAction", "date"];
  defaultSortDesc = [true, true];

  preEdit(item: FindType<"tableSessions">) {
    this.orderList = false;
    this.$emit("openOrder", item);
    return null;
  }

  get hasActions() {
    return this.$tableManager.tableSessionActions.length > 0;
  }

  async clearPendings() {
    await this.$feathers.service("tableSessionActions/clear").create({});
  }

  headerFields = [
    "sessionName",
    "type",
    "status",
    "date",
    "amount",
    "user",
    "tableRefName",
    "actualCapacity",
    "payments.paymentMethod",
    "payments.paymentMethodNetwork",
    "mergedTo",
    "hasPendingAction",
  ];
}
