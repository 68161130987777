
import { Prop, Component, Watch, mixins, FindType, FindPopRawType, getID, Ref, Vue } from "@feathers-client";
import { TableItem, TableView } from "~/plugins/table/view";

enum Direction {
  "UP",
  "DOWN",
}

@Component({})
export default class ViewPicker extends Vue {
  get currentViewSection() {
    return this.$tableManager.views.map(view => view);
  }

  mounted() {
    this.initObserver();
    this.onViewingSection = this.currentViewSection?.[0]?.id;
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  previousX = 0;
  currentX = 0;
  direction: Direction = null;
  onViewingSection = null;

  handleScroll(e) {
    this.currentX = e?.currentTarget?.scrollTop;
    this.direction = this.previousX > this.currentX ? Direction.UP : Direction.DOWN;
    this.previousX = this.currentX;
  }

  scrollToView(view: TableView) {
    const viewHeader = this.$el.querySelector(`[view=${JSON.stringify(view.id)}]`);
    if (viewHeader) {
      viewHeader.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  observer: IntersectionObserver = null;

  initObserver() {
    const options: IntersectionObserverInit = {
      root: document.querySelector(".contentsRoot"),
      threshold: 0.5,
    };

    const nodeList = this.$el.querySelectorAll(".tableView");
    const contents = [nodeList].map(it => it)[0];

    const callback: IntersectionObserverCallback = entries => {
      entries.forEach((item: IntersectionObserverEntry) => {
        if (this.currentX < contents[0].scrollHeight) {
          this.onViewingSection = contents[0].id;
        }

        if (item.isIntersecting) {
          // 0 is UP
          if (this.direction === 0 && item.boundingClientRect.bottom > 0) {
            this.onViewingSection = item.target.id;
          }
          // 1 is DOWN
          if (this.direction === 1 && item.boundingClientRect.top >= 0) {
            this.onViewingSection = item.target.id;
          }
          // return to the first selector
        }
      });
    };

    this.observer = new IntersectionObserver(callback, options);
    this.$el.querySelectorAll(".tableView").forEach(el => {
      this.observer.observe(el);
    });
  }

  hasSessions(view: TableItem) {
    return view.sessions?.[0];
    // return view.sessions.find(it => it.session.status === "ongoing");
  }
}
