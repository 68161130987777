import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand } from "../index";
import type { PrintJob, PrintQueue } from "../printQueue";
import { sign, init, jwk } from "../utils/cloudAuth";
import { FeieyunPrintSequence } from "../printSequence/feieyun";
import { XPrintSequence } from "../printSequence/xprinter/escpos";
import { wrapVue } from "pos-printer";
import { CanvasLabelSequence } from "../labelSequence/canvas";
import { HTMLPrintSequence } from "../printSequence/html";
import { CanvasPrintJob } from "../labelSequence/canvas/job";
import { renderToHtml } from "../labelSequence/canvas/renderer";

export interface DebugConf extends PrinterConf {
  port: "debug";
}

export class DebugPrinter extends PrinterBase<DebugConf, DebugConf> {
  constructor(server: PrinterServer, conf: DebugConf) {
    super(server, conf);
  }

  createSequence<T>(context?: Vue): T {
    if (this.conf.type === "thermal" && this.conf.opts?.escpos !== "escposGraph") {
      return new HTMLPrintSequence(wrapVue(context ?? this.parent.context), this) as any;
    } else if (this.conf.type === "label") {
      return new CanvasLabelSequence(wrapVue(context ?? this.parent.context), this) as any;
    }
    return super.createSequence<T>(context);
  }

  get printerOpts() {
    if (this.conf.type === "thermal") {
      if (this.conf.opts?.escpos || this.conf.opts?.features?.escpos) {
        return super.printerOpts;
      }
    }
    return [];
  }

  onDisconnectedCore() {}

  async initCore() {}

  async tryConnectCore() {
    await init();
  }

  async printCore(job: PrintJob) {
    if(this.conf.opts?.exportData) {
      return;
    }
    try {
      let html: string;
      if (job instanceof CanvasPrintJob) {
        html = await renderToHtml(job);
      } else {
        if (!job.data.length) return;
        html = `<html><head><style>
:root { color-scheme: only light; } body { color: black; background: white; } html, body { padding: 0; margin: 0; } 
</style></head><body style="margin: ${
          job.opts.bodyMarginEm ?? "8px"
        }">${job.data.toString()}</body></html>`;
      }

      await (this.parent.context as any).$openDialog(
        // @ts-ignore
        import("../dialogs/DebugPreview.vue"),
        {
          html,
        },
        {
          contentClass: "editor-dialog",
        },
      );
    } catch (e) {
      if (e.response && e.response.data) {
        throw new Error(e.response.data.message);
      } else {
        throw e;
      }
    }
  }

  async disconnectCore() {}

  async requestNewDeviceCore() {
    return this.conf;
  }
}
