import { Component, Vue, Prop, mixins } from "@feathers-client";
import _ from "lodash";
import {
  Secrets,
  Spi as SpiClient,
  SuccessState,
  TerminalConfigurationResponse,
  TransactionOptions,
  TransactionType,
} from "@mx51/spi-client-js";
import { PairingFlowState } from "@mx51/spi-client-js/SpiModels";
import { PurchaseData } from "@mx51/spi-client-js/Purchase";
import { Data } from "@mx51/spi-client-js/Messages";
import { Logger } from "../payments/logger";

export interface SimplePaymentIntegrationConfig {
  pairingInput?: PairingInput;
  printMerchantCopy?: boolean;
  verifySignOnTerminal?: boolean;
}

export interface PairingInput {
  posId?: string;
  tenantCode: string;
  serialNumber: string;
  eftposAddress: string;
  autoAddressResolution?: boolean;
  testMode: boolean;
}

export interface SimplePaymentIntegrationTransactionRaw {
  [key: string]: any;
  PosRefId: string;
  Success: string;
  Type: string;
  AmountCents?: number;
  AttemptingToCancel?: boolean;
  AwaitingSignatureCheck?: boolean;
  AwaitingPhoneForAuth?: boolean;
  AwaitingGtResponse?: boolean;
  Finished: boolean;
  SignatureRequiredMessage?: {
    _receiptToSign?: string;
  };
  PhoneForAuthRequiredMessage?: string;
  DisplayMessage?: string;
  Response: {
    Data: PurchaseData & Data;
  };
}

export interface SimplePaymentIntegrationTransaction {
  status: "success" | "failed" | "unknown";
  merchantReceipt: string;
  merchantReceiptPrinted: boolean;
  customerReceipt: string;
  customerReceiptPrinted: boolean;
  transactionType: "purchase" | "refund" | "other";
  amount?: number;
  tips?: number;
  surcharge?: number;
  message?: string;
  network?: string;
  raw: SimplePaymentIntegrationTransactionRaw;
}

let spi: SpiClient;

@Component
export class SimplePaymentIntegrationManager extends mixins(
  Logger({
    name: "SimplePaymentIntegration",
  }),
) {
  status: "notSetup" | "connected" | "disconnected" | "pairing" | "connecting" = "notSetup";

  posVendorId = "BOXS";
  apiKey = "R7uBn10YuEa6IwloEUwymJlQ9dpoGoBs";
  confirmMessage = "";
  confirmCode = "";
  posNeedConfirm = false;
  terminalInfo: {
    commsSelected: string;
    merchantId: string;
    paVersion: string;
    paymentInterfaceVersion: string;
    pluginVersion: string;
    serialNumber: string;
    terminalId: string;
    terminalModel: string;
  } = null;

  get formattedTerminalInfo() {
    return this.terminalInfo
      ? [
          `Comms Selected: ${this.terminalInfo.commsSelected}`,
          `Merchant Id: ${this.terminalInfo.merchantId}`,
          `PA Version: ${this.terminalInfo.paVersion}`,
          `Payment Interface Version: ${this.terminalInfo.paymentInterfaceVersion}`,
          `Plugin Version: ${this.terminalInfo.pluginVersion}`,
          `Serial Number: ${this.terminalInfo.serialNumber}`,
          `Terminal Id: ${this.terminalInfo.terminalId}`,
          `Terminal Model: ${this.terminalInfo.terminalModel}`,
        ].join("\n")
      : "";
  }

  created() {
    this.connect().catch(console.error);

    document.addEventListener("StatusChanged", this.statusChanged);
    document.addEventListener("SecretsChanged", this.secretsChanged);
    document.addEventListener("PairingFlowStateChanged", this.pairingFlowStateChanged);
    document.addEventListener("TxFlowStateChanged", this.txFlowStateChanged);
    document.addEventListener("DeviceAddressChanged", this.deviceAddressChanged);
  }

  @Prop()
  getSetting: () => SimplePaymentIntegrationConfig;

  @Prop()
  setSetting: (v: SimplePaymentIntegrationConfig) => void;

  @Prop()
  whenDestroy: () => void;

  beforeDestroy() {
    this.whenDestroy?.();

    document.removeEventListener("StatusChanged", this.statusChanged);
    document.removeEventListener("SecretsChanged", this.secretsChanged);
    document.removeEventListener("PairingFlowStateChanged", this.pairingFlowStateChanged);
    document.removeEventListener("TxFlowStateChanged", this.txFlowStateChanged);
    document.removeEventListener("DeviceAddressChanged", this.deviceAddressChanged);
  }

  get settings() {
    return this.getSetting?.() || {};
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get pairingInput() {
    return this.settings.pairingInput || null;
  }

  set pairingInput(v) {
    this.settings = {
      ...this.settings,
      pairingInput: v,
    };
  }

  msecrets: Secrets = null;
  get secrets() {
    if (this.msecrets) {
      return this.msecrets;
    }
    const secrets = localStorage["simplePaymentIntegrationSecerts"];
    if (secrets) {
      try {
        return (this.msecrets = JSON.parse(secrets) as Secrets);
      } catch (e) {
        console.warn(e);
      }
    }
    return null;
  }

  set secrets(v) {
    this.msecrets = v;
    localStorage["simplePaymentIntegrationSecerts"] = v ? JSON.stringify(v) : "";
  }

  get printMerchantCopy() {
    return this.settings.printMerchantCopy || false;
  }

  set printMerchantCopy(v) {
    this.settings = {
      ...this.settings,
      printMerchantCopy: v,
    };
  }

  get verifySignOnTerminal() {
    return this.settings.verifySignOnTerminal ?? true;
  }

  set verifySignOnTerminal(v) {
    this.settings = {
      ...this.settings,
      verifySignOnTerminal: v,
    };
    if (spi) {
      spi.Config.SignatureFlowOnEftpos = v;
    }
  }

  get receiptOptions() {
    const receiptOptions = new TransactionOptions();
    receiptOptions.SetMerchantReceiptHeader("");
    receiptOptions.SetMerchantReceiptFooter("");
    receiptOptions.SetCustomerReceiptHeader("");
    receiptOptions.SetCustomerReceiptFooter("");
    return receiptOptions;
  }

  @Prop()
  getInfo: () => {
    shopId?: string;
    shopName?: string;
    cashierId?: string;
    cashierShortId?: string;
    cashierName?: string;
    countryCode?: string;
    posVersion?: string;
  };

  get shopInfo() {
    return this.getInfo?.() || {};
  }

  get cashierId() {
    return this.shopInfo.cashierId || "";
  }

  get cashierShortId() {
    return this.shopInfo.cashierShortId || "";
  }

  get posId() {
    return (this.cashierShortId || this.cashierId || "POS").replace(/[^a-zA-Z0-9]/g, "").slice(-16);
  }

  get countryCode() {
    return this.shopInfo.countryCode || "AU";
  }

  get posVersion() {
    return this.shopInfo.posVersion || "0.0.0";
  }

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./SimplePaymentIntegrationDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async connect(user?: boolean, reconnect?: boolean) {
    const pairingInput = this.pairingInput;
    if (!pairingInput) {
      return;
    }

    /**
     * Instantiate the SPI library as an instance object
     *
     * @param posId - required
     * @param serialNumber - required
     * @param eftposAddress - required
     * @param secrets - can be null if not previously paired (used to reconnect to the terminal without having to "pair" again)
     **/
    let needStart = false;
    if (!spi) {
      spi = new SpiClient(this.posId, pairingInput.serialNumber, pairingInput.eftposAddress || "", this.secrets);
      needStart = true;
    }
    spi._log = {
      info: this.log.bind(this, "[info]"),
      warn: this.log.bind(this, "[warn]"),
      error: this.log.bind(this, "[error]"),
      debug: this.log.bind(this, "[debug]"),
      trace: this.log.bind(this, "[trace]"),
    } as any;
    spi.SetPosId(this.posId);
    spi.SetSerialNumber(pairingInput.serialNumber);
    spi.SetEftposAddress(pairingInput.eftposAddress || "");
    spi.SetPosInfo(this.posVendorId, this.posVersion);
    spi.SetTenantCode(pairingInput.tenantCode);
    spi.SetDeviceApiKey(this.apiKey);
    spi.SetAutoAddressResolution(pairingInput.autoAddressResolution);
    spi.SetSecureWebSockets(true);
    spi.SetTestMode(pairingInput.testMode);
    spi.Config.PrintMerchantCopy = this.printMerchantCopy;
    spi.Config.SignatureFlowOnEftpos = this.verifySignOnTerminal;
    spi.TerminalConfigurationResponse = this.terminalConfiguration;
    spi.TerminalStatusResponse = this.terminalStatus;
    spi.TransactionUpdateMessage = this.transactionUpdateMessage;
    spi.BatteryLevelChanged = this.batteryLevelChanged;

    if (needStart) {
      spi.Start();
      if (this.secrets) {
        this.status = "connecting";
      }
    }
  }

  async disconnect(save?: boolean) {
    if (!spi) return;
    if (save) {
      spi.Unpair();
    }
    spi._conn?.Disconnect?.();
    this.$store.commit('SET_SUCCESS', this.$t('simplePaymentIntegration.unpairSuccess'));
  }

  async settlement() {
    spi.AckFlowEndedAndBackToIdle();
    const id = crypto.randomUUID();
    const detailTask = new Promise<SimplePaymentIntegrationTransactionRaw>((resolve, reject) => {
      this.txResponses["settlement_" + id] = { resolve, reject };
    });
    spi.InitiateSettleTx(id);
    const t = await detailTask;
    return this.convertTransaction(t);
  }

  statusCb: (status: string) => void;

  async pay(
    info: {
      payment: string;
      amount: number;
      tips?: number;
      cashout?: number;
      surchargeAmount?: number;
      promptForCashout?: boolean;
      moto?: boolean;
    },
    printCb: (receipt: string) => Promise<void>,
    statusCb?: (status: string) => void,
    signal?: AbortSignal,
  ) {
    spi.AckFlowEndedAndBackToIdle();
    const detailTask = new Promise<SimplePaymentIntegrationTransactionRaw>((resolve, reject) => {
      this.txResponses["purchase_" + info.payment] = {
        resolve,
        reject,
        printCb,
        statusCb,
      };
    });
    if (info.moto) {
      const pay = spi.InitiateMotoPurchaseTx(
        info.payment,
        Math.round(info.amount * 100),
        Math.round((info.tips ?? 0) * 100),
        undefined,
        this.receiptOptions,
      );
      if (!pay.Initiated) {
        throw new Error("Initiate purchase failed");
      }
    } else {
      const pay = spi.InitiatePurchaseTxV2(
        info.payment,
        Math.round(info.amount * 100),
        Math.round((info.tips ?? 0) * 100),
        Math.round((info.cashout ?? 0) * 100),
        info.promptForCashout ?? false,
        this.receiptOptions,
        Math.round((info.surchargeAmount ?? 0) * 100),
      );
      if (!pay.Initiated) {
        throw new Error("Initiate purchase failed");
      }
    }

    if(signal) {
      signal.addEventListener("abort", () => {
        this.cancel().catch(console.error);
      });
    }

    const detail = await detailTask;

    return this.convertTransaction(detail);
  }

  convertTransaction(detail: SimplePaymentIntegrationTransactionRaw): SimplePaymentIntegrationTransaction {
    let status = "";
    let transactionType = "";
    switch (detail.Success) {
      case SuccessState.Success:
        status = "success";
        // Display the successful transaction UI adding detail for user (e.detail.Response.Data.host_response_text)
        // Close the sale on the POS
        switch (detail.Type) {
          case TransactionType.Purchase:
            // Perform actions after purchases only
            transactionType = "purchase";
            break;
          case TransactionType.Refund:
            // Perform actions after refunds only
            transactionType = "refund";
            break;
          default:
            transactionType = "other";
          // Perform actions after other transaction types
        }
        break;
      case SuccessState.Failed:
        status = "failed";
        // Display the failed transaction UI adding detail for user:
        // e.detail.Response.Data.error_detail
        // e.detail.Response.Data.error_reason
        // if (e.detail.Response.Data.host_response_text) {
        //     e.detail.Response.Data.host_response_text
        // }
        break;
      case SuccessState.Unknown:
        // Display the manual transaction recovery UI
        status = "unknown";
        break;
      default:
        throw new Error("Invalid success state");
      // Throw error: invalid success state
    }

    const purchaseData = detail.Response?.Data;

    return {
      status,
      merchantReceipt: this.fixFormat(purchaseData?.merchant_receipt),
      merchantReceiptPrinted: purchaseData?.merchant_receipt_printed,
      customerReceipt: this.fixFormat(purchaseData?.customer_receipt),
      customerReceiptPrinted: purchaseData?.customer_receipt_printed,
      transactionType: detail.Type,
      amount: typeof purchaseData?.purchase_amount === "number" ? purchaseData?.purchase_amount / 100 : undefined,
      tips: typeof purchaseData?.tip_amount === "number" ? purchaseData?.tip_amount / 100 : undefined,
      surcharge: typeof purchaseData?.surcharge_amount === "number" ? purchaseData?.surcharge_amount / 100 : undefined,
      message: purchaseData?.error_detail
        ? `${purchaseData?.error_detail}${
            purchaseData?.host_response_text ? `: ${purchaseData?.host_response_text}` : ""
          }`
        : purchaseData?.error_reason ?? detail.DisplayMessage,
      network: purchaseData?.scheme_name,
      raw: detail,
    } as SimplePaymentIntegrationTransaction;
  }

  fixFormat(str: string) {
    if (!str) return str;
    return str.replace(/\r\n\s*?placeholder\s*?\r\n/gm, "");
  }

  async refund(info: { payment: string; amount: number; surchargeAmount?: number }) {
    spi.AckFlowEndedAndBackToIdle();
    const detailTask = new Promise<SimplePaymentIntegrationTransactionRaw>((resolve, reject) => {
      this.txResponses["refund_" + info.payment] = { resolve, reject };
    });
    const refund = spi.InitiateRefundTx(info.payment, info.amount, true, this.receiptOptions);
    if (!refund.Initiated) {
      throw new Error("Initiate refund failed: " + refund.Message);
    }
    return this.convertTransaction(await detailTask);
  }

  async query(id: string) {
    spi.AckFlowEndedAndBackToIdle();
    const detailTask = new Promise<SimplePaymentIntegrationTransactionRaw>((resolve, reject) => {
      this.txResponses["get_" + id] = { resolve, reject };
    });
    const result = spi.InitiateGetTx(id);
    if (!result.Initiated) {
      throw new Error(result.Message);
    }
    return this.convertTransaction(await detailTask);
  }

  isInProgress(id: string) {
    return (
      !!this.txResponses["purchase_" + id] || !!this.txResponses["refund_" + id] || !!this.txResponses["get_" + id]
    );
  }

  clearInProgress(id: string) {
    let cb = this.txResponses["purchase_" + id];
    if (cb) {
      cb.reject?.(new Error("Transaction cancelled"));
      delete this.txResponses["purchase_" + id];
    }
    cb = this.txResponses["refund_" + id];
    if (cb) {
      cb.reject?.(new Error("Transaction cancelled"));
      delete this.txResponses["refund_" + id];
    }
    cb = this.txResponses["get_" + id];
    if (cb) {
      cb.reject?.(new Error("Transaction cancelled"));
      delete this.txResponses["get_" + id];
    }
  }

  async cancel() {
    const result = spi.CancelTransaction();
    if(!result.Valid) {
      throw new Error(result.Message);
    }
  }

  waitingPairing = false;

  async pair(pairingInput: {
    posId?: string;
    tenantCode: string;
    serialNumber: string;
    eftposAddress: string;
    autoAddressResolution?: boolean;
    testMode: boolean;
  }) {
    await this.disconnect();
    pairingInput.posId = pairingInput.posId || this.posVendorId;
    pairingInput.autoAddressResolution = pairingInput.autoAddressResolution ?? true;
    this.pairingInput = pairingInput;
    this.posNeedConfirm = false;
    this.confirmCode = this.confirmMessage = "";

    await this.disconnect();
    await Vue.nextTick();

    this.secrets = null;
    spi.Unpair();
    await this.connect();
    if (!spi.Pair()) {
      throw new Error("Pairing failed");
    }
    this.waitingPairing = true;
    setTimeout(() => {
      if (this.waitingPairing) {
        this.status = "notSetup";
        this.$store.commit("SET_ERROR", "Pairing timeout");
      }
    }, 5000);
  }

  cancelPair() {
    spi.PairingCancel();
  }

  async resolveAddress(input: { posId?: string; tenantCode: string; serialNumber: string; testMode: boolean; }) {
    await this.disconnect();
    this.pairingInput = {
      ...(this.pairingInput || {}),
      posId: input.posId || this.posVendorId,
      tenantCode: input.tenantCode,
      serialNumber: input.serialNumber,
      eftposAddress: "",
      testMode: input.testMode ?? false,
      autoAddressResolution: true,
    };
    await this.connect();
  }

  /**
   * Event: StatusChanged
   * This method will be called when the terminal connection status changes
   **/
  statusChanged(e: any) {
    this.log("Status changed", e);

    switch (e?.detail) {
      case "PairedConnected": {
        this.status = "connected";
        break;
      }

      case "PairedConnecting": {
        this.status = "connecting";
        break;
      }

      case "Paired": {
        this.status = "connected";
        break;
      }

      case "Unpaired": {
        this.secrets = null;
        this.status = "notSetup";
        this.terminalInfo = null;
        break;
      }
    }
  }

  /**
   * Event: SecretsChanged
   * For saving secrets after terminal paired (in order to keep current terminal instance activated)
   **/
  secretsChanged(e: any) {
    this.log("Secrets changed", e);

    if (e?.detail) {
      this.secrets = e.detail;
    }
  }

  /**
   * Event: PairingFlowStateChanged
   * To get latest updates on the pairing process
   **/
  pairingFlowStateChanged(e: any) {
    this.log("Pairing flow state changed", e);
    const detail: PairingFlowState = e.detail;
    this.log(
      detail?.AwaitingCheckFromEftpos && detail?.AwaitingCheckFromPos
        ? `${detail?.Message}: ${detail?.ConfirmationCode}`
        : detail?.Message,
    );

    if (!detail) return;

    // if paring flow state of Successful and Finished turns to true, then we call terminal back to idle status
    if (detail.Finished) {
      this.waitingPairing = false;
      if (detail.Successful) {
        spi.AckFlowEndedAndBackToIdle();
      } else {
        this.status = "notSetup";
        this.$store.commit("SET_ERROR", detail.Message);
      }
    } else {
      if (detail.AwaitingCheckFromPos || detail.AwaitingCheckFromEftpos) {
        this.waitingPairing = false;
      }
      this.posNeedConfirm = detail.AwaitingCheckFromPos;
      this.status = "pairing";
      if (detail.ConfirmationCode) {
        this.confirmMessage = detail?.Message;
        this.confirmCode = detail.ConfirmationCode;
      }
    }
  }

  confirmPairingCode(confirm: boolean) {
    if (confirm) {
      spi.PairingConfirmCode();
    } else {
      spi.PairingCancel();
      this.status = "notSetup";
    }
  }

  txResponses: Record<
    string,
    {
      resolve?: (value?: any) => void;
      reject?: (err: any) => void;
      printCb?: (receipt: string) => Promise<void>;
      statusCb?: (status: string) => void;
    }
  > = {};

  _currentDialogId: string;

  /**
   * Event: TxFlowStateChanged
   * To get latest updates on the transaction flow
   **/
  async txFlowStateChanged(e) {
    this.log("Transaction flow state changed", e);

    if (!e.detail) return;

    let prefix = "";
    const detail: SimplePaymentIntegrationTransactionRaw = e.detail;
    switch (e.detail.Type) {
      case TransactionType.Purchase:
      case TransactionType.MOTO:
        // Perform actions after purchases only
        prefix = "purchase_";
        break;
      case TransactionType.Refund:
        // Perform actions after refunds only
        prefix = "refund_";
        break;
      case TransactionType.GetTransaction:
        // Perform actions after get transaction only
        prefix = "get_";
        break;
      case TransactionType.Settle:
        // Perform actions after settle only
        prefix = "settlement_";
        break;
      default:
      // Perform actions after other transaction types
    }
    const cb = this.txResponses[prefix + detail.PosRefId];

    if (detail.Finished) {
      if (cb) {
        cb.resolve?.(detail);
        delete this.txResponses[prefix + detail.PosRefId];
      }
    }

    if (detail.AwaitingSignatureCheck) {
      // Print the receipt: e.detail.SignatureRequiredMessage._receiptToSign
      // Display the signature confirmation UI
      const receipt = this.fixFormat(detail.SignatureRequiredMessage?._receiptToSign);
      let curId: string;
      await cb?.printCb?.(receipt);
      const resp = await this.$openDialog(
        // @ts-ignore
        import("./SignatureConfirmDialog.vue"),
        {
          manager: this,
          receipt: receipt,
          printCb: cb?.printCb,
          message: detail.DisplayMessage,
        },
        {
          maxWidth: "80%",
          contentClass: "editor-dialog",
        },
        id => {
          this._currentDialogId = curId = id;
        },
      );
      if (curId === this._currentDialogId) {
        spi.AcceptSignature(!!resp);
      }
      this._currentDialogId = null;
    } else if (this._currentDialogId) {
      const e = { result: false, id: this._currentDialogId };
      this._currentDialogId = null;
      this.$root.$emit("modalResult", e);
    }

    if (detail.AwaitingPhoneForAuth) {
      // Display the MOTO phone authentication UI
    } else if (detail.Finished) {
    }
  }

  deviceAddressChanged(e: any) {
    this.log("Device address changed", e);

    if (e?.detail.ip) {
      this.pairingInput = {
        ...this.pairingInput,
        eftposAddress: e.detail.ip,
      };
      this.$emit("deviceAddressChanged", e.detail.ip);
    } else if (e?.detail.fqdn) {
      this.pairingInput = {
        ...this.pairingInput,
        eftposAddress: e.detail.fqdn,
      };
      this.$emit("deviceAddressChanged", e.detail.fqdn);
    }
  }

  terminalConfiguration(e: any) {
    this.log("Terminal configuration", JSON.stringify(e));

    this.terminalInfo = e.Data
      ? {
          commsSelected: e.Data.comms_selected,
          merchantId: e.Data.merchant_id,
          paVersion: e.Data.pa_version,
          paymentInterfaceVersion: e.Data.payment_interface_version,
          pluginVersion: e.Data.plugin_version,
          serialNumber: e.Data.serial_number,
          terminalId: e.Data.terminal_id,
          terminalModel: e.Data.terminal_model,
        }
      : null;
  }

  terminalStatus(e: any) {
    this.log("Terminal status", JSON.stringify(e));
  }

  transactionUpdateMessage(e: any) {
    this.log("Transaction update message", JSON.stringify(e));
    const refId = e?.Data?.pos_ref_id;
    if (refId) {
      const cb = this.txResponses["purchase_" + refId];
      if (cb?.statusCb) {
        cb.statusCb(e.Data.display_message_text);
      }
    }
  }

  batteryLevelChanged(e: any) {
    this.log("Battery level changed", JSON.stringify(e));
  }
}
