var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("pages.spendLogs.$")))]),_c('data-table',{attrs:{"data":{
      path: 'userSpends',
      filter: {
        user: _vm.user,
      },
      sortBy: ['date'],
      sortDesc: [true],
      name: _vm.$t('userSpends.$'),
    },"headers":[
      { text: _vm.$t('userSpends.date'), sortable: true, value: 'date', format: 'moment' },
      { text: _vm.$t('userSpends.totalIn'), sortable: true, value: 'totalInInt', format: 'currencyInt' },
      { text: _vm.$t('userSpends.totalOut'), sortable: true, value: 'totalOutInt', format: 'currencyInt' },
      { text: _vm.$t('userSpends.amount'), sortable: true, value: 'amountInt', format: 'currencyInt' },
      { text: _vm.$t('userSpends.used'), sortable: true, value: 'used' },
    ],"actions":"","no-remove":"","no-clone":"","no-edit":"","no-add":"","default":{
      point: _vm.point,
      value: 0,
      user: _vm.user,
      message: 'by admin',
    }},scopedSlots:_vm._u([{key:"post-actions",fn:function({ reload }){return [_c('b-btn',{attrs:{"alt-text":_vm.$t('basic.add')},on:{"click":function($event){return _vm.adjustSpend(reload)}}},[_c('v-icon',[_vm._v("add")])],1)]}},{key:"editor",fn:function(edit){return [_c('v-text-field',{attrs:{"label":_vm.$t('point.value')},model:{value:(edit.item.amount),callback:function ($$v) {_vm.$set(edit.item, "amount", _vm._n($$v))},expression:"edit.item.amount"}}),_c('v-text-field',{attrs:{"label":_vm.$t('point.message')},model:{value:(edit.item.message),callback:function ($$v) {_vm.$set(edit.item, "message", $$v)},expression:"edit.item.message"}})]}}])}),_c('data-table',{attrs:{"data":{
      path: 'userSpendLogs',
      filter: {
        user: _vm.user,
      },
      sortBy: ['date'],
      sortDesc: [true],
      name: _vm.$t('userSpendLogs.$'),
      populate: ['session'],
    },"headers":[
      { text: _vm.$t('userSpendLogs.date'), sortable: true, value: 'date', format: 'moment' },
      { text: _vm.$t('userSpendLogs.from'), sortable: true, value: 'fromInt', format: 'currencyInt' },
      { text: _vm.$t('userSpendLogs.to'), sortable: true, value: 'toInt', format: 'currencyInt' },
      { text: _vm.$t('userSpendLogs.delta'), sortable: true, value: 'deltaInt', format: 'currencyInt' },
    ],"itemCanClick":""},scopedSlots:_vm._u([{key:"expand",fn:function({ item: log }){return [(log.session)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text')],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }