
import { Component, Vue, FindType, Prop, PropSync, checkID, Watch, mixins } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";

@Component({})
export default class DatesSelector extends mixins(Dialog()) {
  @Prop()
  initOption;
  @Prop()
  initStart;
  @Prop()
  initEnd;

  mounted() {
    if (this.initOption) this.sOption = this.initOption;
    if (this.initStart) this.start = this.initStart;
    if (this.initEnd) this.end = this.initEnd;
    if (this.initStart && this.initEnd) this.sOption = null;
  }

  sOption: "today" | "three" | "seven" = "today";
  start: Date = new Date();
  end: Date = new Date();

  get options() {
    const today = new Date();
    return [
      { name: "今日", _id: "today", end: today, start: this.$moment().toDate() },
      {
        name: "前3天",
        _id: "three",
        end: today,
        start: this.$moment().subtract(2, "days").toDate(),
      },
      {
        name: "最近7天",
        _id: "seven",
        end: today,
        start: this.$moment().subtract(6, "days").toDate(),
      },
    ];
  }

  cancel() {
    this.modalResult(null);
  }

  submit() {
    const option = this.options.find(x => x._id === this.sOption);
    this.modalResult({ start: this.start, end: this.end, option });
  }

  @Watch("sOption")
  sOptionChanged(v) {
    const option = this.options.find(x => x._id === v);
    if (option) {
      this.start = option.start;
      this.end = option.end;
    }
  }
}
