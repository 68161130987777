
import Dialog from "@feathers-client/mixins/Dialog";
import { mixins } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { Component, FindType, Prop } from "@feathers-client";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    EditorTextField,
  },
})
export default class StaffPasswordDialog extends mixins(Dialog) {
  password = "";
  error = false;

  @Watch("password")
  onPassword() {
    this.error = false;
  }

  @Prop()
  staff: FindType<"staffs">;

  confirm() {
    if (this.staff.passCode === this.password) {
      this.modalResult(true);
    } else {
      this.error = true;
    }
  }
}
