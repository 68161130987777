import type DB from "@db";
import { LangArrType, LangType } from "./util";
import Vue from "vue";
// @ts-ignore
import type { PrinterOpts } from "pos-printer/printers/baseConf";

export type ProductLine = Partial<(typeof DB.TableSession._mongoType)["products"][number]>;

export type AvailPointType = {
  _id: string;
  amount: number;
  name: LangType;
  icon: string;
  tag: string;
  firstExpiry: {
    date: Date;
    amount: number;
    tag: string;
  };
}[];

export type Constructor<T> = new (...args: any[]) => T & Vue;

export type AllPrintJobData = OrderKitchenView | ReviewJob | ReceiptJob | TestJobData;
export type AllPrintJobTemplateData =
  | OrderKitchenViewData
  | ReviewJobData
  | ReceiptJobData
  | QrLinkData
  | TaiwanInvoiceJobData;

export interface PrintJobBaseData {
  jobType: string;
  actionSource?: string;
  session?: typeof DB.TableSession._mongoType;
  rePrint?: boolean;
  filter?: {
    waterBar?: string;
    kitchen?: string;
  };
}

export interface PrintTemplateData {
  session?: typeof DB.TableSession._mongoType;

  rePrint?: boolean;
  shop?: typeof DB.Shop._mongoType;
  jobOptions?: KitchenViewOptions;

  printerConf?: PrinterOpts;
  smallSize?: boolean;
}

export interface OrderKitchenView extends PrintJobBaseData {
  jobType: "table-kitchen" | "table-waterBar";
  table?: typeof DB.TableViewItem._mongoType;

  type?: "new" | "move" | "cancel" | "reprint" | "edit";
  sourceSessionName?: string;
  sourceTableName?: string;
  sourceTableSplit?: number;
  sourceChairId?: string;

  tableName?: string;
  tableSplit?: number;
  chairId?: string;

  staffName?: string;
  deviceId?: string;
  kioskName?: string;
  actionSource?: "pos" | "mobilePos" | "online" | "kiosk" | "candao";
  takeAway?: boolean;

  items: OrderKitchenViewItem[];
  includeDetails?: boolean;
}

export interface OrderKitchenViewData extends PrintTemplateData {
  job: Omit<OrderKitchenView, "session">;
  code?: string;
  dineIn?: boolean;
  sourceName?: string;
  targetName?: string;
  setRef?: string;
  sourceTableName?: string;
  targetTableName?: string;
  todayPickUp?: boolean;
  staffName?: string;
  deviceId?: string;
  jobName?: string;
}

export interface OrderKitchenViewItem {
  product: ProductLine;
  name: LangArrType;
  shortName: LangArrType;
  index: number;
  sourceIndex?: number;
  pickupLocation?: string;
  rootProduct: string;
  fromProduct?: string;
  kitchenGroup?: string;
  categoryName?: LangArrType;

  curQuantity?: number;
  totalQuantity?: number;
}

export interface ReviewJob extends PrintJobBaseData {
  jobType: "table-receipt";
}

export interface ReviewJobData extends PrintTemplateData {
  dineIn: boolean;
  numOfGuests: number;
  surcharges: (typeof DB.TableSession._mongoType)["surcharges"];
}

export interface ReceiptJob extends Omit<ReviewJob, "jobType"> {
  jobType: "table-payment-receipt";
  payment?: typeof DB.Payment._mongoType;
  paymentMethod?: typeof DB.PaymentMethod._mongoType;
  twInvoice?: typeof DB.TwInvoice._mongoType;
  printTwInvoice?: boolean;
  user?: typeof DB.User._mongoType;
  availPoints?: AvailPointType;
  staffName?: string;
  paymentStaffName?: string;
}

export interface ReceiptJobData extends ReviewJobData {
  toPay?: boolean;
  kioskName?: string;
  twInvoice?: typeof DB.TwInvoice._mongoType;
  printTwInvoice?: boolean;
  paymentMethod: typeof DB.PaymentMethod._mongoType;
  methodName?: LangArrType;
  user?: typeof DB.User._mongoType;
  availPoints?: AvailPointType;
  previousRecords?: (typeof DB.Payment._mongoType)[];
  payment?: typeof DB.Payment._mongoType;
  cashbox?: boolean;
  paymentIndex?: number;
  previousPayments?: number;
  newOutstanding?: number;
  staffName?: string;
  paymentStaffName?: string;
  products?: (typeof DB.TableSession._mongoType)["products"];
  pagination?: {
    offset?: number;
    offsetEnd?: number;
    remain?: number;
    total?: number;
    skipped?: boolean;
  };
}

export interface QrLinkData extends PrintTemplateData {
  tableOnlineOrderPostfix: string;
  tableName: string;
  url: string;
}

export interface TaiwanInvoiceJobData extends PrintTemplateData {
  invoice: typeof DB.TwInvoice._mongoType;
  reprint?: boolean;
  printDetails?: "only" | boolean;
}

export interface TestJobData extends PrintJobBaseData {
  jobType: "test";
}

export interface ViewOptions {
  printQR?: boolean;
  preferShortName?: boolean;
  printProductOptionName?: boolean;
}

export interface KitchenViewOptions extends ViewOptions {
 
  showUserInfo?: boolean;

  printNew?: boolean;
  printMove?: boolean;
  printCancel?: boolean;
  printReprint?: boolean;
  printEdit?: boolean;

  splitQuantity?: boolean;

  tableOnlineOrderPostfix?: string;
}

export interface ReceiptViewOptions {

  showUserInfo?: boolean;
  tablePrintToPayWhenCheckBill?: boolean;
  tableDontAutoPrintReceiptCash?:  boolean;
  tableDontAutoPrintReceiptAll?: boolean;
  autoPrintCancelReceipt?: boolean;
  dontPrintZeroProduct?: boolean;
}

export default {};
