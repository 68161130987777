import { render, staticRenderFns } from "./pickTable.vue?vue&type=template&id=836fd484"
import script from "./pickTable.vue?vue&type=script&lang=ts"
export * from "./pickTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('836fd484')) {
      api.createRecord('836fd484', component.options)
    } else {
      api.reload('836fd484', component.options)
    }
    module.hot.accept("./pickTable.vue?vue&type=template&id=836fd484", function () {
      api.rerender('836fd484', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/table/reservation/pickTable.vue"
export default component.exports