
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import components from "./components";
import _ from "lodash";
import type {
  SchemaHelper,
  EditorConfig,
  EditorField,
  SearchField,
  EditorFieldOptions,
  NormalizedRole,
  EditorViewSetting,
  DataTableHeader,
} from "./plugin";
import "./editor.scss";
const itemTemplatePrefix = "item";
import { LangType } from "@feathers-client/i18n";
import EditorSearchMenu from "./EditorSearchMenu.vue";
import type {
  SchemaDefJson,
} from "@db/schema";

@Component({
  components: {
    ...components,
    EditorSinglePage: async () => (await import("./EditorSinglePage.vue")).default,
    EditorSearchMenu,
  },
})
export default class extends Vue {
  @Prop({ default: true })
  multiSelect: boolean;

  @Prop({ default: true })
  showBatchActions: boolean;

  @Prop()
  exportFilterOverride: any;

  @Prop()
  saveCallback: (item: any, origin?: any) => Promise<any>;

  @Prop()
  dateCutOffTime: string;

  @Prop(Boolean)
  nestedSearch: boolean;

  @Prop()
  preEdit: (item: any) => any | Promise<any>;

  @Prop({})
  defaultSort: string | string[];

  @Prop({})
  defaultSortDesc: boolean | boolean[];

  get filterFuncResult() {
    if (!this.config) return;
    return this.filterFunc?.(this);
  }

  @Watch("filter")
  onUpdateFilter() {
    if (this.nested) {
      this.query = _.merge({}, this.config?.filter || {}, this.filter, this.filterFuncResult);
    }
  }

  @Watch("filterFuncResult")
  onUpdateFilterFuncResult() {
    if (this.query && this.filterFuncResult) {
      for (let [k, v] of Object.entries(this.filterFuncResult)) {
        Vue.set(this.query, k, v);
      }
    }
  }

  toggleSoftDelete() {
    if (this.disableSoftDelete) {
      if (this.query.deletedAt) {
        Vue.delete(this.query, "deletedAt");
      } else {
        this.disableSoftDelete = false;
      }
    } else {
      this.disableSoftDelete = true;
      Vue.set(this.query, "deletedAt", { $ne: null });
    }
  }

  @Prop()
  prepareExportHook: any;

  addActionClick() {
    if (this.nested) return;
    (this.$refs.table as any).editItem();
  }

  addItem(item?) {
    return (this.$refs.table as any).editItem(item);
  }

  editItem(item?) {
    return (this.$refs.table as any).editItem(item);
  }

  async deleteItem(item?) {
    return await (this.$refs.table as any).deleteItem(item);
  }

  deleteItemCore(item, delay?: boolean) {
    return (this.$refs.table as any).deleteItemCore(item, delay);
  }

  importActionClick() {
    if (this.nested) return;
    this.$openDialog(
      import("./EditorImport.vue"),
      {
        config: this.config,
        editParams: this.editParams,
      },
      {
        contentClass: "editor-dialog",
        persistent: true,
      },
    );
  }

  exportActionClick() {
    if (this.nested) return;
    (this.$refs.table as any).beginExport();
  }

  exportWithNested(params?: any) {
    (this.$refs.table as any).beginExport(params);
  }

  async getExportContext(params?: any) {
    return (this.$refs.table as any).getExportContext(params);
  }

  refreshActionClick(props?) {
    if (this.nested) return;
    (this.$refs.table as any).refresh(props);
  }

  refresh(props?) {
    (this.$refs.table as any).refresh(props);
  }

  handlers: {
    func: () => void;
    action: string;
  }[];

  query: any = null;
  serializedState: any = {};

  @Prop()
  editParams: any;

  get finalEditParams() {
    return {
      ...(this.editParams || {}),
      ...(this.config?.softDelete ? { $disableSoftDelete: true } : {}),
    }
  }

  addons: {
    name: LangType;
    action: (item: any) => void;
  }[] = [];

  @Prop({ type: Boolean, required: false })
  nested: boolean;

  @Prop({ type: Boolean, required: false })
  hidden: boolean;

  @Prop({ type: String, required: false })
  path: string;

  @Prop()
  schema: SchemaDefJson;

  @Prop()
  staticItems: any[];

  @Prop({ required: false })
  filter: any;

  @Prop({ required: false })
  filterFunc: (editor: this) => any;

  @Prop({ type: Boolean, default: true })
  inlineEdit: boolean;

  @Prop()
  defaultItem: any;

  @Prop(Boolean)
  rowActions: boolean;

  @Prop({ type: Boolean, default: undefined })
  pager: boolean;

  @Prop(String)
  rootPath: string;

  @Prop(Boolean)
  noSort: boolean;

  get disableSoftDelete() {
    return this.query?.$disableSoftDelete ?? false;
  }

  set disableSoftDelete(v: boolean) {
    Vue.set(this.query, "$disableSoftDelete", v);
  }

  role: NormalizedRole = null;

  @Prop()
  extraExportHeaders: DataTableHeader[];

  @Prop()
  configOverride: {
    import?: boolean;
    export?: boolean;
    create?: boolean;
    patch?: boolean;
    remove?: boolean;
    clone?: boolean;
  };

  get canImport() {
    return (
      (!this.role || this.role.write) &&
      (this.configOverride?.import ?? this.config.import) &&
      (!this.acl || this.acl(this, "create")) &&
      !this.staticItems
    );
  }

  get canCreate() {
    return (
      (!this.role || this.role.write) &&
      (this.configOverride?.create ?? this.config.create) &&
      (!this.acl || this.acl(this, "create")) &&
      !this.staticItems
    );
  }

  get canPatch() {
    return (!this.role || this.role.write) && (this.configOverride?.patch ?? this.config.patch) && !this.staticItems;
  }

  get canRemove() {
    return (!this.role || this.role.write) && (this.configOverride?.remove ?? this.config.remove) && !this.staticItems;
  }

  get canExport() {
    return (!this.role || this.role.read) && (this.configOverride?.export ?? this.config.export);
  }

  get canClone() {
    return (
      (!this.role || (this.role.write && this.role.read)) &&
      (this.configOverride?.clone ?? this.config.clone) &&
      (!this.acl || this.acl(this, "clone"))
    );
  }

  get acl() {
    return this.config.acl && this.$schemas.aclList[this.config.acl];
  }

  densify(fields: EditorField[]) {
    return _.map(fields, (f) => {
      const nf = { ...f };
      nf.props = {
        ...f.props,
        dense: true,
        hideDetails: true,
      };
      if (nf.inner) {
        nf.inner = this.densify(nf.inner);
      }
      if (nf.default) {
        nf.default = this.densify(nf.default);
      }
      return nf;
    });
  }

  setting: EditorViewSetting = {};

  beforeMount() {
    if (!this.nested && this.$route.query.state) {
      try {
        this.serializedState = JSON.parse(`${this.$route.query.state}`);
      } catch (e) {
        console.warn(e);
      }
    }
    const path = this.path || this.$route.params.pathMatch;
    if (this.$store.state.settings?.editorSettings) {
      this.setting = _.cloneDeep(
        this.$store.state.settings?.editorSettings.find((it) => it.path === path)?.value || {},
      );
    } else if (localStorage["editorSettings_" + path]) {
      try {
        this.setting = JSON.parse(localStorage["editorSettings_" + path]);
      } catch (e) {}
    }
    if (this.$schemas.routes) {
      this.initConfig();
    } else {
      this.$schemas.init()?.then?.(this.initConfig);
    }
  }

  @Watch('schema')
  initConfig() {
    const route = "/" + (this.path || this.$route.params.pathMatch);
    this.config = this.schema ? this.$schemas.convertTempSchema(this.path, this.schema, this.schema.params?.editor as any) : this.$schemas.lookupRoute(route);
    if (!this.config) {
      console.warn(`Route not found ${route}`);
      return;
    }
    this.$emit("config", this.config);
    if (this.config.roles) {
      const roles = this.config.roles.filter((it) => this.$schemas.hasRole(it.role));
      this.role = {
        role: roles[0]?.role ?? "none",
        read: roles.some((it) => it.read),
        write: roles.some((it) => it.write),
      };
    }

    if (!this.nested) {
      this.$store.commit("SET_TITLE", {
        title: { $t: this.config.name },
        actions: [
          ...(this.canImport
            ? [
                {
                  icon: "file_upload",
                  name: "import",
                  action: "import",
                  altText: { $t: "basic.import" },
                },
              ]
            : []),
          ...(this.canExport
            ? [
                {
                  icon: "file_download",
                  name: "export",
                  action: "export",
                  altText: { $t: "basic.export" },
                },
              ]
            : []),
          {
            icon: "refresh",
            name: "refresh",
            action: "refresh",
            altText: { $t: "basic.refresh" },
          },
          ...(this.canCreate
            ? [
                {
                  icon: "add",
                  name: "add",
                  action: "add",
                  altText: { $t: "basic.add" },
                },
              ]
            : []),
          ...this.config.actions,
        ],
        fullPage: true,
      });
    }
    if (this.config || !this.nested) {
      this.query = _.merge({}, this.config.filter || {}, this.filter, this.filterFuncResult);
    }

    let fields = this.config.fields;
    this.fields = this.$schemas.sortFields(fields);

    this.handlers = [];
    for (let action of this.config.actions) {
      const handler = {
        func: async () => {
          const component = this.$schemas.components[action.component];
          if (component) {
            await this.$openDialog(await component);
          } else {
            console.warn(`Component ${action.component} not found`);
          }
        },
        action: action.action,
      };
      this.handlers.push(handler);
      this.$root.$on(action.action, handler.func);
    }
  }

  get itemSlots() {
    return _.pickBy(
      this.$scopedSlots,
      (_, k) => k.startsWith(`${itemTemplatePrefix}.`) || k.startsWith("custom:"),
    );
  }

  get editSlots() {
    return _.pickBy(this.$scopedSlots, (_, k) => k.startsWith("before:") || k.startsWith("after:"));
  }

  @Prop()
  headerFields: any[];

  @Prop()
  extraHeaders: DataTableHeader[];

  @Prop({ type: String, default: "schema" })
  exportMode: "schema" | "table";

  get headers() {
    const customItems = Object.keys(this.itemSlots).map((it) =>
      it.substring(itemTemplatePrefix.length + 1),
    );

    const extraItems = customItems.filter((it) => it.startsWith("$"));

    const headerFields = this.headerFields || this.setting.headers;

    const headers = headerFields
      ? [...this.config.headers, ...this.config.extraHeaders].filter((it) =>
          headerFields.includes(it.value),
        )
      : this.config.headers;

    return headers
      .map((it) => {
        if (customItems.includes(it.value)) {
          return {
            ...it,
            type: "custom",
            slot: `${itemTemplatePrefix}.${it.value}`,
            objectOnly: true,
          };
        }
        return it;
      })
      .concat(
        (this.extraHeaders || []).filter((it) =>
          !it.toggleable || it.revertShow
            ? !headerFields?.includes(it.id)
            : headerFields?.includes(it.id),
        ),
      )
      .concat(
        extraItems.map((it) => ({
          type: "custom",
          path: null,
          value: null,
          text: this.$t(`pages.${this.path}.${it.substring(1)}`),
          slot: `${itemTemplatePrefix}.${it}`,
          objectOnly: true,
        })),
      );
  }

  get exportHeaders() {
    const customItems = Object.keys(this.itemSlots).map((it) =>
      it.substring(itemTemplatePrefix.length + 1),
    );

    const headers = [
      ...this.config.headers,
      ...(this.exportMode === "schema"
        ? [...this.config.extraHeaders, ...(this.extraHeaders || [])]
        : [...(this.extraHeaders || []), ...this.config.extraHeaders]),
      ...(this.extraExportHeaders || []),
    ];

    return headers.map((it) => {
      if (customItems.includes(it.value)) {
        return {
          ...it,
          type: "custom",
          slot: `${itemTemplatePrefix}.${it.value}`,
          objectOnly: true,
        };
      }
      return it;
    });
  }

  beforeDestroy() {
    _.each(this.handlers, (handler) => {
      this.$root.$off(handler.action, handler.func);
    });
  }

  config: EditorConfig = null;
  data: any;
  fields: EditorField[] = [];
  showSearchMenu = false;

  renderEditor(_c, props) {
    return this.renderEditorTemplate(this.fields, _c, props);
  }

  renderExpand(_c, props) {
    return this.renderEditorTemplate(this.config.expands, _c, props);
  }

  renderEditorTemplate(fields: EditorField[], _c, props) {
    const mitem = props.item;
    return fields.map((field) => {
      const rootPath = field.rootPath ?? "item";
      const item = rootPath === "item" ? mitem : props[rootPath];
      if (!props.mustShow && field.cond && !field.cond(props)) {
        return this._e();
      }
      return _c(field.component, {
        key: field.path,
        props: {
          ...field.props,
          ...(field.propsFunc ? field.propsFunc(props) : {}),
          ...(field.nameField && field.name
            ? {
                [field.nameField]:
                  typeof field.name === "string"
                    ? this.$root.$t(field.name)
                    : this.$root.$td(field.name),
              }
            : {}),
          root: item,
        },
        model: field.path
          ? {
              value: item?.[field.path],
              callback: ($$v) => {
                if (item) Vue.set(item, field.path, $$v);
              },
            }
          : field.path === ""
          ? {
              value: item,
              callback: ($$v) => {
                props.item = $$v;
              },
            }
          : null,
        scopedSlots: {
          ...(field.inner?.length
            ? {
                item: (scope) => {
                  return this.renderEditorTemplate(field.inner, _c, { ...props, ...scope });
                },
              }
            : {}),
          ...(field.default?.length
            ? {
                default: () => {
                  return this.renderEditorTemplate(field.default, _c, props);
                },
              }
            : {}),
        },
      });
    });
  }

  async validate({ params, query, store, app, redirect, route }) {
    const schemas: SchemaHelper = app.$schemas;
    await schemas.init();
    if (params.pathMatch) {
      const config = schemas.routes["/" + params.pathMatch];
      if (config) {
        return true;
      } else {
        const config = schemas.routes["/" + params.pathMatch + "/"];
        if (config) {
          // need redirect with trailingslash
          redirect({
            ...route,
            params: {
              ...params,
              pathMatch: params.pathMatch + "/",
            },
          });
        }
      }
    }
    return false;
  }

  scroll() {
    this.showSearchMenu = false;
  }

  async multiEdit(mode?) {
    if (!(this.$refs.table as any).mselected?.length) return;
    const res = await this.$openDialog(
      import("./EditorBatchEdit.vue"),
      {
        items: (this.$refs.table as any).mselected,
        provider: this.$refs.table,
        config: this.config,
        editor: this,
        mode,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  get selectedItems() {
    return (this.$refs.table as any).mselected;
  }

  async showViewSetting() {
    await this.$openDialog(
      import("./EditorViewSetting.vue"),
      {
        editor: this,
        config: this.config,
        extraHeaders: this.extraHeaders,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  saveViewSettings() {
    const path = this.path || this.$route.params.pathMatch;
    this.$store.commit("SET_SETTINGS", {
      editorSettings: (this.$store.state.settings?.editorSettings || [])
        .filter((it) => it.path && it.path !== path)
        .concat([
          {
            path,
            value: _.cloneDeep(this.setting),
          },
        ]),
    });
    localStorage["editorSettings_" + path] = JSON.stringify(this.setting);
  }

  @Ref()
  searchMenu: EditorSearchMenu;

  setKeyword(keyword: string) {
    if (this.searchMenu) {
      this.searchMenu.keyword = keyword;
    }
  }
}
