
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Ingredient } from "~/plugins/table/ingredient";
import { fromDb, toDb } from "@common/table/ingredients";

@Component
export default class ProductInfo extends Vue {
  @Prop()
  ingredient: Ingredient;

  get currentStock() {
    return fromDb(this.ingredient.currentStock);
  }

  set currentStock(v: number) {
    this.ingredient.currentStock = toDb(v);
  }
}
