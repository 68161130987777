
import { getVersion } from '../nativeIntegrations'
import { ns } from '../messageQueue'

export interface KeyPress {
    keyCode: number
    modifierFlags: KeyPressModifier
    characters?: string
}

export enum KeyPressModifier {
    None = 0,
    AlphaShift = 1,
    Shift = 2,
    Control = 4,
    Alternate = 8,
    Command = 16,
    NumericPad = 32,
}

export async function on(cb: (result: KeyPress) => void) {
    return ns('keyboard').on('keypress', cb);
}

export async function off(handle : string) {
    return ns('keyboard').off(handle);
}

export async function start() {
    return ns('keyboard').call('start');
}

export async function stop() {
    return ns('keyboard').call('stop');
}

// prevent key press in webview
export async function suppress(v: boolean) {
    return ns('keyboard').call('suppress', v);
}

export function supported() {
    if(!getVersion()) return Promise.resolve(false);
    return Promise.race([
        ns('keyboard').call('supported'),
        new Promise(resolve => setTimeout(() => resolve(false), 1000)),
    ]).catch(e => false);
}


