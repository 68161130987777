
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeLabelPage, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorPage extends Vue {
  @Prop() template!: PrinterTemplateNodeLabelPage;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  get w() {
    return this.size[0] * 8;
  }

  get h() {
    return this.size[1] * 8;
  }

  get size() {
    if (this.template.props["size"]) {
      const size = this.template.props["size"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [40, 30];
    }
  }

  get gap() {
    if (this.template.props["gap"]) {
      const size = this.template.props["gap"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [1.5, 0];
    }
  }
}
