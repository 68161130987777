<script>

import { Bar } from 'vue-chartjs'
import 'chartjs-plugin-labels';
import _ from 'lodash'
import reactive from './reactive'

export default {
    extends: Bar,
    mixins: [reactive()],
    computed: {
        moptions() {
            return _.merge({
            }, this.options || {});
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.moptions)
    }
}
</script>
