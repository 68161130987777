
import type { ProductLine, TableSession } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
@Component
export default class CustomProductAdd extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  category: string;

  @Prop()
  subCategory: string;

  async addCustomProduct() {
    const resp = await this.$openDialog(
      import("./addDialog.vue"),
      {
        session: this.session,
        defaultCategory: this.category,
        defaultSubCategory: this.subCategory,
      },
      {
        maxWidth: "max(50vw,500px)",
        contentClass: "h-80vh",
      },
    );
  }
}
