import _ from 'lodash'
import { EscPosPrintSequence } from "../escpos";
import { PrinterBaseConf } from '../../printers/baseConf';
import { BitmapOpts, WrappedContext } from "../../common";

export class XPrintSequence extends EscPosPrintSequence {
    needCut = false;
    autoCut = true;
    needCashBox = false;

    constructor(public context : WrappedContext, public printer : PrinterBaseConf) {
        super(context, printer);
        this.autoCut = printer?.conf?.opts?.autoCut ?? true;
        this.compactMode = true;
        this._defaultImageHiRes = false;
    }

    getJobOpts() {
        return {
            ...super.getJobOpts(),
            autoCut: this.autoCut,
            cashBox: this.needCashBox,
        }
    }

    finish(): void {
      if(!this.dirty && this.autoCut) {
        this.autoCut = false;
      }
      if(!this.dirty && this.printer?.conf?.opts?.tagMode === "xpyun") {
        this._chunks = [];
      }
    }

    cut() {
        if(!this.autoCut) {
            return super.cut();
        }
        this.dirty = true;
        this.needCut = true;
        return this;
    }

    getPreLine() {
        if(!this.autoCut) {
            return super.getPreLine();
        }
        if(this.needCut) {
            super.cut();
            this.needCut = false;
        }
        return super.getPreLine();
    }

    cashBox(which : number, time : number) {
        this.needCashBox = true;
        return super.cashBox(which, time);
    }
}

