
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, checkID } from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import type { CartItem } from "@common/table/cart";

@Component
export default class CartList extends Vue {
  @Prop()
  session: TableSession;

  @PropSync("cleanOnUpdated")
  cleanOnUpdatedSync: boolean;

  @Prop()
  staticProducts: ProductLine[];

  @Prop()
  cart: CartItem[];

  @Prop({ type: Boolean, default: true })
  hasMenu: boolean;

  @Prop()
  selectingCart: boolean;

  @Prop()
  selectingLine: boolean;

  @Prop()
  singleItemCheckout: boolean;

  @Prop(Boolean)
  posScreen: boolean;

  get items() {
    return this.cart ?? this.session?.cart ?? [];
  }

  get productDict() {
    const products = this.staticProducts ?? this.session?.products ?? [];
    return Object.fromEntries(products.map(p => [p.id, p] as const));
  }

  get productRoot() {
    const products = this.staticProducts ?? this.session?.products ?? [];
    const productDict = this.productDict;

    return Object.fromEntries(
      products.map(p => {
        let product = p;
        while (product.fromProduct) {
          const parent = productDict[product.fromProduct];
          if (!parent) break;
          product = parent;
        }

        return [p.id, product] as const;
      }),
    );
  }

  get products() {
    const products = this.staticProducts ?? this.session?.products ?? [];
    const productRoot = this.productRoot;
    return _.orderBy(
      products.map(p => [p, productRoot[p.id] || p]),
      [
        ([ori, product]) => {
          if (this.displayOrdering == "order") return 0;
          if (product.status === "cancel") {
            return -1;
          }

          if (product.status === "pending") {
            return 0;
          }

          switch (product.kdsStatus) {
            case "hold":
              return 5;
            case "pending":
              return 2;
            case "done":
            case "partialDone":
              return 1;
          }
        },
        ([ori, product]) => {
          return product.seq;
        },
        ([ori, product]) => {
          return product.date || product.id;
        },
        ([ori, product]) => {
          return ori.fromOptionSeq ?? -1;
        },
        ([ori, product]) => {
          return ori.fromChoiceSeq ?? -1;
        },
        ([ori, product]) => {
          return ori.date || ori.id;
        },
      ],
      ["asc", "asc", "asc", "asc", "asc", "asc"],
    ).map(([ori, product]) => ori);
  }

  get displayOrdering() {
    return this.$shop.localOptions.orderingDisplay;
  }

  async remove(item: CartItem) {
    if (item.fromProductCart) {
      if (item.fromProductCart.mergeStatus === "merged" && item.mergeStatus === "mergedFrom") {
        this.session.undoMerge(item.fromProductCart);
      } else {
        const option = item.fromProductCart.productOptionsWithPrice.find(p => checkID(p.options, item.fromOption));
        if (option) {
          const selection = option.selectionItems.find(s => checkID(s.selection.id, item.fromSelection));
          if (selection) {
            selection.remove();
          }
        }
      }
    }
    item.remove();
    if (this.session.selectingCartItemId === item.id) {
      this.session.selectingCartItem = null;
    }
    await this.session.updateAutoMerge();
    this.$emit("removeLastCartItem");
    this.$emit("updated");
  }

  scrollAfterAnimation = false;

  @Watch("products")
  onProducts() {
    if (this.cleanOnUpdatedSync) {
      this.session.cart = [];
      this.cleanOnUpdatedSync = false;
    }
    this.scrollAfterAnimation = true;
  }

  @Watch("items")
  onItems() {
    this.scrollAfterAnimation = true;
  }

  async saveRows(lines: ProductLine[]) {
    await this.$feathers.service("tableSessions/order").patch(null, {
      session: this.session.item._id,
      products: lines,
      staff: this.$shop.staffId,
    });
  }

  handleResize() {
    if (!this.scrollAfterAnimation) return;
    const elem = this.$refs.orderDetails as Element;
    elem.scrollTop = elem.scrollHeight;
    this.scrollAfterAnimation = false;
  }
}
