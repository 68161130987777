import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _69746620 = () => interopDefault(import('../pages/actionLogs/index.vue' /* webpackChunkName: "pages/actionLogs/index" */))
const _1189a4ec = () => interopDefault(import('../pages/appSettings.vue' /* webpackChunkName: "pages/appSettings" */))
const _7592bc5c = () => interopDefault(import('../pages/appUsers/index.vue' /* webpackChunkName: "pages/appUsers/index" */))
const _5b1e7e5c = () => interopDefault(import('../pages/cashboxLogs/index.vue' /* webpackChunkName: "pages/cashboxLogs/index" */))
const _1824f2da = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _1e157821 = () => interopDefault(import('../pages/costSummary.vue' /* webpackChunkName: "pages/costSummary" */))
const _1aac6538 = () => interopDefault(import('../pages/dailySummary/index.vue' /* webpackChunkName: "pages/dailySummary/index" */))
const _4298ebc8 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _3a37b83e = () => interopDefault(import('../pages/giftRedeemRecords.vue' /* webpackChunkName: "pages/giftRedeemRecords" */))
const _4b54c234 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _7b6c8232 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _033e204d = () => interopDefault(import('../pages/messageCentre/index.vue' /* webpackChunkName: "pages/messageCentre/index" */))
const _59c64874 = () => interopDefault(import('../pages/ods.vue' /* webpackChunkName: "pages/ods" */))
const _cadf5f68 = () => interopDefault(import('../pages/odsSettings/index.vue' /* webpackChunkName: "pages/odsSettings/index" */))
const _b9052b18 = () => interopDefault(import('../pages/paymentMethods.vue' /* webpackChunkName: "pages/paymentMethods" */))
const _0ceb6e55 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _27404258 = () => interopDefault(import('../pages/posDevices/index.vue' /* webpackChunkName: "pages/posDevices/index" */))
const _0f312d30 = () => interopDefault(import('../pages/posScreen.vue' /* webpackChunkName: "pages/posScreen" */))
const _02ccb2d4 = () => interopDefault(import('../pages/printIdLabel.vue' /* webpackChunkName: "pages/printIdLabel" */))
const _a86236aa = () => interopDefault(import('../pages/printJobs.vue' /* webpackChunkName: "pages/printJobs" */))
const _4378ca8f = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _5d069074 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4a99dbb6 = () => interopDefault(import('../pages/punch.vue' /* webpackChunkName: "pages/punch" */))
const _723af61c = () => interopDefault(import('../pages/queueing/index.vue' /* webpackChunkName: "pages/queueing/index" */))
const _123d9b4c = () => interopDefault(import('../pages/salesSetting.vue' /* webpackChunkName: "pages/salesSetting" */))
const _2821235e = () => interopDefault(import('../pages/shopRankGroups.vue' /* webpackChunkName: "pages/shopRankGroups" */))
const _25af5e08 = () => interopDefault(import('../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _9b91a888 = () => interopDefault(import('../pages/staffPunchRecords.vue' /* webpackChunkName: "pages/staffPunchRecords" */))
const _e285f46a = () => interopDefault(import('../pages/statSystem.vue' /* webpackChunkName: "pages/statSystem" */))
const _3e231c1a = () => interopDefault(import('../pages/systemSettings.vue' /* webpackChunkName: "pages/systemSettings" */))
const _1aead74e = () => interopDefault(import('../pages/table/index.vue' /* webpackChunkName: "pages/table/index" */))
const _71ccd190 = () => interopDefault(import('../pages/tvConnect.vue' /* webpackChunkName: "pages/tvConnect" */))
const _184da4c2 = () => interopDefault(import('../pages/tvContent.vue' /* webpackChunkName: "pages/tvContent" */))
const _fc533fae = () => interopDefault(import('../pages/tvMenu.vue' /* webpackChunkName: "pages/tvMenu" */))
const _6743511d = () => interopDefault(import('../pages/tvMenus/index.vue' /* webpackChunkName: "pages/tvMenus/index" */))
const _18699f06 = () => interopDefault(import('../pages/versions/index.vue' /* webpackChunkName: "pages/versions/index" */))
const _6e17a92c = () => interopDefault(import('../pages/waterBarScanner.vue' /* webpackChunkName: "pages/waterBarScanner" */))
const _7e76e7fa = () => interopDefault(import('../pages/ingredients/manage.vue' /* webpackChunkName: "pages/ingredients/manage" */))
const _48cc2b5c = () => interopDefault(import('../pages/ingredients/semiProduct.vue' /* webpackChunkName: "pages/ingredients/semiProduct" */))
const _0a929461 = () => interopDefault(import('../pages/ingredients/summary.vue' /* webpackChunkName: "pages/ingredients/summary" */))
const _453c874b = () => interopDefault(import('../pages/legacyIngredients/edit/index.vue' /* webpackChunkName: "pages/legacyIngredients/edit/index" */))
const _4f11fa9e = () => interopDefault(import('../pages/legacyIngredients/expenditure.vue' /* webpackChunkName: "pages/legacyIngredients/expenditure" */))
const _29fd9616 = () => interopDefault(import('../pages/legacyIngredients/ingredientCostDailys.vue' /* webpackChunkName: "pages/legacyIngredients/ingredientCostDailys" */))
const _f5cf4c44 = () => interopDefault(import('../pages/legacyIngredients/ingredientCostInput.vue' /* webpackChunkName: "pages/legacyIngredients/ingredientCostInput" */))
const _5c071427 = () => interopDefault(import('../pages/legacyIngredients/ingredientCosts.vue' /* webpackChunkName: "pages/legacyIngredients/ingredientCosts" */))
const _f5506868 = () => interopDefault(import('../pages/legacyIngredients/toPayCost.vue' /* webpackChunkName: "pages/legacyIngredients/toPayCost" */))
const _394304f7 = () => interopDefault(import('../pages/products/batch.vue' /* webpackChunkName: "pages/products/batch" */))
const _a2e32e9a = () => interopDefault(import('../pages/profile/manage.vue' /* webpackChunkName: "pages/profile/manage" */))
const _80dc93e4 = () => interopDefault(import('../pages/queueing/clinic.vue' /* webpackChunkName: "pages/queueing/clinic" */))
const _193b99c4 = () => interopDefault(import('../pages/queueing/pos.vue' /* webpackChunkName: "pages/queueing/pos" */))
const _588bc968 = () => interopDefault(import('../pages/queueing/posMobileClinic.vue' /* webpackChunkName: "pages/queueing/posMobileClinic" */))
const _92246fcc = () => interopDefault(import('../pages/queueing/setting.vue' /* webpackChunkName: "pages/queueing/setting" */))
const _2c0fd24a = () => interopDefault(import('../pages/reservation/details/index.vue' /* webpackChunkName: "pages/reservation/details/index" */))
const _38eaaa9a = () => interopDefault(import('../pages/reservation/orders/index.vue' /* webpackChunkName: "pages/reservation/orders/index" */))
const _34b99fb2 = () => interopDefault(import('../pages/reservation/plans/index.vue' /* webpackChunkName: "pages/reservation/plans/index" */))
const _1c24c7b6 = () => interopDefault(import('../pages/settings/candao.vue' /* webpackChunkName: "pages/settings/candao" */))
const _270b4d51 = () => interopDefault(import('../pages/settings/dev.vue' /* webpackChunkName: "pages/settings/dev" */))
const _0afda442 = () => interopDefault(import('../pages/settings/exportTemplate.vue' /* webpackChunkName: "pages/settings/exportTemplate" */))
const _63bcfea4 = () => interopDefault(import('../pages/settings/general.vue' /* webpackChunkName: "pages/settings/general" */))
const _3a0ad4d6 = () => interopDefault(import('../pages/settings/printer.vue' /* webpackChunkName: "pages/settings/printer" */))
const _79a58e07 = () => interopDefault(import('../pages/settings/reportEmail/index.vue' /* webpackChunkName: "pages/settings/reportEmail/index" */))
const _6e3b1e8c = () => interopDefault(import('../pages/settings/scanner.vue' /* webpackChunkName: "pages/settings/scanner" */))
const _4e054495 = () => interopDefault(import('../pages/settings/sms.vue' /* webpackChunkName: "pages/settings/sms" */))
const _2061b017 = () => interopDefault(import('../pages/settings/turncloud/index.vue' /* webpackChunkName: "pages/settings/turncloud/index" */))
const _fee416ae = () => interopDefault(import('../pages/settings/uberEats.vue' /* webpackChunkName: "pages/settings/uberEats" */))
const _3fc2eaed = () => interopDefault(import('../pages/settings/verify.vue' /* webpackChunkName: "pages/settings/verify" */))
const _641e70ee = () => interopDefault(import('../pages/settings/verifyPayment.vue' /* webpackChunkName: "pages/settings/verifyPayment" */))
const _244326e1 = () => interopDefault(import('../pages/settings/xero/index.vue' /* webpackChunkName: "pages/settings/xero/index" */))
const _7f946764 = () => interopDefault(import('../pages/shops/cashBox.vue' /* webpackChunkName: "pages/shops/cashBox" */))
const _794a658a = () => interopDefault(import('../pages/shops/displaySettings.vue' /* webpackChunkName: "pages/shops/displaySettings" */))
const _fbfc0adc = () => interopDefault(import('../pages/shops/inventory.vue' /* webpackChunkName: "pages/shops/inventory" */))
const _942103f8 = () => interopDefault(import('../pages/shops/openClose.vue' /* webpackChunkName: "pages/shops/openClose" */))
const _7349c581 = () => interopDefault(import('../pages/table/kds.vue' /* webpackChunkName: "pages/table/kds" */))
const _6300e6ee = () => interopDefault(import('../pages/table/orders.vue' /* webpackChunkName: "pages/table/orders" */))
const _73a2e9c3 = () => interopDefault(import('../pages/table/salesSummary.vue' /* webpackChunkName: "pages/table/salesSummary" */))
const _21fdc96d = () => interopDefault(import('../pages/table/summary.vue' /* webpackChunkName: "pages/table/summary" */))
const _ce24b1a4 = () => interopDefault(import('../pages/test/hid.vue' /* webpackChunkName: "pages/test/hid" */))
const _7f65865c = () => interopDefault(import('../pages/test/jsonImport.vue' /* webpackChunkName: "pages/test/jsonImport" */))
const _979c5aae = () => interopDefault(import('../pages/test/scanner.vue' /* webpackChunkName: "pages/test/scanner" */))
const _06ba333d = () => interopDefault(import('../pages/messageCentre/dialogs/CreateDialog.vue' /* webpackChunkName: "pages/messageCentre/dialogs/CreateDialog" */))
const _642a2f93 = () => interopDefault(import('../pages/messageCentre/dialogs/TestDialog.vue' /* webpackChunkName: "pages/messageCentre/dialogs/TestDialog" */))
const _2559e234 = () => interopDefault(import('../pages/reservation/dialogs/AddDisabledDateDialog.vue' /* webpackChunkName: "pages/reservation/dialogs/AddDisabledDateDialog" */))
const _c27549a4 = () => interopDefault(import('../pages/reservation/dialogs/AddReasonDialog.vue' /* webpackChunkName: "pages/reservation/dialogs/AddReasonDialog" */))
const _37ea6922 = () => interopDefault(import('../pages/reservation/dialogs/AddRestrictionDialog.vue' /* webpackChunkName: "pages/reservation/dialogs/AddRestrictionDialog" */))
const _038685d1 = () => interopDefault(import('../pages/reservation/dialogs/AddSectionRestrictionDialog.vue' /* webpackChunkName: "pages/reservation/dialogs/AddSectionRestrictionDialog" */))
const _143297d6 = () => interopDefault(import('../pages/reservation/dialogs/AddTimeSlotDialog.vue' /* webpackChunkName: "pages/reservation/dialogs/AddTimeSlotDialog" */))
const _2ac3156f = () => interopDefault(import('../pages/settings/reportEmail/emailSetting.vue' /* webpackChunkName: "pages/settings/reportEmail/emailSetting" */))
const _5060bc86 = () => interopDefault(import('../pages/settings/xero/callback.vue' /* webpackChunkName: "pages/settings/xero/callback" */))
const _58df715a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c6be53f6 = () => interopDefault(import('../pages/reservation/plans/edit/_id.vue' /* webpackChunkName: "pages/reservation/plans/edit/_id" */))
const _59dfb664 = () => interopDefault(import('../pages/actionLogs/detail/_id.vue' /* webpackChunkName: "pages/actionLogs/detail/_id" */))
const _4009c036 = () => interopDefault(import('../pages/appUsers/edit/_id.vue' /* webpackChunkName: "pages/appUsers/edit/_id" */))
const _198e4a82 = () => interopDefault(import('../pages/dailySummary/edit/_id.vue' /* webpackChunkName: "pages/dailySummary/edit/_id" */))
const _0049e073 = () => interopDefault(import('../pages/legacyIngredients/edit/_id.vue' /* webpackChunkName: "pages/legacyIngredients/edit/_id" */))
const _4a12cb8a = () => interopDefault(import('../pages/messageCentre/edit/_id.vue' /* webpackChunkName: "pages/messageCentre/edit/_id" */))
const _a15bb1aa = () => interopDefault(import('../pages/odsSettings/edit/_id.vue' /* webpackChunkName: "pages/odsSettings/edit/_id" */))
const _17edaba3 = () => interopDefault(import('../pages/posDevices/edit/_id.vue' /* webpackChunkName: "pages/posDevices/edit/_id" */))
const _96043bf0 = () => interopDefault(import('../pages/products/edit/_id.vue' /* webpackChunkName: "pages/products/edit/_id" */))
const _6b0ed859 = () => interopDefault(import('../pages/reservation/settings/_id.vue' /* webpackChunkName: "pages/reservation/settings/_id" */))
const _7bc3ae8c = () => interopDefault(import('../pages/tvMenus/edit/_id.vue' /* webpackChunkName: "pages/tvMenus/edit/_id" */))
const _187d00ff = () => interopDefault(import('../pages/templateEditor/_.vue' /* webpackChunkName: "pages/templateEditor/_" */))
const _937e9b32 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/actionLogs",
    component: _69746620,
    name: "actionLogs",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/actionLogs/index.vue').default
  }, {
    path: "/appSettings",
    component: _1189a4ec,
    name: "appSettings",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/appSettings.vue').default
  }, {
    path: "/appUsers",
    component: _7592bc5c,
    name: "appUsers",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/appUsers/index.vue').default
  }, {
    path: "/cashboxLogs",
    component: _5b1e7e5c,
    name: "cashboxLogs",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/cashboxLogs/index.vue').default
  }, {
    path: "/changePassword",
    component: _1824f2da,
    name: "changePassword",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/changePassword.vue').default
  }, {
    path: "/costSummary",
    component: _1e157821,
    name: "costSummary",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/costSummary.vue').default
  }, {
    path: "/dailySummary",
    component: _1aac6538,
    name: "dailySummary",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dailySummary/index.vue').default
  }, {
    path: "/dashboard",
    component: _4298ebc8,
    name: "dashboard",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/giftRedeemRecords",
    component: _3a37b83e,
    name: "giftRedeemRecords",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/giftRedeemRecords.vue').default
  }, {
    path: "/login",
    component: _4b54c234,
    name: "login",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/logout",
    component: _7b6c8232,
    name: "logout",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/messageCentre",
    component: _033e204d,
    name: "messageCentre",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/messageCentre/index.vue').default
  }, {
    path: "/ods",
    component: _59c64874,
    name: "ods",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/ods.vue').default
  }, {
    path: "/odsSettings",
    component: _cadf5f68,
    name: "odsSettings",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/odsSettings/index.vue').default
  }, {
    path: "/paymentMethods",
    component: _b9052b18,
    name: "paymentMethods",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/paymentMethods.vue').default
  }, {
    path: "/payments",
    component: _0ceb6e55,
    name: "payments",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/payments.vue').default
  }, {
    path: "/posDevices",
    component: _27404258,
    name: "posDevices",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posDevices/index.vue').default
  }, {
    path: "/posScreen",
    component: _0f312d30,
    name: "posScreen",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posScreen.vue').default
  }, {
    path: "/printIdLabel",
    component: _02ccb2d4,
    name: "printIdLabel",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/printIdLabel.vue').default
  }, {
    path: "/printJobs",
    component: _a86236aa,
    name: "printJobs",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/printJobs.vue').default
  }, {
    path: "/products",
    component: _4378ca8f,
    name: "products",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/products/index.vue').default
  }, {
    path: "/profile",
    component: _5d069074,
    name: "profile",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/punch",
    component: _4a99dbb6,
    name: "punch",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/punch.vue').default
  }, {
    path: "/queueing",
    component: _723af61c,
    name: "queueing",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/queueing/index.vue').default
  }, {
    path: "/salesSetting",
    component: _123d9b4c,
    name: "salesSetting",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/salesSetting.vue').default
  }, {
    path: "/shopRankGroups",
    component: _2821235e,
    name: "shopRankGroups",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shopRankGroups.vue').default
  }, {
    path: "/shops",
    component: _25af5e08,
    name: "shops",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shops/index.vue').default
  }, {
    path: "/staffPunchRecords",
    component: _9b91a888,
    name: "staffPunchRecords",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/staffPunchRecords.vue').default
  }, {
    path: "/statSystem",
    component: _e285f46a,
    name: "statSystem",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/statSystem.vue').default
  }, {
    path: "/systemSettings",
    component: _3e231c1a,
    name: "systemSettings",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/systemSettings.vue').default
  }, {
    path: "/table",
    component: _1aead74e,
    name: "table",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/table/index.vue').default
  }, {
    path: "/tvConnect",
    component: _71ccd190,
    name: "tvConnect",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/tvConnect.vue').default
  }, {
    path: "/tvContent",
    component: _184da4c2,
    name: "tvContent",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/tvContent.vue').default
  }, {
    path: "/tvMenu",
    component: _fc533fae,
    name: "tvMenu",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/tvMenu.vue').default
  }, {
    path: "/tvMenus",
    component: _6743511d,
    name: "tvMenus",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/tvMenus/index.vue').default
  }, {
    path: "/versions",
    component: _18699f06,
    name: "versions",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/versions/index.vue').default
  }, {
    path: "/waterBarScanner",
    component: _6e17a92c,
    name: "waterBarScanner",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/waterBarScanner.vue').default
  }, {
    path: "/ingredients/manage",
    component: _7e76e7fa,
    name: "ingredients-manage",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/ingredients/manage.vue').default
  }, {
    path: "/ingredients/semiProduct",
    component: _48cc2b5c,
    name: "ingredients-semiProduct",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/ingredients/semiProduct.vue').default
  }, {
    path: "/ingredients/summary",
    component: _0a929461,
    name: "ingredients-summary",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/ingredients/summary.vue').default
  }, {
    path: "/legacyIngredients/edit",
    component: _453c874b,
    name: "legacyIngredients-edit",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/edit/index.vue').default
  }, {
    path: "/legacyIngredients/expenditure",
    component: _4f11fa9e,
    name: "legacyIngredients-expenditure",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/expenditure.vue').default
  }, {
    path: "/legacyIngredients/ingredientCostDailys",
    component: _29fd9616,
    name: "legacyIngredients-ingredientCostDailys",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/ingredientCostDailys.vue').default
  }, {
    path: "/legacyIngredients/ingredientCostInput",
    component: _f5cf4c44,
    name: "legacyIngredients-ingredientCostInput",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/ingredientCostInput.vue').default
  }, {
    path: "/legacyIngredients/ingredientCosts",
    component: _5c071427,
    name: "legacyIngredients-ingredientCosts",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/ingredientCosts.vue').default
  }, {
    path: "/legacyIngredients/toPayCost",
    component: _f5506868,
    name: "legacyIngredients-toPayCost",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/toPayCost.vue').default
  }, {
    path: "/products/batch",
    component: _394304f7,
    name: "products-batch",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/products/batch.vue').default
  }, {
    path: "/profile/manage",
    component: _a2e32e9a,
    name: "profile-manage",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/manage.vue').default
  }, {
    path: "/queueing/clinic",
    component: _80dc93e4,
    name: "queueing-clinic",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/queueing/clinic.vue').default
  }, {
    path: "/queueing/pos",
    component: _193b99c4,
    name: "queueing-pos",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/queueing/pos.vue').default
  }, {
    path: "/queueing/posMobileClinic",
    component: _588bc968,
    name: "queueing-posMobileClinic",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/queueing/posMobileClinic.vue').default
  }, {
    path: "/queueing/setting",
    component: _92246fcc,
    name: "queueing-setting",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/queueing/setting.vue').default
  }, {
    path: "/reservation/details",
    component: _2c0fd24a,
    name: "reservation-details",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/details/index.vue').default
  }, {
    path: "/reservation/orders",
    component: _38eaaa9a,
    name: "reservation-orders",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/orders/index.vue').default
  }, {
    path: "/reservation/plans",
    component: _34b99fb2,
    name: "reservation-plans",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/plans/index.vue').default
  }, {
    path: "/settings/candao",
    component: _1c24c7b6,
    name: "settings-candao",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/candao.vue').default
  }, {
    path: "/settings/dev",
    component: _270b4d51,
    name: "settings-dev",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/dev.vue').default
  }, {
    path: "/settings/exportTemplate",
    component: _0afda442,
    name: "settings-exportTemplate",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/exportTemplate.vue').default
  }, {
    path: "/settings/general",
    component: _63bcfea4,
    name: "settings-general",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/general.vue').default
  }, {
    path: "/settings/printer",
    component: _3a0ad4d6,
    name: "settings-printer",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/printer.vue').default
  }, {
    path: "/settings/reportEmail",
    component: _79a58e07,
    name: "settings-reportEmail",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/reportEmail/index.vue').default
  }, {
    path: "/settings/scanner",
    component: _6e3b1e8c,
    name: "settings-scanner",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/scanner.vue').default
  }, {
    path: "/settings/sms",
    component: _4e054495,
    name: "settings-sms",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/sms.vue').default
  }, {
    path: "/settings/turncloud",
    component: _2061b017,
    name: "settings-turncloud",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/turncloud/index.vue').default
  }, {
    path: "/settings/uberEats",
    component: _fee416ae,
    name: "settings-uberEats",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/uberEats.vue').default
  }, {
    path: "/settings/verify",
    component: _3fc2eaed,
    name: "settings-verify",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/verify.vue').default
  }, {
    path: "/settings/verifyPayment",
    component: _641e70ee,
    name: "settings-verifyPayment",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/verifyPayment.vue').default
  }, {
    path: "/settings/xero",
    component: _244326e1,
    name: "settings-xero",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/xero/index.vue').default
  }, {
    path: "/shops/cashBox",
    component: _7f946764,
    name: "shops-cashBox",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shops/cashBox.vue').default
  }, {
    path: "/shops/displaySettings",
    component: _794a658a,
    name: "shops-displaySettings",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shops/displaySettings.vue').default
  }, {
    path: "/shops/inventory",
    component: _fbfc0adc,
    name: "shops-inventory",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shops/inventory.vue').default
  }, {
    path: "/shops/openClose",
    component: _942103f8,
    name: "shops-openClose",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/shops/openClose.vue').default
  }, {
    path: "/table/kds",
    component: _7349c581,
    name: "table-kds",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/table/kds.vue').default
  }, {
    path: "/table/orders",
    component: _6300e6ee,
    name: "table-orders",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/table/orders.vue').default
  }, {
    path: "/table/salesSummary",
    component: _73a2e9c3,
    name: "table-salesSummary",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/table/salesSummary.vue').default
  }, {
    path: "/table/summary",
    component: _21fdc96d,
    name: "table-summary",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/table/summary.vue').default
  }, {
    path: "/test/hid",
    component: _ce24b1a4,
    name: "test-hid",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test/hid.vue').default
  }, {
    path: "/test/jsonImport",
    component: _7f65865c,
    name: "test-jsonImport",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test/jsonImport.vue').default
  }, {
    path: "/test/scanner",
    component: _979c5aae,
    name: "test-scanner",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test/scanner.vue').default
  }, {
    path: "/messageCentre/dialogs/CreateDialog",
    component: _06ba333d,
    name: "messageCentre-dialogs-CreateDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/messageCentre/dialogs/CreateDialog.vue').default
  }, {
    path: "/messageCentre/dialogs/TestDialog",
    component: _642a2f93,
    name: "messageCentre-dialogs-TestDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/messageCentre/dialogs/TestDialog.vue').default
  }, {
    path: "/reservation/dialogs/AddDisabledDateDialog",
    component: _2559e234,
    name: "reservation-dialogs-AddDisabledDateDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/dialogs/AddDisabledDateDialog.vue').default
  }, {
    path: "/reservation/dialogs/AddReasonDialog",
    component: _c27549a4,
    name: "reservation-dialogs-AddReasonDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/dialogs/AddReasonDialog.vue').default
  }, {
    path: "/reservation/dialogs/AddRestrictionDialog",
    component: _37ea6922,
    name: "reservation-dialogs-AddRestrictionDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/dialogs/AddRestrictionDialog.vue').default
  }, {
    path: "/reservation/dialogs/AddSectionRestrictionDialog",
    component: _038685d1,
    name: "reservation-dialogs-AddSectionRestrictionDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/dialogs/AddSectionRestrictionDialog.vue').default
  }, {
    path: "/reservation/dialogs/AddTimeSlotDialog",
    component: _143297d6,
    name: "reservation-dialogs-AddTimeSlotDialog",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/dialogs/AddTimeSlotDialog.vue').default
  }, {
    path: "/settings/reportEmail/emailSetting",
    component: _2ac3156f,
    name: "settings-reportEmail-emailSetting",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/reportEmail/emailSetting.vue').default
  }, {
    path: "/settings/xero/callback",
    component: _5060bc86,
    name: "settings-xero-callback",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings/xero/callback.vue').default
  }, {
    path: "/",
    component: _58df715a,
    name: "index",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/reservation/plans/edit/:id?",
    component: _c6be53f6,
    name: "reservation-plans-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/plans/edit/_id.vue').default
  }, {
    path: "/actionLogs/detail/:id?",
    component: _59dfb664,
    name: "actionLogs-detail-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/actionLogs/detail/_id.vue').default
  }, {
    path: "/appUsers/edit/:id?",
    component: _4009c036,
    name: "appUsers-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/appUsers/edit/_id.vue').default
  }, {
    path: "/dailySummary/edit/:id?",
    component: _198e4a82,
    name: "dailySummary-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dailySummary/edit/_id.vue').default
  }, {
    path: "/legacyIngredients/edit/:id",
    component: _0049e073,
    name: "legacyIngredients-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/legacyIngredients/edit/_id.vue').default
  }, {
    path: "/messageCentre/edit/:id?",
    component: _4a12cb8a,
    name: "messageCentre-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/messageCentre/edit/_id.vue').default
  }, {
    path: "/odsSettings/edit/:id?",
    component: _a15bb1aa,
    name: "odsSettings-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/odsSettings/edit/_id.vue').default
  }, {
    path: "/posDevices/edit/:id?",
    component: _17edaba3,
    name: "posDevices-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posDevices/edit/_id.vue').default
  }, {
    path: "/products/edit/:id?",
    component: _96043bf0,
    name: "products-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/products/edit/_id.vue').default
  }, {
    path: "/reservation/settings/:id?",
    component: _6b0ed859,
    name: "reservation-settings-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/reservation/settings/_id.vue').default
  }, {
    path: "/tvMenus/edit/:id?",
    component: _7bc3ae8c,
    name: "tvMenus-edit-id",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/tvMenus/edit/_id.vue').default
  }, {
    path: "/templateEditor/*",
    component: _187d00ff,
    name: "templateEditor-all",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/templateEditor/_.vue').default
  }, {
    path: "/*",
    component: _937e9b32,
    name: "all",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }],

  parseQuery: function(q) {
      return require("qs").parse(q);
    },
  stringifyQuery: function(q) {
      var r = require("qs").stringify(q);
      return r ? "?" + r : "";
    },
  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  router.routes = routerOptions.routes;

  return router
}
