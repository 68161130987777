var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loaded)?_c('div'):(_vm.$shop.castSetup)?_c('div',{staticClass:"h-full w-full",style:({
    width: _vm.showWidth + 'px',
    height: _vm.showHeight + 'px',
    transform: `rotate(${_vm.rotate}deg)`,
    position: 'absolute',
    left: _vm.showLeft + 'px',
    top: _vm.showTop + 'px',
  })},[_vm._t("default")],2):_c('div',{staticClass:"d-flex items-center flex-col"},[_c('div',[_vm._v("Device ID: "+_vm._s(_vm.keyCache.shortId))]),_c('img',{staticClass:"mt-5",staticStyle:{"max-width":"min(512px, 30vw)"},attrs:{"src":_vm.qrImage}})])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }