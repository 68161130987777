export const categoryColorList = {
  red: "#EC9393", // dark-text-danger
  orange: "#FF8E17", // Palette/orange300
  yellow: "#F2C94C", // Palette/yellow100
  green: "#4BC77F", // palette/green400
  purple: "#c4b5fd", // palette/purple300
  blue: "#3DB2F5", // palette/blue400
  grey: "#BDBDBD", // dark/icon/secondary
  white: "#FFFFFF",
};

export const odsColorList = {
  color1: "#ED6B6B",
  color2: "#EB930F",
  color3: "#ECC256",
  color4: "#47C498",
  color5: "#47A8ED",
  color6: "#C285EB",
  color7: "#D358A2",
  color8: "#D4A3AA",
  color9: "#D39278",
  color10: "#CABF7B",
  color11: "#B9AFF7",
  color12: "#6EC493",
  color13: "#69B4C7",
  color14: "#EB85AA",
};
