<template>
  <div>
    <style>
      @media print { @page { margin: 0mm {{isAndroid ? 4 : 17}}mm; } }
    </style>
    <a v-if="!$route.query.noprint" href="javascript:window.print()" class="print-button" v-t="'invoice.print'"></a>
    <nuxt />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAndroid: false,
    };
  },
  beforeMount() {
    this.isAndroid = !!window.navigator.userAgent.match(/android/gi);
    if (this.$route.query.locale) {
    }
  },
};
</script>

<style>
/*
* Author: Vasterad
* URL: http://purethemes.net
*/

/* ------------------------------------------------------------------- */
/* Invoice Styles
---------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600");

html {
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 100%;
}

strong {
  font-weight: 600;
  color: #333;
  display: inline-block;
}

body {
  background: #f4f4f4;
  color: #666;
  line-height: 28px;
}

.invoice {
  background: white;
  width: auto;
  max-width: 900px;
  padding: 60px;
  margin: 0px auto 60px auto;
  border-radius: 4px;
}

h1,
h2,
h3 {
  color: #333;
  clear: both;
  margin: 0;
}

h2 {
  font-size: 36px;
  line-height: 1;
  margin: 15px 0 45px 0;
}

p {
  margin: 0;
  padding-bottom: 40px;
  clear: both;
}

#logo img {
  max-height: 128px;
}

#details {
  text-align: right;
}

table {
  width: 100%;
}

table.productList {
  margin: 0 0 0px 0px;
  padding: 1px 0;
  border-spacing: 0;
}

.productList th,
.productList td {
  padding: 15px 0;
  text-align: left;
}

.productList th {
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #333;
}

.productList th:last-child,
.productList td:last-child {
  text-align: right;
}

.productList th span {
  position: relative;
  display: inline-block;
  height: 100%;
}

.productList th span::after {
  content: "";
  width: 100%;
  bottom: -16px;
  position: absolute;
  right: 0;
  border-bottom: 1px solid #2980b9;
}

#footer {
  width: 100%;
  border-top: 1px solid #ddd;
  margin: 60px 0 0 0;
  padding: 20px 0 0 0;
  list-style: none;
  font-size: 15px;
}

#footer li {
  display: inline-block;
  padding: 0 20px;
  border-right: 1px solid #ddd;
  line-height: 11px;
}

#footer li:first-child {
  padding-left: 0;
}
#footer li:last-child {
  border: none;
}

#footer li span {
  color: #2980b9;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}

.print-button,
.print-button:hover {
  line-height: 24px;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  background-color: #e6e6e6;
  border-radius: 50px;
  padding: 10px 20px;
  display: block;
  text-align: center;
  margin: 40px auto 40px auto;
  max-width: 190px;
  transition: 0.3s;
}

.print-button:hover {
  background-color: #e2e2e2;
}

/* Print Styles*/
@media print {
  .print-button {
    display: none !important;
  }

  body {
    background: #fff;
    height: 100%;
    color: #666;
  }

  strong,
  th,
  h1,
  h2,
  h3 {
    color: #111;
  }

  table,
  th,
  #footer,
  #footer li {
    border-color: #bbb;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  @page {
    size: A4;
  }

  .invoice {
    max-width: 100%;
    padding: 17mm 12px;
    margin: 0;
    position: relative;
    page-break-before: always;
  }

  #footer {
    position: fixed;
    bottom: 17mm;
  }

  .content-block,
  p {
    page-break-inside: avoid;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
