
import { FindType } from "@feathers-client";
import { Component, Prop, Ref, mixins } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import { ScannerEvent } from "pos-printer/scanner";

@Component
export default class StaffCodeDialog extends mixins(Dialog) {
  @Prop()
  defaultStaffCode;

  @Prop()
  defaultStaff;

  @Ref()
  staffIdInput: any;

  @Prop()
  permissions: string[];

  staffCode = "";

  verfiyStaffCode = "";

  showStaffCode = false;

  staff: FindType<"staffs"> = null;

  showPassword = false;

  password = "";

  staffCodeVerified = false;

  loading = false;

  error = false;

  errorMsg = null;

  focusedInput = "";

  created() {
    if (this.defaultStaffCode) {
      this.staffCode = this.defaultStaffCode;
      this.onEnter();
    }
    if (this.defaultStaff) {
      this.staff = this.defaultStaff;
      this.staffCodeVerified = true;
    }
    this.$scanner.registerHandler(this.onScanner);
  }

  beforeDestroy() {
    this.$scanner.unregisterHandler(this.onScanner);
  }

  async onScanner(info: ScannerEvent) {
    info.handled = true;
    if (info.type === 'keyboard' && this.staffIdInput === document.activeElement) return;
    this.reset();
    this.staffCode = info.code;
    info.handled = true;
    await this.onEnter();
  }

  reset() {
    this.staffCode = "";
    this.staff = null;
    this.loading = false;
  }

  async openScanner() {
    const code = await this.$openDialog(
      import("pos-printer/dialogs/QrScanner.vue"),
      {},
      {
        maxWidth: "80%",
      },
    );
    if (!code) return;
    this.staffCode = code;
    await this.onEnter();
  }

  async onEnter() {
    if (this.staffCodeVerified) {
      if (this.password === this.staff.passCode) {
        this.error = false;
        this.modalResult(this.staff);
        return;
      } else {
        this.error = true;
        this.errorMsg = this.$t("scanner.invalidPassCode");
        this.password = "";
        return;
      }
    }
    if (this.loading) return;
    this.loading = true;
    try {
      const staff = (
        await this.$feathers.service("staffs").find({
          query: {
            ...(/^[0-9a-fA-F]{24}$/.test(this.staffCode)
              ? {
                  _id: this.staffCode,
                }
              : {
                  code: this.staffCode,
                }),
            $paginate: false,
          },
          paginate: false,
        })
      )[0];

      if (!staff) {
        this.error = true;
        this.errorMsg = this.$t("scanner.invalidCode");
        this.staffCode = "";
        return;
      } else if (
        !this.permissions ||
        this.permissions.every(permission => staff?.permissions?.filter(p => p === permission).length)
      ) {
        this.error = false;
        this.staff = staff;
      } else {
        this.error = true;
        this.errorMsg = this.$t("scanner.noPermission");
        this.staffCode = "";
        return;
      }

      if (staff.passCode) {
        this.staffCodeVerified = true;
      } else {
        this.modalResult(this.staff);
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }
}
