
import _ from "lodash";
import { Component, Prop, Vue } from "nuxt-property-decorator";
import PointRecord from "./pointRecord.vue";
import { FindType, getID } from "@feathers-client";

@Component({
  components: {
    PointRecord,
  },
})
export default class TransactionList extends Vue {
  @Prop()
  type: string;

  @Prop()
  point: FindType<"shopPoints">;

  @Prop()
  dollar: FindType<"shopPoints">;

  @Prop()
  user: string;

  get couponQuery() {
    return {
      status: "used",
      ...(this.user ? { user: this.user } : {}),
      shop: this.$shop.shopId,
      $populate: ["coupon"],
    };
  }

  get pointQuery() {
    return {
      point: this.type === "point" ? getID(this.point) : getID(this.dollar),
      shop: this.$shop.shopId,
      ...(this.user ? { user: this.user } : {}),
      $populate: ["session"],
    };
  }
}
