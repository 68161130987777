
import { Component, Vue, FindType, FindPopRawType, Prop, PropSync, checkID, Watch, mixins, VModel } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorObjectPickerNew2 from "@schemaEditor/EditorObjectPickerNew2.vue";
import EditorUploader from "@schemaEditor/EditorUploader.vue";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";

type IngredientCostsType = FindPopRawType<["cat","supplier"],"ingredientCosts">;
type IngredientSuppliersType = FindPopRawType<["defaultExpensesInput.cat"],"ingredientSuppliers">;

@Component({ components: { EditorObjectPickerList, EditorObjectPickerNew2, EditorUploader, EditorDatePicker } })
export default class CostForm extends mixins(Dialog()) {
  // @Prop()
  // value: IngredientCostsType;
  ingredientSuppliersQuery = {
    $sort:{
      order:-1
    }
  }

  hasDefaultSettings = false;
  defaultSettings: IngredientSuppliersType['defaultExpensesInput'] = null;

  applySupplierDefaults() {
    this.resetSettings()
    this.item.cat = this.defaultSettings.cat;
    this.item.expensive = this.defaultSettings.expensive;
    this.item.cost = this.defaultSettings.cost;
    this.item.paymentType = this.defaultSettings.paymentType;
    this.hasDefaultSettings = false;
  }


  @VModel()
  item: IngredientCostsType;

  priceAsExpensive: number = 1000;

  costChanged() {
    if (this.isPriceExceed) this.item.expensive = true;
  }
  // get item() {
  //   return this.value;
  // }
  // set item(v) {
  //   this.$emit("input", v);
  // }

  get isPriceExceed() {
    return this.item?.cost >= this.priceAsExpensive;
  }

  get isCatExpensive() {
    return (this.item?.cat as any)?.expensive;
  }

  get paymentStatus() {
    return [
      {
        _id: "unPaid",
        name: { $t: "paymentStatus.unPaid" },
      },
      {
        _id: "paid",
        name: { $t: "paymentStatus.paid" },
      },
    ];
  }
  get paymentType() {
    return [
      {
        _id: "cash",
        name: { $t: "ingredientCosts.paymentType.cash" },
      },
      {
        _id: "monthly",
        name: { $t: "ingredientCosts.paymentType.monthly" },
      },
      {
        _id: "other",
        name: { $t: "ingredientCosts.paymentType.other" },
      },
    ];
  }

  @Watch("item.supplier")
  async loadDefaultSettings(newValue, oldValue){
    if(newValue && oldValue && newValue._id === oldValue._id) return;
    if(!newValue){
      this.hasDefaultSettings = false;
      this.defaultSettings = null;
    }
    try {
      const ingredientSuppliers : IngredientSuppliersType[] = await this.$feathers.service("ingredientSuppliers").find({
        query: {
          _id: newValue._id,
          $populate: ['defaultExpensesInput.cat'],
          $paginate: false,
        },
        paginate: false,
      }) as any;
      if(!ingredientSuppliers.length || !Object.keys(ingredientSuppliers[0]?.defaultExpensesInput || {}).length ){
        // this.resetDefaultSettings()
        this.hasDefaultSettings = false;
        this.defaultSettings = null;
      }else if(ingredientSuppliers.length){
        // this.item = {
        //   ...this.item,
        //   ...ingredientSuppliers[0]?.defaultExpensesInput,
        // }
        this.hasDefaultSettings = true;
        this.defaultSettings = ingredientSuppliers[0]?.defaultExpensesInput;
      }
    } catch (error) {
      // this.$store.commit("SET_ERROR", error.message);
      console.error(error)
    } finally {
    }
  }

  resetSettings(){
    this.item.expensive = undefined;
    this.item.cat = undefined;
    this.item.cost = undefined;
    this.item.paymentType = undefined;
    this.item.dueDate = undefined;
    this.item.inCharge = undefined;
    this.item.status = undefined;
    this.item.remark = undefined;
    this.item.images = undefined;
  }
    
    
  

  @Watch("item.expensive")
  expensiveChanged() {
    this.costChanged();
  }

  @Watch("item.cat.expensive")
  catChanged(v) {
    if (v) {
      this.item.expensive = true;
    }
  }

}
