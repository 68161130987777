import { checkID, CreateType, FindType, getID } from "@feathers-client";
import { ObjectID } from "@mfeathers/db/schemas";
import { MHookContext, MApplication, MService } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import errors from "@feathersjs/errors";
import _ from "lodash";
import moment from "moment";
import { getWeekdayName } from "@common/table/util";
import { OrderKitchenView, OrderKitchenViewItem } from "@common/table/invoiceSequencer";
import type { OfflineManager } from "../../";
import { ShopManager } from "@server/shop/shop";

export type ProductLine = Partial<FindType<"tableSessions">["products"][number]>;

export type CreateOrderType = CreateType<"tableSessions/order">;
export type OrderType = FindType<"tableSessions">;

async function updateOrder(
  hook: MHookContext<AdminApplication, CreateOrderType, OrderType>,
  opts?: {
    printReceipt?: boolean;
    updateIds?: string[];

    staffName?: string;
    kioskName?: string;
    actionSource?: "pos" | "online" | "kiosk";
    noSplit?: boolean;
    includeDetails?: boolean;
  },
) {
  const jobList: any[] = [];
  const toWaterBars: Set<string> = new Set();

  const session = hook.result;
  const shopCache = await hook.app?.$shopCache?.getShop(session.shop);
  const tables = await getMapById(
    hook.app.service("tableViewItems"),
    _.uniq(hook.result.products.map(it => getID(it.table)).filter(it => !!it)),
  );

  const jobFlatten: JobEntry[] = [];

  const updateIdDict = new Set(opts?.updateIds ?? []);

  for (let i = 0; i < hook.result.products.length; i++) {
    const item = hook.result.products[i];
    if (item.status === "init" || updateIdDict.has(item.id)) {
      item.status = "sent";

      const table = tables[getID(item.table)];
      const job: Partial<OrderKitchenView> = {
        session: session,

        tableName: table?.name,
        tableSplit: item.tableSplit,
        chairId: item.tableChairId,
        table,

        staffName: opts?.staffName,
        kioskName: opts?.kioskName,
        actionSource: opts?.actionSource,

        type: updateIdDict.has(item.id) ? "edit" : "new",
      };

      const entries = getProductItems(item, job, shopCache);

      jobFlatten.push(...entries);
      for (let entry of entries) {
        if (entry.filter.waterBar) {
          toWaterBars.add(entry.filter.waterBar);
        }
      }
    }
  }

  const patches: any = {};

  if (toWaterBars.size && hook.params.addTvStatus && !session.tvStatus) {
    patches.tvStatus = "making";
    const tvStatuses = await Promise.all(
      Array.from(toWaterBars).map(key =>
        hook.app.service("tvOrderStatuses").create({
          shop: getID(session.shop),
          code: session.sessionName,
          orderId: getID(session),
          status: "making",
          waterBar: getID(key),
        }),
      ),
    );
    patches.tvOrderStatuses = tvStatuses.map(it => it._id);
  }

  hook.result = await hook.app.service("tableSessions").patch(hook.data.session, {
    products: session.products,
    ...patches,
  });

  if (opts?.printReceipt && hook.result.type === "dineIn") {
    jobList.push({
      type: "table-receipt",
      job: {
        jobType: "table-receipt",
        session,
        actionSource: opts?.actionSource ?? "pos",
      },
    });
  }
  if (!hook.data?.noPrint) {
    await handlePrintJob(
      hook.app,
      hook.result,
      jobList,
      jobFlatten,
      {
        noSplit: opts?.noSplit,
        includeDetails: opts?.includeDetails,
      },
      hook.data.device,
    );
  }
}

export async function handlePrintJob(
  app: MApplication<AdminApplication>,
  session: OrderType,
  jobList: any[],
  jobFlatten: JobEntry[] = [],
  options?: {
    noSplit?: boolean;
    includeDetails?: boolean;
  },
  device?: ObjectID<"posDevices">,
) {
  const [kitchenPrinters, waterBars] = await Promise.all([
    getMapById(
      app.service("kitchenPrinters"),
      jobFlatten.map(it => it.filter.kitchen),
      true,
    ),
    getMapById(
      app.service("waterBars"),
      jobFlatten.map(it => it.filter.waterBar),
      true,
    ),
  ]);

  for (let [key, list] of Object.entries(_.groupBy(jobFlatten, job => job.filter.kitchen || job.filter.waterBar))) {
    let totalQuantity = 0;
    for (let item of list) {
      item.item.curQuantity = totalQuantity;
      totalQuantity += item.item.product.quantity;
    }
    for (let item of list) {
      item.item.totalQuantity = totalQuantity;
    }
  }

  const jobGroup = _.groupBy(jobFlatten, job => {
    const keys: string[] = [];
    if (job.filter.waterBar) {
      keys.push(job.filter.waterBar);
      const bar = waterBars[job.filter.waterBar];
      if (!options?.noSplit) {
        if (bar?.splitOrder || bar?.splitOrderOnlyForShops?.find?.(s => checkID(s, session.shop))) {
          keys.push(job.filter.entry);
        }
      }
    }
    if (job.filter.kitchen) {
      keys.push(job.filter.kitchen);
      const bar = kitchenPrinters[job.filter.kitchen];
      if (!options?.noSplit) {
        if (bar?.splitOrder || bar?.splitOrderOnlyForShops?.find?.(s => checkID(s, session.shop))) {
          keys.push(`${job.item.index}`);
        }
      }
    }
    if (job.item.product.takeAway) keys.push("takeAway");
    return keys.join(",");
  });

  for (let list of Object.values(jobGroup)) {
    jobList.push({
      type: list[0].type,
      filter: list[0].filter ? _.pick(list[0].filter, "kitchen", "waterBar") : null,
      job: {
        ...list[0].job,
        jobType: list[0].type,
        items: list.map(it => it.item),
        includeDetails: options?.includeDetails,
        takeAway: !!list.find(it => it.item.product.takeAway),
      } as OrderKitchenView,
      entry: list.map(it => it.filter.entry),
    });
  }

  if (!jobList.length) return;
  function getIndex(item: any) {
    switch (item.type) {
      case "table-waterBar":
        return 0;
      case "table-kitchen":
        return 1;
      case "table-receipt":
        return 2;
      default:
        return 3;
    }
  }
  jobList.sort((a, b) => getIndex(a) - getIndex(b));

  const offline: OfflineManager = (app as any).$offline;

  for (let job of jobList) {
    const jobs = await offline.root.$shop.queuePrintJobs(job.job, `${device}`, job.filter);

    const entries: string[] = job.entry ? (Array.isArray(job.entry) ? job.entry : [job.entry]) : [];

    if (jobs.length) {
      for (let entry of entries) {
        const item = session.products.find(it => it.id === entry);
        if (item) {
          item.printJob = jobs[jobs.length - 1]?._id;
        }
      }
    }
  }

  await app.service("tableSessions").patch(session._id, {
    products: session.products,
  });
}

function getRootNode(edgeMap: Record<string, string>, node: string) {
  while (edgeMap[node]) {
    const next = edgeMap[node];
    if (next === node) {
      break;
    }
    node = next;
  }
  return node;
}

export async function create(hook: MHookContext<AdminApplication, CreateOrderType, FindType<"tableSessions">>) {
  if (hook.params.query?.addTvStatus) {
    hook.params.addTvStatus = true;
  }
  const session = await hook.app.service("tableSessions").get(hook.data.session, { mongoose: { lean: false } });

  if (!["done", "ongoing", "toPay", "booking", "queue", "test"].includes(session.status || "ongoing")) {
    throw new errors.BadRequest("Invalid session status: " + session.status);
  }

  const seq = (_.max(session.products.map(it => it.seq)) ?? 0) + 1;
  const prodSeq = session.prodSeq || 0;

  let newItems: ProductLine[] = <any>hook.data.products;
  let updateData: any = {};

  // if (session.type === "takeAway" || session.type === "dineInNoTable") {
  //   const estimatedTime =
  //     (
  //       await hook.app.service("estimatedOrderTimes").find({
  //         query: {
  //           orderType: session.type,
  //           shop: getID(session.shop),
  //           weekdays: getWeekdayName(new Date()),
  //           startTime: { $lte: moment().format("HH:mm") },
  //           endTime: { $gte: moment().format("HH:mm") },
  //         },
  //         paginate: false,
  //       })
  //     )[0]?.estimatedTime ?? 15;
  //   updateData.estimatedTime = estimatedTime;
  // }

  const replaceDict = Object.fromEntries((hook.data.replaceProducts || []).map(it => [it, true]));

  const replaceItems = newItems.filter(it => replaceDict[it.id]);
  newItems = newItems.filter(it => !replaceDict[it.id]);

  for (let i = 0; i < replaceItems.length; i++) {
    const item = replaceItems[i];
    delete replaceDict[item.id];
    const curIdx = session.products.findIndex(it => it.id === item.id);
    if (curIdx !== -1) {
      session.products[curIdx] = {
        ...session.products[curIdx],
        ...item,
      };
    }
  }

  const idDict = Object.fromEntries(session.products.map(p => [p.id, p] as const));
  for (let item of newItems) {
    if (idDict[item.id]) {
      throw new errors.BadRequest("ID Conflict");
    }
    idDict[item.id] = item as any;
  }

  const edgeMap = Object.fromEntries(newItems.map(it => [it.id, it.fromProduct]));
  const rootProductMap = _.groupBy(
    newItems.map(it => it.id),
    it => getRootNode(edgeMap, it),
  );
  const rootProducts = Object.keys(rootProductMap);

  for (let item of newItems) item.seq = seq;
  for (let i = 0; i < newItems.length; i++) {
    const root = getRootNode(edgeMap, newItems[i].id);
    newItems[i].prodSeq = prodSeq + i + 1;
    newItems[i].staff = hook.data.staff as any;
    newItems[i].rootProduct = `${rootProducts.indexOf(root) + 1} - ${rootProductMap[root].indexOf(newItems[i].id) + 1}`;
  }

  session.products.push(...(newItems as any[]));

  const offline: OfflineManager = (hook as any).$offline;

  if (!session.testing) {
    await offline.lockStocks(hook, session, newItems, !hook.data.verify, session.source === "selfOrder");
  }

  for (let item of newItems) {
    if (!item.waterBars?.length) {
      item.kdsStatus = "done";
    }
  }

  hook.result = await hook.app.service("tableSessions").patch(session._id, {
    products: session.products,
    ...updateData,
  });

  const staff = hook.data.staff && (await hook.app.service("staffs").get(hook.data.staff));

  await updateOrder(hook, {
    printReceipt: true,
    staffName: staff?.name,
    actionSource: (hook.data.actionSource as any) ?? "pos",
    noSplit: hook.data.noSplit,
    updateIds: replaceItems.map(it => it.id),
  });

  if (Object.keys(replaceDict).length) {
    // need to cancel
    hook.result = await hook.app.service("tableSessions/cancel").create({
      session: hook.result._id,
      products: Object.keys(replaceDict),
      noPrint: hook.data.noPrint,
    });
  }
}

export async function patch(hook) {
  if (hook.params.query?.addTvStatus) {
    hook.params.addTvStatus = true;
  }
  const session = await hook.app.service("tableSessions").get(hook.data.session);
  hook.result = session;
  let newItems: ProductLine[] = hook.data.products as any;

  const offline: OfflineManager = (hook as any).$offline;
  const shopInfo = offline.root?.$shop.shopData;
  if (hook.data.verify) {
    throw new Error("Not implemented");
  }

  const kdsStockUpdates: Record<string, Record<string, number>> = {};

  for (let i = 0; i < newItems.length; i++) {
    const item = newItems[i];

    if (item.kdsStatus !== undefined && hook.data.updateKdsStock && shopInfo.kdsProductEnabled && item.product) {
      const current = session.products.find(it => it.id === item.id);
      if (current && item.kdsStatus !== current.kdsStatus && current.kitchenPrinters?.length) {
        const nowIsDone = current.kdsStatus === "done" || current.kdsStatus === "partialDone";
        const toDone = item.kdsStatus === "done" || item.kdsStatus === "partialDone";
        if (nowIsDone !== toDone && (toDone || (!toDone && item.kdsStockUsed > 0))) {
          let update = kdsStockUpdates[getID(item.product)];
          if (!update) {
            update = kdsStockUpdates[getID(item.product)] = {};
          }
          const hash = optionHash(current);
          update[hash] = (update[hash] || 0) + (toDone ? current.quantity : -item.kdsStockUsed);
          item.kdsStockUsed = toDone ? current.quantity : 0;
        }
      }
    }

    const line = session.products.find(it => it.id === item.id);
    if (line) {
      Object.assign(line, item);
    }
  }
  hook.result = await hook.app.service("tableSessions").patch(hook.data.session, {
    products: session.products,
    ...(session.status === "done" || session.status === "toPay"
      ? {
          endTime: null,
          status: "ongoing",
        }
      : {}),
  });
  const staff = hook.data.staff && (await hook.app.service("staffs").get(hook.data.staff));
  await updateOrder(hook, {
    updateIds: hook.data.reprint ? newItems.map(it => it.id) : [],
    staffName: staff?.name,
    actionSource: (hook.data.actionSource as any) ?? "pos",
    noSplit: hook.data.noSplit,
    includeDetails: hook.data.includeDetails,
  });

  // TODO: fix kds stock
  // await Promise.all(
  //   Object.entries(kdsStockUpdates).map(async ([product, list]) => {
  //     // TODO: update multiple
  //     for (let [hash, quantity] of Object.entries(list)) {
  //       await hook.app.service("products/kdsStocks/adjust").create({
  //         product,
  //         hash,
  //         quantity: Math.abs(quantity),
  //         shop: shopInfo?._id,
  //         type: quantity > 0 ? "use" : "undoUse",
  //       });
  //     }
  //   }),
  // );
}

const weekday = {
  Sunday: 7,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export function checkTime(weekdays: string[], from: string, to: string, time: Date) {
  const m = moment(time);
  const days = weekdays.map(day => weekday[day]);
  const ts = m.format("HH:mm");

  if (!days.includes(m.get("isoWeekday")) || ts < from || ts > to) {
    return false;
  }
  return true;
}
export interface JobEntry {
  item: OrderKitchenViewItem;
  job: Partial<OrderKitchenView>;
  type: string;
  filter: {
    kitchen?: string;
    waterBar?: string;
    entry: string;
  };
}

export function getProductItems(
  product: Partial<ProductLine>,
  job: Partial<OrderKitchenView>,
  shopCache: ShopManager,
  def: Partial<OrderKitchenViewItem> = {},
): JobEntry[] {
  const entries: JobEntry[] = [];
  for (let key of product.kitchenPrinters ?? []) {
    const categoryName = shopCache?.categories?.[getID(product.category)]?.name;
    entries.push({
      job,
      type: "table-kitchen",
      item: {
        ...def,
        product: product,
        index: product.prodSeq,
        name: product.name,
        shortName: product.shortName,
        fromProduct: product.fromProduct,
        rootProduct: product.rootProduct,
        categoryName: categoryName,
      },
      filter: {
        kitchen: getID(key),
        entry: product.id,
      },
    });
  }

  for (let key of product.waterBars ?? []) {
    const categoryName = shopCache?.categories?.[getID(product.category)]?.name;
    entries.push({
      job,
      type: "table-waterBar",
      item: {
        ...def,
        product: product,
        index: product.prodSeq,
        name: product.name,
        shortName: product.shortName,
        fromProduct: product.fromProduct,
        rootProduct: product.rootProduct,
        categoryName: categoryName,
      },
      filter: {
        waterBar: getID(key),
        entry: product.id,
      },
    });
  }
  return entries;
}

export function optionHash(line: ProductLine) {
  const type = line.takeAway ? "T" : "D";
  const options = (line.options || []).slice().sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  const hash = options
    .map(opt => {
      const optNum = opt.selections
        .slice()
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .map(opt => [opt._id, opt.quantity] as [any, number])
        .reduce(
          (a, b) => {
            if (String(a[a.length - 1]?.[0]) === String(b[0])) {
              a[a.length - 1][1] = b[1];
            } else {
              a.push(b);
            }
            return a;
          },
          [] as [any, number][],
        );

      return `${opt.option}:${optNum.map(opt => `${opt[0]}@${opt[1]}`).join(",")}`;
    })
    .join(";");
  return `${type}|${hash}`;
}

export async function getMapById<T>(service: MService<T>, ids: string[], optional = false) {
  ids = _.uniq(ids).filter(it => !!it);
  if (!ids.length) {
    return {};
  }
  const items = await service.find({
    query: {
      _id: { $in: ids },
      $paginate: false,
    } as any,
    paginate: false,
  });

  const dict: {
    [key: string]: T;
  } = {};
  for (let item of items) {
    dict[getID(item as any)] = item as any;
  }

  if (!optional) {
    for (let id of ids) {
      if (!dict[id]) {
        throw new errors.NotFound(`${id} not found in ${(service as any).id}`);
      }
    }
  }

  return dict;
}
