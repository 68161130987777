
import type { ProductLine, ProductLineOption, TableSession } from "~/plugins/table/session";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import _ from "lodash";
import type { CartItem } from "@common/table/cart";
import { TableSessionRef } from "~/plugins/table";
import { checkID, getID } from "@feathers-client";
import type { ProductType } from "~/plugins/shop";
import { StockLevel, getProductTree, getProductTreeInfo } from "@common/table/util";
import type { LangArrType } from "@feathers-client/i18n";
import NestedRouter from "@feathers-client/components/NestedRouter.vue";

@Component
export default class OrderSystemProductLineSide extends Vue {
  @Prop()
  session: TableSession;

  @Ref()
  router: NestedRouter;

  editCartItem: boolean = false;

  get line() {
    return this.session?.selectingCartItem?.line;
  }

  get cart() {
    return this.session?.selectingCartItem?.cart;
  }

  currentTempPriceVal: number = null;
  get currentTempPrice() {
    return this.currentTempPriceVal === null ? this.cart?.price : this.currentTempPriceVal;
  }

  set currentTempPrice(v) {
    this.currentTempPriceVal = v;
  }

  get item(): (CartItem | ProductLine) & { replacing?: boolean } {
    return this.cart || this.line;
  }

  @Watch("item")
  onItemChanged() {
    // temp fix, will remove router later
    this.router.reset(this.orderSystem ? "order" : this.shouldShowOptions ? "options" : "cart");
  }

  get editingOptions() {
    return this.session?.selectingCartItem?.showOptions;
  }

  set editingOptions(v) {
    if (this.session?.selectingCartItem) {
      this.session.selectingCartItem.showOptions = v;
    }
  }

  currentPage = "";
  tempKitchenOptions: { _id: string; name: LangArrType }[] = [];

  get hasNextOptions() {
    if (!this.cart) return null;
    const list = this.session.cart;
    let cartIndex = list.indexOf(this.cart);
    if (cartIndex === -1) return null;

    for (let i = cartIndex + 1; i < list.length; i++) {
      if (list[i].pendingOptions) return list[i];
    }
    return null;
  }

  get orderSystem() {
    return !!this.line;
  }

  get orderReadOnly() {
    return !this.session?.postEditing && this.session?.status !== "ongoing";
  }

  get isCancelled() {
    return this.item.status === "cancel";
  }

  get cancellable() {
    // only can cancel
    // 1. if not cancelled
    // 2. order is not read only
    // 3. if in cart, not set product
    return (
      !this.isCancelled &&
      !this.item.fromCoupon &&
      (!this.line || !this.line.fromProduct) &&
      !this.orderReadOnly &&
      (!this.line || this.$shop.hasStaffRole("cancelProduct"))
    );
  }

  get shouldShowOptions() {
    return this.editingOptions || this.cart?.pendingOptions;
  }

  goKitchenOptions() {
    this.tempKitchenOptions = this.item.kitchenOptions as any;
    this.router.navigate("kitchenOptions");
  }

  editNumber(method) {
    if (method == "add") {
      this.item.quantity++;
    } else {
      this.item.quantity--;
      if (this.item.quantity <= 0) {
        this.moveFromCart();
        return;
      }
    }
  }
  moveFromCart() {
    this.$emit("remove");
    this.session.openProductMenu(null);
  }

  async splitItem() {
    if (!this.cart) return;
    const curIdx = this.session.cart.indexOf(this.cart);
    const offset = this.cart.moveTo(curIdx); // offset after all related items
    this.cart.quantity--;
    const newItem = await this.session.cloneItem(this.cart);
    if (newItem) {
      newItem.quantity = 1;
    }
  }

  async cancelProduct() {
    if (!this.line) {
      this.moveFromCart();
      return;
    }
    const c = await this.$openDialog(
      import("./cancelReason.vue"),
      {
        line: [this.line],
        session: this.session,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
    if (c) {
      this.session.updateCoupons();
    }
  }

  async reprintProduct() {
    if (!this.line) return;
    try {
      await this.$feathers.service("tableSessions/reprint").create({
        session: this.session.id,
        products: [this.line.id],
        staff: this.$shop.staffId,
      });
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  get isCurrentProduct() {
    if (this.cart) {
      return this.cart.relatedTree.includes(this.hasNextOptions);
    }
  }

  navigateNext() {
    if (this.hasNextOptions) {
      this.session.openProductMenu({
        cart: this.hasNextOptions,
        showOptions: true,
      });
      return;
    }
  }

  async confirmPriceEdit() {
    if (this.currentTempPriceVal !== null) {
      if (!this.$shop.hasPermission([`orderManage/tableSessionEditProductPrice`])) {
        const staff = await this.$shop.checkPermission(["orderManage/tableSessionEditProductPrice"]);
        if (staff === false) return;

        this.cart.editPriceStaff = staff;
      } else {
        this.cart.editPriceStaff = this.$shop.staff;
      }

      this.cart.manualPrice = this.currentTempPriceVal;
    }
    this.router.navigate();
  }

  async editCustomProduct() {
    this.closeMenu();
    const resp = await this.$openDialog(
      import("./customProduct/addDialog.vue"),
      {
        session: this.session,
        cartItem: this.cart,
      },
      {
        maxWidth: "max(50vw,500px)",
        contentClass: "h-80vh",
      },
    );
  }

  async editConfirmed() {
    let cur = this.line;
    while (cur?.fromProduct) {
      cur = this.session.products.find(it => cur.fromProduct === it.id);
    }
    if (!cur) return;
    const tree = getProductTree(this.session, cur).filter(it => it.status !== "cancel");
    const newItems = await this.session.addFromJSONs(tree, { replacing: true });
    for (let item of tree) {
      Vue.set(item, "replacing", true);
    }
    this.session.openProductMenu({ cart: newItems[0], showOptions: true });
    await this.session.updateAutoMerge();
    // this.closeMenu();
    this.session.openProductMenu({ cart: this.cart, line: this.line, showOptions: true });
  }

  async cancelEdit() {
    let cur = this.line;
    if (!cur && this.cart?.fromLine) {
      cur = this.session.products.find(it => this.cart.fromLine.id === it.id);
    }
    while (cur?.fromProduct) {
      cur = this.session.products.find(it => cur.fromProduct === it.id);
    }
    if (!cur) return;
    const tree = getProductTree(this.session, cur);
    for (let item of tree) {
      for (let it of this.session.cart) {
        if (it.id === item.id) {
          it.remove();
        }
      }
    }
    await this.session.reload();
    this.closeMenu();
  }

  async confirmKitchenOptions(navigate: any) {
    try {
      try {
        await this.$feathers.service("tableSessions/order").patch(null, {
          session: this.session.item._id,
          products: [
            {
              ...this.line,
              kitchenOptions: this.tempKitchenOptions,
            },
          ],
          reprint: true,
          staff: this.$shop.staffId,
        });
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
      navigate();
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.mesage);
    } finally {
    }
  }

  async manualAdjustment(item: CartItem | ProductLine, fromLine: boolean = false) {
    let tempCart: CartItem[] = [];
    if (fromLine) {
      let cur = this.line;
      while (cur?.fromProduct) {
        cur = this.session.products.find(it => cur.fromProduct === it.id);
      }
      if (!cur) return;
      const tree = getProductTree(this.session, cur).filter(it => it.status !== "cancel");
      tempCart = await this.session.addFromJSONs(tree, { temp: true });
      item = tempCart.filter(it => !it.fromProduct)?.[0];
    }
    const result: { item: CartItem; staff: string } = await this.$openDialog(
      import("./manualAdjustDialog.vue"),
      {
        session: this.session,
        cart: item,
      },
      {
        maxWidth: "600px",
      },
    );
    if (fromLine && result) {
      for (let [k, v] of Object.entries(result.item)) {
        Vue.set(item, k, v);
      }
      await this.$feathers.service("tableSessions/order").create({
        session: this.session.item._id,
        products: tempCart.map(it => it.toLine()),
        replaceProducts: tempCart.map(it => it.id),
        staff: result.staff,
        noPrint: true,
        update: {
          ...this.session.cachedPriceDetails,
        },
        device: this.$shop.device._id,
      });

      await this.$feathers.service("actionLogs").create({
        session: this.session.item._id,
        view: getID(this.session.item.view),
        staff: result.item.staff,
        type: "orderManage/tableSessionEditProductPrice",
        detail: {
          cartItem: result.item,
          originalPrice: result.item.originalPrice,
          newPrice: result.item.manualPrice,
          productName: result.item.name,
        },
      });

      this.line.predestinedDiscount = result.item.predestinedDiscount as any;
      this.line.predestinedDiscountName = result.item.predestinedDiscountName;
      this.line.overridePrice = result.item.overridePrice;
      this.line.openKey = result.item.openKey;
      this.line.manualPercentAdjust = result.item.manualPercentAdjust;
    }
    await this.session.updateCachedInfo(true);

    await this.session.updateAutoMerge();

    this.session.syncOrder();
  }

  compareRef(a: TableSessionRef, b: TableSessionRef) {
    return !!a === !!b && a && b && a.table === b.table && a.split === b.split;
  }

  get tableRef() {
    return this.session.tableRefs.find(
      it => checkID(it.table.id, this.item.table) && it.split === this.item.tableSplit,
    );
  }

  set tableRef(v) {
    if (!this.cart) return;
    this.cart.tableRef = v
      ? {
          table: v.table.id,
          split: v.split,
          chairId: null, // TODO: chair
        }
      : null;
  }

  get targetRef() {
    return (
      this.item.table &&
      this.session.tableRefs.find(it => it.table.id === `${this.item.table}` && it.split === this.item.tableSplit)
    );
  }

  get targetName() {
    return (
      (this.line as any)?.targetName || (this.targetRef ? this.targetRef.session.getSplitName(this.targetRef) : "")
    );
  }

  closeMenu() {
    this.session.openProductMenu(null);
  }

  adjustPrice() {
    this.currentTempPriceVal = null;
    this.router?.navigate?.("customPrice");
  }

  get isGenerated() {
    return this.item.fromProduct || this.item.fromCoupon;
  }
}
