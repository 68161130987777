
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";

@Component
export default class TwTaxCreditCardInstallment extends Vue {
  @Prop()
  session: TableSession;

  period: number = 1;
  periodStr: string = "1";

  mounted() {
    this.flushUpdates();
  }

  @Watch("period")
  onAmount() {
    this.flushUpdates();
  }

  async flushUpdates() {
    this.session.sessionData.twTaxPeriod = this.period;
    this.session.delaySave({
      twTaxPeriod: this.session.sessionData.twTaxPeriod,
    });

    this.session.syncOrder();
  }
}
