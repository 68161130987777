
import {
  Component,
  Vue,
  FindType,
  FindPopRawType,
  Prop,
  PropSync,
  getID,
  checkID,
  Watch,
  mixins,
} from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import CategoriesFilter from "~/components/ingredient/CategoriesFilter.vue";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import _ from "lodash";

@Component({
  components: {
    EditorObjectPickerList,
    CategoriesFilter,
    EditorDatePicker,
  },
})
export default class DayRecords extends Vue {
  @Prop()
  value;

  sorting = null;
  sCat = null;
  sStatus = null;

  sCosts = null;

  editing = false;
  selectedItems = [];

  selectAll = false;

  items: FindPopRawType<["supplier", "cat"], "ingredientCosts">[] = [];
  itemSelectState = {};

  @Watch("date")
  dateChanged() {
    this.loadData();
  }

  @Watch("selectAll")
  selectAllChanged() {
    if (this.selectAll) {
      this.itemSelectState = _.fromPairs(this.items.map(item => [item._id, true]));
    } else if (this.selectAll === false) {
      this.itemSelectState = _.fromPairs(this.items.map(item => [item._id, false]));
    }
  }

  @Watch("itemSelectState", { deep: true })
  itemSelectStateChanged() {
    this.selectedItems = Object.entries(this.itemSelectState)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    if (Object.values(this.itemSelectState).every(v => v === true)) {
      this.selectAll = true;
    } else if (Object.values(this.itemSelectState).every(v => v === false)) {
      this.selectAll = false;
    } else {
      this.selectAll = null;
    }
  }

  goEdit() {
    this.$router.push(`/legacyIngredients/edit/?date=${this.date && this.$moment(this.date).format("YYYY-MM-DD")}`);
  }

  async mounted() {
    this.$store.commit("SET_TITLE", {
      title: { $t: "pages.ingredients.expenses.$" },
      fullPage: true,
      actions: [
        {
          icon: "add",
          name: "add",
          action: "add",
          altText: { $t: "pages.ingredients.expenses.add" },
          textIcon: true,
        },
      ],
    });
    this.loadData();
  }

  async debugging() {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.deleteConfirm"),
        desc: this.$t("pages.ingredients.expenses.deleteConfirmDesc"),
      },
      {
        maxWidth: "400px",
      },
    );
  }

  async loadData() {
    this.items = (await this.$feathers.service("ingredientCosts").find({
      query: {
        ...this.detailsQuery,
        $sort: { date: -1 },
        $paginate: false,
      },
      paginate: false,
    })) as any;
    this.itemSelectState = Object.fromEntries(this.items.map(item => [getID(item), false]));
  }

  async cloneRecord(id) {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.cloneConfirm"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    await this.$feathers.service("dailySummary/cloneIngredientCosts").create({ id });
    this.loadData();
    this.$emit("updated");
  }
  async deleteRecord(id) {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.deleteConfirm"),
        confirmBtnClass: "!bg-light-action-danger-default !text-white",
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    await this.$feathers.service("dailySummary/removeIngredientCosts").create({ id });
    this.loadData();
    this.$emit("updated");
  }
  async cloneSelected() {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.cloneSelectedConfirm"),
        desc: this.$t("pages.ingredients.expenses.itemSelected", { count: this.selectedItems.length }),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    await this.$feathers.service("dailySummary/cloneIngredientCosts").create({ id: this.selectedItems });
    this.loadData();
    this.$emit("updated");
  }
  async deleteSelected() {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.deleteSelectedConfirm"),
        desc: this.$t("pages.ingredients.expenses.itemSelected", { count: this.selectedItems.length }),
        descClass: "text-light-action-danger-default",
        confirmBtnClass: "!bg-light-action-danger-default !text-white",
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    await this.$feathers.service("dailySummary/removeIngredientCosts").create({ id: this.selectedItems });
    this.loadData();
    this.$emit("updated");
  }
  async cloneAll() {
    const c = await this.$openDialog(
      import("~/components/dialogs/ConfirmDialog.vue"),
      {
        title: this.$t("pages.ingredients.expenses.cloneAllConfirm"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    await this.$feathers.service("dailySummary/cloneIngredientCosts").create({ id: _.keys(this.itemSelectState) });
    this.loadData();
    this.$emit("updated");
  }

  get date() {
    return this.value;
  }
  set date(v) {
    this.$emit("input", v);
  }

  async openCatBottomSheet() {
    const resp = await this.$openDialog(
      CategoriesFilter,
      {
        sorting: this.sorting,
        cat: this.sCat,
        status: this.sStatus,
      },
      { contentClass: "fixed w-full bottom-0" },
    );
    if (resp) {
      this.sorting = resp.sorting;
      this.sCat = resp.cat;
      this.sStatus = resp.status;
    }
  }

  get detailsQuery() {
    const $sort = this.sorting === "oldest" ? { date: 1 } : { date: -1 };
    const status = this.sStatus;
    const cat = this.sCat?._id;
    return {
      date: {
        $gte: this.$moment(this.date).startOf("day").toDate(),
        $lte: this.$moment(this.date).endOf("day").toDate(),
      },
      ...(status ? { status } : {}),
      ...(cat ? { cat } : {}),
      $sort,
      $populate: ["supplier", "cat"],
    };
  }
  get paymentStatus() {
    return [
      {
        _id: "unPaid",
        name: this.$t("paymentStatus.unPaid"),
      },
      {
        _id: "paid",
        name: this.$t("paymentStatus.paid"),
      },
    ];
  }

  get paymentTypes() {
    return [
      {
        _id: "cash",
        name: this.$t("ingredientCosts.paymentType.cash"),
      },
      {
        _id: "monthly",
        name: this.$t("ingredientCosts.paymentType.monthly"),
      },
      {
        _id: "other",
        name: this.$t("ingredientCosts.paymentType.other"),
      },
    ];
  }
}
