
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'
import { getSplitName } from "~/plugins/table/utils";
import { SessionConainterLayout } from '~/plugins/table/view'
import type { Shape } from "konva/lib/Shape";
import type { Group } from "konva/lib/Group";
import { TableItem } from '~/plugins/table'

@Component
export default class ItemSession extends Vue {
    @Prop()
    layout : SessionConainterLayout

    @Prop()
    item: TableItem

    get groupConfig() {
        return {
            x: this.layout.x + this.layout.w / 2,
            y: this.layout.y + this.layout.h / 2,
            listening: false,
        }
    }

    configContainerFunc(x: number, y: number) {
        return {
            x,
            y,
            width: this.layout.w,
            height: this.layout.h,
            fill: 'white',
            cornerRadius: 10,
            shadowOffset: {
                x: 3,
                y: 3,
            },
            stroke: this.$config.colors.grey200,
            strokeWidth: 3,
            shadowColor: 'rgba(150, 134, 190, 0.12)',
            fillAfterStrokeEnabled: true,
            sceneFunc(context : CanvasRenderingContext2D, shape : Shape) {
                const cornerRadius : number = (shape as any).cornerRadius();
                const width = shape.width(), height = shape.height();
                context.beginPath();
                if (!cornerRadius) {
                    // simple rect - don't bother doing all that complicated maths stuff.
                    context.rect(0, 0, width, height);
                }
                else {
                    let topLeft = 0;
                    let topRight = 0;
                    let bottomLeft = 0;
                    let bottomRight = 0;
                    if (typeof cornerRadius === 'number') {
                        topLeft = topRight = bottomLeft = bottomRight = Math.min(cornerRadius, width / 2, height / 2);
                    }
                    else {
                        topLeft = Math.min(cornerRadius[0] || 0, width / 2, height / 2);
                        topRight = Math.min(cornerRadius[1] || 0, width / 2, height / 2);
                        bottomRight = Math.min(cornerRadius[2] || 0, width / 2, height / 2);
                        bottomLeft = Math.min(cornerRadius[3] || 0, width / 2, height / 2);
                    }
                    context.moveTo(topLeft, 0);
                    context.lineTo(width - topRight, 0);
                    // context.arc(width - topRight, topRight, topRight, (Math.PI * 3) / 2, 0, false);
                    context.lineTo(width, topRight);
                    context.lineTo(width, height - bottomRight);
                    context.arc(width - bottomRight, height - bottomRight, bottomRight, 0, Math.PI / 2, false);
                    context.lineTo(bottomLeft, height);
                    context.arc(bottomLeft, height - bottomLeft, bottomLeft, Math.PI / 2, Math.PI, false);
                    context.lineTo(0, topLeft);
                    context.arc(topLeft, topLeft, topLeft, Math.PI, (Math.PI * 3) / 2, false);
                }
                context.closePath();
                (context as any).fillStrokeShape(this);
            }
        }
    }

    get configBgContainer() {
        return this.configContainerFunc(-this.layout.w / 2 + 5, -this.layout.h / 2 + 5);
    }

    get configContainer() {
        return this.configContainerFunc(-this.layout.w / 2, -this.layout.h / 2);
    }

    get configCorner() {
        const x = this.layout.w / 2;
        const y = -this.layout.h / 2;
        return {
            points: [
                x - 10,
                y,

                x - 10,
                y + 10,

                x,
                y + 10,
            ],
            closed: true,
            fill: '#ccc',
        }
    }

    get configLabel() {
        const w = this.layout.w;
        const h = this.layout.h;

        return {
            x: -w / 2 + 8,
            y: -h / 2 + 10,
            width: w - 5,
            height: h / 2,
            align: 'left',
            verticalAlign: 'top',
            text: `X${this.layout.items.length}`,
            fontFamily: this.$config.fontFamily,
            fontSize: Math.min(w / 2, 32),
            perfectDrawEnabled: false,
            listening: false,
            fill: '#828282',
        }
    }

    get timerGroup() {
        return {
            x: 0,
            y: this.layout.h / 2 - 15,
        }
    }

    get iconTimer() {
        return {
            x: -this.layout.w / 2 + 8,
            y: -5,
            width: 9,
            height: 14,
            fill: this.timerColor,
            sceneFunc(context : CanvasRenderingContext2D, shape : Shape) {
                context.beginPath();
                context.moveTo(9,0);
                context.lineTo(0,0);
                context.lineTo(0,3.5);
                context.lineTo(3,7);
                context.lineTo(0,10.5);
                context.lineTo(0,14);
                context.lineTo(9,14);
                context.lineTo(9,10.5);
                context.lineTo(6,7);
                context.lineTo(9,3.5);
                context.lineTo(9,0);
                context.closePath();
                (context as any).fillStrokeShape(this);
            }
        }
    }

   get timerText() {
        const time = this.layout.items[0].session.sessionTime;

        return time === -1 ? null : `${time}'`
    }

    get configTimer() {
        const w = this.layout.w;
        return {
            x: -this.layout.w / 2 + 5 * (this.timerText ?? '').length,
            y: -4,
            width: w / 2,
            height: 10,
            align: 'right',
            verticalAlign: 'center',
            text: this.timerText,
            fontFamily: this.$config.fontFamily,
            fontSize: Math.min(w / 4, 16),
            fontStyle: 'bold',
            perfectDrawEnabled: false,
            listening: false,
            fill: this.timerColor,
        }
    }

    get timerColor() {
        const status = this.layout.items[0].session.sessionTimeStatus;
        return status === 'nearDue' ? 'orange' : status === 'due' ? 'red' : 'black'
    }

    mounted() {
        Vue.nextTick(() => {
            (<any>this.$refs.group)?.getNode?.()?.cache?.();
        })
    }

    dirty = false;

    @Watch('configLabel')
    @Watch('groupConfig')
    @Watch('configContainer')
    @Watch('configCorner')
    @Watch('configTimer')
    onUpdated() {
        if(!this.dirty) {
            this.dirty = true;
            Vue.nextTick(() => {
                this.dirty = false;
                (<any>this.$refs.group)?.getNode?.()?.cache?.();
            })
        }

    }

    onSessionEvent(e) {
        e.cancelBubble = true;
    }

    onSessionClick(e) {
        e.cancelBubble = true;
        this.$emit('hideMenu');
    }
}

