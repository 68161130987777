
import { Vue, Component, Prop, VModel, FindType, Watch } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { PaymentMethodBase } from "pos-printer/payments/methodBase";

@Component({
  components: {
    EditorTextField,
  },
})
export default class PaymentMethodSecrets extends Vue {
  @Prop()
  label: string;

  @VModel({ default: () => ({}) })
  inputValue: any;

  @Prop()
  item: FindType<"paymentMethods">;

  get privateKey() {
    return this.inputValue?.privateKey;
  }
  set privateKey(v) {
    this.inputValue = {
      ...this.inputValue,
      privateKey: v,
    };
  }

  manager: PaymentMethodBase = null;

  @Watch("item.type", { immediate: true })
  async onMethod() {
    this.manager = null;
    if (this.item?.type) {
      const type = this.item.type;
      const manager = await this.$paymentManager.getAllMethod(type);
      if (manager && type === this.item?.type) {
        this.manager = manager;
      }
    }
  }

  get secretFields() {
    return this.manager?.capabilities?.secretsFields || {};
  }

  get hasSecretFields() {
    return Object.keys(this.secretFields).length > 0;
  }

  getField(key: string) {
    return this.inputValue?.[key];
  }

  setField(key: string, value: any) {
    this.inputValue = {
      ...this.inputValue,
      [key]: value,
    };
  }
}
