
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import { FindPopRawType, FindType, getID } from "@feathers-client";
import { SessionSurchargeType, SessionTaxType } from "@common/table/sessionBase";
import { checkID } from "@feathers-client";

@Component
export default class OrderSystemTax extends Vue {
  tab: string = "serviceItem";
  serviceTab = null;
  taxTab = null;
  totalDineInTax = 0;
  totalTakeAwayTax = 0;

  @Prop()
  session: TableSession;

  get items() {
    return [
      { _id: "serviceItem", name: this.$t("tax.serviceItem") },
      { _id: "taxItem", name: this.$t("tax.taxItem") },
    ];
  }

  async setServiceEnable(serviceContent: SessionSurchargeType, enabled: boolean) {
    const staff = await this.$shop.checkPermission([`paymentManage/tableSessionServiceFee`]);
    if (staff === false) return;
    const item = this.session.coupons.find(it => it.discountSource === "surcharge" && checkID(it._id, serviceContent));
    if (item) {
      item.disabled = !enabled;
    }
    this.session.updateCoupons();
    this.session.atomic({
      ...this.session.cachedPriceDetails,
    });

    await this.$feathers.service("actionLogs").create({
      session: this.session.item._id,
      view: getID(this.session.item.view),
      staff: staff?._id || this.$shop.staffId,
      type: `paymentManage/tableSessionServiceFee`,
      detail: { serviceContent, enabled },
    });
  }

  async setTaxEnable(serviceContent: SessionTaxType, enabled: boolean) {
    const staff = await this.$shop.checkPermission([`paymentManage/tableSessionTax`]);
    if (staff === false) return;
    const coupon = this.session.coupons.find(it => it.discountSource === "tax" && checkID(it._id, serviceContent));
    if (coupon) {
      coupon.disabled = !enabled;
    }
    this.session.updateCoupons();
    this.session.atomic({
      ...this.session.cachedPriceDetails,
    });

    await this.$feathers.service("actionLogs").create({
      session: this.session.item._id,
      view: getID(this.session.item.view),
      staff: staff?._id || this.$shop.staffId,
      type: `paymentManage/tableSessionTax`,
      detail: { serviceContent, enabled },
    });
  }
}
