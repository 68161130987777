import { BinaryPacket, STR, BUF, BYTE } from "../utils/binaryPack";

export const transactionStatus = {
  "00": "TRANS. ACCEPTED",
  "01": "PLEASE CALL ISSUER",
  "02": "REFERRAL NNNN",
  "51": "DECLINED",
  "41": "PLEASE CALL - LC",
  "43": "PLEASE CALL - CC",
  "BB": "TERMINAL BUSY",
  "OT": "INPUT TIMEOUT S9000",
  "CN": "TRANS. CANCELLED",
  "LC": "PLS TRY AGAIN-LC",
  "NC": "PLS TRY AGAIN-NC",
  "TO": "PLS TRY AGAIN-TO",
  "PF": "INVALID PASSWORD",
  "NT": "NO TRANS. RECORD",
  "78": "TRACE NOT FOUND",
  "NA": "AUTH NOT ALLOWED",
  "NJ": "NO ADJUSTMENT",
  "NM": "NO MANUAL ENTRY",
  "NO": "NO OFFLINE ENTRY",
  "NR": "NO REFUND",
  "NV": "VOID NOT ALLOWED",
  "CB": "PLS SETTLE BATCH",
  "VD": "TRANS. VOIDED",
  "AN": "HOST NOT FOUND",
  "77": "RECONCILE ERROR",
  "OR": "LOYALTY TRANSACTION WITH OVER CEILING LIMIT",
  "NP": "PLEASE INSERT PAPER",
  "IP": "CALL HELP – IP",
  "IR": "CALL HELP - IR",
  "IS": "CALL HELP – IS",
};


export enum TransactionType {
  EDC_SALE = "0",
  EDC_OFFLINE = "1",
  EDC_REFUND = "2",
  EDC_VOID_REQUEST = "3",
  EDC_TRANSACTION_RETRIEVAL = "4",
  EDC_AUTH = "8",
  INSTALMENT_SALE = "L",
  HASE_LOYALTY_PAYWAVE_REDEMPTION = "=",
  HASE_LOYALTY_MULTI_UP = "D",
  HASE_LOYALTY_PURE_REDEMPTION = "E",
  HASE_LOYALTY_INSTALLMENT_REDEMPTION = "F",
  EPS_SALE_REQUEST = "5",
  EPS_TRANSACTION_RETRIEVAL = "6",
  EPS_CUP_SALE_REQUEST = "J",
  EPS_CUP_VOID_REQUEST = "O",
  RETRIEVE_CARD_TOTALS = "7",
  EDC_ADJUSTMENT_REQUEST = ";",
  EDC_EPS_GET_LAST_TRANSACTION_TRACE = ">",
  REQUEST_EDC_HOST_TOTAL = "?",
  EDC_SETTLEMENT_REQUEST = "@",
  REQUEST_ACQUIRER_BATCH_NO = "I",
  CUP_ONLINE_SALE = "a",
  CUP_OFFLINE_SALE = "b",
  CUP_PRE_AUTH = "c",
  CUP_VOID_TRANSACTION = "d",
  CUP_RETRIEVAL = "h",
  CUP_PRE_AUTH_COMPLETE = "m",
  CUP_REFUND = "l",
  CUP_UPLAN_SALE = "*",
  CUP_GET_LAST_TRANSACTION_TRACE = "j",
  REQUEST_CUP_BATCH_NUMBER = "g",
  CUP_HOST_TOTAL = "k",
  CUP_SETTLEMENT_REQUEST = "f",
  HASE_LOYALTY_ENQUIRY = "M",
  HASE_OLD_LOYALTY_HOST_TOTAL = "N",
  NEW_HASE_LOYALTY_HOST_TOTAL = "Q",
  QR_SALE = "A",
  QR_VOID = "B",
  QR_REFUND = "C",
  QR_REFUND_ENQUIRY = "T",
  QR_ORDER_ENQUIRY = "Y",
  FPS_SALE = "}",
  OCTOPUS_SALE = "^",
  WORLD_CARD_REDEMPTION = "R",
  WORLD_CARD_BALANCE_ENQUIRY = "S",
  DCC_OPT_OUT = "Z",

  PROCESSING = "P",
  TERMINAL_BUSY = "X",
}

export enum HasePayType {
  EDC = "EDC",
  // EDC_OFFLINE = "EDC_OFFLINE",
  // EPS = "EPS",
  // EPS_CUP = "EPS_CUP",
  CUP = "CUP",
  // CUP_OFFLINE = "CUP_OFFLINE",
  QR = "QR",
  // FPS = "FPS",
  OCTOPUS = "OCTOPUS",
}

export class EDCLastTransactionTrace extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.EDC_EPS_GET_LAST_TRANSACTION_TRACE;
  @STR(() => 1)
  transactionType: "C" | "E";
}

export class EDCLastTransactionTraceResponse extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.EDC_EPS_GET_LAST_TRANSACTION_TRACE;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 20)
  responseText: string;
}

export class BaseRetrieval extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 6)
  traceNumber: string;
}
export class QREnquiry extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.QR_ORDER_ENQUIRY;
  @STR(() => 6)
  password: string;
}
export class BaseSaleRequest extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
}
export class EDCSaleOfflineRequest extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
  @STR(() => 6)
  approvalCode: string;
}
export class BaseVoidRequest extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  voidPassword?: string;
}
export class FPSSaleRequest extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.FPS_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
}
export class OctopusSaleRequest extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.OCTOPUS_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
}

export class QRResponse extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.QR_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 40)
  responseText: string;
  @STR(() => 12)
  transactionDateTime: string;
  @STR(() => 8)
  terminalNumber: string;
  @STR(() => 40)
  merchantNumber: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  batchNumber: string;
  @STR(() => 32)
  tradeNumber: string;
  @STR(() => 40)
  orderNumber: string;
  @STR(() => 1)
  transactionType: string;
  @STR(() => 48)
  openID: string;
  @STR(() => 12)
  uPlanDiscount: string;
  @STR(() => 18)
  couponCode: string;
  @STR(() => 40)
  platformOrderID: string;
  @STR(() => 40)
  transactionID: string;
  @STR(() => 130)
  reserved: string;
}

export class EDCSaleResponse extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.EDC_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 20)
  responseText: string;
  @STR(() => 12)
  transactionDateTime: string;
  @STR(() => 10)
  cardType: string;
  @STR(() => 19)
  cardNumber: string;
  @STR(() => 4)
  expirationDate: string;
  @STR(() => 23)
  cardHolderName: string;
  @STR(() => 8)
  terminalNumber: string;
  @STR(() => 15)
  merchantNumber: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  batchNumber: string;
  @STR(() => 6)
  approvalCode: string;
  @STR(() => 12)
  retrievalReferenceNo: string;
  @STR(() => 1)
  entryMode: string;
  @STR(() => 32)
  emvApplicationId: string;
  @STR(() => 16)
  emvTransactionCryptogram: string;
  @STR(() => 16)
  emvApplicationName: string;
  @STR(() => 1)
  emvnoSignatureRequiredIndicator: string;
}
export class CupSaleResponse extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 20)
  responseText: string;
  @STR(() => 12)
  transactionDateTime: string;
  @STR(() => 10)
  cardType: string;
  @STR(() => 19)
  cardNumber: string;
  @STR(() => 4)
  expirationDate: string;
  @STR(() => 8)
  terminalNumber: string;
  @STR(() => 15)
  merchantNumber: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  batchNumber: string;
  @STR(() => 6)
  approvalCode: string;
  @STR(() => 12)
  retrievalReferenceNo: string;
  @STR(() => 2)
  operatorId: string;
  @STR(() => 11)
  issuerReferenceNo: string;
  @STR(() => 11)
  acquirerReferenceNo: string;
  @STR(() => 8)
  posCenterReferenceNo: string;
  @STR(() => 6)
  upiTraceNumber: string;
  @STR(() => 24)
  hostResponseMessage: string;
  @STR(() => 12)
  uPlanDiscount: string;
  @STR(() => 18)
  couponCode: string;
  @STR(() => 12)
  originalRrnOrApprovalCode: string;
  @STR(() => 1)
  entryMode?: string;
  @STR(() => 32)
  emvApplicationId?: string;
  @STR(() => 16)
  emvTransactionCryptogram?: string;
  @STR(() => 16)
  emvApplicationName?: string;
  @STR(() => 1)
  ecMode: string;
  @STR(() => 3)
  transactionCurrency: string;
  @STR(() => 8)
  exchangeRate: string;
  @STR(() => 12)
  amountInCents: string;
  @STR(() => 12)
  balance: string;
  @STR(() => 14)
  hotCardInformation: string;
  @STR(() => 4)
  emvApplicationInterchangeProfile: string;
  @STR(() => 8)
  emvCardVerificationResult: string;
  @STR(() => 8)
  emvUnpredictableNumber: string;
  @STR(() => 2)
  emvTransactionType: string;
  @STR(() => 3)
  cardPanSequenceNumber: string;
  @STR(() => 1)
  cupQpsIndicator: string;
}

export class OctopusResponse extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.OCTOPUS_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  remainingValue: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 20)
  responseText: string;
  @STR(() => 12)
  transactionDateTime: string;
  @STR(() => 19)
  cardNumber: string;
  @STR(() => 20)
  octopusRefNo: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  batchNumber: string;
  @STR(() => 15)
  merchantNumber: string;
  @STR(() => 15)
  terminalNumber: string; // renamed from deviceId
  @STR(() => 1)
  entryMode: string;
}

export class FPSResponse extends BinaryPacket {
  @STR(() => 1)
  type: string = TransactionType.FPS_SALE;
  @STR(() => 16)
  ecrRefNo: string;
  @STR(() => 12)
  amount: string;
  @STR(() => 12)
  tips: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 40)
  responseText: string;
  @STR(() => 12)
  transactionDateTime: string;
  @STR(() => 8)
  terminalNumber: string;
  @STR(() => 40)
  merchantNumber: string;
  @STR(() => 6)
  traceNumber: string;
  @STR(() => 6)
  reserved: string;
  @STR(() => 40)
  merchantOrderId: string;
  @STR(() => 40)
  platformOrderId: string;
  @STR(() => 40)
  thirdPartyOrderId: string;
  @STR(() => 128)
  reserved2: string;
}

export class SettlementRequest extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 10)
  hostName: string;
  @STR(() => 6)
  password: string;
}

export class SettlementResponse extends BinaryPacket {
  @STR(() => 1)
  type: string;
  @STR(() => 2)
  responseCode: string;
  @STR(() => 20)
  responseText: string;
  @STR(() => 1)
  batchUpload: string;
}

export function decodePacket(buf: Buffer) {
  const content = buf.slice(2, buf.length - 2);
  switch (String.fromCharCode(buf[2])) {
    case TransactionType.EDC_SALE:
    case TransactionType.EDC_OFFLINE:
    case TransactionType.EDC_REFUND:
    case TransactionType.EDC_VOID_REQUEST:
    case TransactionType.EDC_AUTH:
    case TransactionType.INSTALMENT_SALE:
    case TransactionType.HASE_LOYALTY_PAYWAVE_REDEMPTION:
    case TransactionType.HASE_LOYALTY_MULTI_UP:
    case TransactionType.HASE_LOYALTY_PURE_REDEMPTION:
    case TransactionType.HASE_LOYALTY_INSTALLMENT_REDEMPTION:
    case TransactionType.EDC_TRANSACTION_RETRIEVAL:
      return EDCSaleResponse.fromBuffer(content);
    case TransactionType.CUP_ONLINE_SALE:
    case TransactionType.CUP_OFFLINE_SALE:
    case TransactionType.CUP_PRE_AUTH:
    case TransactionType.CUP_VOID_TRANSACTION:
    case TransactionType.CUP_RETRIEVAL:
    case TransactionType.CUP_PRE_AUTH_COMPLETE:
    case TransactionType.CUP_REFUND:
    // case TransactionType.CUP_UPLAN_SALE: // TODO
      return CupSaleResponse.fromBuffer(content);

    // case TransactionType.HASE_LOYALTY_ENQUIRY: // TODO
    // case TransactionType.HASE_OLD_LOYALTY_HOST_TOTAL: // TODO
    // case TransactionType.NEW_HASE_LOYALTY_HOST_TOTAL: // TODO

    // case TransactionType.QR_REFUND: // TODO
    // case TransactionType.QR_REFUND_ENQUIRY: // TODO
    // case TransactionType.QR_ORDER_ENQUIRY: // TODO

    // case TransactionType.WORLD_CARD_REDEMPTION: // TODO
    // case TransactionType.WORLD_CARD_BALANCE_ENQUIRY: // TODO
    // case TransactionType.DCC_OPT_OUT: // TODO

    case TransactionType.EDC_EPS_GET_LAST_TRANSACTION_TRACE:
      return EDCLastTransactionTraceResponse.fromBuffer(content);

    case TransactionType.QR_SALE:
    case TransactionType.QR_VOID:
      return QRResponse.fromBuffer(content);
    case TransactionType.OCTOPUS_SALE:
      return OctopusResponse.fromBuffer(content);
    case TransactionType.FPS_SALE:
      return OctopusResponse.fromBuffer(content);

    case TransactionType.EDC_SETTLEMENT_REQUEST:
      return SettlementResponse.fromBuffer(content);

    default:
      throw new Error("Payment Error: Unexpected response type: " + String.fromCharCode(buf[2]));
  }
}
