
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import {
  Currencies,
  CurrencyDef,
  CurrencyType,
  MultiCurrencyType,
  fromHumanNumberToRaw,
  getHumanNumber,
} from "@feathers-client/currency";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorTextField,
  },
})
export default class CurrencyEditor extends Vue {
  @Prop()
  label: string;

  @Prop()
  value: number | null;

  @Prop()
  multiple: Boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  editor: boolean;

  @Prop(Boolean)
  multiLine: boolean;

  @Prop({ type: Boolean })
  outlined: boolean;

  @Prop({ type: Boolean })
  dense: Boolean;

  @Prop({ type: Boolean })
  hideDetails: Boolean;

  @Prop(Boolean)
  revert: boolean;

  get inputValue() {
    const v = this.value;
    if (typeof v === "number" || !isNaN(+v)) {
      if (this.revert) {
        return fromHumanNumberToRaw(+v, this.$shop.currency);
      }
      return getHumanNumber(+v, this.$shop.currency);
    }
    return "";
  }

  set inputValue(v) {
    if (typeof v === "number" || !isNaN(+v)) {
      if (this.revert) {
        this.$emit("input", getHumanNumber(+v, this.$shop.currency));
      } else {
        this.$emit("input", fromHumanNumberToRaw(+v, this.$shop.currency));
      }
    } else if (this.inputValue !== null) {
      this.$emit("input", null);
    }
  }

  getIcon(icon) {
    switch (icon) {
      case "USD":
        return "- flag-icon flag-icon-us";
      case "HKD":
        return "- flag-icon flag-icon-hk";
      case "CNY":
        return "- flag-icon flag-icon-cn";
      case "SGD":
        return "- flag-icon flag-icon-sg";
      case "JPY":
        return "- flag-icon flag-icon-jp";
      case "MYR":
        return "- flag-icon flag-icon-my";
      case "VND":
        return "- flag-icon flag-icon-vn";
      case "THB":
        return "- flag-icon flag-icon-th";
      case "AUD":
        return "- flag-icon flag-icon-au";
      case "TWD":
        return "- flag-icon flag-icon-tw";
    }
  }
}
