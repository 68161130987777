
import { Component, Prop, Vue, Watch, mixins, Ref, FindType, checkID } from "@feathers-client";
import moment from "moment";
import type { TableSession } from "~/plugins/table/session";
import _ from "lodash";

@Component
export default class ReservationPickTable extends Vue {
  @Prop()
  item: TableSession;

  @Prop(Boolean)
  hideCancel: boolean;

  @Prop(Boolean)
  useCurrentOccupance: boolean;

  @Prop(Boolean)
  useReservations: boolean;

  @Prop(Boolean)
  loadReservations: boolean;

  @Prop(Boolean)
  checkSelection: boolean;

  get sessionValid() {
    return !this.checkSelection || this.item?.tables?.length;
  }

  get targetRefs() {
    return this.item.item.tables.map(it => `${it.item}`);
  }

  set targetRefs(tableIds: string[]) {
    let remainSize = this.item.desiredCapacity;
    let remainCap = _.sumBy(
      tableIds.map(it => this.$tableManager.viewItemDict[it]).filter(it => !!it),
      it => (this.useCurrentOccupance ? it.remainCapacity : it.capacity),
    );

    const tables = tableIds.map(itemId => {
      const it = this.$tableManager.viewItemDict[itemId];
      if (!it)
        return {
          item: itemId,
          capacity: 0,
          split: -1,
        };
      const remainCapacity = this.useCurrentOccupance ? it.remainCapacity : it.capacity;
      const capacity = Math.min(Math.ceil((remainSize / (remainCap || 1)) * remainCapacity), remainSize);
      remainCap -= remainCapacity;
      remainSize -= capacity;
      return {
        item: it.id as any,
        capacity,
        split: this.useCurrentOccupance ? it.nextSessionSplit : -1,
      };
    });

    if (remainSize && tables.length) {
      tables[0].capacity += remainSize;
    }

    this.item.item.tables = tables;
  }

  reservations: FindType<"tableSessions">[] = [];

  get finalReservations() {
    return (this.useReservations ? this.$tableManager.bookings.map(it => it.item) : this.reservations).filter(
      it => !checkID(it, this.item.id),
    );
  }

  get reservationMap() {
    return Object.fromEntries(this.finalReservations.flatMap(r => r.tables?.map?.(it => [it.item, r]) ?? []));
  }

  async beforeMount() {
    if (this.loadReservations) {
      const checkBefore = moment(this.item.bookedTime).subtract(1, "hour").toDate();
      const checkAfter = moment(this.item.bookedTime).add(0.5, "hour").toDate();

      this.reservations = await this.$feathers.service("tableSessions").find({
        query: {
          status: "booking",
          bookedTime: {
            $gt: checkBefore,
            $lt: checkAfter,
          },
          $paginate: false,
          ...(this.item.item._id
            ? {
                _id: { $ne: this.item.item._id },
              }
            : {}),
        },
        paginate: false,
      });
    }
  }

  formatTime(time) {
    return moment(time).format("HH:mm");
  }

  get nextFit() {
    return this.$tableManager.views
      .flatMap(v => v.sortedViewItems)
      .find(
        it =>
          (this.useCurrentOccupance ? it.remainCapacity : it.capacity) >= this.item.desiredCapacity &&
          !this.reservationMap[it.id],
      );
  }

  autoFit() {
    if (!this.nextFit) return;
    this.targetRefs = [this.nextFit.id];
  }
}
