

import { Component, Prop, Vue, Watch, mixins,FindType, VModel } from "@feathers-client";
import EditorObjectPickerNew from '@schemaEditor/EditorObjectPickerNew.vue'


@Component({
    components: {
      EditorObjectPickerNew,
    }
})
export default class ProductOptionValue extends Vue {
    @VModel()
    inputValue: string | string[];

    @Prop()
    option: string

    @Prop()
    label: string

    @Prop(Boolean)
    multiple: boolean
}


