import { PrinterBase, PrinterConf } from "./base";
import { supported, getDevices, connect, UsbDevice, UsbDeviceInfo, getConfig, init, UsbConfig } from "../ports/usb";
import { PrinterServer } from "../index";
import { PrintJob } from "../printQueue";
import _ from "lodash";
import { lookupConfig } from "./cloud";

export interface UsbConf extends PrinterConf {
  devices: { vendorId: number; productId?: number; opts?: PrinterConf }[];
  pid: number;
  vid: number;
  port: "usb";
}

class CancellablePromise<T> {
  constructor(
    cb: (resolve: (v?: T) => void, reject?: (e?: any) => void) => any,
    public clean: (resolve: (v?: T) => void, reject?: (e?: any) => void, status?: any) => void,
  ) {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = v => {
        this.resolve = null;
        this.cancel();
        resolve(v);
      };
      this.reject = e => {
        this.reject = null;
        this.cancel();
        reject(e);
      };
      this.status = cb(this.resolve, this.reject);
    });
  }

  promise: Promise<T>;
  resolve: (v?: T) => void;
  reject: (e?: any) => void;
  status: any;

  cancel() {
    this.clean?.(this.resolve, this.reject, this.status);
    this.status = null;
    this.clean = null;
    this.resolve = null;
    this.reject = null;
  }

  static race<T>(promises: (CancellablePromise<T> | Promise<T>)[]): Promise<T>;
  static race<TA, TB>(
    promises: [CancellablePromise<TA> | Promise<TA>, CancellablePromise<TB> | Promise<TB>],
  ): Promise<TA | TB>;

  static async race<T>(promises: (CancellablePromise<T> | Promise<T>)[]): Promise<T> {
    const result = await Promise.race(promises.map(it => (it instanceof CancellablePromise ? it.promise : it)));
    for (let p of promises) {
      if (p instanceof CancellablePromise) {
        p.cancel();
      }
    }
    return result;
  }
}

export class UsbPrinter extends PrinterBase<UsbDeviceInfo, UsbConf> {
  constructor(server: PrinterServer, conf: UsbConf) {
    super(server, _.cloneDeep(conf));
    this.sendSupported = true;
  }

  conn: UsbDevice;

  onDisconnectedCore() {
    this.conn = null;
  }

  async initCore() {}

  get initDelay() {
    return this.device ? 2 : 10;
  }

  get maxDelay() {
    return this.device ? 15 : 120;
  }

  async backoffWait(delay: number) {
    await new Promise<void>(resolve => {
      let timer;
      const cleanup = () => {
        this.parent?.context?.$root?.$off?.("deviceAttach", handleDevice);
        resolve();
      };
      const handleDevice = device => {
        if (timer) clearTimeout(timer);
        console.log(device);
        cleanup();
      };
      this.parent?.context?.$root?.$on?.("deviceAttach", handleDevice);
      timer = setTimeout(cleanup, delay);
    });
  }

  async tryConnectCore() {
    const devices = await getDevices();
    if (!devices.devices.find(it => it.name === this.device.name)) {
      this.device = await this.requestNewDeviceCore();
    }
    if (this.parent?.context?.$root?.$root) {
      init(this.parent.context.$root?.$root);
    }
    let config: UsbConfig;
    let interfaceId: number;
    try {
      config = (await getConfig(this.device.name))[0];
      if (config && config.interfaces) {
        const iface = config.interfaces.find(
          it => it.endpoints?.find(ep => ep.direction === 128) && it.endpoints?.find(ep => ep.direction === 0),
        );
        if (iface) {
          interfaceId = iface.id;
        }
      }
    } catch (e) {
      console.warn(e);
    }
    const d = await connect(this.device.name, undefined, undefined, {
      interfaceId,
    });
    d.once("close", () => {
      if (this.conn) {
        this.conn = null;
      }
      this.onDisconnected?.("Connection closed");
    });
    try {
      let ifaceIdx = config?.interfaces?.findIndex?.(iface => iface.iclass === 7) ?? -1;
      if (ifaceIdx === -1 && interfaceId !== undefined) {
        ifaceIdx = interfaceId;
      }
      if (ifaceIdx !== -1) {
        const epIdx = config.interfaces[ifaceIdx].endpoints?.findIndex?.(ep => ep.direction === 128) ?? -1;
        await d.startRead(ifaceIdx, epIdx);
        this.sendSupported = true;
        d.on("data", d => {
          // console.log("<<<", Buffer.from(d, "base64").toString("hex"));
          this.pushData(Buffer.from(d, "base64"));
        });
        d.once("error", () => {
          d.close();
        });
      }
    } catch (e) {
      console.warn(e);
    }
    this.conn = d;
  }

  async printCore(job: PrintJob) {
    await this.queuePrinter(async () => {
      if (!job.cashBoxOnly) {
        try {
          const asb = await this.preAsbCheck();

          if (!asb) {
            this.onDisconnected("Printer failed to respond to ASB check");
            throw new Error("Printer failed to respond to ASB check");
          }
        } catch (e) {
          job.lastPreFailed = true;
          throw e;
        }
      }

      const buf = job.getDataWithRetry(this.parent.iconv);

      try {
        await this.conn.send(buf);
      } catch (e) {
        if (this.conn) {
          await this.conn.close();
          this.conn = null;
        }
        throw e;
      }

      if (!job.cashBoxOnly) {
        await this.postAsbCheck();
      }
    });
  }

  async disconnectCore() {
    const d = this.conn;
    if (d) {
      this.conn = null;
      await d.close();
    }
  }

  async requestNewDeviceCore() {
    const devices = await getDevices();
    let device: UsbDeviceInfo = devices.devices.find(
      it => this.conf.address === it.name && it.vendorId === this.conf.vid,
    );

    if (!device) {
      device = devices.devices.find(
        it => it.vendorId === this.conf.vid && (!this.conf.pid || it.productId === this.conf.pid),
      );
    }

    if (!device) {
      device = devices.devices.find(it => this.conf.address === it.name);
    }

    if (!device) {
      const newDevices = devices.devices.filter(it => {
        const device = this.conf.devices.find(jt => {
          return it.vendorId === jt.vendorId && (!jt.productId || it.productId === jt.productId);
        });
        return !!device;
      });
      if (newDevices.length) {
        if (newDevices.length === 1) device = newDevices[0];
        else {
          device = await this.parent.context.$openDialog(
            import("../dialogs/UsbSelector.vue"),
            {
              server: this,
              devices: newDevices,
            },
            {
              maxWidth: "80%",
            },
          );
        }
      }
    }
    if (!device) {
      this.device = null;
      throw new Error("Device not found");
    }
    if (this.conf.pid !== device.productId || this.conf.vid !== device.vendorId) {
      let printerOpts: any;
      try {
        const conf = await lookupConfig(device.vendorId, device.productId);
        if (conf) printerOpts = conf.printerOpts;
      } catch (e) {
        console.warn(e);
      }

      if (!printerOpts) {
        const d = this.conf.devices.find(jt => {
          return device.vendorId === jt.vendorId && (!jt.productId || device.productId === jt.productId);
        });
        if (d && d.opts) this.conf.opts = { ...this.conf.opts, ...d.opts };
      } else {
        this.conf.opts = { ...this.conf.opts, ...printerOpts };
      }
      this.conf.pid = device.productId;
      this.conf.vid = device.vendorId;
    }

    this.conf.address = device.name;
    return device;
  }

  async send(buf: Buffer): Promise<void> {
    // console.log(">>>", buf.toString('hex'));
    try {
      await this.conn.send(buf);
    } catch (e) {
      console.warn(e);
      if (this.conn) {
        await this.conn.close();
        this.conn = null;
      }
      this.onDisconnected(`Failed to send data: ${e.message}`);
    }
  }
}
