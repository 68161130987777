
import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'
import iconFonts from '~/assets/icon-fonts/iconfont.json'
// @ts-ignore
import fnbIconWoff from "!file-loader!~/assets/icon-fonts/iconfont.woff2"

@Component
class IconFont extends Vue {
    icons = Object.fromEntries(Object.keys(iconFonts).map(it => [it, ''])) as typeof iconFonts;


    async created() {
        if(process.server) return;

        const fontFace = new FontFace('iconfont', `url(${fnbIconWoff})`, {
            unicodeRange: 'U+F100-F1FF'
        });
        await fontFace.load();

        console.log('Font loaded');
        for(let [k, v] of Object.entries(iconFonts)) {
            this.icons[k] = v;
        }

        (document.fonts as any).add(fontFace);
    }
}

const instance = new IconFont();
const iconFont = instance.icons as typeof iconFonts;
export default iconFont;

