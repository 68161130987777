import _ from "lodash";
import { TextLine, RawLine } from "./rawLine";
import type { PrintSequence } from "./";

export class PrintTable {
  constructor(
    public seq: PrintSequence,
    public columns: PrintColumn[] = [],
  ) {}

  minSize = 2;
  fillLine = true;
  colPadding = 0;

  fit(size: number) {
    let fixedSize = _.sumBy(this.columns, column => (column.oriSize ? column.oriSize : 0));
    let sumWeight = _.sumBy(this.columns, column => (column.oriSize ? 0 : column.weight));
    const additionalSize = sumWeight * this.minSize;

    if (additionalSize + fixedSize > size) {
      // column size too large
      if (this.columns.length * this.minSize > size) {
        // too many columns
      } else {
        let needReduce = additionalSize + fixedSize - size;
        let sumCanReduce = this.columns.reduce(
          (a, b) => a + (b.oriSize ? Math.max(0, b.oriSize - this.minSize) : 0),
          0,
        );

        if (sumCanReduce >= needReduce) {
          for (let column of this.columns) {
            if (column.oriSize) {
              const canReduce = Math.max(0, column.oriSize - this.minSize);
              if (canReduce && sumCanReduce) {
                const toReduce = Math.floor((canReduce * needReduce) / sumCanReduce);
                sumCanReduce -= canReduce;
                needReduce -= toReduce;
                column.oriSize -= toReduce;
                column.size -= toReduce;
                fixedSize -= toReduce;
              }
            }
          }
        } else {
          // too many columns
        }
      }
    }

    let remainSize = size - fixedSize;

    for (let column of this.columns) {
      if (!column.oriSize) {
        const fitSize = ((column.weight / sumWeight) * remainSize) | 0;
        remainSize -= fitSize;
        sumWeight -= column.weight;
        column.size = fitSize;
      }
    }

    for (let column of this.columns) {
      column.format();
    }

    const maxLine = Math.max(...this.columns.map(column => column.lines.length));

    for (let column of this.columns) {
      column.formatLines(maxLine);
    }

    return maxLine;
  }

  print() {
    this.seq.printTable(this);
    return this.seq;
  }

  column(content: string | (string | TextLine | RawLine)[], opts: PrintColumnOpts = {}) {
    this.columns.push(new PrintColumn(this, content, opts));
    return this;
  }

  ncolumn(
    content: string | (string | TextLine | RawLine)[],
    size?: number,
    align?: string,
    valign?: string,
    padding?: number,
  ) {
    this.columns.push(
      new PrintColumn(this, content, {
        size,
        align,
        valign,
        padding,
      }),
    );
    return this;
  }
}

export interface PrintColumnOpts {
  size?: number;
  align?: string;
  valign?: string;
  weight?: number;
  padding?: number;

  bold?: boolean;
  color?: number;
  italic?: boolean;
  fontFamily?: string;
  fontSize?: number;
  inverted?: boolean;
  yScale?: number;
}

export class PrintColumn {
  content: (TextLine | RawLine)[];
  oriSize: number;
  size: number;
  weight: number;
  align: string;
  valign: string;
  padding: number;
  lines: Buffer[];
  linesPadLeft: number[];
  linesPadRighht: number[];
  debugLoc?: any;
  bold?: boolean;
  color?: number;
  italic?: boolean;
  fontFamily?: string;
  fontSize?: number;
  inverted?: boolean;
  yScale?: number;

  constructor(
    public table: PrintTable,
    content: string | (string | TextLine | RawLine)[],
    opts: PrintColumnOpts = {},
  ) {
    const list: (string | TextLine | RawLine)[] = Array.isArray(content) ? content : [content];
    this.content = list.flatMap(it => {
      return (
        typeof it === "string" ? it.split("\n").map(line => new TextLine(table, line.replace(/\t/g, "    "))) : [it]
      ) as (RawLine | TextLine)[];
    });
    this.oriSize = opts.size || 0;
    this.size = this.oriSize;
    this.weight = opts.weight || 1;
    this.align = opts.align || "left";
    this.valign = opts.valign || "top";
    this.padding = opts.padding === undefined ? 1 : opts.padding;
    this.debugLoc = table?.seq?.debugLoc;
    this.bold = opts.bold;
    this.color = opts.color;
    this.italic = opts.italic;
    this.fontFamily = opts.fontFamily;
    this.fontSize = opts.fontSize;
    this.inverted = opts.inverted;
    this.yScale = opts.yScale;
  }

  get printSize() {
    return this.size - this.padding;
  }

  format() {
    const rawLines = this.content.flatMap(line => {
      if (line instanceof RawLine) {
        return [line];
      } else if (line instanceof TextLine) {
        const align = line.align || this.align || "left";
        return line.split(this.printSize, undefined, align);
      }
    });

    const lines: Buffer[] = [];
    const linesPadLeft: number[] = [];
    const linesPadRight: number[] = [];

    for (let line of rawLines) {
      const align = line.align || this.align || "left";
      const chars = line.chars;
      let padLeft = 0;
      switch (align) {
        case "left":
        default:
          break;
        case "right":
          padLeft = this.printSize - chars;
          break;
        case "center":
          padLeft = ((this.printSize - chars) / 2) | 0;
          break;
      }
      const padRight = chars > this.size ? this.size - this.printSize - padLeft : this.size - chars - padLeft;
      linesPadLeft.push(padLeft);
      linesPadRight.push(padRight);
      if (this.table.fillLine) {
        const left = Buffer.from(new Uint8Array(padLeft).fill(0x20));
        const right = Buffer.from(new Uint8Array(padRight).fill(0x20));
        lines.push(Buffer.concat([left, line.buf, right]));
      } else {
        lines.push(line.buf);
      }
    }

    this.lines = lines;
    this.linesPadLeft = linesPadLeft;
    this.linesPadRighht = linesPadRight;
  }

  formatLines(numLines: number) {
    let padTop = 0;
    switch (this.valign || "top") {
      case "top":
      default:
        break;
      case "bottom":
        padTop = numLines - this.lines.length;
        break;
      case "center":
        padTop = ((numLines - this.lines.length) / 2) | 0;
        break;
    }
    const padBottom = numLines - padTop - this.lines.length;
    const empty = this.table.fillLine ? Buffer.from(new Uint8Array(this.size).fill(0x20)) : Buffer.alloc(0);

    this.lines = [...new Array(padTop).fill(empty), ...this.lines, ...new Array(padBottom).fill(empty)];
  }
}
