
import { Component, Prop, Vue, PropSync } from "@feathers-client";
import TimeListPicker from "./TimeListPicker.vue";

@Component({
  components: {
    TimeListPicker,
  },
})
export default class CustomTimeRangePicker extends Vue {
  @PropSync('startTimeUpdated') startTimeUpdatedSync: any;
  @PropSync('endTimeUpdated') endTimeUpdatedSync: any;


  updateStartTime(v) {
    this.startTimeUpdatedSync = v;
  }
  updateEndTime(v) {
    this.endTimeUpdatedSync = v;
  }
}
