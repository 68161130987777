import { UsbConf } from "./printers/usb";
import { BluetoothConf } from "./printers/bluetooth";

export default <(UsbConf | BluetoothConf)[]>[
  {
    service: "000018f0-0000-1000-8000-00805f9b34fb",
    oservice: "000018f0-0000-1000-8000-00805f9b34fb",
    name: "Bluetooth Label Printer",
    type: "label",
    port: "bluetooth",
    extraFilters: [
      {
        service: "e7810a71-73ae-499d-8c15-faa9aef0c3f2",
        char: "bef8d6c9-9c21-4c9e-b632-bd58c1009f9f",
        notifyChar: "bef8d6c9-9c21-4c9e-b632-bd58c1009f9f",
        reliableWrite: true,
      },
    ],
  },
  {
    devices: [
      {
        vendorId: 0x0471, // 1137
        productId: 0x0055, // 85
      },
      {
        vendorId: 0xa5f, // 2655 Zebra
        productId: 0x181, // 385 ZD411
        escpos: "zpl",
      },
    ],
    name: "USB Label Printer",
    type: "label",
    port: "usb",
  },
  {
    service: "000018f0-0000-1000-8000-00805f9b34fb",
    name: "Bluetooth Thermal Printer",
    port: "bluetooth",
    type: "thermal",
    opts: {
      lineWidth: 48,
      clineWidth: 60,
    },
  },
  {
    name: "USB Thermal Printer",
    type: "thermal",
    port: "usb",
    devices: [
      {
        vendorId: 0x493, // 1171
        productId: 0x8760, // 34656
        opts: {
          lineWidth: 32,
          clineWidth: 40,
        },
      },
      {
        vendorId: 0x416, // 1046
        productId: 0x5011, // 20497
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },
      {
        vendorId: 0x0483, // 1155
        productId: 0x5743, // 22339
        opts: {
          lineWidth: 48,
          clineWidth: 60,
          waitReady: false, // not compatible
          waitDone: false, // not compatible
        },
      },
      {
        vendorId: 0x6868, // 26728
        productId: 0x0300, // 768
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },
      {
        vendorId: 0x0fe6, // 4070
        productId: 0x811e, // 33054
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },
      {
        vendorId: 0x1fc9, // 8137
        productId: 0x2016, // 8214
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },

      {
        vendorId: 0x483, // 1155
        productId: 0x70b, // 1803
        opts: {
          lineWidth: 33,
          clineWidth: 41,
          lineImageHeight: 20,
        },
      },
      {
        // ZHU HAI SUNCSW Receipt Printer Co.,Ltd. Gprinter GP-L80180
        vendorId: 0x8866, // 34918
        productId: 0x0100, // 256
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },

      {
        // iMin S1
        vendorId: 0x483, // 1155
        productId: 0x7540, // 30016
        opts: {
          lineWidth: 48,
          clineWidth: 60,
          statusCheck: false,
          waitDone: false,
        },
      },

      {
        // iMin D4
        vendorId: 0x519, // 1305
        productId: 0x2013, // 8211
        opts: {
          lineWidth: 48,
          clineWidth: 60,
          statusCheck: false,
          waitDone: false,
        },
      },

      {
        // iMin Swift
        vendorId: 0x28e9, // 10473
        productId: 0x28d, // 653
        opts: {
          lineImage: false,
          lineWidth: 32,
          clineWidth: 40,
          statusCheck: false,
          waitDone: false,
        },
      },

      {
        // Star TSP-100 IV
        vendorId: 0x519, // 1305
        productId: 0x3, // 3
        opts: {
          lineWidth: 38,
          clineWidth: 48,
          statusCheck: false,
          waitReady: false,
          waitDone: false,
          escpos: "star",
        },
      },

      {
        // Star mPop
        vendorId: 0x519, // 1305
        productId: 0x17, // 23
        opts: {
          lineWidth: 29,
          clineWidth: 36,
          statusCheck: false,
          waitReady: false,
          waitDone: false,
          escpos: "star",
        },
      },

      {
        // Samsung Kiosk
        vendorId: 0x1504,
        productId: 0x103,
        opts: {
          lineWidth: 48,
          clineWidth: 60,
        },
      },

      {
        // Samsung Kiosk
        vendorId: -1,
        productId: 0x1,
        opts: {
          lineWidth: 42,
          clineWidth: 52,
        },
      },
    ],
    opts: {
      lineWidth: 48,
      clineWidth: 60,
    },
  },
  // {
  //     name: 'Socket Thermal Printer',
  //     type: 'thermal',
  //     port: 'socket',
  // },
  // {
  //     name: 'Socket Label Printer',
  //     type: 'label',
  //     port: 'socket',
  // },
];
