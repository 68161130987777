
import { Component, Vue, Prop, Watch } from "@feathers-client";

@Component
export default class Percentage extends Vue {
  @Prop({ type: Number, required: true, default: 0 }) latestData!: number;
  @Prop({ type: Number, required: true, default: 0 }) lastData!: number;
  // @Prop({ type: String, required: true, default: 0 }) modeType!: string;

  get percentageChange() {
    if (!this.latestData && !this.lastData) {
      return
    }
    if (this.latestData - this.lastData === 0) {
      return 0;
    }
    const values = ((this.latestData - this.lastData) / this.lastData) * 100;
    return parseFloat(values.toFixed(1));
  }
  type: string = ''
  // get mode() {
  //   if (this.modeType == "today") {
  //     return this.type = '相較上一天'
  //   } else if (this.modeType == "week") {
  //     return this.type = '相較上星期'
  //   } else if (this.modeType == "month") {
  //     return this.type = '相較上月'
  //   } else if (this.modeType == "year") {
  //     return this.type = '相較上年'
  //   }
  //   return
  // }

}
