import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer } from "../index";
import { PrintJob } from "../printQueue";
import { directConnect, install as initBluetooth } from "../utils/bluetoothPolyfill";

export interface BluetoothConf extends PrinterConf {
  service?: string;
  oservice?: string;
  char?: string;
  notifyChar?: string;

  all?: boolean;
  port: "bluetooth";
  identifier?: string;
  mtu?: number;
  reliableWrite?: boolean;
  extraFilters?: {
    service: string;
    char?: string;
    notifyChar?: string;
    reliableWrite?: boolean;
  }[];
}

export class BluetoothPrinter extends PrinterBase<BluetoothDevice, BluetoothConf> {
  constructor(server: PrinterServer, conf: BluetoothConf) {
    super(server, conf);
    this.onGattDisconnected = this.onGattDisconnected.bind(this);
  }

  server: BluetoothRemoteGATTServer;
  service: BluetoothRemoteGATTService;
  ch: BluetoothRemoteGATTCharacteristic;

  onDisconnectedCore() {
    this.server = this.service = this.ch = null;
  }

  async initCore() {
    this.device && this.device.addEventListener("gattserverdisconnected", this.onGattDisconnected);
  }

  onGattDisconnected() {
    this.onDisconnected("GATT disconnected");
  }

  async tryConnectCore() {
    this.server = await this.device.gatt.connect();
    await new Promise(resolve => setTimeout(resolve, 100));
    this.service = await Promise.race([
      this.server.getPrimaryService(this.conf.service || "000018f0-0000-1000-8000-00805f9b34fb"),
      new Promise<BluetoothRemoteGATTService>((resolve, reject) =>
        setTimeout(() => reject(new Error("GATT: getPrimaryService timeout")), 5000),
      ),
    ]);
    if (!this.service) {
      const allServices = await Promise.race([
        this.server.getPrimaryServices(),
        new Promise<BluetoothRemoteGATTService[]>((resolve, reject) =>
          setTimeout(() => reject(new Error("GATT: getPrimaryService timeout")), 5000),
        ),
      ]);

      console.log("allServices", allServices);
    }
    await new Promise(resolve => setTimeout(resolve, 100));
    this.ch = await Promise.race([
      this.service.getCharacteristic(this.conf.char || "00002af1-0000-1000-8000-00805f9b34fb"),
      new Promise<BluetoothRemoteGATTCharacteristic>((resolve, reject) =>
        setTimeout(() => reject(new Error("GATT: getCharacteristic timeout")), 5000),
      ),
    ]);
    console.log(this.ch);
  }

  async printCore(job: PrintJob) {
    const buf = job.getDataWithRetry(this.parent.iconv);
    const chunks = [];

    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;

    let mtu = this.conf.mtu || 120;
    let wait = false;
    if ((this.device as any).requestMtu) {
      mtu = await (this.device as any).requestMtu(512);
      mtu -= 3;
      console.log("New mtu", mtu);
    } else {
      if (isAndroid) {
        mtu = 20;
        wait = true;
      }
    }

    for (let i = 0; i < buf.length; i += mtu) chunks.push(buf.slice(i, i + mtu));
    for (let i = 0; i < chunks.length; i++) {
      if(this.conf.reliableWrite ?? false) {
        await this.ch.writeValueWithResponse(chunks[i]);
      } else {
        await this.ch.writeValueWithoutResponse(chunks[i]);
      }
      if (wait) await new Promise(resolve => setTimeout(resolve, 10));
    }
  }

  async disconnectCore() {
    const d = this.device;
    d.removeEventListener("gattserverdisconnected", this.onGattDisconnected);
    this.server = this.service = this.ch = null;
    try {
      await d.gatt.disconnect();
    } catch (e) {
      console.log(e);
    }
  }

  async requestNewDeviceCore() {
    await initBluetooth();
    if (this.conf.identifier && navigator.bluetooth.getDevices) {
      try {
        const devices = await navigator.bluetooth.getDevices();
        const device = devices.find(it => it.id === this.conf.identifier);
        if (device && device.watchAdvertisements) {
          await device.watchAdvertisements();
          await new Promise(resolve => {
            device.addEventListener("advertisementreceived", resolve);
          });
          await (this.device as any)?.unwatchAdvertisements?.();
          return device;
        }
      } catch (e) {
        console.warn(e);
      }
    }
    const conf = this.conf;
    const r: RequestDeviceOptions = conf.address
      ? {
          filters: [
            {
              name: conf.address,
              ...(conf.all
                ? {}
                : {
                    services: [conf.service || "000018f0-0000-1000-8000-00805f9b34fb"],
                  }),
            },
          ],
          ...(conf.oservice
            ? {
                optionalServices: [conf.oservice],
              }
            : {}),
        }
      : {
          ...(conf.all
            ? {
                acceptAllDevices: true,
              }
            : {
                filters: [
                  {
                    services: [conf.service || "000018f0-0000-1000-8000-00805f9b34fb"],
                  },
                ],
              }),
          ...(conf.oservice
            ? {
                optionalServices: [conf.oservice],
              }
            : {}),
        };

    const device = conf.address
      ? await directConnect(this.parent.context, conf.address, r)
      : await navigator.bluetooth.requestDevice(r);
    conf.address = (device as any).address || device.name;
    conf.identifier = device.id;
    return device;
  }
}
