<template functional>
    <component bottom right offset-overflow :is="injections.components.VTooltip" v-if="props.altText">
        <template v-slot:activator="{on}">
            <component :is="injections.components.VBtn" v-bind="{...data.props, ...data.attrs, icon: data.attrs.icon === undefined ? true : data.attrs.icon}" v-on="{...on, ...data.on}" :class="data.staticClass">
                <slot/>
            </component>
        </template>
        <div v-text="props.altText" style="white-space: pre-wrap;"/>
    </component>
    <component v-else :is="injections.components.VBtn" v-bind="{...data.props, ...data.attrs}" v-on="data.on" icon>
        <slot/>
    </component>
</template>


<script>

import {
    VBtn,
    VTooltip,
} from 'vuetify/lib'

export default {
    inject: {
        components: {
            default: {
                VBtn,
                VTooltip,
            }
        }
    },
};


</script>
