import { FindType, getID } from "@feathers-client";
import { MHookContext } from "@feathersjs/feathers";
import { AdminApplication } from "serviceTypes";
import _ from "lodash";
import { NotFound } from "@feathersjs/errors";
import moment from "moment";
import type { OfflineManager } from "../";

export async function all(hook: MHookContext<AdminApplication, FindType<"payments">>) {
  if (hook.method === "create" && hook.data.status === "done") {
    hook.data.paidTime = new Date();
  }
  if (hook.method === "create") {
    const offline: OfflineManager = (hook as any).$offline;
    if (!hook.data.orderSession && hook.data.session) {
      const session = await hook.app.service("tableSessions").get(hook.data.session);
      hook.data.orderSession = session.shopSession;
      hook.data.orderStartTime = session.startTime;
      hook.data.orderDate = session.salesDate;
      hook.data.orderId = session.orderId;
    }
    if (!hook.data.orderPaymentId && hook.data.orderId && hook.data.session) {
      const lastPayment = (
        await hook.app.service("payments").find({
          query: {
            session: hook.data.session,
            $sort: { orderPaymentId: -1 },
            $limit: 1,
          },
          paginate: false,
        })
      )[0];
      let seq = 1;
      if (lastPayment?.orderPaymentId) {
        seq = parseInt(lastPayment.orderPaymentId.split("-").at(-1)) + 1;
        if (isNaN(seq)) seq = 1;
      }
      hook.data.orderPaymentId = `${hook.data.orderId}-${seq.toString().padStart(2, "0")}`;
    }
    if (!hook.data.paidSession) {
      hook.data.paidSession = offline.root.$shop.shopData.currentSession;
    }
    if (!hook.data.paidSession) {
      hook.data.salesDate = moment().format("YYYY-MM-DD");
    } else {
      hook.data.salesDate =
        (
          await hook.app.service("shopSessions").find({
            query: {
              _id: hook.data.paidSession,
            },
            paginate: false,
          })
        )?.[0]?.salesDate ?? moment().format("YYYY-MM-DD");
    }
  }
  if (hook.method === "patch") {
    const before = await hook.app.service("payments").get(hook.id);
    if (!before) {
      throw new NotFound(`Payment ${hook.id} not found`);
    }
    hook.params.old = before;
    if (before.status !== "done" && hook.data.status === "done") {
      hook.data.paidTime = new Date();
    }
  }
}

export async function allAfter(hook: MHookContext<AdminApplication, FindType<"payments">>) {
  if (hook.method === "create" || hook.method === "patch") {
    const paymentItem: FindType<"payments"> = hook.result;
    if (
      paymentItem.status === "done" &&
      (hook.method === "create" || (hook.method === "patch" && hook.params.old.status === "pending"))
    ) {
      await hook.app.service("tableSessions/pay").create({
        session: getID(paymentItem.session),
        payment: getID(paymentItem),
      });
    }
  }
}
