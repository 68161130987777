
import { Vue, Component, Prop } from "@feathers-client";

@Component
export default class CenterMenu extends Vue {
  @Prop({ type: Number, default: 0 })
  selected;

  get menu() {
    return [
      ...(this.$shop?.shopData?.openSideDineIn
        ? [{ svg: "dineIn", text: "tableView.sidebar.dineIn", navi: "dineIn" }]
        : []),
      ...(this.$shop?.shopData?.openSideTakeAway
        ? [{ svg: "ic24-takeaway", text: "tableView.sidebar.takeaway", navi: "takeaway" }]
        : []),
      ...(this.$shop?.shopData?.openSideManagement
        ? [{ svg: "orderList", text: "tableView.sidebar.manage", navi: "management" }]
        : []),
      ...(this.$shop?.shopData?.openSideQueuing
        ? [{ svg: "queue", text: "tableView.sidebar.queuing", navi: "queuing" }]
        : []),
      ...(this.$shop?.shopData?.openSideOnlineBooking
        ? [{ svg: "reservation", text: "tableView.sidebar.booking", navi: "booking" }]
        : []),
    ];
  }
}
