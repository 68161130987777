
import { Vue, Component, Prop, Model, Watch, PropSync } from "@feathers-client";
import _ from "lodash";

@Component
export default class PosSideBarTab extends Vue {
  @Prop()
  item: any;

  @PropSync("current")
  currentSync: any;

  @Prop()
  ticket: any;

  get quantity() {
    return _.filter(this.ticket, ticket => ticket.queueingGroup === this.item?.queueingGroup).length;
  }
}
