
import { FindPopRawType, FindType } from "@feathers-client";
import _ from "lodash";
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class PointRecord extends Vue {
  @Prop()
  item: FindPopRawType<["session"], "userPointLogs">;

  @Prop({ type: Boolean })
  user: Boolean;

  @Prop({ type: Boolean })
  isDollar: Boolean;
}
