
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
    get options() {
        return [
            { text: "枱是你們餐廳安排座位的首要條件" },
            {
                text: "椅子是你們餐廳安排座位的首要條件",
                disabled: true,
                iconComponent: "table-chair-svg",
                width: 100,
                height: 100,
            },
        ];
    }
}
