import { Component, Vue, Prop, Watch } from "@feathers-client";
import msgpack5 from "msgpack5";
import {
  init,
  OctopusDriver,
  OctopusCommandTransaction,
  OctopusCommandRespTransactionSuccess,
  OctopusCommandRespTransactionError,
  OctopusCommandRespSuccessSysInfo,
} from "./loader";
import { BOXS_CLOUD_URL } from "../ports/cloud";
import SerialDevice, { connect, getDevices, portPicker, SerialPortSelection } from "../ports/serial";
import { CacheFile, CacheStorage, getStorage } from "@feathers-client/storage";
import moment, { Moment } from "moment";
import momentTZ from "moment-timezone";
import uuid from "uuid/v4";
import { getVersion } from "../nativeIntegrations";
import { ns } from "../messageQueue";
import { EspDevice, EspDeviceSerial } from "../esp32";

const msgpack = msgpack5();

export interface OctopusConfig {
  serial?: SerialPortSelection;
  consoleLogging?: boolean;
  locationId?: string;
  autoHousekeeping?: boolean;
  autoXFile?: boolean;
  ignoreNative?: boolean;
  overrideUsb?: boolean;
  overrideUsbValue?: boolean;
  increasedMemory?: boolean;
  webVariant?: "linux" | "windows";
}

export interface OctopusServerNotification {
  messageId: string;
  level: "info" | "warning" | "error";
  identifier: string;
  dateGMT8?: string;
  data?: any;
}

@Component
export class OctopusManager extends Vue {
  created() {
    this._exec();
    this.connect().catch(console.error);
    window.addEventListener("beforeunload", this.unload);
    this.flushOldLog();
    this.flushNotification();
    this.saveDraftLog();
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unload);
    this.isExit = true;
    this.housekeepingWorkerResolve?.();
    this.whenDestroy?.();
    if (this.logDirtyTimer) {
      clearTimeout(this.logDirtyTimer);
      this.logDirtyTimer = null;
    }
  }

  _exec() {
    this.preTask = this.task();
    this.preTask.catch(e => {
      console.error(e);
      this.log(`Failed to start octopus ${e.message}`);
      this.status = "error";
    });
  }

  preTask: Promise<void>;
  housekeepingTask: Promise<void>;

  @Prop()
  getSetting: () => OctopusConfig;

  @Prop()
  setSetting: (v: OctopusConfig) => void;

  @Prop()
  getInfo: () => {
    shopId?: string;
    shopName?: string;
    cashierId?: string;
    locationId?: string;
    cashierName?: string;
    cloudArgs?: any;
  };

  @Prop()
  whenDestroy: () => void;

  @Prop()
  syncEnquiryState: (state: any) => void;

  get shopInfo() {
    return this.getInfo?.();
  }

  get shopId() {
    return this.shopInfo?.shopId;
  }

  get cashierId() {
    return this.shopInfo?.cashierId;
  }

  get locationId() {
    return this.settings?.locationId ?? this.shopInfo?.locationId;
  }

  set locationId(v) {
    this.settings = {
      ...(this.settings || {}),
      locationId: v,
    };
  }

  get savedLocationId() {
    let locId = this.locationId || "0";
    locId = locId.trim().toUpperCase();
    const chars = locId.split("");
    const c1 = chars[0] >= "A" && chars[0] <= "Z" ? chars.shift() : " ";
    const c2 = chars[0] >= "A" && chars[0] <= "Z" ? chars.shift() : " ";
    const d = chars.join("");
    const num = +d | 0;

    if (isNaN(num) || num < 0 || num >= 1000000) {
      return 0;
    }

    const letter1 = c1 === " " ? 0 : c1.charCodeAt(0) - "A".charCodeAt(0) + 1;
    const letter2 = c2 === " " ? 0 : c2.charCodeAt(0) - "A".charCodeAt(0) + 1;

    return (letter1 + letter2 * 27) * 1000000 + num;
  }

  formatLocationId(num: number) {
    const locId = num ?? 0;
    if (isNaN(locId)) return "  000000";
    const digits = locId % 1000000;
    const letters = (locId / 1000000) | 0;
    const letter1 = letters % 27;
    const letter2 = Math.min(27, Math.max(0, (letters / 27) | 0));

    return [
      letter1 === 0 ? " " : String.fromCharCode(letter1 - 1 + "A".charCodeAt(0)),
      letter2 === 0 ? " " : String.fromCharCode(letter2 - 1 + "A".charCodeAt(0)),
      digits.toString().padStart(6, "0"),
    ].join("");
  }

  get versionLocationId() {
    return this.formatLocationId(this.version?.locId ?? 0);
  }

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get serialConfig() {
    return this.settings?.serial;
  }

  set serialConfig(s) {
    this.settings = {
      ...(this.settings || {}),
      serial: s,
    };
  }

  get consoleLogging() {
    return this.settings?.consoleLogging;
  }

  set consoleLogging(v) {
    this.settings = {
      ...(this.settings || {}),
      consoleLogging: v,
    };
  }

  get overrideUsb() {
    return this.settings?.overrideUsb;
  }

  set overrideUsb(v) {
    this.settings = {
      ...(this.settings || {}),
      overrideUsb: v,
    };
  }

  get overrideUsbValue() {
    return this.settings?.overrideUsbValue;
  }

  set overrideUsbValue(v) {
    this.settings = {
      ...(this.settings || {}),
      overrideUsbValue: v,
    };
  }

  get increasedMemory() {
    return this.settings?.increasedMemory;
  }

  set increasedMemory(v) {
    this.settings = {
      ...(this.settings || {}),
      increasedMemory: v,
    };
  }

  get ignoreNative() {
    return this.settings?.ignoreNative;
  }

  set ignoreNative(v) {
    this.settings = {
      ...(this.settings || {}),
      ignoreNative: v,
    };
  }

  get webVariant() {
    return this.settings?.webVariant;
  }

  set webVariant(v) {
    this.settings = {
      ...(this.settings || {}),
      webVariant: v,
    };
  }

  networkLogging = false;
  _lastbytesReceived = 0;
  _lastbytesSent = 0;
  _bytesTimer: any;

  bytesReceived = 0;
  bytesSent = 0;

  @Watch("networkLogging")
  onNetworkLoggingChanged() {
    if (this.networkLogging) {
      this.bytesReceived = 0;
      this.bytesSent = 0;
      this._lastbytesReceived = 0;
      this._lastbytesSent = 0;
      this._bytesTimer = setInterval(() => {
        if (this.bytesReceived !== this._lastbytesReceived || this.bytesSent !== this._lastbytesSent) {
          this._lastbytesReceived = this.bytesReceived;
          this._lastbytesSent = this.bytesSent;
          this.log(`Network traffic: ${this.bytesReceived} bytes received, ${this.bytesSent} bytes sent`);
        }
      }, 5000);
    } else {
      clearInterval(this._bytesTimer);
    }
  }

  get autoHousekeeping() {
    return this.settings?.autoHousekeeping ?? true;
  }

  set autoHousekeeping(v) {
    this.settings = {
      ...(this.settings || {}),
      autoHousekeeping: v,
    };
  }

  get autoXFile() {
    return this.settings?.autoXFile ?? true;
  }

  set autoXFile(v) {
    this.settings = {
      ...(this.settings || {}),
      autoXFile: v,
    };
  }

  octopus: OctopusDriver = null;
  status: "connected" | "disconnected" | "starting" | "connecting" | "manual" | "error" = "starting";
  connected = false;

  serial: SerialDevice = null;
  webSerial: any = null;
  webSerialWriter: any = null;
  espSerial: EspDeviceSerial = null;

  reconnectTimeout: number | NodeJS.Timer | null = null;

  _archiveData: Uint8Array;
  _initFunc: Awaited<ReturnType<typeof init>>;
  terminal: any;

  get version() {
    return this.octopus?.version;
  }
  get versionTime() {
    return this.octopus?.versionTime;
  }
  get lastUploadInfo() {
    return this.octopus?.lastUploadInfo;
  }
  get metadataTime() {
    return this.octopus?.metadataTime;
  }
  get metadataDownloadTime() {
    return this.octopus?.metadataDownloadTime;
  }
  get accountId() {
    return this.octopus?.accountId;
  }

  get hasConnection() {
    if (this.useNative) {
      return !!this.serialConfig;
    }
    return !!(this.serial || this.webSerial || this.espSerial);
  }

  logBuffer: Buffer;
  logOffset: number;
  logDirty: boolean;
  logDirtyTimer: any;
  logStart: number;
  useNative = false;
  usb = false;

  async task() {
    this.status = "starting";
    const useNative = this.settings?.ignoreNative ? false : await nativeSupported();
    this.useNative = useNative;
    if (!this._archiveData) {
      const cache = await this.getCacheStorage();
      const variant = useNative ? "min" : this.settings?.webVariant === "windows" ? "web-windows" : "web";
      const cacheKey = `octopus-${variant}.bin`;
      const cached = await cache.loadFileCache(cacheKey);

      if (cached) {
        try {
          const resp = await fetch(
            new URL(`/api/octopus/lib?type=${variant}&head=1`, BOXS_CLOUD_URL).toString(),
          );
          if (!resp.ok) {
            throw new Error(`Bad status ${resp.status}`);
          } else {
            const info = await resp.json();
            if (info.hash === cached.metadata.hash) {
              this._archiveData = cached.data;
            }
          }
        } catch (e) {
          this.log(`Failed to check octopus lib version ${e.message}, using cache`);
          this._archiveData = cached.data;
        }
      }

      if (!this._archiveData) {
        await cache.cleanFiles();
        this.log("Downloading octopus library");
        const buf = new Uint8Array(
          await (
            await fetch(new URL(`/api/octopus/lib?type=${variant}`, BOXS_CLOUD_URL).toString())
          ).arrayBuffer(),
        );

        const sha256 = Buffer.from(await crypto.subtle.digest("SHA-256", buf)).toString("hex");

        cache
          .saveFile(cacheKey, Buffer.from(buf), {
            hash: sha256,
          })
          .catch(console.error);
        this._archiveData = buf;
      }
    }
    if (!this._initFunc) {
      this._initFunc = await init(this._archiveData);
    }

    let com: number = 0;
    let usb = false;
    if (this.serialConfig?.port) {
      let port = this.serialConfig.port;
      let isCom = false;
      if (port.startsWith("COM")) {
        port = port.slice(3);
        isCom = true;
      }
      if (port.includes("ttyUSB") || port.includes("ttyACM")) {
        usb = true;
      }
      // get last digit
      const m = port.match(/\d+$/);
      com = m ? +m[0] : 0;
      if (isNaN(com)) com = 0;
      else if (!isCom) com++;
    } 
    else if (
      (+this.serialConfig?.vendorId === 0x0525 && +this.serialConfig?.productId === 0xa4a7) ||
      (this.serialConfig?.espConfig?.vid === 0x0525 && this.serialConfig?.espConfig?.pid === 0xa4a7)
    ) {
      usb = true;
    }
    this.usb = usb;
    if (this.overrideUsb) {
      this.usb = this.overrideUsbValue ?? false;
    }

    await this._initFunc({
      archive: this._archiveData,
      com,
      usb,
      native: useNative,
      log: this.log,
      consoleOutput: async buf => {
        if (this.consoleLogging) {
          console.log(buf);
        }
        if (this.terminal) {
          this.terminal.write(buf);
        }
        this.writeLog(buf);
      },
      preRun: async octopus => {
        this.octopus = octopus;
        this.ensureHousekeeping();
      },
      init: async () => {
        this.status = "connecting";
        if (this.serial || this.webSerial || this.espSerial) {
          await this.octopus.sendCommand({ type: "connected" });
        }
        this.$emit("connecting");
      },
      connected: async ver => {
        this.status = "connected";
        if (this.housekeepingWorkerResolve) {
          const r = this.housekeepingWorkerResolve;
          this.housekeepingWorkerResolve = null;
          r();
        }
        this.scheduleHousekeeping(async () => {
          await this.checkSysInfo();
          if (this.savedLocationId !== ver.locId) {
            await this.writeId();
          }
        });
        this.ensureHousekeeping();
        this.$emit("connected");
        if (this.version) {
          localStorage["lastOctopusVersion"] = JSON.stringify(this.version);
        }
      },
      disconnected: async () => {
        this.status = "connecting";
        this.$emit("connecting");
      },
      serialSend: async buffer => {
        const buf = Buffer.from(buffer);
        if (this.networkLogging) {
          this.bytesSent += buf.length;
        }
        if (this.serial) {
          await this.serial.send(buf);
        }
        if (this.webSerialWriter) {
          await this.webSerialWriter.write(buf);
        }
        if (this.espSerial) {
          await this.espSerial.write(buf);
        }
      },
      downloadMetadata: async allowCache => {
        let cached: CacheFile;
        const cache = await this.getCacheStorage();
        cached = await cache.loadFileCache("octopus-metadata.bin");
        if (cached) {
          this.log(`Loaded cached metadata from ${cached.date}, hash = ${cached.metadata.hash}`);
        } else {
          this.log(`No cached metadata found`);
        }
        try {
          const metadata: ArrayBuffer = (await this.$feathers.service("cloudOctopus/metadatas").find({
            query: {
              ...(cached?.metadata?.hash ? { hash: cached.metadata.hash } : {}),
              ...(this.shopInfo.cloudArgs || {}),
            },
          })) as any;

          if (!(metadata instanceof ArrayBuffer)) {
            throw new Error("Invalid metadata");
          }

          if (cached && metadata.byteLength === 0) {
            this.log(`Using cached metadata from ${cached.date}, hash = ${cached.metadata.hash}`);
            return msgpack.decode(cached.data);
          }

          (async () => {
            try {
              const sha256 = Buffer.from(await crypto.subtle.digest("SHA-256", metadata)).toString("hex");

              this.log(`Saving metadata to cache hash = ${sha256};`);

              const obj = await cache.saveFile("octopus-metadata.bin", Buffer.from(metadata), {
                hash: sha256,
              });

              this.log(`Saved metadata to cache, date = ${obj.date} hash = ${sha256};`);
            } catch (e) {
              console.error(e);
              this.log(`Failed to save metadata to cache`);
            }
          })();
          return msgpack.decode(Buffer.from(metadata));
        } catch (e) {
          if (allowCache && cached) {
            this.log(
              `Failed to download metadata ${e.message}, using cache from ${cached.date}, hash = ${cached.metadata.hash};`,
            );
            return msgpack.decode(cached.data);
          }
          throw e;
        }
      },
      saveExchange: async (path, buf) => {
        this.log("Save exchange in cache");
        const mpsStorage = await this.getMPSStorage();
        await mpsStorage.saveFile(path, Buffer.from(buf));
        this.getOldLogStorage()
          .then(storage => {
            storage.saveFile(path, Buffer.from(buf), {
              devId: (this.version?.devId || 0).toString(16).padStart(8, "0"),
            });
          })
          .catch(console.error);
      },
      uploadExchange: async (path, buf) => {
        const mpsStorage = await this.getMPSStorage();
        await mpsStorage.saveFile(path, Buffer.from(buf));
        this.getOldLogStorage()
          .then(storage => {
            storage.saveFile(path, Buffer.from(buf), {
              devId: (this.version?.devId || 0).toString(16).padStart(8, "0"),
            });
          })
          .catch(console.error);
        try {
          await this.$feathers.service("cloudOctopus/exchanges").create(
            msgpack
              .encode({
                path,
                data: buf,
                deviceId: (this.version?.devId || 0).toString(16).padStart(8, "0"),
                shopId: this.shopId,
                shopName: this.shopInfo?.shopName,
                cashierId: this.cashierId,
                cashierName: this.shopInfo?.cashierName,
              })
              .slice(),
            {
              query: {
                ...(this.shopInfo.cloudArgs || {}),
              },
            },
          );
          await mpsStorage.deleteFile(path);
        } catch (e) {
          console.error(e);
          this.log(`Failed to upload exchange to server, save to cache ${e.message}`);
          e.uploadFail = true;
          throw e;
        }

        await this.flushMPSStorage();
      },
      autoStart: true,
      useBootCache: true,
      ...(this.increasedMemory ? { memorySize: 96 * 1024 * 1024 } : {}),
      loadCache: async name => {
        const storage = await this.getCacheStorage();
        const resp = await storage.loadFileCache(name);
        return resp?.data;
      },
      saveCache: async (name, buf) => {
        const storage = await this.getCacheStorage();
        await storage.saveFile(name, Buffer.from(buf));
      },
      loadTempDatas: async () => {
        const storage = await this.getTempStorage();
        const files = await storage.listFiles();
        const data = await Promise.all(
          files.map(async file => {
            const resp = await storage.loadFileCache(file);
            return {
              path: file,
              data: resp?.data,
            };
          }),
        );
        return data;
      },
      saveTempData: async (path, buf) => {
        const storage = await this.getTempStorage();
        await storage.saveFile(path, Buffer.from(buf));
      },
      loop: true,
      ...(useNative
        ? {
            createWorker: () => createWorker(usb),
          }
        : {}),
      requestRestart: () => {
        this.log("Requesting restart");
        this.reset(true, true);
      }
    });
  }

  log(str: string) {
    console.log(str);
    const formattedDate = momentTZ().tz("Asia/Hong_Kong").format("YYYY-MM-DDTHH:mm:ss.SSS");
    this.writeLog(`${formattedDate}+08:00 [JS] ${str}`);
  }

  private ensureHousekeeping() {
    if (!this.housekeepingTask && (this.autoHousekeeping || this.autoXFile)) {
      this.housekeepingTask = this.housekeepingWorker();
      this.housekeepingTask.catch(e => {
        console.error(e);
        this.housekeepingTask = null;
      });
    }
  }

  writeLog(buf: string) {
    const encoded = new TextEncoder().encode(buf);
    if (!this.logBuffer || this.logOffset + encoded.length > this.logBuffer.length) {
      this.flushLogBuffer();
      if (encoded.length > this.logBuffer.length) {
        const fullBuf = Buffer.concat([
          Buffer.from(`\r\n----Start of log ${this.formatLogName()}----\r\n`),
          Buffer.from(encoded),
          Buffer.from(`\r\n----End of log ${this.formatLogName()}----\r\n`),
        ]);
        this.saveLogBuffer(fullBuf);
        return;
      }
    }
    const logBuffer = this.logBuffer;
    for (let i = this.logOffset, j = 0; j < encoded.length; i++, j++) {
      logBuffer[i] = encoded[j];
    }
    this.logOffset += encoded.length;
    this.logDirty = true;
  }

  async sendServerNotification(notification: OctopusServerNotification) {
    const shopId = this.shopId || "unknown";
    const cashierId = this.cashierId || "unknown";
    const shopName = this.shopInfo?.shopName || "unknown";
    const cashierName = this.shopInfo?.cashierName || "unknown";

    let version = this.version;
    if (!version) {
      try {
        version = JSON.parse(localStorage["lastOctopusVersion"]);
      } catch (e) {}
    }

    const locationId =
      ((version?.locId ? this.formatLocationId(version?.locId ?? 0) : this.locationId) || "").toUpperCase() ||
      "unknown";
    const devId = version?.devId?.toString?.(16)?.padStart?.(8, "0") || "unknown";

    notification.dateGMT8 = (momentTZ() as any).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm:ss");
    notification.identifier = `${shopId}_${cashierId}${notification.identifier ? "_" + notification.identifier : ""}`;
    notification.data = notification.data || {};
    notification.data.shopId = shopId;
    notification.data.cashierId = cashierId;
    notification.data.locationId = locationId;
    notification.data.devId = devId;
    notification.data.shopName = shopName;
    notification.data.cashierName = cashierName;

    notification.data.port = this.serialConfig;
    notification.data.version = this.version;

    this.log(`[Server Notification] ${JSON.stringify(notification)}\r\n`);
    try {
      await this.$feathers.service("cloudOctopus/notifications").create(notification, {
        query: {
          ...(this.shopInfo.cloudArgs || {}),
        },
      });
    } catch (e) {
      console.warn(e);
      localStorage["lastOctopusServerNotification"] = JSON.stringify(notification);
    }
  }

  async clearServerNotification(prefix?: string) {
    const shopId = this.shopId || "unknown";
    const cashierId = this.cashierId || "unknown";
    delete localStorage["lastOctopusServerNotification"];
    try {
      await this.$feathers.service("cloudOctopus/notifications").remove(null, {
        query: {
          identifierPrefix: `${shopId}_${cashierId}${prefix ? "_" + prefix : ""}`,
          ...(this.shopInfo.cloudArgs || {}),
        },
      });
    } catch (e) {}
  }

  flushLogBuffer() {
    if (this.logOffset) {
      const buf = Buffer.concat([
        Buffer.from(Uint8Array.prototype.slice.call(this.logBuffer, 0, this.logOffset)),
        Buffer.from(`\r\n----End of log ${this.formatLogName()}----\r\n`),
      ]);
      this.saveLogBuffer(buf);
    }
    this.logOffset = 0;
    if (!this.logBuffer) {
      this.logBuffer = Buffer.alloc(64 * 1024);
      this.writeLog(`----Start of log ${this.formatLogName()}----\r\n`);
    }
    this.logStart = Date.now();
  }

  async getCacheStorage() {
    if (!this.cacheStorage) {
      this.cacheStorage = await getStorage("octopusCache");
    }
    return this.cacheStorage;
  }

  async getLogStorage() {
    if (!this.logStorage) {
      this.logStorage = await getStorage("octopusLog");
    }
    return this.logStorage;
  }

  async getOldLogStorage() {
    if (!this.oldLogStorage) {
      this.oldLogStorage = await getStorage("oldOctopusLog");
    }
    return this.oldLogStorage;
  }

  async getMPSStorage() {
    if (!this.mpsStorage) {
      this.mpsStorage = await getStorage("mpsStorage");
    }
    return this.mpsStorage;
  }

  async getTempStorage() {
    if (!this.tempStorage) {
      this.tempStorage = await getStorage("octopusTempStorage");
    }
    return this.tempStorage;
  }

  async getTransactionLogStorage() {
    if (!this.transactionLogStorage) {
      this.transactionLogStorage = await getStorage("octopusTransactionLog");
    }
    return this.transactionLogStorage;
  }

  cacheStorage: CacheStorage;
  logStorage: CacheStorage;
  oldLogStorage: CacheStorage;
  transactionLogStorage: CacheStorage;
  mpsStorage: CacheStorage;
  tempStorage: CacheStorage;

  formatLogName() {
    return `${moment().format("YYYY-MM-DD-HH-mm-ss")}_${
      this.version?.devId?.toString?.(16)?.padStart?.(8, "0") || "unknown"
    }_${this.shopId || "unknown"}_${this.cashierId || "unknown"}_${(
      (this.version?.locId ? this.versionLocationId : this.locationId) || ""
    ).toUpperCase()}`;
  }

  async saveLogBuffer(buf: Uint8Array) {
    if (buf.length) {
      try {
        const storage = await this.getLogStorage();
        await storage.saveFile(`${this.formatLogName()}.log`, Buffer.from(buf));
        await this.syncLogs();
        return true;
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async getTransactionLog(id: string) {
    try {
      const storage = await this.getTransactionLogStorage();
      const resp = await storage.loadFileCache(id);
      if (resp?.data) {
        const j = JSON.parse(resp.data.toString());
        return j as OctopusCommandRespTransactionSuccess | OctopusCommandRespTransactionError;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  async saveTransactionLog(id: string, cmd: OctopusCommandRespTransactionSuccess | OctopusCommandRespTransactionError) {
    try {
      const storage = await this.getTransactionLogStorage();
      await storage.saveFile(`${id}.json`, Buffer.from(JSON.stringify(cmd)));
    } catch (e) {
      console.warn(e);
    }
  }

  async syncLogs() {
    const storage = await this.getLogStorage();
    const oldStorage = await this.getOldLogStorage();

    for (let key of await storage.listFiles()) {
      try {
        const data = await storage.loadFile(key, null, null);
        await this.$feathers.service("cloudOctopus/logs").create(
          msgpack
            .encode({
              path: key,
              data,
            })
            .slice(),
          {
            query: {
              ...(this.shopInfo.cloudArgs || {}),
            },
          },
        );

        await oldStorage.saveFile(key, data);
        await storage.deleteFile(key);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  unload() {
    if (this.logOffset) {
      localStorage["lastOctopusLog"] =
        new TextDecoder().decode(this.logBuffer.slice(0, this.logOffset)) +
        `\r\n----End of log ${this.formatLogName()}----`;
      this.logOffset = 0;
    }
  }

  async flushOldLog() {
    if (localStorage["lastOctopusLog"]) {
      const log = localStorage["lastOctopusLog"];
      delete localStorage["lastOctopusLog"];
      if (await this.saveLogBuffer(new TextEncoder().encode(log))) return;
    }
    await this.syncLogs();
  }

  async flushNotification() {
    if (localStorage["lastOctopusServerNotification"]) {
      try {
        const noti = JSON.parse(localStorage["lastOctopusServerNotification"]);
        try {
          await this.$feathers.service("cloudOctopus/notifications").create(noti, {
            query: {
              ...(this.shopInfo.cloudArgs || {}),
            },
          });
          delete localStorage["lastOctopusServerNotification"];
        } catch (e) {
          console.warn(e);
        }
      } catch (e) {
        delete localStorage["lastOctopusServerNotification"];
      }
    }
  }

  async saveDraftLog() {
    this.logDirtyTimer = null;
    if (this.logDirty) {
      localStorage["lastOctopusLog"] =
        new TextDecoder().decode(this.logBuffer.slice(0, this.logOffset)) +
        `\r\n----End of log ${this.formatLogName()}----`;
      this.logDirty = false;
    }
    if (this.logOffset && Date.now() - this.logStart > 24 * 60 * 60 * 1000) {
      this.flushLogBuffer();
    }
    this.logDirtyTimer = setTimeout(this.saveDraftLog, 60 * 5 * 1000); // save log every 5min
  }

  needReconnect: boolean;

  async connect(user?: boolean, reconnect?: boolean) {
    if (!reconnect && this.hasConnection) {
      return;
    }
    const useNative = this.settings?.ignoreNative ? false : await nativeSupported();
    if (!reconnect && this.serialConfig && useNative) {
      return;
    }
    if (this.hasConnection) {
      await this.disconnect(reconnect);
    }
    this.log(`Going to connect ${reconnect ? '' : JSON.stringify(this.serialConfig)}`)
    const device = await portPicker(
      this,
      reconnect ? undefined : this.serialConfig,
      user,
      useNative,
      "Octopus",
      "Octopus",
      (msg) => this.log(msg),
    );
    if (useNative) {
      this.serialConfig = device;
      await this.reset(false, true);
      return;
    }
    if (device) {
      this.disconnect();
      this.clearReconnect();
      switch (device.type) {
        case "serial": {
          this.serialConfig = device;
          const serial = (this.serial = await connect(device.port, {
            baudRate: 115200,
          }));
          this.serial.on("data", buf => {
            if (this.octopus) {
              if (this.networkLogging) {
                this.bytesReceived += buf.length;
              }
              this.octopus.serialInput(buf);
            }
          });
          this.serial.on("close", () => {
            if (this.serial === serial) {
              this.onDisconnect();
            }
          });
          break;
        }
        case "webSerial": {
          this.serialConfig = {
            type: "webSerial",
          };
          this.webSerial = device.device;
          try {
            this.webSerial.addEventListener("disconnect", () => {
              if (this.webSerial === device.device) {
                this.onDisconnect();
              }
            });
            await this.webSerial.open({
              baudRate: 115200,
            });
            this.webSerialWriter = this.webSerial.writable.getWriter();
            const reader = this.webSerial.readable.getReader();
            const task = (async () => {
              while (true) {
                const data = await reader.read();
                if (this.octopus) {
                  if (this.networkLogging) {
                    this.bytesReceived += data.value.length;
                  }
                  this.octopus.serialInput(data.value);
                }
              }
            })();
            task.catch(e => {
              console.error(e);
              if (this.webSerial === device.device) {
                this.onDisconnect();
              }
            });
          } catch (e) {
            if (this.webSerial === device.device) {
              this.onDisconnect();
            }
          }
          break;
        }
        case "espSerial": {
          this.serialConfig = {
            type: "espSerial",
            espConfig: device.espConfig,
            vendorId: device.vendorId,
            productId: device.productId,
          };
          const serial = (this.espSerial = await (device.device as EspDevice).getSerialAsync());
          const newUsb = device.espConfig?.vid === 0x0525 && device.espConfig?.pid === 0xa4a7;
          if (newUsb !== this.usb) {
            this.log("ESP32 USB mode changed, going to reset");
            this.reset(false, true);
          }
          this.espSerial.on("data", buf => {
            if (this.espSerial === serial) {
              if (this.octopus) {
                if (this.networkLogging) {
                  this.bytesReceived += buf.length;
                }
                this.octopus.serialInput(buf);
              }
            }
          });
          this.espSerial.on("close", (reason?: string) => {
            if (this.espSerial === serial) {
              if (reason === "detached") {
                this.$openDialog(
                  // @ts-ignore
                  import("@feathers-client/components-internal/ConfirmDialog.vue"),
                  {
                    title: this.$t("octopus.espDetached"),
                    hasCancel: false,
                  },
                  {
                    maxWidth: "400px",
                  },
                );
              }
              this.onDisconnect(reason === "detached");
            }
          });
          break;
        }
      }

      this.reconnectTrials = 0;
      if (this.octopus) {
        this.octopus.sendCommand({ type: "connected" }).catch(() => void 0);
        if (this.needReconnect) {
          this.octopus.sendCommand({ type: "reconnect" }).catch(() => void 0);
          this.needReconnect = false;
        }
      }
    } else if (this.serialConfig) {
      this.reconnectTrials++;
      this.scheduleReconnect();
    }
  }

  private onDisconnect(noAutoReconnect?: boolean) {
    this.disconnect();
    if (this.serialConfig && !noAutoReconnect) {
      this.scheduleReconnect();
    }
  }

  async disconnect(save?: boolean) {
    if (this.serial) {
      this.serial.removeAllListeners("data");
      this.serial.close();
      this.serial = null;
    }
    if (this.webSerial) {
      try {
        await this.webSerial.close();
      } catch (e) {
        console.warn(e);
      }
      try {
        await this.webSerial.forget();
      } catch (e) {
        console.warn(e);
      }
      this.webSerial = null;
      this.webSerialWriter = null;
    }
    if (this.espSerial) {
      this.espSerial.removeAllListeners("data");
      this.espSerial.close();
      this.espSerial = null;
    }
    if (save) {
      this.serialConfig = null;
    }
    if (this.status === "connected") {
      this.status = "disconnected";
      this.needReconnect = true;
    }
    if (this.octopus) {
      this.octopus.sendCommand({ type: "disconnected" }).catch(() => void 0);
    }
    const useNative = this.settings?.ignoreNative ? false : await nativeSupported();
    if (useNative) {
      await this.reset(false, false);
    }
  }

  clearReconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout as any);
      this.reconnectTimeout = null;
    }
  }

  reconnectTrials = 0;

  scheduleReconnect() {
    this.clearReconnect();
    if (this.serialConfig) {
      const expTimeout =
        Math.min(60000, 3000 * Math.pow(1.5, Math.min(this.reconnectTrials, 20))) * (1 + Math.random());
      this.reconnectTimeout = setTimeout(this.autoReconnect, expTimeout);
    }
  }

  async autoReconnect() {
    this.reconnectTimeout = null;
    if (this.serialConfig) {
      try {
        await this.connect();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async timeVer() {
    try {
      return await this.octopus?.timeVer?.();
    } catch (e) {
      if (e.message === "Cancelled") return;
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async syncTime() {
    try {
      await this.octopus?.syncTime?.();
    } catch (e) {
      if (e.message === "Cancelled") return;
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  lastComplainBlockList = 0;

  async checkSysInfo(inDownload?: boolean) {
    try {
      const sysinfo = (await this.octopus?.sendCommand?.({
        type: "sysInfo",
      })) as OctopusCommandRespSuccessSysInfo;
      if (sysinfo) {
        if (!sysinfo.blkUpToDate) {
          if (!inDownload && !this.downloading) {
            this.log("Block list outdated, going to download");
            await this.download();
            return;
          } else {
            this.log("Block list outdated, but already in download state");
          }
          if (Date.now() - this.lastComplainBlockList < 60 * 1000) return;
          this.sendServerNotification({
            messageId: "octopus.blackListOutdated",
            level: "error",
            identifier: `exchangeFailed`,
          });
          this.lastComplainBlockList = Date.now();
          this.$openDialog(
            // @ts-ignore
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("octopus.blockListOutdated"),
              hasCancel: false,
            },
            {
              maxWidth: "400px",
            },
          );
        } else {
          this.clearServerNotification("exchangeFailed");
        }
      }
    } catch (e) {
      if (e.message === "Cancelled") return;
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  downloading = false;
  async download(notify?: boolean) {
    if (this.downloading) {
      return;
    }
    this.downloading = true;
    try {
      await this.octopus?.download?.();
      this.clearServerNotification("exchangeFailed");
      if (notify) {
        this.$store.commit("SET_SUCCESS", this.$t("basic.done"));
      }
    } catch (e) {
      if (e.message === "Cancelled") return;
      this.sendServerNotification({
        messageId: "octopus.exchangeFailed",
        level: "error",
        identifier: `exchangeFailed`,
        data: {
          error: e.message,
          stack: e.stack,
        },
      });
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      setTimeout(() => (this.downloading = false), 1000);
    }
    await this.checkSysInfo(true);
  }

  uploading = false;
  async upload(notify?: boolean) {
    if (this.uploading) {
      return;
    }
    this.uploading = true;
    try {
      this.writeLog("Going to XFile");
      await this.octopus?.upload?.();
      this.clearServerNotification("xfileFailed");
      this.writeLog("XFile Done");
      if (notify) {
        this.$store.commit("SET_SUCCESS", this.$t("basic.done"));
      }
    } catch (e) {
      if (e.message === "Cancelled") return;
      this.sendServerNotification({
        messageId: e.uploadFail ? "octopus.xfileUploadFailed" : "octopus.xfileFailed",
        level: "error",
        identifier: e.uploadFail ? `xfileFailedUpload` : `xfileFailed`,
        data: {
          error: e.message,
          stack: e.stack,
        },
      });
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      setTimeout(() => (this.uploading = false), 1000);
    }
  }

  async writeId() {
    try {
      this.writeLog(`Going to write ID ${this.savedLocationId}`);
      await this.octopus?.sendCommand?.({
        type: "writeID",
        id: this.savedLocationId,
      });
    } catch (e) {
      if (e.message === "Cancelled") return;
      if (e.message?.startsWith?.("Timeout")) {
        return;
      }
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./OctopusDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async enquiry() {
    return await this.$openDialog(
      // @ts-ignore
      import("./EnquiryDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async reset(fullReload?: boolean, autoStart = true) {
    try {
      await this.preTask;
    } catch (e) {}
    this.preTask = null;
    if (this.octopus) {
      await this.octopus.stop();
      this.octopus = null;
    }
    if (fullReload) {
      this._archiveData = null;
      this._initFunc = null;
      const cache = await this.getCacheStorage();
      await cache.cleanFiles();
    }
    if (autoStart) {
      this._exec();
    }
  }

  getNextXFile() {
    if (!this.autoXFile) {
      return new Date(9999, 0, 1);
    }
    let m: Moment = (momentTZ(this.lastUploadInfo?.time) as any).tz("Asia/Hong_Kong");
    m = m.startOf("day").add(1, "hour");
    if (Date.now() > m.toDate().getTime()) {
      m = m.add(1, "day");
    }
    return m.toDate();
  }

  getNextHousekeeping() {
    if (!this.autoHousekeeping) {
      return new Date(9999, 0, 1);
    }
    if (!this.metadataDownloadTime) {
      return new Date(2000, 0, 1);
    }
    let m: Moment = (momentTZ(this.metadataDownloadTime) as any).tz("Asia/Hong_Kong");
    // our server download @ every 40pm
    m = m.startOf("hour").add(50, "minute");
    if (Date.now() > m.toDate().getTime()) {
      m = m.add(1, "hour");
    }
    return m.toDate();
  }

  housekeepingWorkerTimer: any;
  housekeepingWorkerResolve: any;
  currentHousekeeping: Promise<void>;
  isExit = false;
  async housekeepingWorker() {
    try {
      let lastMin = 0;
      while (this.autoXFile || this.autoHousekeeping) {
        const nextXFile = this.getNextXFile();
        const nextHousekeeping = this.getNextHousekeeping();

        let nextSchedule = Math.min(nextXFile.getTime(), nextHousekeeping.getTime());

        if (this.status !== "connected") {
          nextSchedule = nextXFile.getTime();
        }

        let millis = Math.max(0, nextSchedule - Date.now());
        if (nextSchedule === lastMin && millis === 0) millis = 10 * 60 * 1000;
        lastMin = nextSchedule;
        this.log(`Next schedule time ${nextSchedule} in ${millis / 1000} seconds, ${nextXFile}, ${nextHousekeeping}`);

        if (millis > 0) {
          await new Promise<void>(resolve => {
            this.housekeepingWorkerTimer = setTimeout(() => {
              this.housekeepingWorkerTimer = null;
              this.housekeepingWorkerResolve = null;
              resolve();
            }, millis);
            this.housekeepingWorkerResolve = resolve;
          });
        }

        if (this.isExit) return;
        if (this.status !== "connected") {
          this.writeLog("Octopus Not connected when doing housekeeping, waiting for connection");
          try {
            await this.waitReady("background", 60 * 1000);
          } catch (e) {
            // warn failed xfile

            this.sendServerNotification({
              messageId: "octopus.xfileFailedNoConnection",
              level: "warning",
              identifier: `xfileFailed`,
            });
            await this.waitReady("background");
          }
          this.writeLog("Octopus connected, resume housekeeping worker");
        }

        if (Date.now() >= nextXFile.getTime()) {
          this.writeLog("Going to XFile in housekeeping worker");
          try {
            await this.scheduleHousekeeping(async () => {
              await this.upload();
            });
          } finally {
            this.currentHousekeeping = null;
          }
        }

        if (Date.now() >= nextHousekeeping.getTime()) {
          this.writeLog("Going to Download in housekeeping worker");
          try {
            await this.scheduleHousekeeping(async () => {
              await this.download();
            });
          } finally {
            this.currentHousekeeping = null;
          }
        }
      }
    } finally {
      this.housekeepingTask = null;
    }
  }

  async waitReady(
    forceUser?: boolean | "background",
    timeout = 0,
    connecting?: () => void,
    allowConnecting = false,
    signal?: AbortSignal,
  ) {
    if (!this.hasConnection && forceUser !== "background") {
      await this.connect(true);
    }
    if (!this.hasConnection && forceUser !== "background") {
      const err = new Error("MOP connection failure");
      (err as any).messaget = [
        { lang: "en", value: "MOP connection failure" },
        { lang: "zh-hk", value: "未能接駁八達通收費器" },
        { lang: "zh-cn", value: "未能接驳八达通收费器" },
      ];
      throw err;
    }
    let abortReject: (e: Error) => void;
    const abortHandler = () => {
      abortReject?.(new Error("Cancelled"));
    };
    signal?.addEventListener?.("abort", abortHandler);
    while (this.status !== "connected" && (!allowConnecting || this.status !== "connecting")) {
      connecting?.();
      // @ts-ignore
      signal?.throwIfAborted?.();
      await new Promise((resolve, reject) => {
        abortReject = reject;
        this.$once("connected", resolve);
        if (allowConnecting) {
          this.$once("connecting", resolve);
        }
        if (timeout) {
          setTimeout(() => {
            const err = new Error("MOP connection failure");
            (err as any).messaget = [
              { lang: "en", value: "MOP connection failure" },
              { lang: "zh-hk", value: "未能接駁八達通收費器" },
              { lang: "zh-cn", value: "未能接驳八达通收费器" },
            ];
            reject(err);
          }, timeout);
        }
      });
      // @ts-ignore
      signal?.throwIfAborted?.();

      await new Promise(resolve => setTimeout(resolve, 10));
      if (this.currentHousekeeping) {
        await this.currentHousekeeping;
        continue;
      }
      break;
    }
  }

  currentTransaction: Promise<OctopusCommandRespTransactionSuccess | OctopusCommandRespTransactionError>;

  async transaction(
    input: Partial<OctopusCommandTransaction>,
    paymentId: string,
    progress?: (status: "starting" | "mustRetry" | "retry" | "error") => void,
    retry?: (err: OctopusCommandRespTransactionError, status: "mustRetry" | "retry") => Promise<boolean>,
    signal?: AbortSignal,
  ) {
    while (this.currentHousekeeping || this.currentTransaction) {
      if (this.currentHousekeeping) {
        try {
          await this.currentHousekeeping;
        } catch (e) {}
      }
      if (this.currentTransaction) {
        try {
          await this.currentTransaction;
        } catch (e) {}
      }
    }
    const task = this.transactionInner(input, paymentId, progress, retry, signal);
    try {
      this.currentTransaction = task;
      return await task;
    } finally {
      if (this.currentTransaction === task) {
        this.currentTransaction = null;
      }
    }
  }

  async transactionInner(
    input: Partial<OctopusCommandTransaction>,
    paymentId: string,
    progress?: (status: "starting" | "mustRetry" | "retry" | "error") => void,
    retry?: (err: OctopusCommandRespTransactionError, status: "mustRetry" | "retry") => Promise<boolean>,
    signal?: AbortSignal,
  ) {
    if (!this.octopus) {
      throw new Error("Octopus Not connected");
    }
    // @ts-ignore
    signal?.throwIfAborted?.();
    let mustRetrying = !!input.pollExpect;
    while (true) {
      await this.waitReady(undefined, undefined, undefined, true, mustRetrying ? undefined : signal);
      let transaction: OctopusCommandRespTransactionError | OctopusCommandRespTransactionSuccess;
      try {
        input._id = uuid();
        if (!this.useNative) {
          input.millis = Date.now();
        }
        if (!mustRetrying && signal) {
          signal.addEventListener("abort", () => {
            this.cancel().catch(console.warn);
          });
        }
        transaction = await this.octopus.sendTransactionCommand(input as any, e => {
          if (e.type === "transactionStarting") {
            progress?.("starting");
          }
        });
      } catch (e) {
        if (e?.type === "transactionError") {
          transaction = e;
        } else {
          throw e;
        }
      }
      if (transaction.type === "transactionError") {
        if (transaction.error === "noCardPresent" && !mustRetrying) {
          throw transaction;
        }
        if (
          transaction.octopusErrorMessage &&
          (transaction.octopusErrorMessage[6] === "yes" ||
            transaction.octopusErrorMessage[6] === "must" ||
            mustRetrying)
        ) {
          const must = mustRetrying || transaction.octopusErrorMessage[6] === "must";
          if (transaction.octopusErrorMessage[6] === "must" && transaction.pollParsed?.cardId && !input.pollExpect) {
            await this.saveTransactionLog(paymentId, transaction);
            input.pollExpect = transaction.pollParsed?.cardId;
            input.pollCount = 1;
          } else if (!mustRetrying) {
            await this.saveTransactionLog(paymentId, transaction);
          }
          if (must) {
            mustRetrying = true;
          }
          progress?.(must ? "mustRetry" : "retry");
          if (await retry?.(transaction, must ? "mustRetry" : "retry")) {
            continue;
          }
        }
        progress?.("error");
        throw transaction;
      } else {
        await this.saveTransactionLog(paymentId, transaction);
        return transaction;
      }
    }
  }

  async restoreTransaction(
    input: Partial<OctopusCommandTransaction>,
    paymentId: string,
    progress?: (status: "starting" | "mustRetry" | "retry" | "error") => void,
    retry?: (err: OctopusCommandRespTransactionError, status: "mustRetry" | "retry") => Promise<boolean>,
  ) {
    try {
      const log = await this.getTransactionLog(paymentId);
      if (log) {
        if (log.type === "transactionError") {
          const must = log.octopusErrorMessage[6] === "must" && log.pollParsed?.cardId;
          if (must) {
            input.pollExpect = log.pollParsed?.cardId;
            return await this.transaction(input, paymentId, progress, retry);
          } else {
          }
        }
      }
      return log;
    } catch (e) {
      progress?.("error");
      throw e;
    }
  }

  async cancel() {
    if (this.octopus) {
      await this.octopus.sendCommand({
        type: "cancelTransaction",
      });
    }
  }

  async clearDisplay() {
    if (this.octopus) {
      await this.octopus.clearDisplay?.();
    }
  }

  async cleanLogs() {
    await this.flushMPSStorage();
    await (await this.getCacheStorage()).cleanFiles();
    await (await this.getLogStorage()).cleanFiles();
    await (await this.getOldLogStorage()).cleanFiles();
    await (await this.getTransactionLogStorage()).cleanFiles();
    await (await this.getMPSStorage()).cleanFiles();
    await (await this.getTempStorage()).cleanFiles();
  }

  async flushMPSStorage() {
    const mpsStorage = await this.getMPSStorage();
    for (let path of await mpsStorage.listFiles()) {
      try {
        this.log(`Resume upload exchange ${path}`);
        const data = await mpsStorage.loadFileCache(path);
        await this.$feathers.service("cloudOctopus/exchanges").create(
          msgpack
            .encode({
              path,
              data: data.data,
              deviceId: data.metadata?.devId,
              shopId: this.shopId,
              shopName: this.shopInfo?.shopName,
              cashierId: this.cashierId,
              cashierName: this.shopInfo?.cashierName,
            })
            .slice(),
          {
            query: {
              ...(this.shopInfo.cloudArgs || {}),
            },
          },
        );
        await mpsStorage.deleteFile(path);
      } catch (e) {
        console.error(e);
        this.log(`Failed to resume upload exchange to server, ${path}, ${e.message}`);
      }
    }
  }

  async scheduleHousekeeping(func: () => Promise<void>) {
    try {
      await this.flushMPSStorage();
    } catch (e) {
      console.error(e);
    }
    while (this.currentTransaction || this.currentHousekeeping) {
      if (this.currentTransaction) {
        try {
          await this.currentTransaction;
        } catch (e) {}
      }
      if (this.currentHousekeeping) {
        try {
          await this.currentHousekeeping;
        } catch (e) {}
      }
    }
    const task = func();
    if (this.currentHousekeeping) {
      throw new Error("Housekeeping already running");
    }
    this.currentHousekeeping = task;
    try {
      await this.currentHousekeeping;
    } finally {
      if (this.currentHousekeeping === task) {
        this.currentHousekeeping = null;
      }
    }
  }
}

export function nativeSupported(): Promise<boolean> {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("octopus").call("supported"),
    new Promise(resolve => setTimeout(() => resolve(false), 1000)),
  ]).catch(e => false);
}

async function createWorker(usb?: boolean): Promise<Worker> {
  const { port } = await ns("octopus").call("launch", {
    cloudUrl: BOXS_CLOUD_URL,
    usb,
  });
  const mport: MessagePort = port;
  (port as any).terminate = () => {
    mport.close();
  };
  return port as any;
}
