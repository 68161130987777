
import { SearchField } from "@schemaEditor/plugin";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import { Component, Vue, Prop, Watch, FindType, getID } from "@feathers-client";

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class PresetDatePicker extends Vue {
  @Prop({ default: "" }) mode: string;

  loading = false;
  isOpen = false;

  updateSetDate(dateSet: string) {
    this.$emit("updateSetDate", dateSet);
  }

  onClickOutside() {
    this.isOpen = false;
  }
}
