
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, VModel } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import moment from "moment";

const populate = [
  {
    path: "tables.item",
    populate: [
      {
        path: "view",
      },
    ],
  },
] as const;

@Component
export default class ReservationDetailDialog extends mixins(Dialog) {
  @VModel()
  show: boolean;

  @Prop()
  item: FindPopRawType<typeof populate, "tableSessions">;

  @Prop(Boolean)
  details: boolean;

  menu = false;

  get tableName() {
    return this.item.tables?.[0]?.item?.name ? this.item.tables?.[0]?.item?.name : "-";
  }

  get setStatue() {
    return ["checkIn", "absent"];
  }

  get currentStatus() {
    return this.item.status === "ongoing" ? "checkIn" : this.item.status;
  }

  close() {
    this.show = false;
  }

  openDialog() {
    if (this.currentStatus !== "booking") return;
    this.menu = !this.menu;
  }

  checkIn() {
    if (this.currentStatus !== "booking") return;
    this.$emit("checkIn");
  }
  absent() {
    if (this.currentStatus !== "booking") return;
    this.$emit("absent");
  }
  cancel() {
    if (this.currentStatus !== "booking") return;
    this.$emit("cancel");
  }
  beginEdit() {
    if (this.currentStatus !== "booking") return;
    this.$emit("beginEdit");
  }

  formatDate(time) {
    const m = moment(time);
    const today = moment().startOf("day");
    const tmr = moment(today).add(1, "day");
    const tmrtmr = moment(tmr).add(1, "day");

    if (m.isSameOrAfter(tmrtmr) || m.isBefore(today)) {
      return m.format("YYYY-MM-DD");
    } else if (m.isBefore(tmr)) {
      return this.$t("time.today");
    } else {
      return this.$t("time.tomorrow");
    }
  }

  formatTime(time) {
    return moment(time).format(this.details ? "YYYY-MM-DD HH:mm" : "HH:mm");
  }
}
