
import { Component, Prop, Vue, Watch, VModel } from "nuxt-property-decorator";
import { TableView } from "~/plugins/table/view";
import { TableItem } from "~/plugins/table";
import { SceneCanvas } from "konva/lib/Canvas";
import { TableSession } from "~/plugins/table/session";

@Component
export default class ItemSessionMerge extends Vue {
  @Prop()
  selectedItems: TableItem[];

  @Prop()
  tableView: TableView;

  @Prop()
  canvasWidth: number;

  @Prop()
  canvasHeight: number;

  @VModel()
  selectMergeMenu: boolean;

  @Prop()
  isMergeTable: boolean;

  configLayer = { listening: true, opacity: 0 };
  headerHeight = 56;
  footerHeight = 328;
  firstSelectedTableText = "";
  firstSelectedSession: TableSession;
  cachedSceneCanvas: SceneCanvas;
  cachedSceneValid = false;
  newTableSize: number = 0;

  get width() {
    return this.canvasWidth - 120;
  }

  get tablesNBills() {
    const selectedTable = this.selectedItems[this.selectedItems.length - 1];
    let selectedTableText = "";
    if (this.firstSelectedTableText == "" && this.selectedItems.length) {
      this.firstSelectedTableText = this.selectedItems[0].name;
      if (this.tableView.selectedSession) {
        this.firstSelectedSession = this.tableView.selectedSession;
        this.firstSelectedTableText += " (Bill " + this.tableView.selectedSession.sessionName + ")";
      }
      if (this.firstSelectedSession?.tableRefs.length > 1) {
        this.firstSelectedSession.tableRefs.slice(1).map(table => {
          this.firstSelectedTableText += ", " + table.table.name;
        });
      }
    } else {
      if (this.selectedItems.length > 1) {
        if (selectedTable.sessions.length > 0) {
          selectedTable.deselect();
        }
        this.selectedItems.slice(1).map(item => {
          if (!this.firstSelectedTableText.includes(item.name)) {
            selectedTableText += ", " + item.name;
          }
        });
      }
    }
    return this.firstSelectedTableText + selectedTableText;
  }

  mounted() {
    if (this.selectMergeMenu) {
      this.onToggleMenu();
    }
  }

  @Watch("selectMergeMenu")
  onToggleMenu() {
    if (!this.selectMergeMenu) {
      this.reset();
    }
  }

  reset() {
    this.newTableSize = 0;
    this.firstSelectedTableText = "";
    this.firstSelectedSession = null;
  }

  get tableCapacity() {
    return this.tableView.selectedCapacity;
  }

  get tableSize() {
    return this.newTableSize || this.tableView.selectedCapacity || 1;
  }
  set tableSize(v) {
    this.newTableSize = v;
  }

  async mergeTable() {
    if (this.selectedItems.length <= 1) return;
    let session = this.firstSelectedSession;
    let remainSize = this.tableSize;
    let remainCap = this.tableCapacity;
    let selectedItems = [...this.tableView.selectedItems];
    if (selectedItems.length > 1) {
      let sessionTables = session.tables.map(it => it.item.toString());
      const toBeMergeTables = selectedItems
        .filter(table => !sessionTables.includes(table.id))
        .map(it => {
          const capacity = Math.min(Math.ceil((remainSize / (remainCap || 1)) * it.remainCapacity), remainSize);
          remainCap -= it.remainCapacity;
          remainSize -= capacity;
          return {
            item: it.id,
            capacity,
            split: 0,
          };
        }) as any;

      session.tables = session.tables.concat(toBeMergeTables);
      await Vue.nextTick();
      session.updateCachedDetails();
      await session.save();
    }

    this.$emit("hideMenu");
  }

  async splitTable() {
    let session = this.firstSelectedSession;
    session.tables = [this.firstSelectedSession.tables[0]];
    await Vue.nextTick();
    session.updateCachedDetails();
    await session.save();

    this.$emit("hideMenu");
  }
}
