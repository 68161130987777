
import { Component, Vue, VModel, Prop, FindType, Watch } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import SingleCurrencyEditor from "~/components/boxs/SingleCurrencyEditor.vue";

@Component({
  components: {
    EditorObjectPickerNew,
    EditorObjectPickerList,
    SingleCurrencyEditor,
  },
})
export default class RankGroupItemEditor extends Vue {
  @VModel()
  rankItem: FindType<"shopRankGroups">["ranks"][number];

  @Prop()
  accumType: "current" | "start";

  @Watch("accumType")
  updateAccumPeriodUnit() {
    if (this.accumType === "start") {
      this.rankItem.accumPeriodUnit = "month";
    }

    this.rankItem.expiryType = this.accumType;
  }

  get accumPeriodUnit() {
    return [
      {
        _id: "month",
        name: { $t: "pages.shopRankGroups.accumPeriodUnit.month" },
      },
      ...(this.accumType === "current"
        ? [
            {
              _id: "day",
              name: { $t: "pages.shopRankGroups.accumPeriodUnit.day" },
            },
          ]
        : []),
    ];
  }

  get expiryType() {
    return [
      ...(this.accumType === "start"
        ? [
            {
              _id: "start",
              name: { $t: "pages.shopRankGroups.accumType.start" },
            },
          ]
        : [
            {
              _id: "current",
              name: { $t: "pages.shopRankGroups.accumType.current" },
            },
            {
              _id: "accumulate",
              name: { $t: "pages.shopRankGroups.accumType.accumulate" },
            },
          ]),
      {
        _id: "unlimited",
        name: { $t: "pages.shopRankGroups.accumType.unlimited" },
      },
    ];
  }
}
