
import { Component, Prop, Vue, Watch, mixins, getID, checkID, FindType } from "@feathers-client";
import type { GiftType } from "@common/table/coupon";
import Dialog from "domore-table/mixins/Dialog";
import _ from "lodash";
import { TableSession } from "~/plugins/table/session";

@Component
export default class RedeemGift extends mixins(Dialog()) {
  @Prop()
  user: FindType<"appUsers">;

  @Prop()
  gift: GiftType;

  @Prop()
  session: TableSession;

  loading = false;

  async saveItem() {
    try {
      this.loading = true;

      if (this.gift.coupon) {
        await this.$feathers.service("gifts/check").create({
          user: getID(this.user),
          shop: getID(this.$shop.shopData),
          test: true,
          coupons: [
            ...this.session.coupons.filter(it => it.gift && it.discountSource === 'gift' && !it.giftRecord).map(it => ({
              gift: getID(it.gift),
            })),
            {
              gift: getID(this.gift),
            },
          ],
        } as any);
        this.modalResult({
          type: "coupon",
          coupon: this.gift.coupon,
        });
      } else {
        // fallback logic for lassana
        const record = await this.$feathers.service("gifts/redeem").create({
          gift: getID(this.gift),
          user: getID(this.user),
          shop: getID(this.$shop.shopData),
        } as any);
        this.$store.commit("SET_SUCCESS", "OK!");
        this.modalResult({
          type: "redeem",
          record,
        });
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", this.$td(e.data?.messaget ?? e.message));
    } finally {
      this.loading = false;
    }
  }
}
