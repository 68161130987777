
import { Vue, Component, Prop, VModel, PropSync, FindType, Watch } from "@feathers-client";
import LanguagePicker from "~/components/LanguagePicker.vue";
import moment from "moment";
import { wrapJob } from "../../plugins/printer/invoiceSequencer";
import { printTemplate } from "@common/table/invoiceSequencer";
import { PrintSequence } from "pos-printer/printSequence";

@Component({
  LanguagePicker,
})
export default class InputPeopleNum extends Vue {
  @VModel() numDialog: boolean;
  @Prop() kioskMode: boolean;
  @Prop({ default: false }) darkMode: boolean;
  @Prop() maxNum: number;

  inputValueSyncStr: string = "";
  openOverlay = false;
  ticket: FindType<"shopQueueTickets"> = null;
  newTicketNum = "";
  error = false;
  loading = false;

  onClickOutside() {
    if (this.kioskMode) return;
    this.numDialog = false;
  }
  async confirmNum() {
    if (!this.inputValue) return;
    this.loading = true;
    const shopQueueingGroup = this.$shopSession.shopQueueingGroups.find(
      item => this.inputValue >= item.tableCapacity.lower && this.inputValue <= item.tableCapacity.upper && item.active,
    );
    let shopSessionData = { ...this.$shopSession.shopSessionData };
    if (shopQueueingGroup && shopQueueingGroup.active) {
      //create ticket
      try {
        this.ticket = await this.$feathers.service("queueing/getTicket").create({
          group: shopQueueingGroup._id,
          noOfPeople: this.inputValue,
          prefix: shopQueueingGroup.prefix,
          shopSession: shopSessionData._id,
        });
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
        return;
      }
      this.newTicketNum = this.ticket.ticketNumber;
      this.printTicket(this.newTicketNum, this.inputValue);

      //show the new ticket user got
      this.openOverlay = true;
      setTimeout(() => {
        this.openOverlay = false;
      }, 5000); //showing the ticket for 5 seconds
    } else {
      this.newTicketNum = "";
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, 5000); //showing error message for 5 seconds
    }
    //pos mode just hide the num dialog and emit the error message if no ticket
    if (!this.kioskMode) {
      this.numDialog = false;
      if (!this.newTicketNum) {
        this.$store.commit("SET_ERROR", this.$t("queueing.noTicketGroupFound"));
      }
    }
    this.inputValueSyncStr = "0";
    this.loading = false;
  }
  get inputValue() {
    if (
      this.maxNum != undefined &&
      this.inputValueSyncStr != undefined &&
      Number(this.inputValueSyncStr) > this.maxNum
    ) {
      this.inputValueSyncStr = this.maxNum.toString();
    }
    if (this.inputValueSyncStr == undefined) {
      return 0;
    }
    return Number(this.inputValueSyncStr);
  }

  get showSplitModeNumPad() {
    return (
      this.$shop.localOptions.queueingKioskShowNumInputSection && !this.$shop.localOptions.queueingKioskShowFullScreen
    );
  }

  async printTicket(ticketNum: string, noOfPeople: number) {
    try {
      await wrapJob<any, PrintSequence>(this, "queueing-ticket", printTemplate, {
        templateName: "queueingTicket",
        data: {
          shop: this.$shop.shopData,
          time: moment().format("DD/MM/YYYY HH:mm:ss"),
          ticketNum,
          noOfPeople,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  }
}
