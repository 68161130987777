
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableView, TableItem } from "~/plugins/table";

@Component
export default class extends Vue {
  @Prop({ type: Number, default: 1 })
  index: number;

  @Prop()
  tableView: TableView;

  salesDate = ""

  beforeMount() {
    this.tableView.$on("resetSelect", this.reset);
  }

  beforeDestroy() {
    this.tableView.$on("resetSelect", this.reset);
  }

  reset() {
    this.newTableSize = 0;
  }

  get tableCapacity() {
    return this.tableView.selectedCapacity;
  }

  newTableSize: number = 0;
  get tableSize() {
    return this.newTableSize || this.tableView.selectedCapacity || 1;
  }
  set tableSize(v) {
    this.newTableSize = v;
  }

  async createSession() {
    let remainSize = this.tableSize;
    let remainCap = this.tableCapacity;

    const testing = !!this.tableView.selectedItems.find(it => it?.view?.testing);

    const tables = this.tableView.selectedItems.map(it => {
      const capacity = Math.min(Math.ceil((remainSize / (remainCap || 1)) * it.remainCapacity), remainSize);
      remainCap -= it.remainCapacity;
      remainSize -= capacity;
      return {
        item: it.id as any,
        capacity,
        split: it.nextSessionSplit,
      };
    });

    if (remainSize) {
      tables[0].capacity += remainSize;
    }

    const session = this.tableView.addSession({
      startTime: new Date(),
      tables,
      type: "dineIn",
      testing,
    });

    await Vue.nextTick();
    session.sessionData.tableRefName = session.name;
    if (this.$shop.shopData.autoSelectionTimeSection) {
      if (session.availableSections.length) {
        session.delaySave({ section: session.availableSections[0]._id });
      } else {
        console.warn("No available sections");
      }
    }
    session.updateCachedDetails();
    await session.save();
    await session.handleSessionCreate();

    session.selected = true;
    this.tableView.disableMultiSelect();
  }
}
