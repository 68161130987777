
import _ from "lodash";
import { Component, FindPopRawType, Prop, Vue, checkID, Watch } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { TableSession } from "~/plugins/table/session";
import { CouponItem, CouponType } from "~/common/table/coupon";

@Component({ components: { EditorTextField } })
export default class OrderSystemCoupon extends Vue {
  @Prop()
  session: TableSession;

  couponCode: string;

  userCoupons: (FindPopRawType<["coupon"], "userCoupons"> & { selected?: boolean })[] = [];

  get selectedCoupon() {
    return this.userCoupons.filter(c => this.session.coupons.some(d => d.coupon && checkID(d.coupon._id, c._id))) ?? [];
  }

  get availableCoupon() {
    return (
      this.userCoupons.filter(c => this.session.coupons.every(d => !d.coupon || !checkID(d.coupon._id, c._id))) ?? []
    );
  }

  async addCoupon(coupon: CouponType) {
    const result = await this.session.tryApplyCoupon(coupon);
    if (result instanceof CouponItem) {
      if (result.error) {
        await result.tryFix();
      }
      if (result.error) {
        this.$store.commit("SET_ERROR", this.$td(result.error));
        result.remove();
      } else if (this.session.type === "dineIn") {
        try {
          this.session.updateCachedDetails();
          await this.session.save();
        } catch (e) {
          console.warn(e);
          this.$store.commit("SET_ERROR", this.$t("couponErrors.cannotApply"));
          result.remove();
        }
      }
      this.session.updateCoupons();
      await this.session.atomic({
        discounts: this.session.discounts,
        ...this.session.cachedPriceDetails,
      });
      this.session.syncOrder();
    } else {
      this.$store.commit("SET_ERROR", this.$td(result));
    }
  }

  async removeCoupon(coupon: CouponType) {
    const current = this.session.coupons.find(c => checkID(c.coupon, coupon));
    if (current && current?.coupon?.coupon?.freeProducts?.length) {
      if (this.session.products.find(p => checkID(p.fromCoupon, current._id))) {
        this.$store.commit("SET_ERROR", this.$t("couponErrors.cannotRemove"));
        return;
      }
    }
    current.remove();
    this.session.updateCoupons();
    await this.session.atomic({
      discounts: this.session.discounts,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  async beforeMount() {
    this.loadCoupons();
  }

  @Watch("session.item.user")
  async onUserChanged() {
    console.log("triggered");
    this.loadCoupons();
  }

  async loadCoupons() {
    const userCoupons = await this.$feathers.service("userCoupons").find({
      query: {
        user: String(this.session.item.user),
        shops: { $in: [this.session.shopId] },
        status: "valid",
        $or: [{ startDate: { $lt: Date.now() } }, { startDate: null }],
        endDate: { $gt: Date.now() },
        $populate: ["coupon"],
        $paginate: false,
      },
      paginate: false,
    });

    this.userCoupons = userCoupons.filter(c => c.coupon.tag !== "ticket");
  }

  async openQrCodeScanner() {
    const code = await this.$openDialog(
      import("@feathers-client/components-internal/QrCodeStreamDialog.vue"),
      {
        title: this.$t("pages.tableSessions.coupons.scanCoupon"),
      },
      {
        maxWidth: "min(500px,80vw)",
        contentClass: "h-70vh",
      },
    );
    if (!code) return;
    this.$emit("decode", code);
  }
}
