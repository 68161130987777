
import { checkID } from "@feathers-client";
import { Component, Prop, Vue, Watch, mixins, getID } from "@feathers-client";
import _ from "lodash";
import { StockLevel, getProductStockLevel } from "~/common/table/util";
import { ProductType } from "~/plugins/shop";
import { TableSession } from "~/plugins/table/session";

@Component
export default class ProductNumPad extends Vue {
  codeFilter: string = null;
  code = "";

  @Prop()
  session: TableSession;

  getProductPrice(product: ProductType) {
    if (product.discountMapping?.length) {
      const p = product.discountMapping.find(it => checkID(it.shop, this.$store.state.user.shop));
      if (p) {
        return (p.price ?? product.price) * (p.discount ?? 1);
      }
    }
    return product.price;
  }

  get products() {
    if (this.code || this.codeFilter) {
      const list = this.codeFilter
        ? this.productCodeMap[this.codeFilter === "_noprefix" ? "" : this.codeFilter]
        : this.fullList;

      const allList = list.filter(it => it[1].includes(this.code));
      const exactList = allList.filter(it => +it[1] === +this.code);
      const notExactList = allList.filter(it => +it[1] !== +this.code);

      return exactList.concat(notExactList).slice(0, 10);
    }
    return [];
  }

  get fullList() {
    return Object.values(this.productCodeMap).flat();
  }

  get productCodeMap() {
    return _.groupBy(
      Array.from(
        this.$shop.products.map(
          it =>
            [
              (it.code?.match?.(/[A-Z]+/gi)?.[0] || "").slice(0, 3).toUpperCase(),
              it.code?.match?.(/\d+/gi)?.[0] || "",
              it,
            ] as const,
        ),
      ),
      it => it[0],
    );
  }

  get productPrefix() {
    return Object.keys(this.productCodeMap).map(it => ({
      _id: it || "_noprefix",
      name: it || "-",
    }));
  }

  lastPress: number = 0;

  isNotSelling(product: ProductType) {
    const level = getProductStockLevel(product, {
      session: this.session,
      productsDict: this.$shop.productDict,
      optionsDict: this.$shop.productOptionDict,
    });
    return level === StockLevel.OutOfStock || level === StockLevel.Disabled;
  }

  async shortpress(product: ProductType) {
    if (Date.now() - this.lastPress < 250) return;
    this.lastPress = Date.now();
    await Vue.nextTick();
    if (this.isNotSelling(product)) {
      const stock = product.stock;
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title:
            stock?.mode === "notSelling"
              ? this.$t("tableView.confirmNotSelling", { name: this.$td(product.name) })
              : this.$t("tableView.confirmOutOfStock", { name: this.$td(product.name) }),
        },
        {
          maxWidth: "400px",
        },
      );
      if (!c) return;
    }
    this.codeFilter = "";
    this.code = "";
    this.addToCart(product);
  }

  async longpress(product: ProductType) {
    if (Date.now() - this.lastPress < 250) return;
    this.lastPress = Date.now();
    await Vue.nextTick();
    this.codeFilter = "";
    this.code = "";
    this.addToCart(product, true);
  }

  confirm() {
    if (this.products.length) {
      this.shortpress(this.products[0][2]);
    }
  }

  async addToCart(item: ProductType, showOptions = false) {
    const cart = this.session.addToCartFromProduct(item, {
      swap: true,
      mustInsert: !this.$shop.localOptions.autoCombineSameProduct,
    });
    if (item.options?.find?.(it => this.$shop.productOptionDict[getID(it)]?.required)) {
      showOptions = true;
    }
    if (showOptions) {
      await new Promise(resolve => setTimeout(resolve, 200));
      this.session.openProductMenu({
        cart,
        showOptions,
      });
    }
  }
}
