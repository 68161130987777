
import { getVersion } from '../nativeIntegrations'
import { ns } from '../messageQueue'

export async function start() {
    return ns('scanner').call('start');
}

export async function stop() {
    return ns('scanner').call('stop');
}

export async function on(cb: (result: string) => void) {
    return ns('scanner').on('scan', cb);
}

export async function off(handle : string) {
    return ns('scanner').off(handle);
}

export function supported() {
    if(!getVersion()) return Promise.resolve(false);
    return Promise.race([
        ns('scanner').call('supported'),
        new Promise(resolve => setTimeout(() => resolve(false), 1000)),
    ]).catch(e => false);
}


