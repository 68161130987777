
import { Component, Prop, Vue } from "@feathers-client";
import { cond, over } from "lodash";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeBlock,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  compile,
  compileLabel,
} from "pos-printer-template";
import { parseFont } from "pos-printer/labelSequence/base";

@Component
export default class PrinterEditorBlock extends Vue {
  @Prop() template!: PrinterTemplateNodeBlock;

  @Prop() context: any;

  @Prop(Boolean)
  inBlock: boolean;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  get position() {
    if (this.template.props["pos"]) {
      const size = this.template.props["pos"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [
        +(this.template.props["x"]?.eval?.(this.context) || "0"),
        +(this.template.props["y"]?.eval?.(this.context) || "0"),
      ];
    }
  }

  get size() {
    if (this.template.props["size"]) {
      const size = this.template.props["size"].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [
        +(this.template.props["w"]?.eval?.(this.context) || "0"),
        +(this.template.props["h"]?.eval?.(this.context) || "0"),
      ];
    }
  }
}
