
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeFeed, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorFeed extends Vue {
  @Prop() template!: PrinterTemplateNodeFeed;

  @Prop() context: any;

  get n() {
    return this.template.props["n"]?.eval?.(this.context);
  }

  get height() {
    return `${(this.n || 1) * 5 + 20}px`;
  }
}
