
import { Component, Vue, FindType, Prop, PropSync, checkID } from "@feathers-client";

import {
  IngredientPlanEntry,
  IngredientItem,
  fromDb,
  sumIngredients,
  multiplyIngredients,
  priceFromDb,
  priceToDb,
} from "@common/table/ingredients";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorDatePicker,
    EditorTextField,
  },
})
export default class SemiProduct extends Vue {
  @Prop()
  item: IngredientItem;

  @Prop()
  stock: any;

  @Prop(Boolean)
  readonly: boolean;

  @Prop({ type: String })
  type: "semiProduct" | "ingredient" | "transfer" | "adjust";

  @Prop(Boolean)
  dense: boolean;

  @Prop()
  unitQuantityType: "single" | "kilo" | "unit";
  @Prop()
  priceType: "unit" | "sum";

  showDetails = false;

  get info() {
    return this.$shop.ingredientDict[this.item._id];
  }

  @PropSync("planEntry")
  planEntrySync: Partial<IngredientPlanEntry>;

  fromDb = fromDb;

  get statusClass() {
    return this.item.currentStock < (this.item.lowStockAlert || 0) ? "text-red300" : "";
  }

  get stepQuantity() {
    switch (this.unitQuantityType) {
      case "unit":
        return this.info.ingredientRatio || this.info.priceQuantity || 1;
      case "single":
        return 1000;
      case "kilo":
        return 1000 * 1000;
    }
  }

  get showPlaceholder() {
    return this.type === "adjust" && (!this.planEntrySync || this.planEntrySync?.amount === null);
  }

  get showPlaceholderCost() {
    return this.type === "adjust" && (!this.planEntrySync || this.planEntrySync?.cost === null);
  }

  get placeholderAmount() {
    return (this.item?.currentStock ?? 0) / this.stepQuantity;
  }

  get amount() {
    if (this.showPlaceholder) {
      return null;
    }
    return (this.planEntrySync?.amount ?? 0) / this.stepQuantity;
  }

  get defaultPlan() {
    return {
      ...(this.type === "adjust"
        ? {
            cost: null,
            supplier: null,
            expiry: null,
          }
        : {
            cost: 0,
          }),
      ...this.planEntrySync,
      amount: null,
    };
  }

  set amount(v: number) {
    this.planEntrySync =
      v || (this.type === "adjust" && v !== null)
        ? {
            ...this.defaultPlan,
            amount: this.type === "adjust" && v === null ? null : v * this.stepQuantity,
          }
        : null;
  }

  get amountDb() {
    return this.planEntrySync?.amount ?? 0;
  }

  get placeholderCost() {
    return this.priceType === "unit" ? this.placeholderUnit : this.placeholderSumCost;
  }

  get editingCost() {
    return this.priceType === "unit" ? this.cost : this.sumCost;
  }

  set editingCost(v) {
    if (this.priceType === "unit") {
      this.cost = v;
    } else {
      this.sumCost = v;
    }
  }

  get cost() {
    if (this.showPlaceholderCost) return null;
    return priceFromDb(this.planEntrySync?.cost ?? 0, this.info?.priceQuantity);
  }

  set cost(v: number) {
    this.planEntrySync = {
      ...(this.planEntrySync || this.defaultPlan),
      cost: priceToDb(v, this.info?.priceQuantity),
    };
  }

  get sumCost() {
    if (this.showPlaceholderCost) return null;
    const currentAmount = this.showPlaceholder ? this.item.currentStock : this.planEntrySync?.amount ?? 0;
    return this.cost * (currentAmount / (this.info?.priceQuantity ?? 1));
  }

  set sumCost(v: number) {
    const currentAmount = this.showPlaceholder ? this.item.currentStock : this.planEntrySync?.amount ?? 0;
    this.cost = v / (currentAmount / (this.info?.priceQuantity ?? 1));
  }

  get placeholderUnit() {
    const cost = this.stock?.costAmount ? this.stock.costSum / this.stock.costAmount : undefined;
    return priceFromDb(cost, this.info?.priceQuantity);
  }

  get placeholderSumCost() {
    return this.placeholderUnit * ((this.planEntrySync?.amount ?? 0) / (this.info?.priceQuantity ?? 1));
  }

  get expiry() {
    return this.planEntrySync?.expiry ?? this.stock?.expiry ?? null;
  }

  set expiry(v: Date) {
    this.planEntrySync = {
      ...(this.planEntrySync || this.defaultPlan),
      expiry: v,
    };
  }

  get supplier() {
    return this.planEntrySync?.supplier ?? null;
  }

  set supplier(v: string) {
    this.planEntrySync = {
      ...(this.planEntrySync || this.defaultPlan),
      supplier: v,
    };
  }

  get useIngredients() {
    if (!this.info || !this.amount) return [];

    return multiplyIngredients(sumIngredients([], this.info.ingredients), this.amountDb / this.info.ingredientRatio);
  }

  previewImage(info: any) {
    this.$openDialog(
      import("~/components/dialogs/ImageDialog.vue"),
      {
        src: this.$image(info.image),
        title: this.$td(info.name),
      },
      {
        maxWidth: "80%",
      },
    );
  }
}
