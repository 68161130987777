import _ from "lodash";
import { LabelSequenceBase } from "./base";
import { BitmapOpts } from "../common";
import { convertImage } from "../printSequence/escpos/image";

export class LabelSequence extends LabelSequenceBase {
  get qrCodeMode() {
    return this.printer?.conf?.opts?.qrCodeMode ?? "gprinter";
  }

  initInner() {
    super.initInner();
    if (this.codePage === "utf8") {
      this.cmd(`CODEPAGE UTF-8`);
    }
  }

  getText(text: string): Buffer | number[] {
    return this.printer.iconv.encode(text, this.codePage);
  }

  decodeText(buf: Buffer) {
    return this.printer.iconv.decode(buf, this.codePage);
  }

  cmd(line: string) {
    this.raw(this.printer.iconv.encode(line + "\r\n", this.codePage));
    return this;
  }

  size(w: number, h: number) {
    this._curWidth = w;
    this._curHeight = h;
    this.cmd(`SIZE ${w} mm,${h} mm`);
    return this;
  }

  gap(w: number, h: number) {
    this.cmd(`GAP ${w} mm, ${h} mm`);
    return this;
  }

  feed(n: number) {
    this.cmd("FORMFEED");
    return this;
  }

  rect(x: number, y: number, w: number, h: number) {
    this.cmd(`BAR ${x},${y},${w},${h}`);
    return this;
  }

  async textRaw(x: number, y: number, text: string, font = "1", sizeW = 1, sizeH = 1, r = 0) {
    this.cmd(`TEXT ${x},${y},"${font}",${this._r},${sizeW},${sizeH},"${text.replace(/\"/g, '\\["]')}"`);
    return this;
  }

  async vtext(text: string, x: number, y: number, font = "1", size = 1) {
    this.cmd(`TEXT ${x},${y},"${font}",0,${size},${size},${text}`);
    return this;
  }

  qrcode(text: string, x: number, y: number, ecc = "H", width = 4) {
    if (this.qrCodeMode === "xprinter") {
      this.cmd(`QRCODE ${x},${y},${ecc},${width},A,${this._r},"${text.replace(/"/g, '\\["]')}"`);
    } else {
      this.cmd(`QRCODE ${x},${y},${ecc},${width},A,${this._r},2,7,"${text.replace(/"/g, '\\["]')}"`);
    }
    return this;
  }

  barcode(text: string, x: number, y: number, height = 10, type = "ean13", width = 2) {
    this.cmd(`BARCODE ${x},${y},"${type.toUpperCase()}",${height},1,0,1,2,"${text}"`);
    return this;
  }

  direction(n: number) {
    this.cmd(`DIRECTION ${n},0`);
    return this;
  }

  reset() {
    super.reset();
    this.raw(Buffer.from("\r\n"));
    this.cmd("DIRECTION 0,0");
    this.cmd("REFERENCE 0,0");
    this.cmd("SET TEAR ON");
    this.cmd("CLS");
    this.cmd("CLS");
    this.cmd("CLS");
    this.cmd("CLS");
    return this;
  }

  finish(): void {
    super.finish();
    this.cmd("CLS");
    this.cmd("CLS");
    this.cmd("CLS");
    this.cmd("CLS");
    // this.cmd("EOJ");
    // <ESC>!?
    // this.raw(Buffer.from([0x1b, 0x21, 0x3f]));
    this.raw(Buffer.from("\r\n"));
  }

  print(n: number) {
    this.cmd(`PRINT ${n || 1},1`);
    return this;
  }

  counter(id: number, step: number, start: string) {
    this.cmd(`SET COUNTER @${id || 1} ${step || 1}`);
    this.cmd(`@${id || 1}="${start || 1}"`);
    return this;
  }

  async printImage(
    x: number,
    y: number,
    url: string,
    width: number,
    mode: "dither" | "color" | "colorAlpha" | "gray" | "mono" | "alpha" = "dither",
  ) {
    width = Math.ceil(width / 8) * 8;
    const img = await convertImage(url, width, mode);

    this.raw(Buffer.from(`BITMAP ${x},${y},${img.width / 8},${img.height},0,`));

    const b = img.width / 8;
    for (let i = 0; i < img.height; i++) {
      const buf = Buffer.alloc(b);
      let ofs = width * i * 4;

      for (let j = 0; j < b; j++) {
        let v = 0;
        for (let k = 0; k < 8; k++) {
          const mv = 128 >> k;
          v |= img.buffer[ofs] ? mv : 0;
          ofs += 4;
        }
        buf[j] = v;
      }
      this.raw(buf);
    }

    this.raw(Buffer.from("\n"));
    return this;
  }

  async printImageTagInner(x: number, y: number, pts: BitmapOpts, url: string, width: number) {
    this.raw(Buffer.from(`PUTBMP ${x},${y},"${pts.name}.BMP"`));
    return this;
  }

  async printImageData(x: number, y: number, buffer: Uint8ClampedArray, width: number, height: number) {
    const originalWidth = width;
    width = Math.ceil(width / 8) * 8;
    this.raw(Buffer.from(`BITMAP ${x},${y},${width / 8},${height},0,`));

    const b = width / 8;
    for (let i = 0; i < height; i++) {
      const buf = Buffer.alloc(b);
      let ofs = originalWidth * i * 4;
      let eofs = ofs + originalWidth * 4;

      for (let j = 0; j < b; j++) {
        let v = 0;
        for (let k = 0; k < 8; k++) {
          const mv = 128 >> k;
          const color = ofs >= eofs ? 255 : buffer[ofs];
          v |= color ? mv : 0;
          ofs += 4;
        }
        buf[j] = v;
      }
      this.raw(buf);
    }

    this.raw(Buffer.from("\n"));
    return this;
  }
}
