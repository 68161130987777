
import { Component, Prop, Vue, Watch, FindType, Ref } from "@feathers-client";
import { OrderGroup } from "../../plugins/table/kds";
import _ from "lodash";
import { playAudioFile } from "@feathers-client/qrcode-scanner/beep";

const audioFile = require("~/assets/audio/kds.mp4");

@Component
export default class PendingOrderList extends Vue {
  @Ref()
  dataList: any;

  orderList: boolean = false;

  selectedOrderGroup: OrderGroup = null;

  beepEvent: NodeJS.Timeout = null;

  async mounted() {
    await this.$shop.loadShopTask;
    if (
      this.$shop.shopData?.enableBeepForIncomingOrder &&
      this.$shop.shopData?.enableManualConfirmOrder &&
      this.$shop.shopData?.beepUntilAllOrderConfirmed
    )
      this.beepEvent = setInterval(async () => {
        this.beepForIncomingOrder();
      }, 3000);
  }

  beforeDestroy() {
    if (this.beepEvent) {
      clearInterval(this.beepEvent);
      this.beepEvent = null;
    }
  }

  async beepForIncomingOrder() {
    if (this.sessionPendingOrders.length && !this.$shop.localOptions.muteSoundForIncomingOrder) {
      await playAudioFile(audioFile, 100);
    }
  }

  get sessions() {
    return [...this.$tableManager.sessions, ...this.$tableManager.takeAways, ...this.$tableManager.dineInNoTables];
  }

  get sessionPendingOrders(): OrderGroup[] {
    return this.sessions
      .flatMap(it => it.orderOrderPendingGroups)
      .sort((a, b) => this.$moment(b.date).valueOf() - this.$moment(a.date).valueOf());
  }

  totalPrice(order: OrderGroup) {
    if (order.session.item?.manualConfirm) {
      return order.session.totalPrice;
    } else {
      return _.sumBy(order.items, it => it.price * it.quantity);
    }
  }

  openOrder(item) {
    this.orderList = false;
    this.selectedOrderGroup = null;
    this.$emit("openOrder", item);
  }

  async sendOrder(item: Partial<OrderGroup>) {
    try {
      if (!item) item = this.selectedOrderGroup;
      await item.session?.sendOrder(item);
      this.selectedOrderGroup = null;
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async declineOrder(item: Partial<OrderGroup>) {
    try {
      if (!item) item = this.selectedOrderGroup;
      item.session?.declineOrder(item);
      this.selectedOrderGroup = null;
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
