
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import { CouponItem } from "@common/table/coupon";
import { Surcharge } from "@adyen/api-library/lib/src/typings/management/surcharge";

@Component
export default class CartPriceDetails extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  items: any[];

  @Prop(Boolean)
  hideDetails: boolean;

  @Prop(Boolean)
  posScreen: boolean;

  paymentTotal(payment: TableSession["payments"][0]) {
    return payment.amount + payment.surcharge;
  }

  get totalQuantity() {
    return this.session?.totalQuantity ?? _.sumBy(this.items, p => p.quantity);
  }

  get gifts() {
    return (this.session.coupons || []).filter(it => it.discountSource === "gift");
  }

  get serviceCharges() {
    return this.session.surcharges;
  }

  cancelGift(gift: CouponItem) {
    gift.remove();
    this.flushUpdates();
  }

  flushUpdates() {
    this.session.updateCoupons();
    if (!this.session.item._id) {
      return;
    }
    this.session.delaySave({
      discounts: this.session.discounts,
      ...this.session.cachedPriceDetails,
    });
    this.session.syncOrder();
  }

  handleResize() {
    const elem = this.$refs.orderDetails as Element;
    elem.scrollTop = elem.scrollHeight;
  }
}
