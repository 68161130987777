var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-text-field',{attrs:{"label":_vm.$t('basic.keyword'),"celarable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('data-table',{attrs:{"data":{
            path: 'products',
            filter: {
                shops: _vm.shop._id,
            },
            populate: ['category']
        },"iterator":"","content-tag":"div","paginate":1000,"hide-tool-bar":"","flat":"","localSearch":_vm.keyword,"localSearchFilter":_vm.localSearch,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"itemContainer",fn:function({items}){return [_c('v-list',[_vm._l((items),function(item,idx){return [(item.category && (!items[idx - 1] || (items[idx - 1].category && items[idx - 1].category._id) !== item.category._id))?[_c('div',{key:'c' + item._id,staticClass:"headline my-4"},[_vm._v(_vm._s(_vm.$td(item.category.name)))])]:_vm._e(),_c('product-cart-item',{key:item._id,attrs:{"product":item,"cart":_vm.cart,"shop":_vm.shop,"allow-edit":""}})]})],2)]}}])})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }