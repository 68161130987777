import { Component } from "@feathers-client/index";
import { getOptions } from "@feathers-client/util";
import Vue from "vue";
import { BluetoothConf } from "./devices/bluetooth";
import { getDevices, UsbDevice, getConfig, UsbDeviceInfo, connect, supported as usbSupported, init } from "./ports/usb";
import { supported as serialSupported, getDevices as serialGetDevices, connect as serialConnect } from "./ports/serial";
import {
  supported as scannerSupported,
  start as scannerStart,
  stop as scannerStop,
  on as scannerOn,
} from "./ports/scanner";
import {
  supported as keyboardSupported,
  on as keyboardOn,
  suppress as keyboardSuppress,
  start as keyboardStart,
  KeyPressModifier,
} from "./ports/keyboard";
import SerialDevice from "./ports/serial";
import type iconv from "iconv-lite";
import { BluetoothScanner } from "./scanners/bluetooth";
import { install as initBluetooth } from "./utils/bluetoothPolyfill";
import {
  HidCollection,
  HidKeyboardReport,
  HidReportInfo,
  parse,
  parseHid,
  UsbDescriptorHID,
} from "./utils/usbDescriptors";
import _ from "lodash"
import { LangType } from "@feathers-client/i18n";

const keyMappings: [number, string, string, string, string][] = [
  [
    4,
    "abcdefghijklmnopqrstuvwxyz1234567890\n",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()\n",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890\n",
    "abcdefghijklmnopqrstuvwxyz!@#$%^&*()\n",
  ],
  [43, "\t -=[]\\", "\t _+{}|", "\t -=[]\\", "\t _+{}|"],
  [51, ";'`,./", ':"~<>?', ";'`,./", ':"~<>?'],
  [84, "/*-+\n1234567890.\\\0\0=", "/*-+\n1234567890.|\0\0=", "/*-+\n1234567890.\\\0\0=", "/*-+\n1234567890.|\0\0="],
];

const keyCodeMapping = {
  Digit1: "1",
  Digit2: "2",
  Digit3: "3",
  Digit4: "4",
  Digit5: "5",
  Digit6: "6",
  Digit7: "7",
  Digit8: "8",
  Digit9: "9",
  Digit0: "0",
  Minus: "-",
  Equal: "=",
  Tab: "\t",
  KeyQ: "q",
  KeyW: "w",
  KeyE: "e",
  KeyR: "r",
  KeyT: "t",
  KeyY: "y",
  KeyU: "u",
  KeyI: "i",
  KeyO: "o",
  KeyP: "p",
  BracketLeft: "[",
  BracketRight: "]",
  Enter: "\n",
  KeyA: "a",
  KeyS: "s",
  KeyD: "d",
  KeyF: "f",
  KeyG: "g",
  KeyH: "h",
  KeyJ: "j",
  KeyK: "k",
  KeyL: "l",
  Semicolon: ";",
  Quote: '"',
  Backquote: "`",
  Backslash: "\\",
  KeyZ: "z",
  KeyX: "x",
  KeyC: "c",
  KeyV: "v",
  KeyB: "b",
  KeyN: "n",
  KeyM: "m",
  Comma: ",",
  Period: ".",
  Slash: "/",
  NumpadMultiply: "*",
  Space: " ",
  Numpad7: "7",
  Numpad8: "8",
  Numpad9: "9",
  NumpadSubtract: "-",
  Numpad4: "4",
  Numpad5: "5",
  Numpad6: "6",
  NumpadAdd: "+",
  Numpad1: "1",
  Numpad2: "2",
  Numpad3: "3",
  Numpad0: "0",
  NumpadDecimal: ".",
};

const keyCodeMappingShift = {
  Digit1: "!",
  Digit2: "@",
  Digit3: "#",
  Digit4: "$",
  Digit5: "%",
  Digit6: "^",
  Digit7: "&",
  Digit8: "*",
  Digit9: "(",
  Digit0: ")",
  Minus: "_",
  Equal: "+",
  Tab: "\t",
  KeyQ: "Q",
  KeyW: "W",
  KeyE: "E",
  KeyR: "R",
  KeyT: "T",
  KeyY: "Y",
  KeyU: "U",
  KeyI: "I",
  KeyO: "O",
  KeyP: "P",
  BracketLeft: "{",
  BracketRight: "}",
  Enter: "\n",
  KeyA: "A",
  KeyS: "S",
  KeyD: "D",
  KeyF: "F",
  KeyG: "G",
  KeyH: "H",
  KeyJ: "J",
  KeyK: "K",
  KeyL: "L",
  Semicolon: ":",
  Quote: '"',
  Backquote: "~",
  Backslash: "|",
  KeyZ: "Z",
  KeyX: "X",
  KeyC: "C",
  KeyV: "V",
  KeyB: "B",
  KeyN: "N",
  KeyM: "M",
  Comma: "<",
  Period: ">",
  Slash: "?",
  NumpadMultiply: "*",
  Space: " ",
  Numpad7: "7",
  Numpad8: "8",
  Numpad9: "9",
  NumpadSubtract: "_",
  Numpad4: "4",
  Numpad5: "5",
  Numpad6: "6",
  NumpadAdd: "+",
  Numpad1: "1",
  Numpad2: "2",
  Numpad3: "3",
  Numpad0: "0",
  NumpadDecimal: ".",
};

interface ScannerServerOptions{
  storage?: Storage;
}

export interface ScannerEvent {
  code: string;
  type: "keyboard" | "usb" | "serial" | "bluetooth" | "native" | "camera"
  usbDevice?: UsbDeviceInfo;
  serialDevice?: SerialDevice;
  bluetoothDevice?: BluetoothScanner;
  imeActive?: boolean;
  handled?: boolean;
  delay?: number;

  fallbackMessage?: LangType;
}

export type ScannerHandler = (event: ScannerEvent) => Promise<void> | void;
export interface ScannerHandlerItem {
  handler: ScannerHandler;
  priority: number;
}

@Component
export default class Scanner extends Vue {
  inited = false;

  init(opts: ScannerServerOptions) {
    if(this.inited) return;
    this.inited = true;
    init(this.$root);
    this.$root.$on("deviceAttach", d => this.handleDevice(d));
    this.initNativeScanner();
    this.initNativeKeyboard();
  }

  run(opts: ScannerServerOptions = {}) {
    this.init(opts);
    this.setStorage(opts.storage);
  }

  storage: Storage;
  async setStorage(storage: Storage = localStorage) {
    if(storage === this.storage) return;
    this.storage = storage;
    try {
      this.usbVendors = JSON.parse(this.storage["scanner_usbVendors"] || "[]");
    } catch (e) {
      console.warn(e);
    }

    this.initUSB();
    this.initBluetooth();
  }

  created() {
    window.addEventListener("keypress", this.onKeyPress);
  }

  beforeDestroy() {
    window.removeEventListener("keypress", this.onKeyPress);
  }

  handlers: ScannerHandlerItem[] = [];

  registerHandler(handler: ScannerHandler, priority = 0) {
    const insertIdx = _.sortedIndexBy(this.handlers, {handler, priority}, it => -it.priority);
    this.handlers.splice(insertIdx, 0, {handler, priority});
  }

  unregisterHandler(handler: ScannerHandler) {
    const idx = this.handlers.findIndex(it => it.handler === handler);
    if(idx !== -1) this.handlers.splice(idx, 1);
  }

  codes: string[] = [];
  imeActive = false;
  lastCode = 0;
  nativeScannerStarted = false;
  paused = false;
  supressKeypress = false;
  iconv: typeof iconv;

  async ensureICONV() {
    this.iconv = await import("iconv-lite");
  }

  onKeyPress(e: KeyboardEvent) {
    if (this.supressKeypress) return;
    const elem = e.target as HTMLElement;
    if (elem.tagName === "TEXTAREA" || elem.tagName === "INPUT") return;
    if (e.altKey || e.ctrlKey) return;
    if (!e.key || (e.key.length > 1 && e.which !== 13)) return;
    if (Date.now() - this.lastCode > 500) {
      this.codes = [];
    }
    if (e.code === "Enter") {
      if (this.codes.length === 0) return;
      const code = this.codes.join("");
      const imeActive = this.imeActive;
      this.imeActive = false;
      this.codes = [];

      this.onResult({
        code,
        type: "keyboard",
        imeActive,
      });
    } else {
      if (keyCodeMapping[e.code]) {
        let chr = e.shiftKey ? keyCodeMappingShift[e.code] : keyCodeMapping[e.code];
        const capsLock = e.getModifierState?.("CapsLock") ?? false;

        if (capsLock) {
          if (chr.match(/[a-z]/)) chr = chr.toUpperCase();
          else if (chr.match(/[A-Z]/)) chr = chr.toLowerCase();
        }

        if (e.key !== chr) {
          this.imeActive = true;
        }
        this.codes.push(chr);
      } else {
        this.codes.push(e.key);
      }
      this.lastCode = Date.now();
    }
  }

  async initUSB() {
    if (!(await usbSupported())) {
      return;
    }

    await this.ensureICONV();

    await new Promise((resolve) => setTimeout(resolve, 3000));

    for (let device of (await getDevices()).devices) {
      this.handleDevice(device);
    }
  }

  devices: UsbDevice[] = [];
  serialDevices: SerialDevice[] = [];
  usbVendors: number[] = [];
  usbDefVensors = [26214, 1317, 3118, 44176, 1507];

  async handleDevice(device: UsbDeviceInfo) {
    console.log("Device attached", device.name, device.vendorId, device.productId);
    if (device.vendorId === 7504) return;
    if (device.vendorId === 8746) return; //iMin Kiosk Touch Screen
    if (device.vendorId === 0x27C0) return; // yoov touch screen
    if (device.vendorId === 0x0eef) return; // yoov (egalaxy touch) touch screen
    if (device.vendorId === 0x27c6) return; // iMin Touch screen

    const currentDevice = this.devices.find(it => it.name === device.name);
    if (currentDevice) {
      console.log("Skipping same device", device.name, device.vendorId, device.productId);
      return;
    }

    try {
      const config = await getConfig(device.name);
      const cdcConfig =
        (this.usbDefVensors.indexOf(+device.vendorId) !== -1 || this.usbVendors.indexOf(+device.vendorId) !== -1) &&
        config.find(c => !!c.interfaces.find(iface => iface.iclass === 10 || iface.iclass === 255));
      const hidConfig = config.find(c => {
        const isVendor = this.usbVendors.indexOf(+device.vendorId) !== -1;
        const hasKey = !!c.interfaces.find(
          iface =>
            iface.iclass === 3 &&
            (iface.subclass === 0 || iface.subclass === 1) &&
            (iface.protocol === 0 || iface.protocol === 1),
        );
        const hasMouse = !!c.interfaces.find(
          iface => iface.iclass === 3 && (iface.subclass === 0 || iface.subclass === 1) && iface.protocol === 2,
        );

        if (isVendor && hasKey) return true;
        else return hasKey && !hasMouse;
      });

      let ifaceIdx: number;
      let epIdx: number;

      if (cdcConfig) {
        ifaceIdx = cdcConfig.interfaces.findIndex(
          iface => (iface.iclass === 10 && iface.subclass === 0 && iface.protocol === 0) || iface.iclass === 255,
        );
        if (ifaceIdx === -1) return;
        const iface = cdcConfig.interfaces[ifaceIdx];
        const eps = iface.endpoints.slice();
        eps.sort((a, b) => a.attributes - b.attributes); // prefer bulk more than iso
        const ep = eps.find(ep => ep.direction === 128);
        epIdx = iface.endpoints.indexOf(ep);
        console.log("Cdc device", device.name, ifaceIdx, epIdx);
      } else if (hidConfig) {
        ifaceIdx = hidConfig.interfaces.findIndex(
          iface =>
            iface.iclass === 3 &&
            (iface.subclass === 0 || iface.subclass === 1) &&
            (iface.protocol === 0 || iface.protocol === 1),
        );
        if (ifaceIdx === -1) return;
        epIdx = hidConfig.interfaces[ifaceIdx].endpoints.findIndex(ep => ep.direction === 128);
        console.log("Hid device", device.name, device.vendorId, device.productId, ifaceIdx, epIdx);
        if(epIdx === -1) return;
      } else return;

      let hidCollection: HidReportInfo;

      let lastData = 0;
      let capsLock = false;
      const buf: string[] = [];
      const altCodes: string[] = [];

      const handleBuf = (da: Buffer) => {
        if (cdcConfig) {
          if (Date.now() - lastData > 500) {
            buf.splice(0, buf.length);
          }
          lastData = Date.now();
          for (let i = 0; i < da.length; i++) {
            const c = da[i];
            if (!c) continue;
            if (c < 128) {
              pushChar(String.fromCharCode(c));
            } else {
              const buf = Buffer.alloc(2);
              buf[0] = c;
              buf[1] = da[++i];
              pushChar(this.iconv.decode(buf, "gbk"));
            }
          }
        } else if (hidCollection) {
          const reports = hidCollection.parse(da);
          console.log(reports);
          const codes: number[] = [];
          for (let report of reports) {
            if (!(report instanceof HidKeyboardReport)) continue;

            for (let keycode of report.keycodes) {
              if (keycode) codes.push(keycode);
            }
          }

          if (!codes.length) return;

          if (Date.now() - lastData > 500) {
            buf.splice(0, buf.length);
            altCodes.splice(0, altCodes.length);
          }
          lastData = Date.now();

          const isShift = codes.find(it => it === 0xe1 || it === 0xe5);
          const isAlt = codes.find(it => it === 0xe2 || it === 0xe6);
          console.log(codes, isShift, isAlt, capsLock);

          for (let b of codes) {
            if (b === 0x39) {
              capsLock = !capsLock;
            } else if (isAlt) {
              if (b) {
                if (b >= 0x59 && b <= 0x61) {
                  altCodes.push(`${b - 0x59 + 1}`);
                } else if (b === 0x62) {
                  altCodes.push("0");
                }
              }
            } else {
              for (let j = 0; j < keyMappings.length; j++) {
                const map = keyMappings[j];
                if (b >= map[0] && b < map[0] + map[1].length) {
                  const ofs = b - map[0];
                  const c = capsLock ? (isShift ? map[4][ofs] : map[3][ofs]) : isShift ? map[2][ofs] : map[1][ofs];
                  pushChar(c);
                }
              }
            }
          }
        }
      };

      const flushAlt = () => {
        if (altCodes.length) {
          const code = +altCodes.join("");
          altCodes.splice(0, altCodes.length);
          if (!isNaN(code)) {
            if (code < 256) {
              pushChar(String.fromCharCode(code));
            } else {
              const buf = Buffer.alloc(2);
              buf.writeUInt16BE(code, 0);
              pushChar(this.iconv.decode(buf, "gbk"));
            }
          }
        }
      };

      const pushChar = (c: string) => {
        if (c === "\r" || c === "\n") {
          if (!buf.length) return;
          const code = buf.join("");
          this.onResult({
            code,
            type: "usb",
            usbDevice: device,
          });
          buf.splice(0, buf.length);
          altCodes.splice(0, altCodes.length);
        } else {
          buf.push(c);
        }
      };

      const isMac = navigator.appVersion.indexOf("Mac") != -1 && (await serialSupported());
      if (isMac) {
        if (hidConfig) return;
        if (cdcConfig) {
          const sdl = await serialGetDevices();
          const sd = sdl.devices?.find?.(
            it => parseInt(it.productId, 16) === device.productId && parseInt(it.vendorId, 16) === device.vendorId,
          );
          if (!sd) return;
          const d = await serialConnect(sd.path);
          d.on("data", da => {
            handleBuf(da instanceof Buffer ? da : Buffer.from(da.data));
          });
          d.on("close", () => {
            const idx = this.serialDevices.indexOf(d);
            idx !== -1 && this.serialDevices.splice(idx, 1);
            console.log("Device stopped", device.name, device.vendorId, device.productId);
          });
          this.serialDevices.push(d);
          return;
        }
      }

      const d = await connect(device.name, true, "Scanner");

      d.on("data", d => {
        // console.log(Buffer.from(d, 'base64').toString('hex'));
        const da = Buffer.from(d, "base64");
        if (hidCollection) {
          if (d === "AAAAAAAAAAA=") {
            flushAlt();
            return;
          }
        }
        handleBuf(da);
      });
      d.on("stop", () => {
        const idx = this.devices.indexOf(d);
        idx !== -1 && this.devices.splice(idx, 1);
        console.log("Device stopped", device.name, device.vendorId, device.productId);
      });
      await d.startRead(ifaceIdx, epIdx);
      this.devices.push(d);

      if (hidConfig) {
        try {
          const rawDescriptors = await d.getRawDescriptors();
          const descriptors = parse(rawDescriptors);
          console.log(descriptors);
          const hidDescriptor = descriptors.find(it => it instanceof UsbDescriptorHID) as UsbDescriptorHID;

          let hidReportDescriptors: Buffer;

          try {
            hidReportDescriptors = await d.readControlTransfer(
              0x81,
              0x06,
              0x2200,
              0x00,
              hidDescriptor.wDescriptorLength,
              2000,
            );
          } catch (e) {
            console.log("first trail error", e);
            hidReportDescriptors = await d.readControlTransfer(
              0x81,
              0x06,
              0x2200,
              0x00,
              hidDescriptor.wDescriptorLength,
              2000,
            );
          }

          hidCollection = parseHid(hidReportDescriptors);
          console.log(hidCollection);
        } catch (e) {
          console.log("Cannot read hid description", e);
          hidCollection = parseHid(
            Buffer.from(
              "05010906a101050719e029e71500250175019508810295017508810395057501050819012905910295017503910395067508150025650507190029658100c0",
              "hex",
            ),
          );
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  async openCameraScanner(name: LangType) {
    const code = await this.$openDialog(
      import("@feathers-client/components-internal/QrCodeStreamDialog.vue"),
      {
        title: name,
      },
      {
        maxWidth: "min(500px,80vw)",
        contentClass: "h-70vh",
      },
    );
    if (!code) return;
    this.onResult({
      code,
      type: "camera",
    });
  }

  bluetooths: BluetoothScanner[] = [];

  async initBluetooth() {
    if (this.storage.getItem("bluetoothScanners")) {
      try {
        const scanners: BluetoothConf[] = JSON.parse(this.storage.getItem("bluetoothScanners"));
        if (scanners.length) {
          await initBluetooth();
          if (navigator.bluetooth.getDevices) {
            for (let conf of scanners) {
              if(this.bluetooths.find(it => it.conf.identifier === conf.identifier)) continue;

              const scanner = new BluetoothScanner(conf);
              this.bluetooths.push(scanner);
              this.handleBluetooth(scanner);
              scanner.tryConnect();
            }
          } else {
            await this.connectBluetooth();
          }
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async connectBluetooth() {
    const scanner = new BluetoothScanner({
      service: "0000fff0-0000-1000-8000-00805f9b34fb",
      name: "Bluetooth Scanner",
      type: "scanner",
      port: "bluetooth",
    });
    try {
      await scanner.tryConnect();
      this.handleBluetooth(scanner);
      this.bluetooths.push(scanner);
      this.storage.setItem(
        "bluetoothScanners",
        JSON.stringify(this.bluetooths.map(it => it.conf).filter(it => !!it.identifier)),
      );
    } catch (e) {
      scanner.disconnect(true);
      console.warn(e);
    }
  }

  removeBluetooth(id?: string) {
    if (!id) {
      this.storage.setItem("bluetoothScanners", "[]");
      for (let item of this.bluetooths) item.disconnect(true);
      this.bluetooths = [];
    } else {
      const itemIdx = this.bluetooths.findIndex(it => it.conf.id === id);
      if (itemIdx !== -1) {
        const item = this.bluetooths[itemIdx];
        this.bluetooths.splice(itemIdx, 1);
        item.disconnect();

        this.storage.setItem(
          "bluetoothScanners",
          JSON.stringify(this.bluetooths.map(it => it.conf).filter(it => !!it.identifier)),
        );
      }
    }
  }

  handleBluetooth(scanner: BluetoothScanner) {
    let lastData = 0;
    const buf: string[] = [];

    scanner.on("data", da => {
      for (let i = 0; i < da.length; i++) {
        const c = da[i];
        if (Date.now() - lastData > 500) {
          buf.splice(0, buf.length);
        }

        lastData = Date.now();
        if (c === 0xa || c === 0xd) {
          const c = buf.join("");
          if (c) {
            this.onResult({
              code: c,
              type: "bluetooth",
              bluetoothDevice: scanner,
            });
          }

          buf.splice(0, buf.length);
        } else {
          buf.push(String.fromCharCode(c));
        }
      }
    });
  }

  get connected() {
    return (
      !!this.bluetooths.find(it => it.connected) ||
      !!this.devices.length ||
      !!this.serialDevices.length ||
      this.nativeScannerStarted
    );
  }

  async ensureScanner() {
    for (let bluetooth of this.bluetooths) {
      if (bluetooth.connected) return true;
    }
    if (this.devices.length) return true;

    await this.initUSB();
    return !!this.devices.length;
  }

  saveUsbVensors() {
    this.storage.setItem("scanner_usbVendors", JSON.stringify(this.usbVendors));
  }

  async pickUsbScanner() {
    const newDevices = (await getDevices()).devices;
    const cdcDevices: UsbDeviceInfo[] = [];
    for (let device of newDevices) {
      const config = await getConfig(device.name);
      const cdcConfig = config.find(c => !!c.interfaces.find(iface => iface.iclass === 10 || iface.iclass === 255));
      const hidConfig = config.find(c => {
        const hasKey = !!c.interfaces.find(
          iface =>
            iface.iclass === 3 &&
            (iface.subclass === 0 || iface.subclass === 1) &&
            (iface.protocol === 0 || iface.protocol === 1),
        );
        return hasKey;
      });
      if (cdcConfig || hidConfig) {
        cdcDevices.push(device);
      }
    }
    const device: UsbDeviceInfo = await this.$openDialog(
      import("./dialogs/UsbSelector.vue"),
      {
        server: this,
        devices: cdcDevices,
      },
      {
        maxWidth: "80%",
      },
    );

    if (device) {
      if (this.usbVendors.indexOf(device.vendorId) === -1) {
        this.usbVendors.push(device.vendorId);
        this.saveUsbVensors();
      }

      this.handleDevice(device);
    }
  }

  async initNativeScanner() {
    if (await scannerSupported()) {
      scannerOn(result => {
        this.onResult({
          code: result,
          type: "native",
        });
      });
      if (!this.paused) {
        await scannerStart();
      }
      this.nativeScannerStarted = true;
    }
  }

  async suspend() {
    if (this.paused) return;
    this.$emit("scan", null);
    this.paused = true;
    if (this.nativeScannerStarted) {
      try {
        await scannerStop();
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async resume() {
    if (!this.paused) return;
    this.paused = false;
    if (this.nativeScannerStarted) {
      try {
        await scannerStart();
      } catch (e) {
        console.warn(e);
      }
    }
  }

  pauseScanUntil = 0;

  async onResult(info: ScannerEvent) {
    if(this.pauseScanUntil && Date.now() < this.pauseScanUntil) return;

    this.$emit("scan", info.code);

    for(let handler of this.handlers) {
      try {
        await handler.handler(info);
      } catch(e) {
        console.warn("Scanner handler", info, e);
      }
      if(info.delay) {
        this.pauseScanUntil = Date.now() + info.delay;
      }
      if(info.handled) return;
    }

    if(info.fallbackMessage) {
      // @ts-ignore
      this.$store?.commit?.("SET_ERROR", this.$td?.(info.fallbackMessage));
    }

    if(info.type === "keyboard") {
      this.$root.$emit("scanner-key", info);
    } else {
      this.$root.$emit("scanner", info);
    }
  }

  async scanOnce(timeout = -1) {
    await this.resume();
    try {
      return await new Promise<string>((resolve, reject) => {
        const timer =
          timeout === -1
            ? null
            : setTimeout(() => {
                this.$off("scan", handler);
                reject(new Error("Timeout"));
              }, timeout);

        const handler = (code: string) => {
          if (timer) {
            clearTimeout(timer);
          }
          if (!code) reject(new Error("Cancelled"));
          else resolve(code);
        };
        this.$once("scan", handler);
      });
    } finally {
      await this.suspend();
    }
  }

  async initNativeKeyboard() {
    if (await keyboardSupported()) {
      await keyboardStart();
      await this.ensureICONV();
      const altCodes = [];

      const flushAlt = () => {
        if (altCodes.length) {
          const code = +altCodes.join("");
          altCodes.splice(0, altCodes.length);
          if (!isNaN(code)) {
            if (code < 256) {
              pushChar(String.fromCharCode(code));
            } else {
              const buf = Buffer.alloc(2);
              buf.writeUInt16BE(code, 0);
              pushChar(this.iconv.decode(buf, "gbk"));
            }
          }
        }
      };

      const pushChar = (c: string) => {
        if (c === "\r" || c === "\n") {
          if (!this.codes.length) return;
          const code = this.codes.join("");
          this.onResult({
            code,
            type: "keyboard",
          });
          this.codes = [];
          altCodes.splice(0, altCodes.length);
        } else {
          this.codes.push(c);
        }
      };

      keyboardOn(result => {
        if (Date.now() - this.lastCode > 500) {
          this.codes = [];
          altCodes.splice(0, altCodes.length);
        }

        this.lastCode = Date.now();

        const isShift = !!(result.modifierFlags & KeyPressModifier.Shift);
        const isAlt = !!(result.modifierFlags & KeyPressModifier.Alternate);
        const capsLock = !!(result.modifierFlags & KeyPressModifier.AlphaShift);

        const b = result.keyCode;

        if (isAlt) {
          if (b) {
            if (b >= 0x59 && b <= 0x61) {
              altCodes.push(`${b - 0x59 + 1}`);
            } else if (b === 0x62) {
              altCodes.push("0");
            }
          }
        } else {
          flushAlt();
          for (let j = 0; j < keyMappings.length; j++) {
            const map = keyMappings[j];
            if (b >= map[0] && b < map[0] + map[1].length) {
              const ofs = b - map[0];
              const c = capsLock ? (isShift ? map[4][ofs] : map[3][ofs]) : isShift ? map[2][ofs] : map[1][ofs];
              if (c.charCodeAt(0) === 0) continue;
              pushChar(c);
            }
          }
        }
      });
      this.supressKeypress = true;
    }
  }
}

declare module "vue/types/vue" {
  export interface Vue {
    $scanner: Scanner;
  }
}

let scanner: Scanner;

Object.defineProperty(Vue.prototype, "$scanner", {
  get(this: Vue) {
    if (!scanner) {
      scanner = new Scanner(getOptions(this.$root));
    }
    return scanner;
  },
});
