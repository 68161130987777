import { Canvas, CanvasKit, Font, FontCollection, Paint, ParagraphStyle, Surface, Typeface, TypefaceFontProvider } from "./canvaskit";
import CanvasKitInit from "./canvaskit";
import { BaseRenderer } from "./base";
import { FontItem } from "../../common";
import { PrinterBaseConf } from "../../printers/baseConf";

let _initCanvasKitPromise: Promise<RenderContext> | null = null;

interface RenderContext {
  CanvasKit: CanvasKit;
  typefaceFontProvider: TypefaceFontProvider;
  typefaces: Record<string, Typeface>;
  fontCollection: FontCollection;
}

export async function initCanvasKit(printer?: PrinterBaseConf): Promise<RenderContext> {
  if (!_initCanvasKitPromise) {
    _initCanvasKitPromise = _initCanvasKit(printer);
  }
  return _initCanvasKitPromise;
}

async function _initCanvasKit(printer?: PrinterBaseConf): Promise<RenderContext> {
  // @ts-ignore
  const canvaskitUrl = (printer as any)?.canvasKitUrl ?? (await import("!!file-loader!./canvaskit.wasm")).default;
  const CanvasKit = await CanvasKitInit({
    locateFile: file => printer?.fixLoadPath?.(canvaskitUrl) ?? canvaskitUrl,
  });

  const typefaceFontProvider = CanvasKit.TypefaceFontProvider.Make();
  const fontCollection = CanvasKit.FontCollection.Make();
  fontCollection.setDefaultFontManager(typefaceFontProvider);

  return { CanvasKit, typefaceFontProvider, typefaces: {}, fontCollection };
}

const fonts: Record<string, Promise<void>> = {};

export function loadFont(printer: PrinterBaseConf, font: FontItem) {
  const key = `${font.name} ${font.variant}`;

  return (fonts[key] = fonts[key] || loadFontInternal(printer, font));
}

async function loadFontInternal(printer: PrinterBaseConf, font: FontItem) {
  const key = `${font.name} ${font.variant}`;

  const { typefaceFontProvider } = await _initCanvasKitPromise;
  let buffer: ArrayBuffer;
  if (printer.loadFont) {
    buffer = await printer.loadFont(font);
  } else {
    const file = await fetch(font.url);
    buffer = await file.arrayBuffer();
  }
  typefaceFontProvider.registerFont(buffer, key);
}

export class SkiaRenderer extends BaseRenderer {
  _canvas: Canvas;
  _canvasKit: CanvasKit;
  _surface: Surface;
  _paragraphStyle: ParagraphStyle;
  _renderContext: RenderContext;
  _paint: Paint;

  drawText(
    text: string,
    x: number,
    w: number,
    size: number,
    yScale: number = 1,
    align?: "left" | "right" | "center",
    tight?: boolean,
    inverted?: boolean,
    colPadding: number = 0,
  ) {
    this._canvas.save();

    const { lines, metrics, paragraph } = this.measureText(text, w - colPadding * 2);
    paragraph.disableAntialiasing();

    const line = lines?.[lines.length - 1];
    const bytesToDraw = line?.textRange?.last ?? 0;
    let charsToDraw = new TextDecoder().decode(
      new Uint8Array(new TextEncoder().encode(text).slice(0, bytesToDraw)),
    ).length;

    while (charsToDraw !== text.length && (text[charsToDraw] === " " || text[charsToDraw] === "\t")) {
      charsToDraw++;
    }
    if(!charsToDraw) {
      console.warn("No bytes to draw", text);
      charsToDraw++;
    }

    const width = metrics.map(it => it.width).reduce((a, b) => Math.max(a, b), 0);

    const fromX = align === "right" ? w - width : align === "center" ? (w - width) / 2 : 0;
    const toX = align === "right" ? x + w : align === "center" ? x + (w + width) / 2 : x + width;
    const fontHeight =
      (line ? Math.ceil(line.bottom ?? 0) : Math.ceil(paragraph.getGlyphInfoAt(0)?.graphemeLayoutBounds?.[3] ?? 0)) + 2;

    this._canvas.translate(x + fromX, 0);

    if (yScale != 1) {
      this._canvas.scale(1, yScale);
    }

    if (inverted) {
      this._paint.setColor(this._canvasKit.BLACK);
      this._canvas.drawRect([0, 0, w, fontHeight + colPadding * 2], this._paint);
    }

    this._canvas.drawParagraph(paragraph, colPadding, colPadding + 2);
    this._canvas.restore();

    paragraph.delete();

    return {
      x: Math.ceil(toX + colPadding * 2),
      top: 0,
      height: Math.ceil(fontHeight * yScale + colPadding * 2),
      charsToDraw,
    };
  }

  loadedFontProps: {
    fontFamily: string;
    isDefault: boolean;
    bold: boolean;
    italic: boolean;
    fonts: string[];
  } = null;

  async setFont(
    fontFamily: string,
    size: number,
    isDefault: boolean,
    props?: {
      bold?: boolean;
      italic?: boolean;
      inverted?: boolean;
      maxLines?: number;
    },
  ) {
    if (!this._canvas) {
      await this.prepareContext();
    }

    const bold = !!props?.bold;
    const italic = !!props?.italic;

    if (
      !this.loadedFontProps ||
      this.loadedFontProps.fontFamily !== fontFamily ||
      this.loadedFontProps.isDefault !== isDefault ||
      this.loadedFontProps.bold !== bold ||
      this.loadedFontProps.italic !== italic
    ) {
      const fontSettings = (this.parent || this.label).printer?.fontsInfo;

      if (!fontSettings) {
        throw new Error("Font settings not found");
      }

      let fonts: FontItem[] = [];

      if (!isDefault) {
        const style = props?.bold ? (props?.italic ? "italic" : "regular") : props?.italic ? "100italic" : "100";
        const font =
          fontSettings.fonts[`${fontFamily} ${style}`] ||
          fontSettings.fonts[`${fontFamily} 100`] ||
          fontSettings.fonts[`${fontFamily} regular`] ||
          fontSettings.fonts[fontFamily];
        if (font) {
          fonts.push(font);
        }
      }

      if (!fonts.length) {
        const fontItem =
          fontSettings.settings[
            props?.bold ? (props?.italic ? "boldItalic" : "bold") : props?.italic ? "italic" : "normal"
          ] || fontSettings.settings.normal;

        if (!fontItem) {
          throw new Error("Font not found");
        }

        for (let name of fontItem.lists) {
          const font = fontSettings.fonts[name];
          if (font) {
            fonts.push(font);
          }
        }
      }

      if (!fonts.length) {
        throw new Error("Font not found");
      }

      this.loadedFontProps = {
        fontFamily,
        isDefault,
        bold,
        italic,
        fonts: fonts.map(font => `${font.name} ${font.variant}`),
      };

      await Promise.all(fonts.map(font => loadFont((this.parent || this.label).printer, font)));
    }

    this._paragraphStyle = new this._canvasKit.ParagraphStyle({
      textAlign: this._canvasKit.TextAlign.Left,
      textStyle: {
        color: props?.inverted ? this._canvasKit.WHITE : this._canvasKit.BLACK,
        fontFamilies: this.loadedFontProps.fonts,
        fontSize: size,
        heightMultiplier: 1,
        fontStyle: italic ? {
          slant: this._canvasKit.FontSlant.Italic
        } : undefined,
      },
      ...(props?.maxLines
        ? {
            maxLines: props.maxLines,
            ellipsis: "…",
          }
        : {
            maxLines: 1,
          }),
    });
  }

  getImageData(x: number, y: number, w: number, h: number): ImageData {
    this._surface.flush();

    // console.log(
    //   "data:image/png;base64," + Buffer.from(this._surface.makeImageSnapshot().encodeToBytes()).toString("base64"),
    // );

    const pixels = this._canvas.readPixels(x, y, {
      alphaType: this._canvasKit.AlphaType.Unpremul,
      colorSpace: this._canvasKit.ColorSpace.SRGB,
      colorType: this._canvasKit.ColorType.RGBA_8888,
      width: w,
      height: h,
    });
    if (!pixels) {
      return null;
    }
    if (typeof ImageData === "undefined") {
      return {
        data: new Uint8ClampedArray(pixels.buffer),
        width: w,
        height: h,
        colorSpace: "srgb",
      };
    } else {
      return new ImageData(new Uint8ClampedArray(pixels.buffer), w, h);
    }
  }

  measureText(text: string, w: number) {
    const paragraphBuilder = this._canvasKit.ParagraphBuilder.MakeFromFontCollection(
      this._paragraphStyle,
      this._renderContext.fontCollection,
    );

    paragraphBuilder.addText(text);
    const paragraph = paragraphBuilder.build();
    paragraph.layout(w);
    const lines = paragraph.getShapedLines();
    const metrics = paragraph.getLineMetrics();

    paragraphBuilder.delete();
    // paragraph.delete();

    return { lines, metrics, paragraph };
  }

  async prepareContext() {
    if (!this._canvasKit) {
      const renderContext = await initCanvasKit(this.parent?.printer ?? this.label?.printer);
      this._canvasKit = renderContext.CanvasKit;
      this._renderContext = renderContext;
      this._paint = new this._canvasKit.Paint();
    }
    if (!this._canvas) {
      const width = this._canvasWidth;
      const height = this._canvasHeight;

      this._surface = this._canvasKit.MakeSurface(width, height);
      this._canvas = this._surface.getCanvas();
    }
    this._canvas.clear(this._canvasKit.Color(255, 255, 255, 255));
    return this._canvas;
  }

  disposeContext() {
    if (this._paint) {
      this._paint.delete();
      this._paint = null;
    }
    // if (this._canvas) {
    //   this._canvas.delete();
    //   this._canvas = null;
    // }
    // it crashes, seems the above code already delete the surface
    // try {
    if (this._surface) {
      this._surface.dispose();
      // this._surface.delete();
      this._surface = null;
    }
    // } catch (e) {
    //   console.warn(e);
    // }
  }
}
