import { render, staticRenderFns } from "./VirtualDataTableHeader.vue?vue&type=template&id=572c47d4&scoped=true"
import script from "./VirtualDataTableHeader.vue?vue&type=script&lang=ts"
export * from "./VirtualDataTableHeader.vue?vue&type=script&lang=ts"
import style0 from "./VirtualDataTableHeader.vue?vue&type=style&index=0&id=572c47d4&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572c47d4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('572c47d4')) {
      api.createRecord('572c47d4', component.options)
    } else {
      api.reload('572c47d4', component.options)
    }
    module.hot.accept("./VirtualDataTableHeader.vue?vue&type=template&id=572c47d4&scoped=true", function () {
      api.rerender('572c47d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/domore-table/VirtualDataTableHeader.vue"
export default component.exports