
import { Component, Prop, Vue, Watch, FindType } from "@feathers-client";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
  components: {
    QrcodeStream,
  },
})
export default class Scanner extends Vue {
  scanner = false;

  onDecode(code) {
    this.$emit("scan", code);
    this.scanner = false;
  }
}
