
import { Vue, Component, Prop, Watch, Ref, FindType } from "@feathers-client";
import { CarouselItem } from "./multiShop.vue";

@Component
export default class Carousel extends Vue {
  @Prop()
  order: CarouselItem;

  @Prop(Boolean)
  duplicate: boolean;

  appear = false;

  @Watch("order.newItem", { immediate: true })
  onItem() {
    if (this.duplicate) return;
    if (this.order.newItem) {
      this.appear = true;
      this.order.newItem = false;
    }
  }

  get style() {
    for (let shop of this.order.shops) {
      return {
        "--shop-color": shop.color,
      };
    }
    return {
      "--shop-color": this.$config.colors?.red200,
    };
  }

  get code() {
    let code = this.order.item.code;
    for (let shop of this.order.shops) {
      if (shop.prefix) {
        if (code.startsWith(shop.prefix)) {
          return code;
        }
        return shop.prefix + code;
      }
    }
    return code;
  }
}
