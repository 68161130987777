
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, checkID } from "@feathers-client";
import type { ProductLine, TableSession } from "~/plugins/table/session";
import { translate } from "~/common/table/util";
import { LangArrType } from "~/common/table/session";

export function tjoin(parts: (LangArrType | string | number)[], join: string = ","): LangArrType {
  const langs = _.uniq([..._.flatMap(parts.map(part => (Array.isArray(part) ? part.map(p => p.lang) : [])))]);
  return langs.map(lang => ({
    lang,
    value: parts.map(jt => (Array.isArray(jt) ? translate(jt, lang) : `${jt}`)).join(join),
  }));
}

@Component
export default class People extends Vue {
  @VModel()
  menu: boolean;

  @Prop()
  session: TableSession;

  updateCachedName(modifiers: any[]) {
    const activeModiferNames = modifiers
      .map(m => this.$shop.modifiers.find(it => checkID(it, m)))
      .filter(it => !!it)
      .map(it => it.name);

    this.session.delaySave({
      modifierNames: tjoin(activeModiferNames, ", "),
      modifiers,
    });
  }

  updateBeeperNum(beeperNum: string) {
    this.session.delaySave({
      beeperNum,
    });
  }

  updateRemarks(remarks: InputEvent) {
    this.session.delaySave({
      remarks: (remarks.target as HTMLTextAreaElement).value,
    });
  }
}
