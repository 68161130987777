import { render, staticRenderFns } from "./TimePicker.vue?vue&type=template&id=3804c7d6"
import script from "./TimePicker.vue?vue&type=script&lang=ts"
export * from "./TimePicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VBtn,VIcon,VMenu,VSpacer,VTimePicker})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/lassana/coffee-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3804c7d6')) {
      api.createRecord('3804c7d6', component.options)
    } else {
      api.reload('3804c7d6', component.options)
    }
    module.hot.accept("./TimePicker.vue?vue&type=template&id=3804c7d6", function () {
      api.rerender('3804c7d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/TimePicker.vue"
export default component.exports