
import { Component, Prop, Vue, Watch, mixins, Ref, FindPopRawType, FindType, checkID, getID } from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import CartPanelBase from "../cartPanelBase";
import _ from "lodash";
@Component({})
export default class MobilePickerOption extends mixins(CartPanelBase) {
  @Prop()
  session: TableSession;

  @Prop()
  currentPage: string;

  get currentCart() {
    return this.session?.cart ?? [];
  }

  selectingAll() {
    this.$emit("selectAll");
    this.$emit("pickerOption");
    if (this.currentPage !== "cart") return this.$emit("navigate", "cart");
  }

  // onSessionTypeUpdate(type){
  //   this.session.delaySave({
  //     type: type
  //   })
  //   switch (type){
  //     case "dineInNoTable":
  //       this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.dineInSuccess"));
  //       break;
  //     case "takeAway":
  //       this.$store.commit("SET_SUCCESS", this.$t("pos.sessionType.takeAwaySuccess"));
  //       break;
  //   }
  // }
}
