
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel } from "@feathers-client";
import { ProductLine, TableSession } from "~/plugins/table/session";
import CartPanelBase from "./cartPanelBase";

@Component
export default class OrderSystemMore extends mixins(CartPanelBase) {
  @VModel()
  more: boolean;

  @Prop()
  session: TableSession;

  get setDisplay() {
    return this.$shop.localOptions.orderingDisplay;
  }
  set setDisplay(v) {
    this.$shop.localOptions.orderingDisplay = v;
  }

  async cancelOrderHandler() {
    if ((await this.cancelOrder()) == true) {
      this.$emit("close");
    }
  }

  items = [
    {
      _id: "order",
      name: this.$t("basic.orderMode"),
    },
    {
      _id: "cashier",
      name: this.$t("basic.cashierMode"),
    },
  ];

  changeDisplay() {
    if (this.$shop.localOptions.orderingDisplay === "order") this.setDisplay = "cashier";
    else this.setDisplay = "order";
  }
}
