
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { SessionConainterLayout, TableView } from "~/plugins/table/view";
import type { TableItem, TableSessionRef } from "~/plugins/table";

@Component
export default class TakeAway extends Vue {
  @Prop()
  width: number;
  @Prop()
  height: number;

  @Prop()
  view: TableView;

  @Prop(Boolean)
  takeAwayOnly: boolean;

  @Prop(Boolean)
  dineInNoTable: boolean;

  itemWidth = 60;
  // itemHeight = 55;
  padding = 10;

  get itemHeight() {
    return this.showTable ? 80 : 55;
  }

  get showTable() {
    return (
      this.$shop.shopData.posDINTShowTable &&
      this.$shop.shopData.onlineDineInNoTableEnabledFixedCode &&
      this.dineInNoTable
    );
  }

  get takeAwayAutoPrint() {
    return this.$shop.shopData.takeAwayAutoPrint;
  }

  get onlineDineInNoTableEnabledFixedCode() {
    return this.$shop.shopData.onlineDineInNoTableEnabledFixedCode;
  }

  get bgConfig() {
    return {
      x: 0,
      y: 0,
      width: this.width,
      height: this.height,
      fill: this.$config.colors.grey600,
    };
  }

  get strokeConfig() {
    return {
      ...this.bgConfig,
      height: 0,
      stroke: this.$config.colors.grey600,
      dash: [4, 4],
    };
  }

  get takeAwayTitle() {
    return {
      text: this.dineInNoTable ? this.$t("tableView.dineInNoTable") : this.$t("tableView.takeAwayOrders"),
      fontFamily: this.$config.fontFamily,
      x: 0,
      y: 0,
      align: "right",
      fill: this.$config.colors.grey400,
      fontSize: 24,
      width: this.width - 12,
      height: this.height,
      verticalAlign: "middle",
    };
  }

  beginDrag() {}

  endDrag() {}

  get items() {
    const items: SessionConainterLayout[] = [];
    let sessions = (this.dineInNoTable ? this.$tableManager.dineInNoTables : this.$tableManager.takeAways) || [];
    sessions = sessions.filter(
      it =>
        it.item.source === "pos" ||
        (it.status !== "ongoing" && it.status !== "toPay") ||
        this.takeAwayAutoPrint ||
        this.onlineDineInNoTableEnabledFixedCode,
    ); // filter unpaid online orders
    let x = 0;
    let y = 0;

    const padLeft = (this.takeAwayOnly ? 78 : 116) * (+this.$shop.currentFontSize / 100);

    let maxPerRow = Math.floor((this.width - padLeft - 54) / (this.itemWidth + this.padding));
    for (let i = 0; i < sessions.length; i++) {
      const item = sessions[sessions.length - i - 1];
      if (x >= maxPerRow) {
        x = 0;
        y++;
        if (y >= 3) break;
      }
      items.push({
        multiple: false,
        x: (this.itemWidth + this.padding) * x + padLeft,
        y: (this.takeAwayOnly ? 60 : 12) + (this.itemHeight + this.padding) * y,
        w: this.itemWidth,
        h: this.itemHeight,
        item: {
          session: item,
          table: null,
          split: null,
        },
      });
      x++;
    }
    return items;
  }

  touchingSameRef = false;

  onElemDown(e) {
    const evt = e.evt;
    if (evt && window.TouchEvent && evt instanceof TouchEvent) {
      if (evt.touches.length > 1) return;
    }
    e.cancelBubble = true;
    const ref: TableSessionRef = e.currentSession;

    if (ref) {
      if (ref.session.selected && ref.session.selectedRef?.session === ref.session) {
        this.touchingSameRef = true;
        return;
      } else {
        if (this.$tableManager.takeAwaySession) {
          this.$tableManager.takeAwaySession.selected = false;
          this.$tableManager.takeAwaySession = null;
        }
        ref.session.selected = true;
        ref.session.selectedRef = ref;
        this.$tableManager.takeAwaySession = ref.session;
      }
    } else {
      if (this.$tableManager.takeAwaySession) {
        this.$tableManager.takeAwaySession.selected = false;
        this.$tableManager.takeAwaySession = null;
      }
    }

    this.view.clearSelect();
    this.$emit("hideMenu");
  }

  onElemClick(e) {
    e.cancelBubble = true;
    const ref: TableSessionRef = e.currentSession;

    if (ref) {
      if (ref.session.selectedRef?.session === ref.session && this.touchingSameRef) {
        this.$emit("openOrder", ref.session);
      }
    }
  }

  cancelClick(e) {
    this.touchingSameRef = false;
  }

  onElemTouchUp(e) {
    const ref: TableSessionRef = e.currentSession;
    if (this.touchingSameRef) {
      this.$emit("openOrder", ref?.session);
    }
  }
}
