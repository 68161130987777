export default {
  discount: [
    { lang: "en", value: "Shop Discount" },
    { lang: "chs", value: "店铺优惠" },
    { lang: "cht", value: "店鋪優惠" },
  ],
  openKey: [
    { lang: "en", value: "Price Adjustment" },
    { lang: "chs", value: "价钱调整" },
    { lang: "cht", value: "價錢調整" },
  ],
  manualDiscount: [
    { lang: "en", value: "Manual Discount" },
    { lang: "chs", value: "手动折扣" },
    { lang: "cht", value: "手動折扣" },
  ],
  customRemark: [
    { lang: "en", value: "Custom Remark" },
    { lang: "chs", value: "特别要求" },
    { lang: "cht", value: "特別要求" },
  ],
  coupon: [
    { lang: "en", value: "Coupon" },
    { lang: "chs", value: "优惠券" },
    { lang: "cht", value: "優惠券" },
  ]
};
