
import { Component, Prop, Vue } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import type { CartItem } from "@common/table/cart";

@Component
export default class ProductOptionsFooter extends Vue {
  @Prop()
  cart: CartItem;

  @Prop()
  session: TableSession;
}
