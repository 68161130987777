
import { Component, Prop, Vue, mixins } from "@feathers-client";
import {
  Expression,
  PrinterTemplateNodeBase,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeRoot,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  compile,
  compileLabel,
} from "pos-printer-template";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorPosition extends mixins(PrinterEditorEditBase) {
  get x() {
    if (this.positionNode.props["pos"]?.isStatic) {
      const size = this.positionNode.props["pos"].expression;
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return +size.split(",")[0];
      } else if (typeof size === "string" || typeof size === "number") {
        return +size;
      }
    }
    const e = this.positionNode?.props?.["x"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set x(value: number | string) {
    if (!this.positionNode) return;
    if (this.positionNode.props["pos"]) {
      this.positionNode.deleteProp("pos");
    }
    if (value === undefined) {
      this.positionNode.deleteProp("x");
    } else {
      this.positionNode.setProp("x", value.toString());
    }
    this.$emit("update");
  }

  get y() {
    if (this.positionNode.props["pos"]?.isStatic) {
      const size = this.positionNode.props["pos"].expression;
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return +size.split(",")[1];
      } else if (typeof size === "string" || typeof size === "number") {
        return +size;
      }
    }
    const e = this.positionNode?.props?.["y"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set y(value: number | string) {
    if (!this.positionNode) return;
    if (this.positionNode.props["pos"]) {
      this.positionNode.deleteProp("pos");
    }
    if (value === undefined) {
      this.positionNode.deleteProp("y");
    } else {
      this.positionNode.setProp("y", value.toString());
    }
    this.$emit("update");
  }

  get w() {
    if (this.positionNode.props["pos"]?.isStatic) {
      const size = this.positionNode.props["pos"].expression;
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return +size.split(",")[2];
      } else if (typeof size === "string" || typeof size === "number") {
        return +size;
      }
    }
    const e = this.positionNode?.props?.["w"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set w(value: number | string) {
    if (!this.positionNode) return;
    if (this.positionNode.props["pos"]) {
      this.positionNode.deleteProp("pos");
    }
    if (value === undefined) {
      this.positionNode.deleteProp("w");
    } else {
      this.positionNode.setProp("w", value.toString());
    }
    this.$emit("update");
  }

  get h() {
    if (this.positionNode.props["pos"]?.isStatic) {
      const size = this.positionNode.props["pos"].expression;
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return +size.split(",")[3];
      } else if (typeof size === "string" || typeof size === "number") {
        return +size;
      }
    }
    const e = this.positionNode?.props?.["h"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set h(value: number | string) {
    if (!this.positionNode) return;
    if (this.positionNode.props["pos"]) {
      this.positionNode.deleteProp("pos");
    }
    if (value === undefined) {
      this.positionNode.deleteProp("h");
    } else {
      this.positionNode.setProp("h", value.toString());
    }
    this.$emit("update");
  }
}
