
import _ from "lodash";
import { checkID, getID, FindType, Component, Prop, Vue, Watch, mixins, Ref } from "@feathers-client";
import { PropSync, VModel } from "vue-property-decorator";
import { ObjectID } from "../../../dep/typings/dep/feathers/db/schemas";
import ProductOptionBase from "./productOptionBase";
import { TableSession } from "~/plugins/table/session";
import type { CartItem, ProductOptionSelectWithPrice, ProductOptionSelectWithPriceItem } from "@common/table/cart";

@Component
export default class ProductOptionItemNew extends mixins(ProductOptionBase, Vue) {
  @Prop()
  session: TableSession;

  @Prop()
  cart: CartItem;

  @Prop()
  opt: ProductOptionSelectWithPrice;

  @PropSync("idx")
  idxSync: number;
  @PropSync("jdx")
  jdxSync: string;

  @Prop({ type: Boolean, default: false })
  nested: boolean;

  @Prop()
  parent;
}
