
import _ from "lodash";
import { DB, ObjectID } from "@db";
import { Component, Prop, Vue, Watch, mixins, PropSync, Ref } from "nuxt-property-decorator";
import type { TableSession } from "~/plugins/table/session";
import { checkID, getID, FindType } from "@feathers-client";
import MemberList from "./memberList.vue";

@Component({
  components: {
    MemberList,
  },
})
export default class dashboard extends Vue {
  @Prop()
  scannedMemberId: string;

  dashboard = false;

  @Watch("scannedMemberId")
  openDashBoard() {
    if (this.scannedMemberId) {
      this.dashboard = true;
    }
  }

  @Watch("dashboard")
  closeDashBoard() {
    if (!this.dashboard) {
      this.$emit("closeDetail");
    }
  }
}
