
import {
  Component,
  Prop,
  Vue,
  PropSync,
  mixins,
  Ref,
  FindPopRawType,
  FindType,
  checkID,
  getID,
} from "@feathers-client";
import type { TableSession } from "~/plugins/table/session";
import _ from "lodash";
@Component({})
export default class MobileOrderType extends Vue {
  @Prop()
  session: TableSession;

  @PropSync("scanning")
  scanningSync: boolean;

  userQuery = {
    $populate: ["vipLevel"],
  };

  isVipActive(user: FindPopRawType<["vipLevel"], "appUsers">) {
    return user.vipLevel && user.status === "active" && (!user.expiry || new Date(user.expiry) > new Date());
  }
}
