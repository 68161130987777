
import { Component, Prop, Vue } from "@feathers-client";
import {
  PrinterTemplateNodeBase,
  PrinterTemplateNodeCol,
  PrinterTemplateNodeRow,
  compile,
  compileLabel,
} from "pos-printer-template";

@Component
export default class PrinterEditorCol extends Vue {
  @Prop() template!: PrinterTemplateNodeCol;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  @Prop()
  path: string;

  get children() {
    const children: {
      condition: boolean;
      item: PrinterTemplateNodeBase<any>;
    }[] = [];

    let lastCondFailed = true;
    for (const item of this.template.children) {
      let curCondition = true;

      if (item.condNeedFailed && !lastCondFailed) {
        curCondition = false;
      } else if (item.cond) {
        if (!item.cond.eval(this.context)) {
          curCondition = false;
        }
      }

      lastCondFailed = !curCondition;
      children.push({ condition: curCondition, item });
    }

    return children;
  }

  get content() {
    if (this.context) {
      return this.template.getTextValue(this.context);
    }
  }

  get size() {
    return +(this.template.props["n"]?.eval?.(this.context) ?? 0);
  }

  get weight() {
    if (this.template.props["w"]) {
      const weight = +this.template.props["w"].eval(this.context);
      if (isNaN(weight)) {
        return undefined;
      }
      return weight;
    }
  }

  get fontWeight() {
    if (this.template.props["weight"]) {
      const weight = this.template.props["weight"].eval(this.context);
      if (weight === "normal") {
        return false;
      } else if (weight === "bold" || !weight) {
        return true;
      }
    }
  }

  get fontItalic() {
    if (this.template.props["fontStyle"]) {
      const fontStyle = this.template.props["fontStyle"].eval(this.context);
      if (fontStyle === "normal") {
        return false;
      } else if (fontStyle === "italic" || !fontStyle) {
        return true;
      }
    }
  }

  get fontSize() {
    let msize: number;
    const sizeKey = "fontSize";
    if (this.template.props[sizeKey]) {
      const size = this.template.props[sizeKey].eval(this.context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        msize = +size.split(",")[0];
      } else if (typeof size === "string" || typeof size === "number") {
        msize = +size;
      }
    }

    const fontSize = this.template.props["fontSize"]?.eval?.(this.context);
    let finalPxSize = (msize || 0) * 12 + 24;
    if (typeof fontSize === "string" || typeof fontSize === "number") {
      finalPxSize = +fontSize;
    } else if (msize === undefined) {
      return undefined;
    }

    return finalPxSize;
  }

  get color() {
    if (this.template.props["color"]) {
      const color = this.template.props["color"].eval(this.context);
      if (color === "red" || color == 1 || color === true) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  get align() {
    if (this.template.props["align"]) {
      const align = this.template.props["align"].eval(this.context);
      if (align === "left") {
        return "left";
      } else if (align === "right") {
        return "right";
      } else if (align === "center") {
        return "center";
      }
    }
  }

  get inverted() {
    if (this.template.props["inverted"]) {
      const inverted = this.template.props["inverted"].eval(this.context);
      if (inverted === undefined || inverted === "true" || inverted === true || +inverted === 1) {
        return true;
      }
      return false;
    }
  }

  get style() {
    return {
      ...(this.size
        ? {
            width: `${this.size / 2}em`,
          }
        : {
            flexGrow: `${this.weight || 1}`,
            flexBasis: 0,
          }),
      ...(this.fontSize !== undefined
        ? {
            fontSize: `${this.fontSize}px`,
          }
        : {}),
      ...(this.color !== undefined
        ? {
            color: this.color ? "red" : "var(--text-primary)",
            "--current-color": this.color ? "red" : "var(--text-primary)",
          }
        : {}),
      ...(this.inverted !== undefined
        ? {
            backgroundColor: this.inverted ? "var(--current-color)" : "var(--surface-default)",
            color: this.inverted ? "var(--surface-default)" : "var(--current-color)",
          }
        : {}),
      ...(this.fontWeight !== undefined
        ? {
            fontWeight: this.fontWeight ? "bold" : "normal",
          }
        : {}),
      ...(this.fontItalic !== undefined
        ? {
            fontStyle: this.fontItalic ? "italic" : "normal",
          }
        : {}),
      ...(this.align !== undefined
        ? {
            textAlign: this.align,
          }
        : {}),
    };
  }
}
