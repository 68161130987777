import { AppApplication } from 'serviceTypes';
import { 
    FindType as ServerFindType,
    FindPopType as ServerFindPopType,
    FindPopRawType as ServerFindPopRawType,
} from '@feathersjs/feathers'
import { DB, ObjectID } from '@db'
export { createDecorator } from 'vue-class-component'
export * from "vue-property-decorator";
export * from "vue-class-component"

export interface SetCurrentApp {
};

export type CurrentApp = (SetCurrentApp extends { app : infer T } ? T : AppApplication) extends infer U ? U : AppApplication;
export type CurrentDB = (SetCurrentApp extends { db : infer T } ? T : DB) extends infer U ? U : DB;

export type FindType<TRoute extends keyof CurrentApp> = ServerFindType<TRoute, CurrentApp>
export type FindPopType<TPop extends { 
    readonly query: {
        readonly $populate: any;
    };
}, TRoute extends keyof CurrentApp> = ServerFindPopType<TPop, TRoute, CurrentApp>;
export type FindPopRawType<TPop, TRoute extends keyof CurrentApp> = ServerFindPopRawType<TPop, TRoute, CurrentApp>

export function getOptions(v : any, props?) : any {
    return v.$root ? {
        parent: v ? v : null,
        propsData: props,
    } : {
        store: (v as any).store || (v as any).$store,
        i18n: (v as any).i18n || (v as any).$i18n,
        $router: (v as any).router || (v as any).$router,
        $feathers: (v as any).$feathers,
        propsData: props,
    }
}

function getIDCore(item : string | null | ObjectID<any>) : string | null;
function getIDCore(item : any) : string | null;
function getIDCore<T extends { _id: ObjectID<any>}>(item : T) : string;
function getIDCore<T extends { _id: any}>(item : T) : string;

function getIDCore(item : any) : string | null {
    if(!item) {
        return null;
    } else if(typeof item === 'string') {
        return item;
    } else if(typeof item === 'number') {
        return `${item}`;
    } else if(item._id !== undefined) {
        // fix server usage
        return item._id ? `${item._id}` : null;
    } else {
        console.warn("Unknown id type", item);
        throw new Error("Unknown id type");
    }
}

function checkIDCore(a : any, b : any) : boolean;
function checkIDCore<T extends { _id: ObjectID<any>}>(a : string | ObjectID<any> | T | null, b : string | ObjectID<any> | T | null| ObjectID<any>) :boolean;
function checkIDCore<T extends { _id: ObjectID<any>}, T2 extends { _id: ObjectID<any>}>(a : string | T | null| ObjectID<any>, b : string | T2 | null| ObjectID<any>) : boolean;
function checkIDCore<T extends { _id: any}>(a : string | T | null, b : string | T | null| ObjectID<any>) :boolean;
function checkIDCore<T extends { _id: any}, T2 extends { _id: ObjectID<any>}>(a : string | T | null| ObjectID<any>, b : string | T2 | null| ObjectID<any>) : boolean;

function checkIDCore(a : any, b : any) : boolean {
    return getIDCore(a) === getIDCore(b);
}

export const getID = getIDCore;
export const checkID = checkIDCore;
