
import _ from "lodash";
import { checkID, getID, FindType, Component, Prop, Vue, Watch, mixins } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import type { CartItem, ProductOptionSelectWithPrice, ProductOptionSelectWithPriceItem } from "@common/table/cart";
import { StockLevel, getOptionStockLevel } from "~/common/table/util";
import { ProductOptionType } from "~/plugins/shop";

@Component
export default class OrderSystemProductOptionsItem extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  cart: CartItem;

  @Prop()
  opt: ProductOptionSelectWithPrice;

  @Prop(Boolean)
  dark: boolean;

  get options() {
    return this.opt.options;
  }

  get product() {
    return this.cart.product;
  }

  stockStatus(option: ProductOptionSelectWithPrice, value: ProductOptionSelectWithPriceItem) {
    const level =
      option.available && value.available
        ? getOptionStockLevel(option.options, value.item, {
            session: this.session,
            optionsDict: this.$shop.productOptionDict,
            productsDict: this.$shop.productDict,
          })
        : StockLevel.NotSelling;
    switch (level) {
      case StockLevel.None:
      case StockLevel.Disabled:
        return "hidden";

      case StockLevel.Conflicted:
        return "text-red300";

      case StockLevel.OutOfStock:
      case StockLevel.NotSelling:
        return "bg-grey500";

      case StockLevel.Low:
        return "bg-red300";
      case StockLevel.Medium:
        return "bg-yellow000";
      case StockLevel.High:
        return "bg-green100";
    }
  }

  toggleItem(item: ProductOptionSelectWithPriceItem) {
    item.toggleSelection();
  }
}
