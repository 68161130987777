import _ from "lodash";
import { WrappedContext } from "../common";
import type { VueConstructor } from "vue";
import { PrintTable } from "./table";

import { RawLine, TextLine } from "./rawLine";
import type { PrinterBaseConf } from "../printers/baseConf";
import { PrintJob } from "../printJob";
import { convertImage, js_process_floyd } from "./escpos/image";
import { PrintGraph } from "./graph";
import { ThermalText, ThermalCmd } from "./canvas/job";
import { BaseRenderer } from "./raster/base";
import { CanvasRenderer } from "./raster/canvas";
import { SkiaRenderer } from "./raster/skia";

export enum TextAlign {
  Left = 0,
  Center = 1,
  Right = 2,
}

export enum MultiCodePage {
  US = 0,
  France = 1,
  Germany = 2,
  UK = 3,
  Denmark_I = 4,
  Sweden = 5,
  Italy = 6,
  Spain_I = 7,
  Japan = 8,
  Norway = 9,
  Denmark_II = 10,
  Spain_II = 11,
  LatinAmerica = 12,
  Korean = 13,
  Slovenia_Croatia = 14,
  Chinese = 15,
  Vietnam = 16,
  Arabic = 17,
  India_Devanagari = 66,
  India_Bengali = 67,
  India_Tamil = 68,
  India_Telugu = 69,
  India_Assamese = 70,
  India_Oriya = 71,
  India_Kannada = 72,
  India_Malayalam = 73,
  India_Gujarati = 74,
  India_Punjabi = 75,
  India_Marathi = 82,
}

export enum SingleCodePage {
  USA = 0, // PC437: USA, Standard Europe
  Katakana = 1,
  PC850 = 2, // PC850: Multilingual
  PC860 = 3, // PC860: Portuguese
  PC863 = 4, // PC863: Canadian-French
  PC865 = 5, // PC865: Nordic
  Hiragana = 6,
  OnePassKanji = 7,
  OnePassKanji2 = 8,
  PC851 = 11, // PC851: Greek
  PC853 = 12, // PC853: Turkish
  PC857 = 13, // PC857: Turkish
  PC737 = 14, // PC737: Greek
  ISO8859_7 = 15, // ISO8859-7: Greek
  WPC1252 = 16,
  PC866 = 17, // PC866: Cyrillic #2
  PC852 = 18, // PC852: Latin 2
  PC858 = 19, // PC858: Euro
  Thai42 = 20, // Thai Character Code 42
  Thai11 = 21, // Thai Character Code 11
  Thai13 = 22, // Thai Character Code 13
  Thai14 = 23, // Thai Character Code 14
  Thai16 = 24, // Thai Character Code 16
  Thai17 = 25, // Thai Character Code 17
  Thai18 = 26, // Thai Character Code 18
  TCVN3 = 30, // TCVN-3: Vietnamese
  PC720 = 32, // PC720: Arabic
  WPC775 = 33, // WPC775: Baltic Rim
  PC855 = 34, // PC855: Cyrillic
  PC861 = 35, // PC861: Icelandic
  PC862 = 36, // PC862: Hebrew
  PC864 = 37, // PC864: Arabic
  PC869 = 38, // PC869: Greek
  ISO8859_2 = 39, // ISO8859-2: Latin 2
  ISO8859_15 = 40, // ISO8859-15: Latin 9
  PC1098 = 41, // PC1098: Farsi
  PC1118 = 42, // PC1118: Lithuanian
  PC1119 = 43, // PC1119: Lithuanian
  PC1125 = 44, // PC1125: Ukrainian
  WPC1250 = 45, // WPC1250: Latin 2
  WPC1251 = 46, // WPC1251: Cyrillic
  WPC1253 = 47, // WPC1253: Greek
  WPC1254 = 48, // WPC1254: Turkish
  WPC1255 = 49, // WPC1255: Hebrew
  WPC1256 = 50, // WPC1256: Arabic
  WPC1257 = 51, // WPC1257: Baltic Rim
  WPC1258 = 52, // WPC1258: Vietnamese
  KZ_1048 = 53, // KZ-1048: Kazakhstan
  Devanagari = 66,
  Bengali = 67,
  Tamil = 68,
  Telugu = 69,
  Assamese = 70,
  Oriya = 71,
  Kannada = 72,
  Malayalam = 73,
  Gujarati = 74,
  Punjabi = 75,
  Marathi = 82,
  Page254 = 254,
  Page255 = 255,
}

type TupleSequence<Seqeunce extends readonly any[], L extends number, A extends readonly any[] = readonly []> =
  | A
  | (A["length"] extends L ? never : TupleSequence<Seqeunce, L, readonly [...Seqeunce, ...A]>);
type TableType = TupleSequence<[number, string | string[]], 20>; // max length of 20
type TableAlignType = TupleSequence<[number, "left" | "center" | "right" | number, string | string[]], 21>; // max length of 21

export abstract class PrintSequence {
  slient = true;
  includeDebug = false;

  /**
   * Get raw chunks
   */
  // @ts-ignore
  get chunks() {
    console.warn("Please consider use printer.getJob()");
    return this._chunks;
  }

  protected _chunks: Buffer[] = [];

  finished = false;
  cutPreFeed = 10;
  cutPostFeed = 1;

  /**
   * Finish the sequence
   */
  finish() {
    if (this.finished) return;
    this.finished = true;
    this.flush();
    this._disposeContext();
  }

  /**
   * Flush the sequence
   */
  flush() {}

  /**
   * Convert the sequence to print job
   * @param name print job name
   * @param id print job id
   * @returns self
   */
  getJob(name: string, id?: string) {
    this.finish();
    if (!this.slient) console.log(Buffer.concat(this._chunks).toString());
    return new PrintJob(name, Buffer.concat(this._chunks), id, this.getJobOpts());
  }

  /**
   * Get the job opts
   * @returns self
   */
  getJobOpts() {
    return {};
  }

  /**
   * Current line width
   */
  lineWidth = 32;
  /**
   * Line width at normal font style
   */
  normalLineWidth = 32;
  /**
   * Line width at compressed font style
   */
  cnormalLineWidth = 40;

  debugData?: any;
  debugLoc?: any;
  dirty = false;

  _defaultImageHiRes = true;

  get doubleVDensity() {
    return this.printer?.conf?.opts?.doubleVDensity ?? true;
  }

  get fontWidth() {
    return this.printer?.conf?.opts?.fontWidth ?? 12;
  }

  get fontHeight() {
    return this.printer?.conf?.opts?.fontHeight ?? 12;
  }

  /**
   * Create a command sequence
   * @param context Vue context
   * @param printer printer instance
   */
  constructor(
    public context: WrappedContext,
    public printer: PrinterBaseConf,
  ) {
    if (!process.env.TESTING && (!printer || !printer.ensurePrinter?.())) {
      console.warn("Please consider use printer.createSequence()");
    }
    this.context = context;
    this.lineWidth = this.normalLineWidth = printer?.conf?.opts?.lineWidth ?? 48;
    this.cnormalLineWidth = printer?.conf?.opts?.clineWidth ?? (this.lineWidth * 1.25) | 0;
    this.cutPreFeed = printer?.conf?.opts?.cutPreFeed ?? 10;
    this.cutPostFeed = printer?.conf?.opts?.cutPostFeed ?? 10;
  }

  /**
   * Add raw buffer to the sequence
   * @param array buffer
   * @returns self
   */
  raw(array: number[] | Buffer | Uint8Array) {
    if (this.finished) throw new Error("Sequence is closed");
    const b = array instanceof Buffer ? array : Buffer.from(new Uint8Array(array));
    if (!b.length) return;
    this.dirty = true;
    this._chunks.push(b);
    return this;
  }

  /**
   * Add raw text buffer
   */
  rawText(array: Buffer | number[]) {
    this.raw(array);
    return this;
  }

  /**
   * Convert image at url to raw image bitmap
   * @param url image url
   * @param width size
   * @returns raw image bitmap
   */
  async convertImage(
    url: string,
    width: number,
    opts?: {
      mode?: "dither" | "color" | "colorAlpha" | "gray" | "mono" | "alpha";
      pxRatio?: number;
      pxPerLine?: number;
      fixCorner?: boolean;
      drawWidth?: number;
    },
  ) {
    return await convertImage(
      url,
      width,
      opts?.mode,
      opts?.pxRatio || 1,
      opts?.pxPerLine || 1,
      opts?.fixCorner ?? true,
      opts?.drawWidth,
    );
  }

  fixLineImageResolution(hiRes: boolean | number) {
    if (hiRes === true || hiRes === 33) {
      return this.doubleVDensity ? hiRes : 1;
    } else if (hiRes === 32) {
      return this.doubleVDensity ? hiRes : 0;
    }
    return hiRes;
  }

  /**
   * Print image at url
   * @param url image url
   * @param width size
   * @param hiRes high resolution
   * @returns
   */
  async printImage(url: string, width: number, hiRes?: boolean | number) {
    if (!url) {
      console.warn("Invalid image url");
      return this;
    }
    hiRes = this.fixLineImageResolution(hiRes);
    const hHi = hiRes === true || hiRes === 1 || hiRes === 33;
    this.printRawImage(await this.getImage(url, (width / (hHi ? 12 : 6)) | 0, hiRes));
    return this;
  }

  async printImageData(
    buffer: Uint8ClampedArray,
    width: number,
    height: number,
    hiRes?: boolean | number,
    color?: number,
  ) {
    console.warn("Not supported: printImageData");
    return this;
  }

  async printImageTag(tag: string, url?: string) {
    const opts = this.printer?.bitmapList?.[tag];
    const hiRes =opts?.hiRes ?? this._defaultImageHiRes;

    if (!opts) {
      console.warn(`Invalid image tag: ${tag}`);
      if (url) {
        return await this.printImage(url, this.currentLineWidth * 6, hiRes);
      }
    }
    url = this.printer.resolveBitmap(tag, url, this.context);
    return await this.printImage(url, opts?.width ? opts.width * (hiRes ? 2 : 1) : this.currentLineWidth * 6, hiRes);
  }

  /**
   * Print a qr code
   * @param text content
   * @param numChars size
   * @returns self
   */
  printQR(text: string, numChars: number, width?: number) {
    console.warn("Not supported: printQR");
    return this;
  }

  /**
   * Convert image at url to raw image lines
   * @param url image url
   * @param numChars size of image
   * @param hiRes high resolution
   * @returns self
   */
  async getImage(url: string, numChars: number, hiRes?: boolean | number): Promise<RawLine[]> {
    console.warn("Not supported: getImage");
    return [];
  }

  /**
   * Convert text to qr code raw image lines
   * @param url content
   * @param size size of the code
   * @returns self
   */
  getQR(url: string, size: number): RawLine[] {
    console.warn("Not supported: getQR");
    return [];
  }

  /**
   * Print raw image
   * @param lines image lines
   */
  printRawImage(lines: RawLine[]) {
    console.warn("Not supported: printRawImage");
  }

  /**
   * Asks status of the printer, the status is returned in the 'data' event
   * @returns self
   */
  status() {
    console.warn("Not supported: status");
    return this;
  }

  /**
   * Feed the paper
   * @param n number of steps
   * @returns
   */
  feed(n?: number) {
    console.warn("Not supported: feed");
    return this;
  }

  /**
   * Cuts the paper
   * @returns self
   */
  cut() {
    console.warn("Not supported: cut");
    return this;
  }

  cutWithFeed() {
    if (this.cutPreFeed) {
      this.feed(this.cutPreFeed);
    }
    this.cut();
    if (this.cutPostFeed) {
      this.feed(this.cutPostFeed);
    }
    return this;
  }

  /**
   * Set alignment
   * @param n alignment
   * @returns self
   */
  align(n: TextAlign) {
    console.warn("Not supported: align");
    return this;
  }

  /**
   * Set align to left
   * @returns self
   */
  left() {
    this.align(0);
    return this;
  }

  /**
   * Set align to center
   * @returns self
   */
  center() {
    this.align(1);
    return this;
  }

  /**
   * Set align to right
   * @returns self
   */
  right() {
    this.align(2);
    return this;
  }

  /**
   * Set font style
   * @param n font style number
   * @returns self
   */
  font(n: number) {
    console.warn("Not supported: font");
    return this;
  }

  /**
   * Toggle bold mode
   * @param on on/off
   * @returns self
   */
  bold(on: boolean) {
    console.warn("Not supported: bold");
    return this;
  }

  /**
   * Toggle italic mode
   * @param on on/off
   * @returns self
   */
  italic(on: boolean) {
    console.warn("Not supported: italic");
    return this;
  }

  /**
   * Set color
   * @param n color number
   * @returns self
   */
  color(n: number) {
    console.warn("Not supported: color");
    return this;
  }

  /**
   * Sets font style to normal
   * @returns self
   */
  normal() {
    this.font(0);
    this.lineWidth = this.normalLineWidth;
    return this;
  }

  /**
   * Sets font style to compressed
   * @returns self
   */
  compress() {
    this.font(1);
    this.lineWidth = this.cnormalLineWidth;
    return this;
  }

  pxSize = 24;

  /**
   * Sets font size
   * @param w width scale
   * @param h height scale
   * @returns self
   */
  fontSize(w?: number, h?: number) {
    console.warn("Not supported: fontSize");
    return this;
  }

  get fontWidthScale() {
    console.warn("Not supported: fontWidthScale");
    return 1;
  }

  /**
   * Reset font style and size
   * @returns self
   */
  resetFont() {
    this.fontSize(0, 0);
    this.bold(false);
    return this;
  }

  get currentLineWidth() {
    console.warn("Not supported: currentLineWidth");
    return this.lineWidth;
  }

  /**
   * Convert text to raw buffer
   * @param text
   * @returns raw buffer
   */
  getText(text: string): Buffer | number[] {
    console.warn("Not supported: getText");
    return [];
  }

  /**
   * Get new line command
   * @returns array buffer
   */
  getLine(): Buffer | number[] {
    console.warn("Not supported: getLine");
    return [];
  }

  /**
   * Get prefix for each line
   * @returns array buffer
   */
  getPreLine(): Buffer | number[] {
    return [];
  }

  /**
   * Get posfix for each line
   * @returns array buffer
   */
  getPostLine(): Buffer | number[] {
    return [];
  }

  /**
   * Flush current line (do nothing)
   */
  flushLine() {
    return this;
  }

  /**
   * Print a line of text
   * @param text text to print
   * @returns self
   */
  text(text: string) {
    this.raw(this.getPreLine());
    this.rawText(this.getText(text));
    this.raw(this.getPostLine());
    this.raw(this.getLine());
    return this;
  }

  /**
   * Print lines of text
   * @param text text to print
   * @returns self
   */
  textWithBreak(text: string) {
    let currentBuffers: Buffer[] = [];
    let currentLength = 0;

    const addLine = () => {
      if (!currentBuffers.length) {
        this.feed(3);
      } else {
        this.raw(this.getPreLine());
        this.rawText(Buffer.concat(currentBuffers));
        this.raw(this.getPostLine());
        this.raw(this.getLine());
        this.flushLine();
      }
      currentBuffers = [];
      currentLength = 0;
    };
    for (let t of text.trim().split(/(\s+|[\u0100-\uffff])/)) {
      if (!t) continue;
      if (t === "\r" || t === "\n") {
        addLine();
        continue;
      }

      const bufs = t.split("").map(c => {
        const array = this.getText(c);
        return array instanceof Buffer ? array : Buffer.from(new Uint8Array(array));
      });

      const lens = bufs.map(b => (b.length > 1 ? 2 : 1));
      const sumLens = lens.reduce((a, b) => a + b, 0);

      if (currentLength && currentLength + sumLens > this.currentLineWidth) {
        addLine();
      }

      if (currentLength + sumLens <= this.currentLineWidth) {
        currentBuffers.push(Buffer.concat(bufs));
        currentLength += sumLens;
        continue;
      }

      for (let i = 0; i < lens.length; i++) {
        if (currentLength && currentLength + lens[i] > this.currentLineWidth) {
          addLine();
        }
        currentBuffers.push(bufs[i]);
        currentLength += lens[i];
      }
      if (currentLength) {
        addLine();
      }
    }
    if (currentLength) {
      addLine();
    }
    return this;
  }

  /**
   * Prints a table
   * @param args repeating sequence of [column width, content]
   * when column width < 0, align to the right
   * when |column width| < 1, fill the remaining space
   * content is string or string[]
   * @returns self
   */
  table(...args: TableType) {
    const argcols: [number, string | string[]][] = _.chunk(args, 2) as any;
    const table = new PrintTable(this);
    for (let [n, arg] of argcols.entries()) {
      table.column(arg[1], {
        size: Math.floor(Math.abs(arg[0]) * this.fontWidthScale),
        align: arg[0] < 0 ? "right" : "left",
        padding: n === argcols.length - 1 ? 0 : 1,
      });
    }
    table.print();
    return this;
  }

  printTable(table: PrintTable) {
    const maxLine = table.fit(this.currentLineWidth);
    for (let i = 0; i < maxLine; i++) {
      this.raw(this.getPreLine());
      for (let column of table.columns) {
        if (column.bold !== undefined) {
          this.bold(column.bold);
        }
        if (column.color !== undefined) {
          this.color(column.color);
        }
        this.rawText(column.lines[i]);
        if (column.bold !== undefined) {
          this.bold(false);
        }
        if (column.color !== undefined) {
          this.color(0);
        }
      }
      this.raw(this.getPostLine());
      this.raw(this.getLine());
    }
    return this;
  }

  /**
   * Print a table with explicit align
   * @param args [column width, align, content]
   * @returns self
   */
  tableWithAlign(...args: TableAlignType) {
    const argcols: [number, "left" | "center" | "right" | number, string | string[]][] = _.chunk(args, 3) as any;
    const table = new PrintTable(this);
    _.each(argcols, (arg, n) => {
      table.column(arg[2], {
        size: Math.floor(Math.abs(arg[0])),
        align: typeof arg[1] === "string" ? arg[1] : arg[1] < 0 ? "left" : arg[1] > 0 ? "right" : "center",
        padding: n === argcols.length - 1 ? 0 : 1,
      });
    });
    table.print();
    return this;
  }

  /**
   * Create a table
   * @returns the table
   */
  ntable() {
    return new PrintTable(this);
  }

  /**
   * Create a graph
   */
  graph(width: number, height: number) {
    return new PrintGraph(this, width, height);
  }

  protected _dpi = 12;

  protected get _canvasHeight() {
    return this._dpi * 16;
  }

  get pxWidth() {
    return this._dpi * this.normalLineWidth;
  }

  /**
   * Print bitmap text
   */
  async bitmapText(opts: {
    text: string;
    align?: "left" | "center" | "right";
    size?: number;
    fontFamily?: string;
    bold?: boolean;
    color?: number;
    scaleY?: number;
    hiRes?: boolean | number;
    tight?: boolean;
    italic?: boolean;
  }) {
    const renderer = this._prepareContext();
    if (!renderer) return false;
    await renderer.bitmapText(opts);
    return true;
  }

  /**
   * Print bitmap table
   */
  async bitmapTable(
    table: PrintTable,
    opts: {
      fontFamily?: string;
      bold?: boolean;
      color?: number;
      scaleY?: number;
      hiRes?: boolean | number;
      tight?: boolean;
      italic?: boolean;
      fontSize?: number;
      colPadding?: number;
    },
  ) {
    const renderer = this._prepareContext();
    await renderer.bitmapTable(table, opts);
  }

  fontFamily = "'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Roboto', sans-serif";
  _renderer: BaseRenderer;

  protected _prepareContext() {
    if (!this._renderer) {
      if (this.printer?.fontsInfo && Object.keys(this.printer?.fontsInfo?.fonts || {}).length && (this.printer?.conf?.opts?.bitmapRenderer ?? "skia") === "skia") {
        this._renderer = new SkiaRenderer(this);
      } else if (typeof document !== "undefined") {
        this._renderer = new CanvasRenderer(this);
      }
    }
    return this._renderer;
  }

  protected _disposeContext() {
    if (this._renderer) {
      this._renderer.disposeContext();
      this._renderer = null;
    }
  }

  async printCmds(cmds: ThermalCmd[]) {
    const renderer = this._prepareContext();
    if (!renderer) return;
    for (let cmd of cmds) {
      switch (cmd.type) {
        case "text":
          await renderer.printTextCmd([cmd]);
          break;

        case "multiText":
          await renderer.printTextCmd(cmd.parts);
          break;

        case "table":
          if (cmd.fill) {
            this.fill(cmd.fill);
          } else {
            const table = new PrintTable(this);
            for (let col of cmd.cols) {
              table.column(col.text, {
                fontSize: col.fontSize,
                yScale: col.yScale,
                fontFamily: col.fontFamily,
                bold: col.bold,
                italic: col.italic,
                color: col.color,
                inverted: col.inverted,

                size: col.w,
                align: col.align,
              });
            }
            table.fit(this.currentLineWidth);
            await this.bitmapTable(table, {
              fontSize: cmd.fontSize,
              scaleY: cmd.yScale,
              fontFamily: cmd.fontFamily,
              bold: cmd.bold,
              italic: cmd.italic,
              color: cmd.color,

              colPadding: cmd.colPadding,
            });
          }
          break;

        case "qrcode":
          this.align(
            cmd.align === "center" ? TextAlign.Center : cmd.align === "right" ? TextAlign.Right : TextAlign.Left,
          );
          if(this.nativeQR || !this.useBitmapImage && this.useLineImage && !this.doubleVDensity) {
            this.printQR(cmd.text, cmd.size, cmd.scale);
          } else {
            const g = this.graph(this.pxWidth, 0);
            const { width, height } = g.mesaureQrCode(cmd.text, undefined, cmd.scale);
            g.resize((((width + 7) / 8) | 0) * 8, height);
            g.qrCode(cmd.text, 0, 0, width, height, undefined, cmd.scale);
            await g.print();
          }
          break;

        case "image":
          this.align(
            cmd.align === "center" ? TextAlign.Center : cmd.align === "right" ? TextAlign.Right : TextAlign.Left,
          );
          await this.printImageData(cmd.data, cmd.w, cmd.h, cmd.hiRes);
          break;

        case "cut": {
          await this.cut();
          break;
        }

        case "cashBox": {
          await this.cashBox(cmd.which, cmd.time);
          break;
        }

        case "feed": {
          await this.feed(cmd.offset);
          break;
        }

        case "beep": {
          await this.beep(cmd.times, cmd.duration);
          break;
        }
      }
    }
    this._disposeContext();
  }

  /**
   * Print an table if scope is not null
   * @param context reference scope
   * @param args sees table, but when the content is prefixed with `$`, it is key path of scope. When prefixed with `$$`, it represents raw text
   * for example, $num -> scope['num'], $$num -> "num"
   * @returns self
   */
  condTable(context: any, ...args: TableType) {
    return this.condTableCore({}, context, ...args);
  }

  /**
   * Print an empty table
   * @param context reference scope
   * @param args sees condTable
   * @returns self
   */
  condTableEmpty(context: any, ...args: TableType) {
    return this.condTableCore({ empty: true }, context, ...args);
  }

  private condTableCore(opts, context: any, ...args: TableType) {
    const argcols: [number, string | string[]][] = _.chunk(args, 2) as any;
    let has = true;
    for (let arg of argcols) {
      const processOne = item => {
        if (item && item.startsWith && item.startsWith("$$")) {
          return item.substr(2);
          // fix name starts with '$'
        } else if (item[0] === "$") {
          const cmd = item.substr(1);
          const cargs = cmd.split("|");
          let v = _.get(context, cargs[0]);
          if (v === undefined || v === null) {
            has = false;
            return "";
          }
          if (cargs.length > 1) {
            const args = cargs.length > 2 ? JSON.parse(cargs[2]) : [];
            v = (this.context.constructor as VueConstructor<any>)?.filter?.(cargs[1])?.(this.context, v, ...args);
          }
          if (v !== undefined && v !== null) v = v.toString();
          if (v === undefined || v === null || (v === "" && opts.empty)) {
            has = false;
            return "";
          }
          return v;
        }
        return item;
      };

      const av = arg[1];
      if (av instanceof Array) arg[1] = _.map(av, it => processOne(it)).join("");
      else arg[1] = processOne(av);
    }
    if (has) {
      this.table(...(_.flatten(argcols) as any));
    }
    return this;
  }

  /**
   * Print a separator
   * @param c character to fill
   * @param n number of characters (defaults to line width)
   * @returns self
   */
  fill(c?: string, n?: number) {
    // this.center()

    c = c || "-";
    n = n || this.currentLineWidth;
    const cb = Buffer.from(new Uint8Array(Buffer.from(c)));
    const repeat = (n / cb.length) | 0;
    this.text(c.repeat(repeat));
    // this.left();
    return this;
  }

  /**
   * Prints bar code
   * @param type Bar code type
   * @param code Bar code data
   * @returns self
   */
  printCode(
    type: "upca" | "upce" | "ean8" | "ean13" | "code39" | "itf" | "codabar" | "code93" | "code128",
    code: string,
    showChars?: false | "above" | "below",
    height?: number,
  ) {
    console.warn("Not supported: printCode");
    return this;
  }

  /**
   * Sets bar code height
   * @param height
   * @returns self
   */
  codeHeight(height: number) {
    console.warn("Not supported: codeHeight");
    return this;
  }

  /**
   * Resets styling
   * font -> normal
   * align -> left
   * lineHeight -> default
   * @returns self
   */
  reset() {
    this.normal().left().lineHeight();
    return this;
  }

  /**
   * Set current line height
   * @param height Line height
   * @returns self
   */
  lineHeight(height?: number) {
    console.warn("Not supported: lineHeight");
    return this;
  }

  /**
   * Open cash box
   * @param which GPIO pin
   * @param time ms to stay high
   * @returns self
   */
  cashBox(which: number = 48, time: number = 50) {
    console.warn("Not supported: cashBox");
    return this;
  }

  beep(times = 1, duration = 5) {
    console.warn("Not supported: beep");
    return this;
  }

  get usePageMode(): boolean {
    return this.printer?.conf?.opts?.pageMode ?? false;
  }

  get useLineImage(): boolean {
    return this.printer?.conf?.opts?.lineImage ?? true;
  }
  
  get useBitmapImage(): boolean {
    return this.printer?.conf?.opts?.bitmapImage ?? true;
  }

  get nativeQR(): boolean {
    return this.printer?.conf?.opts?.nativeQR ?? true;
  }

  get lineImageHeight(): number {
    return this.printer?.conf?.opts?.lineImageHeight ?? 0;
  }

  pageMode() {
    console.warn("Not supported: pageMode");
    return this;
  }

  pageModePos(x: number, y: number, w: number, h: number) {
    console.warn("Not supported: pageModePos");
    return this;
  }

  pageModePrint() {
    console.warn("Not supported: pageModePrint");
    return this;
  }

  pageModeDirection(dir: "ltr" | "btt" | "rtl" | "ttb" = "ltr") {
    console.warn("Not supported: pageModeDirection");
    return this;
  }

  multiCodePage(codePage: MultiCodePage) {
    console.warn("Not supported: multiCodePage");
    return this;
  }
}
