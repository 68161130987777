
import { Component, Prop, Vue, Watch, FindType } from "@feathers-client";
import { TableSessionAction } from "~/plugins/table/session";

@Component
export default class PendingAction extends Vue {
  @Prop()
  session: FindType<"tableSessions">;

  get pendingAction() {
    return this.$tableManager.tableSessionActions.find(it => it.session === this.session._id) as TableSessionAction;
  }

  async openPendingAction() {
    await this.$openDialog(import("./pendingActionDialog.vue"), {
      pendingAction: this.pendingAction,
      session: this.session,
    }, {
      contentClass: "h-full d-flex justify-end theme--dark"
    })
  }
}
