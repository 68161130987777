import { PrinterBase, PrinterConf } from "./base";
import { PrinterServer, PrinterCommand } from "../index";
import { PrintJob } from "../printQueue";
import { connect, mdns, SocketDevice } from "../ports/socket";

export interface SocketConf extends PrinterConf {
  port: "socket";
  socketHost: string;
  socketPort: number;
}

export class SocketPrinter extends PrinterBase<SocketConf, SocketConf> {
  constructor(server: PrinterServer, conf: SocketConf) {
    super(server, conf);
    this.sendSupported = true;
  }

  conn: SocketDevice;

  async _connectInner() {
    const connectOne = async (ip: string) => {
      if (this.conn) {
        await this.conn.close();
        this.conn = null;
      }
      const d = await connect(ip, this.conf.socketPort);
      d.once("close", () => {
        if (this.conn === d) {
          d.removeAllListeners("data");
          this.conn = null;
          this.onDisconnected?.("Connection closed");
        }
      });
      d.on("data", data => {
        if (this.conn === d) {
          this.pushData(data);
        } else {
          console.warn("Data from unknown connection");
          d.close();
        }
      });
      this.conn = d;
      return d;
    };
    try {
      return await connectOne(this.conf.address);
    } catch (e) {
      if (this.conf.socketHost) {
        const result = await mdns({
          name: this.conf.socketHost,
        });
        const ip = result[0].ip;
        if (ip) {
          const ipParts = ip.split(".");
          const isValidV4 = ipParts.length === 4 && ipParts.every(p => !isNaN(parseInt(p, 10)) && +p >= 0 && +p <= 255);
          if (isValidV4) {
            await connectOne(ip);
            if (this.parent) {
              try {
                this.conf.address = ip;
                this.parent.savePrinters();
              } catch (e) {
                console.warn(e);
              }
            }
          } else {
            throw e;
          }
        }
      } else {
        throw e;
      }
    }
  }

  onDisconnectedCore() {
    if (this.conn) {
      this.conn.removeAllListeners("data");
      this.conn.close().catch(e => console.warn(e));
      this.conn = null;
    }
  }

  async initCore() {}

  async tryConnectCore() {
    await this.queuePrinter(async () => {});
  }

  async printCore(job: PrintJob) {
    await this.queuePrinter(async () => {
      if (!job.cashBoxOnly) {
        try {
          const asb = await this.preAsbCheck();

          if (!asb) {
            this.onDisconnected("Printer failed to respond to ASB check");
            throw new Error("Printer failed to respond to ASB check");
          }
        } catch (e) {
          job.lastPreFailed = true;
          throw e;
        }
      }

      const buf = job.getDataWithRetry(this.parent.iconv);

      try {
        await this.conn.send(buf);
        await this.conn.flush();
      } catch (e) {
        if (this.conn) {
          await this.conn.close();
          this.conn = null;
        }
        throw e;
      }

      if (!job.cashBoxOnly) {
        await this.postAsbCheck();
      }
    });
  }

  async disconnectCore() {
    const d = this.conn;
    if (d) {
      d.removeAllListeners("data");
      this.conn = null;
      await d.close();
    }
    const qs = this._queues;
    if (qs) {
      this._queues = null;
      for (const q of qs) {
        q.reject(new Error("Disconnected"));
      }
    }
  }

  async requestNewDeviceCore() {
    return this.conf;
  }

  async send(buf: Buffer): Promise<void> {
    try {
      await this.conn.send(buf);
    } catch (e) {
      console.warn(e);
      if (this.conn) {
        await this.conn.close();
        this.conn = null;
      }
      this.onDisconnected(`Failed to send data: ${e.message}`);
    }
  }

  async statusProbeCore(): Promise<void> {
    if (this.conf.opts?.multiSessionProbe) {
      const c = this.conn;
      this.conn = null;
      c.close().catch(console.warn);
    } else {
      return super.statusProbeCore();
    }
  }

  async idleTimeoutCore(): Promise<void> {
    if (this.conf.opts?.multiSessionProbe) {
      const c = this.conn;
      this.conn = null;
      c.close().catch(console.warn);
    } else {
      return super.idleTimeoutCore();
    }
  }

  async queuePrinterCore(): Promise<void> {
    if (!this.conn) {
      try {
        await this._connectInner();
      } catch (e) {
        this.onDisconnected(`Failed to connect: ${e.message}`);
        throw e;
      }
    }
  }
}
