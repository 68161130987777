
export default {
    "appUsers": {
        fields: ["email", "name", "phone"],
        view: {
            name: ["email", "name", "phone"]
        }
    },
    "users": {
        fields: ["email"]
    },
    "categories": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    },
    "ingredientCats": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    },
    "ingredientSuppliers": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    },
    "sections": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    },
    "products": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "productOptions": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "shops": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "appUsers": {
        fields: ["email"],
        view: {
            name: ["email"],
        }
    },
    "tableSessions": {
        fields: ["sessionName"],
        view: {
            name: ["sessionName"],
        }
    },
    "payments": {
        fields: ["paymentId"],
        view: {
            name: ["_id", "method", "date"],
        }
    },
    "stampEvents": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "coupons": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "orderDiscounts": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "kitchenPrinters": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "waterBars": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "vipEvents": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "vipLevels": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "coinsSets": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "staffs": {
        fields: ["name"],
        view: {
            name: ["name", "code"],
        }
    },
    "tvMenus": {
        fields: ["name.value"],
        view: {
            name: ["name"],
            translate: true
        }
    },
    "subCategories": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    },
    "paymentMethods": {
        fields: ["name.value"],
        view: {
            name: ['name'],
            translate: true,
        }
    }
}
