
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { TableSession } from "~/plugins/table/session";
import type { CartItem } from "@common/table/cart";

@Component
export default class OrderSystemProductOptions extends Vue {
  @Prop()
  session: TableSession;

  @Prop()
  cart: CartItem;

  @Prop(Boolean)
  dark: boolean;
}
