
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import _ from "lodash";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
  components: {
    QrcodeStream,
  },
})
export default class Supacity extends Vue {
  @Prop()
  session: TableSession;

  @Prop(Number)
  partialPayment: number;

  onDecode(code) {
    this.$root.$emit("scanner", { code });
  }

  resetSupacity() {
    this.session.supacityInfo = null;
  }
}
