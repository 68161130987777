// @ts-ignore
import { PrintSequence } from "pos-printer/printSequence";
// @ts-ignore
import moment from "moment";
import _ from "lodash";
import type DB from "@db";
import { checkID } from "@common";
import { getSplitName, getSortProducts } from "./util";
// @ts-ignore
import { compile, compileLabel, PrinterTemplateNodeBase } from "~/dep/pos-printer-template/index";
// @ts-ignore
import { createContext } from "~/dep/pos-printer-template/util";
import LRUCache from "lru-cache";
import {
  AllPrintJobData,
  AllPrintJobTemplateData,
  KitchenViewOptions,
  OrderKitchenView,
  OrderKitchenViewData,
  ProductLine,
  ReceiptJob,
  ReceiptJobData,
  ReceiptViewOptions,
  ReviewJob,
  ReviewJobData,
  TaiwanInvoiceJobData,
} from "./printJobTypes";

export * from "./printJobTypes";

const defaultTemplates = require.context("!!raw-loader!./templates", true, /\.vue$/);
export const defaultTemplateMap: Record<string, any> = {};

export function registerTemplateContext(context: __WebpackModuleApi.RequireContext) {
  for (let key of context.keys()) {
    const okey = key;
    if (key.endsWith(".vue")) {
      key = key.substring(0, key.length - 4);
    }
    const parts = key.substring(2).split("/");
    if (parts[parts.length - 1] === "index") {
      parts.pop();
    }
    const kebab = parts.map(it => _.kebabCase(it)).join("-");
    const camel = parts.map((it, i) => (i ? _.upperFirst(_.camelCase(it)) : _.camelCase(it))).join("");
    const partialKebab = parts.join("-");

    defaultTemplateMap[kebab] = context(okey).default;
    defaultTemplateMap[camel] = context(okey).default;
    defaultTemplateMap[partialKebab] = context(okey).default;
  }
}
registerTemplateContext(defaultTemplates);

let defaultTemplateLoader: (key: string) => Promise<string> = null;

export function setTemplateLoader(loadTemplate: (key: string) => Promise<string>) {
  defaultTemplateLoader = loadTemplate;
}

const cache = new LRUCache<string, PrinterTemplateNodeBase<any>>({
  max: 100,
});

export async function resolveTemplate(
  templateName: string,
  printerType: "label" | "thermal" = "thermal",
  loadTemplate?: (key: string) => Promise<string>,
) {
  if (!loadTemplate) {
    loadTemplate = defaultTemplateLoader;
  }
  let template: string = await loadTemplate?.(templateName);
  if (!template) {
    template = defaultTemplateMap[templateName];
  }
  if (template) {
    return await compileTemplate(template, printerType);
  }
  return null;
}

export async function compileTemplate(template: string, printerType: "label" | "thermal" = "thermal") {
  let compiled: PrinterTemplateNodeBase<any> = cache.get(template);
  if (!compiled) {
    cache.set(template, (compiled = printerType === "label" ? compileLabel(template) : compile(template)));
  }
  return compiled;
}

export async function printTemplate<T extends AllPrintJobTemplateData = any>(opts: {
  sequence: PrintSequence;
  template?: string; // id
  templateName: string; //tag
  loadTemplate?: (key: string) => Promise<string>;
  data: T;
  printerType?: "label" | "thermal";
}) {
  let { sequence, template, templateName, loadTemplate, data, printerType } = opts;

  // console.log('printTemplate', template)

  if (!printerType) {
    printerType = sequence?.printer?.conf?.type as any;
  }

  if (!loadTemplate) {
    loadTemplate = defaultTemplateLoader;
  }

  if (!template) {
    template = await loadTemplate?.(templateName);
  }
  if (!template) {
    template = defaultTemplateMap[templateName];
  }

  if ((sequence.context as any).$config?.devMode) {
    console.log("Print template", template, data);
  }

  const compiled = await compileTemplate(template, printerType);

  // console.log('compiled', compiled)

  data.printerConf = sequence?.printer?.conf?.opts;
  if (data.printerConf) {
    data.smallSize = data.printerConf.lineWidth <= 40;
  }
  const context = createContext(sequence.context, data);
  context.resolveTemplate = resolveTemplate;
  await compiled.execute(sequence, context);
  sequence.debugData = data;
}

// for kitchen / pickup point
export async function kitchenSequencer(opts: {
  sequence: PrintSequence;
  job: OrderKitchenView;
  jobOptions: KitchenViewOptions;
  template?: string;
  printerType?: "thermal" | "label";
  shop?: typeof DB.Shop._mongoType;
  jobName?: string;
}) {
  await printTemplate<OrderKitchenViewData>({
    sequence: opts.sequence,
    template: opts.template,
    templateName:
      opts.printerType === "thermal" ? (opts.job.jobType === "table-kitchen" ? "kitchen" : "waterBar") : "kitchenLabel",
    printerType: opts.printerType ?? "thermal",
    data: {
      shop: opts.shop,
      session: opts.job.session,
      jobOptions: opts.jobOptions,
      job: opts.job,
      jobName: opts.jobName,
      code: `${opts.job.session._id}/${opts.job.items.map(it => it.index).join(",")}`,
      sourceName: opts.job.sourceSessionName
        ? `${opts.job.sourceSessionName} - ${opts.job.items.map(it => it.sourceIndex).join(",")}`
        : undefined,
      targetName: `${opts.job.session.sessionName} - ${opts.job.items.map(it => it.index).join(",")}`,
      setRef: opts.job.items.length === 1 && `${opts.job.items.map(it => `[ ${it.rootProduct} ]`).join(", ")}`,
      sourceTableName: opts.job.sourceTableName
        ? `${opts.job.sourceTableName}${getSplitName(opts.job.sourceTableSplit)}`
        : null,
      targetTableName: opts.job.tableName ? `${opts.job.tableName}${getSplitName(opts.job.tableSplit)}` : null,
      todayPickUp: moment(opts.job.session.pickUpTime).isSame(moment(), "day"),
      dineIn: opts.job.session.type === "dineIn" || opts.job.session.type === "dineInNoTable",
      staffName: opts.job.staffName,
      deviceId: opts.job.deviceId,
    },
  });
}

// @deprecated
export const tableOrderSequencer = kitchenSequencer;

// for order review
export async function orderReviewSequencer(opts: {
  sequence: PrintSequence;
  job: ReviewJob;
  jobOptions: KitchenViewOptions;
  template?: string;
  shop?: typeof DB.Shop._mongoType;
}) {
  if (opts.job.session) {
    opts.job.session.totalQuantity = opts.job.session.products
      .filter(it => it.status !== "cancel")
      .reduce((a, b) => a + b.quantity, 0);
  }

  let surcharge = _.groupBy(
    _.filter(opts?.job?.session?.surcharges, it => !it.disabled),
    it => it.percent,
  );
  const surcharges = _.values(surcharge).map(it => ({
    ...it[0],
    amount: _.sumBy(it, p => p.amount),
    accum: _.sumBy(it, p => p.accum),
  }));

  if (opts?.job?.session?.products) opts.job.session.products = getSortProducts(opts.job.session.products);
  await printTemplate<ReviewJobData>({
    sequence: opts.sequence,
    template: opts.template,
    templateName: "review",
    data: {
      shop: opts.shop,
      session: opts.job.session,
      jobOptions: opts.jobOptions,
      numOfGuests: _.sumBy(opts.job.session.tables, "capacity") || opts.job.session.desiredCapacity || 1,
      surcharges,
      dineIn: opts.job.session.type === "dineIn" || opts.job.session.type === "dineInNoTable",
    },
  });
}

// @deprecated
export const tableOrderRecordSequencer = orderReviewSequencer;

// for payment receipt

export interface ReceiptInput {
  job: ReceiptJob;

  previousRecords?: (typeof DB.Payment._mongoType)[];
  shop?: typeof DB.Shop._mongoType;
  cashbox?: boolean;
  rePrint?: boolean;
  toPay?: boolean;
  kioskName?: string;
  jobOptions?: ReceiptViewOptions;
  template?: string;
  products?: (typeof DB.TableSession._mongoType)["products"];
  pagination?: {
    offset?: number;
    offsetEnd?: number;
    remain?: number;
    total?: number;
    skipped?: boolean;
  };
}

export async function receiptSequencer(
  opts: {
    sequence: PrintSequence;
  } & ReceiptInput,
) {
  let {
    sequence,
    job,

    previousRecords,
    cashbox = false,
    rePrint = false,
    toPay = false,
    kioskName = null,
    template,
    products,
    pagination,
  } = opts;
  const { payment, paymentMethod, user } = job;

  if(opts?.jobOptions?.dontPrintZeroProduct){
    products = products.filter(p => {
      if(p.fromProduct){
        return p.fromProductPrice > 0
      }
      return p.price > 0
    });
  }

  const context = sequence.context;
  if (cashbox) {
    sequence.cashBox(48, 50);
  }

  const session = job.session;

  let paymentIndex = (session.payments || []).findIndex(p => checkID(p.payment, payment));

  if (payment?.status === "cancelled") {
    paymentIndex = (session.payments || []).length;
  }

  let previousPayments =
    session.payments?.length !== 1 || payment?.amount - (payment?.surcharge ?? 0) !== session.amount
      ? _.sumBy((session.payments || []).slice(0, paymentIndex), p => p.amount - (p.surcharge ?? 0))
      : 0;
  let newOutstanding: number;

  if (payment?.status === "cancelled") {
    previousPayments += payment.amount;
    newOutstanding = session.outstanding;
  } else {
    newOutstanding = session.amount - previousPayments - (payment?.amount - (payment?.surcharge ?? 0));
  }

  newOutstanding = Math.round(newOutstanding * 100) / 100;

  let methodName = paymentMethod?.name;
  if (payment?.methodSubType) {
    const subMethodInfo = paymentMethod?.subMethodInfos?.find(it => it.subMethod === payment.methodSubType);
    if (subMethodInfo) {
      methodName = subMethodInfo.name;
    }
  }

  await printTemplate<ReceiptJobData>({
    sequence,
    template,
    templateName: "receipt",
    data: {
      shop: opts.shop,
      session: opts.job.session,
      jobOptions: opts.jobOptions,

      numOfGuests: _.sumBy(opts.job.session.tables, "capacity") || opts.job.session.desiredCapacity || 1,
      surcharges: session.surcharges,
      dineIn: opts.job.session.type === "dineIn" || opts.job.session.type === "dineInNoTable",

      twInvoice: opts.job.twInvoice,
      printTwInvoice: opts.job.printTwInvoice,
      paymentMethod,
      methodName,
      user,
      availPoints: opts.job.availPoints,
      payment,
      cashbox,
      rePrint,
      toPay,
      kioskName,

      previousRecords,
      paymentIndex,
      previousPayments,
      newOutstanding,
      products,
      pagination,
      staffName: opts.job.staffName,
      paymentStaffName: opts.job.paymentStaffName,
    },
  });
}

// @deprecated
export const tablePaymentReceiptSequencer = receiptSequencer;

export async function taiwanTaxSequencer(props: {
  sequence: PrintSequence;
  invoice: typeof DB.TwInvoice._mongoType;
  props?: any;
  template?: string;
  printDetails?: "only" | boolean;
}) {
  await printTemplate<TaiwanInvoiceJobData>({
    sequence: props.sequence,
    template: props.template,
    templateName: "taiwanTax",
    data: {
      invoice: props.invoice,
      reprint: props.invoice.printTimes > 1,
      printDetails: props.printDetails,
    },
  });
}
