
import { Component, Prop, Vue, Watch, mixins, Ref, FindType } from "@feathers-client";
import { format, fromNumber, fromHumanNumber } from "@feathers-client/currency";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import SessionSummary from "~/components/table/orderSystem/session/summary.vue";
import SummaryBase from "~/components/table/summaryBase.vue";
import _, { values } from "lodash";
import { PropSync } from "vue-property-decorator";
import NestedRouter from "@feathers-client/components/NestedRouter.vue";

enum region {
  global,
  hongkong,
  taiwan,
}

type regionKey = keyof typeof region;
type regionValue = (typeof region)[regionKey];

type container = {
  cash: number;
  input: string;
};
@Component({
  components: {
    EditorDatePicker,
    EditorTextField,
    EditorObjectPickerNew,
    SessionSummary,
  },
})
export default class CloseShop extends mixins(SummaryBase) {
  reloadInterval = null;
  reloadCounter = 60;

  @Ref()
  router: NestedRouter;

  @Prop()
  status;

  @Prop()
  reasons;

  @PropSync("reason")
  reasonSync;

  @Prop()
  openTime;

  @Prop()
  expectedBalanceInt;

  @PropSync("openAmountClear")
  openAmountClearSync;

  @Prop()
  priceStr;

  @PropSync("openAmountNum")
  openAmountNumSync: Number;

  @PropSync("openAmount")
  openAmountSync: String;

  @PropSync("comment")
  commentSync;

  @PropSync("reservedCashboxAmount")
  reservedCashboxAmountSync: String | Number;

  @Watch("selectedPreset")
  selectedPresetOnChange() {
    if (this.selectedPreset !== null) {
      this.reservedCash = this.selectedPreset.toString();
    } else {
      this.reservedCash = "";
    }
  }

  @Watch("presetEnable")
  enableOnChange() {
    if (!this.presetEnable) {
      this.selectedPreset = null;
      this.reservedCash = "";
    }
  }

  @Watch("totalCashAmount")
  onChangeCashAmount() {
    this.openAmountSync = this.totalCashAmount;
  }

  fetch({ store }) {
    store.commit("SET_TITLE", { fullPage: true, tableMode: true, disableTextSelect: true, dark: true });
  }

  async mounted() {
    const shopData = await this.$feathers.service("shops").find({
      query: {
        _id: this.$store.state.user.shop,
        $limit: 1,
      },
    });

    const presetData = shopData.data[0]?.["closeShopReservedCashPreset"]?.map(it => it.amount);
    const presetAmount = presetData?.[0]?.map(it => it.value) ?? [];
    this.presetAmount = presetAmount;

    this.reloadInterval = setInterval(() => {
      this.reloadCounter--;
      if (this.reloadCounter === 0) {
        this.reloadCounter = 60;
        this.reloadCashboxLogs();
      }
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.reloadInterval);
  }

  presetAmount = [];
  presetEnable = false;
  selectedPreset: Number | null = null;

  regionKey: regionKey = "hongkong";
  regionValue: regionValue = region[this.regionKey];

  cashNumPad = false;
  cashNumPadIndex: number = 0;
  deviceNumPad = false;
  reservedCashNumPad = false;

  selectedMethod = "cashAmount";

  selectedOctopus = false;
  selectedCreditCard = false;

  octopusOpenAmount = "";
  octopusOpenAmountNum = 0;

  creditCardOpenAmount = "";
  creditCardOpenAmountNum = 0;

  octopusReason = "";
  creditCardReason = "";

  octopusComment = "";
  creditCardComment = "";

  reservedCash: string = "";
  reservedCashInt: number = 0;

  cashAmount: container[] = [
    { cash: 0.1, input: "" },
    { cash: 0.2, input: "" },
    { cash: 0.5, input: "" },
    { cash: 1, input: "" },
    { cash: 2, input: "" },
    { cash: 5, input: "" },
    { cash: 10, input: "" },
    { cash: 20, input: "" },
    { cash: 50, input: "" },
    { cash: 100, input: "" },
    { cash: 500, input: "" },
    { cash: 1000, input: "" },
  ];

  @Watch("selectedMethod")
  async checkCashCalculator() {
    if (this.selectedMethod === "cashAmount") {
      for (let i = 0; i < this.cashAmount.length; i++) {
        if (this.cashAmount[i].input !== "") {
          const confirm = await this.calculateCashWarning();

          if (!confirm) {
            this.selectedMethod = "cashCalculate";
          } else {
            await this.resetCashAmount();
            this.$emit("resetOpenAmount");
            this.openAmountClearSync = true;
            this.selectedMethod = "cashAmount";
          }
          return;
        }
      }
    }
  }

  async calculateCashWarning() {
    const confirm = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("pages.shops.selectedMethod.changeMethod") + " ？ ",
        content: this.$t("pages.shops.selectedMethod.prompContent"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        confirm: this.$t("pages.shops.selectedMethod.confirm"),
      },
      {
        maxWidth: "500px",
      },
    );
    return confirm;
  }

  async navigateDialog(navigateID?: string) {
    switch (navigateID) {
      case "cashReserved":
        const countingCashAmount = this.openAmountSync === "" ? this.expectedBalanceInt : this.openAmountSync;

        const confirmCash = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog2.vue"),
          {
            title: this.$t("pages.shops.navigateDialog.confirm") + " ？ ",
            content:
              this.$t("pages.shops.navigateDialog.countingCash", { amount: this.$price(countingCashAmount) }) + " ？ ",
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            confirm: this.$t("pages.shops.selectedMethod.confirm"),
          },
          {
            maxWidth: "500px",
          },
        );

        if (confirmCash) {
          this.openAmountSync = countingCashAmount.toString();
          this.router?.navigate?.(navigateID);
        }
        return;

      case "closeShop":
        const confirmCloseShopAmount = this.reservedCash === "" ? this.openAmountSync : this.reservedCash;

        const confirmClose = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog2.vue"),
          {
            title: this.$t("pages.shops.navigateDialog.confirmCloseShop") + " ？ ",
            content:
              this.$t("pages.shops.navigateDialog.countingReservedCash", {
                amount: this.$price(confirmCloseShopAmount),
              }) + " ？ ",
            titleClass: "text-xl font-medium",
            contentClass: "text-mbase font-normal",
            dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
            confirm: this.$t("pages.shops.selectedMethod.confirm"),
          },
          {
            maxWidth: "500px",
          },
        );

        if (confirmClose) {
          this.reservedCashboxAmountSync = +confirmCloseShopAmount;
          this.checkCashDrawer();
          this.confirmOpen();
        }
        return;

      default:
        return this.router?.navigate?.();
    }
  }

  get currentRegion() {
    return [this.regionKey, this.regionValue];
  }

  get totalCashAmount() {
    let total = 0;
    for (let i = 0; i < this.cashAmount.length; i++) {
      total += +this.cashAmount[i].cash * +this.cashAmount[i].input;
    }
    return total.toFixed(2);
  }

  get devicesPriceStr() {
    if (this.octopusOpenAmount || this.creditCardOpenAmount) {
      const parts = this.octopusOpenAmount.split(".") ?? this.creditCardOpenAmount.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.$price(this.octopusOpenAmountNum) ?? this.$price(this.creditCardOpenAmountNum);
    }
  }

  get reservedCashStr() {
    if (this.reservedCash) {
      const parts = this.reservedCash.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$shop.currencySymbol} ${parts.join(".")}`;
    } else {
      return this.priceStr;
    }
  }

  get reservedCashResult() {
    const finalAmount = Math.abs(+this.openAmountSync - +this.reservedCash);
    return this.$price(finalAmount);
  }

  get calMethods() {
    return [
      { _id: "cashAmount", name: { $t: "enum.pages.shop/pos/openClose.totalCash" }, icon: "$dollarCircle" },
      { _id: "cashCalculate", name: { $t: "enum.pages.shop/pos/openClose.cashAmount" }, icon: "$payment_CASH" },
    ];
  }

  get exportCashAmountTitle() {
    if (+this.openAmountSync > +this.reservedCash || +this.openAmountSync === +this.reservedCash) {
      return this.$t("enum.pages.shop/pos/openClose.reservePrettyCashExport");
    } else {
      return this.$t("enum.pages.shop/pos/openClose.reservePrettyCashImport");
    }
  }

  get smAndDown() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  reset() {
    this.$emit("reset");
  }

  confirmOpen() {
    this.$emit("confirmOpen");
  }

  doAction() {
    this.$emit("doAction");
  }

  async resetOpenAmount() {
    if (this.selectedMethod === "cashCalculate") {
      const confirm = await this.calculateCashWarning();

      if (!confirm) {
        return;
      } else {
        await this.resetCashAmount();
        this.$emit("resetOpenAmount");
        this.openAmountClearSync = true;
      }
    }
    this.$emit("resetOpenAmount");
    this.openAmountClearSync = true;
  }

  async resetCashAmount() {
    this.cashAmount.map(item => (item.input = ""));
  }

  @Prop()
  session: FindType<"shopSessions">;

  @Prop()
  staff: FindType<"staffs">;

  @Prop()
  lastCheck: FindType<"cashboxLogs">;

  @PropSync("openAmountNumInt")
  openAmountNumIntSync;

  @Prop()
  expectedDeltaInt;

  @Prop()
  diffInt;

  @PropSync("dialogOpen")
  dialogOpenSync: boolean;

  reloadCashboxLogs() {
    this.$emit("reloadCashboxLogs");
    this.reloadCounter = 60;
  }

  async checkCashDrawer() {
    const cashbox = await this.$feathers.service("cashboxLogs/action").create({
      type: "cashDrawerCheck",
      shop: this.$shop.shopId,
      session: this.session?._id,
      staff: this.staff?._id,
      reason: this.reasonSync,
      comment: this.commentSync,
      // expectedAmountInt: this.expectedBalanceInt,
      amountInt: this.openAmountNumIntSync,
      // deltaInt: this.diffInt,
    });

    await this.$feathers.service("actionLogs").create({
      staff: this.staff?._id,
      type: `openingClosing/cashDrawerCheck`,
      detail: { session: this.session, cashbox },
    });

    this.reasonSync = "";
    this.commentSync = "";
    // this.$emit('reloadCashboxLogs');
  }
}
