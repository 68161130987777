
import _ from "lodash";
import { DB, ObjectID } from "@db";
import { Component, Prop, Vue, Watch, mixins, PropSync, Ref } from "nuxt-property-decorator";
import { checkID, getID, FindType, VModel } from "@feathers-client";
import MemberInfo from "./memberInfo.vue";
import MemberHistory from "./memberHistory.vue";
import type NestedRouter from "@feathers-client/components/NestedRouter.vue";
import { ProductType } from "~/plugins/shop";
import { FindPopRawType } from "@feathers-client";

@Component
export default class memberDetail extends Vue {
  @Prop()
  memberId: ObjectID;

  memberDetail: FindType<'appUsers'> = null;

  @Prop({ type: Boolean })
  fullScreen: boolean;

  @Prop({ type: Boolean })
  mobile: boolean;

  @Prop({ type: Boolean })
  simple: boolean;

  session: FindType<"tableSessions">[] = null;

  @Ref("router")
  router: NestedRouter;

  sessionDetail: boolean = false;
  selectedDetail: FindType<"tableSessions"> = null;

  get sessionQuery() {
    const query = {
      user: this.memberId,
      type: { $nin: ["hidden"] },
      status: "done",
      $paginate: false,
      $sort: {
        paidTime: -1,
      },
    };

    return query;
  }

  async mounted() {
    await this.userHistory();
  }

  async userHistory() {
    this.session = await this.$feathers.service("tableSessions").find({
      query: {
        user: this.memberId,
        type: { $nin: ["hidden"] },
        status: "done",
        $paginate: false,
        $sort: {
          paidTime: -1,
        },
      },
      paginate: false
    });
  }

  async userDetail(_id: ObjectID) {
    const member = await this.$feathers.service("appUsers").get(getID(_id), {
      query: {
        $populate: ["vipLevel"],
      },
    });
    return (this.memberDetail = member);
  }

  thumb(product) {
    const it = typeof product === "object"
      ? (product as any as ProductType)
      : this.$shop.productDict[`${product}`];

    return (
      (it?.image && this.$thumb(it.image)) ||
      this.$config.appLogo ||
      require("~/assets/images/logo.png")
    );
  }

  get totalAmount() {
    return this.session?.reduce((total, session) => {
      return total + session.amount;
    }, 0);
  }

  get showSelectedSessionDetails() {
    if (this.session && this.selectedDetail) {
      return console.log(_.find(this.session, { _id: this.selectedDetail }));
    }
    return [];
  }
}
