import { PrintSequence, TextAlign } from ".";
import { RawLine } from "./rawLine";
import _ from "lodash";
import { WrappedContext } from "../common";
import type { PrinterBaseConf } from "../printers/baseConf";
import { PrintTable } from "./table";

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export class BBPOSHTMLPrintSequence extends PrintSequence {
  constructor(
    public context: WrappedContext,
    printer: PrinterBaseConf,
  ) {
    super(context, printer);
    this.raw(Buffer.from(`<html><body>`));
  }

  get isBBPay() {
    return this.printer?.conf?.opts?.isBBPay;
  }

  _useAttach = false;
  _useNativeLayout = true;
  useAttach() {
    this._useAttach = true;
    return this;
  }

  attachments: {
    filename: string;
    contentType: string;
    cid: string;
    content: string;
  }[] = [];

  pageSize = 0;

  raw(array: number[] | Buffer | Uint8Array) {
    this.pageSize += array.length;
    return super.raw(array);
  }

  finish(): void {
    this.raw(Buffer.from(`</body></html>`));
    super.finish();
  }

  async getImage(url: string, numChars: number, hiRes?: boolean): Promise<RawLine[]> {
    console.warn("Not supported: getImage");
    return [new RawLine(Buffer.from(`<img src="${url}"/>`), this.currentLineWidth)];
  }

  async printImage(url: string, wwidth: number, hiRes?: boolean) {
    if (this.isBBPay) {
      try {
        const resp = await fetch(url);
        const ct = resp.headers.get("content-type") || "image/png";
        const buf = await resp.arrayBuffer();
        const durl = `data:${ct};base64,${Buffer.from(buf).toString("base64")}`;
        this.raw(this.getPreLine());
        this.raw(Buffer.from(`<img src="${encodeURI(durl)}"></img>`));
        this.raw(this.getPostLine());
      } catch (e) {
        console.warn("Failed to print image", e);
      }
      return this;
    }
    this.raw(this.getPreLine());
    this.raw(Buffer.from(`<img src="${encodeURI(url)}"></img>`));
    this.raw(this.getPostLine());
    return this;
  }

  async printImageData(
    buffer: Uint8ClampedArray,
    width: number,
    height: number,
    hiRes?: boolean | number,
    color?: number,
  ) {
    if (this.isBBPay) {
      // @ts-ignore
      if (typeof OffscreenCanvas !== "undefined") {
        this.raw(this.getPreLine());
        // @ts-ignore
        const canvas = new OffscreenCanvas(width, height);
        const ctx = canvas.getContext("2d");
        const imageData = ctx.createImageData(width, height);
        if (color === 1) {
          // for red color
          for (let i = 0; i < buffer.length; i += 4) {
            const r = buffer[i];
            const isBitSet = r === 0;
            buffer[i] = isBitSet ? 255 : 255;
            buffer[i + 1] = isBitSet ? 0 : 255;
            buffer[i + 2] = isBitSet ? 0 : 255;
          }
        }
        imageData.data.set(buffer);
        ctx.putImageData(imageData, 0, 0);
        const blob = await canvas.convertToBlob();
        const buf = Buffer.from(await blob.arrayBuffer());

        const durl = `data:image/png;base64,${buf.toString("base64")}`;

        this.raw(this.getPreLine());
        this.raw(Buffer.from(`<img src="${encodeURI(durl)}"></img>`));
        this.raw(this.getPostLine());
      }
      return this;
    } else {
      return super.printImageData(buffer, width, height, hiRes, color);
    }
  }

  printQR(url: string, numChars: number) {
    this.raw(Buffer.from(`<qrcode>${url}</qrcode>`));
    return this;
  }

  getQR(url: string, size: number) {
    return [new RawLine(Buffer.from(`<qrcode>${url}</qrcode>`), size)];
  }

  feed(n: number) {
    for (let i = 0; i < Math.ceil(n / 10); i++) this.raw(Buffer.from("<br/>"));
    return this;
  }

  fill(c?: string, n?: number) {
    if (this.isBBPay) {
      this.raw(Buffer.from("<hr>"));
      return this;
    }
    c = c || "-";
    n = (n || this.currentLineWidth) * 2;
    const cb = Buffer.from(new Uint8Array(Buffer.from(c)));
    const repeat = (n / cb.length) | 0;
    this.raw(this.getPreLine());
    this.rawText(this.getText(c.repeat(repeat)));
    this.raw(this.getPostLine());
    return this;
  }

  cut() {
    return this;
  }

  currentAlign = TextAlign.Left;
  currentWidth = 0;
  currentHeight = 0;
  isBold = false;

  get fontWidthScale() {
    return (this.currentWidth + 2) / 2;
  }

  get currentLineWidth() {
    return Math.floor(this.lineWidth / this.fontWidthScale);
  }

  align(n: TextAlign) {
    this.currentAlign = n;
    return this;
  }

  bold(b) {
    this.isBold = b ?? false;
    return this;
  }

  fontSize(w: number, h: number) {
    this.currentWidth = w || 0;
    this.currentHeight = h || 0;
    return this;
  }

  getText(text: string) {
    return Buffer.from(text, "utf-8");
  }

  rawText(buf: Buffer) {
    this.raw(Buffer.from(`${escapeHtml(buf.toString())}`));
    return this;
  }

  text(text: string) {
    this.tableWithAlign(
      0,
      this.currentAlign === TextAlign.Center ? "center" : this.currentAlign === TextAlign.Right ? "right" : "left",
      text,
    );
    return this;
  }

  // @ts-ignore
  printTable(_table: any) {
    const table: PrintTable = _table;
    table.fillLine = false;
    const maxLine = table.fit(this.currentLineWidth);
    for (let i = 0; i < maxLine; i++) {
      this.raw(Buffer.from(`<row size=${Math.round(this.fontWidthScale * 20)}>`));
      let accum = 0;
      for (let j = 0; j < table.columns.length; j++) {
        const column = table.columns[j];
        const line = column.lines[i];

        if (line?.length) {
          this.raw(
            Buffer.from(
              `<column offset=${
                column.align === "right" && j === table.columns.length - 1
                  ? "last"
                  : Math.floor(((accum + (column.linesPadLeft[i] || 0)) / this.currentLineWidth) * 100) / 100
              } ${this.isBold ? "bold=1" : ""} >`,
            ),
          );
          this.raw(line);
          this.raw(Buffer.from(`</column>`));
        }

        accum += column.size;
      }
      this.raw(Buffer.from("</row>"));
      if (!this.isBBPay && this.pageSize > 3500) {
        this.pageSize = 0;
        this.raw(Buffer.from("</p></body></html><html><body>"));
      }
    }

    return this as any;
  }

  getLine() {
    return Buffer.alloc(0);
  }

  getPreLine() {
    return Buffer.from(
      `<p align="${
        this.currentAlign === TextAlign.Left ? "left" : this.currentAlign === TextAlign.Right ? "right" : "center"
      }" ${this.isBold ? "bold=1" : ""} size=${Math.round(this.fontWidthScale * 20)}>`,
    );
  }

  getPostLine() {
    if (!this.isBBPay && this.pageSize > 3500) {
      this.pageSize = 0;
      return Buffer.from("</p></body></html><html><body>");
    }
    return Buffer.from("</p>");
  }

  toHTML() {
    return this.getJob("").data.toString();
  }
}
