import _ from "lodash";
import { LabelSequenceBase } from "./base";
import { BitmapOpts } from "../common";
import { convertImage } from "../printSequence/escpos/image";
import { PrintJob } from "../printJob";

export class ZPLLabelSequence extends LabelSequenceBase {
  initInner() {
    super.initInner();
  }

  getText(text: string): Buffer | number[] {
    return this.printer.iconv.encode(text, this.codePage);
  }

  decodeText(buf: Buffer) {
    return this.printer.iconv.decode(buf, this.codePage);
  }

  cmd(line: string) {
    this.raw(this.printer.iconv.encode(line + "\r\n", this.codePage));
    return this;
  }

  inited = false;
  _sizeSet = false
  _direction = 0
  _dirSet = false

  ensureInit() {
    if (!this.inited) {
      this.reset();
    }
  }

  size(w: number, h: number) {
    this._curWidth = w;
    this._curHeight = h;
    this._sizeSet = true;
    return this;
  }

  direction(n: number): this {
    this._direction = n;
    this._dirSet = true;
    return this;
  }


  page() {
    this.ensureInit();
    if (!this._sizeSet) {
      this.cmd(`^LL${this._curHeight * 8}.${this._curWidth * 8}^LH0,0`);
      this.cmd(`^PW${this._curWidth * 8}`);
    }
    if (this._dirSet) {
      this.cmd(`^PO${this._direction ? 'N' : 'I'}`);
    }
    return this;
  }

  feed(n: number) {
    return this;
  }

  rect(x: number, y: number, w: number, h: number) {
    this.cmd(`^FO${x},${y}`);
    this.cmd(`^GB${w},${h},1^FS`);
    return this;
  }

  async textRaw(x: number, y: number, text: string, font = null, sizeW = 1, sizeH = 1, r = 0) {
    this.cmd(`^FO${x},${y}`);
    if (font && isNaN(+font)) {
      this.cmd(`^CW9,${font}`);
      this.cmd(`^A9N,${sizeH * 24},${sizeW * 24}^FD${text}^FS`);
    } else {
      this.cmd(`^A1N,${sizeH * 16},${sizeW * 8}^FD${text}^FS`);
    }
    return this;
  }

  async vtext(text: string, x: number, y: number, font = null, size = 1) {
    this.cmd(`^FO${x},${y}`);
    if (font && isNaN(+font)) {
      this.cmd(`^CW9,${font}`);
      this.cmd(`^A9N,${size * 24},${size * 24}`);
    } else {
      this.cmd(`^A1N,${size * 16},${size * 8}`);
    }
    if (text.startsWith("@")) {
      const counter = this.counterList[+text.slice(1)];
      if (counter) {
        this.cmd(`^SN${counter.start},${counter.step},Y^FS`);
      } else {
        this.cmd(`^FD${text}^FS`);
      }
    } else {
      this.cmd(`^FD${text}^FS`);
    }
    return this;
  }

  qrcode(text: string, x: number, y: number, ecc = "H", width = 4) {
    this.cmd(`^FO${x},${y}`);
    this.cmd(`^BQN,2,${width},${ecc}`);
    text = text.replace(/\_/g, "_5f").replace(/\^/g, "_5e");
    this.cmd(`^FH^FDMA,${text}^FS`);
    return this;
  }

  barcode(text: string, x: number, y: number, height = 10, type = "ean13", width = 2) {
    this.cmd(`^FO${x},${y}`);
    this.cmd(`^BY${width}`);
    switch (type) {
      case "ean13":
        this.cmd(`^BEN,${height},N,N`);
        break;
      case "ean8":
        this.cmd(`^B8N,${height},N,N`);
        break;
      default:
        this.cmd(`^BCN,${height},N,N,N`);
        break;
    }
    text = text.replace(/\_/g, "_5f").replace(/\^/g, "_5e");
    this.cmd(`^FH^FD${text}^FS`);
    return this;
  }

  reset() {
    super.reset();
    this.raw(Buffer.from("\r\n"));
    this.cmd("^XA");
    if (this.codePage === "utf8") {
      this.cmd(`^CI28`);
    } else {
      this.cmd("^CI26");
    }
    this.inited = true;
    return this;
  }

  print(n: number) {
    this.cmd(`^PQ${n}`);
    this.cmd("^XZ");
    this.raw(Buffer.from("\r\n"));
    return this;
  }

  counterList: Record<
    number,
    {
      step: number;
      start: string;
    }
  > = {};

  counter(id: number, step: number, start: string) {
    this.counterList[id] = { step, start };
    return this;
  }

  getJob(name?: string, id?: string): PrintJob {
    const job = super.getJob(name, id);
    console.log(job.data.toString());
    return job;
  }

  async printImage(
    x: number,
    y: number,
    url: string,
    width: number,
    mode: "dither" | "color" | "colorAlpha" | "gray" | "mono" | "alpha" = "dither",
  ) {
    width = Math.ceil(width / 8) * 8;
    const img = await convertImage(url, width, mode);

    this.cmd(`^FO${x},${y}`);

    const rowBytes = img.width / 8;
    const bytes = (width / 8) * img.height;

    this.raw(Buffer.from(`^GFB,${bytes},${bytes},${rowBytes},`));

    const b = img.width / 8;
    for (let i = 0; i < img.height; i++) {
      const buf = Buffer.alloc(b);
      let ofs = width * i * 4;

      for (let j = 0; j < b; j++) {
        let v = 0;
        for (let k = 0; k < 8; k++) {
          const mv = 128 >> k;
          v |= img.buffer[ofs] ? 0 : mv;
          ofs += 4;
        }
        buf[j] = v;
      }
      this.raw(buf);
    }

    this.raw(Buffer.from("\n"));
    this.cmd("^FS");
    return this;
  }

  async printImageTagInner(x: number, y: number, pts: BitmapOpts, url: string, width: number) {
    this.raw(Buffer.from(`PUTBMP ${x},${y},"${pts.name}.BMP"`));
    return this;
  }

  async printImageData(x: number, y: number, buffer: Uint8ClampedArray, width: number, height: number) {
    const originalWidth = width;
    width = Math.ceil(width / 8) * 8;
    this.cmd(`^FO${x},${y}`);

    const rowBytes = width / 8;
    const bytes = (width / 8) * height;

    this.raw(Buffer.from(`^GFB,${bytes},${bytes},${rowBytes},`));

    const b = width / 8;
    for (let i = 0; i < height; i++) {
      const buf = Buffer.alloc(b);
      let ofs = originalWidth * i * 4;
      let eofs = ofs + originalWidth * 4;

      for (let j = 0; j < b; j++) {
        let v = 0;
        for (let k = 0; k < 8; k++) {
          const mv = 128 >> k;
          const color = ofs >= eofs ? 255 : buffer[ofs];
          v |= color > 128 ? 0 : mv;
          ofs += 4;
        }
        buf[j] = v;
      }
      this.raw(buf);
    }

    this.raw(Buffer.from("\n"));
    this.cmd("^FS");
    return this;
  }
}
