export default function Dialog(opts) {
    return {
        props: {
            value: {},
            modalId: {},
        },
        data() {
            return {
                modalDisposed: false,
            }
        },
        computed: {
            inputValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                }
            }
        },
        methods: {
            modalResult(item) {
                if(!this.modalDisposed) {
                    this.modalDisposed = true;
                }
                this.$root.$emit('modalResult', { result: item, id: this.modalId });
            }
        }
    }
}

