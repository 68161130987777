
import { Component, Prop, Vue } from "@feathers-client";
import { PrinterTemplateNodeInterpolation, compile, compileLabel } from "pos-printer-template";

@Component
export default class PrinterEditorBlock extends Vue {
  @Prop() template!: PrinterTemplateNodeInterpolation;

  @Prop() context: any;

  @Prop({ type: Boolean, default: true })
  condition: boolean;

  @Prop({ type: Boolean, default: true })
  parentCondition: boolean;

  get allCondition() {
    return this.condition && this.parentCondition;
  }

  get content() {
    if (this.context) {
      return this.template.getTextValue(this.context);
    }
  }
}
