
import { Component, Prop, Vue, mixins } from "@feathers-client";
import {
  Expression,
  PrinterTemplateNodeBase,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeRoot,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  compile,
  compileLabel,
} from "pos-printer-template";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorFeed extends mixins(PrinterEditorEditBase) {
  get chars() {
    return [
      {
        name: { $t: "printerEditor.separatorStyles.normal" },
        _id: "-",
      },
      {
        name: { $t: "printerEditor.separatorStyles.double" },
        _id: "=",
      },
    ];
  }

  get char() {
    const e = this.selectedNode?.props?.["char"];
    if (!e || !e.isStatic) return "-";
    return e.expression || "-";
  }

  set char(value: string) {
    if (!this.selectedNode) return;
    if (!value || value === "-") {
      this.selectedNode.deleteProp("char");
    } else {
      this.selectedNode.setProp("char", value);
    }
    this.$emit("update");
  }
}
