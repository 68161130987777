
import { Vue, Component, Prop, VModel, FindType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import { HasePayType } from "pos-printer/hase";
import { PayType } from "pos-printer/turncloud";
import { RazerPaymentMethod } from "pos-printer/razerpay";
import { PaymentMethodBase } from "pos-printer/payments/methodBase";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
    EditorCheckbox,
  },
})
export default class PaymentMethodOptions extends Vue {
  @Prop()
  label: string;

  @VModel({ default: () => ({}) })
  inputValue: any;

  @Prop()
  item: FindType<"paymentMethods">;

  manager: PaymentMethodBase = null;

  @Watch("item.type", { immediate: true })
  async onMethod() {
    this.manager = null;
    if (this.item?.type) {
      const type = this.item.type;
      const manager = await this.$paymentManager.getAllMethod(type);
      if (manager && type === this.item?.type) {
        this.manager = manager;
      }
    }
  }

  get availBBmslSubTypes() {
    return [
      { _id: "CREDIT", name: this.$t("paymentMethods.CREDIT") },
      { _id: "ALIPAY", name: this.$t("paymentMethods.ALIPAY") },
      { _id: "WECHAT", name: this.$t("paymentMethods.bbpos_wechat") },
      { _id: "OCTOPUS", name: this.$t("paymentMethods.OCTOPUS") },
      { _id: "QUICKPASS", name: this.$t("paymentMethods.QUICKPASS") },
      { _id: "QRPAY", name: this.$t("paymentMethods.QRPAY") },
      { _id: "OTHERS", name: this.$t("basic.others") },
    ];
  }

  get availMypaySubTypes() {
    return [
      { _id: "CREDITCARD", name: this.$t("mypay.CREDITCARD") },
      { _id: "UNIONPAY", name: this.$t("mypay.UNIONPAY") },
      { _id: "ALIPAY", name: this.$t("mypay.ALIPAY") },
      { _id: "WECHAT", name: this.$t("mypay.WECHAT") },

      { _id: "LINEPAYON", name: this.$t("mypay.LINEPAYON") },
      { _id: "LINEPAYOFF", name: this.$t("mypay.LINEPAYOFF") },
      { _id: "CASH", name: this.$t("mypay.CASH") },
      { _id: "OFFLINE", name: this.$t("mypay.OFFLINE") },
    ];
  }

  get availAllinpaySubTypes() {
    return [
      { _id: "CREDIT", name: this.$t("paymentMethods.CREDIT") },
      { _id: "SCAN", name: this.$t("paymentMethods.SCAN") },
      { _id: "QRCODE", name: this.$t("paymentMethods.QRCODE") },
    ];
  }

  get availSubMethods() {
    const subMethods = this.manager?.capabilities?.subMethods;
    if (subMethods) {
      return subMethods.map(method => ({
        _id: method.subType,
        name: this.$td(method.name),
      }));
    }
  }

  get availAllinpayCurrencies() {
    return [
      { _id: "HKD", name: "HKD" },
      { _id: "SGD", name: "SGD" },
      { _id: "USD", name: "USD" },
    ];
  }

  get availHaseSubtypes() {
    return Object.keys(HasePayType).map(key => ({
      _id: key,
      name: this.$t(`hase.${key}`),
    }));
  }

  get haseSubtypes() {
    return this.inputValue?.haseSubtypes ?? [];
  }

  set haseSubtypes(v) {
    this.inputValue = {
      ...this.inputValue,
      haseSubtypes: v || [],
    };
  }

  get availTurnCloudSubTypes() {
    return Object.keys(PayType).map(key => ({
      _id: key,
      name: this.$t(`turncloud.${key}`),
    }));
  }

  get availRazerSubTypes() {
    return Object.keys(RazerPaymentMethod).map(key => ({
      _id: key,
      name: this.$t(`razer.${key}`),
    }));
  }

  get clientKey() {
    return this.inputValue?.clientKey;
  }
  set clientKey(v) {
    this.inputValue = {
      ...this.inputValue,
      clientKey: v,
    };
  }

  get merchantId() {
    return this.inputValue?.merchantId;
  }
  set merchantId(v) {
    this.inputValue = {
      ...this.inputValue,
      merchantId: v,
    };
  }

  get scanAtPos() {
    return this.inputValue?.scanAtPos;
  }
  set scanAtPos(v) {
    this.inputValue = {
      ...this.inputValue,
      scanAtPos: v,
    };
  }

  get customerScan() {
    return this.inputValue?.customerScan;
  }

  set customerScan(v) {
    this.inputValue = {
      ...this.inputValue,
      customerScan: v,
    };
  }

  get mypaySubTypes() {
    return this.inputValue?.mypaySubTypes ?? [];
  }
  set mypaySubTypes(v) {
    this.inputValue = {
      ...this.inputValue,
      mypaySubTypes: v || [],
    };
  }

  get bbmslSubTypes() {
    return this.inputValue?.bbmslSubTypes ?? [];
  }
  set bbmslSubTypes(v) {
    this.inputValue = {
      ...this.inputValue,
      bbmslSubTypes: v || [],
    };
  }

  get allinpaySubTypes() {
    return this.inputValue?.allinpaySubTypes ?? [];
  }
  set allinpaySubTypes(v) {
    this.inputValue = {
      ...this.inputValue,
      allinpaySubTypes: v || [],
    };
  }

  get turnCloudSubTypes() {
    return this.inputValue?.turnCloudSubTypes ?? [];
  }

  set turnCloudSubTypes(v) {
    this.inputValue = {
      ...this.inputValue,
      turnCloudSubTypes: v || [],
    };
  }

  get razerSubTypes() {
    return this.inputValue?.razerSubTypes ?? [];
  }

  set razerSubTypes(v) {
    this.inputValue = {
      ...this.inputValue,
      razerSubTypes: v || [],
    };
  }

  get turnCloudTaxEnabled() {
    return this.inputValue?.turnCloudTaxEnabled;
  }

  set turnCloudTaxEnabled(v) {
    this.inputValue = {
      ...this.inputValue,
      turnCloudTaxEnabled: v,
    };
  }

  get turnCloudMerchant() {
    return this.inputValue?.merchant;
  }

  set turnCloudMerchant(v) {
    this.inputValue = {
      ...this.inputValue,
      merchant: v,
    };
  }

  get currency() {
    return this.inputValue?.currency ?? "HKD";
  }
  set currency(v) {
    this.inputValue = {
      ...this.inputValue,
      currency: v || "HKD",
    };
  }
  get printReceipt() {
    return this.inputValue?.printReceipt;
  }
  set printReceipt(v) {
    this.inputValue = {
      ...this.inputValue,
      printReceipt: v,
    };
  }

  get subMethods() {
    const subMethodKey = this.manager?.capabilities?.subMethodsKey ?? "subMethods";
    return this.inputValue?.[subMethodKey] ?? [];
  }

  set subMethods(v) {
    const subMethodKey = this.manager?.capabilities?.subMethodsKey ?? "subMethods";
    this.inputValue = {
      ...this.inputValue,
      [subMethodKey]: v || [],
    };
  }

  get secretFields() {
    return this.manager?.capabilities?.propsFields || {};
  }

  get hasSecretFields() {
    return Object.keys(this.secretFields).length > 0;
  }

  getField(key: string) {
    return this.inputValue?.[key];
  }

  setField(key: string, value: any) {
    this.inputValue = {
      ...this.inputValue,
      [key]: value,
    };
  }

  get hasEnv() {
    return !!this.manager?.capabilities?.envs?.length;
  }

  get availEnvs() {
    return (this.manager?.capabilities?.envs || []).map(it => ({
      _id: it.id,
      name: it.name || (it.testing ? { $t: "basic.testing" } : { $t: "basic.production" }),
    }));
  }

  get env() {
    return this.item.env;
  }

  set env(v) {
    const env = this.manager?.capabilities?.envs?.find?.(it => it.id === v);
    if (!env) return;
    Vue.set(this.item, "env", env.id);
    Vue.set(this.item, "testing", env.testing ?? false);
  }
  get point() {
    return this.inputValue?.point;
  }
  set point(v) {
    this.inputValue = {
      ...this.inputValue,
      point: v,
    };
  }

  get usePointRatioInt() {
    return this.inputValue?.usePointRatioInt;
  }
  set usePointRatioInt(v) {
    this.inputValue = {
      ...this.inputValue,
      usePointRatioInt: v,
    };
  }
}
