
import { FindType } from "@feathers-client";
import { Component, Vue, Prop } from "@feathers-client";
import { Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Verify extends Vue {
  @Prop()
  session: FindType<"tableSessions/summaries/verify">[number]["items"][number]["errors"][number];

  round(num: number) {
    return Math.round(num * 100) / 100;
  }

  expanded = false;
  loaded = false;
  adv = false;

  sessionData: FindType<"tableSessions"> = null;
  payments: FindType<"payments">[] = [];

  get name() {
    return `${this.session._id} (${this.session.sessionName || "-"})`;
  }

  @Watch("expanded")
  onExpandedChange() {
    if (this.expanded && !this.loaded) {
      this.loadDetails();
    }
  }

  async loadDetails() {
    this.loaded = true;
    try {
      this.sessionData = await this.$feathers.service("tableSessions").get(this.session._id);
      this.payments = await this.$feathers.service("payments").find({
        query: {
          session: this.session._id,
          $paginate: false,
        },
        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async repair() {
    try {
      await this.$feathers.service("tableSessions/repair").create({
        sessions: [this.session._id],
        operation: "sync",
      });
      await this.loadDetails();
      this.$store.commit("SET_SUCCESS");
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      console.error(e);
    }
  }

  async cancelPayment(payment: FindType<"payments">) {
    try {
      await this.$feathers.service("payments/cancel").create({
        payment: payment._id,
        status: "cancelled",
        cancelReason: "Force Cancel via repair",
      });
      await this.loadDetails();
      this.$store.commit("SET_SUCCESS");
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      console.error(e);
    }
  }

  pos() {
    this.$shop.editOrder(this.session);
  }
}
