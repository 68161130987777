
import { Vue, Component, Prop, FindType } from "@feathers-client";

@Component
export default class ProductStatusCell extends Vue {
  @Prop()
  user: FindType<"users">;

  @Prop()
  point: FindType<"shopPoints">;
}
