
import { Vue, Component, Prop, Watch, getID } from "@feathers-client";

interface CardDetails {
  queueingGroup: string;
  color: string;
  ticketLimit: string;
  currentTicketName: string;
  currentCallingTicketName: string;
  currentCallingTicketId: string;
  waitingLength: number;
}

@Component
export default class QueueingCard extends Vue {
  @Prop() cardDetails: CardDetails;
  @Prop() width: number;
  @Prop() height: number;
  @Prop() noOfRows: number;

  @Watch("cardDetails.currentCallingTicketId")
  async onCardDetailsUpdated() {
    if (this.cardDetails.currentCallingTicketId) {
      try {
        setTimeout(async () => {
          await this.$feathers.service("queueing/callTicketEnd").create({
            currentTicketName: this.cardDetails.currentTicketName,
            currentCallingTicketName: this.cardDetails.currentCallingTicketName,
            ticket: this.cardDetails.currentCallingTicketId,
            group: this.cardDetails.queueingGroup,
            shopSession: getID(this.$shopSession.shopSessionData),
          });
        }, 5000); // 5 seconds
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      }
    }
  }
}
