
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, FindType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import OrderSystemCheckoutBase from "../checkoutBase";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
    QrcodeStream,
  },
})
export default class OrderSystemCheckout extends mixins(OrderSystemCheckoutBase) {
  @Prop()
  session: TableSession;

  @Prop()
  currentAction: string;

  @Prop()
  merchantPortalMode: boolean;

  @Prop()
  dollar: FindType<"shopPoints">;

  created() {
    this.paymentDialog = true;
  }

  async mounted() {
    await this.initCheckout(this.currentAction === "useDollar" ? this.dollar?._id : null);
  }

  get splitted() {
    return (this.session?.splittedPayments?.length ?? 0) > 0;
  }

  checkoutTab = "method";
  get checkoutTabs() {
    return [
      "method",
      "discount",
      // "tip",
      // "point",
      ...(this.$shop?.shopData?.enablePartialPayment ? ["partial"] : []),
      ...(this.session?.user ? ["coupon"] : []),
      ...(this.session?.payments?.length ? ["record"] : []),
      // "remarks",
    ];
  }

  // discount, partial, method, record, splitBill, splitBillPaid
  get currentTabForSplitPayment() {
    if (this.session?.isPayingSplit) {
      if (this.session?.currentSplitPaid && this.session?.payingSplitBill !== null) {
        return "splitBillPaid";
      } else {
        return "method";
      }
    } else if (this.isPaying) {
      if (this.splitted) {
        return "splitBill";
      } else {
        return "method";
      }
    } else {
      return "method";
    }
  }

  @Watch("currentTabForSplitPayment")
  updateTab() {
    if (this.session && this.session?.isPayingSplit === null && !this.session?.isPayingSplit) return;
    this.checkoutTab = this.currentTabForSplitPayment;
  }

  async navigateAndPay() {
    if (!this.$shop.shopData?.handheldPaymentEnabled) {
      this.$store.commit(
        "SET_ERROR",
        this.$t("pos.mobile.handheld.payment.paymentFunctionNotEnable", {
          status: this.$t("pos.mobile.handheld.payment.pay"),
        }),
      );
      return;
    }
    this.manualConfirming = null;
    this.checkoutTab = "method";
    await Vue.nextTick();
    return await this.tryPay();
  }

  get isPaying() {
    return (this.session.postEditing && this.session.screenOverride === "checkout") || this.session.status === "toPay";
  }
}
