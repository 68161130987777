
import { Component, Vue, Prop, VModel } from "@feathers-client";
@Component
export default class ListPickerNew extends Vue {
  @Prop()
  items: any;

  @VModel() isOpen: boolean;

  name: string = "basic.all";

  get checkedName() {
    if (this.name === "basic.all") {
      return this.$t("basic.all");
    } else {
      return this.$td(this.name);
    }
  }

  onClickOutside() {
    this.isOpen = false;
  }

  update(v) {
    this.isOpen = false;
    this.name = v.name;
    this.$emit("update", v);
  }
  updateToAll() {
    this.isOpen = false;
    this.name = "basic.all";
    this.$emit("update", "all");
  }
}
