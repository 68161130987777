
import { Component, Prop, Vue, mixins } from "@feathers-client";
import {
  Expression,
  PrinterTemplateNodeBase,
  PrinterTemplateNodeInterpolation,
  PrinterTemplateNodeRoot,
  PrinterTemplateNodeTemplate,
  PrinterTemplateNodeText,
  compile,
  compileLabel,
} from "pos-printer-template";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import PrinterEditorEditBase from "./base";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  },
})
export default class PrinterEditorFeed extends mixins(PrinterEditorEditBase) {
  get n() {
    const e = this.selectedNode?.props?.["n"];
    if (!e || !e.isStatic) return undefined;
    return +e.expression;
  }

  set n(value: number | string) {
    if (!this.selectedNode) return;
    if (!value || value === "0") {
      this.selectedNode.deleteProp("n");
    } else {
      this.selectedNode.setProp("n", value.toString());
    }
    this.$emit("update");
  }
}
