
import { Component, Prop, Vue, Watch, VModel } from "nuxt-property-decorator";
import { TableView } from "~/plugins/table/view";
import { TableItem } from "~/plugins/table";
import { TableSession } from "~/plugins/table/session";
import { SceneCanvas } from "konva/lib/Canvas";

@Component
export default class ItemResize extends Vue {
  @Prop()
  item: TableItem;

  @Prop()
  tableView: TableView;

  @Prop()
  canvasWidth: number;

  @Prop()
  canvasHeight: number;

  @VModel()
  selectResizeMenu: boolean;

  @Prop()
  isMergeTable: boolean;

  configLayer = { listening: true, opacity: 0 };
  headerHeight = 56;
  footerHeight = 328;
  firstSelectedTableText = "";
  firstSelectedSession: TableSession;
  cachedSceneCanvas: SceneCanvas;
  cachedSceneValid = false;
  newTableSize: number = 0;
  isExisted = false;
  tableNames = [];
  currentTableName = "";

  get width() {
    return this.canvasWidth - 120;
  }

  mounted() {
    if (this.selectResizeMenu) {
      this.onToggleMenu();
    }
    this.tableNames = this.tableView.viewItems.map(table => table.name);
    this.currentTableName = this.item.name;
  }

  @Watch("selectResizeMenu")
  onToggleMenu() {
    if (!this.selectResizeMenu) {
      this.reset();
    }
  }

  reset() {
    this.newTableSize = 0;
    this.firstSelectedTableText = "";
    this.firstSelectedSession = null;
  }

  get tableCapacity() {
    return this.tableView.selectedCapacity;
  }

  get tableSize() {
    return this.newTableSize || this.tableView.selectedCapacity || 1;
  }
  set tableSize(v) {
    this.newTableSize = v;
  }

  async deleteTable() {
    const c =
      !this.item.item._id ||
      (await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("basic.doYouWantToDelete"),
        },
        {
          maxWidth: "400px",
        },
      ));
    if (c) {
      if (this.tableView.addingItem) {
        this.tableView.commitRemove();
      }
      this.item.deselect();
      this.$emit("resetSelect");
      await this.item.deleteTable();
    }
  }

  @Watch("item")
  onItemChange() {
    this.tableNames = this.tableView.viewItems.map(table => table.name);
    this.currentTableName = this.item.name;
    this.isExisted = false;
  }

  checkValid() {
    if (this.tableNames.includes(this.item.name) && this.currentTableName !== this.item.name) {
      this.isExisted = true;
      this.item.name = this.currentTableName;
    } else {
      this.isExisted = false;
      this.$emit("done");
    }
  }
}
