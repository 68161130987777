import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import VueI18n from "vue-i18n";
import url from "url";
import qs from "querystring";
import { Component, Prop } from "nuxt-property-decorator";
//@ts-ignore
import { loadLanguageAsync } from "@nuxt-root/nuxt-i18n/plugin.utils";
import { boxsCloudPost, appName } from "./remote";

if (process.client) {
//@ts-ignore
  global.__translateHelperInject__ = {
    Vue,
    Component,
    Prop,
  };
}

Vue.filter("tf", function (ctx, value, item, parent, header) {
  const t = _.get(value, header.tpath + "t");
  const v = (!_.isEmpty(t) && t) || _.get(value, header.tpath);
  return ctx.$td(v);
});

Vue.filter("t", function (ctx, value, item, parent, header) {
  return ctx.$td(value);
});

export type LangArrType = { lang: string; value: string }[];
export type LangObjType = { $t?: string; $join?: LangType[]; $a?: any; $ta?: { [key: string]: LangType }, $fallback?: LangType };
export type LangType = LangArrType | LangObjType | string;
type Fallback = { path: LangType; args?: { [key: string]: LangType } };
export type LangFallbackType = LangType | Fallback;

export type LangGenericType<T = any> = { lang: string; value: T }[];
export type LangInlineType<T = any> = (T & { lang: string })[];

declare let PRODUCTION_MODE: boolean;

export function bind(el, binding, vnode) {
  t(el, binding, vnode);
}

function localeEqual(el, vnode) {
  const vm = vnode.context;
  return el._locale === vm.$i18n.locale;
}

export function update(el, binding, vnode, oldVNode) {
  if (localeEqual(el, vnode) && _.isEqualWith(binding.value, binding.oldValue)) return;
  t(el, binding, vnode);
}

function t(el, binding, vnode) {
  let value = binding.value;
  let result = "";

  const vm = vnode.context;
  if (!vm) {
    console.warn("not exist Vue instance in VNode context");
    return;
  }
  const i18n = vm.$i18n;
  if (!i18n) {
    console.warn("not exist VueI18n instance in Vue instance");
    return;
  }
  const mappedLocale = mappedLocales[i18n.locale] || i18n.locale;

  if (value) {
    if (value.$join) {
      return _.map(value.$join, it => vm.$td(it)).join("");
    }
    let $ta = value.$ta;
    let $a = value.$a;
    if (value.path) {
      if (value.args) $ta = value.args;
      value = value.path;
    }

    if (typeof value === "string") {
      result = value;
    } else if (_.isArray(value)) {
      let enValue, locValue, defValue;
      _.each(value, v => {
        if (v.lang === mappedLocale) locValue = v.value;
        if (v.lang === "en") enValue = v.value;
        defValue = v.value;
      });
      result = locValue || enValue || defValue;
    } else if (typeof value === "object") {
      if (value.$t) {
        if ($ta) {
          const args = {
            ...$a,
            ..._.mapValues($ta, it => vm.$td(it)),
          };
          result = vm.$i18n.t(value.$t, args);
        } else if ($a) {
          result = vm.$i18n.t(value.$t, $a);
        } else {
          result = i18n.t(value.$t);
        }
        if(value.$fallback && result === value.$t) {
          result = vm.$td(value.$fallback);   
        }
      } else {
        result = value[mappedLocale] || value["en"] || _.map(value, it => it)[0] || "";
      }
    }
  }

  el._vt = el.textContent = result;
  el._locale = mappedLocale;
}

Vue.directive("td", {
  bind,
  update,
});

let i18NInit = false;

Vue.prototype.$ensureI18N = function () {
  if (process.browser) {
    if (i18NInit) return;
    const m = this.$i18n.locales.find(it => it.code === this.$i18n.locale);
    moment.locale(m.moment || m.iso);
    i18NInit = true;
  }
};

const mappedLocales = {
  "zh-hk": "cht",
  "zh-cn": "chs",
  "en-us": "en",
  "en-hk": "en",
  en: "en",
};
const unmappedLocales = {
  cht: "zh-hk",
  chs: "zh-cn",
};

Vue.prototype.$td = function (item, locale) {
  if (typeof item === "string") return item;
  else if (!item) return "";

  const i18n: VueI18n = this.$i18n || this.$root.$i18n;
  const unmapLocale = locale ? unmappedLocales[locale] || locale : i18n.locale;
  locale = locale || i18n.locale;
  locale = mappedLocales[locale] || locale;

  if (item.$join) {
    return _.map(item.$join, it => this.$td(it, locale)).join("");
  } else if (item.$t) {
    let result;
    if (item.$ta) {
      const args = {
        ...item.$a,
        ..._.mapValues(item.$ta, it => this.$td(it, locale)),
      };
      result = i18n.t(item.$t, unmapLocale, args);
    } else if (item.$a) {
      result = i18n.t(item.$t, unmapLocale, item.$a);
    } else {
      result = i18n.t(item.$t, unmapLocale, {});
    }
    if(item.$fallback && result === item.$t) {
      result = this.$td(item.$fallback, locale);   
    }
    if(result) return result;
  } else if (_.isArray(item)) {
    let enValue, locValue, defValue;
    _.each(item, v => {
      if (v.lang === locale) locValue = v.value;
      if (v.lang === "en") enValue = v.value;
      defValue = v.value;
    });
    return locValue || enValue || defValue;
  }

  return item[locale] || item["en"] || _.map(item, it => it)[0] || "";
};

Vue.prototype.$tdf = function (item, fallback) {
  return (!_.isEmpty(item) && this.$td(item)) || fallback;
};

Vue.prototype.$tObj = function <T extends { lang: string }>(item: T[], locale?: string, fallback = true): T {
  if (typeof item === "string") return item;
  else if (!item) return null;

  const i18n: VueI18n = this.$i18n || this.$root.$i18n;
  locale = locale || i18n.locale;
  locale = mappedLocales[locale] || locale;

  if (Array.isArray(item)) {
    let enValue: T, locValue: T, defValue: T;
    _.each(item, v => {
      if (v.lang === locale) locValue = v;
      if (v.lang === "cht") enValue = v;
      defValue = v;
    });
    if (!fallback) return locValue;
    return locValue || enValue || defValue || null;
  }
};

Vue.prototype.$i18nPush = function (url) {
  this.$router.push(this.$localePath(url));
};

Vue.prototype.$i18nReplace = function (url) {
  this.$router.replace(this.$localePath(url));
};

Vue.prototype.$tconcat = function (this: Vue, ...parts: (LangType | number)[]): LangArrType {
  return this.$tjoin(parts, "");
};

Vue.prototype.$tjoin = function (this: Vue, parts: (LangType | number)[], join: string = ","): LangArrType {
  const langs = _.uniq(
    [
      ...(this.$i18n as any).locales.map(it => it.code),
      ..._.flatMap(parts.map(part => (Array.isArray(part) ? part.map(p => p.lang) : []))),
    ].map(l => mappedLocales[l] || l),
  );
  return langs.map(lang => ({
    lang,
    value: parts.map(jt => (Array.isArray(jt) || typeof jt === "object" ? this.$td(jt, lang) : `${jt}`)).join(join),
  }));
};

export function localePath(router: any, i18n: VueI18n, url: string) {
  if (!url) return;
  if (url === "index") {
    url = "";
  }
  let route;
  if (typeof url === "string") {
    url = url.replace(/(\/?[\w\d-_]+)(\/?)(\?.*)?$/, (text, g1, g2, g3) => `${g1}/${g3 || ""}`);
    route = router.match(url) || router.match(`/${i18n.locale}${url}`);
  } else if (typeof url === "object") {
    route = url;
  }

  if (!route || !route.name) {
    console.warn("Cannot match url", {
      url,
      route,
    });
    route = router.match("/");
  }

  route = router.match({
    name: route.name ? [route.name.split("___")[0], i18n.locale].join("___") : null,
    params: route.params,
    query: route.query,
    hash: route.hash,
  });

  return route.fullPath;
}

let needNormalize = false;

Vue.prototype.$localePath = function (this: Vue, url: string) {
  if(needNormalize) {
    return localePath(this.$router, this.$i18n, url);
  }
  return url;
};

function getMoment() {
  const locales = new Set<string>(moment.locales());
  function m(...args) {
    const lang = (m as any).lang;
    if(locales.has(lang)) {
      return moment(...args);
    } else {
      return moment(...args).locale(lang);
    }
  }
  return m;
}

export default function (context) {
  const { app, store } = context;
  if(app.i18n.nuxtOptions?.strategy !== 'no_prefix') {
    needNormalize = true;
    const match = app.router.match;
    app.router.match = (to, from) => {
      if ((to?.name ?? "").indexOf("___") !== -1) {
        to.query.locale = to.name.split("___")[1];
        let route = match.call(app.router, to, from);
        return route;
      }
      if (app.i18n.__redirect) {
        let route = match.call(app.router, to, from);
        return route;
      }
      let route = match.call(app.router, to, from);
      if (!route?.name) {
        if (typeof to === "string") {
          route = match.call(app.router, `/${app.i18n.locale}${to}`, from);
        } else if (typeof to.path === "string") {
          to.path = `/${app.i18n.locale}${to.path}`;
          route = match.call(app.router, to, from);
        }
      }
      if (!route || !route.name) {
        console.warn("Cannot match url", {
          from,
          to,
          route,
        });
        route = match.call(app.router, "/");
      }
  
      const toLocale = route.query.locale;
      delete route.query.locale;
  
      route = match.call(app.router, {
        name: route.name ? [route.name.split("___")[0], toLocale || app.i18n.locale].join("___") : route.name,
        params: route.params,
        query: route.query,
        hash: route.hash,
      });
  
      return route;
    };
  
    if (app.i18n.locales.length > 1) {
      let initRedirect = true;
      app.router.beforeEach((to, from, next) => {
        if (initRedirect) {
          initRedirect = false;
          next();
          return;
        }
        if (app.context._redirected) {
          app.context._redirected = false;
          next();
          return;
        }
        const toName = (to.name || "").split("___")[0];
        const fromName = (from.name || "").split("___")[0];
        const toLang = (to.name || "").split("___")[1] || app.i18n.defaultLocale;
        const fromLang = (from.name || "").split("___")[1];
  
        if (process.browser) {
          if (
            toName === fromName &&
            _.isEqual(_.omit(to.query, "locale"), _.omit(from.query, "locale")) &&
            _.isEqual(to.params, from.params)
          ) {
            next(false);
            if (app.i18n.locale !== toLang) {
              window.history.replaceState(null, null, to.fullPath);
              // not sure is needed or not, but newer i18n plugin redirect in set locale function
              // set the route to prevent it
              app.context.route = to;
              app.i18n.setLocale(toLang || app.i18n.defaultLocale);
            }
            return;
          }
        }
        next();
      });
  
      if (process.client) {
        const replaceState = window.history.replaceState;
        const pushState = window.history.pushState;
        window.history.replaceState = function (data, title, u) {
          if (u && (<string>u).indexOf("locale=") !== -1) {
            const p = url.parse(<string>u);
            const q = qs.parse(p.query);
            delete q.locale;
            u = url.resolve(<string>u, "?" + qs.stringify(q));
            if (u.endsWith("?")) u = u.substr(0, u.length - 1);
          }
          replaceState.call(this, data, title, u);
        };
        window.history.pushState = function (data, title, u) {
          if (u && (<string>u).indexOf("locale=") !== -1) {
            const p = url.parse(<string>u);
            const q = qs.parse(p.query);
            delete q.locale;
            u = url.resolve(<string>u, "?" + qs.stringify(q));
            if (u.endsWith("?")) u = u.substr(0, u.length - 1);
          }
          pushState.call(this, data, title, u);
        };
      }
    }
  }


  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {};
  // onLanguageSwitched called right after a new locale has been set

  const langSwitch = app.i18n.onLanguageSwitched;
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    langSwitch?.();
    console.log("lang from", oldLocale, "to", newLocale);
    const locale = app.i18n.locales.find(it => it.code === newLocale);
    app.$moment.lang = locale.iso.toLowerCase();
    if (app.vuetify) {
      app.vuetify.framework.lang.current = locale.code;
    }
  };

  const dict = {};
  let missingList: any[] = [];
  let missingTimer: any = null;

  if (process.client && PRODUCTION_MODE && process.env.BUILD_INFO) {
    try {
      const j = localStorage["cachedMissingList"];
      if (j) {
        const list = JSON.parse(j);
        if (process.env.BUILD_INFO === list.builInfo) {
          Object.assign(dict, Object.fromEntries(list.list.map(k => [k, true])));
        }
      }
    } catch (e) {}
  }

  app.i18n.missing = (lang, key, vm) => {
    if (PRODUCTION_MODE && !appName) return;
    if (!dict[key]) {
      dict[key] = true;
      if (missingTimer) {
        clearTimeout(missingTimer);
        missingTimer = null;
      }
      missingList.push({
        key,
        url: vm?.$route?.path,
        locale: lang,
      });
      missingTimer = setTimeout(
        async () => {
          const list = missingList;
          missingList = [];
          missingTimer = null;
          if (!PRODUCTION_MODE) {
            try {
              const r = await fetch(`${app.$config.apiUrl}/api/missingTranslations`, {
                method: "POST",
                body: JSON.stringify(list),
                headers: {
                  "Content-Type": "application/json",
                },
              });
              await r.text();
            } catch (e) {
              console.warn(e);
            }
          } else {
            try {
              localStorage["cachedMissingList"] = JSON.stringify({
                buildInfo: process.env.BUILD_INFO || "{}",
                list: Object.keys(dict),
              });
            } catch (e) {
              console.warn(e);
            }
            await boxsCloudPost("missingTranslate", {
              list,
              app: appName,
            });
          }
        },
        PRODUCTION_MODE ? 3000 : 3000,
      );
    } else {
      return key;
    }
  };

  (VueI18n.prototype as any)._isSilentFallback = function (this: any, locale, key) {
    if (PRODUCTION_MODE) {
      return key;
    } else if (dict[key]) {
      return key;
    } else {
      return this._isSilentFallbackWarn(key) && (this._isFallbackRoot() || locale !== this.fallbackLocale);
    }
  };

  (VueI18n.prototype as any)._isSilentFallbackWarn = function (this: any, locale, key) {
    return true;
  };

  (VueI18n.prototype as any)._isSilentTranslationWarn = function (this: any, locale, key) {
    if (PRODUCTION_MODE) {
      return key;
    } else if (dict[key]) {
      return key;
    } else {
      return this._silentTranslationWarn;
    }
  };

  app.i18n.loadAllLocales = (VueI18n.prototype as any).loadAllLocales = async () => {
    await Promise.all(app.i18n.locales.map(locale => app.i18n.loadLocale(locale.code)));
  };

  app.$t = app.i18n.t.bind(app.i18n);

  app.i18n.loadLocale = (VueI18n.prototype as any).loadLocale = (lang): Promise<void> => {
    return loadLanguageAsync(context, lang);
  };

  const locale = app.i18n.locales.find(it => it.id === app.i18n.locale) || app.i18n.locales.find(it => it.code === app.i18n.locale) || app.i18n.locales[0];

  app.$moment = getMoment();
  app.$moment.lang = (locale.iso || locale.code || locale.locale || '').toLowerCase();
  if (app.vuetify) {
    app.vuetify.framework.lang.current = locale.code;
  }

  if (process.client && !PRODUCTION_MODE) {
    import("./helper").then(c => c.default(context));
  }
}
