
import { Component, Vue, Prop, Watch, mixins, FindType } from "@feathers-client";
import { TableSession } from "~/plugins/table/session";
import OrderSystemCheckoutBase from "../orderSystem/checkoutBase";
import NumPadNew from "@feathers-client/components/NumPadNew.vue";

@Component({ components: { NumPadNew } })
export default class Topup extends mixins(OrderSystemCheckoutBase) {
  @Prop()
  memberId: string;

  @Prop()
  session: TableSession;

  @Prop()
  dollar: FindType<"shopPoints">;

  topUpAmount = "";

  confirmed = false;

  beforeDestroy() {
    if (this.session.isOngoing || this.session.isPaying) this.session.cancelPending();
  }

  get sessionPayment() {
    return this.session.payments?.slice?.(-1)?.[0] ?? null;
  }

  get priceAmount() {
    return Number(this.topUpAmount) * this.dollar?.topUpRatioInt || 0;
  }

  async confirmTopUp() {
    await this.session.addToCart(
      {
        quantity: 1,
      },
      {
        customProduct: { name: "dollarOpenKey", price: this.priceAmount } as any,
      },
    );

    await this.session.atomic({ topUp: { point: this.dollar._id, value: Number(this.topUpAmount) } });

    await this.session.placeAndConfirm();

    await this.initCheckout(null, false);

    this.confirmed = true;
  }

  async editTopUp() {
    this.session.cancelBilling();
    this.confirmed = false;
  }

  async navigateAndPay(select = false) {
    if (this.paymentComponent && select) {
      return;
    }
    this.manualConfirming = null;
    await Vue.nextTick();
    await this.tryPay();
  }
}
